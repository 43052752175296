  <template>
  <div id="top-info">
    <table class="detail-top">
      <tbody>
        <tr>
          <td>
<!--            <span class="title">{{ $t('exchange.basic.trading_page.last_price') }}:</span>-->
<!--            <br class="visible_rp">-->
            <span class="price-current-coin" :class="{grown: lastPriceGrownStatus  > 0, down: lastPriceGrownStatus  < 0}">
              {{currentPrice | formatCurrencyAmount(currency, '0')}}
            </span>
            <fiat-currency class="price-previous-coin" :value="usdtPrice" v-if="!isUsdtCurrency"></fiat-currency>
          </td>
          <td>
            <div class="title">{{ $t('exchange.basic.trading_page.change_24h') }}</div>
            <span class="nb_basic_tn" :class="{grown: percentChange > 0, down: percentChange < 0}">{{ get24hPriceChanged() | formatCurrencyAmount(currency, '0') }}</span>
            <span class="nb_basic_tn" :class="{grown: percentChange > 0, down: percentChange < 0}">{{ characterIncrease }}{{ formatShortText((percentChange), 2, 8)}}%
             </span>
          </td>
          <td>
            <div class="title">{{ $t('exchange.basic.trading_page.high_24h') }}</div>
            <span class="nb_basic_tn">{{ maxPrice | formatCurrencyAmount(currency, '0') }}</span>
          </td>
          <td>
            <div class="title">{{ $t('exchange.basic.trading_page.low_24h') }}</div>
            <span class="nb_basic_tn">{{ minPrice | formatCurrencyAmount(currency, '0') }}</span>
          </td>
          <td>
            <div class="title">{{ $t('exchange.basic.trading_page.volume_24h') }}({{ coin.toUpperCase() }})</div>
            <span class="nb_basic_tn">{{ volume | formatCurrencyAmount(currency, '0') }}
<!--              {{ coin | uppercase }}-->
            </span>
          </td>
        </tr>
      </tbody>
    </table>

  </div>
</template>

<script>
import rf from '@/request/RequestFactory'
import Utils from '@/common/Utils'
import Const from '@/common/Const';
import BigNumber from 'bignumber.js';

export default {
    data() {
      return {
        currency: this.$route.query.currency || Const.DEFAULT_CURRENCY,
        coin: this.$route.query.coin || Const.DEFAULT_COIN,
        prices: {},
        lastPriceGrownStatus: 0,
        previousPrice: 0,
        currentPrice: 0,
        changePrice: 0, // change price with latest price
        usdtPrice: 0,
        minPrice: 0,
        maxPrice: 0,
        price24h: 0,
        volume: 0,
        percentChange: 0,
      };
    },

    computed: {
      tradingPair: function () {
        return this.currency + '_' + this.coin;
      },

      tradingPairPrice: function() {
        return this.prices[this.tradingPair] || {};
      },

      isUsdtCurrency() {
        return this.currency === 'usdt';
      },

      characterIncrease(percent) {
        if (this.percentChange <= 0) {
          return '';
        }
        return '+';
      }
    },

    watch: {
      $route (to, from) {
        this.currency = this.$route.query.currency || Const.DEFAULT_CURRENCY;
        this.coin = this.$route.query.coin || Const.DEFAULT_COIN;

        this.initData();
      },
    },

    methods: {
      formatShortText(value, maxDecimal, maxLength) {
        if(value == null || value == "Infinity") return '0.0';
        const split = parseFloat(value).toString().split('.');
        const beforeDecimal = split[0];
        const numFormat = this.numFormat(value, maxDecimal)
        const shorttedNumber = beforeDecimal.length > maxLength ? numFormat.slice(0, maxLength-1).replace(/\d(?=(\d{3})+\.)/g, "$&,").concat('...') :
          numFormat.slice(0, maxLength).replace(/\d(?=(\d{3})+\.)/g, "$&,").concat('...')
        const returnValue = numFormat.length > maxLength ? shorttedNumber : numFormat.replace(/\d(?=(\d{3})+\.)/g, "$&,");
        return returnValue.toString();
      },
      numFormat (value, maxDecimal) {
        const numFormat = new BigNumber(value).toFixed(maxDecimal, BigNumber.ROUND_DOWN);
        return numFormat;
      },
      getSocketEventHandlers() {
        return {
          PricesUpdated: this.onPricesUpdated,
          OrderTransactionCreated: this.onOrderTransactionCreated
        }
      },
      getEventHandlers() {
        return {
          SpotSelectedPairMarket: this.onSpotSelectedPairMarket,
        }
      },

      onPricesUpdated(newPrices) {
        this.prices = Object.assign({}, this.prices, newPrices);
        if (window._.isEmpty(this.prices)) {
          return;
        }
        let coinPrice = newPrices[this.tradingPair];
        if (!coinPrice) {
          return;
        }
        if (this.currentPrice) {
          this.changePrice = new BigNumber(coinPrice.price).sub(this.currentPrice).toString();
        }
        this.price24h = coinPrice.last_24h_price;
        this.previousPrice = coinPrice.previous_price || this.currentPrice;
        this.currentPrice = coinPrice.price;
        if (!this.previousPrice || !this.currentPrice) {
          this.lastPriceGrownStatus = 0;
        } else {
          this.lastPriceGrownStatus = new BigNumber(this.currentPrice).comparedTo(this.previousPrice);
        }
        if (new BigNumber(this.currentPrice).gt(this.maxPrice)) {
          this.maxPrice = this.currentPrice;
        }
        if (new BigNumber(this.currentPrice).lt(this.minPrice)) {
          this.minPrice = this.currentPrice;
        }

        this.percentChange = this.getPercentChanged();
        this.usdtPrice = this.getusdtPrice();

        let titlePrice = this.tradingPairPrice.price;
        titlePrice = Utils.formatCurrencyAmount(titlePrice, this.currency);
        titlePrice = titlePrice + " " + this.coin + '/' + this.currency;
        if(this.$route.name === 'Spot Exchange' ) {
          document.title = titlePrice.toUpperCase() + ` – ${process.env.VUE_APP_NAME}`;
        }
      },

      onOrderTransactionCreated(data) {
        if (data.orderTransaction.coin === this.coin && data.orderTransaction.currency === this.currency) {
          this.volume = new BigNumber(this.volume).add(data.orderTransaction.quantity);
        }
      },

      onSpotSelectedPairMarket(pair) {
        this.currency = pair.currency;
        this.coin = pair.coin;

        this.getPriceScope()
          .then(() => {
            this.onPricesUpdated(this.prices);
          });
      },

      getusdtPrice() {
        if (this.currency === 'usdt') {
          return this.currentPrice;
        }
        let usdtPrice = this.prices['usdt_' + this.currency];
        if (!usdtPrice) {
          return 0;
        }
        const price = usdtPrice.price;
        return new BigNumber(this.currentPrice).mul(price).toString();
      },

      get24hPriceChanged() {
        return new BigNumber(this.currentPrice).sub(this.price24h).toString();
      },

      getPercentChanged() {
        var change = this.get24hPriceChanged();
        if (change === '0') return '0';

        if (this.price24h == 0) return '100'; //division to zero

        return new BigNumber(change).div(this.price24h).mul(100);
      },

      getPriceScope() {
        let params = {
          currency: this.currency,
          coin: this.coin
        };
        return rf.getRequest('PriceRequest').getPriceScope(params).then(res => {
          this.maxPrice = res.data.max_price;
          this.minPrice = res.data.min_price;
          this.volume = res.data.volume;
          this.$broadcast('SpotUpdateVolumeMarket', res.data);
        }).catch(e => {
          console.log(e);
        });
      },

      initData() {
        this.changePrice = 0;
        this.currentPrice = 0;

        rf.getRequest('PriceRequest').getPrices().then((res) => {
          this.onPricesUpdated(res.data);
        });
      }
    },

    mounted() {
      this.initData();
    }
  }
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

  .dark-theme {
    #top-info {
      border-left: 4px solid var(--color-border-right);
      .price-previous-coin {
        color: $color-auro-metal;
      }

      .title {
        color: $color-auro-metal;
      }

      .nb_basic_tn {
        color: $color-white;
      }
    }
  }

  @media only screen and (max-width: 1601px) {
    .dark-theme #top-info {
      .price-current-coin {
        color: $color-white;
      }
    }
  }

    .grown {
      color: $text-color-jade!important;
    }
    .down {
      color: $text-color-red!important;
    }
  #top-info {
    display: flex;
    align-items: center;
    height: 100%;
    color: $color-white;
    border-left: 4px solid var(--color-border-right);
    // width: calc(100% - 145px - 26px);
    overflow: hidden;
    padding-left: 16px;
    line-height: 13px;
    float: left;
    .detail-top {
      font-size: $font-mini;
      color: $color-gray-dark;
      tbody {
        tr {
          td {
            padding-right: 30px;
          }
        }
      }
    }

    .title {
      font-size: $font-medium-bigger;
      color: $color-gray-dark;
      margin-bottom: 4px;
    }

    .price-previous-coin {
      font-style: normal;
      font-weight: 500;
      font-size: $font-root;
      line-height: 15px;
      color: $color-gray-dark;
    }
    .price-current-coin {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: var(--text-primary-color);
      font-family: $font-inter-medium;
    }

    .nb_basic_tn {  
      font-size: $font-root;
      color: $color-grey-dark;
      margin-right: 3px;
    }
    .nb_basic_10 {
      color: $color-white;
      font-size: $font-mini-mini;
    }
  }

  @media only screen and (max-width: 1500px) {
    #top-info {
      padding-left: 0;
      .detail-top {
        tbody {
          tr {
            td {
              padding-right: 10px;
              .title {
                font-size: $font-root;
              }
              .nb_basic_tn {
                font-size: $font-smaller;
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1500px) {
    #top-info {
      padding-left: 16px;
    }
  }

@media only screen and (max-width: 996px) {
  #top-info {
    border-left: none;
  }
  .dark-theme {
    #top-info {
      border-left: none;
    }
  }
}

</style>
