<template>
  <div class="ReceiveCodeModal">
    <modal :name="name" :handleOutSide="resetTab">
      <div slot="body">
        <div class="modal-title">{{ $t("receive_code.modal.title") }}</div>
        <div class="modal-main">
          <div class="tabs">
            <button
              :class="{ active: activedTab === 'email' }"
              @click="setActivedTab('email')"
            >
              Email
            </button>
            <button
              :class="{ active: activedTab === 'mobile' }"
              @click="setActivedTab('mobile')"
            >
              Mobile
            </button>
          </div>
          <div class="content">
            <div class="title">{{ $t("receive_code.modal.sorry") }}</div>
            <ol v-if="activedTab === 'email'">
              <li>{{ $t("receive_code.modal.email.step_1") }}</li>
              <li>{{ $t("receive_code.modal.email.step_2") }}</li>
              <li>{{ $t("receive_code.modal.email.step_3") }}</li>
            </ol>
            <ol v-else>
              <li>{{ $t("receive_code.modal.mobile.step_1") }}</li>
              <li>{{ $t("receive_code.modal.mobile.step_2") }}</li>
              <li>{{ $t("receive_code.modal.mobile.step_3") }}</li>
            </ol>
            <div class="title">
              {{ $t("receive_code.modal.another_solution") }}
            </div>
          </div>
        </div>
        <button class="understood" @click="understood()">
          {{ $t("receive_code.modal.understood") }}
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/shared_components/common/Modal";
import rf from "@/request/RequestFactory";

export default {
  components: {
    Modal,
  },
  props: {
    name: { type: String, default: "ReceiveCodeModal" },
  },
  data() {
    return {
      activedTab: 'email',
    };
  },
  methods: {
    resetTab() {
      this.getUser();
    },
    setActivedTab(params) {
      this.activedTab = params;
    },
    understood() {
      CommonModal.hide("ReceiveCodeModal");
      CommonModal.show("SafetyVerificationModal");
    },
    getUser() {
      rf.getRequest("UserRequest")
        .getCurrentUser(true)
        .then((res) => {
          const user = Object.assign({}, this.user, res.data);
          console.log('user', user);
          if (
            user?.security_setting?.phone_verified &&
            user?.security_setting?.is_enable_sms
          ) {
            this.activedTab = "mobile";
          } else {
            this.activedTab = "email";
          }
        });
    },
  },
  mounted() {
    this.getUser();
  },
};
</script>

<style lang="scss">
.ReceiveCodeModal {
  .modal .modal-dialog .modal-content {
    .modal-header {
      padding: 40px 30px !important;
      font-size: 30px;
      font-weight: 600;
      text-align: center;
    }

    .modal-title {
      color: var(--text-primary-color);
      font-size: 30px;
      font-weight: 600;
      text-align: center;
    }

    .modal-body {
      margin-top: -65px;

      .modal-main {
        margin-top: 34px;

        .tabs {
          display: flex;
          gap: 30px;

          button {
            background-color: transparent;
            color: var(--text-close);
            font-size: 16px;
            font-weight: 500;
            line-height: 19px;
            transition: 0.4s ease;
            position: relative;

            &::after {
              background-color: transparent;
              bottom: -10px;
              content: "";
              height: 4px;
              left: 0;
              transition: 0.4s ease;
              position: absolute;
              width: 100%;
            }

            &.active {
              color: var(--dark-1);

              &::after {
                background-color: $color-caribbean-green;
                border-radius: 20px;
              }
            }
          }
        }

        .content {
          color: var(--text-close);
          display: flex;
          flex-direction: column;
          font-size: 14px;
          gap: 20px;
          margin-top: 30px;

          ol {
            padding-left: 16px;
          }
        }
      }

      .understood {
        color: $color-grey-dark;
        background-color: $color-caribbean-green;
        border-radius: 8px;
        font-size: 16px;
        line-height: 21px;
        margin-top: 20px;
        padding: 14px 22px;
        width: 100%;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
</style>
