<template>
  <div id="contact_details" class="clearfix">
     <div class="clearfix"></div>
    
      <div id="header_balance" @click="showBalance()">
        <span class="txt">{{ $t('margin.balance') }}</span><i class="icon icon-arrow2"></i>
      </div>
      <div class="clearfix"></div>
      <div id="content_balance">
        <div v-if='balance.walletBalance' class="box_balance clearfix">
            
          <div class="lastPriceWidget">
            <div class="name_balance1">
              <div class="priceWidget up">{{ balance.walletBalance | formatMarginCapital }}<span class="name_coin">{{ $t('margin.symbol.btc') }}</span></div>
              <div class="tooltip_custom_c1">
                {{ $t('margin.wallet_balance_tooltip') }}
              </div>
            </div>
          </div>

          <div class="clearfix"></div>

          <div class="group_balance clearfix">
            <ul class="list_balance_margin">
              <li class="item clearfix">
                <div class="name_balance">
                  {{ $t('margin.order_margin') }}
                  <div class="tooltip_custom_c">
                    {{ $t('margin.order_margin_tooltip') }}
                  </div>
                </div>
                <div class="right_balance_margin">
                  <span class="value">{{ balance.orderMargin | formatMarginCost }}</span>
                  <span class="name_coin">{{ $t('margin.symbol.btc') }}</span>
                </div>
                <div class="clearfix"></div>
                <div class="parameter down" :style="{ width: displayPercent(orderMarginBgPercent) + '%' }">
                  <span class="value_parameter" :style="{ width: 100 + '%' }"></span>
                </div>
              </li>
              <li class="item clearfix">
                <div class="name_balance">
                  {{ $t('margin.isolated_balance') }}
                  <div class="tooltip_custom_c">
                    {{ $t('margin.isolated_balance_tooltip') }}
                  </div>
                </div>
                <div class="right_balance_margin">
                  <span class="value">{{ balance.isolatedBalance | formatMarginCost }}</span>
                  <span class="name_coin">{{ $t('margin.symbol.btc') }}</span>
                </div>
                <div class="clearfix"></div>
                <div class="parameter up" :style="{ width: displayPercent(isolatedBalancePercent) + '%' }">
                  <span class="value_parameter" :style="{ width: 100 + '%' }"></span>
                </div>
              </li>
              <!-- <div class=seperator></div> -->
              <li class="item clearfix">
                <div class="name_balance">
                  {{ $t('margin.cross_equity') }}
                  <div class="tooltip_custom_c">
                    {{ $t('margin.cross_equity_tooltip') }}
                  </div>
                </div>
                <div class="right_balance_margin">
                  <span class="value">{{ balance.crossEquity | formatMarginCapital }}</span>
                  <span class="name_coin">{{ $t('margin.symbol.btc') }}</span>
                </div>
                <div class="clearfix"></div>
                <div class="parameter up" :style="{ width: displayPercent(crossEquityPercent) + '%' }">
                  <span class="value_parameter" :style="{ width: 100 + '%' }"></span>
                </div>
              </li>
              <li class="item clearfix">
                <div class="name_balance">
                  {{ $t('margin.unrealised_pnl') }}
                  <div class="tooltip_custom_c">
                    {{ $t('margin.unrealised_pnl_tooltip') }}
                  </div>
                </div>
                <div class="right_balance_margin">
                  <span class="value">{{ balance.unrealisedPnl | formatCurrencyAmount(null, '0') }}</span>
                  <span class="name_coin">{{ $t('margin.symbol.btc') }}</span>
                </div>
                <div class="clearfix"></div>
                <div class="parameter" :class="{ 'up': balance.unrealisedPnl >= 0, 'down': balance.unrealisedPnl < 0 }"
                  :style="{ width: isUnrealisedPnlPositive() ? displayPercent(100) + '%' : displayPercent(unrealisedPnlPercent) + '%' }">
                  <span class="value_parameter" :style="{ width: isUnrealisedPnlPositive() ? unrealisedPnlPercent + '%' : 100 + '%' }"></span>
                </div>
              </li>
              <li class="item clearfix">
                <div class="name_balance">
                  {{ $t('margin.cross_margin') }}
                  <div class="tooltip_custom_c">
                    {{ $t('margin.cross_margin_tooltip') }}
                  </div>
                </div>
                <div class="right_balance_margin">
                  <span class="value">{{ balance.crossMargin | formatMarginCost }}</span>
                  <span class="name_coin">{{ $t('margin.symbol.btc') }}</span>
                </div>
                <div class="clearfix"></div>
                <div class="parameter down" :style="{ width: displayPercent(crossMarginBgPercent) + '%' }">
                  <span class="value_parameter" :style="{ width: displayPercent(crossMarginPercent) + '%' }"></span>
                </div>
              </li>
              <li class="item clearfix">
                <div class="name_balance">
                  {{ $t('margin.available_balance') }}
                  <div class="tooltip_custom_c">
                    {{ $t('margin.available_balance_tooltip') }}
                  </div>
                </div>
                <div class="right_balance_margin">
                  <span class="value">{{ displayPositive(balance.availableBalance) | formatMarginCapital }}</span>
                  <span class="name_coin">{{ $t('margin.symbol.btc') }}</span>
                </div>
                <div class="clearfix"></div>
                <div class="parameter up" :style="{ width: displayPercent(availableBalancePercent) + '%' }">
                  <span class="value_parameter" :style="{ width: 100 + '%' }"></span>
                </div>
              </li>
            </ul>
          </div>

        </div>
      </div>
  </div>
</template>

<script type="text/javascript">
  import BigNumber from 'bignumber.js';
  import { mapGetters } from 'vuex';

  export default {
    components: {
    },
    computed: {
      ...mapGetters({ balance: 'mamBalance' }),
      orderMarginPercent: function () {
        return new BigNumber(`${this.balance.orderMargin || 0}`).div(`${this.balance.walletBalance || 0}`).mul('100').toString();
      },
      orderMarginBgPercent: function () {
        return new BigNumber(`${this.balance.orderMargin || 0}`).div(`${this.balance.walletBalance || 0}`).mul('100').toString();
      },
      isolatedBalancePercent: function () {
        return new BigNumber(`${this.balance.isolatedBalance || 0}`).div(`${this.balance.walletBalance || 0}`).mul('100').toString();
      },
      crossEquityPercent: function () {
        return this.isUnrealisedPnlPositive() ? '100'
          : new BigNumber(`${this.balance.crossEquity || 0}`).div(`${this.balance.crossBalance || 0}`).mul('100').toString();
      },
      unrealisedPnlPercent: function () {
        return this.isUnrealisedPnlPositive()
          ? new BigNumber(`${this.balance.unrealisedPnl || 0}`).div(`${this.balance.crossEquity || 0}`).mul('100').toString()
          : new BigNumber(`${this.balance.unrealisedPnl || 0}`).absoluteValue().div(`${this.balance.crossBalance || 0}`).mul('100').toString();
      },

      crossMarginPercent: function () {
        const total = new BigNumber(`${this.balance.unrealisedPnl || 0}`).absoluteValue().add(`${this.balance.crossMargin || 0}`).toString();
        return new BigNumber(`${this.balance.crossMargin || 0}`).div(`${total || 0}`).mul('100').toString();
      },
      crossMarginBgPercent: function () {
        const maxCross = this.maxCross();
        return new BigNumber(`${this.balance.unrealisedPnl || 0}`).absoluteValue().add(`${this.balance.crossMargin || 0}`).div(`${maxCross || 0}`).mul('100').toString();
      },

      availableBalancePercent: function () {
        const maxCross = this.maxCross();
        const subAmount = new BigNumber(`${this.balance.unrealisedPnl || 0}`).absoluteValue().add(`${this.balance.crossMargin || 0}`).toString();
        return new BigNumber(`${maxCross || 0}`).minus(`${subAmount || 0}`).div(`${maxCross || 0}`).mul('100').toString();
      },
    },
    data() {
      return {
        
      }
    },
    methods: {
      displayPositive (value) {
        if (new BigNumber(`${value || 0}`).comparedTo(0) < 0) {
          return 0;
        }
        return value;
      },
      displayPercent (value) {
        if (this.balance.walletBalance == 0 || this.balance.walletBalance == '0') {
          return 0;
        }
        if (new BigNumber(`${value || 0}`).comparedTo(100) > 0) {
          return 100;
        }
        return value;
      },
      showBalance() {
        var element = document.getElementById("content_balance");
        element.classList.toggle("isHidden");
        var element = document.getElementById("header_balance");
        element.classList.toggle("isRemove");
      },
      isUnrealisedPnlPositive() {
        return new BigNumber(`${this.balance.unrealisedPnl || 0}`).comparedTo(0) >= 0 ? true : false;
      },
      maxCross () {
        return BigNumber.max(new BigNumber(`${this.balance.crossBalance || 0}`).toString(), new BigNumber(`${this.balance.crossEquity || 0}`)).toString();
      },
    },
    mounted() {
    }
   }
</script>

<style lang="scss" scoped>
  @import '@/assets/sass/variables';
  #your_position {
    height: 100%;
  }
  .up {
    color: $color-jungle-green;
  }
  .down {
    color: $color-denim;
  }
  #contact_details {
    margin-top: -1px;
  }
  #header_balance {
    height: 41px;
    background-color: $color-ebony-clay;
    padding: 10px 15px;
    line-height: 20px;
    font-size: $font-root;
    color: $color-orange;
    font-family: $font-family-roboto-bold;
    cursor: pointer;
    border-top: solid 1px $color-bright-gray-dark;
    border-bottom: solid 1px $color-bright-gray-dark;
    .icon {
      float: right;
      font-size: $font-mini-mini;
      display: inline-block;
      height: 20px;
      line-height: 20px;
    }
    .icon-arrow2 {
      -moz-transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
    }
    &.isRemove {
      .icon-arrow2 {
        -moz-transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        -ms-transform: rotate(180deg); /* IE 9 */
        -webkit-transform: rotate(180deg); /* Safari */
        transform: rotate(180deg);
      }
    }
  }
  #content_balance {
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    min-height: 250px;
    opacity: 1;
    background-color: #111722;
    .seperator {
      border-bottom: solid 1px $color-bright-gray-dark;
      margin-bottom: 10px
    }
    &.isHidden {
      min-height: 0;
      height: 0px;
      opacity: 0.5;
      overflow: hidden;
      -moz-transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
    }
    .box_balance {
      padding: 15px 15px;
      .lastPriceWidget {
        line-height: 25px;
        margin-bottom: 15px;
        text-align: center;
        .priceWidget {
          color: $color-grey;
          letter-spacing: 0.6px;
          font-size: $font-big-20;
          font-family: $font-family-roboto-bold;
          vertical-align: middle;
          .icon {
            font-size: $font-big;
          }
          &.up {
            color: $color-orange;
          }
          &.down {
            color: $color-denim;
          }
          .name_coin {
            margin-left: 5px;
          }
        }
      }

      .name_balance1 {
        position: relative;
        .tooltip_custom_c1 {
          display: none;
          width: 250px;
          background-color: #222832;
          color: #cccccc;
          padding: 5px 5px;
          border-radius: 4px;
          font-size: 13px;
          -webkit-box-shadow: 0px 0px 3px #cccccc;
          box-shadow: 0px 0px 3px #cccccc;
          position: absolute;
          z-index: 50;
          left: -2%;
          top: calc(100% + 5px);
          text-align: left;
          font-family: $font-family-roboto;
        }
        &:hover {
          cursor: help;
          .tooltip_custom_c1 {
            display: block;
          }
        }
      }

      .group_balance {
        
        .list_balance_margin {
          display: block;
          width: 100%;
          .item {
            width: 100%;
            display: block;
            line-height: 14px;
            margin-bottom: 13px;
            &:last-child {
              margin-bottom: 0px;
            }
            .name_balance {
              display: inline-block;
              float: left;
              font-size: $font-small;
              color: $color-grey;
              position: relative;
              .tooltip_custom_c {
                display: none;
                width: 250px;
                background-color: #222832;
                color: #cccccc;
                padding: 5px 5px;
                border-radius: 4px;
                font-size: 13px;
                -webkit-box-shadow: 0px 0px 3px #cccccc;
                box-shadow: 0px 0px 3px #cccccc;
                position: absolute;
                z-index: 50;
                left: -50%;
                bottom: calc(100% + 5px);
                text-align: left;
                font-family: $font-family-roboto;
              }
              &:hover {
                background: rgba(45, 167, 117, 0.2);
                cursor: help;
                .tooltip_custom_c {
                  display: block;
                }
              }
            }
            .right_balance_margin {
              display: inline-block;
              float: right;
              font-family: Roboto-Medium;
              text-align: right;
              font-size: $font-smaller;
              color: $color-grey-sliver;
            }
            .parameter {
              margin-top: 3px;
              display: block;
              height: 2px; 
              width: 100%;
              position: relative;
              overflow: hidden;
              .value_parameter {
                position: absolute;
                height: 100%;
                top: 0px;
                left: 0px;
                z-index: 5;
              }
              &.down {
                float: right;
                background-color: rgba(247, 73, 64, 0.2);
                .value_parameter {
                  background-color: $color-denim;
                }
              }
              &.down-left {
                background-color: rgba(247, 73, 64, 0.2);
                .value_parameter {
                  background-color: $color-denim;
                  right: 0px;
                  left: auto;
                }
              }
              &.up {
                background-color: rgba(45, 172, 145, 0.2);
                .value_parameter {
                  background-color: $color-jungle-green;
                  right: 0px;
                  left: auto;
                }
              }
            }
          }
        }
      }
    }

  }
</style>