<template>
	<div class="content-box second">
		<div class="qr-code">
			<img class="img_qr" :src="urlQR" v-if="urlQR" />
        <!-- <button class="btn btn-show-recovery-code" @click="ShowRecoveryCode = !ShowRecoveryCode">
          <span v-if="!ShowRecoveryCode">{{ $t('account.modal.agreement_otp.view_secret_key') }}</span>
          <span type="text" class="recovery-code" name="recovery_code" v-if="ShowRecoveryCode">{{ key2fa }}</span>
        </button> -->
        <div class="btn-show-recovery-code">{{key2fa}}</div>
        <span class="scan_qrcode_des">{{$t('account.otp.qr_text')}}</span>
		</div>
	</div>
</template>
<script>
	import rf from '@/request/RequestFactory'

	export default {
    data() {
      return {
        urlQR : '',
        key2fa: '',
        ShowRecoveryCode: false,
      }
    },
    methods : {
      getQRCodeGoogleUrl() {
        rf.getRequest('UserRequest').getQRCodeGoogleUrl().then(res => {
          this.urlQR = res.data.url;
          this.key2fa = res.data.key;
          this.$emit('keyUpdated', this.key2fa);
        });
      },
    },
    created () {
      this.getQRCodeGoogleUrl();
    }
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/sass/variables';
  .scan_qrcode_des {
    font-family: $font-inter-regular;
    color: var(--text-close);
    font-size: 18px;
    line-height: 20px;
  }
  .btn-show-recovery-code {
    text-align: center;
    font-size: 22px;
    line-height: 27px;
    font-family: $font-inter-bold;
    color: var(--text-primary-color);
  }

@media screen and (max-width: 992px) { 
  .scan_qrcode_des {
    font-size: 14px;
    line-height: 16px;
  }
  .btn-show-recovery-code {
    font-size: 16px;
    line-height: 19px;
  }
}
</style>