<template>
  <div id="order_form" class="page_container clearfix">
<!--    <div class="spot-title">{{ $t('landing.title_Spot') }}</div>-->
<!--    <div class="order-type">-->
<!--      <div class="dropdown style-focus" tabindex="12">-->
<!--        <button class="btn form-control btn-order-form" type="button" data-toggle="dropdown">-->
<!--          <span class="left order-type-selected" v-if="tab == 'limit'">{{ $t('order.order_form.limit') }}</span>-->
<!--          <span class="left order-type-selected" v-if="tab == 'market'">{{ $t('orders.open_order.market') }}</span>-->
<!--          <span class="left order-type-selected" v-if="tab == 'stop_limit'">{{ $t('order.order_form.stop_limit') }}</span>-->
<!--          <span class="left order-type-selected" v-if="tab == 'stop_market'">{{ $t('order.order_form.stop_market') }}</span>-->
<!--          <span class="icon icon-arrow1"></span>-->
<!--        </button>-->
<!--        <ul class="dropdown-menu">-->
<!--          <div v-on:click="tab='limit'" class="tab-element" v-bind:class="{'active': tab === 'limit'}">-->
<!--            <span class="text-size">{{ $t('order.order_form.limit') }}</span>-->
<!--          </div>-->
<!--          <div v-on:click="tab='market'" class="tab-element" v-bind:class="{'active': tab === 'market'}">-->
<!--            <span class="text-size">{{ $t('orders.open_order.market') }}</span>-->
<!--          </div>-->
<!--          <div v-on:click="tab='stop_limit'" class="tab-element" v-bind:class="{'active': tab === 'stop_limit'}">-->
<!--            <span class="text-size">{{ $t('order.order_form.stop_limit') }}</span>-->
<!--            &lt;!&ndash; <span class="question-sign" @click="showTooltipLimit()" v-click-outside="hideTooltipLimit"></span> &ndash;&gt;-->
<!--            <div v-if="isShowTooltipLimit" class="tooltip-order">-->
<!--              <p class="text-tooltip">{{ $t('order.order_form.stop_limit_tooltip') }}</p>-->
<!--              <a :href="$siteSupportArticle($i18n.locale, 'how-to-use-stop-limit-function')" target="_blank"> {{ $t('order.order_form.view_more') }}</a>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div v-on:click="tab='stop_market'" class="tab-element" v-bind:class="{'active': tab === 'stop_market'}">-->
<!--            <span class="text-size">{{ $t('order.order_form.stop_market') }}</span>-->
<!--            &lt;!&ndash; <span class="question-sign" @click="showTooltipMarket()" v-click-outside="hideTooltipMarket"></span> &ndash;&gt;-->
<!--            <div v-if="isShowTooltipMarket" class="tooltip-order">-->
<!--              <p class="text-tooltip"> {{ $t('order.order_form.stop_market_tooltip') }}</p>-->
<!--              <a :href="$siteSupportArticle($i18n.locale,'how-to-use-stop-limit-function')" target="_blank">{{ $t('order.order_form.view_more') }}</a>-->
<!--            </div>-->
<!--          </div>-->
<!--        </ul>-->
<!--      </div>-->
<!--    </div>-->

    <div class="tab_content tab_responsive">
      <order-form-tabs class="w-50" :tab="tab" v-on:tabChange="handleTabChange"></order-form-tabs>
      <order-form-tabs class="w-50" :showFormOrder="0" :disabledTab="true" :tab="tab" v-on:tabChange="handleTabChange"></order-form-tabs>
    </div>
    <div class="tab_content tab_responsive2">
      <order-form-tabs :tab="tab" v-on:tabChange="handleTabChange" :showFormOrder="triggerOrderFormTab"></order-form-tabs>
    </div>

 </div>
</template>
<script>
  import OrderFormTabs from './OrderFormTabs.vue';

  export default {
    components: {
      OrderFormTabs,
    },
    props: {
      triggerOrderFormTab: { type: Number, default: 1 },
    },
    data() {
      return {
        tab: 'limit',
        isShowTooltipLimit: false,
        show_select: false,
        isShowTooltipMarket: false,
        isMobileScreen: window.matchMedia('(max-width: 1250px)').matches
      };
    },
    methods : {
      handleWindowResize() {
        this.checkMobileScreen();
      },
      checkMobileScreen (){
        const isMobile = window.matchMedia('(max-width: 1500px)').matches
        this.isMobileScreen = isMobile
      },
      handleTabChange(event) {
        this.tab = event;
      },
      getOrderTypeName() {
        const result = window._.find(this.getOrderTypes(), item => item.name === this.orderType);
        return result ? result.name : 'Limit';
      },
      getOrderTypes() {
        return [
          {name: 'Limit'},
          {name: 'Market'},
          {name: 'Stop Limit'},
          {name: 'Stop Market'}
        ];
      },
      showTooltipLimit() {
        this.hideTooltipMarket();
        this.isShowTooltipLimit = !this.isShowTooltipLimit;
      },
      hideTooltipLimit() {
        this.isShowTooltipLimit = false;
      },

      showTooltipMarket() {
        this.hideTooltipLimit();
        this.isShowTooltipMarket = !this.isShowTooltipMarket;
      },
      hideTooltipMarket() {
        this.isShowTooltipMarket = false;
      },
    },
    mounted() {
      window.addEventListener('resize', this.handleWindowResize)

    }
  };
</script>
<style lang="scss" scoped>
@import "@/assets/sass/common.scss";

.dark-theme {
  #order_form {
    .spot-title {
      color: $color-white;
    }
  }
}

 #order_form {
  display: block;
  width: 100%;

   .spot-title {
     font-weight: 600;
     font-size: $font-medium-bigger;
     font-family: $font-inter-bold;
     line-height: 19px;
     color: $color-grey-dark;
     margin-bottom: 1rem;
   }

  .order-type {
    position: relative;
    margin-bottom: 30px;
    .btn-order-form {
      height: 40px;
      border-radius: 3px;
      border: solid 1px $color-bright-gray-dark;
      color: $color-grey;
      font-size: $font-small;
      line-height: 20px;
      padding: 9px 13px 9px 20px;
      background-color: #181f2b;
      text-align: left;
      width: 100%;
      overflow: hidden;
      .icon {
        float: right;
        font-size: $font-mini;
        line-height: 22px;
        display: inline-block;
        position: absolute;
        top: 9px;
        right: 9px;
      }
    }
    .dropdown-menu {
      width: 100%;
      border-radius: 3px;
      box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.16);
      background-color: $color-white;
      padding: 10px 0px;
      .tab-element {
        cursor: pointer;
        display: block;
        width: 100%;
        line-height: 20px;
        padding: 5px 20px;
        font-size: $font-root;
        color: $color-grey-dark;
        &:hover {
          color: $color-jungle-green-light;
        }
      }
    }
  }
 }
  .style-focus {
    outline-width: 1px; 
  }

    .tab_responsive {
      display: flex;
    }
    .tab_responsive2 {
      display: none;
    }
    @media screen and (max-width: 1250px) {
      .tab_responsive {
        display: none!important;
      }
      .tab_responsive2 {
        display: flex!important;
      }
    }
  @media screen and (max-width: 992px) {
  .dark-theme {
    #order_form {
      border-bottom-color:$color-cinder;
    }
  }

  #order_form {
    padding: 0 20px 18px 20px;
    margin-top: 15px;
    border-bottom: 3px solid $color-alabaster;
  }
}
</style>
