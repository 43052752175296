<template>
    <div class="content-box third">
      <img src="/images/icon/icon-backup-key.svg" alt="icon" class="icon">
        <div class="show-key">
            <p type="text" class="recovery-code" name="recovery_code">{{ key2fa }}</p>
        </div>
        <div class="backup-key">
          {{$t('account.otp.backup_text3')}}
            <!-- <p class="text-bold">{{$t('account.otp.backup_text2')}}</p>
            <p class="text-grey">{{$t('account.otp.backup_text1')}}</p> -->
        </div>
    </div>
    <!-- end third -->
</template>
<script>
  export default {
    props: {
      key2fa: { type: String, default: '' },
    },
  }
</script>
<style lang="scss" scoped>
.recovery-code{
  color: var(--text-primary-color);
}
</style>