<template>
  <div>
    <div class="balance-container">
      <div class="table-container table-history">
        <data-table4 ref="datatable" :getData="getData" :msgEmptyData="$t('mam.empty.commission')" id="order-table" :limit="10">
          <template slot-scope="props">
            <th data-sort-field="interval">
              {{$t('mam.interval')}}
            </th>
            <th data-sort-field="entry_capital">
              {{$t('mam.entry_capital')}}
            </th>
            <th data-sort-field="exit_capital">
              {{$t('mam.exit_capital')}}
            </th>
            <th data-sort-field="entry_balance">
              {{$t('mam.entry_balance')}}
            </th>
            <th data-sort-field="exit_balance">
              {{$t('mam.exit_balance')}}
            </th>
            <th data-sort-field="followers">
              {{$t('mam.followers')}}
            </th>
            <th data-sort-field="commission">
              {{$t('mam.commission')}}
            </th>
          </template>
          <template slot="body" slot-scope="props">
            <tr>
              <td>{{ props.item.interval | convertToLocalTime }}</td>
              <td>{{ props.item.entry_capital | formatMarginNumber(8, '0') }} {{ $t('margin.symbol.btc') }}</td>
              <td>{{ props.item.exit_capital | formatMarginNumber(8, '0') }} {{ $t('margin.symbol.btc') }}</td>
              <td>{{ props.item.entry_balance | formatMarginNumber(8, '0') }} {{ $t('margin.symbol.btc') }}</td>
              <td>{{ props.item.exit_balance | formatMarginNumber(8, '0') }} {{ $t('margin.symbol.btc') }}</td>
              <td>{{ props.item.followers }}</td>
              <td>{{ props.item.commission | formatMarginNumber(8, '0') }} {{ $t('margin.symbol.btc') }}</td>
            </tr>
          </template>
        </data-table4>
        <!-- end pagination-container -->
      </div>
      <!-- end table container -->
      </div>
  </div>
</template>

<script>
  import rf from '@/request/RequestFactory'

  export default {
    data() {
      return {
     
      }
    },
    methods: {
      getData (params) {
        return rf.getRequest('MamRequest').getCommission(params)
      },
    }
  }
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
@import "@/assets/sass/common.scss"; 
  
  .clearfix-20 {
    display: block;
    clear: both;
    height: 20px;
  }

  #order-table {
    background-color: transparent;
    // padding-bottom: 22px;
    table {
      border: 0px;
      thead {
        background-color: $color-white;
        border: 0px;
        tr {
          height: 48px;
          th {
            font-family: $font-family-roboto-medium;
            font-size: $font-small;
            padding: 8px 0px 8px 20px;
            &:last-child {
              padding: 8px 20px 8px 0px;
            }
            &[data-sort-order='asc'] {
              color: $color-jungle-green;
            }
            &[data-sort-order='desc'] {
              color: $color-jungle-green;
            }
          }
        }
      }
      tbody {
        text-align: left;
        tr {
          &.canceled {
            opacity: 0.3
          }
          height: auto;
          font-size: $font-small;
          td {
            font-size: $font-root;
            font-weight: 500;
            color: $color-grey-dark;
            font-family: $font-family-roboto-medium;
            line-height: 21px;
            padding: 7px 0px 6px 20px;
            overflow: initial;
            vertical-align: middle;
            &.blue {
              color: $color-jungle-green;
            }
            &.red {
              color: $color-denim;
            }
            &:last-child {
              text-align: left;
              padding: 7px 20px 6px 0px;
            }
          }
        }
      }
    }
  }
</style>