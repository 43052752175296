import { render, staticRenderFns } from "./OrderConfirm.vue?vue&type=template&id=1b570a3e&scoped=true"
import script from "./OrderConfirm.vue?vue&type=script&lang=js"
export * from "./OrderConfirm.vue?vue&type=script&lang=js"
import style0 from "./OrderConfirm.vue?vue&type=style&index=0&id=1b570a3e&prod&lang=scss"
import style1 from "./OrderConfirm.vue?vue&type=style&index=1&id=1b570a3e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b570a3e",
  null
  
)

export default component.exports