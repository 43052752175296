<template>

  <div id="top_mid_table" class="top_mid_table">
    <div class="content_orderbook">

      <div class="head_orderbook">
        <div class="group_sort_orderbook">
          <div class="d-lg-block d-none  title-order-book">
            {{$t('order.order_book.orderBook')}}
          </div>
          <ul class="list_sort_od">
            <li>
              <div v-on:click="tab = tabConfig.bothSellBuy" class="icon_sort_od" v-bind:class="{'active' : tab === tabConfig.bothSellBuy}" >
                <span id="tooltip-target-1">
                  <img :src="require(`@/assets/images/icons/bothSellBuy${theme === 'light-theme'? '': '-dark'}.svg`)">
                  <div class="tool-tip-custom">
                    {{ $t('tooltip_order-book') }}
                  </div>
                </span>
              </div>
            </li>

            <li>
              <div v-on:click="tab = tabConfig.buy" class="icon_sort_od" v-bind:class="{'active' : tab === tabConfig.buy}" >

                <span id="tooltip-target-1">
                  <img :src="require(`@/assets/images/icons/buy${theme === 'light-theme'? '': '-dark'}.svg`)">
                  <div class="tool-tip-custom" :class="{'tooltip-buy': tab === tabConfig.buy}">
                    {{ $t('tooltip_buy-book') }}
                  </div>
                </span>
              </div>
            </li>

            <li>
              <div v-on:click="tab = tabConfig.sell" class="icon_sort_od" v-bind:class="{'active' : tab === tabConfig.sell}" >

                <span id="tooltip-target-1">
                  <img :src="require(`@/assets/images/icons/sell${theme === 'light-theme'? '': '-dark'}.svg`)">
                  <div class="tool-tip-custom">
                    {{ $t('tooltip_sell-book') }}
                  </div>
                </span>
              </div>
            </li>
          </ul>
          <div class="list_decimals_od">
            <select-box
              v-model="selectedPriceGroup"
              :options="priceGroupsByPair"
              :isReturnObject="true"
              :hasCustomLabel="true"
            >
              <template slot="label_selected" slot-scope="{ selected }">
                <span>{{ `${selected.precision} ` }}{{$t('order.order_book.decimals')}}</span>
              </template>
              <template slot="options" slot-scope="{ option }">
                <span class="full_name_coin_select">{{ `${option.precision}`}} {{$t('order.order_book.decimals')}}</span>
              </template>
            </select-box>
          </div>
        </div>
      </div>

        <div class="body_orderbook" v-bind:class="{'body_both' : tab === tabConfig.bothSellBuy}">
            <div class="d-lg-none d-flex block_center_od">
                <div class="border_block_center_od">
                    <div class="current-price">
             <span class="od_number_fix" :class="{'grown-price': changedPrice > 0, 'down-price': changedPrice < 0}">
            {{ latestPrice | formatCurrencyAmount(currency, '0') }}
            </span>
                        <fiat-currency
                                classes="od_number_balance"
                                sub-classes="text-small"
                                :value="fiatCurrency" v-if="currency !== 'usdt'">
                        </fiat-currency>

                    </div>

                    <i class="icon_zhang" size="12" :class="{'icon-arrow5': changedPrice > 0, 'icon-arrow6': changedPrice < 0}"></i>
                    <!--        <i class="icon_analytics icon-chart1"></i>-->
                </div>
            </div><!-- End Current price -->

            <!-- Begin SellOrderBook -->
            <div class="order-red-container" v-bind:class="{'active_red_max' : tab === tabConfig.sell,' active_red_min' : tab === tabConfig.buy}">
                <div class="top-title">
                    <ul class="title-group">
                        <!-- <li class="od-price">{{ $t('order.order_book.price') }}&nbsp;({{ $t(`currency.${currency}.shortname`) }})</li> -->
                        <li class="od-price">{{ $t('order.order_book.price') }}({{ currency.toUpperCase() }})</li>
                        <li class="od-amount">{{ $t('order.order_book.amount') }}({{ coin.toUpperCase() }})</li>
                        <li class="od-total">{{ $t('order.order_book.total') }}&nbsp;
                            <!--              ({{ coin.toUpperCase() }})-->
                        </li>
                    </ul>
                </div>
                <table id="orderbook_red">
                    <tbody class="orderbook_red">
                    <tr v-for="(item, index) in sellOrderBookDisplay" :key="index" @click.stop="onSellOrderBookClicked(item, index)" :class="{'row-clicked': item.isClickedRow}"  v-if="index < calculateSizeSellOrder(tab) ">
                        <div class="fix_sell_od" :style="{width: item.percent + '%'}"></div>
                        <td class="sell_color" :title="item.price | toNumber | formatCurrencyAmount(coin, '--') | paddingRight(selectedPriceGroup.precision, '0')">
                            {{ item.price | toNumber | formatCurrencyAmount(coin, '--') | paddingRight(selectedPriceGroup.precision, '0') }}
                        </td>
                        <td class="padr10 p-right"
                            :title="item.quantity | toNumber | formatCurrencyAmount(coin, '--') | paddingRight(coinSettingByPair.fractionPartQuantity, '0')">
                            {{ item.quantity | formatCurrencyAmount(coin, '--') | paddingRight(coinSettingByPair.fractionPartQuantity, '0') }}
                        </td>
                        <td class="total-color" :title="calculateTotalQuantityCoinForDisplay(sellOrderBookDisplay, item, index, 'sell') | toNumber | formatCurrencyAmount(coin, '--') | paddingRight(coinSettingByPair.fractionPartQuantity, '0')">
                            <!-- {{ totalCurrency(item.price, item.quantity) | toNumber | formatCurrencyAmount(coin, '--') | paddingRight(coinSettingByPair.fractionPartAmount, '0') }} -->
                            {{ calculateTotalQuantityCoinForDisplay(sellOrderBookDisplay, item, index, 'sell') | toNumber | formatCurrencyAmount(coin, '--') | paddingRight(coinSettingByPair.fractionPartQuantity, '0') }}
                        </td>
                    </tr>
                    </tbody>
                </table><!-- End SellOrderBook -->
            </div>

            <!-- Begin Current price -->
            <div class="d-lg-flex d-none block_center_od w-100">
                <div class="border_block_center_od w-100">
                    <div class="current-price">
             <span class="od_number_fix" :class="{'grown-price': changedPrice > 0, 'down-price': changedPrice < 0}">
            {{ latestPrice | formatCurrencyAmount(currency, '0') }}
            </span>
                        <fiat-currency
                                classes="od_number_balance"
                                sub-classes="text-small"
                                :value="fiatCurrency" v-if="currency !== 'usdt'">
                        </fiat-currency>

                    </div>

                    <i class="icon_zhang" size="12" :class="{'icon-arrow5': changedPrice > 0, 'icon-arrow6': changedPrice < 0}"></i>
                    <!--        <i class="icon_analytics icon-chart1"></i>-->
                </div>
            </div><!-- End Current price -->

            <!-- Begin BuyOrderBook -->
            <table id="orderbook_blue" v-bind:class="{' active_blue_min' : tab === tabConfig.sell,' active_blue_max' : tab === tabConfig.buy, 'active_both' : tab === tabConfig.bothSellBuy}">
                <div class="top-title">
                    <ul class="title-group">
                        <!-- <li class="od-price">{{ $t('order.order_book.price') }}&nbsp;({{ $t(`currency.${currency}.shortname`) }})</li> -->
                        <li class="od-price">{{ $t('order.order_book.price') }}({{ currency.toUpperCase() }})</li>
                        <li class="od-amount">{{ $t('order.order_book.amount') }}({{ coin.toUpperCase() }})</li>
                        <li class="od-total">{{ $t('order.order_book.total') }}&nbsp;
                            <!--              ({{ coin.toUpperCase() }})-->
                        </li>
                    </ul>
                </div>
                <tbody v-bind:class="{' orderbook_blue_min' : tab === 1,' orderbook_blue_max' : tab === 3}" class="orderbook_blue" >
                <tr v-for="(item, index) in buyOrderBookDisplay" @click.stop="onBuyOrderBookClicked(item, index)"  v-if="index < calculateSizeSellOrder(tab)"
                    :class="{'row-clicked': item.isClickedRow}" class="orderbook__row" :key="index">
                    <div class="fix_back_od" :style="{width: item.percent + '%'}"></div>
                    <td class="sell_color" :title="item.price | toNumber | formatCurrencyAmount(coin, '--') | paddingRight(selectedPriceGroup.precision, '0')">
                        {{ item.price | toNumber | formatCurrencyAmount(coin, '--') | paddingRight(selectedPriceGroup.precision, '0') }}
                    </td>
                    <td class="padr10 p-right"
                        :title="item.quantity | toNumber | formatCurrencyAmount(coin, '--') | paddingRight(coinSettingByPair.fractionPartQuantity, '0')">
                        {{ item.quantity | toNumber | formatCurrencyAmount(coin, '--') | paddingRight(coinSettingByPair.fractionPartQuantity, '0') }}
                    </td>
                    <td  class="total-color" :title="calculateTotalQuantityCoinForDisplay(buyOrderBookDisplay, item, index, 'buy') | toNumber | formatCurrencyAmount(coin, '--') | paddingRight(coinSettingByPair.fractionPartQuantity, '0')">
                        <!-- {{ totalCurrency(item.price, item.quantity) | toNumber | formatCurrencyAmount(coin, '--') | paddingRight(coinSettingByPair.fractionPartAmount, '0') }} -->
                        {{ calculateTotalQuantityCoinForDisplay(buyOrderBookDisplay, item, index, 'buy') | toNumber | formatCurrencyAmount(coin, '--') | paddingRight(coinSettingByPair.fractionPartQuantity, '0') }}
                    </td>
                </tr>
                </tbody>
            </table><!-- End BuyOrderBook -->
        </div>
</div>
</div>

</template>
<script>
  import rf from '@/request/RequestFactory'
  import Const from '@/common/Const';
  import BigNumber from 'bignumber.js';
  import SelectBox from '@/components/shared_components/common/SelectBox';
  import { mapState } from 'vuex';
  import Cookies from 'js-cookie'

  const SELL = 'sell';
  const BUY = 'buy';
  const BLANK = '--';
  const TAB_SELL = 1;
  const BOTH_SELL_BUY = 2;
  const TAB_BUY = 3;

  export default {
    components: {
      SelectBox,
    },
    data() {
      return {
        coin: this.$route.query.coin || Const.DEFAULT_COIN,
        currency: this.$route.query.currency || Const.DEFAULT_CURRENCY,
        tab : BOTH_SELL_BUY,
        tabConfig: {sell: TAB_SELL, bothSellBuy: BOTH_SELL_BUY, buy: TAB_BUY},

        buyOrderBook: [],
        sellOrderBook: [],

        maxSizeDisp: 12,
        minSizeDisp: 7,

        prices: {},
        latestPrice: undefined,
        previousPrice: undefined,
        changedPrice: undefined, // Price changed compare with previous price.

        coinSettings: [],
        coinSettingByPair: {},

        priceGroups: [],
        priceGroupsByPair: [],
        selectedPriceGroup: {},
        disableByBetaTester: false,
        isAuthencation : window.isAuthenticated,
        ticketSize: undefined,
        theme: Cookies.get("user-theme", { domain: process.env.VUE_APP_PUBLIC_URL }) || 'dark-theme',
      }
    },
    watch: {
      userThemeMode(theme) {
        this.theme = theme;
      },
      selectedPriceGroup(newPriceGroup) {
        if (newPriceGroup) {
          this.tickerSize = newPriceGroup.value;
        }
        this.getOrderBook();
      },

      '$route' (to, from) {
        this.currency = this.$route.query.currency || Const.DEFAULT_CURRENCY;
        this.coin = this.$route.query.coin || Const.DEFAULT_COIN;

        this.onChangedPairCurrency();
      },

      maxSizeDisp(newValue) {
        this.fillRowBlankIfNeed(this.sellOrderBook, SELL);
        this.fillRowBlankIfNeed(this.buyOrderBook, BUY);
      },

      tab(newValue) {
        this.$nextTick(() => {
          this.reCalculateMinMaxRowDisp();
          this.scrollToEnd();
        });
      },
    },
    computed: {
      ...mapState({
        masterdata: state => state.masterdata,
        pairCoins: state => state.pairCoins,
        userThemeMode: state => state.userThemeMode
      }),
      tradingPair() {
        return this.currency + '_' + this.coin;
      },

      tradingPairPrice: function() {
        return this.prices[this.tradingPair] || {};
      },

      fiatCurrency() {
        const tradingPrice = this.tradingPairPrice || { price: 0 };
        const usdPrice = this.prices[`usdt_${this.currency}`] || { price: 1 };
        return new BigNumber(`${tradingPrice.price || 0}`).mul(usdPrice.price || 0).toString();
      },

      sellOrderBookDisplay() {
        // Show all sellOrderBook
        if (this.tab === TAB_SELL) {
          return this.sellOrderBook;
        }
        let currentSellOrderBook = [];
        const length = this.sellOrderBook.length;
        if (!length) {
          this.$broadcast('SpotSellOrderBookDisplay', []);
          return [];
        }

        currentSellOrderBook = window._.slice(this.sellOrderBook, length - this.minSizeDisp, length);
        this.$broadcast('SpotSellOrderBookDisplay', currentSellOrderBook);

        return currentSellOrderBook;
      },

      buyOrderBookDisplay() {
        const length = this.buyOrderBook.length;
        if (this.tab === TAB_BUY || length < this.maxSizeDisp) {
          return this.buyOrderBook;
        }
        return window._.slice(this.buyOrderBook, 0, this.maxSizeDisp);
      }
    },
    methods: {
      calculateSizeSellOrder(tab) {
        if (tab === this.tabConfig.bothSellBuy) {
          return this.maxSizeDisp || 20;
        } else {
          return this.maxSizeDisp || 40;
        }
      },
      getEventHandlers() {
        return {
          SpotSelectedPairMarket: this.onSpotSelectedPairMarket,
        }
      },

      onSpotSelectedPairMarket(pair) {
        this.coin = pair.coin;
        this.currency = pair.currency;
        // this.getOrderBook();
        this.getPriceGroupsByPairCurrency();
      },

      onSellOrderBookClicked(item, index) {
        if (!item || !item.price) {
          return;
        }
        this.clickedRow(item);

        const subListSellOrderBook = window._.slice(this.sellOrderBookDisplay, index);
        const [price, maxAmount] = this.getPriceAndAmountSubOrderBook(item.price, subListSellOrderBook);

        this.$broadcast('SellOrderBookClicked', price, maxAmount);
      },

      onBuyOrderBookClicked(item, index) {
        if (!item || !item.price) {
          return;
        }
        this.clickedRow(item);

        const subListBuyOrderBook = window._.slice(this.buyOrderBook, 0, index + 1);
        const [price, maxAmount] = this.getPriceAndAmountSubOrderBook(item.price, subListBuyOrderBook);

        this.$broadcast('BuyOrderBookClicked', price, maxAmount);
      },

      calculateTotalQuantityCoinForDisplay(inputList, item, index, type) {
        let list = window._.cloneDeep(inputList);
        if (item.price == undefined) {
          return undefined;
        }

        let subListOrderBook = window._.slice(list, index);
        if (type == 'buy') {
          subListOrderBook = window._.slice(list, 0, index + 1);
        }
        const [price, maxAmount] = this.getPriceAndAmountSubOrderBook(item.price, subListOrderBook);

        return maxAmount;
      },

      calculateTotalQuantityCoin(list, item, index, type) {
        if (item.price == undefined || item.isClickedRow == undefined) {
          return undefined;
        }

        let subListOrderBook = window._.slice(list, index);
        if (type == 'buy') {
          subListOrderBook = window._.slice(list, 0, index + 1);
        }
        const [price, maxAmount] = this.getPriceAndAmountSubOrderBook(item.price, subListOrderBook);

        return maxAmount;
      },

      getPriceAndAmountSubOrderBook(price, subOrderBook) {
        const maxAmount = window._.sumBy(subOrderBook, row => row.quantity);
        return [price, maxAmount];
      },

      clickedRow(row) {
        row.isClickedRow = true;
        setTimeout(() => {
          row.isClickedRow = false;
        }, 100);
      },

      getSocketEventHandlers() {
        return {
          PricesUpdated: this.onPricesUpdated,
          OrderBookUpdated: this.onReceiveOrderBook,
        }
      },

      onPricesUpdated(newPrices) {
        this.prices = Object.assign({}, this.prices, newPrices);
        if (window._.isEmpty(this.prices)) {
          return;
        }
        this.reCalcLatestPrice();
      },

      onReceiveOrderBook(data) {
        if (this.currency != data.currency || this.coin != data.coin ) {
          return;
        }
        if (data.isFullOrderBook) {
          this.rebuildOrderBook(data.orderBook);
        } else {
          this.buyOrderBook = window._.concat(this.buyOrderBook, data.orderBook.buy);
          this.sellOrderBook = window._.concat(this.sellOrderBook, data.orderBook.sell);

          this.onOrderBookUpdated();
        }
      },

      onChangedPrecisionClicked(priceGroup) {
        this.selectedPriceGroup = priceGroup;
      },

      onChangedPairCurrency() {
        // reset price when changed pair currency.
        this.latestPrice = 0;
        this.changedPrice = 0;

        this.getCoinSettingsByPairCurrency();
        this.getPriceGroupsByPairCurrency();
        this.reCalcLatestPrice();
        this.initBetaTesterData();
      },

      reCalcLatestPrice() {
        const coinPrice = this.prices[this.tradingPair];
        if (!coinPrice) {
          return;
        };

        this.currentPrice = coinPrice.price;
        this.previousPrice = coinPrice.previous_price || this.currentPrice ;

        this.changedPrice = new BigNumber(this.currentPrice).comparedTo(this.previousPrice);
        // set latest price.
        this.latestPrice = coinPrice.price;
      },

      getExponent(num) {
        return Math.floor(Math.log10(num)) > 0 ? Math.floor(Math.log10(num)): Math.floor(Math.log10(num)) * -1;
      },

      countDecimalDigits(number) {
        const match = number.to.toString().match(/\.(\d+)/);
        return match ? match[1].length : 0;
      },

      roundToDecimalBuy(number, decimalPrecision) {
        const factor = 10 ** decimalPrecision;
        return Math.round(number * factor) / factor;
      },

      roundToDecimalSell(value, precision) {
        return new BigNumber(value).toFormat(precision, BigNumber.ROUND_UP).replace(/,/g, '');
      },

      getRoundedData(data, decimal,type) {
        // Create new array with rounded prices
        const roundedData = data.map(obj => {
          return {
            quantity: obj.quantity,
            price: type === 'sell' ? parseFloat(this.roundToDecimalSell(obj.price, decimal)) :  parseFloat(this.roundToDecimalBuy(obj.price, decimal))
          };
        });

        // Filter unique prices
        const uniquePrices = [...new Set(roundedData.map(obj => obj.price))];

        // Create final array with unique prices and corresponding quantities
        const finalData = uniquePrices.map(price => {
          const quantities = roundedData.filter(obj => obj.price === price).map(obj => obj.quantity);
          return {
            quantity: quantities.reduce((total, num) => total + num),
            price: price
          };
        });

        return finalData;
      },

      convertData(data, exponent) {
        let newData = [];
        let sumQuantity = 0;
        data.forEach(item => {
          const exp = this.getExponent(item.price);
          if (exp <= exponent) {
            newData.push(item);
          } else {
            sumQuantity += item.quantity;
          }
        });

        if (newData.length > 0) {
          newData[newData.length - 1].quantity += sumQuantity;
        }
        return newData;
      },

      rebuildOrderBook(data) {
        if (this.disableByBetaTester) {
          data.buy = [];
          data.sell = [];
          this.buyOrderBook = [];
          this.sellOrderBook = [];
        }
        this.convertOrderBookDataType(data);
        const orderBook = this.transformOrderBook(data);

        this.buyOrderBook = window._.orderBy(data.buy, (row) => parseFloat(row.price), ['desc']);
        this.sellOrderBook = window._.orderBy(data.sell, (row) => parseFloat(row.price), ['desc']);

        this.buyOrderBook = this.getRoundedData(this.buyOrderBook, this.getExponent(this.selectedPriceGroup.value),'buy')
        this.sellOrderBook = this.getRoundedData(this.sellOrderBook.reverse(), this.getExponent(this.selectedPriceGroup.value), 'sell')
        this.sellOrderBook.reverse();

        this.fillRowBlankIfNeed(this.sellOrderBook, SELL);
        this.fillRowBlankIfNeed(this.buyOrderBook, BUY);

        this.onOrderBookUpdated();
      },

      onOrderBookUpdated() {
        if (window._.isEmpty(this.buyOrderBook) || window._.isEmpty(this.sellOrderBook) || !this.tickerSize) {
          return;
        }

        // price of trade Buy is 0 (price after a round by priceGroup) => display random any number.
        this.reCalcBuyOrderBookIfNeed(this.buyOrderBook);

        this.calculateQuantityPercent();
      },

      reCalcBuyOrderBookIfNeed(data) {
        window._.map(data, item => {
          if (parseFloat(item.price) === 0) {
            item.price = new BigNumber(1).div(`${Math.pow(10, this.selectedPriceGroup.precision)}`).toString();
          }
          return item;
        });
      },

      calculateQuantityPercent() {
        const maxBuyRow = this.getMaxQuantity(this.buyOrderBook);
        const maxSellRow = this.getMaxQuantity(this.sellOrderBook);

        const maxBuyQuantity = maxBuyRow ? maxBuyRow.quantity : 0;
        const maxSellQuantity = maxSellRow ? maxSellRow.quantity : 0;
        const maxQuantity = Math.max(maxBuyQuantity, maxSellQuantity);

        this.calculatePercent(this.buyOrderBook, maxQuantity);
        this.calculatePercent(this.sellOrderBook, maxQuantity);
      },

      getMaxQuantity(data) {
        return window._.chain(data)
        .filter(item => item.quantity !== BLANK)
        .map(item => {
          item.quantity = parseFloat(item.quantity);
          return item;
        })
        .maxBy('quantity')
        .value();
      },

      calculatePercent(data, maxQuantity) {
        for (let i in data) {
          let row = data[i];
          if (maxQuantity) {
            row.percent = row.quantity * 100 / maxQuantity;
          } else {
            row.percent = 0;
          }
        }
      },

      transformOrderBook(data) {
        let orderBook = {};
        orderBook.buy = window._.values(data.buy);
        orderBook.sell = window._.values(data.sell);
        return orderBook;
      },

      totalCurrency(price, amount) {
        if (price === BLANK || amount === BLANK) {
          return BLANK;
        }
        return new BigNumber(`${price || 0}`).mul(`${amount || 0}`).toFixed(10);
      },

      convertOrderBookDataType(orderBook) {
        this.convertStringToNumber(orderBook.buy);
        this.convertStringToNumber(orderBook.sell);
      },

      convertStringToNumber(data) {
        window._.each(data, item => {
          item.price = parseFloat(item.price);
          item.quantity = parseFloat(item.quantity);
          item.count = parseFloat(item.count);
          item.percent = 0;

          // Flag which show border when row is clicked.
          item.isClickedRow = false;
        });
      },

      fillRowBlankIfNeed(subOrderBook, tradeType) {
        // Remove row blank
        this.removeRowBlank(subOrderBook);
        if (subOrderBook.length > this.maxSizeDisp) {
          return;
        }
        const data = [];
        while(data.length < (this.maxSizeDisp - subOrderBook.length)) {
          const item = this.createRowSubOrderBook();
          data.push(item);
        }
        if (tradeType === SELL) {
          this.sellOrderBook = window._.concat(data, subOrderBook);
        } else {
          this.buyOrderBook = window._.concat(subOrderBook, data);
        }
      },

      removeRowBlank(data) {
        window._.remove(data, item => !item.price || !item.quantity);
      },

      createRowSubOrderBook() {
        return {
          price: undefined,
          quantity: 0,
          percent: 0
        };
      },

      getOrderBook() {
        rf.getRequest('OrderRequest').getOrderBook(this.getDefaultParams()).then(res => {
          this.rebuildOrderBook(res.data);
        });
      },

      getPriceGroups() {
        this.coinSettings = window._.map(this.masterdata?.coin_settings, item => {
          item.fractionPartQuantity = this.getDecimalPrecision(item.coin, item.quantity_precision);
          item.fractionPartAmount = 8;
          return item;
        });
        this.getCoinSettingsByPairCurrency();

        this.priceGroups = window._.map(this.masterdata?.price_groups, item => {
          item.precision = this.getDecimalPrecision(item.currency, item.value);
          return item;
        });
        this.getPriceGroupsByPairCurrency();
      },

      getPrices() {
        rf.getRequest('PriceRequest').getPrices().then(res => {
          this.onPricesUpdated(res.data);
        });
      },

      getDefaultParams() {
        return {
          currency: this.currency,
          coin: this.coin,
          tickerSize: this.tickerSize
        };
      },

      getPriceGroupsByPairCurrency() {
        if (window._.isEmpty(this.priceGroups)) {
          this.priceGroupsByPair = [];
          return;
        }
        const priceGroupFilter = window._.filter(this.priceGroups, item => {
          return item.currency === this.currency && item.coin === this.coin;
        });

        // Sort by precision asc
        this.priceGroupsByPair = window._.sortBy(priceGroupFilter, 'precision');

        let isSetPrecision = false;
        this.priceGroupsByPair.forEach(item => {
          if (item.value == this.coinSettingByPair.price_precision) {
            this.selectedPriceGroup = item;
            isSetPrecision = true;
          }
        });
        if (!isSetPrecision) {
          this.selectedPriceGroup = window._.last(this.priceGroupsByPair);
        }
      },

      getCoinSettingsByPairCurrency() {
        if (window._.isEmpty(this.coinSettings)) {
          this.coinSettingByPair = {};
          return;
        }
        const result = window._.filter(this.coinSettings, item => {
          return item.currency === this.currency && item.coin === this.coin;
        });
        if (!window._.isEmpty(result)) {
          this.coinSettingByPair = result[0];
        }
      },

      getDecimalPrecision(currency, value) {
        return Math.round(Math.log(1 / value) / Math.log(10));
      },

      handleWindowResize() {
        this.reCalculateMinMaxRowDisp();
      },

      reCalculateMinMaxRowDisp() {
        const isMobile = window.matchMedia('(max-width: 1150px)').matches
        if (!isMobile) {
          setTimeout(() => {
            let heightRow = 25
            const $elSell = this.$el.querySelector('#orderbook_red')
            const $elBuy = this.$el.querySelector('#orderbook_blue')

            switch (this.tab) {
              case TAB_SELL:
                // heightRow = $elSell.querySelector("tbody > tr").firstChild.offsetHeight || heightRow;
                this.maxSizeDisp = Math.floor($elSell.offsetHeight / heightRow)
                break
              case BOTH_SELL_BUY:
                // if ($elSell.querySelector("tbody > tr") && $elBuy.querySelector("tbody > tr")) {
                //   const heightRowSellChild = !$elSell.querySelector("tbody > tr").firstChild.offsetHeight;
                //   const heightRowBuyChild = $elBuy.querySelector("tbody > tr").firstChild.offsetHeight;
                //   heightRow = heightRowSellChild > heightRowBuyChild ? heightRowSellChild: heightRowBuyChild;
                // }
                if ($elSell.offsetHeight > $elBuy.offsetHeight) {
                  this.maxSizeDisp = Math.floor($elSell.offsetHeight / heightRow)
                  this.minSizeDisp = Math.floor($elBuy.offsetHeight / heightRow)
                } else {
                  this.maxSizeDisp = Math.floor($elBuy.offsetHeight / heightRow)
                  this.minSizeDisp = Math.floor($elSell.offsetHeight / heightRow);
                }
                break
              case TAB_BUY:
                // heightRow = $elBuy.querySelector("tbody > tr").firstChild.offsetHeight || heightRow;
                this.maxSizeDisp = Math.floor($elBuy.offsetHeight / heightRow)
                break
              default:
                console.error('Tab selection incorrect')
                break
            }
          }, 201) // with trasition: 0.2s
        }
        else  {
            setTimeout(() => {
                this.maxSizeDisp = 12
                this.minSizeDisp = 12
            }, 201) // with trasition: 0.2s
        }
      },

      scrollToEnd() {
        let container = this.$el.querySelector(".orderbook_red");
        container.scrollTop = container.scrollHeight;
      },

      initData() {
        this.getPriceGroups();
        this.getPrices();
      },
      
      initBetaTesterData() {
        this.disableByBetaTester = false;
        let coin = this.$route.query.coin || Const.DEFAULT_COIN;
        let currency = this.$route.query.currency || Const.DEFAULT_CURRENCY;

        const setting = this.pairCoins;
        if (!setting.is_enable && setting.is_show_beta_tester) {
          this.disableByBetaTester = true;

          if (window.isAuthenticated) {
            rf.getRequest("UserRequest")
              .getUserPairTradingSetting({ coin, currency })
              .then(res => {
                if (res && res.data && res.data.enable_trading == Const.ENABLE_TRADING.ENABLE) {
                  this.disableByBetaTester = false;
                }
                this.getOrderBook();
              });
          } else {
            this.getOrderBook();
          }
        } else {
          this.getOrderBook();
        }
      }
    },
    mounted() {
      window.addEventListener('resize', this.handleWindowResize)
      this.reCalculateMinMaxRowDisp();
      this.initData();
      this.initBetaTesterData();
    }
  }
</script>

<style lang="scss">
  @import "@/assets/sass/variables";
  #top_mid_table {
    .list_decimals_od {
      .button_drop_search {
        width: 100px;
        background: transparent;
        padding: 0 !important;
        display: flex;
        align-items: center;
        span {
          font-size: 14px;
        }
        i.icon-arrow1 {
          color: var(--text-close) !important;
          font-size: 10px;
          margin-top: 2px;
        }
      }
      .full_name_coin_select{
        font-size: $font-small;
      }
    }
  }
</style>

<style lang="scss" scoped>

@import "@/assets/sass/variables";

  $heightOptionsHeader           : 104px;
  $heightHeaderTitle             : 27px;
  $heightBlockCenter             : 65px;
  $transition                    : all 0.2s;

.dark-theme {

  #top_mid_table .content_orderbook  {
    .head_orderbook .group_sort_orderbook .title-order-book{
      color: $color-white;
    }
    .body_orderbook table tbody tr td {
      color: $color-white;
    }
    .orderbook_red tr td:nth-child(2) {
      color: #E02D3C;
    }
    .body_orderbook .orderbook_blue tr td:nth-child(2) {
      color: #00B277;
    }
  }

  .page-container-mid {
    .content_orderbook {
      background: $color-mirage;

      .title-order-book, .p-right, .total-color, .od_number_fix  {
        color: $color-white;
      }
      .od-total, .od-amount, .od-price, .od_number_balance {
        color: $color-storm-grey!important;
      }

      .list_sort_od {
        background: #002E26!important;
      }
    }
  }

  .list_sort_od {
    background: #002E26 !important;
  }
}

.icon-arrow5 {
  color: $text-color-jade;
  font-weight: bold;
  margin-top: 6px;
  margin-left: 8px;
  @media screen and (max-width: 992px) {
   margin-top: 1px;
  }
}
.icon-arrow6 {
  color: $text-color-red;
  font-weight: bold;
  margin-top: 6px;
  margin-left: 8px;
}

#top_mid_table{
  display: block;
  width: 100%;
  ul{
    list-style-type: none;
    margin: 0px;
  }
  .content_orderbook{

      .head_orderbook{
      //border-bottom: 1px solid;
      border-color: $color-bright-gray-dark;
      width: 100%;
      padding: 5px 10px 3px 10px;
      display: -webkit-box;
      display: -ms-flexbox;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
      flex-direction: row-reverse;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      .group_sort_orderbook{
        position: relative;
        display: inline-block;
        padding: 0px;
        width: 100%;
        .list_sort_od{
          display: flex;
          flex-wrap: wrap;
          float: left;
          list-style-type: none;
          padding: 6px;
          border-radius: 6px;
          background: $color-aqua-day;
          position: relative;
          li{
            float: left;
            margin-left: 10px;

            &:first-child {
              margin-left: 0;
            }
            .icon_sort_od{
              display: flex;
              width: 20px;
              height: 20px;
              cursor: pointer;
              border-color: transparent;
              opacity: 0.3;

              .icon_up_sort{
                color: $color-red;
              }
              #tooltip-target-1 {
                img {
                  line-height: 0px;
                  vertical-align: baseline;
                }
                .tool-tip-custom {
                  display: none;
                }
              }
            }
         
            .icon_sort_od.active{
              //border-color: $color-eden-dark;
              opacity: 1;
            }
            .active {
              .tool-tip-custom {
                background: var(--bg-tooltip-orderbook);
                border-radius: 8px;
                margin-top: 9px;
                padding: 10px;
                width: 96px;
                height: 38px;
                color: $color-white !important;
                font-size: 14px;
              }
              
              #tooltip-target-1 {
                .tool-tip-custom {
                  display: none;
                }
                &:hover {
                  .tool-tip-custom {
                    display: block;
                    position: absolute;
                    left: 0px;
                    top: 26px;
                    text-align: center;
                    z-index: 100;
                  }
                  
                }
              }
            }
          }
        }

        .title-order-book {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          color: $color-grey-dark;
          font-family: $font-inter-regular;
          margin-bottom: 12px;
          margin-top: 11px;
        }

        .select_decimals_od{
          background: #efefef;
          font-family: Arial;
          font-size: $font-small;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $color-grey-dusty;
          height: 22px;
          padding: 0px 5px;
          border-color: $color-grey-border;

        }
        .list_decimals_od{
          float: right;
          position: relative;
          padding: 3px 0;
        }
      }
      .border-first-line{
        padding: 0;
      }
    }

    .body_orderbook{
      $heightFullOrderBook: 384px;
      width: 100%;
      background: transparent;
      .orderbook_red{
        visibility: visible;
        height: 210px;
        transition: 0.1s;
        overflow: hidden;
        tr{
          td{
            &:nth-child(2){
              color: $text-color-red;
            }
            &:nth-child(3){
              padding-left: 2px;
              padding-right: 2px;
            }
          }
        }
      }
      .orderbook_red.orderbook_red_min{
        visibility: hidden;
        transition: 0.1s;
        height: 0px;
        padding-top: 0px;
        margin-top: -1px;
      }
      .orderbook_red.orderbook_red_max{
        visibility: visible;
        transition: 0.1s;
        height: $heightFullOrderBook;
        &::-webkit-scrollbar {
          width: 0px;
        }
      }
      .orderbook_blue{
        visibility: visible;
        transition: 0.1s;
        height: 210px;
        overflow: hidden;
        tr{
          position: relative;
          td{
            position: relative;
            z-index: 10;
            &:nth-child(2){
              color: $text-color-jade;
            }
          }
        }
      }
      .orderbook_blue.orderbook_blue_min{
        visibility: hidden;
        transition: 0.1s;
        height: 0px;
      }
      .orderbook_blue.orderbook_blue_max{
        visibility: visible;
        transition: 0.1s;
        height: $heightFullOrderBook;
        overflow-y: auto !important;
        &::-webkit-scrollbar {
        width: 0px;
       }
     }
     table{
      width: 100%;
      display: block;
      line-height: 20px;
      transition: all 0.3s;
      tbody{
        display: block;
        width: 100%;
        padding-top: 6px;
        tr{
          position: relative;
          padding: 0px 10px;
          overflow: hidden;
          display: block;
          width: 100%;
          cursor: pointer;
          td{
            position: relative;
            z-index: 1;
            width: 31%;
            height: 24px;
            text-overflow: ellipsis;
            overflow: hidden;
            display: block;
            float: left;
            text-align: center;
            font-size: $font-root;
            color: $color-grey-dark;
            font-weight: 400;
            padding: 3px 0px;
            font-family: $font-inter-regular;
            letter-spacing: 0.2px;
          }
          td:nth-child(2) {
            text-align: left;
          }
          td:nth-child(4) {
            width: 38% !important;
            text-align: right;
          }
          .fix_back_od{
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            background-color: rgba($text-color-jade, 0.1);
            transition: all 0.3s ease-in-out;
          }
          .fix_sell_od {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            background-color: rgba($text-color-red, 0.1);
            transition: all 0.3s ease-in-out;
          }
        }
        .row-clicked {
          border: 0px solid #459cd4;
        }
      }
    }
    .block_center_od{
      background: transparent;
      display: block;
      overflow: hidden;
      position: relative;
      z-index: 3;

      .border_block_center_od{
        padding: 4px 10px 4px 10px;
        // height: 30px;
        position: relative;
        display: flex;
        overflow: hidden;
        align-items: flex-start;
        // justify-content: space-between;
        margin-bottom: 16px;

        div:first-child {
          display: flex;
          align-items: baseline;
        }
        div:last-child {
          font-size: 24px;
          padding-right: 1rem;
        }

        .current-price {
          flex-direction: column;
          .grown-price {
            color: $text-color-jade;
          }
          .down-price {
            color: $text-color-red;
          }
        }

        .od_number_fix{
          //color: $color-grey-dark;
          font-family: $font-inter-regular;
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 29px;
          letter-spacing: 0.5px;
          float: left;

          .icon_zhang{
            height: 19px;
            line-height: 19px;
            margin-right: 2px;
            display: inline-block;
            float: left;
            width: 15px;
            font-size: $font-small;
            margin-top: 2px;
                //color: $color-green;
                overflow: hidden;
              }
              .icon_zhang:before{
                display: block;
              }
            }
            .od_number_balance{
              display: inline-block;
              float: left;
              margin-left: 6px;
              font-size: $font-medium-bigger;
              line-height: 20px;
              color: $color-gray-dark;
            }
            .icon_analytics{
              display: inline-block;
              width: 20px;
              height: 19px;
              position: absolute;
              top: 50%;
              right: 5px;
              margin-top: -8px;
              color: $color-green;
              font-size: $font-medium;
              display: inline-block;
            }
          }
        }
        .border-first-line {
          div {
            padding: 0 !important;
            border: none !important;
            border-top: 1px solid $color-grey-border !important;
          }
        }
      }
    }
  }

  #top_mid_table{
    height: 100%;
    .content_orderbook{
      height: 100%;
      padding: 0 10px;
      border-right: 4px solid var(--color-border-right);
      background-color: var(--background-current_coin);


      .body_orderbook{
        height: calc(100% - #{$heightOptionsHeader});
        &::-webkit-scrollbar {
          width: 0px;
        }
        .top-title{
          background: transparent;
          position: relative;
          z-index: 10;
          margin-top: 12px;
          .title-group{
            padding: 0 10px;
            display: inline-block;
            width: 100%;
            // border-bottom: 1px solid $color-alto;
            li{
              width: 33.33%;
              display: block;
              float: left;
              text-align: center;
              font-size: $font-root;
              color: $dark-2;
              font-weight: 500;
              padding: 4px 0px 3px 0px;
              line-height: 20px;
              height: 27px;
              &:nth-child(1) {
                text-align: left;
              }
              &:nth-child(3) {
                text-align: right;
              }
            }
          }
        }
        .order-red-container{
          transition: $transition;
          height: calc( 50% - 18px );
          position: relative;
          overflow: hidden;
          &.active_red_min{
            height: 0px;
          }
          &.active_red_max{
            transition: $transition;
            height: calc(100% - #{$heightHeaderTitle} - #{$heightBlockCenter});
            overflow: auto;
            &::-webkit-scrollbar {
              width: 0px;
            }
          }
        }
        #orderbook_red{
          width: 100%;
          min-height: calc(100% - 44px);
          transition: $transition;
          tbody{
            tr{
              // border-top: 1px solid transparent;
              &:last-child{
              border-bottom: 1px solid transparent;
              }
            }
          }
          .orderbook_red{
            transition: $transition;
            height: 100%;
            overflow-y: auto;
            padding-top: 0px;
          }
        }
        #orderbook_blue{
          height: calc( 50% - 20px );
          transition: $transition;
          overflow: hidden;
          tbody{
            tr{
              // border-top: 1px solid transparent;
              &:last-child{
              border-bottom: 1px solid transparent;
              }
            }
          }
          .orderbook_blue{
            transition: $transition;
            height: 100%;
            padding-top: 0;
          }
          &.active_blue_max{
            height: calc(100% - #{$heightHeaderTitle} - #{$heightBlockCenter});
            transition: $transition;
            //overflow: auto;
          }
          &.active_blue_min{
            height: 0px;
            transition: $transition;
          }
          &.active_both {
            .top-title {
              display: none;
            }
          }
        }
        .p-right {
          text-align: right;
          padding-right: 4px !important;
        }
      }
    }
  }

@media screen and (max-width: 992px) {
  #top_mid_table .content_orderbook {
    border: none;
    padding : 0;
    .head_orderbook {
      padding: 20px 16px 12px 16px;
      .group_sort_orderbook .title-order-book {
        margin-top: 20px;
        margin-bottom: 12px;
        font-size: $font-root;
      }
    }
    .body_orderbook {
      padding: 0 6px 20px 6px;
      .block_center_od .border_block_center_od {
        .od_number_fix {
          font-size: 20px;
        }
        .od_number_balance {
          font-size: 14px;
        }

      }

      .top-title .title-group li {
        font-size: 12px;
      }
      #orderbook_blue.active_both .top-title {
        display: block;
      }
    }
    .body_both {
      display: grid;
      grid-template-columns: 50% 50%;
      grid-template-areas:
        'header header'
        'grid-left grid-right';
      .block_center_od {
        grid-area: header;
      }
      .order-red-container {
        grid-area: grid-left;
      }
      #orderbook_blue {
        grid-area: grid-right;
      }
      .order-red-container, #orderbook_blue {
        height: 100%;
        .od-total {
          display: none!important;
        }
        .od-price ,.od-amount {
          width: 50%!important;
        }
        table  tbody tr {
          td {
            width: 50%!important;
            &:first-child {
              text-align: left!important;
            }
            &:nth-child(2){
              text-align: left!important;
            }

          }
        }
        tbody tr {
          td {
            width: 50%!important;
            &:first-child {
              text-align: left!important;
            }
            &:nth-child(2){
              text-align: left!important;
            }
          }
          .total-color {
            display: none!important;
          }
        }
      }
    }
  }
  #top_mid_table .list_decimals_od .button_drop_search span {
    font-size: $font-root;
  }

}

</style>
