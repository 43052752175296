<template>
  <div id="history-transfer">
    <div class="transfer_container" :class="{ isPagination: lastPage > 1 }">
      <div class="title-transfer">{{ $t("transfer_history_title") }}</div>
      <div class="filter-table">
        <div class="group-control">
          <div>
            <div class="label-filter">{{ $t("address.coin") }}</div>
            <div
              class="drop-down-pair drop-down position-relative d-flex justify-content-between align-items-center"
              v-click-outside="clickOutSidePair"
            >
              <span
                class="w-100 h-100 current-pair"
                @click="openDropPair = !openDropPair"
              >
                <!-- <span>{{ $t("address.coin") }}</span> -->
                <span v-if="currentPair.name" class="placeholder-input">{{
                  currentPair.name == "All"
                    ? $t("funds_all_coins")
                    : currentPair.name
                }}</span>
              </span>
              <span
                class="w-100 h-100 current-pair"
                @click="openDropPair = !openDropPair"
              ></span>
              <img
                src="/images/icon/icon-down.svg"
                alt="icon"
                v-if="!openDropPair"
                @click="openDropPair = true"
              />
              <img
                src="/images/icon/icon-up.svg"
                alt="icon"
                v-if="openDropPair"
                @click="clickOutSidePair"
              />
              <div
                class="position-absolute drop-list drop-list-pair"
                v-show="openDropPair"
              >
                <div class="position-relative text-search-pair">
                  <input
                    v-model="pairName"
                    class="h-100"
                    type="text"
                    @input="searchPair"
                    @focus="focusInput = true"
                    :placeholder="$t('order.trade_history.search')"
                  />
                  <img
                    class="clear-text position-absolute"
                    width="19"
                    height="19"
                    :src="require(`@/assets/images/icon-x${dark}.svg`)"
                    @click="clearSearch"
                  />
                  <img
                    class="close-value position-absolute"
                    width="19"
                    height="19"
                    :src="
                      require(`@/assets/images/landing/icon-search${dark}.svg`)
                    "
                  />
                </div>
                <div class="list-pair-drop">
                  <template v-if="pairShow?.length > 0">
                    <div
                      class="list-pair"
                      v-for="pair in pairShow"
                      :key="pair.value"
                      @click="selectPair(pair)"
                    >
                      <span
                        :class="{
                          active:
                            pair.name.toUpperCase() ===
                            currentPair.name.toUpperCase(),
                        }"
                      >
                        <!-- <img
                      v-if="pair.name !== $t('order.order_form.all')"
                      :src="`${coinImages[pair.name]}`"
                      alt=""
                      width="30"
                      height="30"
                      class="coin-img"
                    /> -->
                        {{
                          pair.name !== $t("order.order_form.all")
                            ? pair.name.toUpperCase()
                            : pair.name
                        }}</span
                      >
                    </div>
                  </template>
                  <div v-else class="list-no-data">
                    {{ $t("common.datatable.no_have_data") }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div class="label-filter">{{ $t("funds.transfer.from") }}</div>

            <div
              class="drop-down-pair drop-down drop-down-type position-relative d-flex justify-content-between align-items-center"
              v-click-outside="clickOutSideType"
            >
              <span
                class="w-100 h-100 current-pair"
                @click="openDropType = !openDropType"
              >
                <!-- <span>{{ $t("address.coin") }}</span> -->
                <span
                  v-if="currentTypeTransfer.name"
                  class="placeholder-input"
                  >{{ $t(currentTypeTransfer.name) }}</span
                >
              </span>
              <span
                class="w-100 h-100 current-pair"
                @click="openDropType = !openDropType"
              ></span>
              <img
                src="/images/icon/icon-down.svg"
                alt="icon"
                v-if="!openDropType"
                @click="openDropType = true"
              />
              <img
                src="/images/icon/icon-up.svg"
                alt="icon"
                v-if="openDropType"
                @click="openDropType = false"
              />

              <div
                class="position-absolute drop-list drop-list-type"
                v-show="openDropType"
              >
                <div class="list-pair-drop">
                  <div
                    class="list-pair"
                    v-for="typeTransfer in listTypes"
                    :key="typeTransfer.value"
                    @click="selectTypeTransfer(typeTransfer)"
                  >
                    <span
                      :class="{
                        active:
                          typeTransfer.value === currentTypeTransfer.value,
                      }"
                    >
                      {{ $t(typeTransfer.name) }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div class="label-filter">{{ $t("mam.date") }}</div>
            <div class="group-date-transfer d-flex align-items-center">
              <datepicker
                @selected="selectDateStart"
                :value="dateFromDefault"
                :format="customFormatter"
                class="date-start"
                :language="lang"
              >
              </datepicker>
              <div class="to-title">{{ $t("order.history.time-to") }}</div>
              <datepicker
                :value="dateToDefault"
                class="date-end"
                @selected="selectDateEnd"
                :format="customFormatter"
                :language="lang"
              ></datepicker>
            </div>
          </div>
        </div>
        <div>
          <router-link to="/funds/history-wallet?type=transfer">
            {{ $t("funds.deposit.view_all") }}
          </router-link>
        </div>
      </div>

      <div class="transfer_table">
        <data-table3
          :getData="getDataTransfer"
          :msg-empty-data="$t('funds.transaction_history.no_deposit_data')"
          :limit="6"
          :widthTable="'100%'"
          ref="datatableTransfer"
          @isPagination="isPagination"
        >
          <template>
            <th class="cl1" data-sort-field="created_at">
              {{ $t("orders.order_history.time") }}
              <!-- <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
              <span class="iconFixSorIE" v-if="props.resetIE === true"></span> -->
            </th>
            <th class="cl2" data-sort-field="coin">
              {{ $t("user_setting.coin") }}
              <!-- <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
              <span class="iconFixSorIE" v-if="props.resetIE === true"></span> -->
            </th>
            <th class="cl3" data-sort-field="source">
              {{ $t("funds.transfer.from") }}
              <!-- <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
              <span class="iconFixSorIE" v-if="props.resetIE === true"></span> -->
            </th>
            <th class="cl4" data-sort-field="destination">
              {{ $t("funds.transfer.to") }}
              <!-- <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
              <span class="iconFixSorIE" v-if="props.resetIE === true"></span> -->
            </th>
            <th class="cl5" data-sort-field="amount">
              {{ $t("funds.transfer.amount") }}
              <!-- <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
              <span class="iconFixSorIE" v-if="props.resetIE === true"></span> -->
            </th>
            <th class="cl5" data-sort-field="amount">
              {{ $t("order.order_history.status") }}
              <!-- <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
              <span class="iconFixSorIE" v-if="props.resetIE === true"></span> -->
            </th>
          </template>
          <template slot="body" slot-scope="props">
            <tr
              :class="{
                white: (props.index + 1) % 2 !== 0 || props.index === 0,
                white: (props.index + 1) % 2 === 0 && props.index !== 0,
              }"
            >
              <td class="cl1 t-left fs18">
                <div class="time-day">
                  {{ props.item.created_at | formatMomentDay }}
                </div>
                <div class="time-hour">
                  {{ props.item.created_at | formatMomentHour }}
                </div>
              </td>
              <td class="cl2 t-left fs18">
                <img
                  class="icon-coin"
                  :src="`${coinImages[props.item.coin]}`"
                  alt=""
                  width="30"
                  height="30"
                />
                {{ props.item.coin | uppercase }}
              </td>
              <td class="cl3 t-left fs18">
                {{ props.item.source | filterWallet(props.item.coin) }}
              </td>
              <td class="cl4 t-left fs18">
                {{ props.item.destination | filterWallet(props.item.coin) }}
              </td>
              <td class="cl5 t-left fs18">
                {{ props.item.amount | formatNumberAndDecimal }}
              </td>
              <td :class="['cl5', 't-left', 'fs18', props.item.status]">{{ props.item.status | statusTransfer(props.item.status)}}</td>
            </tr>
          </template>
        </data-table3>
      </div>
    </div>
  </div>
</template>

<script>
import rf from "@/request/RequestFactory";
import Header from "@/pages/wallet/component/Header.vue";
import { mapState } from "vuex";
import moment from "moment";
import ETHIcon from "@/assets/images/ethereum-eth-logo.png";
import Cookies from "js-cookie";
import Datepicker from "vuejs-datepicker";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

const LIST_TYPES = [
  {
    value: null,
    name: "funds.transfer.from",
  },
  {
    value: "main",
    name: "landing.tab_Spot",
  },
  {
    value: "usd_m",
    name: "usdm_transfer",
  },
  {
    value: "coin_m",
    name: "coinm_transfer",
  },
];

export default {
  components: {
    Header,
    DateRangePicker,
    Datepicker,
  },
  data() {
    return {
      listTypes: LIST_TYPES,
      coinNames: {},
      coinImages: {},
      openDropPair: false,
      openDropType: false,
      pairName: "",
      focusInput: false,
      pairShow: [],
      timeName: "",
      currentPair: {
        name: this.$t("order.order_form.all"),
      },
      currentTypeTransfer: LIST_TYPES[0],
      currentTime: {
        name: this.$t("funds.transfer.past_7"),
      },
      pairs: [],
      filter: {
        dateStart: moment()
          .subtract(6, "days")
          .startOf("day")
          .format("YYYY-MM-DD"),
        coin: "",
      },
      ethicon: ETHIcon,
      sizeScreen: window.innerWidth,
      lastPage: 0,
      isVi:
        Cookies.get("locale", { domain: process.env.VUE_APP_PUBLIC_URL }) ==
        "vi",
      lang:
        Cookies.get("locale", { domain: process.env.VUE_APP_PUBLIC_URL }) ||
        "en",
      dateFromDefault: moment().subtract(6, "days").toDate(),
      dateToDefault: moment().toDate(),
      disableToDate: {
        from: new Date(),
      },
      isMobileScreen: window.matchMedia("(max-width: 1250px)").matches,
      dateStart: moment()
        .subtract(6, "days")
        .startOf("day")
        .format("YYYY-MM-DD"),
      dateEnd: moment().endOf("day").format("YYYY-MM-DD"),
    };
  },
  computed: {
    ...mapState({
      masterData: (state) => state.masterdata,
      theme: (state) => state.userThemeMode,
    }),
    dark() {
      return this.theme === "light-theme" ? "" : "-dark";
    },
  },
  watch: {
    sockets: {
      connect: function () {},
    },
    "window.innerWidth": {
      handler() {
        this.resizeHandler();
      },
    },
  },
  methods: {
    clearSearch() {
      this.pairName = "";
      this.pairShow = this.pairs;
    },
    isPagination(value) {
      this.lastPage = value;
    },
    resizeHandler() {
      this.sizeScreen = window.innerWidth;
    },
    onDatatableFinish() {},
    refresh() {
      this.$refs.datatable.refresh();
    },
    filterParams() {
      let filterParams = {
        start_date: this.dateStart,
        end_date: this.dateEnd,
      };
      if (this.filter.coin && this.filter.coin !== "All") {
        filterParams.coin = this.filter.coin;
      }
      return filterParams;
    },
    async selectDateStart(dateStart) {
      this.dateStart = moment(dateStart).format("YYYY-MM-DD");
      this.$refs.datatableTransfer.filter(this.filterParams());
    },
    async selectDateEnd(dateEnd) {
      this.dateEnd = moment(dateEnd).format("YYYY-MM-DD");
      if (dateEnd) {
        this.disableToDate = {
          from: new Date(dateEnd),
        };
      }
      this.$refs.datatableTransfer.filter(this.filterParams());
    },
    customFormatter(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    getDataTransfer(params) {
      let paramsFilter = {};
      if (this.dateStart) {
        paramsFilter.dateStart =
          moment(this.dateStart).startOf("day").format("x") / 1000;
      }
      if (this.dateEnd) {
        paramsFilter.dateEnd =
          moment(this.dateEnd)
            .add(1, "day")
            .subtract(7, "hours")
            .endOf("day")
            .format("x") / 1000;
      }
      if (this.filter.coin.length !== 0) {
        paramsFilter.coin = this.filter.coin;
      }
      if (this.filter.type) {
        paramsFilter.type = this.filter.type;
      }
      return rf
        .getRequest("TransactionRequest")
        .getTransferHistory(Object.assign({}, paramsFilter, params));
    },
    clickOutSidePair() {
      this.openDropPair = false;
      this.focusInput = false;
      this.pairShow = this.pairs;
      this.pairName = "";
    },
    clickOutSideType() {
      this.openDropType = false;
    },
    searchPair() {
      const debouncedFunc = _.debounce(() => {
        this.pairShow = this.pairs.filter((item) =>
          item.name.toLowerCase().trim().includes(this.pairName.toLowerCase())
        );
      }, 500);

      return debouncedFunc();
    },
    // handleDelete() {
    //   this.filter.coin = "";
    //   this.pairName = "";
    //   this.currentPair = {
    //     name: "",
    //   };
    //   this.pairShow = this.pairs;
    //   this.$refs.datatableTransfer.refresh();
    // },
    selectPair(pair) {
      this.currentPair = {
        name:
          pair.name !== this.$t("order.order_form.all")
            ? pair.name.toUpperCase()
            : pair.name,
      };
      this.openDropPair = false;
      this.filter.coin = pair.name === "All" ? "" : pair.name;
      this.$refs.datatableTransfer.refresh();
    },
    selectTypeTransfer(type) {
      this.currentTypeTransfer = type;
      this.openDropType = false;
      this.filter.type = type.value;
      this.$refs.datatableTransfer.refresh();
    },
    updateFixedDivPositionCallendar() {
      const referenceDiv = document.querySelector(".date-start");
      const referenceDiv2 = document.querySelector(".date-end");
      const fixedDiv = document.querySelector(
        ".date-start .vdp-datepicker__calendar"
      );
      const fixedDiv2 = document.querySelector(
        ".date-end .vdp-datepicker__calendar"
      );
      // Get the position and size of the referenceDiv
      const rect = referenceDiv.getBoundingClientRect();
      const rect2 = referenceDiv2.getBoundingClientRect();

      // Set the position of the fixedDiv based on the referenceDiv
      fixedDiv.style.top = `${36}px`;
      // fixedDiv.style.left = `${rect.left}px`;
      fixedDiv2.style.top = `${36}px`;
      fixedDiv2.style.left = `20px`;
      if (this.isMobileScreen) {
        fixedDiv.style.left = `60%`;
        fixedDiv2.style.left = `65%`;
      }
    },
    handleMasterData() {
      _.forEach(this.masterData.coins, (item, key) => {
        this.coinNames[item.coin] = item.name;
        this.coinImages[item.coin] = item.icon_image;
        this.pairs.push({ name: `${item.coin}`, value: key + 1 });
      });
      this.coinNames["usd"] = window.i18n.t(`currency.usd.fullname`);
      this.coinImages["usd"] = `/images/color_coins/usd.png`;
      this.coinImages["eth"] = this.ethicon;
      // this.pairs.push({ name: "usd", value: this.pairs.length + 1 });
      this.pairs = this.pairs.sort((a, b) => {
        const coinA = a.name;
        const coinB = b.name;
        return coinA.localeCompare(coinB);
      });

      this.pairs.unshift({ name: this.$t("order.order_form.all"), value: 0 });
      this.pairShow = this.pairs;
    },
  },
  mounted() {
    this.handleMasterData();
    this.updateFixedDivPositionCallendar();
    this.sockets.subscribe("balance", (data) => {
      this.$refs.datatableTransfer.filter(this.filterParams());
    });
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.dark-theme {
  #history-transfer {
    .vdp-datepicker input {
      background-color: #1b1d24 !important;
      background-image: url("@/assets/images/icon/date-time-home-dark.svg");
    }
    .vdp-datepicker__calendar {
      background: $color-gray-charade;
    }

    .trade_layout_trade_history {
      background: transparent !important;

      .group-control {
        background: transparent !important;
      }

      .tableContainer3 {
        background: transparent !important;

        table {
          background: transparent !important;
        }
      }
    }
  }
}

.light-theme {
  #history-transfer {
    .vdp-datepicker input {
      background-image: url("@/assets/images/icon/date-time-order.svg");
      background-repeat: no-repeat;
      background-size: 20px;
    }
  }
}
.cl4 {
  width: 166px;
}
.overflow {
  width: 84px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.icon-coin {
  object-fit: contain;
}
.group-date-transfer {
  display: inline-block;
  margin-left: 0;
  position: relative;
  .vdp-datepicker {
    display: inline-block;
    float: left;
  }
  .date-start input {
    background-image: none !important;
  }
  .date-end {
    margin-left: 0;
    input {
      width: 140px !important;
      border: none !important;
      border-radius: 0 8px 8px 0 !important;
      background-size: 20px;
      background-position: top 10px right 10px !important;
      background-repeat: no-repeat !important;
    }
  }

  .to-title {
    background-color: var(--monas-background-dropdown-convert) !important;
    color: var(--text-primary-color) !important;
    height: 40px;
    width: fit-content;
    display: flex;
    align-items: center;
    padding: 0 8px;
    font-size: 18px;
  }
  .date-title {
    margin-right: 5px;
  }
  .vdp-datepicker {
    display: inline-block;
    position: unset;
  }
  //.date-end{
  //  margin-left: -5px;
  //}
}

#history-transfer {
  .APPROVED {
    color: #00B277 !important;
  }
  .REJECTED {
    color: #E02D3C !important;
  }
  .label-filter {
    color: var(--text-primary-color);
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 4px;
  }
  .vdp-datepicker__calendar {
    padding: 20px 25px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
    border: 1px solid #fff;
    header span {
      color: var(--text-primary-color);
      font-size: 16px;
    }

    .cell {
      padding: 0;
      color: var(--text-primary-color);
      font-size: 16px;
      height: 32px;
      width: 32px;
      line-height: 32px;
      margin: 0 2px;
    }

    .day-header {
      color: var(--monas-convert-color-tab);
    }
  }
  .vdp-datepicker {
    &__calendar {
      width: 311px;
    }
    input {
      font-family: $font-inter-regular;
      font-size: $font-medium-bigger;
      background: $color-alabaster;
      height: 40px;
      cursor: pointer;
      color: var(--color-percent);
    }
  }

  .date-end {
    input {
      height: 40px;
      width: 140px !important;
      border: none !important;
      border-radius: 0px 8px 8px 0px !important;
      background-position: top 8px right 14px;
    }
  }
  .date-start {
    input {
      height: 40px;
      background-image: none !important;
      padding: 0 0 0 16px !important;
      border: none !important;
      border-radius: 8px 0px 0px 8px !important;
      width: 108px !important;
    }
  }
  .vue-daterange-picker {
    ::v-deep {
      .reportrange-text {
        background: $color-alabaster;
        border-radius: 8px;
        border: none;
        box-shadow: none;
        display: flex;
        align-items: center;
        font-family: $font-inter-regular;
        font-size: $font-medium-bigger;

        .range-from {
          color: $color-gray-dark;
          margin-right: 4px;
          width: 95px;
        }
        .range-to {
          color: $color-gray-dark;
          margin-left: 4px;
          width: 95px;
        }

        .icon-calendar {
          margin-left: 8px;
        }

        span {
          color: $color-grey-dark;
        }
      }

      .daterangepicker {
        td.active {
          background-color: $color-aqua-green;
          border-color: transparent;
          color: white;
          border-radius: 50%;
        }
        td.off {
          background-color: #fff !important;
          border-color: transparent !important;
          color: #999 !important;
        }
      }
    }
  }
  .transfer_table {
    margin-bottom: 28px;
  }

  .filter-table {
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      color: #00ddb3;
      font-size: 16px;
      text-decoration-line: underline;
    }

    @media screen and (max-width: 570px) {
      flex-wrap: wrap;
      margin-bottom: 12px;
    }
  }
  .title-transfer {
    font-size: 34px;
    font-weight: 600;
    color: var(--text-primary-color);
  }

  .time-day {
    font-size: 14px;
  }

  .time-hour {
    font-size: 14px;
    color: var(--color-percent);
  }

  .transfer_container {
    display: grid;
    width: 100%;
    padding: 0;
    max-width: 1340px;
    margin-top: 120px;
  }
  .group-control {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 32px 0;
    flex-wrap: wrap;

    @media screen and (max-width: 570px) {
      padding-bottom: 0;
      margin-bottom: 24px;
    }

    .drop-down-pair {
      width: 220px;
      height: 40px;
      padding: 8px 15px;
      background: var(--monas-background-dropdown-convert);
      color: var(--text-second-color);
      border-radius: 8px;
      cursor: pointer;
      .current-pair {
        font-size: 18px;
        color: var(--monas-convert-color-tab);
        .placeholder-input {
          position: absolute;
          transform: translateY(-5%);
          // right: 36px;
          text-align: right;
          color: var(--monas-convert-color-tab);
        }
      }
      .icon-arrow1,
      .icon-arrow2 {
        color: var(--text-primary-color);
        &:before {
          font-size: 14px;
        }
      }
      .drop-list {
        width: 220px;
        background: var(--background-color-primary);
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        top: 42px;
        left: -25%;
        transform: translateX(25%);
        padding: 15px 20px;
        z-index: 1;

        .list-pair {
          margin-bottom: 20px;
          font-size: 18px;
          line-height: 21px;
          color: var(--color-dropdown);
          &:hover {
            color: $color-caribbean-green;
          }
          span {
            display: flex;
            gap: 8px;
            align-items: center;
          }
          .active {
            color: $color-caribbean-green;
          }
          img {
            //clip-path: circle(47%);
            width: 28px;
            object-fit: contain;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
        .list-pair-drop {
          max-height: 184px;
          overflow-y: auto;
          overflow-x: hidden;
        }
        .list-no-data {
          text-align: center;
          padding: 24px 0;
          color: var(--text-close);
        }

        .text-search-pair {
          width: 144px;
          height: 42px;
          margin-bottom: 16px;
          background: var(--bg-text-search);
          border-radius: 8px;
          padding: 10px 15px;

          input {
            border: none;
            background: transparent;
            color: var(--text-primary-color);
            font-size: 18px;
            line-height: 22px;
            width: 60px;
          }
          ::placeholder {
            color: var(--color-text-search);
          }

          .icon-search {
            left: 15px;
            top: 50%;
            transform: translateY(-50%);
          }
          .clear-text {
            top: 50%;
            transform: translateY(-50%);
            right: 40px;
          }
          .close-value {
            top: 50%;
            transform: translateY(-50%);
            right: 15px;
          }
        }
      }
      .drop-list-pair {
        width: 170px;
        height: 268px;
        padding: 15px 14px 0 14px;
      }
      .drop-list-type {
        height: fit-content;
      }
    }
    .drop-down {
      width: 170px;
    }
    .drop-down-type {
      min-width: 216px !important;
    }
    .group-type {
      display: inline-block;
      margin-right: 20px;
      float: left;
      .sc_search_select {
        height: 30px;
        width: 95px;
        display: inline-block;
        float: left;
      }

      button {
        line-height: 20px;
        height: 49px;
        padding: 6px 16px;
        width: auto;
        min-width: 85px;
      }
    }
    .button-reset {
      margin-left: 25px;
      margin-right: 10px;
      background-color: $color-caribbean-green;
      color: $color-grey-dark;
      box-shadow: none;
      font-size: 18px;
      border: 1px solid $color-caribbean-green;
      border-radius: 8px !important;
      width: 100px !important;
      height: 49px;
      float: left;
      -webkit-transition: 0.5s;
      transition: 0.5s;
      letter-spacing: 0.9px;

      &:hover {
        background: $color-aquamarine;
        border-color: $color-aquamarine;
        color: $color-grey-dark;
        -webkit-transition: 0.5s;
        transition: 0.5s;
      }
      @media screen and (max-width: 570px) {
        display: none;
      }
    }
  }

  .group-type-mb {
    display: inline-block;
    margin-right: 20px;
    float: left;
    .sc_search_select {
      height: 30px;
      width: 95px;
      display: inline-block;
      float: left;
    }

    button {
      line-height: 20px;
      height: 49px;
      padding: 6px 16px;
      width: auto;
      min-width: 85px;
    }
  }

  .button-reset-mb {
    display: none;
    margin: 20px 0;
    background-color: $color-caribbean-green;
    color: $color-grey-dark;
    box-shadow: none;
    font-size: 18px;
    border: 1px solid $color-caribbean-green;
    border-radius: 8px !important;
    width: 100px !important;
    height: 49px;
    float: left;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    letter-spacing: 0.9px;

    &:hover {
      background: $color-aquamarine;
      border-color: $color-aquamarine;
      color: $color-grey-dark;
      -webkit-transition: 0.5s;
      transition: 0.5s;
    }

    @media screen and (max-width: 570px) {
      display: block;
    }
    @media screen and (max-width: 430px) {
      margin-top: 0px;
    }
  }

  .transfer_table {
    .tableContainer {
      table {
        border-top: 0px;
        thead {
          height: auto;
          tr {
            th {
              background: var(--color-tab-data-table);
              font-size: $font-root;
              padding: 15px 0px;
              line-height: 20px;
              text-align: left;
              color: var(--text-close);
              &:nth-child(1) {
                padding-left: 25px;
                border-radius: 8px 0px 0px 8px;
                width: 20%;
                min-width: 200px;
              }
              &:nth-child(2) {
                width: 16%;
                min-width: 132px;
                padding-left: 5px;
              }
              &:nth-child(3) {
                width: 20%;
                min-width: 150px;
                padding-left: 5px;
              }
              &:nth-child(4) {
                width: 21%;
                padding-left: 5px;
                min-width: 150px;
              }
              &:nth-child(5) {
                padding-right: 25px;
                padding-left: 5px;
                min-width: 150px;
              }
              &:last-child {
                border-radius: 0px 8px 8px 0px;
                @media screen and (max-width: 768px) {
                  min-width: 170px;
                }
              }
            }
          }
        }
        tbody {
          tr {
            vertical-align: top;
            height: 40px;
            overflow-y: hidden;
            transition-property: height;
            transition-duration: 0.3s, 0.3s;
            transition-timing-function: ease, ease-in;

            &:nth-child(1) {
              width: 24.5%;
            }
            &:nth-child(2) {
              width: 16%;
            }
            &:nth-child(3) {
              width: 20%;
            }
            &:nth-child(4) {
              width: 21%;
            }
            &:nth-child(4) {
              width: 21%;
            }

            &.even {
              background: transparent;
            }
            &:hover {
              background: transparent;
            }
            &.active {
              max-height: 300px;
              transition-property: height;
              transition-duration: 0.3s, 0.3s;
              background: transparent;
              transition-timing-function: ease, ease-in;
              .glyphicon-menu-down {
                transition-duration: 0.5s;
                transform: rotate(180deg);
              }
            }

            &.empty-data {
              background: transparent;
              height: 196px !important;
              .spinner {
                padding-top: 90px;
                 @media screen and (max-width: 768px) {
                   padding-top: 30px;
                  }
              }
            }

            td {
              font-size: 14px;
              line-height: 19px;
              padding: 24px 0px;
              text-align: left;
              color: var(--text-primary-color);

              &:nth-child(1) {
                padding-left: 25px;
              }
              &:nth-child(2) {
                padding-left: 5px;
                padding-top: 20px;
              }
              &:nth-child(3) {
                padding-left: 5px;
              }
              &:nth-child(4) {
                padding-left: 5px;
              }
              &:nth-child(5) {
                padding-right: 25px;
                padding-left: 5px;
                // position: absolute;
                // right: 0px;
              }
            }
          }
        }
      }
    }
    @media screen and (max-width: 768px) {
      overflow: auto;
      white-space: nowrap;
    }
  }
  //@media screen and (max-width: 430px) {
  //  .group-control {
  //    flex-direction: column;
  //    align-items: flex-start !important;
  //    .button-reset {
  //      margin-left: 0;
  //    }
  //  }
  //}
}
@media screen and (max-width: 992px) {
  #history-transfer {
    .isPagination {
      margin-bottom: 60px;
    }
    .transfer_container {
      margin-top: 45px;

      .title-transfer {
        font-size: 28px;
      }

      .group-control {
        padding-top: 12px;
      }
    }
  }
}
</style>
