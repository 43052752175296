var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-container"},[_c('div',{attrs:{"id":"main-market"}},[_c('h3',{staticClass:"market-title"},[_vm._v(_vm._s(_vm.$t("landing.market.title")))]),_c('div',{staticClass:"group-coin row"},[_c('div',{staticClass:"page-container volume col-xs-12 col-md-6 col-xl-3 col-sm-12 custom-col"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("landing.title_volume")))]),_vm._l((_vm.volume),function(itemVolume,index){return _c('a',{key:index,staticClass:"main-volume",attrs:{"href":'/spot-exchange/basic?currency=usdt&coin=' + itemVolume.name,"target":"_blank"},on:{"click":function($event){return _vm.selectedCurrenyPair( 'usdt', itemVolume.name)}}},[_c('div',{staticClass:"img-coin"},[_c('img',{staticClass:"icon-coin",attrs:{"src":itemVolume.img,"alt":"","width":"30","height":"30"}})]),_c('div',{staticClass:"item-volume name-coin"},[_vm._v(_vm._s(itemVolume.name))]),_c('div',{staticClass:"price-container d-flex justify-content-between"},[_c('div',{staticClass:"item-volume price-coin",class:{
                upCoin: itemVolume?.changedLastestPrice > 0,
                downCoin: itemVolume?.changedLastestPrice < 0,
              }},[(itemVolume.lastest_price >=1)?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatNumberAfterDecimal")(_vm._f("toNumber")(itemVolume.lastest_price), 2))+" ")]):(itemVolume.lastest_price < 1 && itemVolume.lastest_price>=0.1)?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatNumberAfterDecimal")(_vm._f("toNumber")(itemVolume.lastest_price),4))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("formatNumberAfterDecimal")(_vm._f("toNumber")(itemVolume.lastest_price),8))+" ")])]),_c('div',{staticClass:"item-volume percent-coin",class:{
                grown: +itemVolume.changed_percent > 0,
                down: +itemVolume.changed_percent < 0,
              }},[(itemVolume.changed_percent >=1000)?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatChangedPercent")(itemVolume.changed_percent,5))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("changedPercent")(_vm.formatShortText((itemVolume.changed_percent), 2, 8)))+" ")])])])])})],2),_c('Listing',{staticClass:"col-xs-12 col-md-6 col-xl-3 col-sm-12 custom-col",attrs:{"dataListing":_vm.listing}}),_c('GainerCoin',{staticClass:"col-xs-12 col-md-6 col-xl-3 col-sm-12 custom-col",attrs:{"data-gainer":_vm.gainer}}),_c('LoserCoin',{staticClass:"col-xs-12 col-md-6 col-xl-3 col-sm-12 custom-col",attrs:{"dataLoser":_vm.loser}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }