 import rf from '@/request/RequestFactory';

const actions = {
  getBalance({ commit }) {
    return new Promise((resolve, reject) => {
      rf.getRequest('MarginRequest').getBalance().
        then(res => {
          commit('updateBalance', res.data);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        })
    });
  },
  getBalanceRequest({}, params) {
    return rf.getRequest('MarginRequest').getBalance(params);
  },
  updateBalance({ commit }, data) {
    // console.log(data)
    if (data && data.is_mam == 0) {
      commit('updateBalance', data);
    }
  },
}

const getters = {
  balance: state => {
    return state.balance
  }
}

const mutations = {
  updateBalance: (state, payload) => {
    state.balance = {
      walletBalance: payload.balance,
      isolatedBalance: payload.isolated_balance,
      crossBalance: payload.cross_balance,
      crossEquity: payload.cross_equity,
      unrealisedPnl: payload.unrealised_pnl,
      crossMargin: payload.cross_margin,
      orderMargin: payload.order_margin,
      availableBalance: payload.available_balance,
      maxAvailableBalance: payload.max_available_balance,
    }
  }
}

export default {
  actions,
  getters,
  mutations,
  state: {
    balance: {}
  },
}