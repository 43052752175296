<template>
  <div id="overview_container" class="page-overview">
    <div v-if="sizeScreen > 1300 && showMenu()" class="left-menu">
      <NavigationWallet
        :sizeScreen="sizeScreen"
        :tabIdActive="tabIdActive"
        @selected="select"
      />
    </div>
    <div :class="['content-right', sizeScreen < 769 && 'overScreen']">
      <router-view />
    </div>
  </div>
</template>
<script>
import NavigationWallet from "@/components/shared_components/common/NavigationWallet.vue";
import WalletOverView from "@/pages/wallet/component/WalletOverview.vue";
import SpotOverview from "@/pages/wallet/component/SpotOverview.vue";

export default {
  data() {
    return {
      tabIdActive: 1,
      sizeScreen: window.innerWidth,
    };
  },
  components: {
    NavigationWallet,
    WalletOverView,
    SpotOverview,
  },
  methods: {
    select(id) {
      this.tabIdActive = parseInt(id);
    },
    resizeHandler() {
      this.sizeScreen = window.innerWidth;
    },
    showMenu() {
      return this.$route.path !== '/funds/transfer'
    },
    checkActiveTab() {
      if (this.$route.path == "/funds/history-wallet") this.select(4);
      else if (
        this.$route.path == "/funds/spot-wallet" ||
        this.$route.path == "/funds/deposits-wallet" ||
        this.$route.path == "/funds/withdraw-wallet" ||
        this.$route.path == "/funds/transfer"
      )
        this.select(2);
      else if (
        this.$route.path == "/funds/balances-wallet" ||
        this.$route.path == "/funds/withdraw-usd-wallet" ||
        this.$route.path == "/funds/deposit-usd-wallet" ||
        this.$route.path == "/funds/transfer"
      )
        this.select(1);
      else this.select(3);
    },
  },
  watch: {
    "$route.path": {
      handler() {
        this.changeActiveTab;
      },
    },
    "window.innerWidth": {
      handler() {
        this.resizeHandler;
      },
    },
  },
  computed: {
    changeActiveTab: function () {
      this.checkActiveTab();
    },
    checkSizeScreen: function () {
      window.addEventListener("resize", this.resizeHandler);
    },
  },
  mounted() {
    this.checkActiveTab();
    this.resizeHandler();
    window.addEventListener("resize", this.resizeHandler);
  },
};
</script>
<style lang="scss">
@import "@/assets/sass/variables";
.page-overview {
  display: flex;
  height: 100%;
  background-color: var(--color_border_gap);
  padding-top: 4px;
  .left-menu {
    width: 340px;
    min-width: 337px;
    padding: 0 !important;
    transition: width 0.5s;
    background-color: var(--background-color-primary-2);
    border-right: solid 4px var(--color_border_gap);
    @media only screen and (max-width: 1440px) {
      flex: unset;
    }
  }
  .content-right {
    width: calc(100% - 300px);
    flex: 1%;
    background-color: var(--background-color-primary-2);
    @media screen and (max-width: 1440px) {
      //width: 100%;
    }
  }
  .overScreen {
    width: 100%;
  }
}
</style>
