<template>
  <div id="about" class="clearfix">
      <section class="top-about">
          <div class="content-des-about">
            <div class="title-top-about">
              <h3 class="title-about">{{ $t("about.title") }}</h3>
              <p class="sub-title">{{ $t("about.sub_title") }}</p>

              <div class="about-infor">{{ $t("about.information") }}</div>
            </div>
           
            <div v-if="sizeScreen < 992" class="bgr-mobile">
              <img width="375px" height="300px" src="@/assets/images/about/bgr-about-mobile2.png"/>
            </div>
          </div>
        
      </section>
      <about-vision></about-vision>

      <section class="about-value">
          <div class="content-value">
              <h3 class="title-value">{{ $t('about.title_value') }}</h3>
              <div class="group-item">
                 <div class="item-value">
                    <img src="@/assets/images/about/value-trans.png"/>
                    <p>{{ $t('about.title_transparency') }}</p>
                </div>
                <div class="item-value">
                    <img src="@/assets/images/about/value-stable.png"/>
                    <p>{{ $t('about.title_stable') }}</p>
                </div>
                <div class="item-value">
                    <img src="@/assets/images/about/value-con.png"/>
                    <p>{{ $t('about.title_convenient') }}</p>
                </div>
              </div>
          </div>
      </section>

      <section class="about-reason">
          <div class="content-value">
            <h3 class="title-value">{{ $t('about.title_reasons') }}</h3>
            <div class="group-reason row">
                <div class="item-reason col-6 col-xs-6 col-md-6 col-xl-3 col-sm-6">
                <img :src="require(`@/assets/images/about/img-security${dark}.png`)"/>
                <p>{{ $t('about.title_security') }}</p>
                <div class="des-reason">{{ $t('about.des_security') }}</div>
              </div>
              <div class="item-reason  col-6 col-xs-3 col-md-6 col-xl-3 col-sm-6">
                <img :src="require(`@/assets/images/about/img-ratio${dark}.png`)"/>
                <p>{{ $t('about.title_ratio') }}</p>
                <div class="des-reason">{{ $t('about.des_ratio') }}</div>
              </div>
              <div class="item-reason  col-6 col-xs-6 col-md-6 col-xl-3 col-sm-6">
                <img :src="require(`@/assets/images/about/img-smart${dark}.png`)"/>
                <p>{{ $t('about.title_smart') }}</p>
                <div class="des-reason">{{ $t('about.des_smart') }}</div>
              </div>
              <div class="item-reason  col-6 col-xs-6 col-md-6 col-xl-3 col-sm-6">
                <img :src="require(`@/assets/images/about/img-customer${dark}.png`)"/>
                <p>{{ $t('about.title_customer') }}</p>
                <div class="des-reason">{{ $t('about.des_customer') }}</div>
              </div>
              </div>
          </div>
      </section>
  </div>
</template>

<script>
import AboutVision from '@/components/about_pages/AboutVision.vue';
import {mapState} from 'vuex';

  export default {
    components: {
      AboutVision,
    },
    data() {
      return {
        sizeScreen: window.innerWidth
      }
    },
    computed: {
      ...mapState({
          theme: state => state.userThemeMode,
        }),
        dark() {
          return this.theme === 'light-theme'? '': '-dark';
        }
    },
    beforeDestroy() {
      window.removeEventListener('beforeunload', this.scrollToTop);
    },
    methods :{
      resizeHandler() {
        this.sizeScreen = window.innerWidth;
      },
      scrollToTop() {
        window.scrollTo(0, 0);
      },
    },
    mounted() {
      window.addEventListener("resize", this.resizeHandler);

      window.addEventListener('beforeunload', this.scrollToTop);
    },
    created () {
        document.title = this.$t('menu.about') + ` – ${this.tabTitle}`;
    },
  }
</script>

<style lang="scss">
  @import "@/assets/sass/variables";
 .clearfix::after{    
    display: block;
    clear: both;
    content: "";
  }
  .dark-theme{
    #about{
      .about-value {
        background-image: url('@/assets/images/about/bgr-value-dark.png');
      }
    }
  }
  #about {
    background-color: var(--background-home-page);
    .top-about {
      background-image: url('@/assets/images/about/bgr-about.png');
      background-repeat: no-repeat;
      background-size: 103% 98%;
      @media screen and (max-width: 1400px) {
        background-size: 114% 88%

      }
      .content-des-about {
        margin: 0 auto;
        padding: 117px 0px 115px;
        font-family: "inter-regular";
        max-width: 1440px;

        .title-about {
          font-size: 34px;
          line-height: 40px;
          color: $color-white;
          margin-bottom: 8px;
          font-family: $font-inter-bold;
        }
        .sub-title {
          font-size: 16px;
          line-height: 19px;
          color: $color-white;
          margin-bottom: 0;
        }
        .about-infor {
          white-space: pre-line;
          font-size: 16px;
          line-height: 19px;
          color: var(--text-close);
          max-width: 645px;
          margin-top: 32px;
        }
      }
    }
    .content-value {
        margin: 0 auto;
        padding: 80px 0px 150px;
        font-family: "inter-regular";
        max-width: 1440px;
    }
    .title-value {
        font-size: 34px;
        line-height: 40px;
        color: var(--text-primary-color);
        font-family: $font-inter-bold;
        margin-bottom: 60px;
      }

    .about-value {
      background-image: url('@/assets/images/about/bgr-value.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;

      .group-item {
        display: flex;
        justify-content: center;
        padding-top: 33px;
        gap: 300px;
      }

      .item-value {
        text-align: center;
        p{
          font-size: 20px;
          line-height: 24px;
          color: var(--text-primary-color);
          font-family: $font-inter-bold;
          margin-top: 20px;
        }
      }
    }

    .about-reason {
      .item-reason {
        p {
          font-size: 24px;
          line-height: 29px;
          font-family: $font-inter-bold;
          max-width: 229px;
          min-height: 58px;
          margin: 0;
          color: var(--text-primary-color);
        }
        .des-reason {
          font-size: 16px;
          line-height: 19px;
          color: $color-gray-dark;
          max-width: 261px;
          margin-top: 15px;
        }
      }
    }
  }
  @media screen and (max-width: 1500px) {
    #about {
      .top-about {
        .content-des-about {
          padding-left: 16px;
          padding-right: 16px;
        }
      }
      .content-value {
        padding-left: 16px;
        padding-right: 16px;
      }
    }
    .page-container-about {
      padding-left: 16px;
          padding-right: 16px;
    }
  }
  @media screen and (max-width: 992px) {
    #about {
      .top-about {
        background: $color-eerie-black;
        .content-des-about {
          padding: 60px 0px 0;
          .title-top-about {
            padding: 0 32px;
          }
          .title-about {
            font-size: 28px;
            line-height: 34px;
          }
          .sub-title {
            margin-bottom: 50px;
          }
          .about-infor {
            font-size: 14px;
            line-height: 16px;
          }
          .bgr-mobile {
            padding-top: 40px;
            text-align: right;
            /* img {
              width: 100%;
            } */
           
          }
        }
      }
      .content-value {
        padding: 35px 23px;
      }
      .title-value {
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 0px;
      }
      .about-value {
        background-image: url('@/assets/images/about/bgr-value-mobile.png');
        .group-item {
          gap: 10px;
          justify-content: space-between;
        

          .item-value {
            img {
              width: 50px;
              height: 50px;
            }
            p {
              font-size: 14px;
              line-height: 16px;
            }
          }
        }
      }

      .about-reason {
        .title-value {
          margin-bottom: 30px;
        }
        .item-reason {
          padding-bottom: 30px;
          img {
            width: 147px;
            height: 80px;
          }
          p {
            font-size: 16px;
            line-height: 19px;
            min-height: 38px;
          }
          .des-reason {
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 9375px) {

  }
</style>