<template>
  <div id="modal">
    <modal :name="modalName" width="620">
      <template slot="body">
        <div slot="body" class="phone-modal">
          <div class="phone-modal-img" v-if="type === 'verify'">
            <img
              width="105"
              height="105"
              :src="`/images/icon-phone-number${dark}.svg`"
              alt="icon"
            />
          </div>
          <div class="phone-modal-title">
            {{ titleModal }}
          </div>
          <div class="phone-modal-body">
            <template v-if="type === 'verify'">
              <div class="phone-modal-body__title">
                {{ $t("phone_number.add.email-verification-code") }}
              </div>
              <div
                class="phone-modal-body__verification-code"
                :class="{ error: errors.has('email_code_verify') }"
              >
                <currency-input-otp
                  name="email_code_verify"
                  v-validate="'required'"
                  class="phone-modal-body__verification-code-input-verification"
                  v-model="emailCodeVerify"
                  type="text"
                  maxlength="6"
                  data-vv-validate-on="none"
                  @focus="resetError"
                  :placeholder="
                    $t('phone_number.add.email-verification-code-placeholder')
                  "
                />
                <button
                  @click="getVerifyCode(true)"
                  :class="
                    isStatusCodeEmail !== 2
                      ? 'phone-modal-body__verification-code-add-btn'
                      : 'phone-modal-body__verification-code-text'
                  "
                >
                  {{ typeVerifyCode }}
                </button>
              </div>
              <div class="message-wrapper">
                <p class="error-msg" v-if="errors.has('email_code_verify')">
                  {{ $t("phone_number.error.code_invalid") }}
                </p>
              </div>
              <div
                class="phone-modal-body__count-down-timer mb-3"
                v-if="isStatusCodeEmail === 2"
              >
                {{ $t("phone_number.request-new-code") }} {{ formatTimeEmail }}
              </div>
            </template>

            <div class="phone-modal-body__title">
              {{ $t("phone_number.add.verification-code") }}
            </div>
            <div
              class="phone-modal-body__verification-code"
              :class="{ error: errors.has('code_verify') }"
            >
              <currency-input-otp
                name="code_verify"
                v-validate="'required'"
                class="phone-modal-body__verification-code-input-verification"
                v-model="codeVerify"
                type="text"
                maxlength="6"
                data-vv-validate-on="none"
                @focus="resetError"
                :placeholder="
                  $t('phone_number.add.verification-code-placeholder')
                "
              />
              <button
                @click="getVerifyCode(false)"
                :class="
                  isStatusCode !== 2
                    ? 'phone-modal-body__verification-code-add-btn'
                    : 'phone-modal-body__verification-code-text'
                "
              >
                {{ typeVerifyCode }}
              </button>
            </div>
            <div class="message-wrapper">
              <p class="error-msg" v-if="errors.has('code_verify')">
                {{ $t("phone_number.error.code_invalid") }}
              </p>
            </div>
            <div
              class="phone-modal-body__count-down-timer"
              v-if="isStatusCode === 2"
            >
              {{ $t("phone_number.request-new-code") }} {{ formatTime }}
            </div>

            <button
              name="btnSubmit"
              :disabled="isSubmitting || codeVerify?.length !== 6"
              class="phone-modal-body__submit-btn"
              @click="handleSubmitBtn"
            >
              {{ $t("change_password_form.submit_button") }}
            </button>
          </div>
        </div>
      </template>
    </modal>
    <confirmation-modal></confirmation-modal>
  </div>
</template>

<script>
import Modal from "@/components/shared_components/common/Modal";
import rf from "@/request/RequestFactory";
import { mapState } from "vuex";
import VuePhoneNumberInput from "vue-phone-number-input";
import RemoveErrorsMixin from "@/common/RemoveErrorsMixin";
import CurrencyInputOtp from "@/components/shared_components/common/CurrencyInputOtp.vue";
import ConfirmationModal from "@/components/shared_components/common/ConfirmationModal.vue";

const TypeVerifyCode = {
  getCode: 1,
  pending: 2,
  resent: 3,
};

const TIME = 10 * 60;

export default {
  name: "EditPhoneNumberModal",
  mixins: [RemoveErrorsMixin],

  components: {
    Modal,
    VuePhoneNumberInput,
    CurrencyInputOtp,
    ConfirmationModal,
  },
  data() {
    return {
      modalName: "EditPhoneNumberModal",
      phoneNumber: "",
      dataPhoneNumber: {},
      codeVerify: "",
      emailCodeVerify: "",
      isStatusCode: TypeVerifyCode.getCode,
      isStatusCodeEmail: TypeVerifyCode.getCode,
      disabledInputCode: false,
      countdownInterval: null,
      emailCountdownInterval: null,
      countdown: TIME,
      emailCountdown: TIME,
    };
  },
  props: {
    type: "change" | "delete" | "verify",
    token: "",
  },
  methods: {
    handleUpdatePhoneInput(data) {
      this.phoneNumber = data.formattedNumber;
      this.dataPhoneNumber = { ...data };
    },
    resetErrors() {
      if (this.errors.any()) {
        this.errors.clear();
      }
    },

    closeModal() {
      this.codeVerify = "";
      window.CommonModal.hide("EditPhoneNumberModal");
      if (this.mode === "resend") {
        this.$emit(`RESENDMODAL:CLOSE`, true);
      }
    },
    resetParams() {
      this.params.code = "";
    },
    clearForm() {
      this.phoneNumber = "";
      this.dataPhoneNumber = {};
      this.codeVerify = "";
      clearInterval(this.countdownInterval);
      this.isStatusCode = TypeVerifyCode.getCode;
      this.disabledInputCode = false;
      this.countdownInterval = null;
      this.countdown = TIME;
    },
    handleGetCode() {
      const data = {
        to: this.phoneNumber,
      };
      return new Promise((resolve, reject) => {
        rf.getRequest("UserRequest")
          .getCodePhoneNumber(data)
          .then((res) => {
            Message.success(window.i18n.t("validation.get_code_success"));
            resolve(res);
          })
          .catch((e) => {
            if (e.response.data.message === "validation.ip-active.") {
              this.errors.add({
                field: "error",
                msg: this.$t(`${e.response.data.message}`),
              });
            } else {
              this.errors.add({
                field: "email",
                msg: this.$t(`${e.response.data.message}`),
              });
            }
          });
      });
    },
    handleResendCode(isEmail = false) {
      return new Promise((resolve) => {
        (isEmail
          ? rf.getRequest("UserRequest").resendCodeToEmail({
              transaction_id: this.token,
            })
          : rf.getRequest("UserRequest").resendCodeToPhone()
        )
          .then((res) => {
            Message.success(
              window.i18n.t(
                isEmail
                  ? "validation.get_code_success"
                  : "phone_number.message-get-code-phone"
              )
            );
            resolve(res);
          })
          .catch((e) => {
            if (e.response.data.message === "validation.ip-active.") {
              this.errors.add({
                field: "error",
                msg: this.$t(`${e.response.data.message}`),
              });
            } else {
              this.errors.add({
                field: "email",
                msg: this.$t(`${e.response.data.message}`),
              });
            }
          });
      });
    },
    handleVerifyCode() {
      const data = {
        code: this.codeVerify,
      };
      return new Promise((resolve) => {
        (isEmail
          ? rf.getRequest("UserRequest").verifyEmailNumber(data)
          : rf.getRequest("UserRequest").verifyPhoneNumber(data)
        )
          .then((res) => {
            resolve(res);
          })
          .catch((e) => {
            this.errors.add({
              field: "code_verify",
              msg: this.$t(`${e.response.data.message}`),
            });
          });
      });
    },
    handleChangePhoneNumber() {
      return new Promise((resolve) => {
        rf.getRequest("UserRequest")
          .changePhoneNumber()
          .then((res) => {
            resolve(res);
            this.$emit("getUserInfo");
            this.closeModal();
            this.$emit("showPhoneModal", { type: this.type });
            this.clearForm();
          })
          .catch((e) => {
            this.errors.add({
              field: "code_verify",
              msg: this.$t(`${e.response.data.message}`),
            });
          });
      });
    },
    handleDeletePhoneNumber() {
      const data = {
        code: this.codeVerify,
      };
      return new Promise((resolve) => {
        rf.getRequest("UserRequest")
          .deletePhoneNumber(data)
          .then((res) => {
            resolve(res);
            this.$emit("getUserInfo");
            this.closeModal();
            this.clearForm();
            if (this.type === "change") {
              this.$emit("showPhoneModal", { type: this.type });
            } else {
              Message.success(window.i18n.t("phone_number.delete_success"));
            }
          })
          .catch((e) => {
            this.errors.add({
              field: "code_verify",
              msg: this.$t(`${e.response.data.message}`),
            });
          });
      });
    },
    startCodeCountDownEmail() {
      if (this.emailCountdownInterval) {
        clearInterval(this.emailCountdownInterval);
      }

      this.emailCountdownInterval = setInterval(() => {
        this.emailCountdown--;
        if (this.emailCountdown === 0) {
          clearInterval(this.emailCountdownInterval);
          this.emailCountdownInterval = null;
          this.isStatusCodeEmail = TypeVerifyCode.resent;
          this.emailCodeVerify = "";
        }
      }, 1000);
    },
    startCodeCountDown() {
      if (this.countdownInterval) {
        clearInterval(this.countdownInterval);
      }

      this.countdownInterval = setInterval(() => {
        this.countdown--;
        if (this.countdown === 0) {
          clearInterval(this.countdownInterval);
          this.countdownInterval = null;
          this.isStatusCode = TypeVerifyCode.resent;
          this.codeVerify = "";
        }
      }, 1000);
    },
    getVerifyCode(isEmail = false) {
      let statusCode = isEmail ? this.isStatusCodeEmail : this.isStatusCode;
      if (statusCode !== TypeVerifyCode.pending) {
        this.handleResendCode(isEmail).then((res) => {
          console.log("in resend code");
          if (res.success) {
            if (isEmail) {
              this.isStatusCodeEmail = TypeVerifyCode.pending;
              this.startCodeCountDownEmail();
            } else {
              this.isStatusCode = TypeVerifyCode.pending;
              this.startCodeCountDown();
            }
          }
        });
      } else {
        console.log("in verify code");
        this.handleVerifyCode(isEmail).then((res) => {
          this.disabledInputCode = true;
          this.disabledInputEmail = true;
          let countdownInterval = isEmail
            ? this.emailCountdownInterval
            : this.countdownInterval;
          clearInterval(countdownInterval);
        });
      }
    },
    async handleSubmit() {
      this.resetError();
      if (this.isSubmitting) {
        return;
      }
      await this.$validator.validate("code_verify");

      this.handleDeletePhoneNumber();
    },
    confirmWithdrawal(message) {
      window.ConfirmationModal.show({
        type: message,
        title: this.$t("funds.withdrawals.confirm_withdrawal"),
        btnCancelLabel: null,
        btnConfirmLabel: null,
        onConfirm: () => {},
        onCancel: () => {
          this.$router.push("/");
          this.$refs.modal.hide();
        },
      });
    },

    invalidWithdrawal(message) {
      window.ConfirmationModal.show({
        type: message,
        title: this.$t("login.confirm_failed"),
        content: "",
        btnCancelLabel: null,
        btnConfirmLabel: null,
        onConfirm: () => {},
        onCancel: () => {
          this.$refs.modal.hide();
        },
      });
    },
    async handleVerifyPhoneNumber() {
      const data = {
        token: this.token,
        codeSms: this.codeVerify,
        codeEmail: this.emailCodeVerify,
      };
      try {
        await rf.getRequest("TransactionRequest").confirmWithdraw(data);
        this.closeModal();
        this.confirmWithdrawal("confirm-withdrawal-transaction");
      } catch (e) {
        this.invalidWithdrawal("confirm-withdrawal-fail-modal");
      }
    },
    handleSubmitBtn() {
      switch (this.type) {
        case "verify":
          this.handleVerifyPhoneNumber();
          break;
        default:
          this.handleSubmit();
          break;
      }
    },
  },
  computed: {
    ...mapState({
      userThemeMode: (state) => state.userThemeMode,
      theme: (state) => state.userThemeMode,
    }),
    titleModal() {
      switch (this.type) {
        case "change":
          return `${window.i18n.t("phone_number.change")}`;
        case "delete":
          return `${window.i18n.t("phone_number.delete")}`;
        case "verify":
          return `${window.i18n.t("phone_number_title")}`;
        default:
          return;
      }
    },
    isGetCodeButtonDisabled() {
      return (
        this.isStatusCode === TypeVerifyCode.confirm &&
        this.codeVerify.length < 6
      );
    },
    formatTime() {
      const minutes = Math.floor(this.countdown / 60);
      const seconds = this.countdown % 60;
      return `${minutes < 10 ? "0" : ""}${minutes}:${
        seconds < 10 ? "0" : ""
      }${seconds}`;
    },
    formatTimeEmail() {
      const minutes = Math.floor(this.emailCountdown / 60);
      const seconds = this.emailCountdown % 60;
      return `${minutes < 10 ? "0" : ""}${minutes}:${
        seconds < 10 ? "0" : ""
      }${seconds}`;
    },
    typeVerifyCode() {
      switch (this.isStatusCode) {
        case TypeVerifyCode.pending:
          return this.$t("phone_number.add.code-sent");
          break;
        case TypeVerifyCode.resent:
          return this.$t("phone_number.add.resend");
          break;
        default:
          return this.$t("phone_number.add.get-code");
      }
    },
    dark() {
      return this.theme === "light-theme" ? "" : "-dark";
    },
  },
  created() {
    // this.$on("hideCommonModal", (modalName) => {
    //   this.resetParams();
    //   if (
    //     this.mode === "success" ||
    //     this.mode === "change" ||
    //     this.mode === "invalid"
    //   ) {
    //     this.$router.push({ name: "SettingsSecurity" });
    //   }
    //   if (modalName !== this.modalName) {
    //     return;
    //   }
    // });
  },
  watch: {
    type() {
      this.codeVerify = "";
      clearInterval(this.countdownInterval);
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.phone-modal {
  &-title {
    font-size: 34px;
    line-height: 40px;
    font-weight: 600;
    text-align: center;
    color: var(--dark-1);
    font-family: $font-inter-medium;
    margin-bottom: 40px;
  }
  &-body {
    &__title {
      font-size: 18px;
      line-height: 22px;
      color: var(--dark-1);
    }
    &__phone-input {
      margin-top: 8px;
      margin-bottom: 6px;
    }
    &__verification-code {
      margin-top: 8px;
      background-color: $color-alabaster;
      padding: 5.5px 6px 5.5px 15px;
      border-radius: 8px;

      &-input-verification {
        width: calc(100% - 99px - 12px);
        background-color: transparent;
        border: 0;
        font-size: 18px;
        line-height: 21px;

        &::placeholder {
          color: $color-gray-dark;
        }
      }

      &-text {
        background-color: transparent;
        font-size: 16px;
        padding: 8px;
        line-height: 19px;
      }

      &-add-btn {
        background: $color-gray-alto;
        padding: 9.5px 12px;
        border-radius: 4px;
        font-size: 16px;
        line-height: 19px;
      }
    }
    .error {
      border: 1px solid $text-color-red !important;
    }
    .error-msg {
      color: $text-color-red;
      margin-bottom: 16px;
      margin-top: 8px;
    }
    &__submit-btn {
      @include btn-common(yes);
      width: 100%;
      // background-color: #c1fff3;
      margin-top: 20px;
    }
    &__count-down-timer {
      font-size: 16px;
      line-height: 19px;
      color: $color-gray-dark;
    }
  }

  &-img {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
  }
}

.dark-theme {
  .phone-modal {
    &-body {
      &__verification-code {
        background-color: $color-dark;
        color: $color-white;

        &-add-btn {
          background-color: $color-gray-charade;
          color: $color-white;
        }
      }
    }
  }
}

#confirmationModal {
  z-index: 99999 !important;
}

#modal {
  ::v-deep {
    .modal-footer {
      display: none;
    }
  }
}

.message-wrapper {
  height: 19px;
}

@media screen and (max-width: 992px) {
  .phone-modal {
    &-title {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 20px;
    }
    &-body {
      &__title {
        font-size: 14px;
        line-height: 16px;
      }
      &__verification-code {
        &-input-verification {
          font-size: 14px;
          line-height: 16px;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin-right: 10px;
        }
      }
    }
  }

  #modal {
    ::v-deep {
      .modal-header {
        padding-bottom: 0;
      }
      .modal-body {
        padding-left: 16px;
        padding-right: 16px;
      }
    }
  }
}
</style>
