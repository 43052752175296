<template>
    <div class="box-table">
      <div id="container-tab" class="clearfix">
        <!-- <h2 class="market-title" v-if="!shortNameTab">
                  <i class="icon-24h"></i>
                  <span>{{ $t("common.spot_exchange") }}</span>
                  {{ $t("common.market_info") }}
              </h2> -->
        <div class="clearfix group_table_landing">
          <label v-for="item in marketTabMain" :key="item" @click="showTabMain(item)" :class="tabMain === item ? 'current-market' : ''">
            <i v-if="item === 'Favorites'" class="icon-star2"></i>
            {{ $t('landing.tab_' + item) }}
          </label>

          <router-link v-if="tabMain === 'Spot'" :to="`/market-overview?tabMain=${tabMain}&scroll=true`">
            <span class="more-btn">{{ $t('m_common.action.more') }}</span>
            <img src="@/assets/images/home/arrow-right.svg" alt="" />
          </router-link>
          <router-link v-else :to="`/market-overview?tabMain=${tabMain}&market=${selectedMarket}`">
            <span class="more-btn">{{ $t('m_common.action.more') }}</span>
            <img src="@/assets/images/home/arrow-right.svg" alt="" />
          </router-link>
        </div>
        
      </div>
  
      <div class="table-content">
        <template v-if="(showType === CONSTS.TYPE_FUTURE)">
          <div id="content">
            <market-future id="table-future"></market-future>
          </div>
        </template>
  
        <div id="content" v-else>
          <div id="content-future" v-show="tabMain === 'Futures'">
            <div class="container-tab">
              <label @click="showMarket(CONSTS.TYPE_USDT_CONTRACTS)" class="tab" :class="selectedMarket === CONSTS.TYPE_USDT_CONTRACTS ? 'active-tab item-favorite' : 'item-favorite' ">
                {{ $t('landing.future.usdt_contracts')}}
              </label>
              <label @click="showMarket(CONSTS.TYPE_USD_CONTRACTS)" class="tab" :class="selectedMarket === CONSTS.TYPE_USD_CONTRACTS ?'active-tab item-favorite' : 'item-favorite'">
                {{ $t('landing.future.usd_contracts')}}
              </label>
            </div>
            <market-coin
              :marketSelected="showType"
              :columns="columns"
              :coinNames="coinNames"
              @selectedPairMarket="onSelectedPair"
              :tabMain="tabMain"
              :isHomePage="true"
              :futuresTable="true"
              :subTabMain="selectedMarket"
              :key="selectedMarket"
            >
            </market-coin>
          </div>
          <div v-show="tabMain === 'Spot'">

            <market-coin
            :priceScope="priceScope"
            @selectedPairMarket="onSelectedPair"
            :tabMain="tabMain"
            :isHomePage="true"
            :key="selectedMarket"
          >
          </market-coin>
        </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import rf from "@/request/RequestFactory";
  import BigNumber from "bignumber.js";
  import MarketCoin from "@/pages/landing/MarketCoin.vue";
  import Const from "@/common/Const";
  import {mapState} from "vuex";
  import MarketFuture from "@/pages/landing/TabFuture.vue";
  import MarketPagination from "@/pages/landing/marketPagination";

  import Cookies from 'js-cookie'

  const TYPE_SPOT = 'Spot';
  const TYPE_FUTURE = "Future";
  const TYPE_USDT_CONTRACTS = 'USD_M';
  const TYPE_USD_CONTRACTS = "COIN_M";


  export default {
    components: {
      MarketCoin,
      MarketFuture,
      MarketPagination
    },
    props: {
      defaultMarket: { type: String, default: Const.DEFAULT_MARKET},
      columns: {
        type: [Array],
        default: () => {
          return [];
        },
      },
      shortNameTab: { type: Boolean, default: false },
      callback: { type: Function, required: false },
      isComingSoon : {
        type: Boolean,
        default: true,
      }
    },
    sockets: {
      connect: function () {
      },
    },
    data() {
      return {
        favorites: [],
        priceScope: {},
        pricesFilter: {},
        sortColumn: "coin",
        isSortDesc: false,
        isShowSearchField: false,
        markets: [],
        selectedMarket: "btc",
        tabFavorites: Const.FAVORITES_TAB,
        marketTabMain: Const.MARKET_TAB_HOME,
        showType: 'Spot',
        futureFilter: {},
        favoritesFuture: [],
        CONSTS: {
          ...Const,
          TYPE_SPOT: TYPE_SPOT,
          TYPE_FUTURE: TYPE_FUTURE,
          TYPE_USDT_CONTRACTS: TYPE_USDT_CONTRACTS,
          TYPE_USD_CONTRACTS: TYPE_USD_CONTRACTS,
        },
  
        tabMain: 'Spot',
        theme: Cookies.get("user-theme", { domain: process.env.VUE_APP_PUBLIC_URL }) || 'dark-theme',
        prices: []
        // coinNames: {},
      };
    },
    computed: {
      ...mapState({
        masterdata: (state) => state.masterdata,
        userThemeMode: state => state.userThemeMode
      }),
      getPairFavorites() {
        return window._.chain(this.favorites)
          .map((item) => item.coin_pair)
          .value();
      },
      coinNames() {
        const fullCoinNames = {};
  
        _.forEach(this.masterdata?.coins, (item, key) => {
          fullCoinNames[item.coin] = item.name;
        });
        return { ...fullCoinNames }.toString();
      },
    },
    watch: {
      userThemeMode(theme) {
          this.theme = theme;
        },


      masterdata() {
        // _.forEach(this.masterdata.coins, (item, key) => {
        //   this.coinNames[item.coin] = item.name;
        // });
        // this.coinNames = {...this.coinNames}
        this.markets = this.masterdata?.market_settings;
      },
     
    },
    methods: {
      onSelectedPair(pair) {
        if (this.callback) {
          return this.callback();
        }
        // Handle changed pair if need.
      },
  
      filterCoinByTab(item) {
        // Market coin tab
        let filterByTab = item.currency === this.selectedMarket;
  
        // Favorites tab
        if (this.tabFavorites === this.selectedMarket) {
          const pair = `${item.coin}/${item.currency}`;
          filterByTab = this.getPairFavorites.indexOf(pair) > -1;
        }
        return filterByTab;
      },
  
      toUpperCase(value) {
        if (!value) {
          return "";
        }
        return value.toUpperCase();
      },
  
      showMarket(market) {
        this.selectedMarket = market;
        // if (market === this.CONSTS.TYPE_USD_CONTRACTS || market === this.CONSTS.TYPE_USDT_CONTRACTS) {
        //   rf.getRequest("PriceRequest")
        //       .get24hFuture({'contractType': market})
        //       .then((res) => {
        //         this.futureData(res.data)
        //       })
        // }
        // this.getFavorites()
      },
      showTabMain(tabMain) {
        this.tabMain = tabMain;
        this.showMarket(tabMain)
        switch (tabMain) {
        case 'Spot':
          this.showMarket('btc');
          break;
        // case 'Favorites':
        //   this.showMarket('Favorites');
        //   break;
        case 'Futures':
          this.showMarket(this.CONSTS.TYPE_USDT_CONTRACTS);
          break;
        default:
          return '';
      }
      },
  
      getSocketEventHandlers() {
        return {
          PricesUpdated: this.onPricesUpdated,
          // OrderTransactionCreated: this.onOrderTransactionCreated,
        };
      },
  
      onPricesUpdated(newPrices) {
        this.prices = Object.assign({}, this.prices, newPrices);
  
        window._.each(this.priceScope, (item) => {
          const coinPrice = newPrices[item.currency + "_" + item.coin];
          if (!coinPrice) {
            return;
          }
  
          const latestPrice = new BigNumber(`${coinPrice.price || 0}`);
  
          if (latestPrice.gt(`${item.max_price || 0}`)) {
            item.max_price = coinPrice.price;
          }
          if (latestPrice.lt(`${item.min_price || 0}`)) {
            item.min_price = coinPrice.price;
          }
  
          if (item.current_price) {
            let result = latestPrice.sub(`${item.current_price || 0}`).toString();
            this.$set(item, "changePrice", result);
          }
  
          item.changed_percent = coinPrice.change;
          item.previous_price = coinPrice.previous_price;
          item.quoteVolume = coinPrice?.volume || 0;
          item.current_price = latestPrice.toString();
          // item.usdPrice = this.toUsdPrice(item.current_price, item.currency);
        });
      },
  
      onOrderTransactionCreated(data) {
        const pair = `${data.orderTransaction?.currency}_${data.orderTransaction?.coin}`;
        const priceScope = this.priceScope?.[pair];
        if (priceScope) {
          priceScope.volume = new BigNumber(`${priceScope.volume || 0}`).add(
            `${data.orderTransaction.quantity}`
          );
        }
      },
  
      toUsdPrice(price, fromCurrency) {
        return new BigNumber(price)
          .mul(new BigNumber(this.getPrice("usd_" + fromCurrency).price))
          .toString();
      },
  
      getPrice(pair) {
        return this.prices[pair] || { price: 1 };
      },
  
      calculateUsdPrice() {
        window._.forEach(this.priceScope, (item) => {
          item.usdPrice = this.toUsdPrice(item.current_price, item.currency);
        });
      },
  
      initData() {
        this.markets = this.masterdata?.market_settings;
        // this.getFavorites();
  
        rf.getRequest("PriceRequest")
          .getPrices()
          .then((res) => {
            this.onPricesUpdated(res.data);
          });
  
        rf.getRequest("PriceRequest")
          .getPrices24h()
          .then((res) => {
            // this.pricesScopeUpdated(res.data);
            // this.selectedMarket = this.getSelectTabViaRoute();
          });

        // return rf.getRequest("PriceRequest")
        //   .get24hFuture({'contractType': 'USD_M'})
        //   .then((res) => {
        //     this.futureData(res.data)
        //   })
      },
  
      getSelectTabViaRoute() {
        const query = this.$route.query || {};
        return query.tab || this.defaultMarket;
      },
  
      // getFavorites() {
      //   if(this.tabMain === 'Futures') {
      //     FavoritesManager.getFutureFavorites().then((data) => {
      //       this.favoritesFuture = data;
      //     });
      //   }
      //   else {
      //     FavoritesManager.getFavorites().then((data) => {
      //       this.favorites = data;
      //     });
      //   }
      // },
      // futureData(res) {
      //   const data =[]
      //   res.forEach(items => {
      //     data.push(
      //       {
      //         coin: items.symbol,
      //         current_price: items.lastPrice,
      //         min_price: items.lowPrice,
      //         max_price: items.highPrice,
      //         changed_percent: items.priceChangePercent,
      //         volume: items.volume
      //       }
      //     )
      //   })
      //   // this.futureFilter = data
      //   // this.$forceUpdate()
      // },


      pricesScopeUpdated(newPrices) {
        this.pricesFilter = this.priceScope = newPrices;
  
        this.calculateUsdPrice();
      },
    },
    mounted() {
      this.sockets.subscribe('tickers', (data) => {
        // this.futureData(data)
      });


    },
    created () {
      this.initData();
      if( this.selectedMarket === 'btc') {
        const usdMarketValue = rf.getRequest("PriceRequest")
            .getPrices24h({ coin: 'usd' , limit: 20 })
            .then((res) => {
              return res.data.data;
            })
        const usdtMarketValue = rf.getRequest("PriceRequest")
            .getPrices24h({ coin: 'usdt' , limit: 20  })
            .then((res) => {
              return res.data.data;
            })
        Promise.all([usdtMarketValue , usdMarketValue]).then(res => {
          this.priceScope =  Object.assign({}, ...res)
        })
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>
  @import "@/assets/sass/variables";
  .box-table .table-content {
    margin-top: 20px;
    min-height: 300px;
  }
  .block {
        display: block;
  }
      .none {
        display: none;
  }
  
  .clearfix {
    content: "";
    clear: both;
    display: table;
  }

  .dark-theme {
    .box-table #container-tab .group_table_landing{
      border-bottom: 1px solid $color-gray-charade;
    }
  }
  .box-table {
    #container-tab {
      
      display: block;
      margin: 0 auto;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none;
      &::-webkit-scrollbar {
          display: none;
        }
      .market-title {
        font-family: $font-family-roboto-semibold;
        font-size: $font-title-size-medium;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.14;
        letter-spacing: normal;
        text-align: center;
        color: $color-eden;
        margin-bottom: 57px;
        position: relative;
        span {
          font-family: $font-family-roboto-bold;
          position: relative;
          padding-left: 10px;
        }
        span.bold {
          font-weight: bold;
          padding-left: 0;
        }
        i {
          position: relative;
          top: 9px;
          font-size: 50px;
          display: inline-block;
        }
      }
      .group_table_landing {
        display: block;
        width: auto;
        margin: 0 0 40px 0;
        border-bottom: 1px solid $color-alabaster;

        a {
          float: right;
          font-size: 18px;
          line-height: 22px;
          color: var(--text-primary-color);
        }

        .more-btn {
          font-size: $font-big;
          color: var(--text-primary-color);
          opacity: 0.8;
          cursor: pointer;
          margin-right: 8px;
        }
      }
  
      .current-market {
        color: var(--text-primary-color);
      }
      .current-market::after {
        width: 100%;
        height: 4px;
        content: "";
        background: $color-caribbean-green;
        position: absolute;
        bottom: 0;
        left: 0;
        border-radius: 20px;
      }
     
  
      label {
        cursor: pointer;
        display: inline-block;
        float: none;
        padding: 0 0 10px 0;
        background: transparent;
        border: 0px;
        margin: 0px;
        font-family: $font-inter-regular;
        font-size: $font-big-20;
        letter-spacing: normal;
        color: var(--text-close);
        position: relative;
        margin-right: 40px;
        font-weight: 400;
        .icon-star2 {
          padding-top: 1px;
          display: block;
          width: 20px;
          height: 20px;
          float: left;
          margin-right: 5px;
          color: $color-yellow;
        }
        .icon-star2.star_color {
          color: $color-yellow;
        }
      }
      .active-tab{
        background: $color-caribbean-green !important;
        color: $color-grey-dark !important;
      }
  
      .item-favorite{
        background: var(--color-dark-4);
        color: var(--color-neutral-dark);
        font-size: 18px;
        max-height: 41px;
        padding: 10px 30px;
        border-radius: 8px;
        margin: 0;
        line-height: 22px;
        display: inline-block;
        margin-right: 16px;
      }
     
  
      input {
        &:checked + label {
          background: $color-white;
          color: $text-yellow;
          position: relative;
          z-index: 6;
          height: 100%;
          border-bottom: 0px solid $color-white;
        }
      }
  
      .search__coin {
        font-weight: 400;
        padding: 0px;
        margin-left: 15px;
        float: right;
        position: relative;
        .icon-search {
          position: absolute;
          top: 13px;
          right: 15px;
          font-size: 15px;
          color: #cfcfcf;
        }
        .input-search {
          width: 360px;
          height: 42px;
          border-radius: 8px;
          border: none;
          font-family: $font-inter-regular;
          font-size: $font-small;
          font-weight: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: var(--text-primary-color);
          padding: 10px;
          background: var(--color-tab-data-table);
          &:hover,
          &:active,
          &:focus {
            border-color: $color-green;
          }
  
          &::placeholder {
            color: var(--text-search);
            font-size: $font-big;
          }
        }
        .input_group_seach {
          cursor: pointer;
          float: left;
          &:before {
            content: "\e948";
            font-family: "icomoon" !important;
            font-size: $font-big;
            color: $color-grey;
            line-height: 20px;
            padding-top: 7px;
            height: 30px;
            width: 30px;
            display: block;
            text-align: center;
          }
        }
      }
  
      .container-tab {
        display: block;
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        margin-top: 30px;
        gap: 16px;
        white-space: nowrap;
  
        &::-webkit-scrollbar {
          display: none;
        }
  
        .active {
          background: $color-caribbean-green;
        }
      }
      .block {
        display: block;
      }
      .none {
        display: none;
      }
    }
    #content {
      padding-bottom: 25px;
      color: $color-grey-dark;
      position: relative;
      width: 100%;
      z-index: 5;
      border: 0px;
      border-top: none;
      overflow-y: auto;
      overflow-x: auto;
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
      &::-webkit-scrollbar-track {
        background-color: var(--color-tab-data-table);
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background: var(--bgr-btn);
      }
      div {
      }
      .is-muted {
        color: $color-grey;
      }

      .container-tab {
        display: block;
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        margin-bottom: 40px;
        gap: 16px;
        white-space: nowrap;

        &::-webkit-scrollbar {
          display: none;
        }

        .active {
          background: $color-caribbean-green;
        }
      }
      .active-tab{
        background: $color-caribbean-green !important;
        color: $color-grey-dark !important;
      }

      .item-favorite{
        cursor: pointer;
        background: var(--color-dark-4);
        color: var(--color-neutral-dark);
        font-size: 18px;
        font-weight: 400;
        font-family: $font-inter-regular;
        max-height: 41px;
        padding: 10px 30px;
        border-radius: 8px;
        margin: 0;
        line-height: 22px;
        display: inline-block;
        margin-right: 16px;
      }
    }
  
    #container-tab input {
      &#tab-favirotes:checked ~ #content #content-favirotes,
      &#tab-usd:checked ~ #content #content-usd,
      &#tab-btc:checked ~ #content #content-btc,
      &#tab-eth:checked ~ #content #content-eth {
        opacity: 1;
        z-index: 100;
        display: block;
      }
    }
  
    input.visible {
      visibility: visible !important;
    }
    .coming-soon {
      text-align: center;
      padding-top: 70px;
      p {
        color: var( --text-close);
        font-size: 16px;
        line-height: 19px;
        padding-top: 16px;
      }
    }
  }
  @media screen and (max-width: 992px) {
    .box-table {
      #container-tab {
        label {
          font-size: 16px;
        }
        .search__coin {
          margin: 16px 0 0;
          float: left;
          width: 100%;
          .input-search {
            width: 100%;
            height: 32px;
  
            &::placeholder {
              font-size: 14px;
            }
          }
  
          .icon-search{
            top: 7px;
          }
        }
        .container-tab{
          .item-favorite{
            font-size: 14px;
            line-height: 16px;
          }
        }
      }
    }
  }
    @media screen and (max-width: 768px) {}
    @media screen and (max-width: 576px) {}
  </style>
  