<template>
    <div class="landing-page clearfix">
      <!-- head home landing -->
      <div class="left_home_landing transitionSlide">
        <div v-if="sizeScreen > 730" class="header-bg">
          <img src="@/assets/images/home/background_landing-home.png"/>
        </div>
        <div v-if="sizeScreen > 730" class="coin-big-home position-absolute">
          <img src="@/assets/images/home/coin_big_home.png"/>
        </div>
        <div v-if="sizeScreen > 730" class="iphone-pro-max position-absolute">
          <img src="@/assets/images/home/ip14pro.svg"/>
        </div>
        <div class="landingslide landingfirstSlide">
          <div class="title-top">
            <h2 :class="'landtit_'+$t('i18n.lang')">
            <span v-html="$t('landing.banner.title.text')"></span>
            </h2>
            <p class="sub-title">{{ $t('landing.banner.title.sub') }}</p>
            <div v-if="isAuthenticated" class="links-home">
                <div class="group-link">
                  <router-link :to="{name: 'Market Overview'}">
                    <div class="link-item">
                      
                      <img :src="require(`@/assets/images/home/icon-link-markets${sizeScreen < 1200 ? '-mobile' : ''}.png`)"/>
                      {{ $t('landing.title_market') }}
                    </div>
                  </router-link>
                  <router-link to="/spot-exchange/basic">
                    <div class="link-item">
                      <img :src="require(`@/assets/images/home/icon-link-spot${sizeScreen < 1200 ? '-mobile' : ''}.png`)"/>
                     {{ $t('landing.title_spot') }}
                    </div>
                  </router-link>
                  <router-link :to="{name: 'Margin Exchange'} " >
                    <div class="link-item">
                      <img :src="require(`@/assets/images/home/icon-link-future${sizeScreen < 1200 ? '-mobile' : ''}.png`)"/>
                    {{ $t('landing.title_future') }}
                    </div>
                  </router-link>
                </div>
            </div>
            <div v-else class="tool">
              <p>
                  <router-link
                  :to="{name: 'Register', query: {destination: $route.name}}"
                  >
                  <button>
                    <img src="@/assets/images/home/icon-people.svg" />
                  {{$t('landing.signup')}}
                  </button>
                 
                  </router-link>
              </p>
              <p class="sub-tool">{{ $t('landing.sub.button') }}</p>

              <p>
                  <router-link
                  :to="{name: 'Login', query: {destination: $route.name}}"
                 
                  >
                  <button   class="tool_login">    {{$t('landing.log_in')}}</button>
                  <!-- to="/margin-exchange?symbol=BTCUSD" -->
              
                  </router-link>
              </p>
            </div>  
          </div>
            <div  v-if="sizeScreen < 730" class="header-bg-mobile">
              <img class="img-mobile-decor" src="@/assets/images/home/img-mobile-decor.png" />
          </div>
          </div>
          <!-- <div class="socical-network">
            <a v-for="(socicalNetwork, index) in socicalNetworks" :key="index" :href="socicalNetwork.link" :title="socicalNetwork.name" target="_blank" class="icon-social">
              <i :class="socicalNetwork.icon_class"></i>
            </a>
          </div> -->
        
      </div>
<!--      <div id="pingpongchat" @click="openChatWidget()"><img src="@/assets/images/icon/icon-chat.svg" ></div>-->
      <div id="content-home-markets">
        <!-- <div class="freshdesh">
          <freshdesh-f-a-q></freshdesh-f-a-q>
        </div> -->

        <markets-main id="markets_table_landing" visibleCoinName></markets-main>
      </div>
  
     
  
      <div class="clearfix"></div>
      
      <!-- <current-rate></current-rate> -->
     
      <div class="banner-big-group">
        <div class="banner-content">
         
          <div class="group-content-info">
             <div class="container-info row">
                <div class="info-left col-xs-12 col-md-6 col-xl-6 col-sm-12">
                    <h3>{{ $t('landing.info_title') }}</h3>

                    <div class="group-info">
                        <img src="@/assets/images/home/icon_High.png" />

                        <div class="text-info">
                            <h4>{{ $t('landing.info_title_sub_high') }}</h4>
                            <p>{{ $t('landing.info_content_high') }}</p>
                        </div>
                    </div>

                    <div class="group-info">
                        <img src="@/assets/images/home/icon_Interface.png" />

                        <div class="text-info">
                            <h4>{{ $t('landing.info_title_sub_inter') }}</h4>
                            <p>{{ $t('landing.info_content_inter') }}</p>
                        </div>
                    </div>

                    <div class="group-info">
                        <img src="@/assets/images/home/icon_chat.png" />

                        <div class="text-info">
                            <h4>{{ $t('landing.info_title_sub_chat') }}</h4>
                            <p>{{ $t('landing.info_content_chat') }}</p>
                        </div>
                    </div>
                </div>
                <div class="info-right col-xs-12 col-md-6 col-xl-6 col-sm-12">
                  
                    <img class="img-iphone" :src="require(`@/assets/images/home/bg-iphone-monas.png`)" />
                </div>
             </div>
          </div>
          <div class="group-content-item">
            <div class="container-info row">
              <router-link :to="{name: 'Market Overview'}" class="col-xs-12 col-md-6 col-xl-4 col-sm-12 link-content">
                <div class="content-item group-info content-link">
                  <img :src="require(`@/assets/images/home/icon-markets${dark}.png`)"/>
                  <div class="text-info">
                            <h4>{{ $t('landing.title_market') }}</h4>
                            <p>{{ $t('landing.content_market') }}</p>
                        </div>
                </div>
              </router-link>
              <router-link to="/spot-exchange/basic" class="col-xs-12 col-md-6 col-xl-4 col-sm-12 link-content">
                <div class="content-item group-info content-link content-spot">
                  <img :src="require(`@/assets/images/home/icon-spot${dark}.png`)"/>
                  <div class="text-info">
                            <h4>{{ $t('landing.title_spot') }}</h4>
                            <p>{{ $t('landing.content_spot') }}</p>
                        </div>
                </div>
              </router-link>
              <router-link :to="{name: 'Margin Exchange'}" class="col-xs-12 col-md-6 col-xl-4 col-sm-12 link-content">
                <div class="content-item group-info content-link content-spot">
                  <img :src="require(`@/assets/images/home/icon-futures${dark}.png`)"/>
                  <div class="text-info">
                            <h4>{{ $t('landing.title_future') }}</h4>
                            <p>{{ $t('landing.content_future') }}</p>
                        </div>
                </div>
              </router-link>
<!--                <div class="content-item content-saving group-info col-xs-12 col-md-6 col-xl-4 col-sm-12">-->
<!--                  <img :src="require(`@/assets/images/home/icon-saving${dark}.png`)"/>-->
<!--                  <div class="text-info">-->
<!--                            <h4>{{ $t('landing.title_saving') }}</h4>-->
<!--                            <p>{{ $t('landing.content_saving') }}</p>-->
<!--                        </div>-->
<!--                </div>-->
<!--                <div class="content-item content-saving group-info col-xs-12 col-md-6 col-xl-4 col-sm-12">-->
<!--                  <img :src="require(`@/assets/images/home/icon-staking${dark}.png`)"/>-->
<!--                  <div class="text-info">-->
<!--                            <h4>{{ $t('landing.title_staking') }}</h4>-->
<!--                            <p>{{ $t('landing.content_staking') }}</p>-->
<!--                        </div>-->
<!--                </div>-->
<!--                <div class="content-item content-saving group-info col-xs-12 col-md-6 col-xl-4 col-sm-12">-->
<!--                  <img :src="require(`@/assets/images/home/icon-copy${dark}.png`)"/>-->
<!--                  <div class="text-info">-->
<!--                            <h4>{{ $t('landing.title_copy') }}</h4>-->
<!--                            <p>{{ $t('landing.content_copy') }}</p>-->
<!--                        </div>-->
<!--                </div>-->
                
            </div>
          </div>
          <div class="group-content-info group-device">
            <div class="container-info row">
              <div class="info-left col-xs-12 col-md-12 col-xl-6 col-sm-12">
                  <ul>
                    <li>
                      <img :src="require(`@/assets/images/home/icon-check${dark}.svg`)"/>{{ $t('landing.sub_info_1') }}
                    </li>
                    <li><img :src="require(`@/assets/images/home/icon-check${dark}.svg`)"/>{{ $t('landing.sub_info_2') }}</li>
                    <li><img :src="require(`@/assets/images/home/icon-check${dark}.svg`)"/>{{ $t('landing.sub_info_3') }}</li>
                  </ul>
                  <div class="install-app">
                    <div class="install-ios">
                      <QrcodeVue
                        :value="siteSetting.ios_app_url"
                        render-as='svg'
                        :size="60"
                      />
                      <div class="install-text">
                        <h4>{{ $t('install_ios') }}</h4>
                        <span>{{ $t('install_text') }}</span>
                      </div>
                    </div>
                    <div class="install-ios">
                      <QrcodeVue
                      :value="siteSetting.android_app_url"
                        render-as='svg'
                        :size="60"
                      />
                      <div class="install-text">
                        <h4>{{ $t('install_android') }}</h4>
                        <span>{{ $t('install_text') }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="app-link row">
                    <a  :href="siteSetting.android_app_url" target="_blank" class="col-4 ">
                      
                      <div class="col-app">
                        <div class="icon-google"></div>
                        <p> {{ $t('app_google') }}</p>
                      </div>
                    </a>
                    
                    <a :href="siteSetting.ios_app_url" target="_blank" class="col-4 ">
                      <div class="col-app">
                        <!-- <img class="opacity-5" :src="require(`@/assets/images/home/icon-apple${dark}.svg`)" alt=""> -->
                        <div class="icon-app-store"></div>
                        <p>{{ $t('app_apple') }}</p>
                      </div>
                    </a>  

                    <a :href="siteSetting.app_link" target="_blank" class="col-4 ">
                    <div class="col-app">
                      <!-- <img class="opacity-5" :src="require(`@/assets/images/home/icon-android${dark}.svg`)" alt=""> -->
                      <div class="icon-android-apk"></div>
                      <p> {{ $t('app_android') }}</p>
                    </div>
                    </a>
                    <a class="col-4" target="_blank" @click="gotoAPIDocs()" >
                      <div class="col-app">
                      <img class="opacity-5" :src="require(`@/assets/images/home/icon-api${dark}.svg`)" alt="">
                      <!-- <div class="icon-api"></div> -->
                      <p>{{ $t('app_api') }}</p>
                    </div>
                    </a>
                   
                    <div class="col-4 col-app col-text">
                      <img :src="require(`@/assets/images/home/icon-mac${dark}.svg`)" alt="">
                      <p>{{ $t('app_mac') }}</p>
                      
                    </div>
                    <div class="col-4 col-app col-text">
                      <img :src="require(`@/assets/images/home/icon-window${dark}.svg`)" alt="">
                      <p> {{ $t('app_win') }}</p>
                     
                    </div>
                  </div>
              </div>
              <div class="info-right col-xs-12 col-md-12 col-xl-6 col-sm-12">
                    <img class="img-device" :src="require(`@/assets/images/home/img-device${sizeScreen > 992 ? '' : '-mobile'}.png`)" />
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
    import rf from '@/request/RequestFactory'
    import CookiesUtils from '@/common/CookiesUtils';
    import Const from '@/common/Const';
    import MarketFooter from '@/components/shared_components/common/MarketFooter.vue';
    import CoinChart from '../pages/landing/CoinChart.vue';
    import BigNumber from 'bignumber.js';
    import MarketsMain from '@/components/home_pages/MarketMain.vue';
    import CurrentRate from '../pages/landing/CurrentRate.vue';
    import moment from 'moment';
    import AuthenticationUtils from '@/common/AuthenticationUtils';
    import TSlide from '@/components/Slide.vue';
    import {mapState} from 'vuex';
    import QrcodeVue from "qrcode.vue";
    import FreshdeshFAQ from "@/pages/freshdeshFAQ";

    const CHART_COLORS = ['#e8d579', '#94bcea', '#f2bd8e', '#82e0c9', '#d9d9f1'];
  
    export default {
      components: {
        FreshdeshFAQ,
        CoinChart,
        MarketFooter,
        MarketsMain,
        CurrentRate,
        QrcodeVue,
        TSlide
      },
      data() {
        return {
          currenSlide: 0,
          prices: {},
          top24hBtcCoins: [],
          pricesScope: {},
          idxColor: 0,
          currency: this.$route.query.currency || Const.DEFAULT_CURRENCY,
          coin: this.$route.query.coin || Const.DEFAULT_COIN,
          isAuthenticated: window.isAuthenticated,
          swiperOption: {
            slidesPerView: 4,
            spaceBetween: 24,
            initialSlide: 0,
            direction: 'horizontal',
            autoplay: {
              delay: 5000,
              stopOnLastSlide: false,
              disableOnInteraction: true
            },
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
          },
          banners: [],
          apiUrl: process.env.VUE_APP_STORAGE_URL,
          siteSetting: {},
          socicalNetworks: [],
          coinImages: [],
  
          insurance: {},
          sizeScreen: window.innerWidth,
        }
      },
      computed: {
        ...mapState({
          masterdata: state => state.masterdata,
          theme: state => state.userThemeMode,
        }),
        visibleCoinChart() {
          if (window._.isEmpty(this.top24hBtcCoins) || window._.isEmpty(this.pricesScope)) {
            return false;
          }
          setTimeout(function () {
            $(".carousel").carousel({
              activate: function () { },
              timerAnimSlide: 400,
              itensDisplay: 4,
              spaceBetweenItens: 20,
              itensMove: 1,
              breakPoint: [
                { width: 800, itensDisplay: 3 },
                { width: 580, itensDisplay: 1 },
                { width: 700, itensDisplay: 2 }
              ],
              responsive: true
            });
          }, 100)
          return true;
        },
        localeLang() {
          let lang = this.$store.state.localLang;
          if (typeof lang === 'object') {
            lang = window.localStorage.locale;
          }
          if (lang === 'ko') {
            lang = 'en';
          }
          if (lang === 'ko') {
            lang = 'en';
          }
          // return lang;
          let obj = {
            lang: lang,
            en: 'en',
          }
          return obj;
        },
        dark() {
          return this.theme === 'light-theme'? '': '-dark';
        }
      },
      methods: {
        getImages() {
          return this.coinImages;
        },
        getPrice(pair) {
          return this.prices[pair] || { price: 0 };
        },
        checkShowByLocale() {
          return AuthenticationUtils.getLocale() !== 'en';
        },
        getChartColor() {
          if (this.top24hBtcCoins && this.idxColor > this.top24hBtcCoins.length) {
            return;
          }
          const index = this.idxColor % 5;
          this.idxColor++;
          return CHART_COLORS[index];
        },
  
        getPriceScope(currency, coin) {
          return window._.filter(this.pricesScope, (priceScope) => {
            return priceScope.currency === currency && priceScope.coin === coin;
          })[0];
        },
  
        toUsdPrice(price, fromCurrency) {
          return new BigNumber(price).mul(new BigNumber(this.getPrice('usd_' + fromCurrency).price)).toString();
        },
  
        pricesScopeUpdated(newPrices) {
          this.pricesScope = newPrices;
  
          this.calculateUsdPrice();
        },
        onPricesUpdated(newPrices) {
          this.prices = Object.assign({}, this.prices, newPrices);
          this.calculateUsdPrice();
          this.top24hBtcCoins = this.getTop24hBtcCoins();
        },
  
        calculateUsdPrice() {
          window._.forEach(this.pricesScope, (item) => {
            item.usdPrice = this.toUsdPrice(item.current_price, item.currency);
          });
        },
  
        getTop24hBtcCoins() {
          let dataReturn = window._.chain(this.prices)
            .filter(item => item.currency === 'btc')
            .orderBy(['volume'], ['desc'])
            .map(item => item.coin)
            .take(4)
            .value();
          return dataReturn;
        },
  
        gotoUrl(url) {
          window.location.href = url;
        },
  
        gotoAPIDocs() {
        let url = process.env.VUE_APP_API_URL + "/api/documentation";
        window.open(url)
      },
        getInsuranceFund() {
          return rf.getRequest("InsuranceRequest")
            .getInsuranceFund()
            .then(res => {
              this.insurance = res.data;
            })
            .catch(error => {
              console.log(error);
            });
        },
  
        initData() {
  
          rf.getRequest('PriceRequest').getPrices().then(res => {
            this.onPricesUpdated(res.data);
          });
  
          rf.getRequest('PriceRequest').getPrices24h().then(res => {
            this.pricesScopeUpdated(res.data);
          });
  
          rf.getRequest('NoticeRequest').getBannerNotices().then(res => {
            this.banners = res.data;
          });
          
          _.forEach(this.masterdata?.coins, (item, key) => {
            this.coinImages[item.coin] = item.icon_image;
          });
          this.coinImages['usd'] = `@/assets/images/color_coins/usd.png`;
          this.getInsuranceFund();
        },

        setCookiesReferralCode() {
          const referralCode = this.$route.query[Const.REFERRAL_KEY] || null;
          if (referralCode) {
            CookiesUtils.saveItem(Const.REFERRAL_KEY, referralCode);
          }
        },
  
        doSlide() {
          if (this.currenSlide >= 3) {
            this.currenSlide = 0;
          }
          else {
            this.currenSlide = this.currenSlide + 1;
          }
        },
  
        goSlide(slide) {
          clearInterval(slideTimer);
          this.currenSlide = slide;
          slideTimer = setInterval(function () {
            window["landingCpn"].doSlide();
          }, 5000);
        },
  
        parseBannerDate(str) {
          if (str !== null && str.toString() !== "") {
            var date = new Date(str);
            return moment(String(date)).format('DD / MM / YYYY');
          }
          else {
            return str;
          }
        },
        convertSiteSetting(data) {
          window._.each(data, item => {
            this.siteSetting[item.key] = item.value;
          });
        },

        getMasterdata() {
          this.convertSiteSetting(this.masterdata?.settings);
          this.socicalNetworks = window._.filter(this.masterdata?.social_networks, item => item.is_active) || {};

        },
        resizeHandler() {
          this.sizeScreen = window.innerWidth;
        },
      },
      watch: {
        masterdata() {
          this.getMasterdata()
        }
      },
      mounted() {
        this.getMasterdata();
        if (this.$route.params.isChangedPassword === true || this.$route.query.isChangedPassword) {
          Message.success(window.i18n.t('change_password_form.successful_message'), {}, { position: 'bottom_left' });
          AuthenticationUtils.removeAuthenticationData();
          this.isAuthenticated = false;
          window.app.$broadcast('CHANGED_PASSWORD');
        }
        this.setCookiesReferralCode();
        this.initData();
  
        // Handle 'window.GlobalSocket.notifyPricesUpdate'
        this.$on('PricesUpdated', data => {
          this.onPricesUpdated(data);
        });
  
        let recaptchaScript = document.createElement('script')
        recaptchaScript.setAttribute('src', '/tradingview/charting_library/static/js/external/carousel.js')
        document.head.appendChild(recaptchaScript);
        window.addEventListener("resize", this.resizeHandler);
      },
      created() {
        window["landingCpn"] = this;
       this.getMasterdata();
      }
    }
  
    var slideTimer;
  </script>
  
  <style lang="scss" scoped>
    @import "@/assets/sass/variables";
    @import "@/assets/sass/slide/slide";
    @import "@/assets/sass/slide/TransitionSlide";
  
    $bland_w_1200: 1200px;
    $wain_btn_color: #C30B57;
    $wain_bg: 1px solid rgba(255, 255, 255, 0.7);

    .dark-theme{
      .icon-google {
        background-color: #ffffff;
        &:hover {
          background-color: #999999;
        }
      }
      .icon-app-store {
        background-color: #ffffff;
        &:hover {
          background-color: #999999;
        }
      }
      .icon-android-apk {
        background-color: #ffffff;
        &:hover {
          background-color: #999999;
        }
      }
      .icon-api {
        background-color: #ffffff;
        &:hover {
          background-color: #999999;
        }
      }
      .opacity-5 {
        &:hover {
          opacity: 0.6;
        }
      }
    }

    #content-home-markets ::v-deep {
      .item-tab .table-landing table td {
        font-size: 14px;
      }
    }

    .iphone-pro-max {
      right: 13vw;
      bottom: 120px;
      width: 25vw;
    }
    .coin-big-home {
      right: 24vw;
      bottom: 510px;
      width: 20vw;
    }

    @media screen and (max-width: 1191px) {
      .iphone-pro-max {
        right: 25vw;
        bottom: 15px;
        width: 25vw;
      }
      .coin-big-home {
        right: 27vw;
        bottom: 520px;
      }
    }

    #content-home-markets{
      padding-top: 120px;
      background: var(--background-home-page);
      .freshdesh {
        margin: 0 auto 80px;
        max-width: 1440px;
      }
    }

    .img-mobile-decor {
      width: 375px; 

      @media screen and (max-width: 576px) {
        height: 500px;
      }
      @media screen and (max-width: 376px) {
        width: auto !important; 
      }
    }

    .img-iphone {
      width: 525px;
      height: 660px;
      @media screen and (max-width: 768px) {
        width: 327px;
        height: 411px;
      }
    }

    .icon-google {
      width: 38px;
      height: 38px;
      background-color: #333333;
      -webkit-mask-image: url('@/assets/images/home/icon-google-play.svg');
      mask-image: url('@/assets/images/home/icon-google-play.svg');
      -webkit-mask-repeat: no-repeat;
      &:hover {
        background-color: #666666;
      }
    }

    .icon-app-store {
      width: 38px;
      height: 38px;
      background-color: #333333;
      -webkit-mask-image: url('@/assets/images/home/icon-apple.svg');
      mask-image: url('@/assets/images/home/icon-apple.svg');
      -webkit-mask-repeat: no-repeat;
      &:hover {
        background-color: #666666;
      }
    }

    .icon-android-apk {
      width: 38px;
      height: 38px;
      background-color: #333333;
      -webkit-mask-image: url('@/assets/images/home/icon-android.svg');
      mask-image: url('@/assets/images/home/icon-android.svg');
      -webkit-mask-repeat: no-repeat;
      &:hover {
        background-color: #666666;
      }
    }

    .icon-api {
      width: 38px;
      height: 38px;
      background-color: #333333;
      -webkit-mask-image: url('@/assets/images/home/icon-api.svg');
      mask-image: url('@/assets/images/home/icon-api.svg');
      -webkit-mask-repeat: no-repeat;
      &:hover {
        background-color: #666666;
      }
    }

    .opacity-5 {
      &:hover {
        opacity: 0.8;
      }
    }

    .img-device {
      width: 680px;
      @media screen and (max-width: 768px) {
        width: 327px;
      }
      
    }
  
    .fade-enter-active,
    .fade-leave-active {
      transition: opacity .5s
    }
  
    .fade-enter,
    .fade-leave-to {
      opacity: 0
    }
  
    .banner-big-group {
      float: left;
      width: 100%;
      height: auto;
      /* height: 1950px;
      background-image: linear-gradient(to bottom, #003f6b, #2dac91 50%, #12575f); */
    }
  
   
    .banner-big-group .banner-content {
      background-color: var(--background-color-primary);
      .group-content {
        width: 100%;
        height: 790px;
        display: flex;
        flex-direction: row;
        overflow: hidden;
        box-sizing: border-box;
        background: url('@/assets/images/landing/landingbg.png') repeat-x;
        background-size: 100% auto;
        background-position: top left;
        background-color: #CECFCF;
        z-index: 0;
        position: relative;
        .center-bg {
          position: absolute;
          top: 0px;
          left: 50%;
          transform: translateX(-50%);
          width: 280px;
          height: 100%;
          z-index: 2;
          background: url('@/assets/images/landing/bnleft-bg-1.png') no-repeat;
          background-size: 100% 100%;
        }
        .left-bg {
          position: absolute;
          top: 0px;
          left: 0px;
          width: calc(50% - 130px);
          height: 790px;
          background-image: url('@/assets/images/landing/bnleft-bg-2.png');
          background-repeat: repeat-x;
          background-size: auto 100%;
          z-index: 1;
          /* position: relative; */
        }
        .group {
          width: 1100px;
          height: 790px;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          z-index: 3;
          .left-banner-40,
          .right-banner-40 {
            z-index: 3;
            position: relative;
            padding: 0px;
            a.title {
              width: 100%;
              text-align: left;
              .tit {
                font-size: 64px;
              }
              .subtit {
                font-size: 32px;
                display: inline-block;
                width: 500px;
              }
            }
            .desc {
              font-size: 19px;
              opacity: 1;
            }
            a.button {
              float: left;
              background-color: $wain_btn_color;
              color: #fff;
              border: $wain_bg;
              border-radius: 0px;
              &:hover,
              &:active,
              &:focus {
                transition: all .5s;
                background-color: #fff;
                color: $wain_btn_color;
                outline: none;
              }
            }
          }
          .left-banner-40 {
            width: 50%;
            .desc {
              float: left;
              text-align: left;
            }
          }
          .right-banner,
          .left-banner-1,
          .right-banner-2,
          .left-banner-2 {
            position: relative;
            z-index: 3;
          }
  
          /* banner1 text */
          .left-banner-txt {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }
          .right-banner-txt {
            position: absolute;
            top: 50%;
            width: 41%;
            transform: translateY(-50%);
            right: 0px;
          }
          /* right banner img */
          .right-banner {
            position: absolute;
            width: 54%;
            height: 50%;
            right: 0px;
            top: 50%;
            transform: translateY(-50%);
            img {
              height: 100%;
              width: 100%;
            }
          }
  
          /* btc banner2 img */
          .left-banner-1 {
            width: 50%;
            height: 70%;
            padding: 0px 0px 0px;
            /* text-align: center; */
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            img {
              height: 100%;
              width: 100%;
            }
          }
  
  
  
          .left-banner3-txt {
            /* padding: 170px 0px 0px 0px; */
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }
  
  
  
          /* 火箭 */
          .right-banner-2 {
            position: absolute;
            bottom: 0px;
            right: 0%;
            z-index: 1;
            width: 100%;
            height: 100%;
            padding: 10px 0px 0px;
            text-align: center;
            img {
              height: 100%;
              width: 100%;
            }
          }
          /* last banner */
          .left-banner-2 {
            width: 54%;
            height: 52%;
            position: absolute;
            left: 0px;
            top: 52%;
            transform: translateY(-50%);
            clear: both;
            img {
              height: 100%;
              width: 100%;
            }
          }
          /* last banner text */
          .right-banner4-txt {
            /* width: 36%; */
            .desc {
              font-weight: 600;
            }
            /* padding-left: 130px; */
          }
        }
        .blue-left,
        .white-right {
          width: calc(50% - 550px);
          z-index: 2;
        }
        .blue-left {
          background-color: #12337D;
          background: url('@/assets/images/landing/bnleft-bg-2.png') repeat-x;
          background-size: auto 100%;
        }
        .white-right {
          background-color: #D2D2D2;
          // background: url('@/assets/images/landing/bnleft-bg-3.png') repeat-x;
          background-size: auto 100%;
        }
        .reverse {
          .center-bg {
            transform: rotateX(180deg);
            background-color: #CECFCF;
          }
        }
  
        .links {
          /* float: left; */
          text-align: left;
        }
      }
      .group-reverse {
        .left-bg {
          right: 0px;
          left: unset;
        }
        background-image: none;
        .center-bg {
          right: 50%;
          transform: translateX(-50%) rotateY(180deg);
        }
        
      }
      .container-info {
            margin: 0px auto;
            padding: 76px 0px 50px;
            max-width: 1440px;
        }
      .group-content-info {
        background: var(--bg-linear-home);

       
        .info-left {
            padding-top: 90px;
            h3 {
                font-size: 54px;
                line-height: 65px;
                font-family: $font-inter-bold;
                margin-bottom: 50px;
                color: var(--text-primary-color);
            }
            ul {
              li {
                font-size: 24px;
                line-height: 29px;
                font-family: $font-inter-bold;
                padding-bottom: 18px;
                padding-left: 5px;
                color: var(--text-primary-color);

                img {
                  margin-right: 15px;
                }
              }
            }
        }
        
        .info-right {
            text-align: right;
            img {
              cursor: default;
            }
            
        }
      }
      .t-down {
        color: $color-caribbean-green;
        font-size: 24px;
        line-height: 29px;
        font-family: $font-inter-bold;
        margin-bottom: 8px;
      }
      .t-device {
        color: var(--color-percent);
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 24px;
      }
     
      .app-link {
        padding-top: 24px;
     
        max-width: 315px;
        a {
          img {
            cursor: pointer !important;
          }
        }        
        .col-app {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          padding: 0 0 30px;

          p {
            margin: 15px 0 0;
            color: var(--color-percent);
          }

          img {
            height: 38px;
            
          }

          &:last-child, &:nth-child(5) {
            img {
              cursor: default;
            }
          }
          
        }
        .col-text {
            p {
              color: var(--text-disable)
            }
          }

      }
      .group-info {
            display: flex;
            max-width: 664px;
            gap: 24px;
            padding-bottom: 40px;
            img {
              width: 80px;
              height: 80px;
              cursor: default;
            }
            h4 {
                font-size: 24px;
                line-height: 29px;
                color: var(--color-text-content);
                font-family: $font-inter-bold;
            }
            p {
                color: var(--color-percent);
                font-size: 18px;
                line-height: 21px;
            }
        }
      .group-content-item {
        background-color: var(--background-home-page);

        .content-item {
          padding-bottom: 70px;
          gap: 0px;
           img {
            width: 147px;
            height: 125px;
           }
        }
        .container-info {
          padding-top: 137px;
        }
        .content-link {
          img {
            cursor: pointer;
          }
        }
      }
      .group-device {
        background: var(--background-home-page);

        .container-info {
          padding: 50px 0 150px;

          .info-left {
            padding-top: 0;
          }
          .install-app {
            display: flex;
            gap: 30px;
            padding-bottom: 30px;
            border-bottom: 2px solid var(--color-tab-data-table);
            max-width: 500px;

            .install-ios {
              display: flex;
              gap: 20px;
              align-items: center;

              h4 {
                font-size: 18px;
                line-height: 21px;
                color: $color-caribbean-green;
                margin: 0 0 8px 0;
                font-family: $font-inter-bold;
              }
              span {
                font-size: 14px;
                line-height: 16px;
                color: $color-storm-grey;
              }
            }
          }
        }
      }
    }
  
    .banner-store {
      float: left;
      width: 100%;
      min-height: 600px;
      /* background-color: $color-eden; */
      position: relative;
    }
  
    .banner-store .banner-content {
      float: left;
      width: 100%;
      padding: 113px 0px 0px;
    }
  
    .banner-store .left-banner-60 {
      float: left;
      width: 50%;
      position: relative;
      padding-right: 50px;
    }
  
    .banner-store .left-banner-60 img {
      float: right;
    }
  
    .banner-store .left-banner-40 {
      float: left;
      width: 50%;
    }
  
    .banner-store .left-banner-40 .icon {
      width: 50px;
      height: 50px;
      display: block;
      span {
        font-size: $font-title-size-big-small;
        color: $color-jungle-green;
      }
    }
  
    .banner-store .left-banner-40 a.title {
      float: left;
      width: 367px;
      margin-top: 30px;
      font-family: $font-family-roboto;
      font-size: $font-title-size-medium;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.14;
      letter-spacing: normal;
      color: $color-white;
      span {
        font-weight: bold;
      }
    }
  
    .banner-store .left-banner-40 .desc {
      clear: both;
      float: left;
      margin-top: 20px;
      width: 300px;
      font-family: $font-family-roboto;
      font-size: $font-medium;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.67;
      letter-spacing: normal;
      color: $color-alto;
    }
  
    .banner-store .left-banner-40 .links {
      float: left;
      width: 100%;
      margin-top: 35px;
    }
  
    .banner-store .left-banner-40 .links a {
      margin-right: 10px;
      &:hover,
      &:active,
      &:focus {
        outline: none;
        img {
          opacity: 1;
        }
      }
      img {
        opacity: 0.8;
        &:hover {
          opacity: 1;
        }
      }
    }
  
    .banner-store .slides {
      float: left;
      margin-top: 70px;
      position: absolute;
      right: 44%;
      z-index: 99;
      bottom: 70px;
    }
  
    .banner-store .slides span {
      float: left;
      border-radius: 50%;
      margin-right: 10px;
      width: 6px;
      height: 6px;
      opacity: 0.4;
      background-color: #d8d8d8;
      cursor: pointer;
    }
  
    .banner-store .slides span.active {
      width: 15px;
      border-radius: 3px;
      background-color: $color-jungle-green;
      opacity: 1;
    }
  
    .banner-announcements {
      width: 100%;
      float: left;
      background-color: $color-white;
      padding: 70px 27px 80px 27px;
      .banner-content {
        .content-1 {
          .item-group-1 {
            img {
              height: 250px;
            }
            .text {
              width: 100%;
              >a {
                display: block;
                white-space: inherit;
                text-overflow: inherit;
                overflow: hidden;
                letter-spacing: initial;
                word-break: break-word;
              }
            }
          }
        }
      }
    }
  
    .banner-announcements h2 {
      font-family: $font-family-roboto;
      font-size: $font-title-size-medium;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.14;
      letter-spacing: normal;
      text-align: center;
      color: $color-eden;
    }
  
    .banner-announcements .banner-content {
      float: left;
      width: 100%;
      margin-top: 30px;
    }
  
    .banner-announcements .banner-content .content-1 {
      float: left;
      width: 100%;
      margin-bottom: 110px;
    }
  
    .banner-announcements .banner-content .item-group-1 {
      float: left;
      width: 25%;
      padding-right: 13px;
      padding-left: 13px;
    }
  
    .banner-announcements .banner-content .item-group-1 img {
      width: 100%;
    }
  
    .banner-announcements .banner-content .item-group-1 .date-info {
      display: block;
      font-family: $font-family-roboto;
      font-size: $font-root;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      color: $color-grey;
      margin: 10px 5px;
    }
  
    .banner-announcements .banner-content .item-group-1 .text {
      font-family: $font-family-roboto-medium;
      font-size: $font-medium;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.53;
      letter-spacing: normal;
      line-height: 23px; // width: 250px;
    }
  
    .banner-announcements .banner-content .item-group-1 .text a {
      color: $color-grey-dark;
      font-weight: 500;
      font-family: $font-family-roboto-medium;
    }
  
    .banner-announcements .banner-content .item-group-1 .text a:hover {
      color: $color-jungle-green;
    }
  
    .banner-announcements .banner-content .content-2 {
      float: left;
      width: 100%;
    }
  
    .banner-announcements .banner-content .content-2 .item-group-2 {
      float: left;
      width: 50%;
      padding: 0 13px;
    }
  
    .banner-announcements .banner-content .content-2 .item-group-2 .item-content {
      padding: 40px 0px 40px 50px;
      float: left;
      width: 100%;
      background-color: #f2f2f2;
      min-height: 250px;
    }
  
    .banner-announcements .banner-content .content-2 .item-group-2 .item-content .bg-1 {
      float: left;
      width: 100%;
      background: url(@/assets/images/landing/api.png);
      background-position: 100% 0px;
      background-repeat: no-repeat;
      min-height: 180px;
      .title {
        a {
          color: $color-eden !important;
        }
      }
    }
  
    .banner-announcements .banner-content .content-2 .item-group-2 .item-content .bg-2 {
      float: left;
      width: 100%;
      background: url(@/assets/images/landing/trezor1.png);
      background-position: 100% 0px;
      background-repeat: no-repeat;
      background-size: 159px;
      min-height: 180px;
      .title {
        a {
          color: $color-eden !important;
        }
      }
      .desc {
        width: calc(100% - 190px);
      }
    }
  
    .banner-announcements .banner-content .content-2 .item-group-2 .item-content .title {
      float: left;
      width: calc(100% - 190px);
      font-family: $font-family-roboto;
      font-size: $font-title-size-small-25;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      color: $color-eden;
    }
  
    .banner-announcements .banner-content .content-2 .item-group-2 .item-content .desc {
      line-height: 1.79;
      color: $color-grey-dusty;
      width: 320px;
      float: left;
      clear: both;
    }

    .header_home_landing .header_left {
      /* padding-left: 140px; */
      min-width: 710px;
      width: 40%;
      float: left;
    }
  
    .header_home_landing .header_left h2 {
      font-family: $font-family-roboto;
      font-size: 55px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $color-white;
      margin-top: 40px;
      margin-bottom: 20px;
    }
  
    .header_home_landing .header_left h2>span.bold {
      font-family: $font-family-roboto-bold;
    }
  
    .header_home_landing .header_left .decription {
      width: 391px;
      font-family: $font-family-roboto;
      font-size: $font-medium;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.67;
      letter-spacing: normal;
      color: $color-white;
    }

  
    .lading-page {
      min-width: 1200px;
      background-color: $color-white;
      z-index: 3000;
      .group_link_header_hl {
        position: relative;
        display: block;
        width: 1410px;
        max-width: 96%;
        margin: auto;
        overflow: hidden;
        &:before {
          content: "\e93e";
          font-family: 'icomoon' !important;
          display: bock;
          width: 15px;
          height: 15px;
          position: absolute;
          left: 0px;
          top: 50%;
          font-size: $font-big;
          margin-top: -7px;
          line-height: 15px;
          color: $color-yellow;
        }
      }
      .header_home_landing {
        width: 100%;
        position: relative;
        z-index: 2;
        min-height: 580px;
        /* background-image: linear-gradient(to top, #228a7d, $color-jungle-green); */
        padding-top: 35px;
        &:after {
          content: "";
          display: block;
          width: 100%;
          height: 500px;
          max-height: 100%;
          background-position: 50%;
          background-repeat: no-repeat;
          background-size: cover;
          position: absolute;
          top: 0px;
          left: 0px;
          z-index: -10;
        } // &:before{
        //   content: "";
        //   display: block;
        //   width: 100%;
        //   height: 130px;
        //   max-height: 100%;
        //   top: 0px;
        //   left: 0px;
        //   z-index: -9;
        //   position: absolute;
        //   background: -moz-linear-gradient(0deg, rgba(254, 251, 251, 0) 0, #000000 100%);/* FF3.6+ */
        //   background: -webkit-gradient(linear, 0deg, color-stop(0, rgba(254, 251, 251, 0)), color-stop(100%, 000000));/* Chrome,Safari4+ */
        //   background: -webkit-linear-gradient(0deg, rgba(254, 251, 251, 0)B 0, #000000 100%);/* Chrome10+,Safari5.1+ */
        //   background: -o-linear-gradient(0deg, rgba(254, 251, 251, 0) 0, #000000 100%);/* Opera 11.10+ */
        //   background: -ms-linear-gradient(0deg, rgba(254, 251, 251, 0) 0, #000000 100%);/* IE10+ */
        //   filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1301FE', endColorstr='#F4F60C', GradientType='1'); /* for IE */
        //   background: linear-gradient(0deg, rgba(254, 251, 251, 0) 0, #000000 100%);/* W3C */
        // }
        .group_tit_home_landing {
          text-align: center;
          .img_title_landing {
            width: 60%;
            display: block;
            float: right;
            text-align: left;
            padding-left: 5%;
            background: url('@/assets/images/landing/landing-3.png') top left;
            background-size: auto auto;
            img {
              max-width: 100%;
            }
          }
          .title_landing {
            display: block;
            width: 40%;
            float: left;
            text-align: right;
            padding-right: 5%;
            .center_left_ld {
              width: 300px;
              text-align: center;
              max-width: 100%;
              float: right;
            }
            h2 {
              margin-top: 75px;
              margin-bottom: 30px;
              color: $color-blue-cerulean-light;
              font-weight: 600;
              line-height: 60px;
              display: block;
              font-size: 60px;
              text-transform: uppercase;
            }
          }
          .hidden-button {
            opacity: 0;
            visibility: hidden;
          }
          
        .insurance {
          /* margin-left: 140px; */
          margin-bottom: 30px;
        }
        .insurance>span {
          color: $color-white;
          font-size: $font-medium;
          margin-right: 30px;
        }
      }
  
  
      .c0b225b {
        background-image: linear-gradient(to top, #252A92, #252A92) !important;
      }
    }
      
 .margin-l-15 {
        margin-left: 15px;
      }
    }
@media(max-width: 1440px) {
  #content-home-markets {
      padding: 0px 16px 0;
  }
  .banner-big-group .banner-content .container-info{
    padding: 76px 16px 50px;
  }
  .banner-big-group .banner-content .group-device .container-info {
    padding: 50px 16px 150px;
  }
}

@media(max-width: 1360px) {
.banner-big-group {
  .banner-content {
    .group-device {
      .container-info {
        .info-right {
          img {
            /* width: 100%; */
          }
        }
      }
    }
  }
}
}

@media screen and (max-width: 992px) {
  #content-home-markets {
      padding: 27px 15px 0;
  }
  .banner-big-group {
    .banner-content {
      .container-info {
        padding: 40px 24px;
      }
      .group-content-info {
        background: var(--bg-referral);
        .info-left {
            padding: 0;

          h3 {
            font-size: 28px;
            line-height: 34px;
            margin-bottom: 30px;
          }
          ul {
            li{
              font-size: 16px;
              line-height: 19px;
              display: flex;
              align-items: flex-start;
              padding-left: 0;
            }
          }
        } 
        .info-right {
          text-align: center;
          padding: 0;
          img {
            /* object-fit: none; */
          }
        }
      }
      .group-info {
        padding-bottom: 30px;

        img {
          width: 60px;
          height: 60px;
        }

        h4 {
          font-size: 16px;
          line-height: 19px;
        }
        p { 
          font-size: 16px;
          line-height: 19px;
        }
      }
      .group-content-item {
        .container-info {
          padding: 20px 18px;
        }

        .link-content{
          padding: 0;

         
        }
        .content-spot {
          padding-bottom: 29px !important;
        }
        .content-saving {
          padding-bottom: 30px !important;
        }
        .content-item {
          padding: 0 0 40px 0;
          
          img {
            width: 70px;
            max-width: 70px;
            height: 60px;
            margin-right: 10px;
          }
          h4 {
            font-size: 18px;
            line-height: 21px;
            margin: 0 0 8px 0;
          }
          p {
            font-size: 14px;
            line-height: 16px;
          }
        }
      }
      .group-device {
        background: var(--background-color-primary);
        .container-info {
          padding: 20px 18px;

          .info-left {
            margin-bottom: 20px;
          }

          .install-app {
            flex-direction: column;
            padding-top: 20px;
          }
        }
      }
      .app-link {
        max-width: 100%;
      }
    }
    
  }
  /* @media screen and (max-width: 576px) {
    .banner-big-group {
      .banner-content {
        .group-device {
          .info-right {
            img {
              width: auto;
            }
          }
        }
      }
    }
  } */
}
  </style>
  
 