<template>
  <!-- NEW BALANCE LAYOUT -->
  <div
    id="balance"
    class="page_container"
    :class="{ is_no_single_page: !isSinglePage }"
  >
    <Header />
    <div class="display-flex">
      <SpotOverview
        :totalSpotBtcBalance="
          totalSpotBtcBalance
        "
        :totalSpotUsdBalance="
          totalSpotUsdBalance
        "
        :tongglePrice="tongglePrice"
        @clickHide="hidePriceFunction"
      />
      <TodayPNL :tongglePrice="tongglePrice" :key="updatePNL" />
      <!-- <div class="transaction-box">
        <router-link
            to="/funds/history-wallet"
          >
            <img src="@/assets/images/icon-transaction.svg"/>
            {{ $t('funds.history.transaction_history')}}
          </router-link>
      </div> -->
    </div>
    <div class="balance-container">
      <div class="box-title title-medium-small" v-if="isSinglePage">
        {{ $t("funds.balances.balance") }}
      </div>

      <!-- end-box-title -->

      <div class="input-search fl-left">
        <input
          type="text"
          name=""
          :placeholder="$t('approved_order.btn_search')"
          v-model="search"
        />
        <a class="btn-search" href="#">
          <img
            :src="
              require(`@/assets/images/wallet/icon_search${
                theme === 'light-theme' ? '' : '-dark'
              }.svg`)
            "
            width="19"
            height="19"
           alt="icon-search"/>
        </a>
      </div>
      <div class="es-toolbar clearfix" v-if="isSinglePage">
        <div
          class="btn-small-icon text-center fl-left"
          @click="showNotification()"
          v-click-outside="hideNotification"
        >
          <div class="checkbox-input">
            <input
              type="checkbox"
              @click="hideBalances = !hideBalances"
              id="checkboxFiveInput"
              name="checkbox"
              for="checkboxFiveInput"
              class="checkbox"
            />
            <label for="checkboxFiveInput" class="button-check"></label>
          </div>
          <span id="tooltip-target-1"
            >{{ $t("funds.balances.hide_small_balance_spot") }}
            <span class="tool-tip-custom"
              >{{ $t("funds.balances.hide_small_balance_spot_tooltip") }}
            </span>
          </span>
        </div>
      </div>
      <!-- end es-toolbar -->
      <div class="content-spot-overview">
        <div class="table-container table-history">
          <alert-with-button-modal />
          <message-with-icon />
          <data-table
            :getData="loadData"
            ref="datatable"
            :msgEmptyData="noFunds"
            :limit="10"
            @DataTable:finish="onDatatableFinished"
          >
            <template slot-scope="props">
              <th
                data-sort-field="coin"
                class="coin_balances"
                @click="FixSortIE = !FixSortIE"
              >
                {{ $t("funds.balances.coin") }}
                <div
                  class="group-sort-tabl3"
                  v-html="props.innerHtmlSort"
                ></div>
                <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
              </th>
              <th
                v-if="isSinglePage"
                class="name_blances"
                @click="FixSortIE = !FixSortIE"
              >
                {{ $t("funds.balances.name") }}
              </th>
              <th
                class="min_w150 totalBalance"
                data-sort-field="exchangeBalance"
                @click="FixSortIE = !FixSortIE"
              >
                {{ $t("funds.balances.total_balance") }}
                <div
                  class="group-sort-tabl3"
                  v-html="props.innerHtmlSort"
                ></div>
                <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
              </th>
              <th
                class="min_w120 availableBalance"
                data-sort-field="exchangeAvailabelBalance"
                @click="FixSortIE = !FixSortIE"
              >
                {{ $t("funds.balances.avaliable_balance") }}
                <div
                  class="group-sort-tabl3"
                  v-html="props.innerHtmlSort"
                ></div>
                <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
              </th>
              <th class="max_w180 action">
                {{ $t("funds.balances.action") }}
              </th>
              <!-- <th>{{$t('funds.balances.mam_balance')}}</th> -->
              <!-- <th data-sort-field="btcValue">{{$t('funds.balances.btc_value')}}</th> -->
              <!-- <th v-if="isSinglePage"></th> -->
            </template>
            <template slot="body" slot-scope="props">
              <tr v-if="_.size(coinNames) > 0">
                <td class="coin_balances">
                  <img
                    v-if="coinImages[props.item.coin]"
                    class="icon-coin"
                    :src="`${coinImages[props.item.coin]}`"
                    alt=""
                  />
                  <img
                    v-else
                    class="icon-coin"
                    :src="
                      props.item.coin == 'btc'
                        ? btcicon
                        : props.item.coin == 'xrp'
                        ? xrpicon
                        : eosicon
                    "
                    alt=""
                  />
                  {{ props.item.coin | uppercase }}
                </td>

                <td v-if="isSinglePage">
                  {{
                    props.item.coin == "xrp"
                      ? "Ripple"
                      : props.item.coin == "eos"
                      ? "EOS"
                      : props.item.name | formatCoinNameCustom
                  }}
                </td>
                <td v-else>******</td>
                <td v-if="!tongglePrice">
                  {{ props.item.exchangeBalance  | formatNumberAndDecimal }}
                </td>
                <td v-else>******</td>
                <td v-if="!tongglePrice">
                  {{ props.item.exchangeAvailabelBalance  |  formatNumberAndDecimal }}
                </td>
                <td v-else>******</td>
                <!-- <td >
                  {{ props.item.exchangeBalance  |  formatNumberAndDecimal }}
                </td> -->
                <td class="action_btn">
                  <router-link
                    v-if="props.item.isDeposit"
                    :to="
                      props.item.coin == 'usd'
                        ? { name: 'Deposit USD Wallet' }
                        : {
                            name: 'Deposits Wallet',
                            query: { coin: props.item.coin },
                          }
                    "
                  >
                    {{ $t('funds.balances.deposit')}}
                  </router-link>

                  <router-link
                    v-if="props.item.isWithdraw"
                    :to="
                      props.item.coin == 'usd'
                        ? { name: 'Withdraw USD Wallet' }
                        : {
                            name: 'Withdrawals Wallet',
                            query: { coin: props.item.coin },
                          }
                    "
                  >
                    {{ $t('m_funds.coin_info.withdraw')}} 
                  </router-link>

                  <router-link
                    v-if="props.item.isWithdraw"
                    :to="`/funds/transfer?symbol=${props.item.coin}`"
                  >
                    {{ $t('funds.balances.tranfer')}} 
                  </router-link>
                  <!-- <span
                    class="tranfer-cell"
                    @click="activeRow(props.item)"
                    v-bind:class="{ acvite_transfer: props.item.isActiveRow }"
                    ><a>{{ $t("funds.balances.tranfer") }}</a>
                  </span> -->
                  <!-- <img src="@/assets/images/dot-more.svg"/> -->
                </td>
              </tr>

              <template v-if="props.item.coin == nameItemShow">
                <tr>
                  <td colspan="11" class="table_drop_balances">
                    <div class="close_tbale" @click="activeRow(props.item)">
                      <i class="icon-close"></i>
                    </div>
                    <div class="content_transfer_form">
                      <form class="transfer_form">
                        <!-- From / Amount -->
                        <div
                          class="group_transfer_form"
                          v-if="!props.item.isTransferFromMain"
                        >
                          <label class="txt">{{
                            $t("funds.balances.from")
                          }}</label>
                          <input
                            type="text"
                            class="input-form w_155"
                            disabled="disabled"
                            :placeholder="$t('funds.balances.main_account')"
                          />
                        </div>

                        <!-- btn 1 -->

                        <div
                          class="group_transfer_form"
                          v-if="props.item.isTransferFromMain"
                        >
                          <label class="txt">{{
                            $t("funds.balances.from")
                          }}</label>
                          <div class="dropdown">
                            <button
                              class="btn form-control select-control w_155 disableCustom"
                              type="button"
                              data-toggle="dropdown"
                              disabled="disabled"
                            >
                              <span
                                class="left select-item-selected"
                                v-if="props.item.transferType === 'spot'"
                              >
                                {{ $t("funds.balances.spot_balance") }}
                              </span>
                              <span
                                class="left select-item-selected"
                                v-if="props.item.transferType === 'margin'"
                              >
                                {{ $t("funds.balances.margin_balance") }}
                              </span>
                              <!-- <span class="icon icon-arrow1 select-icon"></span> -->
                            </button>
                            <ul class="dropdown-menu">
                              <div
                                @click="props.item.transferType = 'spot'"
                                class="tab-element"
                                :class="{
                                  active: props.item.transferType === 'spot',
                                }"
                              >
                                <span class="text-size">{{
                                  $t("funds.balances.spot_balance")
                                }}</span>
                              </div>
                              <div
                                v-on:click="
                                  !props.item.disableMarginBalance &&
                                    (props.item.transferType = 'margin')
                                "
                                class="tab-element"
                                :class="{
                                  'disabled-item':
                                    props.item.disableMarginBalance,
                                }"
                              >
                                <span class="text-size">{{
                                  $t("funds.balances.margin_balance")
                                }}</span>
                              </div>
                            </ul>
                          </div>
                        </div>

                        <!-- btn 2 -->

                        <!-- Swap inputs button -->
                        <div
                          class="group_transfer_form"
                          @click="swapBalance($event, props.item)"
                        >
                          <label class="txt"></label>
                          <img
                            :src="
                              require(`@/assets/images/wallet/tranfersIconOverview${
                                theme === 'light-theme' ? '' : '-dark'
                              }.svg`)
                            "
                          />
                          <!-- <button class="btn btn-transfer-form" @click="swapBalance($event, props.item)">
                            <i class="icon-transfer"></i>
                          </button> -->
                        </div>

                        <div
                          class="group_transfer_form"
                          v-if="props.item.isTransferFromMain"
                        >
                          <label class="txt">{{
                            $t("funds.balances.to")
                          }}</label>
                          <input
                            type="text"
                            class="input-form w_155"
                            :placeholder="$t('funds.balances.main_account')"
                            disabled="disabled"
                          />
                        </div>
                        <div
                          class="group_transfer_form"
                          v-if="!props.item.isTransferFromMain"
                        >
                          <label class="txt">{{
                            $t("funds.balances.to")
                          }}</label>
                          <div class="dropdown">
                            <button
                              class="btn form-control select-control w_155 disableCustom"
                              type="button"
                              data-toggle="dropdown"
                              disabled="disabled"
                            >
                              <span
                                class="left select-item-selected"
                                v-if="props.item.transferType === 'spot'"
                              >
                                {{ $t("funds.balances.spot_balance") }}
                              </span>
                              <span
                                class="left select-item-selected"
                                v-if="props.item.transferType === 'margin'"
                              >
                                {{ $t("funds.balances.margin_balance") }}
                              </span>
                              <!-- <span class="icon icon-arrow1 select-icon"></span> -->
                            </button>
                            <ul class="dropdown-menu">
                              <div
                                @click="props.item.transferType = 'spot'"
                                class="tab-element"
                                :class="{
                                  active: props.item.transferType === 'spot',
                                }"
                              >
                                <span class="text-size">{{
                                  $t("funds.balances.spot_balance")
                                }}</span>
                              </div>
                              <div
                                v-on:click="
                                  !props.item.disableMarginBalance &&
                                    (props.item.transferType = 'margin')
                                "
                                class="tab-element"
                                :class="{
                                  'disabled-item':
                                    props.item.disableMarginBalance,
                                }"
                              >
                                <span class="text-size">{{
                                  $t("funds.balances.margin_balance")
                                }}</span>
                              </div>
                            </ul>
                          </div>
                        </div>
<!--                        <&#45;&#45; Amount &ndash;&gt;-->
                        <div class="group_transfer_form">
                          <label class="txt">{{
                            $t("order.trade_history.amount")
                          }}</label>
                          <div class="input-amount">
                            <currency-input
                              :precision="8"
                              :class="{
                                error: errors.has('amount' + props.item.coin),
                              }"
                              class="input-form w_220"
                              :value="props.item.amount"
                              v-model="props.item.amount"
                              @focus="resetValidateRowWhenFocus(props.item)"
                              @blur="() => handleChangeAmount(props.item)"
                            />
                            <span class="name_amount">{{
                              props.item.coin
                            }}</span>
                          </div>
                          <div
                            v-show="errors.has('amount' + props.item.coin)"
                            class="w_220 invalid-feedback"
                          >
                            {{ errors.first("amount" + props.item.coin) }}
                          </div>
                          <div
                            v-show="errors.has('server' + props.item.coin)"
                            class="w_220 invalid-feedback"
                          >
                            {{ errors.first("server" + props.item.coin) }}
                          </div>

                          <div class="group_input_form cleafix">
                            <span class="txt_left_group mgb-none"></span>
                            <label class="input_right_group">
                              <span
                                class="check_percent"
                                :class="[
                                  precentTransfer == 25 && 'activePrecent',
                                ]"
                                @click="onPercentClicked(props.item, 25)"
                                >25%</span
                              >
                              <span
                                class="check_percent"
                                :class="[
                                  precentTransfer == 50 && 'activePrecent',
                                ]"
                                @click="onPercentClicked(props.item, 50)"
                                >50%</span
                              >
                              <span
                                class="check_percent"
                                :class="[
                                  precentTransfer == 75 && 'activePrecent',
                                ]"
                                @click="onPercentClicked(props.item, 75)"
                                >75%</span
                              >
                              <span
                                class="check_percent last_item"
                                :class="[
                                  precentTransfer == 100 && 'activePrecent',
                                ]"
                                @click="onPercentClicked(props.item, 100)"
                                >100%</span
                              >
                            </label>
                          </div>
                        </div>
                        <!-- Transfer button -->
                        <div class="group_transfer_form">
                          <label class="txt"></label>
                          <button
                            class="btn btn-send-transfer-form"
                            @click.prevent="transferBalance($event, props.item)"
                            :disabled="isSubmittingTransfer"
                          >
                            {{ $t("funds.balances.tranfer") }}
                          </button>
                        </div>
                      </form>
                    </div>
                  </td>
                </tr>
              </template>

              <template v-if="props.item.isActiveRowMam">
                <tr>
                  <td colspan="11" class="table_drop_balances">
                    <div class="close_tbale" @click="activeRowMam(props.item)">
                      <i class="icon-close"></i>
                    </div>
                    No data
                  </td>
                </tr>
              </template>
            </template>
          </data-table>
          <!-- end pagination-container -->
        </div>
      </div>

      <!-- end table container -->
      <!-- <airdrop-balance/>
      <mam-transfer-history/> -->
    </div>
    <!-- end-balance-container -->
  </div>
  <!-- END NEW BALANCE LAYOUT -->
</template>
<script>
import rf from "@/request/RequestFactory";
import BigNumber from "bignumber.js";
import Const from "@/common/Const";
import AlertWithButtonModal from "@/components/shared_components/common/AlertWithButtonModal";
import MessageWithIcon from "@/components/shared_components/common/MessageWithIcon";
import AirdropBalance from "@/components/shared_components/common/AirdropBalance.vue";
import Entry from "@/components/shared_components/common/Entry/Entry.vue";
import MamTransferHistory from "@/components/shared_components/common/MamTransferHistory.vue";
import {mapGetters, mapState} from "vuex";
import Cookies from "js-cookie";
import Header from "@/pages/wallet/component/Header.vue";
import BTCIcon from "@/assets/images/icon/coin/color/btc.png";
import XRPIcon from "@/assets/images/icon/coin/color/xrp.png";
import EOSIcon from "@/assets/images/icon/coin/color/eos.png";
import ETHIcon from "@/assets/images/ethereum-eth-logo.png"
import SpotOverview from "@/pages/wallet/component/SpotOverview.vue";
import TodayPNL from "@/pages/wallet/component/TodayPNL.vue";

const MIN_BTC = 0.001;
const MIN_AMOUNT_CRYPTO = Math.pow(10, -8); // 10^-8
const TRANSFER_TYPE_MAIN_BALANCE = "main";
const TRANSFER_TYPE_EXCHANGE_BALANCE = "spot";
const TRANSFER_TYPE_MARGIN_BALANCE = "margin";
const TRANSFER_TYPE_MAM_BALANCE = "mam";

// Prevent convert 0.00000001 to 1e-8
BigNumber.config({ EXPONENTIAL_AT: 15 });

export default {
  data() {
    return {
      FixSortIE: null,
      hideBalances: false,
      isShowNotification: false,
      search: "",
      limit: undefined,
      accountBalances: undefined,
      prices: undefined,
      balances: [],
      totalPendingWithdraws: [],
      selectedCoin: "",
      tradeCoin: [],
      displayPairCoin: false,
      tradeUsd: [],
      totalOrder: 0,

      isAuthenticated: window.isAuthenticated,
      coinSetting: {},
      coinsMasterData: {},
      coinNames: {},
      coinImages: {},
      noFunds: window.i18n.t("funds.balances.no_data"),
      isSubmittingTransfer: false,
      isShowEntryLeaderboard: false,

      btcicon: BTCIcon,
      xrpicon: XRPIcon,
      eosicon: EOSIcon,
      ethicon: ETHIcon,

      precentTransfer: 0,
      nameItemShow: "",
      theme:
        Cookies.get("user-theme", { domain: process.env.VUE_APP_PUBLIC_URL }) ||
        "dark-theme",
      tongglePrice: false,
      updatePNL: false,
      sort: '',
      sortType: '',
      hasSortWhenTransfer: false
    };
  },
  components: {
    AlertWithButtonModal,
    MessageWithIcon,
    AirdropBalance,
    MamTransferHistory,
    Entry,
    Header,
    SpotOverview,
    TodayPNL
  },
  props: {
    isSinglePage: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    accountBalances(newAccBalances) {
      // const currencies = Object.keys(newAccBalances);
      // this.getTotalPendingWithdraw(currencies, this.refresh);
    },
    totalPendingWithdraws(newVal) {
      this.refresh();
    },
    search(newValue) {
      this.$refs.datatable.refresh();
    },
    hideBalances() {
      this.$refs.datatable.refresh();
    },
    isTransferFromMain() {
      this.$refs.datatable.refresh();
    },

    userThemeMode(theme) {
      this.theme = theme;
    },
  },
  computed: {
    ...mapState({
      masterData: (state) => state.masterdata,
      userThemeMode: (state) => state.userThemeMode,
    }),
    ...mapGetters({
      isShowBalances: 'isShowBalances'
    }),
    totalSpotBtcBalance() {
      return this.sumBy("btcSpotValue");
    },
    totalSpotUsdBalance() {
      return this.sumBy("usdSpotValue");
    },
  },
  methods: {
    hidePriceFunction() {
      this.tongglePrice = !this.tongglePrice;
      const spotBalance = {...this.isShowBalances,spotBalance : this.tongglePrice}
      Cookies.set("hide-balance", JSON.stringify(spotBalance, { domain: process.env.VUE_APP_PUBLIC_URL }))
      this.$store.commit('setShowBalances', spotBalance )
    },
    handleSwitchTransfer(item) {
      this.isSwitchTransfer = !this.isSwitchTransfer;
      this.precentTransfer = 0;
      item.amount = 0;
    },
    displayPositive(value) {
      if (new BigNumber(`${value || 0}`).comparedTo(0) < 0) {
        return 0;
      }
      return value;
    },
    onDatatableFinished() {},
    convertSmallBalance() {
      return this.$router.push({ path: "convert-balances" });
      // return this.$route.name === 'Convert Small Balances'
    },
    resetValidateRowWhenFocus(item) {
      this.errors.remove("amount" + item.coin);
      this.errors.remove("server" + item.coin);
    },
    resetValidateRow(item) {
      item.isTransferFromMain = true;
      item.amount = 0;
      this.errors.remove("amount" + item.coin);
      this.errors.remove("server" + item.coin);
    },
    activeRow(item) {
      if (item.coin == this.nameItemShow) {
        this.nameItemShow = "";
        return;
      }
      this.nameItemShow = item.coin;
      this.precentTransfer = 0;
      item.isActiveRow = !item.isActiveRow;
      this.resetValidateRow(item);
      this.$forceUpdate();
    },
    HiddenAcriveRow(item) {
      this.$set(item, "isActiveRow", false);
    },
    activeRowMam(item) {
      item.isActiveRowMam = !item.isActiveRowMam;
      this.$forceUpdate();
    },
    addPricesInBtc() {
      this.prices["btc_btc"] = { price: 1 };
      for (const fiat of Const.FIAT_LIKE_LIST) {
        const fiatPrice = this.prices[`${fiat}_btc`];
        const checkNullPrice =
          fiatPrice && !new BigNumber(fiatPrice?.price.toString()).isZero();
        const price = checkNullPrice
          ? new BigNumber("1").div(fiatPrice?.price.toString()).toString()
          : "0";
        this.prices[`btc_${fiat}`] = { price };
      }
    },
    isShowcoin(coin) {
      return coin === this.selectedCoin;
    },
    showCoin(coin) {
      this.selectedCoin = coin;
      this.tradeCoin = this.currencyCoins.filter((item) => {
        return item.coin.indexOf(coin) > -1;
      });
      this.tradeUsd = this.currencyCoins.filter((item) => {
        return item.currency === "usd";
      });
    },
    getMinBtc() {
      return MIN_BTC;
    },
    showNotification() {
      this.isShowNotification = !this.isShowNotification;
    },
    hideNotification() {
      this.isShowNotification = false;
    },
    loadData(params) {
      return new Promise((resolve) => {
        let data = this.balances;
        data = _.filter(data, (item) =>
          `${item.coin}${item.name}`
            .toLowerCase()
            .includes(this.search.toLowerCase())
        );
        if (this.hideBalances) {
          data = _.filter(data, (item) => {
            item.btcSpotValue = this.convertToBtcValue(
              item.coin,
              item.exchangeBalance
            );
            let btc = new BigNumber(item.btcSpotValue.toString());
            let btcValue = new BigNumber(btc.toString());
            return btcValue.gt(MIN_BTC);
          });
        }
        const sort = !this.hasSortWhenTransfer? params.sort : this.sort
        const sortType = !this.hasSortWhenTransfer? params.sort_type : this.sortType
        if (sort && sortType) {
          data = _.orderBy(
            data,
            (item) => {
              if (isNaN(item[sort])) {
                return item[sort];
              }
              return new BigNumber(`${item[sort]}`).toNumber();
            },
            [sortType]
          );
          this.hasSortWhenTransfer = false
        }
        return resolve({ data });
      });
    },
    refreshNow() {
      // Refresh datatable
      let immediateRefresh = false;
      this.transferData(immediateRefresh);
      this.$refs && this.$refs.datatable && this.$refs.datatable.refresh();
    },
    refresh() {
      const isNoData =
        window._.isEmpty(this.accountBalances) || window._.isEmpty(this.prices);
      if (isNoData) {
        return;
      }
      this.transferData();
      // this.totalOrder = this.sumBy("orderBtcValue");
      this.$refs && this.$refs.datatable && this.$refs.datatable.refresh();
    },

    transferData(isMerge = true) {
      if (this.balances && this.balances.length > 0 && isMerge) {
        this.mergeTransferData();
        return true;
      }

      if (!this.accountBalances) return false;

      let data = []
      let coinList = this.accountBalances[Const.TYPE_MAIN_BALANCE] || {}
      let mainBalanceData = this.accountBalances[Const.TYPE_MAIN_BALANCE] || {}
      let marginBalanceData =
          this.accountBalances[Const.TYPE_MARGIN_BALANCE] || {}
      let exchangeBalanceData =
          this.accountBalances[Const.TYPE_MAIN_BALANCE] || {}
      let mamBalanceData = this.accountBalances[Const.TYPE_MAM_BALANCE] || {}
      let airdropBalanceData =
          this.accountBalances[Const.TYPE_AIRDROP_BALANCE] || {}

      for (let coin in coinList) {
        if(coin !== 'ltc' ) { 
        const item = {};
        item.coin = coin;
        item.name = this.getCoinName(coin);

        item.balance =
          (mainBalanceData &&
            mainBalanceData[coin] &&
            mainBalanceData[coin].balance) ||
          0;
        item.availableBalance =
          (mainBalanceData &&
            mainBalanceData[coin] &&
            mainBalanceData[coin].available_balance) ||
          0;
        item.exchangeBalance =
          (exchangeBalanceData &&
            exchangeBalanceData[coin] &&
            exchangeBalanceData[coin].balance) ||
          0;
        item.airdropBalanceBonus =
          (airdropBalanceData &&
            airdropBalanceData[coin] &&
            airdropBalanceData[coin].balance_bonus) ||
          0;
        item.availableAirdropBalanceBonus =
          (airdropBalanceData &&
            airdropBalanceData[coin] &&
            airdropBalanceData[coin].available_balance_bonus) ||
          0;
        item.exchangeAvailabelBalance =
          (exchangeBalanceData &&
            exchangeBalanceData[coin] &&
            exchangeBalanceData[coin].available_balance) ||
          0;
        item.marginBalance =
          (marginBalanceData &&
            marginBalanceData[coin] &&
            marginBalanceData[coin].balance) ||
          0;
        item.marginAvailabelBalance =
          (marginBalanceData &&
            marginBalanceData[coin] &&
            marginBalanceData[coin].available_balance) ||
          0;
        item.mamBalance =
          (mamBalanceData &&
            mamBalanceData[coin] &&
            mamBalanceData[coin].balance) ||
          0;
        item.airdropBalance =
          (airdropBalanceData &&
            airdropBalanceData[coin] &&
            airdropBalanceData[coin].balance) ||
          0;

        item.totalBalance = new BigNumber(item?.balance).add(
          item.exchangeBalance
        );
        let coinType = item.coin || "";
        if (coinType == Const.BTC) {
          item.totalBalance = new BigNumber(item?.totalBalance).add(
            item.marginBalance
          );
        }
        if (coinType == Const.AML) {
          item.totalBalance = new BigNumber(item?.totalBalance)
            .add(item.marginBalance)
            .add(item.airdropBalance)
            .add(item.airdropBalanceBonus);
        }
        item.totalBalance = this.formatCurrencyRoundDown(
          coin,
          item.totalBalance.toString()
        );

        item.availableBalance = this.formatCurrencyRoundDown(
          coin,
          item.availableBalance
        );
        item.exchangeBalance = this.formatCurrencyRoundDown(
          coin,
          item.exchangeBalance
        );
        item.exchangeAvailabelBalance = this.formatCurrencyRoundDown(
          coin,
          item.exchangeAvailabelBalance
        );
        item.marginBalance = this.formatCurrencyRoundDown(
          coin,
          item.marginBalance
        );
        item.marginAvailabelBalance = this.formatCurrencyRoundDown(
          coin,
          item.marginAvailabelBalance
        );
        item.mamBalance = this.formatCurrencyRoundDown(coin, item.mamBalance);
        item.airdropBalance = this.formatCurrencyRoundDown(
          coin,
          item.airdropBalance
        );

        // item.disableMarginBalance =
        //   coinType != Const.BTC && coinType != Const.AML
        item.disableMarginBalance = true;

        item.enableMamBalance = coinType == Const.BTC;
        if ([Const.BTC, Const.AML, Const.ETH].includes(coinType))
          item.enableAirdropBalance = true;
        else item.enableAirdropBalance = false;
        item.isTransferFromMain = true;
        item.amount = 0;
        item.transferType = TRANSFER_TYPE_EXCHANGE_BALANCE;

        item.order = this.calculateInOrder(item);
        item.orderBtcValue = this.getOrderBtcBalance(item);
        item.btcValue = this.convertToBtcValue(coin, item.totalBalance);

        let usdtValue = this.convertCoins(Const.BTC, Const.USDT, item.btcValue)
        item.btcSpotValue = this.convertToBtcValue(coin, item.exchangeBalance);
        let usdSpotValue = this.convertCoins(Const.BTC, Const.USDT, item.btcSpotValue)
        const pair = 'usdt_btc'
        const price = this.prices[pair] ? this.prices[pair].price : '0'

        if (!usdtValue) {
          usdtValue = new BigNumber(item.btcValue).mul(price).toString();
        }
        item.usdtValue = usdtValue;

        if (!usdSpotValue) {
          usdSpotValue = new BigNumber(item.orderBtcValue)
            .mul(price)
            .toString();
        }
        item.usdSpotValue = usdSpotValue;

        item.isDeposit =
          this.coinSetting[item.coin] && this.coinSetting[item.coin].is_deposit;
        item.isWithdraw =
          this.coinSetting[item.coin] &&
          this.coinSetting[item.coin].is_withdraw;

        data.push(item);

        }
        
      this.balances = window._.sortBy(data, "name");
      }
    },

    mergeTransferData() {
      let data = []
      let coinList = this.accountBalances[Const.TYPE_MAIN_BALANCE]
      let mainBalanceData = this.accountBalances[Const.TYPE_MAIN_BALANCE]
      let marginBalanceData = this.accountBalances[Const.TYPE_MARGIN_BALANCE]
      let exchangeBalanceData =
          this.accountBalances[Const.TYPE_MAIN_BALANCE]
      let mamBalanceData = this.accountBalances[Const.TYPE_MAM_BALANCE]
      let airdropBalanceData = this.accountBalances[Const.TYPE_AIRDROP_BALANCE]

      for (let coin in coinList) {
        const item = {}
        item.coin = coin

        item.balance =
            (mainBalanceData &&
                mainBalanceData[coin] &&
                mainBalanceData[coin].balance) ||
            0
        item.availableBalance =
            (mainBalanceData &&
                mainBalanceData[coin] &&
                mainBalanceData[coin].available_balance) ||
            0
        item.exchangeBalance =
            (exchangeBalanceData &&
                exchangeBalanceData[coin] &&
                exchangeBalanceData[coin].balance) ||
            0
        item.airdropBalanceBonus =
            (airdropBalanceData &&
                airdropBalanceData[coin] &&
                airdropBalanceData[coin].balance_bonus) ||
            0
        item.availableAirdropBalanceBonus =
            (airdropBalanceData &&
                airdropBalanceData[coin] &&
                airdropBalanceData[coin].available_balance_bonus) ||
            0
        item.exchangeAvailabelBalance =
            (exchangeBalanceData &&
                exchangeBalanceData[coin] &&
                exchangeBalanceData[coin].available_balance) ||
            0
        item.marginBalance =
            (marginBalanceData &&
                marginBalanceData[coin] &&
                marginBalanceData[coin].balance) ||
            0
        item.marginAvailabelBalance =
            (marginBalanceData &&
                marginBalanceData[coin] &&
                marginBalanceData[coin].available_balance) ||
            0
        item.mamBalance =
            (mamBalanceData &&
                mamBalanceData[coin] &&
                mamBalanceData[coin].balance) ||
            0
        item.airdropBalance =
            (airdropBalanceData &&
                airdropBalanceData[coin] &&
                airdropBalanceData[coin].balance) ||
            0

        // Note: Please re-check with
        // resources/assets/js/desktop/Layout.vue:getTotalBalanceByCoin
        // when edit this function
        item.totalBalance = new BigNumber(item.balance).add(
            item.exchangeBalance
        )
        let coinType = item.coin || ''
        if (coinType == Const.BTC) {
          item.totalBalance = new BigNumber(item.totalBalance).add(
              item.marginBalance
          )
        }
        if (coinType == Const.AML) {
          item.totalBalance = new BigNumber(item.totalBalance)
              .add(item.marginBalance)
              .add(item.airdropBalance)
              .add(item.airdropBalanceBonus)
        }
        item.totalBalance = this.formatCurrencyRoundDown(
            coin,
            item.totalBalance.toString()
        )

        item.availableBalance = this.formatCurrencyRoundDown(
            coin,
            item.availableBalance
        )
        item.exchangeBalance = this.formatCurrencyRoundDown(
            coin,
            item.exchangeBalance
        )
        item.exchangeAvailabelBalance = this.formatCurrencyRoundDown(
            coin,
            item.exchangeAvailabelBalance
        )
        item.marginBalance = this.formatCurrencyRoundDown(
            coin,
            item.marginBalance
        )
        item.marginAvailabelBalance = this.formatCurrencyRoundDown(
            coin,
            item.marginAvailabelBalance
        )
        item.mamBalance = this.formatCurrencyRoundDown(coin, item.mamBalance)
        item.airdropBalance = this.formatCurrencyRoundDown(
            coin,
            item.airdropBalance
        )

        item.order = this.calculateInOrder(item)
        item.orderBtcValue = this.getOrderBtcBalance(item)

        item.btcValue = this.convertToBtcValue(coin, item.totalBalance)

        let usdtValue = this.convertCoins(Const.BTC, Const.USDT, item.btcValue)
        if (!usdtValue) {
          const pair = 'usdt_btc'
          const price = this.prices[pair] ? this.prices[pair].price : '0'
          usdtValue = new BigNumber(item.btcValue).mul(price).toString()
        }
        item.usdtValue = usdtValue

        data.push(item)
      }

      this.updateTableBalanceAfterTransfer(data)
      this.balances = window._.sortBy(this.balances, 'name')
    },

    updateTableBalanceAfterTransfer(data) {
      var _this = this;
      var newData = [];
      window._.each(data, (item, index) => {
        let coinRecord = window._.find(
          _this.balances,
          (itemBalance) => itemBalance.coin == item.coin
        );

        if (coinRecord) {
          let amount = new BigNumber(coinRecord.amount || "0");
          newData.push({
            ...coinRecord,
            balance: item.balance,
            availableBalance: item.availableBalance,
            exchangeBalance: item.exchangeBalance,
            exchangeAvailabelBalance: item.exchangeAvailabelBalance,
            marginBalance: item.marginBalance,
            marginAvailabelBalance: item.marginAvailabelBalance,
            mamBalance: item.mamBalance,
            airdropBalance: item.airdropBalance,
            airdropBalanceBonus: item.airdropBalanceBonus,
            availableAirdropBalanceBonus: item.availableAirdropBalanceBonus,
            totalBalance: item.totalBalance,
            amount: amount.toString(),
            isActiveRow: coinRecord.isActiveRow,
            order: item.order,
            orderBtcValue: item.orderBtcValue,
            btcValue: item.btcValue,
            usdtValue: item.usdtValue,
          });
        }
      });

      this.balances = newData;
    },
    getCoinName(coin) {
      let coinName = "";
      if (this.coinsMasterData && this.coinsMasterData[coin]) {
        coinName = this.coinsMasterData[coin].name;
      }
      return coinName;
    },
    getBalance(coin, currency) {
      if (!this.accountBalances[Const.TYPE_MAIN_BALANCE][coin]) return 0;

      if (coin === currency) {
        return `${this.accountBalances[Const.TYPE_MAIN_BALANCE][coin].balance}`;
      }
      const pair = `${currency}_${coin}`;
      if (!this.prices[pair]) {
        return 0;
      }
      return new BigNumber(
        `${this.accountBalances[Const.TYPE_MAIN_BALANCE][coin].balance}`
      )
        .mul(this.prices[pair].price)
        .toString();
    },
    formatCurrencyRoundDown(currency, value) {
      // let numberOfDecimalDigits =
      // currency && currency.toLowerCase() === "usd"
      //   ? Const.NUMBER_OF_DECIMAL_DIGITS_USD
      //   : Const.NUMBER_OF_DECIMAL_DIGITS; //currency === 'usd' ? 2 : 8;      let numberOfDecimalDigits =

      let numberOfDecimalDigits = Const.NUMBER_OF_DECIMAL_DIGITS; //currency === 'usd' ? 2 : 8;

      let newValue = new BigNumber(value);
      return newValue
        .round(numberOfDecimalDigits, BigNumber.ROUND_FLOOR)
        .toString();
    },
    getAmalAvailableBalance(coin, currency) {
      if (!this.accountBalances[Const.TYPE_MAIN_BALANCE][coin]) return 0;

      let balance =
        this.accountBalances[Const.TYPE_MAIN_BALANCE][coin].available_balance;
      if (coin === currency) {
        return `${balance}`;
      }

      return this.convertCoins(coin, Const.AML, balance);
    },
    getBtcBalance(coin, currency) {
      if (!this.accountBalances[Const.TYPE_MAIN_BALANCE][coin]) return 0;

      let balance = this.accountBalances[Const.TYPE_MAIN_BALANCE][coin].balance;
      if (coin === currency) {
        return `${balance}`;
      }

      return this.convertToBtcValue(coin, balance);
    },
    getBtcAvailableBalance(coin, currency) {
      if (!this.accountBalances[Const.TYPE_MAIN_BALANCE][coin]) return 0;

      let balance =
        this.accountBalances[Const.TYPE_MAIN_BALANCE][coin].available_balance;
      if (coin === currency) {
        return `${balance}`;
      }

      return this.convertToBtcValue(coin, balance);
    },
    convertToBtcValue(coin, value) {
      let balance = value;
      if (new BigNumber(balance.toString()).isZero()) {
        return 0;
      }

      let pair = `btc_${coin}`;
      if (!this.prices[pair] || !this.prices[pair].price) {
        pair = `${coin}_btc`;
        if (!this.prices[pair] || !this.prices[pair].price) {
          return 0;
        }
        return new BigNumber(`${balance.toString()}`)
            .div(this.prices[pair].price)
            .toString();
      }
      return new BigNumber(balance.toString())
          .mul(this.prices[pair].price)
          .toString();
    },
    convertBtcToCoinValue(coin, value) {
      let balance = value;
      let pair = `btc_${coin}`;
      if (!this.prices[pair] || !this.prices[pair].price) {
        pair = `${coin}_btc`;
        if (!this.prices[pair] || !this.prices[pair].price) {
          return 0;
        }
        return new BigNumber(`${balance.toString()}`)
            .mul(this.prices[pair].price)
            .toString();
      }
      return new BigNumber(`${balance.toString()}`)
          .div(this.prices[pair].price)
          .toString();
    },
    convertCoins(fromCoin, toCoin, value) {
      // Example:
      // If do not have price of usdt_$coin pair, transform to btc_$coin pair then transform to usdt_btc pair.
      let btcValue = this.convertToBtcValue(fromCoin, value);
      let newValue = this.convertBtcToCoinValue(toCoin, btcValue);
      return newValue;
    },
    getOrderBtcBalance(item) {
      if (!this.prices[`btc_${item.coin}`]) {
        return 0;
      }
      return new BigNumber(item.order)
        .minus(item.availableBalance)
        .mul(this.prices[`btc_${item.coin}`].price)
        .toString();
    },
    calculateInOrder(item) {
      // const result =  window._.find(this.totalPendingWithdraws, (row) => { return row && row.currency === item.coin});
      // const totalPendingWithdraw = result ? result.total : '0';
      // return new BigNumber(item.totalBalance).sub(item.availableBalance).sub(totalPendingWithdraw).toString();
      return new BigNumber(`${item.totalBalance}`).toString();
    },
    sumBy(attr) {
      let total = new BigNumber(0);
      for (let item of this.balances) {
        if (item[attr] == "NaN" || new BigNumber(item[attr]).isZero()) {
          continue;
        }
        total = total.add(item[attr]);
      }
      return total.toString();
    },
    onBalanceUpdated(newAccountBalances) {
      this.accountBalances = window._.merge(
        {},
        this.accountBalances,
        newAccountBalances
      );
      this.refresh();
    },
    onPricesUpdated(newPrices) {
      this.prices = Object.assign({}, this.prices, newPrices);
      this.addPricesInBtc();

      // this.refreshNow();
      this.transferData();
      // this.$refs && this.$refs.datatable && this.$refs.datatable.refresh();
    },
    onMainBalanceUpdated(mainUsdBalance) {
      if (
        mainUsdBalance &&
        mainUsdBalance.coin_used &&
        mainUsdBalance.coin_used == "usd" &&
        this.accountBalances &&
        this.accountBalances.main &&
        this.accountBalances.main.usd
      ) {
        this.accountBalances.main.usd.balance = mainUsdBalance.total_balance;
        this.accountBalances.main.usd.available_balance =
          mainUsdBalance.available_balance;
        this.refresh();
      }
    },
    onAmlBalanceUpdated(mainAmalBalance) {
      this.accountBalances.main.amal.balance = mainAmalBalance.total_balance;
      this.accountBalances.main.amal.available_balance =
        mainAmalBalance.available_balance;
      this.refresh();
    },
    onBalanceSpotUpdated(newAccountBalances) {
      this.accountBalances = window._.merge({}, this.accountBalances, {
        spot: newAccountBalances
      });
      this.transferData(true)
      this.$refs.datatable.fetch();
    },

    getPriceCoinGecKotoBTC() {
      for (const coin in this.coinsMasterData) {
        const pairValue = `btc_${this.coinsMasterData[coin].coin}`;
        if (this.prices[pairValue] === undefined && coin !== 'usd' || coin === 'ltc' || coin === 'xrp') {
          rf.getRequest('TransactionRequest')
              .getPriceToBTC({symbol: coin})
              .then(res => {
                this.prices[pairValue] = { price: res?.data || 0 };
              })
        }
      }
      this.$refs.datatable.fetch()
    },

    getSocketEventHandlers() {
      return {
        PricesUpdated: this.onPricesUpdated,
        BalanceUpdated: this.onBalanceUpdated,
        MainBalanceUpdated: this.onMainBalanceUpdated,
        AmlBalanceUpdated: this.onAmlBalanceUpdated,
        SpotBalanceUpdated: this.onBalanceSpotUpdated,
      };
    },
    getTotalPendingWithdraw(currencies, callback) {
      if (window._.isEmpty(currencies)) {
        return;
      }

      const isContainUsdCurrency = window._.includes(currencies, "usd");

      if (!isContainUsdCurrency || currencies.length > 1) {
        rf.getRequest("TransactionRequest")
          .getTotalPendingWithdraw()
          .then((res) => {
            window._.each(res.data, (item) => {
              this.updateCurrencyPendingWithdraw(item.currency, item.total);
            });
            if (callback) {
              callback();
            }
          });
      }
      // usd currency
      if (isContainUsdCurrency) {
        rf.getRequest("TransactionRequest")
          .getTotalUsdPendingWithdraw()
          .then((res) => {
            if (!res.data.total) {
              return;
            }
            this.updateCurrencyPendingWithdraw("usd", res.data.total);
            if (callback) {
              callback();
            }
          });
      }
    },
    updateCurrencyPendingWithdraw(currency, totalPendingWithdraw) {
      const item = window._.find(
        this.totalPendingWithdraws,
        (item) => item.currency === currency
      );
      if (item) {
        item.total = totalPendingWithdraw || "0";
      } else {
        this.totalPendingWithdraws.push(item);
      }
    },
    validate(item) {
      this.errors.remove("amount" + item.coin);
      this.errors.remove("server" + item.coin);

      let amount = new BigNumber(item.amount || 0);
      let isTransferFromMain = item.isTransferFromMain;

      if (amount.isZero()) {
        this.errors.add(
          "amount" + item.coin,
          window.i18n.t("funds.balances.amount_over_0")
        );
        return false;
      }

      if (amount.lt(MIN_AMOUNT_CRYPTO)) {
        this.errors.add(
          "amount" + item.coin,
          window.i18n.t("funds.balances.min_amount_is_invalid")
        );
        return false;
      }

      let availableBalance = new BigNumber(item.availableBalance);
      let exchangeBalance = new BigNumber(item.exchangeBalance);
      let exchangeAvailabelBalance = new BigNumber(
        item.exchangeAvailabelBalance
      );
      let marginBalance = new BigNumber(item.marginBalance);
      let marginAvailabelBalance = new BigNumber(item.marginAvailabelBalance);
      if (!isTransferFromMain) {
        if (availableBalance.lt(amount)) {
          this.errors.add(
            "amount" + item.coin,
            window.i18n.t(
              "funds.balances.amount_can_not_greater_than_exchange_available_balance_without_orderbook"
            )
          );
          return false;
        }
      } else {
        if (
          item.transferType == TRANSFER_TYPE_EXCHANGE_BALANCE &&
          exchangeBalance.lt(amount)
        ) {
          this.errors.add(
            "amount" + item.coin,
            window.i18n.t(
              "funds.balances.amount_can_not_greater_than_exchange_available_balance_without_orderbook"
            )
          );
          return false;
        }

        // With Spot Exchange has orders in orderbook (orders is not match),
        // balance in orders is locking
        // Need check amount with condition:
        // amount <= exchangeAvailabelBalance  --> ok
        // amount > exchangeAvailabelBalance   --> error
        if (
          item.transferType == TRANSFER_TYPE_EXCHANGE_BALANCE &&
          exchangeAvailabelBalance.lt(amount)
        ) {
          this.errors.add(
            "amount" + item.coin,
            window.i18n.t(
              "funds.balances.amount_can_not_greater_than_exchange_available_balance_without_orderbook"
            )
          );
          return false;
        }

        if (
          item.transferType == TRANSFER_TYPE_MARGIN_BALANCE &&
          marginBalance.lt(amount)
        ) {
          this.errors.add(
            "amount" + item.coin,
            window.i18n.t(
              "funds.balances.amount_can_not_greater_than_margin_available_balance"
            )
          );
          return false;
        }
        if (
          item.transferType == TRANSFER_TYPE_MARGIN_BALANCE &&
          marginAvailabelBalance.lt(amount)
        ) {
          this.errors.add(
            "amount" + item.coin,
            window.i18n.t(
              "funds.balances.amount_can_not_greater_than_margin_available_balance"
            )
          );
          return false;
        }
      }

      return true;
    },
    handleChangeAmount(item) {
      // this.validate(item);
    },
    onPercentClicked(item, percent) {
      let balance = 0;
      this.precentTransfer = percent;
      if (!item.isTransferFromMain) {
        balance = item.availableBalance;
      } else {
        if (item.transferType === "spot") {
          balance = item.exchangeAvailabelBalance;
        } else if (item.transferType === "margin") {
          balance = item.marginAvailabelBalance;
        }
      }
      // }
      item.amount = new BigNumber(balance).mul(percent).div(100).toString();
    },
    transferBalance(e, item) {
      e.preventDefault();

      if (!this.validate(item)) {
        return false;
      }

      if (this.isSubmittingTransfer) {
        return false;
      }
      this.sort = this.$refs.datatable.orderBy
      this.sortType = this.$refs.datatable.sortedBy
      this.hasSortWhenTransfer = true
      this.isSubmittingTransfer = true;
      let amount = item.amount || 0;
      let isTransferFromMain = item.isTransferFromMain;
      let coinSubmitting = item.coin;

      // Build data
      let data = {
        coin_value: amount,
        coin_type: item.coin,
        from: isTransferFromMain
          ? item.transferType
          : TRANSFER_TYPE_MAIN_BALANCE,
        to: isTransferFromMain ? TRANSFER_TYPE_MAIN_BALANCE : item.transferType,
      };

      rf.getRequest("UserRequest")
        .transferBalance(data)
        .then((response) => {

          this.getDataBalance();
          this.updatePNL = !this.updatePNL
          this.isSubmittingTransfer = false;
          if (!response.success || !response.data) {
            this.showErrorMessage(
              window.i18n.t("funds.balances.transfer_balance_fails")
            );
          } else {
            this.showInfoMessage(
              window.i18n.t("funds.balances.transfer_balance_success")
            );
          }
          item.amount = "";
          this.precentTransfer = 0;
          // this.refresh();
        })
        .catch((err) => {
          this.isSubmittingTransfer = false;
          if (err.response && err.response.data && err.response.data.message) {
            this.showErrorMessage(err.response.data.message);
          } else {
            this.showErrorMessage(
              window.i18n.t("funds.balances.transfer_balance_fails")
            );
          }
        });
    },
    swapBalance(e, item) {
      e.preventDefault();
      item.isTransferFromMain = !item.isTransferFromMain;
      this.precentTransfer = 0;
      item.amount = "";
      // this.validate(item);
    },
    showErrorMessage(message, callBack = () => {}) {
      window.MessageWithIcon.error(message);
    },
    showInfoMessage(message, callBack = () => {}) {
      window.MessageWithIcon.success(message);
    },
    getWithdrawalLimitBTC() {
      rf.getRequest("UserRequest")
        .getWithdrawalLimitBTC()
        .then((res) => {
          //this.withdrawalLimitBTC = res.data;
          return res;
        });
    },
    getUserInformation() {
      rf.getRequest("UserRequest")
        .getCurrentUser()
        .then((res) => {
          let securityLevel = res.data.security_level;

          rf.getRequest("UserRequest")
            .getWithdrawalLimitBTC()
            .then((resWithdrawalLimit) => {
              let withdrawalLimitBTC = resWithdrawalLimit.data;
              if (withdrawalLimitBTC) {
                this.limit = window._.find(withdrawalLimitBTC, (wl) => {
                  return (
                    wl.security_level === securityLevel && wl.currency === "btc"
                  );
                });
              }
            });
        });
    },
    handleMasterData() {
      this.currencyCoins = this.masterData.coin_settings;
      this.coinSetting = window._.keyBy(
        this.masterData.coins_confirmation,
        (item) => item.coin
      );
      this.coinsMasterData = window._.keyBy(
        this.masterData.coins,
        (item) => item.coin
      );
      this.coinsMasterData.usd = {
        name: window.i18n.t(`currency.usd.fullname`),
      };

      _.forEach(this.masterData.coins, (item, key) => {
        this.coinNames[item.coin] = item.name;
        this.coinImages[item.coin] = item.icon_image;
      });

      this.coinNames["usd"] = window.i18n.t(`currency.usd.fullname`);
      this.coinImages["usd"] = `/images/color_coins/usd.png`;
      this.coinImages["eth"] = this.ethicon
    },

    getDataBalance() {
      rf.getRequest("UserRequest")
        .getBalance(false)
        .then((res) => {
          this.onBalanceUpdated(res.data);
        });
    },
  },

  async created() {
    if (!this.isAuthenticated) {
      return;
    }
    await Promise.all([
      this.handleMasterData(),
      this.getDataBalance(),
    rf.getRequest("PriceRequest")
        .getPrices()
        .then((res) => {
          this.onPricesUpdated(res.data);
        }),
    ])
    await this.getPriceCoinGecKotoBTC(),
    document.title = this.$t("menu.balances") + ` – ${this.tabTitle}`;
  },
  mounted() {
    this.getUserInformation();
    this.tongglePrice = this.isShowBalances.spotBalance
    this.$on("UPDATED_LOCALE", () => {
      if (this.$refs.datatable) {
        const activeRows = this.$refs.datatable.rows.filter((row) => {
          return row.isActiveRow;
        });
        if (!activeRows) {
          return;
        }
        activeRows.forEach((row) => {
          this.validate(row);
        });
      }
    });
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables";
@import "@/assets/sass/common.scss";
.tool-tip-custom {
  background: var(--color-tooltip);
  border-radius: 8px;
  margin-top: 9px;
  padding: 10px;
  width: 227px;
  height: 56px;
  color: $color-white !important;
}
.min_w150 {
  // min-width: 150px;
  width: 70px;
}
.min_w120 {
  width: 90px;
  min-width: 60px;
}
.max_w180 {
  width: 115px;
}
.min_w100 {
  min-width: 100px;
}
.min_w80 {
  min-width: 80px;
}
.table-history {
  padding-bottom: 15px;
}
.full-page-background {
  width: 100%;
  display: inline-block;
  padding-top: 60px;
}
.number-entry-user {
  text-align: center;
  font-weight: normal;
  .title-entry {
    font-size: 30px;
    font-weight: bold;
  }
}
.error {
  border: solid 1px $color-red-main !important;
}
.invalid-feedback {
  width: 100% !important;
  color: $text-color-red;
  font-size: 14px;
}
#balance {
  .transaction-box {
    margin: 39px 16px;
    padding: 14px 22px;
    font-weight: 400;
    line-height: 21px;
    font-size: 16px;
    background: var(--color-header-table);
    border-radius: 8px;
    height: fit-content;
    cursor: pointer;

    a {
       color: var(--text-primary-color);
    }

    img {
      margin-right: 6px;
    }
  }
  .box-flex-table {
    display: flex;
    align-items: center;
  }
  &.is_no_single_page {
    .balance-container {
      margin: 0;
      .table-container thead th {
        height: 22px;
        border-bottom: 1px solid $color-gray;
        font-size: $font-mini;
      }
    }
  }
  table {
    tbody {
      tr {
        overflow-y: initial;
      }
    }
  }
}
.balance-container {
  display: inline-block;
  width: 100%;
  .box-title {
    margin-bottom: $title-bottom-margin;
    &.box-title-secord {
      margin-top: 35px;
    }
  }
  .title-medium-small {
    font-weight: 600;
    font-size: $font-title-size-medium-2;
  }
}
.balance-info {
  line-height: 25px;
  width: 200px;
  margin-top: 8px;
  margin-left: -2px;
  font-weight: 100;
  font-size: $font-small;
  padding-right: 5px;
  padding-left: 5px;
  position: absolute;
  background: $background-default;
  border: $border_thin;
  box-shadow: 0 2px 10px 0 $border-color-grey;
  border-top: none;
  z-index: 10;
  padding: 5px 10px;
}
label,
input {
  margin: 0;
  padding: 0;
  font-weight: normal;
  color: var(--text-primary-color);
}
.sort_up {
  color: $color-corn-pale !important;
}
.sort_down {
  color: $color-corn-pale !important;
}
.es-toolbar {
  font-size: $font-root;
  margin-bottom: 20px;
  .icon {
    color: $color-grey-dusty;
  }
  .btn-small-icon {
    width: 300px;
    height: 32px;
    margin-right: 10px;
    line-height: 20px;
    cursor: pointer;
    color: $color-grey-dark;
    border-radius: 8px;
    padding: 5px 1px 4px 1px;
    position: relative;
    .checkbox-input {
      width: 21px;
      position: absolute;
      top: 4px;
      left: -4px;
      cursor: pointer;

      input {
        display: none;
      }

      label {
        cursor: pointer;
        position: absolute;
        width: 21px;
        height: 21px;
        top: 4px;
        left: 0px;
        border: 1px solid var(--color-input);
        border-radius: 2px;
        background: var(--color-input);
        &:after {
          content: "";
          position: absolute;
          left: 6px;
          top: 1px;
          width: 6px;
          height: 13px;
          border: solid $color-grey-dark;
          border-width: 0 2px 2px 0;
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
          display: none;
        }
        @media screen and (max-width: 576px) {
          left: 4px;
        }
      }
      input[type="checkbox"]:checked + label:after {
        opacity: 1;
      }
      #checkboxFiveInput {
        display: none;
        &[type="checkbox"]:checked + .button-check {
          background-color: $color-caribbean-green;
        }
        &[type="checkbox"]:checked + .button-check:after {
          display: block;
        }
      }
    }
    #tooltip-target-1 {
      .tool-tip-custom {
        display: none;
      }
      &:hover {
        .tool-tip-custom {
          display: block;
          position: absolute;
          left: -30px;
          top: 16px;
          text-align: left;
          z-index: 100;
        }
      }
    }
    span {
      position: absolute;
      left: 24px;
      top: 8px;
      font-weight: 400;
      font-size: 18px;
      color: var(--text-close);
      @media screen and (max-width: 576px) {
        left: 28px;
      }
    }
  }
  .btn-checkbox {
    width: 70px;
    height: 32px;
    line-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $color-gray;
    cursor: pointer;
    margin-right: 10px;
    margin-left: 10px;
    border-radius: 8px;
    padding: 5px 1px 4px 1px;
    .checkbox-input {
      width: 12px;
      height: 12px;
      margin-right: 5px;
      position: relative;
      top: -1px;
      input {
        display: none;
      }
      label {
        cursor: pointer;
        position: absolute;
        width: 12px;
        height: 12px;
        top: 0;
        left: 0;
        border: 1px solid $color-grey-dusty;
        border-radius: 1px;
        &:after {
          opacity: 0;
          content: "";
          position: absolute;
          width: 12px;
          height: 5px;
          background: transparent;
          top: 0px;
          left: 0px;
          border: 3px solid $color-grey-dusty;
          border-top: none;
          border-right: none;
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }
      }
      input[type="checkbox"]:checked + label:after {
        opacity: 1;
      }
    }
  }
  .btn-convert-icon {
    cursor: pointer;
    border-radius: 8px;
    min-width: 150px;
    height: 32px;
    line-height: 20px;
    border: 1px solid $color-alto;
    cursor: pointer;
    color: $color-grey-dark;
    border-radius: 8px;
    margin-right: 10px;
    padding: 5px 1px 4px 1px;
    span {
      vertical-align: middle;
    }
    .icon-convert {
      font-size: $font-title-size-small;
      margin-right: 5px;
    }
    &:hover {
      color: $color-white;
      background-color: $color-blue-custom;
      border-color: $color-blue-custom;
      .icon {
        color: $color-white;
      }
    }
    pointer-events: none;
    opacity: 0.5;
  }
  .input-search {
    width: 170px;
    height: 32px;
    border: 1px solid $color-gray;
    border-radius: 8px;
    background-color: $color-white;
    &:focus-within {
      border-radius: 8px;
      border: solid 1px $color-jungle-green;
    }
    input {
      background: $color-white;
      font-size: $font-small;
      color: $text-grey;
      font-weight: normal;
      border: none;
      width: calc(100% - 37px);
      height: 30px;
      padding-left: 0;
      float: left;
      line-height: 20px;
      padding-top: 5px;
      padding-bottom: 4px;
      &:focus {
        outline: none;
      }
    }
    .btn-search {
      font-size: $font-title-size-small;
      font-weight: bold;
      display: block;
      text-align: center;
      width: 35px;
      height: 32px;
      .icon-search {
        font-size: $font-big-17;
        line-height: 31px;
      }
    }
  }
  @media screen and (max-width: 576px) {
    margin-top: 62px;
    margin-bottom: 20px;
  }
}
.table-container {
  background: $color-white;
  .tableContainer {
    @media screen and (max-width: 768px) {
      overflow: auto;
      white-space: nowrap;
    }
  }
}
.icon-coin {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  vertical-align: middle;
  object-fit: contain;
}
.group_button_tooltip {
  display: inline-block;
  float: left;
  position: relative;
  // width: 23px;
  .btn-deposit-tooltip {
    display: inline-block;
    float: right;
    height: 23px;
    overflow: hidden;
    width: auto;
    padding: 1px 5px;
    border-radius: 11.5px;
    font-size: 20px;
    font-weight: 600;
    border: 0;
    i.icon-signal2 {
      color: $color-alizarin-crimson;
      position: relative;
      top: 2px;
      margin-right: 2px;
    }
  }
  .tooltip_hover {
    position: absolute;
    width: 165px;
    line-height: 20px;
    padding: 16px 14px 14px 19px;
    color: $color-grey-dark;
    font-size: $font-root;
    font-weight: 500;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.22);
    background-color: $color-white;
    border-radius: 8px;
    right: 100%;
    margin-right: 2px;
    top: -50%;
    margin-top: -7px;
    z-index: 5;
    display: none;
    text-align: left;
    white-space: normal;
  }
  &:hover {
    .tooltip_hover {
      display: block;
    }
  }
}
#balance {
  &.page_container {
    .display-flex {
      display: flex;
      justify-content: space-between;
      padding-right: 260px;
      @media screen and (max-width: 1919px) {
        padding-right: 3%;
      }
      @media screen and (max-width: 650px) {
        padding-right: 3%;
        display: block;
      }
    }
    .balance-container {
      padding: 40px;
      padding-right: 260px;
      @media screen and (max-width: 1700px) {
        padding-right: 46px;
      }
      .box-title {
        color: var(--text-primary-color);
      }
      .input-search {
        position: relative;
        margin-right: 40px;
        input {
          width: 380px;
          height: 42px;
          border-radius: 8px;
          border: solid 1px var(--color-tab-data-table);
          background: var(--color-tab-data-table);
          padding-left: 15px;
          padding-right: 40px;

          @media screen and (max-width: 375px) {
            width: 343px;
          }

          &::placeholder {
            color: var(--text-search);
            font-size: 18px;
          }
        }
        a {
          position: absolute;
          right: 14.5px;
          top: 4px;
          font-size: 20px;
        }
      }
      .table-container {
        th {
          border-right: unset;
          line-height: 20px;
          padding: 14px 0 13px 0;
          font-size: $font-root;
          font-weight: 400;
          color: var(--color-percent);
          background-color: var(--color-header-table);
          &:nth-child(9) {
            padding: 20px 10px 10px 0;
          }
          &.after_none:after {
            display: none;
          }
          &.coin_balances {
            width: 200px;
            padding-left: 15px;
          }
          &.name_blances {
            width: 352px;
          }
          &.totalBalance {
            width: 180px;
          }
          &.availableBalance {
            width: 286px;
          }
          &.exchangeBalance {
            width: 210px;
          }
          &.futureBalances {
            width: 190px;
          }
          &.action {
            width: 80px;
            padding-right: 15px;
          }
          &[data-sort-order="asc"],
          &[data-sort-order="desc"] {
            //color: $color-jungle-green;
          }
        }
        th[data-sort-order="desc"],
        th[data-sort-order="asc"] {
          //color: $color-grey !important;
        }
        tr {
          td {
            // text-align: center;
            font-size: $font-root;
            font-weight: 500;
            color: var(--text-primary-color);
            line-height: 21px;
            padding: 23px 0;
            overflow: visible;
            vertical-align: middle;
            &.coin_balances {
              padding-left: 15px;
            }
            &.action_btn {
              display: flex;
              gap: 12px;
              a {
                  font-weight: 400;
                  font-size: $font-root;
                  line-height: 22px;
                  color: $color-aqua-green;
                }
            }
            &:nth-child(9) {
              padding: 7px 10px 6px 0;
            }
            &:last-child {
              text-align: left;
              padding-right: 15px;
            }
            .icon_balances {
              font-size: 20px;
              color: $color-jungle-green;
              cursor: pointer;
            }
            .icon_balances.active-icon {
              color: $color-eden;
            }
            .btn-lose-color {
              .icon_balances {
                color: $color-grey;
                cursor: not-allowed;
              }
            }
            &.tranfer-cell {
              // padding-left: 6px;
            }
            .btn-transfer {
              display: block;
              margin-left: auto;
              margin-right: auto;
              width: 38px;
              height: 38px;
              line-height: 38px;
              position: relative;
              margin-top: -12px;
              top: 6px;
              cursor: pointer;
              border-top-left-radius: 3px;
              border-top-right-radius: 3px;
              text-align: center;
              background-color: transparent;
            }
            &.acvite_transfer {
              .btn-transfer {
                background-color: $color-bright-gray;
              }
            }
            .btn-mam {
              display: inline-block;
              width: 38px;
              height: 38px;
              line-height: 42px;
              position: relative;
              margin-top: -18px;
              font-size: 11px;
              top: 12px;
              cursor: pointer;
              border-top-left-radius: 3px;
              border-top-right-radius: 3px;
              text-align: center;
              overflow: hidden;
              background-color: transparent;
              margin-left: 3px;
            }
            &.acvite_mam {
              .btn-mam {
                background-color: $color-bright-gray;
              }
            }
          }
          &:hover {
            // background-color: $color-bright-gray;
            td {
              .icon_balances {
                color: $color-eden;
              }
              .btn-lose-color {
                .icon_balances {
                  color: $color-grey;
                  cursor: not-allowed;
                }
              }
            }
          }
        }
        .table_drop_balances {
          position: relative;
          padding: 22px 37px 23px 37px;
          background-color: $color-bright-gray;
          overflow: visible;
          background: var(--color-tranfer);
          .close_tbale {
            position: absolute;
            top: 20px;
            right: 20px;
            display: block;
            width: 20px;
            height: 20px;
            font-size: $font-smaller;
            line-height: 20px;
            text-align: center;
            color: $color-grey-dark;
            cursor: pointer;
            .icon-close::before {
              color: var(--color-close-toast);
            }
          }
          &:hover {
            // background-color: $color-bright-gray;
          }
        }
      }
      @media screen and (max-width: 992px) {
        padding: 20px 16px;
      }
    }
  }
  .group_transfer_form {
    display: block;
    float: left;
    margin-right: 15px;
    img {
      padding-top: 8px;
      cursor: pointer;
    }
    .btn-transfer-form {
      border-radius: 8px;
      border: solid 1px $color-alto;
      background-color: $color-bright-gray;
      height: 40px;
      width: 40px;
      font-size: 20px;
      color: $color-eden;
      padding: 8px;
      overflow: hidden;
      text-align: center;
      &:hover {
        color: $color-bright-gray;
        background-color: $color-eden;
      }
    }
    .btn-send-transfer-form {
      border-radius: 8px;
      border: solid 1px $color-aqua-green;
      background-color: $color-aqua-green;
      height: 40px;
      width: 111px;
      font-size: $font-big;
      font-weight: 400;
      color: $dark-1;
      overflow: hidden;
      text-align: center;
      letter-spacing: 0.9px;
      line-height: 21px;
      &:focus,
      &:hover {
        background-color: $color-aquamarine !important;
        border: solid 1px $color-aquamarine !important;
        color: $dark-1;
      }
    }
    label.txt {
      display: block;
      width: 100%;
      line-height: 20px;
      color: var(--text-primary-color);
      font-size: 18px;
      font-weight: 400;
      height: 20px;
      margin-bottom: 10px;
    }
    .dropdown {
      background-color: $color-gray-alto;
      border-radius: 8px;
    }
    input.input-form {
      border-radius: 8px;
      // border: solid 1px $color-alto;
      background-color: var(--color-tranfer-to);
      border: 1px solid var(--color_transfer_border_to);
      height: 42px;

      padding: 10px 13px;
      font-size: 18px;
      line-height: 22px;
      &::placeholder {
        color: var(--color-pagi);
      }
    }
    select.input-form {
      border-radius: 8px;
      border: solid 1px $color-alto;
      background-color: $color-bright-gray;
      height: 42px;
      font-size: $font-root;
      color: $color-grey-dark;
      padding: 10px 10px;
      line-height: 20px;
      border: unset;
    }
    .w_155 {
      width: 220px;
      border: 1px solid var(--color-border-input);
    }
    .disableCustom {
      background: var(--bgr-btn);
      span {
        color: var(--color-pagi);
        font-size: 18px;
        line-height: 21px;
      }
    }
    .w_220 {
      width: 220px;
    }
    .input-amount {
      position: relative;
      .name_amount {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 18px;
        line-height: 22px;
        text-transform: uppercase;
        color: var(--color-pagi);
      }
      input.input-form {
        padding-right: 40px;
        background-color: var(--color-tranfer-to);
        width: 344px;
        border: 1px solid var(--color-border-input);
      }
      & > input:focus {
        border: 1px solid $color-jungle-green;
      }
    }
  }
  .select-control {
    border-radius: 8px;
    border: unset;
    background-color: var(--color-tranfer-to);
    height: 40px;
    font-size: $font-root;
    // color: var(--color-text);
    padding: 10px 10px;
    line-height: 20px;
    text-align: left;
    .select-icon:before {
      color: $color-grey-dark;
      float: right;
      font-size: 11px;
      line-height: 22px;
      display: inline-block;
      position: absolute;
      top: 10px;
      right: 9px;
    }
    &:hover {
      border-color: $color-jungle-green !important;
    }
  }
  .dropdown-menu {
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.16);
    background-color: $color-white;
    padding: 10px 0;
    .tab-element {
      cursor: pointer;
      display: block;
      width: 100%;
      line-height: 20px;
      padding: 5px 20px;
      font-size: $font-root;
      color: $color-grey-dark;
      &:hover {
        color: $color-jungle-green-light;
      }
    }
    .disabled-item {
      display: none;
      background-color: $disable-color;
      cursor: not-allowed;
      color: white;
      &:hover {
        color: white;
      }
    }
  }
  .input_right_group {
    margin-bottom: 12px;
    width: 100%;
    .check_percent {
      display: inline-block;
      margin-top: 5px;
      width: calc((100% / 4) - 4.5px);
      margin-right: 6px;
      border: 1px solid var(--color_transfer_border_to);
      background-color: var(--color-tranfer-to);
      line-height: 21px;
      text-align: center;
      padding: 5px 5px;
      color: var(--color-percent);
      font-size: $font-root;
      border-radius: 4px;
      height: 32px;
      float: left;
      cursor: pointer;
      border-radius: 4px;
      &.last_item {
        margin-right: 0px;
      }
      &:hover {
        background: $color-aquamarine;
        border-color: $color-aquamarine;
        color: $dark-1;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        font-family: $font-inter-bold;
      }
    }
    .activePrecent {
      background: $color-caribbean-green;
      border-color: $color-caribbean-green;
      color: $dark-1 !important;
      font-weight: 600;
      font-size: $font-root;
      -webkit-transition: 0.5s;
      transition: 0.5s;
    }
  }
}
@media screen and (max-width: 992px) {
  #balance {
    &.page_container {
      .balance-container {
        .title-medium-small {
          font-size: 24px;
          line-height: 29px;
        }
        .table-container {
          th {
            font-size: $font-root;
            &.coin_balances {
              width: 150px;
            }
            &.name_blances {
              width: 200px;
            }
          }

          tr {
            td {
              font-size: 14px;
              line-height: 16px;
              padding: 20px 0px;
            }
          }
          .table_drop_balances {
            padding: 24px 20px;
          }
        }
      }
    }
    .group_transfer_form {
      label {
        &.txt {
          font-size: 14px;
          line-height: 16px;
        }
      }
      input {
        &.input-form {
          font-size: 14px;
          line-height: 16px;
        }
      }
      .disableCustom {
        span {
          font-size: 14px;
          line-height: 16px;
        }
      }
      .w_155 {
        width: 170px;
      }
      .input-amount {
        .name_amount {
          font-size: 14px;
          line-height: 16px;
        }
      }
      .btn-send-transfer-form {
        font-size: 16px;
        line-height: 19px;
      }
    }
  }
  .es-toolbar {
    .btn-small-icon {
      span {
        font-size: 14px;
        line-height: 16px;
        top: 11px;
      }
      .checkbox-input {
        label {
          width: 20px;
          height: 20px;
        }
      }
    }
    .estimate-value {
      .limit {
        flex-direction: column;
        font-size: 14px;
        line-height: 16px;

        .in-use {
          margin: 0;
        }
      }
    }
  }
  .content-spot-overview {
    // position: relative;
    width: 100%;
    z-index: 5;
    border: 0px;
    border-top: none;
    overflow-y: auto;
    overflow-x: auto;
  }
  .table-container {
    min-width: $min-width-table;
  }
  .icon-coin {
    width: 24px;
    height: 24px;
  }
}
</style>

<style lang="scss">
#balance {
  .tableContainer {
    overflow-y: auto;
    table {
      overflow-y: inherit;
    }
    .group-sort-tabl3 {
      display: inline-table !important;
      width: 13px;
      height: 20px;
      vertical-align: middle;
      position: relative;
      margin-top: 5px;
    }
    .icon-sort-table {
      //font-size: $font-big-17;
    }
  }
}
</style>