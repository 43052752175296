<template>
  <div class="swiper" @mouseenter="stopPlay" @mouseleave="startPlay">
    <swiper style="height:320px;" ref="mySwiper" :options="swiperOption">
      <!-- ROE个人 & 团队 -->
      <swiper-slide class="swiper-slide">
        <div class="roe_personal_team" :class="'roe_personal_team_'+$t('i18n.lang')">
          <div class="logo">
            <img src="@/assets/images/CEX/PNG/OGEX White.png" />
          </div>
          <!-- <div class="bonus" v-html="$t('roe_personal_team_slide.bonus')"></div> -->
          <div class="tit" v-html="$t('roe_personal_team_slide.tit')"></div>
          <div class="subtit" v-html="$t('roe_personal_team_slide.subtit')"></div>
          <div class="btn-group">
            <!-- <router-link to="/roe-activity?name=team" class="roe_team">
              {{$t('roe_personal_team_slide.team_btn')}}
            </router-link> -->
            <router-link to="/roe-activity?name=personal" class="roe_personal">
              {{$t('roe_personal_team_slide.personal_btn')}}
            </router-link>
          </div>
          <div class="r-img">
              <img src="@/assets/images/roe/roe-slide.png" alt="demo" />
          </div>
        </div>
      </swiper-slide>
      <!-- MAM -->
      <!-- <swiper-slide class="swiper-slide">
        <router-link to="/mam-detail" class="routerlink">
          <div class="coperation_slide tranding_bonus multi_account_manager" :class="'multi_account_manager_'+$t('i18n.lang')">
            <div class="bonus_txt">
              <div class="bonus_tit">{{ $t('mam_tit') }}</div>
              <div class="bonus_time" v-html="$t('mam_subtit')"></div>
              <div class="bonus_btn">{{ $t('mam_btnTxt')}}</div>
            </div>
            <div class="btc_banner_img"></div>
          </div>
        </router-link>
      </swiper-slide> -->
      <!-- 永续合约 -->
      <swiper-slide class="swiper-slide">
        <router-link to="/contract" class="routerlink yx_slide">
          <div class="coperation_slide tranding_bonus bg_yx">
            <div class="bonus_txt">
              <div class="bonus_tit" v-html="$t('contract_perpetual_tit')"></div>
              <div class="bonus_time" v-html="$t('contract_perpetual_subtit')"></div>
              <div class="bonus_time" v-html="$t('contract_perpetual_time')"></div>
              <div class="bonus_btn" v-html="$t('contract_perpetual_btnText')"></div>
            </div>
          </div>
        </router-link>
      </swiper-slide>
      <!-- 合作伙伴  -->
      <!-- <swiper-slide class="swiper-slide">
        <router-link to="/cooperation" class="routerlink">
          <div class="coperation_slide partner-bg" :class="'partner_slide_'+$t('i18n.lang')">
            <div class="partner_slide">
              <div class="cooperation_tit">
                <p class="co_bg_tit" :class="$t('i18n.lang') == 'zh' ? 'bt_40' : ''" v-html="$t('cooperation_banner_tit')"></p>
                <p class="co_bg_subtit" :class="$t('i18n.lang') == 'zh' ? 'bt_28' : ''" v-html="$t('cooperation_banner_subtit')"></p>
                <p class="co_bg_btn">{{$t('cooperation_banner_btntxt')}}</p>
              </div>
            </div>
          </div>
        </router-link>
      </swiper-slide> -->
      <!-- 超级高返利 -->
      <!-- <swiper-slide class="swiper-slide">
        <router-link to="/inviteAward" class="routerlink">
          <div class="coperation_slide trading_high_rebate">
            <div class="trading_slide_item">
              <div class="slide_l_txt">
                <div class="slide_tit" v-html="$t('high_rebate_banner.rebate_tit')"></div>
                <div class="slide_tit">Demo Referral Program</div>  
                <div class="slide_subtit">{{ $t('high_rebate_banner.rebate_subtit') }}</div>
                <div class="high_rebate_step">
                  <div class="rebate_step" v-for="(item, index) in $t('high_rebate_banner.rebate_step')" :key="index">
                    <div class="step_num">{{ item.num }}</div>
                    <p class="step_desc" v-html="item.step_desc"></p>
                  </div>
                </div>
                <div class="slide_btn">{{ $t('high_rebate_banner.rebate_btn') }}</div>
              </div>
              <div class="slide_r_img">
                <img src="@/assets/images/rebate/rebate_r.png" />
              </div>
            </div>
          </div>
        </router-link>
      </swiper-slide> -->

    </swiper>
    <div class="link-group">
      <div class="swiper-button-prev"></div>
      <div class="swiper-pagination"></div>
      <div class="swiper-button-next"></div>
    </div>
  </div>
</template>
<script>
  import { swiper, swiperSlide } from 'vue-awesome-swiper';
  import 'swiper/dist/css/swiper.css';
  export default {
    components: {
      swiper,
      swiperSlide
    },
    data() {
      return {
        swiperOption: {
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          },
          initialSlide: 0,
          direction: 'horizontal',
          speed: 800,
          autoplay: {
            delay: 5000,
            stopOnLastSlide: false,
            disableOnInteraction: true
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          loop: true
        }
      }
    },
    computed: {
      mySwiper() {
        return this.$refs.mySwiper.swiper
      },
    },
    methods: {
      stopPlay() {
        this.mySwiper.autoplay.stop();
      },
      startPlay() {
        this.mySwiper.autoplay.start();
      }
    },
  }
</script>
<style lang="scss">
  .lading-page .swiper-slide {
    height: 320px;
    border-radius: 20px;
  }
</style>
<style scope lang="scss">
  @import "@/assets/sass/slide/TransitionSlide.scss";
  .swiper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
    .link-group {
      min-width: 40%;
      height: 30px;
      line-height: 30px;
      display: flex;
      flex-direction: row;
      position: absolute;
      bottom: 3px;
      /* left: 50%; */
      z-index: 2;
      right: 0px;
      /* transform: translateX(-50%); */
    }
    .swiper-button-prev,
    .swiper-button-next {
      height: 12px;
      width: 30px;
      background-size: 100%;
      margin-top: 0px;
      transform: translateY(-50%);
      outline: none;
    }
    .swiper-button-next {
      right: 25px;
      background-image: url('@/assets/images/rebate/arrow-right.png');
    }
    .swiper-button-prev {
      left: 24px;
      background-image: url('@/assets/images/rebate/arrow-left.png');
    }
    .swiper-pagination {
      left: 50%;
      transform: translateX(-50%);
      z-index: 4;
      .swiper-pagination-bullet {
        width: 1rem;
        height: 1rem;
        margin: 0px 5px;
        outline: none;
        border: 1px solid #fff;
        background-color: rgba(0, 0, 0, .3);
        opacity: 1;
      }
      .swiper-pagination-bullet-active {
        background-color: #fff;
        border: 1px solid #fff;
      }
    }
    .routerlink {
      display: block;
      width: 100%;
      height: 100%;
    }
    .yx_slide {
      background: linear-gradient(127deg, rgba(117, 20, 134, 1) 0%, rgba(45, 54, 148, 1) 100%);
      border-radius: 10px;
    }
  }
</style>