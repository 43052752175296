<template>
  <div id="overview-detail">
    <div class="content-right-wrapper">
      <div class="estimate-balance">
        <div class="estimate">
          <div class="estimate-btc">
            <p>
            {{$t('funds.withdrawals.total_balance')}}
            </p>
            <span v-if="!tongglePrice">
                <img :src=" require(`../assets/icon/eyes${theme === 'light-theme' ? '' : '-dark'}.svg`)"  @click="clickHide()"
                  width="18"
                  height="10"
                />
              </span>
              <span v-else
                ><img
                :src=" require(`../assets/icon/hidePriceIcon${theme === 'light-theme' ? '' : '-dark'}.svg`)"
                  @click="clickHide()"
                width="18"
                height="10"
                />
              </span>
          </div>
          <div v-if="!tongglePrice" class="price-coin">

            <p>{{ priceCoin | formatNumberAndDecimal}} BTC
            </p>
            <span>≈ <fiat-currency :value="valued">
            </fiat-currency></span>
          </div>
          <div class="price-coin-hide" v-else>***************</div>
        </div>

      </div>

      <div class="title-accounts">{{ $t('accounts') }}</div>

      <div class="box-balance">
        <router-link to="/funds/spot-wallet">
          <div class="recent-box">
              <img src="@/assets/images/spot-icon.svg"/>
              <div>
                <p class="recent-title">{{ $t('menu.wallet.spot') }}</p>
                <div v-if="!tongglePrice">

                  <p class="recent-coin">{{ totalSpotBtcBalance | formatNumberAndDecimal}} BTC
                  </p>
                  <p class="recent-rounding">≈ <fiat-currency :value="totalUsdSpotBalance "></fiat-currency></p>
                </div>
                <div v-else>
                  <p class="recent-coin">***********</p>
                </div>
              </div>
            </div>
        </router-link>
        <router-link to="/funds/futures-wallet">
           <div class="recent-box">
            <img src="@/assets/images/future-icon.svg"/>
            <div>
              <p class="recent-title">{{ $t('menu.wallet.futures')}}</p>
              <div v-if="!tongglePrice">

                <p class="recent-coin">{{ totalFuturesBtcBalance | formatNumberAndDecimal}} BTC</p>
                <p class="recent-rounding">≈ <fiat-currency :value="totalUsdFutureBalance"></fiat-currency></p>
              </div>
              <div v-else>
                <p class="recent-coin">***********</p>
              </div>
            </div>
          </div> 
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex";

import Cookies from 'js-cookie'

export default {
  
  methods: {
    clickHide() {
      this.$emit("clickHide");
    },
    convertCoin(coin) {
      if(coin.toString().length > 10) {
        return coin.toString().slice(0,11) + '... '
      }
      return coin;
    },
    checkDisplayFullCoin(coin) {
      if(coin.toString().length > 10) return true;
      return false;
    }
  },
  props: {
    priceCoin: {
      type: String,
      default: "",
    },
    valued: {
      type: String,
      default: "",
    },
    tongglePrice: {
      type: Boolean,
      default: false,
    },
    totalSpotBtcBalance: {
      type: String,
      default: "",
    },
    totalUsdSpotBalance: {
      type: String,
      default: "",
    },
    totalFuturesBtcBalance: {
      type: String,
      default: "",
    },
    totalUsdFutureBalance: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      theme: Cookies.get("user-theme", { domain: process.env.VUE_APP_PUBLIC_URL }) || 'dark-theme',
    }
  },
  computed: {
    ...mapState({
      userThemeMode: (state) => state.userThemeMode,
      showBalances: (state) =>  state.isShowBalances
    }),
    showBalances() {
      return this.tongglePrice = this.showBalances
    }
  },
  watch: {
    userThemeMode(theme) {
      this.theme = theme;
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/sass/variables";
img {
  cursor: pointer;
}
#overview-detail {
  /* background: var(--background-color-primary); */
  .content-right-wrapper {
    padding-left: 40px;
    padding-right: 260px;
     @media screen and (max-width : 1919px) {
        padding-right: 3%;
      }
    .estimate-balance {
      padding: 40px 0px;
      display: flex;
      justify-content: space-between;
      .estimate {
        margin-bottom: 20px;
       
        .estimate-btc {
          // margin-bottom: 16px;
          p {
            font-weight: 400;
            font-size: 16px;
            // line-height: 24px;
            color: var(--color-percent);

            display: inline-block;
          }
          span {
            margin-left: 10px;
            align-items: center;
            display: inline-flex;
          }
        }
       
        .price-coin {
          position: relative;
          p {
            color: var(--text-primary-color);
            font-weight: 600;
            font-size: 34px;
            margin-bottom: 8px;
            line-height: 40px;
          }
          span {
            color: var(--color-percent);
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
          }
         .unHideCoin {
           display: none;
         }
          p:hover {
            .unHideCoin {
              display: block;
              position: absolute;
              padding: 10px;
              border-radius: 8px;
              background: var(--color-tooltip);
              color: $color-white !important;
              top: -40px;
              font-size: 16px;
            }
          }
        }
        .price-coin-hide {
          font-weight: 600;
          font-size: 34px;
          color: var(--text-primary-color);
          @media screen and (max-width: 992px){
            font-size: 20px;
          }
        }
      }
    }

    .title-accounts {
      font-size: 24px;
      font-weight: 600;
      line-height: normal;
      color: var(--text-primary-color);
      margin-bottom: 20px;
    }

    .box-balance {
      display: flex;
      gap: 22px;
      color: var(--text-primary-color);

      a {
        text-decoration: none;
        color: var(--text-primary-color);
      }
      .recent-box {
        display: flex;
        gap: 30px;
        border-radius: 8px;
        width: 482px;
        padding: 36px 30px;
        background: var(--background-callendar-spot);

        .recent-title {
          font-size: 16px;
          font-weight: 400;
          line-height: normal;
        }

        .recent-coin {
          font-size: 24px;
          font-weight: 700;
          line-height: normal;
        }

        .recent-rounding {
          color: var(--color-percent);
          font-size: 16px;
        }
      }
    }

   @media only screen and (max-width: 1200px) {
        padding-top: 20px;
        .estimate-balance {
          display: unset;
          padding-top: 20px;
        }
    }
    @media only screen and (max-width: 1024px) {
      padding: 20px 32px;
      max-width: 798px;
      .estimate-balance {
        display: unset;
        display: block;
      }
    }
    @media only screen and (max-width: 992px) {
      padding: 20px 16px;
       max-width: 100%;
    }
  }
}
@media screen and (max-width: 992px){
  #overview-detail {
    .content-right-wrapper {
      .estimate-balance {
        padding: 0px;
        .estimate {
          // float: left;
          .estimate-btc {
            margin-bottom: 0;
            p {
              font-size: 14px;
              line-height: 16px;
            }
          }
          img {
            width: 14px;
            height: 8px;
          }
          .price-coin {
            p {
              font-size: 18px;
              line-height: 21px;
            }
            span {
              font-size: 14px;
             line-height: 16px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 860px){
#overview-detail {
  .box-balance {
    flex-wrap: wrap;

    .recent-box {
      padding: 24px !important;
      gap: 20px !important;
      width: 100% !important;
    }
  }
}
}

</style>