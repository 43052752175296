<template>
  <div id="modal">
    <modal :name="modalName" width="560" :enableClose="step === 1">
      <template slot="body" v-if="step === 1">
        <div class="title-success">{{ $t("common.action.confirm") }}</div>
        <div class="d-flex justify-content-between convert-img">
          <div class="d-flex left">
            <img style="margin-right: 12px" v-if="currentPair[type ? 'imgTo' : 'imgFrom'] !== 'usd-img'" width="28" height="28" :src="currentPair[type ? 'imgTo' : 'imgFrom']">
            <img style="margin-right: 12px" v-else width="28" height="28" src="@/assets/images/usd-coin.svg">
            <span>{{ fromValue | formatCurrencyAmount((type ? currency : coin).toLowerCase(), '0')}}</span>
          </div>
          <div class="right">{{currentPair[type ? 'to' : 'from']}}</div>
        </div>
        <div class="text-left img-convert">
          <img width="10" height="17" src="@/assets/images/convert-icon-light.svg">
        </div>
        <div class="text-left img-convert-dark">
          <img width="10" height="17" src="@/assets/images/convert-icon-dark.svg">
        </div>
        <div class="d-flex justify-content-between convert-img">
          <div class="d-flex left">
            <img style="margin-right: 12px" v-if="currentPair[type ? 'imgFrom' : 'imgTo'] !== 'usd-img'" width="28" height="28" :src="currentPair[type ? 'imgFrom' : 'imgTo']">
            <img style="margin-right: 12px" v-else width="28" height="28" src="@/assets/images/usd-coin.svg">
            <span>{{toValue | formatCurrencyAmount((type ? coin : currency).toLowerCase(), '0')}}</span>
          </div>
          <div class="right">{{currentPair[type ? 'from' : 'to']}}</div>
        </div>

        <div class="calculate-convert w-100">
          <div class="d-flex w-100 justify-content-between">
            <div class="left">{{ $t("margin.limit_price") }}</div>
            <div class="right">1 {{coin}} = {{rate}} {{currency}}</div>
          </div>

          <div class="d-flex w-100 justify-content-between">
            <div class="left">{{ $t("convert.payment-method") }}</div>
            <div class="right">{{ $t("convert.spot-wallet") }}</div>
          </div>

          <div class="d-flex w-100 justify-content-between align-items-center">
            <div class="left">{{ $t("convert.estimate-transaction-fee") }}</div>
            <div class="right transaction-fee">
              <span>{{fee | formatFeeAmount((type ? coin : currency).toLowerCase(), '0')}}</span>
              <span>{{type ? coin : currency}}</span>
            </div>
          </div>
          <div class="d-flex w-100 justify-content-between spent-price">
            <div class="left">{{ $t("convert.you-will-spend") }}</div>
            <div class="right position-relative" id="infor2">{{(fromValue) | formatFeeAmount((type ? coin : currency).toLowerCase(), '0')}} {{type ? currency : coin}}
              <div class="position-absolute hover-spend" id="content-infor2" v-if="(toValue - fee).toString().length > 15">{{(toValue - fee)}}</div>
            </div>
          </div>
        </div>
        <button class="btn btn-ok" @click="confirmOrder">
          {{ $t("convert.confirm-order") }}
        </button>
      </template>
      <template slot="body" v-else>
        <div class="d-flex justify-content-center img-success">
          <img :src="require(`@/assets/images/icon/send-mail-success${userThemeMode === 'light-theme' ? '' : '-dark'}.svg`)">
        </div>
        <div class="title-success">{{ $t("convert.limit-order-placed") }}</div>
        <div class="text-center asset-title">
          {{ $t("convert.content-placed-order-success", {fromValue: fromValue, type: type ? currency : coin}) }}
        </div>
        <div class="d-flex justify-content-between align-items-baseline mt-last-content">
          <img width="12" height="12" src="@/assets/images/Lightbulb.svg">
          <div class="text-left asset-title fs16">
           <span v-html="$t('convert.even-limit-price-reached')" ></span>
            <!-- <a class="open-order-list" href="/convert/open-order">{{ $t("convert.open-order-list") }}</a> -->
          </div>
        </div>
        <div></div>
        <button class="btn btn-ok" @click="closeModal">
          {{ $t("common.action.ok") }}
        </button>
      </template>
    </modal>
  </div>
</template>


<script>
import Modal from "@/components/shared_components/common/Modal";
import {mapState} from "vuex";
import rf from "@/request/RequestFactory";
import OrderUtils from "@/common/OrderUtils";

export default {
  name: "ConfirmOrderModal",
  components: {
    Modal,
  },
  data() {
    return {
      modalName : 'ConfirmOrderModal',
      step: 1, // 1: meaning confirmation, 2: meaning confirmed and called API order
    }
  },
  props: {
    rate: {
      type: String,
      default : undefined,
    },
    receive : {
      type: Number,
      default : undefined,
    },
    spent: {
      type: String,
      default : undefined,
    },
    currency: {
      type: String,
      default : 'usd'
    },
    coin: {
      type: String,
      default : 'btc'
    },
    type: {
      type: Boolean,
      default : false
    },
    fee: {
      type: String,
      default : undefined
    },
    currentPair: {
      type: Object,
      default : undefined
    },
    toValue: {
      type: Number,
      default : undefined
    },
    fromValue: {
      type: Number,
      default : undefined
    },
    coinBalance: {
      type: String,
      default : undefined
    },
    currencyBalance: {
      type: String,
      default : undefined
    },
    coinPairSettings : {}
  },
  computed : {
    ...mapState({
      userThemeMode: (state) => state.userThemeMode,
    }),
  },
  methods: {
    closeModal() {
      this.step = 1;
      window.CommonModal.hide('ConfirmOrderModal');
      this.$emit("close-modal");
    },
    confirmOrder() {
      const params = {
        trade_type: this.type ? 'buy' : 'sell',
        type: 'limit',
        market_type: 1,
        quantity: this.type ? this.toValue : this.fromValue,
        currency: this.currentPair.name.split('/')[1].toLowerCase(),
        coin: this.currentPair.name.split('/')[0].toLowerCase(),
        balance: this.currencyBalance,
        price: this.rate,
        total: !this.type ? this.toValue : this.fromValue
      }
      try {
        OrderUtils.validateOrderInput(params, this.coinPairSettings, true);
      } catch (error) {
        this.showInputError(error.message);
        return;
      }
      rf.getRequest('OrderRequest').convertOrder(params).then(res => {
        if (res.success) {
          this.step = 2;
        }
      }).catch(error => {
        if (!error.response) {
          this.showError(window.i18n.t("common.message.network_error"));
        } else {
          this.showInputError(error.response.data.message);
        }
      })
    },
    showInputError(message) {
      Message.errorInputSpot(message, window.i18n.t("tooltip.check-again"), { position: "bottom_left" });
    },
  }
}
</script>

<style lang="scss" scoped>

.btn-ok {
  margin-top: 25px;
  width: 100%;
  background: $color-caribbean-green;
  border-radius: 8px;
  height: 49px;
  padding: 14px 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: $dark-1;
  font-size: 18px;

  &:hover, &:active{
    background: $color-aquamarine ;
    color: $color-grey-dark;
  }
  &:disabled {
    background: $color-aero-blue;
  }
}

#modal {
  font-family: $font-inter-regular;
}
.img-success {
  padding-top: 43px;
  padding-bottom: 34px;
  & > img {
    @media screen and (max-width: 450px) {
      width: 80px;
      height: 80px;
    }
  }
}
.calculate-convert {
  width: 500px;
  border-radius: 8px;
  background: var(--monas-convert-price-background);
  padding: 24px;
  font-family: $font-inter-regular;
  margin-bottom: 30px;
  margin-top: 30px;
  .spent-price {
    .left {
      font-weight: 400;
      font-size: 16px;
      color: var(--text-primary-color);
      margin: 0;

    }
    .right {
      font-weight: 600;
      font-size: 20px;
      color: var(--text-primary-color);
      margin: 0;
      .hover-spend {
        padding: 10px;
        display: none;
        top: -50px;
        right: 0;
        border-radius: 10px;
        color: white;
        background: var(--monas-convert-tooltip);
      }
    }
    div#infor:hover div#content-infor {
      display: block;
    }

    div#infor2:hover div#content-infor2 {
      display: block;
    }
  }
  .transaction-fee {
    background: $color-aqua-green;
    border-radius: 4px;
    width: fit-content;
    padding: 6px 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $color-grey-dark;
    margin-bottom: 12px;
    span:first-child {
      margin-right: 8px;
    }
  }
  .left {
    font-weight: 400;
    font-size: 16px;
    color: var(--monas-convert-color-tab);
    margin-bottom: 12px;
  }
  .right {
    font-weight: 500;
    font-size: 16px;
    color: var(--text-primary-color);
  }
  .left2 {
    font-weight: 400;
    font-size: 20px;
    color: var(--text-primary-color);
    margin-bottom: 12px;
  }
  .right2 {
    font-weight: 600;
    font-size: 20px;
    color: var(--text-primary-color);
  }
  hr {
    margin: 16px 0;
    border-color: var(--monas-convert-hr-color);
  }
}
div#infor:hover div#content-infor {
  display: block;
}

div#infor2:hover div#content-infor2 {
  display: block;
}
.information {
  .content {
    width: 227px;
    height: auto;
    z-index: 1;
    right: 0;
    top: 24px;
    background: rgba(25, 25, 25, 0.8);
    border-radius: 8px;
    padding: 10px;
    color: white;
    display: none;
  }

  .infor-light {
    display: block;
  }
  .infor-dark {
    display: none;
  }

}
.title-coint {
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  color: $color-caribbean-green;
  margin-bottom: 30px;
}
.title-spent {
  font-weight: 400;
  font-size: $font-big;
  line-height: 22px;
  text-align: center;
  color: $dark-2;
  margin-bottom: 8px;

}
.title-success {
  font-weight: 600;
  font-size: 34px;
  margin-bottom: 30px;
  text-align: center;
  color: var(--text-primary-color);
}

.asset-title {
  font-size: $font-big;
  color: var(--monas-convert-asset-title);
  @media screen and (max-width: 450px) {
    font-size: 14px;
    line-height: 16px;
  }
}
.fs16 {
  font-size: $font-medium-bigger!important;
  @media screen and (max-width: 450px) {
    font-size: 14px !important;
    line-height: 16px;
  }
}
.open-order-list {
  color: $color-aqua-green;
}

.convert-img {

  .left {
    font-size: 20px;
    color: var(--text-primary-color);
    font-weight: 600;
    img {
      object-fit: contain;
    }
  }
  .right {
    font-size: 20px;
    color: var(--text-primary-color);
    font-weight: 600;
    img {
      object-fit: contain;
    }
  }
}

.mt-last-content {
  margin-top: 30px;
  @media screen and (max-width: 450px) {
    margin-top: 16px;
    align-items: flex-start !important;
  }
  & > img {
    margin-right: 12px;
    @media screen and (max-width: 450px) {
      margin-right: 8px;
      width: 20px;
      height: 20px;
    }
  }
}
#modal {
  ::v-deep {
    .modal-footer {
      display: none;
    }
  }
}
.img-convert {
  display: flex;
  width: 28px;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}
.img-convert-dark {
  display: none;
  width: 28px;
  margin: 10px 0;
}

.dark-theme {
  .img-convert {
    display: none;
  }
  .img-convert-dark {
    display: flex;
    width: 28px;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 1200px) {
  #modal {
    ::v-deep {
      .modal-body {
        padding: 0 16px 30px 16px;
      }
      .modal-header {
        padding-bottom: 0;
      }
    }
  }

  .img-success {
    padding-top: 30px;
    padding-bottom: 20px;
  }
  .title-success {
    font-size: 20px;
  }
  .title-spent {
    font-size:14px;
  }
  .title-coint {
    font-size: 24px;
  }
  .calculate-convert {
    padding: 24px 16px;
  }

  .btn-go-wallet, .btn-ok {
    margin-top: 30px;
    font-size: 16px;
  }

  .convert-img {
    .left, .right {
      font-size: 16px;
      align-items: center;
    }
  }
  .calculate-convert {
    .left, .right {
      font-size: 14px;
    }
  }

}
</style>