<template>
  <div class="swiper" >
    <swiper ref="mySwiper" :options="swiperOption">
      <swiper-slide v-for="faq in faqs" :key="faq.id" class="swiper-slide position-relative">
        <img  @click="clickLinkFeshdesh(faq.title, faq.id)" :src="require(`@/assets/images/home/freshdesh_img${Math.floor(Math.random() * 3) + 1}.png`)" class="image-freshdesh position-absolute">
<!--        <a class="" :href="articleUrl(faq.title, faq.id)" target="_blank">{{ faq.title}}</a>-->
      </swiper-slide>
      <swiper-slide class="swiper-slide d-flex align-items-center justify-content-center coming-slider">
        <div>Comming soon</div>
      </swiper-slide>
    </swiper>
    <div class="link-group" v-show="faqs.length > 3">
      <div class="swiper-button-prev">
        <img src="@/assets/images/rebate/arrow-right.png">
      </div>
      <div class="swiper-button-next">
        <img src="@/assets/images/rebate/arrow-right.png">
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';

export default {
  components: {
    swiper,
    swiperSlide
  },
  data() {
    return {
      faqs: JSON.parse(window.localStorage.getItem('freshdesh')) || [],
      category_id: null,
      folder_id: null,
      api_key_freshdesh: 'tJtc3rTgLqznixKhT5f', // Replace with your actual API key
      domain_freshdesh: 'monas-help', // Replace with your actual Freshdesk domain name
      category_name_freshdesh: 'General',
      folder_name_freshdesh: 'Announcement',
      // api_key_freshdesh: process.env.VUE_APP_API_KEY_FRESHDESH, // Replace with your actual API key
      // domain_freshdesh: process.env.VUE_APP_DOMAIN_FRESHDESH, // Replace with your actual Freshdesk domain name
      // category_name_freshdesh: process.env.VUE_APP_CATEGORY_NAME_FRESHDESH,
      // folder_name_freshdesh: process.env.VUE_APP_FOLDER_NAME_FRESHDESH,
      swiperOption: {
        slidesPerView: 4,
        spaceBetween: 24,
        initialSlide: 0,
        direction: 'horizontal',
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    };
  },
  methods: {
    clickLinkFeshdesh(title,id) {
      const url = this.articleUrl(title, id);
      window.open(url);
    },
    articleUrl(title, id) {
      const titleLower = title.toLowerCase();
      return `https://${this.domain_freshdesh}.freshdesk.com/${i18n.locale}/support/solutions/articles/${id}-${titleLower.replaceAll(' ','-')}`;
    },
    compareArrays(array1, array3) {
      const array2 = JSON.parse(array3)
      // Check if length of arrays is the same
      if (array1.length !== array2.length) {
        return false;
      }

      // Sort arrays
      array1.sort();
      array2.sort();

      // Compare elements in arrays
      for (let i = 0; i < array1.length; i++) {
        if (!window._.isEqual(array1[i],array2[i])) {
          return false;
        }
      }

      // If all elements in both arrays are equal, they are equal
      return true;
    }
  },
  mounted() {
    const category_name = this.category_name_freshdesh || 'FAQ'; // Replace with the name of the category containing the FAQs
    const folder_name = this.folder_name_freshdesh || 'FAQ'; // Replace with the name of the folder containing the FAQs

    // First, retrieve the category ID
    axios.get(`https://${this.domain_freshdesh}.freshdesk.com/api/v2/solutions/categories`, {
      headers: {
        Authorization: `Basic ${btoa(this.api_key_freshdesh + ':x')}`,
        'Content-Type': 'application/json',
      },
    })
        .then((response) => {
          let category = response.data.find((category) => category.name === category_name);
          if(!category) {
            category = response.data[0];
          }
          if (category) {
            this.category_id = category.id;

            // Second, retrieve the folder ID
            axios.get(`https://${this.domain_freshdesh}.freshdesk.com/api/v2/solutions/categories/${this.category_id}/folders`, {
              headers: {
                Authorization: `Basic ${btoa(this.api_key_freshdesh + ':x')}`,
                'Content-Type': 'application/json',
              },
            })
                .then((response) => {
                  let folder = response.data.find((folder) => folder.name === folder_name);
                  if(!folder) {
                    folder = folder.data[0];
                  }
                  if (folder) {
                    this.folder_id = folder.id;

                    // Finally, retrieve the list of FAQs in the folder
                    axios.get(`https://${this.domain_freshdesh}.freshdesk.com/api/v2/solutions/folders/${this.folder_id}/articles`, {
                      headers: {
                        Authorization: `Basic ${btoa(this.api_key_freshdesh + ':x')}`,
                        'Content-Type': 'application/json',
                      },
                    })
                        .then((response) => {
                          let cacheData =  JSON.parse(window.localStorage.getItem('freshdesh'));

                          if(!cacheData) {
                            window.localStorage.setItem('freshdesh', JSON.stringify(response.data));
                            this.faqs = response.data;
                          } else {
                            const checkEqual = this.compareArrays(cacheData, JSON.stringify(response.data));
                            if (!checkEqual) {
                              this.faqs = response.data;
                            }
                          }
                        })
                        .catch((error) => {
                          console.error(error);
                        });
                  } else {
                    console.error(`Folder '${folder_name}' not found`);
                  }
                })
                .catch((error) => {
                  console.error(error);
                });
          } else {
            console.error(`Category '${category_name}' not found`);
          }
        })
        .catch((error) => {
          console.error(error);
        });
  },
};
</script>

<style lang="scss" scoped>
.swiper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
.link-group {
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}
  .coming-slider {
    background: $color-gray-dark;
    color: $color-ebony-clay;
  }
.swiper-slide {
  height: 184px;

  border-radius: 10px;
  a {
    top: 15px;
    left: 15px;
    position: absolute;
    z-index: 3;
    color: white;
  }

  .image-freshdesh {
    border-radius: 10px;
    top: 0;
    left: 0;
    z-index: 2;
  }
}
.swiper-button-prev,
.swiper-button-next {
  height: 38px;
  width: 38px;
  background-size: 100%;
  margin-top: 0px;
  transform: translateY(-50%);
  outline: none;
  background: #33333340;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.swiper-button-next {
  right: 25px;
  img {
    height: 15px;
    width: 15px;
  }
}
.swiper-button-prev {
  left: 24px;
  img {
    height: 15px;
    width: 15px;
    transform: rotate(180deg);
  }
}
}
</style>