 
<template>
  <div class="spot-overview">
    <div class="right-content-wrapper">
      <div class="info-coin">
        <div class="info-coin-left">
          <p class="est-value">
             {{ $t(estimatedTotal) }}
          </p>
          <span v-if="!tongglePrice"
              ><img
               :src=" require(`../assets/icon/eyes${theme === 'light-theme' ? '' : '-dark'}.svg`)"
                 @click="clickHide()"
              />
            </span>
            <span v-else
              ><img
              :src=" require(`../assets/icon/hidePriceIcon${theme === 'light-theme' ? '' : '-dark'}.svg`)"
                 @click="clickHide()"
              />
            </span>
          <div v-if="!tongglePrice">
            <p class="price-coin">{{ totalSpotBtcBalance | formatNumberAndDecimal}} BTC</p>
            <p class="rounding">≈ {{ totalSpotUsdBalance | formatNumberAndDecimal}} USDT</p>
          </div>
          <div v-else class="hidePrice">***********</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "../component/Header.vue";
import {mapState} from "vuex";
import Cookies from 'js-cookie'

export default {
  data() {
    return {
      theme: Cookies.get("user-theme", { domain: process.env.VUE_APP_PUBLIC_URL }) || 'dark-theme',
    };
  },
  methods: {
    clickHide() {
      this.$emit("clickHide");
    },
  },
  components: {
    Header,
  },
  props: {
    totalSpotBtcBalance: {
      type: String,
      default: "",
    },
    totalSpotUsdBalance: {
      type: String,
      default: "",
    },
    tongglePrice: {
      type: Boolean,
      default: false,
    },
    estimatedTotal: {
      type: String,
      default: 'funds.withdrawals.total_balance',
    },
  },
  computed: {
    ...mapState({
      userThemeMode: (state) => state.userThemeMode,
    }),
  },
  watch: {
    userThemeMode(theme) {
      this.theme = theme;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables";
img {
  cursor: pointer;
}
.spot-overview {
  .right-content-wrapper {
    padding: 40px 40px 60px 40px;
    h1 {
      margin-bottom: 26px;
      font-weight: 600;
      font-size: 34px;
      color: $color-grey-dark;
    }
    .info-coin {
      display: flex;
      justify-content: space-between;

      .info-coin-left {
        p {
          margin-bottom: 0px;
        }
        .est-value {
          font-weight: 400;
          font-size: 16px;
          margin-bottom: 16px;
          display: inline-block;
          color: var(--text-close);
          opacity: 0.8;
        }
        span {
            margin-left: 14px;
            align-items: center;
            display: inline-flex;
            img {
              width: 22px;
              height: 12px;
              @media screen and (max-width: 992px){
                width: 14px;
                height: 8px;
              }
            }
          }
        .price-coin,
        .hidePrice {
          font-weight: 600;
          font-size: 34px;
          margin-bottom: 8px;
          color: var(--text-primary-color);
          line-height: 40px;
          @media screen and (max-width:992px){
            font-size: 20px;
          }
        }
        .rounding {
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          color: var(--color-percent);
        }
      }
    }
   
  }
  @media screen and (max-width: 992px){
    .right-content-wrapper {
      padding: 30px 16px; 
      .info-coin {
        .info-coin-left {
          .est-value {
            font-size: 16px;
            line-height: 19px;      

            img {
              width: 19px;
              margin-left: 14px;
            }
          }
          .price-coin {
            font-size: 20px;
            line-height: 24px;
          }
          .rounding {
            font-size: 16px;
            line-height: 19px;    
          }
        }
      }
    }
   
  }
}
</style>