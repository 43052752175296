import IconYourLink from '../assets/images/imgYourLink.svg';
import IconInvite from '../assets/images/imgInvite.svg';
import IconReward from '../assets/images/imgReward.svg';


export const referral_program_box = [
    {
        id : 1 ,
        img : IconYourLink,
        title : '1. ',
        muti : "refferal.title1",
        content : "refferal.text1"
    },
    {
        id : 2 ,
        img : IconInvite,
        title : '2. ',
        muti : "refferal.text2",
        content : "refferal.text3"
    },
    {
        id : 3 ,
        img : IconReward,
        title : '3. ',
        muti : "refferal.get_rewards",
        content : "refferal.text5"
    },


]