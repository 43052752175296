<template>
  <div class="WithdrawConfirmModal">
    <modal :name="name">
      <div slot="body">
        <div class="modal-title">{{ $t("withdraw_confirm.modal.title") }}</div>
        <div class="modal-info">
          <div class="modal-coin">
            <div class="title">{{ $t("funds.history.coin") }}</div>
            <div class="value">
              <img
                class="option__image icon-choice"
                :src="this.selectedCoin.iconCurrency"
                :alt="this.selectedCoin.shortNameCurrency"
              />
              <div>{{ this.selectedCoin.shortNameCurrency }}</div>
            </div>
          </div>
          <div class="modal-address">
            <div class="title">{{ $t("withdraw_confirm.modal.address") }}</div>
            <div class="value">{{ this.params.blockchain_address }}</div>
          </div>
          <div class="modal-network">
            <div class="title">{{ $t("withdraw_confirm.modal.network") }}</div>
            <div class="value">
              {{ params.network || selectedCoin?.network }}
            </div>
          </div>
          <div class="modal-received-amount">
            <div class="title">
              {{ $t("withdraw_confirm.modal.received_amount") }}
            </div>
            <div class="value">{{ Math.abs(this.params.amount) }}</div>
          </div>
        </div>
        <div class="modal-tips">
          <ul>
            <li>{{ $t("withdraw_confirm.modal.tips_1") }}</li>
            <li>{{ $t("withdraw_confirm.modal.tips_2") }}</li>
            <li>{{ $t("withdraw_confirm.modal.tips_3") }}</li>
            <li>{{ $t("withdraw_confirm.modal.tips_4") }}</li>
            <li>{{ $t("withdraw_confirm.modal.tips_5") }}</li>
          </ul>
        </div>
        <div class="agreement">
          {{ $t("withdraw_confirm.modal.agreement") }}
        </div>
        <button class="agree" @click="agreeWithdrawConfirm()">
          {{ $t("withdraw_confirm.modal.agree") }}
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/shared_components/common/Modal";

export default {
  components: {
    Modal,
  },
  props: {
    name: { type: String, default: "WithdrawConfirmModal" },
    selectedCoin: { type: Object, default: {} },
    params: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    agreeWithdrawConfirm() {
      CommonModal.hide("WithdrawConfirmModal");
      CommonModal.show("SafetyVerificationModal");
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.WithdrawConfirmModal {
  .modal .modal-dialog .modal-content {
    .modal-header {
      padding: 30px !important;

      @media screen and (max-width: 800px) {
        padding: 15px !important;
      }
    }

    .modal-body {
      .modal-title {
        color: $color-black;
        font-size: 30px;
        font-weight: 600;
        margin-top: -46px;
        text-align: center;
      }

      .modal-info {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-top: 40px;

        & > div {
          align-items: center;
          display: flex;
          justify-content: space-between;

          .title {
            color: $color-gray-dark;
            font-size: 16px;
          }

          .value {
            color: $color-grey-dark;
            font-size: 16px;
            font-weight: 600;
          }
        }

        .modal-network .value {
          text-transform: capitalize;
        }

        .modal-coin {
          .value {
            display: flex;
            gap: 10px;

            img {
              height: auto;
              width: 26px;
            }
          }
        }

        .modal-address {
          align-items: start;

          .value {
            text-align: right;
            width: 250px;
            word-break: break-word;
          }
        }
      }

      .modal-tips {
        background-color: $color-alabaster;
        border-radius: 8px;
        margin-top: 30px;

        ul {
          display: flex;
          flex-direction: column;
          gap: 16px;
          list-style-type: disc;
          padding: 15px 15px 15px 33px;

          li {
            color: $color-gray-dark;
            font-size: 16px;
          }
        }
      }

      .agreement {
        color: $color-grey-dark;
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
        margin-top: 30px;
        text-align: center;
        font-family: $font-inter-medium;
      }

      .agree {
        color: $color-grey-dark;
        background-color: $color-caribbean-green;
        border-radius: 8px;
        font-size: 16px;
        line-height: 21px;
        margin-top: 15px;
        padding: 14px 22px;
        width: 100%;

        &:hover {
          opacity: 0.8;
        }
      }
      height: 76vh;
      overflow: auto;

      .modal-title {
        margin-top: -4px;
      }
    }
  }
}
.dark-theme .WithdrawConfirmModal {
  .modal .modal-dialog .modal-content {
    // background-color: $color-night;

    .modal-body {
      .modal-title {
        color: $color-white;
      }

      .modal-info {
        & > div {
          .title {
            color: $color-auro-metal;
          }

          .value {
            color: $color-white;
          }
        }
      }

      .modal-tips {
        background-color: $color-black-shark;

        ul {
          li {
            color: $color-auro-metal;
          }
        }
      }

      .agreement {
        color: $color-white;
      }
    }
  }
}
</style>
