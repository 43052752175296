<template>
  <div class="header-overview">
    <p>{{ $t(nameTab) }}</p>
    <!-- <SideBarMobile class="sideBarMobile" /> -->
    <!-- <img class="title-img" :src="require (`@/assets/images/wallet/${sizeScreen > 992 ? 'bgr-wallet.png': 'bgr-wallet-mobile.svg'}`)" alt="" /> -->
    <div class="action-overview">
      <router-link
        to="/funds/deposits-wallet?coin=usdt"
        :class="['button-action', 'button-deposit']"
      >
        {{ $t("funds.balances.deposit") }}
      </router-link>

      <router-link to="/funds/transfer?symbol=usdt" class="button-action">
        {{ $t("funds.balances.tranfer") }}
      </router-link>
      <router-link to="/funds/withdraw-wallet?coin=usdt" class="button-action">
        {{ $t("m_funds.coin_info.withdraw") }}
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      nameTab: "overview_title",
      sizeScreen: window.innerWidth,
    };
  },
  methods: {
    resizeHandler() {
      this.sizeScreen = window.innerWidth;
    },
  },
  mounted() {
    if (this.$route.path == "/funds/history-wallet") {
      this.nameTab = "funds.history.transaction_history";
    } else if (this.$route.path == "/funds/spot-wallet") {
      this.nameTab = "funds.history.spot_overview";
    } else if (this.$route.path == "/funds/futures-wallet") {
      this.nameTab = "funds.history.future_overview";
    }
    window.addEventListener("resize", this.resizeHandler);
  },
};
</script>
<style lang="scss">
@import "@/assets/sass/variables";
.header-overview {
  // background-image: url("../../../assets/images/Menu_Mob.png") ;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background: var(--bgr-order);
  align-items: center;
  padding-right: 50px;

  .action-overview {
    display: flex;
    gap: 16px;

    a {
      text-decoration: none;
      color: #333 !important;
      line-height: 21px;
      font-size: 16px;
      font-weight: 400;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .button-action {
    border-radius: 8px;
    padding: 14px 22px;
    cursor: pointer;
    background: $color-gray-alto;
    height: fit-content;
    height: 50px;
  }

  .button-deposit {
    background: $color-caribbean-green;
  }

  .sideBarMobile {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 30px;
  }
  p {
    padding: 40px;
    margin-bottom: 0px;
    font-weight: 600;
    font-size: 34px;
    color: $color-white;
    line-height: 40px;
    font-family: $font-inter-bold;
  }
  .btn {
    background-color: $color-caribbean-green;
    border-radius: 8px;
    &:not(:last-child) {
      margin-right: 15px;
    }
  }
  img {
    margin-right: 107px;
  }
  @media screen and (max-width: 992px) {
    width: 100%;
    img {
      margin-right: 0px;
    }
    p {
      font-size: 24px;
      line-height: 29px;
      padding: 20px 0 20px 16px;
    }
  }
  @media screen and (max-width: 768px) {
    height: fit-content;
    position: relative;
    padding-bottom: 24px;
    padding-left: 24px;

    img {
    }
  }
  @media screen and (max-width: 576px) {
    // p {
    //   white-space: nowrap;
    //   position: absolute;
    //   z-index: 100;
    // }
    img {
      position: absolute;
      right: 0;
    }
  }
  @media screen and (max-width: 375px) {
    img {
      right: 0;
    }
  }
}
</style>
