<template>
  <div id="policy-page">
    <div class="title-policy">
      <div class="t-title">
        <p>{{ $t('policy.title') }}</p>
        <img src="@/assets/images/about/bgr-policy.svg" />
      </div>
    </div>
    <div class="main-policy">
        <div class="t-bold-policy">{{ $t('policy.text_bold_1') }}</div>
        <p class="text-sub">{{ $t('policy.sub') }}</p>
        <div class="para-policy">
          <div class="t-bold-policy">{{ $t('policy.text_bold_2') }}</div>
          <ul>
            <li>{{ $t('policy.list_policy.parties_1') }}</li>
            <li>{{ $t('policy.list_policy.parties_2') }}</li>
            <li>{{ $t('policy.list_policy.parties_3') }}</li>
          </ul>
        </div>

        <div class="para-policy">
          <div class="t-bold-policy">{{ $t('policy.text_bold_3') }}</div>
          <div class="text-purpose">{{ $t('policy.list_purposes') }}</div>
        </div>
       
        <div class="para-policy">
          <div class="t-bold-policy">{{ $t('policy.text_bold_4') }}</div>
          <ul>
            <li>{{ $t('policy.list_consent.consent_1') }}</li>
            <li>{{ $t('policy.list_consent.consent_2') }}</li>
            <li>{{ $t('policy.list_consent.consent_3') }}</li>
            <li>{{ $t('policy.list_consent.consent_4') }}</li>
            <li>{{ $t('policy.list_consent.consent_5') }}</li>
          </ul>
        </div>

        <div class="para-policy">
          <div class="t-bold-policy">{{ $t('policy.text_bold_5') }}</div>
          <ul>
            <li>{{ $t('policy.list_collect.collect_1') }}</li>
            <li>{{ $t('policy.list_collect.collect_2') }}</li>
            <li>{{ $t('policy.list_collect.collect_3') }}</li>
            <li>{{ $t('policy.list_collect.collect_4') }}</li>
            <li>{{ $t('policy.list_collect.collect_5') }}</li>
            <li>{{ $t('policy.list_collect.collect_6') }}</li>
          </ul>
        </div>

        <div class="para-policy">
          <div class="t-bold-policy">{{ $t('policy.text_bold_6') }}</div>
          <ul>
            <li>{{ $t('policy.list_cookies.cookies_1') }}</li>
            <li>{{ $t('policy.list_cookies.cookies_2') }}</li>
            <li>{{ $t('policy.list_cookies.cookies_3') }}</li>
          </ul>
        </div>

        <div class="para-policy">
          <div class="t-bold-policy">{{ $t('policy.purpose_inform') }}</div>
          <ol>
            <li>{{ $t('policy.purpose_inform_1') }}</li>
            <li>{{ $t('policy.purpose_inform_2') }}</li>
            <li>{{ $t('policy.purpose_inform_3') }}</li>
            <li>{{ $t('policy.purpose_inform_4') }}</li>
            <li>{{ $t('policy.purpose_inform_5') }}</li>
            <li>{{ $t('policy.purpose_inform_6') }}</li>
            <li>{{ $t('policy.purpose_inform_7') }}</li>
            <li>{{ $t('policy.purpose_inform_8') }}</li>
            <li>{{ $t('policy.purpose_inform_9') }}</li>
            <li>{{ $t('policy.purpose_inform_10') }}</li>
           
          </ol>
        </div>

        <div class="para-policy">
          <div class="t-bold-policy">{{ $t('policy.protection_personal') }}</div>
          <ul>
            <li>{{ $t('policy.protection_personal_1') }}</li>
            <li>{{ $t('policy.protection_personal_2') }}</li>
            <li>{{ $t('policy.protection_personal_3') }}</li>
            <li>{{ $t('policy.protection_personal_4') }}</li>
            <li>{{ $t('policy.protection_personal_5') }}</li>
            <li>{{ $t('policy.protection_personal_6') }}</li>
            <li>{{ $t('policy.protection_personal_7') }}</li>
            <li>{{ $t('policy.protection_personal_8') }}</li>
          </ul>
        </div>

        <div class="para-policy">
          <div class="t-bold-policy">{{ $t('policy.modification') }}</div>
          <ul>
            <li>{{ $t('policy.modification_1') }}</li>
          </ul>
        </div>

        <div class="para-policy">
          <div class="t-bold-policy">{{ $t('policy.communication') }}</div>
          <ul>
            <li>{{ $t('policy.communication_1') }}</li>
            <li>{{ $t('policy.communication_2') }}</li>
            <li>{{ $t('policy.communication_3') }}</li>
          </ul>
        </div>

        <div class="para-policy para-disclaim">
          <div class="t-bold-policy">{{ $t('policy.disclaimers') }}</div>
          <ul>
            <li>{{ $t('policy.disclaimers_content_1') }}</li>
            <li>{{ $t('policy.disclaimers_content_2') }}</li>
          </ul>
        </div>

        <p class="t-bold-policy mt-10">{{ $t('policy.team') }}</p>
    </div>
    
  </div>
</template>

<script>
export default {
  data() {
    return {
     
  
      // urlTerms: `${window.location.origin}/terms`
    };
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.scrollToTop);
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    document.title = this.$t("menu.privacy") + ` – ${this.tabTitle}`;
    window.addEventListener('beforeunload', this.scrollToTop);
  }
};
</script>
<style lang="scss">
@import "@/assets/sass/variables";
.mt-10{
  margin-top: 20px;
}
.dark-theme {
  #policy-page {
    background-color: $color-night;
    .title-policy{
      background: $color-black-shark;
      border-top: 4px solid $color-black;
    }
  }
}
#policy-page {
  background-color: $color-white;
  .title-policy {
    background: $color-eerie-black;

    .t-title{
      margin: 0 auto;
      padding: 0;
      max-width: 1440px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;
      p {
        color: $color-white;
        font-size: 34px;
        line-height: 40px;
        font-family: $font-inter-bold;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
      }
    }
  }
  .main-policy {
    margin: 0 auto;
    padding: 50px 0px 120px;
    max-width: 1440px;
    font-size: 16px;
    line-height: 19px;  

    .t-bold-policy {
      font-size: 16px;
      line-height: 19px;
      color: var(--text-primary-color);
      font-family: $font-inter-bold;
    }
    .text-sub {
      color: var(--text-close);
      margin: 8px 0 0;
    }
    .para-disclaim {
      & ul{
        padding-left: 0 !important;
          li{
          list-style: none !important;
        }
      } 
    }
    .para-policy {
      padding: 24px 0 0;

      & ul {
        padding: 8px 0 0px 17px;
        margin: 0;
        li {
          color: var(--text-close);
          list-style: decimal;
          text-align: justify;
        } 
      } 
      & ol{
        counter-reset: item;
        margin-top: 8px;
        li {
            display: block;
            margin-left: 1.7em;
            color: var(--text-close);
            text-align: justify;
            &::before {
                content: counter(item) ". ";
                counter-increment: item;
                position: absolute;
                margin-left: -1.6em;
            }
        }
      }
      .text-purpose {
        color: var(--text-close);
        padding-top: 8px;
        text-align: justify;
      }
    }
  }
}
@media screen and (max-width: 1500px) {
  #policy-page {
    .main-policy {
      padding: 40px 16px;
    }
    .title-policy {
      padding-left: 19px;
    }
  }
}
@media(max-width: 992px){
  #policy-page {
    .main-policy {
      padding: 30px 15px;
      font-size: 14px;
      line-height: 16px;
    }
    .title-policy {
      .t-title {
        max-height: 80px;
        p {
          font-size: 24px;
          line-height: 29px;
          left: 16px;
        }
        img {
            width: 222px;
          }
      }
    }
  }
}
</style>
