<template>
  <div class="">
    <div class="positionLeverageIndicator">
      <span class="initialLeverageIcon tooltipWrapper">
        <i class="icon icon-shield2" v-bind:class="{isVisiHidden: (leverageComputed < 15)}"></i>
      </span>
      <div class="indicatorGraph">
        <div class="lineIndicator" v-bind:style="{left: leverageComputed + '%'}">
          <span class="valueLabel">
            <span :key="index" v-if="position" v-bind:style="{'margin-left': leverageComputed > 66.67 ? '-60px' : '0px'}">
              <span v-if="(position.is_cross || !position.leverage) != 0">
                0.00x
              </span>
              <span v-else>
                {{ Number(position.leverage).toFixed(2) }}x
              </span>
            </span>
          </span>
        </div>
      </div>
      <span class="indicatorLabel" v-bind:class="{isVisi: (leverageComputed < 15)}">{{ $t('margin.leverage') }}</span>
      <span class="maintLeverageIcon tooltipWrapper tooltipWarning">
        <i class="icon icon-warning2" v-bind:class="{isVisiHidden: (leverageComputed > 85)}"></i>
        <span class="tooltipWarningText">{{ $t('margin.maximum_leverage') }}: {{ (1 / this.position.required_init_margin_percent) | formatMarginNumber(2) }}x</span>
      </span>
    </div>
    <div class="clearfix"></div>
    <div class="leverageWrapper" v-if="!easyMode">
      <div class="leverage">
        <div class="orderControlsInput slider">
          <div class="rc-slider rc-slider-with-marks">
            <div class="rc-slider-step">
              <span class="rc-slider-dot"
                v-for="item in SliderHandle"
                :key="item.number"
                v-on:click="activeSliderHandle(item)"
                v-bind:style="{left: item.Left +'%'}"></span>
            </div>
            <div role="slider" class="rc-slider-handle" v-bind:style="{left: SliderComputedAcc.Left + '%'}"></div>
            <div class="rc-slider-mark">
              <span class="rc-slider-mark-text"
                v-for="item in SliderComputed"
                :key="item.number"
                v-on:click="activeSliderHandle(item)"
                v-bind:class="{'active': SliderComputedAcc.number === item.number}"
                v-bind:style="{left: item.Left +'%', marginLeft: item.MarginLeft + 'px'}">
                <span v-if="item.number">{{ item.number | formatMarginNumber(2) }}x</span>
                <span v-else>{{ $t('margin.cross') }}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="edit_leverage">
        <span class="btn" @click="isEditing = !isEditing"><i class="icon icon-edit"></i></span>
      </div>
    </div>
    <div v-else class="rc-slider-mark-text_1">
      {{ $t('margin.cross') }}
    </div>
    <div v-if="isEditing" class="flexBody_lever clearfix">
      <label for="leverage">{{ $t('margin.leverage') }}</label>
      <button class="btn btn-success" type="submit" @click="editLeverage"><i class="icon icon-check2"></i></button>
      <button class="btn btn-danger" type="button" @click="isEditing = !isEditing"><i class="icon icon-close"></i></button>
      <!-- <input v-model="leverage" type="number" id="leverage" autocomplete="off" class="form-control valid" min="0" max="100" name="leverage" placeholder="" step="0.01"> -->
      <currency-input v-model="leverage" :precision="2" id="leverage" autocomplete="off" class="form-control valid" name="leverage"/>
    </div>
    <div v-show="errors.has('max_leverage')" class="invalid-feedback">{{ errors.first('max_leverage') }}</div>
  </div>
</template>

<script type="text/javascript">
  import BigNumber from 'bignumber.js';
  import { mapGetters, mapMutations, mapActions } from 'vuex';

  export default {
    components: {

    },
    computed: {
      ...mapGetters({ position: 'mamPosition' , currentInstrument: 'currentInstrument', currentSymbol: 'currentSymbol', easyMode: 'easyMode' }),
      leverageComputed: function () {
        let multiples = 1;
        if (this.position && this.position.required_init_margin_percent && this.currentInstrument && this.currentInstrument.maint_margin) {
          multiples = (parseFloat(this.position.required_init_margin_percent) - parseFloat(this.currentInstrument.maint_margin)) / parseFloat(this.currentInstrument.maint_margin);
        }

        if ((this.position && this.position.is_cross) == 1) return 0;
        let divide = 2;
        if (this.currentSymbol && this.currentSymbol.toString().includes('BTCUSD')) {
          divide = 4
        }
        return this.position && this.position.leverage ? this.position.leverage / divide * multiples : 0;
      },
      SliderComputed: function () {
        if (!this.position.required_init_margin_percent || this.position.required_init_margin_percent == 0) {
          return this.SliderHandle;
        }

        if (this.currentSymbol && this.currentSymbol.toString().includes('ETH')) {
          let sixthLevel;
          let seventhLevel;
          let maxLeverage = 1 / this.position.required_init_margin_percent;
          if (maxLeverage > 40) {
            sixthLevel = 25;
            seventhLevel = 35;
          } else if (maxLeverage > 30) {
            sixthLevel = 10;
            seventhLevel = 20;
          } else if (maxLeverage > 20) {
            sixthLevel = 10;
            seventhLevel = 20;
          } else if (maxLeverage > 15) {
            sixthLevel = 10;
            seventhLevel = 15;
          } else if (maxLeverage > 10) {
            sixthLevel = 7;
            seventhLevel = 10;
          } else {
            sixthLevel = 6;
            seventhLevel = 9;
          }
          this.setLeverageLevels([0, 1, 2, 3, 4, 5, sixthLevel, seventhLevel, maxLeverage])
        } else if (this.currentSymbol && this.currentSymbol.toString().includes('BTC')) {
          let sixthLevel;
          let seventhLevel;
          let maxLeverage = 1 / this.position.required_init_margin_percent;
          if (maxLeverage > 50) {
            sixthLevel = 25;
            seventhLevel = 50;
          } else if (maxLeverage > 40) {
            sixthLevel = 25;
            seventhLevel = 35;
          } else if (maxLeverage > 35) {
            sixthLevel = 25;
            seventhLevel = 33;
          } else if (maxLeverage > 30) {
            sixthLevel = 20;
            seventhLevel = 25;
          } else if (maxLeverage > 20) {
            sixthLevel = 15;
            seventhLevel = 20;
          } else {
            sixthLevel = 12;
            seventhLevel = 15;
          }
          this.setLeverageLevels([0, 1, 2, 3, 5, 10, sixthLevel, seventhLevel, maxLeverage])
        } else {
          let sixthLevel;
          let seventhLevel;
          let maxLeverage = 1 / this.position.required_init_margin_percent;
          if (maxLeverage > 10) {
            sixthLevel = 5;
            seventhLevel = 10;
          } else if (maxLeverage > 8) {
            sixthLevel = 5;
            seventhLevel = 7.5;
          } else if (maxLeverage > 5) {
            sixthLevel = 4;
            seventhLevel = 5;
          } else if (maxLeverage > 4) {
            sixthLevel = 3.5;
            seventhLevel = 4;
          } else {
            sixthLevel = 3.3;
            seventhLevel = 3.6;
          }
          this.setLeverageLevels([0, 1, 1.5, 2, 2.5, 3, sixthLevel, seventhLevel, maxLeverage])
        }

        return this.SliderHandle;
      },
      SliderComputedAcc: function () {
        if (this.position) {
          _.forEach(this.SliderComputed, (item) => {
            if (this.position.is_cross == 1) {
              this.SliderHandleAcc = item[0];
              return false;
            }
            if (parseFloat(item.number).toFixed(2) - parseFloat(this.position.leverage).toFixed(2) < 0.01) {
              this.SliderHandleAcc = item;
            }
          })
        }
        if (this.SliderHandleAcc == null) {
          this.SliderHandleAcc = this.SliderComputed[0];
        }
        return this.SliderHandleAcc;
      },
    },
    data() {
      return {
        leverage: null,
        isEditing: false,
        SliderHandleAcc: null,
        SliderHandle: [
          { MarginLeft: -4, Left: 0, isActive: false, },
          { MarginLeft: -3, Left: 12.5, isActive: false, },
          { MarginLeft: -5, Left: 25, isActive: false, },
          { MarginLeft: -5, Left: 37.5, isActive: false, },
          { MarginLeft: -5, Left: 50, isActive: false, },
          { MarginLeft: -5, Left: 62.5, isActive: false, },
          { MarginLeft: -7, Left: 75, isActive: false, },
          { MarginLeft: -8, Left: 87.5, isActive: false, },
          { MarginLeft: -15, Left: 100, isActive: false, },
        ],
        index: 0,
      }
    },
    watch: {
      isEditing(value) {
        if(!value) {
          this.errors.clear();
        }
      }
    },
    methods: {
      ...mapMutations({ updatePosition: 'updateMamPosition' }),
      ...mapActions(['updateMamLeverage']),

      async activeSliderHandle(item) {
        try {
          this.SliderHandleAcc = item;
          let dataUpdate = {
            leverage: item.number,
            symbol: this.currentSymbol,
          };
          await this.updateMamLeverage(dataUpdate);
        } catch(e) {
          if (e.response && e.response.data) {
            Message.error(e.response.data.message, {}, { position: 'bottom_left' });
          }
        }
      },

      async editLeverage() {
        try {
          if (!this.validate()) return false;
          let dataUpdate = {
            leverage: this.leverage ? this.leverage : 0,
            symbol: this.currentSymbol,
          };
          await this.updateMamLeverage(dataUpdate);
          this.isEditing = !this.isEditing;
        } catch(e) {
          if (e.response && e.response.data) {
            Message.error(e.response.data.message, {}, { position: 'bottom_left' });
          }
        }
      },

      validate() {
        this.errors.clear();
        if (this.leverage < 1) {
          this.errors.add('max_leverage', window.i18n.t('margin.min_leverage_error', { leverage: 1 }));
        }

        let maxLeverage = 100;
        if (this.position && this.position.required_init_margin_percent) {
          maxLeverage = 1 / this.position.required_init_margin_percent;
        }

        if (this.leverage > maxLeverage) {
          this.errors.add('max_leverage', window.i18n.t('margin.max_leverage_error', { leverage: parseFloat(maxLeverage).toFixed(2) }));
        }
        if (this.errors.count() > 0) {
          return false;
        }
        return true;
      },

      setLeverageLevels(levels) {
        let i = 0;
        _.map(this.SliderHandle, function(item) {
          item.number = levels[i];
          i++;
        })
      }
    },
    mounted() {
    },
    created () {
      this.setLeverageLevels([0, 1, 2, 3, 5, 10, 25, 50, 100])
    }
   }
</script>

<style lang="scss" scoped="">
  @import '@/assets/sass/variables';

  .rc-slider-mark-text_1 {
    display: inline-block;
    font-size: 13px;
    padding-top: 10px;
    width: 11.25%;
    color: $color-jungle-green;
    font-family: $font-family-roboto-bold;
  }
  .positionLeverageIndicator {
    color: $color-white;
    position: relative;
    overflow: hidden;
    .indicatorGraph {
      background: -moz-linear-gradient(90deg, $color-jungle-green 0, $color-denim 100%);/* FF3.6+ */
      background: -webkit-gradient(linear, 90deg, color-stop(0, 2DAC91), color-stop(100%, F74940));/* Chrome,Safari4+ */
      background: -webkit-linear-gradient(90deg, $color-jungle-green 0, $color-denim 100%);/* Chrome10+,Safari5.1+ */
      background: -o-linear-gradient(90deg, $color-jungle-green 0, $color-denim 100%);/* Opera 11.10+ */
      background: -ms-linear-gradient(90deg, $color-jungle-green 0, $color-denim 100%);/* IE10+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1301FE', endColorstr='#F4F60C', GradientType='1'); /* for IE */
      background: linear-gradient(90deg, $color-jungle-green 0, $color-denim 100%);/* W3C */
      border-radius: 3px;
      display: block;
      height: 40px;
      width: 100%;
    }
    .tooltipWrapper {
      position: absolute;
      -webkit-transition: opacity 0.5s ease-in-out;
      transition: opacity 0.5s ease-in-out;
    }
    .initialLeverageIcon {
      top: 10px;
      left: 5px;
      font-size: 18px;
    }
    .maintLeverageIcon {
      top: 10px;
      right: 5px;
      font-size: 18px;
    }
    .indicatorLabel {
      top: 0px;
      left: 0;
      right: 0;
      text-align: center;
      line-height: 40px;
      font-weight: bold;
      font-size: $font-medium-bigger;
      opacity: 0;
      text-shadow: 1px 1px 1px black;
      pointer-events: none;
      position: absolute;
      -moz-transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
    }
  }
  .leverageWrapper {
    padding: 0px 0px 0px 4px;
    min-height: 42px;
    .leverage {
      position: relative;
      display: block;
      width: calc(100% - 30px);
      float: left;
    }
    .edit_leverage {
      display: block;
      width: 30px;
      float: right;
      text-align: right;
      padding-top: 10px;
      .btn {
        float: right;
        width: 20px;
        height: 20px;
        padding: 0px;
        text-align: center;
        line-height: 20px;
      }
      .icon {
        font-size: 15px;
        float: right;
        display: inline-block;
        color: $color-orange;
        cursor: pointer;
      }
    }
    .rc-slider-mark {
      position: relative;
      .rc-slider-mark-text {
        display: inline-block;
        margin-top: 2px;
        font-size: $font-mini;
        color: $color-grey;
        position: absolute;
        padding-top: 5px;
        cursor: pointer;
        width: 11.25%;
        &:last-child {
          text-align: right;
        }
        &.active {
          color: $color-orange;
          text-decoration: underline;
          font-family: $font-family-roboto-bold;
        }
      }
    }

  }
  .flexBody_lever {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 7px;
    label {
      font-size: $font-smaller;
      color: $color-grey-sliver;
      font-weight: 500;
      float: left;
      width: 80px;
      margin: 0px;
      line-height: 20px;
    }
    input.valid {
      width: 78px;
      float: right;
      display: block;
      border-radius: 0px;
      height: 20px;
      padding: 2px;
      text-align: right;
      font-size: $font-mini;
      color: $color-grey-dark;
      border: 1px solid $color-green;
      font-family: $font-family-roboto-bold;
      &:focus,
      &:active,
      &:hover {
        border: 1px solid $color-orange;
      }
    }
    button {
      &.btn-danger {
        display: block;
        width: 20px;
        float: right;
        border-radius: 0px;
        height: 20px;
        padding: 0px;
        margin-left: 1px;
        font-size: 9px;
        font-weight: bold;
        padding: 5px;
      }
      &.btn-success {
        display: block;
        width: 20px;
        float: right;
        border-radius: 0px;
        height: 20px;
        padding: 0px;
        margin-left: 1px;
        font-size: 9px;
        font-weight: bold;
        padding: 5px;
        background: $color-green;
        border-color: $color-green;
      }
    }
  }
  .lineIndicator {
    top: 0;
    height: 100%;
    width: 3px;
    background-color: $color-white;
    position: relative;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    .valueLabel {
      display: block;
      color: $color-white;
      font-size: $font-root;
      font-family: $font-family-roboto-bold;
      line-height: 20px;
      padding: 10px 10px;
    }
  }
  .rc-slider-step {
    position: relative;
    width: 100%;
    height: 2px;
    background-color: $color-bright-gray-dark;
    margin-top: 20px;
    .rc-slider-dot {
      position: absolute;
      bottom: -3px;
      margin-left: -5px;
      width: 10px;
      height: 10px;
      border: 3px solid $color-bright-gray-dark;
      background-color: $color-bright-gray-dark;
      cursor: pointer;
      border-radius: 50%;
      vertical-align: middle;
      z-index: 10;
      &:first-child {
        margin-left: -4px;
      }
      &:last-child {
        margin-left: -4px;
      }
    }
  }
  .rc-slider-handle {
    position: absolute;
    cursor: pointer;
    border-radius: 50%;
    border: solid 2px $color-orange;
    background-color: $color-white;
    margin-left: -5px;
    margin-top: -7px;
    width: 10px;
    height: 10px;
    border-width: 3px;
    z-index: 20;
  }
  .isVisiHidden {
    visibility: hidden;
    opacity: 1 !important;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .isVisi {
    visibility: visible;
    opacity: 1 !important;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .tooltipWarning {
    position: relative;
    display: inline-block;
  }

  /* Tooltip text */
  .tooltipWarning .tooltipWarningText {
    visibility: hidden;
    width: 170px;
    background-color: #222832;
    color: #cccccc;
    text-align: center;
    padding: 5px 0;
    border-radius: 4px;
    font-size: 13px;
  
    /* Position the tooltip text - see examples below! */
    box-shadow: 0px 0px 3px #cccccc;
    position: absolute;
    z-index: 1;
    right: 25px;
    bottom: 1px;
  }

  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltipWarning:hover .tooltipWarningText {
    visibility: visible;
  }
</style>