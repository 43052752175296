<template>
  <!-- NEW BALANCE LAYOUT -->
  <div
    id="balance"
    class="page_container"
    :class="{ is_no_single_page: !isSinglePage }"
  >
    <Header />
    <WalletOverview
      :priceCoin="totalBtcBalance"
      :valued="totalUsdBalance"
      :tongglePrice="tongglePrice"
      @clickHide="hidePriceFunction"
      :totalSpotBtcBalance="totalSpotBtcBalance"
      :totalUsdSpotBalance="totalUsdSpotBalance"
      :totalFuturesBtcBalance="totalFuturesBtcBalance"
      :totalUsdFutureBalance="totalUsdFutureBalance"
    />
    <!-- <div class="balance-container">
      <div class="box-title title-medium-small" v-if="isSinglePage">
        {{ $t("funds.balances.balance") }}
      </div>

      <div class="input-search fl-left">
        <input
          type="text"
          name=""
          :placeholder="$t('approved_order.btn_search')"
          v-model="search"
        />
        <a class="btn-search" href="#">
          <img
            :src="
              require(`@/assets/images/wallet/icon_search${
                theme === 'light-theme' ? '' : '-dark'
              }.svg`)
            "
            width="19"
            height="19"
          />
        </a>
      </div>
      <div class="es-toolbar" v-if="isSinglePage">
        <div
          class="btn-small-icon text-center fl-left"
          @click="showNotification()"
          v-click-outside="hideNotification"
        >
          <div class="checkbox-input">
            <input
              type="checkbox"
              @click="hideBalances = !hideBalances"
              id="checkboxFiveInput"
              name="checkbox"
              for="checkboxFiveInput"
              class="checkbox"
            />
            <label for="checkboxFiveInput" class="button-check"></label>
          </div>
          <span id="tooltip-target-1"
            >{{ $t("funds.balances.hide_small_balance_spot") }}
            <div class="tool-tip-custom">
              {{ $t("funds.balances.hide_small_balance_spot_tooltip") }}
            </div>
          </span>
        </div>
        <div class="estimate-value fl-right">
          <div class="limit">
            <p v-if="limit">
              {{ $t("funds.balances.withdrawal_limit_24") }}:
              <span v-if="!tongglePrice"
                >{{
                  limit.daily_limit | formatCurrencyAmount("btc", "0")
                }}
                BTC<template v-show="sizeScreen > 590">.</template></span
              >
              <span v-else>**** BTC.</span>
            </p>
            <p class="in-use">
              {{ $t("funds.balances.in_use") }}:
              <span v-if="!tongglePrice">
                {{
                  orderBtcValue | toNumber | formatCurrencyAmount("btc", "0")
                }}
                BTC
              </span>
              <span v-else>**** BTC</span>
            </p>
          </div>
        </div>
      </div>
  
      <div class="content-table">
        <div class="table-container table-history">
          <alert-with-button-modal />
          <message-with-icon />
          <data-table
            :getData="loadData"
            ref="datatable"
            :msgEmptyData="noFunds"
            :limit="10"
            @DataTable:finish="onDatatableFinished"
          >
            <template slot-scope="props">
              <th
                data-sort-field="coin"
                class="coin_balances"
                @click="FixSortIE = !FixSortIE"
              >
                {{ $t("funds.balances.coin") }}
                <div
                  class="group-sort-tabl3"
                  v-html="props.innerHtmlSort"
                ></div>
                <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
              </th>
              <th
                v-if="isSinglePage"
                class="name_blances"
                @click="FixSortIE = !FixSortIE"
              >
                {{ $t("funds.balances.name") }}
              </th>
              <th
                class="min_w150 totalBalance"
                data-sort-field="totalBalance"
                @click="FixSortIE = !FixSortIE"
              >
                {{ $t("funds.balances.total_balance") }}
                <div
                  class="group-sort-tabl3"
                  v-html="props.innerHtmlSort"
                ></div>
                <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
              </th>
              <th
                class="min_w120 availableBalance"
                data-sort-field="availableBalance"
                @click="FixSortIE = !FixSortIE"
              >
                {{ $t("funds.balances.avaliable_balance") }}
                <div
                  class="group-sort-tabl3"
                  v-html="props.innerHtmlSort"
                ></div>
                <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
              </th>
              <th
                class="min_w120 exchangeBalance"
                data-sort-field="exchangeBalance"
                @click="FixSortIE = !FixSortIE"
              >
                <div class="box-flex-table">
                  {{ $t("funds.balances.available") }} /<br />
                  {{ $t("funds.balances.total_spot_balance") }}
                  <div
                    class="group-sort-tabl3"
                    v-html="props.innerHtmlSort"
                  ></div>
                  <span
                    class="iconFixSorIE"
                    v-if="props.resetIE === true"
                  ></span>
                </div>
              </th>
              <th
                class="min_w120 futureBalances"
                data-sort-field="marginBalance"
                @click="FixSortIE = !FixSortIE"
              >
                <div class="box-flex-table">
                  {{ $t("funds.balances.available") }} /<br />
                  {{ $t("funds.balances.total_futures_balance") }}
                  <div
                    class="group-sort-tabl3"
                    v-html="props.innerHtmlSort"
                  ></div>
                  <span
                    class="iconFixSorIE"
                    v-if="props.resetIE === true"
                  ></span>
                </div>
              </th>
              <th class="max_w180 action">
                {{ $t("funds.balances.action") }}
              </th>
            </template>
            <template slot="body" slot-scope="props">
              <tr v-if="_.size(coinNames) > 0">
                <td class="coin_balances">
                  <img
                    v-if="coinImages[props.item.coin]"
                    class="icon-coin"
                    :src="`${coinImages[props.item.coin]}`"
                    alt=""
                  />
                  <img
                    v-else
                    class="icon-coin"
                    :src="
                      props.item.coin == 'btc'
                        ? btcicon
                        : props.item.coin == 'xrp'
                        ? xrpicon
                        : eosicon
                    "
                    alt=""
                  />
                  {{ props.item.coin | uppercase }}
                </td>
                <td v-if="isSinglePage">
                  {{
                    props.item.coin == "xrp"
                      ? "Ripple"
                      : props.item.coin == "eos"
                      ? "EOS"
                      : props.item.name | formatCoinName
                  }}
                </td>
                
                <td v-if="!tongglePrice">
                  {{
                    props.item.totalBalance | formatNumberAndDecimal
                  }}
                </td>
                <td v-else>******</td>
                <td v-if="!tongglePrice">
                  {{
                    props.item.availableBalance | formatNumberAndDecimal
                  }}
                </td>
                <td v-else>******</td>
               
                <td v-if="!tongglePrice">
                  {{
                    props.item.exchangeAvailabelBalance | formatNumberAndDecimal
                  }}
                  /
                  {{
                    props.item.exchangeBalance | formatNumberAndDecimal
                  }}
                </td>
                <td v-else>******</td>
               
                <td v-if="!tongglePrice">
                  <span v-if="props.item.marginAvailabelBalance > 0">
                    {{
                      displayPositive(props.item.marginAvailabelBalance) |  formatNumberAndDecimal

                    }}
                  </span>
                  <span v-else>0</span>
                  /
                  <span v-if="props.item.marginBalance > 0">
                    {{
                      displayPositive(props.item.marginBalance) |  formatNumberAndDecimal

                    }}
                  </span>
                  <span v-else>0</span>
                </td>
                <td v-else>******</td>
                <td class="action_btn">
                  <span>
                    <router-link
                      v-if="props.item.isDeposit"
                      :to="
                        props.item.coin == 'usd'
                          ? { name: 'Deposit USD Wallet' }
                          : {
                              name: 'Deposits Wallet',
                              query: { coin: props.item.coin },
                            }
                      "
                    >
                      {{ $t("funds.balances.deposit") }}
                    </router-link>
                  </span>
                  <span>
                    <router-link
                      v-if="props.item.isWithdraw"
                      :to="
                        props.item.coin == 'usd'
                          ? { name: 'Withdraw USD Wallet' }
                          : {
                              name: 'Withdrawals Wallet',
                              query: { coin: props.item.coin },
                            }
                      "
                    >
                      {{ $t("funds.balances.withdrawal") }}
                    </router-link>
                  </span>
                  <span
                    class="tranfer-cell"
                    @click="activeRow(props.item)"
                    v-bind:class="{ acvite_transfer: props.item.isActiveRow }"
                    ><a> {{ $t("funds.balances.tranfer") }}</a>
                  </span>
                </td>
              </tr>

              <template v-if="props.item.coin == nameItemShow">
                <tr>
                  <td colspan="11" class="table_drop_balances">
                    <div class="close_tbale" @click="activeRow(props.item)">
                      <i class="icon-close"></i>
                    </div>
                    <div class="content_transfer_form">
                      <form class="transfer_form">
                        <div class="form-left">
                          <div
                            class="group_transfer_form"
                            v-if="props.item.isTransferFromMain"
                          >
                            <label class="txt">{{
                              $t("funds.balances.from")
                            }}</label>
                            <input
                              type="text"
                              class="input-form w_155"
                              disabled="disabled"
                              :placeholder="$t('funds.balances.main_account')"
                            />
                          </div>
                          <div
                            class="group_transfer_form"
                            v-if="!props.item.isTransferFromMain"
                          >
                            <label class="txt">{{
                              $t("funds.balances.from")
                            }}</label>
                            <div class="dropdown">
                              <button
                                class="btn form-control select-control w_155"
                                type="button"
                                data-toggle="dropdown"
                              >
                                <span
                                  class="left select-item-selected"
                                  v-if="
                                    transferType('SPOT', props.item.coin) &&
                                    props.item.transferType === 'spot'
                                  "
                                >
                                  {{ $t("funds.balances.spot_balance") }}
                                </span>

                                <span
                                  class="left select-item-selected"
                                  v-else-if="
                                    transferType('FUTURE', props.item.coin)
                                  "
                                >
                                  {{ $t("funds.balances.margin_balance") }}
                                </span>
                                <span
                                  class="left select-item-selected"
                                  v-else-if="
                                    (transferType('COIN_M', props.item.coin),
                                    (props.item.transferType = 'future'))
                                  "
                                >
                                  {{ $t("funds.balances.future.coin_M") }}
                                </span>
                                <span
                                  class="icon icon-arrow1 select-icon"
                                ></span>
                              </button>
                              <ul class="dropdown-menu">
                                <div
                                  @click="props.item.transferType = 'spot'"
                                  class="tab-element"
                                  :class="{
                                    active: props.item.transferType === 'spot',
                                  }"
                                  v-if="transferType('SPOT', props.item.coin)"
                                >
                                  <span
                                    class="text-size"
                                    v-if="transferType('SPOT', props.item.coin)"
                                    >{{
                                      $t("funds.balances.spot_balance")
                                    }}</span
                                  >
                                </div>
                                <div
                                  v-on:click="
                                    props.item.transferType = 'future'
                                  "
                                  class="tab-element"
                                  :class="{
                                    active:
                                      props.item.transferType === 'future',
                                  }"
                                  v-if="
                                    transferType('FUTURE', props.item.coin) ||
                                    transferType('COIN_M', props.item.coin)
                                  "
                                >
                                  <span
                                    class="left select-item-selected"
                                    v-if="
                                      transferType('FUTURE', props.item.coin)
                                    "
                                  >
                                    {{ $t("funds.balances.margin_balance") }}
                                  </span>
                                  <span
                                    class="left select-item-selected"
                                    v-if="
                                      transferType('COIN_M', props.item.coin)
                                    "
                                  >
                                    {{ $t("funds.balances.future.coin_M") }}
                                  </span>
                                </div>
                              </ul>
                            </div>
                          </div>

                          <div class="group_transfer_form wrapperImg">
                            <label class="txt"></label>
                            <img
                              :class="[sizeScreen < 769 && 'rotateClass']"
                              :src="
                                require(`@/assets/images/wallet/tranfersIconOverview${
                                  theme === 'light-theme' ? '' : '-dark'
                                }.svg`)
                              "
                              @click="swapBalance($event, props.item)"
                            />

                          </div>

                          <div
                            class="group_transfer_form"
                            v-if="!props.item.isTransferFromMain"
                          >
                            <label class="txt">{{
                              $t("funds.balances.to")
                            }}</label>
                            <input
                              type="text"
                              class="input-form w_155"
                              disabled="disabled"
                              :placeholder="$t('funds.balances.main_account')"
                            />
                          </div>
                          <div
                            class="group_transfer_form"
                            v-if="props.item.isTransferFromMain"
                          >
                            <label class="txt">{{
                              $t("funds.balances.to")
                            }}</label>
                            <div class="dropdown">
                              <button
                                class="btn form-control select-control w_155"
                                type="button"
                                data-toggle="dropdown"
                              >
                                <span
                                  class="left select-item-selected"
                                  v-if="
                                    transferType('SPOT', props.item.coin) &&
                                    props.item.transferType === 'spot'
                                  "
                                >
                                  {{ $t("funds.balances.spot_balance") }}
                                </span>
                                <span
                                  class="left select-item-selected"
                                  v-else-if="
                                    transferType('FUTURE', props.item.coin)
                                  "
                                >
                                  {{ $t("funds.balances.margin_balance") }}
                                </span>
                                <span
                                  class="left select-item-selected"
                                  v-else-if="
                                    (transferType('COIN_M', props.item.coin),
                                    (props.item.transferType = 'future'))
                                  "
                                >
                                  {{ $t("funds.balances.future.coin_M") }}
                                </span>
                                <span
                                  class="icon icon-arrow1 select-icon"
                                ></span>
                              </button>
                              <ul class="dropdown-menu">
                                <div
                                  @click="props.item.transferType = 'spot'"
                                  class="tab-element"
                                  :class="{
                                    active: props.item.transferType === 'spot',
                                  }"
                                  v-if="transferType('SPOT', props.item.coin)"
                                >
                                  <span class="text-size">{{
                                    $t("funds.balances.spot_balance")
                                  }}</span>
                                </div>
                                <div
                                  v-on:click="
                                    props.item.transferType = 'future'
                                  "
                                  v-if="
                                    transferType('COIN_M', props.item.coin) ||
                                    transferType('FUTURE', props.item.coin)
                                  "
                                  class="tab-element"
                                  :class="{}"
                                >
                                  <span
                                    class="text-size"
                                    v-if="
                                      transferType('FUTURE', props.item.coin)
                                    "
                                    >{{
                                      $t("funds.balances.margin_balance")
                                    }}</span
                                  >
                                  <span
                                    class="text-size"
                                    v-if="
                                      transferType('COIN_M', props.item.coin)
                                    "
                                    >{{
                                      $t("funds.balances.future.coin_M")
                                    }}</span
                                  >
                                </div>
                              </ul>
                            </div>
                          </div>
                        </div>
                       
                        <div class="form-right">
                          <div class="group_transfer_form">
                            <label class="txt">{{
                              $t("order.trade_history.amount")
                            }}</label>
                            <div class="input-amount">
                              <currency-input
                                :precision="8"
                                :class="{
                                  error: errors.has('amount' + props.item.coin),
                                }"
                                class="input-form w_220"
                                :value="props.item.amount"
                                v-model="props.item.amount"
                                @focus="resetValidateRowWhenFocus(props.item)"
                                @blur="() => handleChangeAmount(props.item)"
                                @input="handleAmount(props.item)"
                              />
                              <span class="name_amount">{{
                                props.item.coin
                              }}</span>
                            </div>
                            <div
                              v-show="errors.has('amount' + props.item.coin)"
                              class="w_220 invalid-feedback"
                            >
                              {{ errors.first("amount" + props.item.coin) }}
                            </div>
                            <div
                              v-show="errors.has('server' + props.item.coin)"
                              class="w_220 invalid-feedback"
                            >
                              {{ errors.first("server" + props.item.coin) }}
                            </div>

                            <div class="group_input_form cleafix">
                              <span class="txt_left_group mgb-none"></span>
                              <label class="input_right_group">
                                <span
                                  class="check_percent"
                                  :class="[
                                    precentTransfer == 25 && 'activePrecent',
                                  ]"
                                  @click="onPercentClicked(props.item, 25)"
                                  >25%</span
                                >
                                <span
                                  class="check_percent"
                                  :class="[
                                    precentTransfer == 50 && 'activePrecent',
                                  ]"
                                  @click="onPercentClicked(props.item, 50)"
                                  >50%</span
                                >
                                <span
                                  class="check_percent"
                                  :class="[
                                    precentTransfer == 75 && 'activePrecent',
                                  ]"
                                  @click="onPercentClicked(props.item, 75)"
                                  >75%</span
                                >
                                <span
                                  class="check_percent last_item"
                                  :class="[
                                    precentTransfer == 100 && 'activePrecent',
                                  ]"
                                  @click="onPercentClicked(props.item, 100)"
                                  >100%</span
                                >
                              </label>
                            </div>
                          </div>
                         
                          <div class="group_transfer_form">
                            <label
                              v-show="sizeScreen > 768"
                              class="txt"
                            ></label>
                            <button
                              class="btn btn-send-transfer-form"
                              @click.prevent="
                                transferBalance($event, props.item)
                              "
                              :disabled="isSubmittingTransfer"
                            >
                              {{ $t("funds.balances.tranfer") }}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-else></template>
              <template v-if="props.item.isActiveRowMam">
                <tr>
                  <td colspan="11" class="table_drop_balances">
                    <div class="close_tbale" @click="activeRowMam(props.item)">
                      <i class="icon-close"></i>
                    </div>
                    No data
                  </td>
                </tr>
              </template>
            </template>
          </data-table>
      </div>
    </div> -->
  </div>
  <!-- END NEW BALANCE LAYOUT -->
</template>
<script>
import rf from "@/request/RequestFactory";
import BigNumber from "bignumber.js";
import Const from "@/common/Const";
import AlertWithButtonModal from "@/components/shared_components/common/AlertWithButtonModal";
import MessageWithIcon from "@/components/shared_components/common/MessageWithIcon";
// import MessageTransfer from "@/components/shared_components/common/MessageTransfer.vue";
import AirdropBalance from "@/components/shared_components/common/AirdropBalance.vue";
import Entry from "@/components/shared_components/common/Entry/Entry.vue";
import MamTransferHistory from "@/components/shared_components/common/MamTransferHistory.vue";
import { mapGetters, mapState } from "vuex";
import Cookies from "js-cookie";
import Header from "@/pages/wallet/component/Header.vue";
import WalletOverview from "@/pages/wallet/component/WalletOverview.vue";
import BTCIcon from "@/assets/images/icon/coin/color/btc.png";
import XRPIcon from "@/assets/images/icon/coin/color/xrp.png";
import EOSIcon from "@/assets/images/icon/coin/color/eos.png";
import ETHIcon from "@/assets/images/ethereum-eth-logo.png";

const MIN_BTC = 0.01;
const MIN_AMOUNT_CRYPTO = Math.pow(10, -8); // 10^-8
const TRANSFER_TYPE_MAIN_BALANCE = "main";
const TRANSFER_TYPE_EXCHANGE_BALANCE = "spot";
const TRANSFER_TYPE_MARGIN_BALANCE = "future";
const TRANSFER_TYPE_MAM_BALANCE = "mam";

// Prevent convert 0.00000001 to 1e-8
BigNumber.config({ EXPONENTIAL_AT: 18 });

export default {
  data: function () {
    return {
      FixSortIE: null,
      hideBalances: false,
      isShowNotification: false,
      search: "",
      limit: undefined,
      accountBalances: undefined,
      prices: [],
      balances: [],
      totalPendingWithdraws: [],
      selectedCoin: "",
      tradeCoin: [],
      displayPairCoin: false,
      tradeUsd: [],
      totalOrder: 0,

      isAuthenticated: window.isAuthenticated,
      coinSetting: {},
      coinsMasterData: {},
      coinNames: {},
      coinImages: {},
      noFunds: window.i18n.t("common.datatable.no_have_data"),
      isSubmittingTransfer: false,
      isShowEntryLeaderboard: false,

      btcicon: BTCIcon,
      xrpicon: XRPIcon,
      eosicon: EOSIcon,
      ethicon: ETHIcon,

      precentTransfer: 0,
      nameItemShow: "",
      tongglePrice: false,
      theme:
        Cookies.get("user-theme", { domain: process.env.VUE_APP_PUBLIC_URL }) ||
        "dark-theme",
      balanceFutures: {},

      sizeScreen: window.innerWidth,
      amountChange: 0,
      positionData: [],
      costOpenOrder: {},
      Price24h: [],
      assetsWallet: {},
      updatedBalance: false,
      listInstruments: [],
      listAllowcatedMargin: [],
      unrealizedPNLValue: [],
      finishLoadData: false,
      COIN_TRANSFER_TYPE: Const.COIN_TRANSFER_TYPE,
      openOrder: [],
      sort: "",
      sortType: "",
      hasSortWhenTransfer: false,
    };
  },
  components: {
    AlertWithButtonModal,
    MessageWithIcon,
    AirdropBalance,
    MamTransferHistory,
    Entry,
    Header,
    WalletOverview,
    // MessageTransfer
  },
  props: {
    isSinglePage: {
      type: Boolean,
      default: true,
    },
  },
  sockets: {
    connect: function () {},
  },
  watch: {
    accountBalances(newAccBalances) {
      // const currencies = Object.keys(newAccBalances);
      // this.getTotalPendingWithdraw(currencies, this.refresh);
    },
    prices(newPrices) {
      // this.refresh();
    },
    totalPendingWithdraws(newVal) {
      this.refresh();
    },
    search(newValue) {
      this.$refs.datatable.refresh();
    },
    openOrder() {
      this.availableBalanceWithdraw();
    },
    // Price24h() {
    //   if(Object.keys(this.positionData).length !== 0) {
    //     this.unrealizedPostions()
    //   }
    // },
    hideBalances() {
      this.$refs.datatable.refresh();
    },
    isTransferFromMain() {
      this.$refs.datatable.refresh();
    },
    userThemeMode(theme) {
      this.theme = theme;
    },
    amountChange(newValue, oldValue) {
      if (this.isSelectedPercent) return;
      else if (newValue !== oldValue && oldValue !== 0) {
        this.precentTransfer = 0;
      }
    },
    positionData() {
      this.unrealizedPostions();
    },
    balanceFutures() {
      this.unrealizedPostions();
    },
    assetsWallet() {
      this.unrealizedPostions();
      if (this.positionData.length == 0) {
        this.availableBalanceWithdraw();
      }
      // this.$refs.datatable.fetch();
    },
  },
  computed: {
    ...mapState({
      masterData: (state) => state.masterdata,
      userThemeMode: (state) => state.userThemeMode,
    }),
    ...mapGetters({
      isShowBalances: "isShowBalances",
    }),
    totalBtcBalance() {
      return this.sumBy("btcValue");
    },
    totalUsdBalance() {
      return this.sumBy("usdtValue");
    },
    totalSpotBtcBalance() {
      return this.sumBy("btcSpotValue");
    },
    totalUsdSpotBalance() {
      return this.sumBy("usdtSpotValue");
    },
    totalFuturesBtcBalance() {
      return this.sumBy("btcFutureValue");
    },
    totalUsdFutureBalance() {
      return this.sumBy("usdFeatureValue");
    },
    orderBtcValue() {
      return this.sumBy("btcValue") - this.sumBy("availableBalance", "btc");
    },
  },
  methods: {
    transferType(type, symbol) {
      if (type === "COIN_M" && (symbol === "usdt" || symbol === "usd"))
        return false;
      return this.COIN_TRANSFER_TYPE[type].find(
        (item) => item === symbol.toUpperCase()
      );
    },
    hidePriceFunction() {
      this.tongglePrice = !this.tongglePrice;
      const balances = { ...this.isShowBalances, balances: this.tongglePrice };
      Cookies.set(
        "hide-balance",
        JSON.stringify(balances, { domain: process.env.VUE_APP_PUBLIC_URL })
      );
      this.$store.commit("setShowBalances", balances);
    },
    displayPositive(value) {
      if (new BigNumber(`${value || 0}`).comparedTo(0) < 0) {
        return 0;
      }
      return value;
    },
    onDatatableFinished() {},
    convertSmallBalance() {
      return this.$router.push({ path: "convert-balances" });
      // return this.$route.name === 'Convert Small Balances'
    },
    resetValidateRowWhenFocus(item) {
      this.errors.remove("amount" + item.coin);
      this.errors.remove("server" + item.coin);
      this.isSelectedPercent = false;
    },
    handleAmount(item) {
      this.amountChange = item.amount;
    },
    resetValidateRow(item) {
      item.isTransferFromMain = true;
      item.amount = 0;
      this.errors.remove("amount" + item.coin);
      this.errors.remove("server" + item.coin);
    },
    activeRow(item) {
      if (item.coin == this.nameItemShow) {
        this.nameItemShow = "";
        return;
      }
      this.nameItemShow = item.coin;
      this.precentTransfer = 0;
      item.isActiveRow = !item.isActiveRow;
      item.transferType = "spot";
      this.resetValidateRow(item);
      this.$forceUpdate();
    },
    HiddenAcriveRow(item) {
      this.$set(item, "isActiveRow", false);
    },
    activeRowMam(item) {
      item.isActiveRowMam = !item.isActiveRowMam;
      item.transferType = "spot";
      this.$forceUpdate();
    },
    addPricesInBtc() {
      this.prices["btc_btc"] = { price: 1 };
      for (const fiat of Const.FIAT_LIKE_LIST) {
        const fiatPrice = this.prices[`${fiat}_btc`];
        const checkNullPrice =
          fiatPrice && !new BigNumber(fiatPrice.price).isZero();
        const price = checkNullPrice
          ? new BigNumber("1").div(fiatPrice.price).toString()
          : "0";
        this.prices[`btc_${fiat}`] = { price };
      }
    },
    isShowcoin(coin) {
      return coin === this.selectedCoin;
    },
    showCoin(coin) {
      this.selectedCoin = coin;
      this.tradeCoin = this.currencyCoins.filter((item) => {
        return item.coin.indexOf(coin) > -1;
      });
      this.tradeUsd = this.currencyCoins.filter((item) => {
        return item.currency === "usd";
      });
    },
    getMinBtc() {
      return MIN_BTC;
    },
    showNotification() {
      this.isShowNotification = !this.isShowNotification;
    },
    hideNotification() {
      this.isShowNotification = false;
    },
    loadData(params) {
      return new Promise((resolve) => {
        let data = this.balances;
        data = _.filter(data, (item) =>
          `${item.coin}${item.name}`
            .toLowerCase()
            .includes(this.search.toLowerCase())
        );
        if (this.hideBalances)
          data = _.filter(data, (item) => {
            let btcValue = new BigNumber(item.btcValue);
            return btcValue.gte(MIN_BTC);
          });
        const sort = !this.hasSortWhenTransfer ? params.sort : this.sort;
        const sortType = !this.hasSortWhenTransfer
          ? params.sort_type
          : this.sortType;
        if (sort && sortType) {
          data = _.orderBy(
            data,
            (item) => {
              if (isNaN(item[sort])) {
                return item[sort];
              }
              return new BigNumber(`${item[sort]}`).toNumber();
            },
            [sortType]
          );
          this.hasSortWhenTransfer = false;
        }
        return resolve({ data });
      });
    },
    refreshNow() {
      // Refresh datatable
      let immediateRefresh = false;
      this.transferData(immediateRefresh);
      this.$refs && this.$refs.datatable && this.$refs.datatable.refresh();
    },
    totalBalance(balance, balanceFuture) {
      return parseFloat(balance) + parseFloat(balanceFuture);
    },
    refresh() {
      const isNoData =
        window._.isEmpty(this.accountBalances) || window._.isEmpty(this.prices);
      if (isNoData) {
        return;
      }
      this.transferData(false);
      this.$refs && this.$refs.datatable && this.$refs.datatable.refresh();
    },
    positionMargin() {
      const data = {
        contractType: "ALL",
      };
      return rf
        .getRequest("MarginRequest")
        .getPosition(data)
        .then((res) => {
          this.positionData = res.data;
          return res;
        });
    },
    getPrice24h() {
      return rf
        .getRequest("PriceRequest")
        .get24hFuture()
        .then((res) => {
          return (this.Price24h = res.data);
        });
    },
    maskPrice(symbol) {
      const findSymbol = this.Price24h.filter((item) => {
        return item.symbol === symbol;
      });
      return this.convertCoin(findSymbol[0]?.oraclePrice || 0);
    },
    convertCoin(coin) {
      if (coin?.toString().length > 10) {
        return coin.toString().slice(0, 11) + "... ";
      } else if (isNaN(coin)) {
        return 0;
      }
      return coin;
    },
    unrealizedPostions() {
      this.unrealizedPNLValue = [];
      this.listAllowcated = [];
      let balanceFuture = this.balanceFutures.assets;
      for (const item of this.positionData) {
        const multiplier = this.listInstruments[item.symbol].multiplier;

        if (item.currentQty != 0) {
          const markPrice = this.maskPrice(item.symbol);
          const side = item.currentQty > 0 ? 1 : -1;
          const allowcated = this.allowcatedMargin(item, multiplier);
          const unrealizedPNL = this.unrealizedPNL(
            markPrice,
            item,
            side,
            multiplier
          );
          const unrealizedObj = {
            asset: item.asset,
            PNL: unrealizedPNL,
          };
          const allowcatedObj = {
            asset: item.asset,
            allowcated: allowcated,
          };
          this.unrealizedPNLValue.push(unrealizedObj);
          this.listAllowcated.push(allowcatedObj);
          if (balanceFuture) {
            const isCross = Boolean(item.isCross) ? unrealizedPNL : 0;
            balanceFuture[item?.asset].unrealisedPnl = isCross;
          }
          this.availableBalanceWithdraw();
        }
      }
    },

    allowcatedMargin(item, contract) {
      const isCurrency = item.asset === "USD" || item.asset === "USDT";
      const maskPrice = this.maskPrice(item.symbol);
      const absQty = Math.abs(item.currentQty);
      const leverage = parseFloat(item.leverage);
      const entryPrice = parseFloat(item.entryPrice);
      const adjustMargin = parseFloat(item.adjustMargin);
      const multiplierContract = parseFloat(contract);
      const positionMargin = parseFloat(item.positionMargin);
      let allowcatedMarginForCross = isCurrency
        ? new BigNumber(absQty).times(maskPrice).dividedBy(leverage)
        : new BigNumber(absQty)
            .times(multiplierContract)
            .dividedBy(new BigNumber(leverage).times(maskPrice));
      let allowcatedMarginForIsolate = new BigNumber(positionMargin || 0).add(
        adjustMargin || 0
      );
      return Boolean(item.isCross)
        ? allowcatedMarginForCross.toString()
        : allowcatedMarginForIsolate.toString();
    },

    unrealizedPNL(markPrice, item, side, contract) {
      const isCurrency = item.asset === "USD" || item.asset === "USDT";
      const maskPrice = this.maskPrice(item.symbol);
      const absQty = Math.abs(item.currentQty);
      const leverage = parseFloat(item.leverage);
      const entryPrice = parseFloat(item.entryPrice);
      const multiplierContract = parseFloat(contract);
      const inverseEntry = new BigNumber(1).dividedBy(entryPrice);
      const inverseMark = new BigNumber(1).dividedBy(markPrice);
      const unrealizedPNLUSD = new BigNumber(absQty)
        .times(new BigNumber(maskPrice).minus(entryPrice))
        .times(side)
        .toString();
      const unrealizedPNLCoin = new BigNumber(absQty)
        .abs()
        .times(multiplierContract)
        .times(new BigNumber(inverseEntry).minus(inverseMark))
        .times(side)
        .toString();

      const unrealizedPNL = isCurrency ? unrealizedPNLUSD : unrealizedPNLCoin;
      return unrealizedPNL;
    },

    async availableBalanceWithdraw() {
      for (let key in this.balanceFutures.assets) {
        const variableBalance = this.balanceFutures.assets[key];
        const costOrder = this.costOpenOrder[key] || 0;
        const totalAllowcated = this.allowcatedMarginForAsset(key);
        let availableUSDT = new BigNumber(variableBalance.balance)
          .minus(totalAllowcated.toString())
          .minus(costOrder.toString())
          .toString();
        let maximunWithdraw = new BigNumber(availableUSDT)
          .add(variableBalance.unrealisedPnl.toString())
          .toString();
        variableBalance.availableBalance = new BigNumber.min(
          availableUSDT,
          maximunWithdraw
        ).toString();
        const availableBalance = new BigNumber(
          variableBalance.availableBalance.toString()
        );
        variableBalance.availableBalance = BigNumber.max(
          0,
          availableBalance
        ).toString();
      }
      this.transferData(false);
      // this.$refs.datatable.fetch();
    },

    allowcatedMarginForAsset(asset) {
      let unrealizedTotal = 0;
      const total = this.listAllowcated.reduce((sum, current) => {
        // parseFloat(sum) + (current.allowcated * 1)
        if (current.asset === asset && !isNaN(current.allowcated)) {
          unrealizedTotal = parseFloat(sum) + current.allowcated * 1;
        }
        return unrealizedTotal;
      }, 0);
      return total;
    },

    sumCostOpenOrder(order) {
      let costUSD = 0;
      let costUSDT = 0;
      this.costOpenOrder = {};
      order.forEach((item) => {
        if (item.cost !== null) {
          if (
            item.symbol.includes("USDT") &&
            !item.symbol.includes("USDTUSD")
          ) {
            costUSDT += parseFloat(item.cost);
            this.costOpenOrder.USDT = costUSDT || 0;
          } else if (item.contractType === "COIN_M") {
            let assets = item.symbol.replace(/USDM|USDT|USD/, "");
            if (!this.costOpenOrder[assets]) {
              this.costOpenOrder[assets] = 0;
            }
            this.costOpenOrder[assets] += parseFloat(item.cost);
          } else {
            costUSD += parseFloat(item.cost);
            this.costOpenOrder.USD = costUSD || 0;
          }
        }
      });
    },

    getSumOrderMargin() {
      const data = {
        contractType: "ALL",
      };
      return rf
        .getRequest("UserRequest")
        .getOpenOrderMargin(data)
        .then((res) => {
          this.openOrder = res.data;
          this.sumCostOpenOrder(res.data);
        });
    },

    getPriceCoinGecKotoBTC() {
      for (const coin in this.coinsMasterData) {
        const pairValue = `btc_${this.coinsMasterData[coin].coin}`;
        if (
          (this.prices[pairValue] === undefined && coin !== "usd") ||
          coin === "ltc" ||
          coin === "xrp"
        ) {
          rf.getRequest("TransactionRequest")
            .getPriceToBTC({ symbol: coin })
            .then((res) => {
              this.prices[pairValue] = { price: res?.data || 0 };
            });
        }
      }
    },

    transferData(isMerge = true) {
      if (this.balances && this.balances.length > 0 && isMerge) {
        this.mergeTransferData();
        return true;
      }
      
      if (!this.accountBalances) return false;
      let data = [];
      let coinList = this.accountBalances[Const.TYPE_MAIN_BALANCE] || {};
      let mainBalanceData = this.accountBalances[Const.TYPE_MAIN_BALANCE] || {};
      let marginBalanceData = this.balanceFutures.assets || {};
      let exchangeBalanceData =
        this.accountBalances[Const.TYPE_EXCHANGE_BALANCE] || {};
      let mamBalanceData = this.accountBalances[Const.TYPE_MAM_BALANCE] || {};
      let airdropBalanceData =
        this.accountBalances[Const.TYPE_AIRDROP_BALANCE] || {};

      for (let coin in coinList) {
          const item = {};
          item.coin = coin;
          item.name = this.getCoinName(coin);

          item.balance =
            (mainBalanceData &&
              mainBalanceData[coin] &&
              mainBalanceData[coin].balance) ||
            0;
          item.availableBalance =
            (mainBalanceData &&
              mainBalanceData[coin] &&
              mainBalanceData[coin].available_balance) ||
            0;
          // item.exchangeBalance =
          //     (exchangeBalanceData &&
          //         exchangeBalanceData[coin] &&
          //         exchangeBalanceData[coin].balance) ||
          //     0;
          item.airdropBalanceBonus =
            (airdropBalanceData &&
              airdropBalanceData[coin] &&
              airdropBalanceData[coin].balance_bonus) ||
            0;
          item.availableAirdropBalanceBonus =
            (airdropBalanceData &&
              airdropBalanceData[coin] &&
              airdropBalanceData[coin].available_balance_bonus) ||
            0;
          item.exchangeAvailabelBalance =
            (exchangeBalanceData &&
              exchangeBalanceData[coin] &&
              exchangeBalanceData[coin].available_balance) ||
            0;
          // marginBalanceData &&
          // marginBalanceData[coin] &&
          item.marginBalance =
            marginBalanceData[coin.toUpperCase()]?.balance || 0;
          item.marginAvailabelBalance =
            marginBalanceData[coin.toUpperCase()]?.availableBalance || 0;
          item.mamBalance =
            (mamBalanceData &&
              mamBalanceData[coin] &&
              mamBalanceData[coin].balance) ||
            0;
          item.airdropBalance =
            (airdropBalanceData &&
              airdropBalanceData[coin] &&
              airdropBalanceData[coin].balance) ||
            0;
          item.totalBalance = new BigNumber(item.balance)
            // .add(item.exchangeBalance)
            .add(item.marginBalance.toString());
          let coinType = item.coin || "";
          // if (coinType == Const.BTC) {
          //   item.totalBalance = new BigNumber(item.totalBalance).add(
          //     item.marginBalance.toString()
          //   );
          // }
          if (coinType == Const.AML) {
            item.totalBalance = new BigNumber(item.totalBalance.toString())
              .add(item.marginBalance.toString())
              .add(item.airdropBalance)
              .add(item.airdropBalanceBonus);
          }
          item.totalBalance = this.formatCurrencyRoundDown(
            coin,
            item.totalBalance.toString()
          );

          item.availableBalance = this.formatCurrencyRoundDown(
            coin,
            item.availableBalance
          );
          // item.exchangeBalance = this.formatCurrencyRoundDown(
          //     coin,
          //     item.exchangeBalance
          // );
          item.exchangeAvailabelBalance = this.formatCurrencyRoundDown(
            coin,
            item.exchangeAvailabelBalance
          );
          item.marginBalance = this.formatCurrencyRoundDown(
            coin,
            item.marginBalance
          );
          item.marginAvailabelBalance = this.formatCurrencyRoundDown(
            coin,
            item.marginAvailabelBalance
          );
          item.mamBalance = this.formatCurrencyRoundDown(coin, item.mamBalance);
          item.airdropBalance = this.formatCurrencyRoundDown(
            coin,
            item.airdropBalance
          );

          // item.disableMarginBalance =
          //   coinType != Const.BTC && coinType != Const.AML
          item.disableMarginBalance = true;
          item.enableMamBalance = coinType == Const.BTC;
          if ([Const.BTC, Const.AML, Const.ETH].includes(coinType))
            item.enableAirdropBalance = true;
          else item.enableAirdropBalance = false;
          item.isTransferFromMain = true;
          item.amount = 0;
          item.transferType = TRANSFER_TYPE_EXCHANGE_BALANCE;

          item.order = this.calculateInOrder(item);
          item.orderBtcValue = this.getOrderBtcBalance(item);
          item.btcValue = this.convertToBtcValue(coin, item.totalBalance);
          item.btcSpotValue = this.convertToBtcValue(coin, item.balance);
          item.btcFutureValue = this.convertToBtcValue(
            coin,
            item.marginBalance
          );

        let usdtValue = this.convertCoins(Const.BTC, Const.USDT, item.btcValue);
        // if (!usdtValue) {
        //   const pair = 'usdt_btc'
        //   const price = this.prices[pair] ? this.prices[pair].price : '0'
        //   usdtValue = new BigNumber(item.btcValue).mul(price).toString()
        // }
        let usdtSpotValue = this.convertCoins(
            Const.BTC,
            Const.USDT,
            item.btcSpotValue
          );
          let usdFeatureValue = this.convertCoins(
            Const.BTC,
            Const.USDT,
            item.btcFutureValue
          );

        const pair = "usdt_btc";
        const price = this.prices[pair] ? this.prices[pair].price : "0";
        if (!usdtValue) {
          usdtValue = new BigNumber(item.btcValue.toString())
              .mul(price)
              .toString();
        }
        if (!usdtSpotValue) {
          usdtValue = new BigNumber(item.btcSpotValue.toString())
              .mul(price)
              .toString();
        }
        if (!usdFeatureValue) {
          usdtValue = new BigNumber(item.btcFutureValue.toString())
              .mul(price)
              .toString();
          }

        item.usdtValue = usdtValue;
        item.usdtSpotValue = usdtSpotValue;
        item.usdFeatureValue = usdFeatureValue;

          item.isDeposit =
            this.coinSetting[item.coin] &&
            this.coinSetting[item.coin].is_deposit;
          item.isWithdraw =
            this.coinSetting[item.coin] &&
            this.coinSetting[item.coin].is_withdraw;
          data.push(item);
      }
      this.balances = window._.sortBy(data, "name");
      // this.refresh()
    },

    mergeTransferData() {
      let data = [];
      let coinList = this.accountBalances[Const.TYPE_MAIN_BALANCE];
      let mainBalanceData = this.accountBalances[Const.TYPE_MAIN_BALANCE];
      let marginBalanceData = this.balanceFutures.assets || {};
      let exchangeBalanceData =
        this.accountBalances[Const.TYPE_EXCHANGE_BALANCE];
      let mamBalanceData = this.accountBalances[Const.TYPE_MAM_BALANCE];
      let airdropBalanceData = this.accountBalances[Const.TYPE_AIRDROP_BALANCE];

      for (let coin in coinList) {
        const item = {};
        item.coin = coin;

        item.balance =
          (mainBalanceData &&
            mainBalanceData[coin] &&
            mainBalanceData[coin].balance) ||
          0;
        item.availableBalance =
          (mainBalanceData &&
            mainBalanceData[coin] &&
            mainBalanceData[coin].available_balance) ||
          0;
        item.exchangeBalance =
          (exchangeBalanceData &&
            exchangeBalanceData[coin] &&
            exchangeBalanceData[coin].balance) ||
          0;
        item.airdropBalanceBonus =
          (airdropBalanceData &&
            airdropBalanceData[coin] &&
            airdropBalanceData[coin].balance_bonus) ||
          0;
        item.availableAirdropBalanceBonus =
          (airdropBalanceData &&
            airdropBalanceData[coin] &&
            airdropBalanceData[coin].available_balance_bonus) ||
          0;
        item.exchangeAvailabelBalance =
          (exchangeBalanceData &&
            exchangeBalanceData[coin] &&
            exchangeBalanceData[coin].available_balance) ||
          0;
        item.marginBalance =
          marginBalanceData[coin.toUpperCase()]?.balance || 0;

        item.marginAvailabelBalance =
          marginBalanceData[coin.toUpperCase()]?.availableBalance || 0;
        item.mamBalance =
          (mamBalanceData &&
            mamBalanceData[coin] &&
            mamBalanceData[coin].balance) ||
          0;
        item.airdropBalance =
          (airdropBalanceData &&
            airdropBalanceData[coin] &&
            airdropBalanceData[coin].balance) ||
          0;
        // Note: Please re-check with
        // resources/assets/js/desktop/Layout.vue:getTotalBalanceByCoin
        // when edit this function
        item.totalBalance = new BigNumber(item.balance)
          .add(item.balance)
          .add(item.marginBalance.toString());

        let coinType = item.coin || "";
        // if (coinType == Const.BTC) {
        //   item.totalBalance = new BigNumber(item.totalBalance).add(
        //     item.marginBalance.toString()
        //   );
        // }
        // if (coinType == Const.AML) {
        //   item.totalBalance = new BigNumber(item.totalBalance)
        //     .add(item.marginBalance.toString())
        //     .add(item.airdropBalance)
        //     .add(item.airdropBalanceBonus);
        // }
        item.totalBalance = this.formatCurrencyRoundDown(
          coin,
          item.totalBalance.toString()
        );

        item.availableBalance = this.formatCurrencyRoundDown(
          coin,
          item.availableBalance
        );
        item.exchangeBalance = this.formatCurrencyRoundDown(
          coin,
          item.exchangeBalance
        );
        item.exchangeAvailabelBalance = this.formatCurrencyRoundDown(
          coin,
          item.exchangeAvailabelBalance
        );
        item.marginBalance = this.formatCurrencyRoundDown(
          coin,
          item.marginBalance
        );
        item.marginAvailabelBalance = this.formatCurrencyRoundDown(
          coin,
          item.marginAvailabelBalance
        );
        item.mamBalance = this.formatCurrencyRoundDown(coin, item.mamBalance);
        item.airdropBalance = this.formatCurrencyRoundDown(
          coin,
          item.airdropBalance
        );

        item.order = this.calculateInOrder(item);
        item.orderBtcValue = this.getOrderBtcBalance(item);

        item.btcValue = this.convertToBtcValue(coin, item.totalBalance);

        let usdtValue = this.convertCoins(Const.BTC, Const.USDT, item.btcValue);
        if (!usdtValue) {
          const pair = "usdt_btc";
          const price = this.prices[pair] ? this.prices[pair].price : "0";
          usdtValue = new BigNumber(item.btcValue).mul(price).toString();
        }
        item.usdtValue = usdtValue;

        data.push(item);
      }

      this.updateTableBalanceAfterTransfer(data);
      this.balances = window._.sortBy(this.balances, "name");
    },
    updateTableBalanceAfterTransfer(data) {
      var _this = this;
      var newData = [];
      window._.each(data, (item, index) => {
        let coinRecord = window._.find(
          _this.balances,
          (itemBalance) => itemBalance.coin == item.coin
        );

        if (coinRecord) {
          let amount = new BigNumber(coinRecord.amount || "0");
          newData.push({
            ...coinRecord,
            balance: item.balance,
            availableBalance: item.availableBalance,
            exchangeBalance: item.exchangeBalance,
            exchangeAvailabelBalance: item.exchangeAvailabelBalance,
            marginBalance: item.marginBalance,
            marginAvailabelBalance: item.marginAvailabelBalance,
            mamBalance: item.mamBalance,
            airdropBalance: item.airdropBalance,
            airdropBalanceBonus: item.airdropBalanceBonus,
            availableAirdropBalanceBonus: item.availableAirdropBalanceBonus,
            totalBalance: item.totalBalance,
            amount: amount.toString(),
            isActiveRow: coinRecord.isActiveRow,
            order: item.order,
            orderBtcValue: item.orderBtcValue,
            btcValue: item.btcValue,
            usdtValue: item.usdtValue,
          });
        }
      });

      this.balances = newData;
      // this.getBalanceAPI();
    },
    getCoinName(coin) {
      let coinName = "";
      if (this.coinsMasterData && this.coinsMasterData[coin]) {
        coinName = this.coinsMasterData[coin].name;
      }
      return coinName;
    },
    getBalance(coin, currency) {
      if (!this.accountBalances[Const.TYPE_MAIN_BALANCE][coin]) return 0;

      if (coin === currency) {
        return `${this.accountBalances[Const.TYPE_MAIN_BALANCE][coin].balance}`;
      }
      const pair = `${currency}_${coin}`;
      if (!this.prices[pair]) {
        return 0;
      }
      return new BigNumber(
        `${this.accountBalances[Const.TYPE_MAIN_BALANCE][coin].balance}`
      )
        .mul(this.prices[pair].price)
        .toString();
    },
    formatCurrencyRoundDown(currency, value) {
      // let numberOfDecimalDigits =
      //   currency && currency.toLowerCase() === "usd"
      //     ? Const.NUMBER_OF_DECIMAL_DIGITS_USD
      //     : Const.NUMBER_OF_DECIMAL_DIGITS; //currency === 'usd' ? 2 : 8;
      let numberOfDecimalDigits = Const.NUMBER_OF_DECIMAL_DIGITS; //currency === 'usd' ? 2 : 8;

      let newValue = new BigNumber(value.toString());
      return newValue
        .round(numberOfDecimalDigits, BigNumber.ROUND_FLOOR)
        .toString();
    },
    getAmalAvailableBalance(coin, currency) {
      if (!this.accountBalances[Const.TYPE_MAIN_BALANCE][coin]) return 0;

      let balance =
        this.accountBalances[Const.TYPE_MAIN_BALANCE][coin].available_balance;
      if (coin === currency) {
        return `${balance}`;
      }

      return this.convertCoins(coin, Const.AML, balance);
    },
    getBtcBalance(coin, currency) {
      if (!this.accountBalances[Const.TYPE_MAIN_BALANCE][coin]) return 0;

      let balance = this.accountBalances[Const.TYPE_MAIN_BALANCE][coin].balance;
      if (coin === currency) {
        return `${balance}`;
      }

      return this.convertToBtcValue(coin, balance);
    },
    getBtcAvailableBalance(coin, currency) {
      if (!this.accountBalances[Const.TYPE_MAIN_BALANCE][coin]) return 0;

      let balance =
        this.accountBalances[Const.TYPE_MAIN_BALANCE][coin].available_balance;
      if (coin === currency) {
        return `${balance}`;
      }

      return this.convertToBtcValue(coin, balance);
    },
    convertToBtcValue(coin, value) {
      let balance = value;
      if (new BigNumber(balance.toString()).isZero()) {
        return 0;
      }

      let pair = `btc_${coin}`;

      if (!this.prices[pair] || !this.prices[pair].price) {
        pair = `${coin}_btc`;
        if (!this.prices[pair] || !this.prices[pair].price) {
          return 0;
        }
        return new BigNumber(`${balance.toString()}`)
          .div(this.prices[pair].price)
          .toString();
      }
      return new BigNumber(balance.toString())
        .mul(this.prices[pair].price)
        .toString();
    },
    convertBtcToCoinValue(coin, value) {
      let balance = value;
      let pair = `btc_${coin}`;
      if (!this.prices[pair] || !this.prices[pair].price) {
        pair = `${coin}_btc`;
        if (!this.prices[pair] || !this.prices[pair].price) {
          return 0;
        }
        return new BigNumber(`${balance.toString()}`)
          .mul(this.prices[pair].price)
          .toString();
      }
      return new BigNumber(`${balance.toString()}`)
        .div(this.prices[pair].price)
        .toString();
    },
    convertCoins(fromCoin, toCoin, value) {
      // Example:
      // If do not have price of usdt_$coin pair, transform to btc_$coin pair then transform to usdt_btc pair.
      let btcValue = this.convertToBtcValue(fromCoin, value);
      let newValue = this.convertBtcToCoinValue(toCoin, btcValue);

      return newValue;
    },
    getOrderBtcBalance(item) {
      if (!this.prices[`btc_${item.coin}`]) {
        return 0;
      }
      return new BigNumber(item.totalBalance)
        .minus(item.availableBalance)
        .mul(this.prices[`btc_${item.coin}`].price)
        .toString();
    },
    calculateInOrder(item) {
      // const result =  window._.find(this.totalPendingWithdraws, (row) => { return row && row.currency === item.coin});
      // const totalPendingWithdraw = result ? result.total : '0';
      // return new BigNumber(item.totalBalance).sub(item.availableBalance).sub(totalPendingWithdraw).toString();
      return new BigNumber(`${item.totalBalance.toString()}`).toString();
    },
    sumBy(attr, to) {
      let total = new BigNumber(0);
      for (let item of this.balances) {
        if (
          item[attr] == "NaN" ||
          new BigNumber(item[attr].toString()).isZero()
        ) {
          continue;
        }
        if (to === "btc") {
          let value = this.convertToBtcValue(
            item.coin.toLowerCase(),
            item.availableBalance
          );
          total = total.add(value.toString());
          continue;
        } else {
          total = total.add(item[attr].toString());
        }
      }
      return total.toString();
    },
    onBalanceUpdated(newAccountBalances) {
      this.accountBalances = newAccountBalances;
      this.transferData(false);
      // this.$refs.datatable.fetch();
    },
    onPricesUpdated(newPrices) {
      this.prices = Object.assign({}, this.prices, newPrices);
      this.addPricesInBtc();
      // this.refreshNow();
      this.transferData(false);
      // this.$refs && this.$refs.datatable && this.$refs.datatable.refresh();
    },
    onMainBalanceUpdated(newAccountBalances) {
      // if (
      //     mainUsdBalance &&
      //     mainUsdBalance.coin_used &&
      //     mainUsdBalance.coin_used == "usd" &&
      //     this.accountBalances &&
      //     this.accountBalances.main &&
      //     this.accountBalances.main.usd
      // ) {
      //   this.accountBalances.main.usd.balance = mainUsdBalance.total_balance;
      //   this.accountBalances.main.usd.available_balance =
      //       mainUsdBalance.available_balance;
      //   this.refresh();
      // }
      this.accountBalances = window._.merge({}, this.accountBalances, {
        main: newAccountBalances,
      });
      this.transferData(true);
      // this.$refs.datatable.fetch();
    },
    onBalanceSpotUpdated(newAccountBalances) {
      this.accountBalances = window._.merge({}, this.accountBalances, {
        spot: newAccountBalances,
      });
      this.transferData(true);
      // this.$refs.datatable.fetch();
    },
    onAmlBalanceUpdated(mainAmalBalance) {
      this.accountBalances.main.amal.balance = mainAmalBalance.total_balance;
      this.accountBalances.main.amal.available_balance =
        mainAmalBalance.available_balance;
      this.refresh();
    },
    getSocketEventHandlers() {
      return {
        PricesUpdated: this.onPricesUpdated,
        BalanceUpdated: this.onBalanceUpdated,
        MainBalanceUpdated: this.onMainBalanceUpdated,
        AmlBalanceUpdated: this.onAmlBalanceUpdated,
        SpotBalanceUpdated: this.onBalanceSpotUpdated,
      };
    },
    getTotalPendingWithdraw(currencies, callback) {
      if (window._.isEmpty(currencies)) {
        return;
      }

      const isContainUsdCurrency = window._.includes(currencies, "usd");

      if (!isContainUsdCurrency || currencies.length > 1) {
        rf.getRequest("TransactionRequest")
          .getTotalPendingWithdraw()
          .then((res) => {
            window._.each(res.data, (item) => {
              this.updateCurrencyPendingWithdraw(item.currency, item.total);
            });
            if (callback) {
              callback();
            }
          });
      }
      // usd currency
      if (isContainUsdCurrency) {
        rf.getRequest("TransactionRequest")
          .getTotalUsdPendingWithdraw()
          .then((res) => {
            if (!res.data.total) {
              return;
            }
            this.updateCurrencyPendingWithdraw("usd", res.data.total);
            if (callback) {
              callback();
            }
          });
      }
    },
    updateCurrencyPendingWithdraw(currency, totalPendingWithdraw) {
      const item = window._.find(
        this.totalPendingWithdraws,
        (item) => item.currency === currency
      );
      if (item) {
        item.total = totalPendingWithdraw || "0";
      } else {
        this.totalPendingWithdraws.push(item);
      }
    },
    validate(item) {
      this.errors.remove("amount" + item.coin);
      this.errors.remove("server" + item.coin);
      let amount = new BigNumber(item.amount || 0);
      let isTransferFromMain = item.isTransferFromMain;
      if (amount.isZero()) {
        this.errors.add(
          "amount" + item.coin,
          window.i18n.t("funds.balances.amount_over_0")
        );
        return false;
      }

      if (amount.lt(MIN_AMOUNT_CRYPTO)) {
        this.errors.add(
          "amount" + item.coin,
          window.i18n.t("funds.balances.min_amount_is_invalid")
        );
        return false;
      }

      let availableBalance = new BigNumber(item.availableBalance);
      let exchangeBalance = new BigNumber(item.exchangeBalance);
      let exchangeAvailabelBalance = new BigNumber(
        item.exchangeAvailabelBalance
      );
      let marginBalance = new BigNumber(item.marginBalance.toString());
      let marginAvailabelBalance = new BigNumber(
        item.marginAvailabelBalance.toString()
      );

      if (isTransferFromMain) {
        if (availableBalance.lt(amount)) {
          this.errors.add(
            "amount" + item.coin,
            window.i18n.t(
              "funds.balances.amount_can_not_greater_than_exchange_available_balance_without_orderbook"
            )
          );
          return false;
        }
      } else {
        if (
          item.transferType == TRANSFER_TYPE_EXCHANGE_BALANCE &&
          exchangeBalance.lt(amount)
        ) {
          this.errors.add(
            "amount" + item.coin,
            window.i18n.t(
              "funds.balances.amount_can_not_greater_than_available_balance"
            )
          );
          return false;
        }

        // With Spot Exchange has orders in orderbook (orders is not match),
        // balance in orders is locking
        // Need check amount with condition:
        // amount <= exchangeAvailabelBalance  --> ok
        // amount > exchangeAvailabelBalance   --> error
        if (
          item.transferType == TRANSFER_TYPE_EXCHANGE_BALANCE &&
          exchangeAvailabelBalance.lt(amount)
        ) {
          this.errors.add(
            "amount" + item.coin,
            window.i18n.t(
              "funds.balances.amount_can_not_greater_than_exchange_available_balance_without_orderbook"
            )
          );
          return false;
        }

        if (
          item.transferType == TRANSFER_TYPE_MARGIN_BALANCE &&
          marginBalance.lt(amount)
        ) {
          this.errors.add(
            "amount" + item.coin,
            window.i18n.t(
              "funds.balances.amount_can_not_greater_than_available_balance"
            )
          );
          return false;
        }
        if (
          item.transferType == TRANSFER_TYPE_MARGIN_BALANCE &&
          marginAvailabelBalance.lt(amount)
        ) {
          this.errors.add(
            "amount" + item.coin,
            window.i18n.t(
              "funds.balances.amount_can_not_greater_than_margin_available_balance"
            )
          );
          return false;
        }
      }

      return true;
    },
    handleChangeAmount(item) {
      // this.validate(item);
    },
    onPercentClicked(item, percent) {
      let balance = 0;
      this.precentTransfer = percent;
      this.isSelectedPercent = true;
      if (item.isTransferFromMain) {
        balance = item.availableBalance;
      } else {
        if (item.transferType === "spot") {
          balance = item.exchangeAvailabelBalance;
        } else if (item.transferType === "future") {
          balance =
            this.balanceFutures.assets?.[item.coin.toUpperCase()]
              ?.availableBalance;
        }
      }
      if (balance === null) {
        return (item.amount = 0);
      }
      if (balance.toString().length > 15) {
        balance = parseFloat(balance).toFixed(14);
      }
      if (percent < 100) {
        item.amount = new BigNumber(balance).mul(percent).div(100).toString();
      } else {
        item.amount = balance;
      }
    },
    transferBalance(e, item) {
      e.preventDefault();
      if (!this.validate(item)) {
        return false;
      }
      this.sort = this.$refs.datatable.orderBy;
      this.sortType = this.$refs.datatable.sortedBy;
      this.hasSortWhenTransfer = true;
      if (item.transferType === "future") {
        this.transferBalanceFuture(e, item);
        return;
      }
      if (this.isSubmittingTransfer) {
        return false;
      }
      this.isSubmittingTransfer = true;
      let amount = item.amount || 0;
      let isTransferFromMain = item.isTransferFromMain;
      let data = {
        coin_value: amount,
        coin_type: item.coin,
        from: isTransferFromMain
          ? TRANSFER_TYPE_MAIN_BALANCE
          : item.transferType,
        to: isTransferFromMain ? item.transferType : TRANSFER_TYPE_MAIN_BALANCE,
      };
      rf.getRequest("UserRequest")
        .transferBalance(data)
        .then((response) => {
          this.isSubmittingTransfer = false;
          if (!response.success || !response.data) {
            this.showErrorMessage(
              window.i18n.t("funds.balances.transfer_balance_fails")
            );
            this.isLoading = false;
          } else {
            this.showInfoMessage(
              window.i18n.t("funds.balances.transfer_balance_success")
            );
            item.amount = "";
            this.precentTransfer = 0;
            rf.getRequest("UserRequest")
              .getBalance(false)
              .then((res) => {
                this.onBalanceUpdated(res.data);
              });
          }
        })
        .catch((err) => {
          this.isSubmittingTransfer = false;
        });
    },
    transferBalanceFuture(e, item) {
      e.preventDefault();
      this.errors.remove(`amount${item.coin}`);
      this.errors.remove(`server${item.coin}`);

      let balance = item.isTransferFromMain
        ? item.availableBalance
        : item.marginAvailabelBalance;
      balance =
        balance.toString().length > 15
          ? parseFloat(balance).toFixed(14)
          : balance;

      this.isSubmittingTransfer = true;
      const amount = item.amount || 0;

      const data = {
        amount: amount,
        assetType: item.coin.toUpperCase(),
      };
      const dataTransfer = {
        from: TRANSFER_TYPE_MAIN_BALANCE,
        to: "future",
        amount: amount,
        asset: item.coin,
      };
      const request = !item.isTransferFromMain
        ? "withdrawFutureBalance"
        : "transferFutureBalance";
      const bodyData = !item.isTransferFromMain ? data : dataTransfer;
      rf.getRequest("UserRequest")
        [request](bodyData)
        .then((response) => {
          this.isSubmittingTransfer = false;
          this.getBalanceFutureAPI();
          this.positionMargin();
          this.getBalanceAPI();
          item.amount = 0;
          // this.$refs && this.$refs.datatable && this.$refs.datatable.refresh();
        })
        .catch((err) => {
          this.isSubmittingTransfer = false;
        });
    },

    swapBalance(e, item) {
      e.preventDefault();
      item.isTransferFromMain = !item.isTransferFromMain;
      this.precentTransfer = "";
      item.amount = "";
      this.errors.clear();
      // this.validate(item);
    },
    showErrorMessage(message, callBack = () => {}) {
      window.MessageWithIcon.error(message);
    },
    showInfoMessage(message, callBack = () => {}) {
      window.MessageWithIcon.success(message);
    },
    getWithdrawalLimitBTC() {
      rf.getRequest("UserRequest")
        .getWithdrawalLimitBTC()
        .then((res) => {
          //this.withdrawalLimitBTC = res.data;
          return res;
        });
    },
    getUserInformation() {
      rf.getRequest("UserRequest")
        .getCurrentUser()
        .then((res) => {
          let securityLevel = res.data.security_level;

          rf.getRequest("UserRequest")
            .getWithdrawalLimitBTC()
            .then((resWithdrawalLimit) => {
              let withdrawalLimitBTC = resWithdrawalLimit.data;
              if (withdrawalLimitBTC) {
                this.limit = window._.find(withdrawalLimitBTC, (wl) => {
                  return (
                    wl.security_level === securityLevel && wl.currency === "btc"
                  );
                });
              }
            });
        });
    },
    handleMasterData() {
      this.currencyCoins = this.masterData.coin_settings;
      this.coinSetting = window._.keyBy(
        this.masterData.coins_confirmation,
        (item) => item.coin
      );
      this.coinsMasterData = window._.keyBy(
        this.masterData.coins,
        (item) => item.coin
      );
      this.coinsMasterData.usd = {
        name: window.i18n.t(`currency.usd.fullname`),
      };

      _.forEach(this.masterData.coins, (item, key) => {
        this.coinNames[item.coin] = item.name;
        this.coinImages[item.coin] = item.icon_image;
      });

      this.coinNames["usd"] = window.i18n.t(`currency.usd.fullname`);
      this.coinImages["usd"] = `/images/color_coins/usd.png`;
      this.coinImages["eth"] = this.ethicon;
    },

    getBalanceAPI() {
      return rf
        .getRequest("UserRequest")
        .getBalance(false)
        .then((res) => {
          this.COIN_TRANSFER_TYPE.SPOT = Object.keys(res.data.spot).map((key) =>
            key.toUpperCase()
          );
          this.onBalanceUpdated(res.data);
        });
    },

    getBalanceFutureAPI() {
      return rf
        .getRequest("UserRequest")
        .getFuturesBalances()
        .then((res) => {
          const usd_m_assets = ["USDT"];
          const listWithoutUSD = res.data.filter(item => item.asset !== 'USD')
          const assetsArray = listWithoutUSD
            .filter((item) => usd_m_assets.indexOf(item.asset) === -1) // Lọc ra các asset không phải USD hoặc USDT
            .map((item) => item.asset);
          this.COIN_TRANSFER_TYPE.COIN_M = assetsArray;
          this.assetsWallet = listWithoutUSD;
          const assetsKeyObject = {};
          listWithoutUSD.forEach((asset) => {
            assetsKeyObject[asset.asset] = {
              balance: asset?.balance,
              marginBalance: 0,
              availableBalance: 0,
              totalAllowcated: 0,
              unrealisedPnl: 0,
              unrealisedROE: "",
            };
          });
          this.balanceFutures = {
            assets: assetsKeyObject,
            totalBalance: listWithoutUSD.totalBalance,
            totalMarginBalance: listWithoutUSD.totalMarginBalance,
          };
        });
    },

    getPriceAPI() {
      return rf
        .getRequest("PriceRequest")
        .getPrices()
        .then((res) => {
          this.onPricesUpdated(res.data);
          this.getPriceCoinGecKotoBTC();
        });
    },

    getInstruments() {
      return rf
        .getRequest("UserRequest")
        .getInstruments()
        .then((res) => {
          const assetsKeyObject = {};
          return res.data.forEach((asset) => {
            assetsKeyObject[asset.symbol] = {
              ...asset,
            };
            this.listInstruments = assetsKeyObject;
          });
        });
    },

    async initData() {
      await Promise.all([
        this.getInstruments(),
        this.getPrice24h(),
        this.positionMargin(),
        this.getSumOrderMargin(),
        this.getPriceAPI(),
        this.getBalanceAPI(),
        this.getBalanceFutureAPI(),
      ]);
    },
  },
  created() {
    if (!this.isAuthenticated) {
      return;
    }
    this.initData().then(() => {
      this.$refs && this.$refs.datatable && this.$refs.datatable.refresh();
    });
  },
  mounted() {
    document.title = this.$t("menu.balances") + ` – ${this.tabTitle}`;
    this.handleMasterData();
    this.tongglePrice = this.isShowBalances.balances;
    this.sockets.subscribe("tickers", (data) => {
      this.Price24h = data;
    });
    this.sockets.subscribe("position", (data) => {
      if (data.contractType === this.contractType) {
        const index = this.positionData.findIndex(
          (obj) => obj.symbol == data.symbol
        );
        if (index != -1) {
          this.positionData[index] = data;
          if (data.currentQty == 0) {
            this.positionData.splice(index, 1);
          }
        } else if (data.currentQty != 0) {
          this.positionData.unshift(data);
        }
      }
    });
    this.sockets.subscribe("balance", (data) => {
      this.balanceFutures.assets[data.asset].balance = data.balance;
      // this.marginBalance()
      this.availableBalanceWithdraw();
    });
    this.sockets.subscribe("orders", (data) => {
      data.forEach((elements) => {
        if (elements.status == "CANCELED") {
          const index = this.openOrder.findIndex((obj) => {
            return elements.symbol == obj.symbol;
          });
          this.openOrder.splice(index, 1);
          return;
        }
        this.openOrder.push(elements);
      });
      this.sumCostOpenOrder(this.openOrder);
    });
    this.sockets.subscribe("notifications", (data) => {
      const msg = data[0];
      if (msg.amount) {
        if (msg.type == "success") {
          this.showInfoMessage(
            window.i18n.t("funds.balances.transfer_balance_success")
          );
        } else {
          this.showErrorMessage(
            window.i18n.t("funds.balances.transfer_balance_fails")
          );
        }
      }
    });
    this.getUserInformation();
    this.$on("UPDATED_LOCALE", () => {
      if (this.$refs.datatable) {
        const activeRows = this.$refs.datatable.rows.filter((row) => {
          return row.isActiveRow;
        });
        if (!activeRows) {
          return;
        }
        activeRows.forEach((row) => {
          this.validate(row);
        });
      }
    });
  },
  beforeDestroy() {
    this.sockets.unsubscribe("notifications");
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
@import "@/assets/sass/common.scss";
#balance {
  .tableContainer {
    overflow-y: auto;
    .group-sort-tabl3 {
      display: inline-table !important;
      width: 13px;
      height: 20px;
      vertical-align: middle;
      position: relative;
      margin-top: 5px;
    }
  }
}
.tool-tip-custom {
  background: var(--color-tooltip);
  border-radius: 8px;
  margin-top: 9px;
  padding: 10px;
  width: 227px;
  height: 56px;
  color: $color-white !important;
  font-size: $font-root;
}
.min_w150 {
  // min-width: 150px;
  width: 70px;
}
.min_w120 {
  width: 90px;
  min-width: 60px;
}
.max_w180 {
  width: 115px;
}
.min_w100 {
  min-width: 100px;
}
.min_w80 {
  min-width: 80px;
}
.table-history {
  padding-bottom: 15px;
}
.full-page-background {
  width: 100%;
  display: inline-block;
  padding-top: 60px;
}
.number-entry-user {
  text-align: center;
  font-weight: normal;
  .title-entry {
    font-size: 30px;
    font-weight: bold;
  }
}
.error {
  border: solid 1px $color-red-main !important;
}
.invalid-feedback {
  width: 100% !important;
  color: $text-color-red;
  font-size: 14px;
}
#balance {
  .box-flex-table {
    display: flex;
    align-items: center;
  }
  &.is_no_single_page {
    .balance-container {
      margin: 0;
      .table-container thead th {
        height: 22px;
        border-bottom: 1px solid $color-gray;
        font-size: $font-mini;
      }
    }
  }
  table {
    tbody {
      tr {
        overflow-y: initial;
      }
    }
  }
}
.balance-container {
  display: inline-block;
  width: 100%;
  .box-title {
    margin-bottom: $title-bottom-margin;
    &.box-title-secord {
      margin-top: 35px;
    }
  }
  @media screen and (max-width: 1680px) {
    .content-table {
      // position: relative;
      width: 100%;
      z-index: 5;
      border: 0px;
      border-top: none;
      overflow-y: auto;
      overflow-x: auto;
    }
  }
}
.balance-info {
  line-height: 25px;
  width: 200px;
  margin-top: 8px;
  margin-left: -2px;
  font-weight: 100;
  font-size: $font-small;
  padding-right: 5px;
  padding-left: 5px;
  position: absolute;
  background: $background-default;
  border: $border_thin;
  box-shadow: 0 2px 10px 0 $border-color-grey;
  border-top: none;
  z-index: 10;
  padding: 5px 10px;
}
label,
input {
  margin: 0;
  padding: 0;
  font-weight: normal;
  color: var(--text-primary-color);
}
.sort_up {
  color: $color-corn-pale !important;
}
.sort_down {
  color: $color-corn-pale !important;
}
.es-toolbar {
  font-size: $font-root;
  margin-bottom: 80px;
  .icon {
    color: $color-grey-dusty;
  }
  .btn-small-icon {
    width: 200px;
    height: 32px;
    margin-right: 10px;
    line-height: 20px;
    cursor: pointer;
    color: $color-grey-dark;
    border-radius: 8px;
    padding: 5px 1px 4px 1px;
    position: relative;
    .checkbox-input {
      width: 21px;
      position: absolute;
      top: 4px;
      left: -4px;
      cursor: pointer;

      input {
        display: none;
      }

      label {
        cursor: pointer;
        position: absolute;
        width: 21px;
        height: 21px;
        top: 4px;
        left: 0px;
        border: 1px solid var(--color-input);
        border-radius: 2px;
        background: var(--color-input);
        &:after {
          content: "";
          position: absolute;
          left: 6px;
          top: 1px;
          width: 6px;
          height: 13px;
          border: solid $color-grey-dark;
          border-width: 0 2px 2px 0;
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
          display: none;
        }
      }
      input[type="checkbox"]:checked + label:after {
        opacity: 1;
      }
      #checkboxFiveInput {
        display: none;
        &[type="checkbox"]:checked + .button-check {
          background-color: $color-caribbean-green;
        }
        &[type="checkbox"]:checked + .button-check:after {
          display: block;
        }
      }
    }
    #tooltip-target-1 {
      .tool-tip-custom {
        display: none;
      }
      &:hover {
        .tool-tip-custom {
          display: block;
          position: absolute;
          left: -30px;
          top: 16px;
          text-align: left;
          z-index: 100;
        }
      }
    }
    span {
      position: absolute;
      left: 24px;
      top: 8px;
      font-weight: 400;
      font-size: 18px;
      color: var(--color-percent);
    }
  }
  .btn-checkbox {
    width: 70px;
    height: 32px;
    line-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $color-gray;
    cursor: pointer;
    margin-right: 10px;
    margin-left: 10px;
    border-radius: 8px;
    padding: 5px 1px 4px 1px;
    .checkbox-input {
      width: 12px;
      height: 12px;
      margin-right: 5px;
      position: relative;
      top: -1px;
      input {
        display: none;
      }
      label {
        cursor: pointer;
        position: absolute;
        width: 12px;
        height: 12px;
        top: 0;
        left: 0;
        border: none;
        border-radius: 1px;
        &:after {
          opacity: 0;
          content: "";
          position: absolute;
          width: 12px;
          height: 5px;
          background: transparent;
          top: 0px;
          left: 0px;
          border: 3px solid $color-grey-dusty;
          border-top: none;
          border-right: none;
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }
      }
      input[type="checkbox"]:checked + label:after {
        opacity: 1;
      }
      #checkboxFiveInput {
        display: none;
        &[type="checkbox"]:checked + .button-check {
          background-color: $color-caribbean-green;
        }
        &[type="checkbox"]:checked + .button-check:after {
          display: block;
        }
      }
    }
  }
  .btn-convert-icon {
    cursor: pointer;
    border-radius: 8px;
    min-width: 150px;
    height: 32px;
    line-height: 20px;
    border: 1px solid $color-alto;
    cursor: pointer;
    color: $color-grey-dark;
    border-radius: 8px;
    margin-right: 10px;
    padding: 5px 1px 4px 1px;
    span {
      vertical-align: middle;
    }
    .icon-convert {
      font-size: $font-title-size-small;
      margin-right: 5px;
    }
    &:hover {
      color: $color-white;
      background-color: $color-blue-custom;
      border-color: $color-blue-custom;
      .icon {
        color: $color-white;
      }
    }
    pointer-events: none;
    opacity: 0.5;
  }
  .input-search {
    width: 170px;
    height: 32px;
    border: 1px solid $color-gray;
    border-radius: 8px;
    background-color: $color-white;
    &:focus-within {
      border-radius: 8px;
      border: solid 1px $color-jungle-green;
    }
    input {
      background: $color-white;
      font-size: $font-small;
      color: $text-grey;
      font-weight: normal;
      border: none;
      width: calc(100% - 37px);
      height: 30px;
      padding-left: 0px;
      float: left;
      line-height: 20px;
      padding-top: 5px;
      padding-bottom: 4px;
      &:focus {
        outline: none;
      }
    }
    .btn-search {
      font-size: $font-title-size-small;
      font-weight: bold;
      display: block;
      text-align: center;
      width: 35px;
      height: 32px;
      .icon-search {
        font-size: $font-big-17;
        line-height: 31px;
      }
    }
  }
  .estimate-value {
    @media only screen and (max-width: 1086px) {
      margin-top: 16px;
      float: left;
      text-align: left;
    }
    color: $color-grey-dusty;
    font-size: $font-small;
    text-align: right;
    margin-top: 12px;
    .total {
      font-weight: normal;
      margin-bottom: 8px;
      line-height: 16px;
      .number-value {
        color: $color-grey-dark;
        font-size: $font-root;
      }
    }
    .limit {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      color: var(--text-primary-color);
      span {
        font-weight: 600;
        font-size: 16px;
        margin-left: 4px;
      }
      .in-use {
        margin-left: 12px;
      }
      @media screen and (max-width: 375px) {
        display: block;
        .in-use {
          margin-left: -8px;
          margin-top: 16px;
        }
      }
    }
  }
  @media screen and (max-width: 1837px) {
    margin-bottom: 30px;
    margin-top: 62px;
    .btn-small-icon {
      .checkbox-input {
        label {
          left: 4px;
        }
      }
      span {
        right: 0px;
      }
    }
    .estimate-value {
      margin-top: 8px;
    }
    span {
      left: 28px;
    }
  }
  &::after {
    content: unset;
  }
}
.table-container {
  background: $color-white;
}
.icon-coin {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  vertical-align: middle;
  object-fit: contain;
}
.group_button_tooltip {
  display: inline-block;
  float: left;
  position: relative;
  // width: 23px;
  .btn-deposit-tooltip {
    display: inline-block;
    float: right;
    height: 23px;
    overflow: hidden;
    width: auto;
    padding: 1px 5px;
    border-radius: 11.5px;
    font-size: 20px;
    font-weight: 600;
    border: 0px;
    i.icon-signal2 {
      color: $color-alizarin-crimson;
      position: relative;
      top: 2px;
      margin-right: 2px;
    }
  }
  .tooltip_hover {
    position: absolute;
    width: 165px;
    line-height: 20px;
    padding: 16px 14px 14px 19px;
    color: $color-grey-dark;
    font-size: $font-root;
    font-weight: 500;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.22);
    background-color: $color-white;
    border-radius: 8px;
    right: 100%;
    margin-right: 2px;
    top: -50%;
    margin-top: -7px;
    z-index: 5;
    display: none;
    text-align: left;
    white-space: normal;
  }
  &:hover {
    .tooltip_hover {
      display: block;
    }
  }
}
#balance {
  &.page_container {
    .balance-container {
      padding: 40px;
      padding-right: 260px;
      @media screen and (max-width: 1919px) {
        padding-right: 3%;
      }
      .box-title {
        color: var(--text-primary-color);
      }
      .title-medium-small {
        font-weight: 600;
        font-size: $font-title-size-medium-2;
      }
      .input-search {
        position: relative;
        margin-right: 40px;
        input {
          width: 380px;
          height: 42px;
          border-radius: 8px;
          border: solid 1px var(--color-tab-data-table);
          background: var(--color-tab-data-table);
          padding-left: 15px;
          padding-right: 40px;

          &::placeholder {
            color: var(--bgr-btn);
          }
        }
        a {
          position: absolute;
          right: 14.5px;
          top: 6px;
          font-size: 20px;
        }
        @media screen and (max-width: 375px) {
          input {
            width: 343px;
          }
        }
      }
      .table-container {
        th {
          border-right: unset;
          line-height: 20px;
          padding: 6px 0px 6px 0px;
          font-size: $font-root;
          font-weight: 400;
          color: var(--color-percent);
          background-color: var(--color-header-table);
          @media screen and (max-width: 992px) {
            font-size: $font-root;
          }
          &:nth-child(9) {
            padding: 20px 10px 10px 0px;
          }
          &.after_none:after {
            display: none;
          }
          &.coin_balances {
            min-width: 135px;
            padding-left: 15px;
          }
          &.name_blances {
            width: 140px;
          }
          &.totalBalance {
            width: 170px;
          }
          &.availableBalance {
            width: 170px;
          }
          &.exchangeBalance {
            width: 210px;
          }
          &.futureBalances {
            width: 190px;
          }
          &.action {
            width: 260px;
            padding-right: 12px;
            text-align: right;
          }
          &[data-sort-order="asc"],
          &[data-sort-order="desc"] {
            color: $color-jungle-green;
          }
        }
        th[data-sort-order="desc"],
        th[data-sort-order="asc"] {
          color: $color-grey !important;
        }
        tr {
          td {
            // text-align: center;
            font-size: $font-root;
            font-weight: 500;
            color: var(--text-primary-color);
            line-height: 21px;
            padding: 23px 4px;
            overflow: visible;
            vertical-align: middle;
            &.coin_balances {
              padding-left: 15px;
              min-width: 135px;
            }
            &.action_btn {
              text-align: right !important;
              span {
                a {
                  font-weight: 400;
                  font-size: $font-root;
                  line-height: 22px;
                  margin-right: 3px;
                  color: $color-aqua-green;
                }
              }
            }
            &:nth-child(9) {
              padding: 7px 10px 6px 0px;
            }
            &:last-child {
              text-align: left;
            }
            .icon_balances {
              font-size: 20px;
              color: $color-jungle-green;
              cursor: pointer;
            }
            .icon_balances.active-icon {
              color: $color-eden;
            }
            .btn-lose-color {
              .icon_balances {
                color: $color-grey;
                cursor: not-allowed;
              }
            }
            &.tranfer-cell {
              // padding-left: 6px;
            }
            .btn-transfer {
              display: block;
              margin-left: auto;
              margin-right: auto;
              width: 38px;
              height: 38px;
              line-height: 38px;
              position: relative;
              margin-top: -12px;
              top: 6px;
              cursor: pointer;
              border-top-left-radius: 3px;
              border-top-right-radius: 3px;
              text-align: center;
              background-color: transparent;
            }
            &.acvite_transfer {
              .btn-transfer {
                background-color: $color-bright-gray;
              }
            }
            .btn-mam {
              display: inline-block;
              width: 38px;
              height: 38px;
              line-height: 42px;
              position: relative;
              margin-top: -18px;
              font-size: 11px;
              top: 12px;
              cursor: pointer;
              border-top-left-radius: 3px;
              border-top-right-radius: 3px;
              text-align: center;
              overflow: hidden;
              background-color: transparent;
              margin-left: 3px;
            }
            &.acvite_mam {
              .btn-mam {
                background-color: $color-bright-gray;
              }
            }
          }
          &:hover {
            // background-color: $color-bright-gray;
            td {
              .icon_balances {
                color: $color-eden;
              }
              .btn-lose-color {
                .icon_balances {
                  color: $color-grey;
                  cursor: not-allowed;
                }
              }
            }
          }
        }
        .table_drop_balances {
          position: relative;
          padding: 22px 37px 31px 37px;
          background-color: $color-bright-gray;
          overflow: visible;
          background: var(--color-tranfer);
          @media screen and (max-width: 768px) {
            padding: 24px 20px;
          }
          .close_tbale {
            position: absolute;
            top: 20px;
            right: 20px;
            display: block;
            width: 20px;
            height: 20px;
            font-size: $font-smaller;
            line-height: 20px;
            text-align: center;
            color: $color-grey-dark;
            cursor: pointer;
            .icon-close::before {
              color: var(--color-close-toast);
            }
          }
          &:hover {
            // background-color: $color-bright-gray;
          }
          .content_transfer_form {
            .transfer_form {
              display: flex;
              .form-left {
                display: flex;
                justify-content: center;
              }
              .form-right {
                display: flex;
              }
            }
          }
        }

        @media screen and (max-width: 1680px) {
          min-width: 1440px;
        }
      }
      @media screen and (max-width: 1024px) {
        padding-right: 40px;
      }
      @media screen and (max-width: 992px) {
        padding: 16px;
        max-width: 100%;
      }
    }
  }
  .group_transfer_form {
    display: block;
    float: left;
    margin-right: 15px;
    img {
      padding-top: 13px;
      cursor: pointer;
    }
    .btn-transfer-form {
      border-radius: 8px;
      border: solid 1px $color-alto;
      background-color: $color-bright-gray;
      height: 40px;
      width: 40px;
      font-size: 20px;
      color: $color-eden;
      padding: 8px;
      overflow: hidden;
      text-align: center;
      &:hover {
        color: $color-bright-gray;
        background-color: $color-eden;
      }
    }
    .btn-send-transfer-form {
      border-radius: 8px;
      border: solid 1px $color-aqua-green;
      background-color: $color-aqua-green;
      height: 40px;
      width: 111px;
      font-size: $font-big;
      font-weight: 400;
      color: $dark-1;
      overflow: hidden;
      text-align: center;
      letter-spacing: 0.9px;
      line-height: 21px;
      &:focus,
      &:hover {
        background-color: $color-aquamarine !important;
        border: solid 1px $color-aquamarine !important;
        color: $dark-1;
      }
    }
    label.txt {
      display: block;
      width: 100%;
      line-height: 20px;
      color: var(--text-primary-color);
      font-size: 18px;
      font-weight: 400;
      height: 20px;
      margin-bottom: 10px;
    }
    input.input-form {
      border-radius: 8px;
      // border: solid 1px $color-alto;
      background-color: var(--color-tranfer-from);
      height: 42px;
      padding: 10px 13px;
      font-size: 18px;
      line-height: 22px;
      border: unset;
      &::placeholder {
        color: var(--color-percent);
      }
    }
    select.input-form {
      border-radius: 8px;
      border: solid 1px $color-alto;
      background-color: $color-bright-gray;
      height: 42px;
      font-size: $font-root;
      color: $color-grey-dark;
      padding: 10px 10px;
      line-height: 20px;
      border: unset;
    }
    .w_155 {
      width: 220px;
      border: 1px solid var(--color-border-input);
    }
    .w_220 {
      width: 220px;
    }
    .input-amount {
      position: relative;
      .name_amount {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 18px;
        line-height: 22px;
        text-transform: uppercase;
        color: var(--color-pagi);
      }
      input.input-form {
        padding-right: 40px;
        background-color: var(--color-tranfer-to);
        width: 344px;
        border: 1px solid var(--color-border-input);
      }
      & > input:focus {
        border: 1px solid #2dac91;
      }
    }
  }
  .open {
    .select-icon:before {
      transform: rotate(180deg);
    }
  }
  .select-control {
    border-radius: 8px;
    border: unset;
    background-color: var(--color-tranfer-to);
    height: 42px;
    font-size: 18px;
    line-height: 22px;
    // color: var(--color-text);
    padding: 10px 10px;
    text-align: left;
    .select-icon:before {
      color: var(--color-percent);
      float: right;
      font-size: 11px;
      line-height: 22px;
      display: inline-block;
      position: absolute;
      top: 10px;
      right: 9px;
    }
    span {
      color: var(--color-percent);
    }
    &:hover {
      border-color: $color-jungle-green !important;
    }
  }
  .dropdown-menu {
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.16);
    background-color: var(--bgr-dropdown);
    padding: 10px 0px;
    .tab-element {
      cursor: pointer;
      display: block;
      width: 100%;
      line-height: 20px;
      padding: 5px 20px;
      font-size: $font-medium-bigger;
      color: var(--text-primary-color);
      &:hover {
        color: $color-caribbean-green;
      }
    }
    .disable {
      pointer-events: none;
      color: var(--text-disable);
    }
    .disabled-item {
      display: none;
      background-color: #bbb;
      cursor: not-allowed;
      color: white;
      &:hover {
        color: white;
      }
    }
  }
  .input_right_group {
    margin-bottom: 12px;
    width: 100%;
    .check_percent {
      display: inline-block;
      margin-top: 5px;
      width: calc((100% / 4) - 4.5px);
      margin-right: 6px;
      border: 1px solid var(--color_transfer_border_to);
      background-color: var(--color-tranfer-to);
      line-height: 21px;
      text-align: center;
      padding: 5px 5px;
      color: var(--color-percent);
      font-size: $font-root;
      border-radius: 4px;
      height: 32px;
      float: left;
      cursor: pointer;
      border-radius: 4px;
      &.last_item {
        margin-right: 0px;
      }
      &:hover {
        background: $color-aquamarine;
        border-color: $color-aquamarine;
        color: $dark-1;
        -webkit-transition: 0.5s;
        transition: 0.5s;
      }
    }
    .activePrecent {
      background: $color-caribbean-green;
      border-color: $color-caribbean-green;
      color: $dark-1 !important;
      font-weight: 600;
      font-size: $font-root;
      -webkit-transition: 0.5s;
      transition: 0.5s;
    }
  }
}
@media screen and (max-width: 1680px) {
  .table-container {
    min-width: 1440px;
  }
}
@media screen and (max-width: 992px) {
  #balance {
    &.page_container {
      .balance-container {
        .title-medium-small {
          font-size: 24px;
          line-height: 29px;
        }
      }
    }
  }
  .es-toolbar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0px;
    .btn-small-icon {
      span {
        font-size: 14px;
        line-height: 16px;
        top: 11px;
      }
      .checkbox-input {
        label {
          width: 20px;
          height: 20px;
        }
      }
      #tooltip-target-1 {
        left: -12px;
      }
    }
    .estimate-value {
      .limit {
        flex-direction: unset;
        font-size: 14px;
        line-height: 16px;

        .in-use {
          margin-left: 12px;
        }
      }
    }
  }
  .tool-tip-custom {
    min-width: 100%;
  }
  .content-table {
    // position: relative;
    width: 100%;
    z-index: 5;
    border: 0px;
    border-top: none;
    overflow-y: auto;
    overflow-x: auto;
    &::-webkit-scrollbar-thumb {
      background-color: $color-gray-alto;
      border-radius: 6px;
      border: 6px solid $color-gray-alto;
    }
  }
  .table-container {
    min-width: 1440px;
  }
  #balance.page_container .balance-container .table-container tr td {
    font-size: 14px;
    padding: 20px 4px;
  }
  .icon-coin {
    width: 24px;
    height: 24px;
  }
}
@media screen and (max-width: 782px) {
  .es-toolbar {
    display: block;
    .btn-small-icon {
      width: 100%;
      span {
        width: 90%;
        text-align: left;
      }
      #tooltip-target-1 {
        left: 30px;
      }
    }
    .estimate-value {
      margin-bottom: 12px;
    }
  }
  .tool-tip-custom {
    height: 38px;
  }
  #balance {
    &.page_container {
      .balance-container {
        .table-container {
          th {
            &.name_blances {
              width: 160px;
            }
          }
        }
      }
    }
    .group_transfer_form {
      label {
        &.txt {
          font-size: 14px;
          line-height: 16px;
        }
      }
      input {
        &.input-form {
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
    .select-control {
      font-size: 14px;
      line-height: 16px;
    }
  }
}
@media screen and (max-width: 590px) {
  .es-toolbar {
    .estimate-value {
      .limit {
        flex-direction: column;
        .in-use {
          margin-left: 0px;
        }
      }
    }
  }
}
</style>
