<template>
  <div id="trade-history" :class="{ no_padding: options && options.isNoPadding }">
    <div class="group-control" v-if="!options">
      <date-tab @change-select-date="changeDate" ref="selectDateTab"/>
      <div class="group-date">
          <span class="date-title">From</span>
          <datepicker :value="dateFromDefault" class="date-from" @selected="selectDateFrom" :format="customFormatter" :language="lang"></datepicker>
          <datepicker :value="dateToDefault" class="date-to" @selected="selectDateTo" :format="customFormatter" :language="lang"></datepicker>
      </div>
      <button class="button-search" @click="actionSearch">Search</button>
    </div>
    <div class="group-control" v-if="options" :class="{ is_single_page: options && options.isNoPadding }">
      <div class="group-date-picker">
        <div class="label-text">{{ $t('order.order_history.date') }} :</div>
        <div class="group-date">
            <datepicker
            :value="dateFromDefault"
            class="date-from" @selected="selectDateFrom" :format="customFormatter" input-class="date-picker-input"
            :language="lang"></datepicker>
            <datepicker
            :value="dateToDefault"
            class="date-to" @selected="selectDateTo" :format="customFormatter" input-class="date-picker-input"
            :language="lang"></datepicker>
        </div>
      </div>

      <div class="group-pair">
        <div class="label-text">{{ $t('order.order_history.pair') }} :</div>
        <div class="content-search">
          <div class="toolbar-element">
            <div class="coin-input" v-click-outside="clickOut">
              <input type="text" :placeholder="$t('order.order_history.coin')"
                   v-model="filters.coin" @click="showCoinOption()" @keyup="onSearchCoin()" @keyup.enter="search"/>
             <div class="box-coin-option" v-if="isShowCoinOption">
                <div class="coin-option">
                  <span class="option" v-for="coin in coins" @click="onSelectCoin(coin)">{{ coin | uppercase }}</span>
                </div>
              </div>
            </div>
          </div>
          <span class="bw-element">/</span>
          <div class="toolbar-element2" >
            <select-box
              v-model="filters.currency"
              :placeholder="$t('order.order_history.all')"
              :options="getMarkets()"
            />
          </div>
        </div>
      </div>

      <div class="group-type">
        <div class="label-text">{{ $t('order.order_history.side') }} :</div>
        <div class="content-search">
          <select-box
            v-model="filters.tradeType"
            :placeholder="$t('order.order_history.all')"
            :options="commonConst.SELECT_SIDE"
          />
          <button class="button-search" @click="actionSearch">Search</button>
          <button class="button-reset" @click="actionReset">Reset</button>
        </div>
      </div>

      <div class="export-file">
        <a class="excel_export f-fr mart3" @click="downloadReport()" :title="$t('order.order_history.export_complete_trade_history_tooltip')" @mouseover="mouseOver" @mouseout="mouseOver">          
          <span class="icon-folder1"/>&nbsp;
          {{ $t('order.order_history.export_complete_trade_history') }}
        </a>
        <div v-show="isShowToolTip" class="export-file-tooltip">{{ $t('order.order_history.export_complete_trade_history_tooltip') }}</div>
      </div>
    </div>
    <data-table ref="datatable" :getData="getData" :msg-empty-data="$t('order.trade_history.no_data')" class="" id="order-table">
      <th v-for="item in headerTable" :title="$t(item.name)" :data-sort-field="item.id" @click="FixSortIE = !FixSortIE">
        {{ $t(item.name) }}
        <span v-if="FixSortIE === true" class="iconFixSorIE icon-arrow5"></span>
        <span v-if="FixSortIE === false" class="iconFixSorIE icon-arrow6"></span>
      </th>
      <template slot="body" slot-scope="props">
        <tr>
          <td class="cl_date" :title="props.item.created_at | timestampToDate">{{ props.item.created_at | timestampToDate }}</td>
          <td :title="`${props.item.coin} / ${props.item.currency}` | uppercase">{{ props.item.coin | uppercase }}/{{ props.item.currency | uppercase }}</td>
          <td :class="{'blue' : props.item.trade_type === 'buy', 'red' : props.item.trade_type === 'sell'}" :title="props.item.trade_type | tradeType">
            {{ props.item.trade_type | tradeType }}
          </td>
          <td :title="props.item.price | formatCurrencyAmount(props.item.currency, '0')">
            <span v-if="props.item.type == 'market' || props.item.type == 'stop_market'">{{ $t('order.trade_history.market') }}</span>
            <span v-else>{{ props.item.price | formatCurrencyAmount(props.item.currency, '0')  | shortText(11)}}</span>
          </td>
          <td :title="props.item.quantity | toNumber">{{ props.item.quantity | toNumber  | shortText(11)}}</td>
          <td width="190px" class="t-left" v-if="props.item.trade_type === 'buy'" :title="props.item.fee | toNumber | formatCurrencyAmount(props.item.coin)">
            {{ props.item.fee | toNumber | formatCurrencyAmount(props.item.coin)  | shortText(11)}}
          </td>
          <td width="190px" class="t-left" v-if="props.item.trade_type !== 'buy'" :title="props.item.fee | formatCurrencyAmount(props.item.currency)">
            {{ props.item.fee | formatCurrencyAmount(props.item.currency)  | shortText(11)}}
          </td>
          <td :title="props.item.amount | formatCurrencyAmount(props.item.coin)">{{ props.item.amount | formatCurrencyAmount(props.item.coin) | shortText(11) }}</td>
        </tr>
      </template>
    </data-table>
  </div>
</template>

<script>
  import Datepicker from 'vuejs-datepicker';
  import DateTab from '../common/DateTab.vue';
  import OPEN_ORDER_HEADER_CONST from './DataTableInit.js';
  import COMMON_CONST from '@/common/Const';
  import rf from '@/request/RequestFactory'
  import moment from 'moment';
  import MasterdataUtils from '@/common/MasterdataUtils';
  import Utils from '@/common/Utils';
  import SelectBox from '@/components/shared_components/common/SelectBox';

  export default {
    components: {
      DateTab,
      Datepicker,
      SelectBox
    },
    props: {
      options: {
        type: Object,
        default: null
      },
      coin: {
        type: String,
        default: COMMON_CONST.DEFAULT_COIN
      },
      currency: {
        type: String,
        default: COMMON_CONST.DEFAULT_CURRENCY
      }
    },
    data() {
      return {
        headerTable: OPEN_ORDER_HEADER_CONST.TRADE_HISTORY_HEADER,
        commonConst: COMMON_CONST,
        dateFromDefault: moment().subtract(6, 'days').toDate(),
        dateToDefault: moment().toDate(),
        dateFrom: moment().subtract(6, 'days').startOf('day').format('x'),
        dateTo: moment().endOf('day').format('x'),
        coins: [],
        coinMasters: [],
        isShowCoinOption: false,
        filters: {
          currency: '',
          tradeType: ''
        },
        durationDateFrom: null,
        isHide: false,
        isShowToolTip: false,
        FixSortIE: null,
        lang: Cookies.get('locale', { domain: process.env.VUE_APP_PUBLIC_URL }) || 'en',
      }
    },
    methods: {
      changeDate (currentDateTab) {
        switch(currentDateTab.id) {
          case this.commonConst.ONE_DAY:
              this.durationDateFrom = moment().startOf('day').format('x');
              break;
          case this.commonConst.ONE_WEEK:
              this.durationDateFrom = moment().subtract(6, 'days').startOf('day').format('x');
              break;
          case this.commonConst.ONE_MONTH:
              this.durationDateFrom = moment().subtract(1, 'months').add('days', 1).startOf('day').format('x');
              break;
          default:
              this.durationDateFrom = moment().subtract(3, 'months').add('days', 1).startOf('day').format('x');
        }
        const params = this.getDefaultDateRange();
        this.$refs.datatable.filter(params);
      },
      getData (params) {
        if (!isAuthenticated) {
          return new Promise((resolve) => {
            return resolve({});
          });;
        }
        const dateRange = this.getDefaultDateRange();
        params = Object.assign({}, params, dateRange);
        return rf.getRequest('OrderRequest').getTradeHistory(params);
      },

      getDefaultDateRange() {
        return {
          start_date: this.dateFrom,
          end_date: moment().endOf('day').format('x')
        };
      },

      actionSearch () {
        if(this.dateFrom > this.dateTo) {
          ConfirmationModal.show({
            type: 'warning',
            title: '',
            content: window.i18n.t('common.date.warning'),
            btnCancelLabel: '',
            btnConfirmLabel: '',
          })
        }
        this.$refs.datatable.filter(this.filterParams());
        if (!this.options) {
          this.$refs.selectDateTab.resetSelectDate();
        }
      },
      filterParams () {
        const filterParams = {
          start_date: this.dateFrom,
          end_date: this.dateTo
        };
        if (this.filters.coin) {
          filterParams.coin = this.filters.coin;
        }
        if (this.filters.currency) {
          filterParams.currency = this.filters.currency;
        }
        if (this.filters.tradeType) {
          filterParams.trade_type = this.filters.tradeType;
        }
        return filterParams;
      },
      actionReset () {
        this.filters.coin = '';
        this.filters.currency = '';
        this.filters.tradeType = '';
        this.dateFromDefault = moment().subtract(6, 'days').toDate();
        this.dateToDefault = moment().toDate();
        this.dateFrom = moment().subtract(6, 'days').startOf('day').format('x');
        this.dateTo = moment().endOf('day').format('x');
        this,$refs.datatable.refresh();
      },
      selectDateFrom (dateFrom) {
        this.dateFrom = moment(dateFrom).valueOf();
        this.dateFrom = moment(dateFrom).startOf('day').format('x');
      },
      selectDateTo (dateTo) {
        this.dateTo = moment(dateTo).endOf('day').format('x');
      },
      customFormatter(date) {
        return moment(date).format('DD/MM/YYYY');
      },
      requestCoinList() {
        MasterdataUtils.getCoins((coins) => {
          this.coinMasters = coins;
        });
      },
      onSelectCoin(coin) {
        this.isShowCoinOption = false;
        this.filters.coin = coin.toUpperCase();
      },
      clickOut() {
        this.isShowCoinOption = false;
      },
      showCoinOption() {
        this.isShowCoinOption = true;
        this.coins = this.coinMasters;
      },
      async downloadReport() {
        const params = {
          start_date        : this.dateFrom,
          end_date          : this.dateTo,
          timezone_offset   : Utils.getTimzoneOffset()
        };
        rf.getRequest('OrderRequest')
          .downloadTradeHistory(params, 'TradeHistory.csv');
      },
      mouseOver () {
        this.isShowToolTip = !this.isShowToolTip;
      },

      getMarkets() {
        return window._.map(COMMON_CONST.MARKETS, item => {
          return { id: item, name: item.toUpperCase() };
        });
      }
    },
    mounted() {
      this.requestCoinList();
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/sass/variables";

  #trade-history {
    .group-control{
      padding: 9px 20px 9px 20px;
      font-size: $font-small;
      &:after{
        display: block;
        clear: both;
        content: "";
      }
      #date-tab{
        float: left;
      }
      .group-date{
        display: inline-block;
        margin-left: 36px;
        position: relative;
        float: left;
        .date-title{
          margin-right: 5px;
        }
        .vdp-datepicker{
          display: inline-block;
        }
        .date-to{
          margin-left: -5px;
        }
      }
      .button-search{
        margin-left: 5px;
        margin-right: 10px;
        background-color: $color-corn-pale;
        color: $color-white;
        box-shadow: none;
        border: 1px solid $color-corn-pale;
        border-radius: 15px;
        width: 70px !important;
        height: 23px;
        font-size: $font-root;
        text-transform: uppercase;
        float: left;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        letter-spacing: 0.5px;
        &:active, &forcus , &:hover{
          background: $color-corn;
          border-color: $color-white;
          -webkit-transition: 0.5s;
          transition: 0.5s;
        }
      }
      &.is_single_page{
        display: block;
        width: 100%;
        padding: 0 0 20px 0;
        position: relative;
        background: $color-white-smoke;
        &:after{
          display: block;
          clear: both;
          content: "";
        }
        .label-text{
          color: $color-grey-dusty;
          font-size: $font-small;
          line-height: 25px;
        }
        .button-search{
          margin-left: 25px;
          margin-right: 10px;
          background-color: $color-jungle-green;
          color: $color-white;
          box-shadow: none;
          font-family: $font-family-roboto-bold;
          border: 1px solid $color-jungle-green;
          border-radius: 3px;
          width: 85px;
          height: 32px;
          font-size: $font-smaller;
          float: left;
          -webkit-transition: 0.5s;
          transition: 0.5s;
            letter-spacing: 0.9px;
          text-transform: uppercase;
          &:hover, &:active, &:focus{
            background: $color-eden;
            border-color: $color-eden;
            color: $color-white;
            -webkit-transition: 0.5s;
            transition: 0.5s;
          }
        }
        .group-date-picker{
          display: inline-block;
          float: left;
          margin-right: 20px;
          .group-date{
            display: inline-block;
            margin-left: 0;
            position: relative;
            .vdp-datepicker{
              display: inline-block;
            }
            .date-to{
              margin-left: 0;
            }
          }
        }
        .group-pair{
          display: inline-block;
          margin-right: 20px;
          float: left;
          input{
            background-color: transparent;
            height: 30px;
            width: 80px !important;
            float: left;
            border: 0px;
            padding: 0px 5px 0px 14px;
            margin-right: 1px;
            font-size: $font-root;
            color: $color-grey-dusty;
          }
          .sc_search_select{
            background-color: $color-catskill-white;
            height: 30px;
            width: 80px;
          }
          .bw-element {
            float: left;
            font-size: 15px;
            margin-left: 5px;
            height: 30px;
            line-height: 30px;
            margin-right: 5px;
            color: $color-grey-dusty;
          }
        }
        .group-type{
          display: inline-block;
          margin-right: 20px;
          float: left;
          .sc_search_select{
            height: 30px;
            width: 80px;
            display: inline-block;
            float: left;
          }

          button{
            line-height: 20px;
            height: 32px;
            padding: 6px 16px;
            width: auto;
            min-width: 85px;
          }
        }
        .export-file{
          display: inline-block;
          float: right;
          margin-top: 35px;
          line-height: 20px;
          font-size: $font-small;
          font-weight: 500;
          position: relative;
          font-family: $font-family-roboto;
          a {
            color: $color-blue-custom;
            font-size: $font-small;
            .icon-export{
              line-height: 20px;
              font-size: $font-root;
              float: right;
              margin-left: 5px;
            }
          }
          .export-file-tooltip {
            display: none;
            position: absolute;
            bottom: 100%;
            margin-bottom: 5px;
            right: 0;
            width: 220px;
            line-height: 20px;
            background-color: $color-white;
            padding: 5px 10px;
            font-size: $font-smaller;
            font-weight: normal;
          }
          &:hover {
            .export-file-tooltip {
              display: block;
            }
          }
        }
        .button-reset{
          margin-left: 0px;
          margin-right: 0px;
          background-color: transparent;
          color: $color-jungle-green-light;
          box-shadow: none;
          font-family: $font-family-roboto-bold;
          border: 1px solid $color-jungle-green-light;
          border-radius: 3px;
          width: 85px;
          height: 32px;
          font-size: $font-smaller;
          float: left;
          -webkit-transition: 0.5s;
          transition: 0.5s;
            letter-spacing: 0.9px;
          text-transform: uppercase;
          &:hover, &:active, &:focus{
            background: $color-eden;
            border-color: $color-eden;
            color: $color-white;
            -webkit-transition: 0.5s;
            transition: 0.5s;
          }
        }
        .toolbar-element{
          border: 1px solid $color-alto;
          background-color: $color-alabaster;
          margin-right: 0px;
          display: inline-block;
          border-radius: 3px;
          float: left;
        }
        .toolbar-element2{
          background-color: $color-catskill-white;
          margin-right: 0px;
          display: inline-block;
          float: left;
        }
        .coin-input {
          position: relative;
          float: left;
          .box-coin-option {
            left: 0;
            position: absolute;
            top: 100%;
            width: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -ms-flex-negative: 1;
            flex-shrink: 1;
            margin-top: 6px;
            z-index: 999;
            background-color: $color-white;
            -webkit-box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);
            box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);
            &:after {
              bottom: 100%;
              left: 50%;
              border: solid transparent;
              content: " ";
              height: 0;
              width: 0;
              position: absolute;
              pointer-events: none;
              border-color: rgba(136, 183, 213, 0);
              border-bottom-color: $color-white;
              border-width: 5px;
              margin-left: -5px;
            }
            .coin-option {
              position: relative;
              padding: 5px 0px;
              max-height: 135px;
              overflow-y: auto;
              .option {
                display: block;
                position: relative;
                display: inline-block;
                white-space: nowrap;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                width: 100%;
                padding: 0 20px;
                margin: 0;
                line-height: 25px;
                height: 25px;
                font-size: $font-root;
                color: $color-grey-dark;
                font-weight: normal;
                min-width: 100%;
                cursor: pointer;
                &:hover {
                  background-color: $color-jungle-green;
                  color: $color-white;
                }
              }
            }

          }
        }
        .excel_export {
          cursor: pointer;
          font-weight: 500;
        }
      }
    }
    #order-table {
      padding-bottom: 20px;
      table {
        border: 0px;
        thead {
          background-color: $color-white;
          border: 0px;
          tr {
            th {
              font-family: $font-family-roboto-medium;
              font-size: $font-small;
              //padding: 8px 0px 8px 20px;
              &:last-child {
                padding: 8px 20px 8px 0px;
              }
            }
          }
        } 
        tbody {
          text-align: left;
          tr {
            &.canceled {
              opacity: 0.3
            }
            height: auto;
            font-size: $font-small;
            td {
              font-size: $font-root;
              font-weight: 500;
              color: $color-grey-dark;
              font-family: $font-family-roboto-medium;
              line-height: 21px;
              padding: 7px 0px 6px 20px;
              overflow: initial;
              vertical-align: middle;
              &:last-child {
                text-align: left;
                padding: 7px 20px 6px 0px;
              }
            }
          }
        }
      }
    }
  } 

  #date-tab{
    float: left;
  }
</style>


<style lang="scss">
  @import "@/assets/sass/variables";
  #trade-history {
    .is_single_page {
      .sc_search_select {
        .group_search_select {
          .button_drop_search {
            background: transparent;
            padding: 7px 10px 5px 10px;
            width: 100%;
            height: 49px;
            border: 1px solid $color-alto;
            background-color: $color-white-smoke;
            border-radius: 3px;
          }
          .box_list_search_select {
            ul {
              width: 80px;
              li {
                &:hover {
                  background-color: $color-jungle-green;
                  span,
                  a {
                    color: $color-white;
                  }
                }
              }
            }
          }
        }
      }

      .vdp-datepicker{
        input{
          padding: 5px 3px 5px 40px !important;
          background-position: top 8px left 14px !important;
          background-color: $color-white-smoke !important;
        }
      }
    }
  }
</style>
