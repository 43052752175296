<template>
  <div class="content_page_right clearfix" id="leader_board">
    <div class="des_page_leader_board" v-html="$t('des.des_mam_leaderboard')"></div>
    <div class="clearfix clearfix-25"></div>
    <h2 class="title_contracts">{{ $t('title.Leaderboard') }}</h2>
    <div class="clearfix clearfix-20"></div>
    
    <ul class="table_rank_leader clearfix">
      <li class="item" :class="{active: onTabRank === 'notional'}" @click="onTabRank = 'notional'">{{ $t('leaderboard.top_25_traders_by_notional') }}</li>
      <li class="item" :class="{active: onTabRank === 'roe'}" @click="onTabRank = 'roe'">{{ $t('leaderboard.top_25_traders_by_roe') }}</li>
    </ul>
    <div class="clearfix"></div>
    <div class="box_table_contracts">
      <data-table3 class="datatable_contracts" ref="datatable" :limit="25" :getData="getData">
        <template slot-scope="props">
          <th class="w_15">
            {{ $t('table.rank') }}
          </th>
          <th class="w_15">
            {{ $t('mam.fund_id') }}
          </th>
          <th class="w_35">
            {{ $t('withdraw_setting.name') }}
          </th>
          <th class="w_20">
            {{ $t('table.profit') }}
          </th>
          <th class="w_20 text-right">
            {{ $t('table.is_real_name') }}
          </th>
        </template>
        <template slot="body" slot-scope="props">
          <tr>
            <td>{{ props.index + 1 }}</td>
            <td>{{ props.item.fund_id }}</td>
            <td>
              <div class="item_name_setting font14">
                <span class="txt_email_setting font14">{{ props.item.name }}</span>
                <span class="tooltip_name_setting font14">{{ props.item.name }}</span>
              </div>
            </td>
            <td v-if="onTabRank === 'notional'">{{ props.item.notional | formatMarginNumber(8, '0') }} BTC</td>
            <td v-else>{{ props.item.roe * 100 | formatMarginNumber(2, '0') }} %</td>
            <td>
              <div class="fl-right">
                <span class="btn-close-rank" v-if="props.item.use_fake_name === 1"><i class="icon icon-close"></i></span>
                <span class="btn-save-rank" v-else><i class="icon icon-save"></i></span>
              </div>
            </td>
          </tr>
        </template>
      </data-table3>
    </div>
    <div class="clearfix clearfix-50"></div>
  </div>
</template>

<script>
  import rf from '@/request/RequestFactory'
  import TopRoe from './MamLeaderBoardTopROE';
  export default {
    data() {
      return {
        onTabRank: 'notional',
      }
    },
    watch: {
      onTabRank(value) {
        this.$refs.datatable.refresh();
      }
    },
    methods: {
      async getData (params) {
        const res = await rf.getRequest('MarginRequest').exportTopLeader({ is_mam: 1 });
        return this.onTabRank === 'notional' ? res.data.notional : res.data.roe;
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/sass/variables";
  @import "@/assets/sass/common.scss"; 
  .clearfix-20 {
    clear: both;
    width: 100%;
    display: block;
    height: 20px;
  }
  .item_name_setting {
    display: inline-block;
    float: left;
    position: relative;
    .txt_email_setting {
      display: block;
      max-width: 400px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    &:hover {
      .tooltip_name_setting {
        transition: 0.5s;
        display: inline-block;
      }
    }
    .tooltip_name_setting{
      position: absolute;
      top: 0px;
      line-height: 20px;
      padding: 5px 20px;
      left: 100%;
      background-color: $color_white;
      word-break: break-all;
      white-space: pre-wrap;
      width: 300px;
      z-index: 10;
      font-size: $font_root;
      font-weight: 500;
      transition: 0.5s;
      display: none;
      box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);
      &:after {
        right: 100%;
        top: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(136, 183, 213, 0);
        border-right-color: $color_white;
        border-width: 5px;
        margin-top: -5px;
      }
    }
  }
  .clearfix-25 {
    clear: both;
    width: 100%;
    display: block;
    height: 25px;
  }
  .clearfix-50 {
    clear: both;
    width: 100%;
    display: block;
    height: 50px;
  }
  .w_130px {
    width: 130px
  }
  .w_35 {
    width: 35%;
  }
  .w_25 {
    width: 25%;
  }
  .w_20 {
    width: 20%;
  }
  .box_table_contracts {
    background-color: $color-white;
  }
  .content_page_right {
    .des_page_leader_board {
      border-radius: 1px;
      background-color: $color-bright-gray;
      line-height: 20px;
      padding: 10px 15px;
      font-size: $font-smaller;
      color: $color-eden;
    }
    .title_contracts {
      font-size: $font-title-size-small-25;
      font-family: $font-family-roboto-medium;
      display: block;
      color: $color-jungle-green;
      margin: 0px;
      // margin-bottom: 15px;
      line-height: 29px;
    }
    .des_title_contracts {
      font-size: $font-root;
      color: $color-grey-dusty;
      margin-bottom: 12px;
      line-height: 20px;
    }
    .group_search_save {
      display: block;
      float: none;
      width: 100%;
      .form_search_contracts {
        display: block;
        width: 590px;
        float: left;
        max-width: 80%;
        .input-search-contracts {
          display: block;
          float: left;
          width: calc( 100% - 190px);
          border-radius: 3px;
          border: solid 1px $color-alto;
          height: 32px;
          line-height: 20px;
          padding: 6px 15px;
          font-size: $font-small;
          color: $color-grey-dark;
          &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $color-grey-sliver;
            opacity: 1; /* Firefox */
          }

          &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: $color-grey-sliver;
          }

          &::-ms-input-placeholder { /* Microsoft Edge */
            color: $color-grey-sliver;
          }
        }
        .btn-search-contracts {
          display: block;
          float: left;
          width: 85px;
          height: 32px;
          margin-left: 10px;
          border-radius: 3px;
          background-color: $color-jungle-green;
          letter-spacing: 0.9px;
          color: $color-white;
          text-transform: uppercase;
          font-family: $font-family-roboto-bold;
          font-size: $font-smaller;
          &:hover, &:active {
            background-color: $color-blue-custom;
            color: $color-white;
          }
        }
        .btn-clear-search-contracts {
          display: block;
          float: left;
          width: 85px;
          height: 32px;
          margin-left: 10px;
          border-radius: 3px;
          background-color: $color-jungle-green-light;
          letter-spacing: 0.9px;
          color: $color-white;
          text-transform: uppercase;
          font-family: $font-family-roboto-bold;
          font-size: $font-smaller;
          &:hover, &:active {
            background-color: $color-dark-green;
            color: $color-white;
          }
        }
      }
      .save_contracts {
        display: inline-block;
        float: right;
        line-height: 20px;
        padding: 6px 0px;
        color: $color-blue-custom;
        font-size: $font-small;
        cursor: pointer;
        .icon {
          display: inline-block;
          float: right;
          line-height: 20px;
          margin-left: 6px;
        }
      }
    }
    .datatable_contracts {
      .last-column{
        text-align: right;
      }
      th {
        font-size: $font-small;
        color: $color-grey;
        font-family: $font-family-roboto-medium;
        line-height: 20px;
        height: 48px;
        background-color: $color-white;
      }
      td {
        overflow: initial;
        text-align: left;
        min-height: 38px;
        background-color: $color-white;
        font-size: $font-root;
        color: $color-grey-dark;
        font-family: $font-family-roboto-medium;
      }
    }
    .table_rank_leader {
      display: block;
      width: 100%;
      margin: 0 0 0 0;
      .item {
        display: inline-block;
        float: left;
        line-height: 20px;
        padding: 9px 28px 8px 28px;
        font-size: 16px;
        font-size: 16px;
        font-family: $font-family-roboto-medium;
        color: $color-grey-dark;
        cursor: pointer;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        &.active {
          color: $color-jungle-green;
          background-color: $color-white;
        }
      }
    }
    .btn-close-rank {
      width: 20px;
      height: 20px;
      padding: 2px;
      line-height: 18px;
      font-size: $font-root;
      text-align: center;
      color: $color-jungle-green;
      background-color: transparent;
    }
    .btn-save-rank {
      width: 20px;
      height: 20px;
      padding: 2px;
      line-height: 18px;
      font-size: $font-medium-bigger;
      text-align: center;
      color: $color-jungle-green;
      background-color: transparent;
      .icon-save:before {
        color: $color-jungle-green;
      }
    }
  }
</style>