<template>
  <div class="amount-box input-group">
    <span class="amount-input">
      <currency-input
        @focus="$emit('focus')"
        :precision="2"
        v-model="amount"
        :class="{'error': errors.has('amount')}"
        class="form-input txt-right"
        id="amount"
      />
      <span class="max-amount">
        {{ $t('funds.withdrawals.withdrawal_placeholder_available') }}
        <span class="available-USD">
          {{ calcAmountAvailable() | formatCurrencyAmount(coin, '0') }}
        </span>
        <span
          class="color-grey-dark"
        >{{ coin | uppercase }}
        </span>
        <span class="set-max" @click="setMaxAmount()">{{ $t('common.max_only') }}</span>
      </span>
      <span class="invalid-feedback" v-if="errors.has('amount')">{{ errors.first('amount') }}</span>
    </span>
  
    <!-- <span class="max-amount" @click="setMaxAmount()">{{ $t('common.max') }}</span> -->
  </div>
</template>

<script>
import BigNumber from "bignumber.js";

export default {
  inject: ["$validator"],
  data() {
    return {
      amount: ""
    };
  },
  props: {
    value: { required: true },
    coin: { type: String, required: true },
    withdrawLimit: { type: Object, required: true },
    balance: { type: Object, required: true },
    withdrawDaily: { type: String, default: "0" }
  },
  computed: {
    maxWithdrawAvailable: function() {
      const limit = `${this.withdrawLimit.limit || 0}`;
      const fee = `${this.withdrawLimit.fee || 0}`;
      const dailyLimit = `${this.withdrawLimit.daily_limit || 0}`;
      const withdrawDaily = `${this.withdrawDaily || 0}`;
      const availableBalance = this.balance.available_balance || 0;
      const withdrawDailyLimit = BigNumber.max(
        new BigNumber(dailyLimit).minus(withdrawDaily),
        "0"
      );

      let withdrawDailyAvailable = new BigNumber("0");
      if (
        new BigNumber(availableBalance).comparedTo(
          BigNumber.min(withdrawDailyLimit, limit).add(fee)
        ) === -1
      ) {
        withdrawDailyAvailable = new BigNumber(availableBalance).minus(fee);
      } else {
        withdrawDailyAvailable = BigNumber.min(withdrawDailyLimit, limit);
      }
      return BigNumber.max(withdrawDailyAvailable, "0").toString();
    },

    externalAmount() {
      return new BigNumber(`${this.amount || 0}`)
        .sub(`${this.withdrawLimit.fee || "0"}`)
        .toString();
    }
  },

  watch: {
    value(val) {
      const externalAmount = new BigNumber(`${this.externalAmount || 0}`);
      if (externalAmount.comparedTo(`${val || 0}`) === 0) {
        return;
      }
      this.amount = val;
    },

    amount: function(newVal) {
      if (window._.isEmpty(newVal)) {
        return this.emitInput("");
      }
      const newAmount = new BigNumber(`${newVal || 0}`);
      if (newAmount.comparedTo("0") < 1) {
        return this.emitInput("0");
      }
      this.emitInput(this.externalAmount);
    },

    coin: function() {
      this.amount = "";
    }
  },

  methods: {
    emitInput(newVal) {
      if (newVal !== this.value) {
        this.$emit("input", newVal);
      }
    },

    calcAmountAvailable() {
      const amount = new BigNumber(`${this.maxWithdrawAvailable || 0}`).add(
        `${this.withdrawLimit.fee || 0}`
      );
      if (amount.comparedTo(this.withdrawLimit.fee || 0) === 0) {
        return "0";
      }
      return amount.toString();
    },

    validate() {
      if (window._.isEmpty(this.amount)) {
        this.errors.add(
          "amount",
          window.i18n.t("funds.withdrawals.errors.required", {
            field: this.$t('funds.withdraw_usd.amount')
          })
        );
      }
      const amount = `${this.amount || 0}`;
      const minAmount = Number(this.withdrawLimit.minium_withdrawal) + Number(this.withdrawLimit.fee);
      if (new BigNumber(amount).comparedTo(minAmount) < 0) {
        this.errors.add(
          "amount",
          window.i18n.t("funds.withdrawals.errors.min_amount", {
            min_amount: minAmount
          })
        );
      }
      if (
        new BigNumber(amount).comparedTo(
          `${this.balance.available_balance || 0}`
        ) > 0
      ) {
        this.errors.add(
          "amount",
          window.i18n.t("funds.withdrawals.errors.max_balance")
        );
      }
      if (
        new BigNumber(amount).comparedTo(`${this.calcAmountAvailable() || 0}`) >
        0
      ) {
        this.errors.add(
          "amount",
          window.i18n.t("funds.withdrawals.errors.max_once_amount")
        );
      }
    },

    setMaxAmount() {
      this.amount = this.calcAmountAvailable();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.f-left {
  float: left;
}

.form-input {
  background: $color-bright-gray;
  &:focus{
    border: 1px solid $color-jungle-green !important;
  }
}

.amount-box {
  width: 100%;
  height: 42px;
  font-family: $font-inter-regular;
  .amount-input {
    width:100%;
    position: relative;
    float: left;
    height: 100%;
    font-size: $font-root;
    input {
      height: 100%;
      padding-right: 120px;
      height: 45px;
      border-radius: 8px;
      background: var(--bg-input);
      border: solid 1px var(--color-border-input-wdusdt);
      padding-left: 12px;
      font-weight: 400;
      font-size: $font-big;
      color: var(--text-primary-color);
    }
    .error {
       border: 1px solid $text-color-red;
    }
    .max-amount {
      position: absolute;
      right: 10px;
      z-index: 9;
      top: 50%;
      transform: translateY(-50%);
      color: var(--text-close);
      cursor: pointer;

    }
  }
  .set-max {
    display: inline-block;
    margin-left: 9px;
    padding-top:6px;
    font-family: Roboto-Bold;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 29px;
    letter-spacing: 0.92px;
    text-align: center;
    position: relative;
    color: $color-caribbean-green;
    cursor: pointer;
    text-transform: uppercase;
    &:hover {
      color: $color-aquamarine;
    }
    &:disabled {
      color: $color-aero-blue;
    }
  }
  .color-grey-dark {
    color: $color-grey-dark;
    border-right: 1px solid $color-gray-alto;
  }
  .available-USD {
    font-family: $font-inter-bold;
    font-weight: 500;
    color: var(--text-primary-color);
  }
  span.color-grey-dark {
    font-size: 14px;
    font-family: $font-inter-bold;
    color: var(--text-primary-color);
  }
}
</style>
