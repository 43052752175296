import { render, staticRenderFns } from "./License.vue?vue&type=template&id=57b83faf&scoped=true"
var script = {}
import style0 from "./License.vue?vue&type=style&index=0&id=57b83faf&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57b83faf",
  null
  
)

export default component.exports