<template>
  <div id="account_top_header">
    <span class="name_title">{{ title }}</span>
    <img v-if="icon" :src="icon" alt="" class="logo-header">
    <img v-else class="title-img" src="@/assets/images/wallet/bgr-wallet.png" alt="" />
  </div>
</template>
<script>
export default {
    name: 'PageSettings',
    props: {
      title: '',
      icon: '',
    }
}
</script>
<style lang="scss" scoped>
  #account_top_header {
    padding-left: 40px;
    display: flex;
    align-items: center;
    background-repeat: repeat-y;
    background-attachment: fixed;
    background-position: right;
    justify-content: space-between;
    background: var(--bgr-order);
    position: relative;
    .name_title {
      font-family: $font-inter-bold;
      color: $color-white;
      font-size: 34px;
      line-height: 40px;
    }
    img {
      height: 100%;
      margin-right: 120px;
    }
    @media screen and (max-width: 1660px) {
      img {
        margin-right: 0px;
      }
    }
    @media screen and (max-width: 768px) {
      position: relative;
      img {
        position: absolute;
        right: 0px;
        //object-position: 60px;
      }
    }
   
    @media screen and (max-width: 375px) {
      img {
        right: 0;
      }
    }
  }
@media screen and (max-width: 992px) {
  #account_top_header {
    padding: 26px 16px;
    .name_title {
      font-size: 24px;
      line-height: 29px;
    }

    img {
      //object-position: 0;
    }
  }
}

</style>