<template>
  <!-- NEW BALANCE LAYOUT -->
  <div id="balance" class="page_container">
    <join-mam-modal ref='joinMamModalComponent'/>
    <assign-revoke-modal ref='AssignRevokeModal'/>
    <div class="balance-container">
      <div class="box-title title-medium-small" >{{ $t('menu.mam_follower') }}</div>
      <span class="join_funds">
        <button class="btn button-join-funds" @click="showModalJoin"><i class="icon-plus"></i> {{ $t('mam.join') }}</button>
      </span>
      <div class="clearfix clearfix-20"></div>
      <ul class="nav nav-tabs nav_tabs_fund" role="tablist">
        <li class="nav-item" :class="{ 'active': tabs.overview }"
            @click="setActiveTab('overview')">
          <a class="nav-link" role="tab" data-toggle="tab">{{ $t('mam.overview') }}</a>
        </li>
        <li class="nav-item" :class="{ 'active': tabs.curInvest }"
            @click="setActiveTab('curInvest')">
          <a class="nav-link" role="tab" data-toggle="tab">{{ $t('mam.current_investment') }}</a>
        </li>
        <li class="nav-item" :class="{ 'active': tabs.requests }"
            @click="setActiveTab('requests')">
          <a class="nav-link" role="tab" data-toggle="tab">{{ $t('mam.requests') }}</a>
        </li>
        <li class="item-right" v-if="tabs.requests">
          <label class="switch">
            {{$t('mam.show_pending_only') }}
            <input type="checkbox" @click="isShowPendingOnly = !isShowPendingOnly">
            <span>
              <i></i>
            </span>
          </label>
        </li>
      </ul>

      <!-- Tab panes -->
      <div class="tab-content">
        <div v-if="tabs.overview" id="approve">
          <follower-overview/>
        </div>
        <div v-if="tabs.curInvest" id="approve">
          <current-investment/>
        </div>
        <div v-if="tabs.requests">
          <follower-requests/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import rf from '@/request/RequestFactory'
  import JoinMamModal from './common/JoinMamModal.vue';
  import AssignRevokeModal from './common/AssignRevokeModal.vue';
  import FollowerOverview from "./follower/FollowerOverview";
  import CurrentInvestment from "./follower/CurrentInvestment";
  import FollowerRequests from "./follower/FollowerRequests";

  export default {
    components: {
      JoinMamModal,
      AssignRevokeModal,
      FollowerOverview,
      CurrentInvestment,
      FollowerRequests
    },
    data() {
      return {
        fund_id: '',
        tabs: {
          overview: true,
          curInvest: false,
          requests: false,
        },
        isShowPendingOnly: false,
      }
    },
    watch: {
      isShowPendingOnly(value) {
        this.$broadcast('showPendingFollower', value);
      }
    },
    methods: {
      setActiveTab (value) {
        this.tabs = _.map(this.tabs, () => false);
        this.tabs[value] = true;
      },
      showModalJoin() {
        window.CommonModal.show('JoinMamModal');
        this.$refs.joinMamModalComponent.resetData();
        this.$refs.joinMamModalComponent.resetError();
      },
    },
    mounted() {

    }
  }
</script>
<style lang="scss" scoped>
    @import "@/assets/sass/variables";
    @import "@/assets/sass/common.scss";

  .switch{
    margin-bottom: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    input:checked + span{
      background-color: $color-jungle-green;
    }
    span{
      background-color: #d8d8d8;
    }    
  }

  .formInput {
    .checkbox_address {
      line-height: 23px;
      font-size: $font-small;
      font-weight: 400;
      color: $color-grey-dusty;
      margin-top: 12px;
      input{
        display: none;
      }
      span{
        content: "";
        display: inline-block;
        border: 1px solid $color-grey;
        width: 14px;
        height: 14px;
        top: 2px;
        left: 0px;
        border-radius: 0px;
        margin-right: 8px;
        position: relative;
        background-color: transparent;
      }
      input:checked+span:after {
        opacity: .9;
        content: "";
        position: absolute;
        width: 13px;
        height: 7px;
        background: transparent;
        top: 0px;
        left: 0;
        border: 3px solid $color-jungle-green;
        border-top: none;
        border-right: none;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
      }
    }
  }
    .clearfix-20 {
        display: block;
        clear: both;
        height: 20px;
    }

    .w_110px {
        width: 110px;
    }

    .w_140px {
        width: 140px;
    }

    .box-title {
        float: left;
    }

    .button-join-funds {
        float: right;
        width: 90px;
        height: 30px;
        border-radius: 3px;
        background-color: $color-jungle-green;
        border: 1px solid $color-jungle-green;
        text-align: center;
        line-height: 20px;
        padding: 4px;
        letter-spacing: 0.9px;
        color: $color-white;
        font-family: $font-family-roboto-bold;
        text-transform: uppercase;

        .icon-plus {
            font-size: 10px;
            position: relative;
            margin-right: 3px;
            top: -1px;
        }

        &:hover, &:active, &:focus {
            background-color: $color-blue-custom;
            border-color: $color-blue-custom;
        }
    }

    .as_rv_funds {
        color: $color-blue-custom;

        .btn-assign {
            color: $color-blue-custom;

            &:hover {
                text-decoration: underline;
            }
        }

        .btn-revoke {
            color: $color-blue-custom;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .btn-detail {
        width: 70px;
        height: 25px;
        border-radius: 3px;
        border: solid 1px $color-jungle-green;
        color: $color-jungle-green;
        letter-spacing: 0.9px;
        font-size: $font-smaller;
        line-height: 20px;
        padding: 2px;
        overflow: hidden;
        text-transform: uppercase;
        text-align: center;
        background-color: transparent;
        float: right;

        &:hover, &:active, &:focus {
            background-color: $color-blue-custom;
            border-color: $color-blue-custom;
            color: $color-white;
        }
    }

    #balance {
        #order-table {
            background-color: $color-white;
            padding-bottom: 22px;

            table {
                border: 0px;

                thead {
                    background-color: $color-white;
                    border: 0px;

                    tr {
                        height: 48px;

                        th {
                            font-family: $font-family-roboto-medium;
                            font-size: $font-small;
                            padding: 8px 0px 8px 20px;

                            &:last-child {
                                padding: 8px 20px 8px 0px;
                            }

                            &[data-sort-order='asc'] {
                                color: $color-jungle-green;
                            }

                            &[data-sort-order='desc'] {
                                color: $color-jungle-green;
                            }
                        }
                    }
                }

                tbody {
                    text-align: left;

                    tr {
                        &.canceled {
                            opacity: 0.3
                        }

                        height: auto;
                        font-size: $font-small;

                        td {
                            font-size: $font-root;
                            font-weight: 500;
                            color: $color-grey-dark;
                            font-family: $font-family-roboto-medium;
                            line-height: 21px;
                            padding: 7px 0px 6px 20px;
                            overflow: initial;
                            vertical-align: middle;

                            &.blue {
                                color: $color-jungle-green;
                            }

                            &.red {
                                color: $color-denim;
                            }

                            &:last-child {
                                text-align: left;
                                padding: 7px 20px 6px 0px;
                            }
                        }
                    }
                }
            }
        }
    }

    .groupd_pl_funds {
        position: relative;
        display: inline-block;
        float: left;

        .sub_pl {
            position: absolute;
            min-width: 150px;
            border-radius: 1px;
            box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.22);
            background-color: $color-white;
            top: 100%;
            margin-left: -75px;
            left: 50%;
            z-index: 5;
            display: none;
            line-height: 25px;
            font-size: $font-root;
            color: $color-grey-dark;
            padding: 15px 15px 15px 25px;

            .txt_ti {
                font-family: $font-family-roboto;
            }
        }

        &:hover {
            .sub_pl {
                display: block;
            }
        }
    }

    .nav_tabs_fund {
        border: none;

        li {
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;

            a,
            a:hover,
            a:focus {
                border: 0px !important;
            }

            a:hover {
                background-color: transparent;
            }

            &.active {
                background-color: $color-white;

                &:focus,
                &:hover {
                    background-color: $color-white !important;
                }

                a {
                    color: $color-jungle-green;
                }
            }

            a {
                display: inline-block;
                float: left;
                background-color: transparent;
                color: $color-grey-dark;
                font-size: $font-medium-bigger;
                line-height: 20px;
                padding: 15px 20px 12px 20px;
                font-family: $font-family-roboto-medium;
                cursor: pointer;
                text-transform: capitalize;
            }
        }
      .item-right {
        float: right;
      }
    }

    .tab-content {
        background-color: $color-white;
    }
</style>
