<template>
  <div>
   <data-table4 :msgEmptyData="$t('mam.empty.follower')" ref="datatableHistory" :getData="getData" class="" id="assigned-history-table" :limit="10">
      <template>
      <th data-sort-field="user_id">
          {{$t('mam.follower_id')}}
      </th>
      <th data-sort-field="capital_share">
          {{$t('mam.capital_share')}}
      </th>
      <th data-sort-field="user_balance">
          {{$t('mam.balance')}}
      </th>
      <th data-sort-field="user_capital">
          {{$t('mam.high_watermark')}}
      </th>
      <th data-sort-field="current_fee">
          {{$t('mam.performance_fee')}}
      </th>
      <th data-sort-field="unrealised_pnl">
          {{$t('mam.unrelised_pnl')}}
      </th>
      <th data-sort-field="joined_at">
          {{$t('mam.joined_at')}}
      </th>
    </template>
      <template slot="body" slot-scope="props">
        <tr>
          <td>{{ props.item.user_id }}</td>
          <td>{{ props.item.user_capital | formatMarginNumber(8, '0') }} / {{ props.item.capital_share | formatMarginNumber(2, '0') }} %</td>
          <td>{{ props.item.user_balance | formatMarginNumber(8, '0') }} {{ $t('margin.symbol.btc') }}</td>
          <td>{{ props.item.user_capital | formatMarginNumber(8, '0') }} {{ $t('margin.symbol.btc') }}</td>
          <td>{{ (props.item.current_fee > 0 ? props.item.current_fee : 0) | formatMarginNumber(8, '0') }} {{ $t('margin.symbol.btc') }}</td>
          <td>{{ props.item.unrealised_pnl | formatMarginNumber(8, '0') }} {{ $t('margin.symbol.btc') }}</td>
          <td>{{ props.item.joined_at | convertToLocalTime}}</td>
        </tr>
      </template>
    </data-table4>
  </div>
</template>
<script>
  import rf from '@/request/RequestFactory'
  import ConfirmationModal from '@/components/shared_components/common/ConfirmationModal.vue';

  export default {
    components: {
      ConfirmationModal,
    },
    data() {
      return {
        isList: true,
        isHistory: false,
      }
    },
    methods: {
      getData (params) {
        return rf.getRequest('MamRequest').getFollowers(params);
      },
    },
  }
</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables";
@import "@/assets/sass/common.scss";

  .clearfix-20 {
    display: block;
    clear: both;
    height: 20px;
  }

  #assigned-history-table {
    background-color: $color-white;
    // .box_table2 {
    //   margin-bottom: 22px;
    // }
    table {
      border: 0px;
      thead {
        background-color: $color-white;
        border: 0px;
        tr {
          height: 48px;
          th {
            font-family: $font-family-roboto-medium;
            font-size: $font-small;
            padding: 8px 0px 8px 20px;
            height: 48px;
            &:last-child {
              padding: 8px 20px 8px 0px;
            }
            &[data-sort-order='asc'] {
              color: $color-jungle-green;
            }
            &[data-sort-order='desc'] {
              color: $color-jungle-green;
            }
          }
        }
      }
      tbody {
        text-align: left;
        tr {
          &.canceled {
            opacity: 0.3
          }
          height: auto;
          font-size: $font-small;
          td {
            font-size: $font-root;
            font-weight: 500;
            color: $color-grey-dark;
            font-family: $font-family-roboto-medium;
            line-height: 21px;
            padding: 7px 0px 6px 20px;
            overflow: initial;
            vertical-align: middle;
            height: 38px;
            &.blue {
              color: $color-jungle-green;
            }
            &.red {
              color: $color-denim;
            }
            &:last-child {
              text-align: left;
              padding: 7px 20px 6px 0px;
            }
          }
        }
      }
    }
  }
</style>