<template>
  <div>
    <modal :name="modalName" width="620">
      <template slot="body">
        <div slot="body" class="popup_body_cancel_google_auth_form">
          <div class="icon">
            <div class="box-title">
              {{ $t('account.modal.cancel_2fa.title') }}
            </div>
          </div>
          <div class="verify-input">
            <div class="row_form">
              <div class="input-group">
                <div class="name_input">{{ $t('account.otp.txt_pass') }}</div>
                <div class="input_password">
                  <input
                    :type="isShowPassWord ? 'text' : 'password'"
                    @focus="resetErrors"
                    data-vv-validate-on="none"
                    v-validate="'required'"
                    name="password"
                    :placeholder="$t('common.placeholders.login_password')"
                    class="input"
                    v-model="cancelGoogleAuthForm.password"
                    :class="{ error: errors.has('password') }"
                    id="authentication-code"
                  >
                  <img class="icon_password" :src="`/images/${typeIcon}.svg`" alt="icon" @click="toggleShowPassword" />
                </div>
                <div class="invalid-feedback" v-if="errors.has('password')">
                  {{ errors.first('password') }}
                </div>
              </div>
            </div>
            <div class="row_form">
              <div class="input-group" >
                <div class="name_input">{{ $t('common.placeholders.otp_2') }}</div>
                <div @click="resetErrors">
                <input-only-number
                  autocomplete="off"
                  @focus="resetErrors"
                  data-vv-validate-on="none"
                  v-validate="'required|length:6'"
                  :data-vv-as="$t('validation.attributes.otp_recovery_code')"
                  :placeholder="$t('common.placeholders.authen_code')"
                  v-model="cancelGoogleAuthForm.code"
                  class="input"
                  :class="{ error: errors.has('code') }"
                  name="code"
                  maxlength="6">      
                </input-only-number>
                </div>
                <div class="invalid-feedback" v-if="errors.has('code')">
                  {{ errors.first('code') }}
                </div>
              </div>
            </div>
          </div>
          <div class="step-button">
            <button :disabled="isSubmitting" class="" @click="delOtpRecoveryCode()">{{ getSubmitName($t('common.action.confirm')) }}</button>
          </div>
        </div>
      </template>
    </modal>
  </div>
</template>
<script>
  import Modal from '../common/Modal';
  import rf from '@/request/RequestFactory'
  import { mapState } from 'vuex';
  export default {
    components: {
      Modal,
    },
    data() {
      return {
        cancelGoogleAuthForm: {
          code: '',
          password: '',
        },
        isShowPassWord: false,
        modalName:'CancelGoogleAuthModal'
      }
    },
    computed: {
      ...mapState({
        theme: state => state.userThemeMode,
      }),
      typeIcon() {
        if(this.theme === 'light-theme') {
          return this.isShowPassWord ? 'eye-open' : 'eye-close';
        } else {
          return this.isShowPassWord ? 'eyes-open-dark' : 'eyes-close-dark';
        }
      },
    },
    methods: {
      resetErrors() {
        if (this.errors.any()) {
          this.errors.clear();
        }
      },
      async delOtpRecoveryCode() {
        if (this.isSubmitting) {
          return;
        }
        await this.$validator.validateAll();
        if (this.errors.any()) {
          return;
        }
        try {
          this.startSubmit();
          const res = await rf.getRequest('UserRequest').delGoogleAuth(this.cancelGoogleAuthForm);
          this.endSubmit();
          this.$emit('CancelGoogleAuthModal:CLOSE');
          this.showSuccess(this.$t('account.modal.cancel_2fa.success'));
        } catch (error) {
          this.endSubmit();
          this.resetErrors();
          const errorOtp = error.response.data.errors;
            window._.each(errorOtp, (value, key) => {
              this.errors.add(key, this.$t(`${value[0].formatUnicorn({values: ".jpg, .jpeg, .png"})}`));
            })
          // const errorCode = error.response.data.errors?.code;
          // const errorPassword = error.response.data.errors?.password;
          // if(errorCode) {
          //   this.errors.add({
          //     field: 'code',
          //     msg: this.$t('validation.otp_incorrect')
          //   });
          // }
          // if(errorPassword) {
          //   this.errors.add({
          //     field: 'password',
          //     msg: this.$t('validation.custom.password.correct_password')
          //   });
          // }
        }
        
      },
      showSuccess(message) {
        Message.success(
          message,
          {},
          { position: 'bottom_left' }
        );
      },
      clearInput(){
        this.cancelGoogleAuthForm = Object.assign({
          code: '',
          password: '',
        });
      },
      toggleShowPassword() {
        this.isShowPassWord = !this.isShowPassWord
      }
    },
    created(){
      this.$on('hideCommonModal', (modalName) => {
        if (modalName !== this.modalName){
          return;
        }
        this.clearInput();
      });
    }
  }
</script>
<style lang="scss">
  @import "@/assets/sass/variables";
  #CancelGoogleAuthModal {
    .modal-header {
      padding-bottom: 0;
    }
    .modal-body {
      padding: 0 50px 50px;
    }
    .modal-footer {
      display: none;
    }
    .input-group .form-input {
      padding-left: 0;
      border-left: 0;
      border-right: 0;
      border-top: 0;
      &:active, &:focus {
        border-color: $color-green;
      }
    }
  }

  @media screen and (max-width: 992px) {
    #CancelGoogleAuthModal {
      .modal-body {
        padding: 0 16px 30px;
      }
    }
  }
</style>
<style lang="scss" scoped>
  @import "@/assets/sass/variables";

  .popup_body_cancel_google_auth_form {
    .box-title {
      margin-bottom: 40px;
      font-family: $font-inter-bold;
      color: var(--text-primary-color);
      font-size: 34px;
      line-height: 40px;
      text-align: center;
    }
    .icon {
      text-align: center;
      span {
        font-size: $font-title-size-big-small-2;
        color: $color-eden;
      }
      h3 {
        font-family: $font-family-roboto;
        text-align: center;
        color: $color-eden;
      }
    }
    label {
      font-weight: normal;
      color: $color-black;
      margin-bottom: 0px;
    }
    .note_header {
      color: $color-black;
      line-height: 24px;
      margin-bottom: 16px;
    }
    .verify-input {
      .name_input{
        font-family: $font-inter-regular;
        color: var(--text-primary-color);
        font-size: 18px;
        line-height: 22px;
        text-align: left;
        margin-bottom: 8px;
      }
      .input_password {
        position: relative;
        .icon_password {
          position: absolute;
          top: 50%;
          right: 15px;
          transform: translateY(-50%);
        }
      }
      .input {
        background: var(--bgr-hover-row);
        border-radius: 8px;
        padding: 14px 30px 15px 14px;
        border: none;
        width: 100%;
        
        font-size: 18px;
        line-height: 21px;
        font-family: $font-inter-regular;
        color: var(--text-primary-color);
        &::placeholder {
          color: var(--text-close);
        }
        &.error {
          border: 1px solid $text-color-red;
        }
      }
      .invalid-feedback {
        color: $text-color-red;
        font-family: $font-inter-regular;
        font-size: 14px;
        line-height: 17px;
        text-align: left;
        margin-top: 8px;
      }
    }
    .step-button{
      margin: 30px auto 0;
      button{
        @include btn-common(yes);
        width: 100%;
      }
    }
  }

@media screen and (max-width: 992px) {
  .popup_body_cancel_google_auth_form {
    .box-title {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 20px;
    }
    .verify-input {
      .name_input{
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 4px;
      }
      .input {
        font-size: 14px;
        line-height: 16px;
        &::placeholder {
        }
        &.error {
        }
      }
      .invalid-feedback {
      }
    }
    .step-button{
      button{
        
      }
    }
  }
}
</style>