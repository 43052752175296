import Numeral from '../lib/numeral';
import moment from 'moment';
import COMMON_CONST from '@/common/Const';
import BigNumber from 'bignumber.js';

/**
 * Deep copy the given object considering circular structure.
 * This function caches all nested objects and its copies.
 * If it detects circular structure, use cached copy to avoid infinite loop.
 *
 * @param {*} obj
 * @param {Array<Object>} cache
 * @return {*}
 */
export function deepCopy (obj, cache = []) {
  // just return if obj is immutable value
  if (obj === null || typeof obj !== 'object') {
    return obj
  }

  // if obj is hit, it is in circular structure
  const hit = _.find(cache, c => c.original === obj)
  if (hit) {
    return hit.copy
  }

  const copy = Array.isArray(obj) ? [] : {}
  // put the copy into cache at first
  // because we want to refer it in recursive deepCopy
  cache.push({
    original: obj,
    copy
  })

  Object.keys(obj).forEach(key => {
    copy[key] = deepCopy(obj[key], cache)
  })

  return copy
}

export function mapFilters(filters) {
  // console.log('mapFilter', filters, window)
  return filters.reduce((result, filter) => {
      const key = Array.isArray(filter) ? filter[0] : filter
      const value = Array.isArray(filter) ? filter[1] : filter
      result[key] = function(...args) {
          return window.app.$options.filters[value](...args);
      };
      return result;
  }, {});
}

export default {
  getUnit(ref, currentInstrument){
    if(!currentInstrument) {
      console.error('currentInstrument is not defined');
      return 
    }
    const {base_underlying='', quote_currency='', settle_currency=''} = currentInstrument
    if(!['contract_value', 'quantity', 'price'].includes(ref)) {
      console.error('ref is not defined');
      return quote_currency ? quote_currency : (base_underlying ? base_underlying : 'BTC');
    }
    const multiplier = Number(currentInstrument.multiplier)
    const dataEqOne = {
      contract_value: multiplier > 0 ? 1 + ' ' + base_underlying : 1 + ' ' + quote_currency,
      quantity: multiplier > 0 ? base_underlying : quote_currency,
      price: quote_currency
    }
    const dataLtOne = {
      contract_value: multiplier > 0 ? [Math.abs(multiplier), ' ', settle_currency, ' per ', base_underlying].join(" ") : [Math.abs(multiplier), ' ', settle_currency, ' per ', quote_currency].join(" "),
      quantity: window.i18n.t('margin.cont'),
      price: quote_currency
    }
    return Math.abs(multiplier) == 1 ? dataEqOne[ref] : dataLtOne[ref]
  },

  shallowCompare(newObj, prevObj){
    for (key in newObj){
        if(newObj[key] !== prevObj[key]) return true;
    }
    return false;
  },

  qs: function (key) {
    key = key.replace(/[*+?^$.[]{}()|\\\/]/g, "\\$&"); // escape RegEx meta chars
    const match = location.href.match(new RegExp("[?&]"+key+"=([^&]+)(&|$)"));
    return (match && decodeURIComponent(match[1].replace(/\+/g, " ")) || null);
  },

  setI18nLocale(locale) {
    window.i18n.locale = locale;
    window.app.$broadcast('UPDATED_LOCALE', locale);
  },

  toPlainString(num) {
    return (''+ +num).replace(/(-?)(\d*)\.?(\d*)e([+-]\d+)/,
      function(a,b,c,d,e) {
        return e < 0
          ? b + '0.' + Array(1-e-c.length).join(0) + c + d
          : b + c + d + Array(e-d.length+1).join(0);
      });
  },

  formatFee(amount, currency, zeroValue) {
    let num = this.toPlainString(amount);
    let numberOfDecimalDigits = currency && currency.toLowerCase() === 'usd' ? COMMON_CONST.NUMBER_OF_DECIMAL_DIGITS_USD : COMMON_CONST.NUMBER_OF_DECIMAL_DIGITS; //currency === 'usd' ? 2 : 8;
    let value = num?.split('.')[0];
    let decimal = num?.split('.')[1]?.substring(0, numberOfDecimalDigits);

    if (window._.isNil(zeroValue)) {
      zeroValue = '';
    }
    return decimal ? `${value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}.${decimal}` : `${value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
  },
  formatCurrencyAmount(amount, currency, zeroValue) {
    let numberOfDecimalDigits = currency && currency.toLowerCase() === 'usd' ? COMMON_CONST.NUMBER_OF_DECIMAL_DIGITS_USD : COMMON_CONST.NUMBER_OF_DECIMAL_DIGITS; //currency === 'usd' ? 2 : 8;
    const digits = Math.floor(numberOfDecimalDigits)
    let format = digits == 0 ?
      '0,0' :
      '0,0.0[' + Array(digits + 1).join('0') + ']';
    if (window._.isNil(zeroValue)) {
      zeroValue = '';
    }

    return (amount && parseFloat(amount) != 0) ? Numeral(amount).format(format) : zeroValue;
  },

  convertNumberDecimal(value, afterDecimal , maxLength ) {
     value =  this.toFixedSmallNumber(value)
    const factor = Math.pow(maxLength, afterDecimal);
    const numFormat = new BigNumber(value.toString()).toFixed(afterDecimal >= 0 ? afterDecimal : 0, BigNumber.ROUND_DOWN);
    value = new BigNumber(numFormat.toString()).times(factor.toString()).div(factor.toString()).toString()
    if (value?.toString().length > maxLength) {
      value = value.toString().slice(0, maxLength) + '...';
      return value
    }
    return value || 0;
  },

  toFixedSmallNumber(x) {
  if (Math.abs(x) < 1.0) {
    var e = parseInt(x?.toString().split('e-')[1]);
    if (e) {
      x *= Math.pow(10,e-1);
      x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
    }
  } else {
    var e = parseInt(x?.toString().split('+')[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10,e);
      x += (new Array(e+1)).join('0');
    }
  }
  return x;
},

  cutDecimals( num,  afterDecimal, maxLength) {
    let value = parseFloat(num)
    if (value?.toString().length > maxLength) {
       value = value.toString().slice(0, maxLength) + '...';
    }
    const [int, dec] = String(value).split('.');
    const intWithCommas = int.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    if (!dec) {
      return intWithCommas;
    }
    const paddedDec = dec.padEnd(afterDecimal, '0').slice(0, afterDecimal);
    if (/^0+$/.test(paddedDec)) {
      return intWithCommas;
    }
    return `${intWithCommas}.${paddedDec}`;
  },

  cutDecimalForAdmin(value, afterDecimal, maxLength) {
    value = this.toFixedSmallNumber(value);

    if (isNaN(value)) {
      return this.cutDecimals(value, afterDecimal);
    }

    const factor = Math.pow(10, afterDecimal); // Using 10 as base for decimal numbers
    value = new BigNumber(value.toString()).times(factor.toString()).div(factor.toString()).toString();

    const decimalIndex = value.indexOf('.');
    if (decimalIndex !== -1) {
      const integerPart = value.substring(0, decimalIndex);
      const decimalPart = value.substring(decimalIndex + 1, decimalIndex + 1 + afterDecimal);
      value = integerPart + '.' + decimalPart;
    }

    return value || 0;
  },


   numberFormatCutDecimal(num, maxLength, afterDecimal) {
  let numStr = num?.toString(); // Convert number to string

  // Check if numStr is undefined or null
  if (!numStr) {
    return 0;
  }

  const decimalIndex = numStr.indexOf('.');
  if (decimalIndex >= 0) {
    // If the string contains a decimal point, cut off to afterDecimal decimal places
    const decimalPlaces = numStr.substring(decimalIndex + 1);
    if (decimalPlaces.length > afterDecimal) {
      numStr = numStr.substring(0, decimalIndex + (afterDecimal + 1));
    }
  }
  if (numStr.length > maxLength ) {
      const  maxLengthNumberShow = decimalIndex >= 0 ? maxLength + 1: maxLength
      numStr = numStr.substring(0, maxLengthNumberShow ) + '...'; // Cut off and add "..."
  }
  const parts = numStr.split('.');
  parts[0] = parts[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, ','); // Add commas
  return parts.join('.');
},

  formatNumberAfterDecimal(num, afterDecimal) {
    let numStr = num?.toString(); // Convert number to string
    const decimalIndex = numStr.indexOf('.');
    if (decimalIndex >= 0) {
      // If the string contains a decimal point, cut off to 8 decimal places
      const decimalPlaces = numStr.substring(decimalIndex + 1);
      if (decimalPlaces.length > afterDecimal) {
        numStr = numStr.substring(0, decimalIndex + (afterDecimal + 1) );
      }
    }
    const parts = numStr.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ','); // Add commas
    return parts.join('.');
  },

  formatNumberAndDecimal (num) {
    if(!num) return 0
    let numStr = num?.toString(); // Convert number to string
    let beforeDecimal = numStr.split('.')[0];
    beforeDecimal = beforeDecimal.includes('-') ? beforeDecimal.split('-')[1] : beforeDecimal;
    const decimalIndex = numStr.indexOf('.');
    const afterDecimal = beforeDecimal.length > 6 ? 4 : 8;
    numStr = numStr.replace(/(\.[0-9]*[1-9])0+$|\.0*$/, '$1');
    if (decimalIndex > 0) {
      numStr = numStr.substring(0, decimalIndex + (afterDecimal + 1));
    }
    const parts = numStr.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ','); // Add commas
    return parts.join('.');
  },

  formatCurrencyAmount2(amount, currency, zeroValue) {
    if (amount === Infinity) {
      return '0';
    }

    let numberOfDecimalDigits = currency && currency.toLowerCase() === 'usd' ? COMMON_CONST.NUMBER_OF_DECIMAL_DIGITS_USD : COMMON_CONST.NUMBER_OF_DECIMAL_DIGITS; //currency === 'usd' ? 2 : 8;
    const digits = Math.floor(numberOfDecimalDigits) -1;
    let format = digits == 0 ?
        '0,0' :
        '0,0.0[' + Array(digits + 1).join('0') + ']';
    if (window._.isNil(zeroValue)) {
      zeroValue = '';
    }

    return (amount && parseFloat(amount) != 0) ? Numeral(amount).format(format) : zeroValue;
  },

  formatCurrencyAmount8(amount, currency, zeroValue) {
    let numberOfDecimalDigits = 8; //currency === 'usd' ? 2 : 8;
    const digits = Math.floor(numberOfDecimalDigits) -1
    let format = digits == 0 ?
        '0,0' :
        '0,0.0[' + Array(digits + 1).join('0') + ']';
    if (window._.isNil(zeroValue)) {
      zeroValue = '';
    }
    return (amount && parseFloat(amount) != 0) ? Numeral(amount).format(format) : zeroValue;
  },

  getPrecisionNumber(a) {
    const b = Numeral(a).value();
    if (!isFinite(b)) return 0;
    var e = 1, p = 0;
    while (Math.round(b * e) / e !== b) { e *= 10; p++; }
    return p;
  },

  convertToLocalTime(date) {
    if (date == null) {
      return '--';
    }
    var dateFormat = 'YYYY-MM-DD HH:mm:ss';
    var testDateUtc = moment.utc(date);
    var localDate = testDateUtc.local();
    return localDate.format(dateFormat);
  },

  formatMarginCost(amount, zeroValue = '0') {
    const cost = new BigNumber(new BigNumber(`${amount || 0}`).abs().toFixed(8, BigNumber.ROUND_UP));
    return this.formatMarginNumber(cost.toFixed(), 8, zeroValue);
  },

  formatMarginCapital(amount, zeroValue = '0') {
    const capital = new BigNumber(new BigNumber(`${amount || 0}`).abs().toFixed(8, BigNumber.ROUND_DOWN));
    return this.formatMarginNumber(capital.toFixed(), 8, zeroValue);
  },

  formatMarginNumber(amount, numberOfDecimalDigits = 8, zeroValue = null, isSetPositive = false) {
    const digits = Math.floor(numberOfDecimalDigits)
    let format = digits == 0 ?
      '0,0' :
      '0,0.[' + Array(digits + 1).join('0') + ']';

    if (amount && parseFloat(amount) != 0 && isSetPositive) {
      amount = Math.abs(parseFloat(amount));
    }
    
    return (amount && parseFloat(amount) != 0) ? Numeral(amount).format(format) : (zeroValue != null ? zeroValue : '--');
  },

  formatMarginValue(amount) {
    return Numeral(Math.abs(amount.toString().replace(',', ''))).value();
  },

  formatDigitsNumber(amount, numberOfDecimalDigits = 4, showZeroAtTheEnd=false) {
    const digits = Math.floor(numberOfDecimalDigits)
    let format = digits == 0 ?
      '0,0' :
      showZeroAtTheEnd ? '0,0.' + Array(digits + 1).join('0') : '0,0.[' + Array(digits + 1).join('0') + ']';

    let valueReturn = '--';
    if (amount && parseFloat(amount) !== 0) {
      valueReturn = Numeral(amount).format(format);
      // if (valueReturn.indexOf('.') < 0) {
      //   valueReturn += '.0';
      // }
    }
    return valueReturn;
  },

  formatMarginPrice(amount, numberOfDecimalDigits, customDefault='--') {
    const digits = Math.floor(numberOfDecimalDigits)
    let format = digits == 0 ?
      '0,0' :
      '0,0.[' + Array(digits + 1).join('0') + ']';

    return (amount && parseFloat(amount) != 0) ? Numeral(amount).format(format) : customDefault;
  },

  roundCurrencyAmount(amount, currency, zeroValue) {
    let numberOfDecimalDigits = currency && currency.toLowerCase() === 'usd' ? COMMON_CONST.NUMBER_OF_DECIMAL_DIGITS_USD : COMMON_CONST.NUMBER_OF_DECIMAL_DIGITS; //currency === 'usd' ? 2 : 8;
    const digits = Math.floor(numberOfDecimalDigits)
    let format = digits == 0 ?
      '0' :
      '0[.' + Array(digits + 1).join('0') + ']';
    if (window._.isNil(zeroValue)) {
      zeroValue = '';
    }

    return (amount && parseFloat(amount) != 0) ? Numeral(amount).format(format) : zeroValue;
  },

  getCurrencyName(value) {
    return value ? value.toUpperCase() : value;
  },

  getTimzoneOffset() {
    let d = new Date();
    return d.getTimezoneOffset();
  },

  isWalletAddress(currency, address, blockchain_sub_address){
    switch(currency){
      case 'usd':
        //TO DO
        return /^.+$/.test(address);
      case "xrp":
        return /^r[1-9A-HJ-NP-Za-km-z]{24,34}$/.test(address) && Number(blockchain_sub_address) < Math.pow(2, 32);
      case "etc":
        return /^[0-9A-HJ-NP-Za-km-z]{26,35}$/.test(address);
      case "eth":
      case "amal":
      case "tomo":
      case "knc":
      case "tusd":
        if (/^(0x)?[0-9a-fA-F]{40}$/.test(address)) {
          return true;
        }
        return false;
      case "btc":
      case "bch":
      case "wbc":
        return /^[123mntb][0-9A-Za-z]{32,61}$/.test(address);
      case "neo":
        return /^[A][1-9A-HJ-NP-Za-km-z]{26,35}$/.test(address);
      case "dash":
        return /^[0-9A-HJ-NP-Za-km-z]{26,35}$/.test(address);
      case "ltc":
        return /^[Lmn23MQlt][0-9A-Za-z]{33,43}$/.test(address);
      case "sol":
        return /^[1-9A-HJ-NP-Za-km-z]{32,44}$/.test(address);
      case "trx":
        return /^T[1-9A-HJ-NP-Za-km-z]{33}$/.test(address);
    }
    //TODO: Add validator for tusd, ais, xlm, ada, eos
    return true;
  },

  isWalletAddressWithNetworks(network, address){
    switch(network){
      case "ethereum":
      case "bsc":
      case "polygon":
        if (/^(0x)?[0-9a-fA-F]{40}$/.test(address)) {
          return true;
        }
        return false;
      case "solana":
        return /^[1-9A-HJ-NP-Za-km-z]{32,44}$/.test(address);
      case "tron":
        return /^T[1-9A-HJ-NP-Za-km-z]{33}$/.test(address);
    }
    return true;
  },

  getTransactionUrl(currency, transactionId) {
    let erc20Tokens = ['wbc', 'knc', 'tomo', 'tusd'];
    if (erc20Tokens.indexOf(currency) >= 0) {
      currency = 'erc20';
    }
    switch (currency) {
      case 'btc':
        return 'https://blockchain.info/tx/' + transactionId;
      case 'eth':
      case 'erc20':
        return 'https://etherscan.io/tx/' + transactionId;
      case 'etc':
        return 'https://gastracker.io/tx/' + transactionId;
      case 'bch':
        return 'https://explorer.bitcoin.com/bch/tx/' + transactionId;
      case 'xrp':
        return 'https://xrpcharts.ripple.com/#/transactions/' + transactionId;
      case 'ltc':
        return 'https://live.blockcypher.com/ltc/tx/' + transactionId;
      case 'neo':
        return 'https://neotracker.io/tx/' + transactionId;
      case 'dash':
        return 'https://explorer.dash.org/tx/' + transactionId;
      default:
        return '';
    }
  },

  trimEndZero(value) {
    const dot = '.';
    const strValue = `${value}`;
    if (strValue.indexOf(dot) === -1) {
      return strValue;
    }
    const trimEndZero = window._.trimEnd(strValue, '0');
    return window._.trimEnd(trimEndZero, dot);
  },

  //fallback to below function if the browser doesn't support clipboard method
  //ex: if (!navigator.clipboard) {
  //   fallbackCopyTextToClipboard(text);
  //   return;
  // }
  // navigator.clipboard.writeText(text)

  fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.position="fixed";  //avoid scrolling to bottom
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      // console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
  },
  replaceTmpVar(lang, articleNum) { 
    let obj = {
      Lang: lang,
      ArticleNum: articleNum
    }
    let url = `https://amanpuri-exchange.zendesk.com/hc/{Lang}/articles/{ArticleNum}`;
    Object.keys(obj).forEach((key) => {
      url = url.replace(new RegExp(`{${key}}`, 'g'), obj[key])
    })
    return url;
  },

  unrealizedPNL(markPrice, item, side, contract) {
    const isCurrency = item.asset === 'USD' || item.asset === 'USDT';
    const absQty = Math.abs(item.currentQty);
    const entryPrice = parseFloat(item.entryPrice);
    const multiplierContract = parseFloat(contract);
    const inverseEntry = new BigNumber(1).dividedBy(entryPrice);
    const inverseMark = new BigNumber(1).dividedBy(markPrice);
    const unrealizedPNLUSD = new BigNumber(absQty).times(new BigNumber(markPrice).minus(entryPrice)).times(side).toString()
    const unrealizedPNLCoin = new BigNumber(absQty)
      .abs()
      .times(multiplierContract)
      .times(new BigNumber(inverseEntry).minus(inverseMark))
      .times(side)
      .toString();
    return isCurrency ? unrealizedPNLUSD : unrealizedPNLCoin;
  },

  allowcatedMargin(item, contract, markPrice) {
    const isCurrency = item.asset === 'USD' || item.asset === 'USDT';
    const absQty = Math.abs(item.currentQty);
    const leverage = parseFloat(item.leverage);
    const adjustMargin = parseFloat(item.adjustMargin);
    const multiplierContract = parseFloat(contract);
    const positionMargin = parseFloat(item.positionMargin);
    let allowcatedMarginForCross = isCurrency
      ? new BigNumber(absQty).times(markPrice).dividedBy(leverage)
      : new BigNumber(absQty).times(multiplierContract).dividedBy(new BigNumber(leverage).times(markPrice))
    let allowcatedMarginForIsolate = new BigNumber(positionMargin || 0).add(adjustMargin || 0)
    return Boolean(item.isCross) ? allowcatedMarginForCross.toString() : allowcatedMarginForIsolate.toString();
  },

  allowcatedMarginForAsset(asset, listAllowcated) {
    let unrealizedTotal = 0
    const total = listAllowcated.reduce((sum, current) => {
      if (current.asset === asset && !isNaN(current.allowcated)) {
        unrealizedTotal = parseFloat(sum) + (current.allowcated * 1)
      }
      return unrealizedTotal
    }, 0);
    return total
  },

};
