<template>
  <div class="coin_input">
    <input type="text" class="form-control input-coin-number" :placeholder="placeholder" v-bind:class="{ 'border-bottom': component === OrderForm}" :value="value" :disabled="disabled" v-if="inputText"/>
    <currency-input
      :class="getClasses()"
      :precision="getPrecision()"
      :restrictStepPrice="restrictStepPrice"
      :maxLengthCus='16'
      v-model="internalValue" :placeholder="placeholder"
      @focus="$emit('focus')"
      :disabled="disabled" :ref="refCurrencyInput" v-bind:allowNegative="allowNegative" v-else/>
    <span :class="{'r10i': !visibleButton}" v-if="visibleUnit">{{unit | uppercase}}</span>
    <button tabindex="32766" class="plus" v-if="visibleButton" @click="onIncreaseClicked()"><span class="icon-arrow2"></span></button>
    <button tabindex="32767" class="minus" v-if="visibleButton" @click="onDecreaseClicked()"><span class="icon-arrow1"></span></button>
  </div>
</template>
<script>
  import BigNumber from 'bignumber.js';
  import CurrencyInput from './CurrencyInput';

  export default {
    components: {
      CurrencyInput
    },
    props: {
      value               : {},
      precision           : {type: Number},
      decimal             : {type: Number},
      restrictStepPrice   : {type: Boolean, default: false},
      unit                : {type: String},
      disabled            : {type: Boolean, default: false},
      inputText           : {type: Boolean, default: false},
      visibleButton       : {type: Boolean, default: false},
      classes             : {type: String},
      placeholder         : {type: String},
      component           : {type: String},
      allowNegative       : {type: Boolean, default: false},
    },
    data() {
      return {
        internalValue: '',
        refCurrencyInput: this.getRefUniqueId(),
        OrderForm: "order-form"
      };
    },
    watch: {
      value(newValue) {
        this.updateValue();
      },
      precision(newPrecision) {
        // console.log(' #check precision', newPrecision)
        this.updatePrecision(newPrecision);
      },
      internalValue(val) {
       this.$emit('input', val);
     }
   },
   computed: {
    visibleUnit() {
      return !window._.isEmpty(this.unit);
    },
  },
  methods: {
    onIncreaseClicked() {
      if(this.internalValue == '-'){
        return;
      }
      this.internalValue = this.newBigNumber(this.internalValue).plus(`${this.precision}`).toString();
    },
    onDecreaseClicked() {
      if(this.internalValue == '-'){
        return;
      }
      const result = this.newBigNumber(this.internalValue).sub(`${this.precision}`);
      if (result.gte(this.precision) || this.allowNegative) {
        this.internalValue = result.toString();
      }
    },

    getClasses() {
      if (!this.classes) {
        return 'input-coin-number';
      }
      return `input-coin-number ${this.classes}`;
    },

    getPrecision() {
      // console.log('check decimal', this.decimal)
      if (this.decimal || this.decimal == 0) {
        return this.decimal;
      }
      return this.unit === 'usd' ? 0 : 8;
    },

    getRefUniqueId() {
      return window._.uniqueId('input-coin-number-');
    },

    newBigNumber(value) {
      if (window._.isEmpty(value)) {
        value = 0;
      }
      return new BigNumber(value);
    },

    updateValue() {
      if (this.value instanceof BigNumber) {
       this.internalValue = this.value.toString();
       return;
      }
      this.internalValue = this.value;
    },

   updatePrecision(newPrecision) {
    const precision = Math.round(Math.log(1 / this.precision) / Math.log(10));
    // console.log('updatePrecision', precision)
    this.$refs[this.refCurrencyInput] && this.$refs[this.refCurrencyInput].setPrecision(precision);
  }
},
mounted() {
  this.updateValue();
}
}
</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables";

.coin_input{
  &.error{
    margin-right: 4px !important;
    border-radius: 4px;
    > input{
      border: solid 1px $color-red-main;
      border-color: $color-red-main !important;
    }
  }
  position: relative;
  input[type="text"]{
    padding: 10px;
    height: 40px;
    border: 1px solid $color-charade;
    width: 100%;
    padding-right: 27px;
    padding-left: 15px;
    border-radius: 3px;
    font-weight: bold;
    color: $color-grey-sliver;
    background-color: $color-charade;
    font-size: $font-root;
    font-weight: 500;
    &:focus {
      border: 1px solid $color-aqua-green;
    }
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
       font-size: $font-root;
       color: $color-grey-dusty;
       font-weight: 500;
    }
    &::-moz-placeholder { /* Firefox 19+ */
       font-size: $font-root;
       color: $color-grey-dusty;
       font-weight: 500;
    }
    &:-ms-input-placeholder { /* IE 10+ */
       font-size: $font-root;
       color: $color-grey-dusty;
       font-weight: 500;
    }
    &:-moz-placeholder { /* Firefox 18- */
       font-size: $font-root;
       color: $color-grey-dusty;
       font-weight: 500;
    }
  }
  &>span {
    position: absolute;
    top: 0;
    right: 27px;
    color: $color-grey;
    line-height: 40px;
    font-size: $font-root;
  }

  button{
    position: absolute;
    right: 0;
    height: 20px;
    width: 20px;
    background-color: transparent;
    border: 0;
    &:focus {outline:0;}
    &.plus{
      top: 1px;
      border-top: none;
      &>span {
        position: absolute;
        top: 4px;
        right: 7px;
        color: $color_grey_border;
        line-height: 20px;
        font-size: $font-mini-mini;
      }
    }
    &.minus{
      bottom: 1px;
      &>span {
        position: absolute;
        bottom: 3px;
        right: 7px;
        color: $color_grey_border;
        line-height: 20px;
        font-size: $font-mini-mini;
      }
    }
    }
    &:focus{
      outline: -webkit-focus-ring-color auto 5px;
    }
  }

</style>
