<template>
  <div>
    <AccountTopHeader :title="$t('account.security')" :icon="require(`@/assets/images/banner-phone-number.svg`)"/>
    <div
      v-if="isLoading"
      class="d-flex justify-content-center align-items-center contents_setting"
      style="max-width: initial; min-height: 50vh"
    >
      <div class="loader"></div>
    </div>
    <template v-else>
      <phone-number-modal
        :type="typeModal"
        ref="modalPhoneNumber"
        @getUserInfo="getCurrentUser"
      ></phone-number-modal>
      <edit-phone-number-modal
        :type="typeModal"
        ref="modalEditPhoneNumber"
        @getUserInfo="getCurrentUser"
        @showPhoneModal="emitShowPhoneModal"
      ></edit-phone-number-modal>
      <div class="body-phone-number w-100">
        <div
          class="d-flex position-relative top-body align-items-center justify-content-center w-100"
        >
          <span class="icon-arrow4 position-absolute" @click="clickBack"></span>
          <div class="title-phone-number">{{ $t("phone_number_title") }}</div>
        </div>

        <div v-if="!phoneVerified">
          <div class="phone-number-description">
            <div>{{ $t("phone_number_description-1") }}</div>
            <div>{{ $t("phone_number_description-2") }}</div>
          </div>
          <div class="phone-number-description--mobile">
            <div>{{ $t("phone_number_description") }}</div>
          </div>
          <div class="btn-group">
            <button
              name="btnSubmit"
              :disabled="isSubmitting"
              class="btn-add-phone-number"
              @click="showPhoneModal('add')"
            >
              {{ $t("phone_number.add") }}
            </button>
          </div>
        </div>

        <div v-else class="phone-number-wrapper">
          <div class="phone-number-action">
            <div class="phone-number-action__content">
              <div>{{ phoneNumber }}</div>
              <!-- <div class="phone-number-action__content-suffix">
                <img
                  :src="require(`@/assets/images/edit-icon${dark}.svg`)"
                  @click="openEditModal"
                />
                <img
                  :src="require(`@/assets/images/delete-icon${dark}.svg`)"
                  @click="openDeleteModal"
                />
              </div> -->
            </div>

            <div class="phone-number-action__time">Added: {{ createdAt }}</div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import AccountTopHeader from "@/components/shared_components/common/AccountTopHeader.vue";
import AccountSiteBar from "@/components/shared_components/common/AccountSiteBar";
import rf from "@/request/RequestFactory";
import { mapState } from "vuex";
import PhoneNumberModal from "./modal/PhoneNumberModal.vue";
import EditPhoneNumberModal from "./modal/EditPhoneNumberModal.vue";
import moment from "moment";
import ConfirmationModal from "@/components/shared_components/common/ConfirmationModal.vue";

export default {
  components: {
    AccountTopHeader,
    AccountSiteBar,
    PhoneNumberModal,
    EditPhoneNumberModal,
    ConfirmationModal,
  },

  data() {
    return {
      params: {
        oldCode: "",
        newCode: "",
        confirmNewCode: "",
      },
      step: this.mode,
      typeModal: "add",
      sizeScreen: window.innerWidth,
      APP_SHORT_NAME: process.env.VUE_APP_SHORT_NAME,
      phoneVerified: "",
      phoneNumber: "",
      phoneNumberCreatedAt: "",
      isLoading: false,
    };
  },
  props: {
    mode: {
      type: "create" | "change",
      default: "create",
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      theme: (state) => state.userThemeMode,
    }),
    confirmedCode() {
      return this.params.confirmNewCode === this.params.newCode;
    },
    createdAt() {
      return moment(this.phoneNumberCreatedAt).format("MMMM DD, YYYY");
    },
    dark() {
      return this.theme === "light-theme" ? "" : "-dark";
    },
  },
  methods: {
    clickBack() {
      this.$router.push({ name: "SettingsSecurity" });
    },

    getCurrentUser() {
      this.isLoading = true;
      rf.getRequest("UserRequest")
        .getCurrentUser(false)
        .then((res) => {
          this.isLoading = false;
          this.phoneVerified = res?.data?.security_setting?.phone_verified;
          (this.phoneNumber = res?.data?.phone_number),
            (this.phoneNumberCreatedAt = res?.data?.phone_register_created_at);
        });
    },

    openEditModal() {
      window.ConfirmationModal.show({
        content: window.i18n.t("phone_number.confirm_change"),
        btnCancelLabel: window.i18n.t("common.action.no"),
        btnConfirmLabel: window.i18n.t("common.action.yes"),
        onConfirm: () => {
          this.typeModal = "change";
          window.CommonModal.show("EditPhoneNumberModal");
        },
        onCancel: () => {},
      });
    },

    openDeleteModal() {
      window.ConfirmationModal.show({
        content: window.i18n.t("phone_number.confirm_delete"),
        btnCancelLabel: window.i18n.t("common.action.no"),
        btnConfirmLabel: window.i18n.t("common.action.yes"),
        onConfirm: () => {
          this.typeModal = "delete";
          window.CommonModal.show("EditPhoneNumberModal");
        },
        onCancel: () => {},
      });
    },

    showPhoneModal(type = "add") {
      this.typeModal = type;
      window.CommonModal.show("PhoneNumberModal");
      // this.$refs.modalPhoneNumber.$props.mode = this.mode;
    },

    emitShowPhoneModal(event) {
      setTimeout(() => {
        this.showPhoneModal(event.type);
      }, 1000);
    },

    async changeAntiPhishingModal() {
      const params = {
        is_anti_phishing: !!this.params.confirmNewCode,
        anti_phishing_code: this.params.confirmNewCode,
      };
      this.resetErrors();
      await this.$validator.validate("confirmNewCode");

      if (this.params.newCode !== this.params.confirmNewCode) {
        this.errors.add(
          "confirmNewCode",
          this.$t("anti.validate.code_not_match")
        );
      }
      await this.$validator.validate("newCode");
      if (this.errors.any()) {
        return;
      }
      rf.getRequest("UserRequest")
        .createAntiPhishing(params)
        .then(() => {
          Message.success("Successfully");
          this.$refs.modalAntiPhishing.$props.mode = "resend";
          this.$router.push({
            name: "EmailVerification",
            query: { code: this.params.confirmNewCode, type: "update" },
          });
        })
        .catch((err) => {
          Message.error("");
        });
    },
    resetErrors() {
      if (this.errors.any()) {
        this.errors.clear();
      }
    },
    resizeHandler() {
      this.sizeScreen = window.innerWidth;
    },
  },
  mounted() {
    this.getCurrentUser();
    window.scrollTo({
      top: 0,
      left: 0,
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.loader {
  display: block;
  margin: auto;
  border: 5px solid $color-grey-concrete;
  border-radius: 50%;
  border-top: 5px solid $color-blue-custom;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.phone-number-wrapper {
  display: flex;
  justify-content: center;
}
.phone-number-action {
  display: flex;
  justify-content: center;
  flex-direction: column;

  &__content {
    display: flex;
    justify-content: space-between;
    width: 529px;
    background: var(--bgr-hover-row);
    padding: 14px 15px;
    border-radius: 8px;
    font-size: 16px;
    line-height: 19px;
    color: var(--dark-1);

    &-suffix {
      display: flex;
      gap: 12px;
    }
  }

  &__time {
    color: $dark-2;
    font-size: 18px;
    line-height: 22px;
    margin-top: 8px;
  }
}
.dark-theme {
  .body-phone-number {
    .top-body {
      .icon-arrow4 {
        &::before {
          color: $color-auro-metal;
        }
      }
      .title-phone-number {
        color: $color-white;
      }
    }

    .phone-number-description,
    .phone-number-description--mobile {
      color: $color-auro-metal;
    }

    .phone-number-action {
      &__time {
        color: $color-auro-metal;
      }
    }
  }
}

.body-phone-number {
  padding: 36px 45px;

  .form-control {
    box-shadow: inset 0 0 0 rgb(0 0 0 / 8%);
  }

  .top-body {
    margin-bottom: 32px;

    .icon-arrow4 {
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      &::before {
        color: $color-grey-dark;
      }
    }
    .title-phone-number {
      font-weight: 600;
      font-family: $font-inter-bold;
      font-size: 30px;
      color: $color-grey-dark;
      text-align: center;
    }
  }

  .btn-group {
    margin-top: 48px;
    width: 100%;
    display: flex;
    justify-content: center;

    .btn-add-phone-number {
      @include btn-common(yes);
      width: 100%;
      max-width: 529px;
    }
  }

  .phone-number-description {
    font-size: 18px;
    line-height: 21px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $color-gray-dark;

    &--mobile {
      display: none;
      color: $color-gray-dark;
    }
  }
}

@media screen and (max-width: 992px) {
  .body-phone-number {
    padding: 30px 16px;
    height: 380px;
    margin-bottom: 60px;

    .top-body {
      margin-bottom: 30px;

      .title-phone-number {
        font-size: 20px;
        line-height: 24px;
      }
    }
    .phone-number-description {
      font-size: 14px;
      line-height: 16px;
      display: none;

      &--mobile {
        display: block;
        text-align: center;
      }
    }

    .phone-number-action {
      width: 100%;
      &__content {
        width: 100%;
      }

      &__time {
        font-size: 14px;
        line-height: 16px;
      }
    }

    .btn-group {
      margin-top: 30px;
    }
  }
}
</style>
