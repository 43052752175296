<template>
  <div>
    <modal :name="modalName" width="560">
      <template slot="body">
        <h3 class="box-title-modalsub">
          <span>
            <template v-if="isAssign">{{ $t('funds.balances.assign') }}</template>
            <template v-else>{{ $t('funds.balances.revoke') }}</template>
          </span>
        </h3>
        <div class="modalsub_container clearfix">
          <div class="des_content_join">
            <div class="left_des_ctj"  v-if="isAssign">
              <div class="group_des_ctj">
                <span class="txt_des_ctj">{{ $t('funds.balances.available_amount') }}: </span>{{ availableBalance | formatMarginNumber(8, '0') }} {{ $t('margin.symbol.btc') }}
              </div>
              <div class="clearfix"></div>
            </div>
            <div class="left_des_ctj" v-else>
              <div class="group_des_ctj">
                <span class="txt_des_ctj">{{ $t('mam.my_balance') }}: </span>{{ displayInvestment | formatMarginNumber(8, '0') }} {{ $t('margin.symbol.btc') }}
              </div>
              <div class="clearfix"></div>
              <div class="group_des_ctj">
                <span class="txt_des_ctj">{{ $t('mam.my_profit') }}: </span>{{ profit | formatMarginNumber(8, '0') }} {{ $t('margin.symbol.btc') }}
              </div>
              <div class="clearfix"></div>
              <div class="group_des_ctj">
                <select-box
                  :options="revokeTypeSelectBox"
                  v-model="revokeType"
                />
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
          <div class="clearfix"></div>
          <form class="form_join_modal" v-on:submit.prevent>
            <div class="group_input_join">
              <label>{{ $t('mam.amount') }}</label>
              <div class="clearfix"></div>
              <div class="gr_input_jm">
                <currency-input :precision="8" 
                                :class="{'error': errors.has('amount')}"
                                :maxLengthCus='16'
                                v-model="assignRevokeAmount" 
                                @focus="resetError"
                                :disabled="revokeType == 3"/>
                <span class="rihg_input_as"></span>
              </div>
              <div class="clearfix"></div>
              <div class="clearfix" v-if="errors.has($t('amount'))"
                   :class="{ 'msg-error' : errors.first('amount') }">{{ errors.first('amount') }}
              </div>
            </div>
            <div v-if="isFreezeTime()">
              <div>{{ $t('mam.freeze_time') }}</div>
              <div class="clearfix"></div>
            </div>
            <div class="clearfix"></div>
            <button class="btn btn_send" @click="onAssignRevokeRequest()">{{ isAssign ? $t('funds.balances.assign') : $t('funds.balances.revoke') }}
            </button>
          </form>
        </div>
      </template>
    </modal>
    <show-error-assign-revoke-modal @ShowErrorAssignRevokeModal:CLOSE="handleCloseShowWarningModal" :message="amountErrorMessage"/>

  </div>
</template>
<script>
  import Modal from './Modal';
  import rf from '@/request/RequestFactory'
  import BigNumber from 'bignumber.js';
  import moment from 'moment';
  import RemoveErrorsMixin from '@/common/RemoveErrorsMixin';
  import SelectBox from './SelectBox';
  import Mam from './mam.js'
  import Utils from '@/common/Utils';
  import ShowErrorAssignRevokeModal from './ShowErrorAssignRevokeModal.vue'

  export default {
    components: {
      Modal,
      SelectBox,
      ShowErrorAssignRevokeModal,
      configs: {
        name: '',
      },
    },
    mixins: [RemoveErrorsMixin],
    data() {
      return {
        modalName: 'AssignRevokeModal',
        availableBalance: 0,
        investmentBalance: 0,
        profit: 0,
        isAssign: true,
        assignRevokeAmount: '',
        maxRevokeAmount: '',
        followerId: null,
        revokeType: Mam.revokeTypeSelectBox[0].id,
        revokeTypeSelectBox: Mam.revokeTypeSelectBox,
        amountErrorMessage: '',
      }
    },
    computed: {
      displayInvestment () {
        return new BigNumber(`${this.investmentBalance || 0}`).toString();
      },
    },
    watch: {
      revokeType (value) {
        // console.log(value)
        if (value == '3') {
          this.assignRevokeAmount = '';
        }
      }
    },
    methods: {
      handleCloseShowWarningModal () {
        this.callAssignRevokeRequest();
      },
      isFreezeTime () {
        if (moment.unix(moment.now() / 1000).format("DD") > '25') {
          return true;
        }
        return false;
      },
      closeModal() {
        this.resetData();
        this.$emit(`${this.modalName}:CLOSE`);
        window.CommonModal.hide('AssignRevokeModal');
      },
      isAssignModal(assignId) {
        this.followerId = assignId;
        this.isAssign = true;
        this.initData();
      },
      isRevokeModal(revokeId) {
        this.followerId = revokeId;
        this.isAssign = false;
        this.initData();
      },
      resetData() {
        this.assignRevokeAmount = '';
      },
      resetError() {
        this.errors.clear();
      },
      validate() {
        this.maxRevokeAmount = new BigNumber(`${this.investmentBalance || 0}`).plus(`${this.profit || 0}`).toString();
        if (new BigNumber(`${this.assignRevokeAmount || 0}`).comparedTo(`${this.maxRevokeAmount || 0}`) > 0 && !this.isAssign) {
          this.openShowErrorModal(
            window.i18n.t('mam.revoke.errors.max_amount', { amount: Utils.formatMarginNumber(this.assignRevokeAmount) })
          );
          return false;
        }

        this.errors.clear();
        if (!this.assignRevokeAmount && this.revokeType != '3') {
          this.errors.add('amount', window.i18n.t('common.error.required', { field: 'amount' }));
        }

        if (new BigNumber(`${this.assignRevokeAmount || 0}`).comparedTo(0) == 0 && this.revokeType != '3') {
          this.errors.add('amount', window.i18n.t('funds.deposit_usd.errors.min_amount', { min_amount: 0 }));
        }

        if (new BigNumber(`${this.assignRevokeAmount || 0}`).comparedTo(`${this.availableBalance || 0}`) > 0 && this.isAssign) {
          this.errors.add('amount', window.i18n.t('mam.balance.not_enough'));
        }

        if (this.errors.count() > 0) {
          return false;
        }

        return true;
      },
      openShowErrorModal(message) {
        this.amountErrorMessage = message;
        window.CommonModal.show('ShowErrorAssignRevokeModal');
        return;
      },
      onAssignRevokeRequest() {
        if (!this.validate()) return;
        this.callAssignRevokeRequest();
      },
      callAssignRevokeRequest() {
        let params = {
          amount: this.isAssign ? this.assignRevokeAmount : '-' + (this.revokeType == '3' ? this.maxRevokeAmount : this.assignRevokeAmount),
          follower_id: this.followerId,
        };
        if (!this.isAssign) {
          params.revoke_type = this.revokeType;
        }
        rf.getRequest("MamRequest").createAssignRevokeRequest(params)
          .then(res => {
            let locale = res.success;
            if (locale) {
              this.showSuccess(this.isAssign ? this.$t('mam.msg.assigned.success') : this.$t('mam.msg.revoked.success'));
              this.closeModal();
            }
          })
          .catch(error => {
            if (error && error.response && error.response.data && error.response.data.message) {
              this.showError(error.response.data.message);
            }
            this.convertRemoteErrors(error);
          })
      },
      initData() {
        rf.getRequest("MamRequest").getDetailFollower({ follower_id: this.followerId })
          .then(res =>{
            if (res && res.data) {
              this.availableBalance = res.data.available_balance;
              this.investmentBalance = res.data.user_balance;
              let inversePerformanceRate = new BigNumber(100).minus(`${res.data.performance_rate || 0}`).toString();
              this.profit = new BigNumber(`${res.data.user_balance || 0}`).comparedTo(`${res.data.user_capital || 0}`) >= 0
                ? new BigNumber(`${res.data.user_balance || 0}`).minus(`${res.data.user_capital || 0}`).mul(`${inversePerformanceRate || 0}`).div(100).toString() : 0;
            }
          })
      },
      showSuccess(message) {
        Message.success(message, {}, {position: 'bottom_left'});
      },
      showError(message) {
        Message.error(message, {}, {position: 'bottom_left'});
      },
    },
    mounted() {
    },
  }
</script>
<style lang="scss" scoped>
  @import "@/assets/sass/variables.scss";

  .formInput {
    .checkbox_address {
      line-height: 23px;
      font-size: $font-small;
      font-weight: 400;
      color: $color-grey-dusty;
      margin-top: 12px;
      input{
        display: none;
      }
      span{
        content: "";
        display: inline-block;
        border: 1px solid $color-grey;
        width: 14px;
        height: 14px;
        top: 2px;
        left: 0px;
        border-radius: 0px;
        margin-right: 8px;
        position: relative;
        background-color: transparent;
      }
      input:checked+span:after {
        opacity: .9;
        content: "";
        position: absolute;
        width: 13px;
        height: 7px;
        background: transparent;
        top: 0px;
        left: 0;
        border: 3px solid $color-jungle-green;
        border-top: none;
        border-right: none;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
      }
    }
  }
  .msg-error {
      color: $color-red-warning;
      font-size: $font-small;
      line-height: 20px;
  }

  .box-title-modalsub {
    margin: 5px 0px 0px 0px;
    background-color: $color-white;
    text-align: center;
    overflow: hidden;
    padding-bottom: 20px;

    span {
      display: inline-block;
      padding: 5px 10px;
      position: relative;
      font-size: $font-big-20;
      font-family: $font-family-roboto-medium;
      color: $color-eden;
      line-height: 30px;

      &:before {
        content: "";
        background-color: $color-light-grey;
        display: block;
        width: 60px;
        height: 1px;
        position: absolute;
        top: 50%;
        left: -60px;
      }

      &:after {
        content: "";
        background-color: $color-light-grey;
        display: block;
        width: 60px;
        height: 1px;
        position: absolute;
        top: 50%;
        right: -60px;
      }
    }
  }

  .group_find_join_modal {
    margin: 0px auto 30px auto;
    width: 320px;
    max-width: 94%;
    overflow: hidden;

    .txt_find {
      display: inline-block;
      float: left;
      line-height: 40px;
      width: 24px;
      font-size: $font-root;
      color: $color-grey-dark;
    }

    .btn-find {
      display: block;
      float: right;
      width: 80px;
      height: 40px;
      color: $color-white;
      margin-left: 10px;
      font-size: 12px;
      letter-spacing: 0.9px;
      border-radius: 3px;
      font-family: $font-family-roboto-bold;
      text-transform: uppercase;
      background-color: $color-jungle-green;
      border: 1px solid $color-jungle-green;

      &:hover, &:focus {
        background-color: $color-blue-custom;
        border-color: $color-blue-custom;
      }
    }

    .input_find {
      display: inline-block;
      float: left;
      width: calc(100% - 24px - 90px);
      height: 40px;
      border-radius: 3px;
      border: solid 1px $color-alto;
      padding: 10px;
      line-height: 20px;
      font-size: $font-root;
      color: $color-grey-dark;

      &:focus {
        border-color: $color-jungle-green;
      }
    }
  }

  .modalsub_container {
    padding: 25px 0px;
    background-color: $color-alabaster;

    .left_des_ctj {
      padding-left: 60px;
      padding-right: 5px;

      .group_des_ctj {
        font-size: $font-root;
        color: $color-grey-dark;
        line-height: 20px;
        margin-bottom: 8px;
        font-family: $font-family-roboto-bold;

        .txt_des_ctj {
          font-family: $font-family-roboto;
        }
      }
    }
  }

  .form_join_modal {
    padding: 5px 60px;

    .group_input_join {
      display: block;
      width: 100%;
      margin-bottom: 15px;

      label {
        color: $color-grey-dark;
        font-size: $font-root;
        line-height: 16px;
        font-weight: 500;
        margin-bottom: 8px;
      }

      input {
        width: 100%;
        border-radius: 3px;
        border: solid 1px $color-alto;
        height: 40px;
        line-height: 20px;
        padding: 5px 50px 5px 20px;
        font-size: $font-root;
        color: $color-grey-dark;

        &:focus {
          border-color: $color-jungle-green;
        }
      }

      textarea {
        width: 100%;
        border-radius: 3px;
        border: solid 1px $color-alto;
        min-height: 60px;
        line-height: 20px;
        padding: 5px 20px;
        font-size: $font-root;
        color: $color-grey-dark;

        &:focus {
          border-color: $color-jungle-green;
        }
      }

      .gr_input_jm {
        position: relative;
        display: block;
        width: 100%;

        .rihg_input_as {
          position: absolute;
          right: 15px;
          line-height: 40px;
          bottom: 0px;
          display: inline-block;
          font-size: $font-root;
          color: $color-grey-dark;
          font-family: $font-family-roboto-medium;
        }
      }
    }
  }

  .btn_send {
    display: block;
    margin: 20px auto 30px auto;
    width: 220px;
    height: 50px;
    border-radius: 3px;
    background-color: $color-jungle-green;
    line-height: 20px;
    padding: 15px;
    letter-spacing: 1.1px;
    color: $color-white;
    font-size: $font-root;
    text-transform: uppercase;
    font-family: $font-family-roboto-bold;

    &:hover, &:focus {
      background-color: $color-blue-custom;
      border-color: $color-blue-custom;
    }
  }

  .col-6 {
    width: 50%;
    float: left;
  }

</style>

<style lang="scss">
  @import "@/assets/sass/variables.scss";

  #AssignRevokeModal {
    .modal-footer {
      display: none;
    }

    .modal-body {
      padding: 0px;
    }

    table {
      tbody {
        tr.empty-data:hover {
            background-color: transparent !important;
        }
      }
    }
  }
</style>
