const openOrderHeader = [
  {
    id: 'created_at',
    name: 'order.open_order.date',
  },
  {
    id: 'coin',
    name: 'order.open_order.pair',
  },
  {
    id: 'type',
    name: 'order.open_order.type',
  },
  {
    id: 'trade_type',
    name: 'order.open_order.side',
  },
  {
    id: 'price',
    name: 'order.open_order.price',
  },
  {
    id: 'quantity',
    name: 'order.open_order.amount',
  },
  {
    name: 'order.open_order.filled',
  },
  {
    name: 'order.open_order.total',
  },
  {
    name: 'order.open_order.trigger_conditions',
    textRight: true,
  },
];
const openOrderConvertHeader = [
  {
    id: 'created_at',
    name: 'order.open_order.date',
    isSort: true
  },
  {
    id: 'coin',
    name: 'order.open_order.pair',
    isSort: true
  },
  {
    id: 'from',
    name: 'funds.balances.from',
    isSort: false
  },
  {
    id: 'to',
    name: 'funds.balances.to',
    isSort: false
  },
  {
    id: 'price',
    name: 'order.open_order.price',
    isSort: false
  },
  {
    id: 'filled',
    name: 'order.open_order.filled',
    isSort: false
  },
];

const orderHistoryHeader = [
  {
    id: 'updated_at',
    name: 'order.open_order.date',
    isSort: true

  },
  {
    id: 'coin',
    name: 'order.open_order.pair',
    isSort: false
  },
  {
    id: 'type',
    name: 'order.open_order.type',
  },
  {
    id: 'trade_type',
    name: 'order.open_order.side',
  },
  {
    name: 'order.order_history.average',
  },
  {
    id: 'price',
    name: 'order.open_order.price',
  },
  {
    name: 'order.open_order.filled',
  },
  {
    name: 'order.open_order.amount',
  },
  {
    name: 'order.open_order.total',
  },
  {
    name: 'order.open_order.trigger_conditions',
  },
  {
    name: 'order.order_history.status',
  },
];

const tradeHistoryHeader = [
  {
    id: 'created_at',
    name: 'order.open_order.date',
  },
  {
    id: 'coin',
    name: 'order.open_order.pair',
  },
  {
    id: 'trade_type',
    name: 'order.open_order.side',
  },
  {
    id: 'price',
    name: 'order.open_order.price',
  },
  {
    name: 'order.open_order.filled',
  },
  {
    id: 'fee',
    name: 'order.trade_history.fee',
  },
  {
    name: 'order.open_order.total',
  },
];
const convertHistory = [
  {
    id: 'coin',
    name: 'order.open_order.pair',
    isSort: true

  },
  {
    id: 'type',
    name: 'order.open_order.type',
    isSort: false
  },
  {
    id: 'from',
    name: 'funds.balances.from',
    isSort: false,
    hoverTitle: 'convert.history.hover_from'
  },
  {
    id: 'price',
    name: 'funds.balances.to',
    isSort: false,
    hoverTitle: 'convert.history.hover_to'
  },
  {
    name: 'order.open_order.executed',
    isSort: false,
    hoverTitle: 'convert.history.hover_executed'
  },
  {
    id: 'settlement',
    name: 'order.history.average_price',
    isSort: false,
    hoverTitle: 'convert.history.hover_avarage_price'
  },
  {
    id: 'updated_at',
    name: 'order.history.settlement_date',
    isSort: true

  },
  {
    id: 'status',
    name: 'order.order_history.status',
    isSort: false
  },
];

export default {
   OPEN_ORDER_HEADER: openOrderHeader,
   OPEN_ORDER_CONVERT_HEADER: openOrderConvertHeader,
   ORDER_HISTORY_HEADER: orderHistoryHeader,
   TRADE_HISTORY_HEADER: tradeHistoryHeader,
    CONVERT_HISTORY_HEADER: convertHistory
 };
