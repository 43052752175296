<!--  -->
<template>
  <div class=''>
    <!-- use geetest -->
    <div class="input-group">
      <div>
        <div :id="uniqueId"></div>
        <p id="wait" class="show">{{ $t('common.geetest.loading') }} </p>
        <p id="notice" class="hide">{{ $t('common.geetest.fail') }}</p>
      </div>
      <div class="invalid-feedback" v-if="geetestFail">
        {{ $t('common.geetest.fail') }}
      </div>
    </div>
    <!-- end geetest -->
  </div>
</template>

<script>

import Cookies from 'js-cookie'
import rf from '@/request/RequestFactory'

  export default {
    components: {},
    data() {
      return {
        host: window.location.origin,
        gtserver: 0,
        geetestFail: false,
        isPassed: false,
        captchaObj: null,
        uniqueId: 'embed-captcha-' + Math.floor(Math.random() * 100000),
      };
    },
    created() {
        this.getInitGtTest();
    },
    methods: {
      handlerEmbed (captchaObj) {
        if(!$('#' + this.uniqueId).length){
          return;
        }
        this.captchaObj = captchaObj;
        captchaObj.appendTo('#' + this.uniqueId);
        captchaObj
          .onReady(() => {
            $("#wait")[0].className = "hide";
          })
          .onSuccess(() => {
            const data = captchaObj.getValidate();
            if (!data) {
              this.geetestFail = true;
              this.isPassed = false;
              this.$emit('geetest_updated', null);
              return;
            }
            this.geetestFail = false;
            this.isPassed = true;
            data.gtserver = this.gtserver;
            this.$emit('geetest_updated', data);
          })
          .onError(() => {
            this.geetestFail = true;
            this.isPassed = false;
            this.$emit('geetest_updated', null);
          })
          .onClose(() => {
            this.geetestFail = false;
            this.isPassed = false;
            this.$emit('geetest_updated', null);
          })
      },

      getInitGtTest () {
        rf.getRequest('UserRequest').preVerifyCaptcha().then((res) => {
          this.gtserver = res.gtserver;
          window.initGeetest({
            gt: res.gt,
            challenge: res.challenge,
            offline: !res.success, // 用户后台检测极验服务器是否宕机
            new_captcha: res.new_captcha, // 用于宕机时表示新验证码的宕机是
            lang: Cookies.get('locale', { domain: process.env.VUE_APP_PUBLIC_URL }),
            product: 'popup', // 产品形式, float, popup
            width: "100%", //  width: "300px" 极验显示宽度
          }, this.handlerEmbed);
        });
      },

      resetForm () {
        this.geetestFail = false;
        this.isPassed = false;
        this.captchaObj.reset();
      },

      validateForm () {
        if (!this.isPassed) {
          this.geetestFail = true;
        }
        return this.isPassed;
      }
    }
  }

</script>

<style></style>
