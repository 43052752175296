<template>
    <div id="account_reward">
        <AccountTopHeader :title="$t('account.reward_center')" :icon="require(`@/assets/images/bgr-order.svg`)"/>
        <div class="content_reward">
            <div class="choose-type">
                <div class="voucher-type">
                    <div class="text-main choose-type_text">{{ $t('account.voucher_type') }}</div>
                    <select-box
                        v-model="voucherType"
                        :options="listVoucherType"
                    />
                </div>
                <div class="status">
                    <div class="text-main choose-type_text">{{ $t('account.status') }}</div>
                    <select-box
                        v-model="status"
                        :options="listStatus"
                    />
                </div>
            </div>
            <div class="main-reward">
                <div class="list-reward" v-if="listVoucher.length > 0">
                    <div class="item" v-for="item in listVoucher" :key="item.id" :class="{active : checkStatus(item), inprogress: convertStatus(item) === 'In Progress' && !item.user_id}">
                        <div class="content-item" >
                            <div class="content-money">
                                <img  v-if="!isNaN(checkStatus(item))" :src="require(`/public/images/icon-star-reward${theme === 'dark-theme' ? 'semi-dark': ''}.svg`)" alt="" class="icon icon1">
                                <img  v-else :src="require(`/public/images/icon-star-reward${theme === 'dark-theme' ? 'semi-dark': ''}.svg`)" alt="" class="icon icon1">
                                <div class="value-money">{{ item.user_id ? item.amount_old : item.amount || 0 | toNumber }}</div>
                                <div class="coin">{{ item.currency | uppercase }}</div>
                              <img  v-if="!isNaN(checkStatus(item))" :src="require(`/public/images/icon-star-reward${theme === 'dark-theme' ? 'semi-dark': ''}.svg`)" alt="" class="icon icon2">
                              <img  v-else :src="require(`/public/images/icon-star-reward${theme === 'dark-theme' ? 'semi-dark': ''}.svg`)" alt="" class="icon icon2">
                                <span class="circle circle1"></span>
                                <span class="circle circle2"></span>
                            </div>
                            <div class="content-value">
                                <div class="text-main">{{ item.name}} <span>{{ item.type}}</span></div>
                                <div class="text-sub text-date">{{ $t('account.expiry_date') }} {{ getDate(item.expires_date) }}</div>
                                <div class="length-value">
                                    <div class="length-main" :style="{ width: calculateWidth(item.total_trading, item.user_id ? item.conditions_use_old: item.conditions_use) }"></div>
                                </div>
                                <div class="trade-volume">
                                    <div class="text-sub text-trade">{{ $t('account.trading_volume') }}</div>
                                    <div class="text-sub text-trade">{{ item.total_trading | toNumber}} / {{item.user_id ? item.conditions_use_old: item.conditions_use | toNumber}} {{ item.currency | uppercase}}</div>
                                </div>
                                <div class="wrap-button">
                                    <button class="button" @click="claimReward(item)" v-if="checkStatus(item)">{{ convertStatus(item) }}</button>
                                    <button class="button" v-else>{{ convertStatus(item) }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="nodata" v-else>
                    <img class="icon-search" :src="require(`@/assets/images/landing/icon-nodata${theme === 'light-theme' ? '' : '-dark'}.svg`)"/>
                    <div class="mess_nodata">{{ $t('common.datatable.data_empty.reward') }}</div>
                </div>
                <Pagination
                    v-if="last_page > 1"
                    :per-page="8"
                    :records="records"
                    :chunk="last_page"
                    @Pagination:page="onPageChange"
                    :pageParent="page"
                />
            </div>
        </div>
    </div>
</template>
<script>
import {mapState} from "vuex";
import moment from "moment";
import AccountTopHeader from '@/components/shared_components/common/AccountTopHeader.vue';
import SelectBox from '@/components/shared_components/common/SelectBox.vue';
import rf from '@/request/RequestFactory'
import Pagination from '@/components/shared_components/common/DataTable/Pagination.vue';

export default {
    name: "RewardCenter",
    data() {
        return {
            voucherType: '',
            status: '',
            listStatus: [
                { id: '', name: this.$t('account.all')},
                { id: 'available', name: this.$t('account.in_progress')},
                { id: 'claim', name: this.$t('account.claim')},
                { id: 'redeemed', name: this.$t('account.received')},
                { id: 'expired', name: this.$t('account.expired')},
            ],
            listVoucherType: [
                { id: '', name: this.$t('account.all')},
                { id: 'spot', name: this.$t('account.spot_reward')},
                { id: 'future', name: this.$t('account.futures_reward')},
            ],
            listVoucher: [],
            listOriginVoucher: [],
            limit: 8,
            last_page: 0,
            records: 0,
            page: 1,
        };
    },
    components: { 
        AccountTopHeader,
        SelectBox,
        Pagination,
    },
    computed: {
        ...mapState({
            theme: (state) => state.userThemeMode,
        }),
    },
    watch: {
        voucherType() {
            this.initData(this.voucherType, this.status, '', this.limit, this.page);
        },
        status() {
            this.initData(this.voucherType, this.status, '', this.limit, this.page);
        },
    },
    async mounted() {
        this.initData();
    },
    methods: {
        onPageChange(page) {
            this.page = page;
            this.initData(this.voucherType, this.status, '', this.limit, this.page);
        },
        async initData(type, status, expires_date, limit, page) {
            const res = await rf.getRequest('UserRequest').getListVoucher(type, status, expires_date, limit, page);
            const data = res.data.data;

            if(typeof data.data === 'object') {
                data.data = Object.values(data.data);
            }

            this.listVoucher = data.data.map(item => {
                const num = item.user_id ? item.conditions_use_old : item.conditions_use;
                return { 
                    ...item,
                    total_trading: Number(item.total_trading) <= Number(num) ? item.total_trading : num,
                }
            })
            this.listOriginVoucher = this.listVoucher;
            this.last_page = data.last_page;
            this.records = data.total;
        },
        calculateWidth(total_trading, conditions_use_old) {
            return `${total_trading * 100 / conditions_use_old}%`;
        },
        claimReward(item) {
            const now = Date.now();
            const timeItem = Date.parse(this.getDate(item.expires_date));
            if(!(timeItem && now < timeItem && item.status_use === 'available' && item.user_id)) {
                Message.error(this.$t('account.claim_fail'));
                return;
            }
            const claimType = item.type === 'spot' ? 'claimVoucher' : 'claimVoucherFuture'
            rf.getRequest('UserRequest')[claimType]({ voucher_id: item.id })
                .then(res => {
                    Message.success(this.$t('account.successfully_claimed'));
                })
                .catch(err => {
                    Message.error(this.$t('account.claim_fail'));
                })
                .finally(() => {
                    this.initData(this.voucherType, this.status, '', this.limit, this.page);
                })
                
        },
        convertStatus(item) {
            const now = Date.now();
            const timeItem = Date.parse(this.getDate(item.expires_date));
            if(item.status_use === 'redeemed') return this.$t('account.received');
            if(( timeItem && now > timeItem) || item.status_use === 'expired') return this.$t('account.expired');
            if(item.status_use === 'available') return item.user_id ? this.$t('account.claim') : this.$t('account.in_progress');
            return '';
        },
        checkStatus(item) {
            const now = Date.now();
            const timeItem = Date.parse(this.getDate(item.expires_date));
            return timeItem && now < timeItem && item.status_use === 'available' && item.user_id;
        },
        getDate(date) {
          if(!date) return
            const timeZone = -(new Date().getTimezoneOffset() / 60);
            const dateTime = new Date(date);
            dateTime.setHours(dateTime.getHours() + timeZone);
            const item = dateTime.toString().split(' ').find(item => item.includes('+') || item.includes('-'));
            const time = moment(dateTime).format('YYYY-MM-DD HH:mm:ss');
            return `${time} (${item?.slice(0, -2)})`
        }
    },
};
  </script>
<style lang="scss" scoped>
#account_reward {
    margin-bottom: 100px;
    .text-main {
        font-size: 18px;
        line-height: 22px;
        color: var(--dark-1);
        width: 100%;
      span {
        border-radius: 4px;
        float: right;
        text-transform: capitalize;
        padding: 4px 12px;
        color: var(--text-spot);
        background: var(--background-spot2);
        font-size: $font-medium-bigger;
      }
    }
    .text-sub {
        font-size: 14px;
        line-height: 16px;
    }
    .content_reward {
        padding: 40px;
        .choose-type {
            display: flex;
            gap: 16px;
            .choose-type_text {
                margin-bottom: 8px;
            }
            .voucher-type {
                width: 295px;
            }
        }
        .status {
            width: 295px;
        }

    }
    .main-reward {
        margin-top: 40px;
        .list-reward {
            display: flex;
            gap: 25px 60px;
            flex-wrap: wrap;
            .item {
                overflow: hidden;
                width: 600px;
                max-width: calc(100vw - 16px);
                height: fit-content;
                .content-item {
                    display: flex;
                    border: 2px solid var(--color-input);
                    .content-money {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        background: var(--color-input);
                        gap: 4px;
                        min-width: 174px;
                        position: relative;
                        .value-money {
                            color: var(--text-desc);
                            font-size: 34px;
                            line-height: 41px;
                        }
                        .coin {
                            color: var(--text-desc);
                            font-size: 18px;
                            line-height: 22px;
                        }

                        .icon1 {
                            position: absolute;
                            top: 10px;
                            left: 10px;
                        }
                        .icon2 {
                            position: absolute;
                            bottom: 10px;
                            right: 10px;
                        }
                        .circle {
                            position: absolute;
                            width: 24px;
                            height: 24px;
                            background: var(--background-color-primary);
                            border: 2px solid var(--color-input);
                            border-radius: 50%;
                            right: -12px;
                            &.circle1 {
                                top: -12px;
                            }
                            &.circle2 {
                                bottom: -12px;
                            }
                        }
                    }
                    .content-value {
                        flex: 1;
                        padding: 12px 20px 16.5px;
                        .text-date {
                            display: none;
                            margin-top: 5px;
                            color: var(--dark-1);
                        }
                        .length-value {
                            margin-top: 33px;
                            border-radius: 4px;
                            height: 8px;
                            background: var(--color-dark-5);
                            overflow: hidden;
                            .length-main {
                                border-radius: 4px;
                                height: 8px;
                                background: var(--text-desc);
                            }
                        }
                        .trade-volume {
                            margin-top: 8px;
                            display: flex;
                            justify-content: space-between;
                            .text-trade {
                                color: var(--color-tab-wallet);
                            }
                        }
                        .wrap-button {
                            display: flex;
                            justify-content: flex-end;
                            margin-top: 15px;
                            .button {
                                min-width: 100px;
                                @include btn-common(no);
                                background: var(--background-progress) !important;
                                color: var(--text-progress)  !important;
                                pointer-events: none;
                                padding: 5.5px 10px 7px;
                                border-radius: 4px;
                                font-size: 14px;
                                line-height: 17px;
                                color: $color-gray-dark;
                            }
                        }
                    }
                }
            }
            .item.active .content-item  {
                border: 2px solid $color-caribbean-green;
                .content-money {
                    height: 160px;
                    background: $color-caribbean-green;
                    .value-money, .coin {
                        color: $dark-1;
                    }
                    .circle {
                        border: 2px solid $color-caribbean-green;
                    }
                }
                .content-value {
                    .text-date {
                        display: block;
                        color: var(--text-desc);
                    }
                    .text-main {
                      span{
                        background: $color-caribbean-green;
                        color: $dark-1;
                      }
                    }
                    .length-value  {
                        margin-top: 7px !important;
                        .length-main {
                            background: $color-caribbean-green;
                        }
                    }
                    .trade-volume {
                        .text-trade {
                            color: var(--text-primary-color);
                        }
                    }
                    .wrap-button .button {
                        @include btn-common(yes);
                        pointer-events: unset;
                        background: var();
                        padding: 5.5px 10px 7px;
                        border-radius: 4px;
                        font-size: 14px;
                        line-height: 17px;
                        background: $color-caribbean-green !important;
                        color: $dark-1 !important;
                    }
                }
            }
            .item.inprogress .content-item  {
                border: 2px solid var(--color-border-ticket-reward-center-inprogress);
                .content-money {
                    height: 160px;
                    background: var(--color-ticket-reward-center-inprogress);
                    .value-money, .coin {
                        color: var(--color-text-ticket-reward-center-inprogress);
                    }
                    .circle {
                        border: 2px solid var(--color-border-ticket-reward-center-inprogress);
                    }
                }

                .content-value {
                    .text-main span {
                        color: var(--text-spot-inprogress);
                        background: var(--background-spot2-inprogress);
                    }
                    .length-value .length-main {
                        background: var(--text-progress);
                    }
                    .trade-volume {
                        .text-trade {
                            color: var(--dark-1);
                        }
                    }
                }

            }
        }
        .nodata {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            min-height: 430px;
            .mess_nodata {
                margin-top: 16px;
                font-size: 18px;
                line-height: 22px;
                color : var(--color-image-nodata);
            }
        }
    }
}
@media screen and (max-width: 992px) {
#account_reward {
    margin-bottom: 20px;
    .text-main {
        font-size: 14px;
        line-height: 17px;

        span {
            font-size: 10px;
            line-height: 16px;
        }
    }
    .text-sub {
        font-size: 9px;
        line-height: 10px;
    }
    .content_reward {
        padding: 40px 16px;
    }
    .main-reward {
        margin-top: 30px;
        .list-reward {
            gap: 15px;
            .item {
                .content-item {
                  min-height: 106px ;
                  max-height: 100%;
                    .content-money {
                        min-width: 106px;
                        .value-money {
                            font-size: 20px;
                            line-height: 24px;
                        }
                        .coin {
                            font-size: 14px;
                            line-height: 17px;
                        }
                        .circle {
                            width: 24px;
                            height: 24px;
                            &.circle1 {
                                top: -12px;
                            }
                            &.circle2 {
                                bottom: -12px;
                            }
                        }
                    }
                    .content-value {
                        padding: 7px 14px;
                        .length-value {
                            margin-top: 20px;
                            height: 6px;
                            .length-main {
                                height: 6px;
                            }
                        }
                        .wrap-button {
                            margin-top: 5px;
                            .button {
                                width: 76px;
                                height: 23px;
                                font-size: 12px;
                                line-height: 16px;
                                padding: 3px 6px 4px !important;
                            }
                        }
                      .trade-volume {
                        margin-top: 5px;
                      }
                    }
                }
            }
            .item.active .content-item  {
                .content-value {
                    .wrap-button .button {
                        font-size: 12px;
                        line-height: 16px;
                    }
                }
            }
        }
        .nodata {
            min-height: 250px;
            .mess_nodata {
                font-size: 14px;
                line-height: 16px;
            }
        }
    }
    :deep(.logo-header) {
      object-position: 50px;
    }
}

}
</style>
  
<style lang="scss">

#account_reward {
    .button_drop_search {
        float: unset;
        height: auto;
        margin: 0;
        background: var(--color-dark-5);
        display: flex;
        justify-content: space-between;
        padding: 14px 15px;
        border-radius: 8px;
        i {
            float: unset;
        }
    }
    .box_list_search_select {
        width: 100%;
        ul {
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
            background: var(--background-color-modal);

            li {
                padding: 6px 15px;
                
                span {
                    font-size: $font-big;
                    line-height: 19px;
                }
                &:hover {
                    span {
                        color: $color-caribbean-green;
                    }
                }
            }
        }
    }
    .full_name_coin_select{
        color: var(--text-close);
    }
}
</style>