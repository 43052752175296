<template>
<div>
  <withdraw-page ref="withdraw_page" :configs="configs">
    <template slot="title">
      {{ $t("funds.withdrawals.title_page") }}
    </template>
    <template slot="actions">
      <div
        class="withdraw-content"
        style="background-color: #ececec; display: flex; flex-direction: column"
      >
        <div class="form-notice-danger">
          <ul class="first-ul">
            <li>
              {{ $t("funds.withdrawals.important.text2") }}:
              {{ minimumWithdrawFee | formatCurrencyAmount(coinName, "0") }}
              {{ coinName | uppercase }}
            </li>
            <li>{{ $t("funds.withdrawals.important.text3") }}</li>
          </ul>
        </div>
        <div class="form-group form-address-box">
          <label
            >
            {{ $t("funds.withdrawals.withdrawal_address", { coin: coinName && coinName.toUpperCase(),}) }}</label
          >
          <input-block-chain-address
            ref="blockchain_address"
            v-model="withdrawAddress"
            :coin="coinName"
            :withdrawAddresses="withdrawAddresses"
            :enableWhiteList="enableWhiteList"
            :selectedCoin="selectedCoin"
            @onIsNoMemo="onIsNoMemo"
            @onAddNewAddress="onAddNewAddress"
            @focus="resetErrors"
          />
        </div>
        <div class="clearfix"></div>

        <div class="form-group amount">
          <label
            >{{ $t("funds.withdrawals.amount", {coin_name : coinName && coinName.toUpperCase()}) }}
          </label>
          <div class="withdrawal_limit_24">
            <span>{{ $t("funds.withdrawals.withdrawal_limit_24") }}</span>
            <span class="txt-bold">
              {{ ' ' }}
              <template v-if="withdrawDaily.toString().includes('-')">{{ getNumber(withdrawDaily) }}</template>
              <template v-else>{{ withdrawDaily | formatCurrencyAmount(coinName, "0.000") }}</template>
              /{{ withdrawLimit.daily_limit | formatCurrencyAmount(coinName, "0.000") }}
              {{ coinName | uppercase }}
            </span>
          </div>
          <input-amount
            ref="amount"
            v-model="amount"
            :coin="coinName"
            :withdrawLimit="withdrawLimit"
            :balance="balance"
            :withdrawDaily="withdrawDaily"
            @focus="resetErrors"
          />
        </div>
        <p class="form-group quantity">
          <span class="transaction_fee">
            {{ $t("funds.withdrawals.transaction_fee") }}:
            <span class="txt-bold">{{
              withdrawLimit.fee | formatCurrencyAmount(coinName, "0.000")
            }}</span>
          </span>
          <span class="you_will_get">
            {{ $t("funds.withdrawals.you_will_get") }}: 
            <span class="txt-bold"> {{ Number(amount) <= 0 ? '0.00' : Number(amount).toLocaleString()}}</span>
          </span>
        </p>
        <div class="form-group wrapperBtn">
          <template v-if="isSubmitting">
            <!-- <div class="container-btn"> -->
            <button type="submit" class="form-control btn btn-submit">
              {{ $t("funds.withdrawals.loading") }}
            </button>
            <!-- </div> -->
          </template>
          <template v-else>
            <!-- <div class="container-btn"> -->
            <button
              type="submit"
              class="form-control btn btn-submit"
              @click="withdraw()"
              :disabled="!amount || !withdrawAddress.blockchain_address || 
              (selectedCoin?.networks?.length > 0 && !selectedCoin?.chain) ||
              ((coinName === 'xrp' || coinName === 'eos' || coinName === 'trx') && !withdrawAddress.blockchain_sub_address )"
            >
              {{ $t("funds.withdrawals.submit") }}
            </button>
            <!-- </div> -->
          </template>
        </div>
      </div>
      <div class="form-notice note">
        <p class="txt-medium">{{ $t("funds.withdrawals.tips.text1") }}</p>
        <ul class="second-ul">
          <li v-html="$t('funds.withdrawals.tips.text2')"></li>
          <li v-html="$t('funds.withdrawals.tips.text3')"></li>
        </ul>
      </div>
      <!-- <otp-modal
        class="otp-form"
        ref="otp_modal"
        :submit="createTransaction"
        :params="getParams()"
        :is-disable-button="isDisableButton"
        :contact_email="siteSetting.contact_email"
      /> -->
      <safety-verification-modal
        class="otp-form"
        ref="otp_modal"
        :submit="createTransaction"
        :params="getParams()"
        :is-disable-button="isDisableButton"
        :contact_email="siteSetting.contact_email"
      />

      <div class="kyc-warning-modal">
        <transition name="modal" v-if="isNotPassKYC">
          <div class="modal-mask">
            <div class="modal-wrapper">
              <div class="modal-container">
                <div slot="body" class="kyc-modal">
                <img src="@/assets/images/note_icon.svg"/>
                <div class="kyc-modal__title">{{ $t("text_note")}}</div>
                <div class="kyc-modal__content">{{ $t("note_kyc")}}</div>
                <router-link to="/account/dashboard">
                  <button>{{ $t("verify_button")}}</button> 
                </router-link>
              </div>
              </div>
            </div>
          </div>
      </transition>
    </div>
    <withdraw-confirm-modal :selectedCoin="selectedCoin" :params="getParams()" />
      <edit-phone-number-modal
        type="verify"
        ref="modalEditPhoneNumber"
        :token="token"
      ></edit-phone-number-modal>
      <modal-withdraw-success></modal-withdraw-success>
    </template>
  </withdraw-page>
</div>
  
</template>

<script>
import rf from "@/request/RequestFactory";
import BigNumber from "bignumber.js";
import BaseDepositWithdraw from "./BaseDepositWithdraw";
import InputAmount from "@/components/shared_components/funds/common/InputAmount";
import InputBlockChainAddress from "@/components/shared_components/funds/withdraw/InputBlockChainAddress";
import OtpModal from "@/components/shared_components/funds/common/OtpModal";
import WithdrawConfirmModal from '@/pages/common/WithdrawConfirmModal.vue';
import Modal from "@/components/shared_components/common/Modal";
import { mapState } from "vuex";
import EditPhoneNumberModal from "@/pages/account/modal/EditPhoneNumberModal.vue";
import ModalWithdrawSuccess from "@/pages/account/modal/ModalWithdrawSuccess.vue";
import SafetyVerificationModal from "@/pages/account/modal/SafetyVerificationModal.vue";


export default {
  components: {
    OtpModal,
    InputAmount,
    InputBlockChainAddress,
    WithdrawPage: BaseDepositWithdraw,
    Modal,
    EditPhoneNumberModal,
    WithdrawConfirmModal,
    SafetyVerificationModal,
    ModalWithdrawSuccess
  },
  props: {
      coin: { type: String, default: '' },
  },
  data() {
    return {
      configs: {
        type: "withdraw",
        is_show_title: true,
        msgEmptyData: window.i18n.t("funds.withdrawals.no_data"),
      },
      amount: "",
      balance: {},
      selectedCoin: {},
      withdrawLimit: {},
      withdrawDaily: "0",
      withdrawAddress: {},
      securitySetting: {},
      withdrawAddresses: [],
      isSubmitting: false,
      enableWhiteList: false,
      isDisableButton: false,
      siteSetting: {},
      isNoMemo: false,
      isAddNewAddress: false,
      minimumWithdrawFee: 0,
      isNotPassKYC: false,
      kyc: null,
      token: "",
    };
  },
  computed: {
    ...mapState({
      masterdata: (state) => state.masterdata,
    }),
    coinName() {
      if (_.isEmpty(this.selectedCoin.coinName)) {
        return "";
      }
      return this.selectedCoin.coinName;
    },
  },
  methods: {
    resetErrors() {
      if (this.errors.any()) {
        this.errors.clear();
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    onIsNoMemo(isNoMemo) {
      this.isNoMemo = isNoMemo;
    },
    onAddNewAddress(isAddNewAddress) {
      this.isAddNewAddress = isAddNewAddress;
    },
    getSecuritySettings() {
      rf.getRequest("UserRequest")
        .getSecuritySettings()
        .then((res) => {
          this.securitySetting = res.data;
        });
    },

    getSocketEventHandlers() {
      return {
        OtpUpdated: this.onOtpUpdated,
      };
    },

    async validate() {
      this.errors.clear();
      if (
        new BigNumber(`${this.withdrawLimit.daily_limit || 0}`).comparedTo(
          0
        ) === 0
      ) {
        this.errors.add(
          "amount",
          window.i18n.t("funds.withdrawals.errors.not_allowed_withdraw")
        );
      }
      this.$refs.amount.validate();
      if(Number(this.amount) <= 0){
        this.errors.add(
          "amount",
          window.i18n.t("invaid_amount", { coin: this.coinName?.toUpperCase()})
        );
      }
      const res = await this.$refs.blockchain_address.validate();
      if(!res) return false;
      
      if (this.errors.count() > 0) {
        return false;
      }
      return true;
    },

    async withdraw() {
      await this.getSettingWithdrawLimit(false);
      if (!await this.validate()) {
        return false;
      }
      if (!this.securitySetting.otp_verified ) {
        return Message.error(this.$t('funds.withdrawals.errors.kyc'))
      }
      CommonModal.show('WithdrawConfirmModal');
    },

    onError(errors) {
      this.errors.clear();
      _.forEach(errors, (messages, field) => {
        _.forEach(messages, (message) => {
          // this.errors.add(field, message))
          this.errors.add(field, this.$t(`${message}`));
        });
      });
    },

    getParams() {
      let params = {
        currency: this.coinName,
        amount: `-${this.amount || "0"}`,
        blockchain_address: this.withdrawAddress.blockchain_address,
        is_no_memo: this.isNoMemo,
        is_new_address: this.isAddNewAddress,
        fee: this.withdrawLimit.fee || '0',
        daily_limit: this.withdrawLimit?.daily_limit || "0",
        minium_withdrawal : this.withdrawLimit?.minium_withdrawal || "0",
        limit : this.withdrawLimit?.limit || "0",
        network: this.selectedCoin?.chain || null
      };
      if (!_.isEmpty(this.withdrawAddress.wallet_name)) {
        params.wallet_name = this.withdrawAddress.wallet_name;
      }
      if (this.coinName === "xrp" || this.coinName === "eos" || this.coinName === "trx") {
        params.blockchain_sub_address =
          this.withdrawAddress.blockchain_sub_address;
      }
      return params;
    },

    createTransaction(params) {
      this.isDisableButton = true;
      this.isSubmitting = true;
      rf.getRequest("TransactionRequest")
        .withdraw(params)
        .then((res) => {
          this.onCreateTransaction(res.data);
          this.$broadcast("TransactionCreated");
          this.isDisableButton = false;
        })
        .catch((error) => {
          this.isDisableButton = false;
          this.isSubmitting = false;

          if (
            error.response.data.message.includes(
              "Please enable Google Authentication"
            )
          ) {
            return Message.error(window.i18n.t("funds.withdrawals.errors.google"));
          }

          if (error.response.status === 422) {
            this.onError(error.response.data.errors || []);
            if (
              Object.keys(error.response.data.errors).length != 1 ||
              !error.response.data.errors.otp
            ) {
              CommonModal.hide("SafetyVerificationModal");
            }
          } else {
            if(error.response.data.message === "min_you_get"){
              Message.error(
              window.i18n.t("funds.withdrawals.error_message.min_you_get", {
                min: this.withdrawLimit.minium_withdrawal || 0,
                coin: this.coinName?.toUpperCase()
              })
            );
            }
            else {
               Message.error(
              window.i18n.t(
                `funds.withdrawals.error_message.${error.response.data.message}`
              )
            );
            }
          }
        });
    },

    onCreateTransaction(data) {
      this.token = data.transaction_id;
      this.isSubmitting = false;
      this.withdrawAddress = {};
      this.amount = "";
      this.getWithdrawalAddresses();
      this.getWithdrawDaily(this.coinName);
      CommonModal.hide("SafetyVerificationModal");
      // CommonModal.hide("OtpModal");
      // Message.success(window.i18n.t("funds.withdrawals.success_message"));
      CommonModal.show("ModalWithdrawSuccess");
    },

    getWithdrawalAddresses() {
      rf.getRequest("UserRequest")
        .getWithdrawalAddresses({ coin: this.coinName })
        .then((res) => {
          this.withdrawAddresses = res.data;
        });
    },

    getWithdrawDaily(coin) {
      rf.getRequest("UserRequest")
        .getWithdrawDaily(coin)
        .then((res) => {
          this.withdrawDaily = `${res.data || 0}`;
        });
    },

    init() {
      this.errors.clear();
      this.getWithdrawalAddresses();
      this.getSecuritySettings();
      this.getSettingWithdrawLimit();
      this.getSettingEnableWhiteList();
      this.handleMasterData();
    },

    getSettingEnableWhiteList() {
      rf.getRequest("UserRequest")
        .getUserSettings()
        .then((res) => {
          const row =
            _.find(res.data, (row) => {
              return row.key === "whitelist";
            }) || {};
          this.enableWhiteList = !!parseInt(row.value);
        });
    },

    getSettingWithdrawLimit(useCache = true) {
      rf.getRequest("UserRequest")
        .getCurrentUser(useCache)
        .then((res) => {
           if(!res.data.security_setting.identity_verified){
              this.isNotPassKYC = true
          }
          const securityLevel = res.data.security_level;
          let withdrawLimit = {};

          withdrawLimit =
            window._.find(this.masterdata.withdrawal_limits, (wl) => {
              return (
                wl.security_level === securityLevel &&
                wl.currency === this.coinName
              );
            }) || {};
          this.withdrawLimit = withdrawLimit;
          this.minimumWithdrawFee = new BigNumber(
            `${this.withdrawLimit.minium_withdrawal || 0}`
          ).plus(`${this.withdrawLimit.fee || 0}`);
        });
    },

    onOtpUpdated() {
      this.getSecuritySettings();
      this.getSettingWithdrawLimit(false);
    },

    handleMasterData() {
      window._.each(this.masterdata.settings, (item) => {
        this.siteSetting[item.key] = item.value;
      });
    },
    getNumber(number) {
      const num = number.toString().split('e-')[1];
      return num ? Number(number).toFixed(num) : number;
    },
  },

  mounted() {
    this.scrollToTop();
    this.$refs.withdraw_page.$on("CHANGE_SELECTED_COIN", (selectedCoin) => {
      this.selectedCoin = selectedCoin;
      this.$router.replace({ query: { coin: this.selectedCoin.coinName} })
      this.init();
    });
    this.$refs.withdraw_page.$on("BALANCE_UPDATED", (balance) => {
      this.balance = balance;
      this.getWithdrawDaily(this.coinName);
    });
    document.title = this.$t("menu.withdrawals") + ` – ${this.tabTitle}`;

    this.$on("UPDATED_LOCALE", () => {
      this.configs.msgEmptyData = window.i18n.t("funds.withdrawals.no_data");
    });
  },
};
</script>
<style lang="scss">
.otp-form {
  .modal-footer {
    padding: 0px !important;
  }
}
</style>
<style lang="scss" scoped>
@import "@/assets/sass/variables";
.form-address-box {
  margin-bottom: 0 !important;
  label {
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 18px;
    color: $color-grey-dark;
  }
}
.f-left {
  float: left;
  color: var(--color-text-title);
  font-family: $font-inter-regular;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 6px;
  margin-right: 4px;
  .txt-bold {
    font-weight: 500;
    color: var(--text-primary-color);
    font-size: 14px;
  }
}
.kyc-warning-modal {
  .modal-mask {
      position: fixed;
      z-index: 9998;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      display: block;
      transition: opacity .3s ease;
      overflow: hidden;
    }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .modal-container {
    width: 560px;
    margin: 0px auto;
    padding: 50px;
    background-color: var(--background-color-modal);
    border-radius: 8px;
    transition: all .3s ease;
    .modal-body {
      padding: 0;
      .icon {
        display: block;
        margin: auto;
      }
      .content {
        text-align: center;
      }
    }

    @media screen and (max-width: 600px) { 
     width: 350px;
    }
  }
.kyc-modal {
  text-align: center;
  margin-top: -18px;

  &__title {
    font-size: 30px;
    font-weight: 600;
    color: var(--color-black_2);
    margin-top: 30px;
    margin-bottom: 10px;
  }

  &__content {
    color: var(--color-text-title);
    font-size: 16px;
    font-weight: 400;
  }

  button {
    background: #00DDB3;
    text-align: center;
    padding: 14px;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    color: #333;
    border-radius: 8px;
    margin-top: 20px;
    width: 100%;

    &:hover {
      opacity: 0.8;
    }
  }

  @media screen and (max-width: 870px) { 
    
    &__title {
      margin-top: 20px;
      font-size: 24px;
  }
  }
}
}

.form-group.quantity {
  display: flex;
  justify-content: space-between;
  .transaction_fee, .you_will_get {
    color: var(--color-text-title);
    font-size: 14px;
    line-height: 17px;
  }
}

.withdrawal_limit_24 {
  text-align: right;
  gap: 10px;
  color: var(--color-text-title);
  font-family: $font-inter-regular;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 0px;
  margin-bottom: 6px;
  .txt-bold {
    font-family: $font-inter-bold;
    color: var(--text-primary-color);
    font-size: 14px;
    margin: 0;
  }
}

.withdraw-content {
  // padding: 0 30px;
  border-radius: 8px;
  background-color: var(--color-label) !important;
  .form-important {
    height: 80px;
    width: 100%;
    color: $color-red;
    padding: 15px 12px;
    margin-bottom: 12px;
    ul {
      padding-left: 25px;
      list-style: none;
      li {
        font-size: $font-smaller;
      }
    }
  }
  .form-group {
    margin-bottom: 0px;
    label {
      font-weight: 400;
      font-size: 18px;
      color: var(--text-primary-color);
      margin-bottom: 8px;
    }
  }
}

.txt-bold {
  color: var(--dark-1);
  font-family: $font-inter-bold;
  line-height: 21px;
  margin-bottom: 5px;
  font-weight: 400;
}
.form-group {
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 10px;
  .form-control {
    width: 255px;
    background: $color-jungle-green;
    font-size: $font-big;
    color: $color-white;
    font-weight: 500;
    height: 40px;
    border-radius: 3px;
    margin-bottom: 30px;
    outline: none;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    &:hover,
    &:active,
    &:focus {
      background: $color-blue-custom;
      border-color: $color-blue-custom;
      color: $color-white;
      -webkit-transition: 0.5s;
      transition: 0.5s;
    }
    &.btn-submit {
      width: 100%;
      background: $color-caribbean-green;
      border-radius: 8px;
      color: $dark-1;
      border: none;
      height: 50px;
      padding: 14.5px 0;
      &:hover {
        background: $color-aquamarine;
      }
      &:disabled {
        background: $color-aero-blue;
      }
    }
  }
  @media screen and (max-width: 768px){
    margin-top: 4px;
  }
}

.form-notice {
  padding-top: 20px;
  height: auto;
  .txt-medium {
    color: var(--color-text-title);
    font-size: 14px;
    line-height: 17px;
  }
  .first-ul {
    list-style: disc;
    color: $color-alizarin-crimson;
    li {
      font-size: $font-smaller;
    }
  }
  .second-ul {
    li {
      font-size: $font-medium-bigger !important;
      line-height: 21px;
      font-weight: 400;
      color: var(--text-primary-color);
      margin: 0;
      margin-bottom: 15px;
      &.txt-bold {
        margin-bottom: 15px;
      }
      // &::before {
      //   content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
      //   font-weight: bold; /* If you want it to be bold */
      //   display: inline-block; /* Needed to add space between the bullet and the text */
      //   width: 1em; /* Also needed for space (tweak if needed) */
      //   margin-left: -1em; /* Also needed for space (tweak if needed) */
      // }
    }
  }
}
.form-notice-danger {
  padding: 20px 20px;
  height: auto;
  .first-ul {
    list-style: disc;
    color: $text-color-red;
    padding: 0 0 0 20px;
    li {
      font-size: 16px;
    }
  }
  .second-ul {
    li {
      font-size: $font-smaller !important;
      line-height: 1.67;
      color: $color-grey-dark;
      margin: 0;
      list-style: none;
      &.txt-bold {
        margin-bottom: 15px;
      }
      &::before {
        content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
        font-weight: bold; /* If you want it to be bold */
        display: inline-block; /* Needed to add space between the bullet and the text */
        width: 1em; /* Also needed for space (tweak if needed) */
        margin-left: -1em; /* Also needed for space (tweak if needed) */
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .withdrawal_limit_24 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0;
  }

  .you_will_get {
    display: block;
  }

  .form-group.quantity {
    flex-direction: column;
  }
  .form-notice-danger {
    padding: 20px 16px 10px;
    
    .first-ul {
      li{
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
  .withdraw-content {
    .form-group {
      padding: 16px;
      label {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}
@media screen and (max-width: 870px) {
   .withdrawal_limit_24 {
    flex-direction: unset;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0;
  }
   .withdraw-content {
    .amount {
      padding: 0px 16px 0px 16px;
    }
    .quantity {
      padding-top: 0px;
      margin-top: -4px;
    }
    .wrapperBtn {
      padding-top: 0px;
      button {
        margin-top: 0px;
        margin-bottom: 4px;
      }
    }
  }
  .form-group.quantity {
    margin-top: 3px;
  }
}
</style>
