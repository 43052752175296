<template>
  <div class="transfer_container" :class="{ 'isPagination' : lastPage > 1 } ">
    <div class="group-control">
      <div
        class="drop-down-pair position-relative d-flex justify-content-between align-items-center"
        v-click-outside="clickOutSideTime"
      >
        <span
          class="w-100 h-100 current-pair"
          @click="openDropTime = !openDropTime"
        >
          <span> {{$t('orders.order_history.time')}}</span>
          <span v-if="currentTime.name" class="placeholder-input" :class="{'overflow' : isVi }">{{
            currentTime.name
          }}</span>
        </span>

        <i
          class="icon"
          @click="openDropTime = !openDropTime"
          :class="{
            'icon-arrow2': openDropTime,
            'icon-arrow1': !openDropTime,
          }"
        ></i>
        <div class="position-absolute drop-list" v-show="openDropTime">
          <div class="list-pair-drop">
            <template v-if="timeShow?.length > 0">
              <div
                class="list-pair"
                v-for="pair in timeShow"
                :key="pair.value"
                @click="selectTime(pair)"
              >
                <span :class="{ active: pair.name === currentTime.name }">
                  {{ pair.name }}</span
                >
              </div>
            </template>
          </div>
        </div>
      </div>
      <div
        class="drop-down-pair drop-down position-relative d-flex justify-content-between align-items-center"
        v-click-outside="clickOutSidePair"
      >
        <span
          class="w-100 h-100 current-pair"
          @click="openDropPair = !openDropPair"
        >
          <span>{{$t('address.coin')}}</span>
          <span v-if="currentPair.name" class="placeholder-input">{{
            currentPair.name == 'All' ? $t("order.order_form.all") : currentPair.name
          }}</span>
        </span>
        <span
          class="w-100 h-100 current-pair"
          @click="openDropPair = !openDropPair"
        ></span>
        <i
          class="icon"
          @click="openDropPair = !openDropPair"
          :class="{
            'icon-arrow2': openDropPair,
            'icon-arrow1': !openDropPair,
          }"
        ></i>
        <div
          class="position-absolute drop-list drop-list-pair"
          v-show="openDropPair"
        >
          <div class="position-relative text-search-pair">
            <input
              v-model="pairName"
              class="w-100 h-100"
              type="text"
              @input="searchPair"
              @focus="focusInput = true"
              :placeholder="$t('order.trade_history.search')"
            />
            <img
              class="close-value position-absolute"
              width="19"
              height="19"
              :src="require(`@/assets/images/landing/icon-search${dark}.svg`)"
            />
            <!-- <img
              v-if="focusInput"
              class="close-value position-absolute"
              :src="`/images/icon/icon-close-input${dark}.svg`"
              @click="handleDelete"
            /> -->
          </div>
          <div class="list-pair-drop">
            <template v-if="pairShow?.length > 0">
              <div
                class="list-pair"
                v-for="pair in pairShow"
                :key="pair.value"
                @click="selectPair(pair)"
              >
                <span
                  :class="{
                    active:
                      pair.name.toUpperCase() ===
                      currentPair.name.toUpperCase(),
                  }"
                >
                  <img
                    v-if="pair.name !== $t('order.order_form.all')"
                    :src="`${coinImages[pair.name]}`"
                    alt=""
                    width="30"
                    height="30"
                    class="coin-img"
                  />
                  {{
                    pair.name !== $t("order.order_form.all")
                      ? pair.name.toUpperCase()
                      : pair.name
                  }}</span
                >
              </div>
            </template>
            <div v-else class="list-no-data">{{ $t('common.datatable.no_have_data') }}</div>
          </div>
        </div>
      </div>

      <div  v-if="sizeScreen > 992" class="group-type">
        <div class="content-search search-type side-layout-order">
          <div class="button-side-layout-order">
            <button class="button-reset" @click="handleReset">
              {{ $t("button.reset") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="group-type-mb">
        <div class="content-search search-type side-layout-order">
          <div class="button-side-layout-order">
            <button class="button-reset-mb" @click="handleReset">
              {{ $t("button.reset") }}
            </button>
          </div>
        </div>
    </div>

    <div class="transfer_table">
      <data-table3
        :getData="getDataTransfer"
        :msg-empty-data="$t('funds.transaction_history.no_deposit_data')"
        :limit="6"
        :widthTable="'100%'"
        ref="datatableTransfer"
        @isPagination="isPagination"
      >
        <template slot-scope="props">
          <th class="cl1" data-sort-field="created_at" @click="props.echoclick">
            {{ $t("funds.transfer.date") }}
            <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
            <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
          </th>
          <th class="cl2" data-sort-field="coin" @click="props.echoclick">
            {{ $t("funds.transfer.assets") }}
            <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
            <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
          </th>
          <th class="cl3" data-sort-field="amount" @click="props.echoclick">
            {{ $t("funds.transfer.amount") }}
            <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
            <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
          </th>
          <th class="cl4" data-sort-field="source" @click="props.echoclick">
            {{ $t("funds.transfer.from") }}
            <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
            <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
          </th>
          <th
            class="cl5"
            data-sort-field="destination"
            @click="props.echoclick"
          >
            {{ $t("funds.transfer.to") }}
            <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
            <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
          </th>
        </template>
        <template slot="body" slot-scope="props">
          <tr
            :class="{
              white: (props.index + 1) % 2 !== 0 || props.index === 0,
              white: (props.index + 1) % 2 === 0 && props.index !== 0,
            }"
          >
            <td class="cl1 t-left fs18">
              {{ props.item.created_at | formatMomentDate }}
            </td>
            <td class="cl2 t-left fs18">
              <img
                class="icon-coin"
                :src="`${coinImages[props.item.coin]}`"
                alt=""
                width="30"
                height="30"
              />
              {{ props.item.coin | uppercase }}
            </td>
            <td class="cl3 t-left fs18">{{ props.item.amount
                | formatNumberAndDecimal }}</td>
            <td class="cl4 t-left fs18">
              {{ props.item.source | filterWallet(props.item.coin) }}
            </td>
            <td class="cl4 t-left fs18">
              {{ props.item.destination | filterWallet(props.item.coin) }}
            </td>
          </tr>
        </template>
      </data-table3>
    </div>
  </div>
</template>

<script>
import rf from "@/request/RequestFactory";
import Header from "@/pages/wallet/component/Header.vue";
import {mapState} from "vuex";
import moment from "moment";
import ETHIcon from "@/assets/images/ethereum-eth-logo.png"
import Cookies from 'js-cookie'

export default {
  components: {
    Header,
  },
  data() {
    return {
      coinNames: {},
      coinImages: {},
      openDropPair: false,
      openDropTime: false,
      pairName: "",
      focusInput: false,
      pairShow: [],
      timeShow: [],
      timeName: "",
      currentPair: {
        name: this.$t('order.order_form.all'),
      },
      currentTime: {
        name: this.$t("funds.transfer.past_7"),
      },
      pairs: [],
      filter: {
        dateStart: moment().subtract(6, "days").startOf("day").format("x"),
        dateEnd: moment().add(1, 'day').format("x"),
        coin: "",
      },
      ethicon: ETHIcon,
      sizeScreen: window.innerWidth,
      lastPage: 0,
      isVi: Cookies.get('locale', { domain: process.env.VUE_APP_PUBLIC_URL }) == 'vi'
    };
  },
  computed: {
    ...mapState({
      masterData: (state) => state.masterdata,
      theme: state => state.userThemeMode,
    }),
    dark() {
      return this.theme === "light-theme" ? "" : "-dark";
    },
  },
  watch: {
    "window.innerWidth": {
      handler() {
        this.resizeHandler();
      },
    },
  },
  methods: {
    isPagination(value) {
      this.lastPage = value
    },
    resizeHandler() {
      this.sizeScreen = window.innerWidth;
    },
    onDatatableFinish() {},
    refresh() {
      this.$refs.datatable.refresh();
    },
    getDataTransfer(params) {
      let paramsFilter = {};
      if (this.filter.dateStart.length !== 0) {
        paramsFilter.dateStart = this.filter.dateStart/1000;
      }
      if (this.filter.coin.length !== 0) {
        paramsFilter.coin = this.filter.coin;
      }
      paramsFilter.dateEnd = this.filter.dateEnd/1000
      return rf
        .getRequest("TransactionRequest")
        .getTransferHistory(Object.assign({}, paramsFilter, params));
    },
    clickOutSidePair() {
      this.openDropPair = false;
      this.focusInput = false;
      this.pairShow = this.pairs;
      this.pairName = "";
    },
    clickOutSideTime() {
      this.openDropTime = false;
      this.timeName = "";
    },
    searchPair() {
      const debouncedFunc = _.debounce(() => {
        this.pairShow = this.pairs.filter((item) =>
          item.name.toLowerCase().trim().includes(this.pairName.toLowerCase())
        );
      }, 500);

      return debouncedFunc();
    },
    // handleDelete() {
    //   this.filter.coin = "";
    //   this.pairName = "";
    //   this.currentPair = {
    //     name: "",
    //   };
    //   this.pairShow = this.pairs;
    //   this.$refs.datatableTransfer.refresh();
    // },
    handleReset() {
      // this.lastPage = null;
      this.filter.coin = "";
      this.filter.dateStart = moment().subtract(6, "days").startOf("day").format("x");
      this.currentTime = {
        name: this.$t("funds.transfer.past_7"),
      };
      this.currentPair = {
        name: this.$t("order.order_form.all"),
      };
      this.$refs.datatableTransfer.refresh();
    },
    selectPair(pair) {
      this.currentPair = {
        name:
          pair.name !== this.$t("order.order_form.all")
            ? pair.name.toUpperCase()
            : pair.name,
      };
      this.openDropPair = false;
      this.filter.coin = pair.name === "All" ? "" : pair.name;
      this.$refs.datatableTransfer.refresh();
    },
    selectTime(pair) {
      this.currentTime = {
        name: pair.name,
      };

      switch (pair.value) {
        case 1:
          this.filter.dateStart = moment()
            .subtract(6, "days")
            .startOf("day")
            .format("x");
          break;
        case 2:
          this.filter.dateStart = moment()
            .subtract(1, "months")
            .add("days", 1)
            .startOf("day")
            .format("x");
          break;
        case 3:
          this.filter.dateStart = moment()
            .subtract(3, "months")
            .add("days", 1)
            .startOf("day")
            .format("x");
          break;
      }
      this.openDropTime = false;
      this.$refs.datatableTransfer.refresh();
    },
    handleMasterData() {
      _.forEach(this.masterData.coins, (item, key) => {
        this.coinNames[item.coin] = item.name;
        this.coinImages[item.coin] = item.icon_image;
        this.pairs.push({ name: `${item.coin}`, value: key + 1 });
      });
      this.coinNames["usd"] = window.i18n.t(`currency.usd.fullname`);
      this.coinImages["usd"] = `/images/color_coins/usd.png`;
      this.coinImages["eth"] = this.ethicon
      // this.pairs.push({ name: "usd", value: this.pairs.length + 1 });
      this.pairs = this.pairs.sort((a, b) => {
        const coinA = a.name;
        const coinB = b.name;
        return coinA.localeCompare(coinB);
      });

      this.pairs.unshift({name: this.$t('order.order_form.all'), value: 0 });
      this.pairShow = this.pairs;
      this.timeShow = [
        {
          name: this.$t("funds.transfer.past_7"),
          value: 1,
        },
        {
          name: this.$t("funds.transfer.past_30"),
          value: 2,
        },
        {
          name: this.$t("funds.transfer.past_90"),
          value: 3,
        },
      ];
    },
  },
  mounted() {
    this.handleMasterData();
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/variables";
.cl4 {
  width: 166px;
}
.overflow {
  width: 84px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.icon-coin {
  object-fit: contain;
}
#history {
  .transfer_table {
    margin-bottom: 28px;
  }

  .transfer_container {
    display: grid;
    width: 100%;
    padding: 0;
    max-width: 1340px;
  }
  .group-control {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 32px 0;

    @media screen and (max-width: 570px) {
      padding-bottom: 0;
    }
    
    .drop-down-pair {
      width: 220px;
      height: 49px;
      padding: 12px;
      background: var(--monas-background-dropdown-convert);
      color: var(--text-second-color);
      border-radius: 8px;
      cursor: pointer;
      .current-pair {
        font-size: 18px;
        color: var(--monas-convert-color-tab);
        .placeholder-input {
          position: absolute;
          transform: translateY(-5%);
          right: 36px;
          text-align: right;
          color: var(--text-primary-color);
        }
      }
      .icon-arrow1, .icon-arrow2 {
        color: var(--text-primary-color);
        &:before{
          font-size: 14px;
        }
      }
      .drop-list {
        width: 220px;
        background: var(--background-color-primary);
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        top: 57px;
        left: -25%;
        transform: translateX(25%);
        padding: 15px 20px;
        z-index: 1;

        .list-pair {
          margin-bottom: 12px;
          font-size: 14px;
          line-height: 16px;
          color: var(--color-dropdown);
          &:hover {
            color: $color-caribbean-green;

          }
          span {
            display: flex;
            gap: 8px;
            align-items: center;
          }
          .active {
            color: $color-caribbean-green;
          }
          img {
            //clip-path: circle(47%);
            width: 28px;
            object-fit: contain;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
        .list-pair-drop {
          max-height: 184px;
          overflow-y: auto;
          overflow-x: hidden;
        }
        .list-no-data {
          text-align: center;
          padding: 24px 0;
          color: var(--text-close);
        }

        .text-search-pair {
          width: 130px;
          height: 42px;
          margin-bottom: 16px;

          input {
            background: var(--bg-text-search);
            border-radius: 8px;
            border: none;
            padding: 10px 15px;
            color: var(--text-primary-color);;
            font-size: 18px;
            line-height: 22px;
          }
          ::placeholder {
            color: var(--color-text-search);
          }

          .icon-search {
            left: 15px;
            top: 50%;
            transform: translateY(-50%);
          }
          .close-value {
            top: 50%;
            transform: translateY(-50%);
            right: 15px;
          }
        }
      }
      .drop-list-pair {
        width: 170px;
         height: 268px;
        padding: 15px 20px 0 20px;
      }
    }
    .drop-down {
      width: 170px;
    }
    .group-type {
      display: inline-block;
      margin-right: 20px;
      float: left;
      .sc_search_select {
        height: 30px;
        width: 95px;
        display: inline-block;
        float: left;
      }

      button {
        line-height: 20px;
        height: 49px;
        padding: 6px 16px;
        width: auto;
        min-width: 85px;
      }
    }
    .button-reset {
      margin-left: 25px;
      margin-right: 10px;
      background-color: $color-caribbean-green;
      color: $color-grey-dark;
      box-shadow: none;
      font-size: 18px;
      border: 1px solid $color-caribbean-green;
      border-radius: 8px !important;
      width: 100px !important;
      height: 49px;
      float: left;
      -webkit-transition: 0.5s;
      transition: 0.5s;
      letter-spacing: 0.9px;

      &:hover {
        background: $color-aquamarine;
        border-color: $color-aquamarine;
        color: $color-grey-dark;
        -webkit-transition: 0.5s;
        transition: 0.5s;
      }
      @media screen and (max-width: 570px) {
        display: none;
      }
    }
  }

  .group-type-mb {
      display: inline-block;
      margin-right: 20px;
      float: left;
      .sc_search_select {
        height: 30px;
        width: 95px;
        display: inline-block;
        float: left;
      }

      button {
        line-height: 20px;
        height: 49px;
        padding: 6px 16px;
        width: auto;
        min-width: 85px;
      }
    }

    .button-reset-mb {
      display: none;
      margin: 20px 0;
      background-color: $color-caribbean-green;
      color: $color-grey-dark;
      box-shadow: none;
      font-size: 18px;
      border: 1px solid $color-caribbean-green;
      border-radius: 8px !important;
      width: 100px !important;
      height: 49px;
      float: left;
      -webkit-transition: 0.5s;
      transition: 0.5s;
      letter-spacing: 0.9px;

      &:hover {
        background: $color-aquamarine;
        border-color: $color-aquamarine;
        color: $color-grey-dark;
        -webkit-transition: 0.5s;
        transition: 0.5s;
      }

      @media screen and (max-width: 570px) {
        display: block;
      }
      @media screen and (max-width: 430px) {
        margin-top: 0px;
      }
    }

  .transfer_table {
    .tableContainer {
      table {
        border-top: 0px;
        thead {
          height: auto;
          tr {
            th {
              background: var(--color-tab-data-table);
              font-size: $font-root;
              padding: 15px 0px;
              line-height: 20px;
              text-align: left;
              color: var(--text-close);
              &:nth-child(1) {
                padding-left: 25px;
                border-radius: 8px 0px 0px 8px;
                width: 24.5%;
                min-width: 200px;
              }
              &:nth-child(2) {
                width: 16%;
                min-width: 132px;
                padding-left: 5px;
              }
              &:nth-child(3) {
                width: 20%;
                text-align: center;
                min-width: 150px;
                padding-left: 5px;
              }
              &:nth-child(4) {
                width: 21%;
                text-align: center;
                padding-left: 5px;
                min-width: 150px;
              }
              &:nth-child(5) {
                padding-right: 25px;
                text-align: right;
                padding-left: 5px;
                min-width: 150px;
              }
              &:last-child {
                border-radius: 0px 8px 8px 0px;
                @media screen and (max-width: 768px) {
                  min-width: 170px;
                }
              }
            }
          }
        }
        tbody {
          tr {
            vertical-align: top;
            height: 40px;
            overflow-y: hidden;
            transition-property: height;
            transition-duration: 0.3s, 0.3s;
            transition-timing-function: ease, ease-in;

            &:nth-child(1) {
              width: 24.5%;
            }
            &:nth-child(2) {
              width: 16%;
            }
            &:nth-child(3) {
              width: 20%;
              text-align: right;
            }
            &:nth-child(4) {
              width: 21%;
              text-align: center;
            }
            &:nth-child(4) {
              width: 21%;
              text-align: right;
            }

            &.even {
              background: transparent;
            }
            &:hover {
              background: transparent;
            }
            &.active {
              max-height: 300px;
              transition-property: height;
              transition-duration: 0.3s, 0.3s;
              background: transparent;
              transition-timing-function: ease, ease-in;
              .glyphicon-menu-down {
                transition-duration: 0.5s;
                transform: rotate(180deg);
              }
            }

            &.empty-data {
              background: transparent;
            }

            td {
              font-size: 16px;
              line-height: 19px;
              padding: 24px 0px;
              text-align: left;
              color: var(--text-primary-color);

              &:nth-child(1) {
                padding-left: 25px;
              }
              &:nth-child(2) {
                padding-left: 5px;
                padding-top: 20px;
              }
              &:nth-child(3) {
                text-align: center;
                padding-left: 5px;
              }
              &:nth-child(4) {
                text-align: center;
                padding-left: 5px;
              }
              &:nth-child(5) {
                padding-right: 25px;
                text-align: right;
                padding-left: 5px;
                // position: absolute;
                // right: 0px;
              }
            }
          }
        }
      }
      .text-right {
        .VuePagination {
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
          bottom: 35px;
          position: absolute;
          padding-top: 0;
          @media screen and (max-width: 992px) {
            bottom: 60px;
          }
        }
      }
    }
    @media screen and (max-width: 768px) {
      overflow: auto;
      white-space: nowrap;
    }
  }
  //@media screen and (max-width: 430px) {
  //  .group-control {
  //    flex-direction: column;
  //    align-items: flex-start !important;
  //    .button-reset {
  //      margin-left: 0;
  //    }
  //  }
  //}
}
@media screen and (max-width: 992px) {
  #history {
    .isPagination {
      margin-bottom: 60px;
    }
    .group-control {
      gap: 15px;
      padding-bottom: 15px;
      .drop-down-pair {
        width: 50%;
        height: 50px;
        padding: 7px;
        .current-pair {
          font-size: $font-root;
          display: flex;
          align-items: center;
          .placeholder-input {
            right: 24px;
            transform: translateY(0);
          }
        }
        .icon-arrow1, .icon-arrow2 {
          &:before{
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
