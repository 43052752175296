<template>
  <div id="address">
    <div class="address_right">
      <AccountTopHeader :title="$t('account.security')"/>
      <div class="content_address">
        <div class="top">
          <div class="tit_address">
            {{$t('address.add_address')}}
          </div>
          <div class="group_reported">
            <div 
              class="toggle-switch" 
              :class="enableWhiteList ? 'on' : 'off'" 
              @click="toggleWhiteList"
            >
              <span class="toggle-handle"></span>
            </div>
            <span class="txt_reported">{{enableWhiteList ? $t('address.white_list_on'): $t('address.white_list_off')}}</span>
            <div class="icon_reported">
              <div class="icon-note">
                <img src="/images/icon/icon-info-whitelist.svg" alt="icon">
                <ul class="tiptool" >
                  <li class="t-left">{{$t('address.tooltip1')}}</li>
                  <li class="t-left">{{$t('address.tooltip2')}}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="content_body_address">
          <div class="content_left_address">
              <div class="group">
                <div class="text_common">{{$t('address.select_coin')}}</div>
                <coin-select-box 
                  @focus="resetErrors"
                  :focus="resetErrors"
                  v-model="selectedCoin"
                  :placeholder="$t('funds.action.placeholder_input_coin')"
                  :options="coins" name="coin" :class="{'error': errors.has('coin_address')}"
                />
                <div v-show="errors.has('coin_address')"
                  class="invalid-feedback">{{ errors.first('coin_address') }}
                </div>
              </div>
              <div class="group">
                <div class="text_common">{{$t('address.label')}}</div>
                <input :maxlength="maxLength20" type="text" v-model="params.address_name" class="input"
                  :class="{'error': errors.has('name_address')}" @focus="resetErrors('name_address')"
                  @focusout="clickOut('address_name')"/>
                <div v-show="errors.has('name_address')"
                      class="invalid-feedback">{{ errors.first('name_address') }}</div>
                <p class="labelHint">{{$t('address.label_hint')}}</p>
              </div>
              <div class="group">
                <div class="text_common">{{$t('funds.withdraw_usd.account_info')}}</div>
                <textarea :maxlength="maxLengthInput" v-model="params.wallet_address" class="input" :class="{'error': errors.has('wallet_address')}"
                  rows="4" @focus="resetErrors('wallet_address')" 
                  @focusout="clickOut('wallet_address')"></textarea>
                <span v-show="errors.has('wallet_address')" class="invalid-feedback">{{ errors.first('wallet_address') }}</span>
              </div>
              <div class="group" v-if="coinName === 'xrp' || coinName === 'trx'">
                <input :maxlength="maxLengthInput" type="text" v-model="params.wallet_sub_address" class="input" :placeholder="$t('address.tag')" 
                  :class="{'error': errors.has('wallet_sub_address')}" @focus="resetErrors('wallet_sub_address')" 
                  @focusout="clickOut('wallet_sub_address')">
                <div v-show="errors.has('wallet_sub_address')" class="invalid-feedback">{{ errors.first('wallet_sub_address') }}</div>
              </div>
              <div class="group">
                <div class="checked-group checkbox_address">
                  <div class="checkbox-input">
                    <input
                      type="checkbox"
                      id="checkbox_address_1"
                      v-model="params.white_list"
                    />
                    <label for="checkbox_address_1"></label>
                  </div>
                  <span class="text_address">
                    {{$t('address.add_whitelist')}}
                  </span>
                </div>
              </div>
              <div class="group">
                <button class="btnSubmit_address" v-on:click="onSubmitForm">{{ $t('address.submit') }}</button>
              </div>
          </div><!-- End Form Add Address -->

          <!-- Begin Table Addresss -->
          <div class="content_right_address">
            <list-address ref="listAddress" :enableOtp="enableOtp" :enableWhiteList="enableWhiteList"></list-address>
          </div><!-- End Table Addresss -->
        </div>
      </div>
    </div>
    <otp-modal name="otp_modal_whitelist" :submit="updateModeWhitelist" :params="getParamsUpdateModeWhitelist()" :is-disable-button="isDisableButtonWhiteList" :contact_email="siteSetting.contact_email" />
    <otp-modal name="otp_modal_add_address" :submit="createAddress" :params="getParamsCreateAddress()" :is-disable-button="isDisableButtonAddress" :contact_email="siteSetting.contact_email"/>
  </div>
</template>

<script>
import rf from '@/request/RequestFactory'
import Multiselect from 'vue-multiselect';
import Utils from '@/common/Utils';
import OtpModal from '@/components/shared_components/funds/common/OtpModal';
import CoinSelectBox from '@/components/shared_components/common/CoinSelectBox';
import ListAddress from './ListAddress';
import COMMON_CONST from '@/common/Const';
import {mapState} from 'vuex';
import AccountSiteBar from '@/components/shared_components/common/AccountSiteBar.vue';
import AccountTopHeader from '@/components/shared_components/common/AccountTopHeader.vue';
import ETHIcon from "@/assets/images/ethereum-eth-logo.png"

export default {
    components: {
      Multiselect,
      OtpModal,
      CoinSelectBox,
      ListAddress,
      AccountSiteBar,
      AccountTopHeader
    },
    data() {
      return {
        coins: [],
        selectedCoin: undefined,
        params: this.initDefaultParams(),
        enableWhiteList: undefined,
        settingWhitelist: false,
        showTip: false,
        enableOtp: false,

        listAddress: [],
        maxLengthInput: COMMON_CONST.MAX_LENGTH_INPUT,
        maxLength20: COMMON_CONST.MAX_LENGTH_20,
        isDisableButtonWhiteList: false,
        isDisableButtonAddress: false,
        siteSetting: {},
        coinImages: [],
        sizeScreen: window.innerWidth,
        ethicon: ETHIcon,
      }
    },
    computed: {
      ...mapState({
        masterdata: state => state.masterdata,
        theme: state => state.userThemeMode,
      }),
      coinName() {
        if (_.isEmpty(this.selectedCoin) || _.isEmpty(this.selectedCoin.coinName)) {
          return '';
        }
        return this.selectedCoin.coinName;
      },
    },
    watch: {
      selectedCoin: function () {
        this.resetErrors();
        this.params = this.initDefaultParams();
      },
    },
    methods: {
      clickOut(input) {
        this.params[input] = this.params[input].trim();
      },
      findSelectedCoin() {
        const coinName = this.$route.query.coin || undefined;
        if (coinName) {
          this.selectedCoin = _.find(this.coins, coin => {
            return coin.coinName === coinName;
          });
        }
      },
      resetErrors(name) {
        this.errors.remove(name)
      },
      toggleWhiteList() {
        const message = !this.enableWhiteList
          ? window.i18n.t('address.turn_on_mode_whitelist')
          : window.i18n.t('address.turn_off_mode_whitelist');
        this.updateSettingWhitelist(message);
        this.$nextTick(() => {
          this.enableWhiteList = this.settingWhitelist;
        });
      },


      updateSettingWhitelist(message) {
        let _self = this;
        const icon = this.theme === 'light-theme' ? '/images/icon/icon-question-delete.svg' : '/images/icon/icon-question-delete-dark.svg'
        ConfirmationModal.show({
          type: 'primary',
          title: '',
          icon,
          content: message,
          btnCancelLabel: window.i18n.t('common.action.no'),
          btnConfirmLabel: window.i18n.t('common.action.yes'),
          onConfirm: () => {
            if (this.enableOtp) {
              CommonModal.show('otp_modal_whitelist',{
                close: ()=> {
                  // _self.enableWhiteList = !_self.enableWhiteList ;
                }
              });
            } else {
              const params = this.getParamsUpdateModeWhitelist();
              this.updateModeWhitelist(params);
            }
          },
          onCancel: () => {
            // _self.enableWhiteList = !_self.enableWhiteList ;
            // $(".buton_reported input").prop('checked',!_self.enableWhiteList);
          }
        });
        
      },

      updateModeWhitelist(params) {
        this.isDisableButtonWhiteList = true;
        rf.getRequest('UserRequest').activeWhitelist(params).then(response => {
          CommonModal.hide('otp_modal_whitelist');
          this.settingWhitelist = this.enableWhiteList = response.data.whitelist;
          this.isDisableButtonWhiteList = false;
        }).catch(error => {
          this.isDisableButtonWhiteList = false;
          if (!error.response) {
            Message.error(window.i18n.t('common.message.network_error'));
          } else {
            this.onError(error.response.data.errors || []);
          }
        })
      },

      getParamsUpdateModeWhitelist() {
        return {
          active: !this.settingWhitelist
        }
      },

      onSubmitForm() {
        if (!this.validate()) {
          return false;
        }
        if(this.params.white_list && !this.enableWhiteList) {
          return Message.errorWithdrawalManagement(window.i18n.t('address.turn_on_whitelist'));
        }
        if (this.enableOtp) {
          CommonModal.show('otp_modal_add_address');
        } else {
          const params = this.getParamsCreateAddress();
          this.createAddress(params);
        }
      },
      validate() {
        this.errors.clear();

        if (_.isEmpty(this.coinName)) {
          this.$validator.errors.add({ field: 'coin_address', msg: window.i18n.t('account.identity.errors.required') });
        }
        if (_.isEmpty(this.params.address_name.trim())) {
          this.$validator.errors.add({ field: 'name_address', msg: window.i18n.t('account.identity.errors.required') });
        }
        if (_.size(this.params.address_name) > 20) {
          this.$validator.errors.add({ field: 'name_address', msg: window.i18n.t('address.errors.max_length', {field: 'label', amount: 20}) });
        }
        if (_.isEmpty(this.params.wallet_address.trim())) {
          this.$validator.errors.add({ field: 'wallet_address', msg: window.i18n.t('account.identity.errors.required') });
        }
        if (!Utils.isWalletAddress(this.coinName, this.params.wallet_address, this.params.wallet_sub_address)) {
          this.$validator.errors.add({ field: 'wallet_address', msg: this.$t('address.errors.withdraw_address.invalide')});
        }

        if (_.isEmpty(this.params.wallet_sub_address.trim()) && (this.coinName === 'xrp' || this.coinName === 'eos')) {
          this.$validator.errors.add({ field: 'wallet_sub_address', msg: window.i18n.t('account.identity.errors.required') });
        }

        const listAddress = this.$refs.listAddress.getAddresses() || [];
        const isExistWithdraw = _.find(listAddress, (withdrawAddress) => {
          return withdrawAddress.wallet_address === this.params.blockchain_address
        });
        if (isExistWithdraw) {
          this.$validator.errors.add({ field: 'wallet_address', msg: window.i18n.t('address.errors.exist_withdraw_address') });
        }

        if (this.errors.any()) {
          return false;
        }
        return true;
      },

      getParamsCreateAddress() {
        return {
          coin: this.coinName,
          name: this.params.address_name,
          wallet_address: this.params.wallet_address,
          wallet_sub_address: this.params.wallet_sub_address,
          white_list: this.params.white_list,
        }
      },

      createAddress(params) {
        this.isDisableButtonAddress = true;
        return rf.getRequest('AddressManagerRequest').createANewOne(params).then((res) => {
          this.onCreateAddress();
          this.isDisableButtonAddress = false;
        }).catch(error => {
          this.isDisableButtonAddress = false;
          //TODO: double check AE-490
          // CommonModal.hide('otp_modal_add_address');
          if (!error.response) {
            Message.error(window.i18n.t('common.message.network_error'));
          } else {
            if (error.response.data.errors.wallet_address) {
              this.errors.add('wallet_address', this.$t('validation.user_id_coin_wallet_2'));
               CommonModal.hide('otp_modal_add_address');
            } else this.onError(error.response.data.errors || []);
          }
        })
      },

      onCreateAddress() {
        // Reload list address
        this.$refs.listAddress.refresh();

        this.params = this.initDefaultParams();
        CommonModal.hide('otp_modal_add_address');
        Message.success(window.i18n.t('address.add_success'));
      },

      onError(errors) {
        this.errors.clear();
        if(!errors.otp ) {
          setTimeout(() => {
            CommonModal.hide('otp_modal_add_address');
            _.forEach(errors, (messages, field) => {
              _.forEach(messages, (message) => {
                this.$validator.errors.add({ field , msg: message });
              });
            });
          }, 1000);
        } else {
          this.$validator.errors.add({ field: 'otp', msg: this.$t('validation.correct_otp') });
        }

      },

      initDefaultParams() {
        return {
          address_name: '',
          white_list: false,
          wallet_address: '',
          wallet_sub_address: '',
        };
      },

      getSocketEventHandlers() {
        return {
          OtpUpdated: this.getSettingEnableOTP
        }
      },

      getSettingEnableOTP() {
        rf.getRequest('UserRequest').getSecuritySettings().then(res => {
          this.enableOtp = res.data.otp_verified === 1;
        });
      },

      getUserSettings() {
        rf.getRequest('UserRequest').getUserSettings().then(res => {
          const setting = _.find(res.data, (item) => {
            return item.key === 'whitelist'
          }) || {};
          this.settingWhitelist = this.enableWhiteList = !!parseInt(setting.value);
        });
      },

      getCoinList() {
        let coinCodes = window._.flatMap(this.masterdata.coins_confirmation, function(value) {
          return [value.coin];
        });

        coinCodes = window._.uniq(coinCodes);

        let countCoins = coinCodes.length;

        const coinNames = {};
        const coinImages = {};

        _.forEach(this.masterdata.coins, (item, key) => {
          coinNames[item.coin] = item.name;
          coinImages[item.coin] = item.icon_image;
          coinImages['eth'] = this.ethicon;
        });

        for (let i = 0; i < countCoins; i++) {

          let coinCode = coinCodes[i];
          let coinInfo = window._.findLast(this.masterdata.coins, item => item.coin == coinCode);

          if (!coinInfo || coinCode == 'usd') {
            let coin = window._.findLast(this.masterdata.coin_settings, item => item.coin == coinCode)
            if (!coin) {
              coin = {
                coin: coinCode,
                name: window.i18n.t(`currency.${coinCode}.fullname`)
              };
            }
            if (!coin.name) {
              coin.name = window.i18n.t(`currency.${coinCode}.fullname`);
            }

            coinInfo = coin;
          } else {
              this.coins.push({
                  coinName: coinCode,
                  shortNameCurrency: coinCode.toUpperCase(),
                  fullNameCurrency: coinInfo.name,
                  iconCurrency: coinImages[coinCode]
              });
          }
        }

        this.findSelectedCoin();
      },

      init() {
        this.getSettingEnableOTP();
        this.getUserSettings();
        this.getCoinList();
        this.getMasterData();
      },
      getMasterData() {
        window._.each(this.masterdata.settings, item => {
          this.siteSetting[item.key] = item.value;
        });
      },
      resizeHandler() {
          this.sizeScreen = window.innerWidth;
        },
    },
    mounted() {
      _.forEach(this.masterdata.coins, (item, key) => {
        this.coinImages[item.coin] = item.icon_image;
      });
      this.coinImages['usd'] = `/images/color_coins/usd.png`;
      document.title = this.$t('menu.address') + ` – ${this.tabTitle}`;
      this.init();
      window.addEventListener("resize", this.resizeHandler);
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/sass/variables";
  ::placeholder {
    color: $color-storm-grey;
  }
  label{
    &:after {
      border-bottom: 2px solid #333333 !important;
      border-left: 2px solid #333333 !important;
    }

  }

  #address {
    .address_right {
      flex: 1;
      background-color: var(--background-color-primary-2);
      .content_address {
        max-width: 1320px;
        padding: 36px 0px 36px 43px;
        .top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 40px;
          flex-wrap: wrap;
          gap: 16px;
          .tit_address {
            font-family: $font-inter-bold;
            color: var(--dark-1);
            font-size: 30px;
            line-height: 36px;
          }
        }
        .content_body_address {
          display: flex;
          gap: 77px;
          flex-wrap: wrap;
          .text_common {
            font-family: $font-inter-regular;
            font-size: 18px;
            line-height: 22px;
            color: var(--dark-1);
            margin-bottom: 8px;
          }
          textarea { 
            resize: none;
          }
          .content_left_address {        
            width: 380px;
            .group {
              margin-bottom: 24px;
              .input {
                padding: 14px 15px;
                background: var(--color-dark-5);
                border-radius: 8px;
                font-family: $font-inter-regular;
                font-size: 18px;
                line-height: 21px;
                color: var(--text-primary-color);
                border: none;
                width: 100%;
                &.error {
                  border: 1px solid $text-color-red;
                }
              }
              .invalid-feedback {
                color: $text-color-red;
                font-family: $font-inter-regular;
                font-size: 14px;
                line-height: 17px;
                text-align: left;
                margin-top: 8px;
              }
              .labelHint {
                font-family: $font-inter-regular;
                font-size: 14px;
                line-height: 17px;
                color: var(--dark-1);
                margin-top: 8px;
                margin-bottom: 0;
              }
            }
            .checkbox_address {
              display: flex;
              gap: 12px;
              align-items: center;
              .text_address {
                font-family: $font-inter-regular;
                font-weight: 400;
                font-size: 18px;
                line-height: 24px;
                color: var(--text-desc);
              }
              .checkbox-input {
                width: 21px;
                height: 21px;
                cursor: pointer;
              }
              label {
                border-radius: 2px;
                margin: 0;
                width: 21px;
                height: 21px;

                &:after {
                  width: 12px;
                  height: 8px;
                  left: 4px;
                }
              }
            }
            .btnSubmit_address {
              width: 100%;
              @include btn-common(yes);
            }
          }
          .content_right_address {
            flex: 1;
          }
        }
      }
    }
    .multiselect__tags .multiselect__input {
      border: none !important;
    }
    .multiselect__input {
      height: 35px;
    }
    .button_drop_search {
      background: transparent;
      border-radius: 3px;
      &:hover {
        border-color: $color-green;
        i {
          color: $color-green;
        }
      }
    }
    .toolbarTable{      
      background-color: $color-white;
    }
    .datatable {
      padding: 0px 15px;
      .tableContainer {
        table {
          background-color: $color-white;
          border: 0px;
          thead {
            background-color: $color-white;
            border: 0px;
            tr {
              padding: 0 25px;
              th {
                padding: 15px 0px;
                line-height: 20px;
                height: 50px;
                font-size: $font-root;
                color: $color-grey;
              }
            }
          }
          tbody {
            tr {
              width: 100%;
              height: auto;
              padding: 0 25px;
              td{
                text-align: left;
                font-size: $font-root;
                color: $color-grey-dark;
                padding: 10px 0px;
                line-height: 20px;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1700px){
    #address .address_right .content_address {
        padding-right: 33px;
    }
  }
  @media screen and (max-width: 992px) {
    #address {
    .group_reported {
      .txt_reported {
        font-size: 16px;
        line-height: 19px;
      }
    }
    .address_right {
      .content_address {
        padding: 30px 16px 120px;
        .top {
          width: 100%;
          .tit_address {
            font-size: 20px;
            line-height: 24px;
          }
          .group_reported {
            .icon_reported {
              .tiptool {
                //width: 94vw;
                right: 0;
                top: 100%;
                transform: translateX(-100%);
              }
            }
          }
        }
        .content_body_address {
          flex-direction: column;
          gap: 40px;
          .text_common {
            font-size: 14px;
            line-height: 17px;
          }
          textarea { 
            resize: none;
          }
          .content_left_address {
            width: auto;
            .group {
              .input {
                font-size: 14px;
                line-height: 16px;
              }
              .invalid-feedback {
              }
            }
            .checkbox_address {
              .text_address {
                font-size: 14px;
                line-height: 24px;
              }
            }
          }
          .content_right_address {
          }
        }
      }
    }
  }
}
@media screen and (max-width: 576px) {
  #address{
    .address_right {
      .content_address {
        .top {
          flex-direction: column;
          align-items: flex-start;
          .group_reported {
            width: 100%;
            justify-content: flex-start;
            .icon_reported {
              .tiptool {
                left: 0;
                width: 100%;
                min-width: 100%;
                top: 100%;
                transform: translateX(0%);
              }
            }
          }
        }
      }
    }
  }
}
  @media screen and (max-width: 375px)  {
    #address{
      .address_right {
        .content_address {
          .top {
            .group_reported {
              .icon_reported {
                .tiptool {
                  width: 100%;
                  left: 0;
                  top: 100%;
                  transform: translateX(0%);
                }
              }
            }
          }
        }
      }
    }
  }
</style>

<style lang="scss" scoped>
  @import "@/assets/sass/variables";
  #adress {
    height: 100%;
    width: 100%;
    background: transparent;
    margin: auto;
    .content_body_address {
      .content_left_address {
        .bg_left_address {
          .group {
            .text_common {
              font-family: $font-inter-regular;
              font-size: 18px;
              line-height: 22px;
              color: var(--dark-1);
            }
            .btnSubmit_address {
              width: 100%;
              height: 40px;
              border-radius: 3px;
              background-color: $color-jungle-green;
              text-transform: uppercase;
              margin-bottom: 15px;
              font-family: $font-family-roboto;
              font-size: $font-smaller;
              font-weight: bold;
              letter-spacing: 0.9px;
              text-align: center;
              color: $color-white;
              &:hover, &:active, &:focus {
                background-color: $color-blue-custom;
                border-color: $color-blue-custom;
                color: $color-white;
              }
            }
            .checkbox_address {
              line-height: 23px;
              font-size: $font-small;
              font-weight: 400;
              color: $color-grey-dusty;
              margin-top: 10px;
              margin-bottom: 10px;
              input{
                display: none;
              }
              span{
                content: "";
                display: inline-block;
                border: 1px solid $color-grey;
                width: 14px;
                height: 14px;
                top: 2px;
                left: 0px;
                border-radius: 0px;
                margin-right: 8px;
                position: relative;
                background-color: transparent;
              }
              input:checked+span:after {
                opacity: .9;
                content: "";
                position: absolute;
                width: 13px;
                height: 7px;
                background: transparent;
                top: 0px;
                left: 0;
                border: 3px solid $color-jungle-green;
                border-top: none;
                border-right: none;
                transform: rotate(-45deg);
                -webkit-transform: rotate(-45deg);
                -moz-transform: rotate(-45deg);
                -o-transform: rotate(-45deg);
                -ms-transform: rotate(-45deg);
              }
            }
          }
        }
      }
      .content_right_address {
        flex: 1;
      }
    }
  }
  .group_reported {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    gap: 10px;
    .toggle-switch {
      .toggle-handle {
        background : var(--color-toggle);
      }
    }
    .txt_reported {
      color: var(--text-primary-color);
      font-size: 18px;
      line-height: 22px;
    }
    .icon_reported {
    
      .tiptool {
        display: none;
        width: 227px;
        padding: 10px;
        background: var(--color-tooltip);
        border-radius: 8px;
        font-family: $font-inter-regular;
        color: $color-white;
        font-size: 14px;
        line-height: 18px;
        position: absolute;
        top: 0;
        // left: calc(100% + 4px);
        right: 20px;
        transform: translateY(-50%);
        z-index: 1000;
        ul {
          list-style-type: none;
          margin: 0;
          li + li {
            margin-top: 50px;
          }
        }
      }
      &:hover {
        .tiptool {
          display: block;
        }
      }
    }
  }
</style>
