<template>
  <div :class="['transactions', 'base_deposit_withdraw', showMenu() ? '' : 'hidden-menu']" id="id_base_deposit_wd">
    <div class="transactions-content container">
      <div class="title">
        <router-link to="/funds/balances-wallet">
           <img src="@/assets/images/back_icon.svg" v-if="!showMenu()"/>
        </router-link>
       
        <slot name="title" />
      </div>
      <div class="transactions-content">
        <div class="left-content col-12 col-md-12 col-lg-5" v-if="configs.type !== 'transfer'">
           <div class="name-select">{{$t('funds.history.coin')}}</div>
          <div class="group-switch">
            <coin-select-box
              v-model="selectedCoin"
              :placeholder="
                $t('m_funds.withdrawals.select_coin', {
                  action: $t(`funds.action.${configs.type}`),
                })
              "
              :options="coins"
            ></coin-select-box>
          </div>
          <!-- <div class="option-coin">
            <div 
              v-for="(coin, index) in coinsDefault" 
              :class="[selectedCoin?.shortNameCurrency === coin?.shortNameCurrency && 'option-coin-active', 'option-coin-item']" 
              :key="index" 
              @click="selectDefaultCoins(coin)">
                {{ coin?.shortNameCurrency}}
            </div>
          </div> -->
          <div v-show="chainName">
          <div class="chain-name-select" >{{$t('fund.action.chain_name')}}</div>
          <div>
            <div class="chain-name-box d-flex align-items-center" v-if="selectedCoin?.networks.length === 0">
              {{chainName}}
          </div>
          <div
              class="drop-down-chains position-relative d-flex justify-content-between align-items-center"
              v-click-outside="clickOutSideSelectChains"
              @click="openChains = !openChains"
              v-else
          >
            <div class="w-100 current-chain">
              <span v-if="chainSelected" class="current-chain__selected">{{ chainSelected }}</span>
              <span v-else class="placeholder-input">Choose a chain</span>
            </div>

            <img :src="!openChains ? '/images/icon/icon-down.svg' : '/images/icon/icon-up.svg'" alt="icon"  class="icon">
            <div class="position-absolute drop-list-chains" v-show="openChains">
              <div class="list-chains-drop">
                <div
                  class="list-chains"
                  v-for="chain in selectedCoin?.networks"
                  :key="chain"
                  @click="selectChain(chain)"
                  >
                    <span>{{ chain }}</span>
                  </div>
              </div>
            </div>
          </div>
          </div>
          </div>
          <template v-if="isActive">
            <!-- <div class="chain-name">
                <p class="title-chainName"> {{ $t("funds.withdrawals.title_chain_name") }}</p>
                <input readonly value="Erc20"/>
            </div> -->
            <div class="list-balance">
              <div class="item-balance">
                <span class="name-balance">{{
                  $t("funds.withdrawals.total_balance")
                }}</span>
                <span class="number_coin_dps">
                  {{
                    balance.balance
                      | toNumber
                      | formatCurrencyAmount(selectedCoin.coin, "0")
                  }}
                  {{ selectedCoin.shortNameCurrency }}
                </span>
              </div>
              <div class="item-balance">
                <span class="name-balance">{{
                  $t("funds.balances.in_use")
                }}</span>
                <span class="number_coin_dps">
                  {{
                    balance.in_order
                      | toNumber
                      | formatCurrencyAmount(selectedCoin.coin, "0")
                  }}
                  {{ selectedCoin.shortNameCurrency }}
                </span>
              </div>
              <div class="item-balance">
                <span class="name-balance">{{
                  $t("funds.withdrawals.available_balance")
                }}</span>
                <span class="number_coin_dps">
                  {{
                    balance.available_balance
                      | toNumber
                      | formatCurrencyAmount(selectedCoin.coin, "0")
                  }}
                  {{ selectedCoin.shortNameCurrency }}
                </span>
              </div>
            </div>
          </template>
          <template v-if="isActive && isDepositOrWithdraw && !isDisableDeposit">
            <slot name="actions" />
          </template>
          <div
            class="alert alert-danger text-center"
            role="alert"
            v-show="isActive && (!isDepositOrWithdraw || isDisableDeposit)"
          >
            {{ $t("funds.disable_coin_msg") }}
          </div>
        </div>

        <div v-else>
          <TransferForm />
        </div>
        <div class="right-content col-12 col-md-12 col-lg-5" v-if="isDepositOrWithdraw">
          <transaction-history :configs="configs" ref="transaction_history" />
        </div>
      </div>
       <TransferHistory v-if="!isDepositOrWithdraw"/>
    </div>
  </div>
</template>

<script>
import rf from "@/request/RequestFactory";
import TransactionHistory from "@/components/shared_components/funds/TransactionHistory";
import CoinSelectBox from "@/components/shared_components/common/CoinSelectBox";
import TransferHistory from '@/pages/wallet/component/TransferHistory.vue';
import TransferForm from '@/pages/wallet/component/TransferFrom.vue';
import {mapState} from "vuex";
import ETHIcon from "@/assets/images/ethereum-eth-logo.png"
import BigNumber from "bignumber.js";
import Utils from "@/common/Utils";

const DEFAULT_COINS = ['BTC', 'ETH', 'USDT', 'XRP']

export default {
  inject: ["$validator"],
  components: {
    TransactionHistory,
    CoinSelectBox,
    TransferHistory,
    TransferForm
  },
  data() {
    return {
      coins: [],
      balance: {},
      selectedCoin: {},
      withdrawTransactions: [],
      minHeight: 430,
      isDepositOrWithdraw: false,
      isDisableDeposit: false, // Disable by User Withdrawal Setting
      userWidthdrawSettings: [],
      chainName: "",
      ethicon: ETHIcon,
      balanceFutures: [],
      listInstruments: [],
      positionData: [],
      Price24h: [],
      costOpenOrder: {},
      assetsWallet: {},
      openChains: false,
      chainSelected: '',
      coinsDefault: []
    };
  },
  props: ["configs"],
  watch: {
    $route(to, from) {
      this.findSelectedCoin();
    },
    assetsWallet() {
      this.unrealizedPostions()
      if(this.positionData.length === 0 ) {
        this.availableBalanceWithdraw()
      }
    },

    selectedCoin: function (newValue) {
      this.chainSelected = ''
      this.$emit("CHANGE_SELECTED_COIN", this.selectedCoin);
      this.$broadcast(
        "selectedCoinChange",
        newValue && newValue.shortNameCurrency
      );
      if (window._.isEmpty(newValue)) {
        return;
      }
      this.validateDepositWithdraw();
      this.getCurrencyBalance(newValue.coinName);

      this.getUserWithdrawalSetting().then((res) => {
        // Check Enable by User Withdrawal Setting
        if (this.configs.type !== "withdraw") {
          return true;
        }
        let coinName = newValue.coinName;
        let record = window._.find(this.userWidthdrawSettings, (item) => {
          return item.coin == coinName;
        });
        if (!record || record.enable_withdrawal == "enable") {
          this.isDisableDeposit = false;
        } else {
          this.isDisableDeposit = true;
        }
      });

      this.getChainName();
    },

    isDepositOrWithdraw(newState) {
      if (!newState) {
        return;
      }
      this.$emit("CHANGE_SELECTED_COIN", this.selectedCoin);
    },
    positionData(){
      this.unrealizedPostions()
    },
  },
  computed: {
    ...mapState({
      masterdata: (state) => state.masterdata,
    }),
    isActive() {
      return !window._.isEmpty(this.selectedCoin);
    },
  },
  methods: {
    clickOutSideSelectChains() {
      this.openChains = false;
    },

    showMenu() {
      return this.$route.path !== '/funds/transfer'
    },

    selectChain(chain) {
      this.$emit("CHANGE_SELECTED_COIN", {...this.selectedCoin, chain: chain});
      this.chainSelected = chain
    },

    selectDefaultCoins(coin) {
      this.$emit('CHANGE_SELECTED_COIN', coin);
    },

    getChainName() {
      if (this.selectedCoin.coinName) {
        this.chainName = this.masterdata.coins.find(item => item.coin === this.selectedCoin.coinName)?.network;
      }
    },
    updateTransactionSetting(data) {
      const result = data.find(
        (word) => word.coin === this.selectedCoin.coinName
      );

      if (result) {
        if (result[this.getField()]) {
          this.isDepositOrWithdraw = true;
        } else {
          this.isDepositOrWithdraw = false;
        }
      }
    },
    onUserWithdrawalSettingEvent(data) {
      if (this.getField() == "is_deposit") {
        return true;
      }

      let res = JSON.parse(data);
      if (this.selectedCoin.coinName != res.coin) {
        return true;
      }
      if (res.isDelete) {
        this.isDisableDeposit = false;
      } else {
        if (res.enable_withdrawal == "disable") {
          this.isDisableDeposit = true;
        }
      }
    },

    getField() {
      if (this.configs.type === "withdraw") {
        return "is_withdraw";
      }
      if (this.configs.type === "deposit") {
        return "is_deposit";
      }
    },

    findSelectedCoin() {
      const coinName = this.$route.query.coin || undefined;
      if (coinName) {
        this.selectedCoin = _.find(this.coins, (coin) => {
          return coin.coinName === coinName;
        });
      }
    },

    getCurrencyBalance(currency) {
      this.balance = {};
      if(this.balanceFutures.assets){
        const balancesFuture = this.balanceFutures.assets[currency?.toUpperCase()]
      rf.getRequest("BalanceRequest")
        .getBalanceTransactionMain(currency)
        .then((res) => {
          const data = res.data;
          const totalBalance = new BigNumber(data?.balance).plus(balancesFuture?.balance || '0').toString();
          const availableBalance = new BigNumber(data.available_balance)
          this.balance = {
            balance : totalBalance,
            available_balance: availableBalance,
            blockchain_address: data.blockchain_address,
            currency: data.currency,
            id: data.id,
            in_order: new BigNumber(totalBalance).sub(availableBalance).toString(),
            usd_amount: data.usd_amount
          };

          this.$emit("BALANCE_UPDATED", this.balance);
        });
      }
    },

    getCoinList() {
      let coinCodes = window._.flatMap(
        this.masterdata.coins_confirmation,
        function (value) {
          return [value.coin];
        }
      );

      coinCodes = window._.uniq(coinCodes);

      let countCoins = coinCodes?.length;

      const coinNames = {};
      const coinImages = {};

      _.forEach(this.masterdata.coins, (item, key) => {
        coinNames[item.coin] = item.name;
        coinImages[item.coin] = item.icon_image;
        coinImages['eth'] = this.ethicon;
      });
      for (let i = 0; i < countCoins; i++) {
        let coinCode = coinCodes[i];
        if(coinCode !== 'ltc') {
          let coinInfo = window._.findLast(
          this.masterdata.coins,
          (item) => item.coin == coinCode
        );
        if (!coinInfo || coinCode == "usd") {
          let coin = window._.findLast(
            this.masterdata.coin_settings,
            (item) => {
              return item.coin == coinCode;
            }
          );
          if (!coin) {
            coin = {
              coin: coinCode,
              name: window.i18n.t(`currency.${coinCode}.fullname`),
            };
          }
          if (!coin.name) {
            coin.name = window.i18n.t(`currency.${coinCode}.fullname`);
          }
             this.coins.push({
                coinName: coin.coin,
                shortNameCurrency: coin?.currency?.toUpperCase(),
                fullNameCurrency: coin.name,
                iconCurrency: coinImages[coin.coin],
                network: coinInfo?.network,
                networks: coinInfo?.networks
            });

          coinInfo = coin;
        } else {
            this.coins.push({
                coinName: coinCode,
                shortNameCurrency: coinCode?.toUpperCase(),
                fullNameCurrency: coinInfo.name,
                iconCurrency: coinImages[coinCode],
                network: coinInfo?.network,
                networks: coinInfo?.networks
            });
        }
        }
      }
      this.findSelectedCoin();
    },

    getInstruments() {
      rf.getRequest("UserRequest")
          .getInstruments()
          .then((res) => {
            const assetsKeyObject = {};
            res.data.forEach((asset) => {
              return (assetsKeyObject[asset.symbol] = {
                ...asset,
              });
            });
            this.listInstruments = assetsKeyObject;
          });
    },

    unrealizedPostions() {
      this.listAllowcated = []
      let balanceFuture = this.balanceFutures.assets
      for (const item of this.positionData) {
        const multiplier = this.listInstruments[item.symbol].multiplier

        if (item.currentQty != 0) {
          const markPrice = this.markPrice(item.symbol)
          const side = item.currentQty > 0 ? 1 : -1
          const allowcated = Utils.allowcatedMargin(item, multiplier, markPrice)
          const unrealizedPNL = Utils.unrealizedPNL(markPrice, item, side, multiplier)
          const unrealizedObj = {
            asset: item.asset,
            PNL: unrealizedPNL,
          }
          const allowcatedObj = {
            asset: item.asset,
            allowcated: allowcated,
          }
          this.listAllowcated.push(allowcatedObj)
          if (balanceFuture) {
            const isCross = Boolean(item.isCross) ? unrealizedPNL : 0
            balanceFuture[item?.asset].unrealisedPnl = isCross
          }
          this.availableBalanceWithdraw()
        }
      }
    },

    availableBalanceWithdraw() {
      for (let key in this.balanceFutures.assets) {
        const variableBalance = this.balanceFutures.assets[key]
        const costOrder = this.costOpenOrder[key] || 0
        const totalAllowcated = this.allowcatedMarginForAsset(key)
        let availableUSDT = new BigNumber(variableBalance.balance).minus(totalAllowcated.toString()).minus(costOrder.toString()).toString()
        let maximunWithdraw = new BigNumber(availableUSDT)
            .add(variableBalance.unrealisedPnl.toString())
            .toString();
        variableBalance.availableBalance = new BigNumber.min(availableUSDT, maximunWithdraw).toString();
        const availableBalance = new BigNumber(variableBalance.availableBalance.toString());
        variableBalance.availableBalance = BigNumber.max(0, availableBalance).toString();
      }
      this.getCurrencyBalance(this.$route.query.coin)
    },

    allowcatedMarginForAsset(asset) {
      let unrealizedTotal = 0
      const total = this.listAllowcated.reduce((sum, current) => {
        if (current.asset === asset && !isNaN(current.allowcated)) {
          unrealizedTotal = parseFloat(sum) + (current.allowcated * 1)
        }
        return unrealizedTotal
      }, 0);
      return total
    },

    markPrice(symbol) {
      const findSymbol = this.Price24h.filter((item) => {
        return item.symbol === symbol;
      });
      return findSymbol[0]?.oraclePrice || 0
    },
    sumCostOpenOrder(order) {
      let costUSD = 0;
      let costUSDT = 0;
      this.costOpenOrder = {};
      order.forEach((item) => {
        if (item.cost !== null) {
          if (
              item.symbol.includes("USDT") &&
              !item.symbol.includes("USDTUSD")
          ) {
            costUSDT += parseFloat(item.cost);
            this.costOpenOrder.USDT = costUSDT || 0;
          } else if (item.contractType === "COIN_M") {
            let assets = item.symbol.replace(/USDM|USDT|USD/, "");
            if (!this.costOpenOrder[assets]) {
              this.costOpenOrder[assets] = 0;
            }
            this.costOpenOrder[assets] += parseFloat(item.cost);
          } else {
            costUSD += parseFloat(item.cost);
            this.costOpenOrder.USD = costUSD || 0;
          }
        }
      });
    },
    getSumOrderMargin() {
      const data = {
        contractType: "ALL"
      }
      return rf.getRequest("UserRequest").getOpenOrderMargin(data)
          .then(res => {
            this.openOrder = res.data
            this.sumCostOpenOrder(res.data)
          })
    },

    getBalanceFutureAPI() {
      return rf.getRequest("UserRequest")
          .getFuturesBalances()
          .then((res) => {
            const listWithoutUSD = res.data.filter(item => item.asset !== 'USD')
            this.assetsWallet = listWithoutUSD;
            const assetsKeyObject = {};
            listWithoutUSD.forEach((asset) => {
              assetsKeyObject[asset.asset] = {
                balance: asset?.balance,
                marginBalance: 0,
                availableBalance: 0,
                totalAllowcated: 0,
                unrealisedPnl: 0,
                unrealisedROE: "",
              };
            });
            this.balanceFutures = {
              assets: assetsKeyObject,
            };
          });
    },

    async initData() {
      await Promise.all([
        this.getInstruments(),
        this.positionMargin(),
        this.getSumOrderMargin(),
        this.getBalanceFutureAPI()
      ])
    },

    // onBalanceUpdated(newBalance) {
    //   if (!this.selectedCoin || !newBalance || this.selectedCoin.coinName !== newBalance.currency) {
    //     return;
    //   }

    //   this.balance = {...newBalance}
    //   this.$emit("BALANCE_UPDATED", this.balance);
    // },
    onTransactionCreated() {
      this.getCurrencyBalance(this.selectedCoin.coinName);
    },
    validateDepositWithdraw() {
      this.isDepositOrWithdraw = false;
      const setting = window._.filter(
        this.masterdata.coins_confirmation,
        (item) => item.coin === this.selectedCoin.coinName
      );
    
      if (!window._.isEmpty(setting)) {
        this.isDepositOrWithdraw =
          this.configs.type === "withdraw"
            ? setting[0].is_withdraw
            : setting[0].is_deposit;
      }
    },

    getSocketEventHandlers() {
      return {
        // WithdrawDepositBalanceEvent: this.onBalanceUpdated,
        TransactionSettingEvent: this.updateTransactionSetting,
        UserWithdrawalSettingEvent: this.onUserWithdrawalSettingEvent,
        TransactionCreated: this.onTransactionCreated,
      };
    },

    positionMargin() {
      const data = {
        contractType: "ALL",
      };
      return rf
          .getRequest("MarginRequest")
          .getPosition(data)
          .then((res) => {
            this.positionData = res.data;
            return res;
          });
    },
    getPrice24h() {
      return rf.getRequest("PriceRequest")
          .get24hFuture()
          .then((res) => {
            return this.Price24h = res.data
          });
    },

    getUserWithdrawalSetting() {
      return rf
        .getRequest("UserRequest")
        .getUserWithdrawalSetting()
        .then((res) => {
          this.userWidthdrawSettings = res.data;
          return res;
        });
    },
  },

  mounted() {
    this.getCoinList();
    if(this.coins){
      const filteredArray = this.coins.filter(item => DEFAULT_COINS.includes(item.shortNameCurrency));
      this.coinsDefault = filteredArray
    }
    const height = document.body.clientHeight - 373;
    this.minHeight = height > 430 ? height : 430;

    if (this.getField() == "is_withdraw") {
      this.getUserWithdrawalSetting();
    }
    this.getChainName();
  },
  created() {
    this.initData()
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/variables";
.link-green {
  color : $color-caribbean-green;

  &:hover {
    color : $color-caribbean-green;
  }
}

.drop-down-chains {
  width: 100%;
  height: 49px;
  padding: 14px;
  padding: 12.5px 15px;
  background-color: var(--color-tab-data-table);
  border-radius: 8px;
  cursor: pointer;
    @media screen and (max-width: 992px) {
      height: 44px !important;
    }
  .current-chain {
    font-size: 18px;
    color: var(--monas-choose-pair-convert);
    display: flex;
    align-items: center;

    &__selected {
      text-transform: uppercase;
      color: var(--text-primary-color);
    }
    
    .placeholder-input {
      position: absolute;
      color: var(--text-primary-color);
    }
    @media screen and (max-width: 992px) {
      font-size: 14px;
    }
  }
  
  .icon-arrow1 {
    color: var(--monas-choose-pair-convert);
  }
  .drop-list-chains {
    width: 100%;
    background: var(--bgr-dropdown);
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    top: 55px;
    left: 0;
    padding: 20px 0px 20px 20px;
    z-index: 15;

    .list-chains {
      margin-bottom: 16px;
      font-size: 18px;
      color: var(--text-primary-color);
      text-transform: uppercase;

      &:last-child {
        margin-bottom: 0;
      }
      &:hover{
        color: $color-caribbean-green;
      }
    }
    .list-chains-drop {
      max-height: 230px;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}
#id_base_deposit_wd {
  // background: var(--color-background-withdrawls);
  background-size: 100% 400px;
  //max-width: 1340px;
  padding: 36px 0px 36px 40px;
  .multiselect__tags .multiselect__input {
    border: none !important;
  }
  .multiselect__input {
    height: 35px;
  }
  .multiselect__content-wrapper {
    overflow-x: hidden;
  }
  .button_drop_search {
    background: var(--color-bg-select);
    font-size: $font-big;
    font-family: $font-inter-bold;
  }
  @media screen and (max-width: 992px){
    //background: unset;
    padding: 30px 16px;
    .button_drop_search {
      background: var(--color-bg-select);
      font-size: $font-root;
      font-family: $font-inter-regular;
    }
  }
}
</style>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
.is-error {
  color: $color-green;
}

.f-left {
  float: left;
}

.f-right {
  float: right;
}

.txt-blue {
  color: $color-blue-cerulean-light;
}

.txt-bold {
  font-weight: bold;
}

.form-input {
  background: $background-catskill-white;
  border: 1px solid $color-alto;
}

.form-control {
  border-radius: 0;
}

.btn-submit {
  background: $color-blue-cerulean-light;
  color: $color-white;
  margin-top: 5px;
  margin-bottom: 20px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  &:hover,
  &:active,
  &:focus {
    background: $color-corn;
    border-color: $color-corn;
    color: $color-white;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
}

.hidden-menu {
  margin: auto 240px;

  @media screen and (max-width: 992px){
    margin: 0px auto;
  }
}

.transactions {
  min-height: 100%;
  height: auto;
  background: transparent;
  display: flow-root;
  
  .container {
    float: left;
  }
  .transactions-content {
    .title {
      color: var(--text-primary-color);
      font-size: 34px;
      line-height: 41px;
      margin-bottom: 30px;
      padding: 0;
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 20px;

      img {
        margin-bottom: 2px;
      }
    }
    padding: 0;
    .transactions-content {
      justify-content: space-between;
      display: flex;
      padding-right: 47px;
      @media screen and (max-width: 992px){
        padding-right: 0px;
      }
      .left-content {
        max-width: 480px;
        padding: 0;
        .name-select {
          color: var(--text-primary-color);
          font-size: 16px;
          margin-bottom: 10px;
          font-weight: 500;
        }

        .chain-name-select {
          color: var(--text-primary-color);
          font-size: 16px;
          margin-top: 30px;
          margin-bottom: 8px;
          font-family: $font-inter-regular;
        }

        .chain-name-box {
          background: var(--color-input);
          border-radius: 8px;
          height: 49px;
          padding: 14px 15px;
          color: var(--text-primary-color);
          font-size: 18px;
        }

        .option-coin {
          display: flex;
          gap: 8px;
          
          .option-coin-item {
            margin-top: 10px;
            padding: 8px 15px;
            border-radius: 6px;
            color: var(--text-primary-color);
            cursor: pointer;
          }

          .option-coin-active {
            background: var(--color-bg-select);
          }
        }

        .chain-name {
          margin-top: 30px;
          .title-chainName {
            font-weight: 400;
            font-size: $font-big;
            color: var(--text-primary-color);
            margin-bottom: 8px;
          }
          input {
            height: 49px;
            width: 100%;
            padding-left: 15px;
            font-weight: 400;
            font-size: $font-big;
            border-radius: 8px;
            background: var(--color-bg-select);
            border: unset;
            color: var(--text-primary-color);
          }
        }
        .list-balance {
          margin-top: 26px;
          margin-bottom: 40px;
          .item-balance {
            margin-bottom: 10px;
            display: flex;
            justify-content: space-between;
            .name-balance {
              color: var(--text-close);
              font-size: 18px;
              line-height: 22px;
            }
            .number_coin_dps {
              color: var(--dark-1);
              font-weight: 600;
              font-size: 18px;
              line-height: 22px;
            }
          }
        }
        @media screen and (max-width: 768px){
          max-width: unset;
        }
      }
      .right-content {
        width: 54.18%;
        padding: 0px;
        
       
        .name-history {
          color: var(--dark-1);
          font-size: 20px;
          line-height: 24px;
        }
        @media screen and (max-width: 768px){
          width: 100%;
          margin-top: 30px;
           padding: 0 0 70px 0;
          }
      }
    }
     .row::before {
        content: unset;
     }
  }
}

@media screen and (max-width: 992px){
.transactions {
  .transactions-content {
    max-width: 100%;
    flex-direction: column;
    .title {
      font-size: 24px;
      line-height: 29px;
    }

    .transactions-content {
      .left-content {
        max-width: 100%;
        padding: 0 0 0 8px;
        .name-select {
          font-size: 18px;
          line-height: 21px;
        }
        .list-balance {
          .item-balance {
            .name-balance {
              font-size: 14px;
              line-height: 16px;
            }
            .number_coin_dps {
              font-size: 14px;
              line-height: 16px;
            }
          }
        }
      }
      .right-content {
        max-width: 100%;
        width: 100%;
      }
    }
  }
}
}
@media screen and (max-width: 768px){
  .container {
    max-width: unset;
    .left-content {
      padding: 0px 0px 0px 8px !important;
    }
  }
}
</style>
