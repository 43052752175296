<template xmlns="http://www.w3.org/1999/html">
  <div class="contain">
    <header id="header" class="clearfix in_exchangePage"
    :class="{ 'disable-app' : (isReferralApp || statusApp || isContractSeriesApp), 'in-home': isLanding}"  >
      <!--nav -->
      <div :class=" menuActive? 'activeDropdown': '' " ></div>
      <div class="f-lf nav-m clearfix"   :class=" menuActive? 'nav-active': ''">
        <div class="block_content clearfix">
          <!-- right bar -->
          <div
            class=" responsiveTabRight "
            :class=" menuActive? 'active' : ''"
          >
            <button @click="menuActive = !menuActive"  class='menu-button'>
              <img v-if="isLanding" :src="require(`@/assets/images/icon/hamberger_icon.svg`)" class='menu-button' >
              <img v-else :src="require(`@/assets/images/icon/hamberger_icon${userTheme === 'light-theme' ?  '-dark' : ''}.svg`)" class='menu-button' >
            </button>
<!--            <button-->
<!--              @click="menuActive = !menuActive"-->
<!--            >-->
<!--&lt;!&ndash;            <label class='' for="menu-toggle">&ndash;&gt;-->
<!--&lt;!&ndash;              <div id="menu-toggle"&ndash;&gt;-->
<!--&lt;!&ndash;              ></div>&ndash;&gt;-->
<!--              <div class='menu-button'></div>-->
<!--&lt;!&ndash;            </label>&ndash;&gt;-->
<!--            </button>-->
          </div>
          <div class="custom-left-bar">
            <!--logo-->
            <router-link class="tab active img_logo" to="/" tabindex="1">
              <img v-if="isLanding && sizeScreen > 360"  class="logo-monas" src="@/assets/images/monas-white.svg">
              <img v-else-if="sizeScreen < 360  "  class="logo-monas" :src="require(`/public/favicon${userTheme === 'light-theme' && !isLanding ? '' : '-light'}.svg`)" width="33" height="33">
              <img v-else  class="logo-monas" :src="require(`@/assets/images/monas-${userTheme === 'light-theme'? 'dark': 'white'}.svg`)">
            </router-link>
              <div>

              </div>
              <div
                class="list_btn_ex"  v-show=" menuActive  || sizeScreen > 1300" v-click-outside="clickOutside">
                <router-link tabindex="3" class="btn btn-exchange-page"
                             :class="{ active : isSpotExchangePage, 'ft_11' :  $t('i18n.lang') == 'ru' && isAuthenticated ,
                             'ft_14': $t('i18n.lang') == 'vi' }" :to="{name: 'Market Overview'}">{{$t('menu.market')}}</router-link>
                <div tabindex="4"
                     class="bar fl-left lang-bar dropdown-block"
                     @focus="tradeTab = true"
                     @blur="tradeTab = false"
                     @mouseleave="tradeTab = false"
                     @click="tradeTab = !tradeTab"
                >
                  <button v-if="isLanding && sizeScreen > 1300" class="btn btn-lang-head left_bar"
                          @mouseover="tradeTab = mouseOverDetectScreen(tradeTab)"
                  >
                    <span  :class='tradeTab? "active": ""' >{{ $t('menu.trade') }}</span>
                    <img  class="dropdown-active"  :src="require(`@/assets/images/icon/arrow-${tradeTab? 'up-blue': 'down-white'}.svg`)"/>
                  </button>
                  <button v-else class="btn btn-lang-head left_bar"
                          @mouseover="tradeTab = mouseOverDetectScreen(tradeTab)"
                  >
                    <span  :class='tradeTab? "active": ""' >{{ $t('menu.trade') }}</span>
                    <img  class="dropdown-active" v-if="userTheme === 'light-theme'" :src="require(`@/assets/images/icon/arrow-${tradeTab? 'up-blue': 'down-black'}.svg`)"/>
                    <img  class="dropdown-active" v-else :src="require(`@/assets/images/icon/arrow-${tradeTab? 'up-blue': 'down-white'}.svg`)"/>
                  </button>
                  <div class="dropdown-head right-side ">
                    <ul class="tradeTab" v-show="tradeTab">
                      <li>
                        <router-link :to="{name: 'Spot Exchange', query: {coin : 'btc', currency : 'usdt'}}">
                          <img src="@/assets/images/icon/spot.svg" />
                          <span>{{ $t('menu.spot') }}</span>
                          <div class="img-link"/>
                        </router-link>
                      </li>
                      <li>
                        <router-link to="/convert">
                          <img src="@/assets/images/icon/convert.svg" />
                          <span>{{$t('menu.convert')}}</span>
                          <div class="img-link"/>
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div tabindex="5"
                     class="bar fl-left lang-bar dropdown-block"
                     @focus="futureTab = true"
                     @blur="futureTab = false"
                     @click="futureTab = !futureTab"
                     @mouseleave="futureTab = false"
                >
                  <button v-if="isLanding && sizeScreen > 1300"  class="btn btn-lang-head left_bar"
                          @mouseover="futureTab =  mouseOverDetectScreen(futureTab)"
                  >
                    <span :class='futureTab? "active": ""'>{{ $t('landing.title_future') }}</span>
                    <img  class="dropdown-active" :src="require(`@/assets/images/icon/arrow-${futureTab? 'up-blue': 'down-white'}.svg`)"/>
                  </button>
                  <button v-else class="btn btn-lang-head left_bar"
                          @mouseover="futureTab = mouseOverDetectScreen(futureTab)"
                  >
                    <span   :class='futureTab? "active": ""'>{{ $t('landing.title_future') }}</span>
                    <img  class="dropdown-active" v-if="userTheme === 'light-theme'" :src="require(`@/assets/images/icon/arrow-${futureTab? 'up-blue': 'down-black'}.svg`)"/>
                    <img  class="dropdown-active" v-else :src="require(`@/assets/images/icon/arrow-${futureTab? 'up-blue': 'down-white'}.svg`)"/>
                  </button>
                  <div class="dropdown-head right-side ">
                    <ul class="tradeTab" v-show="futureTab">
                      <li>
                        <a :href="SUB_DOMAIN+'/futures/USD_M'" >
                          <img v-if="userTheme === 'light-theme'" src="@/assets/images/icon/logo_usd_m_light.svg" />
                          <img v-else src="@/assets/images/icon/logo_usd_m_dark.svg" />
                          <span>USDⓈ-M</span>
                          <div class="img-link"/>
                        </a>
                      </li>

                      <li>
                        <a :href="SUB_DOMAIN+'/futures/COIN_M'" >
                          <img v-if="userTheme === 'light-theme'" src="@/assets/images/icon/logo_coin_m_light.svg" />
                          <img v-else src="@/assets/images/icon/logo_coin_m_dark.svg" />
                          <span>COIN-M</span>
                          <div class="img-link"/>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- <router-link tabindex="6" class="btn btn-exchange-page" :class="{ active : isSpotExchangePage, 'ft_11' :  $t('i18n.lang') == 'ru' && isAuthenticated ,'ft_14': $t('i18n.lang') == 'vi' }" to="#">{{$t('menu.copy_trade')}}</router-link> -->
                <!--EARM TAB-->
                <!-- <div tabindex="7"
                     class="bar fl-left lang-bar dropdown-block"
                     @focus="earmTab = true"
                     @blur="earmTab = false"
                     @click="earmTab = !earmTab"
                     @mouseleave="earmTab = false"
                >
                  <button v-if="isLanding && sizeScreen > 1300"  class="btn btn-lang-head left_bar"
                          @mouseover="earmTab =  mouseOverDetectScreen(earmTab)"
                  >
                    <span :class='earmTab? "active": ""'>{{ $t('menu.earn') }}</span>
                    <img  class="dropdown-active" :src="require(`@/assets/images/icon/arrow-${earmTab? 'up-blue': 'down-white'}.svg`)"/>
                  </button>
                  <button v-else class="btn btn-lang-head left_bar"
                          @mouseover="earmTab = mouseOverDetectScreen(earmTab)"
                  >
                    <span   :class='earmTab? "active": ""'>{{ $t('menu.earn') }}</span>
                    <img  class="dropdown-active" v-if="userTheme === 'light-theme'" :src="require(`@/assets/images/icon/arrow-${earmTab? 'up-blue': 'down-black'}.svg`)"/>
                    <img  class="dropdown-active" v-else :src="require(`@/assets/images/icon/arrow-${earmTab? 'up-blue': 'down-white'}.svg`)"/>
                  </button>
                  <div class="dropdown-head right-side ">
                    <ul class="tradeTab" v-show="earmTab">
                      <li>
                        <router-link to="#">
                          <img src="@/assets/images/icon/earm.svg" />
                          <span>{{ $t('menu.earn') }}</span>
                          <div class="img-link"/>

                        </router-link>
                      </li>
                      <li>
                        <router-link to="#" >
                          <img src="@/assets/images/icon/saving.svg" />
                          <span>{{ $t('menu.saving') }}</span>
                          <div class="img-link"/>

                        </router-link>
                      </li>
                      <li>
                        <router-link to="#" >
                          <img src="@/assets/images/icon/staking.svg" />
                          <span>{{ $t('menu.staking') }}</span>
                          <div class="img-link"/>
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </div> -->

                <!--SUPPORT TAB-->
                <div tabindex="8"
                     class="bar fl-left lang-bar dropdown-block"
                     @focus="supportTab = true"
                     @blur="supportTab = false"
                     @click="supportTab = !supportTab"
                     @mouseleave="supportTab = false"
                >
                  <button v-if="isLanding && sizeScreen > 1300"  class="btn btn-lang-head left_bar supportTab"
                          @mouseover="supportTab = mouseOverDetectScreen(supportTab)">
                    <span :class='supportTab? "active": ""' >{{ $t('menu.support') }}</span>
                    <img  class="dropdown-active"  :src="require(`@/assets/images/icon/arrow-${supportTab? 'up-blue': 'down-white'}.svg`)"/>
                  </button>

                  <button v-else class="btn btn-lang-head left_bar supportTab"
                          @mouseover="supportTab = mouseOverDetectScreen(supportTab)">
                    <span :class='supportTab? "active": ""' >{{ $t('menu.support') }}</span>
                    <img  class="dropdown-active" v-if="userTheme === 'light-theme'" :src="require(`@/assets/images/icon/arrow-${supportTab? 'up-blue': 'down-black'}.svg`)"/>
                    <img  class="dropdown-active" v-else :src="require(`@/assets/images/icon/arrow-${supportTab? 'up-blue': 'down-white'}.svg`)"/>
                  </button>
                  <div class="dropdown-head right-side ">
                    <ul class="tradeTab" v-show="supportTab">
                      <li>
                        <a target="_blank" href="https://monas-testing.freshdesk.com/support/solutions">
                          <img src="@/assets/images/icon/FAQ.svg" />
                          <span>{{ $t('menu.FAQ') }}</span>
                          <div class="img-link"/>
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="https://monas-testing.freshdesk.com/support/tickets/new" >
                          <img src="@/assets/images/icon/notice.svg" />
                          <span>{{ $t('menu_bottom.support_center') }}</span>
                          <div class="img-link"/>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div tabindex="9"
                     v-if="isAuthenticated"
                     v-bind:class="{'active' : checkActive(1) }"
                     class="bar lang-bar f-fl dropdown-block btn-responsive "
                     style
                     @focus="funds_bar = true"
                     @mouseleave="funds_bar = false"
                     @click="funds_bar = !funds_bar"
                >
                  <button v-if="isLanding && sizeScreen > 1300" class="btn btn-lang-head left_bar">
                    <span :class='funds_bar? "active": ""' >{{ $t('menu.wallet') }}</span>
                    <img  class="dropdown-active" :src="require(`@/assets/images/icon/arrow-${funds_bar? 'up-blue': 'down-white'}.svg`)"/>
                  </button>
                  <button v-else class="btn btn-lang-head left_bar"
                          @mouseover="funds_bar = mouseOverDetectScreen(funds_bar)"
                  >
                    <span :class='funds_bar? "active": ""' >{{ $t('menu.wallet') }}</span>
                    <img  class="dropdown-active" v-if="userTheme === 'light-theme'" :src="require(`@/assets/images/icon/arrow-${funds_bar? 'up-blue': 'down-black'}.svg`)"/>
                    <img  class="dropdown-active" v-else :src="require(`@/assets/images/icon/arrow-${funds_bar? 'up-blue': 'down-white'}.svg`)"/>
                  </button>
                  <!--              </span>-->
                  <div class="dropdown-head right-side dropdown-large">
                    <ul class="subFunds" v-show="funds_bar">
                      <li>
                        <router-link to="/funds/balances-wallet">
                          <img src="@/assets/images/icon/overview_wallet.svg" />
                          <span>{{ $t('menu.wallet.overview') }}</span>
                          <div class="img-link"/>
                        </router-link>
                      </li>
                      <li>
                        <router-link to="/funds/spot-wallet" >
                          <img src="@/assets/images/icon/spot.svg" />
                          <span>{{ $t('menu.wallet.spot') }}</span>
                          <div class="img-link"/>
                        </router-link>
                      </li>
                      <li>
                        <router-link to="/funds/futures-wallet" >
                          <img src="@/assets/images/icon/future.svg" />
                          <span>{{ $t('menu.wallet.futures') }}</span>
                          <div class="img-link"/>
                        </router-link>
                      </li>
                      <li>
                        <router-link to="/funds/history-wallet" >
                          <img src="@/assets/images/icon/transaction_history.svg" />
                          <span>{{ $t('menu.wallet.transaction_history') }}</span>
                          <div class="img-link"/>
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </div>

<!--                <router-link-->
<!--                  v-if="isAuthenticated"-->
<!--                  tabindex="9"-->
<!--                  class="btn f-fl btn-exchange-page btn-order btn-responsive"-->
<!--                  :class="{ active : isSpotExchangePage, 'ft_11' :  $t('i18n.lang') == 'ru' && isAuthenticated ,'ft_14': $t('i18n.lang') == 'vi' }"-->
<!--                  to="/spot-exchange/orders/open"> {{$t('menu.orders')}}-->
<!--                </router-link>-->
                <div tabindex="10"
                     v-if="isAuthenticated"
                     v-bind:class="{'active' : checkActive(1) }"
                     class="bar lang-bar f-fl dropdown-block btn-responsive "
                     style
                     @focus="order_bar = true"
                     @mouseleave="order_bar = false, order_child = false,  future_child = false, convert_child = false"
                >
                  <button  v-if="isLanding && sizeScreen > 1300" class="btn btn-lang-head left_bar"  @click="order_bar = !order_bar">
                    <span :class='order_bar? "active": ""' >{{$t('menu.orders')}}</span>
                    <img  class="dropdown-active" :src="require(`@/assets/images/icon/arrow-${order_bar? 'up-blue': 'down-white'}.svg`)"/>
                  </button>
                  <button v-else class="btn btn-lang-head left_bar"  @click="order_bar = !order_bar">
                    <span :class='order_bar? "active": ""' >{{$t('menu.orders')}}</span>
                    <img  class="dropdown-active" v-if="userTheme === 'light-theme'" :src="require(`@/assets/images/icon/arrow-${order_bar? 'up-blue': 'down-black'}.svg`)"/>
                    <img  class="dropdown-active" v-else :src="require(`@/assets/images/icon/arrow-${order_bar? 'up-blue': 'down-white'}.svg`)"/>
                  </button>
                  <div class="dropdown-head right-side dropdown-large">
                    <ul class="subFunds sub-child" v-show="order_bar">
                      <li>
                        <button class="btn btn-lang-head left_bar btn-child" @click="order_child = !order_child, future_child = false, convert_child = false"  >
                          <div class="t-btn-child">
                            <img src="@/assets/images/icon/spot.svg" />
                             <span>{{ $t('menu.wallet.spot') }}</span>
                        </div>

                          <img  class="dropdown-active" v-if="userTheme === 'light-theme'" :src="require(`@/assets/images/icon/arrow-${order_child? 'up-blue': 'down-black'}.svg`)"/>
                          <img  class="dropdown-active" v-else :src="require(`@/assets/images/icon/arrow-${order_child? 'up-blue': 'down-white'}.svg`)"/>
                        </button>
                      </li>
                      <div class="list-dropdown-order clearfix-order">
                          <ul v-show="order_child">
                            <li>
                              <router-link to="/spot-exchange/orders/open" >
                                  <span> {{ $t('sidebar.open_order') }}</span>
                                  <div class="img-link"/>
                              </router-link>
                            </li>
                            <li>
                              <router-link  to="/spot-exchange/orders/history"  >
                                  <span> {{ $t('sidebar.order_history') }}</span>
                                <div class="img-link"/>
                              </router-link>
                            </li>
                            <li>
                              <router-link to="/spot-exchange/orders/trade-history">
                                  <span>  {{ $t('sidebar.trade_history') }}</span>
                                <div class="img-link"/>
                              </router-link>
                            </li>
                          </ul>
                        </div>
                      <li>
                        <button class="btn btn-lang-head left_bar btn-child" @click="future_child = !future_child, order_child = false,convert_child = false">
                          <div class="t-btn-child">
                            <img src="@/assets/images/icon/future.svg" />
                            <span>{{ $t('menu.wallet.futures') }}</span>
                          </div>
                          <img  class="dropdown-active" v-if="userTheme === 'light-theme'" :src="require(`@/assets/images/icon/arrow-${future_child? 'up-blue': 'down-black'}.svg`)"/>
                          <img  class="dropdown-active" v-else :src="require(`@/assets/images/icon/arrow-${future_child? 'up-blue': 'down-white'}.svg`)"/>
                        </button>
                      </li>
                      <div class="list-dropdown-order clearfix-order">
                        <ul v-show="future_child">
                          <li>
                            <a :href="SUB_DOMAIN+'/orders?tab=order'">
                              <span> {{ $t('sidebar.open_order') }}</span>
                              <div class="img-link"/>
                            </a>
                          </li>
                          <li>
                            <a :href="SUB_DOMAIN+'/orders?tab=order-history'" >
                              <span> {{ $t('sidebar.order_history') }}</span>
                              <div class="img-link"/>
                            </a>
                          </li>
                          <li>
                            <a :href="SUB_DOMAIN+'/orders?tab=trade-history'">
                              <span>  {{ $t('sidebar.trade_history') }}</span>
                              <div class="img-link"/>
                            </a>
                          </li>
                          <li>
                            <a :href="SUB_DOMAIN+'/orders?tab=transaction-history'">
                              <span>  {{ $t('menu.transaction_history') }}</span>
                              <div class="img-link"/>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <li>
                        <button class="btn btn-lang-head left_bar btn-child" @click="convert_child = !convert_child, order_child = false, future_child = false">
                          <div class="t-btn-child">
                            <img src="@/assets/images/icon/convert.svg" />
                            <span>{{ $t('menu.convert') }}</span>
                          </div>
                          <img  class="dropdown-active" v-if="userTheme === 'light-theme'" :src="require(`@/assets/images/icon/arrow-${convert_child? 'up-blue': 'down-black'}.svg`)"/>
                          <img  class="dropdown-active" v-else :src="require(`@/assets/images/icon/arrow-${convert_child? 'up-blue': 'down-white'}.svg`)"/>
                        </button>
                      </li>
                      <div class="list-dropdown-order clearfix-order">
                        <ul v-show="convert_child">
                          <li>
                            <router-link to="/convert/open-order" >
                              <span> {{ $t('menu.open_orders') }}</span>
                              <div class="img-link"/>
                            </router-link >
                          </li>
                          <li>
                            <router-link to="/convert/history" >
                              <span> {{ $t('menu.history.convert') }}</span>
                              <div class="img-link"/>
                            </router-link >
                          </li>
                        </ul>
                      </div>

                    </ul>
                  </div>
                </div>
                <div class="authenticated-bar">
                  <div v-if="!isAuthenticated" class="login-register-bar">
                    <router-link tabindex="13" :to="{name: 'Login', query: {destination: $route.name}}" class="login item" :class="$t('i18n.lang') == 'ru' ? 'lowerCase' : ''">{{$t('menu.login')}}</router-link>
                    <router-link tabindex="12" :to="{name: 'Register', query: {destination: $route.name}}" class="register item" :class="$t('i18n.lang') == 'ru' ? 'lowerCase' : ''">{{$t('menu.signup')}}</router-link>
                  </div>
                </div>
              </div>
          </div>
          <!-- left-bar -->
            <div v-show="true " class="custom-right-bar ">
              <div class="f-fr tab-single menu-head clearfix">
                <div class="entry-wrapper" v-if="isMarginExchangePage">
                  <entry></entry>
                </div>
                <!-- Wallet -->
                <div tabindex="8"
                     v-if="isAuthenticated"
                     v-bind:class="{'active' : checkActive(1) }"
                     class="bar lang-bar f-fl dropdown-block btn-responsive"
                     style
                     @focus="funds_bar = true"
                     @mouseover="funds_bar = mouseOverDetectScreen(funds_bar)"
                     @click="funds_bar = !funds_bar"
                     @mouseleave="funds_bar = false"
                >
                  <button v-if="isLanding && sizeScreen > 1300" class="btn btn-lang-head left_bar">
                    <span :class='funds_bar? "active": ""' >{{ $t('menu.wallet') }}</span>
                    <img  class="dropdown-active" :src="require(`@/assets/images/icon/arrow-${funds_bar? 'up-blue': 'down-white'}.svg`)"/>
                  </button>
                  <button v-else class="btn btn-lang-head left_bar">
                    <span :class='funds_bar? "active": ""' >{{ $t('menu.wallet') }}</span>
                    <img  class="dropdown-active" v-if="userTheme === 'light-theme'" :src="require(`@/assets/images/icon/arrow-${funds_bar? 'up-blue': 'down-black'}.svg`)"/>
                    <img  class="dropdown-active" v-else :src="require(`@/assets/images/icon/arrow-${funds_bar? 'up-blue': 'down-white'}.svg`)"/>
                  </button>
                  <!--              </span>-->
                  <div class="dropdown-head right-side dropdown-large">
                   
                    <ul class="subFunds" v-show="funds_bar">
                      <div class="action-wallet">
                        <router-link to="/funds/deposits-wallet?coin=usdt">
                          <div class="action-wallet-item">
                            <img :src="require(userTheme === 'light-theme' ? '@/assets/images/deposit_icon.svg' : '@/assets/images/deposit_icon_dark.svg')" />
                            <div>{{ $t("funds.balances.deposit") }}</div>
                          </div>
                        </router-link>
                       
                        <router-link to="/funds/withdraw-wallet?coin=usdt">
                          <div class="action-wallet-item">
                            <img :src="require(userTheme === 'light-theme' ? '@/assets/images/withdraw_icon.svg' : '@/assets/images/withdraw_icon_dark.svg')" />
                            <div>{{ $t("withdraw_setting.withdraw") }}</div>
                          </div>
                        </router-link>
                        
                        <router-link to="/funds/transfer?symbol=usdt">
                          <div class="action-wallet-item">
                            <img :src="require(userTheme === 'light-theme' ? '@/assets/images/transfer_icon.svg' : '@/assets/images/transfer_icon_dark.svg')" />
                            <div>{{ $t("funds.balances.tranfer") }}</div>
                          </div>
                        </router-link>
                       
                      </div>
                      <li>
                        <router-link to="/funds/balances-wallet" >
                          <img src="@/assets/images/icon/overview_wallet.svg" />
                          <span>{{ $t('menu.wallet.overview') }}</span>
                          <div class="img-link"/>
                        </router-link>
                      </li>
                      <li>
                        <router-link to="/funds/spot-wallet" >
                          <img src="@/assets/images/icon/spot.svg" />
                          <span>{{ $t('menu.wallet.spot') }}</span>
                          <div class="img-link"/>
                        </router-link>
                      </li>
                      <li>
                        <router-link to="/funds/futures-wallet">
                          <img src="@/assets/images/icon/future.svg" />
                          <span>{{ $t('menu.wallet.futures') }}</span>
                          <div class="img-link"/>
                        </router-link>
                      </li>
                      <li>
                        <router-link to="/funds/history-wallet" >
                          <img src="@/assets/images/icon/transaction_history.svg" />
                          <span>{{ $t('menu.wallet.transaction_history') }}</span>
                          <div class="img-link"/>
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div tabindex="9"
                     v-if="isAuthenticated"
                     v-bind:class="{'active' : checkActive(1) }"
                     class="bar lang-bar f-fl dropdown-block btn-responsive "
                     style
                     @focus="order_bar = true"
                     @mouseover="order_bar = true"
                     @mouseleave="order_bar = false"
                     @click="order_bar = !order_bar">
                     <button  v-if="isLanding && sizeScreen > 1300" class="btn btn-lang-head left_bar">
                      <span :class='order_bar? "active": ""' >{{$t('menu.orders')}}</span>
                      <img  class="dropdown-active" :src="require(`@/assets/images/icon/arrow-${order_bar? 'up-blue': 'down-white'}.svg`)"/>
                    </button>
                      <button v-else class="btn btn-lang-head left_bar">
                        <span :class='order_bar? "active": ""' >{{$t('menu.orders')}}</span>
                        <img  class="dropdown-active" v-if="userTheme === 'light-theme'" :src="require(`@/assets/images/icon/arrow-${order_bar? 'up-blue': 'down-black'}.svg`)"/>
                      <img  class="dropdown-active" v-else :src="require(`@/assets/images/icon/arrow-${order_bar? 'up-blue': 'down-white'}.svg`)"/>
                    </button>
                  <div class="dropdown-head right-side ">
                    <ul class="subFunds" v-show="order_bar">
                      <li>
                        <router-link to="/spot-exchange/orders/open" >
                          <img src="@/assets/images/icon/spot.svg" />
                          <span>{{ $t('menu.wallet.spot') }}</span>
                          <div class="img-link"/>
                        </router-link>
                      </li>
                      <li>
                        <a :href="SUB_DOMAIN+'/orders'">
                          <img src="@/assets/images/icon/future.svg" />
                          <span>{{ $t('menu.wallet.futures') }}</span>
                          <div class="img-link"/>
                        </a>
                      </li>
                      <li>
                        <router-link to="/convert/open-order" >
                          <img src="@/assets/images/icon/convert.svg" />
                          <span>{{ $t('menu.convert') }}</span>
                          <div class="img-link"/>
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </div>

                <!-- theme -->
                <div tabindex="10"
                     class="bar f-fr dropdown-block"
                     @focus="nav_page_bar = true"
                     @blur="nav_page_bar = false"
                     @mouseover="nav_page_bar = false"
                     @click="nav_page_bar === true"
                     @mouseleave="nav_page_bar = false"
                >
                  <input
                    @change="toggleTheme"
                    id="checkbox"
                    type="checkbox"
                    class="switch-checkbox"
                  />
                  <label v-if="isLanding" for="checkbox" class="switch-label-layout">
                    <img class="img_theme_mode" :src="require(`@/assets/images/icon/${userTheme !== 'light-theme'? 'sun-light': 'moon-light'}.svg`)" />
                  </label>
                  <label v-else for="checkbox" class="switch-label-layout">
                    <img class="img_theme_mode" :src="require(`@/assets/images/icon/${userTheme !== 'light-theme'? 'sun-light': 'moon'}.svg`)" />
                  </label>
                </div>
                <!-- languages -->
                <div tabindex="11"
                     class="bar f-fr lang-bar dropdown-block"
                     @focus="lang_bar = true"
                     @blur="lang_bar = false"
                     @mouseover="sizeScreen > 576 ? lang_bar = mouseOverDetectScreen(lang_bar) : ''"
                     @click="lang_bar = !lang_bar"
                     @mouseleave="lang_bar = false"
                >
                 <button v-if="isLanding" :class="[ isAuthenticated? 'lang' : '' , 'btn', 'btn-lang-head' ]" >
                    <span v-if="this.$i18n.locale == 'en'" :class='lang_bar? "active": ""'>{{ $t('menu.lang.acr.en_2') }} </span>

                    <!-- <span v-if="this.$i18n.locale == 'ko'" :class='lang_bar? "active": ""'>{{ $t('menu.lang.acr.ko_2') }}</span> -->
                    <span v-if="this.$i18n.locale == 'vi'" :class='lang_bar? "active": ""'>{{ $t('menu.lang.acr.vi_2') }}</span>

<!--                    <img  class="dropdown-active" v-if=" !isAuthenticated" :src="require(`@/assets/images/icon/arrow-${lang_bar? 'up-blue': 'down-blue'}.svg`)"/>-->
<!--                    <img  class="dropdown-active" v-else-if="userTheme === 'light-theme' && isAuthenticated" :src="require(`@/assets/images/icon/arrow-${lang_bar? 'up-blue': 'down-black'}.svg`)"/>-->
                    <img  class="dropdown-active" :src="require(`@/assets/images/icon/arrow-${lang_bar? 'up-blue': 'down-white'}.svg`)"/>
                  </button>
                  <button v-else :class="[ isAuthenticated? 'lang' : '' , 'btn', 'btn-lang-head' ]" >
                    <span v-if="this.$i18n.locale == 'en'" :class='lang_bar? "active": ""'>{{ $t('menu.lang.acr.en_2') }}</span>
                    <!-- <span v-if="this.$i18n.locale == 'ko'" :class='lang_bar? "active": ""'>{{ $t('menu.lang.acr.ko_2') }}</span> -->
                    <span v-if="this.$i18n.locale == 'vi'" :class='lang_bar? "active": ""'>{{ $t('menu.lang.acr.vi_2') }}</span>

<!--                    <img  class="dropdown-active" v-if=" !isAuthenticated" :src="require(`@/assets/images/icon/arrow-${lang_bar? 'up-blue': 'down-blue'}.svg`)"/>-->
<!--                    <img  class="dropdown-active" v-else-if="userTheme === 'light-theme' && isAuthenticated" :src="require(`@/assets/images/icon/arrow-${lang_bar? 'up-blue': 'down-black'}.svg`)"/>-->
                    <img  class="dropdown-active" v-if="userTheme === 'light-theme'" :src="require(`@/assets/images/icon/arrow-${lang_bar? 'up-blue': 'down-black'}.svg`)"/>
                    <img  class="dropdown-active" v-else :src="require(`@/assets/images/icon/arrow-${lang_bar? 'up-blue': 'down-white'}.svg`)"/>
                  </button>
                  <div class="dropdown-head">
                    <ul class="subLang" v-show="lang_bar">
                      <li>
                        <a href="#" @click="updateUserLocale('en')">
                          <img src="@/assets/images/icon/flag_en.svg" />
                          <span>{{ $t('menu.lang.acr.en_2') }}</span>
                          <div class="img-link"/>
                        </a>
                      </li>
                      <!-- <li>
                        <a href="#" @click="updateUserLocale('ko')">
                          <img src="@/assets/images/icon/flag_ko.svg" />
                          <span>{{ $t('menu.lang.acr.ko_2') }}</span>
                          <div class="img-link"/>
                        </a>
                      </li> -->
                      <li>
                        <a href="#" @click="updateUserLocale('vi')">
                          <img src="@/assets/images/icon/flag_vi.svg" />
                          <span>{{ $t('menu.lang.acr.vi_2') }}</span>
                          <div class="img-link"/>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="cl-fix f-fr" v-show="isAuthenticated"></div>
                <!-- user -->
                <div tabindex="10"
                     v-if="isAuthenticated"
                     class="bar f-fr user-bar dropdown-block"
                     @click="user_bar = !user_bar"
                     @mouseover="user_bar = true"
                     @mouseleave="user_bar = false
                   subHover = '' "
                >
                  <button :class="[ isAuthenticated? 'lang' : '' , 'btn', 'btn-lang-head' ]">
                    <img v-if="isAuthenticated" class="dropdown-active"  :src="require(`@/assets/images/icon/user_${user_bar? 'blue': 'black'}.svg`)"/>

                  </button>
                  <div class="dropdown-head dropdown-large right-side user-dropdown">
                    <ul class="subUser" v-show="user_bar">
                      <li>
                        <router-link to="/account/dashboard" >
                          <img src="@/assets/images/icon/dashboard.svg" />
                          <span>{{ $t('menu.user.dashboard') }}</span>
                          <div class="img-link"/>
                        </router-link>
                      </li>
                      <li>
                        <router-link to="/account/security" >
                          <img src="@/assets/images/icon/security.svg" />
                          <span>{{ $t('menu.user.security') }}</span>
                          <div class="img-link"/>
                        </router-link>
                      </li>
<!--                      <li>-->
<!--                        <router-link :to="{name: 'IdentityPersonal'}" >-->
<!--                          <img src="@/assets/images/icon/identification.svg" />-->
<!--                          <span>{{ $t('menu.user.identification') }}</span>-->
<!--                          <div class="img-link"/>-->
<!--                        </router-link>-->
<!--                      </li>-->
                      <li>
                        <router-link :to="{name: 'Referral Wallet' }" >
                          <img src="@/assets/images/icon/referral.svg" />
                          <span>{{ $t('menu.user.referral') }}</span>
                          <div class="img-link"/>
                        </router-link>
                      </li>
                      <li>
                        <router-link to="/account/reward-center" >
                          <img src="@/assets/images/icon/reward_center.svg" />
                          <span>{{ $t('menu.user.reward_center') }}</span>
                          <div class="img-link"/>
                        </router-link>
                      </li>
                      <li>
                        <router-link to="/account/api-key" >
                          <img src="@/assets/images/icon/api_management.svg" />
                          <span>{{ $t('menu.user.api_management') }}</span>
                          <div class="img-link"/>
                        </router-link>
                      </li>
                      <li :class="subHover === 3 ? 'li-hover' : ''" @mouseover="subHover = 3">
                        <a class="logout" @click="logout">
                          <img src="@/assets/images/icon/logout.svg" />
                          <span>{{ $t('menu.logout') }}</span>
                          <div class="img-link"/>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <!--login-resister toolbar-->
                <div v-if="!isAuthenticated" class="login-register-bar">
                  <router-link tabindex="13" :to="{name: 'Login', query: {destination: $route.name}}" class="login item" :class="$t('i18n.lang') == 'ru' ? 'lowerCase' : ''">{{$t('menu.login')}}</router-link>
                  <router-link tabindex="12" :to="{name: 'Register', query: {destination: $route.name}}" class="register item" :class="$t('i18n.lang') == 'ru' ? 'lowerCase' : ''">{{$t('menu.signup')}}</router-link>
<!--                  <router-link tabindex="12" :to="{name: 'Download', query: {destination: $route.name}}" class="register item" :class="$t('i18n.lang') == 'ru' ? 'lowerCase' : ''">{{$t('menu_bottom.download')}}</router-link>-->
                </div>
              </div>
            </div>
        </div>
      </div>
    </header>
    <div class="clearfix"></div>
    <!-- <div id="pingpongchat" @click="openChatWidget()"><img src="@/assets/images/icon/icon-chat.svg" ></div> -->
    <div>
    <main v-if="!isBaseContracts" id="content" :class="{ 'page-container clearfix': ( !isWalletBalances && !isWalletPage && !isReferral && !isLanding && !isClientDownload && !isExchangePage && !isSpotExchangePage && !isMarginExchangePage && !isMamExchangePage && !isInviteAward && !isContract && !isCooperation && !isMAMDetail && !isROEActivity && !isLicense && !isJoinUs && !isCoinFormation), 'isContentApp': statusApp, 'isReferral': (isReferralApp || isContractSeriesApp) }">
      <router-view></router-view>
    </main>
    </div>
    <base-contracts v-if="isBaseContracts"/>

    <footer id="footer" class="height200" :class="{ 'disable-app' : (isReferralApp || isContractSeriesApp || statusApp)}"  v-if="!isExchangePage && !isSpotExchangePage && !isMarginExchangePage && !isMamExchangePage">
      <page-footer>

      </page-footer>
    </footer>

    <!-- ============ Begin::Initialize common components ============ -->
    <message></message>
    <confirmation-modal></confirmation-modal>
    <confirmation-modal-second></confirmation-modal-second>
    <unregistered-session-dialog/>
    <masterdata-changed-dialog/>
    <!-- ============ End::Initialize common components ============ -->
  </div>
</template>

<script>
import rf from '@/request/RequestFactory'
import moment from 'moment';
import Utils from '@/common/Utils'
import Const from "@/common/Const";
import Message from "@/components/shared_components/common/Message";
import ConfirmationModal from "@/components/shared_components/common/ConfirmationModal.vue";
import confirmationModalSecond from "@/components/shared_components/common/ConfirmationModalSecond";
import UnregisteredSessionDialog from "@/components/shared_components/common/UnregisteredSessionDialog.vue";
import MasterdataChangedDialog from "@/components/shared_components/common/MasterdataChangedDialog.vue";
import BigNumber from "bignumber.js";
import AuthenticationUtils from "@/common/AuthenticationUtils";
import PageFooter from "./pages/common/PageFooter.vue";
import BaseContracts from "@/components/marginexchange_pages/contracts/BaseContracts.vue";
import {mapMutations, mapState} from 'vuex';
import BalanceUtils from "@/common/BalanceUtils";
import Entry from "@/components/shared_components/common/Entry/Entry";
import Cookies from 'js-cookie'

export default {
  components: {
    Entry,
    Message,
    ConfirmationModal,
    UnregisteredSessionDialog,
    MasterdataChangedDialog,
    PageFooter,
    BaseContracts,
    confirmationModalSecond,
  },
  data() {
    return {
      statusApp: false,
      articles: [],
      nav_page_bar: false,
      user_bar: false,
      lang_bar: false,
      funds_bar: false,
      mam_bar: false,
      order_bar: false,
      order_child: false,
      future_child: false,
      convert_child: false,
      noti_extent: false,
      csrfToken: window.csrf_token,
      isAuthenticated: window.isAuthenticated,
      email: "",
      accountBalances: {},
      prices: {},
      subHover: "",
      languages: Const.LANGUAGES,
      visibleLangueges: false,
      visibleUser: false,
      showNewsList: false,
      isShowTradingVolumeRankingSpot: false,
      isShowTradingVolumeRankingMargin: false,
      news: [],
      newsCountUnRead: 0,
      currentNewsPage: 1,
      newsMaxPage: 1,
      userNewsInfo: {},
      newsReadIds: [],
      totalBtcBalance: 0,
      insurance: {},
      userTheme: "light-theme",
      tradeTab: false,
      futureTab: false,
      earmTab: false,
      supportTab: false,
      responsiveTab: false,
      menuActive: false,
      sizeScreen: window.innerWidth,

    };
  },
  watch: {
    menuActive() {
      if(this.menuActive) {
        document.body.style.overflowY = 'hidden';
      } else {
        document.body.style.overflowY = 'auto';
      }
    },
    Authentication() {
      if (this.Authentication) {
        this.isAuthenticated = true;
      }
    },
  },
  computed: {
    ...mapState({
      coins: (state) => state.masterdata.coins,
      user: (state) => state.user,
      Authentication: (state) => state.isAuthentication,
    }),
    isExchangePage() {
      return 0;
    },
    isLanding() {
      return this.$route.path === '/';
    },
    isSpotExchangePage() {
      return this.$route.path === '/spot-exchange/basic';
    },
    isConvertPage(){
      return this.$route.path === '/convert';
    },
    isMarginExchangePage() {
      // return this.$route.path === '/margin-exchange';
    },
    isMamExchangePage() {
      return this.$route.path === '/mam-exchange' ? true : false;
    },
    isSpotExchangeLane() {
      return this.$route.path.includes('spot-exchange');
    },
    isSalespointLane() {
      return this.$route.path.includes('salespoint');
    },
    isMarginExchangeLane() {
      // return this.$route.path.includes('margin-exchange');
    },
    isMamExchangeLane() {
      return this.$route.path.includes('mam-exchange');
    },
    isClientDownload() {
      return this.$route.path === '/client-download'
    },
    isBaseContracts() {
      return this.$route.path.includes('contracts');
    },
    isWalletPage() {
      return this.$route.path.includes('overview') ;
    },
    isWalletBalances() {
       return this.$route.path == '/wallet/balances' || this.$route.path == '/wallet/history'  ;
    },
    SUB_DOMAIN: () => process.env.VUE_APP_SUB_DOMAIN_PUBLIC_URL,
    isReferral() {
      return this.$route.path.includes('referral');
    },
    isReferralApp() {
      return this.$route.path.includes('/referral-mobile-app-160819');
    },
    isContractSeriesApp() {
      return this.$route.path.includes('/contract-series-app-21102019');
    },
    // handlerCurrencyAmount() {
    //   let mount = this.totalBtcBalance | Utils.formatCurrencyAmount('btc', 0);
    //   // let mount = '631,345.46789201';
    //   let currentAmount = mount.toString().split('.')[0];
    //   return currentAmount;
    // },
    isCooperation() {
      return this.$route.path.includes('/cooperation');
    },
    isMAMDetail() {
      return this.$route.path.includes('/mam-detail');
    },
    isROEActivity() {
      return this.$route.path.includes('/roe-activity');
    },
    isLicense() {
      return this.$route.path.includes('/license');
    },
    isInviteAward() {
      return this.$route.path.includes('/inviteAward');
    },
    isContract() {
      return this.$route.path.includes('/contract');
    },
    isJoinUs() {
      return this.$route.path.includes('/joinUs');
    },
    isCoinFormation() {
      return this.$route.path.includes('/coin-information');
    },

    handlerCurrencyAmount() {
      let amount = this.totalBtcBalance | Utils.formatCurrencyAmount('btc', 0);
      let currentAmount = (amount + '').split('.')[0];
      return currentAmount;
    },
  },
  methods: {
    ...mapMutations(['updateUser']),
    calculateTotalBtcBalance() {
      if (
        window._.isEmpty(this.prices) ||
        window._.isEmpty(this.coins) ||
        window._.isEmpty(this.accountBalances)
      ) {
        return "0";
      }
      this.totalBtcBalance = this.sumBalances();
      return this.totalBtcBalance;
    },
    openChatWidget() {
      window.fcWidget.init({
        token: process.env.VUE_APP_API_FUTURES_URL,
      })
      window.fcWidget.setExternalId(this.user.id)
      window.fcWidget.user.setEmail(this.user.email);
      window.fcWidget.user.setFirstName(this.user.email);
      window.fcWidget.open();
    },
    formatCreatedAt(newsRecord) {
      const EMPTY_SYMBOL = '--';
      let createdAt = (newsRecord.created_at || "");
      if (createdAt == "") {
        return EMPTY_SYMBOL;
      }

      let dateFormatted = moment(createdAt, 'YYYY-MM-DD HH:mm:ss').format('DD-MM');
      if (dateFormatted == "Invalid date") {
        return EMPTY_SYMBOL;
      }

      return dateFormatted;
    },
    checkActive: function(tabIndex) {
      if (tabIndex == 1) {
        if (
          this.$route.path == "/funds/balances" ||
          this.$route.path == "/funds/withdrawals" ||
          this.$route.path == "/funds/deposits" ||
          this.$route.path == "/funds/history"
        ) {
          return true;
        } else {
          return false;
        }
      }
      if (tabIndex == 2) {
        if (
          this.$route.path == "/orders/open" ||
          this.$route.path == "/orders/history" ||
          this.$route.path == "/orders/trade-history"
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    logout() {
      AuthenticationUtils.removeAuthenticationData();
      const keepRedirect = ['/market-overview', '/spot-exchange/basic', '/convert']
      if (keepRedirect.find(item => item === window.location.pathname)) {
        this.gotoUrl(window.location.pathname);
        return;
      }
      this.gotoUrl("/");
    },
    addPricesInBtc() {
      this.prices["btc_btc"] = { price: 1 };
      for (const fiat of Const.FIAT_LIKE_LIST) {
        const fiatPrice = this.prices[`${fiat}_btc`];
        const checkNullPrice = fiatPrice && !(new BigNumber(fiatPrice.price).isZero());
        const price = checkNullPrice
          ? new BigNumber("1").div(fiatPrice.price).toString()
          : "0";
        this.prices[`btc_${fiat}`] = { price };
      }
    },
    getSocketEventHandlers() {
      return {
        PricesUpdated: this.onPricesUpdated,
        BalanceUpdated: this.onBalanceUpdated,
        ZendeskNewsUpdated: this.onZendeskNewsUpdated,
      };
    },
    onPricesUpdated(newPrices) {
      this.prices = Object.assign({}, this.prices, newPrices);
      this.addPricesInBtc();
      this.calculateTotalBtcBalance();
    },
    onBalanceUpdated(newAccountBalances) {
      this.accountBalances = window._.merge(
        {},
        this.accountBalances,
        newAccountBalances
      );
      this.calculateTotalBtcBalance();
    },
    onZendeskNewsUpdated(res) {
      let data = res.data || [];
      let currentLocale = window.localStorage.locale;
      if (currentLocale == 'en') {
        currentLocale = 'en-us';
      }

      for(let i = 0; i < data.length; i++) {
        let currentItem = data[i];
        let newItemLocale = currentItem.locale;
        if (newItemLocale == currentLocale) {
          this.news.push(currentItem);
          this.newsCountUnRead++;
        }
      }

      // Fix bug with newsMaxPage when items change
      //window._.uniqBy(this.news, 'id');


      return this.news;
    },
    sumBalances() {
      let result = new BigNumber("0");
      this.coins.forEach(item => {
        result = result.add(this.getTotalBalanceByCoin(item.coin));
      });
      return result.toString();
    },
    getTotalBalanceByCoin(coin) {
      let coinList = this.accountBalances[Const.TYPE_MAIN_BALANCE] || {};
      let mainBalanceData = this.accountBalances[Const.TYPE_MAIN_BALANCE] || {};
      let marginBalanceData = this.accountBalances[Const.TYPE_MARGIN_BALANCE] || {};
      let exchangeBalanceData = this.accountBalances[Const.TYPE_EXCHANGE_BALANCE] || {};
      let mamBalanceData = this.accountBalances[Const.TYPE_MAM_BALANCE] || {};
      let airdropBalanceData = this.accountBalances[Const.TYPE_AIRDROP_BALANCE] || {};

      let totalBalance = 0;
      let item = {
        ...this.accountBalances[Const.TYPE_MAIN_BALANCE][coin]
      };
      let coinType = coin || '';
      item.balance = (mainBalanceData && mainBalanceData[coin] && mainBalanceData[coin].balance) || 0;
      item.availableBalance = (mainBalanceData && mainBalanceData[coin] && mainBalanceData[coin].available_balance) || 0;
      item.exchangeBalance = (exchangeBalanceData && exchangeBalanceData[coin] && exchangeBalanceData[coin].balance) || 0;
      item.exchangeAvailabelBalance = (exchangeBalanceData && exchangeBalanceData[coin] && exchangeBalanceData[coin].available_balance) || 0;
      item.marginBalance = (marginBalanceData && marginBalanceData[coin] && marginBalanceData[coin].balance) || 0;
      item.mamBalance = (mamBalanceData && mamBalanceData[coin] && mamBalanceData[coin].balance) || 0;
      item.airdropBalance = (airdropBalanceData && airdropBalanceData[coin] && airdropBalanceData[coin].balance) || 0;
      item.airdropBalanceBonus = (airdropBalanceData && airdropBalanceData[coin] && airdropBalanceData[coin].balance_bonus) || 0;

      item.totalBalance = new BigNumber(item.balance).add(item.exchangeBalance);

      if (coinType == Const.BTC) {
        //item.totalBalance = new BigNumber(item.totalBalance).add(item.marginBalance).add(item.mamBalance).add(item.airdropBalance);
        item.totalBalance = new BigNumber(item.totalBalance).add(item.marginBalance);
      }
      if (coinType == Const.AML) {
        item.totalBalance = new BigNumber(item.totalBalance).add(item.marginBalance).add(item.airdropBalance).add(item.airdropBalanceBonus);
      }
      item.totalBalance = item.totalBalance.toString();

      // item.btcValue = this.convertToBtcValue(coin, item.totalBalance);
      item.usdValue = BalanceUtils.convertToUsdtValue(this.prices, coin, item.totalBalance);
      item.btcValue = BalanceUtils.convertCoinsThroughUsdt(this.prices, coin, Const.BTC, item.totalBalance);

      return item.btcValue;
    },
    getBalanceByCoin(coin) {
      if (!this.accountBalances[Const.TYPE_MAIN_BALANCE][coin]) return "0";
      if (coin === "btc") {
        return this.accountBalances[Const.TYPE_MAIN_BALANCE][coin].balance;
      }
      if (coin === "usd") {
        if (!this.prices["usd_btc"]) return "0";
        return new BigNumber(`${this.accountBalances[Const.TYPE_MAIN_BALANCE]["usd"].balance || 0}`)
          .div(`${this.prices["usd_btc"].price}`)
          .toString();
      }
      const pair = `btc_${coin}`;
      if (!this.prices[pair]) return "0";

      let coinBalance = this.accountBalances[Const.TYPE_MAIN_BALANCE][coin].balance || 0;
      let coinPrice = this.prices[pair].price;
      return new BigNumber(coinBalance).mul(coinPrice).toString();
    },
    init() {
      noti_extent: false;
      let token = {
        token: Cookies.get('access_token', { domain: process.env.VUE_APP_PUBLIC_URL }) || ""
      }
      rf.getRequest("UserRequest")
        .getCurrentUser()
        .then(res => {
          this.email = window._.get(res, "data.email", "");
          this.updateUser(res.data);
        });
      rf.getRequest("UserRequest")
        .getBalance()
        .then(res => {
          this.onBalanceUpdated(res.data);
        });
      rf.getRequest("PriceRequest")
        .getPricesNow()
        .then(res => {
          this.onPricesUpdated(res.data);
        });

      // rf.getRequest("UserRequest")
      //   .accessToken(token)
      //   .then(res => {
      //     // this.onPricesUpdated(res.data);
      //   }).catch(err => {
      // });
    },
    updateUserLocale(locale) {
      AuthenticationUtils.setLocale(locale);
      this.news = [];
      this.getNewsData();
      this.getUserNewsInfo();
      let params = {
        lang: locale
      };
      this.$i18n.locale = locale
      // localStorage.setItem('locale', locale)
      Cookies.set('locale', locale || 'en',  { domain: process.env.VUE_APP_PUBLIC_URL })
      window.i18n.locale = locale || 'en'
      // this.$store.commit('updateLocalLang', locale)
      this.$store.dispatch('setLang', locale || 'en')
      if (this.isAuthenticated) {
        rf.getRequest("LocaleRequest").setUserLocale(params).then(res => {
          if (res) {
            window.location.reload()
          }
        });
      }
    },
    getArticles() {
      rf.getRequest("ZendeskRequest")
        .getArticlesAnnouncement()
        .then(res => {
          this.articles.push(res.articles);
        })
        .catch(error=>{
        });
    },
    gotoUrl(url) {
      window.location.href = url;
    },
    getNewsData(page = 1) {
      let params = {
        'page' : page,
        'locale' : Cookies.get('locale', { domain: process.env.VUE_APP_PUBLIC_URL }) == 'en' ? 'en-us' : Cookies.get('locale', { domain: process.env.VUE_APP_PUBLIC_URL }),
      }
      return rf.getRequest("NewsRequest")
        .getList(params)
        .then(res => {
          this.newsMaxPage = (res.data ? res.data.last_page : 1) || 1;
          return this.mappingNewsData(res);
        })
        .catch(error => {
          console.log(error);
        });
    },
    mappingNewsData(res) {
      let data = res.data ? res.data.data : [];
      for(let i = 0, length = data.length; i < length; i++) {
        let currentItem = data[i];
        this.news.push(currentItem);
      }
      this.markReadedNews();

      return this.news;
    },
    getUserNewsInfo() {
      let params = {
        'locale' : AuthenticationUtils.getLocale() == 'en' ? 'en-us' : AuthenticationUtils.getLocale(),
      }

      return rf.getRequest("NewsRequest")
        .getUserNewsInfo(params)
        .then(res => {
          if (res.data) {
            let resData = res.data;
            this.userNewsInfo = resData;
            this.newsCountUnRead = resData.count_unread;
            this.newsReadIds = resData.news_read_ids;
          }
        })
        .catch(error => {
          console.log(error);
        });

    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight }}) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.loadMoreNews()
      }
    },
    loadMoreNews() {
      if (this.currentNewsPage < this.newsMaxPage) {
        this.getNewsData(++this.currentNewsPage);
      }
    },
    markReaded(item) {
      if (item.isRead) return true;
      if (!this.isAuthenticated) return true;

      rf.getRequest("NewsRequest")
        .markReaded(item)
        .then(res => {
          if (res.success && res.data) {
            let resData = res.data;
            this.newsReadIds && resData.id && this.newsReadIds.push(resData.id);
            if(this.newsCountUnRead > 0) {
              this.newsCountUnRead--;
            }
            if(this.userNewsInfo.count_unread > 0) {
              this.userNewsInfo.count_unread--;
            }
            this.markReadedNews();
          }
        });
    },
    markReadedNews() {
      for(var i = 0, length = this.news.length; i < length; i++) {
        let currentItem = this.news[i];
        if (this.newsReadIds.includes(currentItem.id)) {
          currentItem.isRead = 1;
        }
      }
    },
    getInsuranceFund() {
      return rf.getRequest("InsuranceRequest")
        .getInsuranceFund()
        .then(res => {
          this.insurance = res.data;
        })
        .catch(error => {
          console.log(error);
        });
    },
    isAppMobile() {
      if (this.$route.path.includes('/terms') && this.$route.query.isMobileApp == 1) {
        this.statusApp = true
      } else if (this.$route.path.includes('referral') && this.$route.query.isMobileApp == 1) {
        this.statusApp = true
      }
    },
    getSettingShowLeaderboard(){
      rf.getRequest("LeaderboardRequest")
        .getLeaderboardSetting({"type": "spot"})
        .then(res => {
          if(res.success){
            let result = res ? res.data: null;
            this.isShowTradingVolumeRankingSpot = result && result.value === "1";
          }
        });
    },
    toggleTheme() {
      const activeTheme = Cookies.get("user-theme", { domain: process.env.VUE_APP_PUBLIC_URL });
      if (activeTheme === "light-theme") {
        this.setTheme("dark-theme");
        this.$store.dispatch('setUserThemeMode', "dark-theme")
      } else {
        this.setTheme("light-theme");
        this.$store.dispatch('setUserThemeMode', "light-theme")
      }
    },

    getTheme() {
      if (this.$route.query.theme) {
        return this.$route.query.theme === 'light' ? 'light-theme' : 'dark-theme';
      }
      return Cookies.get("user-theme", { domain: process.env.VUE_APP_PUBLIC_URL });
    },

    getLanguage() {
      if (this.$route.query.lang) {
        return this.$route.query.lang;
      } 
      return Cookies.get('locale', { domain: process.env.VUE_APP_PUBLIC_URL })
    },

    setTheme(theme) {
      Cookies.set('user-theme', theme, { domain: process.env.VUE_APP_PUBLIC_URL });
      if (!window.location.pathname.includes('/term')) {
        Cookies.set('locale', Cookies.get("locale") || "en", { domain: process.env.VUE_APP_PUBLIC_URL })
      }
      this.userTheme = theme;
      this.$store.dispatch('setUserThemeMode', theme)
      document.documentElement.className = theme;
    },
    
    setLanguage(lang) {
      Cookies.set('locale', lang, { domain: process.env.VUE_APP_PUBLIC_URL });
      document.documentElement.lang = lang;
    },

    getMediaPreference() {
      const hasDarkPreference = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      if (hasDarkPreference) {
        return "dark-theme";
      } else {
        return "light-theme";
      }
    },
    resizeHandler() {
      this.sizeScreen = window.innerWidth;
      this.menuActive = false
    },
    clickOutside(event) {
      if(event.target.className !== 'menu-button') {
        this.menuActive = false
      }
    },

    mouseOverDetectScreen(value) {
      if (this.sizeScreen > 1300) {
        return value = true
      }
    }
  },
  mounted() {
    this.getUserNewsInfo()
      .then((res) => {
        this.getNewsData();
      });

    this.getInsuranceFund();
    this.getSettingShowLeaderboard();

    let _this = this;
    this.$on('CHANGED_PASSWORD', (type, content, params, configs) => {
      _this.isAuthenticated = false;
    });
    this.isAppMobile()
    const initUserTheme = this.getTheme() || this.getMediaPreference();
    this.setTheme(initUserTheme);
    window.addEventListener("resize", this.resizeHandler);
    if (window.location.pathname.includes('/term')) {
      const initUserLanguage = this.getLanguage();
      this.setLanguage(initUserLanguage);
      this.$i18n.locale = new URLSearchParams(window.location.search).get('lang');
    }
  },
  created() {
      if (this.isAuthenticated) {
          this.init();
      }
  },
    beforeRouteUpdate (to , from, next) {
    this.menuActive = false
    next()
  },
};

</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
@import '@/assets/sass/variables';

.clearfix-order {
   content: "";
  clear: both;
  display: table;
}
.count-icon-null{
  background-color:$color-gray-dark !important;
}
.activeDropdown {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
}
.page-container {
  margin: auto;
  min-height: calc(100% - 65px - 252px);
}
.f-fr {
  float: right;
}
.f-fl {
  float: left;
}
#pingpongchat {
  position: fixed;
  top: 65%;
  right: 0;
  margin-right: 20px;
  z-index: 10;
  img {
    height: 52px;
  }
}

#header {
  position: relative;
  z-index: 2021;
  a {
    text-decoration: none !important;
  }
  background-color: var(--background-color-primary);
  //border-bottom: var(--border-header) 2px solid;
  .nav-m {
    background-color: var(--background-color-primary);
    z-index: 2021;
  }
  .block_content {
    .custom-left-bar {
      display: flex;
      align-items: center;
      float: left;
      max-width: 72%;
      width: 55% !important;
      height: 64px;
      .nav-page-head-bar {
        .btn-wallet {
          border: 0px;
          background-color: transparent;
          border-radius: 3px;
          font-size: $font-root;
          line-height: 20px;
          float: left;
          height: 35px;
          padding: 7px 17px;
          color: var(--text-primary-color);
          font-family: $font-family-roboto-medium;
          .icon {
            float: right;
            line-height: 20px;
            font-size: 10px;
            margin-left: 8px;
          }
        }
        &:hover, &:focus {
          outline: none;
          .btn-wallet {
            background-color: var(--active-bg-color);
            color: $color-orange;
          }
        }


        .news-count-icon {
          position: absolute;
          top: 5px;
          text-align: center;
          font-size: 9px;
          padding: 2px 3px;
          line-height: .9;
          border-radius: 5px;
          border-radius: 10.5px;
          background-color: $color-denim;
        }
        .btn-news-icon {
          border: 0px;
          background-color: transparent;
          border-radius: 3px;
          font-size: $font-root;
          line-height: 20px;
          float: left;
          height: 35px;
          padding: 7px 17px;
          color: var(--text-primary-color);
          font-family: $font-family-roboto-medium;
          display: inline-block;
          padding-right: 21px;
          .icon {
            float: right;
            line-height: 20px;
            font-size: 10px;
            margin-left: 8px;
          }
        }
        &:hover, &:focus {
          .btn-news-icon {
            background-color: var(--background-color-primary);
            color: $color-orange;
          }
        }

        .news-item {
          background-color: red;
        }

        .sub_nav_news {
          width: 640px;
          max-height: 347px;
          overflow-y: auto;
          left: 0;
          box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.16);
          border-radius: 3px;
          background-color: $color-white;
          .sub_nav_page_head {
            background-color: $color-white;
            margin: 0;
            li.readed {
              > a {
                border-bottom: 1px solid $color-bright-gray !important;
              }
              .name-news-item, .right-news-item {
                font-family: $font-family-roboto;
                font-weight: normal !important;
              }
            }
            > li {
              padding-left: 20px;
              padding-right: 20px;
              &:last-child {
                border-bottom: 0px;
              }

              > a {
                border-bottom: 1px solid $color-bright-gray !important;
                display: block;
                width: 100%;
                overflow: hidden;
                padding: 0px;
                padding: 13px 0px;
                background-color: transparent;
                color: $color-grey-dark;
                font-size: $font-root;
                border-bottom: 1px solid $color-alabaster;

                .right-news-item {
                  display: inline-block;
                  float: right;
                  width: 80px;
                  text-align: right;
                }
                .left-news-item {
                  display: block;
                  width: calc(100% - 80px);
                  float: left;
                  .name-news-item {
                    margin: 0px;
                    font-size: $font-root;
                    line-height: 20px;
                    font-weight: bold;
                  }
                  .des-news-item {
                    display: block;
                    color: $color-grey;
                    font-family: $font-family-roboto;
                  }
                  .name-news-item, .des-news-item {
                    text-overflow: ellipsis;
                    width: 500px;
                    white-space: nowrap;
                    overflow: hidden;
                  }
                }
              }
              &:hover {
                background-color: $color-bright-gray;
                a {
                  color: $color-grey-dark;
                  border-bottom-color: $color-bright-gray;
                }
                border-bottom: 1px solid $color-bright-gray;
                &:last-child {
                  border-bottom: 0px;
                }
              }
            }
          }
        }


      }
      .img_logo {
        display: inline-block;
        float: left;
        padding: 18px 0 14px 0;
        outline: none;
      }
      .login-register-bar {
        display: none;
      }
    }
    .btn-exchange-page {
      color: var(--text-primary-color);
      float: left;
      margin: 16px 0 11px 0;
      font-size: $font-medium-bigger;
     padding: 0;
      font-family: $font-inter-regular;
      outline: none;
      &:hover {
        outline: none;
        color: $color-caribbean-green !important;
      }
      &.info_hacking {
        height: auto;
        margin: 5px 0px 4px 10px;
        max-height: 60px;
        text-align: center;

        &:hover, &:focus {
          background-color: transparent;
        }
        &.active {
          box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.125);
          color: $color-white;
          background-color: transparent;
        }
      }
    }
    .list_btn_ex {
      display: flex;
      align-items: center;
      margin-left: 70px;
      gap: 16px;
      @media screen and (max-width: 1300px) {
        margin-left: 0px;
        gap: 0px;
      }
      &.list_btn_demo {
        padding-left: 8px;
      }
      /* ++++++++++++++ */
      &.list_btn_btc {
        font-size: 13px;
        color: $color-white;
        text-align: center;
        margin: 12px 0px 0px;
        &:hover {
          cursor: pointer;
        }
      }
      &.list_btn_btc .btc_tit {
        margin-bottom: 5px;
      }
      .textEllipse {
        max-width: 80px;
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        vertical-align: middle;
      }
      & div {
        .btn-lang-head {
          .active {
            color: $color-caribbean-green;
          }
        }
      }
      .btn-responsive {
        display: none;
      }
      /* ++++++++++++ */

    }

    /* ++++++++++++ */
    .tipBtn {
      position: relative;
      .tipTex {
        position: absolute;
        top: 120%;
        left: 50%;
        transform: translateX(-50%);
        width: auto;
        height: auto;
        color: #fff;
        background-color: rgba(0,0,0,.5);
        padding: 6px 10px;
        border-radius: 8px;
        margin-left: 12px;
        display: none;
      }
      .tipTex::after {
        position: absolute;
        left: 50%;
        top: -67%;
        transform: translateX(-50%);
        width: 0px;
        height: 0px;
        content: '';
        border: 10px solid;
        border-color: transparent transparent rgba(0, 0, 0, .5) transparent;
        display: none;
      }
    }
    .tipBtn:hover {
      .tipTex,
      .tipTex::after {
        display: block;
      }

    }
    /* ++++++++++++ */
    .custom-right-bar {
      display: flex;
      align-items: center;
      float: right;
      max-width: 35%;
      .entry-wrapper{
        margin: 17px 10px 13px 10px;
        float: left;
      }
      .dropdown-block .btn-lang-head {
        padding: 7px 7px;
      }
    }
    .login-register-bar {
      float: left;
      .item {
        color: $color-caribbean-green;
        line-height: 20px;
        float: left;
        margin: 16px 5px 0 10px;
        font-size: 16px;
        line-height: 19px;
        padding: 7px 5px;
        outline: none;
      }
      .lowerCase {
        padding: 7px 8px !important;
        text-transform: none !important;
      }
      .register {
           background-color: $color-caribbean-green;
          color: $color-grey-dark;
          border-radius: 4px;
          padding: 8px 10px;
          &:hover {
            background-color: $color-aquamarine;
          }
      }
    }

    .dropdown-block {
      position: relative;
      display: flex;
      margin: 15px 0px 12px 0px;
      outline: none ;
      gap: 16px;
      @media screen and (max-width: 1300px) {
        gap: 0px;
      }
      .btn-lang-head {
        border-radius: 3px;
        line-height: 19px;
        float: left;
        margin: 0;
        font-family: $font-inter-regular;
        color: var(--text-primary-color);
        //letter-spacing: 0.9px;
        padding: 0;
        font-size: $font-medium-bigger;
        outline: none;
        img {
          margin-bottom: 2px;
          margin-left: 4px;
        }
        &.left_bar{
          color: var(--text-primary-color);
          float: right;
          right: 0
        }
        background-color: transparent;
        .icon-arrow1 {
          font-size: $font-mini-mini;
          float: right;
          line-height: 20px;
          position: absolute;
          margin-left: 8px;

        }
        .icon-arrow-up{
          display: inline-flex;
          font-size: $font-medium-bigger;
          transform: scaleX(1.7);
          transform-origin: 0 0;
          position: absolute;
          margin-left: 5px;
          top: 10px;
        }
        &.lang {
          color: var(--text-primary-color)
        }
        &.supportTab {
          padding: 7px 0px;
        }
      }
      .dropdown-head {
        position: absolute;
        top: 100%;
        margin: 0px;
        right: -25px;
        z-index: 10;
        min-width: 200px;
        width: 100%;
        border-radius: 8px;
        display: flex;
        z-index: 2100;
        &.right-side{
          left: 0;
          > .tradeTab {
            display: flex;
            align-items: center;
            flex-direction: column;
            min-width: 230px;
            > .arrow-right{
              float: right;
            }
          }
        }
        &.dropdown-large {
          left: 0;
          min-width: 280px !important;
          @media screen and (max-width: 1330px){
            min-width: fit-content;
            .sub-child {
              margin-top: 0;
              margin-right: 5px;
            }
          }
          .subFunds{
            width: 100%;
          }

          .action-wallet {
            display: flex;
            justify-content: space-between;
            margin: 8px 12px;

            a {
              color: var(--text-primary-color);
              font-size: 14px;
            }

            .action-wallet-item {
              text-align: center;
              cursor: pointer;
              padding: 8px;

              &:hover {
                background-color: var(--hover-item-nav);
                border-radius: 8px;
              }
               img {
                 margin-bottom: 6px;
               }
            }
          }
          .sub-child {
            padding: 0;
          }
        }
        &.user-dropdown {
          right: -25px;
          left: auto;
        }
        > ul {
          padding: 15px;
          margin-top: 3px;
          //background-color: $dropdown-bg-color;
          background-color: var(--background-color-primary);
          //border: $border-bottom-bold;
          border-radius: 8px;
          /* border:$border-bottom-bold; */
          color: $pure_color_black;
          overflow: hidden;
          box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
          width: 100%;
          li {
            display: block;
            width: 100%;
            //border-bottom: $border-bottom-bold;
            color: var(--text-primary-color);
            a{
              display: flex !important;
              align-items: center;
              span{
                font-family: $font-inter-regular;
                color: var(--text-primary-color);
                font-size: $font-medium-bigger;
                margin-left: 10px;
                width:  100%;
                &.icon-arrow{
                  width: fit-content;
                  > img{
                    float: right;
                  }
                }
              }

            }
            &:hover {
              background-color: var(--hover-item-nav);
              border-radius: 8px;
              a {
                span {
                  color: var(--text-primary-color);


                }
                & div {
                  //background-color: red;
                  background-image: url("../src/assets/images/icon/arrow-right.svg") ;
                  background-repeat: no-repeat;
                  width: 32px;
                  height: 15px;
                }
              }

            }
            &:last-child {
              border-bottom: 0;
            }
            a {
              display: block;
              width: 100%;
              font-size: $font-small;
              color: $color-white;
              line-height: 20px;
              padding: 10px 13px;

            }
          }
        }
        .subLang {
          width: 100%;
          //font-family: $font-family-roboto-medium;
          font-family: $font-inter-regular;
          li {
            a {
              font-size: $font-inter-regular;
            }
          }
        }
      }
    }
    .lang-bar {
      &:hover, &:focus {
        outline: none;
      }
    }
    .user-bar {
      .btn-icon-user-hd {
        border-radius: 3px;
        line-height: 20px;
        float: left;
        margin: 0;
        color: $color-white;
        letter-spacing: 0.9px;
        height: 35px;
        padding: 7px 14px;
        font-size: $font-smaller;
        border: solid 1px transparent;
        background-color: $color-orange;
        .txt_email_us {
          display: inline-block;
          width: 125px;
          overflow: hidden;
          float: left;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .icon-arrow1 {
          font-size: $font-mini-mini;
          float: right;
          line-height: 20px;
          margin-left: 5px;
        }
      }
      ::before {
        border-left: 6px solid black;
        height: 200px;
        position:absolute;
        left: 50%;
        background-color: red;
      }
      &:hover, &:focus {
        outline: none;
        .btn-icon-user-hd {
          background-color: $color-orange;
          border-color: $color-orange;
        }
      }
      .subUser {
        width: 100%;
        li {
          a {
            padding: 10px 16px;
          }
        }
        .txt_user_hd {
          font-size: $font-small;
          color: $color-alto;
          padding: 0px 0px;
          padding-top: 0px;
          padding-bottom: 0px;
        }
        .content_user_hd {
          padding: 0px 0px;
          font-family: $font-family-roboto-bold;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .logout {
      font-family: $font-family-roboto-bold;
      background: transparent;
      border: 0px;
      line-height: 30px;
      padding: 0px 0px;
      margin: 0px;
      color: $color-white;
      padding-right: 10px;
    }
    .nav-page-head-bar {
      position: relative;
      display: inline-block;
      margin: 17px 0px 13px 10px;
      float: left;
      .btn-nav-page-head {
        border-radius: 3px;
        background-color: transparent;
        border: solid 1px transparent;
        font-size: $font-title-size-small;
        line-height: 20px;
        float: left;
        margin: 0;
        height: 35px;
        padding: 5px 10px 6px 10px;
        //color: $color-white;
        color: var(--text-primary-color);
        letter-spacing: 0.9px;
        width: 44px;
      }
      &:hover, &:focus {
        outline: none;
        .btn-nav-page-head {
          //background-color: $active-bg-color;
          background-color: var(--active-bg-color);
          /* border-color: $color-eden-light; */
        }
      }
    }
    .dropdown-page-head {
      position: absolute;
      top: 100%;
      margin: 0px;
      right: 0px;
      z-index: 1200;
      width: 165px;
      ul {
        margin-top: 3px;
        /* background-color: $color-eden-light; */
        background-color: $dropdown-bg-color;
        border-radius: 3px;
        overflow: hidden;
        /* border: $border-bottom-bold; */
        li {
          // display: block;
          width: 100%;
          border-bottom: $border-bottom-bold;
          a {
            display: block;
            width: 100%;
            padding: 10px 10px 10px 20px;
            line-height: 20px;
            font-family: $font-family-roboto-medium;
            font-size: $font-root;
            color: $color-white;
          }
          &:last-child {
            border-bottom: 0;
          }
          &:hover a {
            color: $color-orange;
          }
        }
      }
    }
  }
  .cl-fix{
    display: inline-block;
    border-left: 2px solid $color-gray-alto;
    height: 18px;
    margin: 23px 0 15px 0;
  }
  &.in_exchangePage {
    padding: 0 40px;
    background-color: var(--background-color-primary);
    .user-dropdown{
      left: auto !important;
    }
  }
  .active {
    color: $color-caribbean-green;
  }
  .responsiveTab , .responsiveTabRight {
    display: none;
  }

  #menu-toggle {
    display: none !important;
  }

  .menu-button {
    background: transparent;
  }

  .nav-active {
    position: fixed;
  }
  &.in-home {
    background: none;
    z-index: 2023;

    .nav-m {
      background: none;
    }

    .block_content {
      .btn-exchange-page {
        color: $color-white;
        &:hover {
          color: $color-caribbean-green;
        }
      }
      .dropdown-block {
        .btn-lang-head {
          color: $color-white;
          &.left_bar {
            color: $color-white;
          }
          &.lang {
            color: $color-white;
          }

        }
      }
    }
  }
  .btn-child {
          padding: 10px 13px !important;
          border-radius: 8px!important;
          .t-btn-child {
            img {
              margin-right: 8px;
            }
          }
          img {
            margin: 0;
          }
            &:hover {
              background-color: var(--hover-item-nav) !important;
            }
          }
    .list-dropdown-order {
      width: 100%;
      ul li {
          width: 97% !important;
          margin-left: 5px;
          &:hover {
            color: $color-caribbean-green;
            //background-color:  !important;
       }
       a {
        span {
          margin-left: 0 !important;
            &:hover {
                color: $color-caribbean-green !important;
          }
        }
       }
      }
    }

}

@media screen and (max-width: 1660px) {
  .custom-right-bar{
    .dropdown-large {
      left: -130px !important;
    }
  }
}
@media screen and (max-width: 1500px) {
  #header {
    .nav-m {
      padding: 0 16px;
    }
  }
}
@media screen and (max-width: 1440px) {
  #header.in_exchangePage{
    padding: 0 16px;
  }
  #header .nav-m{
    padding: 0;
  }
}


@media screen and (max-width: 1300px) {
  #header {
    .block_content {
      .custom-left-bar {
        max-width: 100%;
        width: 60% !important;
        .img_logo {
          padding: 15px 10px 14px 10px;
          img {
            width: 119px;
          }
        }
      }

    }
  }
  .responsiveTab {
    position: absolute;
    left: 90%;
    top: 50%;
    display: block !important;
  }

  .page-container {
    min-width: 100%;
    //margin: auto;
    .custom-right-bar{
      display: none;
    }

  }

  .menu-button-container {
    display: flex !important;
  }
  #menu-toggle {
    display: block !important;
  }
}

@media screen and (max-width: 1300px) {
  #header{
    //border-bottom: 2px solid var(--border-header);
    height: 58px !important;

    .nav-m {
      padding: 0;
    }
    .block_content{
      display: flex;
      justify-content: space-between;
      //border-bottom: 2px solid var(--background-color-second);
      .custom-left-bar{
      .list_btn_ex {
        & a {
          width: 100%;
          display: flex !important;
          padding: 0;
        }
        .authenticated-bar {
          height: 500px;
          width: 100%;
          display: flex ;
          justify-content: center;
          align-items: center;
        }
        .login-register-bar {
          display: flex ;
          //flex-direction: row-reverse;
          width: 100%;
          //height: 500px;
          a {
            padding: 7px 5px;
            align-items: center;
            justify-content: center;
          }

        }

      }
      .list_btn_ex{
        //touch-action: pan-x;
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 100%;
        left: 0;
        background-color: var(--background-color-primary);
        width: 315px;
        max-width: 100%;
        height: 91vh;
        padding: 10px 20px;
        z-index:2010;
        overflow-y: auto;
        //position: fixed;
        .dropdown-block {
        display: flex;
        width: 100%;
        align-items: baseline;
        flex-direction: column;
        margin: 0 !important;
        .left_bar {
          //padding: 0;
          display: flex;
          justify-content: space-between;
          width: 100%;
          align-items: center;
          padding: 11.8px 0;
        }
        .dropdown-head {
          position: unset !important;
          box-shadow: none !important;
          > ul {
            padding: 0;
          }
        }
      }
      .btn-responsive {
        display: block;
      }
    }
    }
    .custom-right-bar{
      width: 100% !important;
      left: 0 !important;
      padding-right: 0;
      .dropdown-large {
        left: -130px !important;
      }
    }
    .responsiveTabRight {
      display: block;
      float: right;
      padding: 23px 19px;
      //background: blue;
      width: 58px;
      .menu-toggle {
        .menu-button-container {
          margin: 0;
        }
      }
      &.active{
        background: $color-caribbean-green;
      }
    }
  }
    &.in_exchangePage {
      padding: 0px;
    }
    &.in-home {
      background-color: $color-eerie-black;
      .nav-m {
        background-color: $color-eerie-black;
      }
      .block_content {
        border-bottom: none;
      }
      .block_content {
        .dropdown-block {
          .btn-lang-head {
            color: $color-white;
            &.lang {
              color: $color-white;
            }
            &.left_bar {
              color: var(--text-primary-color);
            }
          }
        }
        .btn-exchange-page {
          color: var(--text-primary-color);

        }
      }
    }
  }
  .menu-head{
    display: flex;
    flex-direction: row-reverse;
    width: 93% !important;
    max-width: 100%;
    align-items: center;
    border-radius: 0px !important;
    //margin-right: 16px;
    gap: 7px;

    .login-register-bar {
      display: none !important;
      & a {
        width: fit-content;
      }
    }
    .btn-responsive {
      display: none !important;
    }
  }
  .menu-button-container {
    display: flex;
  }
  .btn-lang-head{
    margin: 15px 0px;
  }

  .dropdown-head{
    .tradeTab{
      min-width: 100% !important;
      padding: 0 !important;
      //padding-top: 10px !important;

    }
    > ul {
      box-shadow: none !important;
    }
    > .subLang {
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.3) !important;
    }
  }
  .custom-right-bar{
    .dropdown-large {
      > ul {
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1) !important;
      }
  }
  }

  .cl-fix{
    display: none !important;

  }
}
@media screen and (max-width: 992px) {
  #header{
    .block_content{
      height: 58px;
    }
  }
}


@media screen and (max-width: 562px) {
  .menu-head{
    gap: 5px;
    margin-right: 10px;
    .btn-lang-head{
      padding: 7px 2px !important;
    }
    .switch-label-layout {
      padding: 0px
    }
  }
}

</style>
<style lang="scss" >
@import '@/assets/sass/common.scss';

body::-webkit-scrollbar {
  display:none
}
html {
  /*隐藏滚动条，当IE下溢出，仍然可以滚动*/
  -ms-overflow-style:none;
  /*火狐下隐藏滚动条*/
  /* 未找到解决方案 */
}
.switch-checkbox {
  display: none;
}

.switch-label-layout {
  align-items: center;
  border-radius: 8rem;
  cursor: pointer;
  display: flex;
  font-size: calc(5rem * 0.3);
  padding: calc(7rem * 0.1);
  transition: background 0.5s ease;
  justify-content: space-between;
  z-index: 1;
  margin: 0;
  outline: none;
  width: 30px;
}
.img_theme_mode {
  height: 18px;
}

.switch-toggle-checked {
  transform: translateX(calc(8rem * 0.6)) !important;
}
</style>
