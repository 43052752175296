<template>
  <!-- NEW BALANCE LAYOUT -->
  <div id="balance" class="page_container">
    <confirmation-modal/>
  <div class="balance-container">
    <div class="box-title title-medium-small">{{ $t('menu.mam_master') }}</div>
    <label class="btn btn-info btn-approve pull-right">{{ $t('mam.id') }} <span v-if="masterAccount && masterAccount.status != 'closed'">{{ mamId }}</span></label>
    <div class="clearfix clearfix-20"></div>
    <!-- end-box-title -->
    <ul class="tab_table_investors clearfix">
      <li class="item" :class="{ active : tabs.overview}" @click="setActiveTab('overview')">
          {{$t('mam.overview') }}
      </li>
      <li class="item" :class="{ active : tabs.follwers}" @click="setActiveTab('follwers')">
          {{$t('mam.followers') }}
      </li>
      <li class="item" :class="{ active : tabs.requests}" @click="setActiveTab('requests')">
          {{$t('mam.requests') }}
      </li>
      <li class="item" :class="{ active : tabs.commissionHistory}" @click="setActiveTab('commissionHistory')">
          {{$t('mam.commission_history') }}
      </li>
      <li class="item-right" v-if="tabs.requests">
        <label class="switch">
          {{$t('mam.show_pending_only') }}
          <input type="checkbox" @click="isShowPendingOnly = !isShowPendingOnly">
          <span>
            <i></i>
          </span>
        </label>
      </li>
    </ul>

    <div class="clearfix"></div>
    <div class="tab-content">
      <div class="table-container table-history" v-if="tabs.overview">
        <master-overview/>
      </div>

      <div class="table-container table-history" v-if="tabs.follwers">
        <followers/>
      </div>

      <div class="table-container table-history" v-if="tabs.requests">
        <master-requests/>
      </div>

      <div class="table-container table-history" v-if="tabs.commissionHistory">
        <commission-history/>
      </div>
    </div>
      <!-- end table container -->
    </div>
    <!-- end-balance-container -->
  </div>
  <!-- END NEW BALANCE LAYOUT -->
</template>
<script>
  import rf from '@/request/RequestFactory'
  import ConfirmationModal from '@/components/shared_components/common/ConfirmationModal.vue';
  // import mam from '../../../config/mam';
  import MasterOverview from "./master/MasterOverview";
  import Followers from './master/Followers';
  import MasterRequests from "./master/MasterRequests";
  import CommissionHistory from "./master/CommissionHistory";

  export default {
    components: {
      ConfirmationModal,
      MasterOverview,
      Followers,
      MasterRequests,
      CommissionHistory
    },
    data() {
      return {
        masterAccount: null,
        mamId: null,
        isList: true,
        isHistory: false,
        fund: {},
        tabs: {
          overview: true,
          follwers: false,
          requests: false,
          commissionHistory: false,
        },
        isShowPendingOnly: false,
      }
    },
    watch: {
      isShowPendingOnly(value) {
        this.$broadcast('showPendingMaster', value);
      }
    },
    methods: {
      getEventHandlers() {
        return {
          hideCommonModal: this.onHideCommonModal,
        };
      },
      onHideCommonModal(modalName) {
        if(modalName == 'CreateMasterAccount') {
          this.initData();
        }
      },
      setActiveTab (value) {
        this.tabs = _.map(this.tabs, () => false);
        this.tabs[value] = true;
      },
      // getDataInvestor(params) {
      //   params.investorList = true;
      //   return rf.getRequest('MamRequest').index(params)
      // },
      refreshDevice() {
        this.$refs.datatable.refresh();
      },
      initData () {
         rf.getRequest("MamRequest").getMasterAccount()
          .then(res => {
            if (res && res.data) {
              this.masterAccount = res.data;
              this.mamId = res.data.id;
            }
          })
          .catch(error => {
          })
      }
    },
    created () {
      this.initData();
    }
  }
</script>
<style lang="scss" scoped>
  @import "@/assets/sass/variables";
  @import "@/assets/sass/common.scss";

  .switch{
    margin-bottom: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    input:checked + span{
      background-color: $color-jungle-green;
    }
    span{
      background-color: #d8d8d8;
    }    
  }

  .formInput {
    .checkbox_address {
      line-height: 23px;
      font-size: $font-small;
      font-weight: 400;
      color: $color-grey-dusty;
      margin-top: 12px;
      input{
        display: none;
      }
      span{
        content: "";
        display: inline-block;
        border: 1px solid $color-grey;
        width: 14px;
        height: 14px;
        top: 2px;
        left: 0px;
        border-radius: 0px;
        margin-right: 8px;
        position: relative;
        background-color: transparent;
      }
      input:checked+span:after {
        opacity: .9;
        content: "";
        position: absolute;
        width: 13px;
        height: 7px;
        background: transparent;
        top: 0px;
        left: 0;
        border: 3px solid $color-jungle-green;
        border-top: none;
        border-right: none;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
      }
    }
  }
  .clearfix-20 {
    display: block;
    clear: both;
    height: 20px;
  }

  .box-title {
    float: left;
  }

  .tab_table_investors {
    display: block;
    width: 100%;
    margin: 0 0 0 0;

    .item {
      display: inline-block;
      float: left;
      color: $color-grey-dark;
      font-family: $font-family-roboto-medium;
      font-size: $font-medium-bigger;
      line-height: 20px;
      padding: 14px 25px 13px 25px;
      background-color: transparent;
      cursor: pointer;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      min-height: 47px;

      &.active {
        background-color: $color-white;
        color: $color-jungle-green;
      }
    }
    .item-right {
      float: right;
    }
  }

  .button-join-funds {
    float: right;
    width: 90px;
    height: 30px;
    border-radius: 3px;
    background-color: $color-jungle-green;
    border: 1px solid $color-jungle-green;
    text-align: center;
    line-height: 20px;
    padding: 4px;
    letter-spacing: 0.9px;
    color: $color-white;
    font-family: $font-family-roboto-bold;
    text-transform: uppercase;

    .icon-plus {
      font-size: 10px;
      position: relative;
      margin-right: 3px;
      top: -1px;
    }

    &:hover, &:active, &:focus {
      background-color: $color-blue-custom;
      border-color: $color-blue-custom;
    }
  }

  .as_rv_funds {
    color: $color-blue-custom;

    .btn-assign {
      color: $color-blue-custom;

      &:hover {
          text-decoration: underline;
      }
    }

    .btn-revoke {
      color: $color-blue-custom;

      &:hover {
          text-decoration: underline;
      }
    }
  }

  .btn-reject {
    min-width: 70px;
    height: 25px;
    border-radius: 3px;
    border: solid 1px $color-jungle-green;
    color: $color-jungle-green;
    letter-spacing: 0.9px;
    font-size: $font-smaller;
    line-height: 20px;
    padding: 2px;
    overflow: hidden;
    text-transform: uppercase;
    text-align: center;
    background-color: transparent;
    float: right;
    margin-left: 10px;

    // &:hover, &:active, &:focus {
    //   background-color: $color-blue-custom;
    //   border-color: $color-blue-custom;
    //   color: $color-white;
    // }
  }

  .btn-approve {
    min-width: 80px;
    height: 25px;
    border-radius: 3px;
    border: solid 1px $color-jungle-green;
    color: $color-white;
    letter-spacing: 0.9px;
    font-size: $font-smaller;
    line-height: 20px;
    padding: 2px;
    overflow: hidden;
    text-transform: uppercase;
    text-align: center;
    background-color: $color-jungle-green;
    float: right;
    margin-left: 10px;

    // &:hover, &:active, &:focus {
    //   background-color: $color-blue-custom;
    //   border-color: $color-blue-custom;
    //   color: $color-white;
    // }
  }

  #balance {
    #investors-table {
      background-color: $color-white;

      .box_table2 {
        margin-bottom: 22px;
      }

      table {
        border: 0px;

        thead {
          background-color: $color-white;
          border: 0px;

          tr {
            height: 48px;

            th {
              font-family: $font-family-roboto-medium;
              font-size: $font-small;
              padding: 8px 0px 8px 20px;
              height: 48px;

              &:last-child {
                padding: 8px 20px 8px 0px;
              }

              &[data-sort-order='asc'] {
                color: $color-jungle-green;
              }

              &[data-sort-order='desc'] {
                color: $color-jungle-green;
              }
            }
          }
        }

        tbody {
          text-align: left;

          tr {
            &.canceled {
              opacity: 0.3
            }

            height: auto;
            font-size: $font-small;

            td {
              font-size: $font-root;
              font-weight: 500;
              color: $color-grey-dark;
              font-family: $font-family-roboto-medium;
              line-height: 21px;
              padding: 5px 0px 5px 20px;
              height: 38px;
              overflow: initial;
              vertical-align: middle;
              height: 38px;

              &.blue {
                color: $color-jungle-green;
              }

              &.red {
                color: $color-denim;
              }

              &:last-child {
                text-align: left;
                padding: 5px 20px 5px 0px;
              }
            }
          }
        }
      }
    }
  }

  .groupd_pl_funds {
      position: relative;
      display: inline-block;
      float: left;

      .sub_pl {
        position: absolute;
        min-width: 150px;
        border-radius: 1px;
        box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.22);
        background-color: $color-white;
        top: 100%;
        margin-left: -75px;
        left: 50%;
        z-index: 5;
        display: none;
        line-height: 25px;
        font-size: $font-root;
        color: $color-grey-dark;
        padding: 15px 15px 15px 25px;

        .txt_ti {
          font-family: $font-family-roboto;
        }
      }

      &:hover {
        .sub_pl {
          display: block;
        }
      }
  }
  .tab-content {
      background-color: $color-white;
  }
</style>

<style lang="scss">
  @import "@/assets/sass/variables";

  #balance {
    #assigned-history-table,
    #investors-table {
      .box_table2 {
        margin-bottom: 22px;
        min-height: 180px;
      }
    }
  }
</style>
