<template>
  <div id="cancel-box">
    <a class="btn btn-cancel" @click="confirmCancelOrder(config.all)">{{ $t('order.open_order.cancel_all') }}</a>
    <div class="dropdown" v-click-outside="hide" >
      <button class="btn dropdown-toggle" @click="visible = !visible" v-if="!visibleCancel">
        <span v-if="!visibleCancel" :class="{'icon-arrow1': !visible, 'icon-arrow2': visible}"></span>
      </button>
      <ul class="dropdown-menu" v-if="visible">
        <li><a @click="confirmCancelOrder(config.limit)">{{ $t('order.open_order.cancel_limit_orders') }}</a></li>
        <li><a @click="confirmCancelOrder(config.market)">{{ $t('order.open_order.cancel_market_orders') }}</a></li>
        <li><a @click="confirmCancelOrder(config.stopLimit)">{{ $t('order.open_order.cancel_stop_limit_orders') }}</a></li>
        <li><a @click="confirmCancelOrder(config.stopMarket)">{{ $t('order.open_order.cancel_stop_market_orders') }}</a></li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    trigger: {
      type: Object,
      default: () => ({
        limit: true,
        market: true,
        stopLimit: true,
        stopMarket: true,
      }),
    },
    visibleCancel : { type: Boolean, default: false}
  },
  data() {
    return {
      visible: false,
      config: {
        all           : 'all',
        limit         : 'limit',
        market        : 'market',
        stopLimit     : 'stop_limit',
        stopMarket    : 'stop_market',
      }
    }
  },
  methods: {
    confirmCancelOrder (type) {
      let isTrigger = false;
      switch(type) {
        case this.config.all: {
          isTrigger = this.trigger.limit || this.trigger.market || this.trigger.stopLimit || this.trigger.stopMarket;
          break;
        }
        case this.config.limit: {
          isTrigger = this.trigger.limit;
          break;
        }
        case this.config.market: {
          isTrigger = this.trigger.market;
          break;
        }
        case this.config.stopLimit: {
          isTrigger = this.trigger.stopLimit;
          break;
        }
        case this.config.stopMarket: {
          isTrigger = this.trigger.stopMarket;
          break;
        }
      }
      if (!isTrigger) {
        return;
      }
      if (this.config.all !== type) {
        this.visible = !this.visible;
      }
      this.$emit('action-cancel-box', type);
    },
    hide() {
      this.visible = false;
    }
  },
  mounted() {
    // console.log(this.visibleCancel)
    // this.visible = this.visibleCancel
  }

};
</script>
<style lang="scss" scoped>
  @import "@/assets/sass/variables";
  .dark-theme {
    #cancel-box .dropdown .dropdown-menu {
      background: $color-gray-charade;
      li a {
        color: $color-auro-metal;
       
      }
    }
    #cancel-box .dropdown button span.icon-arrow1:before{
      color: $color-storm-grey;
    }
  }

  #cancel-box{
    white-space: nowrap;
    .btn{
      padding-right: 4px;
      border-radius: 0;
      font-size: 13px;
    }
    .btn-cancel{
      font-size: $font-medium-bigger;
      color: $color-caribbean-green;
      border: none;
      line-height: 19px;
      font-size: 14px;
      &:hover{
        color: $color-aquamarine;
      }
    }
    .dropdown{
      display: inline;
      button{
        border: none;
        background-color: transparent;
        font-size: $font-mini;
        line-height: 20px;
        padding: 2px 1px;
        text-align: center;
        width: 22px;
        height: 22px;
        span {
          color: var(--text-close);
          &.icon-arrow1:before {
            color: $color-gray-dark;
          }
        }
        &:hover{
          color: $color-caribbean-green;
          span {
            color: $color-caribbean-green;
          }
        }

        &:after{
          visibility: hidden;
        }
        .caret{
          color: $color-aquamarine;
        }
      }
      .dropdown-menu {
        top:22px;
        right: 0;
        left: auto;
        display: block;
        font-size: 16px;
        color: $color-grey-dark;
        border: none;
        padding: 15px 0 6px;
        border-radius: 8px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);

        li{
          padding-bottom: 9px;
        }
        li a{
          &:hover{
            color: $color-caribbean-green;
            background-color: transparent;
          }
        }
      }
    }
  }
</style>
