<template>
  <div id="modal" class="modal_add_phone">
    <modal :name="modalName" width="620" :handleOutSide="clearForm">
      <template slot="body">
        <div slot="body" class="phone-modal">
          <div class="phone-modal-title">
            {{ titleModal }}
          </div>
          <div class="phone-modal-body">
            <div class="phone-modal-body__title">
              {{ $t("phone_number.add.enter-number") }}
            </div>
            <div class="phone-modal-body__phone-input">
              <VuePhoneNumberInput
                :default-country-code="`VN`"
                v-model="phoneNumber"
                autocomplete="off"
                maxlength="20"
                :dark="dark === '-dark'"
                :clearable="isStatusCode !== 2"
                :disabled="isStatusCode === 2"
                :error="isPhoneNumberError"
                @update="handleUpdatePhoneInput"
                @phone-number-focused="resetErrors"
                no-validator-state
                error-color="#E02D3C"
                :translations="{
                  countrySelectorLabel: '',
                  phoneNumberLabel: '',
                  example: '',
                }"
              />
              <div class="message-wrapper">
                <p class="error-msg" v-if="isPhoneNumberError">
                  {{ $t("phone_number.error.phone_number_required") }}
                </p>
              </div>

              <div class="message-wrapper">
                <p class="error-msg" v-if="errors.has('number-phone')">
                  {{ errors.first("number-phone") }}
                </p>
              </div>
            </div>
            <div class="phone-modal-body__title" style="margin-top: 24px">
              {{ $t("phone_number.add.verification-code") }}
            </div>
            <div
              class="phone-modal-body__verification-code"
              :class="{ error: errors.has('code_verify') }"
            >
              <currency-input-otp
                name="code_verify"
                v-validate="'required'"
                class="phone-modal-body__verification-code-input-verification"
                v-model="codeVerify"
                type="text"
                maxlength="6"
                data-vv-validate-on="none"
                autocomplete="off"
                @focus="resetError"
                :placeholder="
                  $t('phone_number.add.verification-code-placeholder')
                "
                :disabled="isSubmitting"
              />
              <button
                @click="getVerifyCode"
                class="phone-modal-body__verification-code-add-btn"
                v-if="isStatusCode !== 2"
              >
                {{ typeVerifyCode }}
              </button>
              <div
                v-else
                class="phone-modal-body__verification-code-pending-btn"
              >
                {{ typeVerifyCode }}
              </div>
            </div>
            <div class="message-wrapper">
              <p class="error-msg" v-if="errors.has('code_verify')">
                {{ $t("phone_number.error.code_invalid") }}
              </p>
            </div>
            <p
              class="phone-modal-body__count-down-timer"
              v-if="isStatusCode !== 1"
            >
              {{ $t("phone_number.code.countdown") }} {{ formatTime }}
            </p>

            <button
              name="btnSubmit"
              :disabled="isSubmitting || codeVerify?.length !== 6"
              class="phone-modal-body__submit-btn"
              @click="addPhoneNumber"
            >
              {{ $t("phone_number.add.submit") }}
            </button>
          </div>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/shared_components/common/Modal";
import rf from "@/request/RequestFactory";
import { mapState } from "vuex";
import VuePhoneNumberInput from "vue-phone-number-input";
import RemoveErrorsMixin from "@/common/RemoveErrorsMixin";
import CurrencyInputOtp from "@/components/shared_components/common/CurrencyInputOtp.vue";

const TypeVerifyCode = {
  getCode: 1,
  pending: 2,
  resent: 3,
};

export default {
  name: "PhoneNumberModal",
  mixins: [RemoveErrorsMixin],

  components: {
    Modal,
    VuePhoneNumberInput,
    CurrencyInputOtp,
  },
  data() {
    return {
      modalName: "PhoneNumberModal",
      phoneNumber: "",
      dataPhoneNumber: {},
      codeVerify: "",
      isStatusCode: TypeVerifyCode.getCode,
      disabledInputCode: false,
      countdownInterval: null,
      countdown: 10 * 60,
      isPhoneNumberError: false,
    };
  },
  props: {
    type: "add" | "change" | "delete",
    getUserInfo: () => {},
  },
  methods: {
    handleUpdatePhoneInput(data) {
      this.phoneNumber = data.formattedNumber;
      this.dataPhoneNumber = { ...data };
    },
    resetErrors() {
      if (this.errors.any()) {
        this.errors.clear();
      }
      this.isPhoneNumberError = false;
    },
    closeModal() {
      window.CommonModal.hide("PhoneNumberModal");
      if (this.mode === "resend") {
        this.$emit(`RESENDMODAL:CLOSE`, true);
      }
    },
    resetParams() {
      this.params.code = "";
    },
    clearForm() {
      this.resetErrors();
      this.phoneNumber = "";
      this.dataPhoneNumber = {};
      this.codeVerify = "";
      clearInterval(this.countdownInterval);
      this.isStatusCode = TypeVerifyCode.getCode;
      this.disabledInputCode = false;
      this.countdownInterval = null;
      this.countdown = 10 * 60;
    },
    handleGetCode() {
      const data = {
        to: this.dataPhoneNumber.e164,
      };
      return new Promise((resolve, reject) => {
        rf.getRequest("UserRequest")
          .getCodePhoneNumber(data)
          .then((res) => {
            Message.success(window.i18n.t("validation.get_code_sms_success"));
            resolve(res);
          })
          .catch((e) => {
            if (e.response.data.message === "validation.ip-active.") {
              this.errors.add({
                field: "number-phone",
                msg: this.$t(`${e.response.data.message}`),
              });
            } else {
              this.errors.add({
                field: "number-phone",
                msg: this.$t(`${e.response.data.message}`),
              });
            }
          });
      });
    },
    handleAddPhoneNumber() {
      const data = {
        phone: this.dataPhoneNumber.e164,
        code: this.codeVerify,
      };
      this.isSubmitting;
      return new Promise((resolve) => {
        rf.getRequest("UserRequest")
          .addPhoneNumber(data)
          .then((res) => {
            Message.success(
              window.i18n.t(
                this.type === "add"
                  ? "phone_number.add_success"
                  : "phone_number.change_success"
              )
            );
            resolve(res);
            this.$emit("getUserInfo");
            this.closeModal();
            this.clearForm();
          })
          .catch((e) => {
            this.errors.add({
              field: "code_verify",
              msg: this.$t(`${e.response.data.message}`),
            });
          });
      });
    },
    startCodeCountDown() {
      if (this.countdownInterval) {
        clearInterval(this.countdownInterval);
      }

      this.countdownInterval = setInterval(() => {
        this.countdown--;
        if (this.countdown === 0) {
          clearInterval(this.countdownInterval);
          this.countdownInterval = null;
          this.isStatusCode = TypeVerifyCode.resent;
          this.codeVerify = "";
        }
      }, 1000);
    },
    getVerifyCode() {
      if (!this.phoneNumber) {
        this.isPhoneNumberError = true;
        return;
      }
      if (!this.dataPhoneNumber.isValid) {
        this.errors.add({
          field: "number-phone",
          msg: this.$t(`invalid_phone`),
        });
        return;
      }
      if (this.isStatusCode !== TypeVerifyCode.pending) {
        this.handleGetCode().then((res) => {
          if (res.success) {
            this.isStatusCode = TypeVerifyCode.pending;
            this.countdown = 10 * 60;
            this.startCodeCountDown();
          }
        });
      } else {
        this.handleVerifyCode().then((res) => {
          this.disabledInputCode = true;
          this.disabledInputEmail = true;
          clearInterval(this.countdownInterval);
        });
      }
    },
    async addPhoneNumber() {
      this.resetError();
      if (this.isSubmitting) {
        return;
      }
      await this.$validator.validate("code_verify");
      this.handleAddPhoneNumber();
    },
  },
  computed: {
    ...mapState({
      userThemeMode: (state) => state.userThemeMode,
      theme: (state) => state.userThemeMode,
    }),
    titleModal() {
      switch (this.type) {
        default:
          return `${window.i18n.t("phone_number.add")}`;
      }
    },
    isGetCodeButtonDisabled() {
      return (
        this.isStatusCode === TypeVerifyCode.confirm &&
        this.codeVerify.length < 6
      );
    },
    formatTime() {
      const minutes = Math.floor(this.countdown / 60);
      const seconds = this.countdown % 60;
      return `${minutes < 10 ? "0" : ""}${minutes}:${
        seconds < 10 ? "0" : ""
      }${seconds}`;
    },
    typeVerifyCode() {
      switch (this.isStatusCode) {
        case TypeVerifyCode.pending:
          return this.$t("phone_number.add.code-sent");
          break;
        case TypeVerifyCode.resent:
          return this.$t("phone_number.add.resend-code");
          break;
        default:
          return this.$t("phone_number.add.get-code");
      }
    },
    dark() {
      return this.theme === "light-theme" ? "" : "-dark";
    },
  },
  created() {
    // this.$on("hideCommonModal", (modalName) => {
    //   this.resetParams();
    //   if (
    //     this.mode === "success" ||
    //     this.mode === "change" ||
    //     this.mode === "invalid"
    //   ) {
    //     this.$router.push({ name: "SettingsSecurity" });
    //   }
    //   if (modalName !== this.modalName) {
    //     return;
    //   }
    // });
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.phone-modal {
  margin-top: -20px;
  &-title {
    font-size: 34px;
    line-height: 40px;
    font-weight: 600;
    text-align: center;
    color: var(--dark-1);
    font-family: $font-inter-medium;
    margin-bottom: 40px;
  }
  &-body {
    &__title {
      font-size: 18px;
      line-height: 22px;
      color: var(--dark-1);
    }
    &__phone-input {
      margin-top: 8px;
      margin-bottom: 6px;
      ::v-deep .vue-phone-number-input {
        .select-country-container {
          background-color: $color-alabaster;
          border-bottom-left-radius: 8px;
          border-top-left-radius: 8px;
          position: absolute;
          width: unset;
          z-index: 2;
          .country-selector {
            align-items: center;
            background-color: $color-gray-alto;
            border-radius: 4px;
            display: flex;
            height: unset;
            margin: 5.5px 6px;
            min-height: unset;
            padding: 7px 6px 7px 12px;
            .country-selector__country-flag {
              position: static;

              div {
                border-radius: 3px;
              }
            }
            .country-selector__input {
              background-color: transparent !important;
              border: none;
              color: $color-grey-dark;
              font-family: $font-inter-regular !important;
              font-size: 16px;
              height: unset;
              margin-left: 8px;
              min-height: unset;
              padding: 0;
              width: 40px;
            }
            .country-selector__toggle {
              position: static;
            }
            .country-selector__list {
              left: 0;
            }
          }
        }
        .input-tel.input-phone-number {
          height: 49px;
          .input-tel__input {
            font-family: $font-inter-regular !important;
            background-color: $color-alabaster;
            border: none;
            border-radius: 8px !important;
            caret-color: unset !important;
            height: 49px;
            font-size: 18px;
            padding-left: 128px;
          }
          .input-tel__label {
            display: none;
          }
          .input-tel__clear {
            background-image: url("@/assets/images/icon_x_light.svg");
            background-repeat: no-repeat;
            background-position: 50%;
            background-size: 100% 100%;
            top: -50%;
            transform: translateY(50%);
            margin-right: 8px;

            span {
              top: 0;
              display: none;
            }
          }
          &.has-value {
            .input-tel__input {
              padding-top: 0;
            }
          }
          &.is-focused {
            .input-tel__input {
              box-shadow: unset !important;
            }
          }
          &.has-error {
            .input-tel__input {
              box-shadow: rgba(224, 45, 60, 1) 0px 0px 0px 0.125rem;
            }
          }
        }
      }
    }
    &__verification-code {
      display: flex;
      justify-content: space-between;
      margin-top: 8px;
      background-color: $color-alabaster;
      padding: 5.5px 6px 5.5px 15px;
      border-radius: 8px;

      &-input-verification {
        width: calc(100% - 99px - 12px);
        background-color: transparent;
        border: 0;
        font-size: 18px;
        line-height: 21px;

        &::placeholder {
          color: $color-gray-dark;
        }
      }

      &-add-btn {
        background: $color-gray-alto;
        padding: 9.5px 12px;
        border-radius: 4px;
        font-size: 16px;
        line-height: 19px;
        white-space: nowrap;
      }

      &-pending-btn {
        color: $color-grey-dark;
        font-size: 16px;
        padding: 9.5px 12px;
      }
    }
    .error {
      border: 1px solid $text-color-red !important;
    }
    .error-msg {
      color: $text-color-red;
      margin-bottom: 16px;
      margin-top: 8px;
    }
    &__submit-btn {
      @include btn-submit-phone-number(no);
      width: 100%;
      // background-color: #c1fff3;
      margin-top: 20px;
    }
    &__count-down-timer {
      color: $color-gray-dark;
      font-size: 16px;
      line-height: 19px;
      margin-top: 10px;
    }
  }
}

.dark-theme {
  .phone-modal {
    &-body {
      &__phone-input {
        ::v-deep .vue-phone-number-input {
          .select-country-container {
            background-color: $color-black-shark;
            .country-selector {
              background-color: $color-gray-charade;
              .country-selector__input {
                color: $color-white;
              }
            }
          }
          .input-tel.input-phone-number {
            .input-tel__input {
              background-color: $color-black-shark !important;
            }
            .input-tel__clear {
              background-image: url("@/assets/images/icon_x_dark.svg");
              background-repeat: no-repeat;
              background-position: 50%;
              background-size: 100% 100%;
              margin-right: 8px;

              span {
                display: none;
              }
            }
          }
        }
      }
      &__verification-code {
        background-color: $color-dark;
        color: $color-white;

        &-input-verification {
          &::placeholder {
            color: $color-auro-metal;
          }
        }

        &-add-btn {
          background-color: $color-gray-charade;
          color: $color-white;
        }

        &-pending-btn {
          color: $color-white;
        }
      }
      &__submit-btn {
        @include btn-submit-phone-number(yes);
      }
      &__count-down-timer {
        color: $color-auro-metal;
      }
    }
  }
}

.message-wrapper {
  // height: 19px;
}

#modal {
  ::v-deep {
    .modal-footer {
      display: none;
    }
  }
}

@media screen and (max-width: 992px) {
  .phone-modal {
    margin-bottom: -4px;
    &-title {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 20px;
    }
    &-body {
      &__title {
        font-size: 14px;
        line-height: 16px;
      }
      &__verification-code {
        &-input-verification {
          width: calc(100% - 99px - 18px);
          font-size: 14px;
          line-height: 16px;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin-right: 10px;
        }
      }
    }
  }

  #modal {
    ::v-deep {
      .modal-header {
        padding-bottom: 0;
      }
      .modal-body {
        padding-left: 16px;
        padding-right: 16px;
      }
    }
  }
}
</style>
