<template>

  <div id="wait_admin_verify">

    <div id="WaitAdminConfirmBetaTester">
      <modal :name="modalName" width="440" :title="modalTitle">
        <template slot="body">

          <div class="confirmation-beta-tester-modal">

            <div class="box_body clearfix">
              <div v-html="$t('beta_tester.wait_admin_verify.content')">

              </div>
            </div>

            <div class="clearfix"></div>
            <div class="box_footer clearfix">
              <button class="btn btn_footer btn_oke" @click="onClickOk">{{ $t('beta_tester.ok') }}</button>
              <span class="txt">{{ $t('beta_tester.or') }}</span>
              <button class="btn btn_footer btn_cancel" @click="onClickCancel">{{ $t('beta_tester.cancel') }}</button>
            </div>

          </div>

        </template>
      </modal>
    </div>

  </div>
</template>

<script>
  import rf from '@/request/RequestFactory'
  import Const from '@/common/Const';
  import Modal from '@/components/shared_components/common/Modal.vue';
  import { mapGetters } from 'vuex';

  export default {
    components: {
      Modal,
    },
    computed: {
      ...mapGetters(['currentSymbol'])
    },
    data() {
      return {
        coin: this.$route.query.coin || Const.DEFAULT_COIN,
        currency: this.$route.query.currency || Const.DEFAULT_CURRENCY,
        modalName: 'WaitAdminConfirmBetaTester',
        modalTitle: this.$t('beta_tester.wait_admin_verify.header'),
      }
    },
    methods: {
      disableBuySellButton(isTester) {
        // Disable button Buy/Sell
        this.$broadcast('IgnoreBetaTesterStatusUpdate', isTester);
      },
      getEventHandlers() {
        return {
          BetaTesterWaitAdminVerify: this.onBetaTesterWaitAdminVerify,
        };
      },
      onBetaTesterWaitAdminVerify(isShow) {
        setTimeout(() => {
          this.showBetaTesterPopup();
        }, 300)
      },
      showBetaTesterPopup() {
        window.CommonModal.show(this.modalName);
      },
      onClickOk() {
        window.CommonModal.hide(this.modalName);
      },
      onClickCancel() {
        window.CommonModal.hide(this.modalName);
      },
      showError(message) {
        Message.error(message, {}, { position: "bottom_left" });
      },
      showSuccess(message) {
        Message.success(message, {}, { position: "bottom_left" });
      },
      closeModal () {
        this.$emit(`${this.modalName}:CLOSE`);
      },
    },
    mounted() {
      document.title = this.$t('menu.exchange') + ` – ${this.tabTitle}`;
    }
 }
</script>

<style lang="scss">
  @import '@/assets/sass/variables';
  #wait_admin_verify {
    #WaitAdminConfirmBetaTester {
      .modal-content {
        border-radius: 7px;
        min-width: 550px;
        .modal-header {
          border-top-right-radius: 7px;
          border-top-left-radius: 7px;
          background-color: #EFF4F8;
          border-bottom: 1px solid #ccc;
          font-size: 19px;
          padding: 25px 30px 20px 30px;
        }
        .modal-title {
          font-size: 23px;
          line-height: 30px;
        }
        .modal-body {
          .box_body {
            min-height: 100px;
          }
          padding: 0 0 0 0;
          .box_body {
            padding: 15px 30px;
            .group_find_join_modal {
              font-size: 14px;
              color: #333;
              line-height: 20px;
              margin-bottom: 5px;
            }
          }
          .box_footer {
            border-bottom-left-radius: 7px;
            border-bottom-right-radius: 7px;
            background-color: #EFF4F8;
            padding: 15px 30px;
            margin-top: 15px;
            border-top: 1px solid #cccccc;
            .btn_footer {
              height: 40px;
              min-width: 70px;
              text-align: center;
              font-size: 16px;
              line-height: 20px;
              padding: 11px 15px;
              border-radius: 3px;
              color: #999;
              border: 1px solid #ddd;
              display: inline-block;
              float: left;
            }
            .btn_oke {
              min-width: 100px;
              text-transform: uppercase;
              background-color: #2DAC91;
              color: #ffffff;
              border: none;
              &:hover {
                background-color: #2e9bdb;
              }
            }
            .btn_cancel {
              border: none;
              background-color: transparent;
              padding-left: 0;
            }
            .txt {
              display: inline-block;
              float: left;
              line-height: 20px;
              padding: 10px 10px 10px 20px;
            }
          }
        }
        .modal-footer {
          display: none;
        }
      }
      .checkbox-input {
        width: 220px;
        display: inline-block;
        margin-left: 7px;
        position: relative;
        top: 7px;
        margin-top: 13px;
        input {
          display: none;
        }
        label {
          cursor: pointer;
          position: absolute;
          width: 17px;
          height: 17px;
          top: 0;
          left: 0;
          border: 1px solid $color-grey-dusty;
          border-radius: 1px;
          &:after {
            opacity: 0;
            content: '';
            position: absolute;
            width: 16px;
            height: 10px;
            background: transparent;
            top: 0;
            left: 0;
            border: 4px solid $color-grey-dusty;
            border-top: none;
            border-right: none;
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
          }
        }
        input[type=checkbox]:checked + label:after {
          opacity: 1;
        }
        span {
          margin-left: 25px;
        }
      }
      .disabled {
        color: #e2e2e2;
        label {
          border: 1px solid #e2e2e2;
        }
      }
    }
  }
</style>