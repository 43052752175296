<template>
  <transfer-page ref="transfer_page" :configs="configs">
    <template slot="title">{{ $t("funds.balances.transfer") }}</template>
    <template slot="actions">
    </template>
  </transfer-page>
</template>

<script>
import rf from "@/request/RequestFactory";
import Modal from "@/components/shared_components/common/Modal";
import BaseDepositWithdraw from "./BaseDepositWithdraw";
import {mapState} from "vuex";

export default {
  components: {
    Modal,
    TransferPage: BaseDepositWithdraw,
  },
  data() {
    return {
      configs: {
        type: "transfer",
        is_show_title: true,
        msgEmptyData: window.i18n.t("funds.deposit.no_data"),
      },
      selectedCoin: {},
      address: {},
      currency: {},
    };
  },
  computed: {
    ...mapState({
      masterdata: (state) => state.masterdata,
    }),
    coinName() {
      // if (_.isEmpty(this.selectedCoin.coinName)) {
      //   return "";
      // }
      return this.selectedCoin?.coinName || "";
    },
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    showQRCode(name) {
      CommonModal.show(name, {
        position: "center",
        mask: true,
      });
    },
  },
  mounted() {
    this.scrollToTop();
    this.$refs.transfer_page.$on("CHANGE_SELECTED_COIN", (selectedCoin) => {
      this.selectedCoin = selectedCoin;
      this.address = {};
      this.$router.replace({ query: { coin: this.selectedCoin.coinName} })
      this.getDepositAddress();
      this.getConfirmations();
    });

    document.title = this.$t("funds.balances.tranfer") + ` – ${this.tabTitle}`;
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables";

@media screen and (max-width: 992px) {
  
}
@media screen and (max-width: 576px) {
  
}
</style>

