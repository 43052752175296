<template>
  <div id="account_sitebar">
    <div v-for="(item, index) in settingsAccountPath" :key="index">
        <router-link v-if="currentPath === item.path" :to="item.path" class="path-item active">
            <img :src="item.iconActive" alt="icon"/>
            {{ $t(item.name) }}
        </router-link>
        <router-link v-else :to="item.path" class="path-item">
            <img :src="lightMode ? item.icon : item.iconDark" alt="icon"/>
            {{ $t(item.name) }}
        </router-link>
    </div>
  </div>
</template>
<script>
import Const from '@/common/Const';
import { mapState } from 'vuex';

export default {
    name: 'AccountSiteBar',
    data() {
        return {
          settingsAccountPath: Const.SETTINGS_ACCOUNT_PATH,
        }
    },
    computed: {
      currentPath() {
        if(
          this.$route.path === '/account/address' || 
          this.$route.path === '/account/devices' ||
          this.$route.path === '/account/activity' ||
          this.$route.path === '/account/anti-phishing' ||
          this.$route.path === '/account/anti-phishing/create' ||
          this.$route.path === '/account/anti-phishing/change'
        ) {
          return '/account/security'
        } 
        return this.$route.path;
      },
      ...mapState({
        theme: state => state.userThemeMode,
      }),
      lightMode() {
        return this.theme === 'light-theme';
      }
    },
}
</script>
<style lang="scss">
  #account_sitebar {
    min-width: 336px;
    height: 100%;
    background-color: var(--background-color-primary-2);;
    min-height: calc(100vh - 65px - 252px);
    .path-item {
      display: flex;
      align-items: center;
      gap: 22px;
      padding: 18px 18px 18px 50px;
      color: var(--dark-1);
      font-family: $font-inter-regular;
      font-size: 20px;
      line-height: 24px;
      &.active {
        background: var(--hover-item-nav);
        position: relative;
      }
      &.active::before {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 6px;
        height: 100%;
        background: $color-caribbean-green;
      }

      &:hover{
        background: var(--hover-item-nav);
      }
    }
  }
</style>