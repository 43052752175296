 import rf from '@/request/RequestFactory';
import BigNumber from 'bignumber.js';

const actions = {
  getOrderbook({ commit }, symbol) {
    return rf.getRequest('MarginRequest').getOrderbook({ symbol })
      .then(res => {
        commit('setOrderbook', res.data);
      });
  },
  clearOrderbook({ commit }) {
    commit('clearOrderbook');
  },
  updateOrderbook({ state, commit, dispatch }, payload) {
    if (payload.meta && payload.meta.is_full_orderbook) {
      commit('setOrderbook', payload);
      return;
    }

    let orderbook = state.orderbook;

    if (!isValidOrderbook) {
      addToPendingOrderbook(payload);
      return;
    }

    if (payload.meta.prev_updated_at != orderbook.meta.updated_at) {
      isValidOrderbook = false;
    }

    if (isValidOrderbook) {
      commit('updateOrderbook', { ...payload, orderbook });
    } else {
      dispatch('getOrderbook', state.currentSymbol);
    }
  },
}

const getters = {
  orderbook: state => {
    return state.orderbook;
  },
}

const mutations = {
  setOrderbook: (state, payload) => {
    let orderbook = Object.assign({}, payload);
    while (pendingOrderbook.length > 0) {
      const row = pendingOrderbook.shift();
      if (row.meta.updated_at > orderbook.meta.updated_at) {
        orderbook = updateOrderbook(orderbook, row);
      }
    }
    state.orderbook = Object.freeze(orderbook);
    isValidOrderbook = true;
  },

  updateOrderbook: (state, payload) => {
    let orderbook = updateOrderbook(payload.orderbook, payload);
    state.orderbook = Object.freeze(orderbook);
  },

  clearOrderbook: (state, payload) => {
    state.orderbook = Object.freeze({
      buy: [],
      sell: [],
      meta: {},
    });
  }
}

let isValidOrderbook = false;
const pendingOrderbook = [];
const addToPendingOrderbook = (payload) => {
  pendingOrderbook.push(payload);
};

const updateOrderbook = (orderbook, payload) => {
  let buyOrderbook = orderbook.buy;
  let sellOrderbook = orderbook.sell;
  buyOrderbook.forEach(function(item){ 
    delete item.changeQty
  });
  sellOrderbook.forEach(function(item){ 
    delete item.changeQty  
  });
  let buyChange = [];
  let sellChange = [];
  for (let row of payload.buy) {
    [buyOrderbook, buyChange] = updateOrderbookRow(buyOrderbook, row, false, buyChange);
  }
  for (let row of payload.sell) {
    [sellOrderbook, sellChange] = updateOrderbookRow(sellOrderbook, row, true, sellChange);
  }
  return {
    buy: [...buyOrderbook],
    sell: [...sellOrderbook],
    meta: payload.meta,
    change: {
      sell : [...sellChange],
      buy : [...buyChange]
    },
  }
}

const updateOrderbookRow = (rows, newRow, isAscending, change) => {
  let hasNewQuantity = !(new BigNumber(newRow.quantity).eq(0));
  for (let i in rows) {
    let row = rows[i];
    if (new BigNumber(row.price).eq(newRow.price)) {
      if (hasNewQuantity) {
        row.changeQty = new BigNumber(newRow.quantity).sub(row.quantity);
        row.quantity = newRow.quantity;
        row.count = newRow.count; 
        row.time = new Date().getTime();
      } else {
        change.push(row);
        rows.splice(i, 1); 
      }
      return [rows, change];
    } else {
      if (hasNewQuantity) {
        newRow = { ...newRow };
        newRow.changeQty = newRow.quantity;
        newRow.time = new Date().getTime();
        if (new BigNumber(newRow.price).lt(row.price) && isAscending) {
          rows.splice(i, 0, newRow);
          return [rows, change];
        }
        if (new BigNumber(newRow.price).gt(row.price) && !isAscending) {
          rows.splice(i, 0, newRow);
          return [rows, change];
        }
      }
    }
  }

  if (hasNewQuantity) {
    newRow = { ...newRow };
    newRow.changeQty = newRow.quantity;
    newRow.time = new Date().getTime();
    rows.push(newRow);
  }

  return [rows, change];
}

const moduleName = 'orderbook'
export default {
  actions,
  getters,
  mutations,
  state: {
    [moduleName]: Object.freeze({
      buy: [],
      sell: [],
      meta: {},
      change: {},
    }),
  },
}