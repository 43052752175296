<template>
  <!-- NEW BALANCE LAYOUT -->
  <div id="balance" class="page_container" :class="{ is_no_single_page: !isSinglePage }">
    <div class="balance-container">
      <div class="table-container table-history">

        <data-table3 :getData="loadData" ref="datatable" :msgEmptyData="noFunds" :checkNoDataWhenAuthenticated="!this.isAuthenticated">
          <template slot-scope="props">
            <th data-sort-field="coin" @click="props.echoclick">
              <span>{{$t('funds.balances.coin')}}</span>
              <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
              <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
            </th>
            <th data-sort-field="name" v-if="isSinglePage" @click="props.echoclick">
              <span>{{$t('funds.balances.name')}}</span>
              <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
              <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
            </th>
            <th data-sort-field="totalBalance" @click="props.echoclick">
              <span>{{$t('funds.balances.total_balance')}}</span>
              <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
              <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
            </th>
            <th data-sort-field="availableBalance" @click="props.echoclick">
              <span>{{$t('funds.balances.avaliable_balance')}}</span>
              <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
              <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
            </th>
            <th data-sort-field="order" @click="props.echoclick">
              <span>{{$t('funds.balances.in_order')}}</span>
              <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
              <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
            </th>
            <th data-sort-field="btcValue" @click="props.echoclick">
              <span>{{$t('funds.balances.btc_value')}}</span>
              <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
              <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
            </th>
            <th v-if="isSinglePage"></th>
          </template>
          <template slot="body" slot-scope="props">
            <tr>
              <td>
                {{ props.item.coin | uppercase }}
              </td>
              <td v-if="isSinglePage">{{ props.item.coin | fullName }}</td>
              <td>{{ props.item.totalBalance | formatNumberAndDecimal }}</td>
              <td>{{ props.item.availableBalance | formatNumberAndDecimal }}</td>
              <td>{{ props.item.order | formatNumberAndDecimal }}</td>
              <td>{{ props.item.btcValue | formatNumberAndDecimal }}</td>
              <td class="group-btn" v-if="isSinglePage">
                <div class="border">
                  <template v-if="props.item.coin === 'usd'">
                    <div class="btn-trade"  v-on:click="showCoin(props.item.coin); displayPairCoin = !displayPairCoin" @mouseleave="displayPairCoin = false">Trade
                      <div class="menu-trade" v-show="isShowcoin(props.item.coin) && displayPairCoin">
                        <ul>
                            <li v-for="currency in tradeUsd">
                              <router-link :to="{name: 'Basic Exchange', query: {coin : 'usd', currency : currency.coin}}">
                                {{props.item.coin | uppercase}}/{{currency.coin | uppercase}}
                              </router-link>
                            </li>
                          </ul>
                      </div>
                    </div>
                    <router-link class="btn-withdrawal" :to="{name: 'Withdraw USD'}">
                      {{$t('funds.balances.withdrawal')}}
                    </router-link>
                    <router-link class="btn-deposit" :to="{name: 'Deposit USD'}">
                      {{$t('funds.balances.deposit')}}
                    </router-link>
                  </template>
                  <template v-else>
                    <div class="btn-trade"  v-on:click="showCoin(props.item.coin); displayPairCoin = !displayPairCoin" @mouseleave="displayPairCoin = false">
                      {{ $t('funds.balances.trade') }}
                      <div class="menu-trade" v-if="isShowcoin(props.item.coin) && displayPairCoin">
                        <ul>
                          <li v-for="currency in tradeCoin">
                            <router-link :to="{name: 'Basic Exchange', query: {coin : props.item.coin, currency : currency.currency}}">
                              {{props.item.coin | uppercase}}/{{currency.currency | uppercase}}
                            </router-link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <router-link v-if="props.item.isWithdraw" class="btn-withdrawal" :to="{name: 'Withdrawals', query: {coin : props.item.coin}}">
                      {{$t('funds.balances.withdrawal')}}
                    </router-link>
                    <a class="btn-withdrawal suspend" v-else>
                      {{$t('funds.balances.suspend')}}
                    </a>
                    <router-link v-if="props.item.isDeposit" class="btn-deposit" :to="{name: 'Deposits', query: {coin : props.item.coin}}">
                        {{$t('funds.balances.deposit')}}
                    </router-link>
                    <a class="btn-deposit suspend" v-else>
                        {{$t('funds.balances.suspend')}}
                    </a>
                  </template>
                  <div class="group_button_tooltip">
                    <div class="tooltip_hover">{{$t('funds.disable_coin_msg')}}</div>
                    <a class="btn-deposit-tooltip" v-if="!props.item.isDeposit || !props.item.isWithdraw">
                      <i class="icon-signal2"></i>
                    </a>
                  </div>

                </div>
              </td>
            </tr>
          </template>
        </data-table3>
        <!-- end pagination-container -->
      </div>
      <!-- end table container -->
    </div>
    <!-- end-balance-container -->
  </div>
  <!-- END NEW BALANCE LAYOUT -->
</template>
<script>
import rf from '@/request/RequestFactory'
import BigNumber from 'bignumber.js';
import Const from '@/common/Const';
import {mapState} from 'vuex';

const MIN_BTC = 0.001;

  export default {
    data() {
      return {
        hideBalances: false,
        isShowNotification: false,
        search: '',
        limit: undefined,

        accountBalances: undefined,
        prices: undefined,
        balances: undefined,

        totalPendingWithdraws: [],

        selectedCoin: "",
        tradeCoin: [],
        displayPairCoin: false,
        tradeUsd: [],

        isAuthenticated: window.isAuthenticated,
        coinSetting: {},
        noFunds: window.i18n.t('funds.balances.no_data'),
      }
    },
    props: {
      isSinglePage: {
        type: Boolean,
        default: true
      },
    },
    watch: {
      accountBalances(newAccBalances) {
        const currencies = Object.keys(newAccBalances);
        // this.getTotalPendingWithdraw(currencies, this.refresh);
      },
      prices(newPrices) {
        this.refresh();
      },
      totalPendingWithdraws(newVal) {
        this.refresh();
      },
      search(newValue) {
        this.refreshTable();
      },
      hideBalances() {
        this.refreshTable();
      }
    },
    computed: {
      ...mapState({
        masterdata: state => state.masterdata,
      }),
      totalBtcBalance() {
        return this.sumBy('btcValue');
      },
      totalUsdBalance() {
        return this.sumBy('usdValue');
      },
      totalOrder() {
        return this.sumBy('orderBtcValue');
      },
      isSpotExchangePage() {
        return this.$route.path === '/spot-exchange/basic';
      },
    },
    methods: {
      addPricesInBtc () {
        this.prices['btc_btc'] = { price: 1 };
        for (const fiat of Const.FIAT_LIKE_LIST) {
          const fiatPrice = this.prices[`${fiat}_btc`];
          const price = fiatPrice > 0 ? new BigNumber('1').div(fiatPrice.price).toString() : '0';
          this.prices[`btc_${fiat}`] = { price }
        }
      },
      isShowcoin(coin) {
        return coin === this.selectedCoin;
      },
      showCoin(coin) {
        this.selectedCoin = coin;
        this.tradeCoin = this.currencyCoins.filter((item) => {
            return item.coin.indexOf(coin) > -1;
          });
        this.tradeUsd = this.currencyCoins.filter((item) => {
            return item.currency === 'usd';
          });
      },
      getMinBtc() {
        return MIN_BTC;
      },
      showNotification() {
        this.isShowNotification = !this.isShowNotification;
      },
      hideNotification() {
        this.isShowNotification = false;
      },
      loadData (params) {
        return new Promise((resolve) => {
          if (!this.balances && this.balances.length === 0) {
            return ;
          }
          let data = this.balances;
          data = _.filter(data, (item) => `${item.coin}${item.name}`.toLowerCase().includes(this.search.toLowerCase()));
          if (this.hideBalances) {
            data = _.filter(data, (item) => new BigNumber(item.btcValue.toString()).comparedTo(MIN_BTC) >= 0);
          }
          if (params.sort && params.sort_type) {
            data = _.orderBy(data, (item) => {
              if (isNaN(item[params.sort])) {
                return item[params.sort];
              }
              return new BigNumber(`${item[params.sort]}`).toNumber();
            }, [params.sort_type]);
          }
          return resolve({ data });
        });
      },
      refreshTable() {
        this.$refs && this.$refs.datatable && this.$refs.datatable.refresh();
      },
      refresh() {
        const isNoData = window._.isEmpty(this.accountBalances) || window._.isEmpty(this.prices);
        if (isNoData) {
          return;
        }
        this.transferData();
        this.refreshTable();
      },
      transferData() {
        let data = [];
        for (let coin in this.accountBalances) {
          const coinInfor = this.accountBalances[coin];

          const item = {};
          item.coin = coin;
          item.name = this.getCoinName(coin);
          item.totalBalance = coinInfor.balance;
          item.availableBalance = coinInfor.available_balance;
          item.lock_balance = coinInfor.lock_balance;

          item.order = this.calculateInOrder(item);
          item.orderBtcValue = this.getOrderBtcBalance(item);

          item.btcValue = this.getBalance(coin, 'btc');

          let usdValue = this.getBalance(coin, 'usdt');
          // if do not have price of usd_$coin pair, transform to btc_$coin pair then transform to usd_btc pair.
          if (!usdValue) {
            const pair = 'usdt_btc';
            const price = this.prices[pair] ? this.prices[pair].price : '0';
            usdValue = new BigNumber(item.btcValue).mul(price).toString();
          }
          item.usdValue = usdValue;

          item.isDeposit = this.coinSetting[item.coin] && this.coinSetting[item.coin].is_deposit;
          item.isWithdraw = this.coinSetting[item.coin] && this.coinSetting[item.coin].is_withdraw;

          data.push(item);
        }

        // sort by coin name asc
        this.balances = window._.sortBy(data, 'name');
      },
      getCoinName(coin) {
        const key = `currency.${coin}.fullname`;
        return window.i18n.t(key);
      },
      getBalance(coin, currency) {
        if (!this.accountBalances[coin]) return 0;

        if (coin === currency) {
          return `${this.accountBalances[coin].balance}`;
        }
        const pair = `${currency}_${coin}`;
        if (!this.prices[pair]) {
          return 0;
        }

        return this.prices[pair].coin === coin ?  new BigNumber(`${this.accountBalances[coin].balance}`).mul(this.prices[pair].price).toString() : this.accountBalances[coin].balance / this.prices[`${coin}_${currency}`].price;
      },
      getOrderBtcBalance(item) {
        if (!this.prices[`btc_${item.coin}`]) {
          return 0;
        }
        return new BigNumber(item.order).mul(this.prices[`btc_${item.coin}`].price).toString();
      },
      calculateInOrder(item) {
        // const result =  window._.find(this.totalPendingWithdraws, (row) => { return row && row.currency === item.coin});
        // const totalPendingWithdraw = result ? result.total : '0';
        // return new BigNumber(item.totalBalance).sub(item.availableBalance).sub(totalPendingWithdraw).toString();
        // return new BigNumber(`${item.totalBalance}`).sub(item.availableBalance).toString();
        return item?.lock_balance || '0'
      },
      sumBy(attr) {
        let total = new BigNumber(0);
        for (let item of this.balances) {
          total = total.add(new BigNumber(item[attr]));
        }
        return total.toString();
      },
      onBalanceUpdated(newAccountBalances) {
        this.accountBalances = window._.merge({}, this.accountBalances, newAccountBalances);
      },
      updateTable(newAccountBalances) {
        this.refresh();
      },
      onPricesUpdated(newPrices) {
        this.prices = Object.assign({}, this.prices, newPrices);
        this.addPricesInBtc();
      },
      getSocketEventHandlers() {
        return {
          PricesUpdated: this.onPricesUpdated,
          SpotBalanceUpdated: this.onBalanceUpdated,
          BalanceUpdated: this.updateTable
        }
      },
      getTotalPendingWithdraw(currencies, callback) {
        if (window._.isEmpty(currencies)) {
          return;
        }

        const isContainUsdCurrency = window._.includes(currencies, 'usd');

        if (!isContainUsdCurrency || currencies.length > 1) {
          rf.getRequest('TransactionRequest').getTotalPendingWithdraw().then((res) => {
            window._.each(res.data, item => {
              this.updateCurrencyPendingWithdraw(item.currency, item.total);
            });
            if (callback) {
              callback();
            }
          });
        }
        // usd currency
        if (isContainUsdCurrency) {
          rf.getRequest('TransactionRequest').getTotalUsdPendingWithdraw().then((res) => {
            if (!res.data.total) {
              return;
            }
            this.updateCurrencyPendingWithdraw('usd', res.data.total);
            if (callback) {
              callback();
            }
          });
        }
      },
      updateCurrencyPendingWithdraw(currency, totalPendingWithdraw) {
        const item = window._.find(this.totalPendingWithdraws, item => item.currency === currency);
        if (item) {
          item.total = totalPendingWithdraw || '0';
        } else {
          this.totalPendingWithdraws.push(item);
        }
      },
    },
    async created() {
      if (!this.isAuthenticated) {
        return;
      }

      rf.getRequest('UserRequest').getCurrentUser().then(res => {
        let securityLevel = res.data.security_level;
        this.limit = window._.find(this.masterdata.withdrawal_limits, (wl) => {
          return wl.security_level === securityLevel && wl.currency === 'btc';
        });
      });

      rf.getRequest('UserRequest').getBalance(true, Const.TYPE_EXCHANGE_BALANCE).then(res => {
        this.onBalanceUpdated(res.data);
      });
      rf.getRequest('PriceRequest').getPrices().then(res => {
        this.onPricesUpdated(res.data);
      });

      document.title = this.$t('menu.balances') + ` – ${this.tabTitle}`;
    },
    mounted() {
      this.currencyCoins = this.masterdata.coin_settings;
      this.coinSetting = window._.keyBy(this.masterdata.coins_confirmation, item => item.coin);
    },
  }
</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables";
@import "@/assets/sass/common.scss";

.dark-theme {
  #balance {
    .tableContainer3 {
      background: transparent !important;
      table {
        thead {
          tr {
            th {
              color: $color-storm-grey!important;
              span {
                color : $color-auro-metal;
              }
            }
          }
        }

        tbody {
          tr {
            td {
              color: white!important;
            }
            .red {
              color: $text-color-red!important;
            }
            .blue {
              color: $text-color-jade!important;
            }
          }

          .empty-data {
            background: $color-cinder;
          }
        }
      }
    }
  }
}

  #balance {
    //min-width: 1201px;
    overflow-x: auto;
    .tableContainer3 {
      background: $color-gray-alabaster;

      table {
        width: 100%;
        overflow-y: initial;
        margin-bottom: 22px;

        thead {
          tr {
            th {
              width: 20%;
              min-width: 180px;
              span {
                font-family: $font-inter-regular;
                color: $color-gray-dark;
                font-size: 14px;

              }

              &:first-child {
                text-align: left;
                padding-left: 40px;
              }
              &:last-child {
                text-align: right;
                padding-right: 40px;
              }
            }
          }
        }

        tbody {
          tr {
            td {
              font-family: $font-inter-regular;
              color: $color-grey-dark!important;
              //font-size: $font-medium-bigger;
              text-align: center;
              &:last-child {
                text-align: right;
                padding-right: 40px;
              }
            }
            td:first-child {
              text-align: left!important;
              padding-left: 40px!important;
            }
          }
        }
        background: $color-gray-alabaster;
      }
    }

    .table-history {
      padding: 23px 0 24px;

    }
  }

@media screen and (max-width: 992px) {
  #balance .tableContainer3 table {
    thead tr th:first-child {
      padding-left: 20px;
    }
    tbody tr td:first-child {
      padding-left: 20px!important;
    }
  }

}
</style>
