<template>
  <div class="main_agreementInner">
    <div class="tit_page">
      <!-- <img class="img_icon" src="@/assets/images/icon/noun.png"> -->
      <label>{{$t('menu_bottom.withdrawals_guide')}}</label>
    </div>

    <div class="main">
      <h2>
        {{$t('withdraw_guide.limit_title1')}}
        <!-- <div v-if="isAuthenticated">
          <span class="pull-right">{{$t('withdraw_guide.level_title')}} <b>{{$t('withdraw_guide.level_name')}} {{user.security_level}}</b></span>
        </div>-->
      </h2>
      <table class="withdrawal-limit">
        <thead>
          <tr>
            <th class="th_first cl_666 padding_top20">{{$t('withdraw_guide.level')}}</th>
            <th class="th_first cl_666 padding_top20">{{$t('withdraw_guide.level_name')}}1</th>
            <th class="th_first cl_666 padding_top20">{{$t('withdraw_guide.level_name')}}2</th>
            <th class="th_first cl_666 padding_top20">{{$t('withdraw_guide.level_name')}}3</th>
            <th class="th_first cl_666 padding_top20">{{$t('withdraw_guide.level_name')}}4</th>
          </tr>
          <tr>
            <th class="th_first font_roboto">{{$t('withdraw_guide.security_method')}}</th>
            <th class="th_first font_roboto">{{$t('withdraw_guide.email')}}</th>
            <th class="th_first font_roboto">{{$t('withdraw_guide.gauthen')}}</th>
            <th class="th_first font_roboto">{{$t('withdraw_guide.verf_doc')}}</th>
            <th class="th_first font_roboto">{{$t('withdraw_guide.contract_admin')}}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in withdrawal_limits" v-if="item[0].currency !== 'usd'">
            <td>
              <div class="group_icon_coin">
                <img class="icon-coin" :src="coinImages[item[0].currency]">
                {{item[0].currency | currencyName}}
              </div>
            </td>
            <td>
              <span
                v-if="item[0].currency != 'usd'"
              >{{item[0].limit | formatCurrencyAmount(item[0].currency, '0')}} {{item[0].currency | currencyName}}</span>
              <span
                v-if="item[0].currency == 'usd' && item[0].limit == 0"
              >0 {{ item[0].currency | currencyName}}</span>
              <span v-if="item[0].currency == 'usd' && item[0].limit > 0">
                {{item[0].limit | formatCurrencyAmount(item[0].currency, '0')}} ({{$t('withdraw_guide.one_time')}})
                {{item[0].daily_limit | formatCurrencyAmount(item[0].currency, '0')}} ({{$t('withdraw_guide.one_day')}})
              </span>
            </td>
            <td>
              <span
                v-if="item[0].currency != 'usd'"
              >{{item[1].limit | formatCurrencyAmount(item[0].currency, '0')}} {{item[0].currency | currencyName}}</span>

              <span
                v-if="item[0].currency == 'usd' && item[1].limit == 0"
              >0 {{ item[0].currency | currencyName}}</span>
              <span v-if="item[0].currency == 'usd' && item[1].limit > 0">
                {{item[1].limit | formatCurrencyAmount(item[0].currency, '0')}} {{item[0].currency | currencyName}} ({{$t('withdraw_guide.one_time')}})
                {{item[1].daily_limit | formatCurrencyAmount(item[0].currency, '0')}} {{item[0].currency | currencyName}} ({{$t('withdraw_guide.one_day')}})
              </span>
            </td>
            <td>
              <span
                v-if="item[0].currency != 'usd'"
              >{{item[2].limit | formatCurrencyAmount(item[0].currency, '0')}} {{item[0].currency | currencyName}}</span>

              <span
                v-if="item[0].currency == 'usd' && item[2].limit == 0"
              >0 {{ item[0].currency | currencyName}}</span>
              <span v-if="item[0].currency == 'usd' && item[2].limit > 0">
                {{item[2].limit | formatCurrencyAmount(item[0].currency, '0')}} {{item[0].currency | currencyName}} ({{$t('withdraw_guide.one_time')}})
                {{item[2].daily_limit | formatCurrencyAmount(item[0].currency, '0')}} {{item[0].currency | currencyName}} ({{$t('withdraw_guide.one_day')}})
              </span>
            </td>
            <td>
              <span
                v-if="item[0].currency != 'usd'"
              >{{item[3].limit | formatCurrencyAmount(item[0].currency, '0')}} {{item[0].currency | currencyName}}</span>

              <span
                v-if="item[0].currency == 'usd' && item[3].limit == 0"
              >0 {{ item[0].currency | currencyName}}</span>
              <span v-if="item[0].currency == 'usd' && item[3].limit > 0">
                {{item[3].limit | formatCurrencyAmount(item[0].currency, '0')}} {{item[0].currency | currencyName}} ({{$t('withdraw_guide.one_time')}})
                {{item[3].daily_limit | formatCurrencyAmount(item[0].currency, '0')}} {{item[0].currency | currencyName}} ({{$t('withdraw_guide.one_day')}})
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="wid100 block_tric">
        <ul>
          <li>{{$t('withdraw_guide.limit_tip1')}}</li>
          <li>{{$t('withdraw_guide.limit_tip2')}}</li>
          <li>{{$t('withdraw_guide.limit_tip3')}}</li>
        </ul>
      </div>
      <h2>{{$t('withdraw_guide.limit_title2')}}</h2>
      <table class="withdrawal-limit">
        <thead>
          <tr>
            <th colspan="1" class="th_first cl_666 padding_top20">{{$t('withdraw_guide.currency')}}</th>
            <th colspan="1" class="th_first cl_666 padding_top20 min_w140">{{$t('withdraw_guide.deposit_fee')}}</th>
            <th colspan="4" class="th_first cl_666 padding_top20 text-center">{{$t('withdraw_guide.fee_withdrawal')}}</th>
            <th colspan="1" class="colum_fake"></th>
            <th colspan="4" class="th_first cl_666 padding_top20 text-center">{{$t('withdraw_guide.minimum_withdrawal')}}</th>
          </tr>
          <tr>
            <th colspan="1"></th>
            <th colspan="1"></th>
            <th class="th_first font_roboto">{{$t('withdraw_guide.level_name')}}1</th>
            <th class="th_first font_roboto">{{$t('withdraw_guide.level_name')}}2</th>
            <th class="th_first font_roboto">{{$t('withdraw_guide.level_name')}}3</th>
            <th class="th_first font_roboto">{{$t('withdraw_guide.level_name')}}4</th>
            <th colspan="1" class="colum_fake"></th>
            <th class="th_first font_roboto">{{$t('withdraw_guide.level_name')}}1</th>
            <th class="th_first font_roboto">{{$t('withdraw_guide.level_name')}}2</th>
            <th class="th_first font_roboto">{{$t('withdraw_guide.level_name')}}3</th>
            <th class="th_first font_roboto">{{$t('withdraw_guide.level_name')}}4</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in withdrawal_limits" v-if="item[0].currency !== 'usd'">
            <td>
              <div class="group_icon_coin">
                <img :src="coinImages[item[0].currency]" class="img_icon">
                {{item[0].currency | currencyName}}
              </div>
            </td>
            <td>{{$t('withdraw_guide.free')}}</td>
            <td>{{item[0].fee | formatCurrencyAmount(item[0].currency, '0')}} {{item[0].currency | currencyName}}</td>
            <td>{{item[1].fee | formatCurrencyAmount(item[1].currency, '0')}} {{item[1].currency | currencyName}}</td>
            <td>{{item[2].fee | formatCurrencyAmount(item[2].currency, '0')}} {{item[2].currency | currencyName}}</td>
            <td>{{item[3].fee | formatCurrencyAmount(item[3].currency, '0')}} {{item[3].currency | currencyName}}</td>
            <th colspan="1"></th>
            <td>
              {{item[0].minium_withdrawal | formatCurrencyAmount(item[0].currency, "-")}}
              <span v-if="item[0].minium_withdrawal != 0">{{ item[0].currency | currencyName}}</span>
            </td>
            <td>
              {{item[1].minium_withdrawal | formatCurrencyAmount(item[1].currency, "-")}}
              <span v-if="item[1].minium_withdrawal != 0">{{ item[1].currency | currencyName}}</span>
            </td>
            <td>
              {{item[2].minium_withdrawal | formatCurrencyAmount(item[2].currency, "-")}}
              <span v-if="item[2].minium_withdrawal != 0">{{ item[2].currency | currencyName}}</span>
            </td>
            <td>
              {{item[3].minium_withdrawal | formatCurrencyAmount(item[3].currency, "-")}}
              <span v-if="item[3].minium_withdrawal != 0">{{ item[3].currency | currencyName}}</span>
          </td>
          </tr>
        </tbody>
      </table>
      <div class="wid100 block_tric">
        <ul>
          <li>{{$t('withdraw_guide.limit_tip4')}}</li>
          <li>{{$t('withdraw_guide.limit_tip5')}}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import rf from '@/request/RequestFactory'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      withdrawal_limits: [],
      user: {},
      isAuthenticated: window.isAuthenticated,
      coinImages: []
    };
  },
  computed: {
    ...mapState({
      masterdata: state => state.masterdata
    })
  },

  methods: {
    hightLight: function(level, position = 0) {
      if (position == level) return true;
    }
  },
  mounted() {
    this.withdrawal_limits = _.groupBy(this.masterdata.withdrawal_limits, "currency");

    _.forEach(res.coins, (item, key) => {
        this.coinImages[item.coin] = item.icon_image;
    });
    this.coinImages['usd'] = `@/assets/images/color_coins/usd.png`;
    if (this.isAuthenticated) {
      rf.getRequest("UserRequest")
        .getCurrentUser()
        .then(res => {
          this.user = res.data;
        });
    }
    document.title = this.$t("menu.support_withdrawal") + ` – ${this.tabTitle}`;
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables";
.min_w140 {
  min-width: 140px;
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.group_icon_coin {
  white-space: nowrap;
  display: flex;
}
.bg-grey {
  background-color: #363e4c;
}
.tit_page {
  font-family: Roboto;
  font-size: 30px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: $color-eden-light;

  margin: 40px 0 25px 0;
}
.main_agreementInner {
  width: 100%;
  height: auto;
  background-color: $color-alabaster;
  padding-left: 40px;
  margin :0;
}
ul {
  list-style-type: none;
  li {
    font-family: Roboto;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.92;
    letter-spacing: normal;

    color: $color-grey-dusty;
    list-style: none;
  }

  li::before {
    color: $color-grey-dusty;
    content: "\2022";
    font-size: 1em;
    padding-right: 1.1225em;
    position: relative;
    top: 0em;
  }
}
.img_icon {
  width: 16px;
  margin-right: 5px;
  height: 16px;
}
.double_tr {
  height: 35px;
}

.block_tric {
  padding: 15px 10px;
}
.main_agreementInner {
  .content-header {
    width: 100%;
    height: 114px;
    display: flex;
    align-items: center;
    padding: 0 34px;
    .left {
      width: 50%;
      float: left;
      text-align: left;
      font-size: 20px;
      label {
        color: #000;
        font-weight: bold;
        font-size: 22px;
        margin-bottom: 0px;
        padding-bottom: -10px;
        position: relative;
        top: 4px;
      }
      img {
        width: 30px;
        margin-right: 15px;
        position: relative;
        top: 1px;
      }
    }
  }
  .pull-right {
    float: right !important;
    margin-right: 7px;
  }
  .main {
    color: $color-grey-dark;
    font-size: 14px;
    h2 {
      font-family: Roboto;
      font-size: 16px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $color-black;
      margin-top: 32px;
      &:first-child {
        margin-top: 15px;
      }
      span {
        font-size: 13.3px;
        font-weight: normal;
      }
    }

    .text-note {
      font-size: 13.3px;
      word-spacing: 00px;
      line-height: 24.5px;
      width: 97%;
      margin-left: 6px;
    }

    .withdrawal-limit {
      background-color: white;
      margin-top: 28px;
      font-size: 12.72px;
      margin-bottom: 15px;
      width: 100%;
      margin-left: -1px;
      thead {
        tr {
          text-align: left;
          color: $color-grey;
          background-color: $color-white;
          th {
            padding: 4px 20px;
            text-align: left;
            height: 30px;
            width: 170px;
            position: relative;
            font-weight: 500;
            font-family: $font-family-roboto-medium;
            color: $color-grey;
            font-size: $font-small;
            line-height: 20px;
            &.cl_666 {
              color: $color-grey-dusty;
            }
            &.padding_top20 {
              padding-top: 20px;
            }
            &.padding_bot20 {
              padding-bottom: 20px;
            }
            &.font_roboto {
              font-family: $font-family-roboto;
            }
            &.text-center {
              text-align: center;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            padding: 8px 20px;
            text-align: left;
            height: 56px;
            width: 170px;
            position: relative;
            font-family: $font-family-roboto-medium;
            font-size: $font-root;
            font-weight: 500;
            color: $color-grey-dark;
            .icon-coin {
              width: 20px;
              height: 20px;
              margin-right: 10px;
              vertical-align: middle;
              display: inline-block; 
              float: left; 
              position: relative;
              top: -2px;
            }
          }
        }
      }
    }
  }
}
.colum_fake {
  width: 50px !important;
  padding: 8px 10px !important;
}
</style>