<template>
    <div id="order_form_tabs">
      <order-confirm ref='orderConfirmComponent' :orderData="orderData"/>

      <div class="group_input_form clearfix">
        <label class="input_right_group">
          <marked-input :key='index' @focus="onFocusQuantity"  v-model="orderData.quantity" :placeholder="$t('margin.quantity')" :decimal="0" :unit="getUnit('quantity')" :visible-button="true" :isMargin="true" :precision="precisionQuantity" />
        </label>
      </div>

      <div class="group_input_form clearfix">
        <div class="header-percent-form">
          <ul>
            <li class="tab-order" @click="onChangeTypePercent('buy')" v-bind:class="{'active-buy-percent' : percentSide === 'buy'}" >
              BUY
            </li>
            <li  class="tab-trades" @click="onChangeTypePercent('sell')" v-bind:class="{'active-sell-percent' : percentSide === 'sell'}" >
              SELL
            </li>
          </ul>
         </div>
        </div>
       <div class="group_input_form percent_buyForm" v-bind:class="percentSide === 'buy' ? 'buy-form' : 'sell-form' ">
          <span class="check_percent" v-for="percent in percents" :key="percent.key" :class='{active: percent.value}' @click='selectPercent(percent.key,percentSide)'>{{ percent.key }}%</span>
      </div>
      <div class="group_input_form clearfix" v-show="tab === 'limit' ||tab === 'stop_limit' || tab === 'take_profit_limit' || tab === 'oco'">
        <div class="label_input" v-show="tab === 'oco'">{{ $t('margin.limit') }}: </div>
        <label class="input_right_group">
          <marked-input :key='index' v-model="orderData.limitPrice" :placeholder="$t('margin.limit_price')" :decimal="decimal" :unit="contract.quote_currency" :restrict-step-price="true" :visible-button="true" :isMargin="true" :precision="precision" />
        </label>
      </div>
      <div class="group_input_form clearfix" v-show="tab === 'stop_limit' || tab === 'stop_market' || tab === 'oco'">
        <div class="label_input" v-show="tab === 'oco'">{{ $t('margin.stop_limit') }}:</div>
        <label class="input_right_group">
          <marked-input :key='index' v-model="orderData.stopPrice" :placeholder="$t('margin.stop_price')" :decimal="decimal" :unit="contract.quote_currency" :restrict-step-price="true" :visible-button="true" :isMargin="true" :precision="precision" />
        </label>
      </div>
      <div class="group_input_form clearfix" v-show="tab === 'trailing_stop'">
        <label class="input_right_group">
          <marked-input :key='index' v-model="orderData.trailValue" :placeholder="$t('margin.trail_value')" :decimal="decimal" :unit="contract.quote_currency" :visible-button="true" :isMargin="true" :allowNegative="true" :precision="precision" />
        </label>
      </div>
      <div class="group_input_form clearfix" v-show="tab === 'take_profit_market' || tab === 'take_profit_limit' || tab === 'oco'">
        <label class="input_right_group">
          <marked-input :key='index' v-model="orderData.triggerPriceInput" :placeholder="$t('margin.trigger_price')" :decimal="decimal" :unit="contract.quote_currency" :restrict-step-price="true" :visible-button="true" :isMargin="true" :precision="precision" />
        </label>
      </div>
      <div class="group_input_form clearfix" v-show="tab === 'ifd'">
        <div class="label_input" v-show="tab === 'ifd'">{{ $t('margin.entry') }}:</div>
        <label class="input_right_group">
          <marked-input :key='index' v-model="orderData.limitPrice" :placeholder="$t('margin.entry_price')" :decimal="decimal" :unit="contract.quote_currency" :restrict-step-price="true" :visible-button="true" :isMargin="true" :precision="precision" />
        </label>
      </div>
      <div class="group_input_form clearfix" v-show="tab === 'ifd'">
        <div class="label_input" v-show="tab === 'ifd'">{{ $t('margin.exit') }}:</div>
        <label class="input_right_group">
          <marked-input :key='index' v-model="orderData.stopPrice" :placeholder="$t('margin.close_price')" :decimal="decimal" :unit="contract.quote_currency" :restrict-step-price="true" :visible-button="true" :isMargin="true" :precision="precision" />
        </label>
      </div>

      <div class="group_buy_sell clearfix" v-if="isAuthenticated">
        <div class="buy_left" @click="buyClick(disableBuyBtn)" :class="{'disabled': disableBuyBtn}">
          <div class="header_sell_buy">
            <span>{{ getBuyBtnText(tab) }}</span>
          </div>
          <div class="clearfix"></div>
          <div class="content_buy_sell">
            <div v-if="tab === 'market'">
              {{buttonData.quantity}} @ ≥ {{contract.last_price | formatMarginNumber}}
              <div class="tooltip1">
                <div class="icon-help">
                  <span class="tooltipText1 tooltipSell" v-html="tooltipMessage($t('margin.buy'))"></span>
                </div>
              </div>
            </div>
            <div v-else>
              {{buttonData.quantity}} @ {{buttonData.price}}
              <div class="tooltip1">
                <div class="icon-help">
                  <span class="tooltipText1 tooltipSell" v-html="tooltipMessage($t('margin.buy'))"></span>
                </div>
              </div>
            </div>
            <div v-show="tab === 'stop_limit' || tab === 'stop_market' || tab === 'trailing_stop'">
              {{ $t('margin.trigger') }}: ≥ {{buttonData.triggerPrice | formatMarginPrice(contract.tick_size)}}
            </div>
            <div v-show="tab === 'take_profit_limit' || tab === 'take_profit_market'">
              {{ $t('margin.trigger') }}: ≤ {{buttonData.triggerPrice | formatMarginPrice(contract.tick_size)}}
            </div>
            <div v-show="tab === 'oco'">
              {{buttonData.price}} ≥ {{ $t('margin.trigger') }} ≥ {{buttonData.triggerPrice | formatMarginPrice(contract.tick_size)}}
            </div>
          </div>
        </div>
        <div class="sell_right" @click="sellClick(disableSellBtn)" :class="{'disabled': disableSellBtn}">
          <div class="header_sell_buy">
            <span>{{ getSellBtnText(tab) }}</span>
          </div>
          <div class="clearfix"></div>
          <div class="content_buy_sell">
            <div v-if="tab === 'market'">
              {{buttonData.quantity}} @ ≤ {{contract.last_price | formatMarginNumber}}
              <div class="tooltip1">
                <div class="icon-help"></div>
                <span class="tooltipText1 tooltipSell" v-html="tooltipMessage($t('margin.sell'))"></span>
              </div>
            </div>
            <div v-else>
              {{buttonData.quantity}} @ {{buttonData.price}}
              <div class="tooltip1">
                <div class="icon-help"></div>
                <span class="tooltipText1 tooltipSell" v-html="tooltipMessage($t('margin.sell'))"></span>
              </div>
            </div>
            <div v-show="tab === 'stop_limit' || tab === 'stop_market' || tab === 'trailing_stop'">
              {{ $t('margin.trigger') }}: ≤ {{buttonData.triggerPrice | formatMarginPrice(contract.tick_size)}}
            </div>
            <div v-show="tab === 'take_profit_limit' || tab === 'take_profit_market'">
              {{ $t('margin.trigger') }}: ≥ {{buttonData.triggerPrice | formatMarginPrice(contract.tick_size)}}
            </div>
            <div v-show="tab === 'oco'">
              {{buttonData.price}} ≤ {{ $t('margin.trigger') }} ≤ {{buttonData.triggerPrice | formatMarginPrice(contract.tick_size)}}
            </div>
          </div>
        </div>
      </div>

      <div class="clearfix" v-if="isAuthenticated"></div>
      
      <div class="table_Cost_Order" v-if="isAuthenticated">
        <table>
          <thead>
            <th class="fs13 th_width box_custom_tooltip">
              <span class="float_left">
                {{ $t('margin.cost') }}:
              </span>
              <span class='box_value_right float_left'>
                <span class="value_left">{{costValue | formatMarginCost(costValue == 0 ? '0' : '--')}}</span>
              </span>
              <span class="float_left">
                {{ $t('margin.symbol.btc') }}
              </span>
              <span class="tooltip_custom_c left">{{ $t('margin.cost_tooltip', { cost: costTooltip(costValue) }) }}</span>
            </th>
            <th class="text-right fs13 th_width box_custom_tooltip">
              <span class="float_right">
                {{ $t('margin.symbol.btc') }}
              </span>
              <span class='box_value_right float_right'>
                <span class="value_right">{{costValueSell | formatMarginCost(costValueSell == 0 ? '0' : '--')}}</span>
              </span>
              <span class="float_right">
                {{ $t('margin.cost') }}:
              </span>
              <span class="tooltip_custom_c right">{{ $t('margin.cost_tooltip', { cost: costTooltip(costValueSell) }) }}</span>
            </th>
          </thead>
          <tbody>
            <tr>
              <td class="box_custom_tooltip">
                {{ $t('margin.order_value') }}
                <span class="tooltip_custom_c left">{{ $t('margin.order_value_tooltip', { orderValue: costTooltip(orderValue) }) }}</span>
              </td>
              <td class="text-right text-bold box_custom_tooltip">
                <span class="fl-right">
                  <span>
                    {{ $t('margin.symbol.btc') }}
                  </span>
                  <span class='box_value_right fl-left'>
                    <span class="value_left">{{orderValue | formatMarginCost(orderValue == 0 ? '0' : '--') }}</span>
                  </span>
                </span>
                <span class="tooltip_custom_c right">{{ $t('margin.order_value_tooltip', { orderValue: costTooltip(orderValue) }) }}</span>
              </td>
            </tr>
            <tr>
              <td class="box_custom_tooltip">
                {{ $t('margin.available_balance') }}
                <span class="tooltip_custom_c left">{{ $t('margin.avai_balance_tooltip', { avaiBalance: capitalTooltip(this.balance.availableBalance) }) }}</span>
              </td>
              <td class="text-right text-bold box_value_right fl-right box_custom_tooltip">
                <span>{{ displayPositive(this.balance.availableBalance) | formatMarginCapital}} {{ $t('margin.symbol.btc') }}</span>
                <span class="tooltip_custom_c right">{{ $t('margin.avai_balance_tooltip', { avaiBalance: capitalTooltip(this.balance.availableBalance) }) }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="clearfix" v-if="isAuthenticated"></div>

      <div class="content_bottom_order clearfix" v-show="!['market'].includes(tab)" v-if="isAuthenticated && tab !== 'ifd'">
        <form>
          <div class="group_bottom_order clearfix" v-show="tab !== 'limit' && (!tab.includes('limit') || !easyMode)">
            <div class="left box_value_right fl-left">
               <label class="tooltipWrapper">
                <select class="triggerExecInst" v-model="orderData.trigger">
                  <option value="mark">{{ $t('margin.mark') }}</option>
                  <option value="last">{{ $t('margin.last') }}</option>
                  <option value="index">{{ $t('margin.index') }}</option>
                </select>
                {{ $t('margin.trigger') }}
                <!-- {{ JSON.stringify(orderData.triggerPrice)}} -->
              </label>
              <span class="full_value_right cost_buy" v-html="$t('margin.trigger_tooltip')"></span>
            </div>
            <div class="right box_value_right fl-right">
              <label class="label_check" :class="{'active': orderData.closeOnTrigger}">
                {{ $t('margin.close_on_trigger') }}<input type="checkbox" name="vehicle" v-model="orderData.closeOnTrigger" />
              </label>
              <span class="full_value_right cost_sell" v-html="$t('margin.close_trigger_tooltip')"></span>
            </div>
          </div>

          <div class="group_bottom_order clearfix" v-show="(tab === 'limit' || tab === 'stop_limit' || tab ==='take_profit_limit' || tab === 'ifd') && (!tab.includes('limit') || !easyMode)">
            <div class="left box_value_right fl-left" :class="{'disabled': SelectTimeImFor.id !== 'gtc'}">
              <label class="label_check" :class="{'disabled':orderData.hidden, 'active': orderData.postOnly}">
                <input type="checkbox" name="vehicle" 
                :disabled="orderData.hidden" v-model="orderData.postOnly">{{ $t('margin.post_only') }}
              </label>
              <span class="full_value_right cost_buy">{{ $t('margin.post_only_tooltip') }}</span>
            </div>
            <div class="right box_value_right fl-right">
              <label class="label_check" :class="{'active': orderData.reduceOnly}" v-if="tab === 'limit'">
                {{ $t('margin.reduce_only') }}<input type="checkbox" name="vehicle" v-model="orderData.reduceOnly">
              </label>
              <span class="full_value_right cost_sell">{{ $t('margin.reduce_only_tooltip') }}</span>
            </div>
          </div>

          <div class="group_bottom_order clearfix" v-if="easyMode && tab.includes('limit')">
            <div class="left box_value_right fl-left">
              <div>{{ SelectTimeImFor.name }}</div>
              <!-- <span class="full_value_right cost_buy">{{ $t('margin.time_in_force_tooltip') }}</span> -->
            </div>
          </div>

          <div class="group_bottom_order clearfix" v-show="(tab === 'limit' || tab ==='take_profit_limit' || tab === 'stop_limit' || tab === 'ifd') && (!tab.includes('limit') || !easyMode)">
            <div class="left box_value_right fl-left" :class="{'disabled': orderData.postOnly}">
              <select-box id="ID_TimeInFor" :options="TimeInFor" :isReturnObject="true" v-model="SelectTimeImFor"></select-box>
              <span class="full_value_right cost_buy">{{ $t('margin.time_in_force_tooltip') }}</span>
            </div>

            <div v-show="(tab === 'limit' || tab === 'stop_limit' || tab ==='take_profit_limit') && (!tab.includes('limit') || !easyMode)" class="right box_value_right fl-right">
              <label class="label_check" :class="{'disabled':orderData.postOnly, 'active': orderData.hidden}">
                {{ $t('margin.hidden') }}<input type="checkbox" name="vehicle" :disabled="orderData.postOnly" v-model="orderData.hidden">
              </label>
              <span class="full_value_right cost_sell">{{ $t('margin.hidden_tooltip') }}</span>
            </div>
            <div v-if="orderData.hidden" class="right box_value_right fl-right">
              <label class="label_check" @click="$event.target.classList.toggle('active')">{{ $t('margin.display_qty') }}</label>
              <label class="input_right_group1">
                <marked-input v-model="displayQty" :placeholder="$t('margin.quantity')" :decimal="0" :isMargin="true" :precision="precisionQuantity" />
              </label>
              <span class="full_value_right cost_sell">{{ $t('margin.display_qty_tooltip') }}</span>
            </div>

          </div>
        </form>
      </div>

      <div class="group_input_form clearfix mgt15" v-if="!isAuthenticated" >
        <span class="txt_left_group"></span>
        <label class="input_right_group login_area">
          <div class="textAuthen">
              <router-link class="but_get_log" :to="{name: 'Login', params: {customRedirect: 'Margin Exchange'}}">{{ $t('order.order_form.login') }}</router-link>
              <span> {{ $t('order.order_form.or')}} </span>
              <router-link class="but_get_log" :to="{name: 'Register'}" >{{ $t('order.order_form.register') }}</router-link>
              <span> {{ $t('order.order_form.trade')}}</span>
          </div>
        </label>
      </div>

    </div>
</template>

<script>
import rf from '@/request/RequestFactory'
import Utils from '@/common/Utils'
import Const from '@/common/Const';
import OrderUtils from '@/common/OrderUtils';
import BigNumber from "bignumber.js";
import MarkedInput from "@/components/marginexchange_pages/common/MarkedInput.vue";
import SelectBox from '@/components/shared_components/common/SelectBox';
import { mapGetters, mapActions } from 'vuex';
import OrderConfirm from './OrderConfirm.vue';
import MarginCalculator from '@/components/marginexchange_pages/common/MarginCalculator.js';
import Numeral from '@/lib/numeral';

export default {
  components: {
    MarkedInput,
    SelectBox,
    OrderConfirm
  },
  props: ["tab"],

  data() {
    return {
      TimeInFor: Const.TIME_IN_FORCE,
      SelectTimeImFor: Const.TIME_IN_FORCE[0],
      isAuthenticated: window.isAuthenticated,
      orderData: {
        type: 'limit',
        stopType: null,
        side: 'buy',
        quantity: null,
        limitPrice: null,
        stopPrice: null,
        trailValue: null,
        triggerPrice: null,
        trigger: 'last',
        hidden: false,
        postOnly: false,
        reduceOnly: false,
        closeOnTrigger: false,
        costValue: null,
        costValueSell: null,
        orderValue: null,
        positionSize: null,
        triggerPriceInput: null
      },
      displayQty: 0,
      buttonData: {
        quantity: 0,
        price: null,
        triggerPrice: null
      },
      precisionQuantity: 1,
      // precisionPrice: 0.5,
      precision: 0.5,
      decimal: 1,
      index: 0,
      masterAccount: null,
      index1: 0,
      percents: [
          { key: '25', value: false },
          { key: '50', value: false },
          { key: '75', value: false },
          { key: '100', value: false },
      ],
      percentSide:'buy',
      canceledStack: {},
    };
  },

  watch: {
    $route (to, from) {
      this.initData();
    },
    contract(value){
      this.setTriggerPrice();
      this.precision = this.precisionPrice();
      this.decimal = Utils.getPrecisionNumber(this.contract.tick_size);
    },
    'contract.tick_size'(value) {
      this.decimal = Utils.getPrecisionNumber(value);
      this.index++;
    },
    'contract.last_price'(value){
      if(this.orderData.trigger == 'last'){
        const triggerPrice = this.contract.last_price;
        const currentPrice = this.getPrice(this.tab, {triggerPrice, trailValue: this.orderData.trailValue});
        
        if(['take_profit_market', 'take_profit_limit', 'oco'].includes(this.tab) && !this.orderData.triggerPriceInput){
          this.buttonData.triggerPrice = currentPrice;
        }

        if(!['take_profit_market', 'take_profit_limit', 'oco'].includes(this.tab) && !this.orderData.stopPrice){
          this.buttonData.triggerPrice = currentPrice;
        }

        if(this.tab == 'trailing_stop'){
          this.orderData.triggerPrice = triggerPrice;
        }else{
          this.orderData.triggerPrice = currentPrice;
        }
      }
    },
    indexPrice(value){
      if(this.orderData.trigger == 'index'){
        const triggerPrice = this.indexPrice;
        const currentPrice = this.getPrice(this.tab, {triggerPrice, trailValue: this.orderData.trailValue});
        
        if(['take_profit_market', 'take_profit_limit', 'oco'].includes(this.tab) && !this.orderData.triggerPriceInput){
          this.buttonData.triggerPrice = currentPrice;
        }

        if(!['take_profit_market', 'take_profit_limit', 'oco'].includes(this.tab) && !this.orderData.stopPrice){
          this.buttonData.triggerPrice = currentPrice;
        }

        if(this.tab == 'trailing_stop'){
          this.orderData.triggerPrice = triggerPrice;
        }else{
          this.orderData.triggerPrice = currentPrice;
        }
      }
    },
    'contract.mark_price'(value){
      if(this.orderData.trigger == 'mark'){
        const triggerPrice = this.contract.mark_price;
        const currentPrice = this.getPrice(this.tab, {triggerPrice, trailValue: this.orderData.trailValue});

        if(['take_profit_market', 'take_profit_limit', 'oco'].includes(this.tab) && !this.orderData.triggerPriceInput){
          this.buttonData.triggerPrice = currentPrice;
        }

        if(!['take_profit_market', 'take_profit_limit', 'oco'].includes(this.tab) && !this.orderData.stopPrice){
          this.buttonData.triggerPrice = currentPrice;
        }
        if(this.tab == 'trailing_stop'){
          this.orderData.triggerPrice = triggerPrice;
        }else{
          this.orderData.triggerPrice = currentPrice;
        }
      }
    },
    'orderData.triggerPriceInput'(value){
      if (this.orderData.triggerPriceInput) {
        this.buttonData.triggerPrice = this.orderData.triggerPriceInput
      }else{
        this.buttonData.triggerPrice = this.getPrice(this.tab);
      }
    },
    'orderData.trigger'() {
      if (this.orderData.trigger) {
        this.setTriggerPrice();
      }
    },
    'orderData.postOnly'() {
      if (this.orderData.postOnly) {
        this.SelectTimeImFor = Const.TIME_IN_FORCE[0]
      }
    },
    'orderData.quantity'() {
      if (!this.checkFalsy(this.orderData.quantity)){
        this.buttonData.quantity = this.orderData.quantity;

        if (this.tab !== 'limit' && this.tab !== 'stop_limit' && this.tab !== 'take_profit_limit' && this.tab !== 'oco') {
          this.buttonData.price = this.$t('margin.market');
        }
      }
    },
    'orderData.limitPrice'() {
      if (typeof this.orderData.limitPrice !== 'undefined' && this.orderData.limitPrice !== null && this.orderData.limitPrice.toString() !== '' && this.orderData.limitPrice.toString() !== '0'){
        if (this.tab === 'limit' || this.tab === 'stop_limit' || this.tab === 'take_profit_limit' || this.tab === 'oco' || this.tab === 'ifd') {
          this.buttonData.price = this.orderData.limitPrice;
        }
      }
    },
    'orderData.triggerPrice'() {
      // console.log('update trigger', this.tab, this.orderData)
      // if(this.tab === 'stop_limit' && !this.orderData.stopPrice){
      //   this.buttonData.triggerPrice = this.getPrice(this.tab, {triggerPrice: this.orderData.triggerPrice, trailValue: this.orderData.trailValue});
      // }

      if(['take_profit_market', 'take_profit_limit', 'oco'].includes(this.tab) && !this.orderData.triggerPriceInput){
        this.buttonData.triggerPrice = this.getPrice(this.tab, {triggerPrice: this.orderData.triggerPrice, trailValue: this.orderData.trailValue});
      }

      if(!['take_profit_market', 'take_profit_limit', 'oco'].includes(this.tab) && !this.orderData.stopPrice){
        this.buttonData.triggerPrice = this.getPrice(this.tab, {triggerPrice: this.orderData.triggerPrice, trailValue: this.orderData.trailValue});
      }

    },
    'orderData.stopPrice'() {
      if (!this.checkFalsy(this.orderData.stopPrice)){
        if (this.tab === 'stop_limit' || this.tab === 'stop_market'){
          this.buttonData.triggerPrice = this.getPrice(this.tab, {triggerPrice: this.orderData.stopPrice});
        }
      }else{
          this.buttonData.triggerPrice = this.getPrice(this.tab);
      }
    },
    'orderData.trailValue'() {
      if (this.orderData.triggerPrice) {
        this.buttonData.triggerPrice = this.getPrice(this.tab, {triggerPrice: this.orderData.triggerPrice, trailValue: this.orderData.trailValue});
      }
    },
    tab() {
      if(this.tab === 'limit' || this.tab === 'ifd') {
        this.buttonData.price = this.orderData.limitPrice;
      } 
      else if(this.tab === 'market') {
        this.buttonData.price = this.$t('margin.market');
        this.setTriggerPrice('last');
      } 
      else if(this.tab === 'stop_limit') {
        this.buttonData.price = this.orderData.limitPrice;
        this.buttonData.triggerPrice = this.orderData.stopPrice;
      }
      else if(this.tab === 'stop_market') {
        this.buttonData.price = this.$t('margin.market');
        this.buttonData.triggerPrice = this.orderData.stopPrice;
      }
      else if(this.tab === 'trailing_stop' && this.orderData.triggerPrice) {
        this.buttonData.price = this.$t('margin.market');
        this.buttonData.triggerPrice = this.getPrice(this.tab, {triggerPrice: this.orderData.triggerPrice, trailValue: this.orderData.trailValue});
      }
      else if(this.tab === 'take_profit_market') {
        this.buttonData.price = this.$t('margin.market');
        this.buttonData.triggerPrice = this.orderData.triggerPriceInput;
      }
      else if(this.tab === 'take_profit_limit' || this.tab === 'oco') {
        this.buttonData.triggerPrice = this.orderData.triggerPrice;
        this.buttonData.price = this.orderData.limitPrice;
      }
    },
    currentSymbol() {
      this.orderData.quantity = null;
      this.orderData.limitPrice = null;
      this.orderData.stopPrice = null;
      this.orderData.trailValue = null;
      this.orderData.hidden = false;
      this.orderData.triggerPriceInput = null;
      this.displayQty = 0;
      //updated triggerPrice everytime the contract type's changed
      this.setTriggerPrice();
      this.index++;
    },
    easyMode (value) {
      if (value) {
        this.orderData.trigger = 'last';
        this.orderData.closeOnTrigger = false;
        this.orderData.postOnly = false;
        this.orderData.reduceOnly = false;
        this.orderData.hidden = false;
        this.displayQty = 0;
        this.SelectTimeImFor = Const.TIME_IN_FORCE[0];
        this.index1++;
      }
    }
  },

  computed: {
    ...mapGetters({ indexPrice: 'indexPrice', user: 'user', position: 'mamPosition', contract: 'contract', balance: 'mamBalance', currentSymbol: 'currentSymbol', orderbook: 'orderbook', currentInstrument: 'currentInstrument', easyMode: 'easyMode' }),

    disableBuyBtn() {
      if(this.checkFalsy(this.orderData.quantity)) return true;
      if (this.masterAccount == null || this.masterAccount.status != 'opened') return true;

      try{
        if (this.tab === 'limit' || this.tab === 'stop_limit' || this.tab === 'oco' || this.tab === 'ifd') {
          if(this.checkFalsy(this.orderData.limitPrice)) return true;
        }

        if (this.tab === 'stop_limit') {
          if(this.checkFalsy(this.orderData.stopPrice)) return true
          else {
            if (Numeral(this.orderData.stopPrice).value() <= Numeral(this.orderData.triggerPrice).value()){
              return true;
            }
          }
        }

        if (this.tab === 'stop_market') {
          if(this.checkFalsy(this.orderData.stopPrice)) return true
          else {
            if (Numeral(this.orderData.triggerPrice).value() >= Numeral(this.orderData.stopPrice).value()){
              return true;
            }
          }
        }

        if (this.tab === 'oco') {
          if(this.checkFalsy(this.orderData.stopPrice)) return true
          else {
            if (Numeral(this.orderData.triggerPrice).value() < Numeral(this.orderData.limitPrice).value()){
              return true;
            }
          }
        }
          
        if (this.tab === 'trailing_stop') {
          if(this.checkFalsy(this.orderData.trailValue)) return true
          else {
            if (Numeral(this.orderData.trailValue).value() <= 0){
              return true;
            }
            if (Numeral(this.orderData.trailValue).value() + Numeral(this.orderData.triggerPrice).value() == 0){
              return true;
            }
          }
        }

        if (this.tab === 'take_profit_market') {
          if(this.checkFalsy(this.orderData.triggerPriceInput)) return true
          else {
            if (Numeral(this.orderData.triggerPriceInput).value() >= Numeral(this.orderData.triggerPrice).value()){
              return true;
            }
          }
        }

        if (this.tab === 'take_profit_limit') {
          if(this.checkFalsy(this.orderData.triggerPriceInput)) return true
          else {
            if (Numeral(this.orderData.triggerPriceInput).value() >= Numeral(this.orderData.triggerPrice).value()){
              return true;
            }
          }
        }
        
        if (this.tab === 'oco') {
          if(this.checkFalsy(this.orderData.stopPrice)) return true
          if(this.checkFalsy(this.orderData.triggerPriceInput)) return true
          if(this.checkFalsy(this.orderData.triggerPrice)) return true
          else {
            if (Numeral(this.orderData.limitPrice).value() >= Numeral(this.orderData.triggerPrice).value() ) {
              return true
            }
            if (Numeral(this.orderData.stopPrice).value() <= Numeral(this.orderData.triggerPrice).value() ) {
              return true
            }
            if (Numeral(this.orderData.stopPrice).value() <= Numeral(this.orderData.limitPrice).value() ) {
              return true
            }
            if (Numeral(this.orderData.triggerPriceInput).value() == Numeral(this.orderData.triggerPrice).value() ) {
              return true
            }
          }
        }
        
        if (this.tab === 'ifd') {
          if(this.checkFalsy(this.orderData.stopPrice)) return true
          if(Numeral(this.orderData.stopPrice).value() == Numeral(this.orderData.limitPrice).value()) return true
          return Numeral(this.orderData.stopPrice).value() < Numeral(this.orderData.limitPrice).value()
        }

        // if (new BigNumber(`${this.orderValue || 0}`).absoluteValue().add(`${this.position.risk_value || 0}`).comparedTo(`${this.position.risk_limit || 0}`) > 0) {
        //   return true
        // }

        return false;

      }catch(err){
        console.error(err);
        return false;
      }
    },

    disableSellBtn() {
      try{
        if(this.checkFalsy(this.orderData.quantity)) return true;
        if (this.masterAccount == null || this.masterAccount.status != 'opened') return true;

        if (this.tab === 'stop_limit') {
          if(this.checkFalsy(this.orderData.stopPrice)) return true
          else {
            if (Numeral(this.orderData.stopPrice).value() >= Numeral(this.orderData.triggerPrice).value()){
              return true;
            }
          }
        }

        if (this.tab === 'limit' || this.tab === 'oco' || this.tab === 'ifd') {
          if(this.checkFalsy(this.orderData.limitPrice)) return true;
          else{
            if(Numeral(this.contract.triggerPrice).value() > Numeral(this.orderData.limitPrice).value() && this.tab === 'oco') {
              return true;
            }
          }
        }

        if (this.tab === 'stop_market') {
          if(this.checkFalsy(this.orderData.stopPrice)) return true
          else {
            if (Numeral(this.orderData.triggerPrice).value() <= Numeral(this.orderData.stopPrice).value()){
              return true;
            }
          }
        }

        if (this.tab === 'trailing_stop') {
          if(this.checkFalsy(this.orderData.trailValue)) return true
          else {
            if (Numeral(this.orderData.trailValue).value() >= 0){
              return true;
            }
            if (Numeral(this.buttonData.triggerPrice).value() < 0){
              return true;
            }
            if (Numeral(this.orderData.trailValue).value() + Numeral(this.orderData.triggerPrice).value() == 0){
              return true;
            }
          }
        }

        if (this.tab === 'take_profit_market') {
          if(this.checkFalsy(this.orderData.triggerPriceInput)) return true
          else {
            if (Numeral(this.orderData.triggerPriceInput).value() <= Numeral(this.orderData.triggerPrice).value()){
              return true;
            }
          }
        }

        if (this.tab === 'take_profit_limit') {
          if(this.checkFalsy(this.orderData.triggerPriceInput)) return true
          else {
            if (Numeral(this.orderData.triggerPriceInput).value() <= Numeral(this.orderData.triggerPrice).value()){
              return true;
            }
          }
        }
        
        if (this.tab === 'oco') {
          if(this.checkFalsy(this.orderData.stopPrice)) return true
          if(this.checkFalsy(this.orderData.triggerPriceInput)) return true
          if(this.checkFalsy(this.orderData.triggerPrice)) return true
          else {
            if (Numeral(this.orderData.limitPrice).value() <= Numeral(this.orderData.triggerPrice).value() ) {
              return true
            }
            if (Numeral(this.orderData.stopPrice).value() >= Numeral(this.orderData.triggerPrice).value() ) {
              return true
            }
            if (Numeral(this.orderData.stopPrice).value() >= Numeral(this.orderData.limitPrice).value() ) {
              return true
            }
            if (Numeral(this.orderData.triggerPriceInput).value() == Numeral(this.orderData.triggerPrice).value() ) {
              return true
            }
          }
        }

        if (this.tab === 'ifd') {
          if(this.checkFalsy(this.orderData.stopPrice)) return true
          if(Numeral(this.orderData.stopPrice).value() == Numeral(this.orderData.limitPrice).value()) return true
          return Numeral(this.orderData.stopPrice).value() > Numeral(this.orderData.limitPrice).value()
        }

        // if (new BigNumber(`${this.orderValue || 0}`).add(`${this.position.risk_value || 0}`).comparedTo(`${this.position.risk_limit || 0}`) > 0) {
        //   return true
        // }

        return false;
      }catch(err){
        console.error(err);
        return false;
      }
    },
    costValue() {
      if (typeof this.orderData.quantity !== 'undefined' && this.orderData.quantity !== null && this.orderData.quantity.toString() !== '' && this.orderData.quantity.toString() !== '0'){
        let leverage = (typeof this.position.leverage !== 'undefined' && this.position.leverage !== null) ? this.position.leverage : 0;
        if (leverage === 0) {
          leverage = (typeof this.position.riskLimit !== 'undefined' && this.position.riskLimit !== null) ? this.position.riskLimit : 200;
        }

        let quantity = this.orderData.quantity;
        let position = typeof this.position.current_qty !== 'undefined' ? this.position.current_qty : 0;
        let price = this.orderData.triggerPrice;
        if (this.tab === 'limit' || this.tab === 'stop_limit' || this.tab === 'take_profit_limit' || this.tab === 'oco' || this.tab === 'ifd') {
          price = this.orderData.limitPrice;
        }
        else if (this.tab === 'stop_market') {
          price = this.orderData.stopPrice;
        }
        else if (this.tab === 'trailing_stop') {
          price = Numeral(this.orderData.triggerPrice).value() + Numeral(this.orderData.trailValue).value();
        }
        else if(this.tab === 'take_profit_market') {
          price = this.orderData.triggerPriceInput;
        }
        price = this.getRealPrice(price, true);
        let costValue = MarginCalculator.getOpenOrderMargin(price, parseInt(quantity), this.position, this.balance);
        if (costValue == null) {

        } else if (new BigNumber(`${costValue || 0}`).comparedTo(0.000001) < 0) {
          costValue = 0;
        }
        this.orderData.costValue = costValue;
        return costValue;
      }
      return 0;
    },
    costValueSell() {
      if (typeof this.orderData.quantity !== 'undefined' && this.orderData.quantity !== null && this.orderData.quantity.toString() !== '' && this.orderData.quantity.toString() !== '0'){
        let leverage = (typeof this.position.leverage !== 'undefined' && this.position.leverage !== null) ? this.position.leverage : 0;
        if (leverage === 0) {
          leverage = (typeof this.position.riskLimit !== 'undefined' && this.position.riskLimit !== null) ? this.position.riskLimit : 200;
        }

        let quantity = this.orderData.quantity * -1;
        let position = typeof this.position.current_qty !== 'undefined' ? this.position.current_qty : 0;
        let price = this.orderData.triggerPrice;
        if (this.tab === 'limit' || this.tab === 'stop_limit' || this.tab === 'take_profit_limit' || this.tab === 'oco' || this.tab === 'ifd') {
          price = this.orderData.limitPrice;
        }
        else if (this.tab === 'stop_market') {
          price = this.orderData.stopPrice;
        }
        else if (this.tab === 'trailing_stop') {
          price = Numeral(this.orderData.triggerPrice).value() + Numeral(this.orderData.trailValue).value();
        }
        else if(this.tab === 'take_profit_market') {
          price = this.orderData.triggerPriceInput;
        }
        price = this.getRealPrice(price, false);
        let costValue = MarginCalculator.getOpenOrderMargin(price, parseInt(quantity), this.position, this.balance);
        if (costValue == null) {

        } else if (new BigNumber(`${costValue || 0}`).comparedTo(0.000001) < 0) {
          costValue = 0;
        }
        this.orderData.costValueSell = costValue;
        return costValue;
      }
      return 0;
    },
    getCostValue(side){
      return side == 'buy' ? this.orderData.costValue : this.orderData.costValueSell
    },
    orderValue() {
      if (typeof this.orderData.quantity !== 'undefined' && this.orderData.quantity !== null && this.orderData.quantity.toString() !== '' && this.orderData.quantity.toString() !== '0'){
        let quantity = this.orderData.quantity;
        let price = this.orderData.triggerPrice;
        if (this.tab === 'limit' || this.tab === 'stop_limit' || this.tab === 'take_profit_limit' || this.tab === 'oco' || this.tab === 'ifd') {
          price = this.orderData.limitPrice;
        }
        else if (this.tab === 'stop_market') {
          price = this.orderData.stopPrice;
        }
        else if (this.tab === 'trailing_stop') {
          price = Numeral(this.orderData.triggerPrice).value() + Numeral(this.orderData.trailValue).value();
        }
        else if(this.tab === 'take_profit_market') {
          price = this.orderData.triggerPriceInput;
        }

        let orderValue = MarginCalculator.getContractValue(price, quantity);
        this.orderData.orderValue = orderValue;
        if (!isFinite(orderValue)) orderValue = null;
        return orderValue;
      }
      return 0;
    }
  },

  methods: {
    ...mapActions(['getUser', 'getInstrument', 'getMamBalance', 'getMasterAccount']),
    randomInteger(min) {
      return Math.floor(Math.random() * (10000 - min + 1)) + min;
    },
    costTooltip (costValue) {
      return Utils.formatMarginCost(costValue, costValue == 0 ? '0' : '--');
    },
    capitalTooltip (capitalValue) {
      return Utils.formatMarginCapital(capitalValue, capitalValue == 0 ? '0' : '--');
    },
    estLiquidationPrice () {
      let price = '';
      if (this.orderData.type === 'limit') {
        price = this.orderData.limitPrice;
      } else {
        price = this.contract.last_price;
      }
      let availableBalance = this.balance.maxAvailableBalance;
      let quantity = this.orderData.side == 'buy' ? parseInt(this.orderData.quantity) : parseInt(this.orderData.quantity) * -1;
      const position = MarginCalculator.tryToMatchPosition(price, quantity, this.position, availableBalance);
      let estLiquidationPrice = position.liquidation_price;

      if (estLiquidationPrice == 'NaN') {
          estLiquidationPrice = 0;
      }
      return Number(estLiquidationPrice).toFixed(2);
    },
    triggerType () {
      if (this.orderData.trigger == 'last') {
        return window.i18n.t('margin.last');
      } else if (this.orderData.trigger == 'mark') {
        return window.i18n.t('margin.mark');
      } else {
        return window.i18n.t('margin.index');
      }
    },
    tooltipMessage (side) {
      if(this.tab === 'limit') {
        return window.i18n.t('margin.buy_sell_information_limit', { side, type: this.tab, quantity: this.orderData.quantity, symbol: this.currentSymbol, price: this.orderData.limitPrice, positionSize: this.position.current_qty, afterPositionSize: parseInt(this.position.current_qty) + parseInt(this.orderData.quantity), liquidationPrice: this.estLiquidationPrice() });
      }
      else if(this.tab === 'market') {
        return window.i18n.t('margin.buy_sell_information_market', { side, type: this.tab, quantity: this.orderData.quantity, symbol: this.currentSymbol, price: this.orderData.limitPrice, positionSize: this.position.current_qty, afterPositionSize: parseInt(this.position.current_qty) + parseInt(this.orderData.quantity), liquidationPrice: this.estLiquidationPrice() });
      }
      else if(this.tab === 'stop_limit') {
        return window.i18n.t('margin.buy_sell_information_stop_limit', { side, type: this.tab, quantity: this.orderData.quantity, symbol: this.currentSymbol, price: this.orderData.limitPrice, positionSize: this.position.current_qty, afterPositionSize: parseInt(this.position.current_qty) + parseInt(this.orderData.quantity), liquidationPrice: this.estLiquidationPrice(), triggerType: this.triggerType(), stopPrice: new BigNumber(this.orderData.triggerPrice).toString() });
      }
      else if(this.tab === 'stop_market') {
        return window.i18n.t('margin.buy_sell_information_stop_market', { side, type: this.tab, quantity: this.orderData.quantity, symbol: this.currentSymbol, price: this.orderData.limitPrice, positionSize: this.position.current_qty, afterPositionSize: parseInt(this.position.current_qty) + parseInt(this.orderData.quantity), liquidationPrice: this.estLiquidationPrice(), triggerType: this.triggerType(), stopPrice: new BigNumber(this.orderData.triggerPrice).toString() });
      }
      else if(this.tab === 'trailing_stop') {
        return window.i18n.t('margin.buy_sell_information_trailing_stop', { side, type: this.tab, quantity: this.orderData.quantity, symbol: this.currentSymbol, price: this.orderData.limitPrice, positionSize: this.position.current_qty, afterPositionSize: parseInt(this.position.current_qty) + parseInt(this.orderData.quantity), liquidationPrice: this.estLiquidationPrice(), triggerType: this.triggerType(), stopPrice: new BigNumber(this.orderData.triggerPrice).toString() });
      }
      else if(this.tab === 'take_profit_limit') {
        return window.i18n.t('margin.buy_sell_information_take_profit_limit', { side, type: this.tab, quantity: this.orderData.quantity, symbol: this.currentSymbol, price: this.orderData.limitPrice, positionSize: this.position.current_qty, afterPositionSize: parseInt(this.position.current_qty) + parseInt(this.orderData.quantity), liquidationPrice: this.estLiquidationPrice(), triggerType: this.triggerType(), stopPrice: new BigNumber(this.orderData.triggerPrice ).toString()});
      }
      else if(this.tab === 'take_profit_market') {
        return window.i18n.t('margin.buy_sell_information_take_profit_market', { side, type: this.tab, quantity: this.orderData.quantity, symbol: this.currentSymbol, price: this.orderData.limitPrice, positionSize: this.position.current_qty, afterPositionSize: parseInt(this.position.current_qty) + parseInt(this.orderData.quantity), liquidationPrice: this.estLiquidationPrice(), triggerType: this.triggerType(), stopPrice: new BigNumber(this.orderData.triggerPrice ).toString()});
      }
      else if (this.tab === 'oco') {
        return window.i18n.t('margin.buy_sell_information_oco');
      }
      else if (this.tab === 'ifd') {
        return window.i18n.t('margin.buy_sell_information_ifd');
      }
    },
    displayPositive (value) {
      if (new BigNumber(`${value || 0}`).comparedTo(0) < 0) {
        return 0;
      }
      return value;
    },
    getCostValueFunc(side){
      return side == 'buy' ? this.orderData.costValue : this.orderData.costValueSell
    },
    precisionPrice() {
      const a = this.contract && this.contract.tick_size ? this.$options.filters.formatFloatNumber(this.contract.tick_size) : Const.MARGIN_DEFAULT_TICK_SIZE;
      const b = this.contract && this.contract.tick_size ? (typeof this.contract.tick_size == 'string' ? parseFloat(this.contract.tick_size) : this.contract.tick_size) : Const.MARGIN_DEFAULT_TICK_SIZE
      const c = new BigNumber(`${this.contract && this.contract.tick_size || Const.MARGIN_DEFAULT_TICK_SIZE}`).toString();
      return c
    },
    getRealPrice (price, isBuy) {
      let realEntryPrice = price;
      let orderBook = this.orderbook;

      if (isBuy && orderBook.sell && orderBook.sell[0] && orderBook.sell[0].price) {
          if (new BigNumber(`${this.position.multiplier || 0}`).comparedTo(0) < 0 && new BigNumber(`${realEntryPrice || 0}`).comparedTo(`${orderBook.sell[0].price || 0}`) > 0) {
              realEntryPrice = orderBook.sell[0].price;
          }
      }
      if (!isBuy && orderBook.buy && orderBook.buy[0] && orderBook.buy[0].price) {
          if (new BigNumber(`${this.position.multiplier || 0}`).comparedTo(0) > 0 && new BigNumber(`${realEntryPrice || 0}`).comparedTo(`${orderBook.buy[0].price || 0}`) < 0) {
              realEntryPrice = orderBook.buy[0].price;
          }
      }

      return realEntryPrice;
    },
    getUnit(ref){
      return Utils.getUnit(ref, this.contract);
    },
    getBuyBtnText(tab){
      const data = {
        limit: this.$t('margin.btn_buy_limit'),
        market: this.$t('margin.btn_buy_market'),
        stop_limit: this.$t('margin.btn_buy_stop_limit'),
        stop_market: this.$t('margin.btn_buy_stop_market'),
        trailing_stop: this.$t('margin.btn_buy_trailing_stop'),
        take_profit_market: this.$t('margin.btn_buy_take_profit_market'),
        take_profit_limit: this.$t('margin.btn_buy_take_profit_limit'),
        oco: this.$t('margin.btn_buy_oco'),
        ifd: this.$t('margin.btn_buy_ifd'),
      }
      return data[tab]
    },
    getSellBtnText(tab){
      const data = {
        limit: this.$t('margin.btn_sell_limit'),
        market: this.$t('margin.btn_sell_market'),
        stop_limit: this.$t('margin.btn_sell_stop_limit'),
        stop_market: this.$t('margin.btn_sell_stop_market'),
        trailing_stop: this.$t('margin.btn_sell_trailing_stop'),
        take_profit_market: this.$t('margin.btn_sell_take_profit_market'),
        take_profit_limit: this.$t('margin.btn_sell_take_profit_limit'),
        oco: this.$t('margin.btn_sell_oco'),
        ifd: this.$t('margin.btn_sell_ifd'),
      }
      return data[tab]
    },

    checkFalsy(attr){
      return (typeof attr === 'undefined' || attr === null || attr.toString() === '' || new BigNumber(attr).equals(0)) 
    },
    initData() {
      if(window.isAuthenticated) {
        this.getMasterAccount()
          .then(res => {
            if (res && res.data) {
              this.masterAccount = res.data;
            } else {
              this.masterAccount = res;
            }
          }).catch(error => {
          })
      }
    },
    setTriggerPrice(givenData) {
      const data = {
        last: this.contract.last_price,
        mark: this.contract.mark_price,
        index: this.indexPrice,
      }
      if(givenData && ['last','mark','index'].includes(givenData)){
        this.orderData.triggerPrice = data[givenData]
      }else{
        this.orderData.triggerPrice = data[this.orderData.trigger]
      }
    },
    getPrice(tab, data){
      const tick_size = data && data.tick_size ? data.tick_size : (this.contract.tick_size ? parseFloat(this.contract.tick_size) : Const.MARGIN_DEFAULT_TICK_SIZE);
      if(tab == 'trailing_stop'){
        if (data && data.trailValue && data.trailValue !== '-') {
          const price = new BigNumber(`${data.triggerPrice || 0}`).add(`${data.trailValue || 0}`);
          if(data.showFilter)
            return this.$options.filters.formatMarginPrice(price, tick_size);
          else
            return price;

        } else {
          const price = new BigNumber(`${data.triggerPrice || 0}`);
          if(data && data.showFilter)
            return this.$options.filters.formatMarginPrice(price, tick_size);
          else
            return price;
        }
      } else {
        const thisPrice = data && data.triggerPrice ? data.triggerPrice : this.orderData.triggerPrice;
        if(data && data.showFilter)
          return this.$options.filters.formatMarginPrice(thisPrice, tick_size);
        else
          return thisPrice
      }
      return 0
    },
    buyClick(isDisable) {
      if(isDisable) return
      if (!this.validateBuy()) {
        return;
      }
      window.CommonModal.show('OrderConfirm', {
        buttons: [
          {
            label: this.$t('margin.cancel'),
            callback: () => {
              window.CommonModal.hide('OrderConfirm');
            }
          },
          {
            label: this.$t('margin.buy'),
            class: {'btn-buy' : true},
            callback: () => {
                this.createOrder();
            },
          }
        ]
      });
      
      this.orderData.side = "buy";
      this.orderData.costValue = this.costValue;
      this.initConfirmPopup();
    },

    sellClick(isDisable) {
      if(isDisable) return
      if (!this.validateSell()) {
        return;
      }
      window.CommonModal.show('OrderConfirm', {
        buttons: [
          {
            label: this.$t('margin.cancel'),
            callback: () => {
              window.CommonModal.hide('OrderConfirm');
            }
          },
          {
            label: this.$t('margin.sell'),
            class: {'btn-buy' : true},
            callback: () => {
                this.createOrder();
            },
          }
        ]
      });
      
      this.orderData.side = "sell";
      this.orderData.costValueSell = this.costValueSell;
      this.initConfirmPopup();
    },

    initConfirmPopup () {
      this.orderData.positionSize = this.orderData.side == "buy"
        ? new BigNumber(`${this.position.current_qty || 0}`).add(`${this.orderData.quantity || 0}`).toString()
        : new BigNumber(`${this.position.current_qty || 0}`).sub(`${this.orderData.quantity || 0}`).toString();
      switch (this.tab) {
        case 'limit':
          this.orderData.type = 'limit';
          this.orderData.stopType = null;
          break;
        case 'stop_limit':
          this.orderData.type = 'limit';
          this.orderData.stopType = 'stop_limit';
          break;
        case 'take_profit_limit':
          this.orderData.type = 'limit';
          this.orderData.stopType = 'take_profit_limit';
          break;
        case 'market':
          this.orderData.type = 'market';
          this.orderData.stopType = null;
          break;
        case 'stop_market':
          this.orderData.type = 'market';
          this.orderData.stopType = 'stop_market';
          break;
        case 'trailing_stop':
          this.orderData.type = 'market';
          this.orderData.stopType = 'trailing_stop';
          break;
        case 'take_profit_market':
          this.orderData.type = 'market';
          this.orderData.stopType = 'take_profit_market';
          break;
        case 'oco':
          this.orderData.type = 'limit';
          this.orderData.stopType = 'oco';
          break;
        case 'ifd':
          this.orderData.type = 'limit';
          this.orderData.stopType = 'ifd';
          break;
      }
      this.$refs.orderConfirmComponent.initData(this.orderData);
    },

    createOrder() {
      let data = {
        instrument_symbol: this.contract.symbol,
        side: this.orderData.side,
        type: this.orderData.type,
        stop_type: this.orderData.stopType,
        quantity: this.orderData.quantity,
        leverage: this.position.leverage === 0 ? this.position.riskLimit : this.position.leverage,
        price: null,
        stop_price: null,
        trigger_price: null,
        stop_condition: null,
        trigger: null,
        trail_value: null,
        is_post_only: false,
        is_hidden: false,
        display_quantity: 0,
        is_reduce_only: false,
        time_in_force: null
      }

      if(this.tab === 'limit') {
        data.price = this.orderData.limitPrice;
        if (this.SelectTimeImFor.id === 'gtc') {
          data.is_post_only = this.orderData.postOnly;
        }
        data.is_hidden = this.orderData.hidden;
        data.display_quantity = this.displayQty;
        data.is_reduce_only = this.orderData.reduceOnly;
        data.time_in_force = this.SelectTimeImFor.id;
      } 
      else if(this.tab === 'stop_limit') {
        data.price = this.orderData.limitPrice;
        data.stop_price = this.orderData.stopPrice;
        data.trigger = this.orderData.trigger;
        if (this.SelectTimeImFor.id === 'gtc') {
          data.is_post_only = this.orderData.postOnly;
        }
        data.is_hidden = this.orderData.hidden;
        data.display_quantity = this.displayQty;
        data.is_reduce_only = this.orderData.closeOnTrigger;
        data.time_in_force = this.SelectTimeImFor.id;
      }
      else if(this.tab === 'stop_market') {
        data.stop_price = this.orderData.stopPrice;
        data.trigger = this.orderData.trigger;
        data.is_reduce_only = this.orderData.closeOnTrigger;
      }
      else if(this.tab === 'trailing_stop') {
        data.stop_price = this.orderData.triggerPrice;
        data.trail_value = this.orderData.trailValue;
        data.trigger = this.orderData.trigger;
        data.is_reduce_only = this.orderData.closeOnTrigger;
      }
      else if(this.tab === 'take_profit_market') {
        data.stop_price = this.orderData.triggerPriceInput;
        data.trigger = this.orderData.trigger;
        data.is_reduce_only = this.orderData.closeOnTrigger;
      }
      else if(this.tab === 'take_profit_limit') {
        data.price = this.orderData.limitPrice;
        data.stop_price = this.orderData.triggerPriceInput;
        data.trigger = this.orderData.trigger;
        if (this.SelectTimeImFor.id === 'gtc') {
          data.is_post_only = this.orderData.postOnly;
        }
        data.is_hidden = this.orderData.hidden;
        data.display_quantity = this.displayQty;
        data.is_reduce_only = this.orderData.closeOnTrigger;
        data.time_in_force = this.SelectTimeImFor.id;
      }
      else if(this.tab === 'oco') {
        data.price = this.orderData.limitPrice;
        data.stop_price = this.orderData.stopPrice;
        data.trigger_price = this.orderData.triggerPriceInput;
        data.trigger = this.orderData.trigger;
        data.is_reduce_only = this.orderData.closeOnTrigger;
        if (this.SelectTimeImFor.id === 'gtc') {
          data.is_post_only = this.orderData.postOnly;
        }
        data.time_in_force = this.SelectTimeImFor.id;
        data.is_hidden = this.orderData.hidden;
      }
      else if(this.tab === 'ifd') {
        data.price = this.orderData.limitPrice;
        data.stop_price = this.orderData.stopPrice;
        if (this.SelectTimeImFor.id === 'gtc') {
          data.is_post_only = this.orderData.postOnly;
        }
        data.is_hidden = this.orderData.hidden;
        data.time_in_force = this.SelectTimeImFor.id;
        data.trigger = this.orderData.trigger;
      }

      window.CommonModal.hide('OrderConfirm');
      rf.getRequest('MarginRequest').createOrder({ ...data, is_mam: 1}).then(res => {
        let message = {};

        if (!(res && res.data)) {
          throw res;
        }

        const triggerText = {
          mark: this.$t('margin.mark_price'),
          last: this.$t('margin.last_price'),
          index: this.$t('margin.index_price'),
        }
        const trigger = this.orderData.trigger && triggerText[this.orderData.trigger] ? triggerText[this.orderData.trigger] : triggerText['index'];
        this.orderData.limitPrice = new BigNumber(`${this.orderData.limitPrice || 0}`).toString();

        if(this.tab === 'limit' || this.tab === 'market') {
          let price = this.tab === 'limit' ? this.orderData.limitPrice : this.$t('margin.market');
          message = this.$t('margin.order.succes.limit_market', { 
            side: this.orderData.side === 'buy' ? this.$t('margin.buy') : this.$t('margin.sell'),
            symbol: this.contract.symbol,
            price,
            quantity: res.data.quantity,
            contract: this.$tc('margin.contract.pluralization', parseInt(res.data.quantity), {n: parseInt(res.data.quantity)})
          });
        }
        else if(this.tab === 'stop_limit' || this.tab === 'stop_market') {
          let price = this.tab === 'stop_limit' ? this.orderData.limitPrice : this.$t('margin.market');
          message = this.$t('margin.order.succes.stop_limit_market', { 
            side: this.orderData.side === 'buy' ? this.$t('margin.buy') : this.$t('margin.sell'),
            symbol: this.contract.symbol,
            price,
            trigger,
            trigger_price: this.buttonData.triggerPrice,
            condition: this.orderData.side === 'buy' ? this.$t('margin.and_above') : this.$t('margin.and_below'),
            quantity: res.data.quantity,
            contract: this.$tc('margin.contract.pluralization', parseInt(res.data.quantity), {n: parseInt(res.data.quantity)}),
          });
        }
        else if(this.tab === 'trailing_stop') {
          message = this.$t('margin.order.succes.trailing_stop', { 
            side: this.orderData.side === 'buy' ? this.$t('margin.buy') : this.$t('margin.sell'),
            symbol: this.contract.symbol,
            price: this.$t('margin.market'),
            trail_value: this.orderData.trailValue,
            triggerPrice: (this.orderData.side === 'buy' ? '>=' : '<=') + this.getPrice('trailing_stop', {triggerPrice: this.orderData.triggerPrice, trailValue: this.orderData.trailValue, tick_size: this.contract.tick_size, showFilter: true}),
            quantity: res.data.quantity,
            contract: this.$tc('margin.contract.pluralization', parseInt(res.data.quantity), {n: parseInt(res.data.quantity)}),
          });
        }
        else if(this.tab === 'take_profit_market' || this.tab === 'take_profit_limit') {
          let price = this.tab === 'take_profit_limit' ? this.orderData.limitPrice : this.$t('margin.market');

          message = this.$t('margin.order.succes.take_profit_limit_market', { 
            side: this.orderData.side === 'buy' ? this.$t('margin.buy') : this.$t('margin.sell'),
            symbol: this.contract.symbol,
            price,
            trigger,
            trigger_price: this.$options.filters.formatMarginPrice(this.orderData.triggerPriceInput, this.contract.tick_size),
            condition: this.orderData.side === 'buy' ? this.$t('margin.and_below') : this.$t('margin.and_above'),
            quantity: res.data.quantity,
            contract: this.$tc('margin.contract.pluralization', parseInt(res.data.quantity), {n: parseInt(res.data.quantity)}),
          });
        }
        else if(this.tab === 'oco') {
          let price = this.orderData.limitPrice;
          message = this.$t('margin.order.succes.limit_market', { 
            side: this.orderData.side === 'buy' ? this.$t('margin.buy') : this.$t('margin.sell'),
            symbol: this.contract.symbol,
            price,
            quantity: res.data.quantity,
            contract: this.$tc('margin.contract.pluralization', parseInt(res.data.quantity), {n: parseInt(res.data.quantity)}),
          });
          Message.success(message, {}, { position: 'bottom_left', head: this.$t('margin.order.submitted') });

          price = this.orderData.stopPrice;
          message = this.$t('margin.order.succes.stop_limit_market', { 
            side: this.orderData.side === 'buy' ? this.$t('margin.buy') : this.$t('margin.sell'),
            symbol: this.contract.symbol,
            price,
            trigger,
            trigger_price: this.$options.filters.formatMarginPrice(this.orderData.triggerPriceInput, this.contract.tick_size),
            condition: this.orderData.side === 'buy' ? this.$t('margin.and_above') : this.$t('margin.and_below'),
            quantity: res.data.quantity,
            contract: this.$tc('margin.contract.pluralization', parseInt(res.data.quantity), {n: parseInt(res.data.quantity)}),
          });
        }
        else if(this.tab === 'ifd') {
          let price = this.orderData.limitPrice;
          message = this.$t('margin.order.succes.limit_market', { 
            side: this.orderData.side === 'buy' ? this.$t('margin.buy') : this.$t('margin.sell'),
            symbol: this.contract.symbol,
            price,
            quantity: res.data.quantity,
            contract: this.$tc('margin.contract.pluralization', parseInt(res.data.quantity), {n: parseInt(res.data.quantity)}),
          });
          Message.success(message, {}, { position: 'bottom_left', head: this.$t('margin.order.submitted') });
          price = this.orderData.stopPrice;
          message = this.$t('margin.order.succes.limit_market', { 
            side: this.orderData.side === 'buy' ? this.$t('margin.sell') : this.$t('margin.buy'),
            symbol: this.contract.symbol,
            price: this.orderData.stopPrice,
            trigger,
            trigger_price: this.orderData.stopPrice,
            condition: this.orderData.side === 'buy' ? this.$t('margin.and_below') : this.$t('margin.and_above'),
            quantity: res.data.quantity,
            contract: this.$tc('margin.contract.pluralization', parseInt(res.data.quantity), {n: parseInt(res.data.quantity)}),
          });
        }

        Message.success(message, {}, { position: 'bottom_left', head: this.$t('margin.order.submitted') });
      }).catch(error => {
        window.CommonModal.hide('OrderConfirm');

        if (!error.response || !error.response.data || !error.response.data.errors) {
          let message = 'Error!!!';
          if (error.response && error.response.data && error.response.data.message) {
            message = error.response.data.message;
            if (error.response.data.message == 'Insufficient available balance') {
              message = window.i18n.t('margin.insufficient_vailable_balance', {
                cost: this.getCostValueFunc(this.orderData.side),
                availableBalance: this.balance.availableBalance 
              });
            }
            if (error.response.data.message == "reduce_only_error") {
              message = window.i18n.t('margin.reduce_only_error', {
                symbol: this.contract.symbol,
                quantity: this.orderData.side === 'buy' ? this.orderData.quantity : '-' + this.orderData.quantity,
                current_qty: this.position.current_qty 
              });
            }
          }
          Message.error(message, {}, { position: 'bottom_left' });
          return;
        }

        const errors = error.response.data.errors;
        let err = [];
        Object.keys(errors).forEach((key) => {
          err.push({
            field: key,
            msg: errors[key][0]
          });
          return;
        });

        Message.error(err[0].msg, {}, { position: 'bottom_left' });
      });
    },

    validateBuy() {
      let message;
      let costValue = new BigNumber(`${this.orderData.costValue || 0}`);
      if (!costValue.eq('0') && costValue.gt(`${this.balance.availableBalance || 0}`)) {
        message = this.$t('margin.order.error.cost_gt_balance', { 
          cost: this.$options.filters.formatMarginNumber(this.orderData.costValue), 
          balance: this.$options.filters.formatMarginNumber(this.balance.availableBalance)
        });
        Message.error(message, {}, { position: 'bottom_left' });
        return false;
      }
      return true;
    },

    validateSell() {
      let message;
      let costValue = new BigNumber(`${this.orderData.costValueSell || 0}`);
      if (!costValue.eq('0') && costValue.gt(`${this.balance.availableBalance || 0}`)) {
        message = this.$t('margin.order.error.cost_gt_balance', { 
          cost: this.$options.filters.formatMarginNumber(this.orderData.costValueSell), 
          balance: this.$options.filters.formatMarginNumber(this.balance.availableBalance)
        });
        Message.error(message, {}, { position: 'bottom_left' });
        return false;
      }
      return true;
    },

    getSocketEventHandlers() {
      return {
        MarginOrderbookClicked: this.marginOrderbookClicked,
        MarginOrderUpdated: this.onMarginOrderUpdated,
      };
    },

    onMarginOrderUpdated(data) {
      this.getMamBalance();
      if (data && data.order && data.action == 'matched') {
        const isFilled = parseInt(data.order.remaining) == 0 && data.order.status == 'executed'
        const tick_size = window._.get(this, 'contract.tick_size', Const.MARGIN_DEFAULT_TICK_SIZE);
        const numberOfDecimalDigits = Utils.getPrecisionNumber(tick_size);
        let message = this.$t(isFilled ? 'margin.order.success.limit_market.filled' : 'margin.order.success.limit_market.partial_filled', { 
          side: data.order.side,
          symbol: data.order.instrument_symbol,
          price: data.order.type == 'limit' ? this.$options.filters.formatMarginNumber(data.order.trade_price, numberOfDecimalDigits) : 'Market',
          quantity: this.$options.filters.formatMarginNumber(data.order.trade_quantity, 0),
        });
        Message.info(message, {}, { position: 'bottom_left', head: this.$t('margin.order.filled') });
      }

      if (data && data.order && data.action == 'liquid') {
        let message = this.$t('margin.position.liquid.mes' , { 
          symbol: data.order.instrument_symbol,
        });
        Message.info(message, {}, { position: 'bottom_left', head: this.$t('margin.position.liquid') });
      }
      if (data && data.order && data.action == 'canceled') {
        let message = this.$t('margin.order.canceled_success');
        if(this.canceledStack && !this.canceledStack[data.order.reference_id]){
          Message.success(message, {}, { position: 'bottom_left', head: this.$t('margin.order.canceled') });
          if(['ifd', 'oco'].includes(data.order.pair_type)){
            this.canceledStack = {
              ...this.canceledStack,
              [data.order.reference_id]: data.order.id
            }
          }
        }
      }
      if (data && data.order && data.action != 'created' && data.action != 'matched' && data.action != 'canceled' && data.action != 'activated' && data.action != 'liquid') {
        Message.error(data.action, {}, { position: 'bottom_left' });
      }
    },

    marginOrderbookClicked(orderItem) {
      this.orderData.quantity = this.calculatorMaxQuantity(orderItem);

      if(this.tab === 'limit' 
      || this.tab === 'stop_limit' || this.tab === 'take_profit_limit' || this.tab === 'oco' || this.tab === 'ifd') {
        this.orderData.limitPrice = orderItem.price;
      }
      if (this.tab === 'stop_limit' || this.tab === 'stop_market' || this.tab === 'oco' || this.tab === 'ifd') {
        this.orderData.stopPrice = orderItem.price;
      }
      if (this.tab === 'take_profit_market' || this.tab === 'take_profit_limit' || this.tab === 'oco') {
        this.orderData.triggerPriceInput = orderItem.price;
      }
    },

    calculatorMaxQuantity(orderItem) {
      return orderItem.totalQuantity;
    },

    selectPercent(key, side = 'buy') {
      this.percents.forEach(percent => percent.value = false);
      let temp = this.percents.find(percent => percent.key == key);
      if(temp) temp.value = true;

      let price;
      if (this.tab === 'market' || this.tab === 'stop_market' || this.tab === 'trailing_stop') {
        price = this.currentInstrument ? this.currentInstrument.last_price : 0;
      } else {
        price = this.orderData.limitPrice ? this.orderData.limitPrice : 0;
      }
      if(price === 0) return;

      let quantity = MarginCalculator.getContractQuantityByMargin(price, this.position, this.balance, side);
      
      let low = 0;
      let currentQty = new BigNumber(`${this.position.current_qty || 0}`).add(`${this.position.open_order_buy_qty || 0}`).sub(`${this.position.open_order_sell_qty || 0}`).toString();
      if (side == 'buy' && new BigNumber(`${currentQty || 0}`).comparedTo(0) < 0) {
        low = new BigNumber(`${currentQty || 0}`).absoluteValue().toString();
        low = parseInt(low);
      } else if (side == 'sell' && new BigNumber(`${currentQty || 0}`).comparedTo(0) > 0) {
        low = new BigNumber(`${currentQty || 0}`).toString();
        low = parseInt(low);
      }
      let addedQty = parseInt(quantity) - low;

      this.orderData.quantity = new BigNumber(addedQty).mul(key).div(100).round(0, BigNumber.ROUND_HALF_DOWN).add(low).toString();
    },
    onFocusQuantity(){
       this.percents.forEach(percent => percent.value = false);
    },
    onChangeTypePercent(type){
      this.percentSide = type;
      this.percents.forEach(percent => percent.value = false);
    }
  },
  mounted() {
    this.initData();
    this.setTriggerPrice();  
    this.precision = this.precisionPrice();
  }
};
</script>

<style lang="scss" scoped>
  @import '@/assets/sass/variables';
  .th_width {
    width: 120px;
  }
  .float_left {
    float: left;
  }
  .float_right {
    float: right;
  }
  .box_value_right {
    position: relative;
    .value_left {
      padding-left: 5px;
      padding-right: 5px;
      display: inline-block;
      max-width: 60px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      float: left;
    }
    .value_right {
      padding-left: 5px;
      padding-right: 5px;
      display: inline-block;
      max-width: 60px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      float: right;
    }
    .full_value_right {
      position: absolute;
      top: 100%;
      background-color: #222832;
      color: #cccccc;
      min-width: 250px;
      line-height: 20px;
      padding: 1px 5px;
      z-index: 5;
      border-radius: 3px;
      box-shadow: 0px 0px 5px rgba(88, 88, 88, 0.5);
      display: none;
    }
    .cost_buy {
      left: -35px;
    }
    .cost_sell {
      text-align: left;
      left: -200px;
    }
    &:hover {
      cursor: help;
      .full_value_right {
        display: block;
      }
    }
  }
  .text-bold {
    font-family: $font-family-roboto-bold;
  }
  .fs13 {
    font-size: 13px;
  }
  .label_input{
    font-size: 12px;
    color: #cccccc;
    margin-bottom: 5px;
  }
  .group_input_form {
    display: block;
    width: 100%;
    float: left;
  }
  .textAuthen {
    text-align: center;
    font-size: 13px;
    color: #666666;
    line-height: 20px;
    margin-top: 20px;
    a {
      color: #2e9bdb;
    }
  }
  .login_area{
    margin: 0 auto;
    display: block;
  }

  .text-right {
    text-align: right;
  }
  div.disabled
  {
    pointer-events: none;
    opacity: 0.5;
  }
  #order_form_tabs {
    div.disabled
    {
      pointer-events: none;
      opacity: 0.5;
    }
    .group_input_form {
      display: block;
      width: 100%;
      .input_right_group {
        width: 100%;
        margin-bottom: 12px;
      }
    }
    .group_buy_sell {
      display: block;
      margin: 3px 0px;
      width: 100%;
      .buy_left {
        width: calc(50% - 2px);
        display: block;
        float: left;
        margin-right: 2px;
        border-radius: 3px;
        background-color: $color-jungle-green;
        // overflow: hidden;
        cursor: pointer;
      }
      .sell_right {
        width: calc(50% - 2px);
        display: block;
        float: left;
        margin-left: 2px;
        border-radius: 3px;
        background-color: $color-denim;
        // overflow: hidden;
        cursor: pointer;
      }
      .header_sell_buy {
        cursor: hand;
        text-transform: uppercase;
        font-size: $font-smaller;
        border-bottom: 1px solid $color-white;
        text-align: center;
        color: $color-white;
        text-transform: uppercase;
        font-family: $font-family-roboto-bold;
        line-height: 20px;
        padding: 8px 5px;
        letter-spacing: 0.9px;
      }
      .content_buy_sell {
        text-align: center;
        color: $color-white;
        font-size: $font-smaller;
        line-height: 17px;
        padding: 5px 2px;
      }
    }
    .table_Cost_Order {
      margin: 10px 0px;
      font-size: $font-smaller;
      color: $color-grey-sliver;
      table {
        width: 100%;
        th {
          line-height: 20px;
          padding: 5px 0px 5px 0px;
          font-family: $font-family-roboto-medium;
          font-weight: 500;
        }
        tr {
          td {
            line-height: 20px;
            padding: 0 0 0 0;
          }
        }
      }
    }
    .content_bottom_order {
      border-radius: 3px;
      background-color: #111722;
      font-size: $font-smaller;
      padding: 8px 10px;
      color: $color-grey;
      .group_bottom_order {
        clear: both;
        width: 100%;
        margin-bottom: 2px;
        label.disabled{
          cursor: not-allowed;
          opacity: 0.5;
        }
        .left {
          width: 50%;
          float: left;
          padding-right: 2px;
          .label_check {
            float: left;
            input[type="checkbox"] {
              float: left;
              margin-right: 5px;
              margin-left: 0px;
            }
          }
        }
        .right {
          width: 50%;
          float: right;
          padding-left: 2px; 
          .tooltipWrapper {
            float: right;
            .triggerExecInst {
              float: right;
              margin-right: 0px;
            }
          }
        }
        .tooltipWrapper {
          margin: 0px;
          line-height: 22px;
          font-weight: 500;
          .triggerExecInst {
            margin: 0px;
            margin-right: 3px;
            float: left;
            border-radius: 3px;
            border: solid 1px $color-bright-gray-dark;
            height: 22px;
            background-color: transparent;
            cursor: pointer;
            option {
              &:hover {
                color: $color-jungle-green-light;
                background-color: transparent;
              }
            }
          }
          &:hover {
            color: $color-jungle-green-light;
            .triggerExecInst {
              color: $color-grey;
            }
          }
        }
        .label_check {
          float: right;
          line-height: 22px;
          font-weight: 500;
          cursor: pointer;
          margin: 0px;
          position: relative;
          &:after {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0px;
            left: 0px;
            z-index: 2;
            background-color: transparent;
          }
          &.active,
          &:hover {
            color: $color-jungle-green;
          }
          .icon {
            float: right;
            margin-top: 6px;
            margin-right: 0px;
            margin-left: 5px;
          }
          input {
            margin-top: 12px;
            margin-right: 5px;
            & {
              &[type="checkbox"]:checked {
                background-color: $color-blue-custom;
                border-color: $color-blue-custom;
              }
              &[type="checkbox"]:after {
                content: '';
                position: relative;
                left: 2px;
                top: 0px;
                width: 50%;
                height: 70%;
                border: solid $color-white;
                border-width: 0 1px 1px 0;
                transform: rotate(45deg);
                display: none;
              }
              &[type="checkbox"]:checked:after {
                display: block;
              }
            }
          }
          input[type="checkbox"] {
            cursor: pointer;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            outline: 0;
            background: transparent;
            height: 10px;
            width: 10px;
            border-radius: 2px;
            border: solid 1px $color-bright-gray-dark;
            background-color: $color-charade;
            margin-top: 6px;
            margin-right: 0px;
            margin-left: 5px;
            float: right;
          }
        }
      }
    }
  }
  .tooltip1 {
    position: relative;
    display: inline-block;
  }
  /* Tooltip text */
  .tooltip1 .tooltipText1 {
    visibility: hidden;
    width: 250px;
    background-color: #222832;
    color: #cccccc;
    text-align: center;
    padding: 5px 5px;
    border-radius: 4px;
    font-size: 13px;
    font-family: $font-family-roboto;
    line-height: 17px;
  
    /* Position the tooltip text - see examples below! */
    box-shadow: 0px 0px 3px #cccccc;
    position: absolute;
    z-index: 3;
  }

  .tooltipBuy {
    right: -120px;
    // bottom: -230px;
  }

  .tooltipSell {
    right: -10px;
    // bottom: -230px;
  }

  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip1:hover .tooltipText1 {
    visibility: visible;
  }

  .box_custom_tooltip {
    position: relative;
    .tooltip_custom_c {
      font-family: $font-family-roboto;
      font-weight: 400;
      visibility: hidden;
      display: none;
      width: 250px;
      background-color: #222832;
      color: #cccccc;
      text-align: center;
      padding: 5px 0;
      border-radius: 4px;
      font-size: 13px;
      -webkit-box-shadow: 0px 0px 3px #cccccc;
      box-shadow: 0px 0px 3px #cccccc;
      position: absolute;
      z-index: 50;
      left: 100%;
      top: 100%;
      line-height: 18px;
      &.left {
        left: 0px;
        right: auto;
      }
      &.right {
        left: auto;
        right: 0px;
      }
      .underline {
        text-decoration: underline;
        display: block;
        line-height: 20px;
        margin-bottom: 5px;
      }
    }
    &:hover {
      cursor: help;
      .tooltip_custom_c {
        display: block;
        visibility: visible;
        color: #cccccc;
      }
    }
  }

  .percent_buyForm {
      display: block;
      width: 100%;
      margin-bottom: 10px;
      border-radius: 0 0 3px 3px;
      .check_percent {
        display: block;
        width: calc( 100% / 4 - 5px);
        margin-right: 5px;
        float: left;
        border-radius: 3px;
        border: solid 1px $color-charade;
        letter-spacing: 0.5px;
        font-size: $font-smaller;
        text-align: center;
        color: $color-grey;
        height: 25px;
        margin-top: 5px;
        line-height: 24px;
        cursor: pointer;
        &:last-child {
          width: calc( 100% / 4 );
          margin-right: 0px;
        }
      }
    }
    .buy-form{
      border: solid 1px $color-jungle-green;
      padding: 0px 5px 5px 5px;
      .check_percent {
        &.active,
        &:hover {
          background-color: $color-green;
          color: $color-white;
          border-color: $color-green;
        }
      }
    }
    .sell-form{
      border: solid 1px $color-denim;
      padding: 0px 5px 5px 5px;
      .check_percent {
        &.active,
        &:hover {
          background-color: $color-denim;
          color: $color-white;
          border-color: $color-denim;
        }
      }
    }
</style>

<style lang="scss">

  @import '@/assets/sass/variables';
  .input_right_group1 {
    width: 90%;
    float: right;
    text-align: right;
    .coin_input {
      input {
        height: 30px !important;
        padding-top: 5px !important;
        padding-bottom: 5px !important;
      }
    }
  }
  #ID_TimeInFor {
    .group_search_select {
      .button_drop_search {
        border-radius: 3px;
        border: solid 1px $color-bright-gray-dark;
        padding: 1px 5px;
        height: 22px;
        >span {
          color: $color-grey;
          max-width: 75px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: $font-smaller;
        }
      }
    }
  }
  #order_form_tabs {
    #OrderConfirm {
      .modal {
        .modal-dialog {
          .modal-content {
            max-height: 95vh;
            overflow-y: auto;
          }
        }
      }
    }
  }
  .header-percent-form {
    background-color: $color-ebony-clay;
    ul {
      margin: 0px;
      display: block;
      width: 100%;
      overflow: hidden;
      margin-bottom: -1px;
      li {
        width: calc(50% - 1px);
        float: left;
        cursor: pointer;
        text-align: center;
        font-size: $font-root;
        color: $color-grey-sliver;
        font-family: $font-family-roboto-bold;
        border-radius: 3px;
        height: 32px;
        line-height: 20px;
        padding: 6px;
        &:last-child {
          margin-left: 2px;
        }
        &:hover {
          background-color: $color-mirage;
        }
        &.active {
          background-color: $color-mirage;
          color: $color-jungle-green;
        }
        &.active-buy-percent {
          background-color: $color-mirage;
          color: $color-jungle-green;
          border: solid 1px $color-jungle-green;
          border-bottom: 1px solid $color-mirage;
          z-index: 1;
          position: relative;
          bottom: -2px;
          margin-top: -1px;
        }
        &.active-sell-percent {
          background-color: $color-mirage;
          color: $color-denim;
          border: solid 1px $color-denim;
          border-bottom: 1px solid $color-mirage;
          z-index: 1;
          position: relative;
          bottom: -2px;
          margin-top: -1px;
        }
        i.icon {
          &:hover {
            box-shadow: 0px 0px 7px $color-white;
          }
        }
      }
    }
    span{
      margin-top: 5px;
      background-color: $color-ebony-clay;
    }
  }
</style>