<template>
  <div class="coin_input">
    <div class="left-input">{{ leftTitleInput }}</div>
    <input type="text" class="form-control input-coin-number" :placeholder="placeholder" v-bind:class="{ 'border-bottom': component === OrderForm}" :value="value" :disabled="disabled" v-if="inputText"/>
    <currency-input
      :class="getClasses()"
      :precision="getPrecision()"
      :maxLengthCus='16'
      :onKeyDown="onKeyDown"
      v-model="internalValue" :placeholder="placeholder"
      :disabled="disabled" :ref="refCurrencyInput" v-else/>

    <span class="unit-coin" :class="{'r10i': !visibleButton}" v-if="visibleUnit">{{unit | uppercase}}</span>
    <button tabindex="32766" class="plus" v-if="visibleButton" @click="onIncreaseClicked()"><i class="icon-arrow-up-bold1"></i></button>
    <button tabindex="32767" class="minus" v-if="visibleButton" @click="onDecreaseClicked()"><i class="icon-arrow-down-bold1"></i></button>
  </div>
</template>
<script>
  import BigNumber from 'bignumber.js';
  import CurrencyInput from './CurrencyInput';

  export default {
    components: {
      CurrencyInput
    },
    props: {
      value               : {},
      precision           : {type: Number},
      unit                : {type: String},
      disabled            : {type: Boolean, default: false},
      inputText           : {type: Boolean, default: false},
      visibleButton       : {type: Boolean, default: false},
      classes             : {type: String},
      placeholder         : {type: String},
      component           : {type: String},
      leftTitleInput      : {type: String},
      keydown            : {type: Boolean, default: false},
    },
    data() {
      return {
        internalValue: '',
        refCurrencyInput: this.getRefUniqueId(),
        OrderForm: "order-form"
      };
    },
    watch: {
      value(newValue) {
        this.updateValue();
      },
      precision(newPrecision) {
        this.updatePrecision(newPrecision);
      },
      internalValue(val) {
       this.$emit('input', val);
     }
   },
   computed: {
    visibleUnit() {
      return !window._.isEmpty(this.unit);
    },
  },
  methods: {
    onIncreaseClicked() {
      this.internalValue = this.newBigNumber(this.internalValue).plus(`${this.precision}`).toString();
    },

    onDecreaseClicked() {
      const result = this.newBigNumber(this.internalValue).sub(`${this.precision}`);
      if (result.gte(this.precision)) {
        this.internalValue = result.toString();
      }
    },
    onKeyDown (event) {
      if(this.keydown){
        if (event.key === 'ArrowUp') {
          this.onIncreaseClicked()
          event.preventDefault();
        }

        if (event.key === 'ArrowDown') {
          this.onDecreaseClicked()
          event.preventDefault();
        }
      }
    },

    getClasses() {
      if (!this.classes) {
        return 'input-coin-number';
      }
      return `input-coin-number ${this.classes}`;
    },

    getPrecision() {
      const precision = this.precision ? Math.round(Math.log(1 / this.precision) / Math.log(10)) : 8;
      return this.unit === 'usd' ? 0 : precision;
    },

    getRefUniqueId() {
      return window._.uniqueId('input-coin-number-');
    },

    newBigNumber(value) {
      if (window._.isEmpty(value)) {
        value = 0;
      }
      return new BigNumber(value);
    },

    updateValue() {
      if (this.value instanceof BigNumber) {
       this.internalValue = this.value.toString();
       return;
      }
      this.internalValue = this.value;
    },

   updatePrecision(newPrecision) {
    const precision = Math.round(Math.log(1 / newPrecision) / Math.log(10));
    this.$refs[this.refCurrencyInput].setPrecision(precision);
  }
},
mounted() {
  this.updateValue();
}
}
</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables";

.dark-theme {
  .coin_input {

    .left-input{
      color: $color-storm-grey!important;
    }

    input {
      background: $color-gunmetal !important;
    }

    input[type="text"] {
      color: white;
    }
  }

  .coin_input > span.unit-coin {
    color: $color-auro-metal;
  }
  .coin_input input[type=text]::-webkit-input-placeholder {
    color: $color-auro-metal;

  }
  .coin_input {
    .icon-arrow-up-bold1, .icon-arrow-down-bold1 {
    &:before {
      color: $color-storm-grey;
    }
  }
  }
}


.coin_input{
  position: relative;

  .icon-arrow-up-bold1, .icon-arrow-down-bold1 {
    &:before {
      color: $color-gray-dark;
    }
  }

  .left-input {
    font-family: $font-inter-regular;
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
    color: $color-gray-dark;
  }
  input[type="text"]{
    padding: 10px;
    min-height: 49px;
    border: none;
    box-shadow: none;
    width: 100%;
    padding-right: 27px;
    padding-left: 15px;
    border-radius: 8px;
    font-weight: 400;
    color: #191919;
    background-color: $color-alabaster;
    font-size: 18px;
    text-align: left;
    &:focus {
      border: 1px solid $color-aqua-green;
    }
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
       font-size: 18px;
       color: $color-gray-dark;
       font-weight: 400;
       font-family: $font-inter-regular;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      font-size: 18px;
      color: $color-gray-dark;
      font-weight: 400;
      font-family: $font-inter-regular;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      font-size: 18px;
      color: $color-gray-dark;
      font-weight: 400;
      font-family: $font-inter-regular;
    }
    &:-moz-placeholder { /* Firefox 18- */
      font-size: 18px;
      color: $color-gray-dark;
      font-weight: 400;
      font-family: $font-inter-regular;
    }
  }
  &>span {
    position: absolute;
    top: 0;
    right: 27px;
    color: $color-grey;
    line-height: 49px;
    font-size: $font-root;
    &.r10i {
      right: 14px;
    }

    &.unit-coin {
      font-family: $font-inter-regular;
      font-weight: 400;
      font-size: 18px;
      color: $color-gray-dark;
    }
  }

  button{
    position: absolute;
    right: 0;
    height: 20px;
    width: 22px;
    background-color: transparent;
    border: 0;
    &:focus {outline:0;}
    &.plus{
      top: 3px;
      border-top: none;
      &>i {
        position: absolute;
        top: 6px;
        right: 9px;
        color: $color_grey_border;
        line-height: 19px;
        font-size: 6px;
      }
    }
    &.minus{
      bottom: 4px;
      &>i {
        position: absolute;
        bottom: 6px;
        right: 9px;
        color: $color_grey_border;
        line-height: 16px;
        font-size: 6px;
      }
    }
    }
    &:focus{
      outline: -webkit-focus-ring-color auto 5px;
    }
  }
@media screen and (max-width: 992px) {
  .coin_input input {
    font-size: 16px!important;
  }
}
</style>
