<template>
  <deposit-page ref="deposit_page" :configs="configs">
    <template slot="title">{{ $t("funds.deposit.title_page") }}</template>
    <template slot="actions">
      <div class="deposit-content">
        <div class="address-box form-important">
          <!-- <label class="icon-star2" v-if="coinName === 'usdt'"></label> -->
          <template v-if="coinName === 'xrp' || coinName === 'eos' || coinName === 'trx'">
            <div class="form-group address-box">
              <div for="blockchain_sub_address">
                {{
                  $t("funds.deposit.deposit_tag", {
                    coin: coinName.toUpperCase(),
                  })
                }}
              </div>
              <input
                id="blockchain_sub_address"
                class="address"
                :value="address.blockchain_sub_address"
                readonly
              />
            </div>
            <div class="btn-box">
              <button
                tabindex="0"
                :class="{ chrome_only: isChrome }"
                type="button"
                class="btn box_left"
                :disabled="!address.blockchain_sub_address"
                v-clipboard:copy="address.blockchain_sub_address"
                v-clipboard:success="onCopySuccess"
                v-clipboard:error="onCopyError"
              >
                {{ $t("funds.deposit.copy_tag") }}
              </button>
              <button
                tabindex="0"
                :class="{ chrome_only: isChrome }"
                type="button"
                class="btn box_right"
                :disabled="!address.blockchain_sub_address"
                @click="showQRCode('blockchain_sub_address')"
              >
                {{ $t("funds.deposit.show_qr") }}
              </button>
            </div>
          </template>
          <div
            v-if="coinName === 'usdt'"
            class="form-group text-important"
            v-html="
              $t('funds.deposit.important.text1', {
                name: coinName.toUpperCase(),
              })
            "
          ></div>
          <!-- <label class="icon-star2"></label> -->
          <div class="note">
              <img  v-if="coinName === 'xrp' || coinName === 'eos' || coinName === 'trx'" src="/images/icon/icon-note.svg"/>
              <div
                v-if="coinName"
                class="form-group text-important"
                v-html="
                  $t('funds.deposit.important.text2', {
                    name: coinName.toUpperCase(),
                  })
                "
              ></div>
          </div>
          <div v-if="coinName" for="address" class="form-address">
            {{
              $t("funds.deposit.deposit_address", {
                coin: coinName && coinName.toUpperCase(),
              })
            }}
          </div>
          <div class="tool-tip">
<!--              <p v-show="bigger" class="fullAdress">{{address.blockchain_address}}</p>-->
              <input
                  id="address"
                  ref="address"
                  class="address customDepositAddress"
                  :value="selectedCoin?.networks?.length > 0 ? selectedCoin?.chain ? address.blockchain_address : '' : address.blockchain_address"
                  readonly
                />
                
          </div>
          <div class="btn-box">
            <button
              tabindex="0"
              :class="{ chrome_only: isChrome }"
              type="button"
              class="btn box_left"
              :disabled="!address.blockchain_address || selectedCoin?.networks?.length > 0 ? selectedCoin?.chain ? false : true : false"
              v-clipboard:copy="address.blockchain_address"
              v-clipboard:success="onCopySuccess"
              v-clipboard:error="onCopyError"
            >
              <img src="/images/icon/icon-copy.svg" alt="icon" class="icon" />
              {{ $t("funds.deposit.coppy_address") }}
            </button>
            <button
              tabindex="0"
              :class="{ chrome_only: isChrome }"
              type="button"
              class="btn box_right button_qr_code"
              :disabled="!address.blockchain_address || selectedCoin?.networks?.length > 0 ? selectedCoin?.chain ? false : true : false"
              @click="showQRCode('blockchain_address')"
            >
              <img
                src="/images/icon/icon-qr-code.svg"
                alt="icon"
                class="icon"
              />
              {{ $t("funds.deposit.show_qr") }}
            </button>
          </div>
        </div>
      </div>
      <div class="form-notice">
        <p class="text-notice">{{ $t("funds.deposit.tips.text1") }}</p>
        <ul>
          <li
           v-if="(coinName === 'xrp' || coinName === 'eos' || coinName === 'trx') && getConfirmation(coinName) === 1" v-html="`${$t('funds.deposit.tips.text2', { name: coinName.toUpperCase(), count: getConfirmation(coinName) })}` "
          ></li> 
          <li v-html="$t('funds.deposit.tips.text3')" class></li>
        </ul>
      </div>
      <modal v-if="coinName" name="blockchain_address" width="560">
        <div slot="body" class="qrcode-modal">
          <div v-if="coinName" class="text-head text-center">
            {{
              $t("funds.deposit.deposit_address", {
                coin: coinName.toUpperCase(),
              })
            }}
          </div>
          <div class="text-center qr-code">
            <div class="qr-img">
              <img :src="address.qrcode" />
            </div>
          </div>
          <div>
            <div class="text-center model-address">
              {{ address.blockchain_address }}
            </div>
          </div>
          <div>
            <div class="text-center">
              <button
                type="button"
                class="btn-model"
                v-clipboard:copy="address.blockchain_address"
                v-clipboard:success="onCopySuccess"
                v-clipboard:error="onCopyError"
              >
                <img src="/images/icon/icon-copy.svg" alt="icon" class="icon" />
                {{ $t("funds.deposit.coppy_address") }}
              </button>
            </div>
          </div>
        </div>
      </modal>
      <modal v-if="coinName" name="blockchain_sub_address" width="560">
        <div slot="body" class="qrcode-modal">
          <div v-if="coinName" class="text-head text-center">
            {{
              $t("funds.deposit.deposit_tag", { coin: coinName.toUpperCase() })
            }}
          </div>
          <div class="text-center qr-code">
            <div class="qr-img">
              <img :src="address.qr_tag" />
            </div>
          </div>
          <div>
            <div class="text-center">{{ address.blockchain_sub_address }}</div>
          </div>
          <div>
            <div class="text-center">
              <button
                type="button"
                class="btn-model"
                v-clipboard:copy="address.blockchain_sub_address"
                v-clipboard:success="onCopySuccess"
                v-clipboard:error="onCopyError"
              >
                <img src="/images/icon/icon-copy.svg" alt="icon" class="icon" />
                {{ $t("funds.deposit.copy_tag") }}
              </button>
            </div>
          </div>
        </div>
      </modal>

    <div class="kyc-warning-modal">
      <transition name="modal" v-if="isNotPassKYC">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div slot="body" class="kyc-modal">
            <img src="@/assets/images/note_icon.svg"/>
            <div class="kyc-modal__title">{{ $t("text_note")}}</div>
            <div class="kyc-modal__content">{{ $t("note_kyc")}}</div>
            <router-link to="/account/dashboard">
              <button>{{ $t("verify_button")}}</button> 
            </router-link>
          </div>
          </div>
        </div>
      </div>
    </transition>
    </div>

    </template>
  </deposit-page>
</template>

<script>
import rf from "@/request/RequestFactory";
import Modal from "@/components/shared_components/common/Modal";
import BaseDepositWithdraw from "./BaseDepositWithdraw";
import {mapState} from "vuex";

export default {
  components: {
    Modal,
    DepositPage: BaseDepositWithdraw,
  },
  data() {
    return {
      configs: {
        type: "deposit",
        is_show_title: true,
        msgEmptyData: window.i18n.t("funds.deposit.no_data"),
      },
      selectedCoin: {},
      address: {},
      currency: {},
      confimations: {},
      isChrome: window.navigator.userAgent.toLowerCase().indexOf("chrome") > 0,
      bigger : false,
      isNotPassKYC: false
    };
  },
  computed: {
    ...mapState({
      masterdata: (state) => state.masterdata,
    }),
    coinName() {
      // if (_.isEmpty(this.selectedCoin.coinName)) {
      //   return "";
      // }
      return this.selectedCoin?.coinName || "";
    },
  },
  watch: {
    "address": {
      handler() {
       if(this.address.blockchain_address && this.address.blockchain_address.toString().length > 35 ) {
          this.bigger = true;
       }
      },
    },
  },
  methods: {
    getQRCodeGoogleUrl() {
      return rf
        .getRequest("UserRequest")
        .getQRCodeGoogleUrl()
        .then((res) => {
          this.qrCodeUrl = res.data.url;
        });
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    showQRCode(name) {
      CommonModal.show(name, {
        position: "center",
        mask: true,
      });
    },

    onCopySuccess() {
      Message.success(
        window.i18n.t("funds.deposit.copy_success"),
        {},
        { position: "bottom_right" }
      );
    },

    onCopyError() {
      Message.error(
        window.i18n.t("funds.deposit.copy_error"),
        {},
        { position: "bottom_right" }
      );
    },

    getDepositAddress(chain) {
      if(this.isNotPassKYC){
        return
      }
      let params = {
          currency: this.coinName,
          network: chain ? chain:  this.selectedCoin?.networks?.length > 0 ? 'ethereum' : null
        };

      rf.getRequest("UserRequest")
        .getDepositAddress(params)
        .then((res) => {
          this.address = res.data;
        });
    },

    getConfirmation(coin) {
      const confimation = this.confimations[coin]
        ? this.confimations[coin].confirmation
        : 1;
      return parseInt(confimation, 10) || 1;
    },

    getConfirmations() {
      this.confimations = window._.keyBy(
        this.masterdata.coins_confirmation,
        "coin"
      );
    },
    getUser() {
      rf.getRequest("UserRequest")
        .getCurrentUser(true)
        .then((res) => {
          if(!res.data.security_setting.identity_verified){
            this.isNotPassKYC = true
          }
        });
    },
  },
  mounted() {
    this.scrollToTop();
    this.getUser()
    this.$refs.deposit_page.$on("CHANGE_SELECTED_COIN", (selectedCoin) => {
      this.selectedCoin = selectedCoin;
      this.address = {};
      this.$router.replace({ query: { coin: this.selectedCoin.coinName} })
      this.getDepositAddress(selectedCoin?.chain);
      this.getConfirmations();
    });

    document.title = this.$t("menu.deposits") + ` – ${this.tabTitle}`;

    this.$on("UPDATED_LOCALE", () => {
      this.configs.msgEmptyData = window.i18n.t("funds.deposit.no_data");
    });
    
  }
};
</script>
<style lang="scss">
#id_base_deposit_wd {
  .modal-content {
    .modal-body {
      // padding-left: 0;
      // padding-right: 0;
    }
    .modal-footer {
      display: none;
    }
  }
}
</style>
<style lang="scss" scoped>
@import "@/assets/sass/variables";

.kyc-warning-modal {
  .modal-mask {
      position: fixed;
      z-index: 9998;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      display: block;
      transition: opacity .3s ease;
      overflow: hidden;
    }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .modal-container {
    width: 560px;
    margin: 0px auto;
    padding: 50px;
    background-color: var(--background-color-modal);
    border-radius: 8px;
    transition: all .3s ease;
    .modal-body {
      padding: 0;
      .icon {
        display: block;
        margin: auto;
      }
      .content {
        text-align: center;
      }
    }

    @media screen and (max-width: 600px) { 
     width: 350px;
    }
  }
.kyc-modal {
  text-align: center;
  margin-top: -18px;

  &__title {
    font-size: 30px;
    font-weight: 600;
    color: var(--color-black_2);
    margin-top: 30px;
    margin-bottom: 10px;
  }

  &__content {
    color: var(--color-text-title);
    font-size: 16px;
    font-weight: 400;
  }

  button {
    background: #00DDB3;
    text-align: center;
    padding: 14px;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    color: #333;
    border-radius: 8px;
    margin-top: 20px;
    width: 100%;

    &:hover {
      opacity: 0.8;
    }
  }

  @media screen and (max-width: 870px) { 
    &__title {
      margin-top: 20px;
      font-size: 24px;
  }
  }
}
}

.qrcode-modal {
  padding-left: 16px;
  padding-right: 16px;
}

.button_qr_code {
    white-space: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.btn-model {
  @include btn-common(yes);
  padding: 14.5px 20px;
  min-width: 200px;
  max-width: 100%;
}

.text-head {
  font-family: $font-inter-bold;
  color: var(--color-black_2);
  font-size: 30px;
  line-height: 36px;
  margin-bottom: 30px;
}
.qr-code {
  display: flex;
  justify-content: center;
  align-items: center;
  .qr-img {
    background: var(--bgr-qr-code);
    width: 232px;
    padding: 26px;
    border-radius: 8px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }
  }
}
.model-address {
  margin-top: 20px;
  margin-bottom: 40px;
  font-family: $font-inter-bold;
  color: var(--text-primary-color);
  font-size: 18px;
  line-height: 22px;
  overflow-wrap: break-word;
}
.form-notice {
  margin-top: 15px;
  .text-notice {
    color: var(--text-close);
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 5px;
    font-weight: 400;
  }
  li {
    color: var(--dark-1);
    font-size: 16px;
    line-height: 21px;
  }
}
.note {
  display: flex;
  align-items: flex-start;
  img {
    margin-right: 11px;
  }
}
.deposit-content {
  .address-box {
    &.form-important {
      background-color: var(--color-bg-deposit) !important;
      border-radius: 8px;
      padding: 20px;
      @media screen and (max-width: 375px) {
        padding: 15px;
      }
    }
    .text-important {
      color: $text-color-red;
      font-size: 16px;
      line-height: 19px;
      font-weight: 400;
      margin-bottom: 30px;
    }

    label {
    }
    .icon-star2 {
      color: $color-denim;
    }
    .address {
      padding: 13px 15px;
      color: var(--text-primary-color);
      font-size: 18px;
      line-height: 22px;
      border-radius: 8px;
      background: var(--color-input-deposit);
      border: none;
      width: 100%;
      resize: none;
      height: 49px;
    }
    .customDepositAddress {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      pointer-events: none;
    }
    .tool-tip {
      position: relative;
      .fullAdress {
        display: none;
      }
      &:hover{
      .fullAdress {
        display: block;
       position: absolute;
        top: -36px;
        left: 12px;
        padding: 10px;
        background: $bgr-tooltip;
        border-radius: 8px;
        color: $color-white;
      }
      }
    }
    .form-address {
      color: var(--dark-1);
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 8px;
      font-weight: 400;
    }
  }
  .form-group {
    div {
      color: var(--dark-1);
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 8px;
      font-weight: 400;
    }
  }
  .btn-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    margin-top: 15px;
    margin-bottom: 30px;
    .btn {
      @include btn-common(yes);
      flex: 1;
    }
  }
}
.go-to-trade {
  width: 135px;
  height: 19px;
  font-size: 25px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #12575f;
}
.change {
  padding-top: 30px;
  display: flex;
}
//.chrome_only[tabindex]:focus {
  //background-color: $color-blue-custom;
  //border-color: $color-blue-custom;
//}
@media screen and (max-width: 992px) {
  .deposit-content {
    .address-box {
      .text-important {
        font-size: 14px;
        line-height: 16px;
      }

      .form-address {
        font-size: 14px;
        line-height: 16px;
      }

      .address {
        font-size: 14px;
        line-height: 16px;
        resize: none;
        height: 49px;
        padding: 8px 15px;
      }
    }
    .btn-box {
      button {
        font-weight: 400;
        font-size: 14px !important;
      }
    }
  }
  .text-head {
    font-size: 20px;
    line-height: 24px;
  }
  .form-notice {
    li {
      font-size: 14px;
      line-height: 16px;
    }
    .text-notice {
      font-size: 14px;
      line-height: 16px;
    }
  }
  .model-address {
    font-size: 14px;
    line-height: 16px;
  }
  .btn-model {
    
  }
}
@media screen and (max-width: 576px) {
  .deposit-content {
    .btn-box {
      gap: 10px;

      button {
        max-width: 100%;
        font-weight: 400;
        font-size: 13px !important;
      }
      .btn {
        img {
          width: 15px;
        }
      }
    }
  }
}
</style>

