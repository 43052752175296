<template>
    <div :id="containerId" class="trading-view-chart"></div>
</template>

<script>
  import rf from '@/request/RequestFactory'
  import TimezoneUtils from '@/common/TimezoneUtils';
  import LocalStorageUtils from '@/common/LocalStorageUtils';
  import Const from '@/common/Const';

  export default {
    components: {
    },
    props: {
      user: {},
      multipleChart   : { type: Boolean, default: false },
      // resolution      : { default: '1', type: String },
      source          : { default: 'margin', type: String },
      minTick         : { default: 'default', type: String },
      startDate       : { type: Date},
      endDate         : { type: Date},
    },
    data() {
      var dataFeed           = new Datafeeds.UDFCompatibleDatafeed(process.env.VUE_APP_API_URL + "/api/v1/margin/pnl-chart");
      dataFeed.getBars       = this.getBars.bind(this);
      dataFeed.subscribeBars = this.subscribeBars.bind(this);
      return {
        tradingViewChart   : null,
        realtimeDatafeed   : dataFeed,
        symbol             : 'Notional',
        lastBar            : undefined,
        onRealtimeCallback : undefined,
        disableByBetaTester: false,
        indexStudyId: null,
        resolution: '60'
      }
    },
    computed: {
      containerId() {
        const id = 'trading-view-chart';
        if (this.multipleChart) {
          return `${id}-${Date.now()}`;
        }
        return id;
      }
    },
    methods: {
      subscribeBars(symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) {
        this.onRealtimeCallback = onRealtimeCallback;
      },
      fillDataBars(bars, disableByBetaTester) {
        bars = window._.forEach(bars, function(bar) {
          bar.time = parseInt(bar.time);
          bar.open   = disableByBetaTester == true ? 0 : parseFloat(bar.open);
          bar.close  = disableByBetaTester == true ? 0 : parseFloat(bar.close);
          bar.high   = disableByBetaTester == true ? 0 : parseFloat(bar.high);
          bar.low    = disableByBetaTester == true ? 0 : parseFloat(bar.low);
          bar.volume = disableByBetaTester == true ? 0 : parseFloat(bar.volume);
        });
        return bars;
      },
      getBars (symbolInfo, resolution, from, to, onHistoryCallback, onErrorCallback, firstDataRequest) {
        this.$emit('update:resolution', this.resolution);
        let symbol      = symbolInfo.ticker;
        let params = {
          symbol     : symbol,
          resolution : this.resolutionToMilliseconds(this.resolution),
          from       : from,
          to         : to,
          token      : this.user.pnl_chart_code,
          is_mam     : this.source == 'mam' ? 'true' : 'false'
        };
        let self = this;
        rf.getRequest('MarginRequest').getPnLChartBars(params).then(bars => {
          let deniedUser = this.disableByBetaTester;
          bars = this.fillDataBars(bars, deniedUser);
          if (bars.length) {
            let currentTime = Math.floor(Date.now() / resolution) * resolution;
            let lastBar = bars[bars.length - 1];
            if (lastBar.time > currentTime) {
                bars.pop();
            }
          }
          if (bars.length && !self.lastBar) {
            self.lastBar = bars[bars.length - 1];
          }
          onHistoryCallback(bars, {noData: (bars.length == 0)});
        });
      },

      createEmptyBarIfNeeded() {
        let createdAt   = Date.now();
        let resolution  = this.resolutionToMilliseconds(this.resolution);
        let time        = Math.floor(createdAt / resolution) * resolution;

        if (this.lastBar && time > this.lastBar.time) {
          let price = this.lastBar.close;
          this.lastBar = {
            "time"   : time,
            "high"   : price,
            "low"    : price,
            "open"   : price,
            "close"  : price,
            "volume" : 0,
          };
          if (this.onRealtimeCallback) {
            this.onRealtimeCallback(this.lastBar);
          }
        }
      },

      resolutionToMilliseconds(resolution) {
        var minutes = parseInt(resolution);
        // CHARTING_RESOLUTION_MULTIPLICITY : {'D': 1440, 'W': 10080, 'M': 43200},
        if (isNaN(minutes)) {
          minutes = Const.CHARTING_RESOLUTION_MULTIPLICITY[resolution];
        }
        return minutes * 60 * 1000;
      },

      createChart: function(){
        this.isChartReady = false;
        let disabled_features = [
            "use_localstorage_for_settings",
            "context_menus",
            "display_market_status",
            "timeframes_toolbar",
            "header_compare",
            "header_undo_redo",
            "header_saveload",
            "header_screenshot",
            "header_interval_dialog_button",
            "volume_force_overlay",
            "control_bar",
            "border_around_the_chart",
            "header_widget",
            "header_symbol_search",
            "chart_property_page_scales",
            "left_toolbar",
            "main_series_scale_menu",
            "remove_library_container_border",
            "chart_property_page_style",
            "property_pages",
            "show_chart_property_page",
            "chart_property_page_background",
            "chart_property_page_timezone_sessions",
            "chart_property_page_trading",
            "countdown",
            "compare_symbol",
            "header_fullscreen_button",
            "create_volume_indicator_by_default",
            "support_multicharts",
            "header_layouttoggle",
            "show_logo_on_all_charts",
            "chart_crosshair_menu",
            "add_to_watchlist",
            "footer_screenshot",
            "open_account_manager",
            "trading_notifications",
            "multiple_watchlists",
            "show_trading_notifications_history",
            "delete_button_in_legend",
          ];
        this.tradingViewChart = new TradingView.widget({
          symbol                     : this.symbol,
          interval                   : this.resolution,
          locale                     : window.i18n.locale,
          timezone                   : TimezoneUtils.getTimezone(),
          container_id               : this.containerId,
          datafeed                   : this.realtimeDatafeed,
          library_path               : window.location.protocol + '//' + window.location.host + "/tradingview/charting_library/",
          autosize                   : true,
          withdateranges             : false,
          client_id                  : 'pnl_chart',
          user_id                    : 'public_user_id',
          save_image                 : false,
          drawings_access            : { type: 'black', tools: [ { name: "Regression Trend" } ] },
          disabled_features          : disabled_features,
          indicators_file_name       : '/js/pnl.js',
          custom_css_url             : '/css/tradingview-margin-contract.css',
          // enabled_features: [
          //   "dont_show_boolean_study_arguments",
          //   "hide_last_na_study_output",
          // ],
          overrides: {
            "mainSeriesProperties.style"                           : 2,
            "mainSeriesProperties.candleStyle.barColorsOnPrevClose": false,
            "mainSeriesProperties.minTick"                         : this.minTick,
            "paneProperties.topMargin"                             : 15,
            "paneProperties.background"                            : "#ffffff",
            "paneProperties.vertGridProperties.color"              : "#ffffff",
            "paneProperties.horzGridProperties.color"              : "#ffffff",
            "scalesProperties.lineColor"                           : "#ffffff",
            "scalesProperties.showStudyLastValue"                  : true,

            // "paneProperties.legendProperties.showLegend": true,
            // "paneProperties.legendProperties.showStudyArguments": true,
            // "paneProperties.legendProperties.showStudyTitles": false,
            // "paneProperties.legendProperties.showStudyValues": false,
            // "paneProperties.legendProperties.showSeriesTitle": true,
            "paneProperties.legendProperties.showSeriesOHLC": false,

            // "paneProperties.leftAxisProperties.autoScale":false,
            // "paneProperties.leftAxisProperties.autoScaleDisabled":false,
            // "paneProperties.leftAxisProperties.percentage":false,
            // "paneProperties.leftAxisProperties.percentageDisabled":false,
            // "paneProperties.leftAxisProperties.log":false,
            // "paneProperties.leftAxisProperties.logDisabled":false,
            // "paneProperties.leftAxisProperties.alignLabels":false,
            // "scalesProperties.showLeftScale": false,
            // "scalesProperties.showRightScale": false,
            // "scalesProperties.backgroundColor": "#ffffff",
            // "scalesProperties.fontSize": 11,
            // "scalesProperties.lineColor": "#555",
            // "scalesProperties.textColor": "#555",
            // "scalesProperties.scaleSeriesOnly": false,
            // "scalesProperties.showSeriesLastValue": false,
            // "scalesProperties.showSeriesPrevCloseValue": false,
            // "scalesProperties.showStudyLastValue": false,
            // "scalesProperties.showStudyPlotLabels": false,
            // "scalesProperties.showSymbolLabels": false,
          },
          // studies_overrides: {
          //   "volume.volume.color.0": "#000000",
          //   "volume.volume.color.1": "#ffffff",
          //   "volume.volume.transparency": 15,
          //   "volume.volume ma.color": "#f74940",
          //   "volume.volume ma.transparency": 0,
          //   "volume.volume ma.linewidth": 1,
          //   "volume.volume ma.plottype": 'line',
          //   "volume.show ma": true,
          // }
        });
        this.tradingViewChart.onChartReady(() => {
          this.isChartReady = true;
          this.setRangeDate(this.startDate, this.endDate);
          this.changeChartStudy();
          this.watchMinTick(this.minTick);
          this.$watch('minTick', this.watchMinTick);
        });
      },
        
      watchMinTick(newMinTick) {
        const overrides = Object.assign({}, this.tradingViewChart.options.overrides);
        overrides['mainSeriesProperties.minTick'] = newMinTick;
        this.tradingViewChart.applyOverrides(overrides);
      },

      getResolution(desc) {
        const a = this.tradingViewChart.chart().resolution();
        // console.log(desc, a)
      },

      setRangeDate(from, to) {
        if(!from && !to) return
        if(this.isChartReady){
          this.tradingViewChart.chart().setVisibleRange({from, to}, null);
        }
      },
      setBarStyle(type) {
        this.tradingViewChart.chart().setChartType(type);
      },
      initData() {
        this.createChart();
      },
      changeChartStudy() {
        if (this.indexStudyId) {
          this.tradingViewChart.chart().removeEntity(this.indexStudyId);
        }
        this.tradingViewChart.chart().createStudy('roe', false, false, [], (entityId) => {
          this.indexStudyId = entityId;
        });
      },
    },
    watch: {
      resolution: function(newResolution) {
        if(newResolution) {
          this.tradingViewChart.chart().setResolution(newResolution, null);
        }
      }, 

      // source: function() {
      //   this.tradingViewChart.onChartReady(function() {
      //     this.tradingViewChart.chart().setSymbol(this.symbol, this.resolution, () => {
      //       console.log('update source', this.symbol);
      //     });
      //   });
      // },

      source: function(newSource) {
        // console.log('update source 1', this.symbol, newSource);
        // if (this.isChartReady) {
        //   this.tradingViewChart.setSymbol(this.symbol, this.resolution, function(){
        //     console.log('update source 2', this.symbol, newSource);
        //     this.changeChartStudy();
        //   });
        // }
        this.createChart();
      },

    },
    mounted() {
      this.initData();
      // Create chart for the first time
      TradingView.onready(function()
      {
        this.createChart();
      }.bind(this));

      window.setInterval(() => {
        this.createEmptyBarIfNeeded();
      }, 15000);

      // Create chart when route change, re-render
      if (typeof this.tradingViewChart != 'undefined'){
        this.createChart();
      }
    }
  }
</script>

<style lang="scss" scoped>
  .trading-view-chart {
    height: 100%;
  }
</style>
