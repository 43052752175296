<template>
    <div class="base_contract clearfix">
      <div class="left_content_contract">
        <nav-slider/>
      </div>
      <div class="right_content_contract">
        <content-wrap/>
      </div>
    </div>
</template>

<script>
  import NavSlider from './NavSlider.vue';
  import ContentWrap from './ContentWrap.vue';
  import { mapGetters, mapActions } from 'vuex'

   export default {
    components: {
      NavSlider,
      ContentWrap,
    },
    computed: {
      // ...mapGetters(['user', 'funding'])
    },

    methods: {
      // ...mapActions(['getUser', 'getInstrument', 'getFunding'])
    },
    mounted() {
      // this.getInstruments().then(() => {
      // });
      // this.getFunding().then(() => {
      //   this.$broadcast('marginFunding');
      // });
    }
  }

</script>


<style lang="scss" scoped>  
  @import '@/assets/sass/variables';
  $widthLeftContracts    : 235px;
  $marginRightContracts  : 0px;
  $minHeightConTracts    : 1200px;

  .base_contract {
    min-width: 1200px;
    display: block;
    width: 100%;
    position: relative;
    background-color: $color-white;
    .left_content_contract {
      width: #{$widthLeftContracts};
      display: block;
      margin-right: #{$marginRightContracts};
      min-height:  #{$minHeightConTracts};
      padding-bottom: 60px;
      height: 100%;
      float: left;
    }
    .right_content_contract {
      min-height: #{$minHeightConTracts};
      width: calc( 100% - #{$widthLeftContracts} - #{$marginRightContracts} );
      float: right;
      background-color: $color-alabaster;
    }
  }
</style>