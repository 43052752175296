<template>
  <div>
    <div class="entry-teams-tab tab_entry" v-if="onTabEntry==='list'">
      <div class="group_search_save clearfix">
        <div class="form_search_contracts">
          <input :placeholder="$t('notice.search')" class="input-search-contracts" type="text" v-model="filterTeam">
          <button @click="actionSearch" class="btn btn-search-contracts">{{ $t('order.order_history.search') }}</button>
          <!--                <button class="btn btn-clear-search-contracts" @click="actionClearSearch">{{ $t('button.clear') }}</button>-->
          <button :disabled="!canEntry" @click="onClickCreateTeam" class="btn btn-create-team">
            <i class="icon icon-plus"></i>
            {{ $t('margin.entry.create_team_button') }}
          </button>
        </div>
      </div>
      <div class="data-table" id="data-table-list">
        <data-table4 :getData="getTeamEntryList" :limit="5" :msgEmptyData="$t('common.datatable.no_data')"
                     class="datatable_contracts"
                     ref="teamListTable">
          <template slot-scope="props">
            <th class="" data-sort-field="id">
              {{ $t('margin.entry.team_id') }}
            </th>
            <th class="sort" data-sort-field="name">
              {{$t('margin.entry.team_name') }}
            </th>
            <th class="sort" data-sort-field="number_entry_count">
              {{$t('margin.entry.team_member_number')}}
            </th>
            <th class="w_20 text-right">
              {{$t('mam.type.join')}}
            </th>
          </template>
          <template slot="body" slot-scope="props">
            <tr>
              <td>{{ props.item.id }}</td>
              <td>
                <div class="item_name_setting font14">
                  <span class="txt_email_setting font14">{{ props.item.name }}</span>
                  <span class="tooltip_name_setting font14">{{ props.item.name }}</span>
                </div>
              </td>
              <td>{{ props.item.number_entry_count }}</td>
              <td class="text-right" style="text-align: right">
                      <span @click="onClickJoinTeam(props.item)" class="icon icon-plus btn-icon-login"
                            style="margin-right: 21px"
                            v-if="canEntry && isIndexOf(props.item.id) && contest.maximum_member > props.item.number_entry_count"/>
              </td>
            </tr>
          </template>
        </data-table4>
      </div>
    </div>
    <modal id="createTeamModal" name="createTeamModal" width="560">
      <template slot="body">
        <h3 class="box-title-modalsub">
          <span>{{ $t('margin.entry.team_battle_create_modal_title') }}</span>
        </h3>
        <div class="modalsub_container clearfix">
          <div class="group_input_join">
            <label>{{ $t('margin.entry.team_name') }}</label>
            <div class="clearfix"></div>
            <div class="gr_input_jm">
              <input :class="{ error: errors.has('teamNameForm') }" :data-vv-as="$t('margin.entry.team_name')"
                     class="input-search-contracts"
                     @input="onChangeTeamNameForm"
                     maxlength="190"
                     name="teamNameForm"
                     type="text"
                     v-model="teamNameForm"
              >
              <div class="invalid-feedback" >{{errorTeamName}}</div>
            </div>
          </div>
        </div>
        <button :disabled="isLoading" @click="onCreateAccount()" class="btn btn_send">{{
          $t('mam.create') }}
        </button>
      </template>
    </modal>

    <modal id="joinTeamModal" name="joinTeamModal" width="560">
      <template slot="body">
        <h3 class="box-title-modalsub">
          <span>{{ $t('margin.entry.join_team_modal_title') }}</span>
        </h3>
        <div class="modalsub_container clearfix">
          <div class="selected-team-information">
            <div class="custom-row">
              <div class="name-column">
                <div class="txt_des_ctj">{{ $t('margin.entry.team_name') | upperFirst }} :</div>
              </div>
              <div class="value-column">
                <div class="value"> {{selectedTeam.name }}</div>
              </div>
            </div>
            <div class="custom-row">
              <div class="name-column">
                <div class="txt_des_ctj">{{ $t('margin.entry.team_id') | upperFirst }} :</div>
              </div>
              <div class="value-column">
                <div class="value"> {{selectedTeam.id }}</div>
              </div>
            </div>
            <div class="custom-row">
              <div class="name-column">
                <div class="txt_des_ctj">{{$t('margin.entry.leader_id')}} :</div>
              </div>
              <div class="value-column">
                <div class="value">{{selectedTeam.entry_leader.id}}</div>
              </div>
            </div>
            <div class="custom-row">
              <div class="name-column">
                <div class="txt_des_ctj">{{$t('margin.entry.team_member_number')}} :</div>
              </div>
              <div class="value-column">
                <div class="value">{{selectedTeam.number_entry_count}}</div>
              </div>
            </div>
          </div>
          <div class="group_input_join">
            <label>{{ $t('mam.note') }}</label>
            <div class="clearfix"></div>
            <div class="gr_input_jm">
              <input maxlength="190" v-model="noteForm"/>
            </div>
          </div>
        </div>
        <button :disabled="isLoading" @click="onRequestToJoin()" class="btn btn_send">{{
          $t('mam.request_to_join') }}
        </button>
      </template>
    </modal>
  </div>
</template>

<script>
  import rf from '@/request/RequestFactory'
  import Modal from '@/components/shared_components/common/Modal.vue';


  export default {
    name: "TeamEntryList",
    components: {
      Modal,
    },
    props: {
      onTabEntry: {
        type: String
      },
      canEntry: {
        type: Boolean,
        defaultValue: false,
      },
      contest: {}
    },
    data: function () {
      return {
        teamNameForm: '',
        filterTeam: '',
        isLoading: false,
        selectedTeam: {entry_leader: {}},
        noteForm: '',
        pendingRequest: [],
        errorTeamName: '',
      };
    },
    methods: {
      getEventHandlers() {
        return {
          MarginEntryMyRequestUpdated : this.onMarginEntryMyRequestUpdated
        };
      },
      onMarginEntryMyRequestUpdated(data) {
        if (data && data["status"] == "reject") {
          if (data["teamID"]) {
            let index = this.pendingRequest.indexOf(data["teamID"]);
            if (index !== -1) this.pendingRequest.splice(index, 1);
          }
        }
        this.$refs.teamListTable.refresh();
      },
      onChangeTeamNameForm(value){
        if(value==''){
          this.errorTeamName = window.i18n.t('margin.entry.team_name_is_required');
        }else{
          this.errorTeamName = '';
        }
      },
      actionSearch() {
        this.$refs.teamListTable.refresh();
      },
      getTeamEntryList(params) {
        const search = {
          ...params,
          searchName: this.filterTeam
        }
        this.getPendingRequest();
        return rf.getRequest("EntryRequest").getTeamEntryList(search);
      },
      onClickCreateTeam() {
        this.teamNameForm = '';
        this.errorTeamName = '';
        window.CommonModal.show("createTeamModal");
        // window.CommonModal.hide("TeamEntry");
      },
      async getPendingRequest() {
        const pendingRequest = await rf.getRequest("EntryRequest").getPendingRequest();
        this.pendingRequest = pendingRequest.data;
      },
      isIndexOf(index) {
        return this.pendingRequest.indexOf(index) === -1
      },
      async onCreateAccount() {
        if (!this.teamNameForm) {
          this.errorTeamName = window.i18n.t('margin.entry.team_name_is_required');
          return false;
        }
        const params = {
          team_name: this.teamNameForm
        };
        try {
          this.isLoading = true;
          const res = await rf.getRequest("EntryRequest").createTeamEntry(params);
          if (res && res.data) {
            this.$emit('reload-entry');
            this.showSuccess(this.$t('entry.msg.create_team.success'));
          }
          window.CommonModal.show("TeamEntry");
          window.CommonModal.hide("createTeamModal");
        } catch (error) {
          if (error && error.response && error.response.data && error.response.data.message) {
            if(error.response.data.message === 'mam_user_cannot_join_exception'){
              this.showError(this.$t('entry.exception.msg.mam_user_join_request'));
            } else if (error.response.data.message === 'team_name_already_exists') {
              this.showError(this.$t('entry.exception.msg.team_name_already_exists'));
            } else if (error.response.data.message === 'user_already_entry') {
              this.showError(this.$t('entry.exception.msg.user_already_entry'));
            } else if (error.response.data.message === 'user_not_enough_margin_balance') {
              this.showError(this.$t('entry.exception.msg.margin_balance_not_enough'));
            }  else {
              this.showError(this.$t('message.response.request_invalid'));
            }
          }
        } finally {
          this.isLoading = false;
        }
      },
      onClickJoinTeam(selectedTeam) {
        this.selectedTeam = selectedTeam;
        this.noteForm = '';
        window.CommonModal.show("joinTeamModal");
        // window.CommonModal.hide("TeamEntry");
      },
      async onRequestToJoin() {

        const params = {
          note: this.noteForm,
          team_id: this.selectedTeam.id,
        };
        try {
          this.isLoading = true;
          const res = await rf.getRequest("EntryRequest").createJoinRequestTeamEntry(params);
          if (res && res.data) {
            this.showSuccess(this.$t('mam.join.success'));
          } else {
            this.showError('Your request is invalid!');
          }
          this.getPendingRequest();
          window.CommonModal.hide("joinTeamModal");
          // window.CommonModal.show("TeamEntry");
        } catch (error) {
          if (error && error.response && error.response.data && error.response.data.message) {
            if (error.response.data.message === 'mam_user_cannot_join_exception') {
              this.showError(this.$t('entry.exception.msg.mam_user_join_request'));
            } else if (error.response.data.message === 'user_already_entry') {
              this.showError(this.$t('entry.exception.msg.user_already_entry'));
            } else if (error.response.data.message === 'user_not_enough_margin_balance') {
              this.showError(this.$t('entry.exception.msg.margin_balance_not_enough'));
            } else if (error.response.data.message === 'user_already_created_request') {
              this.showError(this.$t('entry.exception.msg.user_already_create_join_request'));
            } else if (error.response.data.message === 'team_has_maximum_member') {
              this.showError(this.$t('entry.exception.msg.team_has_maximum_member'));
            }else {
              this.showError(this.$t('message.response.request_invalid'));
            }
          }
        } finally {
          this.isLoading = false;
        }
      },
      showSuccess(message) {
        Message.success(message, {}, {position: 'bottom_left'});
      },
      showError(message) {
        Message.error(message, {}, {position: 'bottom_left'});
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/sass/variables";

  #data-table-list {
    .tableContainer {
      overflow-y: inherit;

      td {
        overflow: initial;
      }
    }
  }

  .item_name_setting {
    display: inline-block;
    float: left;
    position: relative;

    .txt_email_setting {
      display: block;
      max-width: 300px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &:hover {
      .tooltip_name_setting {
        transition: 0.5s;
        display: inline-block;
      }
    }

    .tooltip_name_setting {
      position: absolute;
      top: 0px;
      line-height: 20px;
      padding: 5px 20px;
      left: 100%;
      background-color: $color_white;
      word-break: break-all;
      white-space: pre-wrap;
      width: 300px;
      z-index: 10;
      font-size: $font_root;
      font-weight: 500;
      transition: 0.5s;
      display: none;
      box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);

      &:after {
        right: 100%;
        top: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(136, 183, 213, 0);
        border-right-color: $color_white;
        border-width: 5px;
        margin-top: -5px;
      }
    }
  }
</style>

<style lang="scss">
  @import "@/assets/sass/variables";

  #createTeamModal, #joinTeamModal {

    .selected-team-information {
      margin-bottom: 15px;

      .custom-row {
      }

      .name-column {
        display: inline-block;
        width: 30%;
        vertical-align: top;
      }

      .value-column {
        display: inline-block;
        width: 69%;

        .value {
          font-weight: bold;
          word-break: break-word;
        }
      }
    }

    .box-title-modalsub {
      margin: 5px 0px 0px 0px;
      background-color: $color-white;
      text-align: center;
      overflow: hidden;
      padding-bottom: 20px;
      text-transform: capitalize;

      span {
        display: inline-block;
        padding: 5px 10px;
        position: relative;
        font-size: $font-big-20;
        font-family: $font-family-roboto-medium;
        color: $color-eden;
        line-height: 30px;

        &:before {
          content: "";
          background-color: $color-light-grey;
          display: block;
          width: 60px;
          height: 1px;
          position: absolute;
          top: 50%;
          left: -60px;
        }

        &:after {
          content: "";
          background-color: $color-light-grey;
          display: block;
          width: 60px;
          height: 1px;
          position: absolute;
          top: 50%;
          right: -60px;
        }
      }
    }

    .modalsub_container {
      .txt_des_ctj {
        text-transform: capitalize;
      }

      padding: 25px 10px;
      background-color: $color-alabaster;

      .group_input_join {
        display: block;
        width: 100%;
        margin-bottom: 15px;

        label {
          text-transform: capitalize;
          color: $color-grey-dark;
          font-size: $font-root;
          line-height: 16px;
          font-weight: 500;
          margin-bottom: 8px;
        }

        input {
          width: 100%;
          border-radius: 3px;
          border: solid 1px $color-alto;
          height: 40px;
          line-height: 20px;
          padding: 5px 50px 5px 20px;
          font-size: $font-root;
          color: $color-grey-dark;
          margin-bottom: 5px;

          &:focus {
            border-color: $color-jungle-green;
          }
        }

        textarea {
          width: 100%;
          border-radius: 3px;
          border: solid 1px $color-alto;
          min-height: 60px;
          line-height: 20px;
          padding: 5px 20px;
          font-size: $font-root;
          color: $color-grey-dark;

          &:focus {
            border-color: $color-jungle-green;
          }
        }

        .gr_input_jm {
          position: relative;
          display: block;
          width: 100%;

          .rihg_input_as {
            position: absolute;
            right: 15px;
            line-height: 40px;
            bottom: 0px;
            display: inline-block;
            font-size: $font-root;
            color: $color-grey-dark;
            font-family: $font-family-roboto-medium;
          }
        }
      }
    }

    .btn_send {
      display: block;
      margin: 20px auto 30px auto;
      min-width: 220px;
      height: 50px;
      border-radius: 3px;
      background-color: $color-jungle-green;
      line-height: 20px;
      padding: 15px;
      letter-spacing: 1.1px;
      color: $color-white;
      font-size: $font-root;
      text-transform: uppercase;
      font-family: $font-family-roboto-bold;

      &:hover, &:focus {
        background-color: $color-blue-custom;
        border-color: $color-blue-custom;
      }
    }
  }

</style>