<template>
  <div>
    <modal :name="modalName" width="560">
      <template slot="body">
        <h3 class="box-title-modalsub">
          <span>{{ $t('mam.create_master_account') }}</span>
        </h3>
        <div class="modalsub_container clearfix">
          <form class="form_join_modal" v-on:submit.prevent>
            <div class="group_input_join">
              <label>{{ $t('mam.initial_amount_btc') }}</label>
              <div class="clearfix"></div>
              <div class="gr_input_jm">
                <currency-input :precision="8" 
                                :class="{'error': errors.has('amount')}"
                                :maxLengthCus='15'
                                v-model="amount" 
                                @focus="resetError"/>
                <span class="rihg_input_as"></span>
              </div>
              <div class="clearfix"></div>
              <div class="clearfix" v-if="errors.has('amount')"
                   :class="{ 'msg-error' : errors.first('amount') }">{{ errors.first('amount') }}
              </div>
            </div>

            <div class="group_input_join">
              <label>{{ $t('mam.performance_fee_percent') }}</label>
              <div class="clearfix"></div>
              <div class="gr_input_jm">
                <currency-input :precision="2" 
                                :maxLengthCus='16'
                                :class="{'error': errors.has('fee')}"
                                v-model="performanceFee"
                                @focus="resetError"/>
                <span class="rihg_input_as"></span>
              </div>
              <div class="clearfix"></div>
              <div class="clearfix" v-if="errors.has('fee')"
                   :class="{ 'msg-error' : errors.first('fee') }">{{ errors.first('fee') }}
              </div>
            </div>
            <div class="clearfix"></div>
            <div class="group_note clearfix">
              <div class="title_note">{{ $t('Note') }}</div>
              <div class="clearfix"></div>
              <div class="content_note clearfix">
                <div>{{ $t('mam.initial_amount_note_1') }}</div>
                <div>{{ $t('mam.initial_amount_note_2') }}</div>
                <div>{{ $t('mam.initial_amount_note_3') }}</div>
              </div>
            </div>
            <button class="btn btn_send" :disabled="!!callingApi" @click="confirmCreateMasterAccount()">{{
              $t('mam.create') }}
            </button>
          </form>
        </div>
      </template>
    </modal>
  </div>
</template>
<script>
  import Modal from './Modal';
  import rf from '@/request/RequestFactory'
  import RemoveErrorsMixin from '@/common/RemoveErrorsMixin';
  import SelectBox from './SelectBox';
  import BigNumber from "bignumber.js";

  export default {
    components: {
      Modal,
      SelectBox,
    },
    mixins: [RemoveErrorsMixin],
    data() {
      return {
        modalName: 'CreateMasterAccount',
        amount: 0,
        performanceFee: 0,
        callingApi: false,
      }
    },
    computed: {},
    methods: {
      closeModal() {
        this.$emit(`${this.modalName}:CLOSE`);
        window.CommonModal.hide('CreateMasterAccount');
      },
      resetData() {
        this.amount = '';
        this.performanceFee = '';
      },
      resetError() {
        this.errors.clear();
      },
      validate() {
        this.errors.clear();
        if (!this.amount) {
          this.errors.add('amount', window.i18n.t('common.error.required', { field: this.$t('mam.initial_amount')}));
        }
        if (!this.performanceFee) {
          this.errors.add('fee', window.i18n.t('common.error.required', { field: this.$t('mam.performance_fee').toLowerCase() }));
        }
        if (new BigNumber(`${this.amount || 0}`).lt(0.5)) {
          this.errors.add('amount', window.i18n.t('mam.create_account.initial_amount.error_min_value', { min_amount: 0.5 }));
        }
        if (new BigNumber(`${this.performanceFee || 0}`).comparedTo(0) === 0) {
          this.errors.add('fee', window.i18n.t('mam.create_account.performance_fee.error_min_value', { min_amount: 0 }));
        }
        if (this.performanceFee >= 100) {
          this.errors.add('fee', window.i18n.t('mam.change.performance_fee_error_max_value', { MAX_VALUE: '100' }));
        }

        if (this.errors.count() > 0) {
          return false;
        }

        return true;
      },
      confirmCreateMasterAccount() {
        this.onCreateMasterAccount();
        // window.CommonModal.hide('CreateMasterAccount');
        // window.ConfirmationModal.show({
        //   type: 'primary',
        //   title: '',
        //   content: window.i18n.t('mam.confirm.create_account'),
        //   btnCancelLabel: window.i18n.t('common.action.no'),
        //   btnConfirmLabel: window.i18n.t('common.action.yes'),
        //   onConfirm: () => {
        //     this.onCreateMasterAccount();
        //   },
        //   onCancel: () => {}
        // });
      },
      onCreateMasterAccount() {
        if (!this.validate()) return;

        this.callingApi = true;
        const params = {
          amount: this.amount,
          performance_rate: this.performanceFee,
        };
        rf.getRequest("MamRequest").createMasterAccount(params)
          .then(res => {
            this.callingApi = false;
            if (res && res.data == 'Balance is not enough!') {
              this.showError(res.data);
            } else if (res && res.success) {
              this.showSuccess(this.$t('mam.msg.create_account.success'));
              this.resetData();
              this.closeModal();
            }
          })
          .catch(error => {
            this.callingApi = false;
            if (error && error.response && error.response.data && error.response.data.message) {
              this.showError(error.response.data.message);
            }
            this.convertRemoteErrors(error);
          })
      },

      showSuccess(message) {
        Message.success(message, {}, {position: 'bottom_left'});
      },
      showError(message) {
        Message.error(message, {}, {position: 'bottom_left'});
      },
    },
    mounted() {

    },
  }
</script>
<style lang="scss" scoped>
  @import "@/assets/sass/variables.scss";

  .group_note {
    border-radius: 1px;
    padding: 15px 20px;
    font-size: 13px;
    line-height: 20px;
    background-color: $color-gallery;
    color: $color-grey-dusty;

    .title_note {
      margin-bottom: 7px;
      line-height: 16px;
      font-weight: 500;
      font-family: $font-family-roboto-medium;
      font-size: $font-root;
      color: $color-grey-dark;
    }
  }
  .error {
    border: solid 1px $color-red-main;
  }
  .formInput {
    .checkbox_address {
      line-height: 23px;
      font-size: $font-small;
      font-weight: 400;
      color: $color-grey-dusty;
      margin-top: 12px;
      input{
        display: none;
      }
      span{
        content: "";
        display: inline-block;
        border: 1px solid $color-grey;
        width: 14px;
        height: 14px;
        top: 2px;
        left: 0px;
        border-radius: 0px;
        margin-right: 8px;
        position: relative;
        background-color: transparent;
      }
      input:checked+span:after {
        opacity: .9;
        content: "";
        position: absolute;
        width: 13px;
        height: 7px;
        background: transparent;
        top: 0px;
        left: 0;
        border: 3px solid $color-jungle-green;
        border-top: none;
        border-right: none;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
      }
    }
  }
  .msg-error {
      color: $color-red-warning;
      font-size: $font-small;
      line-height: 20px;
  }

  .box-title-modalsub {
    margin: 5px 0px 0px 0px;
    background-color: $color-white;
    text-align: center;
    overflow: hidden;
    padding-bottom: 20px;

    span {
      display: inline-block;
      padding: 5px 10px;
      position: relative;
      font-size: $font-big-20;
      font-family: $font-family-roboto-medium;
      color: $color-eden;
      line-height: 30px;

      &:before {
        content: "";
        background-color: $color-light-grey;
        display: block;
        width: 60px;
        height: 1px;
        position: absolute;
        top: 50%;
        left: -60px;
      }

      &:after {
        content: "";
        background-color: $color-light-grey;
        display: block;
        width: 60px;
        height: 1px;
        position: absolute;
        top: 50%;
        right: -60px;
      }
    }
  }

  .group_find_join_modal {
    margin: 0px auto 30px auto;
    width: 320px;
    max-width: 94%;
    overflow: hidden;

    .txt_find {
      display: inline-block;
      float: left;
      line-height: 40px;
      width: 24px;
      font-size: $font-root;
      color: $color-grey-dark;
    }

    .btn-find {
      display: block;
      float: right;
      width: 80px;
      height: 40px;
      color: $color-white;
      margin-left: 10px;
      font-size: 12px;
      letter-spacing: 0.9px;
      border-radius: 3px;
      font-family: $font-family-roboto-bold;
      text-transform: uppercase;
      background-color: $color-jungle-green;
      border: 1px solid $color-jungle-green;

      &:hover, &:focus {
        background-color: $color-blue-custom;
        border-color: $color-blue-custom;
      }
    }

    .input_find {
      display: inline-block;
      float: left;
      width: calc(100% - 24px - 90px);
      height: 40px;
      border-radius: 3px;
      border: solid 1px $color-alto;
      padding: 10px;
      line-height: 20px;
      font-size: $font-root;
      color: $color-grey-dark;

      &:focus {
        border-color: $color-jungle-green;
      }
    }
  }

  .modalsub_container {
    padding: 25px 0px;
    background-color: $color-alabaster;

    .left_des_ctj {
      padding-left: 60px;
      padding-right: 5px;

      .group_des_ctj {
        font-size: $font-root;
        color: $color-grey-dark;
        line-height: 20px;
        margin-bottom: 8px;
        font-family: $font-family-roboto-bold;

        .txt_des_ctj {
          font-family: $font-family-roboto;
        }
      }
    }
  }

  .form_join_modal {
    padding: 5px 60px;

    .group_input_join {
      display: block;
      width: 100%;
      margin-bottom: 15px;

      label {
        color: $color-grey-dark;
        font-size: $font-root;
        line-height: 16px;
        font-weight: 500;
        margin-bottom: 8px;
      }

      input {
        width: 100%;
        border-radius: 3px;
        border: solid 1px $color-alto;
        height: 40px;
        line-height: 20px;
        padding: 5px 50px 5px 20px;
        font-size: $font-root;
        color: $color-grey-dark;

        &:focus {
          border-color: $color-jungle-green;
        }
      }

      textarea {
        width: 100%;
        border-radius: 3px;
        border: solid 1px $color-alto;
        min-height: 60px;
        line-height: 20px;
        padding: 5px 20px;
        font-size: $font-root;
        color: $color-grey-dark;

        &:focus {
          border-color: $color-jungle-green;
        }
      }

      .gr_input_jm {
        position: relative;
        display: block;
        width: 100%;

        .rihg_input_as {
          position: absolute;
          right: 15px;
          line-height: 40px;
          bottom: 0px;
          display: inline-block;
          font-size: $font-root;
          color: $color-grey-dark;
          font-family: $font-family-roboto-medium;
        }
      }
    }
  }

  .btn_send {
    display: block;
    margin: 20px auto 30px auto;
    width: 220px;
    height: 50px;
    border-radius: 3px;
    background-color: $color-jungle-green;
    line-height: 20px;
    padding: 15px;
    letter-spacing: 1.1px;
    color: $color-white;
    font-size: $font-root;
    text-transform: uppercase;
    font-family: $font-family-roboto-bold;

    &:hover, &:focus {
      background-color: $color-blue-custom;
      border-color: $color-blue-custom;
    }
  }

  .col-6 {
    width: 50%;
    float: left;
  }

</style>

<style lang="scss">
  @import "@/assets/sass/variables.scss";

  #CreateMasterAccount {
    .modal-footer {
      display: none;
    }

    .modal-body {
      padding: 0px;
    }

    table {
      tbody {
        tr.empty-data:hover {
            background-color: transparent !important;
        }
      }
    }
  }
</style>