<template>
  <div id="trade-history-screen" class="page_container container_history">
    <div class="box-title title-medium-small">{{ $t('menu.trade_history') }}</div>
    <div class="trade-history-content">
      <trade-history class="order-group-screen" :options="{isNoPadding: true}"/>
    </div>
  </div>
</template>

<script>
  import COMMON_CONST from '@/common/Const';
  import TradeHistory from './TradeHistory.vue';

  export default {
    components: {
      TradeHistory,
    },
    data() {
      return {
        dateList: COMMON_CONST.OPEN_ORDER_DATE_TAB
      }
    },
    methods: {
      selectDate (item) {

      },
    },
    mounted() {
    }
  }
</script>
<style lang="scss" scoped>
  @import "@/assets/sass/variables";
  .container_history{
    width: 100%;
    margin: 0px auto;
  }
  #trade-history-screen {
    margin-bottom: 50px;
    .box-title{
      margin-bottom: $title-bottom-margin;
    }
    .trade-history-content{
      background-color: $color_white
    }
  }

  </style>
