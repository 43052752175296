<template>
  <div class="input-group">
    <input-text-and-number :class="{'error': errors.has('blockchain_sub_address')}" type="text" class="form-input" v-model="tag" :disabled='disabled'/>
    <span v-show="errors.has('blockchain_sub_address')" class="f-left invalid-feedback">
      {{ errors.first('blockchain_sub_address') }}
    </span>
  </div>
</template>

<script>
  import InputTextAndNumber from '../../common/InputTextAndNumber';
  export default {
    inject: ['$validator'],
    components: {
      InputTextAndNumber,
    },
    data() {
      return {
        tag: ''
      }
    },
    props: {
      value: {required: true},
      coin: {type: String, required: true},
      disabled: {type: Boolean, default: false}
    },
    watch: {
      coin: function() {
        this.tag = '';
      },
      value: function(val) {
        this.tag = val || '';
      },
      tag: function(val) {
        if(val !== this.value) {
          this.$emit('input', val);
        }
      }
    },
    methods: {
      validate() {
        if (_.isEmpty(this.tag)) {
          this.errors.add('blockchain_sub_address', window.i18n.t('address.errors.tag_required'));
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/sass/variables";
  .form-input {
    background-color: transparent;
    background-color: var(--bg-input);
    border-radius: 8px;
    color: var(--text-primary-color);
    border: none;
    &:focus{
      border-color:$color-jungle-green !important;
    }
  }
  .invalid-feedback {
    font-size: $font-root;
  }
</style>