 import rf from '@/request/RequestFactory';

const state = {
  user: {}
}

const actions = {
  getUser({ commit, dispatch, state }, params) {
    return new Promise((resolve, reject) => {
      rf.getRequest('UserRequest').getCurrentUser()
        .then(res => {
          commit('updateUser', res.data);
          dispatch('getPosition', {userId: state.user.id}, {root: true});
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        })
    });
  },
  getStatusAnimtion({commit, dispatch, state}, params) {
    if (window.isAuthenticated) {
      return rf.getRequest('UserRequest').getAnimationStatus()
      .then(res=> {
        commit('setAnimation', res.data == 1);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      })
    }

  },
  updateAnimation: ({commit, dispatch, state}, params) => {
    commit('setAnimation', params.status == 1);
    if (window.isAuthenticated) {
      return rf.getRequest('UserRequest').changeAnimationStatus(params)
        .then(res=> {
          return res.data;
        })
        .catch((err) => {
          console.log(err);
        })
    }
    
  }
}

const getters = {
  user: state => {
    return state.user
  },
  animation: state => {
    return state.animation
  }
}

const mutations = {
  updateUser: (state, payload) => {
    state.user = {...payload}
  },
  setAnimation: (state, status) => {
    state.animation = status;
  }
}

export default {
  state : {
    animation : false,
  },
  actions,
  mutations,
  getters,
}