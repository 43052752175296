<template>
  <div class="OtpModal">
    <modal :name="name" :width="sizeScreen">
      <div slot="body">
        <div class="content_login_form_container">
          <div class="content_login_form">
            <img src="/images/icon/icon-logo-google.svg" alt="icon" class="icon">
            <div class="box-title">{{ $t('account.google_auth') }}</div>
            <div class="input-group authenticator">
              <currency-input-otp :placeholder="$t('common.placeholders.authen_code')" type="text" maxlength="6" id="otp-field"
                                 class="input" :class="{'error' : errors.has('otp')}"
                                 name="otp" v-model="otp" data-vv-as="authentication code"></currency-input-otp>
              <div class="invalid-feedback" v-show="errors.has('otp')">{{ errors.first('otp') }}</div>
            </div>
            <div class="lost_verify_code">
              <div class="lost_verify_click" @click="showModalLostOtp()">
                {{ $t('shared_components.google_authentication_modal.lost_code') }}
              </div>
            </div>
            <div>
              <button :disabled="isDisableButton" class="btn-submit" @click="submitOtpForm">
                {{ getSubmitName($t('anti.btn_submit')) }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <lost-otp-modal :name="`${this.name}_lostOtpForm`" :contact_email="contact_email"/>
  </div>
</template>

<script>
  import Modal from '@/components/shared_components/common/Modal';
  import LostOtpModal from '@/components/shared_components/otp/LostOtpModal';
  import CurrencyInputOtp from '../../common/CurrencyInputOtp.vue';

  export default {
    inject: ['$validator'],
    components: {
      Modal,
      LostOtpModal,
      CurrencyInputOtp
    },
    data() {
      return {
        otp: '',
        // isDisableLoginOtp: false,
        otpRequest: '',
        sizeScreen: "610"
      }
    },
    props: {
      name: {type: String, default: 'OtpModal'},
      submit: {type: Function},
      params: {type: Object, default: () => {return {}}},
      errorMessenger: {type: String},
      isDisableButton: {type: Boolean, default: false},
      contact_email: {type: String, default: ''},
    },
    watch: {
      otp: function(newVal) {
        if (window._.size(newVal) === 6) {
          this.submitOtpForm();
        }
      },
      isDisableButton: function(newVal) {
        if(newVal === true){
          this.startSubmit();
        }else{
          this.endSubmit();
        }
      }
    },
    methods: {
      validate() {
        if (window._.isEmpty(this.otp)) {
          this.$validator.errors.add({ field: 'otp', msg: window.i18n.t('funds.withdrawals.errors.otp_required') });
        } else if (this.otp.length !== 6) {
          this.$validator.errors.add({ field: 'otp', msg: window.i18n.t('funds.withdrawals.errors.otp_length_digits') });
        } else if (this.errorMessenger) {
          this.$validator.errors.add({ field: 'otp', msg: this.errorMessenger });
        }
        return !this.$validator.errors.has('otp');
      },

      submitOtpForm() {
        this.$validator.errors.remove('otp');
        if (!this.validate()) {
          return;
        }
        this.otpRequest = this.otp;
        // this.otp = '';
        const params = Object.assign({}, this.params, {otp: this.otpRequest});
        this.startSubmit();
        this.submit(params)
        //   .then(isErrorOtp => {
        //   if(isErrorOtp) {
        //     this.errors.add({
        //       field: 'otp',
        //       msg: this.$t('validation.otp_incorrect')
        //     });
        //   }
        // });
      },
      showModalLostOtp() {
        CommonModal.hide(this.name);
        CommonModal.show(`${this.name}_lostOtpForm`);
      },
      handleWindowResize() {
        if(window.innerWidth > 800) {
          this.sizeScreen = "610";
        } else {
          this.sizeScreen = "550";
        }
      },
    },
    mounted() {
      window.addEventListener('resize', this.handleWindowResize)
      this.$on('hideCommonModal', (name) => {
        if (name === this.name) {
          this.otp = '';
          this.errors.remove('otp');
        }
      })
    }
  }
</script>
<style lang="scss">
  @import "@/assets/sass/variables";
  .OtpModal {
    .modal-footer {
      padding-top: 0px !important;
      padding-bottom: 12px !important;
    }

  }
  @media screen and (max-width: 992px) {
    .OtpModal {
      .modal-body {
        padding: 0 15px 30px !important;
      }
    }
  }
</style>
<style lang="scss" scoped>
  @import "@/assets/sass/variables";
  .content_login_form {
    width: 100%;
    .icon {
      margin: 0 auto 20px;
      display: block;
    }
    .box-title {
      margin-bottom: 40px;
      font-family: $font-inter-bold;
      color: var(--dark-1);
      font-size: 34px;
      line-height: 40px;
      text-align: center;
    }
    .input {
      background: var(--background-color-second);
      border-radius: 8px;
      padding: 14px 15px;
      border: none;
      width: 100%;
      
      font-size: 18px;
      line-height: 21px;
      font-family: $font-inter-regular;
      color: var(--text-primary-color);
      &.error {
        border: 1px solid $text-color-red;
      }
    }
    .invalid-feedback {
      color: $text-color-red;
      font-family: $font-inter-regular;
      font-size: 14px;
      line-height: 17px;
      text-align: left;
      margin-top: 8px;
    }
    .submit {
      width: 100%;
      height: 45px;
      transition: all ease-out 0.2s;
      color: $color-white;
    }
    .btn-submit {
      border-radius: 8px;
      padding: 15.5px 10px;
      background: $color-aqua-green;
      font-family: $font-inter-regular;
      color: $dark-1;
      font-size: 18px;
      line-height: 21px;
      width: 100%;
      margin-top: 30px;
    }
  }
  .lost_verify_code {
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
    .lost_verify_click {
      display: inline-block;
      font-family: $font-inter-regular;
      font-size: 16px;
      line-height: 19px;
      color: $color-aqua-green;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  @media screen and (max-width: 992px) {
    .content_login_form {
      .box-title {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 20px;
      }

      .icon {
        width: 104px;
        height: 80px;
      }
      .input {
        &::placeholder {
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
    .lost_verify_code {
      .lost_verify_click {
        font-size: 14px;
        line-height: 16px;
      }
    }

  }
  @media screen and (max-width: 992px) {
    .OtpModal {
      :deep(.modal-dialog) {
        max-width: 60%;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .OtpModal {
      :deep(.modal-dialog) {
        max-width: 60%;
      }
    }
  }
  @media screen and (max-width: 676px) {
    .OtpModal {
      :deep(.modal-dialog) {
        max-width: 70%;
      }
    }
  }
  @media screen and (max-width: 576px) {
    .OtpModal {
      :deep(.modal-dialog) {
        max-width: 80%;
      }
    }
  }
  @media screen and (max-width: 476px) {
    .OtpModal {
      :deep(.modal-dialog) {
        max-width: 90%;
      }
    }
  }
  @media screen and (max-width: 400px) {
    .OtpModal {
      :deep(.modal-dialog) {
        max-width: 94%;
      }
    }
  }
</style>
