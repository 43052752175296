<template>
  <div id="open-order-screen" class="page_container container_history">
    <div class="box-title title-medium-small">
      <span class="txt">{{ $t('menu.open_orders') }}</span>
      <div class="export-file">
        <a class="excel_export f-fr mart3" :title="$t('order.open_order.export_complete_open_order')" @mouseover="mouseOver" @mouseout="mouseOver">          
          <span class="icon icon-export"/>&nbsp;
          {{ $t('order.open_order.export_complete_open_order') }}
        </a>
        <div class="export-file-tooltip">{{ $t('order.order_history.export_complete_topen_order_tooltip') }}</div>
      </div>
    </div>
    <div class="open-order-content">
      <open-order class="order-group-screen" :options="{isNoPadding: true}"/>
    </div>
  </div>
</template>

<script>
  import COMMON_CONST from '@/common/Const';
  import OpenOrder from './OpenOrder.vue';

  export default {
    components: {
      OpenOrder,
    },
    data() {
      return {
        dateList: COMMON_CONST.OPEN_ORDER_DATE_TAB
      }
    },
    methods: {
      selectDate (item) {

      },
    },
    mounted() {
    }
  }
</script>
<style lang="scss" scoped>
  @import "@/assets/sass/variables";
  .container_history{
    width: 100%;
    margin: 0px auto;
    &after{
      display: block;
      clear: both;
      content: "";
    }
  }
  #open-order-screen {
    margin-bottom: 50px;
    .box-title{
      margin-bottom: $title-bottom-margin;
    }
    .open-order-content{
      background-color: $color_white;
      padding-bottom: 22px;
    }
    .export-file{
      display: inline-block;
      float: right;
      margin-top: 10px;
      line-height: 20px;
      font-size: $font-small;
      font-weight: 500;
      position: relative;
      font-family: $font-family-roboto;
      a {
        color: $color-blue-custom;
        font-size: $font-small;
        .icon-export{
          line-height: 20px;
          font-size: $font-root;
          float: right;
          margin-left: 5px;
        }
      }
      .export-file-tooltip {
        display: none;
        position: absolute;
        bottom: 100%;
        margin-bottom: 5px;
        right: 0;
        width: 220px;
        line-height: 20px;
        background-color: $color-white;
        padding: 5px 10px;
        font-size: $font-smaller;
        font-weight: normal;
      }
      &:hover {
        .export-file-tooltip {
          display: block;
        }
      }
    }
  }

  </style>
