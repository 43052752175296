import zlib from 'browserify-zlib';
import buffer from 'buffer';
import rf from '@/request/RequestFactory';
import VueSession from 'vue-session';
import BigNumber from "bignumber.js";
const masterdataVersionKey = 'masterdata_version';
const masterdataKey = 'masterdata';

export default class FavoritesManager {

  static addFavorite(pair) {
    if (window.isAuthenticated) {
      return rf.getRequest('FavoriteRequest').createANewOne(pair)
        .then(res => res.data);
    } else {
      let favoritePairs = FavoritesManager.get('favorite_pairs') || [];
      favoritePairs.push(pair);
      FavoritesManager.set('favorite_pairs', favoritePairs);
      return Promise.resolve(pair);
    }
  }

  static addFavoriteFuture(pair) {
    if (window.isAuthenticated) {
      return rf.getRequest('FavoriteRequest').addFavorite(pair)
          .then(res => res.data);
    } else {
      let favoritePairs = FavoritesManager.get('favorite_pairs_futures') || [];
      favoritePairs.push(pair);
      FavoritesManager.set('favorite_pairs_futures', favoritePairs);
      return Promise.resolve(pair);
    }
  }

  static removeFavorite(pair) {
    if (window.isAuthenticated) {
      return rf.getRequest('FavoriteRequest').deleteFavorite(pair)
        .then(res => pair);
    } else {
      let favoritePairs = FavoritesManager.get('favorite_pairs') || [];
      window._.remove(favoritePairs, item => item.coin_pair === pair.coin_pair);
      FavoritesManager.set('favorite_pairs', favoritePairs);
      return Promise.resolve();
    }
  }
  static removeFavoriteFuture(pair) {
    if (window.isAuthenticated) {
      return rf.getRequest('FavoriteRequest').addFavorite(pair)
          .then(res => res.data);
    } else {
      let favoritePairs = FavoritesManager.get('favorite_pairs_futures') || [];
      window._.remove(favoritePairs, item => item.symbol === pair.symbol);
      FavoritesManager.set('favorite_pairs_futures', favoritePairs);
      return Promise.resolve();
    }
  }

  static async getFavorites() {
    let favoritePairs = FavoritesManager.get('favorite_pairs') || [];
    if (window.isAuthenticated) {
      // if (favoritePairs.length > 0) {
      //   await rf.getRequest('FavoriteRequest').createList(favoritePairs);
      //   FavoritesManager.set('favorite_pairs', []);
      // }
      return rf.getRequest('FavoriteRequest').getList()
        .then(res => res.data);
    } else {
      return Promise.resolve(favoritePairs);
    }
  }

  static async getFutureFavorites() {
    let favoritePairs = FavoritesManager.get('favorite_pairs_futures') || [];
    if (window.isAuthenticated) {
      return rf.getRequest('FavoriteRequest').getFuturesFavorite()
        .then(res => res.data);
    } else {
      return Promise.resolve(favoritePairs);
    }
  }
  static reformatDataFavorites(oldData, params, allPriceCoinsData) {
    const total = oldData.length;
    const last_page = Math.ceil(total/params.limit);
    let from = params.page;
    if(params.page) {
      from = (params.page - 1) * params.limit + 1;
    }
    let to = params.page;
    if(params.page) {
      to = (params.page - 1) * params.limit + params.limit;
    }
    if (to > total) {
      to = total;
    }
    let data_pairs = oldData.map(f =>
      window._.assign(f,
        allPriceCoinsData[`${f.coin_pair.split('/')[1]}_${f.coin_pair.split('/')[0]}`],
        allPriceCoinsData[`${f.coin_pair.split('_')[1]}_${f.coin_pair.split('_')[0]}`])
    );
    if(params.sort) {
      data_pairs = window._.orderBy(
        data_pairs,
        (item) => {
          if(!item.pair) {
            item.pair = item?.coin_pair;
          }
          if (isNaN(item[params.sort])) {
            if (params.sort !== "full_name") {
              return item[params.sort];
            } else {
              return window.i18n.t("currency." + item.coin + ".fullname");
            }
          }
          return new BigNumber(`${item[params.sort] || 0}`).toNumber();
        },
        [params.sort_type]
      );
    }
    if (params.page) {
      const start = (params.page - 1) * params.limit;
      const end = start + params.limit;
      data_pairs = data_pairs.slice(start, end);
    }
    const data = {
      current_page: params.page ? params.page : 1,
      data: data_pairs,
      first_page_url: "/?page=1",
      from: from,
      last_page: last_page,
      last_page_url: `/?page=${last_page}`,
      path: "/",
      per_page: params.limit,
      to: to,
      total: total
    };

    return data;
  }

  static async getFavoritesPagination(params, allPriceCoinsData) {
    let favoritePairs = FavoritesManager.get('favorite_pairs') || [];
    if (window.isAuthenticated) {
      let favoritePairsAuthenticated = JSON.parse(window.localStorage.getItem('favorite_pairs_authenticated'));
      if (favoritePairsAuthenticated && favoritePairsAuthenticated.length > 0) {
        if(params.socket) {
          let data = FavoritesManager.reformatDataFavorites(favoritePairsAuthenticated || [], params, allPriceCoinsData)
          return Promise.resolve({data});
        } else  {
          return Promise.resolve({data: favoritePairsAuthenticated});
        }

      }
      return favoritePairsAuthenticated && favoritePairsAuthenticated.length ==0 ? Promise.resolve({data: []}) :rf.getRequest('FavoriteRequest').getListPagination({limit: params.limit, page: params.page})
    } else {
      let data = FavoritesManager.reformatDataFavorites(favoritePairs, params, allPriceCoinsData)
      return Promise.resolve({data});
    }
  }

  static get(key) {
    const string = window.localStorage.getItem('favorites_' + key);
    if (string) {
      return JSON.parse(string);
    }
  }

  static set(key, value) {
    window.localStorage.setItem('favorites_' + key, JSON.stringify(value));
  }
};
