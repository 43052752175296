<template>
  <!-- <span :class="classes" v-if="usdCurrency" :title="usdAmount | formatCurrencyAmount(usdAmount, '0')">${{ usdAmount | formatCurrencyAmount(usdAmount, '0') }}</span>
  <span :class="classes" v-else-if="usdCurrency" :title="value | formatCurrencyAmount('usd', '0')">
    {{ value | formatCurrencyAmount('usd', '0') }} 
    <span class="lastPriceUnit" :class="subClasses">{{ unit }}</span>
  </span>
  <span :class="classes" v-else-if="isLocaleVi()" :title="value | formatCurrency">
    {{ value | formatCurrency }}
    <span class="lastPriceUnit" :class="subClasses">{{ unit }}</span>
  </span>
  <span :class="classes" v-else :title="usdAmount | formatCurrency">${{ usdAmount | formatCurrency }}</span> -->
  <span :class="classes" >{{ value | formatNumberAndDecimal }} USDT</span>
</template>

<script>
  // import Utils from '@/common/Utils'
  // import BigNumber from 'bignumber.js';
  // import rf from '@/request/RequestFactory'

  // const LOCALE_VI = 'vi';

  // const USD_KEY = 'usd_price';

  export default {
    props: {
      classes               : {type: String},
      subClasses            : {type: String},
      value                 : {type: [String, Number], required: true},
      unit                  : {type: String, default: 'USD'},
      usdCurrency           : {type: Boolean, default: false},
      usdCurrency           : {type: Boolean, default: false},
    },

    // data() {
    //   return {
    //     usdPrice: 1
    //   }
    // },

    // computed: {
    //   usdAmount() {
    //     return new BigNumber(`${this.value || 0}`).div(`${this.usdPrice}`).toString();
    //   }
    // },

    // filters: {
    //   formatCurrency(value) {
    //     if (window.i18n.locale === LOCALE_VI) {
    //       return Utils.formatCurrencyAmount(value, 'usd', '0');
    //     }
    //     return Utils.formatCurrencyAmount(value, 'usd', '0');
    //   }
    // },

    // methods: {
    //   isLocaleVi () {
    //     return window.i18n.locale === LOCALE_VI;
    //   }
    // },
  }
</script>

<style lang="scss" scoped>
</style>
