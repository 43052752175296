<template>
    <!-- 招聘 -->
    <div class="joinUs">
        <div class="join_tit">
            <p>{{$t('joinUs_tit')}}</p>
            <p>{{$t('joinUs_subtit')}}</p>
        </div>
        <div class="join_info">
            <div v-for="(item, index) in $t('recruit')" :key="index">
                <p class="tit">{{ item.tit }}</p>
                <p class="sub">{{$t('joinUs_duty')}}：</p>
                <ol>
                    <li v-for="(duty_item, duty_index) in item.duty" :key="duty_index">{{duty_item}}</li>
                </ol>
                <p class="sub">{{$t('joinUs_sen')}}：</p>
                <ol>
                    <li v-for="(need_item, need_index) in item.need" :key="need_index">{{ need_item }}</li>
                </ol>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                recruit: [{
                    tit: '海外品牌总监',
                    duty: ["负责公司海外市场战略企划的实施，制定公司的整体市场工作计划，并就市场推广、品牌行销、公共关系、活 动等提出具体的方向和实行计划；", "建立促销机制，开发市场资源，负责开发和维护客户，媒体和营销渠道；", "制定公司的全球公关战略和公共关系应对；", "负责业务推广和营销活动的想发；", "追踪市场动态和竞争产品，总结和分析市场扩展效果，提供给公司的高级管理层作为决策依据。"],
                    need: ["在互联网或国际金融产业具有3-5年以上品牌推广相关的工作经验。具有日本、韩国、欧美等区块链产业和加密货币经验者优先;", "学士或以上学历，英语6级以上，有海外学习或工作经验者优先。能用英语与人流利地沟通。"]
                }, {
                    tit: '风险和内部管理经理',
                    duty: ["带领全体企业业务，负责排定与支援公司内部管理。负责运营过程中的风险管理；", "分析用户的交易行为、账户状态、识别风险并设计有效的风险管理措施；", "追踪、监控、管理关键数据指标，分析和优化现有可解决方案，增强用户体验；", "评估新项目、新用户、用户交易和转帐，分析和确认潜在风险以及设计和执行风险管理；", "为每个部门撰写及传达指导和训练资料，并定期报告风险讯息；", "进一步发展企业风险管理流程。完成其他相关工作。"],
                    need: ["本科系以上学历毕业，金融、经济学、法律、计算机等专业；", "1-3年工作经验，有金融机构风险管理、区块链产业、数字加密货币领域经验优先；", "熟悉相关法律，法规和管制政策，熟悉数字加密货币特色、操作方法和风险管理工具；", "具有较强的逻辑思维能力和分析能力，善于独立和缜密的思考；", "负责任，善于沟通和团队合作，充满工作热情，有较强的项目推广能力。"],
                }, {
                    tit: '运营专员（英语，韩语，日语，俄语，越南语）',
                    duty: ["制定社区级别目标和计划，并负责建立和管理用户的海外社区（包括但不限于Facebook，BitcoinTalk，Twitter，Telegram，Medium等），以达到并达到用户管理和运营目的；", "收集用户需求并推广产品，帮助技术上和运营面改善；", "为用户解决问题。"],
                    need: ["学士或以上学历；有海外学习或工作经验者优先;", "1-3年的互联网社群营运经验；具有两年以上主流金融媒体或区块炼亦或数字资产垂直媒体工作经验者优先;", "熟悉海外社交媒体平台，如Facebook、Twitter、telegram、medium；", "对区块链产业有一定的了解；", "需要中英文专业知识。在各领域的营运专员要求具备可相对应的韩语，日语或俄语的专业知识。"],
                }, {
                    tit: '平台运营专员',
                    duty: ['负责平台日常数据的分析；', "负责代币/代币的挂牌工作，以及项目持有人和业务部门的对接；", "协助完成与各个不同部门（例如：客户服务、市场营销部门）相关的活动，以支援部门内的目标；", "提出、建议和执行流程改进，以提高效率和服务的提供，在必要时作为部门之间的联络人；", '以简明扼要的方式提出解决办法，并调查责任领域中出现的异常的问题；', "由经理分配其他岗位职责和相关职责。"],
                    need: ["要求相关领域的学士学位；有海外学习或工作经验者优先;", "1-3年的运营工作经验；具有主流金融媒体，区块炼或数字资产垂直媒体两年以上工作经验者优先;", "较强的组织能力和学习与执行能力"]
                }]
            }
        },
        methods: {
        },
        computed: {

        },
        watch: {

        },
        created() {

        },
    }
</script>
<style lang="scss" scoped>
    /* @import "@/assets/sass/variables"; */
    @import "@/assets/sass/common";
    
    $min-w: 1200px;
    $max-w: 80%;
    $bc: #ccc;
    .joinUs {
        min-width: 1200px;
        max-width: 100%;
        background-color: $color-white;
        .join_tit {
            width: 100%;
            height: auto;
            text-align: center;
            color: #3A9D87;
            background-color: #000;
            font-weight: 600;
            overflow: hidden;
            padding: 38px;
            p {
                font-family: $font-family-roboto;
            }
            :nth-child(1) {
                font-size: $font-title-size-medium-small;
                margin-bottom: 18px;
                 @include text-gradient-golden($text-gradient-center);
            }
            :nth-child(2) {
                font-size: $font-big-24;
                 @include text-gradient-golden;
            }
        }
        .join_info {
            width: $max-w;
            margin: 0px auto;
            padding-bottom: 100px;
            .tit {
                font-size: $font-medium-bigger;
                position: relative;
                font-weight: 600;
                margin: 66px auto 30px;
                padding-left: 10px;
                font-family: $font-family-roboto;
                &::after {
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    width: 5px;
                    height: 24px;
                    content: '';
                    background-color: $color-orange;
                }
            }
            .sub {
                font-size: $font-root;
                font-family: $font-family-roboto;
            }
            ol {
                margin-left: 16px;
            }
            ol li {
                line-height: 24px;
                font-family: $font-family-roboto;
            }
        }
    }
</style>