var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"SafetyVerificationModal"},[_c('modal',{attrs:{"name":_vm.name,"width":_vm.sizeScreen,"resetStatus":_vm.resetStatus}},[_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('div',{staticClass:"content_login_form_container"},[_c('div',{staticClass:"content_login_form"},[_c('div',{staticClass:"title-popup"},[_vm._v(_vm._s(_vm.$t("safety_verification.title")))]),_c('div',[_c('div',{staticClass:"countdown"},[(_vm.isUsePhoneNumber)?_c('div',{staticClass:"label-input"},[_vm._v(" "+_vm._s(_vm.$t("safety_verification.mobile"))+": "+_vm._s(_vm.user?.phone_number)+" ")]):_c('div',{staticClass:"label-input"},[_vm._v(" "+_vm._s(_vm.$t("safety_verification.email"))+": "+_vm._s(_vm.user?.email)+" ")]),(
                  _vm.isStatusCode !== 1 ||
                  (!_vm.disabledInputCode && _vm.isStatusCode === 3)
                )?_c('p',{staticClass:"count-down-timer"},[_vm._v(" "+_vm._s(_vm.formatTime)+" ")]):_vm._e()]),(_vm.disabledInputCode && _vm.isStatusCode === 3)?_c('div',{staticClass:"mask-input"}):_vm._e(),_c('otp-input',{ref:"otpInput",class:_vm.isStatusCode === 3 && _vm.disabledInputCode && 'disable-input',attrs:{"name":"otp-phone","inputClasses":!_vm.errors.has('otp-phone') ? 'otp-input' : 'otp-phone-error',"separator":"","num-inputs":6,"should-auto-focus":true,"is-input-num":true,"input-type":"number"},on:{"on-change":_vm.handleInputCodePhone,"on-complete":_vm.handleInputCodePhone}}),(_vm.errors.has('otp-phone'))?_c('div',{staticClass:"error-otp"},[_vm._v(" "+_vm._s(_vm.errors.first("otp-phone"))+" ")]):_vm._e()],1),_c('div',{staticClass:"button-send"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t( _vm.isUsePhoneNumber ? "safety_verification.des1_sms" : "safety_verification.des1_email" ))+" ")]),_c('button',{attrs:{"disabled":_vm.isGetCodeButtonDisabled || _vm.disabledInputCode},on:{"click":_vm.getVerifyCode}},[_vm._v(" "+_vm._s(_vm.typeVerifyCode)+" ")])]),_c('div',[_c('div',{staticClass:"label-input"},[_vm._v(" "+_vm._s(_vm.$t("safety_verification.label_gg_code"))+" ")]),_c('otp-input',{ref:"otp",attrs:{"id":"otp-field","inputClasses":!_vm.errors.has('otp') ? 'otp-input' : 'otp-error',"separator":"","num-inputs":6,"is-input-num":true,"input-type":"number"},on:{"on-change":_vm.handleInputCodeGG,"on-complete":_vm.handleInputCodeGG}}),(_vm.errors.has('otp'))?_c('div',{staticClass:"error-otp"},[_vm._v(" "+_vm._s(_vm.errors.first("otp"))+" ")]):_vm._e()],1),_c('div',{staticClass:"button-send"},[_c('div',[_vm._v(_vm._s(_vm.$t("safety_verification.des_gg_code")))])]),_c('div',{staticClass:"note-otp",on:{"click":function($event){return _vm.showTips()}}},[_vm._v(" "+_vm._s(_vm.$t("safety_verification_note"))+" ")]),_c('div',[_c('button',{staticClass:"btn-submit",attrs:{"disabled":_vm.isDisableButton ||
                _vm.otp?.length < 6 ||
                _vm.otpPhone?.length < 6 ||
                !_vm.disabledInputCode},on:{"click":_vm.submitOtpForm}},[_vm._v(" "+_vm._s(_vm.$t("kyc.btn_verify"))+" ")])])])])])]),_c('receive-code-modal')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }