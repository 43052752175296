<template>
  <div class="clearfix">
<div class="about_footer_landing f-wrap" :class="{'is_landing' : isLanding}">
      <div class="path_footer col-sm-12 col-lg-2 col-xl-3 col-12 header">
        <div class="group_logo_ft clearfix">
            <router-link class="link_logo_ft" to="/">
              <img src="@/assets/images/monas-white.svg" height="32px;" class="img-logo" alt="logo">
            </router-link>
          <div class="copyright" v-html="siteSetting.copyright">
          </div>
          </div>
        <div class="path_footer social-network">
          <a v-for="socicalNetwork, index in socicalNetworks" :href="socicalNetwork.link" :title="socicalNetwork.name" :key="index" target="_blank" class="icon-social">
            <img :src="require(`@/assets/images/icon/social/${socicalNetwork.icon_class}.svg`)" >
          </a>
        </div>
      </div>
      <div class="copyright width-sm" v-html="siteSetting.copyright">
      </div>
   <div class="row g-0">
    <div class="path_footer col-sm-4 col-lg-2 col-xl-2 col-6 ">
        <li class="item item_title">{{$t('menu_bottom.about_us')}}</li>
        <div class="list_menu_ft clearfix">
          <li><a class="item" href="/about">{{$t('menu_bottom.about_us')}}</a></li>
          <li><a class="item" :href="url_announcement" target="_blank">{{$t('menu_bottom.announcements')}}</a></li>
        </div>
       
      </div>
      <div class="path_footer col-sm-4 col-lg-2 col-xl-2 col-6">
        <li class="item item_title">{{$t('menu_bottom.products')}}</li>
        <div class="list_menu_ft clearfix">
          <li><router-link class="item" :to="{ name: 'Spot Exchange', query: {coin : 'btc', currency : 'usdt'}}">{{$t('menu_bottom.spot_trade')}}</router-link></li>
            <li><router-link class="item" to="/convert">{{$t('menu.convert')}}</router-link></li>
            <li><router-link class="item" :to="{name: 'Margin Future',  params: { coin: `futures/USD_M` }} ">{{$t('footer.futures-usdm')}}</router-link></li>
            <li><router-link class="item" :to="{name: 'Margin Future',  params: { coin: `futures/COIN_M` }} ">{{$t('footer.futures-coinm')}}</router-link></li>
<!--          <li><router-link class="item" to="#">{{$t('menu_bottom.earn')}}</router-link></li>-->
          <li v-if="sizeScreen > 576" class="item item_title contact">{{$t('menu_bottom.contact')}}</li>
          <div  v-if="sizeScreen > 576" class="list_menu_ft clearfix">
            <li><span class="item email" to="#" >{{siteSetting.contact_email}} </span></li>
          </div>
        </div>
      </div>
      <div class="path_footer col-sm-4 col-xl-2 col-lg-2 col-6">
        <li class="item item_title">{{$t('menu_bottom.services')}}</li>
        <div class="list_menu_ft clearfix">
          <li @click="goTop">
            <router-link class="item" to="/account/referral">{{$t('menu_bottom.referral-program')}}</router-link>
          </li>
          <li @click="goTop">
            <router-link class="item" to="/account/reward-center">{{$t('menu.user.reward_center')}}</router-link>
          </li>
        </div>
      </div>
      <div class="path_footer col-sm-4 col-xl-2 col-lg-2 col-6">
        <li class="item item_title">{{$t('menu_bottom.support')}}</li>
        <div class="list_menu_ft clearfix">
          <li><a class="item" :href="url_faq" target="_blank">{{$t('menu_bottom.FAQ')}}</a></li>
          <li><a class="item" :href="url_support" target="_blank">{{$t('menu_bottom.support_center')}}</a></li>
          <li><a class="item" :href="url_fees" target="_blank">{{$t('menu_bottom.fees')}}</a></li>
          <li><a class="item" @click="gotoAPIDocs()" target="_blank" rel="noopener noreferrer" >{{ $t('menu_bottom.api_document') }}</a></li>

        </div>
      </div>
      <div class="path_footer col-sm-4 col-xl-2 col-lg-2 col-6">
        <li class="item item_title"
        >{{$t('menu_bottom.legal') }}</li>
        <div class="list_menu_ft clearfix">
          <li><a class="item" href="/terms">{{ $t('menu_bottom.term') }}</a></li>
          <li><a class="item" href="/policy">{{ $t('menu_bottom.privacy_policy') }}</a></li>
        </div>
      </div>
      <div v-if="sizeScreen < 576" class="path_footer col-sm-4 col-xl-2 col-lg-2 col-6">
        <p class="title_contact">{{$t('menu_bottom.contact')}}</p>
            <p class="item_email" to="#">{{emailContact}} </p>
      </div>
     <div class="path_footer fl-right col-sm-4 col-xl-2 col-lg-2 col-6 social-network-right">
       <div class="fl-right" :class='{"socicalNetworks" : socicalNetworks.length > 4}'>
         <a v-for="socicalNetwork in socicalNetworks" :href="socicalNetwork.link" :title="socicalNetwork.name" :key="socicalNetwork.id" target="_blank" class="icon-social">
           <img :src="require(`@/assets/images/icon/social/${socicalNetwork.icon_class}.svg`)" >
         </a>
       </div>
       
     </div>
   </div>
</div>
    <div class="clearfix"></div>
    <div id="footer_bottom_all" class="clearfix">
      <div class="about_footer clearfix">
<!--        <div class="list_link_ft clearfix">-->
<!--          <a v-for="socicalNetwork, index in socicalNetworks" :href="socicalNetwork.link" :title="socicalNetwork.name" :key="index" target="_blank" class="icon-social">-->
<!--            <i :class="socicalNetwork.icon_class"></i>-->
<!--            {{socicalNetwork.icon_class}}-->
<!--          </a>-->
<!--        </div>-->
        <button class="btn-go-top" @click="goTop"><i class="icon-arrow5"></i></button>
<!--        <div class="right_footer_ex">-->
<!--          {{ siteSetting.copyright }}-->
<!--        </div>-->
      </div>
    </div>

  </div>

</template>

<script>
  import Const from '@/common/Const';
  import AuthenticationUtils from '@/common/AuthenticationUtils';
  import rf from '@/request/RequestFactory'
  import ExchangeFooter from '@/components/salespoint_pages/exchange/basic/ExchangeFooter.vue';
  import {mapState} from 'vuex';

  export default {
    components: {
      ExchangeFooter,
    },
    data() {
      return {
        visibleLangueges : false,
        languages: Const.LANGUAGES,
        socicalNetworks: {},
        siteSetting: {},
        lang_bar: false,
        isAuthenticated: window.isAuthenticated,
        url_announcement: process.env.VUE_APP_ZENDESK_ANNOUNCEMEN,
        url_support: process.env.VUE_APP_ZENDESK_SUPPORT,
        url_faq: process.env.VUE_APP_ZENDESK_FAQ,
        url_fees: process.env.VUE_APP_ZENDESK_FEES,
        sizeScreen: window.innerWidth,
        masterDataChange: false
      }
    },
    props: {
      miniFooter  : { type: Boolean, default: false },
    },
    computed: {
      ...mapState({
        masterdata: state => state.masterdata,
      }),
      isExchangePage() {
        return this.$route.path === PathRoot.path;
      },
      isLanding() {
        return this.$route.path === '/';
      },
      emailContact() {
        return this.siteSetting?.contact_email
      }
    },
    methods: {
      getWhitePaper(locale = 'en') {
          let url = process.env.VUE_APP_API_URL + '/white-paper/' + locale;
          return url;
      },
      gotoUrl(url) {
        window.location.href = url;
      },

      gotoAPIDocs() {
        let url = process.env.VUE_APP_API_URL + "/api/documentation";
        window.open(url)
        // this.gotoUrl(url);
      },
      goTop(){
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      },
      updateUserLocale(locale){
        AuthenticationUtils.setLocale(locale);

        let params = {
          lang: locale
        };

        if (this.isAuthenticated) {
          rf.getRequest('LocaleRequest').setUserLocale(params);
        }
      },

      convertSiteSetting(data) {
        window._.each(data, item => {
           this.siteSetting[item.key] = item.value;
          this.$forceUpdate()
        });
      },

      getMasterdata() {
        this.convertSiteSetting(this.masterdata?.settings);
        this.socicalNetworks = window._.filter(this.masterdata?.social_networks, item => item.is_active) || {};

      },
      resizeHandler() {
          this.sizeScreen = window.innerWidth;
        },
    },
    watch: {
      masterdata() {
        this.$nextTick(() => {
          this.convertSiteSetting(this.masterdata?.settings)
        })
      }
    },
    created () {
      this.getMasterdata();
      window.addEventListener("resize", this.resizeHandler);
    },

  }
</script>
<style lang="scss" scoped>
  @import '@/assets/sass/variables';
  .list_link_ft {
    float: left;
    margin-top: 5px;
    a {
      font-size: $font-big-19;
      margin-right: 19px;
      &:hover, &:active, &:focus {
        color: $color-white;
      }
    }
  }
  .clearfix-20 {
    height: 20px;
    clear: both;
  }
  .line-break {
    float: left;
    width: 100%;
    padding-top: 50px;
  }
  .line-break hr {
    width: 100%;
    height: 1px;
    opacity: 0.1;
    background-color: $color-white;
    margin: 0px;
  }
  .list_menu_ft {
    text-align: left;
    li {
      .item {
        font-family: $font-inter-regular;
        font-size: 16px;
        line-height: 22px;
        color: var(--color-footer);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 10px;
        display: inline-block;
        cursor: pointer;
        display: block;
        padding-bottom: 10px;
      }
        &:hover {
          color: $color-aquamarine;
        }
      .email {
        cursor: unset !important;
        display: inline-block;
      }
    }
  }
  li {
    width: 100%;
    float: left;
    display: block;
    color: $color-white !important;
    &.item_title{
      font-family: $font-inter-regular;
      font-size: 18px;
      line-height: 22px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 10px;
      display: inline-block;
      margin-bottom: 16px;
      padding-bottom: 0;
    }
    &.contact{
      margin-top: 24px;
      padding: 0;
    }
    &:last-child{
      padding: 0;
    }
  }
  .group_logo_ft{
    margin-right: 10%;
    margin-bottom: 10px;
    .logo_ft{
      float: left;
      margin-right: 3px;
    }
  }
  .about_footer_landing{
    padding: 50px 0px 0;
    width: 1490px;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    .path_footer{
      .social-network {
       display: none;
      }
      float: left;
      &.mr-5{
        margin-right: 5%;
      }
      .copyright {
        font-size: 16px;
        line-height: 19px;
        color: var(--color-footer);
      } 

      img{
        margin: 0  0 10px 10px;
        height: 32px;
      }
      .img-logo {
        margin: 0  0 28px 0px;
      }
      &.fl-right{
        max-width: 100%;
        float: right ;
      }
    }
    .width-sm {
      display: none;
    }
    .path_footer_1 {
      width: 33.33%;
    }
    &.is_landing {
      .path_footer{

      }
      path_footer-2{
        width: 20%;
      }
    }
    .lang-bar {
      display: inline-block;
      float: left;
      position: relative;
      .btn-lang-head {
        border-radius: 3px;
        border: solid 1px $color-white;
        height: 35px;
        background-color: transparent;
        min-width: 85px;
        color: $color-alto;
        font-family: $font-family-roboto;
        .icon-arrow1 {
          font-size: 10px;
          float: right;
          line-height: 24px;
          margin-left: 7px;
        }
      }
      .dropdown-head {
        position: absolute;
        left: 0px;
        top: 100%;
        min-width: 100%;
        .subLang {
          width: 100%;
          li {
            background-color: $color-eden-dark;
            width: 100%;
            a {
              display: block;
              width: 100%;
              line-height: 20px;
              padding: 10px 13px;
              border-bottom: 1px solid $color-eden;
            }
            &:first-child {
              border-top-left-radius: 3px;
              border-top-right-radius: 3px;
            }
            &:last-child {
              border-bottom-left-radius: 3px;
              border-bottom-right-radius: 3px;
            }
            &:hover {
              background-color: $color-jungle-green-light;
              a {
                color: $color-white;
              }
            }
          }
        }
      }
    }
  }
  .line_ft {
    width: 100%;
    height: 1px;
    display: block;
    background-color: rgba(255, 255, 255, 0.1);
  }
  .social-network-right{
    float: right;
    .fl-right {
      width: 100px;
    }
    .socicalNetworks {
      width: 126px !important;
    }
  }
  #footer_bottom_all {
    background-color: var(--background-footer);
    .about_footer {
      padding: 22px 25px 25px 25px;
      .list_link {
        margin-top: 5px;
        a.icon {
          display: inline-block;
          float: left;
          margin-right: 5px;
          width: 30px;
          height: 30px;
          line-height: 20px;
          padding: 5px;
          font-size: $font-big-20;
          color: $color-bali-hai;
          border-radius: 3px;
          &:hover {
            color: $color-white;
          }
        }
      }
      .right_footer_ex {
        margin-top: 5px;
        float: right;
        font-size: $font-small;
        color: $color-bali-hai;
        line-height: 30px;
      }
    }
  }
  .btn-go-top {
    display: block;
    width: 40px;
    height: 40px;
    float: right;
    line-height: 40px;
    text-align: center;
    border-radius: 3px;
    /* background-color: $color-casal; */
    background-color: $active-bg-color;
    border: $border-bottom-bold;
    color: $color-white;
    font-size: $font-big-20;
    padding: 3px 0px;
    border: 0px;
    margin-left: 17px;
    &:hover {
      background-color: $color-caribbean-green;
    }
    &:focus {
      background-color: $active-bg-color;
    }
  }

  @media screen and (max-width: 1024px) {
    .about_footer_landing{
      padding: 50px 0 0;
      display: flex;
      flex-direction: column;
  }
    .row{
      padding: 10px;
      margin: 0 auto;
      row-gap: 30px
    }
    .path_footer {
      .social-network {
        display: block !important;
        div {
          display: flex;
          flex-direction: row;
        }

      }
      .copyright {
        display: none;
      }
    }
    .copyright {
      display: flex;
      gap: 10px;
      margin-left: 20px;

    }
    .width-sm {
      display: flex !important;
    }
    .header{
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    .social-network-right {
      display: none !important;
    }
  }

  @media screen and (max-width: 992px) {
    .about_footer_landing {
      padding: 30px 16px;
    }
    .row{
      padding: 25px 0 0px !important;
    }
    .path_footer {
      padding: 0 0px 0px 0px;
      margin-bottom: 8px;
      .social-network {
        //display: flex !important;
        //padding: 0 10px ;
        //gap: 10px;
        padding: 0;
        a {
          margin-right: 10px;
          img {
            margin-bottom: 10px !important;
            width: 24px;
            height: 24px;
          }
        }
        div {
          display: flex;
          flex-direction: row;
          gap: 10px;
        }
      }
      img{
        margin: 0 !important;
      }
    }
    .title_contact {
      font-size: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 10px;
      display: inline-block;
      cursor: pointer;
      color: $color-white;
      margin-bottom: 0px;
    }
    .copyright {
      margin-left: 0px;
      border-bottom: 1px solid $color-gray-charade;
      padding-bottom: 25px;
      p {
        font-size: $font-root;
      }
    }
    li {
      &.item_title{
          font-size: $font-medium-bigger;
      }
      .item {
        font-size: $font-root !important;
      }
      &.contact{
        margin-top: 20px;
      }
    }

    .f-wrap {
      display: flex;
      flex-wrap: wrap;
    }
    .row{
      padding: 10px;
      margin: 0 auto;
      row-gap: 20px;
    }
  }
</style>