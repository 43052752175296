<template>
    <div class="symbolDetail">
        <div class="detail_menu">
            <div class="detail_menu_con">
                <div class="scrollBlock">
                    <vuescroll :ops="scrollOption">
                        <ul>
                            <li class="menu_item" v-for="(item, index) in $t('symbol_detail_left')" :key="index"><a href="javascript:void(0);" :data-id="item.id" @click="getItemId" :class="item.id == targetId ? 'active' : ''">{{ item.title_txt }}</a></li>
                        </ul>
                    </vuescroll>
                </div>
            </div>
        </div>
        <div class="detail_article">
            <div class="article_con">
                <div class="article_tit">{{$t('symbol_detail_article.'+targetId+'.article_tit')}}</div>
                    <div class="article_detail_i mt_10">{{ $t('symbol_detail_introduction') }}</div>
                    <div class="article_detail_c mt_10">{{$t('symbol_detail_article.'+targetId+'.article_detail')}}</div>  
                <div class="article_link">
                    <div class="mt_10">
                        <strong>{{ $t('symbol_detail_org') }}</strong>
                        <a :href="$t('symbol_detail_article.'+targetId+'.article_link[0]')" target="_blank">{{$t('symbol_detail_article.'+targetId+'.article_link[0]')}}</a>
                    </div>
                    <div class="mt_10" v-if="$t('symbol_detail_article.'+targetId+'.article_link[1]')">
                        <strong>{{ $t('symbol_detail_block') }}</strong>
                        <a :href="$t('symbol_detail_article.'+targetId+'.article_link[1]')" target="_blank">{{$t('symbol_detail_article.'+targetId+'.article_link[1]')}}</a>
                    </div>
                    <div v-if="$t('symbol_detail_article.'+targetId+'.article_link[2]')">
                        <strong>{{ $t('symbol_detail_book') }}</strong>
                        <a :href="$t('symbol_detail_article.'+targetId+'.article_link[2]')" target="_blank">{{$t('symbol_detail_article.'+targetId+'.article_link[2]')}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import vuescroll from 'vuescroll';
    export default {
        data() {
            return {
                targetId: 'btc',
                scrollOption:{
                    bar: {
                        showDelay: 500,
                        onlyShowBarOnScroll: true,
                        keepShow: false,
                        background: '#02273b',
                        opacity: 1,
                        hoverStyle: false,
                        specifyBorderRadius: false,
                        minSize: false,
                        size: '6px',
                    },
                }
            }
        },
        components:{
            vuescroll
        },
        computed: {
            // getItemId() {
            //     console.log(this.$route.path);
            // }
        },
        methods: {
            getItemId(e) {
                this.targetId = e.target.dataset.id;
            }            
        },
    }
</script>
<style lang="scss" scope>
    @import "@/assets/sass/variables";
    $min-w: 1200px;
    $max-w: 80%;
    $c_white: rgba(255,255,255,.65);
    .symbolDetail {
        min-width: $min-w;
        height: auto;
        display: flex;
        justify-content: center;
        position: relative;
        margin-left: 0;
        margin-right: 0;
        box-sizing: border-box;
        .detail_menu {
            background-color: #021521;
            display: flex;
            justify-content: flex-end;
            flex-grow: 1;
            flex-shrink: 0;
            .detail_menu_con {
                min-width: 240px;
                height: auto;
                padding-top: 50px;
                background-color: transparent;
                .scrollBlock {
                    height: 780px;
                }
                .menu_item {
                    padding: 0 16px 0 28px;
                    font-size: 14px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    a {
                        height: 60px;
                        line-height: 60px;
                        color: #3C505F;
                        font-family: $font-family-roboto;
                        &:hover {
                            color: $color-orange;
                        }
                        &:focus {
                            outline: none;
                        }
                    }
                }
                .active {
                    color: $color-orange !important;
                }
            }
        }
        .detail_article {
            background: #02273b;
            flex-grow: 1;
            flex-shrink: 0;
            .article_con {
                width: 960px;
                padding: 90px 30px 90px;
                .article_tit {
                    font-size: 20px;
                    font-weight: 600;
                    color: #fff;
                    font-family: $font-family-roboto;
                    margin-bottom: 30px;
                    cursor: default;
                }
                .article_detail_i {
                    font-size: 16px;
                    font-weight: 600;
                    color:  $c_white;
                    font-family: $font-family-roboto;
                    cursor: default;
                }
                .article_detail_c {
                    font-size: 14px;
                    color:  $c_white;
                    font-family: $font-family-roboto;
                    line-height: 24px;
                    text-indent: 2em;
                    cursor: default;
                }
                .article_link {
                    font-size: 14px;
                    color:  $c_white;
                    font-family: $font-family-roboto;
                    strong {
                        cursor: default;
                    }
                    a {
                        font-size: 14px;
                        color:  $c_white;
                        &:hover {
                            color: $color-orange;
                        }
                        &:focus {
                            outline: none;
                        }
                    }
                }
            }
        }
        .mt_10 {
            margin-bottom: 10px;
        }
    }

</style>