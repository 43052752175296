<template>
  <div id="trade-history" :class="{ no_padding: options && options.isNoPadding }">
    <div class="group-control" v-if="!options">
      <date-tab @change-select-date="changeDate" ref="selectDateTab"/>
      <div class="group-date">
          <span class="date-title">From</span>
          <datepicker :value="dateFromDefault" class="date-from" @selected="selectDateFrom" :format="customFormatter"></datepicker>
          <datepicker :value="dateToDefault" class="date-to" @selected="selectDateTo" :format="customFormatter"></datepicker>
      </div>
      <button class="button-search" @click="actionSearch">Search</button>
    </div>
    <div class="group-control" v-if="options" :class="{ is_single_page: options && options.isNoPadding }">
      <div class="group-date-picker">
        <div class="label-text">{{ $t('order.order_history.date') }} :</div>
        <div class="group-date">
            <datepicker
            :value="dateFromDefault"
            class="date-from" @selected="selectDateFrom" :format="customFormatter" input-class="date-picker-input"></datepicker>
            <datepicker
            :value="dateToDefault"
            class="date-to" @selected="selectDateTo" :format="customFormatter" input-class="date-picker-input"></datepicker>
        </div>
      </div>

      <!-- <div class="group-pair">
        <div class="label-text">{{ $t('order.order_history.pair') }} :</div>
        <div class="content-search">
          <div class="toolbar-element">
            <div class="coin-input" v-click-outside="clickOut">
              <input type="text" :placeholder="$t('order.order_history.coin')"
                   v-model="filters.coin" @click="showCoinOption()" @keyup="onSearchCoin()" @keyup.enter="actionSearch"/>
             <div class="box-coin-option" v-if="isShowCoinOption">
                <div class="coin-option">
                  <span class="option" v-for="coin in coins" @click="onSelectCoin(coin)">{{ coin | uppercase }}</span>
                </div>
              </div>
            </div>
          </div>
          <span class="bw-element">/</span>
          <div class="toolbar-element2" >
            <select-box
              v-model="filters.currency"
              :placeholder="$t('order.order_history.all')"
              :options="getMarkets()"
            />
          </div>
        </div>
      </div> -->

      <div class="group-type">
        <div class="label-text">{{ $t('order.order_history.side') }} :</div>
        <div class="content-search">
          <select-box
            v-model="filters.trade_type"
            :placeholder="$t('order.order_history.all')"
            :options="sideList"
          />
          <button class="button-search" @click="actionSearch">{{$t('orders.trade_history.search')}}</button>
          <button class="button-reset" @click="actionReset">{{$t('orders.trade_history.reset')}}</button>
        </div>
      </div>

      <div class="export-file">
        <a class="excel_export f-fr mart3" @click="downloadReport()" @mouseover="mouseOver" @mouseout="mouseOver">          
           <span class="icon-export"/>&nbsp;
          {{ $t('order.order_history.export_complete_trade_history') }}
        </a>
        <div v-show="isShowToolTip" class="export-file-tooltip">{{ $t('order.order_history.export_complete_trade_history_tooltip') }}</div>
      </div>
    </div>
    <data-table3 ref="datatable" :getData="getData" :msg-empty-data="$t('order.trade_history.no_data')" class="datatable" id="order-table" :limit="10">
      <template slot-scope="props">
        <th class="width20" :title="$t('order.open_order.date')" data-sort-field="created_at" @click="props.echoclick">
          {{ $t('order.open_order.date') }}
          <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
        <th :title="$t('table.symbol')" data-sort-field="instrument_symbol" @click="props.echoclick">
          {{ $t('table.symbol') }}
          <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
        <!-- <th :title="$t('orders.open_order.exec_type')" data-sort-field="exec_type" @click="props.echoclick">
          {{ $t('orders.open_order.exec_type') }}
          <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th> -->
        <th :title="$t('order.open_order.side')" data-sort-field="trade_type" @click="props.echoclick">
          {{ $t('order.open_order.side') }}
          <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
        <th :title="$t('order.open_order.exec_type')" data-sort-field="exec_type" @click="props.echoclick">
          {{ $t('order.open_order.exec_type') }}
          <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
        <th :title="$t('order.open_order.price')" data-sort-field="price" @click="props.echoclick">
          {{ $t('order.open_order.price') }}
          <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
        <!-- <th :title="$t('order.open_order.filled')" data-sort-field="filled" @click="props.echoclick">
          {{ $t('order.open_order.filled') }}
          <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
        <th :title="$t('order.trade_history.fee')" data-sort-field="fee" @click="props.echoclick">
          {{ $t('order.trade_history.fee') }}
          <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th> -->
        <th class="width10" :title="$t('margin.quantity')" data-sort-field="quantity" @click="props.echoclick">
          {{ $t('margin.quantity') }}
          <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
        <!-- <th class="w_130" :title="$t('table.text')" data-sort-field="order_text" @click="props.echoclick">
          {{ $t('table.text') }}
          <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th> -->
        <!-- <th class="text-right" :title="$t('table.order_id')" data-sort-field="order_id" @click="props.echoclick">
          {{ $t('table.order_id') }}
          <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th> -->
         <th class="width10" :title="$t('margin.fee_rate')" data-sort-field="fee_rate" @click="props.echoclick">
          {{ $t('margin.fee_rate') }}
          <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
        <th class="width10 last-column padding-right-0" :title="$t('withdraw_fee_setting.fee')"  @click="props.echoclick">
          <span class="fl-right">{{ $t('withdraw_fee_setting.fee') }}</span>
          <i class="group-sort-tabl3 fl-right" v-html="props.innerHtmlSort"></i>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
      </template>
      <template slot="body" slot-scope="props">
        <tr>
          <td>{{ props.item.created_at | localTime }}</td>
          <td>{{ props.item.instrument_symbol }}</td><!-- 
          <td>{{ $t('table.exec_type.'+props.item.trade_type) }}</td> -->
          <td v-if="props.item.trade_type== commonConst.FUNDING || props.item.trade_type == ''" > -- </td>
          <td v-else :class="{'blue' : props.item.trade_type == 'buy' , 'red' : props.item.trade_type == 'sell'}"> {{props.item.trade_type | upperFirst }}</td>
          <!-- <td v-else-if="typeTrade =='all' || typeTrade =='' " :class="{'blue' : props.item.buy_owner_email === user.email, 'red' : props.item.sell_owner_email === user.email}">
            {{ $t('table.side.'+(props.item.sell_owner_email === user.email ? 'sell' : 'buy')) }}
          </td>
          <td class="red" v-else-if="typeTrade=='sell'">Sell</td>
          <td  class="blue" v-else-if="typeTrade=='buy'">Buy</td> -->

          <td class="text-left">{{$t('trade.exec_type_' + props.item.exec_type)}}</td>
          <td>{{ props.item.price | formatMarginNumber(8) }}</td><!-- 
          <td>{{ props.item.filled }}</td>
          <td>{{ props.item.fee }}</td> -->
          <td>{{ props.item.quantity | formatMarginNumber(8) }}</td>
          <td v-bind:class="[{'blue' : getFeeRateFunding(props.item.sell_fee_rate, props.item.buy_fee_rate) < 0}]" v-if="props.item.trade_type== commonConst.FUNDING || props.item.trade_type == ''">{{getFeeRateFunding(props.item.sell_fee_rate, props.item.buy_fee_rate) * 100 | formatMarginNumber(8,0)}}%</td>
          <td v-bind:class="[{'blue' : props.item.sell_fee_rate < 0}]" v-if="props.item.trade_type== 'sell'">{{props.item.sell_fee_rate * 100 | formatMarginNumber(8,0)}}%</td>
          <td v-bind:class="[{'blue' : props.item.buy_fee_rate < 0}]" v-if="props.item.trade_type== 'buy'">{{props.item.buy_fee_rate * 100 | formatMarginNumber(8,0)}}%</td>
          <td v-bind:class="['last-column', {'blue' :getFeeRateFunding(props.item.sell_fee, props.item.buy_fee) < 0}]" v-if="props.item.trade_type== commonConst.FUNDING || props.item.trade_type == ''">{{getFeeRateFunding(props.item.sell_fee, props.item.buy_fee) | formatMarginNumber(8,0)}} {{(props.item.is_amal_fee_seller == 1 ? commonConst.AML : commonConst.BTC) | uppercase}}</td>
          <td v-bind:class="['last-column', {'blue' : props.item.sell_fee < 0}]" v-else-if="props.item.trade_type== 'sell'">{{ props.item.sell_fee | formatMarginNumber(8,0) }} {{(props.item.is_amal_fee_seller == 1 ? commonConst.AML : commonConst.BTC) | uppercase}}</td>
          <td v-bind:class="['last-column', {'blue' : props.item.buy_fee < 0}]" v-else >{{ props.item.buy_fee | formatMarginNumber(8,0) }} {{(props.item.is_amal_fee_buyer ==1 ? commonConst.AML : commonConst.BTC) | uppercase}}</td>
          <!-- <td><div class="content_text_table">{{ props.item.order_text }}</div></td> -->
          <!-- <td><div class="text-right">{{ getOrderId(props.item) }}</div></td> -->
        </tr>
      </template>
    </data-table3>
  </div>
</template>

<script>
  import Datepicker from 'vuejs-datepicker';
  import DateTab from '@/components/marginexchange_pages/common/DateTab.vue';
  import COMMON_CONST from '@/common/Const';
  import rf from '@/request/RequestFactory'
  import BigNumber from 'bignumber.js';
  import moment from 'moment';
  import MasterdataUtils from '@/common/MasterdataUtils';
  import Utils from '@/common/Utils'
  import SelectBox from '@/components/shared_components/common/SelectBoxTabFocus';
  import {mapActions, mapGetters, mapMutations} from 'vuex';

  export default {
    components: {
      DateTab,
      Datepicker,
      SelectBox
    },
    props: {
      options: {
        type: Object,
        default: null
      },
      coin: {
        type: String,
        default: COMMON_CONST.DEFAULT_COIN
      },
      currency: {
        type: String,
        default: COMMON_CONST.DEFAULT_CURRENCY
      }
    },
    data() {
      return {
        commonConst: COMMON_CONST,
        dateFromDefault: moment().subtract(6, 'days').toDate(),
        dateToDefault: moment().toDate(),
        dateFrom: moment().subtract(6, 'days').startOf('day').format('x'),
        dateTo: moment().endOf('day').format('x'),
        coins: [],
        coinMasters: [],
        isShowCoinOption: false,
        typeTrade: 'all',
        filters: {
          currency: '',
          trade_type: ''
        },
        durationDateFrom: null,
        isHide: false,
        isShowToolTip: false,
        sideList: [
          {
            id: 'all',
            name: window.i18n.t('order.order_form.all'),
          },
          {
            id: 'buy',
            name: window.i18n.t('order.order_form.buy'),
          },
          {
            id: 'sell',
            name: window.i18n.t('order.order_form.sell'),
          }
        ],
      }
    },
    computed: {
      ...mapGetters(['user', 'masterAccount']),
    },
    methods: {
      ...mapActions(['getMasterAccount']),
      ...mapMutations(['updateMamMasterAccount']),
      getSocketEventHandlers() {
        return {
          MarginOrderUpdated: this.onMarginOrderUpdated,
        };
      },
      onMarginOrderUpdated() {
        this.$refs.datatable.refresh();
      },
      changeDate (currentDateTab) {
        switch(currentDateTab.id) {
          case this.commonConst.ONE_DAY:
              this.durationDateFrom = moment().startOf('day').format('x');
              break;
          case this.commonConst.ONE_WEEK:
              this.durationDateFrom = moment().subtract(6, 'days').startOf('day').format('x');
              break;
          case this.commonConst.ONE_MONTH:
              this.durationDateFrom = moment().subtract(1, 'months').add('days', 1).startOf('day').format('x');
              break;
          default:
              this.durationDateFrom = moment().subtract(3, 'months').add('days', 1).startOf('day').format('x');
        }
        const params = this.getDefaultDateRange();
        this.$refs.datatable.filter(params);
      },
      getData (params) {
        params = {
          ...params,
          start_date: this.dateFrom,
          end_date: this.dateTo,
          is_mam: 1,
        };
        if (!isAuthenticated) {
          return new Promise((resolve) => {
            return resolve({});
          });
        }

        if (!this.masterAccount) {
          return this.getMasterAccount()
            .then((res) => {
              if (res && res.data) {
                this.updateMamMasterAccount(res.data);
              }
              return this.getTradeList(params);
            }).catch(error => console.log(error));
        }

        return this.getTradeList(params).catch(error => console.log(error));
      },
      getTradeList(params) {
        return rf.getRequest('MarginRequest').getTradeList(params)
          .then(res => {
            let data = res.data.data;
            let masterAccountId = this.masterAccount.account_id;
            data = window._.map(data, item => {
              let tradeType = (masterAccountId == item.buy_account_id) ? 'buy' : 'sell';
              return {
                ...item,
                trade_type: tradeType,
              }
            });
            res.data.data = data;
            return res;
          });
      },
      getDefaultDateRange() {
        return {
          start_date: this.dateFrom,
          end_date: moment().endOf('day').format('x')
        };
      },

      actionSearch () {
        if(this.dateFrom > this.dateTo) {
          this.showError(window.i18n.t('common.date.warning'));
          return false;
        }
        this.typeTrade = this.filters.trade_type;
        this.$refs.datatable.filter(this.filterParams());
        if (!this.options) {
          this.$refs.selectDateTab.resetSelectDate();
        }
      },
      filterParams () {
        const filterParams = {
          start_date: this.dateFrom,
          end_date: this.dateTo
        };
        // if (this.filters.coin) {
        //   filterParams.coin = this.filters.coin;
        // }
        // if (this.filters.currency) {
        //   filterParams.currency = this.filters.currency;
        // }
        if (this.filters.trade_type) {
          if(this.filters.trade_type == 'all')
            filterParams.trade_type = '';
          else
            filterParams.trade_type = this.filters.trade_type;
        }
        return filterParams;
      },
      actionReset () {
        // this.filters.coin = '';
        // this.filters.currency = '';
        this.filters.trade_type = '';
        this.dateFromDefault = moment().subtract(6, 'days').toDate();
        this.dateToDefault = moment().toDate();
        this.dateFrom = moment().subtract(6, 'days').startOf('day').format('x');
        this.dateTo = moment().endOf('day').format('x');
        this.$refs.datatable.refresh();
        $(".datatable").find("th").attr("data-sort-order", "");
      },
      selectDateFrom (dateFrom) {
        this.dateFrom = moment(dateFrom).valueOf();
        this.dateFrom = moment(dateFrom).startOf('day').format('x');
      },
      selectDateTo (dateTo) {
        this.dateTo = moment(dateTo).endOf('day').format('x');
      },
      customFormatter(date) {
        return moment(date).format('YYYY - MM - DD');
      },
      requestCoinList() {
        MasterdataUtils.getCoins((coins) => {
          this.coinMasters = coins;
        });
      },
      // onSelectCoin(coin) {
      //   this.isShowCoinOption = false;
      //   this.filters.coin = coin.toUpperCase();
      // },
      clickOut() {
        this.isShowCoinOption = false;
      },
      showCoinOption() {
        this.isShowCoinOption = true;
        this.coins = this.coinMasters;
      },
      downloadReport() {
        const params = {
          start_date        : this.dateFrom,
          end_date          : this.dateTo,
          timezone_offset   : Utils.getTimzoneOffset(),
          is_mam            : 1,
        };
        rf.getRequest('MarginRequest')
          .exportTradeCSV(params, 'MAM-TradeHistory.csv');
      },
      mouseOver () {
        this.isShowToolTip = !this.isShowToolTip;
      },
      getMarkets() {
        return window._.map(COMMON_CONST.MARKETS, item => {
          return { id: item, name: item.toUpperCase() };
        });
      },
      getOrderId(item){
        try{
          const {buy_account_id=0, sell_account_id=0, buy_order_id, sell_order_id} = item;
          const thisAccountId = this.user.id;;
          if(buy_account_id == thisAccountId) return buy_order_id;
          if(sell_account_id == thisAccountId) return sell_order_id;
          return 0;
        }catch(err){
          console.error(err);
        }
      },
      showError(message) {
        Message.error(message, {}, { position: "bottom_left" });
      },
      showSuccess(message) {
        Message.success(message, {}, { position: 'bottom_left' });
      },
      getFeeRateFunding(sell_fee_rate, buy_fee_rate) {
        return new BigNumber(sell_fee_rate).add(buy_fee_rate).toString();
      },
    },
    mounted() {
      if(window.isAuthenticated) {
        this.getMasterAccount();
      }

      this.requestCoinList();
    }
  }
</script>
<style lang="scss" scoped>
  @import '@/assets/sass/variables';
  .fl-right {
    padding-left: 4px;
    padding-right: 18px;
  }
  .padding-right-0{
    padding-right: 0px;
  }
  .last-column {
    text-align: right !important;
  }
  .width20{
    width: 20%;
  }
  .width10{
    width: 10%;
  }
  .w_130 {
    width: 130px;
  }
  .w_165 {
    width: 165px;
  }
  .content_text_table {
    max-width: 135px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  #trade-history {
    .group-control{
      padding: 9px 20px 9px 20px;
      font-size: $font-small;
      &:after{
        display: block;
        clear: both;
        content: "";
      }
      #date-tab{
        float: left;
      }
      .group-date{
        display: inline-block;
        margin-left: 36px;
        position: relative;
        float: left;
        .date-title{
          margin-right: 5px;
        }
        .vdp-datepicker{
          display: inline-block;
        }
        .date-to{
          margin-left: -5px;
        }
      }
      &.is_single_page{
        display: block;
        width: 100%;
        padding: 0 0 20px 0;
        position: relative;
        background: $color-white-smoke;
        &:after{
          display: block;
          clear: both;
          content: "";
        }
        .label-text{
          color: $color-grey-dusty;
          font-size: $font-small;
          line-height: 25px;
          margin-bottom: 3px;
        }
        .button-search{
          margin-left: 25px;
          margin-right: 10px;
          background-color: $color-jungle-green;
          color: $color-white;
          box-shadow: none;
          font-family: $font-family-roboto-bold;
          border: 1px solid $color-jungle-green;
          border-radius: 3px;
          width: 85px;
          height: 32px;
          font-size: $font-smaller;
          float: left;
          -webkit-transition: 0.5s;
          transition: 0.5s;
            letter-spacing: 0.9px;
          text-transform: uppercase;
          &:hover {
            background: $color-blue-custom;
            border-color: $color-blue-custom;
            color: $color-white;
            -webkit-transition: 0.5s;
            transition: 0.5s;
          }
        }
        .group-date-picker {
          display: inline-block;
          float: left;
          margin-right: 20px;
          .group-date {
            display: inline-block;
            margin-left: 0;
            position: relative;
            .vdp-datepicker {
              display: inline-block;
              float: left;
            }
            .date-to{
              margin-left: 0;
            }
          }
        }
        .group-pair{
          display: inline-block;
          margin-right: 20px;
          float: left;
          input{
            background-color: transparent;
            height: 30px;
            width: 80px !important;
            float: left;
            border: 0px;
            padding: 0px 5px 0px 14px;
            margin-right: 1px;
            font-size: $font-root;
            color: $color-grey-dusty;
          }
          .sc_search_select{
            background-color: $color-catskill-white;
            height: 30px;
            width: 80px;
          }
          .bw-element {
            float: left;
            font-size: 15px;
            margin-left: 5px;
            height: 30px;
            line-height: 30px;
            margin-right: 5px;
            color: $color-grey-dusty;
          }
        }
        .group-type{
          display: inline-block;
          margin-right: 20px;
          float: left;
          .sc_search_select{
            height: 30px;
            width: 80px;
            display: inline-block;
            float: left;
          }

          button{
            line-height: 20px;
            height: 32px;
            padding: 6px 16px;
            width: auto;
            min-width: 85px;
          }
        }
        .export-file{
          display: inline-block;
          float: right;
          margin-top: 40px;
          line-height: 20px;
          font-size: $font-small;
          font-weight: 500;
          position: relative;
          font-family: $font-family-roboto;
          a {
            color: $color-blue-custom;
            font-size: $font-small;
            .icon-export{
              line-height: 20px;
              font-size: $font-root;
              float: right;
              margin-left: 5px;
            }
          }
          .export-file-tooltip {
            display: none;
            position: absolute;
            bottom: 100%;
            margin-bottom: 5px;
            right: 0;
            width: 220px;
            line-height: 20px;
            background-color: $color-white;
            padding: 5px 10px;
            font-size: $font-smaller;
            font-weight: normal;
          }
          &:hover {
            .export-file-tooltip {
              display: block;
            }
          }
        }
        .button-reset{
          margin-left: 0px;
          margin-right: 0px;
          background-color: $color-jungle-green;
          color: $color-white;
          box-shadow: none;
          font-family: $font-family-roboto-bold;
          border: 1px solid $color-jungle-green;
          border-radius: 3px;
          width: 85px;
          height: 32px;
          font-size: $font-smaller;
          float: left;
          -webkit-transition: 0.5s;
          transition: 0.5s;
            letter-spacing: 0.9px;
          text-transform: uppercase;
          &:hover {
            background: $color-blue-custom;
            border-color: $color-blue-custom;
            color: $color-white;
            -webkit-transition: 0.5s;
            transition: 0.5s;
          }
        }
        .toolbar-element {
          background-color: $color-alabaster;
          margin-right: 0px;
          display: inline-block;
          float: left;
          input {
            border: 1px solid $color-alto;
            border-radius: 3px;
            line-height: 20px;
            padding-top: 5px;
            padding-bottom: 5px;
            height: 32px;
            &:focus {
              border: 1px solid $color-jungle-green;
            }
          }
        }
        .toolbar-element2{
          background-color: $color-catskill-white;
          margin-right: 0px;
          display: inline-block;
          float: left;
        }
        .coin-input {
          position: relative;
          float: left;
          .box-coin-option {
            left: 0;
            position: absolute;
            top: 100%;
            width: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -ms-flex-negative: 1;
            flex-shrink: 1;
            margin-top: 6px;
            z-index: 999;
            background-color: $color-white;
            -webkit-box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);
            box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);
            &:after {
              bottom: 100%;
              left: 50%;
              border: solid transparent;
              content: " ";
              height: 0;
              width: 0;
              position: absolute;
              pointer-events: none;
              border-color: rgba(136, 183, 213, 0);
              border-bottom-color: $color-white;
              border-width: 5px;
              margin-left: -5px;
            }
            .coin-option {
              position: relative;
              padding: 5px 0px;
              max-height: 135px;
              overflow-y: auto;
              .option {
                display: block;
                position: relative;
                display: inline-block;
                white-space: nowrap;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                width: 100%;
                padding: 0 20px;
                margin: 0;
                line-height: 25px;
                height: 25px;
                font-size: $font-root;
                color: $color-grey-dark;
                font-weight: normal;
                min-width: 100%;
                cursor: pointer;
                &:hover {
                  background-color: $color-jungle-green;
                  color: $color-white;
                }
              }
            }

          }
        }
        .excel_export {
          cursor: pointer;
          font-weight: 500;
        }
      }
    }
    #order-table {
      padding-bottom: 0px;
      table {
        border: 0px;
        thead {
          background-color: $color-white;
          border: 0px;
          tr {
            th {
              font-family: $font-family-roboto-medium;
              font-size: $font-small;
              padding: 8px 0px 8px 20px;
              &:last-child {
                padding: 8px 20px 8px 0px;
              }
            }
          }
        } 
        tbody {
          text-align: left;
          tr {
            &.canceled {
              opacity: 0.3
            }
            height: auto;
            font-size: $font-small;
            td {
              font-size: $font-root;
              font-weight: 500;
              color: $color-grey-dark;
              font-family: $font-family-roboto-medium;
              line-height: 21px;
              padding: 7px 0px 6px 20px;
              overflow: initial;
              vertical-align: middle;
              &.blue {
                color: $color-jungle-green;
              }
              &.red {
                color: $color-denim;
              }
              &:last-child {
                text-align: left;
                padding: 7px 20px 6px 0px;
              }
            }
          }
        }
      }
    }
  } 

  #date-tab{
    float: left;
  }
</style>


<style lang="scss">
  @import '@/assets/sass/variables';
  #trade-history {
    .is_single_page {
      .sc_search_select {
        .group_search_select {
          .button_drop_search {
            background: transparent;
            padding: 7px 10px 5px 10px;
            width: 100%;
            height: 32px;
            border: 1px solid $color-alto;
            background-color: $color-white-smoke;
            border-radius: 3px;
            &:focus,
            &:hover,
            &.active {
              border: 1px solid $color-jungle-green;
            }
          }
          .box_list_search_select {
            ul {
              width: 80px;
              li {
                &:hover {
                  background-color: $color-jungle-green;
                  span,
                  a {
                    color: $color-white;
                  }
                }
              }
            }
          }
        }
      }

      .vdp-datepicker{
        input{
          padding: 5px 3px 5px 40px !important;
          background-position: top 8px left 14px !important;
          background-color: $color-white-smoke !important;
          margin-right: 0px !important;
          &:focus {
            border: 1px solid $color-jungle-green;
          }
        }
        &.date-from {
          input {
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
          }
        }
        &.date-to {
          input {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
          }
        }
      }
    }
  }
</style>
