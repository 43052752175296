import BaseModelRequest from './base/BaseModelRequest';

export default class FavoriteRequest extends BaseModelRequest {

  getModelName() {
    return 'favorites';
  }

  listAllPagination() {
    return 'favorites/get-list-favorite';
  }

  createList(favorites) {
    const url = '/favorites/add-all';
    const params = {
      data: JSON.stringify(favorites)
    }
    return this.post(url, params);
  }

  deleteFavorite(favoritePair) {
    if (favoritePair.id) {
        return this.removeOne(favoritePair.id);
    }
    let url = '/' + this.getModelName();
    return this.del(url, favoritePair);
  }

  addFavorite(favorite) {
    let url = '/user/favorite';
    return this.postFutures(url, favorite);
  }

  getFuturesFavorite() {
    let url = '/user/favorite';
    return this.getFutures(url);
  }

}