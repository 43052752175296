 import rf from '@/request/RequestFactory';
import BigNumber from 'bignumber.js';

const actions = {
  getMamPositions({ commit }, params) {
    // console.log('getPositions getPositions 0');
    return new Promise((resolve, reject) => {
      rf.getRequest('MarginRequest').getPositions({ ...params, is_mam: 1 }).
        then(res => {
          const data = res.data && Array.isArray(res.data) ? res.data : []
          const count = _.filter(data, (item) => parseInt(item.current_qty)).length;
          commit('updateMamPositions', data);
          commit('updateMamPositionCount', count);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        })
    });
  },
  getMamPositionRequest({}, params) {
    return rf.getRequest('MarginRequest').getPositions({ ...params, is_mam: 1 });
  },
  updateMamLeverage({ commit }, params) {
    return new Promise((resolve, reject) => {
      rf.getRequest('MarginRequest').updateLeverage({ ...params, is_mam: 1 })
        .then(res => rf.getRequest('MarginRequest').getPositions({ is_mam: 1 }))
        .then(res => {
          commit('updateMamPositions', res.data);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        })
    });
  },
  updateMamMargin({ commit }, params) {
    return new Promise((resolve, reject) => {
      rf.getRequest('MarginRequest').updateMargin({ ...params, is_mam: 1 })
        .then(res => rf.getRequest('MarginRequest').getPositions({ is_mam: 1 }))
        .then(res => {
          commit('updateMamPositions', res.data);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        })
    });
  },
  updateMamRiskLimit({ commit }, params) {
    return new Promise((resolve, reject) => {
      rf.getRequest('MarginRequest').updateRiskLimit({ ...params, is_mam: 1 })
        .then(res => rf.getRequest('MarginRequest').getPositions({ is_mam: 1 }))
        .then(res => {
          commit('updateMamPositions', res.data);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        })
    });
  },
};

const getters = {
  mamCurrentPosition: (state, getters) => {
    const data = state.mamPositions.find(el=>el.symbol == state.currentSymbol)
    return data ? data : { 
      current_qty: 0,
      entry_price: 0,
      entry_value: 0,
      init_margin: 0,
      is_cross: 1,
      leverage: getters.currentInstrument.risk_limit,
      maint_margin: 0,
      open_order_buy_qty: 0,
      open_order_buy_value: 0,
      open_order_sell_qty: 0,
      open_order_sell_value: 0,
      risk_limit: getters.currentInstrument.risk_limit,
      risk_value: 0,
      required_init_margin_percent: getters.currentInstrument.init_margin,
      required_maint_margin_percent: getters.currentInstrument.maint_margin,
      symbol: state.currentSymbol,
    }
  },
  mamPositionCount: (state) => {
    return state.mamPositionCount
  },
  mamPosition: (state, getters) => {
    return getters.mamCurrentPosition
  },
  mamPositions: state => {
    return state.mamPositions
  },
  mamClosedPosition: state => {
    return state.mamPositions
  }
}

const mutations = {
  updateMamPositions: (state, payload) => {
    state.mamPositions = [...payload]
  },

  updateMamPositionCount: (state, payload) => {
    state.mamPositionCount = payload
  },

  //Update any position by symbol itself, if there is no symbol found we use current symbol instead
  //Example: this.updateCurrentPosition({symbol: 'BTCUSD', 'risk_limit': 12 ...}) => update any properties you want to
  //or this.updateCurrentPosition({'risk_limit': 12}) => update properties finding by current symbol
  updateMamPosition: (state, position) => {
    if(!(state.mamPositions && Array.isArray(state.mamPositions))) state.mamPositions = [];

    const index = state.mamPositions.findIndex(el=>el.symbol == position.symbol);
    if (index >= 0) {
        // if (new BigNumber(position.current_qty || 0).eq('0')) {
        //     state.mamPositions.splice(index, 1);
        // } else {
            state.mamPositions[index] = position;
        // }
    } else {
        // if (!(new BigNumber(position.current_qty || 0).eq('0'))) {
            state.mamPositions.push(position);
        // }
    }

    state.mamPositions = [...state.mamPositions];
  },
}

export default {
  actions,
  getters,
  mutations,
  state: {
    mamPositions: [],
    mamPositionCount: 0,
  },
}