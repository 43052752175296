import BaseRequest from './base/BaseRequest';

export default class MamRequest extends BaseRequest {

  getMasterAccount (params) {
    const url = '/mam/master';
    return this.get(url, params);
  }

  getFollowers (params) {
    const url = '/mam/master/followers';
    return this.get(url, params);
  }

  getCommission (params) {
    const url = '/mam/master/commission';
    return this.get(url, params);
  }

  createMasterAccount (params) {
    const url = '/mam/master/create';
    return this.post(url, params);
  }

  closeMasterAccount (params) {
    const url = '/mam/master/close';
    return this.post(url, params);
  }

  getMasterRequest (params) {
    const url = '/mam/master/request';
    return this.get(url, params);
  }

  updateNextPerformanceFee (params) {
    const url = '/mam/master/update-next-performance-fee';
    return this.post(url, params);
  }

  getMasterOverview (params) {
    const url = '/mam/master/overview';
    return this.get(url, params);
  }


  getInvestments (params) {
    const url = '/mam/follower/investments';
    return this.get(url, params);
  }

  createJoiningRequest (params) {
    const url = '/mam/follower/create-joining-request';
    return this.post(url, params);
  }

  createAssignRevokeRequest (params) {
    const url = '/mam/follower/create-assign-revoke-request';
    return this.post(url, params);
  }

  getFollowerRequest (params) {
    const url = '/mam/follower/request';
    return this.get(url, params);
  }

  getDetailFollower (params) {
    const url = '/mam/follower/detail-follower';
    return this.get(url, params);
  }

  getFollowerOverview (params) {
    const url = '/mam/follower/overview';
    return this.get(url, params);
  }


  updateRequest (params) {
    const url = '/mam/update-request';
    return this.post(url, params);
  }

  getTransferHistory (params) {
    const url = '/mam/transfer-history';
    return this.get(url, params);
  }
}
