<template>
  <div id="open-history" class="page_history position-relative" :class="{ no_padding: options && options.isNoPadding, trade_layout_order_history: openOrderLayout === 'trade', order_layout_history: openOrderLayout !== 'trade'  }">
    <modal-sub/>
    <div class="wrap-group-control">
      <div class="group-control d-flex align-items-baseline" v-if="!options">
        <date-tab @change-select-date="changeDate" ref="selectDateTab"/><span  v-if="openOrderLayout === 'trade'" class="time-picker">{{ $t('order.history.time') }}</span>
        <div  class="group-date d-flex align-items-center">
          <!--          <span  class="date-title">{{ $t('funds.history.from') }}</span>-->
          <datepicker
              :value="dateFromDefault"
              class="date-from" @selected="selectDateFrom" :format="customFormatter" input-class="date-picker-input" :language="lang"></datepicker>
          <div class="to-title">{{ $t('order.history.time-to') }}</div>
          <datepicker
              :value="dateToDefault"
              class="date-to" @selected="selectDateTo" :format="customFormatter" input-class="date-picker-input" :language="lang"></datepicker>

        </div>

        <!--      <date-range-picker-->
        <!--          v-if="openOrderLayout === 'trade'"-->
        <!--          class="ml-1"-->
        <!--          ref="picker"-->
        <!--          :opens="opens"-->
        <!--          :locale-data="{ firstDay: 1, format: 'yyyy-MM-dd' }"-->
        <!--          :singleDatePicker="singleDatePicker"-->
        <!--          :timePicker="timePicker"-->
        <!--          :timePicker24Hour="timePicker24Hour"-->
        <!--          :showWeekNumbers="showWeekNumbers"-->
        <!--          :showDropdowns="showDropdowns"-->
        <!--          :autoApply="autoApply"-->
        <!--          v-model="dateRange"-->
        <!--          :ranges="show_ranges ? undefined : false"-->
        <!--          :linkedCalendars="linkedCalendars"-->
        <!--          :always-show-calendars="true"-->
        <!--          :alwaysShowCalendars="alwaysShowCalendars"-->
        <!--          :append-to-body="appendToBody"-->
        <!--          :closeOnEsc="closeOnEsc"-->
        <!--          @update="selectDateRange"-->
        <!--      >-->
        <!--        <template #input="picker">-->
        <!--          <span class="range-from">{{ picker.startDate | dateFormatSupport }}</span>-->
        <!--          <span>{{ $t('order.history.time-to') }}</span>-->
        <!--          <span class="range-to">{{ picker.endDate | dateFormatSupport }}</span>-->
        <!--          <img v-if="userThemeMode === 'light-theme'" class="p-1"  src="@/assets/images/icon/callendar-range-light.svg" alt="icon-callendar-range"/>-->
        <!--          <img v-else class="p-1" src="@/assets/images/icon/callendar-range-dark.svg" alt="icon-callendar-range"/>-->


        <!--        </template>-->
        <!--      </date-range-picker>-->
        <button class="button-search" @click="actionSearch">{{$t('order.order_history.search')}}</button>
        <button class="button-reset" @click="actionReset">{{$t('button.reset')}}</button>

      </div>

      <div id="is_single_page_order_history" class="group-control" v-if="options" :class="{ is_single_page: options && options.isNoPadding }">
        <div class="group-date-picker">
          <div class="label-text">{{ $t('order.order_history.date_from') }}:</div>
          <div class="group-date from-date">
            <datepicker
                :value="dateFromDefault"
                class="date" @selected="selectDateFrom" :format="customFormatter" input-class="date-picker-input"
                :language="lang">

            </datepicker>

          </div>
        </div>

        <div class="group-date-picker">
          <div class="label-text">{{ $t('order.order_history.date_to') }}:</div>
          <div class="group-date to-date">
            <datepicker
                :value="dateToDefault"
                class="date" @selected="selectDateTo" :format="customFormatter" input-class="date-picker-input" :language="lang"></datepicker>
          </div>
        </div>

        <div class="group-pair">
          <div class="label-text">{{ $t('order.order_history.pair') }}:</div>
          <div class="content-search">
            <div class="toolbar-element">
              <div class="coin-input" v-click-outside="clickOut">

                <input type="text" :placeholder="$t('order.order_history.coin')"
                       v-model="filters.coin"  @click="showCoinOption()" @keyup="onSearchCoin()" @keyup.enter="actionSearch()"/>
                <div class="box-coin-option" v-if="isShowCoinOption">
                  <div class="coin-option">
                    <span v-if="coins.length == 0" class="no_result"> {{$t('convert.noresult')}}.</span>
                    <span class="option" v-for="(coin, i) in coins" :key="`coin - ${i}`" @click="onSelectCoin(coin)" :class="{ 'active_coin_select' : filters.coin.toLowerCase() === coin}">
                    <img v-if="coin !== $t('order.order_form.all')" :src="`${coinImages[coin]}`" alt="" width="30" height="30" class="coin-img"/>
                    {{ coin }}</span>
                  </div>
                </div>
              </div>
            </div>
            <span class="bw-element">/</span>
            <div class="toolbar-element2" >
              <select-box
                  v-model="filters.currency"
                  :placeholder="$t('order.order_history.all')"
                  :options="getMarkets()"
              />
            </div>
          </div>
        </div>

        <div class="group-type">
          <div class="label-text">{{ $t('order.order_history.side') }}:</div>
          <div class="content-search search-type side-layout-order">
            <select-box
                v-model="filters.tradeType"
                :placeholder="$t('order.order_history.all')"
                :options="sideOptions"
            />
            <div class="button-side-layout-order">
              <button class="button-search" @click="actionSearch">{{$t('orders.trade_history.search')}}</button>
              <button class="button-reset" @click="actionReset">{{$t('orders.trade_history.reset')}}</button>
            </div>
          </div>
        </div>
        <div class="export-file">
          <a class="excel_export f-fr mart3" @click="downloadReport()" @mouseover="mouseOver" @mouseout="mouseOver">
            <img src="@/assets/images/icon/icon-export-history.svg" alt="" class="icon-export"/>
            {{ $t('order.order_history.export_complete_order_history') }}
          </a>
          <div v-show="isShowToolTip" class="export-file-tooltip">{{ $t('order.order_history.export_complete_order_history_tooltip') }}</div>
        </div>
      </div>
    </div>

    <data-table3 ref="datatable" :getData="getData"
     :tableOrderHistory="tableOrderHistory" 
     :typeEmpty="openOrderLayout !== 'trade' && !typeEmpty"
     :msg-empty-data="$t('order.order_history.no_data')" class="datatable" id="order-table" :limit="6">
      <template slot-scope="props">
        <th v-for="item in headerTable" :key="item.id" :title="$t(item && item.name)" :data-sort-field="item && item.id !== 'triggerConditions' ? item.id : null" @click="props.echoclick" :class="{trigger: item.id === 'triggerConditions'}">
          <span>{{ $t(item.name) }}</span>
          <i v-if="item.id !== 'triggerConditions'" class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
        <th :title="$t('order.order_history.status')" data-sort-field="status" @click="props.echoclick">
          <span ><i v-html="props.innerHtmlSort" class="group-sort-tabl3"></i></span>
          <span>{{$t('order.order_history.status')}}</span>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
      </template>
      <template slot="body" slot-scope="props">
        <tr :class="{canceled: _.get(props.item,'status') === 'canceled'}">
          <td class="cl_date" :title="_.get(props.item,'updated_at') | timestampToDate">{{_.get(props.item,"updated_at") | timestampToDate}}</td>
          
          <td class="cl_pair" :title="`${_.get(props.item,'coin')}/${_.get(props.item,'currency')}` | uppercase">
            <img :src="`${coinImages[props?.item?.coin]}`" alt="" width="30" height="30" class="coin-img"/>
            <img :src="`${currencyCoinsImages[props?.item?.currency]}`" alt="" width="30" height="30" class="currency-img"/>
            {{ _.get(props?.item,'coin') |uppercase}}/{{ _.get(props?.item,'currency') | uppercase }}
          </td>
          <td class="cl_type" :title="_.get(props.item,'type') | uppercaseFirst">{{ localizeType(_.get(props.item,'type')) | uppercaseFirst }}</td>
          <td class="status" :class="{'blue' : _.get(props.item,'trade_type') === 'buy', 'red' : _.get(props.item,'trade_type') === 'sell'}" :title="_.get(props.item,'trade_type')  | tradeType">
            {{ _.get(props.item,'trade_type')  | tradeType }}
          </td>
          <td :title="_.get(props.item,'executed_price') | formatNumberAndDecimal">
            {{ _.get(props.item,'executed_price')| formatNumberAndDecimal}}
          </td>
          <!-- <td :title="_.get(props.item,'price') | formatCurrencyAmount(_.get(props.item,'currency'), '0')">
            <span v-if="_.get(props.item,'type') == 'market' || _.get(props.item,'type') == 'stop_market'"
            :title="$t('order.order_history.market')">{{ $t('order.order_history.market') }}</span>
            <span v-else :title="_.get(props.item,'price') | formatCurrencyAmount(_.get(props.item,'currency'), '0')">
              {{ _.get(props.item,'price') | formatCurrencyAmount(_.get(props.item,'currency'), '0')}}</span>
          </td> -->

          <!-- td column market -->
          <td v-if="_.get(props.item,'type') == 'market' || _.get(props.item,'type') == 'stop_market'" :title="$t('order.order_history.market')">
            {{ $t('order.order_history.market') }}
          </td>
          <td v-else :title="_.get(props.item,'price') | formatNumberAndDecimal">
            {{ _.get(props.item,'price') | formatNumberAndDecimal}}
          </td>
          <!-- td column market -->

          <td :title="_.get(props.item,'executed_quantity') | formatNumberAndDecimal">
            {{ _.get(props.item,'executed_quantity') | toNumber | formatNumberAndDecimal}}
          </td>
          <td :title="_.get(props.item,'quantity') | toNumber | formatNumberAndDecimal">
            {{ _.get(props.item,'quantity') | toNumber | formatNumberAndDecimal}}
          </td>
          <td :title="_.get(props.item,'executed_price') | mulBigNumber(_.get(props.item,'executed_quantity')) | formatNumberAndDecimal">
            {{ _.get(props.item,'executed_price') | mulBigNumber(_.get(props.item,'executed_quantity')) | toNumber | formatNumberAndDecimal}}
          </td>
          <td>{{ _.get(props.item,'stop_condition') | convertConditionStatus }} {{ _.get(props.item,'base_price') | formatNumberAndDecimal }}</td>
          <td class="t-right2" :class="{'blue' : props.item.custom_status == 'filled', 'red' : props.item.custom_status === 'canceled'}">{{ props.item | order_status }}</td>
          <!-- <td class="t-right" v-if="isSpotExchangePage">
            <buttpn class="btn btn-subs" @click="showModalSub">subs</buttpn>
          </td> -->
        </tr>
      </template>
    </data-table3>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import DateTab from '../common/DateTab.vue';
import OPEN_ORDER_HEADER_CONST from './DataTableInit.js';
import COMMON_CONST from '@/common/Const';
import rf from '@/request/RequestFactory'
import moment from 'moment';
import MasterdataUtils from '@/common/MasterdataUtils';
import Utils from '@/common/Utils'
import SelectBox from '@/components/shared_components/common/SelectBox';
import ModalSub from '@/components/shared_components/common/ModalSub';
import {mapGetters, mapState} from 'vuex';
import Cookies from 'js-cookie'
import ETHIcon from "@/assets/images/ethereum-eth-logo.png"

export default {
    components: {
      DateTab,
      Datepicker,
      SelectBox,
      ModalSub,
      DateRangePicker
    },
    props: {
      options: {
        type: Object,
        default: null
      },
      coinCode: {
        type: String,
        default: COMMON_CONST.DEFAULT_COIN
      },
      currencyCode: {
        type: String,
        default: COMMON_CONST.DEFAULT_CURRENCY
      },
      tableOrderHistory: {
        type: Boolean,
        default: false,
      },
      openOrderLayout: {
        type: 'trade' | 'order',
        default: 'order'
      },
      typeEmpty: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        opens: 'right',
        dateRange: {
          startDate: moment().subtract(6, 'days').toDate(),
          endDate: moment().toDate(),
        },
        single_range_picker: false,
        show_ranges: false,
        singleDatePicker: false,
        timePicker: false,
        timePicker24Hour: false,
        showDropdowns: false,
        autoApply: true,
        showWeekNumbers: false,
        linkedCalendars: false,
        alwaysShowCalendars: true,
        appendToBody: false,
        closeOnEsc: false,
        headerTable: OPEN_ORDER_HEADER_CONST.ORDER_HISTORY_HEADER,
        commonConst: COMMON_CONST,
        sideOptions: [],
        dateFromDefault: moment().subtract(6, 'days').toDate(),
        dateToDefault: moment().toDate(),
        dateFrom: moment().subtract(6, 'days').startOf('day').format('x'),
        dateTo: moment().endOf('day').format('x'),
        coins: [],
        coinMasters: [],
        isShowCoinOption: false,
        filters: {
          coin: '',
          currency: '',
          tradeType: ''
        },
        durationDateFrom: null,
        isHide: false,
        isShowToolTip: false,
        coin: this.coinCode,
        currency: this.currencyCode,
        fetchingOrderHistory: false,
        coinNames: {},
        coinImages: {},
        currencyCoinNames:{},
        currencyCoinsImages:{},
        theme: Cookies.get("user-theme", { domain: process.env.VUE_APP_PUBLIC_URL }) || 'dark-theme',
        ethicon: ETHIcon,
        lang: Cookies.get('locale', { domain: process.env.VUE_APP_PUBLIC_URL }) || 'en',
        isMobileScreen: window.matchMedia('(max-width: 1250px)').matches

      }
    },
    computed: {
      ...mapState({
        masterData: state => state.masterdata,
        userThemeMode: (state) => state.userThemeMode,
      }),
      ...mapGetters(['user']),
      isSpotExchangePage() {
        return this.$route.path === '/spot-exchange/basic';
      },
    },
    watch: {
      userThemeMode(theme) {
        this.theme = theme;
      },
    },
    methods: {
      // getSocketEventHandlers() {
      //   return {
      //     OrderTransactionCreated: this.onSocketUpdated,
      //   }
      // },
      onSocketUpdated(data) {
        const myTransaction = data.orderTransaction;
        console.log(data)
        if (myTransaction.buyer_id === this.user.id || myTransaction.seller_id === this.user.id) {
          this.actionSearch();
        }
      },
      localizeType(type) {
        if(type == 'limit') return this.$t('order.order_form.limit');
        if(type == 'market') return this.$t('orders.open_order.market');
        if(type == 'stop_market') return this.$t('order.order_form.stop_market_table');
        if(type == 'stop_limit') return this.$t('order.order_form.stop_limit_table');
      },
      getEventHandlers() {
        return {
          SpotSelectedPairMarket: this.onSpotSelectedPairMarket,
          SpotOrderListUpdated: this.onOrderListUpdated,
        }
      },
      onSpotSelectedPairMarket(pair) {
        this.coin = pair.coin;
        this.currency = pair.currency;
      },
      onOrderListUpdated(data, isHideOtherPair) {
        if (data.action === 'created') {
          return;
        }
        if (!this.fetchingOrderHistory) {
          this.fetchingOrderHistory = true;
          this.getData()
            .then((res) => {
                if (isHideOtherPair) {
                    this.$refs.datatable.filter({ coin: this.coin, currency: this.currency });
                } else {
                    this.$refs.datatable.refresh();
                }
            });
        }
      },
      changeDate (currentDateTab) {
        switch(currentDateTab.id) {
          case this.commonConst.ONE_DAY:
              this.durationDateFrom = moment().startOf('day').format('x');
              break;
          case this.commonConst.ONE_WEEK:
              this.durationDateFrom = moment().subtract(6, 'days').startOf('day').format('x');
              break;
          case this.commonConst.ONE_MONTH:
              this.durationDateFrom = moment().subtract(1, 'months').add('days', 1).startOf('day').format('x');
              break;
          default:
              this.durationDateFrom = moment().subtract(3, 'months').add('days', 1).startOf('day').format('x');
        }
        const params = {
          start_date: this.durationDateFrom,
          end_date: moment().endOf('day').format('x'),
        }
        this.$refs.datatable.filter(params);
      },
      getData (params) {
        if (!isAuthenticated) {
          return new Promise((resolve) => {
            return resolve({});
          });;
        }
        // const el = document.getElementById('is_single_page_order_history');
        // if (el) {
        //   el.scrollIntoView({behavior: "smooth"});
        // }
        // }
        if (params?.hide) {
          this.isHide = params.hide === 'hide';
        }
        const dateRange = this.getDefaultDateRange();
        params = Object.assign({}, dateRange, params);
        if (this.isHide) {
          params.coin = this.coinCode;
          params.currency = this.currencyCode;
        }
        return rf.getRequest('OrderRequest').getOrderHistory(params)
            .then(res => {
              this.fetchingOrderHistory = false;
              return res;
            });
      },
      getDefaultDateRange() {
        return {
          start_date: this.durationDateFrom || moment().subtract(6, 'days').format('x'),
          end_date: moment().endOf('day').format('x')
        };
      },
      showModalSub() {
        window.CommonModal.show('ModalSub');
      },
      showError(message) {
        Message.error(message, {}, { position: "bottom_left" });
      },
      actionSearch () {
        if(this.dateFrom > this.dateTo) {
          this.showError(this.$t("common.date.warning"));
          return ;
        }
        this.$refs.datatable.filter(this.filterParams());
        if (!this.options) {
          this.$refs.selectDateTab.resetSelectDate();
        }
      },
      filterParams () {
        const filterParams = {
          start_date: this.dateFrom,
          end_date: this.dateTo
        };
        if (this.filters.coin && this.filters.coin !== 'All') {
          filterParams.coin = this.filters.coin;
        }
        if (this.filters.currency && this.filters.currency !== 'All') {
          filterParams.currency = this.filters.currency;
        }
        if (this.filters.tradeType && this.filters.tradeType !== 'all') {
          filterParams.trade_type = this.filters.tradeType;
        }
        return filterParams;
      },
      actionReset () {
        this.filters.coin = '';
        this.filters.currency = '';
        this.filters.tradeType = '';
        this.dateFromDefault = moment().subtract(6, 'days').toDate();
        this.dateToDefault = moment().toDate();
        this.dateRange = {
          startDate: moment().subtract(6, 'days').toDate(),
          endDate: moment().toDate(),
        },
        this.dateFrom = moment().subtract(6, 'days').startOf('day').format('x');
        this.dateTo = moment().endOf('day').format('x');
        // this.$refs.datatable.refresh();
        $(".datatable").find("th").attr("data-sort-order", "");
        this.actionSearch();
      },
      selectDateFrom (dateFrom) {
        this.dateFrom = moment(dateFrom).startOf('day').format('x');
      },
      selectDateTo (dateTo) {
        this.dateTo = moment(dateTo).endOf('day').format('x');
      },
      async selectDateRange (date) {
        this.dateFrom = moment(date.startDate).valueOf();
        this.dateFrom = moment(date.startDate).startOf('day').format('x');
        this.dateTo = moment(date.endDate).endOf('day').format('x');
        if(date) {
          this.disableToDate = {
            from : new Date(date.endDate)
          }
        }
      },
      customFormatter(date) {
        return moment(date).format('YYYY-MM-DD');
      },
      requestCoinList() {
        MasterdataUtils.getCoins((coins) => {
          this.coinMasters = coins.filter((item) => item.toLowerCase() !== 'ltc');
          this.coinMasters.unshift(this.$t('order.order_form.all'));
        });
      
      },
      onSelectCoin(coin) {
        this.isShowCoinOption = false;
        this.filters.coin = coin.toUpperCase();
        if ( coin === "All" ) {
          this.filters.coin = coin;
        }
      },
      onSearchCoin () {
        this.coins = _.filter(this.coinMasters, (item) => {
          return _.isMatch(item.toUpperCase(), this.filters.coin.toUpperCase());
        });
      },
      clickOut() {
        this.isShowCoinOption = false;
      },
      showCoinOption() {
        this.isShowCoinOption = true;
        this.coins = this.coinMasters;
      
      },
      downloadReport() {
        const params = {
          start_date        : this.dateFrom,
          end_date          : this.dateTo,
          timezone_offset   : Utils.getTimzoneOffset(),
          coin: this.filters.coin.length == 0 ? undefined : this.filters.coin,
          currency: this.filters.currency.length == 0 ? undefined : this.filters.currency,
          trade_type: this.filters.tradeType.length == 0 ? undefined : this.filters.tradeType
        };
        rf.getRequest('OrderRequest')
          .downloadTransactions(params, 'OrderHistory.csv');
      },
      mouseOver () {
        this.isShowToolTip = !this.isShowToolTip;
      },

      getMarkets() {
        return window._.map(COMMON_CONST.MARKETS_2, item => {
          return { 
            id: item.name, 
            name: this.$t(item.name), 
            icon: item.icon 
          };
        });
      },
      initSideOptions() {
        this.sideOptions = this.commonConst.SELECT_SIDE.map((option) => {
          return {
            id: option.id,
            name: this.$t(option.name),
          };
        });
      },
      handleMasterData() {
        this.currencyCoins = this.masterData.coin_settings; 
        this.coinSetting = window._.keyBy(this.masterData.coins_confirmation, item => item.coin);
        this.coinsMasterData = window._.keyBy(this.masterData.coins, item => item.coin);
        this.coinsMasterData.usd = { name: window.i18n.t(`currency.usd.fullname`) };

        _.forEach(this.masterData.coins, (item, key) => {
            this.coinNames[item.coin] = item.name;
            this.coinImages[item.coin] = item.icon_image;
            this.currencyCoinNames[item.coin]=item.name;
            this.currencyCoinsImages[item.coin]=item.icon_image;
        });

        this.coinNames['usd'] = window.i18n.t(`currency.usd.fullname`);
        this.coinImages['usd'] = `/images/color_coins/usd.png`;
        this.coinImages["eth"] = this.ethicon
        this.currencyCoinNames['usd'] = window.i18n.t(`currency.usd.fullname`);
        this.currencyCoinsImages['usd'] = `/images/color_coins/usd.png`;
        this.currencyCoinsImages["eth"] = this.ethicon

      },
      updateFixedDivPositionCallendar() {
        if (this.openOrderLayout !== 'trade') {
          return;
        }
        const referenceDiv = document.querySelector(".date-from");
        const referenceDiv2 = document.querySelector(".date-to");
        const fixedDiv = document.querySelector(".date-from .vdp-datepicker__calendar");
        const fixedDiv2 = document.querySelector(".date-to .vdp-datepicker__calendar");
        // Get the position and size of the referenceDiv
        const rect = referenceDiv.getBoundingClientRect();
        const rect2 = referenceDiv2.getBoundingClientRect();

        // Set the position of the fixedDiv based on the referenceDiv
        fixedDiv.style.top = `${60}px`;
        fixedDiv.style.left = `${rect.left}px`;
        fixedDiv2.style.top = `${60}px`;
        fixedDiv2.style.left = `${rect2.left}px`;
        if (this.isMobileScreen) {
          fixedDiv.style.left = `60%`;
          fixedDiv2.style.left = `65%`;
        }
       },
      handleWindowResize() {
        this.checkMobileScreen();
        this.updateFixedDivPositionCallendar();
      },
      checkMobileScreen (){
        const isMobile = window.matchMedia('(max-width: 1250px)').matches
        this.isMobileScreen = isMobile
      }
    },
  
    mounted() {
      this.requestCoinList();
      this.initSideOptions();
      this.$on('UPDATED_LOCALE', () => {
        this.initSideOptions();
      });
      this.handleMasterData();
      this.updateFixedDivPositionCallendar();
      window.addEventListener('resize', this.handleWindowResize)
    }
  }
</script>
<style lang="scss" scoped>
  @import "@/assets/sass/variables";
  .order_layout_history {
    ::v-deep {
      .VuePagination {
        padding-top: 0;
      }
    }
  }
  .dark-theme {
    .vue-daterange-picker {
      ::v-deep {
        .reportrange-text {
          background: $color-gunmetal!important;

          .range-from, .range-to {
            color: $color-storm-grey!important;
          }
          span {
            color: $color-gray-mako!important;
          }
        }
      }
    }
    
    #open-history .group-control .time-picker {
      color : $color-auro-metal;
    }

    .trade_layout_order_history {
      ::v-deep {
        .tableContainer3 {
          background: transparent !important;

          table {
            background: transparent !important;
          }
        }
      }
    }


    #open-history {
      ::v-deep {
        .tableContainer3 {
          //min-height: 500px;

          table {
            margin-bottom: 0!important;
            thead {
              th {
                color: $color-storm-grey;
              }
            }

            tbody {
              tr {
                td {
                  color: white!important;
                }
                .red {
                  color: $color-denim!important;
                }
                .blue {
                  color: $text-color-jade!important;
                }
              }
            }
          }
        }
      }

      ::-webkit-scrollbar-thumb {
        background-color: $color-storm-grey;
      }
    }
    
  }

  .btn-subs {
    width: 55px;
    height: 25px;
    border-radius: 3px;
    border: solid 1px $color-jungle-green;
    color: $color-jungle-green;
    letter-spacing: 0.9px;
    font-size: $font-smaller;
    line-height: 20px;
    padding: 2px;
    overflow: hidden;
    text-transform: uppercase;
    text-align: center;
    &:hover, &:active, &:focus {
      background-color: $color-blue-custom;
      border-color: $color-blue-custom;
      color: $color-white;
    }
  }
  #open-history {
    //overflow-x: auto;
    ::-webkit-scrollbar-thumb {
      background-color: $color-gray-alto;
    }
    .group-control{
      // padding: 9px 20px 9px 20px;
      font-size: $font-small;
      &:after{
        display: block;
        clear: both;
        content: "";
      }
      #date-tab{
        float: left;
      }
      .group-date{
        display: inline-block;
        margin-left: 36px;
        //position: relative;
        float: left;
        .date-title{
          margin-right: 5px;
        }
        .vdp-datepicker{
          display: inline-block;
          position: unset;
        }
        //.date-to{
        //  margin-left: -5px;
        //}

        .to-title {
          background-color: var(--background-callendar-spot) !important;
          color: var(--color-to-title) !important;
          height: 35px;
          width: fit-content;
          display: flex;
          align-items: center;
        }
      }
      .vue-daterange-picker {
        ::v-deep {
          .reportrange-text {
            background: $color-alabaster;
            border-radius: 8px;
            border:none;
            box-shadow: none;
            display:flex;
            align-items: center;
            font-family: $font-inter-regular;
            font-size: $font-medium-bigger;

            .range-from {
              color: $color-gray-dark;
              margin-right: 4px;
              width: 95px;
            }
            .range-to {
              color: $color-gray-dark;
              margin-left: 4px;
              width: 95px;
            }

            .icon-calendar {
              margin-left: 8px;
            }

            span {
              color: $color-grey-dark;
            }
          }

          .daterangepicker {
            
            td.active {
              background-color: $color-aqua-green;
              border-color: transparent;
              color: white;
              border-radius: 50%;
            }
            td.off {
              background-color: #fff!important;
              border-color: transparent!important;
              color: #999!important;
            }
          }
        }
      }
      .time-picker {
        font-size: $font-root;
        color: $color-gray-dark;
        margin-left: 30px;
        margin-right: 8px;
      }
      .button-reset {
        width: 63px;
        height: 35px;
        margin-left: 0px;
        margin-right: 0px;
        color: var(--text-close);
        box-shadow: none;
        font-family: $font-inter-regular;
        font-size: 14px;
        float: left;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        letter-spacing: 0.9px;
        border-radius: 8px !important;
        background-color: var(--text-search);
        &:hover {
            background:$color-gray-dark ;
            border-color: $color-gray-dark ;
            color: $color-white;
            -webkit-transition: 0.5s;
            transition: 0.5s;
          }
      }
      &.is_single_page{
        display: block;
        width: 100%;
        position: relative;
        // max-width: 1318px;
        font-family: $font-inter-regular;
        &:after{
          display: block;
          clear: both;
          content: "";
        }
        .label-text{
          color: var(--text-primary-color);
          font-size: $font-big;
          line-height: 25px;
          margin-bottom: 8px;
        }
        .button-search{
          margin-left: 25px;
          margin-right: 10px;
          background-color: $color-caribbean-green;
          color: $color-grey-dark;
          box-shadow: none;
          font-size: 18px;
          border: 1px solid $color-caribbean-green;
          border-radius: 8px !important;
          width: 100px !important;
          height: 49px ;
          float: left;
          -webkit-transition: 0.5s;
          transition: 0.5s;
          letter-spacing: 0.9px;
         
          &:hover, &:active{
            background: $color-aquamarine ;
            border-color: $color-aquamarine ;
            color: $color-grey-dark;
            -webkit-transition: 0.5s;
            transition: 0.5s;
          }
          &:disabled {
            background: $color-aero-blue ;
            border-color: $color-aero-blue ;
            color: $color-gray-dark;
          }
        }

        .group-date-picker{
          display: inline-block;
          float: left;
          margin-right: 20px;
          .group-date{
            display: inline-block;
            margin-left: 0;
            position: relative;
            .vdp-datepicker{
              display: inline-block;
              float: left;
            }
            .date-to{
              margin-left: 0;
            }
          }
        }
        .group-pair{
          display: inline-block;
          margin-right: 20px;
          float: left;
          input{
            background-color: var(--color-tab-data-table) !important;
            height: 49px ;
            float: left;
            border: 0px ;
            padding: 0px 5px 0px 14px;
            margin-right: 1px;
            font-size: $font-root;
            color: var(--text-primary-color);
            font-size: 18px;
            border-radius: 8px !important;
            &:focus {
              border: 1px solid $color-jungle-green;
            }

            &::placeholder{
              color: var(--text-search);
              font-size: 18px;
            }
          }
          .coin-all{
            //text-transform: capitalize;
          }
          .sc_search_select{
            /* background-color: $color-catskill-white; */
            height: 30px;
          }
          .bw-element {
            float: left;
            font-size: 15px;
            margin-left: 5px;
            margin-top: 9px;
            height: 30px;
            line-height: 30px;
            margin-right: 5px;
            color: var(--text-close);
          }
        }
        .group-type{
          display: inline-block;
          margin-right: 20px;
          float: left;
          .sc_search_select{
            height: 30px;
            width: 95px;
            display: inline-block;
            float: left;
          }

          button{
            line-height: 20px;
            height: 49px;
            padding: 6px 16px;
            width: auto;
            min-width: 85px;
          }
        }
        .export-file{
          display: inline-block;
          float: right;
          margin-top: 50px;
          line-height: 20px;
          font-size:$font-medium-bigger;
          position: relative;
          font-family: $font-inter-regular;
          a {
            color: $color-caribbean-green;
            font-size: $font-medium-bigger;
            .icon-export{
              margin-right: 8px;
              margin-top: -3px;
            }
          }
          
          .export-file-tooltip {
            display: none;
            position: absolute;
            top: 150%;
            margin-bottom: 5px;
            right: 0;
            width: 220px;
            line-height: 20px;
            background-color: $bgr-tooltip;
            padding: 10px;
            border-radius: 8px;
            color: $color-white;
            font-size: 14px;
            line-height: 18px;
            z-index: 10;
          }
          &:hover {
            .export-file-tooltip {
              display: block;
            }
          }
        }
        .button-reset {
          margin-left: 0px;
          margin-right: 0px;
          background-color: var(--text-search);
          color: var(--color-neutral-dark);
          box-shadow: none;
          font-family: $font-inter-regular;
          border: 1px solid var(--text-search);
          border-radius: 3px;
          width: 100px !important;
          height: 49px ;
          font-size: $font-smaller;
          float: left;
          -webkit-transition: 0.5s;
          transition: 0.5s;
            letter-spacing: 0.9px;
          border-radius: 8px !important;
          font-size: 18px !important;
          &:hover, &:active{
            background:$color-gray-dark ;
            border-color: $color-gray-dark ;
            color: $color-white;
            -webkit-transition: 0.5s;
            transition: 0.5s;
          }
        }
        .toolbar-element {
          background-color: none;
          margin-right: 0px;
          display: inline-block;
          float: left;
          width: 95px;
          input {
            /* border: 1px solid $color-alto; */
            border-radius: 3px;
            line-height: 20px;
            padding-top: 5px;
            padding-bottom: 5px;
            height: 49px;
            width: 100%;
            &:focus {
              border: 1px solid $color-jungle-green;
            }

          }
        }
        .toolbar-element2 {
          /* background-color: $color-catskill-white; */
          margin-right: 0px;
          display: inline-block;
          float: left;
          width: 95px;
        }
        .coin-input {
          position: relative;
          float: left;
          .active_coin_select {
                color: $color-caribbean-green !important;
              }
          .box-coin-option {
            left: 0;
            position: absolute;
            top: 100%;
            // width: 116px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -ms-flex-negative: 1;
            flex-shrink: 1;
            margin-top: 6px;
            z-index: 999;
            background: var(--bgr-dropdown);
            box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
            border-radius: 8px;
            
            .coin-option {
              position: relative;
              padding: 15px 0px;
              max-height: 300px;
              overflow-y: auto;
              .option {
                display: flex;
                gap: 8px;
                position: relative;
                white-space: nowrap;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                width: 100%;
                padding: 6px 8.15px 6px 20px;
                margin: 0;
                line-height: 21px;
                min-height: 25px;
                font-size: 18px;
                color: var(--color-dropdown);
                font-weight: normal;
                min-width: 100%;
                cursor: pointer;
                align-items: center;
                text-transform: uppercase;
                img {
                  object-fit: contain;
                }
                &:hover {
                  color: $color-caribbean-green;
                 
                }
                &:first-child{
                  font-weight: 400; 
                  font-size: 18px;
                  line-height: 17px;
                  //text-transform: capitalize;
                  color: var(--text-dropdown);
                  &:hover {
                    color: $color-caribbean-green !important;
                  }
                  .select-name{
                    color: $color-caribbean-green !important;
                  }
                }
              }
              .no_result {
                  font-size: 14px;
                  line-height: 17px;
                  text-transform: capitalize;
                  color: var(--text-dropdown);
                  display: inline-block;
                  white-space: nowrap;
                  width: 100%;
                  padding: 0 10px;
              }
            }

          }
        }
        .excel_export {
          cursor: pointer;
          font-weight: 500;
        }
      }
    }

    #order-table.table-history-order{
      @media screen and (min-width: 1548px) {
        ::v-deep{
          table {
          //table-layout: fixed;
        }
        }
         
      }
      table{
        thead {
          th {
            &:last-child {
              min-width: 100px;
              padding-right: 24px;
            }
            &[data-sort-field="updated_at"] {
              width: 13%;
              min-width: 175px;
              }
              &[data-sort-field="coin"] {
                min-width: 140px;
                width: 11%;
                text-align: left;
              }
              &[data-sort-field="type"] {
                width: 6%;
                min-width: 85px;
              }
              &[data-sort-field="trade_type"] {
                width: 6%;
                min-width: 80px;
              }
              &[data-sort-field="executed_price"] {
                //width: 8.59%;
                min-width: 110px;
              }
              &[data-sort-field="price"] {
                //width: 6.95%;
                min-width: 89px;
              }
              &[data-sort-field="executed_quantity"] {
                //width: 5.78%;
                min-width: 89px;
              }
              &[data-sort-field="quantity"] {
                //width: 6.95%;
                min-width: 74px;
              }
              &[data-sort-field="total"] {
                //width: 7.81%;
                min-width: 100px;
              }
              &.trigger{
                //width: 10.86%;
                min-width: 139px;
              }
              &[data-sort-field="status"] {
                //width: 10.16%;
                min-width: 100px;
              }
          }
        }
        tbody{
          td{
            //font-size: 18px !important;
            padding: 20px 0;
            &:first-child {
              padding-right: 10px;
            }
            &:nth-child(11){
              padding-right: 24px;
            }
            @media only screen and (max-width: 992px) {
              font-size: 14px !important;
              padding: 17px 0;
            }
          }
        }
      }
    }
    #order-table {
  
      padding: 30px 0 0;
      .VuePagination {
        background-color: $color-alabaster;
      }
      table {
        border: 0px;
        thead {
          background-color: $color-white;
          border: 0px;
          tr {

            th {
              font-size: $font-small;
              font-family: $font-inter-regular;
              font-size: 14px;
              //padding: 8px 0px 8px 20px;
              background: var(--background-color-second);
              /* color: $color-gray-dark; */
              // height: 63px;
              th:nth-child(10) {
                width: 10%;
              }
              &:first-child{
                border-radius: 8px 0 0 8px;
              }
              &:last-child {
                padding-right: 40px;
                border-radius: 0 8px 8px 0px;
                text-align: right;
                span {
                  float: right;
                }
                &:after {
                  margin-left: 0px;
                  margin-right: 5px;
                }
              }
            }
          }
        } 
        tbody {
          text-align: left;
          tr {
            &.canceled {
              opacity: 1 !important;
            }
            height: auto;
            font-size: $font-small;
            .cl_date{
              //width: 7%;
              padding-left: 24px;
            }
            td:nth-child(2) {
               //width: 10%;
            }
            td:nth-child(4) {
              min-width: 65px;
            }
            td:nth-child(3) {
              min-width: 85px;
            }
            
            td:nth-child(5), td:nth-child(6), 
            td:nth-child(7), td:nth-child(8), td:nth-child(9), td:nth-child(13) {
              min-width: 100px;
            }
            td:nth-child(10) {
              width: 10%;
            }
            td:nth-child(11) {
              min-width: 120px;
              padding-right: 40px;
            }
           
            td {
              padding-right: 10px;
              font-size: $font-root;
              font-weight: 500;
              color: $color-grey-dark;
              font-family: $font-family-roboto-medium;
              line-height: 21px;
              //padding: 7px 0px 6px 20px;
              overflow: initial;
              vertical-align: middle;
              &.blue {
                color: $text-color-jade;
              }
              &.red {
                color: $text-color-red;
              }
              &:last-child {
                text-align: right;
              }
            }
          }
        }
      }
    }
  }


  .trade_layout_order_history {
    .wrap-group-control {
      overflow-x: auto;
      /* Hide scrollbar for IE, Edge and Firefox */
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }
      .group-control {
        min-width: 1500px;
      }
      ::v-deep {
        .vdp-datepicker__calendar {
          transform: translateX(-60%);
        }
      }

    }
  }

  ::v-deep {
    .tableContainer3 {
      .wrap-table {
        overflow-x: auto;
        //padding-bottom: 25px;

        //table {
        //  min-width: 1500px;
        //}

      }


    }
  }

  @media screen and (max-width: 992px) {
    #open-history .group-control.is_single_page .export-file {
      margin-top: 24px;
      width: 100%;
      a {
        font-size: $font-root;
      }
      .export-file-tooltip {
        width: 100%;
      }
    }

    .side-layout-order {
      .sc_search_select {
        width: 100%!important;
        height: auto!important;
      }
      .button-side-layout-order {
        width: 100%;
        margin-top: 24px;
        .button-search {
          margin-left: 0!important;
          margin-right: 8px!important;
          font-size: 14px !important;
          padding: 9px 20px!important;
        }
        .button-reset {
          font-size: 14px !important;
          padding: 9px 20px!important;
        }
      }
    }
  }
</style>


<style lang="scss">
  @import "@/assets/sass/variables";
  .dark-theme {
    #open-history .vdp-datepicker input{
      background-image: url('@/assets/images/icon/date-time-home-dark.svg') !important;
    }
    .vdp-datepicker__calendar{
      border: 1px solid $color-gray-charade !important;
      background: $color-gray-charade;
      }
    .trade_layout_order_history {
      .group-control {
        background: transparent!important;
      }

      // .tableContainer3 {
      //   min-height: 500px;
      //   background: $color-cinder!important;
      //   padding-bottom: 30px;
      //   table {
      //     background: $color-cinder!important;
      //     margin-bottom: 0!important;
      //     padding-bottom: 30px!important;
      //     thead {
      //       tr {
      //         th {
      //           color: $color-storm-grey!important;
      //         }

      //         th:first-child {
      //           width: 17.24%;
      //         }

      //         th:nth-child(2) {
      //           width: 12.32%;
      //         }

      //         th:nth-child(3) {
      //           width: 6.54%;
      //         }
      //         th:nth-child(4) {
      //           width: 6.16%;
      //         }
      //         th:nth-child(5) {
      //           width: 8.47%;
      //         }
      //         th:nth-child(6) {
      //           width: 9.24%;
      //         }
      //         th:nth-child(7) {
      //           width: 6.16%;
      //         }
      //         th:nth-child(8) {
      //           width: 6.16%;
      //         }
      //         th:nth-child(9) {
      //           width: 9.24%;
      //         }
      //         th:nth-child(10) {
      //           width: 10.78%;
      //         }
      //         th:nth-child(11) {
      //           width: 7.7%;
      //         }
      //         th[data-sort-field="status"] {
      //           padding-right: 24px;
      //         }
      //       }
      //     }

      //     tbody {
      //       tr {
      //         td {
      //           color: white!important;
      //         }
      //         .red {
      //           color: $text-color-red!important;
      //         }
      //         .blue {
      //           color: $text-color-jade!important;
      //         }
      //       }

      //       .empty-data {
      //         background: $color-cinder;
      //         .icon-no-data-1 {
      //           margin: 0 !important;
      //         }
      //       }
      //     }
      //   }
      // }
    }
  }

  

  .trade_layout_order_history {
    //min-width: 1201px;
    min-height: 450px;

    .tableContainer3 {
      padding-top: 0 !important;
      table {
        table-layout: fixed;
        thead {
          tr {
            height: auto!important;
            th {
              text-align: center;
              /* color: $color-gray-dark!important; */
              font-weight: 400;
              background: none !important;
            }

            th:not(:last-child) {
              padding-right: 0!important;
            }

            th:first-child {
              text-align: left;
              width: 15%!important;
              padding-left: 40px!important;
              min-width: 170px;
            }

            th:nth-child(2) {
              width: 10%!important;
              min-width: 100px;
            }

            th:nth-child(3) {
              width: 8%!important;
              min-width: 85px;
            }
            th:nth-child(4) {
              width: 8%!important;
              min-width: 75px;
            }
            th:nth-child(5) {
              width: 8%!important;
              min-width: 110px;
            }
            th:nth-child(6) {
              width: 8%!important;
              min-width: 110px;
            }
            th:nth-child(7) {
              width: 8%!important;
              min-width: 110px;
            }
            th:nth-child(8) {
              width: 8%!important;
              min-width: 110px;
            }
            th:nth-child(9) {
              width: 8%!important;
              min-width: 110px;
            }
            th:nth-child(10) {
              width: 11%!important;
              min-width: 145px;
              cursor: default;
            }
            th:nth-child(11) {
              width: 9%!important;
              min-width: 75px;
            }
          }
        }

        tbody {
          tr {
            .coin-img, .currency-img {
              display: none;
            }
            td {
              border-top: 16px solid transparent;
              padding-top: 0!important;
              padding-bottom: 0!important;

              &:first-child {
                padding-left: 40px!important;
                text-align: left!important;
              }
              &:nth-child(2) {
                text-align: center !important;
              }
            }

            .t-right {
              .image {
                img {
                  display: none;
                }

              }

              .cancel {
                display: block;
              }
            }
          }

        }
        tr.empty-data td {
          border-top: none;
        }
      }
    }

    .button-search {
      margin-left: 28px!important;
    }

    .group-date {
      .vdp-datepicker {
        input {
          padding-top: 0 !important;
        }
      }

      .date-from {
        input {
          height: 35px!important;
        }
      }

      .date-to {
        input {
          height: 35px!important;
        }
      }

    }
  }

  .table-history{
    background: none !important;
    table{
      background: transparent !important;

      .empty-data{
       td{
        padding: 80px 0 0 !important;
        @media screen and (max-width: 992px) {
          padding: 0!important; 
        }
       }
      }
    }
  }

  #open-history {

    .vdp-datepicker  {
      input {
        font-family: $font-inter-regular;
        font-size: $font-medium-bigger;
        background: $color-alabaster;
        height: 35px;
        cursor: pointer;
      }
    }

    .date-to {
      input {
        height: 35px;
        width: 140px!important;
        border: none!important;
        border-radius: 0px 8px 8px 0px!important;
        background-position: top 8px right 14px;
      }
    }
    .date-from {
      input {
        height: 35px;
        background-image: none!important;
        padding: 0 0 0 16px !important;
        border: none!important;
        border-radius: 8px 0px 0px 8px!important;
        width: 108px!important;
      }
    }

    #order-table {
      table {
        //margin-bottom: 20px;

        tbody{
          td{
            font-family: $font-inter-regular;
            color: $color-grey-dark;
            font-size: 14px !important;
            &:first-child {
              padding-left: 24px;
            }
          }
          .cl_date{
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
          }
          .cl_pair{
            font-size: 18px;
            position: relative;
            text-align: left;
            padding-left: 10px !important;
            img {
              object-fit: contain;
            }
              .currency-img{
                margin-left: -8px;
                object-fit: contain;
              }
              .coin-img {
                object-fit: contain;
              }
          }
          .red{
            color: $text-color-red;
          }
        }
      }
    }
    .is_single_page {

      .search-type{
        .sc_search_select{
            .group_search_select{
              .box_list_search_select{
               
                ul{
                  width: 95px !important;
                  left: 0 !important;
                  li{
                    text-transform: capitalize;
                  }
                }
              }
            }
        }
      }

      .sc_search_select {
        .group_search_select {
          .button_drop_search {
            background: transparent;
            padding:16px 10px !important;
            height: 49px !important;
            border: none !important;
            border-radius: 8px !important;
            &.active {
              border: 1px solid$color-caribbean-green ;
            }
          }
          .list_search_select{
            background: var(--bgr-dropdown);
            left: -21px !important;
            img {
              object-fit: contain;
              border-radius: 50%;
            }
          }

          .box_list_search_select {
            font-family: $font-inter-regular;
         
            ul {
              width: 127px !important;
              padding: 15px 0;
              li {
                text-transform: uppercase;
                padding: 6px 20px;
                span{
                  color: var(--color-dropdown);
        
                  img{
                    margin-right: 5px;

                  }
                }
                &:hover {
                  background: none !important;
                  span,
                  a {
                    color:  $color-caribbean-green !important;
                  }
                }

                &:first-child{
                  font-weight: 400; 
                  font-size: 14px;
                  line-height: 17px;
                  text-transform: capitalize !important;

                  span{
                    color: var(--text-dropdown);
                    &:hover {
                
                    color:  $color-caribbean-green !important;
                    &.select-name{
                      color: $color-caribbean-green !important;
                    }
                  }
                
                 }
                }
              }

            }
          }
        }
      }
      .from-date {
        .vdp-datepicker__calendar{
          right: auto;
          left: 0px;
        }
      }
      .vdp-datepicker__calendar{
        width: 310px;
        padding: 20px 30px;
        border-radius: 8px;
        box-shadow: 0px 4px 10px $color-shadow;
        border: 1px solid $color-white;
        header {
          span.up {
            //width: 55.428571%;
          }
        }
        .cell{
            padding: 0;
            color: var(--text-primary-color);
            font-size: 16px;
            height: fit-content;
            line-height: 36px;
            &.day-header{
              color: var(--text-close);
              font-size: 16px;
              line-height: 19px;
            }
            &.blank {
              color: #424754;
            }
            &.selected {
               color:$color-grey-dark;;
            }
          @media screen and (max-width: 992px) {
              font-size: $font-root;
              &.day-header{
                font-size: 14px;
              }
          }
          }
        header {
          span {
            color: var(--text-primary-color);
            font-size: 16px;
          }
        }
      }
      .to-date {
        .vdp-datepicker__calendar{
          right: 0;
          left: auto;
        }
      }
      .vdp-datepicker{
        input{
          padding: 5px 3px 5px 47px !important;
          background-position: top 11px left 14px;
          height: 49px !important;
          background-color: var(--color-tab-data-table)!important;
          margin-right: 0px !important;
          border: none !important;
          border-radius: 8px !important;
          color: var(--text-close) !important;
          font-size: 18px !important;
          font-family: $font-inter-regular;
          &:focus {
            border: 1px solid $color-jungle-green;
          }
        }

        @media screen and (max-width: 992px) {
          input{
            height: 36px !important;
            background-position: top 5px left 12px !important;
          }
        }
        
      }
    }
    .vdp-datepicker__calendar{
      width: 310px;
      padding: 20px 30px;
      border-radius: 8px;
      box-shadow: 0px 4px 10px $color-shadow;
      border: 1px solid $color-white;
      header {
        span.up {
          //width: 55.428571%;
        }
      }
      .cell{
        padding: 0;
        color: var(--text-primary-color);
        font-size: 16px;
        height: fit-content;
        line-height: 36px;
        &.day-header{
          color: var(--text-close);
          font-size: 16px;
          line-height: 19px;
        }
        &.blank {
          color: #424754;
        }
        &.selected {
          color:$color-grey-dark;;
        }
        @media screen and (max-width: 992px){
          font-size: $font-root;
          &.day-header {
            font-size: $font-root;
          }
        }
      }
      header {
        span {
          color: var(--text-primary-color);
          font-size: 16px;
          @media screen and (max-width: 992px){
            font-size: $font-root;
          }
        }

      }
    }
    .tableContainer3 {
      table {
        width: 100%;
        overflow-y: initial;
        margin-bottom: 22px;

        th{
          font-family: $font-inter-regular !important;

          &[data-sort-field="updated_at"] {
            width: 15%;
            min-width: 170px;
          }
          &[data-sort-field="coin"] {
            padding-left: 10px;
            width: 13%;
          }
          &[data-sort-field="quantity"] {
            width: 10%;

          }

        }

        thead {
          tr {
            th:first-child {
              text-align: left!important;
              padding-left: 24px;
            }
          }
        }

        tbody {
          tr {
            td {
                font-family: $font-inter-regular!important;
                color: $color-grey-dark;
                //font-size: $font-medium-bigger!important;
                text-align: center;
            }
          }
         
        }
        background: $color-gray-alabaster;
      }
    }
    .side-layout-order {
      display: flex;
      flex-wrap: wrap;
    }
  }
  @media screen and (max-width: 1550px) {
    #order-history-screen .order-group-screen {
      position: relative;
     
    }
    #order-table.table-history-order {
      margin-bottom: 112px;
      .VuePagination {
        position:unset;
        left:0;
        right:0;
        margin-left:auto;
        margin-right:auto;
        bottom: 165px;
        padding: 0;
        margin-top: 20px;

      }
    
  } 
  }

  @media screen and (max-width: 1200px) {
    #open-history .group-control.is_single_page .export-file {
      float: left!important;
    }
  }



  @media screen and (max-width: 992px) {
    .trade_layout_order_history .tableContainer3 table tbody tr td:first-child {
      padding-left: 20px!important;
    }

    #open-history .group-control.is_single_page {
      display: flex!important;
      flex-wrap: wrap;
      justify-content: space-between;
      .group-date-picker {
        margin-right: 0!important;
        width: 48%;
      }
      .vdp-datepicker input {
        width: 100%;
      }
      .group-type {
          .button-search{
            &:hover{
              background-color: $color-caribbean-green;
              border-color: $color-caribbean-green ;
              color: $color-grey-dark;
              -webkit-transition: 0.5s;
              transition: 0.5s;
            }
          }
          .button-reset {
            font-size: 14px !important;
          }
        }
    }

    #open-history .is_single_page .vdp-datepicker input {
      font-size: 14px !important;
      background-size: 23px 20px;
      background-position: top 6px left 14px !important;
    }
    #open-history .group-control.is_single_page .group-date-picker .group-date .vdp-datepicker{
      width: 100%;
    }
    #open-history .group-control.is_single_page .group-date-picker .group-date {
      width: 100%;
    }
    #open-history .is_single_page .sc_search_select .group_search_select .button_drop_search {
      height: 36px !important;
      padding: 9px 10px !important;
    }
    #open-history .group-control.is_single_page .group-type button {
      height: 36px !important;
    }
    #open-history .group-control.is_single_page .group-pair input{
      height: 36px !important;
      font-size: 14px !important;

      &::placeholder {
        font-size: 14px !important;
        line-height: 16px;
      }
    }
    #open-history .group-control.is_single_page {
      .group-pair, .group-type {
        width: 100%;
        margin-top: 8px;
        margin-right: 0!important;
      }
      .coin-input {
          .box-coin-option {
            max-width: 100% !important;
          }
        }
    }
    #open-history {
      .group-control {
        &.is_single_page {
          .coin-input {
            width: 100%;
          }
          .group-pair {
            .bw-element {
              margin-top: 3px !important;
            }
          }
        }
      }

      .is_single_page {
        .sc_search_select {
          .group_search_select {
            .box_list_search_select {
              left: 0px !important;
              width: 100%;
              ul {
                width: 100% !important;
                left: 0 !important;
              }
            }
          }
        }
      }
    }

    .label-text {
      font-size: $font-root!important;
    }

    .content-search {
      display: flex;
      justify-content: space-between;
      .toolbar-element {
        width: 48%!important;
        .coin-input {
          input {
            width: 100%;
          }
        }
      }
      .toolbar-element2 {
        width: 48%!important;
      }
    }

   
 
  }
  
</style>
