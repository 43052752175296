 import rf from '@/request/RequestFactory';

const actions = {
  getActiveIndices({ commit }){
    return new Promise((resolve, reject) => {
      rf.getRequest('MarginRequest').getActiveIndices().
        then(res => {
          commit('getActiveIndices', res.data);
          resolve(res.data);
        })
        .catch(err => reject(err));
    })
  },
  getIndiceBySymbol({ commit, state }, {symbol}){
    if(!symbol) symbol = state.currentIndexSymbol
    return new Promise((resolve, reject) => {
      rf.getRequest('MarginRequest').getIndiceBySymbol({}, symbol).
        then(res => {
          // console.log('getIndiceBySymbol', JSON.stringify(res.data))
          commit('updateCurrentIndex', res.data);
          resolve(res.data);
        })
        .catch(err => reject(err));
    })
  },
  getIndexCompositeRequest({ commit, state }, {symbol}){
    if(!symbol) symbol = state.currentIndexSymbol
    return new Promise((resolve, reject) => {
      rf.getRequest('MarginRequest').getIndexCompositeRequest({}, symbol).
        then(res => {
          // console.log('getIndexCompositeRequest', res)
          // commit('updateCurrentIndex', res.data);
          resolve(res.data);
        })
        .catch(err => reject(err));
    })
  },
  setActiveIndexSymbol({commit}, payload){
    commit('setActiveIndexSymbol', payload);
    return
  }
};

const getters = {
  indices: state => {
    return state.indices
  },
  currentIndice: state => {
    return state.indices.find(index => index.symbol === state.currentIndexSymbol) || {};
  },
  indexBySymbol: (state) => (symbol=null) => {
    return state.indices.find(index => index.symbol === symbol) || {};
  },
  indicesByRootSymbol: state => {
    try{
        if(state.indices && state.indices.length > 0){
          return window._.chain(state.indices)
          .filter(el=>el.symbol !== el.reference_symbol)
          .orderBy(['id'], ['asc'])
          .groupBy("root_symbol")
          .map((key, value)=>({rootSymbol: value, child: key}))
          .value();
        }
        return {}
    }catch(err){
      console.error(err);
      return {}
    }
  },
  indexPrice: (state, getters) => {
    const instrument = getters.currentInstrument;
    const symbol = window._.get(instrument, 'reference_index', null);
    const index = getters.indexBySymbol(symbol);
    if (symbol && index) {
      return index.value;
    }
    return null
  },
  indicesUsingThisIndex: (state, getters) => ({symbol}) => {
    const i = state.indices.filter(el=>el.reference_symbol == symbol && el.symbol != symbol)
    return (i && i.length > 0) ? i : []
  }
}

const mutations = {
  getActiveIndices: (state, payload) => {
    if(payload && Array.isArray(payload)){
      const data = payload.map(el=>{
        if(typeof el.is_index_price !== undefined) {
          el['reference'] = el.is_index_price == 1 ? '--' : 'Amanpuri';
        }
        
        return calculateChangedPercent(el);
      })
      state.indices = data;
    }
  },

  updateIndex: (state, payload) => {
    let indices = state.indices;
    let i = indices.findIndex(item => item.symbol === payload.symbol);
    if (i >= 0) {
      const data = Object.assign({}, payload);
      const prevValue = indices[i].value ? indices[i].value : data.value
      indices[i]['previous_value'] = prevValue;
      const changedIndice = calculateChangedPercent(data, prevValue);
      indices[i] = {...indices[i], ...changedIndice};
      if (data.symbol == 'BTC') 
      {
        // console.log('update BTC', indices[i], prevValue, data);
      }
    } else {
      // comment this if you want to prevent insert the new one
      // indices.push(payload);
    }

    state.indices = [...indices];
  },
  setActiveIndexSymbol: (state, payload) => {
     state.currentIndexSymbol = payload
  },
  updateCurrentIndex: (state, payload) => {
    if(!payload.symbol) payload.symbol = state.currentIndexSymbol
    const currentIndice = state.indices.find(el=>el.symbol == payload.symbol)
    if(state.indices && Array.isArray(state.indices) && currentIndice){
      let changedIndice = {...currentIndice, ...payload};
      changedIndice = calculateChangedPercent(changedIndice);
      state.indices = window._.unionBy(state.indices, [changedIndice], "symbol");
    } else {
      state.indices = [];
      state.indices.push(payload);
    }
  },
}

const calculateChangedPercent = (index, prevValue) => {
  let changedPercent = 0;
  if(!prevValue) prevValue = index.previous_value;
  if (prevValue && parseFloat(prevValue) !== 0) {
    changedPercent = (index.value - prevValue) * 100 / prevValue;
  }
  index['changedPercent'] = changedPercent;
  return index;
};

export default {
  actions,
  getters,
  mutations,
  state: {
    indices: [],
    currentIndexSymbol: ''
  },
}
