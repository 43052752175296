<template>
  <div class="content_page_right clearfix" id="bitcoin_series_xbt_guide">
    <h2 class="title_contracts" id="title_overview">{{symbol}}: {{ $t('title.contract_specification') }}</h2>
    <div class="clearfix"></div>
    <div class="list_sub_link">
      <ul>
        <li class="xbt">{{ $t('common.the_base_currency_is_symbol', {symbol: instrumentData && instrumentData.base_underlying}) }}</li>
        <li class="usd">{{ $t('common.the_quote_currency_is_symbol', {symbol: instrumentData && instrumentData.quote_currency}) }}</li>
      </ul>
    </div>    
    <div class="clearfix clearfix-10"></div>
    <div class="title_sub_contracts">{{ $t('title.description') }}</div>
    <div class="clearfix clearfix-10"></div>
    <template v-if="instrumentData">
      <div class="des_title_contracts" v-if="instrumentData.type == 2" v-html="$t('title.des.description', {contractSpecifications: instrumentData.contractSpecifications, symbol: instrumentData.symbol,  base_underlying: instrumentData.base_underlying, quote_currency: instrumentData.quote_currency, expiry: getTypeContract(instrumentData.type, instrumentData), contract_value: getContractSize(), nextDate: getFundingTimestamp(), funding_base_index: instrumentData.funding_base_index, funding_quote_index: instrumentData.funding_quote_index, funding_premium_index: instrumentData.funding_premium_index, fundingRate: getFundingRate(), funding_interval: instrumentData.funding_interval, date: getFundingTimestamp(), routerIndex: instrumentData.routerIndex, routerFBase: instrumentData.routerFBase, routerFQuote: instrumentData.routerFQuote, routerFPremium: instrumentData.routerFPremium, pay: getLongShort(true), receive: getLongShort(false)})"></div>
      <div class="des_title_contracts" v-else v-html="$t('title.contract.description.futures_contract', {contractSpecifications: instrumentData.contractSpecifications, symbol: instrumentData.symbol, base_underlying: instrumentData.base_underlying, quote_currency: instrumentData.quote_currency, routerIndex: instrumentData.routerSettleIndex, settlementIndex: instrumentData.settlement_index})"></div>
      <div class="clearfix clearfix-20"></div>
      <router-link :to="getPath(instrumentData.root_symbol, 'Contract Series Guide Index')" class="btn btb_view" >
        <i class="icon icon-help"></i>{{$t('common.view_contract_series_guide', {root_symbol: instrumentData.root_symbol})}}
      </router-link>
    </template>
    <div class="clearfix clearfix-40"></div>
    <chart-contracts id="char_contracts" :symbol="symbol" chartData="contract-series"></chart-contracts>
    <div v-if="instrumentData && instrumentData.type === 2">
      <div class="clearfix clearfix-40"></div>
      <div class="title_sub_contracts">{{ $t('title.funding_calculation', {perpetualGuide: perpetualContractsGuide}) }}</div>
      <div class="clearfix"></div>
      <div class="des_title_contracts" v-html="$t('title.des.' + symbol.toLowerCase() + '.funding_calculation', {perpetualGuide: perpetualContractsGuide.path})"></div>
      <div class="clearfix clearfix-10"></div>
      <div class="box_table_funding_calculation">
        <data-table3 class="datatable_contracts" ref="datatable" :limit="20" :getData="getData">
          <template slot-scope="props">
            <th></th>
            <th>{{ $t('table.interest_base') }}</th>
            <th>{{ $t('table.interest_quote') }}</th>
            <th>{{ $t('table.premium') }}</th>
          </template>
          <template slot="body" slot-scope="props">
              <tr v-if="props.item">
                <td><div class="name_fulling_calculation">{{ props.item.name }}</div></td>
                <td>{{ props.item.interest_base }}</td>
                <td>{{ props.item.interest_quote }}</td>
                <td>{{ props.item.premium | formatDigitsNumber() }}</td>
              </tr>
            </template>
        </data-table3>
      </div>
      <div class="clearfix clearfix-20"></div>
      <div class="box_curious clearfix" v-html="$t('common.' + symbol.toLowerCase() + '.rate_funding')"></div>
      <div class="clearfix clearfix-10"></div>
      <div class="des_title_contracts_rate" v-html="$t('common.des.rate_funding')"></div>
      <div class="clearfix clearfix-20"></div>
      <div class="group_formula_result clearfix">
        <div class="box_formula">
          <div class="title_sub_contracts">{{ $t('margin.formula') }}</div>
          <div class="clearfix"></div>
          <div class="box_table_curious">
            <table class="table_curious">
              <tbody>
                <tr><td>{{ $t('margin.formula.i') }}</td></tr>
                <tr><td>{{ $t('margin.formula.f') }}</td></tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box_result">
          <div class="title_sub_contracts">{{ $t('margin.result') }}</div>
          <div class="clearfix"></div>
          <div class="box_table_curious">
            <table class="table_curious">
              <tbody>
                <tr>
                  <td class="w_80">{{ generateInterestRateFomula(true) }}</td>
                  <td class="text-right">{{ generateInterestRateFomula(false) }}%</td>
                </tr>
                <tr>
                  <td class="w_80">{{ generateFundingRateFomula(true) }}</td>
                  <td class="text-right">{{ caculatorPecent(instrumentData.funding_rate) | formatDigitsNumber(3) }}%</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="clearfix clearfix-20"></div>
    <div class="title_sub_contracts">{{ $t('title.full_contract_details') }}</div>
    <div class="clearfix"></div>
    <template v-if="instrumentData">
    <div class="box_table_all_curious">
      <table class="table_all_curious">
        <tbody>
          <tr>
            <td class="w_290"><div class="name_full_curious">{{ $t('common.ticker_root') }}</div></td>
            <td>{{ symbol }}</td>
          </tr>
          <tr>
            <td class="w_290"><div class="name_full_curious">{{ $t('margin.expiry_date') }}</div></td>
            <td>{{ instrumentData.type === 1 ? convertToLocalTime(getExpireDate()) : $t('common.perpetual') }}</td>
          </tr>
          <tr>
            <td><div class="name_full_curious">{{ $t('margin.init_margin') }}</div></td>
            <td>{{ caculatorPecent(instrumentData.init_margin) | formatDigitsNumber(2) }}% + {{ $t('margin.entry_taker_fee') }} + {{ $t('margin.exit_taker_fee') }}</td>
          </tr>
          <tr>
            <td><div class="name_full_curious">{{ $t('margin.maint_margin') }}</div></td>
            <td>
              {{ caculatorPecent(instrumentData.maint_margin) | formatDigitsNumber(2) }}% + {{ $t('margin.exit_taker_fee') }}
              <span v-if="checkShowFundingRate(symbol)"> + {{ $t('margin.funding_rate') }}</span>
            </td>
          </tr>
          <tr v-if="instrumentData.type === 2">
            <td><div class="name_full_curious">{{ $t('margin.funding_base_symbol') }}</div></td>
            <td>{{ instrumentData.funding_base_index }}</td>
          </tr>
          <tr v-if="instrumentData.type === 2">
            <td><div class="name_full_curious">{{ $t('margin.funding_quote_symbol') }}</div></td>
            <td>{{ instrumentData.funding_quote_index }}</td>
          </tr>
          <tr v-if="instrumentData.type === 2">
            <td><div class="name_full_curious">{{ $t('margin.funding_premium_symbol') }}</div></td>
            <td>{{ instrumentData.funding_premium_index }}</td>
          </tr>
          <tr v-if="instrumentData.type === 2">
            <td><div class="name_full_curious">{{ $t('margin.funding_rate') }}</div></td>
            <td>{{ getFundingRate() | formatDigitsNumber(3) }}%</td>
          </tr>
          <tr v-if="instrumentData.type === 2">
            <td><div class="name_full_curious">{{ $t('margin.funding_interval') }}</div></td>
            <td>{{ $t('margin.funding_interval_time', {h: instrumentData.funding_interval}) }}</td>
          </tr>
          <tr v-if="instrumentData.type === 2">
            <td><div class="name_full_curious">{{ $t('margin.funding_timestamp') }}</div></td>
            <td>{{ getFundingTimestamp() }}</td>
          </tr>
          <tr class="hidden" v-if="instrumentData.type === 2">
            <td><div class="name_full_curious">{{ $t('margin.indicative_funding_rate') }}</div></td>
            <td>{{ caculatorPecent(instrumentData.indicative_funding_rate) | formatDigitsNumber(2) }}%</td>
          </tr>
          <tr>
            <td><div class="name_full_curious">{{ $t('margin.mark_price') }}</div></td>
            <td>{{ instrumentData.mark_price | formatDigitsNumber(2) }}</td>
          </tr>
          <tr class="hidden">
            <td><div class="name_full_curious">{{ $t('margin.adl_enabled') }}</div></td>
            <td>{{ $t('margin.adl_enabled.desc') }}</td>
          </tr>
          <tr>
            <td><div class="name_full_curious">{{ $t('margin.mark_method') }}</div></td>
            <td>{{ $t('margin.fair_price') }}</td>
          </tr>
          <tr>
            <td><div class="name_full_curious">{{ $t('margin.fair_basis') }}</div></td>
            <td>{{ instrumentData.fair_basis | formatDigitsNumber() }}</td>
          </tr>
          <tr>
            <td><div class="name_full_curious">{{ $t('margin.fair_basis_rate') }}</div></td>
            <td>{{ caculatorPecent(instrumentData.fair_basis_rate) | formatDigitsNumber(3) }}%</td>
          </tr>
          <tr>
            <td><div class="name_full_curious">{{ $t('margin.fair_method') }}</div></td>
            <td>{{ $t('margin.fair_method.desc') }}</td>
          </tr>
          <tr>
            <td><div class="name_full_curious">{{ $t('margin.risk_limit') }}</div></td>
            <td>{{ instrumentData.risk_limit | formatDigitsNumber(8)}} {{ $t('margin.symbol.btc') }}</td>
          </tr>
          <tr>
            <td><div class="name_full_curious">{{ $t('margin.risk_step') }}</div></td>
            <td>{{ instrumentData.risk_step | formatDigitsNumber(8)}} {{ $t('margin.symbol.btc') }}</td>
          </tr>
          <tr>
            <td><div class="name_full_curious">{{ $t('margin.open_interest') }}</div></td>
            <td>{{ instrumentData.open_interest | formatDigitsNumber(4) }}</td>
          </tr>
          <tr>
            <td><div class="name_full_curious">{{ $t('margin.turnover24h') }}</div></td>
            <td>{{ instrumentData.total_turnover_24h | formatDigitsNumber(4) }}</td>
          </tr>
          <tr>
            <td><div class="name_full_curious">{{ $t('margin.total_volume') }}</div></td>
            <td>{{ instrumentData.total_volume | formatDigitsNumber(4) }}</td>
          </tr>
          <tr class="hidden">
            <td><div class="name_full_curious">{{ $t('margin.type') }}</div></td>
            <td>{{ $t('margin.type.desc', {settle_currency: instrumentData.base_underlying, quote_currency: instrumentData.quote_currency}) }}</td>
          </tr>
          <tr>
            <td><div class="name_full_curious">{{ $t('margin.contract_size') }}</div></td>
            <td>{{ getContractSize() }}</td>
          </tr>
          <tr>
            <td><div class="name_full_curious">{{ $t('margin.settlement') }}</div></td>
            <td>{{ instrumentData.type === 1 ? $t('margin.contract_has_settle', {settlement_index: instrumentData.settlement_index, settlement_index_link: instrumentData.routerSettleIndex, time: instrumentData.expiry}) : $t('margin.contract_dont_settle') }}</td>
          </tr>
          <tr class="hidden">
            <td><div class="name_full_curious">{{ $t('margin.commission') }}</div></td>
            <td>{{ $t('margin.commission.desc') }}</td>
          </tr>
          <tr>
            <td><div class="name_full_curious">{{ $t('margin.min_increment') }}</div></td>
            <td>{{ instrumentData.tick_size | formatMarginNumber }} {{ instrumentData.quote_currency }}</td>
          </tr>
          <tr>
            <td><div class="name_full_curious">{{ $t('margin.max_price') }}</div></td>
            <td>{{ instrumentData.max_price | formatDigitsNumber(0) }}</td>
          </tr>
          <tr>
            <td><div class="name_full_curious">{{ $t('margin.max_order_qty') }}</div></td>
            <td>{{ instrumentData.max_order_qty | formatDigitsNumber(0) }}</td>
          </tr>
          <tr>
            <td><div class="name_full_curious">{{ $t('margin.lot_size') }}</div></td>
            <td>1</td>
          </tr>
          <tr>
            <td><div class="name_full_curious">{{ $t('margin.taker_fee') }}</div></td>
            <td>{{ (instrumentData.taker_fee * 100) | formatDigitsNumber(8) }} %</td>
          </tr>
          <tr>
            <td><div class="name_full_curious">{{ $t('margin.maker_fee') }}</div></td>
            <td>{{ (instrumentData.maker_fee * 100) | formatDigitsNumber(8) }} %</td>
          </tr>
        </tbody>
      </table>
    </div>
    </template>
  </div>
</template>

<script>
  import rf from '@/request/RequestFactory'
  import Const from '@/common/Const';
  import Utils from '@/common/Utils'
  import ChartContracts from "../chart_contracts/ChartIndex.vue";
  import { mapActions, mapGetters } from 'vuex';
  import Numeral from '@/lib/numeral';
  import moment from 'moment';
  import BigNumber from "bignumber.js";
  import { getLayoutPath } from '@/routes';

  export default {
    components: {
      ChartContracts
    },
    watch: {
      '$route.path'() {
        this.initData(true);
      },
      instrument() {
        this.instrumentData = this.instrumentBySymbol(this.symbol);
        this.updateInstrumentDate();
        this.updateTableFundingRate();
        if (typeof this.$refs.datatable !== 'undefined') {
          this.$refs.datatable.refresh();
        }
      },
      symbol(){
        this.instrumentData = this.instrumentBySymbol(this.symbol);
        this.updateInstrumentDate();
        this.updateTableFundingRate();
      },
      instrumentData(){
        this.updateTableFundingRate();
      }
    },
    computed: {
      ...mapGetters(['indices', 'indexBySymbol', 'instrument', 'instrumentBySymbol'])
    },
    data() {
      const uri = getLayoutPath([{name: 'Perpetual Contracts Guide'}, {name: 'Contract Specification Index'}, {name: 'Contract Specification Index'}, {name: 'Indice Series Index'}, {name: 'Indice Series Index'}, {name: 'Indice Series Index'}, {name: "Indice Series Index"}, {name: "Perpetual Contracts Guide"}, {name: 'Futures Guide'}], true);

      return {
        perpetualContractsGuide: uri.find(el=>el.name == 'Perpetual Contracts Guide'),
        contractSpecifications: uri.find(el=>el.name == 'Contract Specification Index') + window._.upperCase(this.symbol),
        AmanpuriBTCIndex: uri.find(el=>el.name == 'Indice Series Index') + 'BTC',
        AmanpuriETHIndex: uri.find(el=>el.name == 'Indice Series Index') + 'ETH_BTC',
        BTCIndex: uri.find(el=>el.name == "Indice Series Index") + 'BTC',
        BTCUSDPerpetualContract: uri.find(el=>el.name == "Perpetual Contracts Guide"),
        futuresGuide: uri.find(el=>el.name == 'Futures Guide'),
        symbol: 'BTC',
        dataTable: [],
        instrumentData: {
          contractGuideLink: '',
          symbol: 'BTCUSD',
          root_symbol: 'BTC',
          init_margin: '0',
          funding_interval: 8,
          extra: {
            funding_rate: '0',
            indicative_funding_rate: '0',
            mark_price: '0',
            fair_basis: '0',
            fair_basis_rate: '0'
          }
        }
      }
    },
    methods: {
      getLongShort (isPay) {
        let isLongPay = new BigNumber(`${this.instrumentData.funding_rate || 0}`).comparedTo(0) > 0;
        if (isPay) { // Pay
          if (isLongPay) return window.i18n.t('title.longs');
          return window.i18n.t('title.shorts');
        } else { // Receive
          if (isLongPay) return window.i18n.t('title.shorts');
          return window.i18n.t('title.longs');
        }
      },
      getPath(symbol, path){
        return getLayoutPath({name: path}, true) + symbol
      },
      getFundingTimestamp(){
        var dateFormat = 'YYYY-MM-DD HH:mm:ss';
        var testDateUtc = moment.utc(this.instrumentData.funding_timestamp);
        var localDate = testDateUtc.local();
        return localDate.format(dateFormat);
        // return moment(this.instrumentData.funding_timestamp, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
      },
      getExpireDate(){
        return moment(this.instrumentData.expiry, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
      },
      convertToLocalTime(date) {
        var dateFormat = 'YYYY-MM-DD HH:mm:ss';
        var testDateUtc = moment.utc(date);
        var localDate = testDateUtc.local();
        return localDate.format(dateFormat);
      },
      getSymbolInPath(){
        const symbol = window._.get(this.$route, "params.symbol", "");
        return symbol ? symbol.toUpperCase() : "";
      },
      initData(reload) {
        this.symbol = this.getSymbolInPath();
        if (this.symbol) {
          this.instrumentData = this.instrumentBySymbol(this.symbol);
        }
      },
      getContractSize(){
        return Utils.getUnit('contract_value', this.instrumentData);
      },
      clamp(x, min, max){
        if(x<min) return min
        if(x>max) return max
        return x
      },
      generateInterestRateFomula(showFomula=false){
        const interestQuote = this.caculatorPecent(this.indexBySymbol(this.instrumentData.funding_quote_index).value);
        const interestBase = this.caculatorPecent(this.indexBySymbol(this.instrumentData.funding_base_index).value);
        const fundingTimePerDay = 24 / parseInt(window._.get(this, "instrumentData.funding_interval", 8));
        const operator = interestBase < 0 ? '('+interestBase+'%)' : interestBase+'%';
        const formulaText = 'I = ( '+interestQuote+'% - '+operator+' ) / '+fundingTimePerDay;
        const interestRate = (interestQuote - interestBase)/fundingTimePerDay;
        return showFomula ? formulaText : interestRate;
      },
      generateFundingRateFomula(showFomula=false){
        let premiumRate = this.caculatorPecent(this.indexBySymbol(this.instrumentData.funding_premium_index).value);
        premiumRate = Utils.formatDigitsNumber(premiumRate);
        const interestRate = this.generateInterestRateFomula(false);
        const subOperator = premiumRate < 0 ? '('+premiumRate+' %)' : premiumRate + '%';
        const formulaText = 'F = ' + premiumRate + '% + ' + 'Clamp( '+ interestRate + '% - ' + subOperator + ', -0.05%, 0.05% )';
        const fundingRate = premiumRate + this.clamp(interestRate - premiumRate, -0.05, 0.05);
        return showFomula ? formulaText : fundingRate;
      },
      updateTableFundingRate(){
        if(this.instrumentData){
          let interestBase = this.indexBySymbol(this.instrumentData.funding_base_index);
          let interestQuote = this.indexBySymbol(this.instrumentData.funding_quote_index);
          let interestPremium = this.indexBySymbol(this.instrumentData.funding_premium_index);
          this.dataTable = [
            {
              name: 'Symbols', 
              interest_base: this.instrumentData.funding_base_index, 
              interest_quote: this.instrumentData.funding_quote_index,
              premium: this.instrumentData.funding_premium_index
            },
            {
              name: 'Rates', 
              interest_base: this.caculatorPecent(interestBase.value) + '%',
              interest_quote: this.caculatorPecent(interestQuote.value) + '%',
              premium: this.caculatorPecent(interestPremium.value) + '%'
            }
          ]
          this.$refs.datatable && this.$refs.datatable.refresh();
        }
      },
      getData(params) {
        return new Promise(resolve => {
          return resolve(this.dataTable);
        });
      },
      caculatorPecent(value) {
        return Numeral(value).value() * 100;
      },

      getFundingIntervalHour(date) {
        if (typeof date == 'number') {
          return date;
        }

        return this.getTimeFrequency(date)
      },
      getTimeFrequency(date) {
        return new Date(date).getHours();
      },

      checkShowFundingRate(symbol) {
        let arrHasFundingRate = [
          'BTCUSD',
          'ETHBTC',
          'ETHUSD',
        ];
        if (window._.indexOf(arrHasFundingRate, symbol) == -1) {
          return false;
        }
        return true;
      },

      getFundingRate() {
        if(typeof this.instrumentData.funding_rate !== 'undefined' && this.instrumentData.funding_rate !== null) {
          let temp = new BigNumber(this.instrumentData.funding_rate).mul(100).abs().toString();
          return Utils.formatDigitsNumber(temp);
        }
        else {
          return 0;
        }
      },
      getTypeContract(contractType, data) {
        if(!data && data.expiry) 
          return contractType == 1 ? data.expiry : this.$i18n.t('common.perpetual');
        else
          return contractType == 1 ? this.$i18n.t('common.futures') : this.$i18n.t('common.perpetual') ;
      },
      updateInstrumentDate(){
        if (this.instrumentData) {
          this.instrumentData.routerSymbol = getLayoutPath({name: 'Contract Specification Index'}, true) + this.instrumentData.symbol;
          this.instrumentData.routerIndex = getLayoutPath({name: 'Indice Series Index'}, true) + this.instrumentData.base_underlying;
          this.instrumentData.routerSettleIndex = getLayoutPath({name: 'Indice Series Index'}, true) + this.instrumentData.settlement_index;
          this.instrumentData.routerFBase = getLayoutPath({name: 'Indice Series Index'}, true) + this.instrumentData.funding_base_index;
          this.instrumentData.routerFQuote = getLayoutPath({name: 'Indice Series Index'}, true) + this.instrumentData.funding_quote_index;
          this.instrumentData.routerFPremium = getLayoutPath({name: 'Indice Series Index'}, true) + this.instrumentData.funding_premium_index;
        }
      }
    },
    created() {
      this.initData(false);
    },
    mounted() {
      this.$emit('EVENT_PAGE_CHANGE', this);
      this.initData(true);
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/sass/variables';
  .clearfix-10 {
    clear: both;
    width: 100%;
    display: block;
    height: 10px;
  }
  .clearfix-20 {
    clear: both;
    width: 100%;
    display: block;
    height: 20px;
  }
  .clearfix-25 {
    clear: both;
    width: 100%;
    display: block;
    height: 25px;
  }
  .clearfix-30 {
    clear: both;
    width: 100%;
    display: block;
    height: 30px;
  }
  .clearfix-40 {
    clear: both;
    width: 100%;
    display: block;
    height: 40px;
  }
  .clearfix-50 {
    clear: both;
    width: 100%;
    display: block;
    height: 50px;
  }
  .w_50 {
    width: 50%
  }
  .w_80 {
    width: 80%
  }
  .w_290 {
    width: 290px;
  }
  #char_contracts {
    height: calc(100vw * 0.35);
    min-height: 495px;
    border: 1px solid $color-grey;
  }
  .box_table_funding_calculation {
    background-color: $color-white;
    padding-bottom: 10px;
  }
  .name_fulling_calculation {
    font-family: $font-family-roboto;
    color: $color-grey-dusty;
  }
  .box_curious {
    background-color: $color-bright-gray;
    padding: 13px 15px;
    font-family: $font-family-roboto-medium;
    line-height: 20px;
    font-size: $font-root;
    margin-bottom: 15px;
  }
  .des_title_contracts_rate {
    margin: 5px 0px;
    border-left: 5px solid $color-bright-gray;
    padding-left: 12px;
    line-height: 20px;
    font-size: $font-root;
    color: $color-grey-dusty;
    font-family: $font-family-roboto;
  }
  .group_formula_result {
    display: block;
    width: 100%;
    .box_formula {
      display: block;
      width: calc(100% - 70%);
      float: left;
    }
    .box_result {
      display: block;
      margin-left: 15px;
      width: calc(100% - 30% - 15px);
      float: left;
    }
    .title_sub_contracts {
      font-family: $font-family-roboto !important;
    }
  }
  .box_table_curious {
    display: block;
    width: 100%;
    background-color: $color-bright-gray;
    padding: 13px 25px 13px 15px;
    margin-bottom: 15px;
    .table_curious {
      width: 100%;
      font-family: $font-family-roboto-medium;
      line-height: 20px;
      font-size: $font-root;
      margin-bottom: 0px  ;
    }
  }
  .box_table_all_curious {
    background-color: $color-white;
    width: 100%;
    padding: 15px 20px;
    .table_all_curious {
      width: 100%;
      td {
        line-height: 20px;
        padding: 10px 0px;
        font-family: $font-family-roboto-medium;
        font-size: $font-root;
        color: $color-grey-dark;
        .name_full_curious {
          font-family: $font-family-roboto;
          color: $color-grey-dusty;
        }
      }
    }
  }
  .list_sub_link {
    text-align: right;
    color: $color-grey;
    font-size: $font-smaller;
    font-family: $font-family-roboto-medium;
    line-height: 20px;
    ul {
      li {
        display: inline-block;
        float: none;
        margin-left: 20px;
        &:after {
          display: block;
          width: 5px;
          height: 20px;
          content: "";
          float: right;
          margin-left: 8px;
        }
        &.xbt {
          &:after {
            background-color: $color-denim;
          }
        }
        &.usd {
          &:after {
            background-color: $color-jungle-green;
          }
        }
      }
    }
  }
  .btb_view {
    height: 40px;
    min-width: 340px;
    text-align: center;
    line-height: 20px;
    padding: 8px 15px;
    border-radius: 3px;
    background-color: $color-jungle-green;
    border: 0px;
    letter-spacing: 1.08px;
    font-size: $font-root;
    color: $color-white;
    font-family: $font-family-roboto-bold;
    text-transform: uppercase;
    .icon {
      font-size: 20px;
      position: relative;
      top: 2px;
      display: inline-block;
      margin-right: 14px;
    }
    &:hover,
    &:active,
    &:focus {
      background-color: $color-blue-custom;
    }
  }
  .content_page_right {
    .table_detai_contracts {
      background-color: $color-white;
      padding-bottom: 10px;
      table {
        margin: 0px;
      }
      th {
        vertical-align: middle;
        height: 48px;
        border: none;
        font-size: $font-small;
        color: $color-grey;
        line-height: 20px;
        padding-left: 20px;
        font-family: $font-family-roboto-medium;
      }
      td {
        border: none;
        height: 38px;
        padding-left: 20px;
        font-size: $font-root ;
        font-family: $font-family-roboto-medium;
        color: $color-grey-dark;
      }
    }
    .list_link_head {
      margin-bottom: 30px;
      >li {
        position: relative;
        padding-left: 25px;
        &:before {
          content: "";
          display: block;
          width: 3px;
          height: 3px;
          border-radius: 50%;
          position: absolute;
          background-color: $color-eden;
          top: 8px;
          left: 0px;
        }
        a {
          display: block;
          color: $color-eden;
          font-size: $font-small;
          font-family: $font-family-roboto-medium;
          line-height: 20px;
          &:hover {
            text-decoration: underline;
            color: $color-blue-custom;
          }
        }
        .sub_menu {
          padding: 2px 0px;
          li {
            position: relative;
            padding-left: 25px;
             &:before {
              content: "";
              display: block;
              width: 3px;
              height: 3px;
              border-radius: 50%;
              position: absolute;
              background-color: $color-eden;
              top: 8px;
              left: 0px;
            }
          }
          .sub_menu2 {
            padding: 2px 0px;
            li {
              position: relative;
              padding-left: 25px;
               &:before {
                content: "";
                display: block;
                width: 3px;
                height: 3px;
                border-radius: 50%;
                position: absolute;
                background-color: $color-eden;
                top: 8px;
                left: 0px;
              }
            }
          }
        }
      }
    }
    .line_body {
      display: block;
      width: 100%;
      height: 1px;
      background-color: $color-alto;
    }
    .title_contracts {
      font-size: $font-title-size-small-25;
      font-family: $font-family-roboto-medium;
      display: block;
      color: $color-jungle-green;
      margin: 0px;
      margin-bottom: 15px;
      line-height: 29px;
    }
    .des_title_contracts {
      font-size: $font-root;
      color: $color-grey-dusty;
      margin-bottom: 12px;
      line-height: 22px;
    }
    .title_sub_contracts {
      line-height: 20px;
      color: $color-grey-dark;
      font-family: $font-family-roboto-medium;
      margin: 0px;
      font-size: $font-medium-bigger;
      margin-bottom: 10px;
    }
    .group_search_save {
      display: block;
      float: none;
      width: 100%;
      .form_search_contracts {
        display: block;
        width: 590px;
        float: left;
        max-width: 80%;
        .input-search-contracts {
          display: block;
          float: left;
          width: calc( 100% - 190px);
          border-radius: 3px;
          border: solid 1px $color-alto;
          height: 32px;
          line-height: 20px;
          padding: 6px 15px;
          font-size: $font-small;
          color: $color-grey-dark;
          &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $color-grey-sliver;
            opacity: 1; /* Firefox */
          }

          &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: $color-grey-sliver;
          }

          &::-ms-input-placeholder { /* Microsoft Edge */
            color: $color-grey-sliver;
          }
        }
        .btn-search-contracts {
          display: block;
          float: left;
          width: 85px;
          height: 32px;
          margin-left: 10px;
          border-radius: 3px;
          background-color: $color-jungle-green;
          letter-spacing: 0.9px;
          color: $color-white;
          text-transform: uppercase;
          font-family: $font-family-roboto-bold;
          font-size: $font-smaller;
          &:hover, &:active {
            background-color: $color-blue-custom;
            color: $color-white;
          }
        }
        .btn-clear-search-contracts {
          display: block;
          float: left;
          width: 85px;
          height: 32px;
          margin-left: 10px;
          border-radius: 3px;
          background-color: $color-jungle-green-light;
          letter-spacing: 0.9px;
          color: $color-white;
          text-transform: uppercase;
          font-family: $font-family-roboto-bold;
          font-size: $font-smaller;
          &:hover, &:active {
            background-color: $color-dark-green;
            color: $color-white;
          }
        }
      }
      .save_contracts {
        display: inline-block;
        float: right;
        line-height: 20px;
        padding: 6px 0px;
        color: $color-blue-custom;
        font-size: $font-small;
        cursor: pointer;
        .icon {
          display: inline-block;
          float: right;
          line-height: 20px;
          margin-left: 6px;
        }
      }
    }
    .datatable_contracts {
      th {
        font-size: $font-small;
        color: $color-grey;
        font-family: $font-family-roboto-medium;
        line-height: 20px;
        height: 48px;
        background-color: $color-white;
      }
      td {
        text-align: left;
        min-height: 38px;
        background-color: $color-white;
        font-size: $font-root;
        color: $color-grey-dark;
        font-family: $font-family-roboto-medium;
      }
    }
  }
</style>
 
 <style lang="scss">
  @import "@/assets/sass/variables";
   .des_tit_list {
      ul {
        list-style-type: decimal;
        li {
          padding-left: 10px;
          margin-left: 30px;
        }
      }
    }
    #char_contracts {
      .sc_search_select {
        .group_search_select {
          .button_drop_search {
            &.active,
            &:hover {
              border: 1px solid #999999;
              background-color: transparent;
            }
          }
        }
      }
      &.chart-container {
       .chart-option {
        .time-option {
          .time {
            &.active-time {
              background-color: transparent;
            }
            &:hover {
              background-color: transparent;
            }
          }
        }
       }
      }
    } 
 </style>