<template>
  <div>
    <div class="bg_deposit">
      <div class="container_deposit_fiat" id="funds_deposit_usd">
        <div class="title">
          {{ $t("menu.deposit_usd") }}
        </div>
        <div class="deposit_fiat row">
          <div class="left_deposit_fiat col-12 col-md-12 col-lg-5 col-xl-5">
            <div class="list-balance">
              <div class="item-balance">
                <span class="name-balance">{{
                  $t("funds.withdrawals.total_balance")
                }}</span>
                <span class="number_coin_dps"
                  >{{
                    balance.balance | formatNumberAndDecimal
                  }}&nbsp;{{ $t("currency.usd.shortname") }}</span
                >
              </div>
              <div class="item-balance">
                <span class="name-balance">{{
                  $t("funds.balances.in_use")
                }}</span>
                <span class="number_coin_dps"
                  >{{
                    balance.in_order | formatNumberAndDecimal
                  }}&nbsp;{{ $t("currency.usd.shortname") }}</span
                >
              </div>
              <div class="item-balance">
                <span class="name-balance">{{
                  $t("funds.withdrawals.available_balance")
                }}</span>
                <span class="number_coin_dps"
                  >{{
                    balance.available_balance | formatNumberAndDecimal
                  }}&nbsp;{{ $t("currency.usd.shortname") }}</span
                >
              </div>
            </div>
            <div v-if="isLoaded && isDepositEnable">
              <div class="box_left2">
                <div class="box_left2-content">
                  <template v-if="hasPendingTransaction">
                    <div class="left-content-result">
                      <p class="first_deposit_table">
                        {{ $t("funds.deposit_usd.deposit_note") }}
                      </p>
                      <table class="pending_deposit_transaction_table">
                        <tr class="table_row">
                          <td class="title_row">
                            {{
                              $t("funds.deposit_usd.pending_transaction.amount")
                            }}
                          </td>
                          <td class="content_row">
                            {{
                              pendingTransaction.amount || 0 | abs
                            }}
                            USD
                          </td>
                        </tr>
                        <tr class="table_row">
                          <td class="title_row">
                            {{
                              $t(
                                "funds.deposit_usd.pending_transaction.bank_name"
                              )
                            }}
                          </td>
                          <td class="content_row">
                            {{ pendingTransaction.bank_name }}
                          </td>
                        </tr>
                        <tr class="table_row">
                          <td class="title_row">
                            {{
                              $t(
                                "funds.deposit_usd.pending_transaction.bank_branch"
                              )
                            }}
                          </td>
                          <td class="content_row">
                            {{ pendingTransaction.bank_branch }}
                          </td>
                        </tr>
                        <tr class="table_row">
                          <td class="title_row">
                            {{
                              $t(
                                "funds.deposit_usd.pending_transaction.account_name"
                              )
                            }}
                          </td>
                          <td class="content_row">
                            {{ pendingTransaction.account_name }}
                          </td>
                        </tr>
                        <tr class="table_row">
                          <td class="title_row">
                            {{
                              $t(
                                "funds.deposit_usd.pending_transaction.account_no"
                              )
                            }}
                          </td>
                          <td class="content_row">
                            {{ pendingTransaction.account_no }}
                          </td>
                        </tr>
                        <tr class="table_row">
                          <td class="title_row">
                            {{
                              $t("funds.deposit_usd.pending_transaction.code")
                            }}
                          </td>
                          <td class="content_row color_code">
                            {{ pendingTransaction.code }}
                          </td>
                        </tr>
                      </table>
                      <div class="paddingBotton"></div>
                      <div class="form-group flex_button">
                        <div class="flex_button_box">
                          <button
                            type="submit"
                            class="btn-submit"
                            @click="createOtherDeposit()"
                          >
                            {{ $t("funds.deposit_usd.other_deposit") }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="left-content-submit">
                      <div class="address-box input-group">
                        <div class="address_for" for="address">
                          {{ $t("funds.deposit_usd.deposit_amount") }}
                        </div>
                        <div class="form-group-input">
                          <currency-input :precision="2" :maxLengthCus='16' v-model="amount" :class="{'error': errors.has('amount')}" class="form-input" @focus="resetError" /> 
                          <!-- <input
                            type="number"
                            v-model="amount"
                            @focus="resetError"
                            :class="{'error': errors.has('amount')}"
                          /> -->
                          <div class="">
                            <template v-if="isLoading">
                              <div class="flex_button_box">
                                <button type="submit" class="btn-submit">
                                  {{ $t("funds.withdrawals.loading") }}
                                </button>
                              </div>
                            </template>
                            <template v-else>
                              <div class="flex_button_box">
                                <button
                                  type="submit"
                                  class="btn-submit"
                                  @click="deposit()"
                                >
                                  {{
                                    $t("funds.deposit_usd.submit_deposit_usd")
                                  }}
                                </button>
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                      <div class="eft-content-submit-invalid">
                        <span
                          v-show="errors.has('amount')"
                          class="invalid-feedback"
                          >{{ errors.first("amount") }}</span
                        >
                      </div>
                    </div>
                  </template>
                </div>
              </div>

              <div class="box_left3">
                <div class="deposit-content">
                  <div class="form-group">
                    <p class="txt-note">{{ $t("funds.deposit.tips.text1") }}</p>
                    <div class="clearfix"></div>
                    <p class="txt-tit">
                      {{ $t("funds.deposit_usd.guide.text1") }}
                    </p>
                    <p>{{ $t("funds.deposit_usd.guide.text2") }}</p>
                    <p class="txt-tit">
                      {{ $t("funds.deposit_usd.guide.text3") }}
                    </p>
                    <p>{{ $t("funds.deposit_usd.guide.text4") }}</p>
                    <p class="txt-tit">
                      {{ $t("funds.deposit_usd.guide.text7") }}
                    </p>
                    <p>
                      {{ $t("funds.deposit_usd.guide.text8") }}
                      {{ $t("funds.deposit_usd.guide.text9") }}
                    </p>
                    <p class="txt-tit">
                      {{ $t("funds.deposit_usd.guide.text10") }}
                    </p>
                    <p>{{ $t("funds.deposit_usd.guide.text11") }}</p>
                    <p class="note">
                      {{ $t("funds.deposit_usd.guide.text12") }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              class="alert alert-danger text-center space-top"
              role="alert"
            >
              {{ $t("funds.disable_coin_msg") }}
            </div>
          </div>
          <div class="right_deposit_fiat col-12 col-md-12 col-lg-7 col-xl-7">
            <div class="box_right1" id="table_deposit_usd">
              <data-table3
                class="table-deposit_fiat"
                :getData="getData"
                :limit="10"
                :widthTable="'100%'"
                ref="datatable"
                :msgEmptyData="msgEmptyData"
              >
                <template slot-scope="props">
                  <th
                    class="sort text-center th-time"
                    data-sort-field="created_at"
                    @click="props.echoclick"
                  >
                    {{ $t("funds.deposit_usd.transaction_history_table.time") }}
                    <i
                      class="group-sort-tabl3"
                      v-html="props.innerHtmlSort"
                    ></i>
                    <span
                      class="iconFixSorIE"
                      v-if="props.resetIE === true"
                    ></span>
                  </th>
                  <th
                    class="t-left sort th-amount"
                    data-sort-field="amount"
                    @click="props.echoclick"
                  >
                  <div class="flex">
                    <span>
                    {{
                      $t("funds.deposit_usd.transaction_history_table.amount")
                    }}
                    / <br>
                    {{
                      $t("funds.deposit_usd.transaction_history_table.status")
                    }}
                    </span>
                    <i
                      class="group-sort-tabl3"
                      v-html="props.innerHtmlSort"
                    ></i>
                    <span
                      class="iconFixSorIE"
                      v-if="props.resetIE === true"
                    ></span>
                  </div>
                  </th>
                  <th class="t-left sort th-account">
                    {{ $t("funds.deposit_usd.transaction_history_table.bank") }}
                    / <br>
                    {{
                      $t("funds.deposit_usd.transaction_history_table.account")
                    }}
                  </th>
                  <th class="sort th-code">
                    {{ $t("funds.deposit_usd.transaction_history_table.code") }}
                  </th>
                </template>
                <template slot="body" slot-scope="props">
                  <tr>
                    <td style="" class="text-center td-time">
                      <div class="text-bold text-bold-time">
                        {{ props.item.created_at | YearMonthDate }}
                      </div>
                      <div class="text-not-bold text-not-bold-time">
                        {{ props.item.created_at | orderTime }}
                      </div>
                    </td>
                    <td style="" class="td-amount">
                      <div class="text-bold text-bold-amount">
                        {{ props.item.amount | toNumber |  formatNumberCutDecimal(20 , 20)  }}
                      </div>
                      <div
                        v-if="props.item.status === statusAccountDefault"
                        class="
                          text-not-bold text-not-bold-amount-pending
                          status-pending
                        "
                      >
                        {{ props.item.status | transactionStatus }}
                      </div>
                      <div
                        v-else
                        class="text-not-bold text-not-bold-amount-cancel"
                        :class="`status-${props.item.status}`"
                      >
                        {{ props.item.status | transactionStatus }}
                      </div>
                    </td>
                    <td style="" class="t-left td-account">
                      <!-- <div class="text-not-bold text-not-bold-account">{{ `${props.item.bank_name || '--'} -  ${props.item.bank_branch || '--'}` }}</div>
                  <div class="text-bold text-bold-account">{{ `${props.item.account_name || '--'} -  ${props.item.account_no || '--'}` }}</div> -->
                      <div class="item_email_user">
                        <span
                          class="text-bold text-not-bold-account txt_email_user"
                          >{{
                            `${props.item.bank_name || "--"} -  ${
                              props.item.bank_branch || "--"
                            }`
                          }}</span
                        >
                        <span
                          class="text-bold-account txt_email_user text-not-bold"
                          >{{
                            `${props.item.account_name || "--"} -  ${
                              props.item.account_no || "--"
                            }`
                          }}</span
                        >
                        <span class="tooltip_email_user">
                          <span
                            >{{ props.item.bank_name }}{{ " - "
                            }}{{ props.item.bank_branch }}</span
                          >
                          <br />
                          <span class="text-not-bold"
                            >{{ props.item.account_name }}{{ " - "
                            }}{{ props.item.account_no }}</span
                          >
                        </span>
                      </div>
                    </td>
                    <td style="" class="td-code">
                      <div class="text-bold text-bold-code">
                        {{ props.item.code }}
                      </div>
                      <a
                        v-if="props.item.status === statusAccountDefault"
                        @click="showConfirm(props.item.id)"
                        class="button_cancel_deposit"
                      >
                        {{ $t("common.action.cancel") }}
                      </a>
                    </td>
                  </tr>
                </template>
              </data-table3>
            </div>
          </div>
        </div>

        <!-- <confirmation-modal></confirmation-modal> -->
      </div>
    </div>
  </div>
</template>

<script>
import rf from "@/request/RequestFactory";
import TransactionHistory from "@/components/shared_components/funds/TransactionHistory";
import BigNumber from "bignumber.js";
import Utils from "@/common/Utils";
// import ConfirmationModal from "@/components/shared_components/common/ConfirmationModal.vue";

export default {
  components: {
    // ConfirmationModal,
    TransactionHistory,
  },
  data() {
    return {
      statusAccountDefault: "pending",
      currencyName: "usd",
      balance: {},
      amount: "",
      pendingTransaction: {},
      isLoading: false,
      msgEmptyData: window.i18n.t("funds.depositUSD.no_data"),
      color: "",

      isLoaded: false,
      isDepositEnable: false,
      totalInOrder: 0,
      balanceFutures: [],
      listInstruments: [],
      positionData: [],
      Price24h: [],
      costOpenOrder: {},
      assetsWallet: {},
      listAllowcated: []
    };
  },
  watch: {
    assetsWallet() {
      this.unrealizedPostions()
      if(this.positionData.length === 0 ) {
        this.availableBalanceWithdraw()
      }
    },
    positionData(){
      this.unrealizedPostions()
    },
  },
  computed: {
    hasPendingTransaction() {
      return !this.isEmpty(this.pendingTransaction);
    },
  },

  methods: {
    resetError() {
      this.errors.clear();
      this.color = "";
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    validateDepositWithdraw() {
      rf.getRequest("MasterdataRequest")
        .getAll()
        .then((res) => {
          const setting = window._.filter(
            res.coins_confirmation,
            (item) => item.coin === "usd"
          );
          if (!window._.isEmpty(setting)) {
            this.isDepositEnable = setting[0].is_deposit;
          }
          this.isLoaded = true;
        })
        .catch((e) => {
          this.isLoaded = true;
        });
    },
    isEmpty(val) {
      return _.isEmpty(val);
    },

    calculateBalanceInOrder() {
      return rf
        .getRequest("TransactionRequest")
        .getTotalUsdPendingWithdraw()
        .then((res) => {
          // const totalUsdPending = res.data.total || '0';
          // const inOrder = new BigNumber(`${this.balance.balance || 0}`).sub(`${this.balance.available_balance || 0}`).sub(`${totalUsdPending}`).toString();
          // this.$set(this.balance, 'in_order', inOrder);

          const totalUsdPendingWithdraw = res.data.total || "0";
          const inOrder = new BigNumber(`${this.totalInOrder || 0}`)
            .add(`${totalUsdPendingWithdraw}`)
            .toString();
          this.$set(this.balance, "in_order", inOrder);
        });
    },

    getUsdBalance() {
      this.balance = {};
      if(this.balanceFutures.assets) {
        const balancesFuture = this.balanceFutures.assets['USD']
        rf.getRequest("UserRequest")
            .getDetailsUsdBalance("usd")
        .then((res) => {
          // this.balance = res.data;
          let total = 0;
          this.totalInOrder = 0;
          const totalBalance = new BigNumber(res.data.main.balance).plus(balancesFuture.balance).toString();
          const availableBalance = new BigNumber(res.data.main.available_balance)
          if (
            res.data.exchange &&
            res.data.exchange.balance &&
            res.data.exchange.available_balance
          ) {
            this.totalInOrder = new BigNumber(`${this.totalInOrder || 0}`).add(
              new BigNumber(`${res.data.exchange.balance || 0}`)
            );
            total = new BigNumber(`${total || 0}`).add(
              new BigNumber(`${res.data.exchange.balance || 0}`)
            );
          }
          if (
            res.data.margin &&
            res.data.margin.balance &&
            res.data.margin.available_balance
          ) {
            this.totalInOrder = new BigNumber(`${this.totalInOrder || 0}`).add(
              new BigNumber(`${res.data.margin.balance || 0}`)
            );
            total = new BigNumber(`${total || 0}`).add(
              new BigNumber(`${res.data.margin.balance || 0}`)
            );
          }
          if (
            res.data &&
            res.data.main &&
            res.data.main.balance &&
            res.data.main.available_balance
          ) {
            this.balance = {
              ...this.balance,
              balance: new BigNumber(`${totalBalance|| 0}`).add(new BigNumber(`${total || 0}`)),
              available_balance: availableBalance,
              "in_order": new BigNumber(`${totalBalance|| 0}`).add(new BigNumber(`${total || 0}`)).sub(availableBalance).toString(),

            };
          }
          // this.calculateBalanceInOrder();
        });
      }
    },

    getData(params) {
      const meta = {
        type: "deposit",
      };
      return rf
        .getRequest("TransactionRequest")
        .getUsdTransactions(Object.assign({}, params, meta));
    },

    onBalanceUpdated(newBalance) {
      // this.balance.balance = newBalance && newBalance.total_balance
      // this.balance.available_balance = newBalance && newBalance.available_balance
      // this.calculateBalanceInOrder();
      this.getUsdBalance();
    },

    validate() {
      this.errors.clear();

      if (this.amount === "" || this.amount === undefined) {
        this.errors.add(
          "amount",
          window.i18n.t("funds.deposit_usd.errors.required", {
            field: "amount",
          })
        );
      }
      if (new BigNumber(`${this.amount || 0}`).comparedTo(0) <= 0) {
        this.errors.add(
          "amount",
          window.i18n.t("funds.deposit_usd.errors.min_amount", {
            min_amount: 0,
          })
        );
      }
      // if (new BigNumber(`${this.amount || 0}`).toNumber() > 0) {
      //   this.errors.add('amount', window.i18n.t('messages.error.balance_insufficient'));
      // }

      if (this.errors.count() > 0) {
        this.color = "#e2221e";
        return false;
      }
      return true;
    },
    showConfirm(data) {
      ConfirmationModal.show({
        type: "confirm",
        title: window.i18n.t('funds.deposit_usd.title_modal_cancel'),
        content: window.i18n.t(
          "m_funds.deposit_usd.transaction_history_table.text1"
        ),
        // btnCancelLabel: window.i18n.t('common.action.no'),
        // btnConfirmLabel: window.i18n.t('common.action.yes'),
        btnCancelLabel: window.i18n.t('funds.deposit_usd.btn_close'),
        btnConfirmLabel: window.i18n.t('funds.deposit_usd.btn_confirm'),
        onConfirm: () => {
          this.cancel(data);
          this.fullscreen = false;
        },
        onCancel: () => {},
        class: 'cancel-transaction'
      });
    },

    cancel(transactionId) {
      rf.getRequest("TransactionRequest")
        .cancelUsdDepositTransaction(transactionId)
        .then((res) => {
          (this.amount = ""), (this.pendingTransaction = {});
          this.$refs.datatable.refresh();
        })
        .catch(function (error) {});
      Message.success(window.i18n.t("address.cancel_success"));
    },

    deposit() {
      if (!this.validate()) {
        return false;
      }

      let params = {
        amount: `${this.amount || 0}`,
      };
      this.isLoading = true;
      rf.getRequest("TransactionRequest")
        .depositUsd(params)
        .then((res) => {
          Message.success(window.i18n.t("funds.deposit_usd.success_message"));
          this.isLoading = false;
          this.pendingTransaction = res.data;
          this.$refs.datatable.refresh();
          this.amount = "";
        })
        .catch(function (error) {
          this.color = "#e2221e";
          console.log(error.response.data.message);
        });
    },

    createOtherDeposit() {
      this.color = "#cfcfcf";
      this.pendingTransaction = {};
    },

    onTransactionCreated() {
      if (this.isLoading) {
        return;
      }
      this.$refs.datatable.refresh();
    },

    getSocketEventHandlers() {
      return {
        TransactionCreated: this.onTransactionCreated,
        MainBalanceUpdated: this.onBalanceUpdated,
      };
    },

  getInstruments() {
    rf.getRequest("UserRequest")
        .getInstruments()
        .then((res) => {
          const assetsKeyObject = {};
          res.data.forEach((asset) => {
            return (assetsKeyObject[asset.symbol] = {
              ...asset,
            });
          });
          this.listInstruments = assetsKeyObject;
        });
  },
  unrealizedPostions() {
    this.listAllowcated = []
    let balanceFuture = this.balanceFutures.assets
    for (const item of this.positionData) {
      const multiplier = this.listInstruments[item.symbol].multiplier

      if (item.currentQty != 0) {
        const markPrice = this.markPrice(item.symbol)
        const side = item.currentQty > 0 ? 1 : -1
        const allowcated = Utils.allowcatedMargin(item, multiplier, markPrice)
        const unrealizedPNL = Utils.unrealizedPNL(markPrice, item, side, multiplier)
        const allowcatedObj = {
          asset: item.asset,
          allowcated: allowcated,
        }
        this.listAllowcated.push(allowcatedObj)
        if (balanceFuture) {
          const isCross = Boolean(item.isCross) ? unrealizedPNL : 0
          balanceFuture[item?.asset].unrealisedPnl = isCross
        }
        this.availableBalanceWithdraw()
      }
    }
  },
  availableBalanceWithdraw() {
    for (let key in this.balanceFutures.assets) {
      const variableBalance = this.balanceFutures.assets[key]
      const costOrder = this.costOpenOrder[key] || 0
      const totalAllowcated = Utils.allowcatedMarginForAsset(key, this.listAllowcated)
      let availableUSDT = new BigNumber(variableBalance.balance).minus(totalAllowcated.toString()).minus(costOrder.toString()).toString()
      let maximunWithdraw = new BigNumber(availableUSDT)
          .add(variableBalance.unrealisedPnl.toString())
          .toString();
      variableBalance.availableBalance = new BigNumber.min(availableUSDT, maximunWithdraw).toString();
      const availableBalance = new BigNumber(variableBalance.availableBalance.toString());
      variableBalance.availableBalance = BigNumber.max(0, availableBalance).toString();
    }
    this.getUsdBalance()
  },

  markPrice(symbol) {
    const findSymbol = this.Price24h.filter((item) => {
      return item.symbol === symbol;
    });
    return findSymbol[0]?.oraclePrice || 0
  },
  sumCostOpenOrder(order) {
    let costUSD = 0;
    let costUSDT = 0;
    this.costOpenOrder = {};
    order.forEach((item) => {
      if (item.cost !== null) {
        if (
            item.symbol.includes("USDT") &&
            !item.symbol.includes("USDTUSD")
        ) {
          costUSDT += parseFloat(item.cost);
          this.costOpenOrder.USDT = costUSDT || 0;
        }  else {
          costUSD += parseFloat(item.cost);
          this.costOpenOrder.USD = costUSD || 0;
        }
      }
    });
  },
  getSumOrderMargin() {
    const data = {
      contractType: "USD_M"
    }
    return rf.getRequest("UserRequest").getOpenOrderMargin(data)
        .then(res => {
          this.openOrder = res.data
          this.sumCostOpenOrder(res.data)
        })
  },

  getBalanceFutureAPI() {
    return rf.getRequest("UserRequest")
        .getFuturesBalances()
        .then((res) => {
          this.assetsWallet = res.data;
          const assetsKeyObject = {};
          res.data.forEach((asset) => {
            assetsKeyObject[asset.asset] = {
              balance: asset?.balance,
              marginBalance: 0,
              availableBalance: 0,
              totalAllowcated: 0,
              unrealisedPnl: 0,
              unrealisedROE: "",
            };
          });
          this.balanceFutures = {
            assets: assetsKeyObject,
          };
        });
  },
  positionMargin() {
    const data = {
      contractType: "USD_M",
    };
    return rf
        .getRequest("MarginRequest")
        .getPosition(data)
        .then((res) => {
          this.positionData = res.data;
          return res;
        });
  },
  async initData() {
    await Promise.all([
      this.getInstruments(),
      this.positionMargin(),
      this.getSumOrderMargin(),
      this.getBalanceFutureAPI()
    ])
  },

  beforeDestroy() {
    window.removeEventListener("focus", (event) => {
      this.color = "#2dac91";
      this.errors.clear();
    });
  },
  },
  mounted() {
    this.scrollToTop();
    window.addEventListener("focus", (event) => {
      this.color = "#2dac91";
      this.errors.clear();
    });
    document.title = this.$t("menu.deposit_usd") + ` – ${this.tabTitle}`;
    this.getUsdBalance();
  },
  created() {
    this.validateDepositWithdraw();
    this.initData()
    this.$on("UPDATED_LOCALE", () => {
      this.msgEmptyData = window.i18n.t("funds.depositUSD.no_data");
    });
  },
};
</script>

<style lang="scss" scoped>
.form-input {
  background: $color-bright-gray;
  &:focus {
    border: 1px solid $color-jungle-green !important;
  }
}
.bg_deposit {
  background: var(--color-background-withdrawls);
  background-size: 100% 337px;
  flex: 1;
}
.container_deposit_fiat {
  padding: 40px;
  max-width: 1318px;
  flex: 1%;
  .title {
    color: var(--dark-1);
    font-size: 34px;
    font-weight: 600;
    line-height: 40px;
    margin-bottom: 30px;
    padding: 0;
  }
  .row::before {
    content: unset;
  }
  .deposit_fiat {
    display: flex;
    gap: 70px;
    .center_deposit_fiat {
      background-color: #f8f8f8;
    }
    .left_deposit_fiat {
      max-width: 450px;
      //overflow-y: scroll;
      overflow: auto;
      padding-left: 9px;
      .list-balance {
        .item-balance {
          margin-bottom: 10px;
          display: flex;
          justify-content: space-between;
          .name-balance {
            color: var(--text-close);
            font-size: 18px;
            line-height: 22px;
          }
          .number_coin_dps {
            color: var(--dark-1);
            font-size: 18px;
            font-weight: 600;
            line-height: 22px;
          }
        }
      }
      .box_left2 {
        .box_left2-content {
          .left-content-submit {
            .address-box {
              margin-top: 40px;
              margin-bottom: 0px;
              display: block;
            }
            .address_for {
              color: var(--dark-1);
              font-size: 18px;
              line-height: 22px;
              margin-bottom: 8px;
            }
            .form-group-input {
              display: flex;
              gap: 12px;
              input {
                border-radius: 8px;
                padding: 14px 15px;
                flex: 1;
                border: 1px solid var(--color-bg-deposit-usd);
                background: var(--color-bg-deposit-usd);
                color: var(--text-primary-color);
                &.error {
                  border: 1px solid $text-color-red;
                }
              }
              input::-webkit-outer-spin-button,
              input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
              /* Firefox */
              input[type="number"] {
                -moz-appearance: textfield;
              }
            }
          }
          .left-content-result {
            background: var(--color-bgr-info-deposit-usd );
            border-radius: 8px;
            padding: 20px;
            p {
              margin-bottom: 0px;
            }
            .pending_deposit_transaction_table {
              width: 100%;
              margin-top: 22px;
              height: 150px;
              .title_row {
                color: var(--text-close);
                font-size: 18px;
                line-height: 22px;
                padding-bottom: 10px;
              }
              .content_row {
                color: var(--dark-1);
                font-size: 18px;
                line-height: 22px;
              }
            }
            .first_deposit_table {
              color: $text-color-red;
              font-size: 16px;
              line-height: 19px;
            }
            .content_row {
              text-align: right;
            }
            .paddingBotton {
              height: 23px;
            }
          }
        }
        .flex_button_box {
          .btn-submit {
            padding: 14.5px 20px;
            width: 100%;
            background: $color-caribbean-green;
            border-radius: 8px;
            font-size: 18px;
            line-height: 21px;
            color: $dark-1;
            font-weight: 400;
            &:hover {
              background-color: $color-aquamarine;
            }
          }
        }
      }
      .box_left3 {
        margin-top: 25px;
        p {
          font-size: 16px;
          line-height: 21px;
          color: var(--dark-1);
          margin-bottom: 15px;
        }
        .note {
          font-size: 14px;
          line-height: 17px;
          color: $text-color-jade;
          margin-bottom: 0;
        }
        .txt-note {
          font-size: 16px;
          line-height: 21px;
          color: var(--text-close);
        }
        .txt-tit {
          font-size: 16px;
          line-height: 21px;
          color: var(--dark-1);
          margin-bottom: 6px;
          font-family: $font-inter-bold;
        }
      }
     
    }
    .center_deposit_fiat {
      width: 30px;
    }
    .right_deposit_fiat {
      
      .table-deposit_fiat {
        background-color: $background-default;
        table {
          thead {
            tr {
              color: var(--dark-2);
              background: var(--color-bg-select);
            }
            .flex {
              display: flex;
              align-items: center;
            }
          }
        }
      }
      th {
        font-size: 14px;
        line-height: 17px;
        color: var(--text-close);
        background: var(--color-bg-select);
        padding: 15px 24px;
      }

      tr {
      }
      td {
        padding-left: 0px;
        color: var(--text-primary-color);
        background: var(--bg-history-table);
        .text-bold {
          font-size: 18px;
          line-height: 22px;
          color: var(--dark-1);
          margin-bottom: 5px;
        }
        .text-not-bold {
          font-size: 16px;
          line-height: 19px;
        }
        .button_cancel_deposit {
          padding: 6px 20px 6px 0;
          // background: $color-caribbean-green;
          // border-radius: 8px;
          font-size: 18px;
          line-height: 22px;
          color: var(--dark-1);
          text-decoration-line : underline;
          &:hover {
            text-decoration-line: underline;
          }
        }
      }
      .th-time,
      .td-time {
        text-align: left;
      }
      .th-time,
      .td-time {
        padding-left: 15px;
      }
      .th-amount,
      .td-amount {
        text-align: left;
        padding-right: 10px;
      }
      .th-account,
      .td-account {
        text-align: left;
      }
      .th-code {
        padding: 0;
        text-align: left;
        padding-left: 0;
      }
      .th-time,
      .td-time {
        min-width: 100px;
        text-align: left !important;
        border-radius: 8px 0 0 8px;
      }
      .th-amount,
      .td-amount {
        min-width: 150px;
        padding-left: 24px;
      }
      .th-account,
      .td-account {
        min-width: 210px;
        padding-left: 0;
      }
      .th-code,
      .td-code {
        min-width: 80px;
        border-radius: 0px 8px 8px 0;
      }
      .td-code {
        text-align: left;
        padding-left: 8px;
      }
      .text-not-bold-time {
        color: var(--text-close);
      }
      .text-bold-account {
        text-overflow: ellipsis;
        width: 200px;
        overflow: hidden;
      }
      .text-not-bold-account {
        color: $text-grey-dusty;
        text-overflow: ellipsis;
        // width: 200px;
        max-width: 250px;
        overflow: hidden;
      }
      .text-not-bold-amount-pending {
        font-size: $font-smaller;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        color: $text-green;
      }
      .text-not-bold-amount-cancel {
        font-size: $font-smaller;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        color: $text-denim;
      }
      .status-success {
        color: $text-color-jade;
      }
      .status-pending {
        color: $text-color-yellow;
      }
      .status-submitted {
        color: $color-dark-green;
      }
      .status-error,
      .status-cancel,
      .status-rejected {
        color: $text-color-red;
      }
    }
  }
  @media screen and (max-width: 992px){
    padding: 30px 16px;
  }
}
.space-top {
  margin-top: 20px;
}
.item_email_user {
  display: block;
  position: relative;
  .txt_email_user {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
     color: var(--text-desc);
  }
  .tooltip_email_user {
    position: absolute;
    top: -10px;
    left: 20px;
    line-height: 20px;
    padding: 5px 20px;
    // left: 100%;
    background: $bgr-tooltip;
    white-space: nowrap;
    width: 350px !important;
    z-index: 100;
    font-size: $font_root;
    font-weight: 500;
    color: $color-white;
    transition: 0.5s;
    padding: 10px;
    display: none !important;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);
    span {
      word-wrap: break-word;
      white-space: normal;
    }
    &:after {
      right: 100%;
      top: 30px;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(136, 183, 213, 0);
      border-right-color: $color_white;
      border-width: 5px;
      margin-top: -5px;
    }
  }
  &:hover {
    .tooltip_email_user {
      display: block !important;
      transition: 0.5s;
    }
  }
}
td,
.td-account {
  overflow: unset;
}

.default-sort {
  color: var(--dark-2);
  background: var(--color-bg-select);
}

.link-green {
  color: $color-aqua-green;
}
#table_deposit_usd {
  max-width: 760px;
  padding-bottom: 30px;
  z-index: 5;
    border: 0px;
    border-top: none;
    overflow-y: auto;
    overflow-x: auto;
  .tableContainer {
    border-radius: 8px;
    width: 100%;
    // min-height: 566px;
    background: var(--background-color-primary-2);
    // padding-bottom: 17px;
    .clearfix {
      color: var(--text-primary-color);
      background: var(--bg-history-table);
    }
  }
  
  @media screen and (max-width: 992px){
    max-width: 100%;
  }
  @media screen and (max-width: 768px){
   width: unset;
  }
  @media screen and (max-width: 576px){
    overflow: auto;
    white-space: nowrap;
  }
}
@media screen and (max-width: 1800px){
  #table_deposit_usd {
    margin-left: 0;
  }
}
#funds_deposit_usd {
  &.container_deposit_fiat {
    .deposit_fiat {
      .right_deposit_fiat {
        .th-code {
          padding: 8px 20px 8px 8px;
        }
      }
    }
  }
}
@media screen and (max-width: 1670px){
  .container_deposit_fiat {
  .deposit_fiat {
    gap: 10px;
  }
}
}

@media screen and (max-width: 1484px){
.container_deposit_fiat {

  padding: 40px 20px;
  .deposit_fiat {
    gap: 10px;
    .left_deposit_fiat {
      max-width: 400px;
    }
  }
}
}
@media screen and (max-width: 1191px){
  .container_deposit_fiat {
    padding: 30px 16px 50px;
    .deposit_fiat {
      gap: 0;
    }

  }
  }
  @media screen and (max-width: 992px){
    .container_deposit_fiat .deposit_fiat .left_deposit_fiat .box_left2 .box_left2-content .left-content-submit .form-group-input {
      flex-direction: column;
    }
    .container_deposit_fiat {
      .deposit_fiat {
        .left_deposit_fiat {
          max-width: 100%;
        }
      }
    }
  }
  @media screen and (max-width: 768px){
  .container_deposit_fiat {
    padding: 30px 16px 100px;
    .deposit_fiat {
      gap: 0;
    }
  }
  }
</style>
<style lang="scss">
#table_deposit_usd {
  table {
    overflow-y: initial;
  }
  .VuePagination {
    position: absolute;
    width: 100%;
    bottom: -20px;

    @media screen and (max-width: 768px){
      bottom: -86px;
    }
  }
}
#confirmationModal {
  .cancel-transaction {
    .modal-dialog {
      .modal-content {
        .modal-body {
          .content_text {
            
            @media screen and (max-width: 992px){
              font-size: 16px;
              line-height: 19px;
            }
          }
        }
      }
    }
  }
}
</style>