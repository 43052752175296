import { render, staticRenderFns } from "./SettingsSecurity.vue?vue&type=template&id=0a3aceee&scoped=true"
import script from "./SettingsSecurity.vue?vue&type=script&lang=js"
export * from "./SettingsSecurity.vue?vue&type=script&lang=js"
import style0 from "./SettingsSecurity.vue?vue&type=style&index=0&id=0a3aceee&prod&lang=scss&scoped=true"
import style1 from "./SettingsSecurity.vue?vue&type=style&index=1&id=0a3aceee&prod&lang=scss&scoped=true"
import style2 from "./SettingsSecurity.vue?vue&type=style&index=2&id=0a3aceee&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a3aceee",
  null
  
)

export default component.exports