var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tableContainer tableContainer3",class:{
      overflow_unset: !this.isAuthenticated,
      'table-history': _vm.tableHistory,
      'table-open-order': _vm.tableOpenOrder,
      'table-history-order': _vm.tableOrderHistory,
     'no-data':this.rows.length === 0
    },style:({ width: _vm.widthTable })},[_c('div',{staticClass:"wrap-table"},[_c('table',{class:{ 'table-history': _vm.tableHistory }},[_c('thead',[_c('tr',{class:_vm.defaultSort ? 'default-sort' : 'custom-sort',on:{"click":_vm.onSort}},[_vm._t("default",null,{"resetIE":_vm.resetIE,"innerHtmlSort":_vm.innerHtmlSort,"echoclick":_vm.echoclick})],2)]),_c('tbody',{ref:"bodyTable"},[_vm._t("first_row"),_vm._l((_vm.rows),function(row,index){return _vm._t("body",null,{"item":row,"index":index})}),(this.rows.length === 0 && !_vm.checkNoDataWhenAuthenticated && _vm.getDataSuccess && !_vm.tableOpenOrder && !_vm.tableHistory && !_vm.tableOrderHistory)?[_c('tr',{staticClass:"empty-data"},[_c('td',{attrs:{"colspan":"50"}},[_c('div',{staticClass:"fixed-empty"},[_c('div',{staticClass:"icon-no-data-1 text-center w-100"},[_c('img',{staticClass:"icon-search",attrs:{"src":require(`@/assets/images/landing/icon-nodata${
                        _vm.theme === 'light-theme' ? '' : '-dark'
                      }.svg`)}}),(_vm.tableNoData === false && _vm.typeHistory === 'deposit')?_c('div',{staticClass:"text-no-data w-100 text-center"},[_vm._v(" "+_vm._s(_vm.$t("funds.transaction_history.no_deposit_data"))+" ")]):_vm._e(),(_vm.tableNoData === false && _vm.typeHistory === 'withdraw')?_c('div',{staticClass:"text-no-data w-100 text-center"},[_vm._v(" "+_vm._s(_vm.$t("funds.transaction_history.no_deposit_data"))+" ")]):_c('div',{staticClass:"text-no-data w-100 text-center"},[(_vm.typeHistory !== 'deposit')?[_vm._v(" "+_vm._s(_vm.msgEmptyData || _vm.$t("common.datatable.no_data"))+" ")]:_vm._e()],2)])])])])]:_vm._e(),(!_vm.getDataSuccess)?[_c('tr',{staticClass:"empty-data",style:({ height: _vm.heightOfBody + 'px'})},[_vm._m(0)])]:_vm._e(),_vm._t("end_row")],2)]),(this.rows.length === 0 && !_vm.checkNoDataWhenAuthenticated && _vm.getDataSuccess && _vm.typeEmpty == true)?[_c('div',{staticClass:"empty-row text-center"},[_c('div',{staticClass:"fixed-empty"},[_c('img',{staticClass:"icon-search text-center",attrs:{"src":require(`@/assets/images/landing/icon-nodata${
                        _vm.theme === 'light-theme' ? '' : '-dark'
                      }.svg`)}}),_c('div',{staticClass:"w-100 text-center text-empty"},[_vm._v(" "+_vm._s(_vm.msgEmptyData)+" ")])])])]:_vm._e()],2),(_vm.lastPage > 1)?[_c('pagination2',{ref:"pagination",staticClass:"text-right",attrs:{"per-page":_vm.perPage,"records":_vm.totalRecord,"pageParent":_vm.page},on:{"Pagination:page":_vm.onPageChange}})]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticStyle:{"padding-right":"0!important"},attrs:{"colspan":"50"}},[_c('div',{staticClass:"fixed-empty spinner"},[_c('div',{staticClass:"spinner-border",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])])])
}]

export { render, staticRenderFns }