 import rf from '@/request/RequestFactory';

const actions = {
  getSymbol({ commit }) {
    return new Promise((resolve, reject) => {
      rf.getRequest('MarginRequest').getSymbol().
        then(res => {
          commit('updateSymbol', res.data);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        })
    });
  },
  getSymbolRequest({}, params) {
    return rf.getRequest('MarginRequest').getSymbol(params);
  },
};

const getters = {
  symbol: (state, symbol) => {
    const s = state.symbol.find(i=>i.symbol==symbol)
    return s
  }
}

const mutations = {
  updateSymbol: (state, payload) => {
    const s = state.symbol.find(i=>i.symbol==symbol)
    state.symbol = { ...payload }
  }
}

export default {
  actions,
  getters,
  mutations,
  state: {
    symbol: []
  },
};