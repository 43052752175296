<template>
  <div class="SafetyVerificationModal">
    <modal :name="name" :width="sizeScreen" :resetStatus="resetStatus">
      <div slot="body">
        <div class="content_login_form_container">
          <div class="content_login_form">
            <div class="title-popup">{{ $t("safety_verification.title") }}</div>
            <div>
              <div class="countdown">
                <div class="label-input" v-if="isUsePhoneNumber">
                  {{ $t("safety_verification.mobile") }}:
                  {{ user?.phone_number }}
                </div>
                <div class="label-input" v-else>
                  {{ $t("safety_verification.email") }}: {{ user?.email }}
                </div>
                <p
                  class="count-down-timer"
                  v-if="
                    isStatusCode !== 1 ||
                    (!disabledInputCode && isStatusCode === 3)
                  "
                >
                  {{ formatTime }}
                </p>
              </div>

              <div
                class="mask-input"
                v-if="disabledInputCode && isStatusCode === 3"
              ></div>

              <otp-input
                :class="
                  isStatusCode === 3 && disabledInputCode && 'disable-input'
                "
                name="otp-phone"
                ref="otpInput"
                :inputClasses="
                  !errors.has('otp-phone') ? 'otp-input' : 'otp-phone-error'
                "
                separator=""
                :num-inputs="6"
                :should-auto-focus="true"
                :is-input-num="true"
                @on-change="handleInputCodePhone"
                @on-complete="handleInputCodePhone"
                input-type="number"
              />
              <div class="error-otp" v-if="errors.has('otp-phone')">
                {{ errors.first("otp-phone") }}
              </div>
            </div>
            <div class="button-send">
              <div>
                {{
                  $t(
                    isUsePhoneNumber
                      ? "safety_verification.des1_sms"
                      : "safety_verification.des1_email"
                  )
                }}
              </div>
              <button
                @click="getVerifyCode"
                :disabled="isGetCodeButtonDisabled || disabledInputCode"
              >
                {{ typeVerifyCode }}
              </button>
            </div>

            <div>
              <div class="label-input">
                {{ $t("safety_verification.label_gg_code") }}
              </div>
              <otp-input
                ref="otp"
                id="otp-field"
                :inputClasses="!errors.has('otp') ? 'otp-input' : 'otp-error'"
                separator=""
                :num-inputs="6"
                :is-input-num="true"
                @on-change="handleInputCodeGG"
                @on-complete="handleInputCodeGG"
                input-type="number"
              />
              <div class="error-otp" v-if="errors.has('otp')">
                {{ errors.first("otp") }}
              </div>
            </div>
            <div class="button-send">
              <div>{{ $t("safety_verification.des_gg_code") }}</div>
            </div>
            <!-- <div class="input-group authenticator">
              <currency-input-otp
                :placeholder="$t('common.placeholders.authen_code')"
                type="text"
                maxlength="6"
                id="otp-field"
                class="input"
                :class="{ error: errors.has('otp') }"
                name="otp"
                v-model="otp"
                data-vv-as="authentication code"
              ></currency-input-otp>
              <div class="invalid-feedback" v-show="errors.has('otp')">
                {{ errors.first("otp") }}
              </div>
            </div> -->
            <div class="note-otp" @click="showTips()">
              {{ $t("safety_verification_note") }}
            </div>
            <div>
              <button
                :disabled="
                  isDisableButton ||
                  otp?.length < 6 ||
                  otpPhone?.length < 6 ||
                  !disabledInputCode
                "
                class="btn-submit"
                @click="submitOtpForm"
              >
                {{ $t("kyc.btn_verify") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <receive-code-modal></receive-code-modal>
  </div>
</template>

<script>
import Modal from "@/components/shared_components/common/Modal";
import CurrencyInputOtp from "@/components/shared_components/common/CurrencyInputOtp.vue";
import OtpInput from "@bachdgvn/vue-otp-input";
import rf from "@/request/RequestFactory";
import ReceiveCodeModal from "@/pages/common/ReceiveCodeModal";

const TIME = 10 * 60;

const TypeVerifyCode = {
  getCode: 1,
  resent: 2,
  confirm: 3,
};

export default {
  inject: ["$validator"],
  components: {
    Modal,
    CurrencyInputOtp,
    OtpInput,
    ReceiveCodeModal,
  },
  data() {
    return {
      otpPhone: "",
      otp: "",
      // isDisableLoginOtp: false,
      otpRequest: "",
      sizeScreen: window.matchMedia("(max-width: 600px)").matches
        ? "370"
        : "560",
      isStatusCode: TypeVerifyCode.getCode,
      countdownInterval: null,
      countdown: TIME,
      disabledInputCode: false,
      user: {},
    };
  },
  props: {
    name: { type: String, default: "SafetyVerificationModal" },
    submit: { type: Function },
    params: {
      type: Object,
      default: () => {
        return {};
      },
    },
    errorMessenger: { type: String },
    isDisableButton: { type: Boolean, default: false },
    contact_email: { type: String, default: "" },
  },
  computed: {
    // ...mapState({
    //   user: (state) => state.user,
    // }),
    isUsePhoneNumber() {
      if (
        this.user?.security_setting?.phone_verified &&
        this.user?.security_setting?.is_enable_sms
      ) {
        return true;
      } else return false;
    },
    typeVerifyCode() {
      switch (this.isStatusCode) {
        case TypeVerifyCode.resent:
          return this.$t("register.verify_code.resent");
          break;
        case TypeVerifyCode.confirm:
          return this.$t("register.verify_code.confirm");
          break;
        default:
          return this.$t("Send");
      }
    },
    isGetCodeButtonDisabled() {
      return (
        this.isStatusCode === TypeVerifyCode.confirm && this.otpPhone.length < 6
      );
    },
    formatTime() {
      const minutes = Math.floor(this.countdown / 60);
      const seconds = this.countdown % 60;
      return `${minutes < 10 ? "0" : ""}${minutes}:${
        seconds < 10 ? "0" : ""
      }${seconds}`;
    },
  },
  watch: {
    isDisableButton: function (newVal) {
      if (newVal === true) {
        this.startSubmit();
      } else {
        this.endSubmit();
        if (!this.errors.has("otp")) {
        this.resetStatus();
      }
      }
    },
  },
  methods: {
    resetErrors() {
      if (this.errors.any()) {
        this.errors.clear();
      }
    },
    resetStatus() {
      this.isStatusCode = TypeVerifyCode.getCode;
      this.disabledInputCode = false;
      clearInterval(this.countdownInterval);
      this.countdownInterval = null;
      this.countdown = TIME;
      this.otpPhone = "";
    },
    validate() {
      if (window._.isEmpty(this.otp)) {
        this.$validator.errors.add({
          field: "otp",
          msg: window.i18n.t("funds.withdrawals.errors.otp_required"),
        });
      } else if (this.otp.length !== 6) {
        this.$validator.errors.add({
          field: "otp",
          msg: window.i18n.t("funds.withdrawals.errors.otp_length_digits"),
        });
      } else if (this.errorMessenger) {
        this.$validator.errors.add({ field: "otp", msg: this.errorMessenger });
      }
      return !this.$validator.errors.has("otp");
    },

    handleGetCode() {
      return new Promise((resolve, reject) => {
        (this.isUsePhoneNumber
          ? rf.getRequest("UserRequest").resendCodeToPhone()
          : rf.getRequest("UserRequest").resendCodeToEmail({
              address: this.params?.blockchain_address,
              currency: this.params?.currency,
              quantity: this.params?.amount,
            })
        )
          .then((res) => {
            Message.success(
              window.i18n.t(
                this.isUsePhoneNumber
                  ? "validation.get_code_sms_success"
                  : "validation.get_code_success"
              )
            );
            resolve(res);
          })
          .catch((e) => {
            if (e.response.data.message === "validation.ip-active.") {
              this.errors.add({
                field: "error",
                msg: this.$t(`${e.response.data.message}`),
              });
            } else {
              this.errors.add({
                field: "otp-phone",
                msg: this.$t(`${e.response.data.message}`),
              });
            }
          });
      });
    },

    handleVerifyCode() {
      const data = {
        code: this.otpPhone,
      };
      return new Promise((resolve) => {
        (this.isUsePhoneNumber
          ? rf.getRequest("UserRequest").verifyPhoneNumber(data)
          : rf.getRequest("UserRequest").verifyCodeEmail(data)
        )
          .then((res) => {
            resolve(res);
          })
          .catch((e) => {
            this.errors.add({
              field: "otp-phone",
              msg: this.$t(`${e.response.data.message}`),
            });
            clearInterval(this.countdownInterval);
            this.countdownInterval = null;
            this.isStatusCode = TypeVerifyCode.resent;
          });
      });
    },

    getVerifyCode() {
      this.resetErrors();
      if (this.isStatusCode === TypeVerifyCode.getCode) {
        this.handleGetCode().then(() => {
          this.isStatusCode = TypeVerifyCode.confirm;
          this.startCodeCountDown();
        });
      } else if (this.isStatusCode === TypeVerifyCode.confirm) {
        this.handleVerifyCode().then((res) => {
          this.disabledInputCode = true;
          clearInterval(this.countdownInterval);
        });
      } else {
        this.handleGetCode().then(() => {
          this.otpPhone = "";
          this.$refs.otpInput.clearInput();
          this.countdown = TIME;
          this.isStatusCode = TypeVerifyCode.confirm;
          this.startCodeCountDown();
        });
      }
    },

    startCodeCountDown() {
      if (this.countdownInterval) {
        clearInterval(this.countdownInterval);
      }

      this.countdownInterval = setInterval(() => {
        this.countdown--;
        if (this.countdown === 0) {
          clearInterval(this.countdownInterval);
          this.countdownInterval = null;
          this.isStatusCode = TypeVerifyCode.resent;
        }
      }, 1000);
    },

    handleInputCodePhone(value) {
      this.resetErrors();
      this.otpPhone = value;
    },

    handleInputCodeGG(value) {
      this.resetErrors();
      this.otp = value;
    },

    submitOtpForm() {
      this.$validator.errors.remove("otp");
      if (!this.validate()) {
        return;
      }
      this.otpRequest = this.otp;
      const params = Object.assign({}, this.params, { otp: this.otpRequest });
      this.startSubmit();
      this.submit(params);
      //   .then(isErrorOtp => {
      //   if(isErrorOtp) {
      //     this.errors.add({
      //       field: 'otp',
      //       msg: this.$t('validation.otp_incorrect')
      //     });
      //   }
      // });
    },
    handleWindowResize() {
      if (window.innerWidth > 600) {
        this.sizeScreen = "560";
      } else {
        this.sizeScreen = "370";
      }
    },
    showTips() {
      CommonModal.show("ReceiveCodeModal");
      CommonModal.hide("SafetyVerificationModal");
      document.body.style.overflow = "hidden";
    },
    getUser() {
      rf.getRequest("UserRequest")
        .getCurrentUser(true)
        .then((res) => {
          this.user = Object.assign({}, this.user, res.data);
        });
    },
  },
  mounted() {
    this.isStatusCode = TypeVerifyCode.getCode;
    this.getUser();
    window.addEventListener("resize", this.handleWindowResize);
    this.$on("hideCommonModal", (name) => {
      if (name === this.name) {
        this.otp = "";
        this.errors.remove("otp");
      }
    });
  },
};
</script>
<style lang="scss">
@import "@/assets/sass/variables";
.SafetyVerificationModal {
  .modal-header {
    padding-top: 30px !important;
    padding-right: 20px !important;
  }
  .modal-body {
    margin-top: -37px;
  }
  .content_login_form_container {
    padding: 0px 10px;
  }

  .mask-input {
    width: 460px;
    height: 72px;
    position: absolute;
    top: 114px;

    @media screen and (max-width: 600px) {
      height: 45px;
      width: 320px;
    }
  }

  .modal-footer {
    padding-top: 0px !important;
    padding-bottom: 12px !important;
  }

  .error-otp {
    font-size: 14px;
    color: #e02d3c;
  }

  .otp-input {
    &:focus {
      border: 1px solid #00ddb3;
    }
  }

  .disable-input {
    .otp-input {
      color: #acacac;
    }
  }

  .otp-input,
  .otp-phone-error,
  .otp-error {
    width: 68.5px;
    height: 72px;
    padding: 5px;
    margin: 10px 10px 10px 0;
    font-size: 34px;
    font-weight: 500;
    color: var(--text-primary-color);
    border-radius: 6px;
    border: none;
    text-align: center;
    background: var(--background-color-second);
  }
  .otp-phone-error,
  .otp-error {
    border: 1px solid #e02d3c !important;
    color: #e02d3c;
  }
  .otp-input::-webkit-inner-spin-button,
  .otp-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media screen and (max-width: 600px) {
    .otp-input,
    .otp-phone-error,
    .otp-error {
      width: 45px;
      height: 45px;
      padding: 5px;
      margin: 10px 10px 10px 0;
      font-size: 18px;
    }
  }
}
@media screen and (max-width: 992px) {
  .SafetyVerificationModal {
    .modal-body {
      padding: 0 15px 30px !important;
    }
  }
}
</style>
<style lang="scss" scoped>
@import "@/assets/sass/variables";
.SafetyVerificationModal {
  .title-popup {
    font-family: $font-inter-bold;
    font-size: 30px;
    font-weight: 600;
    color: var(--text-primary-color);
    text-align: center;
    margin-bottom: 30px;
  }

  .countdown {
    display: flex;
    justify-content: space-between;

    .count-down-timer {
      font-size: 14px;
      font-size: 14px;
      color: var(--text-close);
    }
  }

  .button-send {
    display: flex;
    font-size: 14px;
    font-weight: 400;
    color: var(--text-close);
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 49px;

    button {
      padding: 6px 16px;
      color: #333;
      border-radius: 8px;
      background: #00ddb3;
      font-size: 16px;
      height: 38px;

      &:disabled {
        background-color: var(--button-disabled-modal) !important;
        border-color: $border-bright-gray !important;
        color: var(--text-btn-disable) !important;
      }
      &:hover:enabled,
      &:focus {
        background-color: $color-aquamarine !important;
        border-color: $border-blue-custom !important;
        color: $color-grey-dark !important;
      }
    }
  }
  .note-otp {
    font-size: 14px;
    font-weight: 400;
    color: var(--text-primary-color);
    text-decoration-line: underline;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
  .label-input {
    font-size: 16px;
    color: var(--text-primary-color);
  }
  .content_login_form {
    width: 100%;
    .icon {
      margin: 0 auto 20px;
      display: block;
    }
    .box-title {
      margin-bottom: 40px;
      font-family: $font-inter-bold;
      color: var(--dark-1);
      font-size: 34px;
      line-height: 40px;
      text-align: center;
    }
    .input {
      background: var(--background-color-second);
      border-radius: 8px;
      padding: 14px 15px;
      border: none;
      width: 100%;

      font-size: 18px;
      line-height: 21px;
      font-family: $font-inter-regular;
      color: var(--text-primary-color);
      &.error {
        border: 1px solid $text-color-red;
      }
    }
    .invalid-feedback {
      color: $text-color-red;
      font-family: $font-inter-regular;
      font-size: 14px;
      line-height: 17px;
      text-align: left;
      margin-top: 8px;
    }
    .submit {
      width: 100%;
      height: 45px;
      transition: all ease-out 0.2s;
      color: $color-white;
    }
    .btn-submit {
      border-radius: 8px;
      padding: 15.5px 10px;
      background: $color-aqua-green;
      font-family: $font-inter-regular;
      color: $dark-1;
      font-size: 18px;
      line-height: 21px;
      width: 100%;
      margin-top: 20px;
      &:disabled {
        background: var(--button-disabled-modal);
        color: var(--text-btn-disable) !important;
        cursor: not-allowed;
      }
    }
  }
  .lost_verify_code {
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
    .lost_verify_click {
      display: inline-block;
      font-family: $font-inter-regular;
      font-size: 16px;
      line-height: 19px;
      color: $color-aqua-green;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  @media screen and (max-width: 992px) {
    .content_login_form {
      .box-title {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 20px;
      }

      .icon {
        width: 104px;
        height: 80px;
      }
      .input {
        &::placeholder {
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
    .lost_verify_code {
      .lost_verify_click {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
  @media screen and (max-width: 992px) {
    .SafetyVerificationModal {
      :deep(.modal-dialog) {
        max-width: 60%;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .SafetyVerificationModal {
      :deep(.modal-dialog) {
        max-width: 60%;
      }
    }
  }
  @media screen and (max-width: 676px) {
    .SafetyVerificationModal {
      :deep(.modal-dialog) {
        max-width: 70%;
      }
    }
  }
  @media screen and (max-width: 576px) {
    .SafetyVerificationModal {
      :deep(.modal-dialog) {
        max-width: 80%;
      }
    }
  }
  @media screen and (max-width: 476px) {
    .SafetyVerificationModal {
      :deep(.modal-dialog) {
        max-width: 90%;
      }
    }
  }
  @media screen and (max-width: 400px) {
    .SafetyVerificationModal {
      :deep(.modal-dialog) {
        max-width: 94%;
      }
    }
  }
}
</style>
