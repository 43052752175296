<template>
  <!-- 邀请奖励 -->
  <div class="lading-page">
    <div class="invite_detail_banner">
      <div class="invite_detail_banner_bg">
        <div class="tit">{{ $t('high_rebate_detail.rebate_tit') }}</div>
        <div class="subtit">{{ $t('high_rebate_detail.rebate_subtit') }}</div>
        <div
          class="rebate_rate"
          v-html="$t('high_rebate_detail.rebate_rate')"
        ></div>
        <br />
        <div class="join" @click="joinAmanpuri">
          {{ $t('high_rebate_detail.rebate_joinin') }}
        </div>
        <div class="invite_step">
          <div
            class="step_item"
            v-for="(item, index) in $t(
              'high_rebate_detail.rebate_register_step'
            )"
            :key="index"
          >
            <div class="step_logo">
              <img :src="item.step_img" />
            </div>
            <div class="step_tit">{{ item.step_num }}</div>
            <div class="step_desc">{{ item.step_desc }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 五层返佣 -->
    <div class="invite_detail_rating fl_c">
      <div class="rating">
        <div class="l_con">
          <div class="tit">{{ $t('high_rebate_detail.rebate_five_tit') }}</div>
          <div class="desc mb_22">
            {{ $t('high_rebate_detail.rebate_five_subdesc') }}
          </div>
          <div class="desc">
            {{ $t('high_rebate_detail.rebate_five_desc') }}
          </div>
          <div class="btn" @click="joinAmanpuri">
            {{ $t('high_rebate_detail.rebate_five_become') }}
          </div>
          <div class="watch_tips">
            {{ $t('high_rebate_detail.rebate_five_tips') }}
          </div>
          <div
            class="tips_link"
            @click="checkTips('rebate_public', $t('i18n.lang'), '360039635991')"
          >
            {{ $t('high_rebate_detail.rebale_article_link') }}
          </div>
        </div>
        <div class="r_con">
          <div class="item">
            5ST <br />
            TIER REFERRALS
          </div>
          <div class="item">
            4ST <br />
            TIER REFERRALS
          </div>
          <div class="item">3ST TIER REFERRALS</div>
          <div class="item">2ST TIER REFERRALS</div>
          <div class="item">1ST TIER REFERRALS</div>
          <div class="item">40%</div>
          <div class="person">
            <img src="@/assets/images/rebate/high_d_person.png" />
          </div>
        </div>
      </div>
    </div>
    <div class="invite_detail_explain">
      <div class="invite_step">
        <div
          class="step_item"
          v-for="(item, index) in $t('high_rebate_detail.rebate_explain')"
          :key="index"
        >
          <div class="step_logo">
            <img
              :src="'@/assets/images/rebate/high_d_compute_' + index + '.png'"
            />
          </div>
          <div class="step_tit">{{ item.tit }}</div>
          <div class="step_desc" v-html="item.desc"></div>
        </div>
      </div>
    </div>
    <!-- 用户独享 -->
    <div class="invite_detail_unique">
      <h2>{{ $t('high_rebate_detail.rebate_unique_tit') }}</h2>
      <div
        class="item"
        v-for="(item, index) in $t('high_rebate_detail.rebate_unique')"
        :key="index"
      >
        <img :src="'@/assets/images/rebate/high_d_char' + index + '.png'" />
        <div class="item_r_txt">
          <span class="tit">{{ item.tit }}</span>
          <span class="subtit" v-html="item.subtit"></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Utils from '@/common/Utils'
export default {
  data() {
    return {
      // articleLink:'https://amanpuri-exchange.zendesk.com/hc/en-us/articles/360039635991'
    }
  },
  computed: {
    articleLink() {},
  },
  methods: {
    checkTips(type, lang, articleNum) {
      let url = ``
      lang = lang == 'zh' ? 'zh-cn' : lang == 'ja' ? 'ja' : 'en-us'
      url = Utils.replaceTmpVar(lang, articleNum)
      window.open(url, '_blank')
    },
    joinAmanpuri() {
      let url = `https://amanpuri.io/referral`
      window.open(url, '_blank')
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/assets/sass/variables';
$min-w: 1200px;
$max-w: 90%;
$bc: #e1dfe0;
$bg: #f8f8f8;
$fc: #959394;
$bc_0E2945: #0e2945;
$c_848484: #848484;
$f_10: 10px;
div,
p {
  font-family: 'Roboto-Regular', sans-serif;
}

.mt_40 {
  margin-top: 40px;
}

.mt_10 {
  margin-top: 10px;
}

.fl_r {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.fl_c {
  display: flex;
  flex-direction: column;
}

.lading-page {
  min-width: 1200px;
  max-width: 100%;
  background-color: $color-white;
  .invite_detail_banner {
    background-color: $bc_0E2945;
    height: 606px;
    position: relative;
    .invite_detail_banner_bg {
      min-width: 1024px;
      height: 100%;
      position: absolute;
      top: 0px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
      background: url('@/assets/images/rebate/high_detail_b.png')
        no-repeat;
      background-size: 100% 100%;
      color: #fff;
      text-align: center;

      .tit {
        font-size: 22px;
        font-weight: 600;
        margin-top: 134px;
      }
      .subtit {
        font-size: 12px;
        font-weight: 600;
        margin: 14px auto 22px;
      }
      .rebate_rate {
        font-size: 18px;
        padding: 5px 30px;
        background-color: rgba(255, 255, 255, 0.4);
        display: inline-block;
        border-radius: 10px;
        margin-bottom: 40px;
        letter-spacing: 3px;
        text-indent: 3px;
        cursor: default;
      }
      .join {
        font-size: 12px;
        font-weight: 600;
        display: inline-block;
        background-color: #ff7133;
        padding: 6px 35px;
        border-radius: 30px;
        letter-spacing: 3px;
        margin-bottom: 60px;
        text-indent: 3px;
        cursor: pointer;
      }
    }
  }
  .invite_detail_rating {
    width: 60%;
    margin: 0px auto;
    .rating {
      padding: 54px 0px 76px 0px;
      cursor: default;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .l_con {
        .tit {
          font-size: 15px;
          font-weight: 600;
          margin-bottom: 26px;
        }
        .desc {
          font-size: $f_10;
          color: $c_848484;
          width: 300px;
          line-height: 18px;
        }
        .mb_22 {
          margin-bottom: 22px;
        }
        .btn {
          display: inline-block;
          background-color: #71dcd3;
          font-size: 12px;
          color: #fff;
          padding: 5px 10px;
          border-radius: 8px;
          margin: 30px 0px 116px 30px;
        }
        .watch_tips {
          font-size: 15px;
          font-weight: 600;
          margin-bottom: 22px;
        }
        .tips_link {
          font-size: $f_10;
          color: $c_848484;
          cursor: pointer;
        }
      }
      .r_con {
        width: 300px;
        height: 310px;
        background: url('@/assets/images/rebate/high_rebate_rate.png')
          no-repeat;
        background-size: 100% 100%;
        text-align: center;
        color: #fff;
        .item {
          font-size: 16px;
          transform-origin: center;
          transform: scale(0.5);
        }
        .item:nth-child(1) {
          transform: scale(0.5);
          margin-top: 22px;
        }
        .item:nth-child(3) {
          margin: 8px auto 20px;
          transform: scale(0.6);
        }
        .item:nth-child(4) {
          transform: scale(0.7);
        }
        .item:nth-child(5) {
          margin-top: 20px;
          transform: scale(0.8);
        }
        .item:nth-child(6) {
          margin-top: 50px;
        }
        .person {
          width: 80px;
          height: 80px;
          margin: 20px auto;
        }
      }
    }
  }
  .invite_detail_explain {
    background-color: #0e2945;
    padding: 50px 0px;
  }

  .invite_step {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    cursor: default;
    .step_item {
      flex: 1;
      padding: 0px 10px;
      box-sizing: border-box;
    }
    .step_logo {
      width: 50px;
      height: 50px;
      margin: 0px auto;
    }
    font-family: 'Roboto-Regular', sans-serif;
    color: #fff;
    text-align: center;
    .step_tit {
      font-size: 14px;
      margin: 22px auto 10px;
    }
    .step_desc {
      font-size: 10px;
      /* width: 165px; */
      line-height: 16px;
      margin: 0px auto;
    }
  }
  .invite_detail_explain {
    .invite_step {
      width: 60%;
      margin: 0px auto;
    }
  }
  .invite_detail_unique {
    width: 60%;
    height: auto;
    padding: 58px 0px 80px;
    margin: 0px auto;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
    h2 {
      flex: 0 0 100%;
      text-align: center;
      margin-bottom: 50px;
      font-size: 18px;
      font-weight: 600;
    }
    .item {
      display: inline-block;
      flex: 0 0 50%;
      margin-bottom: 35px;
      /* padding-left: 10%; */
      display: flex;
      flex-direction: row;
      align-items: center;
      img {
        width: auto;
        height: auto;
      }
      .item_r_txt {
        display: inline-block;
        max-width: 85%;
        margin-left: 10px;
        .tit {
          font-size: 10px;
          display: block;
          font-weight: 600;
        }
        .subtit {
          font-size: 8px;
        }
      }
    }
  }
  .invite_detail_banner_bg {
    .invite_step {
      .step_desc {
        width: 170px;
      }
    }
  }
}
</style>
