<template>
  <div id="list-address">
    <div class="bg_right_address">
      <div class="group_head_right_add">
        <div class="dropdown-block">
          <div class="checked-group">
            <div class="checkbox-input">
              <input
                type="checkbox"
                id="checkbox_address_2"
                :checked="(lstChecked.split('--').length === originAddresses.length) && lstChecked !== ''" @change="onChangeCheckboxAll($event.target.checked)" @click="chkAllClick($event.target.checked)"
              />
              <label for="checkbox_address_2"></label>
            </div>
            <span>{{ $t(selectOptions.find(item => item.value == selectedOption).name) }}</span>
          </div>
          <!-- <input type="checkbox" :checked="(lstChecked.split('--').length === originAddresses.length) && lstChecked !== ''" @change="onChangeCheckboxAll($event.target.checked)" @click="chkAllClick($event.target.checked)"> -->
          <span class="icon_drop_checkbox icon-arrow1" v-if="!toggleSelectOptionsMenu " @click="toggleSelectOptionsMenu = !toggleSelectOptionsMenu"></span>
          <span class="icon_drop_checkbox icon-arrow2" v-else @click="toggleSelectOptionsMenu = !toggleSelectOptionsMenu" v-click-outside="sizeScreen > 992 && clickOutside"></span>

          <ul class="menu" v-if="toggleSelectOptionsMenu">
            <li v-for="(option, index) in selectOptions" :key="index">
              <span @click="onSelectedOption(option.value)" :class="option.value == selectedOption ? 'selectedOption' : ''">{{ $t(option.name) }}</span>
            </li>
          </ul>
        </div>
          <button class="button_fleft button_left1" :disabled="!isAddWhitelisted" @click="onAddAddressesWhitelist()">
            {{ $t('address.add_whitelist') }}
          </button>
          <button class="button_fleft button_left2" :disabled="!isRemoveWhitelisted" @click="onRemoveAddressesWhitelist()">
            {{ $t('address.remove_whitelist') }}
          </button>
          <button class="button_fleft button_left3 btn-remove"
            :disabled="!isAddWhitelisted && !isRemoveWhitelisted" @click="onDeleteAddresses()">
            {{$t('address.delete') }}
          </button>
          <span class="favourite-box fright" @click="onlyShowAddressesWhitelist()">
            <i class="icon" :class="showWhitelisted ? 'icon-star' : 'icon-star-line'"></i>
            <div class="tooltip_favourite">{{ $t('address.only_dis') }}</div>
          </span>
      </div>
      <div class="group_input">
        <div class="input_text_icon">
          <input :maxlength="maxLengthInput" type="text" class="search_input" :placeholder="$t('notice.search')" v-model="searchKey">
          <img src="/images/icon/icon-search.svg" alt="icon" class="icon_search">
        </div>
      </div>

      <div class="toolbarTable">
        <div class="datatable">
          <data-table
            :getData="getData"
            @DataTable:finish="onDatatableFinish"
            :widthTable="'100%'"
            :msgEmptyData="$t('address.table.empty_msg')"
            :classHeader="'header-css'" class="table_min" ref="datatable" >
            <th class="col-checkbox"></th>
            <th class="col-coin">{{ $t('address.coin') }}</th>
            <th class="col-label">{{ $t('address.label') }}</th>
            <th class="col-address">{{ $t('address.address') }}</th>
            <th class="col-memo">{{ $t('address.memo') }}</th>
            <th class="col-del"></th>
            <template slot="body" slot-scope="props">
              <tr>
                <td class="col-checkbox">
                  <div class="favourite-box fright">
                    <!-- <input type="checkbox" :checked="lstChecked.indexOf('-' + props.item.id + '-') >= 0" @change="onChangeCheckbox(props.item, $event.target.checked)" @click="checkItemClick(props.item, $event.target.checked)" /> -->
                    <div class="checked-group">
                      <div class="checkbox-input">
                        <input
                          type="checkbox"
                          :id="`checkbox-item-${props.index}`"
                          :checked="lstChecked.indexOf('-' + props.item.id + '-') >= 0" @change="onChangeCheckbox(props.item, $event.target.checked)" @click="checkItemClick(props.item, $event.target.checked)"
                        />
                        <label :for="`checkbox-item-${props.index}`"></label>
                      </div>
                    </div>
                  </div>
                  <i @click="onAddAddressWhitelist(props.item)" :class="props.item.is_whitelist ? 'icon-star' : 'icon-star-line'" alt="icon"></i>
                  <!-- <span class="icon-star2" @click="onAddAddressWhitelist(props.item)"></span> -->
                </td>
                <td class="col-coin">
                  <!-- <img :src="`/images/color_coins/${props.item.coin}.png`" class="img_icon" /> -->
                  {{ props.item.coin | uppercase}}
                </td>
                <td class="col-label" >
                  <span >{{ props.item.wallet_name | shortText(8) }}
                    <div class="tiptool" >
                     <span >{{ props.item.wallet_name }}</span>
                  </div>
                  </span>
                </td>
                <td class="col-address">
                  <div>{{ props.item.wallet_address }}</div>
                </td>
                
                <td class="col-memo">
                  <span v-if="props.item.wallet_sub_address">{{ props.item.wallet_sub_address }}</span>
                  <span v-else>--</span>
                </td>
               
                <td class="col-del">
                  <span class="btn fs16 icon-close fl-right" @click="onRemoveAddress(props.item.id, props.item.wallet_address)"></span>
                </td>
              </tr>
            </template>
          </data-table>

        </div>
      </div>
    </div>
    <otp-modal :name="whitelistModalName" :submit="updateWalletsWhiteList" :params="getParamsAddAddressWhitelist()" :is-disable-button="isDisableButton" :contact_email="siteSetting.contact_email"/>
  </div>
</template>
<script>
import rf from '@/request/RequestFactory'
import OtpModal from '@/components/shared_components/funds/common/OtpModal';
import COMMON_CONST from '@/common/Const';
import {mapState} from 'vuex';

const ALL                       = 1;
  const NONE                      = 2;
  const NOT_WHITELISTED           = 3;
  const WHITELISTED               = 4;

  export default {
    components: {
      OtpModal,
    },
    props: {
      enableOtp          : { type: Boolean, default: false },
      enableWhiteList    : { type: Boolean, default: false },
    },
    data() {
      return {
        selectOptions: [],
        selectedOption: ALL,
        toggleSelectOptionsMenu: false,

        selectAllCheckbox: false,

        isAddWhitelisted: false,
        selectedAddAddressIds: [],

        isRemoveWhitelisted: false,
        selectedRemoveAddressIds: [],

        searchKey: '',
        originAddresses: [],
        params: {},
        showWhitelisted: false,

        whitelistModalName: 'otp_modal_add_whitelist',
        maxLengthInput: COMMON_CONST.MAX_LENGTH_INPUT,
        isDisableButton: false,
        lstChecked: '',
        siteSetting: {},
        sizeScreen: window.innerWidth,
        isRequestInProgress: true,


      }
    },
    watch: {
      searchKey() {
        this.$refs.datatable.rows = this.search();
      },
    },
    computed: {
      ...mapState({
        masterdata: state => state.masterdata,
        theme: state => state.userThemeMode,
      }),
    },
    methods: {
      clickOutside(event) {
        this.toggleSelectOptionsMenu = false
      },
      search() {
        const keyword = window._.toLower(this.searchKey);
        if(keyword.trim().length == 0) this.$refs.datatable.refresh();
        const result = [];
        let index = -1;
        window._.forEach(this.getAddresses(), item => {
          if (item.coin.includes(keyword)) {
            result[++index] = item;
          }
        });
        this.$refs.datatable.totalRecord = result.length;
        return result;
      },

      getData(params) {
        if(this.showWhitelisted == true) {
          params.isWhiteList = true;
        } 
        return rf.getRequest('UserRequest').getWithdrawalsAddresses(params);
      },

      onDatatableFinish() {
        // Reset data when reload datatable again.
        this.originAddresses = [];
        this.getAddresses();
        if(window._.isEmpty(this.originAddresses)) {
          this.isAddWhitelisted = false;
          this.isRemoveWhitelisted = false;
          this.selectAllCheckbox = false;
        }
      },

      refresh() {
        this.$refs.datatable.refresh();
      },

      initSelectOptions() {
        this.selectOptions.push({ value: ALL, name: 'address.all' });
        this.selectOptions.push({ value: NONE, name: 'address.none' });
        this.selectOptions.push({ value: NOT_WHITELISTED, name: 'address.not_whitelisted' });
        this.selectOptions.push({ value: WHITELISTED, name: 'address.whitelisted' });

        this.selectedOption = NONE; // Default is selected all of items.
      },

      onSelectedOption(value) {
        this.selectedOption = value;
        this.toggleSelectOptionsMenu = false;

        switch(this.selectedOption) {
          case ALL:
            this.chkAllClick(true);
            this.onChangeCheckboxAll(true);
            break;
          case NONE:
            this.chkAllClick(false);
            this.onChangeCheckboxAll(false);
            break;
          case NOT_WHITELISTED:
            this.updateCheckboxesWhitelisted(false);
            break;
          case WHITELISTED:
            this.updateCheckboxesWhitelisted(true);
            break;
          default:
            console.error("Selection incorrect!");
            break;
        }
      },

      onChangeCheckboxAll(isChecked) {
        this.selectAllCheckbox = isChecked;
        this.selectedOption = isChecked ? ALL : NONE;
        window._.forEach(this.getAddresses(), item => {
          this.updateStateCheckboxes(item, isChecked);
        });
        this.updateStateButtons();
      },

      onChangeCheckbox(item, isChecked) {
        this.updateStateCheckboxes(item, isChecked);
        this.updateStateButtons();
        //this.selectAllCheckbox = window._.filter(this.getAddresses(), item => item.isChecked).length === this.getAddresses().length;
      },

      updateStateCheckboxes(item, isChecked) {
        item.isChecked = isChecked;
        item.isAddWhitelisted = item.isChecked && !item.is_whitelist;
        item.isRemoveWhitelisted = item.isChecked && item.is_whitelist;
      },

      updateStateButtons() {
        this.isAddWhitelisted = window._.filter(this.getAddresses(), item => item.isAddWhitelisted).length > 0;
        this.isRemoveWhitelisted = window._.filter(this.getAddresses(), item => item.isRemoveWhitelisted).length > 0;
      },

      updateCheckboxesWhitelisted(isWhitelisted) {
        window._.forEach(this.getAddresses(), item => {
          this.checkItemClick(item, item.is_whitelist == isWhitelisted)
          this.onChangeCheckbox(item, item.is_whitelist == isWhitelisted)
        });
      },

      onlyShowAddressesWhitelist(showWhitelistWithoutTrigger) {
        if (!showWhitelistWithoutTrigger) {
          this.showWhitelisted = !this.showWhitelisted;
        }
        let showWhitelistedParam = { }
        if(this.showWhitelisted == true) {
          showWhitelistedParam.isWhiteList = true
        } 

        rf.getRequest('UserRequest').getWithdrawalsAddresses(showWhitelistedParam).then((res) => {
          this.$refs.datatable.rows = res.data.data;
          this.$refs.datatable.totalRecord = res.data.total;
        });
        // let data = this.getAddresses();
        // if (this.showWhitelisted) {
        //   data = window._.filter(this.$refs.datatable.rows, item => item.is_whitelist);
        // } else {
        //   if (!window._.isEmpty(this.searchKey)) {
        //     data = this.search();
        //   }
        // }
        // this.$refs.datatable.rows = data;
      },

      getAddresses() {
        const rows = this.$refs.datatable.rows;
        if (window._.isEmpty(this.originAddresses)) {
          this.originAddresses = rows;
        }
        // In case if has a new address
        // if (rows.length > this.originAddresses.length) {
        //   this.originAddresses = rows;
        // }
        return this.originAddresses;
      },

      getAddressById(data, id) {
        const result = window._.filter(data, item => item.id === id);
        return result ? result[0] : null;
      },

      onRemoveAddressesWhitelist() {
        this.selectedRemoveAddressIds = window._.chain(this.getAddresses())
          .filter(item => item.isRemoveWhitelisted)
          .map(item => item.id)
          .value();

        switch(this.selectedOption) {
          case ALL:
          case NONE:
            this.chkAllClick(false);
            this.onChangeCheckboxAll(false);
            break;
          case NOT_WHITELISTED:
          case WHITELISTED:
            this.updateCheckboxesWhitelisted(false);
            break;
          default:
            console.error("Selection incorrect!");
            break;
        }

        const params = { idWallets: this.selectedRemoveAddressIds, active: false };
        this.updateWalletsWhiteList(params);
      },

      onAddAddressesWhitelist() {
        this.selectedAddAddressIds = window._.chain(this.getAddresses())
          .filter(item => item.isAddWhitelisted)
          .map(item => item.id)
          .value();

        if (!this.enableWhiteList) {
          Message.error(window.i18n.t('address.turn_on_whitelist'));
          return;
        }
        if (this.enableOtp) {
          CommonModal.show(this.whitelistModalName);
          return;
        }

        switch(this.selectedOption) {
          case ALL:
          case NONE:
            this.chkAllClick(false);
            this.onChangeCheckboxAll(false);
            break;
          case NOT_WHITELISTED:
          case WHITELISTED:
            this.updateCheckboxesWhitelisted(false);
            break;
          default:
            console.error("Selection incorrect!");
            break;
        }        
        this.updateWalletsWhiteList(this.getParamsAddAddressWhitelist());
      },

      onAddAddressWhitelist(item) {
        if (!item.is_whitelist) {
          if (!this.enableWhiteList) {
            Message.error(window.i18n.t('address.turn_on_whitelist'));
            return;
          }
          if (this.enableOtp) {
            this.selectedAddAddressIds = [item.id];
            CommonModal.show(this.whitelistModalName);
            return;
          }
        }
        this.addAddressWhitelist(item.id, !item.is_whitelist);
      },

      addAddressWhitelist(addressId, isWhitelisted) {
        const params = { idWallets: [addressId], active: isWhitelisted };
        this.updateWalletsWhiteList(params);
      },

      updateWalletsWhiteList(params) {
        this.isDisableButton = true;
        rf.getRequest('AddressManagerRequest').updateWalletsWhiteList(params).then((res) => {
          this.onUpdateWalletWhiteList(params);
          this.isDisableButton = false;
          this.chkAllClick(false);
          this.onChangeCheckboxAll(false);
          this.$refs.datatable.refresh();
        }).catch(error => {
          this.isDisableButton = false;
          if (!error.response) {
            Message.error(window.i18n.t('common.message.network_error'));
          } else {
            this.onError(error.response.data.errors || []);
          }
        });
      },

      onUpdateWalletWhiteList(params) {
        CommonModal.hide(this.whitelistModalName);
        window._.forEach(this.getAddresses(), item => {
          if (params.idWallets.indexOf(item.id) > -1) {
            item.is_whitelist = params.active;
          }
        });
        //this.onSelectedOption(this.selectedOption);
        if (this.showWhitelisted) {
          this.onlyShowAddressesWhitelist(true);
        }
        Message.success(window.i18n.t('address.updated_success'));
      },

      getParamsAddAddressWhitelist() {
        return {
          idWallets: this.selectedAddAddressIds, active: true
        };
      },

      onDeleteAddresses() {
        const addressIds = window._.chain(this.getAddresses())
          .filter(item => item.isChecked)
          .map(item => item.id)
          .value();
        this.onRemoveAddress(addressIds);
      },

      onRemoveAddress(ids, walletAddress) {
        const msgContent = this.$t('address.confirm_remove_address');
        if (Array.isArray(ids)) {
          this.showModal(msgContent, 'primary', this.removeAddress, ids);
        } else {
          this.showModal(msgContent, 'primary', this.removeAddress,ids, walletAddress);
        }
      },
      removeAddress( addressIds, walletAddress) {
          const params = { idWallets: addressIds };
          rf.getRequest('AddressManagerRequest').removeWalletsAddress(params).then((res) => {
            if (Array.isArray(addressIds)) {
              for (var i = 0; i < addressIds.length; i++) {
                if (this.lstChecked.indexOf('-' + addressIds[i] + '-') >= 0) {
                  this.lstChecked = this.lstChecked.replace('-' + addressIds[i] + '-', '');
                }
              }
              Message.success(window.i18n.t('address.deleted_successfully'));
            } else {
              if (this.lstChecked.indexOf('-' + addressIds + '-') >= 0) {
                this.lstChecked = this.lstChecked.replace('-' + addressIds + '-', '');
              }
              Message.success(window.i18n.t('address.deleted_success'), `${this.$t('address.deleted_sub_success')} ${walletAddress.slice(0, 5)}...`);
            }
          })
              .then(() => {
                this.getData().then((res) => {
                  this.originAddresses = res.data.data;
                  if(this.showWhitelisted) {
                    this.$refs.datatable.rows = res.data.data.filter(item => item.is_whitelist);
                  } else {
                    this.$refs.datatable.rows = res.data.data
                  }
                })
              })
              .catch(error => {
                if (!error.response) {
                  Message.error(window.i18n.t('common.message.network_error'));
                } else {
                  this.onError(error.response.data.errors || []);
                }
              })
              .finally(() => {
                this.isRequestInProgress = true;
              });
      },

      showModal(msgContent, type, callback, params, walletAddress) {
        const icon = this.theme === 'light-theme' ? '/images/icon/icon-question-delete.svg' : '/images/icon/icon-question-delete-dark.svg'
        ConfirmationModal.show({
          type: type,
          title: this.$t('address.delete'),
          icon,
          content: msgContent,
          btnCancelLabel: window.i18n.t('common.action.no'),
          btnConfirmLabel: window.i18n.t('common.action.yes'),
          onConfirm: () => {
            if(this.isRequestInProgress) {
              this.isRequestInProgress = false;
              callback(params, walletAddress);
            }

          }
        });
      },

      onError(errors) {
        this.errors.clear();
        _.forEach(errors, (messages, field) => {
          _.forEach(messages, (message) => {
            this.errors.add(field, this.$t(`${message}`));
          });
        });
      },

      init() {
        this.initSelectOptions();
        this.getMasterData();
      },
      getMasterData() {
        window._.each(this.masterdata.settings, item => {
          this.siteSetting[item.key] = item.value;
        });
      },
      checkItemClick(item, isChecked) {
        if (isChecked && this.lstChecked.indexOf('-' + item.id + '-') < 0) {
          this.lstChecked += '-' + item.id + '-';
        }
        
        if (!isChecked && this.lstChecked.indexOf('-' + item.id + '-') >= 0) {
          this.selectAllCheckbox = false;
          this.lstChecked = this.lstChecked.replace('-' + item.id + '-', '');
        }
      },
      chkAllClick(isChecked) {
        window._.forEach(this.getAddresses(), item => {
          this.checkItemClick(item, isChecked);
        });
      },
      resizeHandler() {
        this.sizeScreen = window.innerWidth;
      },
    },
    mounted() {
      document.title = this.$t('menu.address') + ` – ${this.tabTitle}`;
      this.init();
      window.addEventListener("resize", this.resizeHandler);
      this.$on('UPDATED_LOCALE', () => {
      });
    },
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/sass/variables";
table{
  table-layout: fixed;
}

  #list-address {
    .bg_right_address{
      .group_head_right_add{
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        .dropdown-block{
          min-width: 147px;
          border: 1px solid var(--color-border-security);
          border-radius: 8px;
          position: relative;
          padding: 6px 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-right: auto;
          gap: 8px;
          height: 33px;
          .checkbox-input {
            width: 21px;
            height: 21px;
            cursor: pointer;
          }
          .checked-group {
            display: flex;
            gap: 8px;
            align-items: center;
            span {
              font-size: 18px;
              line-height: 24px;
              color: var(--dark-1);
              @media only screen and (max-width: 992px){
                font-size: 14px;
              }
            }
          }
          label {
            border-radius: 2px;
            margin: 0;
            width: 21px;
            height: 21px;

            &:after {
              width: 12px;
              height: 8px;
              left: 4px;
            }
          }
          span.icon_drop_checkbox {
            cursor: pointer;
            color: $dark-2;
          }
          &:hover {
            span.icon_drop_checkbox {
              color: $color-green;
            }
          }
          .menu {
            width: 100%;
            position: absolute;
            top: calc(100% + 2px);
            left: 0;
            background: var(--bgr-dropdown);
            box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
            border-radius: 8px;
            padding: 15px 0;
            z-index: 1;
            li {
              &:hover {
                background: var(--color-dark-5);
              }
              span {
                display: block;
                padding: 6px 20px;
                font-family: $font-inter-regular;
                color: var(--color-menu-dropdown);
                font-size: $font-medium-bigger;
                line-height: 17px;
                cursor: pointer;
                white-space: nowrap;
              }
              .selectedOption {
                color: $color-caribbean-green;
              }
            }
          }

        }
          .button_fleft{
            border-radius: 8px;
            white-space: nowrap;
            height: 33px;
            &.button_left1, &.button_left2 {
              @include btn-common(yes);
              padding: 6px 20px;
            }
            &.button_left3 {
              padding: 6px 20px;
              background: $text-color-red;
              color: $color-white;
              &:hover {
                opacity: 0.8;
              }
            }
  
            &:disabled {
              opacity: 0.5;
              pointer-events: none;
            }
          }
        .favor_active {
          .icon-star2 {
            &::before {
              color: $color-corn-pale !important;
            }
          }
        }
        .favourite-box{
          width: 32px;
          height: 33px;
          border: 1px solid var(--color-border-security);
          border-radius: 8px;
          position: relative;
          margin: 0;
          input{
            display: none;
          }
          .icon {
            font-size: 19px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, - 50%);
          }
          .icon-star {
            font-size: 24px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, - 50%);
          }
          input:checked+.icon-star2:after{
            color: $color-yellow;
          }
          .tooltip_favourite{
            display: none;
            background: var(--color-tooltip);
            border-radius: 8px; 
            padding: 10px;
            font-family: $font-inter-regular;
            color: $color-white;
            font-size: 14px;
            line-height: 18px;
            width: 227px;
            position: absolute;
            top: calc(100% + 8px);
            left: 0;
            z-index: 100;
          }
          &:hover{
             .tooltip_favourite{
              display: block;
              -webkit-transition: 0.5s;
              transition: 0.5s;
             }
          }
        }
      }
      .group_input {
        display: flex;
        margin-top: 12px;
        justify-content: flex-end;
        .input_text_icon{
          position: relative;
          input.search_input{
            background: var(--color-dark-5);
            border-radius: 8px;
            width: 379px;
            max-width: 100%;
            padding: 10px 70px 10px 15px;
            border: none;
            font-family: $font-inter-regular;
            color: var(--text-primary-color);
            font-size: 18px;
            line-height: 22px;
            &:hover, &:active, &:focus {
              border-color: $color-green;
            }
            &::placeholder {
              color: var(--text-search);
            }
          }
          .icon_search{
            position: absolute;
            right: 34px;
            top: 50%;
            transform: translateY(-50%);
            color: #9E9E9E;
          }
        }
      }
      .toolbarTable{
        margin-top: 30px;
       
        thead {
          th {
            color: var(--text-desc);
            background: var(--color-dark-5);
            padding: 21px 10px;
            cursor: default;
            &:first-child{
              width: 11.7%;
            }
            &:nth-child(2) {
              width: 11.21%;
            }
            &:nth-child(3) {
              width: 13.66%;
            }
            &:nth-child(4) {
              width: 42.93%;
            }
            &:nth-child(5) {
              width: 16.56%;
            }
            &:nth-child(6) {
              width: 3.94%;
            }
          }
          
        }
        tbody {
          td {
            color: var(--text-primary-color);
            padding: 23px 10px;
            text-align: left;
            .checkbox-input {
                width: 21px;
                height: 21px;
                cursor: pointer;
              }
              label {
                border-radius: 2px;
                margin: 0;
                width: 21px;
                height: 21px;

                &:after {
                  width: 12px;
                  height: 8px;
                  left: 4px;
                }
              }
            &:nth-child(5) {
              text-align: center;
            }

          }
          .col-address {
            max-width: 260px;
            overflow-wrap: break-word;
          }
          .col-checkbox {
            display: flex;
            justify-content: center;
            align-items: center;
            input {
              transform: scale(1.6);
            }
          }
          .col-label {
            span {
              position: relative;
              .tiptool {
                display: none;
                width: auto;
                padding: 10px;
                background: rgba(25, 25, 25, 0.8);
                border-radius: 8px;
                font-family: $font-inter-regular;
                color: $color-white;
                font-size: 14px;
                line-height: 18px;
                position: absolute;
                top: 35px;
                left: 10px;
                transform: translateY(-50%);
                z-index: 1000;

              }
              &:hover {
                .tiptool {
                  display: block;
                }
              }
            }
          }
        }
        .favourite-box {
          margin-right: 8px;
          // height: 22px;
        }
        .icon-star {
          font-size: 22px;
          cursor: pointer;
          width: 22px;
        }
        .icon-star-line {
          font-size: 19px;
          cursor: pointer;
          width: 22px;
        }
      }
    }

  }
  label{
    &:after {
      border-bottom: 2px solid #333333 !important;
      border-left: 2px solid #333333 !important;
    }

  }
  
  .icon-close {
    font-size: $font-small;
    color: $text-color-red;
    border: 0px;
    &:hover {
      color: $color-eden;
    }
}
@media screen and (max-width: 1528px) { 
    #list-address {
    .bg_right_address{
      .group_input {
        margin-top: -40px;
      }
    } 
  }
}
@media screen and (max-width: 1382px) { 
    #list-address {
    .bg_right_address{
      .group_input {
       .input_text_icon{
         input {
           width: 182px !important;
         }
       }
      }
    } 
  }
}
@media screen and (max-width: 1352px) { 
    #list-address {
    .bg_right_address{
      .group_input {
        margin-top: 12px;
       .input_text_icon{
          width: 100%;
          input {
            width: 100% !important ;
          }
       }
      }
    } 
  }
}

@media screen and (max-width: 992px) { 
  #list-address {
    .bg_right_address{
      .group_head_right_add{
        display: flex;
        .dropdown-block{
          min-width: 189px;
          margin-right: 0;
          padding: 3px 10px;
          .menu li span {
            font-size: 14px;
            line-height: 19px;
            padding: 6px 15px;
          }
        }
        .group_button {
          display: flex;
          gap: 10px;
          .button_fleft{
            font-size: 14px;
            line-height: 21px;
          }
        }
      }
      .group_input {
        justify-content: flex-end;
        .input_text_icon{
          width: 100%;
          input.search_input{
            width: 100%;
          }
          .icon_search{
            position: absolute;
            right: 34px;
            top: 50%;
            transform: translateY(-50%);
            color: #9E9E9E;
          }
        }
      }
    } 
  }
}
@media screen and (max-width: 576px) {
#list-address {
  .bg_right_address{
    .group_head_right_add {
      justify-content: space-between;
      gap: 15px;
      .button_fleft{
        font-size: 14px !important;
        line-height: 19px !important;
        &.button_left1{
          padding: 6px 20px;
          width: 139px;
          height: 31px;
        }
        &.button_left2{
          margin-right: auto;
          font-size: 14px;
          padding: 6px 12px;
          width: 181px;
          height: 31px;
        }
        &.button_left3 {
          width: 92px;
          height: 31px;
        }
      }
    }
    .toolbarTable {
      max-width: 94vw;
      overflow: auto;
      .datatable {
        min-width: 800px;
        }
      }
    .tooltip_favourite{
      width: 92vw !important;
      transform: translateX(-93%);
      }
    }
  }
}
  @media screen and (max-width: 375px) {
    #list-address {
      .bg_right_address{
        .tooltip_favourite{
          transform: translateX(0)
        }
      }
    }

  }

</style>
<style lang="scss">
#list-address{
  .table_min{
      table{
        table-layout: fixed;
      }
    }
}
</style>