<template>
  <div class="page-container">
    <div id="main-market">
      <h3 class="market-title">{{ $t("landing.market.title") }}</h3>

      <div class="group-coin row">
        <div class="page-container volume col-xs-12 col-md-6 col-xl-3 col-sm-12 custom-col">
          <p class="title">{{ $t("landing.title_volume") }}</p>

          <a
            v-for="(itemVolume, index) in volume"
            :key="index"
            class="main-volume"
            @click="selectedCurrenyPair( 'usdt', itemVolume.name)"
            :href="'/spot-exchange/basic?currency=usdt&coin=' + itemVolume.name"
            target="_blank"
          >
            <div class="img-coin" >
              <img
                class="icon-coin"
                :src="itemVolume.img"
                alt=""
                width="30"
                height="30"
              />
            </div>

            <div class="item-volume name-coin">{{ itemVolume.name }}</div>
            <div class="price-container d-flex justify-content-between">
              <div
                :class="{
                  upCoin: itemVolume?.changedLastestPrice > 0,
                  downCoin: itemVolume?.changedLastestPrice < 0,
                }"
                class="item-volume price-coin"
              >
                <span v-if="itemVolume.lastest_price >=1">
                     {{ itemVolume.lastest_price | toNumber |  formatNumberAfterDecimal( 2)}}
                </span>
                  <span  v-else-if="itemVolume.lastest_price < 1 && itemVolume.lastest_price>=0.1">
                      {{ itemVolume.lastest_price | toNumber  |  formatNumberAfterDecimal(4)}}
                </span>
                  <span  v-else>
                      {{ itemVolume.lastest_price  | toNumber |  formatNumberAfterDecimal(8)}}
                </span>


              </div>
              <div
                :class="{
                  grown: +itemVolume.changed_percent > 0,
                  down: +itemVolume.changed_percent < 0,
                }"
                class="item-volume percent-coin"
              >
                <span v-if="itemVolume.changed_percent >=1000">
                  {{ itemVolume.changed_percent | formatChangedPercent(5) }}
                </span>
                <span  v-else>
                  {{ formatShortText((itemVolume.changed_percent), 2, 8) | changedPercent }}
                </span> 

              </div>
            </div>
          </a>
        </div>
        <Listing
          class="col-xs-12 col-md-6 col-xl-3 col-sm-12 custom-col"
          :dataListing="listing"
        ></Listing>
        <GainerCoin
          class="col-xs-12 col-md-6 col-xl-3 col-sm-12 custom-col"
          :data-gainer="gainer"
        ></GainerCoin>
        <LoserCoin
          class="col-xs-12 col-md-6 col-xl-3 col-sm-12 custom-col"
          :dataLoser="loser"
        ></LoserCoin>
      </div>
    </div>
  </div>
</template>

<script>
import Listing from "./Listing.vue";
import GainerCoin from "./GainerCoin.vue";
import LoserCoin from "./LoserCoin.vue";
import rf from "@/request/RequestFactory";
import {mapState} from "vuex";
import ETHIcon from "@/assets/images/ethereum-eth-logo.png"
import BigNumber from "bignumber.js";


export default {
  components: {
    Listing,
    GainerCoin,
    LoserCoin,
  },
  data() {
    return {
      volume: [],
      listing: [],
      loser: [],
      gainer: [],
      dataTopMarket: [],
      ethicon: ETHIcon,
    };
  },
  computed: {
    ...mapState({
      masterData: (state) => state.masterdata,
    }),
  },
  watch: {
    masterData() {
        this.$nextTick(() => {
          this.marketData(this.dataTopMarket)
        })
    }
  },
  methods: {
    getSocketEventHandlers() {
      return {
        PricesUpdated: this.handleSocket,
      };
    },
    mapCoinData(v) {
      const coin = this.masterData?.coins.find((m) => m.coin == v.name);
      const img = coin.coin === 'eth'? this.ethicon : coin?.icon_image;

      const valueMarket = { ...v, img };
      valueMarket.changedLastestPrice = valueMarket.lastest_price - valueMarket.previous_price;
      if (!valueMarket.lastest_price || !valueMarket.previous_price) {
        valueMarket.changedLastestPrice = 0;
      }
      if (valueMarket.name === "usdt") {
        valueMarket.lastest_price = 1;
      }
      return valueMarket;
    },
    updateValueSocket(list, valueSocket) {
        if (valueSocket) {
            const objIndex = list.findIndex((obj => obj.name == valueSocket.coin));
            if (objIndex >= 0) {
                list[objIndex].changedLastestPrice = valueSocket.price - valueSocket.previous_price;
              if (!valueSocket.price || !valueSocket.previous_price) {
                list[objIndex].changedLastestPrice = 0;
              }
                list[objIndex].lastest_price = valueSocket.price;
                list[objIndex].changed_percent = valueSocket?.change;
            }
        }

    },

    handleSocket(data){
        if (data && Object.keys(data).toString().includes("usdt")) {
            const updateLastPrice = data[Object.keys(data)];
            this.updateValueSocket(this.volume, updateLastPrice);
            this.updateValueSocket(this.listing, updateLastPrice);
            this.updateValueSocket(this.gainer, updateLastPrice);
            this.updateValueSocket(this.loser, updateLastPrice);
            return;
        }
    },

    getMarketStatistic() {
      rf.getRequest("MarketStatistic")
        .getMarketStatistic()
        .then((res) => {
          this.dataTopMarket = res
          this.marketData(res)
        });
    },
    marketData(res) {
      if (res && res.data) {
        this.volume = res.data.volume
            .map((v) => this.mapCoinData(v))
            .slice(0, 4);
        this.listing = res.data.listing
            .map((v) => this.mapCoinData(v))
            .slice(0, 4);
        this.gainer = res.data.gainer
            .map((v) => this.mapCoinData(v))
            .slice(0, 4);
        this.loser = res.data.loser
            .map((v) => this.mapCoinData(v))
            .slice(0, 4);
      }
    },
    selectedCurrenyPair(currency, coin) {
      const pair = { currency: currency, coin: coin };
      let newurl = `/spot-exchange/basic?currency=${pair.currency}&coin=${pair.coin}`;
      this.$broadcast("selectedPairMarket", pair);

      window.history.pushState({ path: newurl }, "", newurl);
      this.$router.go(newurl);
    },
    formatShortText(value, maxDecimal, maxLength) {
      if(value == null || value == "Infinity") return '0.0';
      const split = parseFloat(value).toString().split('.');
      const beforeDecimal = split[0];
      const numFormat = this.numFormat(value, maxDecimal)
      const shorttedNumber = beforeDecimal.length > maxLength ? numFormat.slice(0, maxLength-1).replace(/\d(?=(\d{3})+\.)/g, "$&,").concat('...') :
        numFormat.slice(0, maxLength).replace(/\d(?=(\d{3})+\.)/g, "$&,").concat('...')
      const returnValue = numFormat.length > maxLength ? shorttedNumber : numFormat.replace(/\d(?=(\d{3})+\.)/g, "$&,");
      return returnValue.toString();
    },
    numFormat (value, maxDecimal) {
      const numFormat = new BigNumber(value).toFixed(maxDecimal, BigNumber.ROUND_DOWN);
      return numFormat;
    },
  },
  created() {
    this.getMarketStatistic();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.clearfix {
  content: "";
  clear: both;
  display: table;
}

.item-volume {
  position: relative;
  cursor: pointer;
  .unHideCoin {
    display: none !important;
  }
  &:hover {
    .unHideCoin {
      z-index: 1000;
      white-space: break-spaces;
      display: block !important;
      position: absolute;
      padding: 10px;
      top: -40px;
      border-radius: 8px;
      background: var(--color-tooltip);
      color: $color-white !important;
      font-size: 16px;
      width: 140px !important;
      text-align: start;
    }
  }
}

#main-market {
  margin: 0 auto;
  padding: 60px 0 50px;
  min-height: calc(100% - 65px - 252px);
  font-family: $font-inter-regular;
  max-width: 1510px;
  .market-title {
    font-weight: 600;
    font-size: 34px;
    line-height: 41px;
    color: var(--text-primary-color);
    padding-left: 30px;
    margin: 0;
  }
  .title {
    color: var(--text-close);
    margin: 30px 0 25px;
  }

  .group-coin {
    display: flex;
    justify-content: space-between;
    margin: 0;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .volume {
    padding-right: 30px;
    padding-left: 30px;

    .item-volume {
      color: var(--text-primary-color);
      font-size: $font-big-20;
      font-weight: 400;
    }
  }

  .main-volume {
    display: flex;
    padding-bottom: 16px;
    cursor: pointer;

    .img-coin {
      width: 12%;

      img {
        cursor: pointer;
        object-fit: contain;
      }
    }
    .name-coin {
      width: 23%;
      text-transform: uppercase;
    }

    .price-container {
      width: 65%;
      .price-coin {
        width: fit-content;
      }
      .percent-coin {
        width: fit-content;
        text-align: right;
      }
      .upCoin {
        color: $text-color-jade;
      }
      .downCoin {
        color: $text-color-red;
      }
    }
  }

  .grown {
    color: $text-color-jade !important;
  }
  .down {
    color: $color-denim !important;
  }
}

@media screen and (max-width: 1440px) {
  #main-market {
    .market-title {
      padding-left: 16px;
    }
    .main-volume {
      .name-coin {
        width: 50%;
      }
      .img-coin {
        width: 15%;
      }
    }
    .volume {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  .group-coin {
    .custom-col {
      width: 50%;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }


  }
}
@media screen and (max-width: 1191px) {
  #main-market {
    .main-volume {
      .name-coin {
        width: 38%;
      }
      .price-container {
        width: 50%;
      }
      .img-coin {
        width: 7%;
      }
    }
  }
}
@media screen and (max-width: 992px) {
  #main-market {
    background: var(--color-background-withdrawls);
    padding: 32px 15px;
    .market-title {
      font-size: 24px;
      padding-left: 0;
    }

    .group-coin {
      .page-container {
        padding: 0;
      }
    }
    .main-volume {
      &:last-child {
        padding-bottom: 0;
      }
      .name-coin {
        width: 38%;
      }
      .price-container {
        width: 50%;
      }
    }
    .volume {
      padding: 0 16px 0 0;

      .item-volume {
        font-size: 14px;
      }

      .img-coin {
        width: 9%;
        img {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  #main-market {
    .volume {
      padding: 0;
      .img-coin {
        width: 5%;
      }
    }
  }
}
@media screen and (max-width: 576px) {
  #main-market {
    .volume {
      .img-coin {
        width: 9%;
      }
    }
  }
}
</style>