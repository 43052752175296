<template>
  <div id="SpotExchange_page">
<!--    web Version 1500px>>-->
    <div id="home" class="page_container full-page web-version" :class="{'has-auth': isAuthenticated }">
      <div class="page-container-left">
        <exchange-header class="coin_info_exchange clearfix"></exchange-header>
        <div class="page-container-mid">

          <!--header-block -->
          <div class="order-book-block">
            <order-book/>
          </div> <!-- end-order-book-block -->
          <div class="chart-block">
            <chart-index/>
            <div class="order-trade-block">
              <order-trade-form/>
            </div> <!-- end-order-trade-block -->
          </div> <!-- end-chart-block -->
        </div>

      </div>
      <div class="page-container-right d-none d-sm-block">
        <div class="market-right">
          <div id="container-tab" class="clearfix">
            <label class="search__coin">
              <img class="icon-search icon" :src="require(`@/assets/images/icon_search${dark}.svg`)"/>
              <input
                  type="text"
                  :placeholder="$t('approved_order.btn_search')"
                  class="visible input-search"
                  v-model="searchKey">
            </label>
            <div class="clearfix group_table_landing">
              <!-- <input id="tab-favirotes" type="radio" name="tab-group"/> -->
              <label for="tab-favirotes" :class="selectedMarket === tabFavorites ? 'current-market-favorites' : ''" v-on:click="showMarket(tabFavorites)">
                <span class="icon-star2" :class=" selectedMarket === tabFavorites ? 'star_color' : ''"></span>
<!--                {{$t('landing.favorites')}}-->
              </label>
              <template v-for="market in markets">
                <!-- <input :id="`tab-${market}`" type="radio" name="tab-group" :checked="selectedMarket === market" /> -->
                <label :for="`tab-${market}`" :class="selectedMarket === market ? 'current-market' : ''" v-on:click="showMarket(market)" >{{market.toUpperCase()}}</label>
              </template>
            </div>
          </div>
          <div class="table-content">
            <div id="content">
              <market-coin
                  :priceScope="pricesFilter"
                  :favorites="favorites"
                  :marketSelected="selectedMarket"
                  :columns="columns"
                  :zone="zone"
                  :isSportExchange="true"
                  :isSwitchPercentAndVolumn="true"
                  :trigger-sort-after-update-data-of-socket="triggerSortAfterUpdateDataOfSocket"
                  @SpotSelectedPairMarket="onSpotSelectedPairMarket"
                  @SwitchChangeVolumeSpotPairMarket="switchChangeVolume"
              >
              </market-coin>
            </div>
          </div>
        </div>
        <div class="market-trades position-relative">
          <div class="trade-tab d-flex">
            <div class="tab-trade" @click="tabTrade = 'market'" :class="{'tab-trade-selected':tabTrade === 'market'}">{{ $t('spot.title') }}</div>
            <div class="tab-trade" @click="tabTrade = 'mytrade'" :class="{'tab-trade-selected':tabTrade === 'mytrade'}">{{ $t('landing.my-trades') }}</div>
          </div>
          <div v-show="tabTrade === 'market'">
            <div class="top-title">
              <ul class="title-group">
                <li class="od-price">{{ $t('order.order_book.price') }}({{ $t(`currency.${currency}.shortname`) }})</li>
                <li class="od-amount">{{ $t('order.order_book.amount') }}({{ coin | uppercase}})</li>
                <li class="od-total">{{ $t('order.history.time') }}</li>
              </ul>
            </div>
            <recent-trades class="recent-trade"/>
          </div>
          <div v-show="tabTrade === 'mytrade'">

            <div class="textAuthen" v-if="!this.isAuthenticated">
              <router-link class="but_get_log" :to="{name: 'Login', params: {customRedirect: 'Spot Exchange'}}">{{ $t('order.order_form.login') }}</router-link>
              <span> {{ $t('order.order_form.or')}} </span>
              <router-link class="but_get_log" :to="{name: 'Register'}" >{{ $t('order.order_form.signup') }}</router-link>
              <span> {{ $t('order.order_form.trade')}}</span>
            </div>

            <div v-else>
              <div class="top-title">
                <ul class="title-group">
                  <li class="od-price">{{ $t('order.order_book.price') }}({{ $t(`currency.${currency}.shortname`) }})</li>
                  <li class="od-amount">{{ $t('order.order_book.amount') }}({{ coin | uppercase}})</li>
                  <li class="od-total">{{ $t('order.history.time') }}</li>
                </ul>
              </div>
              <my-trades class="recent-trade"></my-trades>
            </div>

          </div>


        </div>
      </div>
    </div>
<!--    mobile version smaller than 1250px-->
    <div class="mobile-version" v-show="isMobileScreen">
      <div class="page-container-left" :class="{'has-auth': isAuthenticated }">
        <exchange-header v-if="isMobileScreen" class="coin_info_exchange clearfix"></exchange-header>
      </div>

      <div class="d-flex tab-mobile">
        <span class="tab-item" @click="tabMobileSelected = 'chart'" :class="{'tab-item-selected': tabMobileSelected ==='chart'}">Chart</span>
        <span class="tab-item" @click="tabMobileSelected = 'order_book'" :class="{'tab-item-selected': tabMobileSelected ==='order_book'}">Order Book</span>
        <span class="tab-item" @click="tabMobileSelected = 'pair_listing'" :class="{'tab-item-selected': tabMobileSelected ==='pair_listing'}">Pair Listing</span>
        <span class="tab-item" @click="tabMobileSelected = 'trades'" :class="{'tab-item-selected': tabMobileSelected ==='trades'}">Trades</span>
      </div>
      <div v-show="tabMobileSelected === 'chart'">
        <div class="chart-block" style="height: 500px">
          <chart-index v-if="isMobileScreen" :changeTabFromHome="changeTabMobile"/>
        </div>
      </div>
      <div v-show="tabMobileSelected === 'order_book'">
        <div class="order-book-block">
          <order-book v-if="isMobileScreen"/>
        </div>
      </div>
      <div v-show="tabMobileSelected === 'pair_listing'">
        <div class="market-right">
          <div id="container-tab" class="clearfix">
            <label class="search__coin">
              <img class="icon-search icon" src="@/assets/images/icon_search.svg"/>
              <input
                  type="text"
                  :placeholder="$t('approved_order.btn_search')"
                  class="visible input-search"
                  v-model="searchKey">
            </label>
            <div class="clearfix group_table_landing">
              <!-- <input id="tab-favirotes" type="radio" name="tab-group"/> -->
              <label for="tab-favirotes" :class="selectedMarket === tabFavorites ? 'current-market-favorites' : ''" v-on:click="showMarket(tabFavorites)">
                <span class="icon-star2" :class=" selectedMarket === tabFavorites ? 'star_color' : ''"></span>
              </label>
              <template v-for="market in markets">
                <!-- <input :id="`tab-${market}`" type="radio" name="tab-group" :checked="selectedMarket === market" /> -->
                <label :for="`tab-${market}`" :class="selectedMarket === market ? 'current-market' : ''" v-on:click="showMarket(market)" >{{market.toUpperCase()}}</label>
              </template>
            </div>
          </div>
          <div class="table-content">
            <div id="content">
              <market-coin
                  :priceScope="pricesFilter"
                  :favorites="favorites"
                  :marketSelected="selectedMarket"
                  :columns="columns"
                  :zone="zone"
                  :isSportExchange="true"
                  :isSwitchPercentAndVolumn="true"
                  :trigger-sort-after-update-data-of-socket="triggerSortAfterUpdateDataOfSocket"
                  @SpotSelectedPairMarket="onSpotSelectedPairMarket"
                  @SwitchChangeVolumeSpotPairMarket="switchChangeVolume"
              >
              </market-coin>
            </div>
          </div>
        </div>
      </div>
      <div v-show="tabMobileSelected === 'trades'">
        <div class="market-trades position-relative">
          <div class="trade-tab d-flex">
            <div class="tab-trade" @click="tabTrade = 'market'" :class="{'tab-trade-selected':tabTrade === 'market'}">{{ $t('spot.title') }}</div>
            <div class="tab-trade" @click="tabTrade = 'mytrade'" :class="{'tab-trade-selected':tabTrade === 'mytrade'}">{{ $t('landing.my-trades') }}</div>
          </div>
          <div v-show="tabTrade === 'market'">
            <div class="top-title">
              <ul class="title-group">
                <li class="od-price">{{ $t('order.order_book.price') }}&nbsp;({{ $t(`currency.${currency}.shortname`) }})</li>
                <li class="od-amount">{{ $t('order.order_book.amount') }}&nbsp;({{ coin | uppercase}})</li>
                <li class="od-total">{{ $t('order.history.time') }}&nbsp;</li>
              </ul>
            </div>
            <recent-trades class="recent-trade"/>
          </div>
          <div v-show="tabTrade === 'mytrade'">

            <div class="textAuthen" v-if="!this.isAuthenticated">
              <router-link class="but_get_log" :to="{name: 'Login', params: {customRedirect: 'Spot Exchange'}}">{{ $t('order.order_form.login') }}</router-link>
              <span> {{ $t('order.order_form.or')}} </span>
              <router-link class="but_get_log" :to="{name: 'Register'}" >{{ $t('order.order_form.signup') }}</router-link>
              <span> {{ $t('order.order_form.trade')}}</span>
            </div>

            <div v-else>
              <div class="top-title">
                <ul class="title-group">
                  <li class="od-price">{{ $t('order.order_book.price') }}&nbsp;({{ $t(`currency.${currency}.shortname`) }})</li>
                  <li class="od-amount">{{ $t('order.order_book.amount') }}&nbsp;({{ coin | uppercase}})</li>
                  <li class="od-total">{{ $t('order.history.time') }}&nbsp;</li>
                </ul>
              </div>
              <my-trades class="recent-trade"></my-trades>
            </div>

          </div>


        </div>
      </div>

    </div>
    <div class="btn-buy-sell-mobile">
      <template v-if="!this.isAuthenticated">
        <div class="btn-login-signup">
          <router-link class="but_get_log" :to="{name: 'Login', params: {customRedirect: 'Spot Exchange'}}">{{ $t('order.order_form.login') }}</router-link>
          <span style="margin: 0 5px"> {{ $t('order.order_form.or')}} </span>
          <router-link class="but_get_log" :to="{name: 'Register'}" >{{ $t('order.order_form.signup') }}</router-link>
        </div>
      </template>
      <template v-else>
        <div class="d-flex justify-content-between mobile-button">
          <div class="button-buy" @click="click_buy_sell = !click_buy_sell;triggerOrderFormTab = 1">
            {{$t('orders.order_history.buy')}} {{ coin | uppercase }}
          </div>
          <div class="button-sell"  @click="click_buy_sell = !click_buy_sell; triggerOrderFormTab = 0">
            {{$t('orders.order_history.sell')}} {{ coin | uppercase }}
          </div>
        </div>
        <div class="position-fixed order-container" v-if="click_buy_sell">
          <div class="title-spot">Spot</div>
          <div class="close-btn" @click="click_buy_sell = !click_buy_sell">
            <i class="icon-close close-btn" alt="" ></i>
          </div>
          <div class="order-trade-block">
            <order-trade-form :triggerOrderFormTab="triggerOrderFormTab"/>
          </div>
        </div>
        <div class="overlay-order-container" v-show="click_buy_sell"></div>
      </template>
    </div>
    <div class="d-flex w-100 history-table-container">

      <div id="order-group-block">
        <order-group/>
      </div><!-- order-end-trade-group-block -->
<!--      <div class="page-container-right d-block d-sm-none">-->
<!--        <div class="order-trade-block">-->
<!--          <order-trade-form/>-->
<!--        </div> &lt;!&ndash; end-order-trade-block &ndash;&gt;-->
<!--      </div>-->

      <!--      <div class="market-trades-right d-none d-lg-block">&nbsp;</div>-->
    </div>



<!--    <div class="clearfix clearfix-20"></div>-->


<!--    <div class="clearfix"></div>-->
<!--    <exchange-footer></exchange-footer>-->
<!--    <footer id="footer">-->
<!--      <page-footer :miniFooter="true">-->
<!--      </page-footer>-->
<!--    </footer>-->
    <beta-tester-form></beta-tester-form>
    <beta-tester-wait-admin-verify></beta-tester-wait-admin-verify>
    <!-- <transition name="fade">
      <div class="Trade_popup" v-if="isAuthenticated && Trade_popup" @click="popClick(true)">
        <div class="pop_con" @click.stop="popClick(false)">
          <img v-if="$t('i18n.lang') === 'ko' || $t('i18n.lang') === 'ru' || $t('i18n.lang') === 'vi' || !$t('i18n.lang')" :src="`@/assets/images/spot_exchange/trade_battle_en.jpg?t=${dateNow}`" class="conimg" />
          <img v-else :src="'@/assets/images/spot_exchange/trade_battle_'+$t('i18n.lang')+'.jpg?t='+dateNow" class="conimg" />
          <img src="@/assets/images/spot_exchange/close.png" class="closeimg" @click="closePop" />
        </div>
      </div>
    </transition> -->
  </div>
</template>

<script>
import Const from '@/common/Const';
import OrderBook from './OrderBook.vue';
import RecentTrades from './RecentTrades.vue';
import OrderTradeForm from "./OrderTradeForm.vue";
import OrderGroup from '../../order/OrderGroup.vue';
import ChartIndex from "./chart/ChartIndex.vue";
import ExchangeHeader from './Header';
import ExchangeFooter from '@/components/salespoint_pages/exchange/basic/ExchangeFooter.vue';
import BetaTesterForm from './beta-tester/BetaTesterForm.vue';
import BetaTesterWaitAdminVerify from './beta-tester/WaitAdminVerify.vue';
import rf from '@/request/RequestFactory'
import PageFooter from '@/pages/common/PageFooter.vue';
import Cookies from "js-cookie";
import {mapState} from "vuex";
import BigNumber from "bignumber.js";
import FavoritesManager from "@/common/FavoritesManager";
import MarketCoin from '../../exchange/basic/landing/MarketCoin';
import MyTrades from "@/components/spotexchange_pages/exchange/basic/MyTrades";

const VISIBLE_COLUMNS = ['pair', 'current_price', 'quote_volume'];

export default {
    components: {
      MyTrades,
      OrderTradeForm,
      OrderBook,
      RecentTrades,
      OrderGroup,
      ChartIndex,
      ExchangeHeader,
      ExchangeFooter,
      BetaTesterForm,
      BetaTesterWaitAdminVerify,
      PageFooter,
      MarketCoin
    },
    data() {
      return {
        coin:this.$route.query.coin || Const.DEFAULT_COIN,
        currency: this.$route.query.currency || Const.DEFAULT_CURRENCY,
        isAuthenticated: window.isAuthenticated,
        config: {
          displayAction: 'thead',
          showHideOtherPairsCheckbox: true
        },
        Trade_popup:false,


        columns: VISIBLE_COLUMNS,
        zone: 0,

        coinSettings: [],
        favorites: [],
        searchKey: '',
        priceScope: {},
        pricesFilter: {},
        sortColumn: 'coin',
        isSortDesc: false,
        isShowSearchField: false,
        markets : [],
        selectedMarket: 'usdt',
        tabFavorites: Const.FAVORITES_TAB,
        defaultMarket: Const.DEFAULT_MARKET,
        isLogin : window.isAuthenticated,
        theme: Cookies.get("user-theme", { domain: process.env.VUE_APP_PUBLIC_URL }) || 'dark-theme',
        tabMobileSelected: 'chart',
        click_buy_sell: false,
        triggerOrderFormTab: 1,
        isMobileScreen: window.matchMedia('(max-width: 1250px)').matches,
        tabTrade : 'market',
        triggerSortAfterUpdateDataOfSocket: undefined
      }
    },
    props: {
      // tabTrade :{
      //   type: 'market' | 'mytrade',
      //   default: 'market'
      // }
    },
    computed:{
        dateNow() {
            return Date.now();
        },
        changeTabMobile(){
          return this.tabMobileSelected;
        },
      ...mapState({
        masterdata: state => state.masterdata,
        userTheme: state => state.userThemeMode,
      }),
      dark() {
          return this.userTheme === 'light-theme'? '': '-dark';
        },
      getPairFavorites() {
        return window._.chain(this.favorites).map(item => item.coin_pair).value();
      }
    },
    watch: {
      '$route' (to, from) {
        this.currency = this.$route.query.currency || Const.DEFAULT_CURRENCY;
        this.coin = this.$route.query.coin || Const.DEFAULT_COIN;
        if (!this.checkCoinCurrencyFromMasterdata(this.$route.query.coin,this.$route.query.currency)) {
           this.coin = Const.DEFAULT_COIN;
           this.currency = Const.DEFAULT_CURRENCY;
        }

        this.initCoinsPairData();
      },

      selectedMarket(newMarket) {
        // Apply search key if have when change tabs
        if (!window._.isEmpty(this.searchKey)) {
          this.search();
          return;
        }
        const result = window._.chain(this.pricesScope)
            .filter(item => {
              return this.filterCoinByTab(item);
            })
            .orderBy(this.sortColumn, [this.isSortDesc ? 'desc' : 'asc'])
            .value();
        this.pricesFilter = Object.assign({}, result);
      },

      searchKey: function() {
        this.search();
      },

      click_buy_sell(value) {
        if (value) {
          document.documentElement.style.overflow = 'hidden'
          return;
        }

        document.documentElement.style.overflow = 'auto'
      }
    },
    mounted() {
      window.addEventListener('resize', this.handleWindowResize)
      document.title = this.$t('menu.exchange') + ` – ${this.tabTitle}`;
      if (localStorage && localStorage.spotEx_coin && localStorage.spotEx_currency) {
        const pair = { currency: localStorage.spotEx_currency, coin: localStorage.spotEx_coin, zone: localStorage.spotEx_zone || 0 };
        setTimeout(() => {
          this.$broadcast('SpotSelectedPairMarket', pair);
        }, 1500);
      }
      this.Trade_popup = !!window.localStorage.getItem('Trade_popup') ? false : true;
      window.scrollTo({
        top: 0,
        left: 0,
      });
      this.initData();
    },
    created() {
      this.initCoinsPairData()
    },
    methods: {

      checkCoinCurrencyFromMasterdata(coin, currency){
          let count = 0;
          this.masterdata.coin_settings.forEach((item) => {
              const coinPairMasterData = `${item.coin}_${item.currency}`
              const coinPairUrl = `${coin}_${currency}`
              if (coinPairMasterData === coinPairUrl) {
                  count += 1;
              }
          })
          return count > 0;
      },
      handleWindowResize() {
        this.checkMobileScreen();
      },
      checkMobileScreen (){
        const isMobile = window.matchMedia('(max-width: 1250px)').matches
        this.isMobileScreen = isMobile
      },
      closePop() {
        window.localStorage.setItem('Trade_popup', false);
        this.Trade_popup =  false;
      },
      popClick(flag) {
        if(flag) {
          this.closePop();
        }
      },
      initCoinsPairData() {
        let coin = this.$route.query.coin || Const.DEFAULT_COIN;
        let currency = this.$route.query.currency || Const.DEFAULT_CURRENCY;
        if (!this.checkCoinCurrencyFromMasterdata(this.$route.query.coin,this.$route.query.currency)) {
            this.coin = coin = Const.DEFAULT_COIN;
            this.currency = currency = Const.DEFAULT_CURRENCY;
        }
        rf.getRequest("SettingRequest")
          .getPairCoinSettings({ coin, currency })
          .then(res => {
            if (!res && !res.data) {
              return;
            }
            this.$store.commit('setPairCoins', res)
          });
      },

      getEventHandlers() {
        return {
          SpotUpdateVolumeMarket: this.onSpotUpdateVolumeMarket,
        }
      },
      onSpotUpdateVolumeMarket(res) {
        // Update 24h Volumn in Market Table
        const pair = `${res.currency}_${res.coin}`;
        if (this.pricesScope) {
          const priceScope = this.pricesScope[pair];
          if (priceScope) {
            priceScope.volume = res.volume;
            priceScope.quote_volume = res.quote_volume;
          }
        }
      },
      onSpotSelectedPairMarket(pair) {
        // Handle changed pair if need.

        if (this.callback) {
          return this.callback();
        }

      },
      switchChangeVolume(from) {
       if (from) {
         this.columns = ['pair', 'current_price', 'changed_percent']
       }else {
           this.columns = ['pair', 'current_price', 'quote_volume']
       }
      },
      search() {
        const keyWord = window._.toLower(this.searchKey);
        this.pricesFilter = window._.chain(this.pricesScope)
            .filter(item => {
              const filterByTab = this.filterCoinByTab(item);
              return filterByTab && (item.coin.includes(keyWord) || item.currency.includes(keyWord));
            })
            .orderBy(this.sortColumn, [this.isSortDesc ? 'desc' : 'asc'])
            .value();
      },

      filterCoinByTab(item) {
        // Market coin tab
        let filterByTab = item.currency === this.selectedMarket;

        // Favorites tab
        if (this.tabFavorites === this.selectedMarket) {
          const pair = `${item.coin}/${item.currency}`;
          filterByTab = this.getPairFavorites.indexOf(pair) > -1;
        }
        return filterByTab;
      },

      toUpperCase(value) {
        if (!value) {
          return '';
        }
        return value.toUpperCase();
      },

      showMarket(market) {
        this.selectedMarket = market;
      },

      getSocketEventHandlers() {
        return {
          PricesUpdated: this.onPricesUpdated,
          OrderTransactionCreated: this.onOrderTransactionCreated
        }
      },

      onPricesUpdated(newPrices) {
        this.triggerSortAfterUpdateDataOfSocket = newPrices;
        this.prices = Object.assign({}, this.prices, newPrices);


        window._.each(this.pricesScope, item => {
          const coinPrice = newPrices[item.currency + '_' + item.coin];
          if (!coinPrice) {
            return;
          }

          const latestPrice = new BigNumber(`${coinPrice.price || 0}`);

          if (latestPrice.gt(`${item.max_price || 0}`)) {
            item.max_price = coinPrice.price;
          }
          if ( latestPrice.lt(`${item.min_price || 0}`)) {
            item.min_price = coinPrice.price;
          }

          if (item.current_price) {
            let result = latestPrice.sub(`${item.current_price || 0}`).toString();
            this.$set(item,'changePrice',result);
          }

          item.changed_percent = coinPrice.change;
          item.previous_price = coinPrice.previous_price;
          item.quote_volume = coinPrice.volume;
          item.current_price = latestPrice.toString();
          item.usdPrice = this.toUsdPrice(item.current_price, item.currency);
        });
      },

      onOrderTransactionCreated(data) {
        const pair = `${data.orderTransaction.currency}_${data.orderTransaction.coin}`;
        const priceScope = this.pricesScope[pair];
        if (priceScope) {
          priceScope.volume = new BigNumber(`${priceScope.volume || 0}`).add(`${data.orderTransaction.quantity}`);
        }
      },

      toUsdPrice(price, fromCurrency) {
        return new BigNumber(price).mul(new BigNumber(this.getPrice('usd_' + fromCurrency).price)).toString();
      },

      getPrice(pair) {
        return this.prices[pair] || { price: 1 };
      },

      calculateUsdPrice() {
        window._.forEach(this.pricesScope, (item) => {
          item.usdPrice = this.toUsdPrice(item.current_price, item.currency);
        });
      },
      initData() {
        this.coinSettings = this.masterdata.coin_settings;
        this.markets = window._.chain(this.masterdata.market_settings)
            .filter(item => item.status == 'active')
            .map(item => item.currency)
            .value();

        this.markets = ["usdt"];

        if (this.markets && this.markets.length > 0) {
          this.defaultMarket = this.markets[0];
        }
        rf.getRequest('PriceRequest').getPrices24h().then(res => {
          this.pricesScopeUpdated(this.filterData(res.data));
          this.selectedMarket = this.getSelectTabViaRoute();
        });
        this.getFavorites();

        rf.getRequest('PriceRequest').getPrices().then(res => {
          this.onPricesUpdated(res.data);
        });
      },

      filterData(data) {
        let result = {};
        for (let key in data) {
          let pair = data[key];
          let pairZone = this.getPairZone(pair);
          if (pairZone == this.zone) {
            result[key] = pair;
          }
        }
        return result;
      },

      getPairZone(data) {
        for (let setting of this.coinSettings) {
          if (data.currency === setting.currency && data.coin === setting.coin) {
            return setting.zone;
          }
        }
        return -1;
      },

      getSelectTabViaRoute() {
        const query = this.$route.query || {};
        return query.tab || this.defaultMarket;
      },

      getFavorites() {
        FavoritesManager.getFavorites().then(data => {

          let markets = this.markets;

          data = window._.filter(data, item => {
            let coinPair = item.coin_pair;
            let currency = coinPair.split('/');
            if (currency.length > 1) {
              currency = currency[1];
            } else {
              currency = '';
            }
            return markets.indexOf(currency) != -1;
          });

          if(this.isLogin) this.favorites = data;
        });
      },

      pricesScopeUpdated(newPrices) {
        this.pricesFilter = this.pricesScope = newPrices;

        this.calculateUsdPrice();
      },
    },
 }
</script>

<style lang="scss">
  @import "@/assets/sass/variables";
  .dark-theme {
    #home {
      .VuePagination {
        border-top: 1px solid transparent;
      }
    }
    #SpotExchange_page{
      #order-group-block {
        background-color: $color-dark;
        #order-group #order-table table thead th {
          color : $color-auro-metal;
        }
        #order-group #open-history .group-control #date-tab ul li a.active, #trade-history .group-control #date-tab ul li a.active {
          background: $color-gray-charade;
        }
      }

      .market-trades {
        overflow: hidden;
        background-color: $color-mirage;
        border-left: none;
        border-top-color: $color-cinder;

        .title-market-trades {
          color: white;
        }
        .sell {
          color: $text-color-red;
        }
        .buy {
          color: $text-color-jade;
        }
      }

    }
  }
  #home {
    max-width: 1560px;
    margin: 0 auto;
    border-left: 4px solid var(--color-border-right);

    .VuePagination {
      border-top: 1px solid $color_grey_border;
      margin-top: 20px;
      ul {
        margin: 10px 0 0 0 !important;
      }
    }
  }

  .recent-trade {
    height: 89%;
    top: 76px;
    position: absolute;
  }

  #SpotExchange_page {
    border-top: 4px solid var(--color-border-right);
    #order-group-block {
      border-left: 4px solid var(--color-border-right);
      border-right: 4px solid var(--color-border-right);
      background-color: $color-gray-alabaster;
      #order-group {
        padding-top: 30px;
        #order-table {
        
          table {
            thead {
              background-color: transparent;
              th {
                color: $color-gray-dark;
                font-family: $font-inter-regular;
                font-size: $font-root;

                #cancel-box {
                  text-align: right;
                  .btn-cancel {
                    border-color: $color-bright-gray-dark;
                    font-size: 14px;
                    &:hover {
                      border-color: $color-aquamarine;
                      color: $color-aquamarine;
                    }
                  }
                  .dropdown {
                    button {
                      border-color: $color-bright-gray-dark;
                      span {
                        color: var(--text-close);
                        &.icon-arrow1:before {
                          color: var(--text-close);
                        }
                      }
                      &:hover {
                        border-color: $color-aquamarine;
                        color: $color-aquamarine;
                        span {
                          color: $color-aquamarine;
                        }
                      }
                    }
                  }
                }
              }
            }
            tbody {
              tr {
                td {
                  color: $color-grey-dark;
                  line-height: 20px;
                  font-size: 14px;
                  //padding: 9px 0px 8px 20px;
                  &:last-child {
                    text-underline-offset: 3px;
                    text-align: right;
                    padding-right: 40px;
                  }
                  &.blue {
                    color: $text-color-jade;
                  }
                  &.red {
                    color: $text-color-red;
                  }
                }
                &:hover {
                  //background-color: $color-ebony-clay;
                }
                &.empty-data {
                  &:hover {
                    background-color: transparent !important;
                  }
                  td {
                    &:last-child {
                      text-align: center !important;
                    }
                  }
                }
              }
            }
          }
        }
        #trade-history,
        #open-history {
          .group-control {
            background-color: $color-gray-alabaster;
            padding: 0 20px 0 30px;
            margin: 20px 0;
            .button-search {
              width: 85px !important;
              height: 35px;
              border-radius: 8px;
              background-color: $color-caribbean-green;
              margin-left: 40px!important;
              margin-right: 12px;
              letter-spacing: 0.9px;
              text-align: center;
              color: $color-grey-dark;
              font-size: $font-root;
              font-family: $font-inter-regular;
              line-height: 31px;
              &:hover, &:active{
                background: $color-aquamarine ;
                border-color: $color-aquamarine ;
                color: $color-grey-dark;
                -webkit-transition: 0.5s;
                transition: 0.5s;
              }
              &:disabled {
                background: $color-aero-blue ;
                border-color: $color-aero-blue ;
                color: $color-gray-dark;
              }
            }
            #date-tab {
              ul {
                li {
                  a {
                    border-radius: 4px;
                    height: 32px;
                    line-height: 19.36px;
                    padding: 6.5px 12px !important;
                    color: var(--text-close);
                    font-size: $font-root;
                    margin-right: 5px;
                    outline-style: none;

                    &.active {
                      color: var(--text-primary-color);
                      background: var(--bg-time-order);
                    }
                  }
                }
              }
            }
            .group-date {
              font-size: 14px;
              line-height: 30px;
              color: $color-grey-dusty;
              margin-left: 23px;
              font-family: $font-inter-regular;
              .vdp-datepicker {
                background-color: transparent;
                input {
                  width: 130px;
                  background-color: var(--color-border-right);
                  border-radius: 8px 0 0 8px;
                  //background: none;
                  // background-image: url('@/assets/images/icon/date-time-home.png') !important;
                  background-repeat: no-repeat;
                  background-position: top 7px left 11px;
                  height: 35px;
                  font-size: 14px;
                  color: $dark-2;
                  background-size: 20px;
                  background-position: top 8px right 10px !important;
                }
                .vdp-datepicker__calendar {
                  top: 35px;
                }
              }
            }
          }

          ::v-deep {
            .tableContainer3 table {
              background: transparent;
            }
          }
        }

        #balance.page_container {
          .balance-container {
            .table-container {
              background-color: transparent;
              th {
                color: $color-grey-dusty;
                font-family: $font-family-roboto-medium;
                border: none;
                border-bottom: 0;
                cursor: pointer;
                font-weight: normal;
                //padding: 8px 0px 8px 20px;
                line-height: 20px;
                font-size: $font-small;
              }
              tbody {
                tr {
                  td {
                    color: $color-gray-dark;
                    line-height: 20px;
                    &.table_drop_balances {
                      padding: 15px 0px 16px 20px;
                    }
                    .btn-mam {
                      top: 14px;
                    }
                    .btn-transfer {
                      top: 8px;
                    }
                  }
                  &:hover {
                    //background-color: $color-ebony-clay;
                  }
                  &.empty-data {
                    &:hover {
                      background-color: transparent !important;
                    }
                    td {
                      &:last-child {
                        text-align: center !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        #sub-accounts {
           #order-table{
           th {
              color: $color-grey-dusty;
              font-family: $font-family-roboto-medium;
              border: none;
              border-bottom: 0;
              cursor: pointer;
              font-weight: normal;
              //padding: 8px 0px 8px 20px;
              line-height: 20px;
              font-size: $font-small;
            }
          }
        }
      }      
    }
  }
  #SpotExchange_page {
    #order-group-block {
      .VuePagination {
        background-color: transparent;
      }
    }
    .icon-sort-table {
      font-size: 17px;
    }
  }
</style>

<style lang="scss" scoped>
  @import "@/assets/sass/variables";
  @import "@/assets/sass/common.scss";

  $spaceMargin           : 5px;
  $widthOrderBook        : 27%;
  $widthOrderForm        : 26%;
  $minHeightContent      : 550px;
  $heightHeaderTitle     : 27px;

  .dark-theme {

    .overlay-order-container {
      background: #191919;
    }
    .page-container-right {
      background: $color-mirage!important;
    }

    .market-trades-right {
      background: $color-mirage;
    }

    .coin_info_exchange {
      background: $color-mirage;
    }
    .market-right .table-content #content ::v-deep{
      .table-landing > table tbody tr.empty-data {
        background: transparent;
      }
    }
    .market-right #container-tab .search__coin input {
      background: $color-wood-smoke;
      color: white;

      &::placeholder {
        color: $color-storm-grey;
      }
    }
    .market-right #container-tab .group_table_landing label {
      color: $color-auro-metal;
    }


  }

  .overlay-order-container {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 100;
    top: 0;
    left: 0;
    opacity: 0.4;
    background: #191919;
  }
  .history-table-container {
    max-width: 1560px;
    margin: 0 auto;
  }

  ::v-deep {
    .daterangepicker {
      border: 1px solid #E6E6E6;
      border-radius: 8px;
      &:after {
        content: none;
      }
      &:before {
        content: none;
      }
    }

    .daterangepicker td.in-range {
      background-color: #FFFAEA;
    }
    .daterangepicker td.in-range {
      color: $dark-1!important;
    }
    .daterangepicker td.today {
      font-weight: 400;
    }

  }
  @media screen and (max-width: 576px) {
    .dark-theme .page-container-right {
      border-left: none;
    }
  }

  .full-page {
    width: 100%;
    display: grid;
    grid-template-columns: 74% 26%;
    min-width: 1200px;
    //height: 125vh;
    box-sizing: border-box;
  }
  @media screen and (max-width: 1400px) {
    .full-page  {
      grid-template-columns: 72% 28%;

    }
  }
  .clearfix-20 {
    display: block;
    width: 100%;
    height: 20px;
  }
  #SpotExchange_page {
    background-color: var(--background-spot);
    min-width: 1200px;
  }
  #home {
    min-height: 900px;
    //border-bottom: 1px solid $grey-2;
    font-family: $font-inter-regular;
    .page-container-left {
      display: block;
      float: left;
      height: 100%;
      width: 100%;      //border-right: 1px solid $color-bright-gray-dark;
      .coin_info_exchange{
        display: flex;
        height: 68px;
        align-items: center;
        border-bottom: 4px solid var(--color-border-right);
        border-right: 4px solid var(--color-border-right);
        background: var(--background-current_coin);
      }
    }
    .page-container-mid {
      display: flex;
      float: left;
      height: calc(100% - 64px);
      width: 100%;
      min-width: 300px;
      border-right: 4px solid var(--color-border-right);

      //border-right: 1px solid $color-bright-gray-dark;
      .order-book-block {
        width: #{$widthOrderForm};
        height: 100%;
        min-width: 340px;
        ::v-deep {
          .top_mid_table {
            height: 100%;
            min-height: 1025px;
            max-height: 1030px;
          }
        }
      }
      .chart-block {
        width: calc(100% - #{$widthOrderForm});
        height: 100%;
        //max-height: 498px;
        ::v-deep {
          .chart-container {
            height: 500px;
          }
        }
        .order-trade-block {
          width: 100%;
          height: calc(100% - 500px);
          display: flex;
          ::v-deep {
            .tab_content_ordertrade {
              height: 100%;
              .item {
                height: 100%;
                #order_form {
                  border-top: 4px solid var(--color-border-right);
                  height: 100%;
                  .tab_content {
                    height: 100%;
                  }
                }
              }
            }
            #form-input-order-id {
              padding: 30px 15px 30px 30px;
              background: var(--background-order-coin);
            }
          }
        }
      }
    }
    .page-container-right {
      display: block;
      float: left;
      height: 100%;
      width: 100%;
      //min-width: 370px;
      background: $color-gray-alabaster;
      border-right: 4px solid var(--color-border-right);

    }
  }
  #order-group-block {
    width: 100%;
    min-height: 320px;
    padding-bottom: 20px;
    border-top: 4px solid var(--color-border-right);

    //overflow-x: auto;
    //max-width: 1560px;
    margin: 0 auto;
  }

  .market-trades {
    min-height: calc(100% - 425px);
    background: $color-gray-alabaster;
    width: 100%;
    border-top: 4px solid var(--color-border-right);
    min-width: 300px;
    .trade-tab {
      color: var(--text-search);
      .tab-trade {
        cursor: pointer;
        margin-top: 20px;
        margin-left: 20px;
        line-height: 19px;
        margin-right: 15px;
        font-weight: 500;
        font-size: 16px;
        &:last-child {
          margin-left: 0;
        }

      }
      .tab-trade-selected {
        color: $color-caribbean-green;
      }
    }
    .top-title{
      height: #{$heightHeaderTitle};
      background: transparent;
      position: relative;
      z-index: 10;
      margin-top: 10px;
      margin-bottom: 15px;
      .title-group{
        padding: 0 20px;
        display: inline-block;
        width: 100%;
        // border-bottom: 1px solid $color-alto;
        li{
          display: block;
          float: left;
          text-align: center;
          font-size: $font-root;
          color: var(--text-close);
          font-weight: 500;
          padding: 4px 0px 3px 0px;
          line-height: 20px;
          height: 27px;
          &:nth-child(1) {
            text-align: left;
            width: 35%;
          }
          &:nth-child(2) {
            text-align: right;
            width: 40%;
            text-indent: 3%;
          }
          &:nth-child(3) {
            width: 25%;
            text-align: right;
          }
        }
      }
    }
    .title-market-trades {
      padding-left: 10px;
      font-weight: 600;
      margin-top: 15px;
      font-size: 16px;
      line-height: 19px;
      font-family: $font-inter-bold;
      color: $color-grey-dark;

    }
    .mobile-trade {
      height: calc(100% - 50px)
    }
    ::v-deep {
      .trade-history .tbl-history .table-size tr td {
        color: var(--color_dark_market);
      }
      .trade-history .tbl-history .table-size tr td.sell {
        color: $text-color-red;
      }
      .trade-history .tbl-history .table-size tr td.buy {
        color: $text-color-jade;
      }
    }
    .trade-history {
      overflow-y: auto;
      ::v-deep {
        .tbl-history .table-size tr td:first-child {
          padding-left: 20px;
        }
      }
    }
  }

  .market-trades-right {
    width: $widthOrderForm;
    min-height: 350px;
    background: white;
    min-width: 370px;
  }
  
  #SpotExchange_page {
    position: relative;
  }
  .Trade_popup {
    position: absolute;
    top: -50px;
    left: 0px;
    background-color: rgba(0,0,0,.5);
    z-index: 99999;
    width: 100vw;
    height: calc(100% + 50px);
    cursor: pointer;
    .pop_con {
      width: 450px;
      height: 375px;
      position: fixed;
      left: 50%;
      top: 50%;
      cursor: default;
      transform: translate(-50%,-50%);
      .conimg {
        width: 100%;
        height: 100%;
      }
      .closeimg {
        width: 20px;
        height: 20px;
        position: absolute;
        right: -20px;
        top: -20px;
        cursor: pointer;
      }
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .5s
  }
  .fade-enter,
  .fade-leave-active {
    opacity: 0
  }

  @media screen and (max-width: 1500px) {
    #SpotExchange_page {
      min-width: 995px;
    }

    .full-page {
      min-width: 995px;
    }
  }

  @media screen and (max-width: 1150px) {
    .dark-theme #SpotExchange_page .history-table-container .market-trades {
      border-bottom-color: $color-cinder;
    }

    .full-page {
      min-width: 300px;
    }

    .market-trades-right {
      display: none!important;
    }

    #SpotExchange_page {
      min-width: 300px;
      #home {
        min-height: calc(100vh - 50px);
        height: auto;
        flex-wrap: wrap;
        width: 100%;
        .page-container-left, .page-container-mid, .page-container-right {
          width: 100%;
          border: none;
          border-left: none!important;
          .chart-block {
            height: calc(100vh - 50px);
          }
          .coin_info_exchange {
            padding-left: 0;
            flex-wrap: wrap;
            height: auto;
          }

          .order-book-block {
            padding-bottom: 25px;
            background-color: $color-black-shark;
          }

        }
      }
      .history-table-container {
        flex-wrap: wrap-reverse;
        padding: 0;
        #order-group-block {
          width: 100%;
        }
        .market-trades {
          width: 100%;
          border-left: none;
          border-bottom: 4px solid var(--color-border-right);
          padding-bottom: 16px;
          .title-market-trades, .top-title {
            padding: 0 20px;
            font-size: $font-root;
          }
          .recent-trade {
            max-height: 250px;
            overflow-y: auto;
            position: unset;
            padding: 0 20px;
          }
        }
      }
    }

    .light-theme {
      #SpotExchange_page #home .page-container-mid .order-book-block {
        background-color: transparent;
      }
    }
  }

  .clearfix {
    content: "";
    clear: both;
    display: table;
  }
  .market-right {
    padding: 20px 0 20px 20px;
    background: var(--background-current_coin);
    .input-search {
      font-weight: normal;
    }

    #container-tab {
      margin-bottom: 14px;
      margin-right: 20px;
      display: block;
      .search__coin {
        position: relative;
        width: 100%;
        margin-bottom: 20px;

        input{
          height: 34px;
          width: 100%;
          background: rgba(222, 222, 222, 0.3);
          border-radius: 8px;
          border: none;
          padding: 0 16px;
          min-width: 362px;
          &::placeholder {
            font-family: $font-inter-regular;
            font-size: 16px;
            font-weight: 400;
            color: $color-gray-alto;
          }
        }
        img {
          position: absolute;
          right: 9px;
          top: 50%;
          transform: translateY(-50%);
          width: 16px;
          height: 16px;
        }
      }
      .group_table_landing {
        color: $color-gray-alto;
        font-size: $font-root;
        font-family: $font-inter-regular;
        .current-market {
          color: $color-aqua-green !important;
        }
        .current-market-favorites {
          color: #FFBF0F !important;
        }

        label {
          margin-right: 8px;
          cursor: pointer;
          font-weight: 400;
          color: $color-gray-alto;
        }
      }
    }

    .table-content {
      #content {
        ::v-deep{
          .table-landing table {
            table-layout: fixed;
            width: calc(100% - 9px);
            thead {
              th {
                text-align: left;
                padding: 0;
                span {
                  font-size: $font-root;
                  color: var(--text-close);
                }
                &:first-child {
                  width: 30%;
                  min-width: 115px;
                  @media screen and (max-width: 992px){
                    width: 30%;
                    min-width: 103px;
                  }
                }
                &:nth-child(2) {
                  width: 26%;
                  min-width: 114px;
                  @media screen and (max-width: 992px){
                    width: 31%;
                    min-width: 105px;
                  }
                  text-align: right;
                }
                &:nth-child(3) {
                  //width: 38%;
                  text-align: right;
                  padding-right: 30px!important;
                }
                &:last-child {
                  text-align: right;
                }
              }
            }
            tbody {
              tr {
                &:first-child {
                  margin: 0;
                }
              }
              td {
                text-align: left;
                padding: 0;
                color: var(--text-primary-color);
                .icon-star1 {
                  margin-right: 4px;
                }
                &:first-child {
                  width: 30%;
                  min-width: 115px;
                  @media screen and (max-width: 992px){
                    width: 30%;
                    min-width: 103px;
                  }
                }
                &:nth-child(2) {
                  width: 26%;
                  min-width: 114px;
                  text-align: right;
                  @media screen and (max-width: 992px){
                    width: 31%;
                    min-width: 105px;
                  }
                }
                &:nth-child(3) {
                  //width: 32%;
                  //min-width: 124px;
                }
                &:last-child {
                  text-align: right;
                }
              }
            }
            tbody {
              display: block;
              height: 250px;
              overflow-y: scroll;
              overflow-y: overlay;
              padding-right: 9px;
              width: calc(100% + 9px);
              &::-webkit-scrollbar {
                position: absolute;
              }
              &:before {
                content: none;
              }
            }
            thead th:last-child{ padding-right: 13px;}
            thead, tbody tr {
              display: table;
              width: 100%;
            }
          }
        }
        ::-webkit-scrollbar-corner {
          background: rgba(0,0,0,0);
        }
      }
    }

    @media screen and (max-width: 1500px) {
      .table-content #content {
        ::v-deep {
          .table-landing > table tbody td {
            font-size: 12px;
          }
          .table-landing > table {
            thead {
              th {
                &:first-child {
                  width: 23%;
                  min-width: 85px;
                }
                &:nth-child(2) {
                  width: 25%;
                  min-width: 80px;
                }
                &:nth-child(3) {
                  width: 25%;
                  min-width: 80px;
                }
                &:last-child {
                  width: 27%;
                  min-width: 50px;
                  text-align: right;
                }
              }
            }
            tbody {
              td {
                &:first-child {
                  width: 23%;
                  min-width: 85px;
                }
                &:nth-child(2) {
                  width: 25%;
                  min-width: 80px;
                }
                &:nth-child(3) {
                  width: 25%;
                  min-width: 80px;
                }
                &:last-child {
                  width: 27%;
                  min-width: 50px;
                  text-align: right;
                }
              }
            }
            tbody {
              display: block;
              height: 250px;
              overflow-y: auto;
              padding-right: 10px;
            }
            thead th:last-child{ padding-right: 10px;}
            thead, tbody tr {
              display: table;
              width: 100%;
            }
          }
        }
      }
      #container-tab .search__coin input {
        min-width: auto;
      }
    }
  }
  .textAuthen {
    text-align: center;
    font-size: 18px;
    color: $color-grey-dark;
    background: transparent;
    line-height: 20px;
    padding: 80px 0 100px 0;
    white-space: nowrap;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    a {
      font-family: $font-inter-bold;
      color: $color-caribbean-green;
    }
  }
  .mobile-version {
    display: none;
  }

  .btn-buy-sell-mobile {
    display: none;
  }
  @media screen and (max-width: 1250px) {
    .dark-theme {
      .mobile-version {
        .tab-mobile {
          background: var(--background-current_coin);
        }
      }
    }
    .web-version {
      display: none;
    }
    .market-trades {
      height: 350px;
    }
    .mobile-version {
      display: block;
      .page-container-left {
        display: block;
        float: left;
        height: 100%;
        width: 100%;      //border-right: 1px solid $color-bright-gray-dark;
        .coin_info_exchange{
          display: flex;
          align-items: center;
          padding-left: 0;
          flex-wrap: wrap;
          height: auto;
          border-bottom: 2px solid var(--color-border-right);
          border-right: none;
          background: var(--background-current_coin);
        }
      }
      .tab-mobile {
        width: 100%;
        padding: 11px 0 11px 16px;
        background: white;
        border-top: 1px solid var(--color-border-right);

        .tab-item {
          margin-right: 20px;
          font-weight: 400;
          font-size: 18px;
          color: $color-gray-alto;
          cursor: pointer;
        }
        .tab-item-selected {
          color: $color-aqua-green;
        }
      }
    }

    .btn-buy-sell-mobile {
      display: block;
      min-height: 80px;
      background: var(--background-spot);
      box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.04);
      width: 100%;
      position: fixed;
      bottom: 0;
      z-index: 2020;
      padding: 16px;
      .btn-login-signup{
        background: $color-caribbean-green;
        border-radius: 8px;
        width: 100%;
        height: 100%;
        color: $dark-1;
        min-height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        a {
          cursor: pointer;
          color: $dark-1;
          font-weight: 700;
        }
      }
    }

    .mobile-button {
      font-weight: 600;
      font-size: 16px;
      .button-buy {
        width: 48%;
        min-height: 50px;
        text-align: center;
        background: $text-color-jade;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        cursor: pointer;
      }
      .button-sell {
        width: 48%;
        min-height: 50px;
        text-align: center;
        background: $text-color-red;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        cursor: pointer;
      }
    }
    .order-container {
      padding: 20px 0;
      min-height: 375px;
      left: 0;
      bottom: 0;
      width: 100%;
      z-index: 101;
      background: var(--background-order-coin);

      .title-spot {
        font-weight: 600;
        font-size: 18px;
        line-height: 19px;
        color: var(--color_dark_market);
        padding-left: 20px;
      }
      .close-btn {
        position: absolute;
        right: 8px;
        top: 8px;
        color: $color-gray-alto;
        font-size: 12px;
        cursor: pointer;
      }

    }
  }
  @media screen and (max-width: 992px) {
    #SpotExchange_page {
      border-top-width: 2px;
    }

    #order-group-block {
      margin-bottom: 82px;
    }
    .trade-history {
      padding: 0;
      ::v-deep {
        .tbl-history {
          padding: 0 16px;
          .table-size tr td:first-child {
            padding-left: 0px!important;
          }
        }
      }
    }
    .mobile-version .tab-mobile .tab-item {
      font-size: 14px;
    }
    .recent-trade {
      height: 77%;
    }
    .market-trades {
      border-top: none;
      .trade-tab {
        .tab-trade {
          margin-top: 16px;
          margin-left: 16px;
          font-size: $font-root;
        }
      }

      ::v-deep {
        .top-title {
          margin-top: 15px;
          margin-bottom: 12px;
          .title-group {
            padding: 0 16px;
          }
        }
      }
    }
    .market-right {
      padding-left: 16px;
      padding-right: 16px;
      #container-tab {
        margin-right: 0;
      }
    }
    #SpotExchange_page {
      ::v-deep {
        #order-group-block {
          border: none;

          #order-group {
            padding-top: 20px;
            border-top: 4px solid var(--color-border-right);

            #order-table table thead th{
              font-size: 14px;
              &:first-child {
                padding-left: 20px !important;
              }
            }

            #open-history .group-control {
              padding-left: 8px;
            }

            #trade-history .group-control {
              padding-left: 8px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 572px){
    .mobile-version .page-container-left .coin_info_exchange {
      border-bottom: none;
    }

  }
  @media screen and (max-width: 500px) {
    .mobile-version .tab-mobile .tab-item {
      font-size: 14px;
    }
  }
</style>


