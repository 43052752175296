<template>
  <div class="ModalWithdrawSuccess">
    <modal :name="name">
      <div slot="body">
        <div class="wrapper-content">
          <img
            :src="
              theme === 'dark-theme'
                ? require('@/assets/images/icon-success-2-dark.svg')
                : require('@/assets/images/icon-success-2.svg')
            "
          />
          <div class="text">
            {{ $t("funds.withdrawals.confirm_withdrawal") }}
          </div>
          <button @click="handleClick">{{ $t("common.action.ok") }}</button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/shared_components/common/Modal";
import Cookies from "js-cookie";

export default {
  components: {
    Modal,
  },
  data() {
    return {
      theme:
        Cookies.get("user-theme", { domain: process.env.VUE_APP_PUBLIC_URL }) ||
        "dark-theme",
    };
  },
  props: {
    name: { type: String, default: "ModalWithdrawSuccess" },
  },
  methods: {
    handleClick() {
      window.app.$broadcast("hideCommonModal", this.name);
    },
  },
};
</script>

<style lang="scss">
.ModalWithdrawSuccess {
  .modal-dialog {
    width: 560px;
  }
  .modal .modal-dialog .modal-content {
    .modal-header {
      padding: 40px 30px !important;
    }

    .modal-body {
      margin-top: -50px;

      .wrapper-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 40px;

        .text {
          font-size: 18px;
          color: var(--text-dark-2);
          text-align: center;
        }

        button {
          text-align: center;
          background: #00ddb3;
          border-radius: 8px;
          width: 200px;
          height: 50px;
          font-size: 18px;
          color: #333333;

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
}
</style>
