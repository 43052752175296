<template>
  <div class="entry-teams-tab tab_entry" v-if="onTabEntry==='my-team'">
    <div class="group_search_save clearfix">
      <div class="form_search_contracts">
        <!--        <input v-model="filterTeam" type="text" class="input-search-contracts" :placeholder="$t('notice.search')">-->
        <!--        <button class="btn btn-search-contracts" @click="actionSearch">{{ $t('order.order_history.search') }}</button>-->
        <!--                <button class="btn btn-clear-search-contracts" @click="actionClearSearch">{{ $t('button.clear') }}</button>-->
      </div>
      <div class="data-table">
        <data-table4 :getData="getTeamEntryMember" :limit="5" :msgEmptyData="$t('common.datatable.no_data')"
                     class="datatable_contracts"
                     ref="myRequestList">
          <template slot-scope="props">
            <th v-if="isLeader"  class="w_25" data-sort-field="email">
              {{$t('margin.entry.email')}}
            </th>
            <th v-else data-sort-field="id"  class="w_25">
              {{$t('m_account.id')}}
            </th>
            <th class="w_35" data-sort-field="created_at">
              {{$t('mam.joined_at')}}
            </th>
            <th class="w_20 text-left" data-sort-field="is_leader">
              {{$t('margin.entry.is_leader_span')}}
            </th>
          </template>
          <template slot="body" slot-scope="props">
            <tr>
              <td v-if="isLeader">{{props.item.email}}</td>
              <td v-else>{{props.item.id}}</td>
              <td>{{props.item.created_at | convertToLocalTime}}</td>
              <td class="text-left" style="text-align:left">{{props.item.is_leader ? $t('common.action.yes') :
                $t('common.action.no')}}
              </td>
            </tr>
          </template>
        </data-table4>
      </div>
    </div>
  </div>
</template>

<script>
  import rf from '@/request/RequestFactory'

  export default {
    name: "MyTeamEntryOverview",
    props: {
      onTabEntry: {
        type: String,
      },
      isLeader: {},
    },
    data: function () {
      return {}
    },
    methods: {
      getEventHandlers() {
        return {
          MarginEntryUpdated: this.onEntryNumberOfRequest,
        };
      },
      onEntryNumberOfRequest (data) {
        this.$refs.myRequestList.refresh();
        // if (data.isOutTeam) {
        //   window.CommonModal.hide('TeamEntry');
        //   this.onTabEntry = 'list';
        // }
      },
      getTeamEntryMember: function ($params) {
        return rf.getRequest('EntryRequest').getTeamEntryMember($params);
      },
    }
  }
</script>

<style lang="scss" scoped>

</style>