<template>

  <div id="beta_tester_form">
    <!-- <confirmation-beta-tester :modal-name="modalName"></confirmation-beta-tester> -->
    <div id="ConfirmationBetaTester">
      <modal :name="modalName" width="440" :title="modalTitle">
        <template slot="body">

          <div class="confirmation-beta-tester-modal">

            <div class="box_body clearfix">
              <div class="group_find_join_modal" v-html="$t('beta_tester.content')">
              </div>

              <div class="checkbox-input" :class="disableAgreeTermsCheckbox ? 'disabled' : ''">
                <input type="checkbox" @click="changeAgreeTerm" id="agreeTermsInput" name="" :disabled="disableAgreeTermsCheckbox"/>
                <label for="agreeTermsInput" :disabled="disableAgreeTermsCheckbox"></label>
                <span v-html="$t('beta_tester.agree_term')" :disabled="disableAgreeTermsCheckbox"></span>
              </div>
            </div>

            <div class="clearfix"></div>
            <div class="box_footer clearfix">
              <button class="btn btn_footer btn_oke" @click="onClickOk">{{ $t('beta_tester.ok') }}</button>
              <span class="txt">{{ $t('beta_tester.or') }}</span>
              <button class="btn btn_footer btn_cancel" @click="onClickCancel">{{ $t('beta_tester.cancel') }}</button>

              <div class="dont-show-again">
                <div class="checkbox-input" :class="disableIgnoreTesterCheckbox ? 'disabled' : ''">
                  <input type="checkbox" @click="changeIgnoreTester" id="ignoreTesterInput" name="" :disabled="disableIgnoreTesterCheckbox" />
                  <label for="ignoreTesterInput" :disabled="disableIgnoreTesterCheckbox"></label>
                  <span v-html="$t('beta_tester.dont_show_again')" :disabled="disableIgnoreTesterCheckbox"></span>
                </div>
              </div>

              <div class="clearfix"></div>
            </div>

          </div>

        </template>
      </modal>
    </div>



  </div>
</template>

<script>
  import rf from '@/request/RequestFactory'
  import Const from '@/common/Const';
  import Modal from '@/components/shared_components/common/Modal.vue';
  import { mapGetters, mapMutations } from 'vuex';

  export default {
    components: {
      Modal,
    },
    computed: {
      ...mapGetters(['currentSymbol','user'])
    },
    data() {
      return {
        isEnableBetaTeserProgram: true, // Enable Beta Tester Program
        activeTesterStatus: Const.BETA_TESTER.INACTIVE,
        ignoreTester: false,
        agreeTerms: false,
        disableIgnoreTesterCheckbox: false,
        disableAgreeTermsCheckbox: false,
        cancelPopupBetaTester: window.cancelPopupBetaTester || false,
        modalName: 'ConfirmationBetaTester',
        modalTitle: this.$t('beta_tester.header'),
        ignoreExpiredAt: null,
        submitedRegisterBetaTester: false,
        coinSetting: {},
      }
    },
    watch: {
      currentSymbol () {
        this.initData();
      }
    },
    methods: {
      changeIgnoreTester() {
        this.ignoreTester = !this.ignoreTester;
        if (this.ignoreTester) {
          this.disableAgreeTermsCheckbox = true;
        } else {
          this.disableAgreeTermsCheckbox = false;
        }
      },
      changeAgreeTerm() {
        this.agreeTerms = !this.agreeTerms;
        if (this.agreeTerms) {
          this.disableIgnoreTesterCheckbox = true;
        } else {
          this.disableIgnoreTesterCheckbox = false;
        }
      },

      initData() {
        this.ignoreTester = false;
        this.agreeTerms = false;
        this.disableIgnoreTesterCheckbox = false;
        this.disableAgreeTermsCheckbox = false;
        if (window.isAuthenticated && this.isEnableBetaTeserProgram) {
          rf.getRequest("MarginRequest")
            .getContractSetting({ symbol: this.currentSymbol })
            .then(res => {
              if (!res && !res.data) {
                return;
              }
              const setting = res.data;
              if (!setting.is_enable && setting.is_show_beta_tester) {
                this.getTradingSetting();
              } else {
                this.$broadcast('BetaTesterShowData', true);
              }
            });
        }
        else {
          rf.getRequest("MarginRequest")
            .getContractSetting({ symbol: this.currentSymbol })
            .then(res => {
              if (!res && !res.data) {
                return;
              }
              const setting = res.data;
              if (setting.is_enable) {
                this.$broadcast('BetaTesterShowData', true);
              } else {
                this.$broadcast('BetaTesterHideData', true);
              }
            });
        }
      },
      getTradingSetting() {
        const params = {
          symbol: this.currentSymbol,
          is_mam: 1,
        }
        rf.getRequest("MarginRequest")
            .getTradingSetting(params)
            .then(res => {
              if (res.data) {
                let status = res.data.status;
                // // Show Popup Register Beta Tester
                // if (status == Const.ENABLE_TRADING.DISABLE) {
                //   this.showBetaTesterPopup();
                // }
                // // Show Popup Waiting Admin Confirm
                if (status == Const.ENABLE_TRADING.ENABLE) {
                  this.$broadcast('BetaTesterShowData', true);
                  return res;
                }
                if (status == Const.ENABLE_TRADING.WAITING) {
                  this.showAdminWaitingPopup();
                }
              } else {
                const listSymbolNotShowBetaTest = this.user.listSymbolNotShowBetaAgain;
                if(listSymbolNotShowBetaTest && listSymbolNotShowBetaTest.includes(this.currentSymbol)){
                  return
                }
                  this.showBetaTesterPopup();
              }
              this.$broadcast('BetaTesterHideData', true);

              return res;
            });
      },

      showAdminWaitingPopup() {
        this.$broadcast('BetaTesterWaitAdminVerify', true);
      },
      showBetaTesterPopup() {
        window.CommonModal.show(this.modalName);
      },
      validate() {
        if (!this.agreeTerms) {
          this.showError(this.$t('beta_tester.validate.agree_term'));
          return false;
        }
        return true;
      },
      ...mapMutations(['updateUser']),
      onOkDoNotShowAgain(){
        let {listSymbolNotShowBetaAgain} = this.user;
        if(listSymbolNotShowBetaAgain){
          listSymbolNotShowBetaAgain.push(this.currentSymbol);
        }else{
          listSymbolNotShowBetaAgain = [this.currentSymbol];
        }
        this.updateUser({...this.user,listSymbolNotShowBetaAgain});
        window.CommonModal.hide(this.modalName);
      },
      onClickOk() {
        if(this.ignoreTester){
          return this.onOkDoNotShowAgain();
        }
        if (!this.validate()) {
          return false;
        }
        let params = {
          ignore_tester: this.ignoreTester,
          agree_terms: this.agreeTerms,
          symbol: this.currentSymbol,
          is_mam: 1,
        };
        this.submitedRegisterBetaTester = true;
        rf.getRequest("MarginRequest")
          .registerBetaTester(params)
          .then(res => {
            if (res.success) {
              this.showSuccess(this.$t('beta_tester.register_success'));
            } else {
              this.showError(this.$t('beta_tester.register_fail'));
            }
            window.CommonModal.hide(this.modalName);
            if (!this.ignoreTester) {
              this.showAdminWaitingPopup();
            }
            // Reset control states to show popup by Socket Event
            this.ignoreTester = false;
            this.agreeTerms = false;
            this.disableIgnoreTesterCheckbox = false;
            this.disableAgreeTermsCheckbox = false;
          })
          .catch(error => {
            this.showError(this.$t('beta_tester.register_fail'));
          });
      },
      onClickCancel() {
        this.cancelPopupBetaTester = true;
        window.cancelPopupBetaTester = true;
        window.CommonModal.hide(this.modalName);
      },
      showError(message) {
        Message.error(message, {}, { position: "bottom_left" });
      },
      showSuccess(message) {
        Message.success(message, {}, { position: "bottom_left" });
      },
      closeModal () {
        this.$emit(`${this.modalName}:CLOSE`);
      },
    },
    mounted() {
      document.title = this.$t('menu.exchange') + ` – ${this.tabTitle}`;
      this.initData();
    }
 }
</script>

<style lang="scss">
  @import '@/assets/sass/variables';
  #beta_tester_form {
    #ConfirmationBetaTester {
      .modal-content {
        border-radius: 7px;
        min-width: 550px;
        .modal-header {
          border-top-right-radius: 7px;
          border-top-left-radius: 7px;
          background-color: #EFF4F8;
          border-bottom: 1px solid #ccc;
          font-size: 19px;
          padding: 25px 30px 20px 30px;
        }
        .modal-title {
          font-size: 23px;
          line-height: 30px;
        }
        .modal-body {
          .box_body {
            min-height: 250px;
          }
          padding: 0 0 0 0;
          .box_body {
            padding: 15px 30px;
            .group_find_join_modal {
              font-size: 14px;
              color: #333;
              line-height: 20px;
              margin-bottom: 5px;
            }
          }
          .box_footer {
            border-bottom-left-radius: 7px;
            border-bottom-right-radius: 7px;
            background-color: #EFF4F8;
            padding: 15px 30px;
            margin-top: 15px;
            border-top: 1px solid #cccccc;
            .btn_footer {
              height: 40px;
              min-width: 70px;
              text-align: center;
              font-size: 16px;
              line-height: 20px;
              padding: 11px 15px;
              border-radius: 3px;
              color: #999;
              border: 1px solid #ddd;
              display: inline-block;
              float: left;
            }
            .btn_oke {
              min-width: 100px;
              text-transform: uppercase;
              background-color: #2DAC91;
              color: #ffffff;
              border: none;
              &:hover {
                background-color: #2e9bdb;
              }
            }
            .btn_cancel {
              border: none;
              background-color: transparent;
              padding-left: 0;
            }
            .txt {
              display: inline-block;
              float: left;
              line-height: 20px;
              padding: 10px 10px 10px 20px;
            }
          }
        }
        .modal-footer {
          display: none;
        }
      }
      .checkbox-input {
        width: 220px;
        display: inline-block;
        margin-left: 7px;
        position: relative;
        top: 7px;
        margin-top: 13px;
        input {
          display: none;
        }
        label {
          cursor: pointer;
          position: absolute;
          width: 17px;
          height: 17px;
          top: 0;
          left: 0;
          border: 1px solid $color-grey-dusty;
          border-radius: 1px;
          &:after {
            opacity: 0;
            content: '';
            position: absolute;
            width: 16px;
            height: 10px;
            background: transparent;
            top: 0;
            left: 0;
            border: 4px solid $color-grey-dusty;
            border-top: none;
            border-right: none;
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
          }
        }
        input[type=checkbox]:checked + label:after {
          opacity: 1;
        }
        span {
          margin-left: 25px;
        }
      }
      .disabled {
        color: #e2e2e2;
        label {
          border: 1px solid #e2e2e2;
        }
      }
      .dont-show-again {
        float: right;
        .checkbox-input {
          width: 150px;
          margin-top: -9px;
        }
      }
    }
  }
</style>