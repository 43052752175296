<template>
  <div>
    <Preloader />
  <router-view></router-view>
</div>
</template>
<script>
import Preloader from './components/shared_components/common/Preloader.vue'
import {mapGetters} from 'vuex'
import rf from "@/request/RequestFactory";

export default {
  name: 'App',
  components: {
    Preloader,
  },
  computed: {
    ...mapGetters({
      lang: 'getLang'
    })
  },
  watch: {
    lang() {
      this.setLanguageValidate(this.lang);
    }
  },
  created () {
    rf.getRequest('MasterdataRequest').getAll()
    .then((res) => {
      this.$store.commit('setMasterdata', res);
    })
    this.setLanguageValidate(this.lang)
  },
  methods:{
    getSocketEventHandlers() {
      return {
        MasterDataUpdated: this.onMasterDataUpdated,

      };
    },

    setLanguageValidate(lang) {
      const require = { required: this.$t('account.identity.errors.required') }
      const dict = {
        custom: {
          email: {
            ...require,
            email: this.$t("account.email.required"),
          },
          password: require,
          'new_password': require,
          new_password_confirm: require,
          'confirm password': {
            ...require,
            confirmed: this.$t("change_password_form.error_confirm"),},
          code: {
            ...require,
            length: this.$t("funds.withdrawals.errors.otp_length_6_digits")
          },
          otp_recovery_code: require,
          confirmNewCode: require,
          newCode: require,
          code_verify: require,
        }
      };
      this.$validator.localize(lang, dict);
    },
    onMasterDataUpdated() {
      rf.getRequest('MasterdataRequest')._get();
    },
  }
}
</script>
<style lang="scss">
@import '@/assets/sass/main.scss';
 //::-webkit-scrollbar-thumb {
 //   background: var(--background-progress);
 //   border-radius: 10px;
 // }
body {
  margin: 0;
}
.clearfix::before, .row::before {
  display: none;
}
.clearfix::after .row::after {
  display: none;
}
</style>