/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import '@/common/bootstrap';
import '@/common/Filters.js';
import '@/common/gt.js';
import VueCookies from 'vue-cookies';

import Vue from 'vue';
import VueBroadcast from '@/common/VueBroadcast';
import VueRequestCanceler from '@/common/VueRequestCanceler';
import VeeValidate from 'vee-validate';
import VueRouter from 'vue-router';
import GlobalSocket from '@/common/GlobalSocket';
import Const from '@/common/Const';
import rf from '@/request/RequestFactory'

import ClickOutside from 'vue-click-outside';
import Routers from './routes';

import App from './App.vue';
import DataTable from '@/components/shared_components/common/DataTable/DataTable.vue';
import DataTable2 from '@/components/shared_components/common/DataTable/DataTable2.vue';
import DataTable3 from '@/components/shared_components/common/DataTable/DataTable3.vue';
import DataTable4 from '@/components/shared_components/common/DataTable/DataTable4.vue';
import DataTable5 from '@/components/shared_components/common/DataTable/DataTable5.vue';
import FiatCurrency from '@/components/shared_components/common/FiatCurrency.vue';

import VTooltip from 'v-tooltip';
import FullScreen from 'vue-fullscreen';
import Multiselect from 'vue-multiselect';
import VueClipboard from 'vue-clipboard2';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';
import CurrencyInput from '@/components/shared_components/common/CurrencyInput';
import InputOnlyNumber from '@/components/shared_components/common/InputOnlyNumber';
import InputOnlyNumber2 from '@/components/shared_components/common/InputOnlyNumber2';
import InputTextAndNumber from '@/components/shared_components/common/InputTextAndNumber';
import {VueReCaptcha} from 'vue-recaptcha-v3'

import SiteSupport from '@/common/SiteSupport';
import AuthenticationUtils from '@/common/AuthenticationUtils';
import MixPanel from '@/common/MixPanel';
import locales from '@/locales';
import "url-search-params-polyfill";
import store from './store';
import _ from 'lodash';

import VueI18n from 'vue-i18n'

import Cookies from 'js-cookie'
import VueSocketIO from 'vue-socket.io'
import {BootstrapVue} from 'bootstrap-vue'
import SocketIO from 'socket.io-client'
import QrcodeVue from "qrcode.vue";
import Vuex from 'vuex';
// import 'bootstrap/dist/css/bootstrap.scss'
// import 'assets/sass/bootstrap.scss'
import './assets/sass/bootstrap.scss'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-phone-number-input/dist/vue-phone-number-input.css';


Object.defineProperty(Vue.prototype, '_', { value: _ });

Vue.component('data-table', DataTable);
Vue.component('data-table2', DataTable2);
Vue.component('data-table3', DataTable3);
Vue.component('data-table4', DataTable4);
Vue.component('data-table5', DataTable5);
Vue.component('fiat-currency', FiatCurrency);
Vue.component('unregistered-session-dialog', require('@/components/shared_components/common/UnregisteredSessionDialog.vue'));
Vue.component('qrcode', QrcodeVue)
Vue.use(VueCookies);
Vue.use(Vuex);
Vue.use(VueI18n)
Vue.use(VueRouter);
Vue.use(BootstrapVue)
Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_GOOGLE_CAPTCHA_KEY,
  loaderOptions: {
    useRecaptchaNet: true
  }
})

const i18n = new VueI18n({
  locale: Cookies.get('locale', { domain: process.env.VUE_APP_PUBLIC_URL }) || 'en',
  messages: locales,
})

Vue.use(new VueSocketIO({
  debug: false,
  connection: process.env.VUE_APP_SOCKET_URL || '',
  vuex: {
    store,
  },
}))

Vue.use(new VueSocketIO({
  debug: false,
  connection: SocketIO(process.env.VUE_APP_API_FUTURES_URL || '', {
    query: {
      authorization: Cookies.get('access_token', { domain: process.env.VUE_APP_PUBLIC_URL }) || "",
    },
    transports: ['websocket'],
  }),
  vuex: {
    store,
  },
}))

Vue.use(VeeValidate, {
  i18nRootKey: 'validation', // customize the root path for validation messages.
  i18n,
  locale: Cookies.get('locale', { domain: process.env.VUE_APP_PUBLIC_URL }) || 'en',
  dictionary: locales
});

Vue.use(VTooltip);
Vue.use(FullScreen);
Vue.use(VueBroadcast);
Vue.use(VueRequestCanceler);
Vue.use(VueClipboard);
Vue.use(SiteSupport);
Vue.use(MixPanel);
Vue.use(VueAwesomeSwiper);
//Vue.use(VueReCaptcha, { siteKey: '6LeP164UAAAAALnFX0pMaiZzfkZ4qgi8JP5Jh8qA' })

Vue.component('multiselect', Multiselect);
Vue.directive('click-outside', ClickOutside);
Vue.component('currency-input', CurrencyInput);
Vue.component('input-only-number', InputOnlyNumber);
Vue.component('input-only-number-2', InputOnlyNumber2);
Vue.component('input-text-number', InputTextAndNumber);
// Vue.component('vue-recaptcha', VueRecaptcha);

Vue.mixin({
  mounted: function () {
    if (this.getEventHandlers) {
      window._.forIn(this.getEventHandlers(), (handler, eventName) => {
        this.$on(eventName, handler);
      });
    }
    if(this.validate) {
      this.$on('UPDATED_LOCALE', () => {
        if(this.errors.count() > 0) {
          this.validate();
        }
      });
    }
  },
  beforeDestroy() {
    if (this.getEventHandlers) {
      window._.forIn(this.getEventHandlers(), (handler, eventName) => {
        this.$off(eventName, handler);
      });
    }
    if(this.validate) {
      this.$off('UPDATED_LOCALE');
    }
  }
});

const router = new VueRouter(Routers);
let isMixPanelInitialized = false;

export var user = null;
window.GlobalSocket = new GlobalSocket();
router.beforeEach(async (to, from, next) => {
  window.i18n.locale = AuthenticationUtils.getLocale(document.documentElement.lang);
  document.title = window.i18n.t('common.window_title');
  
  let url = window.location.href;
  let redirectRouteName = null;
  let urlSearch = new URLSearchParams(window.location.search);

  if (url.search("login") > 0 && url.search("return_to") > 0) {
    redirectRouteName = urlSearch.get("return_to");
  }
  if (to.meta.auth && !window.isAuthenticated) {
    return next({ path: '/login' });
  }

  if (to.meta.guest && window.isAuthenticated) {
    if (redirectRouteName) {
      let link = await getLink(redirectRouteName);
      window.location.href = link;
      return null;
    } else {
      if ((url.search("login") && (urlSearch.get("code")) ) || ((url.search("reset-password")) && (urlSearch.get("token")))) {
        return next();
      }
      return next({ path: '/' });
    }
  }
  return next();
});

async function getLink(url) {
  let res = await rf.getRequest("UserRequest").getCurrentUser();
  let urlToZendesk = await rf.getRequest("ZendeskRequest").autoLogin(decodeURIComponent(url), res.data.email);
  return urlToZendesk.data;
}

router.afterEach((to, from) => {

  /**
   * ------------------------------------------------------------------
   * -------------- Mixpanel route tracking---------------------------
   * ------------------------------------------------------------------
   */
  const trackRoute = () => {
    if (from && from.name) {
      router.app.$mixpanel.track(`Route - ${to.name}`, { ...to, prevName: from.name, prevFullPath: from.fullPath, });
    } else {
      router.app.$mixpanel.track(`Route - ${to.name}`, { ...to });
    }
  };

  if (router.app && router.app.$mixpanel && to && to.name)  {
    if (from && from.name === to.name) {
      return;
    }

    if (isMixPanelInitialized) {
      // trackRoute();
      return;
    }
    
    router.app.$mixpanel.init(Const.MIXPANEL_TOKEN);
    isMixPanelInitialized = true;

    if (window.isAuthenticated) {
      // Mixpanel set current user
      rf.getRequest('UserRequest').getCurrentUser().then((response) => {
        const currentUser = response.data;
        const userId = currentUser.id;

        router.app.$mixpanel.identify(userId);
        router.app.$mixpanel.people.set({
          ...currentUser,
          '$id': currentUser.id,
        });
      });
    }

    // trackRoute();
  }
  /**
   * ------------------------------------------------------------------
   * -------------- Finish. Mixpanel route tracking-------------------
   * ------------------------------------------------------------------
   */


});

Vue.mixin({
  data () {
    return {
      tabTitle: process.env.VUE_APP_SHORT_NAME,
      isSubmitting: false,
    };
  },
  methods: {
    startSubmit () {
      this.isSubmitting = true;
    },
    endSubmit () {
      this.isSubmitting = false;
    },
    getSubmitName (name) {
      return this.isSubmitting ? this.$t('common.processing') : name;
    },
  }
});

window.app = new Vue({
  i18n,
  router,
  store,
  render: function(createElement) {
    return createElement(App);
  }
}).$mount('#app');

window.app.tabTitle = process.env.VUE_APP_SHORT_NAME +`${window.app.$t('window.suffix_title')}`;
