<template>
  <div v-if="isAuthenticated">

    <div class="head_group_positions">
      <li class="item" @click="showContentPositons = 0" v-bind:class="{'active': showContentPositons === 0}">
        {{ $t('margin.positions') }} <span class="sub">[{{positionCount}}]</span>
      </li>
      <li class="item" @click="showContentPositons = 1" v-bind:class="{'active': showContentPositons === 1}">
        {{ $t('margin.close_positions') }} <span class="sub"></span>
      </li>
      <li class="item" @click="showContentPositons = 2" v-bind:class="{'active': showContentPositons === 2}">
        {{ $t('margin.active_orders') }} <span class="sub">[{{activeOrderCount}}]</span>
      </li>
      <li class="item" @click="showContentPositons = 3" v-bind:class="{'active': showContentPositons === 3}">
        {{ $t('margin.stops') }} <span class="sub">[{{stopOrderCount}}]</span>
      </li>
      <li class="item" @click="showContentPositons = 4; showFillTab = true" v-bind:class="{'active': showContentPositons === 4}">
        {{ $t('margin.fills') }}
        <!-- <i class="icon icon-help"></i> -->
      </li>
      <li class="item" @click="showContentPositons = 5; showHistoryTab = true" v-bind:class="{'active': showContentPositons === 5}">
        {{ $t('margin.order_history') }}
      </li>
    </div>

    <div class="clearfix"></div>
    
    <div class="content_group_positions">
      <div class="" v-show="showContentPositons === 0">
        <positions-table></positions-table>
      </div>
      <div class="" v-show="showContentPositons === 1">
        <closed-positions></closed-positions>
      </div>
      <div class="" v-show="showContentPositons === 2">
        <active-orders></active-orders>
      </div>
      <div class="" v-show="showContentPositons === 3">
        <stop-table></stop-table>
      </div>
      <div class="" v-if="showFillTab" v-show="showContentPositons === 4">
        <fill-table></fill-table>
      </div>
      <div class="" v-if="showHistoryTab" v-show="showContentPositons === 5">
        <order-history></order-history>
      </div>
    </div>

  </div>
</template>

<script>
  import PositionsTable from './positions/Positions.vue';
  import ClosedPositions from './positions/ClosedPositions.vue';
  import ActiveOrders from './positions/ActiveOrders.vue';
  import StopTable from './positions/StopTable.vue';
  import FillTable from './positions/FillTable.vue';
  import OrderHistory from './positions/OrderHistory.vue';
  import { mapGetters } from 'vuex';

  export default {
    components: {
      PositionsTable,
      ClosedPositions,
      ActiveOrders,
      StopTable,
      FillTable,
      OrderHistory,
    },
    computed: {
      ...mapGetters({ activeOrderCount: 'mamActiveOrderCount', stopOrderCount: 'mamStopOrderCount', positions: 'mamPositions' }),
    },
    watch: {
      positions(){
        this.positionCount = Array.isArray(this.positions) && this.positions.filter(el=>parseInt(el.current_qty)).length;
      }
    },
    data() {
      return {
        showContentPositons: 0,
        showFillTab: false,
        showHistoryTab: false,
        isAuthenticated: window.isAuthenticated,
        positionCount: 0
      }
    },
    methods: {
    },
    mounted() {
    }
 }
</script>

<style lang="scss" scoped>  
  @import '@/assets/sass/variables';
  .head_group_positions {
    overflow: hidden;
    padding: 5px 15px 0px 15px;
    background-color: $color-ebony-clay;
    margin-bottom: -1px;
    .item {
      display: inline-block;
      float: left;
      height: 35px;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      text-align: center;
      line-height: 16px;
      color: $color-grey-sliver;
      font-size: $font-root;
      font-family: $font-family-roboto-medium;
      padding: 11px 16px 5px 16px;
      margin-right: 1px;
      position: relative;
      background-color: transparent;
      cursor: pointer;
      &.active {
        background-color: $color-mirage;
        color: $color-orange;
      }
      &:hover {
        background-color: $color-mirage;
      }
      .sub {
        font-family: $font-family-roboto;
      }
      .icon {
        font-family: $font-family-roboto;
      }
    }
  }
  .content_group_positions {
    border-top: solid 1px $color-bright-gray-dark;
    overflow-y: hidden;
    min-height: 250px;
    >div {
      width: calc(100% - 4px);
      margin: auto;
    } 
  }
</style>