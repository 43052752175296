<template>
  <div id="order_trade_form">
<!--    <div class="fl-left tab-container">-->
<!--      <div @click="clickOrderTab" class="tab-element" v-bind:class="{'active' : isTabOrderTrade === 1}">-->
<!--        <span class="text-size">{{ $t('order.order_trade_form.order') }}</span>-->
<!--      </div>-->
<!--      <div @click="clickTradeTab" class="tab-element" v-bind:class="{'active' : isTabOrderTrade === 0}">-->
<!--        <span class="text-size">{{ $t('order.order_trade_form.trades') }}</span>-->
<!--      </div>-->
<!--    </div>-->
    <div class="clearfix"></div>
    <div class="tab_content tab_content_ordertrade" >
      <div class="item" v-show="isTabOrderTrade === 1">
        <order-form :triggerOrderFormTab="triggerOrderFormTab"/>
      </div>
<!--      <div class="item" v-show="isTabOrderTrade === 0">-->
<!--        <recent-trades/>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
  import RecentTrades from './RecentTrades.vue';
  import OrderForm from "./order-form/OrderForm.vue";

  export default {
    components: {
      OrderForm,
      RecentTrades,
    },
    props: {
      triggerOrderFormTab: { type: Number, default: 1 },
    },
    data() {
      return {
        isTabOrderTrade: 1,
      }
    },
    methods: {
      getEventHandlers() {
        return {
          SpotSelectedPairMarket: this.onSpotSelectedPairMarket,
          SellOrderBookClicked: this.onSellOrderBookClicked,
          BuyOrderBookClicked: this.onBuyOrderBookClicked,
        }
      },
      onSpotSelectedPairMarket(pair) {
        
      },
      onSellOrderBookClicked(price, maxAmount) {
        this.clickOrderTab();
        setTimeout(() => {
            this.$broadcast('FocusOrderForm', 'sell', price, maxAmount);
        }, 100);
      },
      onBuyOrderBookClicked(price, maxAmount) {
        this.clickOrderTab();
        setTimeout(() => {
            this.$broadcast('FocusOrderForm', 'buy', price, maxAmount);
        }, 100);
      },
      clickOrderTab() {
        this.isTabOrderTrade = 1;
      },
      clickTradeTab() {
        this.isTabOrderTrade = 0;
      },
    }
  }
</script>
 
</style>

<style lang="scss" scoped>
  @import "@/assets/sass/variables";
  @import "@/assets//sass/common.scss";
  #order_trade_form {
    .tab-container {
      border-bottom: 1px solid;
      border-color: $color-bright-gray-dark;
      width: 100%;
      height: 40px;
      padding: 8px 22px 0px 22px;
      background-color: $color-charade;
      .tab-element {
        cursor: pointer;
        display: inline-block;
        float: left;
        line-height: 20px;
        height: 32px;
        border: 0px;
        padding: 6px 15px;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        background-color: transparent;
        width: 50%;
        font-size: $font-root;
        color: $color-grey-sliver;
        font-family: $font-family-roboto-bold;
        text-align: center;
        &.active {
          background-color: #181f2b;
          color: $color-orange;
        }
      }
    }

    .tab_content_ordertrade{
      .item:hover{
        background-color: initial;
      }
    }
  }
</style>

