<template>
  <div id="sub-accounts" class="clearfix">
    <manage-modal/>
    <div class="head-sub-accounts clearfix">
      <button class="btn btn-manage" @click="showManageModal">Manage</button>
    </div>

    <div class="clearfix"></div>
    <data-table ref="datatable" :getData="getData" id="order-table" :limit="10">
      <th data-sort-field="id">ID</th>
      <th>Active</th>
      <th data-sort-field="balance">{{ $t('funds.balances.balance') }}</th>
      <th data-sort-field="avaliable_balance">{{ $t('funds.balances.avaliable_balance') }}</th>
      <th data-sort-field="btc_value">{{ $t('funds.balances.btc_value')}}</th>
      <template slot="body" slot-scope="props">
        <tr>
          <td :title="props.item.id">{{ props.item.id }}</td>
          <td>
            <span>Active</span>
            <span class="blue">Inactive</span>
          </td>
          <td :title="props.item.balance">{{ props.item.balance }}</td>
          <td :title="props.item.avaliable_balance">{{ props.item.avaliable_balance }}</td>
          <td :title="props.item.avaliable_balance">{{ props.item.btc_value }}</td>
        </tr>
      </template>
    </data-table>

  </div>
</template> 

<script>
  import rf from '@/request/RequestFactory'
  import ManageModal from '@/components/shared_components/common/ManageModal.vue';

  export default {
    components: {
      ManageModal,
    },
    data() {
    },
    props: {

    },
    watch: {
     
    },
    computed: {

    },
    methods: {
      getData (params) {
        return new Promise(resolve => {
          return resolve(
            [ 
              {id: '1', balance: '100.000', avaliable_balance: '100.000', btc_value: '100.000'},
              {id: '1', balance: '100.000', avaliable_balance: '100.000', btc_value: '100.000'},
              {id: '1', balance: '100.000', avaliable_balance: '100.000', btc_value: '100.000'},
            ]
          );
        });
      },
      showManageModal() {
        window.CommonModal.show('ManageModal');
      },
    },
    mounted() {
      
    },
  }
</script>

<style lang="scss" scoped="">
  @import "@/assets/sass/variables";

  #sub-accounts {

    .head-sub-accounts {
      padding: 12px 20px;
      border-bottom: solid 1px #3c4049;

      .btn-manage {
        width: 85px;
        height: 30px;
        border-radius: 3px;
        background-color: $color-green;
        border: 1px solid $color-green;
        font-size: $font-smaller;
        letter-spacing: 0.9px;
        color: $color-white;
        text-align: center; 
        font-family: $font-family-roboto-bold;
        text-transform: uppercase;
        &:hover, &:focus {
          background-color: $color-blue-custom;
          border-color: $color-blue-custom ;
        }
      }
    }
  }

  .blue {
    color: $color-green;
  }
  
</style>
