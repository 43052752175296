<template>
  <div id="create-api">
    <div class="create-api-content">
      <div class="setting_top_header">
        <AccountTopHeader :title='$t(!isEditing ? "account.api_create" : "account.api.edit_api")'/>
      </div>
      <div v-if="isEditing" id="edtingAPI">
        <otp-modal ref="OtpModal" :submit="verifyOtp" :is-disable-button="isDisableButton" :params="dataOtp"/>
        <!-- <div class="w-100 edit-api-title">{{ $t('account.api.edit_api')}}</div> -->
        <div class="d-flex justify-content-between align-items-center">
          <h2 class="api-name m-0">
            {{editingValue.name}}
          </h2>
          <div class="api-btn d-flex justify-content-between align-items-center">
            <button class="btn btn-save" @click="showOTPModal({action: 'editAPI'})">{{ $t('account.api.save') }}</button>
            <button class="btn btn-cancel" @click="onCancelEditing()">{{ $t('order.order_history.cancel') }}</button>
          </div>
        </div>
        <div class="api-container">
          <!-- <div class="img-detail-qr p-0">
            <img :src="editingValue.qr_code" class="w-100">
          </div> -->
          <div class="api-key-container">
            <label class="api-label">{{ $t('key_id') }}</label>
            <div class="api-value">{{editingValue.api_key}}
              <div class="btn-copy d-md-none d-block" @click="copyApiKey(editingValue.api_key)">
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                  <g clip-path="url(#clip0_19880_299171)">
                    <path d="M14.0861 14.6598H17.2141C17.5794 14.6595 17.9297 14.5141 18.1879 14.2557C18.4461 13.9973 18.5911 13.647 18.5911 13.2817V4.4718C18.5911 4.10651 18.4461 3.75608 18.1879 3.49768C17.9297 3.23928 17.5794 3.09402 17.2141 3.09375H7.91412C7.54866 3.09375 7.19815 3.23889 6.93973 3.49731C6.6813 3.75574 6.53613 4.10633 6.53613 4.4718V6.82971C6.53606 7.1846 6.67331 7.52578 6.91913 7.78174L13.0921 14.2307C13.2202 14.3657 13.3744 14.4733 13.5452 14.547C13.716 14.6207 13.9 14.6592 14.0861 14.6598Z" fill="#888888"/>
                    <path d="M14.9347 5.91797H3.95172C3.36354 5.91797 2.88672 6.39479 2.88672 6.98297V16.838C2.88672 17.4262 3.36354 17.903 3.95172 17.903H14.9347C15.5229 17.903 15.9997 17.4262 15.9997 16.838V6.98297C15.9997 6.39479 15.5229 5.91797 14.9347 5.91797Z" fill="#DEDEDE"/>
                    <path d="M9.44434 8.87891V14.9419" stroke="#888888" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12.4751 11.9102H6.41211" stroke="#888888" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_19880_299171">
                      <rect width="21" height="21" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
            <label class="api-label">{{ $t('account.api.secret_key') }}:</label>
            <div class="api-value" v-if="secret_key_new && editingValue.index === 0">{{ secret_key_new }}</div>
            <div class="api-value" v-else>{{editingValue.secret}}</div>
          </div>
          <div class="secret-key">
            <label class="api-label mobile-margin">{{ $t('account.api.restriction') }}</label>
            <div class="row secret-checkbox">
              <div class="d-grid grid-checkbox">
                <div class="checkbox-function" v-for="item in scopeSetting" :key="item.key">
                  <label :for="`checkbox_id${item.key}`" class="api-label" :class="{'active' : item.isChecked }">
                    <input class="checkbox_input" type="checkbox" name="checkbox" v-model="item.isChecked" :id="`checkbox_id${item.key}`">
                    <span class="button-check"></span>
                    {{ $t(item.name) }}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="ip-address">
          <label class="api-label mobile-margin">{{ $t('account.api.ip_address') }}:</label>
          <div class="item-input" v-for="item, index in arrayInputs" :key="index">
            <currency-input-otp
              name="ip_address"
              class="input_ip_address" 
              v-model="item.value"
              :maxlength="15"
              :placeholder="$t('account.api.ip_address_placeholder')"/>
            <div class="icon-delete" @click="removeInput(index)">
              <img src="@/assets/images/delete_input.svg"/> 
            </div>
          </div>
          <div class="button-add" @click="addInput" v-if="arrayInputs?.length < maxInputs">
            <img src="@/assets/images/add_input.svg"/>
          </div>
          <div class="ip-address-note">{{ $t("ip_address_note") }}</div>
        </div>

        </div>

      </div>
      <div v-else>
        <div id="box_enter_api_key" v-if="!isLoading && apiList.length > 0">
          <otp-modal ref="OtpModal" :submit="verifyOtp" :is-disable-button="isDisableButton" :params="dataOtp"/>
          <div class="group_enter_key">
            <input type="" name="" class="input_enter_key" v-model="newApiName"
                   :maxlength="20"
                   :placeholder="$t('account.api.input_placeholder')">
            <button class="btn btn_creat_new_key" :disabled="!newApiName || newApiName.trim() == ''"
                    @click="showOTPModal({action: 'create'})">{{ $t('account.api.create_btn') }}
            </button>
          </div>
          <div class="clearfix clearfix-35"></div>
          <div class="content_page_api clearfix">
            <div class="header clearfix">
              <h2 class="title_left">{{ $t('account.api.title') }}</h2>
              <!--          <button class="btn btn-right" @click="confirmDeleteAllApi()">{{$t('account.api.delete_all')}}</button>-->
            </div>

            <div class="clearfix"></div>
            <div class="list-detail-api">
              <div class="list_box_api clearfix" v-for="(api, index) in apiList" :key="api.id">
                <div class="box_api_detail clearfix">
                  <div class="header_detail_api clearfix">
                    <h2 class="name">{{ api.name }}</h2>
                    <div class="list_button">
                      <!--              <button class="btn btn_setting" @click="editApi(api, scopeEditing[api.id])" v-if="!enableEditing[api.id]">{{$t('account.api.edit')}}</button>-->
                      <!--              <button class="btn btn_setting" @click="closeApi(api)" v-if="enableEditing[api.id]">{{$t('account.api.cancel')}}</button>-->
                      <!--              <button class="btn btn_setting" @click="showOTPModal({action: 'save', api, scope: scopeEditing[api.id]})" v-if="enableEditing[api.id]">{{$t('account.api.save')}}</button>-->
                      <button class="btn btn_setting" @click="confirmDeleteApi(api)" v-if="!enableEditing[api.id]">
                        {{ $t('account.api.delete') }}
                      </button>

                      <button class="btn btn_setting btn-edit-api" @click="editAPI(api, index)" v-if="!enableEditing[api.id]">
                        <svg v-if="userThemeMode === 'light-theme'" xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                          <path d="M17.4234 12.9003V13.9182C17.4234 14.6664 17.1261 15.384 16.5971 15.913C16.068 16.4421 15.3505 16.7393 14.6023 16.7393H6.52125C5.77306 16.7393 5.05551 16.4421 4.52646 15.913C3.99741 15.384 3.7002 14.6664 3.7002 13.9182V5.96664C3.7002 5.21835 3.99738 4.5007 4.5264 3.97148C5.05542 3.44226 5.77296 3.14481 6.52125 3.14453H7.67388" stroke="#333333" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
                          <path d="M14.0581 4.10444C14.0508 4.09707 14.0476 4.08655 14.0402 4.07812L9.48235 8.63602L9.05182 10.875C9.03754 10.9488 9.04159 11.0251 9.06363 11.097C9.08566 11.1689 9.12499 11.2344 9.17819 11.2875C9.23138 11.3407 9.29681 11.3801 9.36874 11.4021C9.44067 11.4241 9.51691 11.4282 9.59077 11.4139L11.8276 10.9834L16.3887 6.42128C16.3742 6.41374 16.3605 6.40493 16.3476 6.39497L14.0581 4.10444Z" fill="#333333"/>
                          <path d="M17.7769 3.98037L16.4864 2.68984C16.3466 2.55074 16.1573 2.47266 15.9601 2.47266C15.7628 2.47266 15.5736 2.55074 15.4338 2.68984L14.6475 3.4751C14.6559 3.48247 14.6664 3.48563 14.6738 3.493L16.9643 5.78247C16.9739 5.79563 16.9827 5.80933 16.9906 5.82353L17.778 5.03616C17.9171 4.89631 17.9952 4.70709 17.9952 4.50984C17.9952 4.3126 17.9171 4.12337 17.778 3.98353" fill="#333333"/>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none" v-else>
                          <path d="M17.4224 12.9003V13.9182C17.4224 14.6664 17.1252 15.384 16.5961 15.913C16.0671 16.4421 15.3495 16.7393 14.6013 16.7393H6.52027C5.77208 16.7393 5.05454 16.4421 4.52549 15.913C3.99644 15.384 3.69922 14.6664 3.69922 13.9182V5.96664C3.69922 5.21835 3.9964 4.5007 4.52542 3.97148C5.05444 3.44226 5.77199 3.14481 6.52027 3.14453H7.6729" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
                          <path d="M14.0576 4.10444C14.0503 4.09707 14.0471 4.08655 14.0398 4.07812L9.48186 8.63602L9.05133 10.875C9.03705 10.9488 9.04111 11.0251 9.06314 11.097C9.08517 11.1689 9.12451 11.2344 9.1777 11.2875C9.23089 11.3407 9.29632 11.3801 9.36825 11.4021C9.44018 11.4241 9.51642 11.4282 9.59028 11.4139L11.8271 10.9834L16.3882 6.42128C16.3737 6.41374 16.36 6.40493 16.3471 6.39497L14.0576 4.10444Z" fill="white"/>
                          <path d="M17.7779 3.98037L16.4874 2.68984C16.3475 2.55074 16.1583 2.47266 15.9611 2.47266C15.7638 2.47266 15.5746 2.55074 15.4348 2.68984L14.6484 3.4751C14.6569 3.48247 14.6674 3.48563 14.6748 3.493L16.9653 5.78247C16.9749 5.79563 16.9836 5.80933 16.9916 5.82353L17.779 5.03616C17.9181 4.89631 17.9961 4.70709 17.9961 4.50984C17.9961 4.3126 17.9181 4.12337 17.779 3.98353" fill="white"/>
                        </svg>
                        {{ $t('account.api.edit') }}
                      </button>
                    </div>
                  </div>
                  <div class="clearfix header-api"></div>
                  <div class="body_detail_api ">
                    <!-- <div class="img_qr_code ">
                      <img class="img_qr" :src="api.qr_code" v-if="api.qr_code" alt="image"/>
                      <img class="img_qr fake" src="/images/qr_code.png" v-else alt="image"/>
                      <div class="label_apiKey">{{ $t('key_id') }}</div>
                    </div> -->
                    <div class="right_sub ">
                      <div class="address">
                        <div class="label_apiKey">{{ $t('key_id') }}</div>
                        <div class="group_sub clearifx">
                          <div class="clearfix"></div>
                          <div class="code flex-container">
                            <div class="flex-code">{{ api.api_key }}</div>
                            <span @click="copyApiKey(api.api_key)">
                    <img :src="require(`@/assets/images/icon/copy${
                      userThemeMode === 'light-theme' ? '' : '-dark'}.svg`)"/>
                    </span>
                          </div>
                        </div>
                      </div>
                      <div class="secret_key">
                        <!--                <div class="clearfix clearfix-35"></div>-->
                        <div class="group_sub clearifx">
                          <div class="label_apiKey">{{ $t('account.api.secret_key') }}:</div>
                          <div class="clearfix"></div>
                          <div class="code flex-code" v-if="secret_key_new && index === 0">{{ secret_key_new }}</div>
                          <div class="code flex-code" v-else>{{ api.secret }}</div>
                        </div>
                      </div>
                      <!--              <div class="clearfix clearfix-35"></div>-->
                      <!--              <div class="group_sub clearifx">-->
                      <!--                <label class="name">{{$t('account.api.option')}}:</label>-->
                      <!--                <div class="clearfix"></div>-->
                      <!--                <div class="list_option clearfix" v-if="!!scopeEditing[api.id]">-->
                      <!--                  <label class="container_checkbox_option" v-for="rule in scopeEditing[api.id]" v-if="rule"> {{$t('account.api.permissions.'+rule.key)}}-->
                      <!--                    <input type="checkbox" :checked="rule.enable" :disabled="!enableEditing[api.id] || rule.scope == 4" @click="updateScopeItem(api, rule)"/>-->
                      <!--                    <span :class="{'checkmark_option': true, 'disabled': !enableEditing[api.id] || rule.scope == 4}"></span>-->
                      <!--                  </label>-->
                      <!--                </div>-->
                      <!--              </div>-->
                      <!--              <div class="clearfix clearfix-35"></div>-->
                      <!--              <div class="group_sub clearifx">-->
                      <!--                <label class="name">{{$t('account.api.ip_access_res')}}</label>-->
                      <!--                <div class="clearfix"></div>-->
                      <!--                <div class="list_access_restriction clearfix">-->
                      <!--                  <label class="container_checkbox_restriction">{{$t('account.api.unrestricted')}}<span class="warning">{{$t('account.api.not_recommended')}}</span>-->
                      <!--                    <input type="radio" @click="ipRestricted(api.id, 0)" :disabled="!enableEditing[api.id]" value="0" v-model="is_restrict[api.id]" :name="'is_restrict_' + api.id + '_0'">-->
                      <!--                    <span :class="{'checkmark_restriction': true, 'disabled': !enableEditing[api.id], 'selected': !enableEditing[api.id] && !is_restrict[api.id]}"></span>-->
                      <!--                  </label>-->
                      <!--                  <label class="container_checkbox_restriction">{{$t('account.api.restrict_access')}}-->
                      <!--                    <input type="radio" @click="ipRestricted(api.id, 1)" :disabled="!enableEditing[api.id]" value="1" v-model="is_restrict[api.id]" :name="'is_restrict_' + api.id + '_1'">-->
                      <!--                    <span :class="{'checkmark_restriction': true, 'disabled': !enableEditing[api.id], 'selected': !enableEditing[api.id] && !!is_restrict[api.id]}"></span>-->
                      <!--                  </label>-->
                      <!--                </div>-->
                      <!--                <div class="box_add_api clearfix">-->
                      <!--                  {{$t('account.api.current_ip')}}-->
                      <!--                  <div class="clearfix"></div>-->
                      <!--                  <div class="group_input_api clearfix">-->
                      <!--                    <input v-model="ipInput[api.id]" v-show="enableEditing[api.id]" type="" name="ip" placeholder="Trusted IPs: 0.0.0.0">-->
                      <!--                    <button class="btn btn-confirm" v-show="enableEditing[api.id]" @click="confirmIps(api.id)">{{$t('account.api.confirm')}}</button>-->
                      <!--                  </div>-->
                      <!--                  <div style="display: flex; overflow: scroll; flex-wrap: wrap;">-->
                      <!--                    <div v-for="ipItem in ipRestrictionSettings[api.id]" class="ip-item">{{ipItem}}-->
                      <!--                      <div class="icon" @click.prevent="removeIps(api.id, ipItem)" v-show="enableEditing[api.id]">-->
                      <!--                        <span class="icon-close"></span>-->
                      <!--                      </div>-->
                      <!--                    </div>-->
                      <!--                  </div>-->
                      <!--                </div>-->
                      <!--              </div>-->
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div id="box_enter_api_key" class="clearfix" :class="{ box_api : apiList.length == 0 }"
             v-if="!isLoading && apiList.length == 0">
          <otp-modal ref="OtpModal" :submit="verifyOtp" :is-disable-button="isDisableButton" :params="dataOtp"/>
          <div class="content clearfix">
            <i class="icon-api_2 icon_api_key"></i>
            <div class="clearfix clearfix-25"></div>
            <h2 class="title_page">{{ $t('account.api.title_page_2') }}</h2>
            <div class="group_enter_key">
              <input type="" name="" class="input_enter_key" v-model="newApiName"
                     :maxlength="20"
                     :placeholder="$t('account.api.input_placeholder')">
              <button class="btn btn_creat_new_key" :disabled="!newApiName || newApiName.trim() == ''"
                      @click="showOTPModal({action: 'create'})">{{ $t('account.api.create_btn') }}
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import rf from '@/request/RequestFactory'
import ConfirmationModal from '@/components/shared_components/common/ConfirmationModal';
import Utils from '@/common/Utils';
import OtpModal from '@/components/shared_components/funds/common/OtpModal';
import COMMON_CONST from '@/common/Const';
import AccountSiteBar from '@/components/shared_components/common/AccountSiteBar.vue';
import AccountTopHeader from "@/components/shared_components/common/AccountTopHeader";
import CurrencyInputOtp from "@/components/shared_components/common/CurrencyInputOtp.vue";
import {mapState} from "vuex";

const TOKEN_API_TYPE = 'api_token';
const ruleList = [{
  key: 'read',
  scope: 4,
  enable: true
}, {
  key: 'withdrawal',
  scope: 1,
  enable: false
}, {
  key: 'trading',
  scope: 2,
  enable: false
}]

export default {
  components: {
    ConfirmationModal,
    OtpModal,
    AccountSiteBar,
    AccountTopHeader,
    CurrencyInputOtp
  },

  data() {
    return {
      maxlength: COMMON_CONST.MAX_LENGTH_INPUT,
      maxlengthApi: 20,
      newApiName: '',
      otpCode: null,
      urlQR: {},
      // urlQRLock : '@/assets/images/qr_lock.png',
      key2fa: '',
      enableEditing: {},
      scopeEditing: {},
      apiList: [],
      scopeSetting: [],
      dataOtp: {},
      user: null,
      isDisableButton: false,
      ipRestrictionSettings: {},
      // ip_restricted: {},
      ipInput: {},
      is_restrict: {},
      isLoading: true,
      api: {},
      isEditing: false,
      editingValue: undefined,
      arrayInputs: [],
      maxInputs: 10,
      secret_key_new: ''
    };
  },
  computed: {
    ...mapState({
      userThemeMode: (state) => state.userThemeMode,
    }),
  },
  watch: {
    apiList(value) {
      this.apiList.map(el => {
        const data = this.generateScope(el);
        this.generateIPlist(el);
        this.is_restrict = {
          ...this.is_restrict,
          [el.id]: el.is_restrict
        }

        this.scopeEditing = {
          ...this.scopeEditing,
          [el.id]: data
        }
      });
    },
    ipRestrictionSettings(value) {
    },
    ipInput(value) {

    }

  },

  mounted() {
    this.getUserInformation();
    this.getApiSettings();
    this.getScopeSetting();
  },

  methods: {
    addInput() {
      if(this.arrayInputs.length >= this.maxInputs){
        return
      }
      if (this.arrayInputs.length < this.maxInputs) {
        this.arrayInputs.push({ value: '' });
      }
    },

    removeInput(index) {
      this.arrayInputs.splice(index, 1);
    },

    editAPI(api, index){
      this.isEditing = true;
      this.editingValue = {...api, index: index};
      this.arrayInputs = api?.white_list.length > 0 ? api?.white_list.map(value => ({ value })) : []
      this.scopeSetting.map(item => {
        item.isChecked = api.scopes[0].includes(item.value)
        return item;
      })
    },

    onUpdateScopesAPI(otp) {
      const ip_addresses = this.arrayInputs.map(item => item.value).filter(value => value);
      const params = {
        scopes: this.scopeSetting.filter(item => item.isChecked).map(item => item.value).toString().replaceAll(',',''),
        ip_restricted: this.editingValue.ip_restricted,
        name: this.editingValue.name,
        is_restrict: this.editingValue.is_restrict,
        otp: otp.otp,
        ip_addresses
      }

      rf.getRequest('UserRequest').updateScopesAPI(params, this.editingValue.api_key)
        .then(res => {
          if (res.success) {
            this.secret_key_new = ''
            Message.success(window.i18n.t('account.api.update_success'));
          }
          this.getApiSettings();
          this.onCancelEditing();
        })
        .catch(err => {
          if(err?.response?.data?.message?.message === "Invalid IPv4 address"){
            Message.error(window.i18n.t('account.api.invalid_ip'));
            return
          }
          Message.error(err);
        });
    },

    onCancelEditing() {
      this.isEditing = false;
      this.editingValue = undefined;
    },

    callbackOtp(data) {
      this.otpCode = data.otp

      $('.loading-effect').hide();
      this.isDisableButton = false;
      window.CommonModal.hide('OtpModal');

      if (this.dataOtp.action == 'create')
        this.createNewApi(data)
      if (this.dataOtp.action == 'save')
        this.saveApi(this.dataOtp.api, this.dataOtp.scope)
      if (this.dataOtp.action == 'delete')
        this.deleteApi(this.dataOtp.api)
      if (this.dataOtp.action == 'deleteAll')
        this.deleteApi()
      if (this.dataOtp.action == 'editAPI')
        this.onUpdateScopesAPI(data)
    },

    ipRestricted(api, condition) {
      // this.ipRestrictionSettings[api] = condition;
      this.is_restrict[api] = condition
    },

    confirmIps(api) {
      const ip = this.ipInput[api];

      const validateIp = /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/.test(ip)

      if (validateIp) {
        if (!this.ipRestrictionSettings[api]) this.ipRestrictionSettings[api] = []
        this.ipRestrictionSettings = {
          ...this.ipRestrictionSettings,
          [api]: window._.union(this.ipRestrictionSettings[api], [ip])
        }
      }
      return
    },

    removeIps(api, item) {
      this.ipRestrictionSettings = {
        ...this.ipRestrictionSettings,
        [api]: window._.filter(this.ipRestrictionSettings[api], (el) => el !== item)
      }
    },

    showOTPModal(data, bypass = false) {
      if (this.user.security_setting.otp_verified === 0) {
        Message.error(window.i18n.t('account.otp.should_enable_first'));
        return
      }
      this.dataOtp = {
        ...data,
      }


      if (bypass) {
        this.callbackOtp(this.dataOtp)
        return
      }
      window.CommonModal.show('OtpModal');
      // this.verifyOtp(this.dataOtp);
    },

    verifyOtp(params) {
      $('.loading-effect').css('display', 'flex');
      this.isDisableButton = true;
      rf.getRequest('UserRequest').otpVerify({otp: params.otp}).then(res => {
        this.callbackOtp(params);
      }).catch(error => {
        $('.loading-effect').hide();
        this.isDisableButton = false;
        if (!error.response) {
          this.showError(window.i18n.t('common.message.network_error'));
          return;
        }

        const errors = error.response.data.errors
        if (params.otp?.length !== 6) {
          this.showGoogleAuthenticationModal();
        }
        window._.each(errors, (value, key) => {
          this.errors.add(key, this.$t(`${value[0].formatUnicorn({values: ".jpg, .jpeg, .png"})}`));
        });
      });
    },

    showGoogleAuthenticationModal() {
      window.CommonModal.show('OtpModal');
    },

    getUserInformation(useCache = true) {
      rf.getRequest('UserRequest').getCurrentUser(useCache).then(res => {
        this.user = Object.assign({}, this.user, res.data);
      });
    },

    checkRestrictPage() {
      if (!this.user) {
        this.getUserInformation();
        return
      }
      // if(this.user.security_level < 3) {
      //   const path = '/page-not-found'; //TODO: update getting the layoutpath after merged margin branch
      //   this.$router.push({ path });
      // }
      return
    },

    getScopeSetting() {
      rf.getRequest('UserRequest').getScopesAPISetting()
        .then(res => {
          const outputArray = Object.entries(res.data).map(([key, value]) => {
            return {value: parseInt(key), key: value, name: this.mapNameScopes(value), isChecked: value === 'READ'};
          });
          this.scopeSetting = outputArray;
        })
    },

    mapNameScopes(name) {
      let nameScope = 'Enable Reading';
      switch (name) {
        case 'READ':
          nameScope = 'account.api.enable_reading';
          break;
        case 'TRADE':
          nameScope = 'account.api.enable_spot_trading';
          break;
        case 'FUTURE_TRADING':
          nameScope = 'account.api.enable_future_trading';
          break;
        case 'WITHDRAW':
          nameScope = 'account.api.enable_withdrawals';
          break;
      }

      return nameScope
    },

    getApiSettings() {
      this.checkRestrictPage();
      this.isLoading = true
      rf.getRequest('UserRequest').getUserApiSettings()
        .then(res => {
          this.apiList = res.data;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    generateScope({id, scopes}) {
      const clone = window._.cloneDeep(ruleList);

      if (!scopes || typeof scopes !== 'object' || typeof scopes === 'object' && scopes.length <= 0) {
        return clone
      }

      if (JSON.stringify(scopes) == JSON.stringify(["*"])) {
        clone.map(el => {
          el.enable = true
        })
        return clone

      } else {

        scopes.map(scope => {

          let def = {
            7: [4, 2, 1],
            6: [4, 2],
            5: [4, 1],
            4: [4],
            3: [2, 1],
            2: [2],
            1: [1],
          }

          clone.map(el => {
            const enable = def[scope] && def[scope].includes(el.scope)
            el.enable = el.enable ? el.enable : enable
          })

        })

        return clone
      }
    },

    generateIPlist(data) {
      const {ip_restricted} = data;
      if (ip_restricted) {
        const text = ip_restricted.split(",");
        data.ip_restricted = text;
        this.ipRestrictionSettings[data.id] = text;
        return data
      }
    },

    showModal(config) {
      const classC = config.class;
      const type = config.type;
      const title = config.title;
      const content = config.content;
      const customContent = config.customContent;
      let btnCancelLabel = config.btnCancelLabel || this.$t('common.action.no');
      let btnConfirmLabel = config.btnConfirmLabel || this.$t('common.action.yes');
      if (config.noAction) {
        btnCancelLabel = null;
        btnConfirmLabel = null;
      }
      const onConfirm = config.onConfirm;
      const onCancel = config.onCancel;
      window.ConfirmationModal.show({
        class: classC,
        type: type,
        title: title,
        content: content,
        customContent: customContent,
        btnCancelLabel: btnCancelLabel,
        btnConfirmLabel: btnConfirmLabel,
        onConfirm: onConfirm,
        onCancel: onCancel
      });
    },

    confirmDeleteApi(api) {
      if (this.user.security_setting.otp_verified == 0) {
        Message.error(window.i18n.t('account.otp.should_enable_first'));
        return
      }
      const title = window.i18n.t('account.api.delete_api');
      const message = api && api.name ? window.i18n.t('account.api.delete_api_message_withname', {name: api.name}) : window.i18n.t('account.api.delete_api_message_noname');
      this.showModal({
        type: 'delete_api',
        title: title,
        class: 'custom_modal_del_api',
        content: message,
        btnCancelLabel: window.i18n.t('common.action.no'),
        btnConfirmLabel: window.i18n.t('common.action.yes'),
        widthModal: 560,
        onConfirm: () => {
          this.showOTPModal({action: 'delete', api})
        },
        onCancel: () => {

        }
      });
    },

    confirmDeleteAllApi() {
      const title = window.i18n.t('Delete All');
      const message = window.i18n.t('Are you sure want to delete all API Key?');
      this.showModal({
        type: 'delete_all_api',
        title: title,
        class: 'custom_modal_del_api',
        content: message,
        btnCancelLabel: window.i18n.t('common.action.no'),
        btnConfirmLabel: window.i18n.t('common.action.yes'),
        onConfirm: () => {
          this.showOTPModal({action: 'deleteAll'})
        },
        onCancel: () => {

        }
      });
    },

    updateScopeItem(api, scopes) {
      const item = this.scopeEditing[api.id].find(el => el.key == scopes.key)
      if (item)
        this.scopeEditing[api.id].find(el => el.key == scopes.key).enable = !scopes.enable
    },

    createNewApi(data) {
      const name = this.newApiName ? this.newApiName.trim() : '';
      if (!name) return
      rf.getRequest('UserRequest').createUserApiSettings({name, scopes: 4, ...data}).then(res => {

        if (res.success) {

          this.urlQR = {
            [res.data.token.id]: res.data.qrCodeUrl
          }

          this.secret_key_new = res.data.token.secret
          // this.apiList.map(el => {
          //   el.secret = '***********************'
          // })
          this.apiList.unshift(res.data.token);

          this.newApiName = '';

          Message.success(window.i18n.t('account.api.create_success'));

          this.getApiSettings()
        }

      }).catch(err => {
        if(err?.response?.data?.message === 'Name already exist'){
          Message.error(window.i18n.t('account.api.api_already_exist'));
          return
        }
        Message.error(window.i18n.t('account.api.create_fail'));
      });
    },

    editApi(api, previousRules) {
      const id = api.id
      this.scopeEditing = {
        ...this.scopeEditing,
        [id]: previousRules
      }

      this.enableEditing = {
        ...this.enableEditing,
        [id]: this.enableEditing[id] !== undefined ? !this.enableEditing[id] : true
      }
    },

    closeApi(api) {
      this.enableEditing = {
        ...this.enableEditing,
        [api.id]: false
      }
    },

    saveApi(api, previousRules) {
      const getScopeForCurrentRules = (inputScopeArray) => {
        if (inputScopeArray.every(el => el.enable)) return 7
          return inputScopeArray.filter(el => el.enable).map(el => el.scope).reduce((a, b) => a + b, 0)
      }
      const params = {
        ...api,
        otp: this.otpCode,
        scopes: getScopeForCurrentRules(previousRules),
        ip_restricted: this.ipRestrictionSettings[api.id] ? this.ipRestrictionSettings[api.id].join(",") : "",
        is_restrict: this.is_restrict[api.id]
      }

      rf.getRequest('UserRequest').updateUserApiSettings(params).then(res => {
        if (res.data) {
          this.secret_key_new = ''
          this.closeApi(api);
          Message.success(window.i18n.t('account.api.save_success'), {}, {position: 'bottom_right'});
        }
      }).catch(err => {
        Message.error(window.i18n.t('account.api.save_fail'), {}, {position: 'bottom_right'});
      })
    },

    deleteApi(api) {
      if (api) {
        const id = api.api_key;
        rf.getRequest('UserRequest').deleteOneApiSettings({id, otp: this.otpCode}).then(res => {
          if (api && res.success) {
            this.secret_key_new = ''
            Message.success(window.i18n.t('account.api.delete_success'));
            this.getApiSettings();
          }
        }).catch(err => {
          Message.error(window.i18n.t('account.api.delete_fail'));
        });
      } else {
        rf.getRequest('UserRequest').deleteAllApiSettings({otp: this.otpCode}).then(res => {
          if (res.success) {
            this.getApiSettings();
            Message.success(window.i18n.t('account.api.delete_success'));
          }
        }).catch(err => {
          Message.error(window.i18n.t('account.api.delete_fail'));
        });
      }
    },
    copyApiKey(text) {
      if (!navigator.clipboard) {
        Utils.fallbackCopyTextToClipboard(text);
        Message.success(window.i18n.t('funds.deposit.copy_success'));
        return;
      }
      navigator.clipboard.writeText(text);
      Message.success(window.i18n.t('funds.deposit.copy_success'));
    },
    checkAvailable(api) {
      return api && api.id ? this.enableEditing[api.id] : false
    }
  },

};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables";

#edtingAPI {
  color: var(--dark-1);
  padding: 36px 260px 256px 43px;

  .edit-api-title {
    font-family: $font-inter-regular;
    color: var(--text-primary-color);
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    margin-bottom: 30px;
  }

  .api-name {
    font-family: $font-inter-regular;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
  }
  .api-container {
    margin-top: 40px;
    // display: grid;
    // grid-template-areas:
    //         "imgArea apiKeyContainer"
    //         "imgArea secretKey"
    //         "imgArea ipAddress";
    // grid-template-columns: 0px auto;

    .invalid-input {
     border: 1px solid red !important;
    }

    .input_ip_address {
      width: 428px;
      height: 49px;
      background-color: var(--background-color-second);
      border-radius: 8px;
      padding: 15px;
      border: 1px solid var(--background-color-second);
      //outline: none;
      color: var(--color-text-content);
      font-size: 18px;
      line-height: 21px;
      margin-right: 30px;
      &:active, &:focus {
        border: 1px solid $color-caribbean-green !important;

      }

      &::placeholder {
        color: var(--text-close);
      }

      @media (max-width: 576px) {
        width: 100%;
      }
    }

    .item-input {
      position: relative;
      width: fit-content;
      margin-bottom: 10px;
      width: 428px;
      @media (max-width: 576px) {
        width: 100%;
      }
    }

    .ip-address-note {
      color: $text-color-red;
      margin-top: 5px;
    }

    .icon-delete {
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
    }

    .button-add {
      padding: 15px;
      border: 1px dashed var(--color-border-input);
      border-radius: 8px;
      width: 428px;
      text-align: center;
      cursor: pointer;
      @media (max-width: 576px) {
        width: 100%;
        padding: 8 px;
      }
    }

    .img-detail-qr {
      width: 246px;
      height: 246px;
      grid-area: imgArea;
      margin-right: 60px;
    }
    .api-label {
      font-family: $font-inter-regular;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: var(--text-desc);
      margin-top: 32px;
      display: flex;
      align-items: center;
      &:first-child {
        margin-top: 0;
      }
    }
    .active {
      color: var(--text-primary-color);
    }
    .api-value {
      color: var(--text-dropdown);
      font-family: $font-inter-regular;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 21px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 20px;
      position: relative;
      .btn-copy {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
      }
    }

    .api-key-container {
      grid-area: apiKeyContainer;
    }
    .ip-address {
      margin-top: 32px;
    }
    .secret-key {
      grid-area: secretKey;
      margin-top: 32px;
      .secret-checkbox {
        width: 50%;
      }
      .api-label {
        margin-top: 16px;
        margin-bottom: 0;
      }
    }
  }

  .api-btn {
    .btn-save {
      border-radius: 10px;
      width: 140px;
      height: 50px;
      background: $color-aqua-green;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      font-family: $font-inter-regular;
      color: #333;
    }

    .btn-cancel {
      border-radius: 10px;
      width: 140px;
      height: 50px;
      background: var(--text-search);
      margin-left: 16px;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      font-family: $font-inter-regular;
      color: var(--text-primary-color);
    }
  }
}

@media screen and (max-width: 992px) {
  #edtingAPI {
    padding: 30px 16px 60px 16px;

    .mobile-margin {
      margin-top: 0!important;
    }
    .api-name {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
    }

    .edit-api-title {
      font-size: 20px;
      font-weight: 600;
      line-height: 27px;
    }
    .api-container {
      .api-value {
        font-size: 14px;
      }
      .secret-key .secret-checkbox {
        .api-label, .api-name {
          font-size: 14px;
        }
      }
    }

    .api-btn {
      .btn {
        font-size: 16px;
      }
      .btn-edit {

      }
      .btn-save {
        width: 88px;
        height: 36px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 19px;
      }

      .btn-cancel {
        width: 88px;
        height: 36px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 19px;
        margin-left: 8px;
      }
    }
    .api-container {
      grid-template-areas:
            "imgArea apiKeyContainer"
            "secretKey secretKey";
      grid-template-columns: 106px calc(100% - 106px);
      margin-top: 30px;

      .img-detail-qr {
        width: 78px;
        height: 78px;
        margin-right: 28px;
      }
      .api-key-container {

      }
      .secret-key {
        margin-top: 16px;
        .secret-checkbox {
          width: 100%;
          .grid-checkbox {
            grid-template-columns: 100%;
          }
        }


      }
      .api-label {
        margin-top: 18.5px;
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}

.grid-checkbox {
  grid-template-columns: 50% 50%;

}
.checkbox-function {
  right: 35px;
  font-size: 16px;
  color: $color-grey;
  cursor: pointer;
  label {
    cursor: pointer;
    color: var(--dark-1);
    font-family: $font-inter-regular;
    .button-check {
      cursor: pointer;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      outline: 0;
      background: #DEDEDE;
      border-radius: 2px;
      height: 20px;
      width: 20px;
      display: block;
      float: left;
      position: relative;
      margin-right: 5px;
      &:after {
        content: '';
        position: absolute;
        left: 6px;
        top: 1px;
        width: 6px;
        height: 13px;
        border: solid $color-grey-dark;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        display: none;
      }
    }
    .checkbox_input {
      display: none;
      &[type="checkbox"]:checked + .button-check {
        background-color: $color-caribbean-green;
      }
      &[type="checkbox"]:checked + .button-check:after {
        display: block;
      }
    }
  }

}
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.clearfix-17 {
  clear: both;
  display: block;
  height: 17px;
  width: 100%;
}

.clearfix-35 {
  clear: both;
  display: block;
  height: 35px;
  width: 100%;
}

.dark-theme {
  #create-api .create-api-content #box_enter_api_key .group_enter_key button[disabled] {
    background-color: $bgr-button-disable;
    color: $color-gray-charade;
  }
}

#box_enter_api_new_key {
  width: 520px;
  max-width: 100%;
  display: block;
  margin: 35px auto;
  padding: 40px 80px;
  text-align: center;
  background-color: $color-white;

  .clearfix::after {
    content: "";
    clear: both;
    display: table;
  }

  .clearfix-25 {
    display: block;
    width: 100%;
    height: 25px;
  }

  .clearfix-18 {
    display: block;
    width: 100%;
    height: 18px;
  }

  .icon_api_key {
    font-size: 70px;
    color: $color-eden;
  }

  .title {
    line-height: 19px;
    font-weight: 500;
    margin: 0px;
    font-size: $font-medium-bigger;
    color: $color-black;
  }

  .input_api_key {
    border: 0px;
    height: 30px;
    border-radius: 0px;
    display: block;
    width: 100%;
    border-bottom: 1px solid $color-grey;
    font-size: $font-small;
    color: $color-grey-dark;

    &:focus {
      border-color: $color-blue-custom;
    }

    &::-webkit-input-placeholder {
      color: $color-grey;
    }

    &:-ms-input-placeholder {
      color: $color-grey;
    }

    &::placeholder {
      color: $color-grey;
    }

  }

  .btn-create-new-key {
    display: block;
    width: 100%;
    margin-bottom: 13px;
    border-radius: 3px;
    letter-spacing: 0.92px;
    text-align: center;
    line-height: 20px;
    height: 40px;
    padding: 10px;
    text-transform: uppercase;
    background-color: $color-jungle-green;
    font-family: $font-family-roboto-bold;
    font-size: $font-smaller;
    color: $color-white;

    &:hover {
      background-color: $color-blue-custom;
    }
  }


}

#create-api {
  background-color: var(--background-color-primary-2);
  max-height: 100%;
  height: 100%;

  .account_navbar {
    margin-top: 4px;
  }

  .create-api-content {
    .title_page {
      font-size: $font-title-size-medium-small;
      font-family: $font-inter-bold;
      line-height: 36px;
      color: var(--text-primary-color);
      margin-bottom: 28px;
    }

    #box_enter_api_key {
      padding: 36px 43px;
      //width: 1150px;
      max-width: 100%;

      &.box_api {
        width: 100%;
      }

      .content {
        height: 100%;
        width: 100%;
        display: flex;
        //justify-content: center;
        flex-direction: column;
        align-items: center;
      }
      .content_page_api {
        width: 100%;
        max-width: 1280px;
      }

      .group_enter_key {
        display: flex;
        //justify-content: center;
        .input_enter_key {
          width: 360px;
          height: 49px;
          background-color: var(--background-color-second);
          border-radius: 8px;
          padding: 14px 15px;
          border: 1px solid var(--background-color-second);
          //outline: none;
          color: var(--color-text-content);
          font-size: $font-big;
          line-height: 21px;
          margin-right: 30px;
          &:hover, & :active, &:focus {
            border: 1px solid $color-caribbean-green !important;

          }

          &::placeholder {
            color: var(--text-close);
          }
        }

        .btn_creat_new_key {
          padding: 14.5px 20px;
          width: 180px;
          max-width: 100%;
          background-color: $color-caribbean-green;
          border-radius: 8px;
          font-size: 18px;
          font-family: $font-inter-regular;
          line-height: 21px;
          color: $color-grey-dark;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            background-color: $color-aquamarine;
          }
        }

        button[disabled] {
          background-color: $color-aero-blue;
          color: $color-gray-dark;
          opacity: 1;
        }
      }

      .title_left {
        font-family: $font-inter-bold;
        font-size: $font-title-size-medium-2;
        line-height: 36px;
        color: var(--text-primary-color)
      }

      .box_api_detail {
        padding-right: 20px;
        margin-bottom: 24px;
      }

      .header_detail_api {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 13px;

        .name {
          white-space: nowrap;
          font-family: $font-inter-regular;
          color: var(--text-dropdown);
          font-size: $font-big;
          line-height: 21px;
          margin: 0 !important;
        }

        .list_button {
          width: 100%;
          .btn-edit-api {
            margin-right: 10px;
            background: var(--color-edit-api);
            color: var(--text-primary-color);
          }
        }

        .btn_setting {
          padding: 14px 20px;
          //width: 140px;
          max-width: 100%;
          border-radius: 8px;
          background-color: $text-color-red;
          color: $pure_color_white;
          font-family: $font-inter-regular;
          font-size: $font-big;
          line-height: 21px;
          width: 140px;
          height: 49px;
          float: right;
        }

      }

      .header-api {
        content: '';
        width: 100%;
        border: 1px solid var(--background-color-second);
        margin-bottom: 30px;
        margin-top: 10px;
      }

      .body_detail_api {
        display: flex;
        gap: 60px;

        .right_sub {
          display: flex;
          gap: 60px;

          & div {
            width: 300px;
            max-width: 100%;

            .flex-container {
              display: flex;

            }

            .flex-code {
              white-space: nowrap;
              width: 100%;
              // overflow: hidden;
              // text-overflow: ellipsis;
              font-family: $font-inter-regular;
              font-size: $font-big;
              line-height: 21px;
              color: var(--text-dropdown);
            }
          }

          //width: 400px;
        }

        .img_qr_code {
          display: flex;
          align-items: center;
          flex-direction: column;

          & .img_qr {
            height: 126px;
            width: 126px;
            margin-bottom: 15px;
          }
        }

        .label_apiKey {
          margin-bottom: 5px;
          font-family: $font-inter-regular;
          font-size: $font-medium-bigger;
          line-height: 20px;
          color: var(--text-desc);
        }
      }

      .list-detail-api {
        height: 500px;
        max-height: 100%;
        overflow-x: auto;
      }
    }
  }

  .modal-content {
    width: 560px;
  }
}

#message {
  :deep(#message_content) {
    align-items: center !important;
  }
}

@media screen and (max-width: 1440px) {
  #create-api {
    .create-api-content {

      #box_enter_api_key {
        margin: 0 auto;
        padding: 40px 16px 60px !important;
        width: 85%;

        .body_detail_api {
          .right_sub {
            //flex-direction: column;
            gap: 28px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  #create-api {
    .create-api-content {
      .title_page {
        font-size: $font-big-20;
      }

      #box_enter_api_key {
        height: 100%;
        margin: 0 auto;
        padding: 40px 16px 60px !important;
        width: 100%;

        .content {
        }

        .group_enter_key {
          gap: 16px;
          width: 80%;

          .input_enter_key {
            width: 100%;
            font-size: $font-root;
          }

          .btn_creat_new_key {
            justify-content: center;
            font-size: $font-medium-bigger;
          }
        }

        .title_left {
          font-size: $font-big-20;
        }

        .box_api_detail {
          margin-bottom: 20px;
        }

        .header_detail_api {
          .name {
            font-size: $font-root;
          }

          .btn_setting {
            width: 97px;
            height: 36px;
            padding: 0;
          }
        }

        .body_detail_api {
          gap: 10px;

          .right_sub {
            //flex-direction: column;
            gap: 16px;

            & div {
              width: 237px;

              .flex-container {
              }

              .flex-code {
                font-size: $font-root;
                line-height: 16px;
              }
            }
          }

          .img_qr_code {
            & .img_qr {
              height: 78px;
              width: 78px;
            }
          }

          .label_apiKey {
            font-size: $font-smaller;
          }
        }

        .list-detail-api {
          height: auto;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  #create-api {
    .create-api-content {
      #box_enter_api_key {
        .group_enter_key {
          width: 100%;
          flex-direction: column;

          .btn_creat_new_key {
            width: 100%;
          }
        }

        .body_detail_api {
          .right_sub {
            & div {
              width: 100%;
            }

            flex-direction: column;
            width: 74%;
          }
        }

        .box_api_detail {
          padding-right: 0px !important;
        }
      }
    }

    :deep(.modal-dialog) {
      max-width: 92% !important;
    }

    #message_content::v-deep {
      width: 91.5%;
      min-width: 343px;
    }
  }
}


</style>