<template>
  <div id="tab_profit_loss">

    <div class="header_tab_content clearfix">
      <div class="clearfix">
        <div class="fl-left txt-Side">{{ $t('margin.side') }}:</div>
        <label class="switch box_switch" v-bind:class="{active: isShort}" @click="onClick">
          <div class="txt-long">{{ $t('margin.long') }}</div>
          <span>
            <i></i>
          </span>
          <div class="txt-short">{{ $t('margin.short') }}</div>
        </label>
      </div>

      <div class="clearfix"></div>

      <div class="clearfix">
        <div class="fl-left txt-Side">{{ $t('margin.margin') }}:</div>
        <label class="switch box_switch" v-bind:class="{active: isCross}" @click="onClickMargin">
          <div class="txt-long">{{ $t('margin.isolated') }}</div>
          <span>
            <i></i>
          </span>
          <div class="txt-short">{{ $t('margin.cross') }}</div>
        </label>
      </div>
    </div>

    <div class="body_tab_content clearfix">
      <div class="col-6">
        <div class="form-group form-inline">
          <label for="entryPrice">{{ $t('margin.quantity') }}</label>
          <currency-input :maxLengthCus='16' :key='index' :ref="refCurrencyInput" restrictStepPrice="true" :precision="0" class="form-control valid" v-model="quantity" @input="handleChange"/>
        </div>
        <div class="clearfix"></div>
        <div class="form-group form-inline">
          <label for="entryPrice">{{ $t('margin.entry_price') }}</label>
          <currency-input :maxLengthCus='16' :key='index' :ref="refCurrencyInput" restrictStepPrice="true" :precision="decimal" class="form-control valid" v-model="entryPrice" @input="handleChange"/>
        </div>
        <div class="clearfix"></div>
        <div class="form-group form-inline">
          <label for="entryPrice">{{ $t('margin.exit_price') }}</label>
          <currency-input :maxLengthCus='16' :key='index' :ref="refCurrencyInput" restrictStepPrice="true" :precision="decimal" class="form-control valid" v-model="exitPrice" @input="handleChange"/>
        </div>
        <div class="clearfix"></div>
        <div class="form-group form-inline">
          <label for="entryPrice">{{ $t('margin.leverage') }}</label>
          <currency-input :maxLengthCus='4' :disabled='isCross' :key='index' :ref="refCurrencyInput" :precision="2" class="form-control valid" v-model="leverage" @input="handleChange"/>
        </div>
        <div class="clearfix"></div>
      </div>
      <div class=" col-6">
        <div class="table-container table-calculatorOutput">       
          <data-table ref="datatable" :getData="getData">
            <template slot="body" slot-scope="props">
              <tr>
                <td class="text-left">{{ props.item.name }}</td>
                <td v-if="!props.item.name.includes('%')" class="text-right font-bold">
                  <div class="box_value_right clearfix">
                    <div v-if="props.item.value == margin">
                      <div class="value_right">{{ props.item.value | formatMarginCost(margin == 0 ? '0' : '--') }}</div>
                      <div class="full_value_right">{{ props.item.value | formatMarginCost(margin == 0 ? '0' : '--') }}</div>
                    </div>
                    <div v-else>
                      <div class="value_right">{{ props.item.value | formatMarginNumber }}</div>
                      <div class="full_value_right">{{ props.item.value | formatMarginNumber }}</div>
                    </div>
                  </div>
                </td>
                <td v-else class="text-right font-bold">
                  <div class="box_value_right clearfix">
                    <div class="value_right">{{ props.item.value | formatMarginNumber(2) }}%</div>
                    <div class="full_value_right">{{ props.item.value | formatMarginNumber(2) }}%</div>
                  </div>
                </td>

              </tr>
            </template>
          </data-table>
        </div>
        <div class="profit-loss-notice">
          <span>{{ $t('margin.profit_loss.notice') }}</span>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
  import rf from '@/request/RequestFactory'
  import Utils from '@/common/Utils'
  import BigNumber from 'bignumber.js';
  import MarginCalculator from './MarginCalculator.js';
  import { mapGetters, mapActions } from 'vuex';
  import CurrencyInput from "./CurrencyInput.vue";

  export default {
    components: {
      CurrencyInput
    },
    data() {
      return {
        isShort: false,
        isCross: false,

        quantity: 0,
        entryPrice: 0,
        exitPrice: 0,
        leverage: 0,
        multiplier: 0,

        margin: 0,
        entryValue: 0,
        exitValue: 0,
        pnl: 0,
        pnlPercent: 0,
        roe: 0,
        
        markPrice: 11500,
        currentOrderValue: 0,
        res: null,
        index: 0,
        refCurrencyInput: this.getRefUniqueId(),
      }
    },
    watch: {
      instrument(value) {
        if(this.currentInstrument && this.currentInstrument.multiplier) {
          this.multiplier = this.currentInstrument.multiplier;
        }
      },
      isShort() {
        this.handleChange();
      },
      isCross(value) {
        if (value) {
          this.leverage = 1 / this.position.required_init_margin_percent;
        } else {
          this.leverage= 1;
        }
      },
      currentSymbol() {
        this.$refs[this.refCurrencyInput].setPrecision(this.precisionPrice());
        this.index++;
      }
    },
    computed: {
      ...mapGetters(['currentInstrument', 'position', 'balance', 'currentSymbol', 'orderbook']),
      decimal() {
        if (this.currentInstrument && this.currentInstrument.tick_size) {
          return Utils.getPrecisionNumber(this.currentInstrument.tick_size);
        }
        return 1;
      },
    },
    methods: {
      precisionPrice() {
        const a = this.contract && this.contract.tick_size ? this.$options.filters.formatFloatNumber(this.contract.tick_size) : Const.MARGIN_DEFAULT_TICK_SIZE;
        const b = this.contract && this.contract.tick_size ? (typeof this.contract.tick_size == 'string' ? parseFloat(this.contract.tick_size) : this.contract.tick_size) : Const.MARGIN_DEFAULT_TICK_SIZE
        return (b > 1e-8) ? b : 0.00000001
      },
      getRefUniqueId() {
        return window._.uniqueId('input-coin-number-');
      },
      handleChange() {
        let quantity = this.quantity;
        if (this.isShort) {
          quantity = new BigNumber(`${quantity || 0}`).mul('-1').toString();
        }
        this.entryValue = MarginCalculator.getContractValue(this.entryPrice, quantity);
        this.exitValue = MarginCalculator.getContractValue(this.exitPrice, quantity);
        this.pnl = MarginCalculator.getPnl(this.exitValue, this.entryValue);
        this.pnlPercent = MarginCalculator.getPnlPercent(this.pnl, this.entryValue);
        this.roe = MarginCalculator.getRoe(this.pnlPercent, this.leverage);
        let _position = { ...this.position, leverage: this.leverage };
        if (this.isCross) {
          _position.is_cross = true;
        } else {
          _position.is_cross = false;
          _position.leverage = this.leverage;
        }

        let realEntryPrice = this.entryPrice;
        let orderBook = this.orderbook;

        if (!this.isShort && orderBook.sell && orderBook.sell[0] && orderBook.sell[0].price) {
            if (new BigNumber(`${_position.multiplier || 0}`).comparedTo(0) < 0 && new BigNumber(`${realEntryPrice || 0}`).comparedTo(`${orderBook.sell[0].price || 0}`) > 0) {
                realEntryPrice = orderBook.sell[0].price;
            }
        }
        if (this.isShort && orderBook.buy && orderBook.buy[0] && orderBook.buy[0].price) {
            if (new BigNumber(`${_position.multiplier || 0}`).comparedTo(0) > 0 && new BigNumber(`${realEntryPrice || 0}`).comparedTo(`${orderBook.buy[0].price || 0}`) < 0) {
                realEntryPrice = orderBook.buy[0].price;
            }
        }

        this.margin = MarginCalculator.getOpenOrderMargin(realEntryPrice, quantity, _position, this.balance);
        
        this.$refs.datatable.refresh();
      },
      getData (params) {
        return new Promise(resolve => {
          return resolve(
            [ 
              {name: window.i18n.t('margin.margin'), value: this.margin,},
              {name: window.i18n.t('margin.entry_value'), value: this.entryValue,},
              {name: window.i18n.t('margin.exit_value'), value: this.exitValue,},
              {name: window.i18n.t('margin.profit_loss'), value: this.pnl,},
              {name: window.i18n.t('margin.profit_loss_percent'), value: this.pnlPercent,},
              {name: window.i18n.t('margin.roe_percent'), value: this.roe,},
            ]
          );
        });
      },
      onClick() {
        this.isShort = !this.isShort;
      },
      onClickMargin() {
        this.isCross = !this.isCross;
      },
    },
    async created() {
      if(this.currentInstrument && this.currentInstrument.multiplier) {
        this.multiplier = this.currentInstrument.multiplier;
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/sass/variables';
  .profit-loss-notice{
    font-size: 11px;
    color: $color-grey-dusty;
    padding: 2px 0px;
  }
  .text-left{
    color: $color-grey-dark;
  }
  .box_value_right {
    /*position: relative;*/
    .value_right {
      display: block;
      width: 80px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: normal;
      text-align: right;
    }
    .full_value_right {
      position: absolute;
      right: 30px;
      background-color: $color-white;
      min-width: 100px;
      line-height: 20px;
      padding: 1px 5px;
      z-index: 5;
      border-radius: 3px;
      box-shadow: 0px 0px 5px rgba(88, 88, 88, 0.5);
      display: none;
    }
    &:hover {
      .full_value_right {
        display: block;
      }
    }
  }
  #tab_profit_loss {
    .header_tab_content {
      background-color: $color-bright-gray;
      padding: 6px 30px 2px 30px;
      line-height: 20px;
      .txt-Side {
        font-size: $font-small;
        color: $color-grey-dark;
        font-family: $font-family-roboto-medium;
      }
      .box_switch {
        display: inline-block;
        float: right;
        margin: 0px;
        cursor: pointer;
        .txt-long {
          text-align: center;
          display: inline-block;
          float: left;
          margin-right: 25px;
          min-width: 65px;
          letter-spacing: 0.9px;
          font-size: $font-smaller;
          font-weight: 500;
          font-family: $font-family-roboto-bold;
          text-transform: uppercase;
          color: $color-jungle-green;
        }
        .txt-short {
          text-align: center;
          display: inline-block;
          float: right;
          margin-left: 25px;
          min-width: 45px;
          letter-spacing: 0.9px;
          font-size: $font-smaller;
          font-weight: 500;
          font-family: $font-family-roboto-bold;
          text-transform: uppercase;
          color: $color-grey-dusty;
        }
        span {
          float: left;
          width: 23px;
          height: 14px;
          background-color: $color-jungle-green;
          border-radius: 7.5px;
          top: 3px;
          display: inline-block;
          padding: 0px 6px 0 9px;
          position: relative;
          color: $color-white;
          line-height: 24px;
          text-align: right;
          i {
            left: 2px;
            top: 2px;
            width: 10px;
            height: 10px;
            border-radius: 100%;
            background: $color-white;
            position: absolute;    
            display: inline-block;
            box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
          }
          &:before,
          &:after {
            opacity: 0;
            font-size: 0px;
          }
        }
        &.active span i {
          left: 10px;
        }
        &.active span {
          background-color: $color-denim;
        }
        &.active .txt-short {
          color: $color-denim !important;
        }
        &.active {
          .txt-long {
            color: $color-grey-dusty;
          }
          .txt-short {
            color: $color-denim;
          }
        }
      }
    }
  }
  .body_tab_content {
    padding: 18px 0px;
    .col-6 {
      width: calc(50% - 10px);
      float: left;
      margin-right: 10px;
      &:last-child {
        margin-right: 0px;
        margin-left: 10px;
      }
    }

    .form-group {
      margin: 0px;
      margin-bottom: 5px;
      display: block;
      clear: both;
      float: left;
      width: 100%;
      >label {
        width: 75px;
        font-size: $font-small;
        color: $color-grey-dark;
        font-weight: 500;
        line-height: 25px;
        padding: 5px 0px;
        margin: 0px;
        margin-right: 0px;
        float: left;
      }
      >input {
        height: 35px;
        border-radius: 3px;
        border: solid 1px $color-alto;
        width: calc(100% - 75px);
        float: right;
        font-size: $font-small;
        font-family: $font-family-roboto-bold;
        line-height: 25px;
        padding: 4px 5px 4px 12px;
        box-shadow: none;
        &:focus {
          border: 1px solid $color-jungle-green;
        }
      }
    }

    .table-calculatorOutput {
      border: solid 1px $color-alto;
      background-color: $color-alabaster;
      border-radius: 3px;
      tr {
        height: 28px;
        border-bottom: solid 1px $color-alto;
        &:last-child {
        border-bottom: none;
        }
        td {
          overflow: initial;
          font-size: $font-small;
          padding: 5px 3px 3px 15px;
          &:last-child {
            padding: 5px 10px 3px 3px;
          }
        }
      }
      .font-bold {
        font-family: $font-family-roboto-bold;
      }
    }
  }
</style>