 import rf from '@/request/RequestFactory';

const actions = {
  getMamOrders({ commit }) {
    if (!window.isAuthenticated) return
    return new Promise((resolve, reject) => {
      rf.getRequest('MarginRequest').getActiveOrder({ is_mam: 1 }).
        then(res => {
          if (res.data && res.data.data) {
            commit('updateMamOrders', res.data.data);
            resolve(res.data.data);
          }
        })
        .catch((err) => {
          reject(err);
        })
    });
  },
};

const getters = {
  mamActiveOrderCount: state => {
    return state.mamOrder.activeCount || 0;
  },
  mamStopOrderCount: state => {
    return state.mamOrder.stopCount || 0;
  },
  mamOrders: state => {
    return state.mamOrders;
  },
};

const mutations = {
  setMamActiveOrderCount: (state, payload) => {
    state.mamOrder.activeCount = payload;
  },
  setMamStopOrderCount: (state, payload) => {
    state.mamOrder.stopCount = payload;
  },
  updateMamOrders: (state, payload) => {
    state.mamOrders = [...payload];
  },
};

export default {
  actions,
  getters,
  mutations,
  state: {
    mamOrder: {
      activeCount: 0,
      stopCount: 0,
    },
    mamOrders: {}
  },
};