<template>
  <div class="transfer-form">
    <div class="form-header">
      <div
        class="select-wallet"
        @click="openFrom = !openFrom"
        v-click-outside="clickOutSideSelectFrom"
      >
        <div class="select-wallet__left">
          <img src="@/assets/images/spot-wallet.svg" />
          <div class="title-select">From</div>
          <div class="value-select">{{ walletFromSelected.name }}</div>
        </div>
        <img src="/images/icon/icon-down.svg" alt="icon" v-if="!openFrom" />
        <img src="/images/icon/icon-up.svg" alt="icon" v-if="openFrom" />

        <div class="wallet-list" v-show="openFrom">
          <div
            v-for="(wallet, index) in listWallets"
            :key="index"
            @click="selectWallet(wallet, true)"
            :class="walletFromSelected.value === wallet.value ? 'active' : ''"
          >
            {{ wallet.name }}
          </div>
        </div>
      </div>

      <div class="switch-icon" @click="handleSwitch">
        <img src="@/assets/images/to-icon.svg" />
        <img src="@/assets/images/switch-icon.svg" />
      </div>

      <div
        class="select-wallet"
        @click="openTo = !openTo"
        v-click-outside="clickOutSideSelectTo"
      >
        <div class="select-wallet__left">
          <img src="@/assets/images/future-wallet.svg" />
          <div class="title-select">To</div>
          <div class="value-select">{{ walletToSelected.name }}</div>
        </div>
        <img src="/images/icon/icon-down.svg" alt="icon" v-if="!openTo" />
        <img src="/images/icon/icon-up.svg" alt="icon" v-if="openTo" />

        <div :class="['wallet-list', 'wallet-list-to']" v-show="openTo">
          <div
            v-for="(wallet, index) in listWallets"
            :key="index"
            @click="selectWallet(wallet, false)"
            :class="walletToSelected.value === wallet.value ? 'active' : ''"
          >
            {{ wallet.name }}
          </div>
        </div>
      </div>
    </div>

    <div class="form-content">
      <div class="group-switch">
        <div class="form-content__input-label">Coin</div>
        <coin-select-box-usd
          v-model="selectedCoin"
          :options="listUSDM"
          :click="handleSelectCoin"
          v-if="
            walletToSelected.value === 'usdm' ||
            walletFromSelected.value === 'usdm'
          "
        ></coin-select-box-usd>
        <coin-select-box
          v-model="selectedCoin"
          :options="coins"
          :click="handleSelectCoin"
          v-else
        ></coin-select-box>
      </div>
      <div class="form-content__input-label">{{ $t("transfer_amount") }}</div>
      <div class="form-content__input-block">
        <currency-input
          :precision="8"
          class="input-form w_220"
          placeholder="Amount"
          :value="amount"
          v-model="amount"
          @focus="focusInput"
          :class="{
            error: errors.has('amount'),
          }"
        />
        <div class="label-all" @click="clickAll">All</div>
      </div>
      <div v-show="errors.has('amount')" class="w_220 invalid-feedback">
        {{ errors.first("amount") }}
      </div>

      <div class="available">
        Available:
        <span
          >{{ avaliableSelectedCoin | formatNumberAndDecimal }}
          {{ selectedCoin?.coinName?.toUpperCase() }}</span
        >
      </div>
    </div>

    <button
      class="form-footer"
      @click.prevent="submitTransfer($event)"
      :disabled="disabled || !amount || this.errors.count() > 0"
    >
      Confirm
    </button>
    <message-with-icon />
  </div>
</template>

<script>
import Header from "@/pages/wallet/component/Header.vue";
import { mapState } from "vuex";
import CoinSelectBox from "@/components/shared_components/common/CoinSelectBox";
import CoinSelectBoxUsd from "@/components/shared_components/common/CoinSelectBoxUsd";
import rf from "@/request/RequestFactory";
import BigNumber from "bignumber.js";
import MessageWithIcon from "@/components/shared_components/common/MessageWithIcon";
import ETHIcon from "@/assets/images/ethereum-eth-logo.png";

const MIN_AMOUNT_CRYPTO = Math.pow(10, -8);
const LIST_WALLETS = [
  {
    name: "Spot",
    key: "main",
    value: "main",
  },
  {
    name: "USDⓈ-M Futures",
    key: "future",
    value: "usdm",
  },
  {
    name: "Coin-M Futures",
    key: "future",
    value: "coinm",
  },
];

export default {
  components: {
    Header,
    CoinSelectBox,
    MessageWithIcon,
    CoinSelectBoxUsd,
  },

  data() {
    return {
      amount: "",
      coins: [],
      selectedCoin: {},
      address: {},
      currency: {},
      openFrom: false,
      openTo: false,
      listWallets: LIST_WALLETS,
      walletToSelected:
        this.$route.query.symbol === "usdt" ||
        this.$route.query.symbol === "usd"
          ? LIST_WALLETS[1]
          : LIST_WALLETS[2],
      walletFromSelected: LIST_WALLETS[0],
      balanceFutures: {},
      isAuthenticated: window.isAuthenticated,
      balanceSpot: {},
      avaliableSelectedCoin: "0",
      listInstruments: [],
      listAllowcatedMargin: [],
      unrealizedPNLValue: [],
      positionData: [],
      costOpenOrder: {},
      Price24h: [],
      openOrder: [],
      listUSDM: [],
      disabled: false,
      ethicon: ETHIcon,
    };
  },
  sockets: {
    connect: function () {},
  },
  watch: {
    $route(to, from) {
      this.findSelectedCoin();
    },
    positionData() {
      this.unrealizedPostions();
    },
    balanceFutures() {
      this.unrealizedPostions();
    },
    openOrder() {
      this.availableBalanceWithdraw();
    },
  },
  computed: {
    ...mapState({
      masterdata: (state) => state.masterdata,
    }),
    coinName() {
      // if (_.isEmpty(this.selectedCoin.coinName)) {
      //   return "";
      // }
      return this.selectedCoin?.coinName || "";
    },
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    showQRCode(name) {
      CommonModal.show(name, {
        position: "center",
        mask: true,
      });
    },
    focusInput() {
      this.errors.clear();
      this.disabled = false;
    },
    handleAvailable() {
      this.availableBalanceWithdraw();
      if (this.walletFromSelected.key === "main") {
        this.avaliableSelectedCoin =
          this.balanceSpot[
            this.selectedCoin.coinName.toLowerCase() || "usdt"
          ].available_balance;
      } else {
        this.avaliableSelectedCoin =
          this.balanceFutures.assets[
            this.selectedCoin.coinName.toUpperCase() || "usdt"
          ].availableBalance;
      }
    },
    clickAll() {
      this.amount = this.avaliableSelectedCoin;
    },
    handleSwitch() {
      const typeFrom = this.walletFromSelected;
      this.walletFromSelected = this.walletToSelected;
      this.walletToSelected = typeFrom;
      this.handleAvailable();
    },
    handleSelectCoin() {
      this.$router.push(
        `/funds/transfer?symbol=${this.selectedCoin.coinName.toLowerCase()}`
      );
      this.handleAvailable();
    },
    selectWallet(wallet, isFrom) {
      if (isFrom) {
        this.walletFromSelected = wallet;
        if (wallet.key === this.walletToSelected.key) {
          if (wallet.key === "main") {
            this.walletToSelected = LIST_WALLETS[1];
          } else {
            this.walletToSelected = LIST_WALLETS[0];
          }
        }
      } else {
        this.walletToSelected = wallet;
        if (wallet.key === this.walletFromSelected.key) {
          if (wallet.key === "main") {
            this.walletFromSelected = LIST_WALLETS[1];
          } else {
            this.walletFromSelected = LIST_WALLETS[0];
          }
        }
      }
      if (
        this.walletToSelected.value === "usdm" ||
        this.walletFromSelected.value === "usdm"
      ) {
        this.selectedCoin = this.listUSDM[0];
      } else {
        this.selectedCoin = this.coins[0];
      }
      this.$router.push(
        `/funds/transfer?symbol=${this.selectedCoin.coinName.toLowerCase()}`
      );
      this.handleAvailable();
    },
    clickOutSideSelectTo() {
      this.openTo = false;
    },
    clickOutSideSelectFrom() {
      this.openFrom = false;
    },
    findSelectedCoin() {
      const coinName = this.$route.query.symbol || undefined;
      const listCoins =
        this.walletToSelected.value === "usdm" ||
        this.walletFromSelected.value === "usdm"
          ? this.listUSDM
          : this.coins;
      if (coinName) {
        this.selectedCoin = _.find(listCoins, (coin) => {
          return coin.coinName === coinName;
        });
      }
    },
    validate() {
      this.errors.remove("amount");
      const amountBigNumber = new BigNumber(this.amount || 0);
      const availableBalance = new BigNumber(this.avaliableSelectedCoin || 0);

      if (amountBigNumber.isZero()) {
        this.errors.add(
          "amount",
          window.i18n.t("funds.balances.amount_over_0")
        );
        return false;
      }

      if (amountBigNumber.lt(MIN_AMOUNT_CRYPTO)) {
        this.errors.add(
          "amount",
          window.i18n.t("funds.balances.min_amount_is_invalid")
        );
        return false;
      }

      if (availableBalance.lt(amountBigNumber)) {
        this.errors.add(
          "amount",
          window.i18n.t(
            "funds.balances.amount_can_not_greater_than_exchange_available_balance_without_orderbook"
          )
        );
        return false;
      }
      return true;
    },
    submitTransfer(e) {
      e.preventDefault();
      if (!this.validate()) {
        return false;
      }
      this.disabled = true;
      const dataFromFuture = {
        amount: this.amount,
        assetType: this.selectedCoin.coinName.toUpperCase(),
      };
      const dataFromMain = {
        amount: this.amount || 0,
        asset: this.selectedCoin.coinName.toLowerCase(),
        from: this.walletFromSelected?.key,
        to: this.walletToSelected?.key,
      };
      const request =
        this.walletFromSelected.key === "future"
          ? "withdrawFutureBalance"
          : "transferFutureBalance";
      const bodyData =
        this.walletFromSelected.key === "future"
          ? dataFromFuture
          : dataFromMain;
      rf.getRequest("UserRequest")
        [request](bodyData)
        .then((response) => {
          this.showInfoMessage(
            window.i18n.t("funds.balances.transfer_balance_success")
          );
          this.getBalanceFutureAPI();
          this.getBalanceAPI();
          this.amount = "";
          this.disabled = false;
        })
        .catch((err) => {
          this.disabled = false;
        });
    },
    getCoinList() {
      let coinCodes = window._.flatMap(
        this.masterdata.coins_confirmation,
        function (value) {
          return [value.coin];
        }
      );

      coinCodes = window._.uniq(coinCodes);

      let countCoins = coinCodes.length;

      const coinNames = {};
      const coinImages = {};

      _.forEach(this.masterdata.coins, (item, key) => {
        coinNames[item.coin] = item.name;
        coinImages[item.coin] = item.icon_image;
        coinImages["eth"] = this.ethicon;
      });
      for (let i = 0; i < countCoins; i++) {
        let coinCode = coinCodes[i];
        let coinInfo = window._.findLast(
          this.masterdata.coins,
          (item) => item.coin == coinCode
        );
        if (!coinInfo && coinCode !== "usd") {
          let coin = window._.findLast(
            this.masterdata.coin_settings,
            (item) => {
              return item.coin == coinCode;
            }
          );

          if (!coin) {
            coin = {
              coin: coinCode,
              name: window.i18n.t(`currency.${coinCode}.fullname`),
            };
          }
          if (!coin.name) {
            coin.name = window.i18n.t(`currency.${coinCode}.fullname`);
          }
          this.coins.push({
            coinName: coin.coin,
            shortNameCurrency: coin?.currency?.toUpperCase(),
            fullNameCurrency: coin.name,
            iconCurrency: coinImages[coin.coin],
          });

          coinInfo = coin;
        }
        else if  (coinCode !== "usd" ) {
          if (coinCode === "usdt") {
            this.listUSDM.push({
              coinName: coinCode,
              shortNameCurrency: coinCode?.toUpperCase(),
              fullNameCurrency:
                coinInfo?.name || window.i18n.t(`currency.usd.fullname`),
              iconCurrency:
                coinImages[coinCode] || "/images/color_coins/usd.png",
            });
          } else {
            if (coinCode !== "ltc") {
              this.coins.push({
                coinName: coinCode,
                shortNameCurrency: coinCode?.toUpperCase(),
                fullNameCurrency: coinInfo.name,
                iconCurrency: coinImages[coinCode],
              });
            }
          }
        }
      }
      this.findSelectedCoin();
    },
    getBalanceFutureAPI() {
      return rf
        .getRequest("UserRequest")
        .getFuturesBalances()
        .then((res) => {
          const listWithoutLTC = res.data.filter(
            (item) => item.asset !== "LTC"
          );
          const assetsKeyObject = {};
          listWithoutLTC.forEach((asset) => {
            assetsKeyObject[asset.asset] = {
              balance: asset?.balance,
              marginBalance: 0,
              availableBalance: 0,
              totalAllowcated: 0,
              unrealisedPnl: 0,
              unrealisedROE: "",
            };
          });
          this.balanceFutures = {
            assets: assetsKeyObject,
            totalBalance: listWithoutLTC.totalBalance,
            totalMarginBalance: listWithoutLTC.totalMarginBalance,
          };
        });
    },
    getBalanceAPI() {
      return rf
        .getRequest("UserRequest")
        .getBalance(false)
        .then((res) => {
          this.balanceSpot = res.data.main;
          if (this.walletFromSelected.key === "main") {
            const coinSelected =
              this.selectedCoin?.coinName.toLowerCase() || "usdt";
            this.avaliableSelectedCoin =
              res.data.main[coinSelected].available_balance;
          }
        });
    },
    showInfoMessage(message, callBack = () => {}) {
      window.MessageWithIcon.success(message);
    },
    positionMargin() {
      const data = {
        contractType: "ALL",
      };
      return rf
        .getRequest("MarginRequest")
        .getPosition(data)
        .then((res) => {
          this.positionData = res.data;
          return res;
        });
    },
    convertCoin(coin) {
      if (coin?.toString().length > 10) {
        return coin.toString().slice(0, 11) + "... ";
      } else if (isNaN(coin)) {
        return 0;
      }
      return coin;
    },
    markPriceData(symbol) {
      const findSymbol = this.Price24h.filter((item) => {
        return item.symbol === symbol;
      });
      return this.convertCoin(findSymbol[0]?.oraclePrice || 0);
    },
    allowcatedMargin(item, contract) {
      const isCurrency = item.asset === "USD" || item.asset === "USDT";
      const markPrice = this.markPriceData(item.symbol);
      const absQty = Math.abs(item.currentQty);
      const leverage = parseFloat(item.leverage);
      const entryPrice = parseFloat(item.entryPrice);
      const adjustMargin = parseFloat(item.adjustMargin);
      const multiplierContract = parseFloat(contract);
      const positionMargin = parseFloat(item.positionMargin);
      let allowcatedMarginForCross = isCurrency
        ? new BigNumber(absQty).times(markPrice).dividedBy(leverage)
        : new BigNumber(absQty)
            .times(multiplierContract)
            .dividedBy(new BigNumber(leverage).times(markPrice));
      let allowcatedMarginForIsolate = new BigNumber(positionMargin || 0).add(
        adjustMargin || 0
      );
      return Boolean(item.isCross)
        ? allowcatedMarginForCross.toString()
        : allowcatedMarginForIsolate.toString();
    },
    sumCostOpenOrder(order) {
      let costUSD = 0;
      let costUSDT = 0;
      this.costOpenOrder = {};
      order.forEach((item) => {
        if (item.cost !== null) {
          if (
            item.symbol.includes("USDT") &&
            !item.symbol.includes("USDTUSD")
          ) {
            costUSDT += parseFloat(item.cost);
            this.costOpenOrder.USDT = costUSDT || 0;
          } else if (item.contractType === "COIN_M") {
            let assets = item.symbol.replace(/USDM|USDT|USD/, "");
            if (!this.costOpenOrder[assets]) {
              this.costOpenOrder[assets] = 0;
            }
            this.costOpenOrder[assets] += parseFloat(item.cost);
          } else {
            costUSD += parseFloat(item.cost);
            this.costOpenOrder.USD = costUSD || 0;
          }
        }
      });
    },
    getSumOrderMargin() {
      const data = {
        contractType: "ALL",
      };
      return rf
        .getRequest("UserRequest")
        .getOpenOrderMargin(data)
        .then((res) => {
          this.openOrder = res.data;
          this.sumCostOpenOrder(res.data);
        });
    },
    unrealizedPNL(markPrice, item, side, contract) {
      const isCurrency = item.asset === "USD" || item.asset === "USDT";
      const markPriceResult = this.markPriceData(item.symbol);
      const absQty = Math.abs(item.currentQty);
      const leverage = parseFloat(item.leverage);
      const entryPrice = parseFloat(item.entryPrice);
      const multiplierContract = parseFloat(contract);
      const inverseEntry = new BigNumber(1).dividedBy(entryPrice);
      const inverseMark = new BigNumber(1).dividedBy(markPrice);
      const unrealizedPNLUSD = new BigNumber(absQty)
        .times(new BigNumber(markPriceResult).minus(entryPrice))
        .times(side)
        .toString();
      const unrealizedPNLCoin = new BigNumber(absQty)
        .abs()
        .times(multiplierContract)
        .times(new BigNumber(inverseEntry).minus(inverseMark))
        .times(side)
        .toString();

      const unrealizedPNL = isCurrency ? unrealizedPNLUSD : unrealizedPNLCoin;
      return unrealizedPNL;
    },
    allowcatedMarginForAsset(asset) {
      let unrealizedTotal = 0;
      const total = this.listAllowcated?.reduce((sum, current) => {
        // parseFloat(sum) + (current.allowcated * 1)
        if (current.asset === asset && !isNaN(current.allowcated)) {
          unrealizedTotal = parseFloat(sum) + current.allowcated * 1;
        }
        return unrealizedTotal;
      }, 0);
      return total;
    },
    async availableBalanceWithdraw() {
      for (let key in this.balanceFutures.assets) {
        let variableBalance = this.balanceFutures.assets[key];
        let costOrder = this.costOpenOrder[key] || 0;
        let totalAllowcated = this.allowcatedMarginForAsset(key);
        let availableUSDT = new BigNumber(variableBalance.balance)
          .minus(totalAllowcated.toString())
          .minus(costOrder.toString())
          .toString();
        let maximunWithdraw = new BigNumber(availableUSDT)
          .add(variableBalance.unrealisedPnl.toString())
          .toString();
        variableBalance.availableBalance = new BigNumber.min(
          availableUSDT,
          maximunWithdraw
        ).toString();
        const availableBalance = new BigNumber(
          variableBalance.availableBalance.toString()
        );
        variableBalance.availableBalance = BigNumber.max(
          0,
          availableBalance
        ).toString();
      }
      // if (this.walletFromSelected.key === "future") {
      //   this.handleAvailable();
      // }
    },
    unrealizedPostions() {
      this.unrealizedPNLValue = [];
      this.listAllowcated = [];
      let balanceFuture = this.balanceFutures.assets;
      for (const item of this.positionData) {
        const multiplier = this.listInstruments[item.symbol]?.multiplier;

        if (item.currentQty != 0) {
          const markPrice = this.markPriceData(item.symbol);
          const side = item.currentQty > 0 ? 1 : -1;
          const allowcated = this.allowcatedMargin(item, multiplier);
          const unrealizedPNL = this.unrealizedPNL(
            markPrice,
            item,
            side,
            multiplier
          );
          const unrealizedObj = {
            asset: item.asset,
            PNL: unrealizedPNL,
          };
          const allowcatedObj = {
            asset: item.asset,
            allowcated: allowcated,
          };
          this.unrealizedPNLValue.push(unrealizedObj);
          this.listAllowcated.push(allowcatedObj);
          if (balanceFuture) {
            const isCross = Boolean(item.isCross) ? unrealizedPNL : 0;
            balanceFuture[item?.asset].unrealisedPnl = isCross;
          }
          this.availableBalanceWithdraw();
        }
      }
    },
    getInstruments() {
      return rf
        .getRequest("UserRequest")
        .getInstruments()
        .then((res) => {
          const assetsKeyObject = {};
          return res.data.forEach((asset) => {
            assetsKeyObject[asset.symbol] = {
              ...asset,
            };
            this.listInstruments = assetsKeyObject;
          });
        });
    },

    async initData() {
      await Promise.all([
        this.getBalanceFutureAPI(),
        this.getBalanceAPI(),
        this.positionMargin(),
        this.getSumOrderMargin(),
        this.getInstruments(),
      ]);
    },
  },
  created() {
    if (!this.isAuthenticated) {
      return;
    }
    this.initData();
  },
  mounted() {
    this.getCoinList();
    this.findSelectedCoin();
    // this.$router.push(
    //     `/funds/transfer?symbol=${this.selectedCoin.coinName.toLowerCase()}`
    //   );
    this.sockets.subscribe("tickers", (data) => {
      this.Price24h = data;
    });
    this.sockets.subscribe("position", (data) => {
      if (data.contractType === this.contractType) {
        const index = this.positionData.findIndex(
          (obj) => obj.symbol == data.symbol
        );
        if (index != -1) {
          this.positionData[index] = data;
          if (data.currentQty == 0) {
            this.positionData.splice(index, 1);
          }
        } else if (data.currentQty != 0) {
          this.positionData.unshift(data);
        }
      }
    });
    this.sockets.subscribe("balance", (data) => {
      this.balanceFutures.assets[data.asset].balance = data.balance;
      // this.marginBalance()
      this.unrealizedPostions();
      this.handleAvailable();
    });
    this.sockets.subscribe("orders", (data) => {
      data.forEach((elements) => {
        if (elements.status == "CANCELED") {
          const index = this.openOrder.findIndex((obj) => {
            return elements.symbol == obj.symbol;
          });
          this.openOrder.splice(index, 1);
          return;
        }
        this.openOrder.push(elements);
      });
      this.sumCostOpenOrder(this.openOrder);
    });
    this.sockets.subscribe("notifications", (data) => {
      const msg = data[0];
      if (msg.amount) {
        if (msg.type == "success") {
          this.showInfoMessage(
            window.i18n.t("funds.balances.transfer_balance_success")
          );
        } else {
          this.showErrorMessage(
            window.i18n.t("funds.balances.transfer_balance_fails")
          );
        }
      }
    });
  },
  beforeDestroy() {
    this.sockets.unsubscribe("notifications");
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables";

.transfer-form {
  width: 560px;
  .form-header {
    background: var(--color-bg-select);
    padding: 20px 30px 20px 15px;
    border-radius: 8px;
  }
  .select-wallet {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    width: 100%;
    cursor: pointer;
    position: relative;

    .wallet-list {
      position: absolute;
      top: 30px;
      left: 68px;
      background: var(--background-color-primary);
      padding: 15px 20px;
      box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.12);
      border-radius: 8px;
      color: var(--color-percent);
      display: flex;
      flex-direction: column;
      gap: 12px;
      z-index: 9;
      .active {
        color: #00ddb3;
      }

      div {
        &:hover {
          color: #00ddb3;
        }
      }
    }

    .wallet-list-to {
      left: 50px;
    }

    &__left {
      display: flex;
      align-items: center;
      gap: 8px;

      .title-select {
        font-size: 12px;
        font-weight: 400;
        line-height: 24px;
        color: var(--color-percent);
      }

      .value-select {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: var(--text-primary-color);
      }
    }
  }
  .switch-icon {
    margin: 14px 0px;
    display: flex;
    justify-content: space-between;
  }

  .assest-usdt {
    background: var(--color-bg-select);
    font-size: 18px;
    border-radius: 8px;
    padding: 12px 15px;
    color: var(--text-primary-color);
    display: flex;
    align-items: center;
    gap: 10px;

    span {
      color: var(--color-percent);
      font-size: 12px;
    }
  }

  .form-content {
    &__input-label {
      font-size: 16px;
      font-weight: 500;
      color: var(--text-primary-color);
      margin: 30px 0 10px 0;
    }

    &__input-block {
      display: flex;
      align-items: center;
      padding: 13px 15px;
      border-radius: 8px;
      background-color: var(--color-bg-select);
    }
    .input-form {
      width: 100%;
      border: none;
      background: transparent;
      font-size: 16px;
      line-height: 24px;
      color: var(--text-primary-color);
      &::placeholder {
        color: var(--text-search);
      }
    }

    .label-all {
      color: #00ddb3;
      font-weight: 500;
      font-size: 16px;
      padding-left: 8px;
      cursor: pointer;
      border-left: 1px solid var(--text-search);
    }

    .available {
      float: right;
      margin-top: 10px;
      color: var(--color-percent);
      font-size: 14px;
      span {
        color: var(--text-primary-color);
      }
    }
  }

  .form-footer {
    margin-top: 20px;
    border-radius: 8px;
    background: #00ddb3;
    padding: 14px 22px;
    text-align: center;
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    &:hover {
      opacity: 0.8;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

@media screen and (max-width: 992px) {
}
@media screen and (max-width: 600px) {
  .transfer-form {
    width: 100%;
  }
}
</style>
