<template>
  <!-- 永续合约 -->
  <div class="lading-page slideDetail slideDetail_contract contract_page">
    <div class="banner_modular tranding_bonus">
      <div class="banner_con">
        <div class="bonus_txt">
          <div class="bonus_tit" v-html="$t('contract_perpetual_tit')"></div>
          <div class="bonus_time" v-html="$t('contract_perpetual_subtit')"></div>
          <div class="bonus_time" v-html="$t('contract_perpetual_time')"></div>
          <div class="bonus_btn" v-html="$t('contract_perpetual_btnText')"></div>
        </div>
      </div>
    </div>
    <div class="hy_tit invite_con">
      <div class="f_24">{{$t('contract_time')}}</div>
      <div class="f_24">{{$t('contract_explain')}}</div>
    </div>
    <div class="contract_con bg_F7F5F6">
      <div class="invite_con">
        <p>{{$t('contract_release')}}</p>
        <ul>
          <li>{{$t('contract_btc')}}</li>
          <li>{{$t('contract_eth')}}</li>
          <li>{{$t('contract_month_3')}}</li>
          <li>{{$t('contract_month_6')}}</li>
        </ul>
        <p>{{$t('contract_release_time')}}</p>
        <ul>
          <li>{{$t('contract_release_time_txt')}}</li>
        </ul>
        <p>{{$t('contract_release_trait_tit')}}</p>
        <p>{{$t('contract_release_trait_tit_desc')}}</p>
        <ul>
          <li>{{$t('contract_times')}}</li>
          <li>{{$t('contract_services')}}</li>
          <li>{{$t('contract_mam')}}</li>
          <li>{{$t('contract_api')}}</li>
          <li>{{$t('contract_transition')}}</li>
        </ul>
      </div>
    </div>
    <div class="invite_con">
      <p>{{$t('contract_explain_tit')}}</p>
      <p>{{$t('contract_explain_subtit')}}</p>
      <ul>
        <li>{{$t('contract_explain_li_1')}}</li>
        <li>{{$t('contract_explain_li_2')}}</li>
      </ul>
      <p> {{$t('contract_contract_tit')}}</p>
      <p>{{$t('contract_contract_subtit')}}</p>
      <ul>
        <li>{{$t('contract_contract_li_1')}}</li>
        <li>{{$t('contract_contract_li_2')}}</li>
        <li>{{$t('contract_contract_li_3')}}</li>
      </ul>
      <p>{{$t('contract_contract_subtit_2')}}</p>
    </div>
    <div class="bg_F7F5F6">
      <div class="invite_con">
        <p>{{$t('contract_transition_tit')}}</p>
        <p>{{$t('contract_transition_subtit')}}</p>
        <ul>
          <li>{{$t('contract_transition_li_1')}}</li>
          <li>{{$t('contract_transition_li_2')}}</li>
        </ul>
        <p>{{$t('contract_methods_tit')}}</p>
        <p>{{$t('contract_methods_subtit')}}</p>
        <ul>
          <li>{{$t('contract_methods_li_1')}}</li>
          <li>{{$t('contract_methods_li_2')}}</li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {

      }
    },
    methods: {

    },
    computed: {
      localeLang() {
        let lang = this.$store.state.localLang;
        if (typeof lang === 'object') {
          lang = window.localStorage.locale;
        }
        if (lang === 'ko') {
          lang = 'en';
        }
        // return lang;
        return 'en';
      }
    },
  }
</script>
<style lang="scss" scoped>
  @import "@/assets/sass/variables";
  @import "@/assets/sass/slide/slide";
  @import "@/assets/sass/slide/slidedetail.scss";

  $min-w: 1200px;
  $max-w: 90%;
  $bc: #ccc;
  .lading-page {
    padding-bottom: 100px;
    .banner_modular {
      background: linear-gradient(127deg, rgba(117, 20, 134, 1) 0%, rgba(45, 54, 148, 1) 100%);
      .banner_con {
        min-width: 1200px;
        max-width: 1400px;
        height: 100%;
        margin: 0px auto;
        position: relative;
        z-index: 3;
        &::before {
          position: absolute;
          left: 0px;
          top: 0px;
          width: 100%;
          height: 100%;
          content: '';
          z-index: 0;
          background: url('@/assets/images/rebate/bg_yx.png') no-repeat;
          background-size: 90% 80%;
          background-position: right;
        }
        .bonus_txt {
          padding: 140px 0px 0px 70px;
          width: 120%;
          min-width: 120%;
          .bonus_tit {
            font-size: 74px;
            margin-bottom: 20px;
          }
          .bonus_time {
            margin-bottom: 10px;
          }
          .bonus_btn {
            margin-top: 16px;
            background: linear-gradient(to left, #6BBCDE 0%, #459DD6 100%);
          }
        }
      }
    }

    .invite_con {
      max-width: 80%;
      margin: 0px auto;
      padding: 20px 0px;
      p {
        font-size: $font-root;
        font-weight: 600;
        font-family: $font-family-roboto;
      }

    }

    .hy_tit {
      margin: 62px auto 32px;
    }

    .bg_F7F5F6 {
      background-color: #F7F5F6;
    }

    .f_24 {
      font-size: $font-big-24;
      color: $color-black;
      font-weight: 600;
      font-family: $font-family-roboto;
    }

    ul {
      margin: 16px 0px 30px 14px;
      li {
        list-style-type: disc;
        font-family: $font-family-roboto;
      }
    }

  }
</style>