import BaseModelRequest from './base/BaseModelRequest';
import Utils from '@/common/Utils';

export default class NewsRequest extends BaseModelRequest {
  getModelName() {
    return 'news';
  }

  markReaded(item) {
    const READED = 1;
    //const READED = item.isRead ? 0 : 1;
    const url = `/news/${item.id}/change-status/${READED}`;

    return this.post(url);
  }

  getCountUnread(params) {
    const url = `/news/count-unread`;
    return this.get(url, params);
  }

  getUserNewsInfo(params) {
    const url = `/news/get-user-news-info`;
    return this.get(url, params);
  }

}

