<template>
  <div id="wait_admin_verify">
    <div id="MasterPopup">
      <modal :name="modalName" :title="$t('register.entry')" width="610">
        <template slot="body">

          <div class="confirmation-beta-tester-modal">
            <div class="contest-information" style="padding: 15px 30px;">
                <div class="name-column">
                  <div class="name-row">{{$t('margin.entry.contest_name')}}:</div>
                  <div class="name-row">{{$t('margin.entry.entry_time')}}:</div>
                  <div class="name-row">{{$t('margin.entry.contest_time')}}:</div>
                  <div class="name-row">{{ $t('margin.minimum_amount') }}:</div>
                  <div class="name-row">{{ $t('entry.number_of_users') }}:</div>
                </div>
                <div class="value-column">
                  <div class="value"> {{contest.name}}</div>
                  <div class="value">{{contest.start_entry_time | convertToLocalTime}} - {{contest.end_entry_time | convertToLocalTime}}</div>
                  <div class="value">{{contest.start_contest_time | convertToLocalTime}} - {{contest.end_contest_time | convertToLocalTime}}</div>
                  <div class="value">{{ contest.minimum_margin | formatCurrencyAmount(null, '0') }} BTC</div>
                  <div class="value">{{ contest.number_of_users }}</div>

                </div>
            </div>

            <div class="box_body clearfix" >
              <div v-if="!isJoinedEntry" v-html="$t('participate.entry')"></div>
              <div style="display: inline" v-html="$t('margin.note')"></div>
              <span>:</span>
              <div v-html="$t('participate.entry.note1')"></div>
              <div v-html="$t('participate.entry.note2')"></div>
              <div v-html="$t('participate.entry.note3')"></div>
              <div v-html="$t('participate.entry.note4')"></div>
              <div v-html="$t('participate.entry.note5')"></div>
            </div>

            <div class="clearfix"></div>
          </div>
          <div class="box_footer clearfix"  v-if="!isJoinedEntry">
            <button @click="onClickYes" class="btn btn_footer btn_oke">{{ $t('common.action.yes') }}</button>
            <span class="txt">{{ $t('beta_tester.or') }}</span>
            <button @click="onClickNo" class="btn btn_footer btn_cancel">{{ $t('common.action.no') }}</button>
          </div>
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
  import rf from '@/request/RequestFactory'
  import Modal from '@/components/shared_components/common/Modal.vue';

  export default {
    components: {
      Modal,
    },
    props:{
      isJoinedEntry: {
      },
    },
    data() {
      return {
        modalName: 'RegisterEntry',
        contest: {},
      }
    },
    methods: {
      initData() {
      },
      onClickYes() {
        rf.getRequest("EntryRequest").registerEntry({})
            .then(res => {
              if (res && res.data) {
                this.showSuccess(this.$t('review_register_erc_2_0.success_msg'));
                this.$broadcast('reloadLeaderboard');
                this.$emit('entry-success');
                window.CommonModal.hide(this.modalName);
              } else {
                this.showError(this.$t('entry.msg.register_unsuccessfully'));
              }
            }).catch(error => {
              if (error && error.response && error.response.data && error.response.data.message) {
                if(error.response.data.message === 'mam_user_cannot_join_exception'){
                  this.showError(this.$t('entry.exception.msg.mam_user_join_request'));
                } else if (error.response.data.message === 'user_not_enough_margin_balance') {
                  this.showError(this.$t('entry.exception.msg.margin_balance_not_enough'));
                } else {
                  this.showError(this.$t('entry.msg.register_unsuccessfully'));
                }
              }
            });
      },
      onClickNo() {
        window.CommonModal.hide(this.modalName);
      },
      showError(message) {
        Message.error(message, {}, {position: "bottom_left"});
      },
      showSuccess(message) {
        Message.success(message, {}, {position: "bottom_left"});
      },
      closeModal() {
        this.$emit(`${this.modalName}:CLOSE`);
      },
      getOpeningContestOrLatestContest() {
        if (window.isAuthenticated) {
          rf.getRequest("EntryRequest").getOpeningContestOrLatestContest()
              .then(res => {
                if (res && res.data) {
                  this.contest = res.data;
                  this.isTeamBattle = res.data.is_team_battle;
                }
            })
        }
      },
    },
    mounted() {
      document.title = this.$t('menu.exchange') + ` – ${this.tabTitle}`;
      this.initData();
      this.getOpeningContestOrLatestContest();
    }
  }
</script>

<style lang="scss">
  @import "@/assets/sass/variables";

  #wait_admin_verify {
    #MasterPopup {

      .contest-information {
        padding-left: 20px;

        .name-column {
          display: inline-block;
          vertical-align: top;
          .name-row{
            padding-top: 5px;
          }
        }

        .value-column {
          .value{
            padding-top: 5px;
          }
          margin-left: 5px;
          display: inline-block;
          font-weight: bold;
          word-break: break-word;
        }
      }

      .modal-content {
        border-radius: 7px;
        min-width: 550px;

        .modal-header {
          border-top-right-radius: 7px;
          border-top-left-radius: 7px;
          background-color: #EFF4F8;
          border-bottom: 1px solid #ccc;
          font-size: 19px;
          padding: 25px 30px 20px 30px;
        }

        .modal-title {
          font-size: 23px;
          line-height: 30px;
        }
        @media (max-width: 1300px),(max-height: 700px) {
          .confirmation-beta-tester-modal{
            max-height: 400px;
            overflow-x: scroll;
          }
        }

        .modal-body {
          .box_body {
            min-height: 100px;
          }

          padding: 0 0 0 0;

          .box_body {
            padding: 15px 30px;

            .group_find_join_modal {
              font-size: 14px;
              color: #333;
              line-height: 20px;
              margin-bottom: 5px;
            }
          }

          .box_footer {
            border-bottom-left-radius: 7px;
            border-bottom-right-radius: 7px;
            background-color: #EFF4F8;
            padding: 15px 30px;
            margin-top: 15px;
            border-top: 1px solid #cccccc;

            .btn_footer {
              height: 40px;
              min-width: 70px;
              text-align: center;
              font-size: 16px;
              line-height: 20px;
              padding: 11px 15px;
              border-radius: 3px;
              color: #999;
              border: 1px solid #ddd;
              display: inline-block;
              float: left;
            }

            .btn_oke {
              min-width: 100px;
              text-transform: uppercase;
              background-color: #2DAC91;
              color: #ffffff;
              border: none;

              &:hover {
                background-color: #2e9bdb;
              }
            }

            .btn_cancel {
              border: none;
              background-color: transparent;
              padding-left: 0;
            }

            .txt {
              display: inline-block;
              float: left;
              line-height: 20px;
              padding: 10px 10px 10px 20px;
            }
          }
        }

        .modal-footer {
          display: none;
        }
      }

      .checkbox-input {
        width: 220px;
        display: inline-block;
        margin-left: 7px;
        position: relative;
        top: 7px;
        margin-top: 13px;

        input {
          display: none;
        }

        label {
          cursor: pointer;
          position: absolute;
          width: 17px;
          height: 17px;
          top: 0;
          left: 0;
          border: 1px solid $color-grey-dusty;
          border-radius: 1px;

          &:after {
            opacity: 0;
            content: '';
            position: absolute;
            width: 16px;
            height: 10px;
            background: transparent;
            top: 0;
            left: 0;
            border: 4px solid $color-grey-dusty;
            border-top: none;
            border-right: none;
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
          }
        }

        input[type=checkbox]:checked + label:after {
          opacity: 1;
        }

        span {
          margin-left: 25px;
        }
      }

      .disabled {
        color: #e2e2e2;

        label {
          border: 1px solid #e2e2e2;
        }
      }
    }
  }
</style>