import zlib from 'browserify-zlib';
import buffer from 'buffer';
import BaseRequest from './base/BaseRequest';
import MasterdataUtils from '@/common/MasterdataUtils';

export default class MasterdataRequest extends BaseRequest {

  async getAll(immediate) {
    let data = MasterdataUtils.getCachedMasterdata();
    if (immediate || !data) {
      data = await this._get();
    }
    return data;
  }

  find(table, id) {
    return new Promise((resolve, reject) => {
      this.getAll()
          .then(data => {
            let record = data[table].find(row => parseInt(row.id) === parseInt(id));
            return resolve(record);
          })
    });
  }

  getTable(api, params) {
    let url = `/masterdata`;
    if (!params) params = {};
    params._table = api;
    return this.get(url, params);
  }

  _get() {
    let url = '/masterdata';
    return this.get(url)
      .then((res) => {
        return MasterdataUtils.saveMasterdata(res.dataVersion, res.data);
      })
      .then(data => {
        return MasterdataUtils.getCachedMasterdata();
      });
  }

}
