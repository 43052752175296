<template>
  <div class="item-tab">
    <data-table5
      ref="datatable"
      :msgEmptyData="$t('common.datatable.no_data')"
      :get-data="getData"
      class="table-landing"
      id="data-table-landing"
      :defaultSort="false"
      :limit="6"
    >
      <template slot-scope="props">
        <th
          class="w_20"
          data-sort-field="coin"
          v-if="isVisibleColumn('pair')"
          @click="props.echoclick"
        >
          <span>{{ $t("landing.pair") }}</span>
          <div class="group-sort-tabl3" v-html="props.innerHtmlSort"></div>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
     
        <th
          data-sort-field="current_price"
          class="col-last-price w_15"
          v-if="isVisibleColumn('current_price')"
          @click="props.echoclick"
        >
          <span>{{ $t("landing.last_price") }}</span>
          <div class="group-sort-tabl3" v-html="props.innerHtmlSort"></div>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
        <th
          data-sort-field="changed_percent"
          class="col-24h-changed w_13"
          v-if="isVisibleColumn('changed_percent')"
          @click="props.echoclick"
        >
          <span>{{ $t("landing.change") }}</span>
          <div class="group-sort-tabl3" v-html="props.innerHtmlSort"></div>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
        <th
          data-sort-field="max_price"
          class="col-max-price w_15"
          v-if="isVisibleColumn('max_price')"
          @click="props.echoclick"
        >
          <span>{{ $t("landing.high") }}</span>
          <div class="group-sort-tabl3" v-html="props.innerHtmlSort"></div>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
        <th
          data-sort-field="min_price"
          class="col-min-price w_13"
          v-if="isVisibleColumn('min_price')"
          @click="props.echoclick"
        >
          <span>{{ $t("landing.low") }}</span>
          <div class="group-sort-tabl3" v-html="props.innerHtmlSort"></div>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
        <th
          data-sort-field="volume"
          class="col-volume w_13"
          v-if="isVisibleColumn('volume')"
          @click="props.echoclick"
        >
          <span>{{ $t("landing.volume") }}</span>
          <div class="group-sort-tabl3" v-html="props.innerHtmlSort"></div>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
      </template>
      <template slot="body" slot-scope="props">
        <tr>
          <!-- <tr> -->
          <td
            class="text-left col-pair"
            @click="selectedCurrenyPair(props.item.currency, props.item.coin)"
            v-if="isVisibleColumn('pair')"
          >
            <span
              class="icon-star1"
              :class="
                isFavorite(props.item.coin, props.item.currency)
                  ? 'icon-star2'
                  : 'star_gray'
              "
              @click.stop="
                isFavorite(props.item.coin, props.item.currency)
                  ? removeFavorite(props.item.coin, props.item.currency)
                  : insertFavorite(props.item.coin, props.item.currency)
              "
            ></span>
            <span class="coin">{{ props.item.coin | currencyName }}</span
            ><span class="currency">
              / {{ props.item.currency | currencyName }}</span
            >
          </td>
         
          <td
            class="col-last-price"
            @click="selectedCurrenyPair(props.item.currency, props.item.coin)"
            :title="
              props.item.current_price
                | formatCurrencyAmount(props.item.currency, '0')
            "
            v-if="isVisibleColumn('current_price')"
          >
            <span
              class="change-node"
              :class="calculateLastPriceStatus(props.item)"
            >
              {{
                props.item.current_price
                  | formatCurrencyAmount(props.item.currency, "0")
              }}
            </span>
            <span
              class="is-muted"
              v-if="props.item.currency !== 'usd' && props.item.usdPrice > 0"
            >
              / ${{ props.item.usdPrice | formatCurrencyAmount("usd", "0") }}
            </span>
          </td>
          <td
            class="change-node col-24h-changed"
            @click="selectedCurrenyPair(props.item.currency, props.item.coin)"
            :class="{
              grown: props.item.changed_percent > 0,
              down: props.item.changed_percent < 0,
              black: props.item.changed_percent == 0,
            }"
            :title="props.item.changed_percent | changedPercent"
            v-if="isVisibleColumn('changed_percent')"
          >
            {{ props.item.changed_percent | changedPercent }}
          </td>
          <td
            class="col-max-price"
            @click="selectedCurrenyPair(props.item.currency, props.item.coin)"
            :title="
              props.item.max_price
                | formatCurrencyAmount(props.item.currency, '0')
            "
            v-if="isVisibleColumn('max_price')"
          >
            {{
              props.item.max_price
                | formatCurrencyAmount(props.item.currency, "0")
            }}
          </td>
          <td
            class="col-min-price"
            @click="selectedCurrenyPair(props.item.currency, props.item.coin)"
            :title="
              props.item.min_price
                | formatCurrencyAmount(props.item.currency, '0')
            "
            v-if="isVisibleColumn('min_price')"
          >
            {{
              props.item.min_price
                | formatCurrencyAmount(props.item.currency, "0")
            }}
          </td>
          <td
            class="col-volume"
            @click="selectedCurrenyPair(props.item.currency, props.item.coin)"
            :title="
              props.item.volume | formatCurrencyAmount(props.item.currency, '0')
            "
            v-if="isVisibleColumn('volume')"
          >
            {{
              props.item.volume | formatCurrencyAmount(props.item.currency, "0")
            }}
          </td>
        </tr>
      </template>
    </data-table5>
  </div>
</template>

<script>

import COMMON_CONST from "@/common/Const";
import BigNumber from "bignumber.js";
const ALL_COLUMNS = [
  "pair",
  "coin",
  "current_price",
  "changed_percent",
  "max_price",
  "min_price",
  "volume",
];

export default {
  components: {},
  props: {
    priceScope: { type: [Object, Array] },
    columns: {
      type: [Array],
      default: () => {
        return [];
      },
    },
    favorites: { type: [Array] },
    marketSelected: { type: String },
    coinNames: { type: [Object, Array] },
  },
  data() {
    return {
      pricesSorted: {},
      // noData: window.i18n.t('common.datatable.no_data'),
    };
  },
  watch: {
    priceScope: {
      handler() {
        this.pricesSorted = this.priceScope;
        if (this.$refs.datatable) {
          this.$refs.datatable.refresh();
        }
      },
      immediate: true,
    },
    pricesSorted() {
      if (this.$refs.datatable) {
        this.$refs.datatable.refresh();
      }
    },
  },
  computed: {
    visibleColumns() {
      if (window._.isEmpty(this.columns)) {
        return ALL_COLUMNS;
      }
      return this.columns;
    },
  },
  methods: {
    calculateLastPriceStatus(item) {
      let compareResult = new BigNumber(item.current_price || 0).comparedTo(
        item.previous_price || 0
      );

      return {
        grown: compareResult > 0,
        down: compareResult < 0,
        black: compareResult == 0,
      };
    },

    isVisibleColumn(column) {
      return window._.indexOf(this.visibleColumns, column) > -1;
    },

    isFavorite(coin, currency) {
      const coinPair = this.getCoinPair(coin, currency);
      let result = window._.filter(this.favorites, function (value) {
        return value.coin_pair == coinPair;
      });
      return result.length > 0;
    },

    insertFavorite(coin, currency) {
      const coinPair = this.getCoinPair(coin, currency);
      FavoritesManager.addFavorite({ coin_pair: coinPair }).then((data) => {
        this.favorites.push(data);
      });
    },

    removeFavorite(coin, currency) {
      const coinPair = this.getCoinPair(coin, currency);
      let favoriteObject = window._.find(this.favorites, [
        "coin_pair",
        coinPair,
      ]);

      FavoritesManager.removeFavorite(favoriteObject).then((res) => {
        this.favorites.splice(this.favorites.indexOf(favoriteObject), 1);
        // In the favorites tab, table remove pair which isn't favorited.
        if (this.marketSelected === COMMON_CONST.FAVORITES_TAB) {
          this.pricesSorted = window._.filter(this.pricesSorted, (item) => {
            const pair = this.getCoinPair(item.coin, item.currency);
            return pair !== coinPair;
          });
        }
      });
    },

    getCoinPair(coin, currency) {
      return `${coin}/${currency}`;
    },

    selectedCurrenyPair(currency, coin) {
      const pair = { currency: currency, coin: coin };

      const newurl = `/spot-exchange/basic?currency=${pair.currency}&coin=${pair.coin}`;

      this.$broadcast("selectedPairMarket", pair);

      window.history.pushState({ path: newurl }, "", newurl);

      this.$router.go(newurl);
    },

    getData(params) {
      return new Promise((resolve) => {
        let pricesSorted = this.pricesSorted;
        if (params.sort && params.sort_type) {
          pricesSorted = _.orderBy(
            pricesSorted,
            (item) => {
              if (isNaN(item[params.sort])) {
                if (params.sort !== "full_name") {
                  return item[params.sort];
                } else {
                  return window.i18n.t("currency." + item.coin + ".fullname");
                }
              }
              return new BigNumber(`${item[params.sort]}`).toNumber();
            },
            [params.sort_type]
          );
        }
        if (this.marketSelected === COMMON_CONST.FAVORITES_TAB) {
          return resolve(
            _.chain(pricesSorted)
              .filter((item) => this.isFavorite(item.coin, item.currency))
              .value()
          );
        }
        return resolve(
          _.chain(pricesSorted)
            .filter((item) => item.currency === this.marketSelected)
            .value()
        );
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
.w_10 {
  width: 10%;
}
.w_13 {
  width: 13%;
}
.w_15 {
  width: 15%;
}
.w_17 {
  width: 17%;
}
.w_20 {
  width: 20%;
}
.item-tab {
  display: block;
  width: 100%;
  min-width: $min-width-table;
  .grown {
    color: $color-jungle-green !important;
  }
  .down {
    color: $color-denim !important;
  }
  .table-landing {
    table {      
      .icon-star1 {
        display: block;
        float: left;
        width: 15px;
        font-size: $font-medium;
        margin-right: 20px;
        margin-top: 5px;
        height: 20px;
        float: left;
        color: $color-grey;
        cursor: pointer;
      }
      .icon-star2 {
        color: $color-corn-pale;
        cursor: pointer;
      }

      th {
        padding: 10px 10px 10px 0;
        font-family: $font-inter-regular;
        font-size: $font-root;
        background: var(--color-tab-data-table);
        height: 63px;
        color: $color-gray-dark;
        text-align: left;

        &:first-child {
          border-radius: 8px 0 0 8px;
          padding-left: 24px;
        }

        &:last-child {
          border-radius: 0px 8px 8px 0;
        }
      }

     

      td {
        text-align: left;
        font-family: $font-inter-regular;
        padding: 15px 0px;
        color: var(--text-primary-color);
        font-size: 18px;

        &:first-child {
          padding-left: 24px;
        }

        &:last-child {
          text-align: left;
        }

        .icon-no-data{
          padding-top: 50px;
        }
      }
      
    }
  }
  .col-pair {
    font-family: $font-inter-regular;
    font-size: $font-big;
    .coin {
      color: var(--text-primary-color);
    }
    .currency {
      color: var(--text-primary-color);
    }
  }

  .is-muted {
    color: var(--text-primary-color);
  }
}
</style>

<style lang="scss" scoped>
  @import "@/assets/sass/variables";

  .table-landing > table tr {
    padding: 0 25px;
    cursor: pointer;
  }

  .table-landing > table td {
    padding: 10px 10px 10px 0;
    color: $color-grey-dark;
  }

  .table-landing > table td:last-child {
    padding-right: 25px;
  }

  .table-landing > table tr td:first-child {
    padding-left: 20px;
  }

  .table-landing > table th:first-child {
    padding-left: 60px;
  }
</style>