<template>
  <div class="tab_entry" id="my-request" v-if="onTabEntry==='my-request'">
    <!--            <div class="group_search_save clearfix">-->
    <!--              <label class="switch">-->
    <!--                {{$t('mam.show_pending_only') }}-->
    <!--                <input type="checkbox" @click="isShowPendingOnly = !isShowPendingOnly">-->
    <!--                    <span>-->
    <!--                  <i></i>-->
    <!--                  </span>-->
    <!--              </label>-->
    <!--            </div>-->
    <div class="data-table">
      <data-table4 :getData="getMyEntryRequest" :limit="5" class="datatable_contracts"
                   :msgEmptyData="$t('common.datatable.no_data')"
                   ref="myRequestList">
        <template slot-scope="props">
          <th class="w_25" data-sort-field="team_id">
            {{$t('margin.entry.team_id')}}
          </th>
          <th class="w_25" data-sort-field="name">
            {{$t('margin.entry.team_name') | upperFirst}}
          </th>
          <th class="w_35" data-sort-field="created_at">
            {{$t('mam.submited_at')}}
          </th>
          <th class="w_20" data-sort-field="status">
            {{$t('mam.status')}}
          </th>
          <th class="w_20" data-sort-field="note">
            {{$t('mam.note')}}
          </th>
          <th></th>
        </template>
        <template slot="body" slot-scope="props">
          <tr>
            <td>
              {{props.item.team_id}}
            </td>
            <td>
              <div class="item_name_setting font14">
                <span class="txt_email_setting font14">{{ props.item.name }}</span>
                <span class="tooltip_name_setting font14">{{ props.item.name }}</span>
              </div>
            </td>
            <td>{{props.item.created_at | convertToLocalTime}}</td>
            <td>{{ Mam.getRequestStatus(props.item) }}</td>
            <td>
              <div class="item_name_setting font14">
                <span class="txt_email_setting font14">{{props.item.note}}</span>
                <span class="tooltip_name_setting font14">{{props.item.note}}</span>
              </div>
            </td>
            <td>
              <div class="as_rv_funds">
                <a @click="confirmCancelRequest(props.item.id)"
                   class="btn btn-detail" v-if="props.item.status === 'submitted'">{{ $t("mam.cancel") }}
                </a>
              </div>
            </td>
          </tr>
        </template>
      </data-table4>
    </div>
    <modal id="confirmCancelModal" name="confirmCancelModal" width="560">
      <template slot="body">
        <div class="fo_light_blue">
          <span class="icon-info2 icon-help2"></span>
          <h4 class="tit_modal_confir"> {{$t('mam.confirm.cancel')}}</h4>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
  import rf from '@/request/RequestFactory'
  import Mam from '@/pages/mam/common/mam.js';
  import Modal from '@/components/shared_components/common/Modal.vue';


  export default {
    name: "MyRequestEntryList",
    components:{
      Modal,
    },
    props: {
      onTabEntry: {
        type: String
      },
    },
    data: function () {
      return {
        Mam,
        pendingRequest: [],
      }
    },
    methods: {
      getMyEntryRequest(params) {
        return rf.getRequest("EntryRequest").getEntryMyRequestList(params);
      },
      cancelRequest(id) {
        const params = {
          status: 'cancel',
          entry_request: id,
        };
        rf.getRequest("EntryRequest").changeStatusRequest(params).then((res) => {
          if (res && res.data) {
            this.showSuccess(this.$t('entry.msg.cancel.success'));
          }
          this.$refs.myRequestList.refresh();
        }).catch(error => {
          if (error && error.response && error.response.data) {
            Message.error(this.$t('message.response.request_invalid'), {}, {position: 'bottom_left'});
          }
        }).finally(()=>{
          window.CommonModal.hide('confirmCancelModal');
        })
      },
      confirmCancelRequest(id) {
        window.CommonModal.show('confirmCancelModal', {
          buttons: [
            {
              label: this.$t('common.action.no'),
              class: 'btn btn-cancel btn-space btn-secondary',
              callback: () => {
                window.CommonModal.hide('confirmCancelModal');
              }
            },
            {
              label: this.$t('common.action.yes'),
              class: 'btn btn-confirm btn-space btn-secondary',
              callback: () => {
                this.cancelRequest(id);
              },
            }
          ]
        });
      },
      showSuccess(message) {
        Message.success(message, {}, {position: 'bottom_left'});
      },
    },
    created() {
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/sass/variables";

  #my-request {
    .tableContainer {
      overflow-y: inherit;

      td {
        overflow: initial;
      }
    }

    .item_name_setting {
      display: inline-block;
      float: left;
      position: relative;

      .txt_email_setting {
        display: block;
        max-width: 250px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &:hover {
        .tooltip_name_setting {
          transition: 0.5s;
          display: inline-block;
        }
      }

      .tooltip_name_setting {
        position: absolute;
        top: 0px;
        line-height: 20px;
        padding: 5px 20px;
        left: 100%;
        background-color: $color_white;
        word-break: break-all;
        white-space: pre-wrap;
        width: 300px;
        z-index: 10;
        font-size: $font_root;
        font-weight: 500;
        transition: 0.5s;
        display: none;
        box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);

        &:after {
          right: 100%;
          top: 50%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: rgba(136, 183, 213, 0);
          border-right-color: $color_white;
          border-width: 5px;
          margin-top: -5px;
        }
      }
    }
  }
</style>
<style lang="scss">
  @import "@/assets/sass/variables";
  #confirmCancelModal {
    .fo_light_blue {
      text-align: center;
    }

    .icon-help2, .icon-email3, .icon-money {
      font-size: 70px;
      color: $color-jungle-green;
      margin-top: 0px;
    }

    .modal-footer {
      table {
        background-color: inherit !important;

        td {
          &:first-child {
            text-align: right;
          }

          &:last-child {
            display: flex;
          }
        }
      }

      button {
        width: 30%;
        box-shadow: 0 1px 0 rgba(0, 0, 0, .05);
        border: 1px solid $color-jungle-green;
        padding: 10px 10px;
        font-size: $font-root;
        line-height: 14px;
        height: auto;
        border-radius: 3px;
        min-width: 100px;
        margin: 5px;
        font-weight: 700;
        text-transform: uppercase;
        background-color: $color-jungle-green;
        color: $color_white;
        -webkit-transition: 0.5s;
        transition: 0.5s;

        &:hover, &:active, &:focus {
          background-color: $color-blue-custom;
          border-color: $color-blue-custom;
          -webkit-transition: 0.5s;
          transition: 0.5s;
        }
      }
    }
  }
</style>