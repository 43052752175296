<template>
  <div class = "row-airdrop">
    <span class="box-title title-medium-small box-title-secord">{{ $t('funds.balances.airdrop_balance') }}</span>
    <span class="estimate-value-airdrop fl-right">{{$t('funds.balances.estimated_airdrop_value')}}<strong> {{estimatedAmal}} %</strong></span>
  
    <div class="table-container table-history">
      <alert-with-button-modal/>
      <message-with-icon/>
      <data-table :getData="loadData" ref="datatable" :msgEmptyData="noFunds" :limit="7" @DataTable:finish="onDatatableFinished">
        <th data-sort-field="coin" class="coin_balances" @click="FixSortIE = !FixSortIE">
          {{$t('funds.balances.coin')}}
          <span v-if="FixSortIE === true" class="iconFixSorIE icon-arrow5"></span>
          <span v-if="FixSortIE === false" class="iconFixSorIE icon-arrow6"></span>
        </th>
        <th v-if="isSinglePage" @click="FixSortIE = !FixSortIE">
          {{$t('funds.balances.name')}}
        </th>
        <th class="text-center after_none min_w100">{{$t('funds.balances.transfer')}}</th>
        <th data-sort-field="totalAirdropBalance" @click="FixSortIE = !FixSortIE">
          {{$t('funds.balances.total_airdrop_balance')}}
          <span v-if="FixSortIE === true" class="iconFixSorIE icon-arrow5"></span>
          <span v-if="FixSortIE === false" class="iconFixSorIE icon-arrow6"></span>
        </th>
        <th data-sort-field="availableAirdropBalance" @click="FixSortIE = !FixSortIE">
          {{$t('funds.balances.available_airdrop_balance')}}
          <span v-if="FixSortIE === true" class="iconFixSorIE icon-arrow5"></span>
          <span v-if="FixSortIE === false" class="iconFixSorIE icon-arrow6"></span>
        </th>
        <th data-sort-field="lockBalance" @click="FixSortIE = !FixSortIE">
          {{$t('funds.balances.lock_balance')}}
          <span v-if="FixSortIE === true" class="iconFixSorIE icon-arrow5"></span>
          <span v-if="FixSortIE === false" class="iconFixSorIE icon-arrow6"></span></th>
        <th data-sort-field="unlockDate" @click="FixSortIE = !FixSortIE">
          {{$t('funds.balances.unlock_date')}}
          <span v-if="FixSortIE === true" class="iconFixSorIE icon-arrow5"></span>
          <span v-if="FixSortIE === false" class="iconFixSorIE icon-arrow6"></span></th>
          <template slot="body" slot-scope="props">
            <tr v-if ="props.item.coin == 'btc' || props.item.coin == 'eth' || props.item.coin == 'amal' " >
              <td class="coin_balances"><img class="icon-coin" :src="`/images/color_coins/${props.item.coin}.png`" alt="">{{ props.item.coin | uppercase }}</td>
              <td v-if="isSinglePage && props.item.coin == 'amal'">Dividend Balance</td>
              <td v-if="isSinglePage && props.item.coin != 'amal'">{{ props.item.name }}</td>
              <td class="tranfer-cell" @click="activeRow(props.item)"  v-bind:class="{acvite_transfer : props.item.isActiveRow}">
                <span class="glyphicon icon_balances btn-transfer" :class="props.item.isActiveRow ? 'icon-transfer active-icon' : 'icon-transfer'"></span>
              </td>
              <td>{{ props.item.airdropBalance | toNumber | formatCurrencyAmount(props.item.coin, '0') }}</td>
              <td>{{ props.item.availableAirdropBalance | toNumber | formatCurrencyAmount(props.item.coin, '0') }}</td>
              <td>{{ props.item.airdropBalance - props.item.availableAirdropBalance | toNumber | formatCurrencyAmount(props.item.coin, '0') }}</td>
              <td v-if="props.item.unlockDate">{{ props.item.unlockDate }}</td>
              <td v-else>---</td>
            </tr>
            <template v-if="props.item.isActiveRow">
            <tr>
              <td colspan="11" class="table_drop_balances">
                <div class="close_tbale" @click="activeRow(props.item)"><i class="icon-close"></i></div>
                <div class="content_transfer_form">
                  <form class="transfer_form">

                    <!-- From / Amount -->
                    <div class="group_transfer_form" v-if="props.item.isTransferFromMain">
                      <label class="txt">{{$t('funds.balances.from')}}</label>
                      <input type="text" class="input-form w_155" disabled="disabled" :placeholder="$t('funds.balances.airdrop_balance')"/>
                    </div>
                    <div class="group_transfer_form" v-if="!props.item.isTransferFromMain">
                      <label class="txt">{{$t('funds.balances.from')}}</label>
                      <input type="text" class="input-form w_155" disabled="disabled" :placeholder="$t('funds.balances.main_account')"/>
                    </div>

                    <!-- Swap inputs button -->
                      <div class="group_transfer_form">
                        <label class="txt"></label>
                        <button class="btn btn-transfer-form" @click="swapBalance($event, props.item)">
                          <i class="icon-transfer"></i>
                        </button>
                      </div>
                    
                    <!-- To  -->
                    
                    <div class="group_transfer_form" v-if="props.item.isTransferFromMain">
                      <label class="txt">{{$t('funds.balances.to')}}</label>
                      <input type="text" class="input-form w_155" disabled="disabled" :placeholder="$t('funds.balances.main_account')"/>
                    </div>
                    <div class="group_transfer_form" v-if="!props.item.isTransferFromMain">
                      <label class="txt">{{$t('funds.balances.to')}}</label>
                      <input type="text" class="input-form w_155" disabled="disabled" :placeholder="$t('funds.balances.airdrop_balance')"/>
                    </div>
                    <!-- Amount -->
                    <div class="group_transfer_form">
                      <label class="txt">{{$t('order.trade_history.amount')}}</label>
                      <div class="input-amount">
                        <currency-input
                          :precision="8"
                          :class="{'error': errors.has('amount' + props.item.coin)}"
                          class="input-form w_220"
                          :value="props.item.amount"
                          v-model="props.item.amount"
                          @blur="() => handleChangeAmount(props.item)"
                        />
                        <span class="name_amount">{{ props.item.coin }}</span>
                      </div>
                      <div v-show="errors.has('amount' + props.item.coin)" class="w_220 invalid-feedback">{{ errors.first('amount' + props.item.coin) }}</div>

                    </div>

                    <!-- Transfer button -->
                    <div class="group_transfer_form">
                      <label class="txt"></label>
                      <button class="btn btn-send-transfer-form" @click.prevent="transferBalance($event, props.item)" :disabled="isSubmittingTransfer">{{$t('funds.balances.tranfer')}}</button>
                    </div>

                  </form>
                </div>
              </td>
            </tr>
          </template>
          <template >
            <tr >
              <td class="coin_balances"><img class="icon-coin" :src="`/images/color_coins/${props.item.coin}.png`" alt="">{{ props.item.coin | uppercase }}</td>
              <td v-if="isSinglePage">Dividend Perpetual Balance</td>
              <td class="tranfer-cell" v-bind:class="{acvite_transfer : props.item.isActiveRow}">
                <span class="glyphicon icon_balances btn-transfer disabled" :class="props.item.isActiveRow ? 'icon-transfer active-icon' : 'icon-transfer'"></span>
              </td>
              <td>{{ props.item.airdropBalanceBonus | toNumber | formatCurrencyAmount(props.item.coin, '0') }}</td>
              <td>{{ props.item.availableAirdropBalanceBonus | toNumber | formatCurrencyAmount(props.item.coin, '0') }}</td>
              <td>{{ props.item.airdropBalanceBonus - props.item.availableAirdropBalanceBonus | toNumber | formatCurrencyAmount(props.item.coin, '0') }}</td>
              <td v-if="props.item.unlockDate">{{ props.item.unlockDate }}</td>
              <td v-else>---</td>
            </tr>
          </template>

        </template>
          
      </data-table>
    </div>
  </div>
</template>
<script>
  import rf from '@/request/RequestFactory'
  import BigNumber from 'bignumber.js';
  import Const from '@/common/Const';
  import AlertWithButtonModal from '@/components/shared_components/common/AlertWithButtonModal';
  import MessageWithIcon from '@/components/shared_components/common/MessageWithIcon';
  import { mapState } from 'vuex';
  
  const MIN_BTC = 0.001;
  const MIN_AMOUNT_CRYPTO = Math.pow(10, -8); // 10^-8
  const TRANSFER_TYPE_MAIN_BALANCE = 'main';
  const TRANSFER_TYPE_AIRDROP_BALANCE = 'airdrop';
  
  // Prevent convert 0.00000001 to 1e-8
  BigNumber.config({ EXPONENTIAL_AT: 15 });
  
  export default {
     data() {
      return {
        FixSortIE: null,
        isShowNotification: false,
        search: '',
        limit: undefined,
        accountBalances: undefined,
        prices: undefined,
        balances: [],
        totalPendingWithdraws: [],
        selectedCoin: "",
        tradeCoin: [],
        displayPairCoin: false,
        tradeUsd: [],
        isAuthenticated: window.isAuthenticated,
        coinSetting: {},
        coinsMasterData: {},
        noFunds: window.i18n.t('funds.balances.no_data'),
        isSubmittingTransfer: false,
        estimatedAmal: null,
        totalAMAL: Const.TOTAL_AMAL,
      }
    },
    components: {
      AlertWithButtonModal,
      MessageWithIcon,
    },
    props: {
      isSinglePage: {
        type: Boolean,
        default: true
      },
    },
    watch: {
      accountBalances(newAccBalances) {
        // const currencies = Object.keys(newAccBalances);
        // this.getTotalPendingWithdraw(currencies, this.refresh);
      },
      prices(newPrices) {
        this.refresh();
      },
      totalPendingWithdraws(newVal) {
        this.refresh();
      },
      search(newValue) {
        this.$refs.datatable.refresh();
      },
      isTransferFromMain() {
        this.$refs.datatable.refresh();
      }
    },
    computed: {
      ...mapState({
        masterData: state => state.masterdata,
      }),
      totalBtcBalance() {
        return this.sumBy('btcValue');
      },
      totalUsdBalance() { 
        return this.sumBy('usdValue');
      },
      totalOrder() {
        return this.sumBy('orderBtcValue');
      },
    },
    methods: {
      onDatatableFinished() {
      },
      convertSmallBalance() {
        return this.$router.push({ path: 'convert-balances' });
        // return this.$route.name === 'Convert Small Balances'
      },
      resetValidateRow(item) {
        item.isTransferFromMain = true;
        item.amount = 0;
        this.errors.remove('amount' + item.coin);
      },
      activeRow(item) {
        item.isActiveRow = !item.isActiveRow;
        this.resetValidateRow(item);
        this.$forceUpdate();
      },
      HiddenAcriveRow (item) {
        this.$set(item, 'isActiveRow', false)
      },
      activeRowMam(item) {
        item.isActiveRowMam = !item.isActiveRowMam;
        this.$forceUpdate();
      },
      addPricesInBtc () {
        this.prices['btc_btc'] = { price: 1 };
        for (const fiat of Const.FIAT_LIKE_LIST) {
          const fiatPrice = this.prices[`${fiat}_btc`];
          const price = fiatPrice ? new BigNumber('1').div(fiatPrice.price).toString() : '0';
          this.prices[`btc_${fiat}`] = { price }
        }
      },
      isShowcoin(coin) {
        return coin === this.selectedCoin;
      },
      showCoin(coin) {
        this.selectedCoin = coin;
        this.tradeCoin = this.currencyCoins.filter((item) => {
            return item.coin.indexOf(coin) > -1;
          });
        this.tradeUsd = this.currencyCoins.filter((item) => {
            return item.currency === 'usd';
          });
      },
      getMinBtc() {
        return MIN_BTC;
      },
      showNotification() {
        this.isShowNotification = !this.isShowNotification;
      },
      hideNotification() {
        this.isShowNotification = false;
      },
      estimatedCoin(value) {
        let estimatedCoin = new BigNumber(value);
        return estimatedCoin.div(this.totalAMAL).mul(100).toFixed(4).toString(); 
      },
      loadData (params) {
        return new Promise((resolve) => {
          let data = this.balances;
          data = _.filter(data, (item) => `${item.coin}${item.name}`.toLowerCase().includes(this.search.toLowerCase()));
          if (params.sort && params.sort_type) {
            data = _.orderBy(data, (item) => {
              if (isNaN(item[params.sort])) {
                return item[params.sort];
              }
              return new BigNumber(`${item[params.sort]}`).toNumber();
            }, [params.sort_type]);
          }
          return resolve({ data });
        });
      },
      refresh() {
        const isNoData = window._.isEmpty(this.accountBalances) || window._.isEmpty(this.prices);
        if (isNoData) {
          return;
        }
        this.transferData();
        this.$refs && this.$refs.datatable && this.$refs.datatable.refresh();
      },
      transferData() {
        if (this.balances && this.balances.length > 0) {
          this.mergeTransferData();
          return true;
        }
        let data = [];
        let coinList = this.accountBalances[Const.TYPE_MAIN_BALANCE];
        let mainBalanceData = this.accountBalances[Const.TYPE_MAIN_BALANCE];
        let marginBalanceData = this.accountBalances[Const.TYPE_MARGIN_BALANCE];
        let exchangeBalanceData = this.accountBalances[Const.TYPE_EXCHANGE_BALANCE];
        let mamBalanceData = this.accountBalances[Const.TYPE_MAM_BALANCE];
        let airdropBalanceData = this.accountBalances[Const.TYPE_AIRDROP_BALANCE];
      
        for (let coin in coinList) {
          const item = {};
          item.coin = coin;
          item.name = this.getCoinName(coin);
          if (![Const.AML].includes(item.coin)) {
            continue;
          }
          item.balance = (mainBalanceData && mainBalanceData[coin] && mainBalanceData[coin].balance) || 0;
          item.availableBalance = (mainBalanceData && mainBalanceData[coin] && mainBalanceData[coin].available_balance) || 0;
          item.airdropBalance = (airdropBalanceData && airdropBalanceData[coin] && airdropBalanceData[coin].balance) || 0;
          item.airdropBalanceBonus = (airdropBalanceData && airdropBalanceData[coin] && airdropBalanceData[coin].balance_bonus) || 0;
          item.availableAirdropBalance = (airdropBalanceData && airdropBalanceData[coin] && airdropBalanceData[coin].available_balance) || 0;
          item.availableAirdropBalanceBonus = (airdropBalanceData && airdropBalanceData[coin] && airdropBalanceData[coin].available_balance_bonus) || 0;
          item.exchangeBalance = (exchangeBalanceData && exchangeBalanceData[coin] && exchangeBalanceData[coin].balance) || 0;
          item.marginBalance = (marginBalanceData && marginBalanceData[coin] && marginBalanceData[coin].balance) || 0;
          item.mamBalance = (mamBalanceData && mamBalanceData[coin] && mamBalanceData[coin].balance) || 0;
          item.totalBalance = new BigNumber(item.balance).add(item.exchangeBalance);
          item.unlockDate = (airdropBalanceData && airdropBalanceData[coin] && airdropBalanceData[coin].last_unlock_date) || '';
          let coinType = item.coin || '';          
          let total_dividend_balances = new BigNumber(item.airdropBalance).add(item.airdropBalanceBonus);
          if (coinType == Const.AML) {
            this.estimatedAmal = this.estimatedCoin(total_dividend_balances);
          }
          item.totalBalance = item.totalBalance.toString();
          if ([Const.AML].includes(coinType)) item.enableAirdropBalance = true;
          else item.enableAirdropBalance = false;
          item.amount = 0;
          item.order = this.calculateInOrder(item);
          item.orderBtcValue = this.getOrderBtcBalance(item);
          item.btcValue = this.getBalance(coin, 'btc');
          let usdValue = this.getBalance(coin, 'usd');
          // if do not have price of usd_$coin pair, transform to btc_$coin pair then transform to usd_btc pair.
          if (!usdValue) {
            const pair = 'usd_btc';
            const price = this.prices[pair] ? this.prices[pair].price : '0';
            usdValue = new BigNumber(item.btcValue).mul(price).toString();
          }
          item.usdValue = usdValue;
          item.isTransferFromMain = true;
          data.push(item);
        }
        this.balances = window._.sortBy(data, 'name');
      },
      mergeTransferData() {
        let data = [];
        let coinList = this.accountBalances[Const.TYPE_MAIN_BALANCE];
        let mainBalanceData = this.accountBalances[Const.TYPE_MAIN_BALANCE];
        let marginBalanceData = this.accountBalances[Const.TYPE_MARGIN_BALANCE];
        let exchangeBalanceData = this.accountBalances[Const.TYPE_EXCHANGE_BALANCE];
        let mamBalanceData = this.accountBalances[Const.TYPE_MAM_BALANCE];
        let airdropBalanceData = this.accountBalances[Const.TYPE_AIRDROP_BALANCE];
        
        for (let coin in coinList) {
          const item = {};
          item.coin = coin;
          item.balance = (mainBalanceData && mainBalanceData[coin] && mainBalanceData[coin].balance) || 0;
          item.availableBalance = (mainBalanceData && mainBalanceData[coin] && mainBalanceData[coin].available_balance) || 0;
          item.airdropBalance = (airdropBalanceData && airdropBalanceData[coin] && airdropBalanceData[coin].balance) || 0;
          item.airdropBalanceBonus = (airdropBalanceData && airdropBalanceData[coin] && airdropBalanceData[coin].balance_bonus) || 0;
          item.availableAirdropBalance = (airdropBalanceData && airdropBalanceData[coin] && airdropBalanceData[coin].available_balance) || 0;
          item.availableAirdropBalanceBonus = (airdropBalanceData && airdropBalanceData[coin] && airdropBalanceData[coin].available_balance_bonus) || 0;
          item.exchangeBalance = (exchangeBalanceData && exchangeBalanceData[coin] && exchangeBalanceData[coin].balance) || 0;
          item.marginBalance = (marginBalanceData && marginBalanceData[coin] && marginBalanceData[coin].balance) || 0;
          item.mamBalance = (mamBalanceData && mamBalanceData[coin] && mamBalanceData[coin].balance) || 0;
          item.unlockDate = (airdropBalanceData && airdropBalanceData[coin] && airdropBalanceData[coin].last_unlock_date) || '';
          item.totalBalance = new BigNumber(item.balance);
          let coinType = item.coin || '';                              
          if (coinType == Const.AML) {
            item.totalBalance = new BigNumber(item.totalBalance).add(item.airdropBalance);
            let total_dividend_balances = new BigNumber(item.airdropBalance).add(item.airdropBalanceBonus);
            this.estimatedAmal = this.estimatedCoin(total_dividend_balances);
          }
          item.totalBalance = item.totalBalance.toString();
          data.push(item);
        }
        this.updateTableBalanceAfterTransfer(data);
        this.balances = window._.sortBy(this.balances, 'name');
      },
      updateTableBalanceAfterTransfer(data) {
          var _this = this;
          var newData = [];
          window._.each(data, (item, index) => {
            let coinRecord = window._.find(_this.balances, itemBalance => itemBalance.coin == item.coin);
            if (coinRecord) {
              let amount = new BigNumber(coinRecord.amount || '0');
              newData.push({
                ...coinRecord,
                balance: item.balance,
                availableBalance: item.availableBalance,
                airdropBalance: item.airdropBalance,
                availableAirdropBalance: item.availableAirdropBalance,
                airdropBalanceBonus: item.airdropBalanceBonus,
                availableAirdropBalanceBonus: item.availableAirdropBalanceBonus,
                totalBalance: item.totalBalance,
                amount: amount.toString(),
                unlockDate: item.unlockDate,
                isActiveRow: coinRecord.isActiveRow,
              });
            }
          });
          this.balances = newData;
      },
      getCoinName(coin) {
        let coinName = '';
        if (this.coinsMasterData && this.coinsMasterData[coin]) {
          coinName = this.coinsMasterData[coin].name;
        }
        return coinName;
      },
      getBalance(coin, currency) {
        if (!this.accountBalances[coin]) return 0;
        if (coin === currency) {
          return `${this.accountBalances[Const.TYPE_MAIN_BALANCE][coin].balance}`;
        }
        const pair = `${currency}_${coin}`;
        if (!this.prices[pair]) {
          return 0;
        }
        return new BigNumber(`${this.accountBalances[Const.TYPE_MAIN_BALANCE][coin].balance}`).mul(this.prices[pair].price).toString();
      },
      getOrderBtcBalance(item) {
        if (!this.prices[`btc_${item.coin}`]) {
          return 0;
        }
        return new BigNumber(item.order).mul(this.prices[`btc_${item.coin}`].price).toString();
      },
      calculateInOrder(item) {
        // const result =  window._.find(this.totalPendingWithdraws, (row) => { return row && row.currency === item.coin});
        // const totalPendingWithdraw = result ? result.total : '0';
        // return new BigNumber(item.totalBalance).sub(item.availableBalance).sub(totalPendingWithdraw).toString();
        return new BigNumber(`${item.totalBalance}`).sub(item.availableBalance).toString();
      },
      sumBy(attr) {
        let total = new BigNumber(0);
        for (let item of this.balances) {
          total = total.add(new BigNumber(item[attr]));
        }
        return total.toString();
      },
      onBalanceUpdated(newAccountBalances) {
        this.accountBalances = window._.merge({}, this.accountBalances, newAccountBalances);
        this.refresh();
      },
      onPricesUpdated(newPrices) {
        this.prices = Object.assign({}, this.prices, newPrices);
        this.addPricesInBtc();
      },
      getSocketEventHandlers() {
        return {
          PricesUpdated: this.onPricesUpdated,
          BalanceUpdated: this.onBalanceUpdated
        }
      },
      getTotalPendingWithdraw(currencies, callback) {
        if (window._.isEmpty(currencies)) {
          return;
        }
        const isContainUsdCurrency = window._.includes(currencies, 'usd');
        if (!isContainUsdCurrency || currencies.length > 1) {
          rf.getRequest('TransactionRequest').getTotalPendingWithdraw().then((res) => {
            window._.each(res.data, item => {
              this.updateCurrencyPendingWithdraw(item.currency, item.total);
            });
            if (callback) {
              callback();
            }
          });
        }
        // usd currency
        if (isContainUsdCurrency) {
          rf.getRequest('TransactionRequest').getTotalUsdPendingWithdraw().then((res) => {
            if (!res.data.total) {
              return;
            }
            this.updateCurrencyPendingWithdraw('usd', res.data.total);
            if (callback) {
              callback();
            }
          });
        }
      },
      updateCurrencyPendingWithdraw(currency, totalPendingWithdraw) {
        const item = window._.find(this.totalPendingWithdraws, item => item.currency === currency);
        if (item) {
          item.total = totalPendingWithdraw || '0';
        } else {
          this.totalPendingWithdraws.push(item);
        }
      },
      validate(item) {
        this.errors.remove('amount' + item.coin);
        let amount = new BigNumber(item.amount || 0);
  
        if (amount.isZero()) {
          this.errors.add('amount' + item.coin, window.i18n.t('funds.balances.amount_is_positive'));
          return false;
        }
        if (amount.lt(MIN_AMOUNT_CRYPTO)) {
          this.errors.add('amount' + item.coin, window.i18n.t('funds.balances.min_amount_is_invalid'));
          return false;
        }
        const availableBalance = item.isTransferFromMain ? new BigNumber(item.availableAirdropBalance) : new BigNumber(item.availableBalance);
        if (availableBalance.lt(amount)) {
          if (item.isTransferFromMain) {
            this.errors.add('amount' + item.coin, window.i18n.t('funds.balances.amount_can_not_greater_than_available_dividend_balance'));
          } else {
            this.errors.add('amount' + item.coin, window.i18n.t('funds.balances.amount_can_not_greater_than_available_main_balance'));
          }
          return false;
        }
        return true;
      },
      onPricesUpdated(newPrices) {
        this.prices = Object.assign({}, this.prices, newPrices);
        this.addPricesInBtc();
      },
      handleChangeAmount(item) {
        // this.validate(item);
      },
      swapBalance(e, item) {
        e.preventDefault();
        item.isTransferFromMain = !item.isTransferFromMain;
      },
      transferBalance(e, item) {
        if (!this.validate(item)) {
          return false;
        }
        this.isSubmittingTransfer = true;
        let amount = item.amount || 0;
        // Build data
        let self = this;
        let data = {
          coin_value: amount,
          coin_type: item.coin,
          from: item.isTransferFromMain ? TRANSFER_TYPE_AIRDROP_BALANCE : TRANSFER_TYPE_MAIN_BALANCE,
          to: item.isTransferFromMain ? TRANSFER_TYPE_MAIN_BALANCE : TRANSFER_TYPE_AIRDROP_BALANCE,
        };
        rf.getRequest('UserRequest')
          .transferBalance(data)
          .then(response => {
            this.isSubmittingTransfer = false;
            if (!response.success || !response.data) {
              this.showErrorMessage(window.i18n.t('funds.balances.transfer_balance_fails'));
              return false;
            }
            this.showInfoMessage(window.i18n.t('funds.balances.transfer_balance_success'));
            // Get data balance to update datatable
            rf.getRequest('UserRequest').getBalance().then(res => {
              self.onBalanceUpdated(res.data);
            });
          })
          .catch(err => {
            this.isSubmittingTransfer = false;
          });
      },
      showErrorMessage(message, callBack = () => {}) {
        window.MessageWithIcon.error(message);
      },
      showInfoMessage(message, callBack = () => {}) {
        window.MessageWithIcon.success(message);
      },
    },
    created() {
      rf.getRequest('UserRequest').getTotalSupply().then(res => {
        this.totalAMAL = res;
      });
      if (!this.isAuthenticated) {
        return;
      }
      rf.getRequest('UserRequest').getCurrentUser().then(res => {
        let securityLevel = res.data.security_level;
        this.limit = window._.find(this.masterData.withdrawal_limits, (wl) => {
          return wl.security_level === securityLevel && wl.currency === 'btc';
        });
      });
      rf.getRequest('UserRequest').getBalance().then(res => {
        this.onBalanceUpdated(res.data);
      });
      rf.getRequest('PriceRequest').getPrices().then(res => {
        this.onPricesUpdated(res.data);
      });
      document.title = this.$t('menu.balances') + ` – ${this.tabTitle}`;
    },
    mounted() {
      this.currencyCoins = this.masterData.coin_settings;
      this.coinSetting = window._.keyBy(this.masterData.coins_confirmation, item => item.coin);
      this.coinsMasterData = window._.keyBy(this.masterData.coins, item => item.coin);
      this.coinsMasterData.usd = { name: window.i18n.t(`currency.usd.fullname`) }
    },
  }
</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables";
@import "@/assets/sass/common.scss";

span.disabled
{
  pointer-events: none;
  opacity: 0.5;
}
.min_w100 {
  min-width: 100px;
}
.table-history {
  padding-bottom: 15px;
}
.full-page-background {
  width: 100%;
  display: inline-block;
  padding-top: 60px;
}
.error {
  border: solid 1px $color-red-main !important;
}
.invalid-feedback {
}
#balance{
  &.is_no_single_page{
    .balance-container {
      margin: 0;
      .table-container thead th{
        height: 22px;
        border-bottom: 1px solid $color-gray;
        font-size: $font-mini;
      }
    }
  }
  table {
    tbody {
      tr {
        overflow-y: initial;
      }
    }
  }
}
.balance-container {
  display: inline-block;
  width: 100%;
  .row-airdrop {
    margin-top: 35px;
    margin-bottom: 15px;
    .estimate-value-airdrop{
      padding-top: 15px;
      font-family: Roboto-Regular;
      font-size: 13px;
      color:$color-grey-dark;
    }
  }
  .box-title {
    margin-bottom: $title-bottom-margin;
    &.box-title-secord{
      margin-top : 35px;
    }
  }
}
.balance-info {
  line-height: 25px;
  width: 180px;
  font-weight: 100;
  font-size: $font-small;
  padding-right: 5px;
  padding-left: 5px;
  position: absolute;
  background: $background-default;
  border: $border_thin;
  box-shadow: 0 2px 10px 0 $border-color-grey;
  border-top: none;
  z-index: 10;
  padding: 5px 10px;
}
label,
input {
  margin: 0;
  padding: 0;
  font-weight: normal;
  color: $color-grey-dark;
}
.sort_up{
  color: $color-corn-pale !important;
}
.sort_down{
  color: $color-corn-pale !important;
}
.es-toolbar {
  font-size: $font-root;
  margin-bottom: 20px;
  .icon {
    color: $color-grey-dusty;
  }
  .btn-small-icon {
    width: 150px;
    height: 32px;
    line-height: 31px;
    border: 1px solid $color-gray;
    cursor: pointer;
    color: $color-grey-dark;
    border-radius: 3px;
    .icon-help {
      font-size: $font-medium;
      margin-right: 5px;
      position: relative;
      top: 1px;
    }
  }
  .btn-checkbox {
    width: 70px;
    height: 32px;
    line-height: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $color-gray;
    cursor: pointer;
    margin-right: 10px;
    margin-left: 10px;
    border-radius: 3px;
    .checkbox-input{
      width: 12px;
      height: 12px;
      margin-right: 5px;
      position: relative;
      input{
        display: none;
      }
      label{
        cursor: pointer;
        position: absolute;
        width: 12px;
        height: 12px;
        top: 0;
        left: 0;
        border:1px solid $color-grey-dusty;
        border-radius: 1px;
        &:after{
          opacity: 0;
          content: '';
          position: absolute;
          width: 12px;
          height: 5px;
          background: transparent;
          top: 0px;
          left: 0px;
          border: 3px solid $color-grey-dusty;
          border-top: none;
          border-right: none;
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }
      }
      input[type=checkbox]:checked + label:after {
        opacity: 1;
      }
    }
  }
  .btn-convert-icon {
    cursor: pointer;
    border-radius: 3px;
    min-width: 150px;
    height: 32px;
    line-height: 31px;
    border: 1px solid $color-alto;
    cursor: pointer;
    color: $color-grey-dark;
    border-radius: 3px;
    margin-right: 10px;
    span {
      vertical-align: middle;
    }
    .icon-convert {
      font-size: $font-title-size-small;
      margin-right: 5px;
    }
    &:hover {
      color: $color-white;
      background-color: $color-blue-custom;
      border-color: $color-blue-custom;
      .icon {
        color: $color-white;
      }
    }
  }
  .input-search {
    width: 170px;
    height: 32px;
    border: 1px solid $color-gray;
    border-radius: 3px;
    background-color: $color-white;
    &:focus-within {
      border-radius: 3px;
      border: solid 1px $color-jungle-green;
    }
    input {
      background: $color-white;
      font-size: $font-small;
      color: $text-grey;
      font-weight: normal;
      border: none;
      width: calc(100% - 37px);
      height: 30px;
      padding-left: 0px;
      float: left;
      &:focus {
        outline: none;
      }
    }
    .btn-search {
      font-size: $font-title-size-small;
      font-weight: bold;
      display: block;
      text-align: center;
      width: 35px;
      height: 32px;
      .icon-search {
        font-size: $font-big-17;
        line-height: 31px;
      }
    }
  }
  .estimate-value {
    color: $color-grey-dusty;
    font-size: $font-small;
    text-align: right;
    margin-top: -9px;
    .total {
      font-weight: normal;
      margin-bottom: 8px;
      line-height: 16px;
      .number-value{
        color: $color-grey-dark;
        font-size: $font-root;
        font-family: $font-family-roboto-bold;
      }
    }
    .limit{
      font-weight: normal;
      line-height: 16px;
      span{
        &:first-child{
          margin-right: 3px;
        }
      }
    }
  }
}
.table-container {
  background: $color-white;
}
 .icon-coin {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    vertical-align: middle;
}
.group_button_tooltip {
  display: inline-block;
  float: left;
  position: relative;
  // width: 23px;
  .btn-deposit-tooltip {
    display: inline-block;
    float: right;
    height: 23px;
    overflow: hidden;
    width: auto;
    padding: 1px 5px;
    border-radius: 11.5px;
    font-size: 20px;
    font-weight: 600;
    border: 0px;
    i.icon-signal2 {
      color: $color-alizarin-crimson;
      position: relative;
      top: 2px;
      margin-right: 2px;
    }
  }
  .tooltip_hover {
    position: absolute;
    width: 165px;
    line-height: 20px;
    padding: 16px 14px 14px 19px;
    color: $color-grey-dark;
    font-size: $font-root;
    font-weight: 500;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.22);
    background-color: $color-white;
    border-radius: 3px;
    right: 100%;
    margin-right: 2px;
    top: -50%;
    margin-top: -7px;
    z-index: 5;
    display: none;
    text-align: left;
    white-space: normal;
    font-family: $font-family-roboto-medium;
  }
  &:hover {
    .tooltip_hover {
      display: block;
    }
  }
}
#balance {
  &.page_container {
    .balance-container {
      .table-container {
        th {
          line-height: 20px;
          padding: 20px 0px 10px 0px;
          font-size: $font-small;
          font-weight: 500;
          color: $color-grey;
          font-family: $font-family-roboto-medium;
          &.after_none:after {
            display: none;
          }
          &.coin_balances {
            padding-left: 53px;
          }
          &[data-sort-order="asc"], &[data-sort-order="desc"] {
            color: $color-jungle-green;
          }
        }
        tr {
          td {
            font-size: $font-root;
            font-weight: 500;
            color: $color-grey-dark;
            font-family: $font-family-roboto-medium;
            line-height: 21px;
            padding: 7px 0px 6px 0px;
            overflow: visible;
            vertical-align: middle;
            &.coin_balances {
              padding-left: 20px;
            }
            &:last-child {
              text-align: left;
            }
            .icon_balances {
              font-size: 20px;
              color: $color-jungle-green;
              cursor: pointer;
            }
            .icon_balances.active-icon {
              color: $color-eden;
            }
            .btn-lose-color {
              .icon_balances {
                color: $color-grey;
                cursor: not-allowed;
              }
            }
            &.tranfer-cell {
              padding-left: 6px;
            }
            .btn-transfer {
              display: block;
              margin-left: auto;
              margin-right: auto;
              width: 38px;
              height: 38px;
              line-height: 38px;
              position: relative;
              margin-top: -12px;
              top: 6px;
              cursor: pointer;
              border-top-left-radius: 3px;
              border-top-right-radius: 3px;
              text-align: center;
              background-color: transparent;
            }
            &.acvite_transfer {
              .btn-transfer {
                background-color: $color-bright-gray;
              }
            }
            .btn-mam {
              display: inline-block;
              width: 38px;
              height: 38px;
              line-height: 42px;
              position: relative;
              margin-top: -18px;
              font-size: 11px;
              top: 12px;
              cursor: pointer;
              border-top-left-radius: 3px;
              border-top-right-radius: 3px;
              text-align: center;
              overflow: hidden;
              background-color: transparent;
              margin-left: 3px;
            }
            &.acvite_mam {
              .btn-mam {
                background-color: $color-bright-gray;
              }
            }
          }
          &:hover {
            background-color: $color-bright-gray;
            td {
              .icon_balances {
                color: $color-eden;
              }
              .btn-lose-color {
                .icon_balances {
                  color: $color-grey;
                  cursor: not-allowed;
                }
              }
            }
          }
        }
        .table_drop_balances {
          position: relative;
          padding: 22px 37px 23px 37px;
          background-color: $color-bright-gray;
          overflow: visible;
          .close_tbale {
            position: absolute;
            top: 20px;
            right: 20px;
            display: block;
            width: 20px;
            height: 20px;
            font-size: $font-smaller;
            line-height: 20px;
            text-align: center;
            color: $color-grey-dark;
            cursor: pointer;
          }
          &:hover {
            background-color: $color-bright-gray;
          }
        }
      }
    }
  }
  .group_transfer_form {
    font-family: $font-family-roboto;
    display: block;
    float: left;
    margin-right: 15px;
    .btn-transfer-form {
      border-radius: 3px;
      border: solid 1px $color-alto;
      background-color: $color-bright-gray;
      height: 40px;
      width: 40px;
      font-size: 20px;
      color: $color-eden;
      padding: 8px;
      overflow: hidden;
      text-align: center;
      &:hover {
      color: $color-bright-gray;
      background-color: $color-eden;
      }
    }
    .btn-send-transfer-form {
      border-radius: 3px;
      border: solid 1px $color-jungle-green;
      background-color: $color-jungle-green;
      height: 40px;
      width: 180px;
      font-size: $font-smaller;
      color: $color-white;
      padding: 8px;
      overflow: hidden;
      text-align: center;
      letter-spacing: 0.9px;
      text-transform: uppercase;
      font-family: $font-family-roboto-bold;
      &:focus,
      &:hover {
        background-color: $color-blue-custom;
        border: solid 1px $color-blue-custom;
        color: $color-white;
      }
    }
    label.txt {
      display: block;
      width: 100%;
      line-height: 20px;
      color: $color-grey-dusty;
      font-size: $font-small;
      font-weight: 500;
      height: 20px;
    }
    input.input-form {
      border-radius: 3px;
      border: solid 1px $color-alto;
      background-color: $color-bright-gray;
      height: 40px;
      font-size: $font-root;
      color: $color-grey-dark;
      font-family: $font-family-roboto-medium;
      padding: 10px 13px;
      line-height: 20px;
    }
    select.input-form {
      border-radius: 3px;
      border: solid 1px $color-alto;
      background-color: $color-bright-gray;
      height: 40px;
      font-size: $font-root;
      color: $color-grey-dark;
      font-family: $font-family-roboto-medium;
      padding: 10px 10px;
      line-height: 20px;
    }
    .w_155 {
      width: 155px;
    }
    .w_220 {
      width: 220px;
    }
    .input-amount {
      position: relative;
      .name_amount {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: $font-root;
        color: $color-grey-dark;
        font-family: $font-family-roboto-medium;
        text-transform: uppercase;
      }
      input.input-form {
        padding-right: 40px;
      }
      & > input:focus {
        border: 1px solid #2dac91;
      }
    }
  }
  .select-control {
    border-radius: 3px;
    border: solid 1px $color-alto;
    background-color: $color-bright-gray;
    height: 40px;
    font-size: $font-root;
    color: $color-grey-dark;
    font-family: $font-family-roboto-medium;
    padding: 10px 10px;
    line-height: 20px;
    text-align: left;
    .select-icon:before {
      color: $color-grey-dark;
      float: right;
      font-size: 11px;
      line-height: 22px;
      display: inline-block;
      position: absolute;
      top: 10px;
      right: 9px;
    }
    &:hover {
      border-color: $color-jungle-green !important;
    }
  }
  .dropdown-menu {
    width: 100%;
    border-radius: 3px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.16);
    background-color: $color-white;
    padding: 10px 0px;
    .tab-element {
      cursor: pointer;
      display: block;
      width: 100%;
      line-height: 20px;
      padding: 5px 20px;
      font-size: $font-root;
      color: $color-grey-dark;
      &:hover {
        color: $color-jungle-green-light;
      }
    }
    .disabled-item {
      display: none;
      background-color: #bbb;
      cursor: not-allowed;
      color: white;
      &:hover {
        color: white;
      }
    }
  }
}
</style>
