<template>
  <div id="MarginExchange_page" class="clearfix">
    <div id="home" class="page_container full-page" :class="{'has-auth': isAuthenticated }">
      
      <div class="page_container_left">

        <div class="left_header_home clearfix">
          <exchange-header id="coin_info_exchange" :currentSymbol="symbol"></exchange-header>
          <!-- header block -->

          <div class="chart_block">
            <chart-index id="chart_index"></chart-index>
          </div>
          <!-- chart block -->

          <div class="order-book-block">
            <order-book/>
          </div>
          <!-- end order book -->
        </div>

        <div class="clearfix clearfix-40"></div>

        <div class="group_positions">
          <group-positions id="group_positions"></group-positions>
        </div>
        <!-- end group positions -->

        <div class="clearfix clearfix-20"></div>

        <div class="group_positions">
          <InstrumentsAndIndices id="group_positions"></InstrumentsAndIndices>
        </div>
        <!-- end group positions -->

      </div>

      
      <div class="page_container_right">

        <order-trade-form id="order_trade_form"></order-trade-form>
        <!-- end order trade form -->

        <div class="box_your_position" v-if="isAuthenticated">
          <your-position></your-position>
        </div>
        <!-- end your position -->

        <div class="box_balance_margin" v-if="isAuthenticated && this.masterAccount">
          <balance-margin></balance-margin>
        </div>
        <!-- end Balance -->

        <div class="box_contract_details">
          <contract-details></contract-details>
        </div>
        <!-- end contract details -->

      </div>

    </div>
    <div class="clearfix"></div>
    <exchange-footer></exchange-footer>
    <master-popup></master-popup>
    <beta-tester-form></beta-tester-form>
    <beta-tester-wait-admin-verify></beta-tester-wait-admin-verify>
  </div>
</template>

<script>
  import Const from '@/common/Const';
  import ExchangeHeader from './Header.vue';
  import ChartIndex from "@/components/marginexchange_pages/exchange/basic/chart/ChartIndex.vue";
  import OrderBook from '@/components/marginexchange_pages/exchange/basic/OrderBook.vue';
  import GroupPositions from './GroupPositions.vue';
  import InstrumentsAndIndices from './InstrumentsAndIndices.vue';
  import OrderTradeForm from "./OrderTradeForm.vue";
  import YourPosition from "./YourPosition.vue";
  import ContractDetails from "./ContractDetails.vue";
  import BalanceMargin from "./Balance.vue";
  import ExchangeFooter from '@/components/salespoint_pages/exchange/basic/ExchangeFooter.vue';
  import MasterPopup from './MasterPopup.vue';
  import { mapActions, mapGetters, mapMutations} from 'vuex'
  import BetaTesterForm from './beta-tester/BetaTesterForm.vue';
  import BetaTesterWaitAdminVerify from './beta-tester/WaitAdminVerify.vue';

  export default {
    components: {
      ExchangeHeader,
      ChartIndex,
      OrderBook,
      GroupPositions,
      InstrumentsAndIndices,
      OrderTradeForm,
      YourPosition,
      ContractDetails,
      BalanceMargin,
      ExchangeFooter,
      MasterPopup,
      BetaTesterForm,
      BetaTesterWaitAdminVerify,
    },
    data() {
      return {
        symbol: this.$route.query.symbol,
        isAuthenticated: window.isAuthenticated,
        config: {
          displayAction: 'thead',
          showHideOtherPairsCheckbox: true
        },
      }
    },
    computed: {
      ...mapGetters(['instrument', 'currentSymbol', 'user', 'masterAccount'])
    },
    methods: {
      ...mapActions(['saveAllContractName', 'getActiveIndices', 'getInstrument', 'getMamBalance', 'updateMamBalance', 'getMamPositions', 'getIndices', 'getMamOrders', 'getIndicator']),
     
      getSocketEventHandlers() {
        return {
          MarginBalanceUpdated: this.onMarginBalanceUpdated
        };
      },
      onMarginBalanceUpdated(newBalance) {
        this.updateMamBalance(newBalance);
      },
    },
    watch: {
      '$route' (to, from) {
        this.symbol = this.$route.query.symbol;
      },
      symbol(val){
      },
      currentSymbol(val){
        if(this.currentSymbol)
          this.symbol = this.currentSymbol;
      }
    },
    mounted() {
      document.title = this.$t('menu.exchange') + ` – ${this.tabTitle}`;
    },
    created() {
      this.getInstrument({currentSymbol: this.symbol});
      this.saveAllContractName(this.instrument);
      this.getActiveIndices();
      this.getMamOrders();
      this.getIndicator();
      if(window.isAuthenticated) {
        this.getMamBalance();
        this.getMamPositions();
        setTimeout(() => {
          this.$broadcast('MasterPopup', true);
        }, 1000);
      }
    }
 }
</script>

<style lang="scss" scoped>
  @import '@/assets/sass/variables';

  $widthRightExchange    : 270px;
  $heightHeader          : 85px;
  $minHeightContent      : 700px; 
  $widthOrderBook        : 268px; 
  $heightYourPosition    : 255px; 
  $borderHome            : 1px solid #3c4049; 

  .full-page {
    width: 100%;
    display: inline-block;
    min-width: 1200px;
    box-sizing: border-box;
  }
  .clearfix-40 {
    display: block;
    width: 100%;
    height: 40px;
    float: none;
    overflow: hidden;
  }
  .clearfix-20 {
    display: block;
    width: 100%;
    height: 20px;
    float: none;
    overflow: hidden;
  }
  #MarginExchange_page {
    background-color: $color-mirage;
    min-width: 1200px;
  }
  #home {
    min-height: calc(#{$minHeightContent} + #{$heightHeader});
    .page_container_left {
      width: calc(100% - #{$widthRightExchange});
      height: 100%;
      display: block;
      float: left;
      border-right: #{$borderHome};
      margin-right: -1px;
      background-color: #11212e;
      .left_header_home {
        display: block;
        width: 100%;
        height: calc(100vh - 50px - 40px);
        min-height: #{$minHeightContent};
        background-color: #11212e;
        #coin_info_exchange {
          height: #{$heightHeader};
          border-bottom: #{$borderHome};
          overflow: hidden;
        }
        .chart_block {
          height: calc(100% - #{$heightHeader});
          width: calc(100% - #{$widthOrderBook});
          display: block;
          float: left;
          border-right: #{$borderHome};
          border-bottom: #{$borderHome};
        }
        .order-book-block {
          height: calc(100% - #{$heightHeader});
          width:  #{$widthOrderBook};
          display: block;
          float: left;
          border-bottom: #{$borderHome};
          overflow: hidden;
        }
      }
      .group_positions {
        margin-top: -1px;
        border-top: #{$borderHome};
        border-bottom: #{$borderHome};
      }
    }
    .page_container_right {
      width: #{$widthRightExchange};
      // height: calc(100vh - 50px);
      min-height: calc(#{$minHeightContent} + 40px);
      display: block;
      float: left;
      border-left: #{$borderHome};
      background-color: #11212e;
      #order_trade_form {
        min-height: calc(100% - #{$heightYourPosition});
      }
      .box_your_position {
        // min-height: #{$heightYourPosition};
      }
      .box_balance_margin {
        // min-height: 300px;
        // background-color: #111721;
      }
    }
  }

</style>

