<template>
  <!-- NEW BALANCE LAYOUT -->
  <div
    id="balance"
    class="page_container"
    :class="{ is_no_single_page: !isSinglePage }"
  >
    <Header />
    <div class="overview">
      <div class="contract-type">
        <p
          class="title-total"
          @click="toggleContractType('USD_M')"
          :class="contractType === 'USD_M' ? 'selectedTab' : ''"
        >
          USDⓈ-M
        </p>
        <p
          class="title-total"
          @click="toggleContractType('COIN_M')"
          :class="contractType === 'COIN_M' ? 'selectedTab' : ''"
        >
          COIN-M
        </p>
      </div>
      <div class="total-overview" v-if="contractType === 'USD_M'">
        <div class="margin-balance">
          <p class="title-balance">
            {{ $t("title.total_margin_balance") }}
            <span class="unHideCoin firstCoin"
              >{{$t('balances.future.total_balance')}}</span
            >
          </p>
          <span style="margin-left: 14px">
            <img
              v-if="userThemeMode == 'light-theme'"
              :src="`/images/eye-${!tongglePrice ? 'open' : 'close'}.svg`"
              alt="eye"
              width="22"
              height="12"
              @click="clickHide"
            />
            <img
              v-else
              :src="`/images/eyes-${
                !tongglePrice ? 'open-dark' : 'close-dark'
              }.svg`"
              alt="eye"
              width="22"
              height="12"
              @click="clickHide"
            />
          </span>
          <div v-if="!tongglePrice" class="price-coin">
            <p v-if="totalMarginBalance > 0">
              {{ totalMarginBalance | formatNumberAndDecimal }} USDT
            </p>
            <p v-else>{{ totalMarginBalance| formatNumberAndDecimal }} USDT</p>
            <!--<div
              class="unHideCoin"
              v-if="checkDisplayFullCoin(totalMarginBalance)"
            >
              <p v-if="totalMarginBalance > 0">
                {{ totalMarginBalance | formatNumberAndDecimal }} USDT
              </p>
            </div> -->
          </div>
          <p v-else class="price-coin">***********</p>
        </div>
        <div class="balance">
          <p class="title-balance">
            {{ $t("title.total_wallet_balance") }}
            <span class="unHideCoin-second"
              >{{$t("balances.future.wallet_balance")}}</span
            >
          </p>
          <div v-if="!tongglePrice" class="price-coin">
            <p>{{ totalUSDM | formatNumberAndDecimal}} USDT</p>
            <!--<div class="unHideCoin" v-if="checkDisplayFullCoin(totalUSDM)">
              {{ totalUSDM | formatNumberAndDecimal }}
            </div> -->
          </div>
          <p v-else class="price-coin">***********</p>
        </div>
        <div class="Unrealized">
          <p
            class="title-balance"
            :class="clazzUnrealized(totalUnrealized, !tongglePrice)"
          >
            {{ $t("title.total_PNL") }}
            <span class="unHideCoin"
              >{{$t('balances.future.PNL')}}</span
            >
          </p>
          <div v-if="!tongglePrice" class="price-coin">
            <p :class="clazzUnrealized(totalUnrealized, !tongglePrice)">
              {{ totalUnrealized| formatNumberAndDecimal}}
              USDT
            </p>
           <!-- <div
              class="unHideCoin"
              v-if="checkDisplayFullCoin(totalUnrealized)"
            >
              {{ totalUnrealized | formatNumberAndDecimal }}
            </div>-->
          </div>
          <p v-else class="price-coin">***********</p>
        </div>
      </div>
      <BalanceCoinM
        v-else
        :priceCoin="totalBTCMarginBalance"
        :valued="totalUSDMarginBalance"
        :tongglePrice="tongglePrice"
        :totalSpotBtcBalance="totalBtcBalance"
        :totalUsdSpotBalance="totalUSDBalance"
        :totalUsdFutureBalance="totalPNLtoUSD"
        :totalFuturesBtcBalance="totalPNLtoBTC"
        :key="totalCoinPNL"
        @clickHide="clickHide"
      ></BalanceCoinM>
    </div>
    <div class="balance-container">
      <div class="tab-container">
        <div
          class="tab-element"
          @click="changeTab('postions')"
          v-bind:class="{ active: selectedTab === 'postions' }"
        >
          {{ $t("funds.balance.future.postions") }}
        </div>
        <div
          class="tab-element"
          @click="changeTab('assets')"
          v-bind:class="{ active: selectedTab === 'assets', 'cursor-unset' : !this.balanceFutures.assets }"
        >
          {{ $t("funds.balance.future.assets") }}
        </div>
      </div>

      <!-- end es-toolbar -->
      <div class="content-spot-overview">
        <div class="table-container table-history">
          <alert-with-button-modal />
          <message-with-icon />
          <data-table3
            v-if="selectedTab === 'postions'"
            :getData="positionMargin"
            ref="datatable"
            :limit="10"
            :key="positionChangeTab"
          >
            <template slot-scope="props">
              <th class="min_w150 coin_balances">
                {{ $t("funds.balances.future.symbol") }}
              </th>
              <th class="name_blances">
                {{ $t("funds.balances.future.size") }}
              </th>
              <th class="min_w150 totalBalance">
                {{ $t("funds.balances.future.entry_price") }}
                <span class="unHideCoin"
                  >{{$t('balances.futures.entry_price')}}</span
                >
              </th>
              <th class="min_w150 totalBalance">
                {{ $t("funds.balances.future.mark_price") }}
                <span class="unHideCoin"
                  >{{$t('balances.futures.last_price')}}</span
                >
              </th>
              <th class="min_w450 totalBalance">
                {{ $t("funds.balances.future.unrealized_pnl_roe") }}
                <span class="unHideCoin"
                  >{{$t('balances.futures.unrealized')}}</span
                >
              </th>
            </template>
            <template slot="body" slot-scope="props">
              <tr>
                <td class="symbol">
                  <span
                    :class="
                      props.item.currentQty > 0 ? 'typeLong' : 'typeShort'
                    "
                  ></span>
                  {{ props.item.name | uppercase }} {{ $t('common.perpetual') }}
                </td>
                <td class="size">
                  <span v-if="!tongglePrice">
                    {{
                      adminSetForDecimal(
                        props.item.currentQty,
                        props.item.asset,
                        "size"
                      ) | formatCurrencyAmount(props.item.symbol, 0)
                    }}
                    <span v-if="contractType == 'USD_M'">
                      {{ symbolCoin(props.item.symbol, props.item.asset) }}
                    </span>
                    <span v-else> Cont </span>
                  </span>
                  <span v-else class="price-coin">***********</span>
                </td>
                <td>
                  <span v-if="!tongglePrice">
                    {{
                      adminSetForDecimal(
                        props.item.entryPrice,
                        props.item.symbol,
                        "price"
                      )
                    }}
                    <span v-if="contractType == 'USD_M'">
                      {{ symbolCoin(props.item.symbol, props.item.asset) }}
                    </span>
                    <span v-else>
                      {{ props.item.asset }}
                    </span>
                  </span>
                  <span v-else class="price-coin">***********</span>
                </td>
                <td>
                  <span v-if="!tongglePrice">
                    {{
                      adminSetForDecimal(
                        maskPrice(props.item.symbol),
                        props.item.symbol,
                        "price"
                      )
                        | toNumber | formatNumberAndDecimal
                    }}
                  </span>
                  <span v-else class="price-coin">***********</span>
                </td>
                <td
                  v-if="!tongglePrice"
                  :class="
                    clazzUnrealized(
                      unrealizedValue[props.index]?.PNL,
                      !tongglePrice
                    )
                  "
                >
                  {{ unrealizedValue[props.index]?.PNL | formatNumberAndDecimal}}

                  <span v-if="!isNaN(unrealizedValue[props.index]?.ROE)">
                    ({{
                      formatShortText(unrealizedValue[props.index]?.ROE, 2, 20)
                    }}%)
                  </span>
                  <span v-else>0%</span>
                </td>
                <td v-else>
                  <span class="price-coin">***********</span>
                </td>
              </tr>
            </template>
          </data-table3>

          <data-table3
            v-else
            :getData="loadData"
            ref="datatableAssets"
            :key="positionChangeTab"
            :limit="10"
          >
            <template slot-scope="props">
              <th class="coin_balances_1">
                {{ $t("funds.balance.future.assets") }}
              </th>
              <th class="name_blances">
                {{ $t("funds.balance") }}
                <span class="unHideCoin"
                  >{{$t('balances.futures.total_net_transfer')}}</span
                >
              </th>
              <th class="min_w150 availableBalance">
                {{ $t("funds.balances.unrealized") }}
                <span class="unHideCoin"
                  >{{$t('balances.future.PNL')}}</span
                >
              </th>
              <th class="min_w120 availableBalance">
                {{ $t("funds.balances.margin_balance2") }}
              </th>
              <th class="min_w120 availableBalance">
                {{ $t("funds.balances.future.maximum-withdraw") }}
              </th>
              <th class="max_w180 action">
                {{ $t("funds.balances.action") }}
              </th>
            </template>
            <template slot="body" slot-scope="props">
              <tr v-if="_.size(coinImages) > 0">
                <td class="coin_balances">
                  <img
                    v-if="coinImages[props.item.asset]"
                    class="icon-coin"
                    :src="`${coinImages[props.item.asset]}`"
                    alt=""
                  />
                  <img
                    v-else
                    class="icon-coin"
                    :src="
                      props.item.coin == 'BTC'
                        ? btcicon
                        : props.item.coin == 'XRP'
                        ? xrpicon
                        : eosicon
                    "
                    alt=""
                  />

                  {{ props.item.asset | uppercase }}
                </td>
                <td class="align_center">
                  <span v-if="!tongglePrice"
                    >{{ props.item.balance | formatNumberAndDecimal }}
                  </span>
                  <span v-else class="price-coin">***********</span>
                </td>
                <td class="align_center">
                  <span v-if="!tongglePrice"
                    >{{ unrelizedAsset(props.item.asset, true) }}
                  </span>
                  <span v-else class="price-coin">***********</span>
                </td>
                <td class="align_center">
                  <span v-if="!tongglePrice">
                    {{
                      props.item.marginBalance | formatNumberAndDecimal
                    }}
                  </span>
                  <span v-else class="price-coin">***********</span>
                </td>
                <td class="align_center">
                  <span v-if="!tongglePrice">
                    {{
                      props.item.availableBalance
                        | formatNumberAndDecimal
                    }}
                  </span>
                  <span v-else class="price-coin">***********</span>
                </td>
                <td class="action_btn">
                  <span
                    class="tranfer-cell"
                    @click="activeRow(props.item)"
                    v-bind:class="{ acvite_transfer: props.item.isActiveRow }"
                    ><a>{{ $t("funds.balances.tranfer") }}</a>
                  </span>
                </td>
              </tr>

              <template v-if="props.item.asset == nameItemShow">
                <tr>
                  <td colspan="11" class="table_drop_balances">
                    <div class="close_tbale" @click="activeRow(props.item)">
                      <i class="icon-close"></i>
                    </div>
                    <div class="content_transfer_form">
                      <form class="transfer_form">
                        <div class="group_transfer_form">
                          <label class="txt">{{
                            $t("funds.balances.from")
                          }}</label>
                          <div class="dropdown" v-if="isSwitchTransfer">
                            <button
                              class="btn form-control select-control w_155 disableCustom"
                              type="button"
                              data-toggle="dropdown"
                              disabled="disabled"
                            >
                              <span
                                v-if="contractType === 'USD_M'"
                                class="left select-item-selected"
                              >
                                {{ $t("funds.balances.margin_balance") }}
                              </span>
                              <span v-else class="left select-item-selected">
                                {{ $t("funds.balances.future.coin_M") }}
                              </span>
                            </button>
                          </div>
                          <input
                            v-else
                            type="text"
                            class="input-form w_155"
                            :placeholder="$t('funds.balances.main_account')"
                            disabled="disabled"
                          />
                        </div>
                        <!-- btn 2 -->
                        <!-- Swap inputs button -->
                        <div
                          class="group_transfer_form"
                          @click="handleSwitchTransfer(props.item)"
                        >
                          <label class="txt"></label>
                          <img
                            :src="
                              require(`@/assets/images/wallet/tranfersIconOverview${
                                theme === 'light-theme' ? '' : '-dark'
                              }.svg`)
                            "
                          />
                        </div>

                        <div class="group_transfer_form">
                          <label class="txt">{{
                            $t("funds.balances.to")
                          }}</label>
                          <input
                            type="text"
                            class="input-form w_155"
                            :placeholder="$t('funds.history.spot_overview')"
                            disabled="disabled"
                            v-if="isSwitchTransfer"
                          />
                          <div class="dropdown" v-else>
                            <button
                              class="btn form-control select-control w_155 disableCustom"
                              type="button"
                              data-toggle="dropdown"
                              disabled="disabled"
                            >
                              <span
                                v-if="contractType === 'USD_M'"
                                class="left select-item-selected"
                              >
                                {{ $t("funds.balances.margin_balance") }}
                              </span>
                              <span v-else class="left select-item-selected">
                                {{ $t("funds.balances.future.coin_M") }}
                              </span>
                            </button>
                          </div>
                        </div>

                        <!-- Amount -->
                        <div class="group_transfer_form">
                          <label class="txt">{{
                            $t("order.trade_history.amount")
                          }}</label>
                          <div class="input-amount">
                            <currency-input
                              :precision="8"
                              :balanceFuture="true"
                              :class="{
                                error: errors.has('amount' + props.item.asset),
                              }"
                              class="input-form w_220"
                              :value="props.item.amount"
                              v-model="props.item.amount"
                              placeholder="0"
                              @focus="resetValidateRowWhenFocus(props.item)"
                              @blur="() => handleChangeAmount(props.item)"
                              @input="handleAmount(props.item)"
                            />
                            <span class="name_amount">{{
                              props.item.asset
                            }}</span>
                          </div>
                          <div
                            v-show="errors.has('amount' + props.item.asset)"
                            class="w_220 invalid-feedback"
                          >
                            {{ errors.first("amount" + props.item.asset) }}
                          </div>
                          <div
                            v-show="errors.has('server' + props.item.asset)"
                            class="w_220 invalid-feedback"
                          >
                            {{ errors.first("server" + props.item.asset) }}
                          </div>

                          <div class="group_input_form cleafix">
                            <span class="txt_left_group mgb-none"></span>
                            <label class="input_right_group">
                              <span
                                class="check_percent"
                                :class="[
                                  precentTransfer == 25 && 'activePrecent',
                                ]"
                                @click="onPercentClicked(props.item, 25)"
                                >25%</span
                              >
                              <span
                                class="check_percent"
                                :class="[
                                  precentTransfer == 50 && 'activePrecent',
                                ]"
                                @click="onPercentClicked(props.item, 50)"
                                >50%</span
                              >
                              <span
                                class="check_percent"
                                :class="[
                                  precentTransfer == 75 && 'activePrecent',
                                ]"
                                @click="onPercentClicked(props.item, 75)"
                                >75%</span
                              >
                              <span
                                class="check_percent last_item"
                                :class="[
                                  precentTransfer == 100 && 'activePrecent',
                                ]"
                                @click="onPercentClicked(props.item, 100)"
                                >100%</span
                              >
                            </label>
                          </div>
                        </div>
                        <!-- Transfer button -->
                        <div class="group_transfer_form">
                          <label class="txt"></label>
                          <button
                            class="btn btn-send-transfer-form"
                            @click.prevent="transferBalance($event, props.item)"
                            :disabled="isSubmittingTransfer"
                          >
                            {{ $t("funds.balances.tranfer") }}
                          </button>
                        </div>
                      </form>
                    </div>
                  </td>
                </tr>
              </template>

              <template v-if="props.item.isActiveRowMam">
                <tr>
                  <td colspan="11" class="table_drop_balances">
                    <div class="close_tbale" @click="activeRowMam(props.item)">
                      <i class="icon-close"></i>
                    </div>
                    No data
                  </td>
                </tr>
              </template>
            </template>
          </data-table3>
          <!-- end pagination-container -->
        </div>
      </div>
    </div>
    <!-- end-balance-container -->
  </div>
  <!-- END NEW BALANCE LAYOUT -->
</template>
<script>
import rf from "@/request/RequestFactory";
import BigNumber from "bignumber.js";
import AlertWithButtonModal from "@/components/shared_components/common/AlertWithButtonModal";
import MessageWithIcon from "@/components/shared_components/common/MessageWithIcon";
import AirdropBalance from "@/components/shared_components/common/AirdropBalance.vue";
import Entry from "@/components/shared_components/common/Entry/Entry.vue";
import MamTransferHistory from "@/components/shared_components/common/MamTransferHistory.vue";
import {mapGetters, mapState} from "vuex";
import Cookies from "js-cookie";
import Header from "@/pages/wallet/component/Header.vue";
import SpotOverview from "@/pages/wallet/component/SpotOverview.vue";
import BalanceCoinM from "@/pages/wallet/component/BalanceCoinM.vue";
import BTCIcon from "@/assets/images/icon/coin/color/btc.png";
import XRPIcon from "@/assets/images/icon/coin/color/xrp.png";
import EOSIcon from "@/assets/images/icon/coin/color/eos.png";
import ETHIcon from "@/assets/images/ethereum-eth-logo.png"

import Const from "@/common/Const";

const MIN_BTC = 0.001;
const MIN_AMOUNT_CRYPTO = Math.pow(10, -8); // 10^-8
const TRANSFER_TYPE_MAIN_BALANCE = "main";
const TRANSFER_TYPE_EXCHANGE_BALANCE = "spot";
const TRANSFER_TYPE_MARGIN_BALANCE = "future";
const TRANSFER_TYPE_MAM_BALANCE = "mam";
const CONTRACT_TYPE_COIN_M = "COIN_M";
const CONTRACT_TYPE_USD_M = "USD_M";

// Prevent convert 0.00000001 to 1e-8
BigNumber.config({ EXPONENTIAL_AT: 15 });

export default {
  data() {
    let selectedTable = this.$route.query.type
      ? this.$route.query.type
      : "postions";
    return {
      limit: undefined,
      accountBalances: undefined,
      prices: undefined,
      balances: [],

      isAuthenticated: window.isAuthenticated,
      noFunds: window.i18n.t("common.datatable.no_have_data"),
      isSubmittingTransfer: false,

      precentTransfer: 0,
      nameItemShow: "",
      theme:
        Cookies.get("user-theme", { domain: process.env.VUE_APP_PUBLIC_URL }) ||
        "dark-theme",
      tongglePrice: true,
      balanceFutures: {},
      selectedTab: selectedTable,
      Price24h: [],
      unrealizedPNLValue: [],
      assetsWallet: {},
      totalPNLAsset: 0,
      positionData: [],
      isSwitchTransfer: true,
      availablebalanceMain: {},
      amountChange: 0,
      isSelectedPercent: false,
      costOpenOrder: {},
      openOrder: [],
      contractType: CONTRACT_TYPE_USD_M,
      listInstruments: [],
      listCoinMasterData: [],
      coinImages: {},
      btcicon: BTCIcon,
      xrpicon: XRPIcon,
      eosicon: EOSIcon,
      ethicon: ETHIcon,
      totalMarginBalance: 0,
      totalCoinPNL: 0,
      listAllowcated: [],
      coinsMasterData: {}
    };
  },
  components: {
    BalanceCoinM,
    AlertWithButtonModal,
    MessageWithIcon,
    AirdropBalance,
    MamTransferHistory,
    Entry,
    Header,
    SpotOverview,
  },
  props: {
    isSinglePage: {
      type: Boolean,
      default: true,
    },
  },
  sockets: {
    connect: function () {},
  },
  watch: {
    prices(newPrices) {
      this.refresh();
    },
    openOrder() {
      this.availableBalanceWithdraw();
    },
    isTransferFromMain() {
      this.$refs.datatable.refresh();
    },
    userThemeMode(theme) {
      this.theme = theme;
    },
    Price24h() {
      if (Object.keys(this.positionData).length !== 0) {
        this.unrealizedPostions();
      }
    },
    positionData() {
      this.unrealizedPostions();
    },
    amountChange(newValue, oldValue) {
      if (this.isSelectedPercent) return;
      else if (newValue !== oldValue && oldValue !== 0) {
        this.precentTransfer = 0;
      }
    },
    assetsWallet() {
      this.unrealizedPostions();
      if (this.positionData.length == 0) {
        this.marginBalance();
        this.availableBalanceWithdraw();
      }
    },
  },
  computed: {
    ...mapState({
      masterData: (state) => state.masterdata,
      userThemeMode: (state) => state.userThemeMode,
    }),
    ...mapGetters({
      isShowBalances: 'isShowBalances'
    }),
    unrealizedValue() {
      return this.unrealizedPNLValue;
    },

    totalUnrealized: {
      get: function () {
        return this.unrealizedPNLValue.reduce((sum, current) => {
          if (current.asset !== "USDT" && current.asset !== "USD") {
            let btcValue = this.convertToBtcValue(
              current.asset.toLowerCase(),
              current.PNL
            );
            return sum + btcValue * 1;
          }

          if (current.asset === "USDT" || current.asset === "USD") {
            return sum + current.PNL * 1;
          }
        }, 0);
      },
      set: function (total) {
        this.totalPNLAsset = this.cutDecimals(total);
      },
    },

    totalBTCMarginBalance() {
      return this.sumBy("marginBalance", "btc");
    },
    totalUSDMarginBalance() {
      return this.sumBy("marginBalance", "usdt");
    },
    totalBtcBalance() {
      return this.sumBy("balance", "btc");
    },
    totalUSDBalance() {
      return this.sumBy("balance", "usdt");
    },
    totalPNLtoBTC() {
      return this.sumBy("unrealisedPnlForTotal", "btc");
    },

    totalPNLtoUSD() {
      return this.sumBy("unrealisedPnlForTotal", "usdt");
    },
    totalUSDM() {
      let balance = this.balanceFutures.assets;
      if (balance)
        return new BigNumber(balance?.["USDT"]?.balance);
    },
    positionChangeTab() {
      return `${this.selectedTab}` + `${this.contractType}`;
    },
  },

  methods: {
    iconCoin() {
      _.forEach(this.masterData.coins, (item, key) => {
        this.coinImages[item.coin.toUpperCase()] = item.icon_image;
      });
      this.coinImages["USD"] = `/images/color_coins/usd.png`;
      this.coinImages["eth"] = this.ethicon
    },

    getSocketEventHandlers() {
      return {
        BalanceUpdated: this.onBalanceUpdated,
      };
    },

    onBalanceUpdated(newBalances) {
      if (newBalances && newBalances[Const.TYPE_MAIN_BALANCE]) {
        newBalances = newBalances[Const.TYPE_MAIN_BALANCE];
      }
      this.availablebalanceMain = Object.assign(
        {},
        this.availablebalanceMain,
        newBalances
      );
      if (window._.isEmpty(this.availablebalanceMain)) {
        return;
      }
    },

    toggleContractType(type) {
      this.contractType = type;
      this.changeTab("postions");
      this.unrealizedPostions();
    },

    clickHide() {
      this.tongglePrice = !this.tongglePrice;
      const balances = {...this.isShowBalances,futureBalances : this.tongglePrice}
      Cookies.set("hide-balance", JSON.stringify(balances, { domain: process.env.VUE_APP_PUBLIC_URL }))
      this.$store.commit('setShowBalances', balances )
    },

    formatNumber(n) {
      let value = "";
      var parts = n.toString().split(".");
      const numberPart = parts[0];
      const decimalPart = parts[1];
      const thousands = /\B(?=(\d{3})+(?!\d))/g;
      value =
        numberPart.replace(thousands, ",") +
        (decimalPart ? "." + decimalPart : "");
      return this.convertCoin(n);
    },

    resetValidateRowWhenFocus(item) {
      this.errors.remove("amount" + item.asset);
      this.errors.remove("server" + item.asset);
      this.isSelectedPercent = false;
    },
    resetValidateRow(item) {
      item.isTransferFromMain = true;
      item.amount = 0;
      this.errors.remove("amount" + item.asset);
      this.errors.remove("server" + item.asset);
    },
    activeRow(item) {
      if (item.asset == this.nameItemShow) {
        this.nameItemShow = "";
        return;
      }
      this.nameItemShow = item.asset;
      this.precentTransfer = 0;
      item.isActiveRow = !item.isActiveRow;
      this.isSwitchTransfer = true;
      this.resetValidateRow(item);
      this.$forceUpdate();
    },
    activeRowMam(item) {
      item.isActiveRowMam = !item.isActiveRowMam;
      this.$forceUpdate();
    },
    refreshNow() {
      // Refresh datatable
      let immediateRefresh = false;
      this.transferData(immediateRefresh);
      this.$refs && this.$refs.datatable && this.$refs.datatable.refresh();
    },
    refresh() {
      const isNoData =
        window._.isEmpty(this.accountBalances) || window._.isEmpty(this.prices);
      if (isNoData) {
        return;
      }
      this.$refs && this.$refs.datatable && this.$refs.datatable.refresh();
    },

    validate(item) {
      this.errors.remove("amount" + item.asset);
      this.errors.remove("server" + item.asset);
      let amount = new BigNumber(item.amount || 0);
      if (amount.isZero()) {
        this.errors.add(
          "amount" + item.asset,
          window.i18n.t("funds.balances.amount_over_0")
        );
        return false;
      }
      const transferFromMain =
        this.availablebalanceMain[item.asset.toLowerCase()].available_balance;
      const balanceWithdraw =
        this.balanceFutures.assets[item.asset].availableBalance;
      let balance = this.isSwitchTransfer ? balanceWithdraw : transferFromMain;
      let availableBalance = new BigNumber(balance.toString());
      let marginBalance = new BigNumber(availableBalance.toString());

      if (marginBalance.lt(amount)) {
        this.errors.add(
          "amount" + item.asset,
          window.i18n.t(
            "funds.balances.amount_can_not_greater_than_available_main_balance"
          )
        );
        return false;
      }
      return true;
    },
    convertToBtcValue(coin, value) {
      let balance = value;
      if (new BigNumber(balance.toString()).isZero()) {
        return 0;
      }
      let pair = `btc_${coin}`;
      if (!this.prices[pair] || !this.prices[pair].price) {
        pair = `${coin}_btc`;
        if (!this.prices[pair] || !this.prices[pair].price) {
          return 0;
        }
        return new BigNumber(`${balance}`)
          .div(this.prices[pair].price)
          .toString();
      }
      return new BigNumber(`${balance}`)
        .mul(this.prices[pair].price)
        .toString();
    },
    convertBtcToCoinValue(coin, value) {
      let balance = value;
      let pair = `btc_${coin}`;
      if (!this.prices[pair] || !this.prices[pair].price) {
        pair = `${coin}_btc`;
        if (!this.prices[pair] || !this.prices[pair].price) {
          return 0;
        }
        return new BigNumber(`${balance.toString()}`)
          .mul(this.prices[pair].price)
          .toString();
      }
      return new BigNumber(`${balance.toString()}`)
        .div(this.prices[pair].price)
        .toString();
    },
    sumBy(attr, to) {
      let total = new BigNumber(0);
      for (let item in this.balanceFutures.assets) {
        let value = this.balanceFutures.assets[item];
        if (
          this.contractType !== "USD_M" &&
          item !== "USD" &&
          item !== "USDT"
        ) {
          let balance = value.balance;
          if (
            item[attr] == "NaN" ||
            new BigNumber(balance.toString()).isZero()
          ) {
            continue;
          }
          let balanceToBTC;
          balanceToBTC = this.convertToBtcValue(
            item.toLowerCase(),
            value[attr]
          );
          if (to === "usdt") {
            balanceToBTC = this.convertBtcToCoinValue(to, balanceToBTC);
            total = total.add(balanceToBTC);
            continue;
          }
          total = total.add(balanceToBTC);
        }
      }
      return total.toString();
    },

    handleChangeAmount(item) {
      // this.validate(item);
    },

    convertCoin(coin, afterDecimal = 4) {
      let balance = coin;
      if (isNaN(balance)) {
        this.cutDecimals(balance);
        // return 0;
      }
      const factor = Math.pow(10, afterDecimal);
      balance = Math.floor(balance * factor) / factor;
      if (balance?.toString().length > 10) {
        balance = balance.toLocaleString().toString().slice(0, 10) + "...";
        return balance;
      }
      return balance || 0;
    },
    customConvertCoin(coin, max, slice) {
      if (coin?.toString().length > max) {
        return coin.toString().slice(0, slice) + "... ";
      } else if (isNaN(coin)) {
        return 0;
      }
      return coin;
    },

    handleAmount(item, value) {
      this.amountChange = item.amount;
    },
    handleSwitchTransfer(item) {
      this.isSwitchTransfer = !this.isSwitchTransfer;
      this.precentTransfer = 0;
      item.amount = 0;
    },
    onPercentClicked(item, percent) {
      let balance = 0;
      this.precentTransfer = percent;
      this.isSelectedPercent = true;
      if (!this.isSwitchTransfer) {
        balance =
          this.availablebalanceMain[item.asset.toLowerCase()].available_balance;
      } else {
        balance = this.balanceFutures.assets[item.asset].availableBalance;
      }
      if (balance === null) {
        item.amount = 0;
      } else {
        item.amount =
          new BigNumber(balance.toString()).mul(percent).div(100).toString() ||
          0;
      }
    },

    async transferBalance(e, item) {
      e.preventDefault();
      if (!this.validate(item) || this.isSubmittingTransfer) {
        return false;
      }

      this.isSubmittingTransfer = true;
      const amount = item.amount || 0;
      const assetType = item.asset;

      try {
        if (this.isSwitchTransfer) {
          await rf
            .getRequest("UserRequest")
            .withdrawFutureBalance({ amount: amount, assetType });
        } else {
          const dataTransfer = {
            from: TRANSFER_TYPE_MAIN_BALANCE,
            to: TRANSFER_TYPE_MARGIN_BALANCE,
            amount: amount,
            asset: assetType.toLowerCase(),
          };
          await rf
            .getRequest("UserRequest")
            .transferFutureBalance(dataTransfer);
        }

        this.isSubmittingTransfer = false;
        this.getMainAvailable();
        // this.assetMargin();
        this.getFutureBalance();
        this.positionMargin();
        item.amount = 0;
        this.$refs?.datatableAssets?.refresh();
      } catch (err) {
        this.isSubmittingTransfer = false;
        const errorMessage =
          err?.response?.data?.message ??
          window.i18n.t("funds.balances.transfer_balance_fails");
      }
    },

    formatRoundDown(value, maxDecimal) {
      const numFormat = new BigNumber(value).toFixed(
        maxDecimal || 2,
        BigNumber.ROUND_DOWN
      );
      return numFormat.toString().replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    formatShortText(value, maxDecimal, maxLength) {
      if (!value) {
        return value;
      }
      const numFormat = new BigNumber(value.toString()).toFixed(
        maxDecimal || 2,
        BigNumber.ROUND_DOWN
      );
      const split = value.toString().split(".");
      const beforeDecimal = split[0];
      const shorttedNumber =
        beforeDecimal.length > maxLength
          ? numFormat
              .slice(0, maxLength - 1)
              .concat("...")
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")
          : numFormat
              .slice(0, maxLength)
              .concat("...")
              .replace(/\d(?=(\d{3})+\.)/g, "$&,");
      return numFormat.length > maxLength
        ? shorttedNumber
        : numFormat.replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    isShowTooltip(value, maxLength, maxDecimal) {
      const numFormat = new BigNumber(value).toFixed(
        maxDecimal || 2,
        BigNumber.ROUND_DOWN
      );
      return numFormat.replace(".", "").length > maxLength;
    },

    showErrorMessage(message, callBack = () => {}) {
      window.MessageWithIcon.error(message);
    },
    showInfoMessage(message) {
      window.MessageWithIcon.success(message);
    },
    async initData() {
      await this.getInstruments(),
        await this.getSumOrderMargin(),
        await rf
          .getRequest("PriceRequest")
          .get24hFuture()
          .then((res) => {
            this.Price24h = res.data;
          }),
          await this.getFutureBalance(),
        await this.positionMargin(),
        await this.getPriceAPI(),
        await this.getMainAvailable();
      // this.getPriceLTCtoBTC()
    },

    getInstruments() {
      rf.getRequest("UserRequest")
        .getInstruments()
        .then((res) => {
          const assetsKeyObject = {};
          res.data.forEach((asset) => {
            return (assetsKeyObject[asset.symbol] = {
              ...asset,
            });
          });
          this.listInstruments = assetsKeyObject;
        });
    },
    getPriceAPI() {
      rf.getRequest("PriceRequest")
        .getPrices()
        .then((res) => {
          this.onPricesUpdated(res.data);
        });
    },
    handleMasterData() {
      this.coinsMasterData = window._.keyBy(
          this.masterData.coins,
          (item) => item.coin
      );
    },

    getFutureBalance() {
      rf.getRequest("UserRequest")
        .getFuturesBalances()
        .then((res) => {
          const listWithoutLTC = res.data.filter(item => item.asset !== 'LTC' && item.asset !== 'USD')
          this.assetsWallet = listWithoutLTC;
          const assetsKeyObject = {};
          listWithoutLTC.forEach((asset) => {
            assetsKeyObject[asset.asset] = {
              asset: asset.asset,
              balance: asset?.balance,
              marginBalance: 0,
              availableBalance: 0,
              totalAllowcated: 0,
              unrealisedPnl: 0,
              unrealisedROE: "",
              unrealisedPnlForTotal: 0
            };
          });
          this.balanceFutures = {
            assets: assetsKeyObject,
            totalBalance: listWithoutLTC.totalWalletBalance,
          };
        });
    },
    getPriceCoinGecKotoBTC() {
      for (const coin in this.balanceFutures.assets) {
        const coinPair = coin.toLowerCase()
        const pairValue = `btc_${coinPair}`;
        if (this.prices?.[pairValue] === undefined && coinPair !== 'usd' || coinPair === 'xrp' || coinPair === 'ltc' ) {
          rf.getRequest('TransactionRequest')
              .getPriceToBTC({symbol: coin.toLowerCase()})
              .then(res => {
                this.prices[pairValue] = { price: res?.data || 0 };
              })

        }
      }
    },

    maskPrice(symbol) {
      const findSymbol = this.Price24h.filter((item) => {
        return item.symbol === symbol;
      });
      return this.convertCoin(findSymbol[0]?.oraclePrice || 0);
    },

    onPricesUpdated(newPrices) {
      this.prices = Object.assign({}, this.prices, newPrices);
      this.addPricesInBtc();
      this.getPriceCoinGecKotoBTC()
    },
    addPricesInBtc() {
      this.prices["btc_btc"] = { price: 1 };
      for (const fiat of Const.FIAT_LIKE_LIST) {
        const fiatPrice = this.prices[`${fiat}_btc`];
        const checkNullPrice =
          fiatPrice && !new BigNumber(fiatPrice.price.toString()).isZero();
        const price = checkNullPrice
          ? new BigNumber("1").div(fiatPrice.price).toString()
          : "0";
        this.prices[`btc_${fiat}`] = { price };
      }
    },
    symbolCoin(symbol, asset) {
      const index = symbol?.indexOf(asset);
      return symbol?.slice(0, index) + symbol?.slice(index + asset.length);
    },

    positionMargin() {
      let params = {
        contractType: this.contractType,
      };
      return rf
        .getRequest("MarginRequest")
        .getPosition(params)
        .then((res) => {
          this.positionData = res.data;
          return res;
        });
    },

    loadData() {
      const assets = this.balanceFutures.assets;
      return Promise.resolve(
        Object.keys(this.balanceFutures.assets)
          .filter((key) =>
            this.contractType === "USD_M"
              ? ["USDT"].includes(key)
              : key !== "USD" && key !== "USDT"
          )
          .map((key) => assets[key])
          // .sort((a, b) => b.balance - a.balance)
      );
    },
    changeTab(tab) {
      if (this.selectedTab === tab) return;

      if(!this.balanceFutures.assets) return;

      this.selectedTab = tab;
      this.configs = {
        type: this.selectedTab,
      };
      this.nameItemShow = "";
      this.refresh();
    },

    async unrealizedPostions() {
      this.unrealizedPNLValue = [];
      this.listAllowcated = [];
      let balanceFuture = this.balanceFutures?.assets;
      for (const item of this.positionData) {
        const multiplier = this.listInstruments[item.symbol]?.multiplier;

        if (item.currentQty != 0) {
          const markPrice = this.maskPrice(item.symbol);
          const side = item.currentQty > 0 ? 1 : -1;
          const allowcated = this.allowcatedMargin(item, multiplier);
          const unrealizedPNL = this.unrealizedPNL(
            markPrice,
            item,
            side,
            multiplier
          );
          const ROE = new BigNumber(unrealizedPNL)
            .div(allowcated)
            .times(100)
            .toString();
          const unrealizedObj = {
            asset: item.asset,
            PNL: unrealizedPNL,
            ROE: ROE,
          };
          const allowcatedObj = {
            asset: item.asset,
            allowcated: allowcated,
          };
          this.unrealizedPNLValue.push(unrealizedObj);
          this.listAllowcated.push(allowcatedObj);
          if (balanceFuture) {
            balanceFuture[item?.asset].unrealisedPnl = Boolean(item.isCross) ? unrealizedPNL : 0;
            balanceFuture[item?.asset].unrealisedPnlForTotal = unrealizedPNL;
            balanceFuture[item?.asset].unrealisedROE = ROE;
          }
          await this.marginBalance();
          await this.availableBalanceWithdraw();
        }
      }
    },

    adminSetForDecimal(value, coin, sizeOrPrice) {
      if(this.listInstruments) {
        const maxFiguresForPrice =
            sizeOrPrice === "price"
                ? this.listInstruments[coin]?.maxFiguresForPrice
                : this.listInstruments[coin]?.maxFiguresForSize;
        return this.$options.filters.formatDecimalForAdmin(
            value,
            maxFiguresForPrice
        );
      }
    },

    allowcatedMargin(item, contract) {
      const isCurrency = item.asset === "USD" || item.asset === "USDT";
      const maskPrice = this.maskPrice(item.symbol);
      const absQty = Math.abs(item.currentQty);
      const leverage = parseFloat(item.leverage);
      const entryPrice = parseFloat(item.entryPrice);
      const adjustMargin = parseFloat(item.adjustMargin);
      const multiplierContract = parseFloat(contract);
      const positionMargin = parseFloat(item.positionMargin);
      let allowcatedMarginForCross = isCurrency
        ? new BigNumber(absQty).times(maskPrice).dividedBy(leverage)
        : new BigNumber(absQty)
            .times(multiplierContract)
            .dividedBy(new BigNumber(leverage).times(maskPrice));
      let allowcatedMarginForIsolate = new BigNumber(positionMargin || 0).add(adjustMargin || 0)
      return Boolean(item.isCross)
        ? allowcatedMarginForCross.toString()
        : allowcatedMarginForIsolate.toString();
    },

    unrealizedPNL(markPrice, item, side, contract) {
      const isCurrency = item.asset === "USD" || item.asset === "USDT";
      const maskPrice = this.maskPrice(item.symbol);
      const absQty = Math.abs(item.currentQty);
      const leverage = parseFloat(item.leverage);
      const entryPrice = parseFloat(item.entryPrice);
      const multiplierContract = parseFloat(contract);
      const positionMargin = parseFloat(item.positionMargin);
      const inverseEntry = new BigNumber(1).dividedBy(entryPrice);
      const inverseMark = new BigNumber(1).dividedBy(markPrice);

      const unrealizedPNLUSD = new BigNumber(absQty)
        .times(new BigNumber(maskPrice).minus(entryPrice.toString()))
        .times(side)
        .toString();
      const unrealizedPNLCoin = new BigNumber(absQty)
        .abs()
        .times(multiplierContract)
        .times(new BigNumber(inverseEntry).minus(inverseMark.toString()))
        .times(side)
        .toString();

      return isCurrency ? unrealizedPNLUSD : unrealizedPNLCoin;
    },

    marginBalance() {
      this.totalMarginBalance = 0;
      for (let key in this.balanceFutures.assets) {
        const balance = this.balanceFutures.assets[key];
        const unrelized = this.unrelizedAsset(key) || 0;
        const marginBalance = new BigNumber(balance.balance)
          .add(unrelized.toString())
          .toString();
        balance.marginBalance = marginBalance;
        this.totalBalanceMarginForUSDM(this.balanceFutures.assets);
      }
      // this.$refs?.datatableAssets?.refresh()
    },

    totalBalanceMarginForUSDM(balance) {
      this.totalMarginBalance = new BigNumber(
        balance?.["USDT"]?.marginBalance
      )
    },

    async availableBalanceWithdraw() {
      for (let key in this.balanceFutures.assets) {
        const variableBalance = this.balanceFutures.assets[key];
        const costOrder = this.costOpenOrder[key] || 0;
        const totalAllowcated = this.allowcatedMarginForAsset(key);
        let availableUSDT = new BigNumber(variableBalance.balance)
          .minus(totalAllowcated.toString())
          .minus(costOrder.toString())
          .toString();
        let maximunWithdraw = new BigNumber(availableUSDT)
          .add(variableBalance.unrealisedPnl.toString())
          .toString();
        variableBalance.availableBalance = new BigNumber.min(availableUSDT, maximunWithdraw).toString();
        const availableBalance = new BigNumber(variableBalance.availableBalance.toString());
        variableBalance.availableBalance = BigNumber.max(0, availableBalance).toString();
      }
    },

    getMainAvailable() {
      rf.getRequest("UserRequest")
        .getMainBalance()
        .then((res) => {
          this.availablebalanceMain = res.data.main;
        });
    },

    getSumOrderMargin() {
      let params = {
        contractType: "ALL",
      };
      rf.getRequest("UserRequest")
        .getOpenOrderMargin(params)
        .then((res) => {
          this.sumCostOpenOrder(res.data);
          this.openOrder = res.data;
        });
    },

    sumCostOpenOrder(order) {
      let costUSD = 0;
      let costUSDT = 0;
      this.costOpenOrder = {};
      order.forEach((item) => {
        if (item.cost !== null) {
          if (
            item.symbol.includes("USDT") &&
            !item.symbol.includes("USDTUSD")
          ) {
            costUSDT += parseFloat(item.cost);
            this.costOpenOrder.USDT = costUSDT || 0;
          } else if (item.contractType === "COIN_M") {
            let assets = item.symbol.replace(/USDM|USDT|USD/, "");
            if (!this.costOpenOrder[assets]) {
              this.costOpenOrder[assets] = 0;
            }
            this.costOpenOrder[assets] += parseFloat(item.cost);
          } else {
            costUSD += parseFloat(item.cost);
            this.costOpenOrder.USD = costUSD || 0;
          }
        }
      });
    },

    unrelizedAsset(asset, convert = false) {
      let unrealizedTotal = 0;
      const total = this.unrealizedPNLValue.reduce((sum, current) => {
        this.totalUnrealized = parseFloat(sum) + current.PNL * 1;
        if (current.asset === asset && !isNaN(current.PNL)) {
          unrealizedTotal = parseFloat(sum) + current.PNL * 1;
        }
        return unrealizedTotal;
      }, 0);
      if (convert) {
        this.totalPNLAsset = total;
        return this.formatShortText(total, 8, 20);
      } else if (total !== 0) {
        this.totalPNLAsset = total;
        return total;
      }
    },

    allowcatedMarginForAsset(asset) {
      let unrealizedTotal = 0;
      const total = this.listAllowcated.reduce((sum, current) => {
        // parseFloat(sum) + (current.allowcated * 1)
        if (current.asset === asset && !isNaN(current.allowcated)) {
          unrealizedTotal = parseFloat(sum) + current.allowcated * 1;
        }
        return unrealizedTotal;
      }, 0);
      return total;
    },

    cutDecimals(num) {
      const [int, dec] = String(num).split(".");
      const intWithCommas = int.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      if (!dec) {
        return intWithCommas;
      }
      const paddedDec = dec.padEnd(4, "0").slice(0, 4);
      if (/^0+$/.test(paddedDec)) {
        return intWithCommas;
      }
      return `${intWithCommas}.${paddedDec}`;
    },

    clazzUnrealized(value, toggle) {
      if (value === 0 || !toggle) {
        return "";
      }
      return value > 0 ? "unrealizedPNL" : "unrealizedROE";
    },

    checkDisplayFullCoin(coin) {
      return coin?.toString().length > 10;
    },
  },

  created() {
    if (!this.isAuthenticated) {
      return;
    }
    this.handleMasterData()
    this.initData();
    document.title = this.$t("menu.balances") + ` – ${this.tabTitle}`;
    this.unrealizedPostions();
  },

  mounted() {
    this.iconCoin();
    this.tongglePrice = this.isShowBalances.futureBalances
    this.sockets.subscribe("tickers", (data) => {
      this.Price24h = data;
    });
    this.sockets.subscribe("position", (data) => {
      if (data.contractType === this.contractType) {
        const index = this.positionData.findIndex(
          (obj) => obj.symbol == data.symbol
        );
        if (index != -1) {
          this.positionData[index] = data;
          if (data.currentQty == 0) {
            this.positionData.splice(index, 1);
            // this.$refs.datatable.refresh()
          }
        } else if (data.currentQty != 0) {
          this.positionData.unshift(data);
        }
      }
    });
    this.sockets.subscribe("balance", (data) => {
      this.balanceFutures.assets[data.asset].balance = data.balance;
      this.marginBalance();
      this.availableBalanceWithdraw();
    });
    this.sockets.subscribe("orders", (data) => {
      data.forEach((elements) => {
        if (elements.status == "CANCELED") {
          const index = this.openOrder.findIndex((obj) => {
            return elements.symbol == obj.symbol;
          });
          this.openOrder.splice(index, 1);
          return;
        }
        this.openOrder.push(elements);
      });
      this.sumCostOpenOrder(this.openOrder);
    });
    this.sockets.subscribe("notifications", (data) => {
      const msg = data[0];
      if (msg.amount) {
        if (msg.type == "success") {
          this.showInfoMessage(
            window.i18n.t("funds.balances.transfer_balance_success")
          );
        } else {
          this.showErrorMessage(
            window.i18n.t("funds.balances.transfer_balance_fails")
          );
        }
      }
    });
    this.$on("UPDATED_LOCALE", () => {
      if (this.$refs.datatable) {
        const activeRows = this.$refs.datatable.rows.filter((row) => {
          return row.isActiveRow;
        });
        if (!activeRows) {
          return;
        }
        activeRows.forEach((row) => {
          this.validate(row);
        });
      }
    });
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables";
@import "@/assets/sass/common.scss";

.cursor-unset {
  cursor: unset!important;
}

.tool-tip-custom {
  background: var(--color-tooltip);
  border-radius: 8px;
  margin-top: 9px;
  padding: 10px;
  width: 227px;
  height: 56px;
  color: $color-white !important;
}
.min_w150 {
  // min-width: 150px;
  width: 70px;
}
.min_w450 {
  // min-width: 150px;
  width: 400px !important;
  max-width: 100%;
}
.min_w120 {
  width: 90px;
  min-width: 60px;
}
.max_w180 {
  width: 115px;
}
.min_w100 {
  min-width: 100px;
}
.min_w80 {
  min-width: 80px;
}
.table-history {
  padding-bottom: 15px;
}
.full-page-background {
  width: 100%;
  display: inline-block;
  padding-top: 60px;
}
.number-entry-user {
  text-align: center;
  font-weight: normal;
  .title-entry {
    font-size: 30px;
    font-weight: bold;
  }
}
.error {
  border: solid 1px $text-color-red !important;
}
.invalid-feedback {
  width: 100% !important;
  color: $text-color-red !important;
}
#balance {
  .box-flex-table {
    display: flex;
    align-items: center;
  }
  &.is_no_single_page {
    .balance-container {
      margin: 0;
      .table-container thead th {
        height: 22px;
        border-bottom: 1px solid $color-gray;
        font-size: $font-mini;
      }
    }
  }
  table {
    tbody {
      tr {
        min-height: 100px;
        overflow-y: initial;
      }
    }
  }
  .overview {
    padding: 40px;
    .contract-type {
      .title-total {
        font-size: $font-big-20;
        color: var(--text-primary-color);
        line-height: 24.2px;
        display: inline-block;
        padding-bottom: 16px;
        cursor: pointer;
        margin-right: 20px;
        &.selectedTab  {
          position: relative;
          color: var(--text-primary-color);
          &::before {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            top: 32px;
            background-color:  $color-caribbean-green;
            //top: 30px;
            width: 100%;
            border-radius: 20px;
            height: 4px;
          }
        }
      }
    }
    .total-overview {
      display: flex;
      justify-content: space-between;
      margin-top: 18px;
      //padding-right: 260px;
      gap: 35px;
      div {
        width: 100%;
        min-width: 27.5%;
        .price-coin {
          font-weight: 600;
          font-size: 30px;
          margin-bottom: 12px;
          color: var(--text-primary-color);
          position: relative;
          .unHideCoin {
            display: none;
          }
          &:hover {
            .unHideCoin {
              z-index: 1000;
              white-space: break-spaces;
              display: block;
              position: absolute;
              padding: 10px;
              //min-width: 340px !important;
              width: 100%;
              border-radius: 8px;
              background: var(--color-tooltip);
              color: $color-white !important;
              font-size: 16px;
              width: fit-content !important;
            }
          }
          .unrealizedPNL {
            color: $text-color-jade;
          }
          .unrealizedROE {
            color: $text-color-red;
          }
        }
        .title-balance {
          display: inline-block;
          font-family: $font-inter-regular;
          font-size: $font-big-20;
          color: var(--text-close) !important;
          line-height: 24px;
          position: relative;
          .unHideCoin {
            display: none;
          }
          .unHideCoin-second {
            display: none;
          }
          &:hover {
            .unHideCoin {
              z-index: 1000;
              white-space: break-spaces;
              display: block;
              position: absolute;
              padding: 10px;
              min-width: 340px !important;
              top: -76px;
              border-radius: 8px;
              background: var(--color-tooltip);
              color: $color-white !important;
              font-size: 16px;
              width: fit-content !important;
              text-align: start;
            }
            .unHideCoin-second {
              z-index: 1000;
              white-space: break-spaces;
              display: block;
              position: absolute;
              padding: 10px;
              min-width: 340px !important;
              top: -100px;
              border-radius: 8px;
              background: var(--color-tooltip);
              color: $color-white !important;
              font-size: 16px;
              width: fit-content !important;
              text-align: start;
            }
          }
        }
        & span {
          /* margin-left: 14px; */
          align-items: center;
          display: inline-flex;
          @media screen and (max-width: 992px) {
            margin-left: 8px;
            img {
              width: 14px;
              height: 8px;
            }
          }
        }
        .unrealizedPNL {
          color: $text-color-jade;
        }
        .unrealizedROE {
          color: $text-color-red;
        }
      }
    }
  }
  .tab-container {
    height: 63px;
    .tab-element {
      line-height: 13px;
      height: 16px;
      padding: unset !important;
      font-size: 20px;
      font-weight: 400;
      color: $color-auro-metal !important;
      border: unset !important;
      background: unset !important;
      cursor: pointer;
      position: relative;
      z-index: 1;
      background-color: var(--background-color-primary);
      margin-right: 40px;
      height: 37px;
      &.active {
        color: var(--text-primary-color) !important;
        background-color: unset !important;
        &::before {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          background-color: $color-caribbean-green;
          width: 100%;
          width: -webkit-fill-available;
          border-radius: 20px;
          height: 4px;
        }
      }
    }
  }
}
.balance-container {
  display: inline-block;
  width: 100%;
  .box-title {
    margin-bottom: $title-bottom-margin;
    &.box-title-secord {
      margin-top: 35px;
    }
  }
  .title-medium-small {
    font-weight: 600;
    font-size: $font-title-size-medium-2;
  }
}
.balance-info {
  line-height: 25px;
  width: 200px;
  margin-top: 8px;
  margin-left: -2px;
  font-weight: 100;
  font-size: $font-small;
  padding-right: 5px;
  padding-left: 5px;
  position: absolute;
  background: $background-default;
  border: $border_thin;
  box-shadow: 0 2px 10px 0 $border-color-grey;
  border-top: none;
  z-index: 10;
  padding: 5px 10px;
}
label,
input {
  margin: 0;
  padding: 0;
  font-weight: normal;
  color: var(--text-primary-color);
}
input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: red;
  opacity: 1; /* Firefox */
}
.sort_up {
  color: $color-corn-pale !important;
}
.sort_down {
  color: $color-corn-pale !important;
}
.es-toolbar {
  font-size: $font-root;
  margin-bottom: 20px;
  .icon {
    color: $color-grey-dusty;
  }
  .btn-small-icon {
    width: 200px;
    height: 32px;
    margin-right: 10px;
    line-height: 20px;
    cursor: pointer;
    color: $color-grey-dark;
    border-radius: 8px;
    padding: 5px 1px 4px 1px;
    position: relative;
    .checkbox-input {
      width: 21px;
      position: absolute;
      top: 4px;
      left: -4px;
      cursor: pointer;

      input {
        display: none;
      }

      label {
        cursor: pointer;
        position: absolute;
        width: 21px;
        height: 21px;
        top: 4px;
        left: 0px;
        border: 1px solid var(--color-input);
        border-radius: 2px;
        background: var(--color-input);

        &:after {
          content: "";
          position: absolute;
          left: 6px;
          top: 1px;
          width: 6px;
          height: 13px;
          border: solid $color-grey-dark;
          border-width: 0 2px 2px 0;
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
          display: none;
        }
        @media screen and (max-width: 576px) {
          left: 4px;
        }
      }
      input[type="checkbox"]:checked + label:after {
        opacity: 1;
      }
      #checkboxFiveInput {
        display: none;
        &[type="checkbox"]:checked + .button-check {
          background-color: $color-caribbean-green;
        }
        &[type="checkbox"]:checked + .button-check:after {
          display: block;
        }
      }
    }
    #tooltip-target-1 {
      .tool-tip-custom {
        display: none;
      }
      &:hover {
        .tool-tip-custom {
          display: block;
          position: absolute;
          left: -30px;
          top: 16px;
          text-align: left;
          z-index: 100;
        }
      }
    }
    span {
      position: absolute;
      left: 24px;
      top: 8px;
      font-weight: 400;
      font-size: 18px;
      color: $dark-2;
      @media screen and (max-width: 576px) {
        left: 28px;
      }
    }
  }
  @media screen and (max-width: 576px) {
    margin-top: 62px;
    margin-bottom: 20px;
  }
}
.table-container {
  background: $color-white;
  .tableContainer {
    @media screen and (max-width: 768px) {
      overflow: auto;
      white-space: nowrap;
    }
  }
}
.icon-coin {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  vertical-align: middle;
  object-fit: contain;
}
.group_button_tooltip {
  display: inline-block;
  float: left;
  position: relative;
  // width: 23px;
  .btn-deposit-tooltip {
    display: inline-block;
    float: right;
    height: 23px;
    overflow: hidden;
    width: auto;
    padding: 1px 5px;
    border-radius: 11.5px;
    font-size: 20px;
    font-weight: 600;
    border: 0px;
    i.icon-signal2 {
      color: $color-alizarin-crimson;
      position: relative;
      top: 2px;
      margin-right: 2px;
    }
  }
  .tooltip_hover {
    position: absolute;
    width: 165px;
    line-height: 20px;
    padding: 16px 14px 14px 19px;
    color: $color-grey-dark;
    font-size: $font-root;
    font-weight: 500;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.22);
    background-color: $color-white;
    border-radius: 8px;
    right: 100%;
    margin-right: 2px;
    top: -50%;
    margin-top: -7px;
    z-index: 5;
    display: none;
    text-align: left;
    white-space: normal;
  }
  &:hover {
    .tooltip_hover {
      display: block;
    }
  }
}
#balance {
  &.page_container {
    .balance-container {
      padding: 40px;
      padding-right: 260px;
      @media screen and (max-width: 1700px) {
        padding-right: 5%;
      }
      .box-title {
        color: var(--text-primary-color);
      }
      .table-container {
        th {
          border-right: unset;
          line-height: 20px;
          padding: 14px 0px 13px 0px;
          font-size: $font-root;
          font-weight: 400;
          color: var(--color-percent);
          background-color: var(--color-header-table);
          position: relative;
          text-align: left;
          cursor: auto;
          height: 50px;
          .unHideCoin {
            display: none;
          }
          &:hover {
            .unHideCoin {
              z-index: 1000;
              white-space: break-spaces;
              display: block;
              position: absolute;
              padding: 10px;
              border-radius: 8px;
              background: var(--color-tooltip);
              color: $color-white !important;
              font-size: 16px;
              width: fit-content !important;
              text-align: start;
            }
          }
          &:nth-child(9) {
            padding: 20px 10px 10px 0px;
          }
          &.after_none:after {
            display: none;
          }
          &.coin_balances {
            //width: 20%;
            min-width: 220px;
            border-radius: 8px 0 0 8px;
            margin-right: 15px;
            padding-left: 15px;
          }
          &.coin_balances_1 {
            //width: 25%;
            border-radius: 8px 0 0 8px;
            min-width: 120px;
            margin-right: 15px;
            padding-left: 15px;
          }
          &.name_blances {
            text-align: center;
            width: 300px;
          }
          &.totalBalance {
            text-align: end;
            width: 250px;
          }
          &.availableBalance {
            width: 286px;
            text-align: center;
          }
          &.exchangeBalance {
            width: 210px;
          }
          &.futureBalances {
            width: 190px;
          }
          &.action {
            width: 80px;
            padding-right: 15px;
            text-align: end;
          }
          &[data-sort-order="asc"],
          &[data-sort-order="desc"] {
            color: $color-jungle-green;
          }
        }
        th[data-sort-order="desc"],
        th[data-sort-order="asc"] {
          color: $color-grey !important;
        }
        th:last-child {
          padding-right: 15px;
          border-radius: 0 8px 8px 0;
          //width: 15%;
        }
        tr {
          td {
            text-align: end;
            font-size: $font-root;
            font-weight: 500;
            color: var(--text-primary-color);
            line-height: 21px;
            padding: 13px 0px;
            overflow: visible;
            vertical-align: middle;
            &.coin_balances {
              padding-left: 15px;
              display: flex;
              align-items: center;
              gap: 8px;
            }
            &.action_btn {
              span {
                a {
                  font-weight: 400;
                  font-size: $font-root;
                  line-height: 22px;
                  color: $color-aqua-green;
                }
              }
            }
            &:nth-child(9) {
              padding: 7px 10px 6px 0px;
            }
            &:last-child {
              text-align: end;
              padding-right: 15px;
            }
            .icon_balances {
              font-size: 20px;
              color: $color-jungle-green;
              cursor: pointer;
            }
            .icon_balances.active-icon {
              color: $color-eden;
            }
            .btn-lose-color {
              .icon_balances {
                color: $color-grey;
                cursor: not-allowed;
              }
            }
            &.tranfer-cell {
              // padding-left: 6px;
            }
            .btn-transfer {
              display: block;
              margin-left: auto;
              margin-right: auto;
              width: 38px;
              height: 38px;
              line-height: 38px;
              position: relative;
              margin-top: -12px;
              top: 6px;
              cursor: pointer;
              border-top-left-radius: 3px;
              border-top-right-radius: 3px;
              text-align: center;
              background-color: transparent;
            }
            &.acvite_transfer {
              .btn-transfer {
                background-color: $color-bright-gray;
              }
            }
            .btn-mam {
              display: inline-block;
              width: 38px;
              height: 38px;
              line-height: 42px;
              position: relative;
              margin-top: -18px;
              font-size: 11px;
              top: 12px;
              cursor: pointer;
              border-top-left-radius: 3px;
              border-top-right-radius: 3px;
              text-align: center;
              overflow: hidden;
              background-color: transparent;
              margin-left: 3px;
            }
            &.acvite_mam {
              .btn-mam {
                background-color: $color-bright-gray;
              }
            }
            &.size {
              text-align: center;
            }
            &.align_center {
              text-align: center;
            }
          }
          .symbol {
            display: flex;
            align-items: center;
            gap: 12px;
            margin-left: 12px;
            & span {
              width: 4px;
              height: 24px;
              display: inline-block;
            }
            & .typeLong {
              background-color: $text-color-jade;
            }
            & .typeShort {
              background-color: $text-color-red;
            }
          }
          &:hover {
            // background-color: $color-bright-gray;
            td {
              .icon_balances {
                color: $color-eden;
              }
              .btn-lose-color {
                .icon_balances {
                  color: $color-grey;
                  cursor: not-allowed;
                }
              }
            }
          }
          .unrealizedPNL {
            color: $text-color-jade;
          }
          .unrealizedROE {
            color: $text-color-red;
          }
        }
        .table_drop_balances {
          position: relative;
          padding: 22px 37px 23px 37px;
          background-color: $color-bright-gray;
          overflow: visible;
          background: var(--color-tranfer);
          text-align: start !important;
          .close_tbale {
            position: absolute;
            top: 20px;
            right: 20px;
            display: block;
            width: 20px;
            height: 20px;
            font-size: $font-smaller;
            line-height: 20px;
            text-align: center;
            color: $color-grey-dark;
            cursor: pointer;
            .icon-close::before {
              color: var(--text-close-2);
              font-weight: bold;
            }
          }
          &:hover {
            // background-color: $color-bright-gray;
          }
        }
      }
      @media screen and (max-width: 992px) {
        padding: 20px 16px 70px;
      }
    }
  }
  .group_transfer_form {
    display: block;
    float: left;
    margin-right: 15px;
    img {
      padding-top: 8px;
      cursor: pointer;
    }
    .btn-transfer-form {
      border-radius: 8px;
      border: solid 1px $color-alto;
      background-color: $color-bright-gray;
      height: 40px;
      width: 40px;
      font-size: 20px;
      color: $color-eden;
      padding: 8px;
      overflow: hidden;
      text-align: center;
      &:hover {
        color: $color-bright-gray;
        background-color: $color-eden;
      }
    }
    .btn-send-transfer-form {
      border-radius: 8px;
      border: solid 1px $color-aqua-green;
      background-color: $color-aqua-green;
      height: 40px;
      width: 111px;
      font-size: $font-big;
      font-weight: 400;
      color: $dark-1;
      overflow: hidden;
      text-align: center;
      letter-spacing: 0.9px;
      line-height: 21px;
      &:focus,
      &:hover {
        background-color: $color-aquamarine !important;
        border: solid 1px $color-aquamarine !important;
        color: $dark-1;
      }
    }
    label.txt {
      display: block;
      width: 100%;
      line-height: 20px;
      color: var(--text-primary-color);
      font-size: 18px;
      font-weight: 400;
      height: 20px;
      margin-bottom: 10px;
    }
    .dropdown {
      background-color: $color-gray-alto;
      border-radius: 8px;
    }
    input.input-form {
      border-radius: 8px;
      // border: solid 1px $color-alto;
      background-color: var(--color-tranfer-to);
      border: 1px solid var(--color_transfer_border_to);
      height: 42px;

      padding: 10px 13px;
      font-size: 18px;
      line-height: 22px;
      &::placeholder {
        color: var(--text-second-color);
      }
    }
    select.input-form {
      border-radius: 8px;
      border: solid 1px $color-alto;
      background-color: $color-bright-gray;
      height: 42px;
      font-size: $font-root;
      color: $color-grey-dark;
      padding: 10px 10px;
      line-height: 20px;
      border: unset;
    }
    .w_155 {
      width: 220px;
      border: 1px solid var(--color-border-input);
    }
    .disableCustom {
      background: var(--bgr-btn);
      span {
        color: var(--color-pagi);
        font-size: 18px;
        line-height: 21px;
      }
    }
    .w_220 {
      width: 220px;
    }
    .input-amount {
      position: relative;
      .name_amount {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 18px;
        line-height: 22px;
        text-transform: uppercase;
        color: var(--color-pagi);
      }
      input.input-form {
        padding-right: 40px;
        background-color: var(--color-tranfer-to);
        width: 344px;
        border: 1px solid var(--color-border-input);
      }
      & > input:focus {
        border: 1px solid $color-jungle-green;
      }
    }
  }
  .select-control {
    border-radius: 8px;
    border: unset;
    background-color: var(--color-tranfer-to);
    height: 40px;
    font-size: $font-root;
    // color: var(--color-text);
    padding: 10px 10px;
    line-height: 20px;
    text-align: left;
    .select-icon:before {
      color: $color-grey-dark;
      float: right;
      font-size: 11px;
      line-height: 22px;
      display: inline-block;
      position: absolute;
      top: 10px;
      right: 9px;
    }
    &:hover {
      border-color: $color-jungle-green !important;
    }
  }
  .dropdown-menu {
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.16);
    background-color: $color-white;
    padding: 10px 0px;
    .tab-element {
      cursor: pointer;
      display: block;
      width: 100%;
      line-height: 20px;
      padding: 5px 20px;
      font-size: $font-root;
      color: $color-grey-dark;
      &:hover {
        color: $color-jungle-green-light;
      }
    }

    .disabled-item {
      display: none;
      background-color: $disable-color;
      cursor: not-allowed;
      color: white;
      &:hover {
        color: white;
      }
    }
  }
  .input_right_group {
    margin-bottom: 12px;
    width: 100%;
    .check_percent {
      display: inline-block;
      margin-top: 5px;
      width: calc((100% / 4) - 4.5px);
      margin-right: 6px;
      border: 1px solid var(--color_transfer_border_to);
      background-color: var(--color-tranfer-to);
      line-height: 21px;
      text-align: center;
      padding: 5px 5px;
      color: var(--color-percent);
      font-size: $font-root;
      border-radius: 4px;
      height: 32px;
      float: left;
      cursor: pointer;
      border-radius: 4px;
      &.last_item {
        margin-right: 0px;
      }
      &:hover {
        background: $color-aquamarine;
        border-color: $color-aquamarine;
        color: $dark-1;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        font-family: $font-inter-bold;
      }
    }
    .activePrecent {
      background: $color-caribbean-green;
      border-color: $color-caribbean-green;
      color: $dark-1 !important;
      font-weight: 600;
      font-size: $font-root;
      -webkit-transition: 0.5s;
      transition: 0.5s;
    }
  }
}
@media screen and (max-width: 992px){
  #balance {
    &.page_container {
      .balance-container {
        .title-medium-small {
          font-size: 24px;
          line-height: 29px;
        }
        .table-container {
          th {
            font-size: $font-root;
            &.coin_balances {
              width: 150px;
            }
            &.name_blances {
              width: 200px;
            }
          }

          tr {
            td {
              font-size: 14px;
              line-height: 19px;
            }
          }
          .table_drop_balances {
            padding: 24px 20px;
          }
        }
      }
    }
    .group_transfer_form {
      label {
        &.txt {
          font-size: 14px;
          line-height: 16px;
        }
      }
      input {
        &.input-form {
          font-size: 14px;
          line-height: 16px;
        }
      }
      .disableCustom {
        span {
          font-size: 14px;
          line-height: 16px;
        }
      }
      .w_155 {
        width: 170px;
      }
      .input-amount {
        .name_amount {
          font-size: 14px;
          line-height: 16px;
        }
      }
      .btn-send-transfer-form {
        font-size: 14px;
        line-height: 19px;
        width: 89px;
        height: 42px;
      }
    }
    .overview {
      padding: 41.5px 16px 0;
      .contract-type {
        .title-total {
          font-size: 16px ;
          line-height: 21px ;;
          padding-bottom: 9.5px;
        }
      }
      .total-overview {
        display: block;
        margin-top: 18px;
        padding-right: 0px;
        .margin-balance {
          float: left;
          width: fit-content;
        }
        .balance {
          float: right;
          width: fit-content;
          margin-right: 12px;
        }
        .Unrealized {
          float: left;
        }
        div {
          .price-coin {
            font-size: 16px;
            line-height: 21px;
          }
          .title-balance {
            font-size: $font-root;
            line-height: 19px;
          }
          & span {
            align-items: center;
          }
        }
      }
    }
    .tab-container {
      .tab-element {
        font-size: 16px;
        line-height: 21px;
        height: 33px;
      }
    }
    .icon-coin {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }
  .es-toolbar {
    .btn-small-icon {

      span {
        font-size: 14px;
        line-height: 16px;
        top: 11px;

      }
      .checkbox-input {
        label {
          width: 20px;
          height: 20px;
        }
      }
    }
    .estimate-value {
      .limit {
        flex-direction: column;
        font-size: 14px;
        line-height: 16px;

        .in-use {
          margin: 0;
        }
      }
    }
  }
  .content-spot-overview {
    // position: relative;
    width: 100%;
    z-index: 5;
    border: 0px;
    border-top: none;
    overflow-y: auto;
    overflow-x: auto;

  }
  .table-container {
    min-width: $min-width-table;
  }
}

</style>

<style lang="scss">
#balance {
  .tableContainer {
    min-height: 200px;
    overflow-y: auto;
    table {
      overflow-y: inherit;
    }
    .group-sort-tabl3 {
      display: inline-table !important;
      width: 13px;
      height: 20px;
      vertical-align: middle;
      position: relative;
      margin-top: 5px;
    }
    .icon-sort-table {
      //font-size: $font-big-17;
    }
  }
}
</style>