<template>
  <div class="sc_search_select">
    <div class="group_search_select" v-click-outside="clickOut">
      <template>
        <div class="button_drop_search" id="id_button_drop_search">
          <span v-if="label === placeholder" class="placeholder_coin">{{
            label
          }}</span>
          <span v-else v-html="label" class="item_selected"></span>
          <!-- <img src="/images/icon/icon-down.svg" alt="icon" /> -->
        </div>
      </template>
      <!-- <template v-else>
        <div class="box_input_s">
          <img
            src="/images/icon/icon-search.svg"
            alt="icon"
            class="icon-search"
          />
          <input
            type="Search"
            ref="inputSearch"
            class="input_search_select"
            :placeholder="$t('funds.action.placeholder_input_coin')"
            @keyup="doSearch($event.target.value)"
          />
          <img src="/images/icon/icon-up.svg" alt="icon" class="icon-up" @click="clickOut"/>
        </div>
      </template>
      <div
        class="box_list_search_select active"
        v-if="isShow && avaiableOptions.length > 0"
      >
        <ul class="list_search_select">
          <li
            v-for="(option, index) in avaiableOptions"
            @click="select(option)"
            :key="index"
            v-if="option.shortNameCurrency"
          >
            <span v-if="option.shortNameCurrency" class="icon_coin">
              <img
                class="option__image icon-choice"
                :src="option.iconCurrency"
                alt="option.shortNameCurrency"
            /></span>
            <span
              v-if="option.shortNameCurrency"
              class="code_coin"
              :class="{ active: isChangeCode }"
              >{{ option?.shortNameCurrency }}</span
            >
            <span
              v-if="option.shortNameCurrency"
              class="full_name_coin"
              :class="{ active: isChangeFullName }"
              >{{ option.fullNameCurrency }}</span
            >
          </li>
        </ul>
      </div> -->
      <div
        class="box_list_search_select active no-data"
        v-if="isShow && avaiableOptions.length === 0"
      >
        <div class="empty-row text-center">
          <div class="w-100 text-center text-empty">
            {{ $t("common.datatable.no_have_data") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  props: {
    placeholder: {
      type: String,
    },
    value: {
      default: () => {},
    },
    options: {
      type: Array,
      default: () => [],
    },
    focus: {
      type: Function,
      default: () => {},
    },
    click: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      avaiableOptions: this.options,
      isShow: false,
      search: "",
      isChangeCode: true,
      isChangeFullName: true,
    };
  },
  watch: {
    search() {
      this.avaiableOptions = this.options.filter(
        (item) =>
          this.includes(item.shortNameCurrency, this.search) ||
          this.includes(item.fullNameCurrency, this.search)
      );
    },
  },
  computed: {
    label() {
      const selected = this.value;
      if (selected && selected.shortNameCurrency) {
        return `<span class="icon_coin_select"> <img class="option__image icon-choice" src="${selected.iconCurrency}" alt="${selected.shortNameCurrency}"></span>
          <span class="code_coin_select">${selected.shortNameCurrency}</span>
          <span class="full_name_coin_select">${selected.fullNameCurrency}</span>`;
      }
      return this.placeholder;
    },
    ...mapState({
      userThemeMode: (state) => state.userThemeMode,
    }),
    theme: (state) => state.userThemeMode,
  },
  created() {
    window.addEventListener("click", this.checkClickOn);
  },
  beforeDestroy() {
    window.removeEventListener("click", this.checkClickOn);
  },
  methods: {
    toggleSelect() {
      this.$emit("resetErrors");
      this.focus("coin_address");
      this.isShow = !this.isShow;
      this.$nextTick(() => {
        if (this.isShow) {
          this.search = "";
          this.$refs.inputSearch.focus();
        }
      });
    },
    turnOffSelect() {
      this.isShow = false;
    },
    select(option) {
      this.$emit("input", option);
      this.search = "";
      this.turnOffSelect();
      if (this.click) {
        this.click();
      }
    },
    includes(str, query) {
      /* istanbul ignore else */
      if (str === undefined) str = "undefined";
      if (str === null) str = "null";
      if (str === false) str = "false";
      const text = str.toString().toLowerCase();
      const value = query.trim().toString().toLowerCase();
      return text.includes(value);
    },
    doSearch(value) {
      this.search = value;
    },
    clickOut(e) {
      e.stopPropagation()
      this.isShow = false;
      this.search = "";
    },
    checkClickOn(event) {
      if (
        document.getElementById("id_button_drop_search")?.contains(event.target)
      ) {
        this.isShow = true;
        this.focus("coin_address");
      }
      // this.isShow = document.getElementById('id_button_drop_search')?.contains(event.target) ? true : false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables";

.background_fake {
  display: block;
  width: 100%;
  opacity: 0;
  position: relative;
  top: 0px;
  left: 0px;
  z-index: 10;
  cursor: pointer;
}
.sc_search_select {
  .tit_search_select {
    font-size: $font-title-size-big;
    color: $color-white;
    margin-bottom: 20px;
    line-height: 44px;
  }
  &.error {
    border: 1px solid $text-color-red;
    border-radius: 8px;
  }
  .group_search_select {
    position: relative;
    .button_drop_search {
      position: relative;
      cursor: pointer;
      padding: 12px 15px;
      background: var(--color-dark-5);
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: var(--text-primary-color);
      $font-family: $font-inter-regular;
      font-size: 18px;
      line-height: 21px;
      .placeholder_coin {
        color: var(--text-desc);
      }
      .item_selected {
        display: flex;
        align-items: center;
        gap: 8px;
        color: var(--color-black_3);
        $font-family: $font-inter-regular;
        font-size: 18px;
        line-height: 21px;
        ::v-deep {
          .icon_coin_select {
            width: 26px;
            height: 26px;
            img {
              width: 26px;
              height: 26px;
              object-fit: contain;
              // border-radius: 50%;
            }
          }
          .code_coin_select {
            font-family: $font-inter-bold;
            color: var(--text-primary-color);
            font-size: 20px;
            line-height: 24px;
          }
          .full_name_coin_select {
            font-family: $font-inter-regular;
            color: var(--text-desc);
            font-size: 14px;
            line-height: 24px;
          }
        }
      }
      i.icon-arrow1 {
        display: inline-block;
        float: right;
        color: $color-jungle-green;
        line-height: 15px;
        font-size: $font-small;
        -webkit-transition: 0.5s;
        transition: 0.5s;
      }
    }
    .button_drop_search.active {
      i.icon_arrow_sl {
        // color: $color-yellow-pale;
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        -webkit-transition: 0.5s;
        transition: 0.5s;
      }
    }
    .box_list_search_select {
      position: absolute;
      display: none;
      width: 100%;
      top: calc(100% + 5px);
      left: 0px;
      z-index: 7;
      padding: 14px 16px 14px 12px;
      opacity: 0;
      max-height: 470px;
      overflow: auto;
      background-color: var(--bgr-dropdown);
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      max-height: 238px;
      &.no-data {
        color: var(--text-no-data);
        height: auto;
        font-size: $font-medium-bigger;
        @media screen and (max-width: 992px) {
          font-size: $font-root;
        }
      }
    }
    .box_list_search_select.active {
      display: block;
      opacity: 1;
    }
    .box_input_s {
      display: flex;
      width: 100%;
      border-radius: 8px;
      position: relative;
      background: var(--color-dark-5);

      .icon-search {
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);
      }
      .icon-up {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
      }
      .input_search_select {
        width: 94%;
        border: none;
        background: none;
        height: 46px;
        outline-offset: 0px;
        padding: 10px 15px;
        padding-left: 46px;

        font-family: $font-inter-regular;
        color: var(--text-primary-color);
        font-size: 18px;
        line-height: 22px;
        &::placeholder {
          color: var(--text-desc);
        }
      }
    }
    .list_search_select {
      padding: 7px 0;
      max-height: 210px;
      overflow-y: auto;
      margin: 0;
      li {
        padding: 8px 14px;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 10px;
        &:hover {
          background: var(--coin-input-hover);
          span {
            color: $color-caribbean-green;
          }
        }
        .icon_coin {
          display: inline-block;
          height: 26px;
          width: 26px;
          margin: 0;
          img {
            height: 26px;
            width: 26px;
            object-fit: contain;
            // border-radius: 50%;
          }
        }
        .code_coin {
          font-family: $font-inter-bold;
          color: var(--text-primary-color);
          font-size: 20px;
          line-height: 24px;
        }
        .full_name_coin {
          font-family: $font-inter-regular;
          color: var(--text-desc);
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }
}
::-webkit-scrollbar-track {
  background: $color-white;
}
::-webkit-scrollbar-thumb {
  background: $color-grey-medium;
}
::-webkit-scrollbar-thumb:hover {
  background: $color-grey-medium;
}

@media screen and (max-width: 992px) {
  .sc_search_select {
    .group_search_select {
      position: relative;
      .button_drop_search {
        .item_selected {
          font-size: 14px;
          line-height: 16px;
          ::v-deep {
            .icon_coin_select {
              width: 24px;
              height: 24px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .code_coin_select {
              font-size: 16px;
              line-height: 19px;
            }
            .full_name_coin_select {
              font-size: 14px;
              line-height: 16px;
            }
          }
        }
      }
      .box_input_s {
        .input_search_select {
          font-size: 14px;
          line-height: 16px;
        }
      }
      .list_search_select {
        li {
          .icon_coin {
            width: 24px;
            height: 24px;
          }
          .code_coin {
            font-size: 16px;
            line-height: 19px;
          }
          .full_name_coin {
            font-size: 14px;
            line-height: 16px;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
@import "@/assets/sass/variables";
.icon_coin {
  display: inline-block;
  height: 26px;
  width: 26px;
  margin-right: 10px;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
