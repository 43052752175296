<template>
  <div class="input-group">
    <input-text-number
      :placeholder="$t('funds.withdraw_usd.account_no')"
      class="form-input address"
      :class="{ error: errors.has('account_no') }"
      v-model="internalValue"
      @focus="$emit('focus')"
    />
    <span v-show="errors.has('account_no')" class="invalid-feedback">{{
      errors.first("account_no")
    }}</span>
  </div>
</template>

<script>
export default {
  inject: ["$validator"],
  data() {
    return {
      internalValue: "",
    };
  },
  props: {
    value: { required: true },
  },
  watch: {
    value(val) {
      this.internalValue = val;
    },
    internalValue(val) {
      if (this.internalValue === this.value) {
        return;
      }
      this.$emit("input", val);
    },
  },
  methods: {
    validate() {
      if (_.isEmpty(this.internalValue)) {
        this.errors.add(
          "account_no",
          window.i18n.t("bank_setting.error.lose_account_no")
        );
      }

      const accountNo = this.internalValue || "";
      const pattern = /^[0-9]{8,15}$/;
      // if (!pattern.test(accountNo)) {
      //   this.errors.add(
      //     "account_no",
      //     window.i18n.t("funds.withdrawals.errors.account_no_length", {
      //       field: "account no"
      //     })
      //   );
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
.form-input {
  &:focus {
    border: 1px solid $color-jungle-green !important;
  }
}
.address {
  padding-left: 15px;
  width: 100%;
  height: 45px;
  border: solid 1px var(--color-border-input-wdusdt);
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  // text-align: right;
  background-color: var(--bg-input);
  border-radius: 8px;
  color: var(--text-primary-color);

  &::placeholder {
    color: var(--text-close);
  }
}
</style>
