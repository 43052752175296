import BaseRequest from './base/BaseRequest'
import {ANNOUNCEMENT_ID} from "@/common/SiteSupport"
export default class ZendeskRequest extends BaseRequest {
    getArticles(category) {
        let url = '/zendesk/articles/' + category;
        return this.get(encodeURIComponent(url));
    }
    getArticlesAnnouncement() {
        let url = '/zendesk/articles/' + ANNOUNCEMENT_ID;
        return this.get(url);
    }
    autoLogin(routeName, email) {
        let url ='/support?return_to='+routeName+'&name='+email+'&email='+email;
        return this.get(url);
    }
}