<template>
  <div class="tab_entry" v-if="onTabEntry==='team-request'" id="team-request">
    <div class="data-table">
      <data-table4 class="datatable_contracts" ref="myTeamRequestList"
                   :msgEmptyData="$t('common.datatable.no_data')"
                   :limit="5" :getData="getTeamEntryRequest">
        <template slot-scope="props">
          <th  data-sort-field="email" class="w_25">
            {{$t('margin.entry.submitted_email_title') | upperFirst}}
          </th>
          <th data-sort-field="created_at" class="w_35">
            {{$t('mam.submited_at')}}
          </th>
          <th data-sort-field="status" class="w_20">
            {{$t('mam.status')}}
          </th>
          <th data-sort-field="note" class="w_20">
            {{$t('mam.note')}}
          </th>
          <th></th>
        </template>
        <template slot="body" slot-scope="props">
          <tr>
            <td>{{ props.item.email }}</td>
            <td>{{props.item.created_at | convertToLocalTime}}</td>
            <td>{{ Mam.getRequestStatus(props.item) }}</td>
            <td>
              <div class="item_name_setting font14">
                <span class="txt_email_setting font14">{{props.item.note}}</span>
                <span class="tooltip_name_setting font14">{{props.item.note}}</span>
              </div>
            </td>
            <td>
              <div class="as_rv_funds" v-if="props.item.status === 'submitted'">
                <button @click="confirmApproveRequest(props.item.id)" class="btn btn-approve">{{ $t('common.action.approve') }}</button>
                <button @click="confirmRejectRequest(props.item.id)" class="btn btn-reject">{{ $t('common.action.reject') }}</button>
              </div>
            </td>
          </tr>
        </template>
      </data-table4>
      <modal name="confirmRequestModal" width="560" id="confirmRequestModal">
        <template slot="body">
          <div class="fo_light_blue">
            <span class="icon-info2 icon-help2"></span>
            <h4 class="tit_modal_confir"> {{isReject ? $t('mam.confirm.reject') : $t('mam.confirm.approve')}}</h4>
          </div>
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
  import rf from '@/request/RequestFactory'
  import Mam from '@/pages/mam/common/mam.js';
  import Modal from '@/components/shared_components/common/Modal.vue';

  export default {
    name: "TeamRequestEntryList",
    components:{
      Modal,
    },
    props:{
      onTabEntry:{
        type: String,
      },
    },
    data:function(){
      return {
        Mam,
        isReject: false,
      };
    },
    methods:{
      getEventHandlers() {
        return {
          EntryNumberOfRequest: this.onEntryNumberOfRequest,
        };
      },
      onEntryNumberOfRequest (data) {
        this.$refs.myTeamRequestList.refresh();
      },
      getTeamEntryRequest(params){
        return rf.getRequest("EntryRequest").getEntryTeamRequestList(params);
      },
      acceptRequest(id){
        const params = {
          status:'approve',
          entry_request:id,
        };
        rf.getRequest("EntryRequest").changeStatusRequest(params).then((res)=>{
          if(res && res.data){
            this.showSuccess(this.$t('mam.msg.approve.success'));
          }
          this.$refs.myTeamRequestList.refresh();
        }).catch(error => {
          if (error && error.response && error.response.data) {
            if(error.response.data.message==='member_balance_not_enough'){
              this.showError(this.$t('entry.exception.msg.member_balance_not_enough'));
            }else if(error.response.data.message==='maximum_member_already'){
              this.showError(this.$t('entry.exception.msg.maximum_team_member'));
            } else{
              Message.error(this.$t('message.response.request_invalid'), {}, { position: 'bottom_left' });
            }
          }
        }).finally(()=>{
          window.CommonModal.hide('confirmRequestModal');
        })
      },
      rejectRequest(id){
        const params = {
          status:'reject',
          entry_request:id,
        };
        rf.getRequest("EntryRequest").changeStatusRequest(params).then((res)=>{
          if(res && res.data){
            this.showSuccess(this.$t('mam.msg.reject.success'));
          }
          this.$refs.myTeamRequestList.refresh();
        }).catch(error => {
          if (error && error.response && error.response.data) {
            Message.error(this.$t('message.response.request_invalid'), {}, { position: 'bottom_left' });
          }
        }).finally(()=>{
          window.CommonModal.hide('confirmRequestModal');
        })
      },
      confirmApproveRequest(id){
        this.isReject = false;
        window.CommonModal.show('confirmRequestModal', {
          buttons: [
            {
              label: this.$t('common.action.no'),
              class: 'btn btn-cancel btn-space btn-secondary',
              callback: () => {
                window.CommonModal.hide('confirmRequestModal');
              }
            },
            {
              label: this.$t('common.action.yes'),
              class: 'btn btn-confirm btn-space btn-secondary',
              callback: () => {
                this.acceptRequest(id);

              },
            }
          ]
        });
      },
      confirmRejectRequest(id){
        this.isReject = true;
        window.CommonModal.show('confirmRequestModal', {
          buttons: [
            {
              label: this.$t('common.action.no'),
              class: 'btn btn-cancel btn-space btn-secondary',
              callback: () => {
                window.CommonModal.hide('confirmRequestModal');
              }
            },
            {
              label: this.$t('common.action.yes'),
              class: 'btn btn-confirm btn-space btn-secondary',
              callback: () => {
                this.rejectRequest(id);
              },
            }
          ]
        });
      },
      showSuccess(message) {
        Message.success(message, {}, {position: 'bottom_left'});
      },
      showError(message) {
        Message.error(message, {}, {position: 'bottom_left'});
      },
    }
  }
</script>

<style scoped lang="scss">
  @import "@/assets/sass/variables";

  #team-request{
    .tableContainer{
      table{
        margin-top: 0 !important;
      }
      overflow-y: inherit;
      td{
        overflow: initial;
      }
    }
    .item_name_setting {
      display: inline-block;
      float: left;
      position: relative;
      .txt_email_setting {
        display: block;
        max-width: 300px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      &:hover {
        .tooltip_name_setting {
          transition: 0.5s;
          display: inline-block;
        }
      }
      .tooltip_name_setting{
        position: absolute;
        top: 0px;
        line-height: 20px;
        padding: 5px 20px;
        left: 100%;
        background-color: $color_white;
        word-break: break-all;
        white-space: pre-wrap;
        width: 300px;
        z-index: 10;
        font-size: $font_root;
        font-weight: 500;
        transition: 0.5s;
        display: none;
        box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);
        &:after {
          right: 100%;
          top: 50%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: rgba(136, 183, 213, 0);
          border-right-color: $color_white;
          border-width: 5px;
          margin-top: -5px;
        }
      }
    }
  }
</style>
<style lang="scss">
  @import "@/assets/sass/variables";

  #confirmRequestModal{
    .fo_light_blue{
      text-align: center;
    }
    .icon-help2, .icon-email3, .icon-money {
      font-size: 70px;
      color: $color-jungle-green;
      margin-top: 0px;
    }
    .modal-footer{
      table{
        background-color: inherit !important;
        td{
          &:first-child{
            text-align: right;
          }
          &:last-child{
            display: flex;
          }
        }
      }

      button{
        width: 30%;
        box-shadow: 0 1px 0 rgba(0,0,0,.05);
        border: 1px solid $color-jungle-green;
        padding: 10px 10px;
        font-size: $font-root;
        line-height: 14px;
        height: auto;
        border-radius: 3px;
        min-width: 100px;
        margin: 5px;
        font-weight: 700;
        text-transform: uppercase;
        background-color: $color-jungle-green;
        color: $color_white;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        &:hover, &:active, &:focus{
          background-color: $color-blue-custom;
          border-color: $color-blue-custom;
          -webkit-transition: 0.5s;
          transition: 0.5s;
        }
      }
    }

  }
</style>