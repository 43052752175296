<template>
  <modal :name="modalName" width="610">
    <template slot="body">
      <div slot="body" class="anti-phishing-modal">
        <div v-if="mode !== 'create'" class="d-flex justify-content-center image-anti">
          <img v-if="mode === 'success' || mode === 'change'" :src="require(`@/assets/images/icon/send-mail-success${
                    userThemeMode === 'light-theme' ? '' : '-dark'}.svg`)">
          <img v-if="mode === 'invalid'" :src="require(`@/assets/images/icon/warning-mail-invalid${
                    userThemeMode === 'light-theme' ? '' : '-dark'}.svg`)">
          <img v-if="mode === 'resend'" src="@/assets/images/icon/resend-email.svg">
        </div>

        <div class="w-100 text-center title-modal">
          <span v-if="mode === 'create'">{{ $t('anti.text_create') }}</span>
          <span v-if="mode === 'success'">{{ $t('anti.text_enable') }}</span>
          <span v-if="mode === 'change'">{{ $t('anti.text_change') }}</span>
        </div>
        <div v-if="mode === 'create'" class="input-form-phising position-relative">
          <label>{{ $t('anti_title') }}</label>
          <input type="text" class="form-control"
                 v-validate="{
                    required: true,
                    min: 4,
                    max: 20,
                    regex: /^[a-zA-Z0-9]*$/,
                  }"
                 maxlength="20"
                 v-model="params.code"
                 @focus="resetErrors"
                 name="code_anti_phishing"
                 :class="{ 'error': errors.has('code_anti_phishing') }"

          />
          <span class="recommend" :class="{ 'error': errors.has('code_anti_phishing') }">
            {{ $t('anti.text_anti_note') }}
          </span>
        </div>
        <div class="sub-title" v-if="mode !== 'create'">
          <span v-if="mode === 'success'">{{ $t('anti.code.success') }}</span>
          <span v-if="mode === 'change'">{{ $t('anti.code.change') }}</span>
          <span v-if="mode === 'invalid'">{{ $t('anti.code.invalid') }}</span>
          <span v-if="mode === 'resend'">{{ $t('anti.code.resend', { email }) }}</span>
        </div>
        <button v-if="mode === 'create'" class="btn btn-success" @click="sentMail()">{{ $t('anti.btn_submit') }}</button>
        <button v-else class="btn btn-success" @click="closeModal">
          <span v-if="mode === 'invalid' || mode === 'resend'">{{$t('common.action.ok')}}</span>
          <span v-else>{{ $t('common.action.close') }}</span>
        </button>

      </div>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/shared_components/common/Modal";
import rf from '@/request/RequestFactory'
import {mapState} from "vuex";

export default {
  name: "CreateAntiPhishingModal",
  components: {
    Modal
  },
  data() {
    return {
      modalName : 'CreateAntiPhishingModal',
      params: {
        code: ''
      },
    }
  },
  props: {
    mode: {
      type: 'create' | 'invalid' | 'success' | 'change' | 'resend',
      default: 'create'
    },
    email: ''
  },
  methods : {
    resetErrors() {
      if (this.errors.any()) {
        this.errors.clear();
      }
    },

   async sentMail() {
      const params = {
        is_anti_phishing: !!this.params.code,
        anti_phishing_code: this.params.code
      }
      this.resetErrors();
      await this.$validator.validate('code_anti_phishing');
      if (!params.is_anti_phishing) {
        return;
      }
      if (this.errors.any()) {
        return;
      }
      rf.getRequest('UserRequest').createAntiPhishing(params).then(() => {
        this.$router.push({name: 'EmailVerification', query: { code: this.params.code, type: 'create' }});
      }).catch(err => {
            Message.error('');
          })
    },

    closeModal() {
      window.CommonModal.hide('CreateAntiPhishingModal');
      if (this.mode === 'resend') {
        this.$emit(`RESENDMODAL:CLOSE`, true);
      }
    },
    resetParams() {
      this.params.code = '';
    },

  },
  computed : {
    ...mapState({
      userThemeMode: (state) => state.userThemeMode,
    }),
  },
  created(){
    this.$on('hideCommonModal', (modalName) => {
      this.resetParams();
      if (this.mode === 'success' || this.mode === 'change' || this.mode === 'invalid') {
        this.$router.push({name: 'SettingsSecurity'});
      }
      if (modalName !== this.modalName){
        return;
      }
    });
  },
  mounted() {

  },
}
</script>

<style lang="scss" scoped>
.dark-theme {
  .anti-phishing-modal {
    .sub-title {
      color: $color-auro-metal;
    }

    .title-modal {
      color: $color-white;
    }

    .input-form-phising {
      .recommend {
        color: $color-storm-grey;
        &.error {
          color: $text-color-red;
        }
      }
      label {
        color: $color-white;
      }
      input {
        background: $color-black-shark;
        color: $color-white;
        &.error {
          border: 1.5px solid $text-color-red;
        }
      }
    }

  }
}

  :deep(.modal .modal-dialog .modal-content .modal-body) {
    padding-bottom: 0;
  }

  .anti-phishing-modal {
    padding: 0 10px;

    .image-anti {
      margin-bottom: 24px;
    }

    .sub-title {
      margin: 40px 0;
      font-size: $font-big;
      color: $color-gray-dark;
      text-align: center;
      width: 100%;
    }

    .form-control {
      box-shadow: inset 0 0 0 rgb(0 0 0 / 8%);
    }

    .btn-success {
      background-color: $color-caribbean-green;
      border-radius: 8px;
      color: $dark-1;
      font-size: $font-big;
      width: 100%;
      border: none;
      height: 50px;
      font-weight: 400;
      text-transform: none;
      &:hover, &:active{
        background-color: $color-aquamarine;
      }
    }

    .title-modal {
      font-size: 34px;
      font-family: $font-inter-bold;
      font-weight: 600;
      color: $dark-1 ;
      margin-bottom: 40px;
    }

    .input-form-phising {
      margin-bottom: 40px;

      .recommend {
        font-size: $font-medium-bigger;
        color: $color-gray-dark;
        margin-top: 8px;
        &.error {
        color: $text-color-red;
        }
      }
      label {
        font-size: 18px;
        color: $dark-1;
        font-weight: 400;

      }
      input {
        background: $color-alabaster;
        height: 49px;
        border: none;
        border-radius: 8px;
        font-size: 18px;

        &.error {
          border: 1.5px solid $text-color-red;
        }
      }
    }

  }
  @media screen and (max-width: 992px) {
    .anti-phishing-modal {
      .title-modal {
        font-size: 20px;
        margin-bottom: 20px;
      }

      .input-form-phising {
        margin-bottom: 30px;
      }

      .sub-title {
        font-size: 14px;
        margin: 20px 0;
      }

      .btn-success {
        width: 160px;
        margin: 0 auto;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
      
      }
    }

    :deep(.modal .modal-dialog .modal-content .modal-body) {
      padding-left: 16px;
      padding-right: 16px;
    }

    :deep(.modal .modal-dialog .modal-content .modal-footer) {
      padding-bottom: 20px;
    }
  }
</style>