import { render, staticRenderFns } from "./marketPagination.vue?vue&type=template&id=591b80d9&scoped=true"
import script from "./marketPagination.vue?vue&type=script&lang=js"
export * from "./marketPagination.vue?vue&type=script&lang=js"
import style0 from "./marketPagination.vue?vue&type=style&index=0&id=591b80d9&prod&lang=scss&scoped=true"
import style1 from "./marketPagination.vue?vue&type=style&index=1&id=591b80d9&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "591b80d9",
  null
  
)

export default component.exports