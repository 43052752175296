<template>
  <div id="order_form" class="page_container clearfix">

    <div v-if="isAuth()" class="cleafix">
      <div @click="changeMode()" class="default_mode" v-bind:class="{'active_mode': showEasyMode}">{{ 'Easy' }}</div>
    </div>

    <div class="order-type">
      <div class="dropdown box_dropdown">
        <div class="tooltip_custom_c" v-if="hoverHelp">
          <template v-if="tab == 'limit'">
            {{$t('margin.limit_order_tooltip')}}
          </template>
          <template v-if="tab == 'market'">
            {{$t('margin.market_order_tooltip')}}
          </template>
          <template v-if="tab == 'stop_limit'">
            {{$t('margin.stop_limit_order_tooltip')}}
          </template>
          <template v-if="tab == 'stop_market'">
            {{$t('margin.stop_market_order_tooltip')}}
          </template>
          <template v-if="tab == 'trailing_stop'">
            {{$t('margin.trailing_stop_order_tooltip')}}
          </template>
          <template v-if="tab == 'take_profit_limit'">
            {{$t('margin.take_profit_limit_order_tooltip')}}
          </template>
          <template v-if="tab == 'take_profit_market'">
            {{$t('margin.take_profit_market_order_tooltip')}}
          </template>
        </div>
        <button class="btn form-control btn-order-form" type="button" data-toggle="dropdown" @mouseover="showHelp = true" @mouseleave="showHelp = false">
          <span class="left order-type-selected" v-if="tab == 'limit'">{{ $t('margin.limit') }}</span>
          <span class="left order-type-selected" v-if="tab == 'market'">{{ $t('margin.market') }}</span>
          <span class="left order-type-selected" v-if="tab == 'stop_limit'">{{ $t('margin.stop_limit') }}</span>
          <span class="left order-type-selected" v-if="tab == 'stop_market'">{{ $t('margin.stop_market') }}</span>
          <span class="left order-type-selected" v-if="tab == 'take_profit_limit'">{{ $t('margin.take_profit_limit') }}</span>
          <span class="left order-type-selected" v-if="tab == 'take_profit_market'">{{ $t('margin.take_profit_market') }}</span>
          <span class="left order-type-selected" v-if="tab == 'trailing_stop'">{{ $t('margin.trailing_stop') }}</span>
          <span class="left order-type-selected" v-if="tab == 'oco'">{{ $t('margin.oco') }}</span>
          <span class="left order-type-selected" v-if="tab == 'ifd'">{{ $t('margin.ifd') }}</span>

          <i class="icon-help" v-if="!(tab == 'ifd' || tab == 'oco') && showHelp" @mouseover="hoverHelp = true" @mouseleave="hoverHelp = false"></i>

          <span class="icon icon-arrow1"></span>
        </button>
        <ul class="dropdown-menu">
          <div v-for="item in tabList" v-on:click="tab = item" class="tab-element" v-bind:class="{'active' : tab === item}">
            <span class="text-size">{{ $t('margin.' + item) }}</span>
          </div>
          <!--
          <div v-on:click="tab = 'oco'" class="tab-element" v-bind:class="{'active' : tab === 'oco'}">
            <span class="text-size">{{ $t('margin.oco') }}</span>
          </div>
          <div v-on:click="tab = 'ifd'" class="tab-element" v-bind:class="{'active' : tab === 'ifd'}">
            <span class="text-size">{{ $t('margin.ifd') }}</span>
          </div>
          -->
        </ul>
      </div>
    </div>
  
    <div class="tab_content">
      <order-form-tabs :tab="tab" v-on:tabChange="handleTabChange"></order-form-tabs>
    </div>

 </div>
</template>
<script>
  import rf from '@/request/RequestFactory'
  import OrderFormTabs from './OrderFormTabs.vue';
  import Const from '@/common/Const';
  import { mapGetters, mapMutations, mapActions } from 'vuex';

  export default {
    components: {
      OrderFormTabs,
    },
    computed: {
      ...mapGetters(['contract', 'easyMode', 'allInstrument'])
    },
    data() {
      return {
        tab: 'limit',
        tabList: Const.MARGIN_ORDER_TAB_LIST,
        showHelp: false,
        hoverHelp: false,
        showEasyMode: false,
      };
    }, 
    methods : {
      ...mapMutations(['updateEasyMode']),
      ...mapActions(['updateLeverage']),
      handleTabChange(event) {
        this.tab = event;
      },
      isAuth () {
        return window.isAuthenticated;
      },
      async changeMode (value) {
        this.showEasyMode = !this.showEasyMode;
        if (!this.easyMode) { this.updateAllLeverage() };
        await this.updateEasyMode(!this.easyMode);
      },
      updateAllLeverage() {
        rf.getRequest('MarginRequest').updateToCross().then().catch(e => {})
      },
      getOrderTypeName() {
        const result = window._.find(this.getOrderTypes(), item => item.name === this.orderType);
        return result ? result.name : 'Limit';
      },
      getOrderTypes() {
        return [
          {name: 'Limit'},
          {name: 'Market'},
          {name: 'Stop Limit'},
          {name: 'Stop Market'},
          {name: 'Trailing Stop'},
          {name: 'Take Profit Market'},
          {name: 'Take Profit Market'},
          {name: 'Take Profit Limit'},
          {name: 'OCO'},
          {name: 'IFD'},
        ];
      },
    },
    created () {
      this.showEasyMode = this.easyMode;
    },
  };
</script>
<style lang="scss" scoped>
  @import '@/assets/sass/variables';

  .default_mode {
    user-select: none;
    padding-left: 30px;
    padding-bottom: 5px;
    margin-top: -15px;
    color: $color-denim;
    &:hover{
      cursor: pointer;
    }
  }

  .active_mode {
    color: $color-jungle-green;
  }

  .head_form_order {
    overflow: hidden;
    margin-bottom: 15px;
    ul {
      li {
        display: block;
        width: 50%;
        float: left;
        background-color: $color-charade;
        color: $color-grey;
        font-size: $font-root;
        line-height: 20px;
        height: 40px;
        text-align: center;
        padding: 10px;
        cursor: pointer;
        overflow: hidden;
        &.tab-buy {
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
          &.active{
            background-color: $color-jungle-green;
            color: $color-white;
          }
        }
        &.tab-sell {
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
          &.active{
            background-color: $color-denim;
            color: $color-white;
          }
        }
      }
    }
  }

  #order_form {
    .group_buy_sell_1 {
      display: block;
      margin-top: 3px;
      margin-bottom: 10px;
      width: 100%;
      .buy_left_1 {
        width: calc(50% - 2px);
        display: block;
        float: left;
        margin-right: 2px;
        border-radius: 3px;
        background-color: $color-jungle-green;
        cursor: pointer;
      }
      .header_sell_buy_1 {
        cursor: hand;
        text-transform: uppercase;
        font-size: $font-smaller;
        // border-bottom: 1px solid $color-white;
        text-align: center;
        color: $color-white;
        text-transform: uppercase;
        font-family: $font-family-roboto-bold;
        line-height: 20px;
        padding: 8px 5px;
        letter-spacing: 0.9px;
      }
    }
    display: block;
    width: 100%;
    padding: 13px 15px;
    .order-type {
      position: relative;
      margin-bottom: 12px;
      .box_dropdown {
        &.open {
          .icon-arrow1:before {
            content: "\E903";
            color: $color-jungle-green;
          }
        }
      }
      .btn-order-form {
        height: 40px;
        border-radius: 3px;
        border: solid 1px $color-bright-gray-dark;
        color: $color-grey;
        font-size: $font-small;
        line-height: 20px;
        padding: 9px 13px 9px 20px;
        background-color: #181f2b;
        text-align: left;
        width: 100%;
        overflow: initial;
        .icon {
          float: right;
          font-size: $font-mini;
          line-height: 22px;
          display: inline-block;
          position: absolute;
          top: 9px;
          right: 9px;
        }
      }
      .dropdown-menu {
        width: 100%;
        border-radius: 3px;
        box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.16);
        background-color: $color-white;
        padding: 10px 0px;
        .tab-element {
          cursor: pointer;
          display: block;
          width: 100%;
          line-height: 20px;
          padding: 5px 20px;
          font-size: $font-root;
          color: $color-grey-dark;
          &:hover {
            color: $color-jungle-green;
          }
        }
      }
    }
  }

  .icon-help {
    position: relative;
    top: 2px;
  }

  .tooltip_custom_c {
    width: 310px;
    background-color: #222832;
    color: #cccccc;
    padding: 5px 5px;
    border-radius: 4px;
    font-size: 13px;
    -webkit-box-shadow: 0px 0px 3px #cccccc;
    box-shadow: 0px 0px 3px #cccccc;
    position: absolute;
    z-index: 50;
    right: 10px;
    top: calc(100% + 2px);
    text-align: left;
  }

</style>