<template>
  <div>
    <modal :name="modalName" width="620">
      <template slot="body">
        <div slot="body" class="change-password-modal">
          <div class="text-change" v-show="!isShowOtpField">
            <div class="modal-title">{{ $t('change_password_form.title') }}</div>
          </div>
          <div class="icon" v-show="isShowOtpField">
            <img src="/images/icon-gg-authen.svg" class="icon-gg"/>
            <div class="modal-title">{{ $t('account.google_auth') }}</div>
          </div>

          <div class="row_form" v-show="!isShowOtpField">
            <div class="text-input">{{$t('change_password_form.old_password')}}</div>
            <div class="input-group">
              <input
                :maxlength="maxLengthInput"
                :type="isOpenEye1 ? 'text' : 'password'"
                @focus="resetErrors"
                data-vv-validate-on="none"
                v-validate="'required'"
                name="password"
                :data-vv-as="$t('change_password_form.old_password_change')"
                :placeholder="$t('change_password_form.palaceholder_old_password')"
                class="input"
                v-model="params.password"
                :class="{ error: errors.has('password') }">
                <img :src="`/images/${typeIcon1}.svg`" alt="icon" class="icon-eye" @click="switchOpenPassWord('isOpenEye1')">
              </div>
              <p class="invalid-feedback" v-if="errors.has('password')">
                {{ errors.first('password') }}
              </p>
          </div>
          <div class="row_form" v-show="!isShowOtpField">
            <div class="text-input">{{$t('change_password_form.new_password')}}</div>
            <div class="input-group tooltip_info_newpassword">
              <input
                :maxlength="maxLengthInput"
                :type="isOpenEye2 ? 'text' : 'password'"
                @focus="resetErrors"
                data-vv-validate-on="none"
                v-validate="{
                    required: true,
                    min: 8,
                    max: 72,
                    regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
                  }"
                name="new_password"
                :data-vv-as="$t('change_password_form.new_password_change')"
                :placeholder="$t('change_password_form.palaceholder_new_password')"
                class="input input_newpassword"
                v-model="params.new_password"
                :class="{ error: errors.has('new_password') }"
              />
              <img :src="`/images/${typeIcon2}.svg`" alt="icon" class="icon-eye" @click="switchOpenPassWord('isOpenEye2')">
              <div :class="errors.has('new_password') ? 'tooltip_info_has_error tooltip_info' : 'tooltip_info'">
                <div class="item">
                  <i :class="passwordValidate.checkedLength ? 'icon-check2' : 'icon-close'"></i>
                  <span class="text">{{ $t('validate.format.password_1') }}</span>
                </div>
                <div class="item">
                  <i :class="passwordValidate.checkedNumber ? 'icon-check2' : 'icon-close'"></i>
                  <span class="text">{{ $t('validate.format.password_2') }}</span>
                </div>
                <div class="item">
                  <i :class="passwordValidate.checkedUpperCase ? 'icon-check2' : 'icon-close'"></i>
                  <span class="text">{{ $t('validate.format.password_3') }}</span>
                </div>
                <div class="item">
                  <i :class="passwordValidate.checkedLowerCase ? 'icon-check2' : 'icon-close'"></i>
                  <span class="text">{{ $t('validate.format.password_4') }}</span>
                </div>
              </div>
            </div>
              <p class="invalid-feedback" v-if="errors.has('new_password')">
                {{ errors.first('new_password') }}
              </p>
          </div>
          <div class="row_form" v-show="!isShowOtpField">
            <div class="text-input">{{$t('change_password_form.confirm_new_password')}}</div>
            <div class="input-group">
              <input
                :maxlength="maxLengthInput"
                :type="isOpenEye3 ? 'text' : 'password'"
                @focus="resetErrors"
                data-vv-validate-on="none"
                v-validate="'required'"
                name="new_password_confirm"
                :data-vv-as="$t('change_password_form.confirm_new_password_change')"
                :placeholder="$t('change_password_form.palaceholder_confirm_new_password')"
                class="input"
                v-model="params.new_password_confirm"
                :class="{ error: errors.has('new_password_confirm') }">
                <img :src="`/images/${typeIcon3}.svg`" alt="icon" class="icon-eye" @click="switchOpenPassWord('isOpenEye3')">
              </div>
              <p class="invalid-feedback" v-if="errors.has('new_password_confirm')">
                {{ errors.first('new_password_confirm') }}
              </p>
          </div>

          <div class="row_form" v-show="isShowOtpField">
            <div class="input-group">
              <currency-input-otp
                autocomplete="off"
                @focus="resetErrors"
                data-vv-validate-on="none"
                v-validate="'required|length:6'"
                
                :placeholder="$t('change_password_form.palaceholder_authen_code')"
                v-model="params.otp"
                class="input"
                :class="{ error: errors.has('otp') }"
                name="otp"
                maxlength="6">
                </currency-input-otp>
                
              <p class="invalid-feedback" v-if="errors.has('otp')">
                {{ errors.first('otp') }}
              </p>
            </div>
            <div class="box-otp-gg">
                    <label class="form-check-label text-normal" @click="showModalLostOtp()">
                      {{ $t('shared_components.google_authentication_modal.lost_code') }}
                    </label>
            </div>
          </div>
          <div class="btn-group">
            <button name="btnSubmit" :disabled="isSubmitting" class="btn-change_password" @click="submit()">{{ $t('common.action.confirm') }}</button>
          </div>
        </div>
      </template>
    </modal>
  </div>
</template>
<script>
  import Modal from '@/components/shared_components/common/Modal';
  import rf from '@/request/RequestFactory'
  import AuthenticationUtils from '@/common/AuthenticationUtils';
  import COMMON_CONST from '@/common/Const';
  import CurrencyInputOtp from '@/components/shared_components/common/CurrencyInputOtp.vue';
  import { mapState } from 'vuex';

  export default {
    components: {
      Modal,
      CurrencyInputOtp,
    },
    data() {
      return {
        isShowing: false,
        isSubmitting: false,
        isShowOtpField: false,
        params: {
          otp: ''
        },
        modalName:'ChangePassword',
        maxLengthInput: COMMON_CONST.MAX_LENGTH_INPUT,
        otp_request: '',
        password_request: '',
        new_password_request: '',
        new_password_confirm_request: '',
        isChangedPassword: false,
        isOpenEye1: false,
        isOpenEye2: false,
        isOpenEye3: false,
        passwordValidate: {
          checkedLength: false,
          checkedNumber: false,
          checkedUpperCase: false,
          checkedLowerCase: false,
        },
      }
    },
    computed: {
      ...mapState({
        theme: state => state.userThemeMode,
      }),
      typeIcon1() {
        if(this.theme === 'light-theme') {
          return this.isOpenEye1 ? 'eye-open' : 'eye-close';
        } else {
          return this.isOpenEye1 ? 'eyes-open-dark' : 'eyes-close-dark';
        }
      },
      typeIcon2() {
        if(this.theme === 'light-theme') {
          return this.isOpenEye2 ? 'eye-open' : 'eye-close';
        } else {
          return this.isOpenEye2 ? 'eyes-open-dark' : 'eyes-close-dark';
        }
      },
      typeIcon3() {
        if(this.theme === 'light-theme') {
          return this.isOpenEye3 ? 'eye-open' : 'eye-close';
        } else {
          return this.isOpenEye3 ? 'eyes-open-dark' : 'eyes-close-dark';
        }
      },
    },
    watch: {
      'params.otp' (newVal) {
        if (window._.size(newVal) === 6) {
          this.submit();
        }
      },
      async 'params.new_password'() {
        this.validateInputNewPassword();
        this.checkNewPasswordFormat();
      }
    },
    methods: {
      checkNewPasswordFormat() {
        const password = this.params.new_password.toString().trim();
        this.passwordValidate.checkedLength = password.length >= 8;
        this.passwordValidate.checkedNumber = /\d/.test(password);
        this.passwordValidate.checkedUpperCase = /[A-Z]/.test(password);
        this.passwordValidate.checkedLowerCase = /[a-z]/.test(password);
      },
      resetErrors() {
        if (this.errors.any()) {
          this.errors.clear();
        }
      },

      getParams() {
        return {
          'password': this.password_request,
          'new_password': this.new_password_request,
          'new_password_confirm': this.new_password_confirm_request,
          'otp': this.otp_request,
        };
      },

      async submit() {
        this.resetErrors();
       
        await this.$validator.validate('password');
        if(this.params.new_password) {
          await this.validateInputNewPassword();
        } else {
          await this.$validator.validate('new_password');
        }
        await this.$validator.validate('new_password_confirm');

        if (this.params.password === this.params.new_password) {
          this.errors.add('new_password', this.$t('change_password_form.error_duplicated_password'));
        }
        if (this.params.new_password !== this.params.new_password_confirm) {
          this.errors.add('new_password_confirm', this.$t('change_password_form.error_confirm'));
        }

        this.password_request = this.params.password;
        this.new_password_request = this.params.new_password;
        this.new_password_confirm_request = this.params.new_password_confirm;
        if (this.isShowOtpField) {
          if (this.params.otp.length < 6 && this.params.otp.length > 0) {
            this.errors.add('otp', this.$t('funds.withdrawals.errors.otp_length_6_digits'));
          }

          else if (this.params.otp.length === 0) {
            this.errors.add('otp', this.$t('funds.withdrawals.errors.otp_required'));
          }
      }

        if (this.errors.any()) {
          $('button[name="btnSubmit"]').blur();
          return false;
        }

        this.otp_request = this.params.otp;

        if(this.errors.any() && this.isShowOtpField){
          this.params.password = '';
          this.params.new_password = '';
          this.params.new_password_confirm = '';
          this.params.otp = '';
        }

        this.changePassword(this.getParams());
      },

      changePassword(params) {
        this.isSubmitting = true;
        rf.getRequest('UserRequest').changePassword(params).then(res => {
          this.onChangePassword();
        }).catch(error => {
          let errorsOTP = error.response.data.errors;
          this.isSubmitting = false;
          if (error.response.status === 422) {
            if (error.response.data.errors.password) {
              this.errors.add('password', this.$t('validation.custom.password.correct_password'))
            }
            else if ( errorsOTP.otp[0] === 'validation.correct_otp' ||  errorsOTP.otp[0]  === 'validation.otp_not_used' ) {
              this.errors.add('otp', this.$t(`${error.response.data.message}`))
            }
            else this.onError(error.response.data.errors || []);
          } else {
            Message.error(window.i18n.t('common.message.network_error'));
          }
        });
      },
      showModalLostOtp () {
        window.CommonModal.show('lostOtpForm');
      },

      onChangePassword() {
        this.params = {};
        this.isSubmitting = false;
          window.CommonModal.hide(this.modalName);
          this.logout();
      },

      logout () {
        this.isChangedPassword = true;
        this.$store.commit('setAuthentication' , '')
        window.isAuthenticated = false
        this.$router.push({
          name: 'Landing Page',
          params: { isChangedPassword: this.isChangedPassword }
        });
      },

      onError(errors) {
        this.errors.clear();
        _.forEach(errors, (messages, field) => {
          _.forEach(messages, (message) => {
            if (typeof message !== 'undefined' && typeof message === 'string') {
              this.errors.add(field, message.replace('{attribute}', window.i18n.t('change_password_form.' + field)));
            }
            else {
              this.errors.add(field, message);
            }
          });
        });
        if (this.errors.count() === 1 && this.errors.has('otp') && !this.isShowOtpField) {
          this.errors.remove('otp');
          this.isShowOtpField = true;
        }
      },

      clearInput () {
        Object.assign(this.params, {
          otp: '',
          password: '',
          new_password: '',
          new_password_confirm: '',
        });
      },

      resetData() {
        this.isSubmitting = false;
        this.isShowOtpField = false;
        this.params = {
          otp: ''
        };
        this.isChangedPassword = false;
      },
      getEventHandlers() {
        return {
          showCommonModal: this.onModalShowing,
        };
      },
      onModalShowing(modalName) {
        if(modalName == 'ChangePassword') {
          this.isShowing = true;
        }
      },
      switchOpenPassWord(typeChange) {
        switch (typeChange) {
          case 'isOpenEye1':
            this.isOpenEye1 = !this.isOpenEye1
            break;
          case 'isOpenEye2':
            this.isOpenEye2 = !this.isOpenEye2
            break;
          case 'isOpenEye3':
            this.isOpenEye3 = !this.isOpenEye3
            break;
        }
      },
      async validateInputNewPassword() {
        if (!(await this.$validator.validate('new_password'))) {
          this.errors.items.forEach((item, index) => {
            if(item.field === 'new_password') {
              this.errors.items[index].msg = this.$t('change_password_form.new_password_error')
            }
          })
        }
      },
      preventSpace(event) {
        const key = event.keyCode;
        if (key === 32) {
          event.preventDefault();
        }
      }
    },
    created(){
      this.$on('hideCommonModal', (modalName) => {
        if (modalName !== this.modalName){
          return;
        }
        this.isShowing = false;
        this.clearInput();
      });
    },
    mounted() {
      window.addEventListener('keyup', (event) => {
        if (event.keyCode === 13 && this.modalName == 'ChangePassword' && this.isShowing) { 
          this.submit();
        }
      });
      window.addEventListener('keypress', this.preventSpace);
    },
    beforeDestroy() {
      window.removeEventListener('keypress', this.preventSpace);
    }
  }
</script>
<style lang="scss">
  @import "@/assets/sass/variables";

  #ChangePassword {

    .input {
      padding: 14px 50px 15px 15px;
      background: var(--bgr-hover-row);
      border-radius: 8px;
      font-family: $font-inter-regular;
      font-size: 18px;
      line-height: 21px;
      
      border: none;
      width: 100%;
      color: var(--text-primary-color);
      &::placeholder {
         color: var(--text-close);
      }
      &.error {
        border: 1.5px solid $text-color-red;
      }
    }
    .modal-header {
      padding: 20px 30px 0;

      .icon-close{
        &::before {
        color: var(--color-icon-close);
      }
      }
    }
    .modal-body {
      padding: 0px 50px;
    }
    .input-group {
      position: relative;
      margin-bottom: 0;
      .icon-eye {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
      }
    }
    .icon-close {
     
      &::before {
        color: $text-color-red;
      }
    }
  }
  @media screen and (max-width: 992px) {
    #ChangePassword {
      
      .modal-header {
        padding: 15px;

        .icon-close {
          width: 14px;
        }
      }
      .modal-body {
        padding: 0px 15px;
      }
      .input {
        font-size: 14px;
        line-height: 16px;
        &::placeholder {
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {}
  @media screen and (max-width: 576px) {
    #ChangePassword {
      
      .input {
        &::placeholder {
          font-size: 13px;
          line-height: 16px;
        }
      }
    }
  }
</style>
<style lang="scss" scoped>
  @import "@/assets/sass/variables";
  .group_center_option{
    padding-top: 5px;
    text-align: right;
    a{
      font-size: $font-small;
      color: $color-blue-custom;
      font-family: $font-family-roboto;
    }
  }
  .modal-title {
    font-family: $font-inter-bold;
    font-size: 34px;
    line-height: 40px;
    color: var(--text-primary-color);
    padding-bottom: 15px;
    text-align: center;
  }

  .change-password-modal {
    position: relative;
    .icon-gg{
      padding-bottom: 25px;
    }
    .icon {
      text-align: center;
      span {
        font-size: 50px;
        color: $color-eden;
      }
      h3 {
        font-size: $font-title-size-small-25;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 30px;
        letter-spacing: normal;
        color: $color-eden;
        margin-bottom: 10px;
        margin-top: 10px;
        width: 60%;
      }
    }
    label {
      font-weight: normal;
      color: $color-black;
      margin-bottom: 0px;
    }
    .note_header {
      color: $color-black;
      line-height: 24px;
      margin-bottom: 16px;
    }
    .row_form {
      margin-top: 24px;
      position: relative;
      .text-input {
        font-family: $font-inter-regular;
        font-size: 18px;
        line-height: 22px;
        color: var(--text-primary-color);
        margin-bottom: 8px;
      }
      .tooltip_info_newpassword {
        position: relative;
        .tooltip_info {
          display: none;
          position: absolute;
          top: 0;
          right: 0;
          transform: translateX(calc(100% + 11px));
          padding: 10px;
          background: rgba(25, 25, 25, 0.8);
          border-radius: 8px;
          .item {
            display: flex;
            align-items: center;
            gap: 12px;
            & + .item {
              margin-top: 8px;
            }
            i::before {
              color: $color-aqua-green;
            }
            .text {
              font-family: $font-inter-regular;
              color: $color-white;
              font-size: 18px;
              line-height: 21px;
            }

          }
        }
        .input_newpassword:focus {
          ~ .tooltip_info {
            display: block;
          }
        }
      }
    }

    .btn-group {
      display: block;
      margin-top: 40px;
      .btn-change_password {
        @include btn-common(yes);
        width: 100%;
      }
    }
    .invalid-feedback {
      font-size: 14px;
      line-height: 17px;
      color: $text-color-red;
      font-family: $font-inter-regular;
      margin: 6px 0 0;
    }
  }
  .input-group-prepend {
    height: 100%;
    span {
      line-height: 44px;
    }
  }
  .box-otp-gg {
    height: 20px;
    margin-top: 8px;
    //margin-bottom: 30px;
    font-family: $font-inter-regular;
    font-size: $font-medium-bigger;
    text-align: right;
    width: 100%;
    label {
      text-align: right;
      font-size: 16px;
    line-height: 19px;
    color: $color-aqua-green;
    cursor: pointer;
    }
  }
  @media screen and (max-width: 992px) {
    .modal-title {
      font-size: 20px;
      padding-bottom: 0;
    }
    .change-password-modal {
      .row_form {
        margin: 20px 0 0;

        .text-input {
          font-size: 14px;
          line-height: 16px;
        }
        .tooltip_info_newpassword {
          .tooltip_info {
            right: 103%;
            width: 100%;
            z-index: 1;
            top: 55px;

            .item {
              .text {
                font-size: 16px;
                line-height: 19px;
              }
            }
          }
          .tooltip_info_has_error{
            top: 100px;
          }
        }
      }
      .btn-group {
        margin-top: 30px;
      }
      .box-otp-gg {
        label {
          font-size: 14px;
          line-height: 16px
        }
      }
      .icon-gg {
        padding-bottom: 20px;
      }
    }
  }
  @media screen and (max-width: 768px) {}
  @media screen and (max-width: 576px) {}
</style>