<template>
  <div class="box-table">
    <div id="container-tab" class="clearfix">
      <!-- <h2 class="market-title" v-if="!shortNameTab">
                <i class="icon-24h"></i>
                <span>{{ $t("common.spot_exchange") }}</span>
                {{ $t("common.market_info") }}
            </h2> -->
      <div class="clearfix group_table_landing">

        <label v-for="item in marketTabMain" :key="item" @click="showTabMain(item)" class="market" :class="tabMain === item ? 'current-market' : ''">
            <i v-if="item === 'Favorites'" class="icon-star2"></i>
            {{ $t('landing.title_' + item) }}
            
          </label>

        <div class="search__coin">
          <input
            type="text"
            :placeholder="$t('landing.search')"
            class="visible input-search"
            v-model="searchKey"
          />

          <img
            class="icon-search"
            :src="require(`@/assets/images/landing/icon-search${theme === 'light-theme'? '': '-dark'}.svg`)"
            width="19"
            height="19"
          />
        </div>
      </div>
      <div class="box-tab position-relative" id="box-tab">
        <div class="container-tab" v-if="tabMain === 'Spot'" id="spot-tab" ref="scrollContainer">
          <template v-for="market in markets"  >
            <template v-if="market.status === 'active'">
              <label
                :for="`tab-${market.currency}`"
                :class="
                  selectedMarket === market.currency ? 'active-tab item-favorite' : 'item-favorite'
                "
                :key="market.currency"
                v-on:click="showMarket(market.currency)"
                v-if="shortNameTab"
                >{{ market.currency | uppercase }}</label
              >
              <label
                :for="`tab-${market.currency}`"
                :class="
                  selectedMarket === market.currency ? 'active-tab item-favorite'  : 'item-favorite'
                "
                :key="market.name"
                v-on:click="showMarket(market.currency)"
               
                v-else
                >{{
                  $t("landing.coin_markets", {
                    coin: toUpperCase(market.currency),
                  })
                }}</label
              >
            </template>
          </template>
          <div v-if="showScrollLeftIcon" class="position-absolute btn-left d-xl-none d-block icon-arrow4" @click="clickScroll('btn-right')" id="btn-left"></div>
        <div v-if="showScrollRightIcon" class="position-absolute btn-right d-xl-none d-block icon-arrow3" @click="clickScroll('btn-left')" id="btn-right"></div>
        </div>
        <div class="container-tab" v-else-if="tabMain === 'Favorites'">
          <label @click="showType = CONSTS.TYPE_SPOT" class="tab" :class="showType === CONSTS.TYPE_SPOT ? 'active-tab item-favorite' : 'item-favorite' ">{{ $t('landing.title_Spot')}}</label>
          <label @click="showType = CONSTS.TYPE_FUTURE" class="tab" :class="showType === CONSTS.TYPE_FUTURE ?'active-tab item-favorite' : 'item-favorite'">{{ $t('landing.title_Futures')}}</label>
        </div>
        <div class="container-tab" v-else>
          <label @click="showMarket(CONSTS.TYPE_USDT_CONTRACTS)" class="tab" :class="selectedMarket === CONSTS.TYPE_USDT_CONTRACTS ? 'active-tab item-favorite' : 'item-favorite' ">
            {{ $t('landing.future.usdt_contracts')}}
          </label>
          <label @click="showMarket(CONSTS.TYPE_USD_CONTRACTS)" class="tab" :class="selectedMarket === CONSTS.TYPE_USD_CONTRACTS ?'active-tab item-favorite' : 'item-favorite'">
            {{ $t('landing.future.usd_contracts')}}
          </label>
        </div>
      </div>

      
    </div>

    <div class="table-content">
      <template v-if="(showType === CONSTS.TYPE_FUTURE && tabMain === 'Favorites' )">
        <div id="content">
          <market-coin
            :priceScope="futureFilter"
            :favorites="favoritesFuture"
            :marketSelected="showType"
            :columns="columns"
            :coinNames="searchKey.toLowerCase().trim()"
            @selectedPairMarket="onSelectedPair"
            :tabMain="tabMain"
            :futuresTable="true"
            :key="tabMain"
          >
          </market-coin>
        </div>
      </template>

      <div id="content" v-else>
        <div id="content-future" v-if="tabMain === 'Futures'">
          <market-pagination
            :favorites="favoritesFuture"
            :marketSelected="selectedMarket"
            :columns="columns"
            :coinNames="searchKey.toLowerCase().trim()"
            @selectedPairMarket="onSelectedPair"
            :tabMain="tabMain"
            :futuresTable="true"
            :key="tabMain"

          >
          </market-pagination>
        </div>
        <div id="market-coin-1"  v-else>
          <market-pagination
              :favorites="favorites"
              :marketSelected="selectedMarket"
              :coinNames="searchKey.toLowerCase().trim()"
              :columns="columns"
              @selectedPairMarket="onSelectedPair"
              :tabMain="tabMain"
              :allPriceCoinsData="allPriceCoinsData"
              :key="tabMain"
              
          ></market-pagination>
        </div>
      </div>

      
      
    </div>
  </div>
</template>

<script>
import rf from "@/request/RequestFactory";
import BigNumber from "bignumber.js";
import MarketCoin from "./MarketCoin";
import FavoritesManager from "@/common/FavoritesManager";
import Const from "@/common/Const";
import {mapState} from "vuex";
import Cookies from 'js-cookie'
import MarketFuture from "./TabFuture.vue";
import MarketPagination from "@/pages/landing/marketPagination";

const TYPE_SPOT = 'Spot';
const TYPE_FUTURE = "Future";
const TYPE_USDT_CONTRACTS = 'USD_M';
const TYPE_USD_CONTRACTS = "COIN_M";

export default {
  components: {
    MarketPagination,
    MarketCoin,
    MarketFuture,
  },
  props: {
    defaultMarket: { type: String, default: Const.FAVORITES_TAB },
    columns: {
      type: [Array],
      default: () => {
        return [];
      },
    },
    shortNameTab: { type: Boolean, default: false },
    callback: { type: Function, required: false },
    isComingSoon : {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      favorites: [],
      favoritesFuture: [],
      searchKey: "",
      priceScope: {},
      pricesFilter: {},
      sortColumn: "coin",
      isSortDesc: false,
      isShowSearchField: false,
      markets: [],
      selectedMarket: "usdt",
      tabFavorites: Const.FAVORITES_TAB,
      marketTabMain: Const.MARKET_TAB_MAIN,
      marketTabChildren: Const.FAVORITES_TAB_CHILDREN,
      showType: 'Spot',
      tabChildrenFuture: TYPE_USDT_CONTRACTS ,
      CONSTS: {
        ...Const,
        TYPE_SPOT: TYPE_SPOT,
        TYPE_FUTURE: TYPE_FUTURE,
        TYPE_USDT_CONTRACTS: TYPE_USDT_CONTRACTS,
        TYPE_USD_CONTRACTS: TYPE_USD_CONTRACTS,
      },

      tabMain: this.$route.query.tabMain || 'Spot',
      theme: Cookies.get("user-theme", { domain: process.env.VUE_APP_PUBLIC_URL }) || 'dark-theme',
      key : 1,
      futureFilter: {},
      allPriceCoinsData: [],
      // coinNames: {},
      showScrollLeftIcon: false,
      showScrollRightIcon: true,
      lastPage: 0,
    };

  },
  computed: {
    ...mapState({
      masterdata: (state) => state.masterdata,
      userThemeMode: state => state.userThemeMode
    }),
    getPairFavorites() {
      return window._.chain(this.favorites)
          .map((item) => item.coin_pair)
          .value();
    },
    coinNames() {
      const fullCoinNames = {};

      _.forEach(this.masterdata?.coins, (item, key) => {
        fullCoinNames[item.coin] = item.name;
      });
      return { ...fullCoinNames };
    },
  },
  watch: {
    userThemeMode(theme) {
      this.theme = theme;
    },
    selectedMarket(newMarket) {
      // Apply search key if have when change tabs
      if (!window._.isEmpty(this.searchKey)) {
        this.search();
        return;
      }
      const result = window._.chain(this.pricesScope)
          .filter((item) => {
            return this.filterCoinByTab(item);
          })
          .orderBy(this.sortColumn, [this.isSortDesc ? "desc" : "asc"])
          .value();
      this.pricesFilter = Object.assign({}, result);
      this.getFavorites()
    },

    // searchKey: function () {
    //   this.search();
    // },
    masterdata() {
      // _.forEach(this.masterdata.coins, (item, key) => {
      //   this.coinNames[item.coin] = item.name;
      // });
      // this.coinNames = {...this.coinNames}
      const removeUsd = this.masterdata?.market_settings?.filter(item => item.currency !== 'usd' && item.currency !== 'eth' && item.currency !== 'btc')
      this.markets = removeUsd;
    },

  },
  methods: {
    isPagination(value) {
      this.lastPage = value
    },
    scrollToMarket(){
      const scroll = this.$route.query.scroll;
      if(scroll) {
        const el = document.getElementById('market-coin-1');
        setTimeout(() => {
          if (el) {
            el.scrollIntoView({behavior: "smooth"});
          }
        }, 500);

      }
    },
    onSelectedPair(pair) {
      if (this.callback) {
        return this.callback();
      }
      // Handle changed pair if need.
    },
    search() {
      let coinNames = this.coinNames;
      const keyWord = window._.toLower(this.searchKey.trim().toLowerCase());
      _.debounce(function() {
        this.pricesFilter = window._.chain(this.pricesScope)
            .filter((item) => {
              const filterByTab = this.filterCoinByTab(item);
              return (coinNames[item.coin]?.trim().toLowerCase().includes(keyWord) ||
                  (filterByTab && item.coin.includes(keyWord))
              );
            })
            .orderBy(this.sortColumn, [this.isSortDesc ? "desc" : "asc"])
            .value();
      },500)
    },

    filterCoinByTab(item) {
      // Market coin tab
      let filterByTab = item.currency === this.selectedMarket;

      // Favorites tab
      if (this.tabFavorites === this.selectedMarket) {
        const pair = `${item.coin}/${item.currency}`;
        filterByTab = this.getPairFavorites.indexOf(pair) > -1;
      }
      return filterByTab;
    },

    toUpperCase(value) {
      if (!value) {
        return "";
      }
      return value.toUpperCase();
    },

    showMarket(market) {
      window.localStorage.removeItem('favorite_pairs_authenticated');
      this.selectedMarket = market;
      if(this.tabMain === "Spot") {
        this.$nextTick(() => {
          const container = this.$refs.scrollContainer;
          const button = container.querySelector('.active-tab');

          if (container && button) {
            const containerWidth = container.offsetWidth;
            const buttonOffset = button.offsetLeft;
            const buttonWidth = button.offsetWidth;
            const scrollPosition = buttonOffset - (containerWidth / 2) + (buttonWidth) - (containerWidth / 4);

            container.scrollTo({
              left: scrollPosition,
              behavior: 'smooth' // Optional: Add smooth scrolling effect
            });
          }
        });
      }
    },
    showTabMain(tabMain) {
      this.tabMain = tabMain;
      // this.changeTab= tabMain
      this.showMarket(tabMain)
      switch (tabMain) {
        case 'Spot':
          this.showMarket('usdt');
          break;
        case 'Favorites':
          this.showMarket('Favorites');
          break;
        case 'Futures':
          this.showMarket(this.CONSTS.TYPE_USDT_CONTRACTS);
          break;
        default:
          return '';
      }
    },


    getSocketEventHandlers() {
      return {
        PricesUpdated: this.onPricesUpdated,
        OrderTransactionCreated: this.onOrderTransactionCreated,
      };
    },

    onPricesUpdated(newPrices) {
      this.prices = Object.assign({}, this.prices, newPrices);

      window._.each(this.pricesScope, (item) => {
        if(item.currency == 'usd' && item.coin =='usd') {
          return;
        }
        const coinPrice = newPrices[item.currency + "_" + item.coin];
        if (!coinPrice) {
          return;
        }

        const latestPrice = new BigNumber(`${coinPrice.price || 0}`);

        if (latestPrice.gt(`${item.max_price || 0}`)) {
          item.max_price = coinPrice.price;
        }
        if (latestPrice.lt(`${item.min_price || 0}`)) {
          item.min_price = coinPrice.price;
        }

        if (item.current_price) {
          let result = latestPrice.sub(`${item.current_price || 0}`).toString();
          this.$set(item, "changePrice", result);
        }

        item.changed_percent = coinPrice.change;
        item.previous_price = coinPrice.previous_price;

        item.current_price = latestPrice.toString();
        item.usdPrice = this.toUsdPrice(item.current_price, item.currency);
      });
    },

    onOrderTransactionCreated(data) {
      const pair = `${data.orderTransaction.currency}_${data.orderTransaction.coin}`;

      if (!this.pricesScope) {
        return;
      }

      const priceScope = this?.pricesScope[pair];
      if (priceScope) {
        priceScope.volume = new BigNumber(`${priceScope.volume || 0}`).add(
            `${data.orderTransaction.quantity}`
        );
      }
    },

    toUsdPrice(price, fromCurrency) {
      return new BigNumber(price)
          .mul(new BigNumber(this.getPrice("usd_" + fromCurrency).price))
          .toString();
    },

    getPrice(pair) {
      return this.prices[pair] || { price: 1 };
    },

    futureData(res) {
      const data =[]
      res.forEach(items => {
        data.push(
            {
              coin: items.symbol,
              current_price: items.lastPrice,
              lowPrice: items.lowPrice,
              max_price: items.highPrice,
              changed_percent: items.priceChangePercent,
              lastPriceChange: items.lastPriceChange || 0,
              volume: items.volume,
              quoteVolume: items.quoteVolume,
              contractType: items.contractType
            }
        )
      })
      this.futureFilter = data
    },

    calculateUsdPrice() {
      window._.forEach(this.pricesScope, (item) => {
        item.usdPrice = this.toUsdPrice(item.current_price, item.currency);
      });
    },

    getFavorites() {
      FavoritesManager.getFutureFavorites().then((data) => {
        this.favoritesFuture = data;
      });
      FavoritesManager.getFavorites().then((data) => {
        this.favorites = data;
      });
    },

    initData() {
      const removeUsd = this.masterdata?.market_settings?.filter(item => item.currency !== 'usd' && item.currency !== 'eth' && item.currency !== 'btc')
      this.markets = removeUsd;
      this.getFavorites();

      rf.getRequest("PriceRequest")
          .getPrices()
          .then((res) => {
            this.onPricesUpdated(res.data);
          });

      rf.getRequest("PriceRequest")
          .getPrices24h()
          .then((res) => {
            this.pricesScopeUpdated(res.data);
            // this.selectedMarket = this.getSelectTabViaRoute();
          });

      return rf.getRequest("PriceRequest")
          .get24hFuture()
          .then((res) => {
            this.futureData(res.data)
          })
    },

    getSelectTabViaRoute() {
      const query = this.$route.query || {};
      return query.tab || this.defaultMarket;
    },


    pricesScopeUpdated(newPrices) {
      this.pricesFilter = this.pricesScope = newPrices;
      this.calculateUsdPrice();
    },
    checkScrollPosition() {
      const container = document.getElementById('spot-tab');

      // Check if the container has horizontal overflow
      const hasOverflow = container.scrollWidth > container.clientWidth;

      // Check if the container is scrolled to the left
      const scrolledToLeft = container.scrollLeft === 0;

      // Check if the container is scrolled to the right
      const scrolledToRight = container.scrollLeft === (container.scrollWidth - container.clientWidth);

      // Update the showScrollLeftIcon and showScrollRightIcon based on the conditions
      this.showScrollLeftIcon = hasOverflow && !scrolledToLeft;
      this.showScrollRightIcon = hasOverflow && !scrolledToRight;
    },
    clickScroll(id) {
      const content = document.getElementById('spot-tab');
      const content_scroll_width = content.scrollWidth;
      let content_scoll_left = 0;
      if (id === 'btn-left') {
        content_scoll_left += 100;
        if (content_scoll_left >= content_scroll_width) {
          content_scoll_left = 0;
        }
      } else {
        content_scoll_left -= 100;
      }
      content.scrollTo({
        left: content.scrollLeft + content_scoll_left,
        behavior: 'smooth'
      });
    },
    price24h () {
      rf.getRequest("PriceRequest")
        .getPrices24h()
        .then((res) => {
          this.allPriceCoinsData = res.data;
        });
    }
  },
  created() {
    if(this.$route.query.tabMain === 'Futures') {
      this.selectedMarket =  this.$route.query.market || this.CONSTS.TYPE_USDT_CONTRACTS
    }
  },
  mounted() {
    this.price24h();
    this.initData();
    this.scrollToMarket()
    if(this.tabMain === 'Spot') {
      const container = document.getElementById('spot-tab');
      container.addEventListener('scroll', this.checkScrollPosition);
    }
  },
};
</script>


<style lang="scss">
.VuePagination{
  padding-top: 41px;
}
</style>
<style lang="scss" scoped>
@import "@/assets/sass/variables";
.dark-theme {
  #spot-tab {
    .icon-arrow3, .icon-arrow4  {
      &::before {
        color: $color-auro-metal;
      }
    }
  }
 }

.box-table .table-content {
  margin-top: 20px;
  min-height: 300px;
  overflow: auto;
}
#markets_table_landing {
  padding: 30px 30px 150px;
}
.block {
      display: block;
}
    .none {
      display: none;
}

.clearfix {
  content: "";
  clear: both;
  display: table;
}
.box-table {
  #container-tab {
    
    display: block;
    margin: 0 auto;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    &::-webkit-scrollbar {
        display: none;
      }
    .market-title {
      font-family: $font-family-roboto-semibold;
      font-size: $font-title-size-medium;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.14;
      letter-spacing: normal;
      text-align: center;
      color: $color-eden;
      margin-bottom: 57px;
      position: relative;
      span {
        font-family: $font-family-roboto-bold;
        position: relative;
        padding-left: 10px;
      }
      span.bold {
        font-weight: bold;
        padding-left: 0;
      }
      i {
        position: relative;
        top: 9px;
        font-size: 50px;
        display: inline-block;
      }
    }
    .group_table_landing {
      display: flex;
      position: relative;
    }

    .current-market {
      color: var(--text-primary-color);
    }
    .current-market::after {
      width: 100%;
      height: 4px;
      content: "";
      background: $color-caribbean-green;
      position: absolute;
      bottom: 0;
      left: 0;
      border-radius: 20px;
    }

    label {
      cursor: pointer;
      display: inline-block;

      padding: 0 0 10px 0;
      background: transparent;
      border: 0px;
      margin: 0px;
      font-family: $font-inter-regular;
      font-size: $font-big-20;
      letter-spacing: normal;
      color: var(--text-close);
      position: relative;
      margin-right: 40px;
      font-weight: 400;
      .icon-star2 {
        padding-top: 1px;
        display: block;
        width: 20px;
        height: 20px;
        float: left;
        margin-right: 5px;
        color: $color-yellow;
      }
      .icon-star2.star_color {
        color: $color-yellow;
      }
    }
    .active-tab{
      background: $color-caribbean-green !important;
      color: $color-grey-dark !important;
    }

    .item-favorite{
      background: var(--color-dark-4);
      color: var(--color-neutral-dark);
      font-size: 18px;
      max-height: 41px;
      padding: 10px 30px;
      border-radius: 8px;
      margin: 0;
      line-height: 22px;
      display: inline-block;
      /* margin-right: 16px; */
    }
   

    input {
      &:checked + label {
        background: $color-white;
        color: $text-yellow;
        position: relative;
        z-index: 6;
        height: 100%;
        border-bottom: 0px solid $color-white;
      }
    }

    .search__coin {
      font-weight: 400;
      padding: 0px;
      margin-left: 15px;

      .icon-search {
        position: absolute;
        top: 11.5px;
        bottom: 11.5px;
        right: 15px;
        font-size: 15px;
        color: #cfcfcf;
      }
      .input-search {
        width: 360px;
        height: 42px;
        border-radius: 8px;
        border: none;
        font-family: $font-inter-regular;
        font-size: 18px;
        line-height: 24px;
        right: 15px;
        color: var(--text-primary-color);
        padding: 10px;
        background: var(--color-tab-data-table);
        &:hover,
        &:active,
        &:focus {
          border-color: $color-green;
        }

        &::placeholder {
          color: var(--text-search);
          font-size: 18px;
          line-height: 24px;
        }
      }
      .input_group_seach {
        cursor: pointer;
        float: left;
        &:before {
          content: "\e948";
          font-family: "icomoon" !important;
          font-size: $font-big;
          color: $color-grey;
          line-height: 20px;
          padding-top: 7px;
          height: 30px;
          width: 30px;
          display: block;
          text-align: center;
        }
      }
    }

    .container-tab {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      margin-top: 30px;
      gap: 16px;
      white-space: nowrap;

      &::-webkit-scrollbar {
        display: none;
      }
      label:first-child  {  order: 3;}
      label:nth-child(2) {order: 4;}
      label:nth-child(3) {order: 1;}
      label:nth-child(4) {order: 2;}

      @media screen and (max-width: 768px) {
        margin-top: 20px;
        width: 90%;
        margin-left: 15px;
      }
      .active {
        background: $color-caribbean-green;
      }
    }
    .block {
      display: block;
    }
    .none {
      display: none;
    }
    .btn-left {
      top: 11px;
      left: -9px;
      background: transparent;
    }
    .btn-right {
      top: 11px;
      right: 3px;
      background: transparent;
    }
  }
  #market-coin-1 {
    padding-bottom: 25px;
    color: $color-grey-dark;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-track {
        background-color: var(--color-tab-data-table);
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background: var(--bgr-btn);
      }
    div {
    }
    .is-muted {
      color: $color-grey;
    }
  }

  #container-tab input {
    &#tab-favirotes:checked ~ #content #content-favirotes,
    &#tab-usd:checked ~ #content #content-usd,
    &#tab-btc:checked ~ #content #content-btc,
    &#tab-eth:checked ~ #content #content-eth {
      opacity: 1;
      z-index: 100;
      display: block;
    }
  }

  input.visible {
    visibility: visible !important;
  }
  .coming-soon {
    text-align: center;
    padding-top: 70px;
    p {
      color: var( --text-close);
      font-size: 16px;
      line-height: 19px;
      padding-top: 16px;
    }
  }
}
@media screen and (min-width: 993px) {
  .box-table #container-tab .search__coin .input-search {
    position: absolute;
    right: 0;
    top: 0;
  }
}
@media screen and (max-width: 992px) {
  .box-table {
    #container-tab {
      label {
        font-size: 16px;
        display: inline-block;
      }
      .group_table_landing {
        display: block;
      }
      .search__coin {
        margin: 16px 0 0;
        float: left;
        width: 100%;
        position: relative;

        
        .input-search {
          width: 100%;
          height: 39px;
          font-size: 14px;
          line-height: 19px;
          &::placeholder {
            font-size: 14px;
          }
        }

        .icon-search{
          top: 11px;
          right: 15px;
        }
      }
      .container-tab{
        .item-favorite{
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
  }
}

</style>
