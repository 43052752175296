<template>
  <div>
    <assign-revoke-modal ref='AssignRevokeModal'/>
    <div class="table-container table-history">
      <data-table4 ref="datatable" :getData="getData" :msgEmptyData="$t('common.datatable.data_empty.investment')" id="order-table" :limit="10">
        <template>
          <th data-sort-field="id">
              {{$t('mam.fund_id')}}
          </th>
          <th data-sort-field="max_drawdown">
              {{$t('mam.max_drawdown')}}
          </th>
          <th data-sort-field="fund_capital">
              {{$t('mam.fund_capital')}}
          </th>
          <th data-sort-field="fund_balance">
              {{$t('mam.fund_balance')}}
          </th>
          <th data-sort-field="user_capital">
              {{$t('mam.my_capital_share')}}
          </th>
          <th data-sort-field="user_balance">
              {{$t('mam.my_balance')}}
          </th>
          <th data-sort-field="current_fee">
              {{$t('mam.current_fee')}}
          </th>
          <th data-sort-field="performance_fee">
              {{$t('mam.last_fee')}}
          </th>
          <th>
              {{$t('mam.assign_revoke')}}
          </th>
        </template>
        <template slot="body" slot-scope="props">
          <tr v-if="props.item.user_capital != 0">
            <td>{{ props.item.master_id }}</td>
            <td>{{ (props.item.max_drawdown * 100) | formatMarginNumber(2, '0', true) }} %</td>
            <td>{{ props.item.fund_capital | formatMarginNumber(8, '0') }} {{ $t('margin.symbol.btc') }}</td>
            <td>{{ props.item.fund_balance | formatMarginNumber(8, '0') }} {{ $t('margin.symbol.btc') }}</td>
            <td>{{ props.item.user_capital | formatMarginNumber(8, '0') }} / {{ (props.item.user_capital / props.item.fund_capital * 100) | formatMarginNumber(2, '0') }} %</td>
            <td>{{ props.item.user_balance | formatMarginNumber(8, '0') }} {{ $t('margin.symbol.btc') }}</td>
            <td>{{ (props.item.current_fee > 0 ? props.item.current_fee : 0) | formatMarginNumber(8, '0') }} {{ $t('margin.symbol.btc') }}</td>
            <td>{{ (props.item.performance_fee > 0 ? props.item.performance_fee : 0) | formatMarginNumber(8, '0') }} {{ $t('margin.symbol.btc') }}</td>
            <td>
              <div class="as_rv_funds">
                  <a @click="showAssignModal(props.item.id)" class="btn-assign">{{ $t("funds.balances.assign") }}</a>
                  <span class="cl_blue_custom">/</span>
                  <a @click="showRevokeModal(props.item.id)" class="btn-revoke">{{ $t("funds.balances.revoke") }}</a>
              </div>
            </td>
          </tr>
        </template>
      </data-table4>
      <!-- end pagination-container -->
    </div>
    <!-- end table container -->
  </div>
</template>

<script>
  import rf from '@/request/RequestFactory'
  import AssignRevokeModal from '../common/AssignRevokeModal.vue';

  export default {
    components: {
      AssignRevokeModal,
    },
    data() {
      return {
   
      }
    },
    methods: {
      getData (params) {
        return rf.getRequest('MamRequest').getInvestments(params);
      },
      showAssignModal(assignId) {
        window.CommonModal.show('AssignRevokeModal', {
          close: () => {
            this.$refs.datatable.refresh();
            this.$refs.AssignRevokeModal.resetData();
            this.$refs.AssignRevokeModal.resetError();
          }
        });
        this.$refs.AssignRevokeModal.isAssignModal(assignId);
      },
      showRevokeModal(revokeId) {
        window.CommonModal.show('AssignRevokeModal', {
          close: () => {
            this.$refs.datatable.refresh();
            this.$refs.AssignRevokeModal.resetData();
            this.$refs.AssignRevokeModal.resetError();
          }
        });
        this.$refs.AssignRevokeModal.isRevokeModal(revokeId);
      },
    },
  }
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
@import "@/assets/sass/common.scss"; 
  
  .clearfix-20 {
    display: block;
    clear: both;
    height: 20px;
  }
  .btn-assign {
    font-family: $font-family-roboto-medium;
    font-size: $font-root;
    color: $color-blue-custom;
    &:hover {
      text-decoration: underline;
    }
  }
  .btn-revoke {
    font-family: $font-family-roboto-medium;
    font-size: $font-root;
    color: $color-blue-custom;
    &:hover {
      text-decoration: underline;
    }
  }
  .cl_blue_custom {
    color: $color-blue-custom;
  }
  .btn-detail {
    min-width: 70px;
    height: 25px;
    line-height: 20px;
    padding: 2px 5px;
    border-radius: 3px;
    border: solid 1px $color-jungle-green;
    letter-spacing: 0.92px;
    text-align: center;
    font-family: $font-family-roboto-bold;
    font-size: $font-smaller;
    color: $color-jungle-green;
    text-transform: uppercase;
    background-color: transparent;
    float: right;
    margin-left: 12px;
    &:hover {
      background-color: $color-blue-custom;
      border-color: $color-blue-custom;
      color: $color-white;
    }
  }
  #order-table {
    background-color: transparent;
    // padding-bottom: 22px;
    table {
      border: 0px;
      thead {
        background-color: $color-white;
        border: 0px;
        tr {
          height: 48px;
          th {
            font-family: $font-family-roboto-medium;
            font-size: $font-small;
            padding: 8px 0px 8px 20px;
            &:last-child {
              padding: 8px 20px 8px 0px;
            }
            &[data-sort-order='asc'] {
              color: $color-jungle-green;
            }
            &[data-sort-order='desc'] {
              color: $color-jungle-green;
            }
          }
        }
      }
      tbody {
        text-align: left;
        tr {
          &.canceled {
            opacity: 0.3
          }
          height: auto;
          font-size: $font-small;
          td {
            font-size: $font-root;
            font-weight: 500;
            color: $color-grey-dark;
            font-family: $font-family-roboto-medium;
            line-height: 21px;
            padding: 7px 0px 6px 20px;
            overflow: initial;
            vertical-align: middle;
            &.blue {
              color: $color-jungle-green;
            }
            &.red {
              color: $color-denim;
            }
            &:last-child {
              text-align: left;
              padding: 7px 20px 6px 0px;
            }
          }
        }
      }
    }
  }
</style>