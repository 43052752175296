<template>
  <div id="PositionMarginModal">
    <modal :name="modalName" width="560" height="364">
      <template slot="body">
        <div class="header_modal">
          <h2 class="title_head"><span>{{$t('margin.add_remove_margin')}}</span></h2>
        </div>

        <div class="clearfix clearfix-15"></div>
        <div class="des_header_modal">
          <label class="checkcontainer">
            <input type="radio" checked="true" @click="changeSelection(true)" name="radio">
            <span class="txt">{{$t('margin.add_margin')}}</span>
            <span class="radiobtn"></span>
          </label>
          <label class="checkcontainer">
            <input type="radio" @click="changeSelection(false)" name="radio">
            <span class="txt">{{$t('margin.remove_margin')}}</span>
            <span class="radiobtn"></span>
          </label>
        </div>
        <div class="clearfix clearfix-15"></div>
        <div class="des_header_modal">
          {{$t('margin.your_current_position')}}: <strong>{{ position.current_qty }}</strong> {{$t('margin.contract')}} ({{ position.leverage | formatMarginNumber(2) }}x)<br/>
          {{$t('margin.currently_assigned_margin')}}: <strong>{{ position.init_margin | formatMarginNumber(8) }} {{$t('margin.symbol.btc')}}</strong>.<br/>
          {{$t('margin.available_margin_1')}}: <strong>{{ balance.availableBalance | formatMarginNumber(8) }} {{$t('margin.symbol.btc')}}</strong>.
        </div>
        <div class="clearfix clearfix-20"></div>
        <div class="body_modal">
          <h4 class="title_change">
            <div v-if='isAddMargin'>
              <div>{{$t('margin.add_margin')}}</div>
              <div class="clearfix clearfix-10"></div>
              <div>({{$t('margin.add_remove_max')}}: {{ addMax | formatMarginNumber(8) }} {{ $t('margin.symbol.btc') }})</div>
            </div>
            <div v-else>
              <div>{{$t('margin.remove_margin')}}</div>
              <div class="clearfix clearfix-10"></div>
              <div>({{$t('margin.add_remove_max')}}: {{ removeMax | formatMarginNumber(8) }} {{ $t('margin.symbol.btc') }})</div>
            </div>
          </h4>
          <div class="clearfix"></div>
          <div class="input_group_buyForm">
            <currency-input :precision="8" :class="{'error': errors.has('margin')}" class="input_form_buyForm" v-model="margin" :placeholder="$t('salespoint.enter_amount')"/>
            <span class="txt_Form_buyForm">{{$t('margin.symbol.btc')}}</span>
          </div>
          <div v-show="errors.has('margin')" class="invalid-feedback">{{ errors.first('margin') }}</div>
        </div>
        <div class="clearfix"></div>

        <div class="footer_model">
          <button class="btn btn-cancel" @click="closeModal()">{{$t('margin.cancel')}}</button>
          <button class="btn btn-confirm" @click="handle()" :class="{'disabled': disableBtn }">
            <span v-if='isAddMargin'>{{$t('margin.add')}}</span>
            <span v-else>{{$t('margin.remove')}}</span>
          </button>
        </div>
      </template>
    </modal>
  </div>
</template>
<script>
import rf from '@/request/RequestFactory'
  import BigNumber from 'bignumber.js';
  import Modal from '@/components/shared_components/common/Modal.vue';
  // import MarginCalculator from './MarginCalculator.js';
  import MarginCalculator from '@/components/marginexchange_pages/common/MarginCalculator.js';
  import { mapGetters, mapActions } from 'vuex';

  export default {
    components: {
      Modal,
    },
    data() {
      return {
        modalName:'PositionMarginModal',
        margin: 0,
        isAddMargin: true,
        position: {},
      }
    },
    computed: {
      ...mapGetters({ balance: 'mamBalance' }),
      addMax () {
        const addMax = new BigNumber(`${this.balance.availableBalance || 0}`).toString();
        return BigNumber.max(new BigNumber(`${addMax || 0}`).minus(0.00000001).toString(), 0).toString();
      },
      removeMax () {
        return new BigNumber(`${this.position.extra_margin || 0}`).round(8, BigNumber.ROUND_DOWN).toString();
      },
      disableBtn () {
        if (!this.margin || this.margin == 0) {
          return true;
        }
        if (this.isAddMargin) {
          const addMax = new BigNumber(`${this.balance.availableBalance || 0}`).toString();
          if (new BigNumber(`${this.margin || 0}`).comparedTo(`${addMax || 0}`) > 0) {
            return true;
          }
        } else {
          if (new BigNumber(`${this.margin || 0}`).comparedTo(`${this.position.extra_margin || 0}`) > 0) {
            return true;
          }
        }
        return false;
      }
    },
    methods: {
      ...mapActions(['updateMamMargin']),
      async handle () {
        try {
          let dataUpdate = {
            margin: this.isAddMargin ? this.margin : '-' + this.margin,
            symbol: this.position.symbol,
          };
          await this.updateMamMargin(dataUpdate);
          this.closeModal();
          Message.success(this.isAddMargin ? this.$t('margin.add_margin_successful') : this.$t('margin.remove_margin_successful'), {}, { position: 'bottom_left' });
          this.margin = null;
        } catch(e) {
          if (e.response && e.response.data) {
            Message.error(e.response.data.message, {}, { position: 'bottom_left' });
          }
        }
      },
      getEventHandlers() {
        return {
          selectedPosition: this.onSelectedPosition
        };
      },
      onSelectedPosition(position) {
        this.position = { ...position };
        this.isAddMargin = true;
        this.errors.clear();
      },
      changeSelection(isAddMargin) {
        this.isAddMargin = isAddMargin;
      },
      closeModal () {
        window.CommonModal.hide('PositionMarginModal');
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/sass/variables';
  .invalid-feedback{
    text-align: center;
    margin-top: 5px;
  }
  .clearfix-10 {
    display: block;
    clear: both;
    height: 10px;
    width: 100%;
  }
  .clearfix-15 {
    display: block;
    clear: both;
    height: 15px;
    width: 100%;
  }
  .clearfix-20 {
    display: block;
    clear: both;
    height: 20px;
    width: 100%;
  }
  button.disabled {
    pointer-events: none;
    opacity: 0.8;
  }
  #PositionMarginModal {
    .input_group_buyForm {
      margin-top: 15px;
      margin-left: auto;
      margin-right: auto;
      display: block;
      width: 354px;
      position: relative;  
      border-radius: 3px;
      overflow: hidden;
      .input_form_buyForm {
        width: 100%;
        padding: 15px;
        border-radius: 3px;
        background-color: transparent;
        border: solid 1px $color-charade;
        height: 40px;
        border: solid 1px #cfcfcf;
        font-size: $font-root;
        color: $color-grey-dark;
        &:focus {
          border: solid 1px $color-jungle-green;
        }
        &::placeholder {
          color: $color-grey;
          opacity: 1; /* Firefox */
        }

        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: $color-grey;
        }

        &::-ms-input-placeholder { /* Microsoft Edge */
          color: $color-grey;
        }
      }
      .error {
        border: solid 1px $color-red-main;
      }
      .txt_Form_buyForm {
        display: block;
        width: 80px;
        position: absolute;
        right: 2px;
        font-family: Roboto-Bold;
        top: 1px;
        line-height: 20px;
        padding-right: 25px;
        padding-top: 9px;
        height: 38px;
        text-align: right;
        color: $color-grey-dark;
        font-size: $font-root;
        text-transform: uppercase;
        background: #ececec;
      }
    }
    .checkcontainer {
      display: inline-block;
      position: relative;
      padding-left: 20px;
      margin-bottom: 0px;
      margin-left: 8px;
      margin-right: 8px;
      cursor: pointer;
      font-size: $font-medium-bigger;
      color: $color-grey-dark;
      font-weight: 500;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }
      .radiobtn {
        position: absolute;
        top: 3px;
        left: 0;
        height: 12px;
        width: 12px;
        background-color: $color-white;
        border: 1px solid #979797;
        border-radius: 50%;
      }
      &:hover input ~ .radiobtn {
        background-color: $color-white;
      }
      input:checked ~ .radiobtn {
        background-color: $color-white;
        border: 4px solid $color-jungle-green;
      }
      input:checked ~ .txt {
        color: $color-jungle-green;
      }
      .radiobtn:after {
        content: "";
        position: absolute;
        display: none;
      }
      input:checked ~ .radiobtn:after {
        display: block;
      }
      .radiobtn:after {
        top: 9px;
        left: 9px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: $color-white;
      }
    }
    .header_modal {
      line-height: 20px;
      padding: 24px 25px 14px 25px;
      .title_head {
        font-weight: 500;
        text-align: center;
        margin: 0px;
        line-height: 30px;
        position: relative;
        font-family: $font-family-roboto-medium;
        font-size: $font-big-20;
        color: $color-eden;
        &:after {
          content: "";
          display: block;
          width: 100%;
          height: 1px;
          top: 50%;
          margin-top: 1px;
          position: absolute;
          left: 0px;
          background-color: $color-light-grey;
        }
        >span {
          display: inline-block;
          padding: 5px 17px;
          position: relative;
          z-index: 1;
          background-color: $color-white;
        }
      }
    }
    .des_header_modal {
      display: block;
      padding: 0px 40px;
      text-align: center;
      font-size: $font-root;
      color: $color-grey-dark;
      line-height: 20px;
      strong {
        font-family: $font-family-roboto-bold;
        font-weight: 500;
      }
      a {
        color: $color-jungle-green;
      }
    }
    .body_modal {
      display: block;
      padding: 16px 25px;
      background-color: $color-alabaster;
      color: $color-grey-dark;
      font-weight: 500;
      .title_change {
        text-align: center;
        margin: 0px;
        font-family: $font-family-roboto-medium;
        font-size: $font-medium-bigger;
        color: $color-black;
      }
    }
    .footer_model {
      text-align: center;
      padding: 20px 0px 35px 0px;
      background-color: $color-alabaster;
      .btn-cancel {
        min-width: 130px;
        border-radius: 3px;
        border: solid 1px $color-jungle-green;
        height: 50px;
        text-align: center;
        background-color: transparent;
        letter-spacing: 1.08px;
        text-transform: uppercase;
        line-height: 20px;
        padding: 15px 5px;
        margin-right: 18px;
        font-family: $font-family-roboto-bold;
        font-size: $font-root;
        color: $color-jungle-green;
        &:hover {
          background-color: $color-blue-custom;
          border-color: $color-blue-custom;
          color: $color-white;
        }
      }
      .btn-confirm {
        min-width: 130px;
        border-radius: 3px;
        border: solid 1px $color-jungle-green;
        height: 50px;
        text-align: center;
        background-color: $color-jungle-green;
        letter-spacing: 1.08px;
        text-transform: uppercase;
        line-height: 20px;
        padding: 15px 5px;
        font-family: $font-family-roboto-bold;
        font-size: $font-root;
        color: $color-white;
        &:hover {
          background-color: $color-blue-custom;
          border-color: $color-blue-custom;
          color: $color-white;
        }
      }
    }
  }
</style>

<style lang="scss">
  #PositionMarginModal {
    .modal-header,
    .modal-footer {
      padding: 0px;
      .close {
        position: absolute;
        z-index: 50;
        left: auto;
        right: 5px;
        top: 13px;
      }
    }
    .modal-body {
      padding: 0px;
    }
  }
</style>