<template>
	<div class="install">
		<div class="content-box first">
			<div class="applink">
				<a class="androidapp" target="_blank" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"><img src="@/assets/images/googleplay.png" alt=""></a>
				<a class="iosapp" target="_blank" href="https://itunes.apple.com/us/app/google-authenticator/id388497605"><img src="@/assets/images/appstore.png" alt=""></a>
			</div>
			<div class="text_authen" >
			<p v-html="$t('account.text_install_gg_authen')"></p></div>
		</div>
		<!-- end first -->
	</div>
</template>
<script>
	export default {
  }
</script>
<style lang="scss" scoped>
.text_authen {
	font-family: $font-inter-regular;
	color: var(--color-step-content);
	font-size: 18px;
	line-height: 22px;
	margin-top: 30px;
	text-align: center;
	p {
		margin: 0;
	}
}

@media screen and (max-width: 992px) {
	.text_authen {
		margin-top: 25px;
		font-size: 14px;
		line-height: 17px;
	}
}

</style>
