import BaseModelRequest from './base/BaseModelRequest';

export default class LocaleRequest extends BaseModelRequest {
  getModelName() {
    return 'locale';
  }

  setUserLocale(params) {
    let url = '/locale';
    return this.put(url, params);
  }
}
