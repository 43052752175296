import Layout from "./Layout.vue";
import Balances from "./pages/funds/Balances.vue";
import BalancesSpot from "./pages/funds/BalancesSpot.vue";
import ConvertBalances from "./pages/funds/ConvertBalances.vue";
import History from "./pages/funds/History.vue";
import OpenOrderScreen from "./pages/order/OpenOrderScreen.vue";
import OrderHistoryScreen from "./pages/order/OrderHistoryScreen.vue";
import TradeHistoryScreen from "./pages/order/TradeHistoryScreen.vue";
// import BuyHistoryScreen from './salespoint_pages/order/BuyHistoryScreen.vue';
import Withdrawals from "./pages/funds/Withdrawals.vue";
import VerifyWithdrawal from "./pages/funds/VerifyWithdrawal.vue";
import Deposits from "./pages/funds/Deposits.vue";
import TransferWallet from "./pages/funds/Transfer.vue";
import DepositUsd from "./pages/funds/DepositUsd.vue";
import WithdrawUsd from "./pages/funds/WithdrawUsd.vue";
// import Home from './salespoint_pages/exchange/basic/Home.vue';
import SpotHome from "@/components/spotexchange_pages/exchange/basic/Home.vue";
import OpenOrderScreenSpotEx from "@/components/spotexchange_pages/order/OpenOrderScreen.vue";
import ConvertMarketScreen from "@/components/convert_pages/ConvertMarketScreen";
import LeaderboardScreen from "@/components/spotexchange_pages/exchange/basic/LeaderBoardScreen.vue";
import OrderHistoryScreenSpotEx from "@/components/spotexchange_pages/order/OrderHistoryScreen.vue";
import TradeHistoryScreenSpotEx from "@/components/spotexchange_pages/order/TradeHistoryScreen.vue";

import MamHome from "@/components/mamexchange_pages/exchange/basic/Home.vue";
import MamFollower from "./pages/mam/MamFollower.vue";
import MamMaster from "./pages/mam/MamMaster.vue";
import MamLeaderBoard from "./pages/mam/MamLeaderBoard.vue";
import MamOrderHistory from "@/components/mamexchange_pages/order/MamOrderHistoryScreen.vue";
import MamTradeHistory from "@/components/mamexchange_pages/order/MamTradeHistoryScreen.vue";

import Terms from "./pages/Terms.vue";
import Policy from "./pages/Policy.vue";
import Fee from "./pages/Fee.vue";
import Contact from "./pages/Contact.vue";
import AddressManager from "./pages/address/AddressManager.vue";
import SettingsAccountContainer from "./pages/SettingsAccountContainer.vue";
import SettingsDashboard from "./pages/SettingsDashboard.vue";
import SettingsSecurity from "./pages/SettingsSecurity.vue";
import SettingsDeviceAndActives from "./pages/SettingsDeviceAndActives.vue";
import SettingsActivity from "./pages/SettingsActivity.vue";
import SettingsRewardCenter from "./pages/SettingsRewardCenter.vue";
import ClientDownload from "./pages/ClientDownload.vue";
import About from "./pages/About.vue";
import Referral from "./pages/Referral.vue";
import ReferralMobileApp160819 from "./pages/ReferralMobileApp160819.vue"; //Mobile app Referral
import WithdrawGuide from "./pages/WithdrawGuide.vue";
import Landing from "./pages/landing/Landing.vue";
import MarketHome from "./pages/MarketHome.vue";
import Login from "./pages/auth/Login.vue";
import Register from "./pages/auth/Register.vue";
import ResetPassword from "./pages/auth/ResetPassword.vue";
import IdentityPersonal from "./pages/account/IdentityPersonal.vue";
import AntiPhishing from "./pages/account/AntiPhishing";
import EmailVerification from "./pages/account/EmailVerification";
import ContractSeriesApp21102019
  from "@/components/marginexchange_pages/contracts/contracts_data/ContractSeriesApp21102019.vue";
import OrderHistoryScreenSMarginEx from "@/components/marginexchange_pages/order/OrderHistoryScreen.vue";
import TradeHistoryScreenMarginEx from "@/components/marginexchange_pages/order/TradeHistoryScreen.vue";
import ApiCreated from "./pages/ApiCreated.vue";

import NotFound from "./pages/NotFound.vue";
import SystemMaintenance from "./pages/SystemMaintenance.vue";
// ============================ Cooperation =====================
import Cooperation from "./pages/cooperation/Cooperation.vue";
// ============================ Award ===========================
import InviteAward from "./pages/cooperation/Award.vue";

// ============================ JoinUs ==========================
import JoinUs from "./pages/cooperation/JoinUs.vue";

// ============================ Contract ========================
import Contract from "./pages/cooperation/Contract.vue";
import CoinInformation from "./pages/CoinInformation/CoinInformation.vue";

// ============================ ROEActivityPage===================
import ROEActivityPage from "./pages/cooperation/RoeActivity.vue";

// ============================ MAMDetail===================
import MAMDetailPage from "./pages/cooperation/MAMDetail.vue";

import PNLChart from "./pages/PnlSinglePage.vue";
import LicensePage from "./pages/cooperation/License.vue";
import ContainerOverview from "./pages/wallet/component/ContainerOverview.vue";
import BalancesFutures from "@/pages/funds/BalancesFutures.vue";
import ConvertOpenOrder from "@/pages/order/ConvertOpenOrder.vue";
import ConvertScreen from "@/pages/order/ConvertScreen.vue";
import ConvertHistory from "@/pages/order/ConvertHistory.vue";

import PhoneNumber from "./pages/account/PhoneNumber.vue"

const commonPage = [];

// export const PathRoot = {
//   path: '/salespoint',
//   redirect: { name: 'trading', params: { coin: 'btc', currency: 'usd' } }
// };

const marginCommon = [
        {
          path: '/margin-exchange/',
          name: 'Margin Exchange',
          // component: MarginHome,
          beforeEnter(to, from, next) {
            window.location.href = process.env.VUE_APP_SUB_DOMAIN_PUBLIC_URL
          },
          props: (route) => ({
            symbol: route.query.symbol,
            coin: route.query.coin,
            currency: route.query.currency
          }),
        },
        {
          path: '/margin-future',
          name: 'Margin Future',
          beforeEnter(to, from, next) {
            window.location.href = process.env.VUE_APP_SUB_DOMAIN_PUBLIC_URL+'/'+to.params.coin
          },
          props: (route) => ({
            symbol: route.query.symbol,
            coin: route.query.coin,
            currency: route.query.currency
          }),
        },        
        {
          path: '/margin-exchange/orders/history',
          name: 'Order History MarginEx',
          component: OrderHistoryScreenSMarginEx,
        },
        {
          path: '/margin-exchange/trade-history',
          name: 'Trade History MarginEx',
          component: TradeHistoryScreenMarginEx,
        },
        // {
        //   path: '/mam-exchange',
        //   name: 'mam exchange',
        //   component: MamHome,
        //   props: (route) => ({
        //     symbol: route.query.symbol,
        //     coin: route.query.coin,
        //     currency: route.query.currency
        //   }),
        // },
        // {
        //   path: '/mam-investments-detail',
        //   name: 'mam investments detail',
        //   component: MamInvestmentDetail,
        //   props: (route) => ({
        //     account: route.query.account,
        //     symbol: route.query.symbol,
        //     coin: route.query.coin,
        //     currency: route.query.currency
        //   }),
        // }
      ]

// const marginContract = [
//   {
//     path: '/margin-exchange/contracts',
//     name: 'Margin Contracts',
//     component: FundingHistory,
//   },
//   // {
//   //   path: '/margin-exchange/contracts',
//   //   name: 'Margin Contracts',
//   //   component: FundingHistory,
//   //   children: [
//   //     {
//   //       path: '/margin-exchange/contracts/funding-history',
//   //       name: 'Funding History',
//   //       component: FundingHistory,
//   //     },
//   //     {
//   //       path: '/margin-exchange/contracts/insurance-fund',
//   //       name: 'Daily Insurance Fund Balance',
//   //       component: DailyInsuranceFundBalance,
//   //     },
//   //     {
//   //       path: '/margin-exchange/contracts/settlement-history',
//   //       name: 'Funding History Table',
//   //       component: SettlementHistory,
//   //     },
//   //     {
//   //       path: '/margin-exchange/contracts/leaderboard',
//   //       name: 'Leaderboard',
//   //       component: Leaderboard,
//   //     },
//   //   ]
//   // },
//   {
//     path: '/margin-exchange/contracts/funding-history',
//     name: 'Funding History',
//     component: FundingHistory,
//   },
//   {
//     path: '/margin-exchange/contracts/insurance-fund',
//     name: 'Daily Insurance Fund Balance',
//     component: DailyInsuranceFundBalance,
//   },
//   {
//     path: '/margin-exchange/contracts/settlement-history',
//     name: 'Settlement Table',
//     component: SettlementHistory,
//   },
//   {
//     path: '/margin-exchange/contracts/leaderboard',
//     name: 'Leaderboard',
//     component: Leaderboard,
//   },
//   {
//     path: '/margin-exchange/contracts/futures-guide',
//     name: 'Futures Guide',
//     component: FuturesGuide,
//   },
//   {
//     path: '/margin-exchange/contracts/perpetual-contracts-guide',
//     name: 'Perpetual Contracts Guide',
//     component: PerpetualContractsGuide,
//   }
// ]

// const marginContractGuide = [
//   {
//     path: '/margin-exchange/contracts/contracts-data/contract-specification/',
//     name: 'Contract Specification Index',
//     component: ContractSeries,
//   },
//   {
//     path: '/margin-exchange/contracts/contracts-data/contract-specification/:symbol',
//     name: 'Contract Specification',
//     component: ContractSeries,
//   },
//   {
//     path: '/margin-exchange/contracts/contracts-data/contract-series-guide/',
//     name: 'Contract Series Guide Index',
//     component: ContractSeriesGuide,
//   },
//   {
//     path: '/margin-exchange/contracts/contracts-data/contract-series-guide/:symbol',
//     name: 'Contract Series Guide',
//     component: ContractSeriesGuide,
//   },
// ]

// const contractIndiceSeries = [
//   {
//     path: '/margin-exchange/contracts/indices-data/',
//     name: 'Indice Series Index',
//     component: ContractIndiceSeries,
//   },
//   {
//     path: '/margin-exchange/contracts/indices-data/:symbol',
//     name: 'Indice Series',
//     component: ContractIndiceSeries,
//   }
// ];

const Path = {
  mode: 'history',
  routes: [
    {
      path: '/anti-phishing/verify',
      name: 'EmailVerification',
      component: EmailVerification
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      props: (route) => ({
        confirmationCode: route.query.code,
        deviceCode: route.query.device,
        customRedirect: route.params.customRedirect,
      }),
      meta: {
        guest: true,
      },
    },
    {
      path: '/login?return_to=zendesk',
      name: 'LoginZendesk',
      component: Login,
      props: (route) => ({
        confirmationCode: route.query.code,
        deviceCode: route.query.device,
      }),
      meta: {
        guest: true,
      },
    },
    {
      path: '/pnl-chart',
      name: 'PNLChart',
      component: PNLChart,
      props: (route) => ({
        token: route.query.token,
      })
    },
    {
      path: '/register',
      name: 'Register',
      component: Register,
      meta: {
        guest: true,
      },
    },
    {
      path: '/reset-password',
      name: 'ResetPassword',
      component: ResetPassword,
      props: (route) => ({ token: route.query.token }),
      meta: {
        guest: true,
      },
    },
    {
      path: '/verify-withdrawal',
      name: 'Verify Withdrawal',
      component: VerifyWithdrawal,
      props: (route) => ({ token: route.query.token , coin: route.query.coin}),
      meta: {
        auth: false,
      },
    },
    { 
      path: '/system-maintenance',
      name: 'SystemMaintenance',
      component: SystemMaintenance,
    },
    // {
    //   path: '/mul-charts',
    //   name: 'Multiple Charts',
    //   component: MultipleCharts,
    // },
    {
      path: '/webview/chart',
      name: 'chart_mobile',
      component: () => import('@/mobile/webview/chart/ChartIndex.vue'),
      children: [
        {
          path: '/webview/chart/candle',
          name: 'candle_chart',
          component: () => import('@/mobile/pages/chart/ChartInfo.vue'),
          props: (route) => ({
            coin: route.query.coin,
            currency: route.query.currency,
            resolution: route.query.resolution,
            theme: route.query.theme
          }),
        },
        {
          path: '/webview/chart/depth',
          name: 'depth_chart',
          component: () => import('@/mobile/pages/chart/HighCharts.vue'),
          props: (route) => ({
            coin: route.query.coin,
            currency: route.query.currency,
            theme: route.query.theme
          }),
        },
      ],
    },
    {
      path: '/webview/margin/chart',
      name: 'margin_chart_mobile',
      component: () => import('@/mobile/webview/chart/ChartIndex.vue'),
      children: [
        {
          path: '/webview/margin/chart/candle',
          name: 'margin_candle_chart',
          component: () => import('@/mobile/pages/margin_chart/ChartInfo.vue'),
          props: (route) => ({
            symbol: route.query.symbol,
            resolution: route.query.resolution,
          }),
        },
        {
          path: '/webview/margin/chart/depth',
          name: 'margin_depth_chart',
          component: () => import('@/mobile/pages/margin_chart/HighCharts.vue'),
          props: (route) => ({
            symbol: route.query.symbol,
            resolution: route.query.resolution,
          }),
        },
      ],
    },
    {
      path: '/',
      component: Layout,
      children: [
        {
          path: '/funds/balances',
          name: 'Balances',
          component: Balances,
          meta: {
            auth: true,
          },
        },
        {
          path: '/funds/withdraw-usd',
          name: 'Withdraw USD',
          component: WithdrawUsd,
          meta: {
            auth: true,
          },
        },
        {
          path: '/funds/deposit-usd',
          name: 'Deposit USD',
          component: DepositUsd,
          meta: {
            auth: true,
          },
        },
        {
          path: '/funds/withdrawals',
          name: 'Withdrawals',
          component: Withdrawals,
          meta: {
            auth: true,
          },
        },
        {
          path: '/funds/deposits',
          name: 'Deposits',
          component: Deposits,
          meta: {
            auth: true,
          },
        },
        {
          path: '/funds/history',
          name: 'Fund History',
          component: History,
          meta: {
            auth: true,
          },
        },
        {
          path: '/funds/convert-balances',
          name: 'Convert Small Balances',
          component: ConvertBalances,
          meta: {
            auth: true,
          },
        },
        {
          path: '/mam-follower',
          name: 'mam follower',
          component: MamFollower,
          meta: {
            auth: true,
          },
        },
        {
          path: '/mam-exchange/order-history',
          name: 'mam order history',
          component: MamOrderHistory,
          meta: {
            auth: true,
          },
        },
        {
          path: '/mam-exchange/trade-history',
          name: 'mam trade history',
          component: MamTradeHistory,
          meta: {
            auth: true,
          },
        },
        {
          path: '/mam-master',
          name: 'mam master',
          component: MamMaster,
          meta: {
            auth: true,
          },
        },
        // {
        //   path: '/mam-investor-list',
        //   name: 'mam investor list',
        //   component: MamInvestorList,
        //   meta: {
        //     auth: true,
        //   },
        // },
        // {
        //   path: '/mam-commissions',
        //   name: 'mam commissions',
        //   component: MamCommissions,
        //   meta: {
        //     auth: true,
        //   },
        // },
        {
          path: '/mam-leader-board',
          name: 'mam leader board',
          component: MamLeaderBoard,
          meta: {
            auth: true,
          },
        },
        {
          path: '/orders/open',
          name: 'Open Order',
          component: OpenOrderScreen,
          meta: {
            auth: true,
          },
        },
        {
          path: '/orders/history',
          name: 'Order History',
          component: OrderHistoryScreen,
          meta: {
            auth: true,
          },
        },
        {
          path: '/orders/trade-history',
          name: 'Trade History',
          component: TradeHistoryScreen,
          meta: {
            auth: true,
          },
        },
        // {
        //   path: '/salespoint/buy-history',
        //   name: 'Buy History',
        //   component: BuyHistoryScreen,
        //   meta: {
        //     auth: true,
        //   },
        // },
        // {
        //   path: '/salespoint',
        //   name: 'Basic Exchange',
        //   component: Home,
        //   props: (route) => ({
        //     coin: route.query.coin,
        //     currency: route.query.currency
        //   }),
        // },
        {
          path: '/spot-exchange/basic',
          name: 'Spot Exchange',
          component: SpotHome,
          props: (route) => ({
            coin: route.query.coin,
            currency: route.query.currency
          }),
        },
        {
          path: '/convert',
          name: 'Convert Spot Exchange',
          component: ConvertMarketScreen,
        },
        {
          path: '/spot-exchange/orders/open',
          name: 'Open Order SpotEx',
          component: OpenOrderScreenSpotEx,
          meta: {
            auth: true,
          },
        },
        {
          path: '/spot-exchange/leaderboard',
          name: 'leaderboard SpotEx',
          component: LeaderboardScreen,
        },
        {
          path: '/spot-exchange/orders/history',
          name: 'History Order SpotEx',
          component: OrderHistoryScreenSpotEx,
          meta: {
            auth: true,
          },
        },
        {
          path: '/spot-exchange/orders/trade-history',
          name: 'Trade History SpotEx',
          component: TradeHistoryScreenSpotEx,
          meta: {
            auth: true,
          },
        },
        {
          path: '/mam-exchange',
          name: 'mam exchange',
          component: MamHome,
          meta: {
            auth: true,
          },
          props: (route) => ({
            coin: route.query.coin,
            currency: route.query.currency
          }),
        },
        ...marginCommon,
        // ...marginContract,
        // ...marginContractGuide,
        // ...contractIndiceSeries,
        {
          path: '/terms',
          name: 'Terms of Use',
          component: Terms,
          props: (route) => ({
            isMobileApp: route.query.isMobileApp,
          }),
        },
        {
          path: '/policy',
          name: 'Privacy Policy',
          component: Policy,
        },
        {
          path: '/client-download',
          name: 'ClientDownload',
          component: ClientDownload,
        },
        {
          path: '/contact',
          name: 'Contact US',
          component: Contact,
        },
        {
          path: '/about',
          name: 'About Us',
          component: About,
        },
       
        {
          path: '/referral-mobile-app-160819',
          name: 'Referral Mobile App 160819',
          component: ReferralMobileApp160819,
          meta: {
            auth: true,
          },
        },
        {
          path: '/contract-series-app-21102019',
          name: 'Contract Series App 21102019',
          component: ContractSeriesApp21102019,
          meta: {
            auth: false,
          },
        },
        {
          path: '/fees',
          name: 'Trading Fee Guide',
          component: Fee,
        },
        {
          path: '/withdraw-guide',
          name: 'Withdrawal Guide',
          component: WithdrawGuide,
        },
        // {
        //   path: '/account',
        //   name: 'Account',
        //   component: Account,
        //   meta: {
        //     auth: true,
        //   },
        // },
        {
          path: '/account',
          name: 'SettingsAccountContainer',
          component: SettingsAccountContainer,
          meta: {
            auth: true,
          },
          children: [
            {
              path: '/account/dashboard',
              name: 'SettingsDashboard',
              component: SettingsDashboard,
              meta: {
                auth: true,
              },
            },
            {
              path: '/account/security',
              name: 'SettingsSecurity',
              component: SettingsSecurity,
              meta: {
                auth: true,
              },
            },
            {
              path: '/account/referral',
              name: 'Referral Wallet',
              component: Referral,
              // meta: {
              //   auth: true,
              // },
            },
            {
              path: '/account/devices',
              name: 'SettingsDeviceAndActives',
              component: SettingsDeviceAndActives,
              meta: {
                auth: true,
              },
            },
            {
              path: "/account/phone-number",
              name: "PhoneNumber",
              component: PhoneNumber,
              meta: {
                auth: true
              }
            },
            {
              path: '/account/anti-phishing',
              name: 'AntiPhishing',
              component: AntiPhishing,
              meta: {
                auth: true,
              },
              children: [
                {
                  path: '/account/anti-phishing/create',
                  name: 'AntiPhishing',
                  component: AntiPhishing
                },
                {
                  path: '/account/anti-phishing/change',
                  name: 'AntiPhishing',
                  component: AntiPhishing
                },
                {
                  path: '/account/anti-phishing/create/:code',
                  name: 'AntiPhishing',
                  component: AntiPhishing
                },
                {
                  path: '/account/anti-phishing/change/:code',
                  name: 'AntiPhishing',
                  component: AntiPhishing
                }
              ]
            },
            {
              path: '/account/activity',
              name: 'SettingsActivity',
              component: SettingsActivity,
              meta: {
                auth: true,
              },
            },
            {
              path: '/account/api-key',
              name: 'ApiCreated',
              component: ApiCreated,
              meta: {
                auth: true,
              },
            },
            {
              path: '/account/identityPersonal',
              name: 'IdentityPersonal',
              component: IdentityPersonal,
              meta: {
                auth: true,
              },
            },        
            {
              path: '/account/address',
              name: 'Address Manager',
              component: AddressManager,
              meta: {
                auth: true,
              },
            },
            {
              path: '/account/reward-center',
              name: 'Reward Center',
              component: SettingsRewardCenter,
              meta: {
                auth: true,
              },
            },
          ]
        },
        {
          path: '/market-overview',
          name: 'Market Overview',
          component: Landing,
        },
        {
          path: '/',
          name: 'Landing Page',
          component: MarketHome,
        },{
          path:'/funds',
          name: 'Wallet Overview',
          component: ContainerOverview ,
          children: [
         
            {
              path: '/funds/balances-wallet',
              name: 'Overview wallet',
              component: Balances,
         
            },
            {
              path: '/funds/spot-wallet',
              name: 'Spot Overview',
              component: BalancesSpot,
         
            },
            {
              path: '/funds/futures-wallet',
              name: 'Margin Overview',
              component: BalancesFutures,

            },
            {
              path: '/funds/history-wallet',
              name: 'Transaction History',
              component: History,
         
            },
            {
              path: '/funds/deposit-usd-wallet',
              name: 'Deposit USD Wallet',
              component: DepositUsd,
              meta: {
                auth: true,
              },
            },
            {
              path: '/funds/withdraw-usd-wallet',
              name: 'Withdraw USD Wallet',
              component: WithdrawUsd,
              meta: {
                auth: true,
              },
            },
            {
              path: '/funds/deposits-wallet',
              name: 'Deposits Wallet',
              component: Deposits,
              meta: {
                auth: true,
              },
            },
            {
              path: '/funds/transfer',
              name: 'Transfer Wallet',
              component: TransferWallet,
              meta: {
                auth: true,
              },
            },
            {
              path: '/funds/withdraw-wallet',
              name: 'Withdrawals Wallet',
              component: Withdrawals,
              meta: {
                auth: true,
              },
            },
          ],
        }
        ,{
          path:'/convert',
          name: 'Convert Overview',
          component: ConvertScreen ,
          children: [

            {
              path: '/convert/open-order',
              name: 'Convert Open Order',
              component: ConvertOpenOrder,

            },
            {
              path: '/convert/history',
              name: 'Convert History',
              component: ConvertHistory,

            },
          ],
        }
        ,{
          path: '/cooperation',
          name: 'Cooperation Page',
          component: Cooperation,
        },{
          path: '/inviteAward',
          name: 'InviteAward Page',
          component: InviteAward,
        },{
          path: '/contract',
          name: 'Contract Page',
          component: Contract,
        },
        {
          path: '/joinUs',
          name: 'JoinUs Page',
          component: JoinUs,
        },{
          path: 'coin-information',
          name: 'Coin Information',
          component: CoinInformation
        },{
          path:'/roe-activity',
          name: 'ROE Activity',
          component: ROEActivityPage
        },{
          path:'/mam-detail',
          name: 'MAM Detail',
          component: MAMDetailPage  
        },{
          path:'/license',
          name: 'License Page',
          component: LicensePage  
        },
        { 
          path: '*', 
          name: '404', 
          component: NotFound 
        },
      ]
    }
  ]
}

export const PathRoot = {
  path: '/salespoint',
  redirect: { name: 'trading', params: { coin: 'btc', currency: 'usd' } }
};

export const getLayoutPath = (refs, returnPathString=false) => {
  let getdata = ({path="", name=""}, needPathStingOnly) => {
    const children = Path.routes.find(el=>el.path == '/').children
    const dataByPath = children.find(el=>el.path == path)
    const dataByName = children.find(el=>el.name == name)
    if(!needPathStingOnly) return (!!name) ? dataByName : dataByPath
    return (!!name) ? dataByName.path : dataByPath.path
  }

  try{
    if(typeof refs == 'object' && refs.length > 0){
      const data = refs.map((el, id)=>{
        const value = getdata(el, false);
        return el = {...el, ...value};
      })
      return data
    } else {
      return getdata(refs, returnPathString)
    }

  }catch(err){
    console.error(err);
  }
}

export default Path