import Numeral from '../lib/numeral';
import BigNumber from 'bignumber.js';
import Utils from './Utils';
import Const from './Const';

export default {

  convertToBtcValue(prices, coin, value) {
    let balance = value;
    if (new BigNumber(balance).isZero()) {
      return 0;
    }

    let pair = `btc_${coin}`;
    if (!prices[pair] || !prices[pair].price) {
      pair = `${coin}_btc`;
      if (!prices[pair] || !prices[pair].price) {
        return 0;
      }
      return new BigNumber(`${balance}`).div(prices[pair].price).toString();
    }
    return new BigNumber(`${balance}`).mul(prices[pair].price).toString();
  },

  convertToUsdtValue (prices, coin, value) {
    let balance = value;
    if (new BigNumber(balance).isZero()) {
      return 0;
    }

    if (coin == Const.USDT || coin == Const.USD ) {
      return balance;
    }

    let pair = `usdt_${coin}`;
    if (!prices[pair] || !prices[pair].price) {
      pair = `${coin}_usdt`;
      if (!prices[pair] || !prices[pair].price) {
        return 0;
      }
      return new BigNumber(`${balance}`).div(prices[pair].price).toString();
    }
    return new BigNumber(`${balance}`).mul(prices[pair].price).toString();
  },

  convertCoinsThroughUsdt (prices, fromCoin, toCoin, value) {
    let usdtValue = this.convertToUsdtValue(prices, fromCoin, value);
    let newValue = this.convertUsdtToCoinValue(prices, toCoin, usdtValue);

    return newValue;
  },

  convertUsdtToCoinValue(prices, coin, value) {
    let balance = value;

    if (coin == Const.USDT || coin == Const.USD ) {
      return balance;
    }

    let pair = `usdt_${coin}`;
    if (!prices[pair] || !prices[pair].price) {
      pair = `${coin}_usdt`;
      if (!prices[pair] || !prices[pair].price) {
        return 0;
      }
      return new BigNumber(`${balance}`).mul(prices[pair].price).toString();
    }
    return new BigNumber(`${balance}`).div(prices[pair].price).toString();
  },

};
