<template>
  <div id="salve_id_detail" class="salve_id_detail clearfix">
    <div class="left_content clearfix">
      <ul class="list_total clearfix">
        <li class="item">
          <span class="name">{{ $t('Total Capital') }}</span>
          <span class="value">{{ overviewData.totalCaptital | formatMarginNumber(8, '0') }} {{ $t('margin.symbol.btc') }}</span>
        </li>
        <li class="item">
          <span class="name">{{ $t('Total Balance') }}</span>
          <span class="value">{{ overviewData.totalBalance | formatMarginNumber(8, '0') }} {{ $t('margin.symbol.btc') }}</span>
        </li>
        <!-- <li class="item">
          <span class="name">{{ $t('Created at') }}</span>
          <span class="value">2019-01-22 13:23:00</span>
        </li> -->
      </ul>
      <div class="clearfix"></div>
      <div class="group_note clearfix">
        <div class="title_note">{{ $t('Note') }}</div>
        <div class="clearfix"></div>
        <div class="content_note clearfix">
          <div class="note_line">{{ $t('mam.guide_1') }}</div>
          <div class="note_line">{{ $t('mam.guide_2') }}</div>
          <div class="note_line">
            {{ $t('mam.guide_3') }}<br>
            {{ $t('mam.guide_4') }}<br>
            {{ $t('mam.guide_5') }}<br>
            {{ $t('mam.guide_6') }}
          </div>
          <div class="note_line">{{ $t('mam.guide_7') }}</div>
          <div class="note_line">{{ $t('mam.guide_8') }}</div>
          <div class="note_line">{{ $t('mam.guide_9') }}</div>
          <div class="note_line">{{ $t('mam.guide_10') }}</div>
        </div>
      </div>
    </div>
    <div class="right_content clearfix">
      <data-table3 class="table_salve_id" :getData="getData" :limit="10" :widthTable="'100%'" ref="datatable" :msgEmptyData="msgEmptyData">
        <template slot-scope="props">
          <th class="text-left w_40" @click="props.echoclick">
            {{$t('Type')}}
            <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
            <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
          </th>
          <th class="text-left w_40" @click="props.echoclick">
            {{$t('Count')}}
            <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
            <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
          </th>
          <th class="text-left w_20" @click="props.echoclick">
            {{$t('Amount')}}
            <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
            <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
          </th>
        </template>
        <template slot="body" slot-scope="props">
          <tr>
            <td class="text-left txt-medium">{{ props.item.type }}</td>
            <td class="text-left">{{ props.item.count | formatMarginNumber(0, '0') }}</td>
            <td class="text-left">{{ props.item.amount | formatMarginNumber(8, '0') }} {{ $t('margin.symbol.btc') }}</td>
          </tr>
        </template>
      </data-table3>
    </div>
  </div>
</template>
<script>
  import rf from '@/request/RequestFactory'
  import Const from '@/common/Const';

  export default {
    components: {
    },
    data() {
      return {
        overviewData: {},
        msgEmptyData: ""
      }
    },
    props: {

    },
    watch: {
     
    },
    computed: {

    },
    methods: {
      getEventHandlers() {
        return {
          RefreshFollowerOverview: this.onRefreshFollowerOverview,
        };
      },
      onRefreshFollowerOverview(selectedTab) {
        this.initData();
      },
      getData (params) {
        const { assignCount, assignAmount, revokeCount, revokeAmount, totalCount, totalAmount } = this.overviewData;
        return new Promise(resolve => {
          return resolve(
            [ 
              {type: 'Assign', count: assignCount, amount: assignAmount},
              {type: 'Revoke', count: revokeCount, amount: revokeAmount},
              {type: 'Total', count: totalCount, amount: totalAmount},
            ]
          );
        });
      },
      initData () {
        rf.getRequest("MamRequest").getFollowerOverview()
          .then(res => {
            if (res && res.data) {
              this.overviewData = res.data;
              this.$refs.datatable.refresh();
            }
          })
          .catch(error => {
          })
      },
    },
    mounted() {
      this.initData();
    },
  }
</script>
<style lang="scss" scoped>
  @import "@/assets/sass/variables";
  @import "@/assets/sass/common.scss";
  .note_line {
    padding: 5px 0px;
  }

  .w_40 {
    width: 40%;
  }

  .w_20 {
    width: 20%;
  }

  .salve_id_detail {
    padding: 20px 0px;

    .left_content {
      padding: 0px 25px;
      margin-right: -1px;
      display: block;
      width: 50%;
      float: left;
      min-height: 240px;
      border-right: 1px solid $color-gallery;

      .list_total {
        margin-bottom: 15px;

        .item {
          display: block;
          width: 100%;
          margin-top: 5px;
          margin-bottom: 5px;
          line-height: 20px;
          float: left;

          .name {
            display: block;
            width: 120px;
            float: left;
            margin-right: 5px;
            font-size: $font-root;
            color: $color-grey-dusty;
          }

          .value {
            display: block;
            overflow: hidden;
            font-weight: 500;
            font-family: $font-family-roboto-medium;
            font-size: $font-root;
          }
        }
      }
      .group_note {
        border-radius: 1px;
        padding: 15px 20px;
        font-size: 13px;
        line-height: 20px;
        background-color: $color-gallery;
        color: $color-grey-dusty;

        .title_note {
          margin-bottom: 7px;
          line-height: 16px;
          font-weight: 500;
          font-family: $font-family-roboto-medium;
          font-size: $font-root;
          color: $color-grey-dark;
        }
      }
    }

    .right_content {
      padding: 0px 25px;
      display: block;
      width: 50%;
      float: left;
      min-height: 240px;
      border-left: 1px solid $color-gallery;

      .table_salve_id {

        th{
          padding-left: 10px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          background-color: $background-default;
          font-family: $font-family-roboto-medium;
          font-size: $font-small;
        }
    
        tr {

          &:hover {
            background-color: transparent;
          }

          td {
            padding-left: 10px;
            color: $color-grey-dark;

            &.txt-medium {
              font-family: $font-family-roboto-medium;
            }
          
            &:last-child {
              text-align: left;
            }
          }
        }
      }
    }
  }
     
</style>
