<template>
  <div id="side-bar">
    <ul>
      <li>
        <router-link to="/spot-exchange/orders/open" class="link-sidebar" :class="isOpenOrder ? 'active':''">
          <!-- <img  class="dropdown-active" :src="require(`@/assets/images/icon/icon-open-order-${isOpenOrder? 'green': lightMode ? 'black' : 'black-dark'}.png`)"/> -->
          {{ $t('sidebar.open_order') }}</router-link>
      </li>
      <li >
        <router-link to="/spot-exchange/orders/history" class="link-sidebar" :class="isOpenHistory ? 'active':''">
          <!-- <img  class="dropdown-active" :src="require(`@/assets/images/icon/icon-open-history-${isOpenHistory? 'green': lightMode ? 'black' : 'black-dark'}.svg`)"/> -->
          {{ $t('sidebar.order_history') }}
        </router-link>
      </li>
      <li >

        <router-link to="/spot-exchange/orders/trade-history" class="link-sidebar" :class="isTradeHistory ? 'active':''">
          <!-- <img  class="dropdown-active" :src="require(`@/assets/images/icon/icon-trade-history-${isTradeHistory? 'green':  lightMode ? 'black' : 'black-dark'}.svg`)"/> -->
          {{ $t('sidebar.trade_history') }}
        </router-link>
      </li>
    </ul>
  </div>
</template>
<script>
  import COMMON_CONST from '@/common/Const';
  import { mapState } from 'vuex';

  export default {
    data() {
      return {
        naviList: COMMON_CONST.OPEN_ORDER_SIDEBAR,
        commonConst: COMMON_CONST,
        visible: true,
        visibleFuture: false,
      }
    },
    computed:{
      ...mapState({
        theme: state => state.userThemeMode,
      }),
      lightMode() {
        return this.theme === 'light-theme';
      },
      isOpenOrder() {
        return this.$route.path.includes('/spot-exchange/orders/open');
      },
      isOpenHistory() {
        return this.$route.path.includes('/spot-exchange/orders/history');
      },
      isTradeHistory() {
        return this.$route.path.includes('/spot-exchange/orders/trade-history');
      },
    }
  }
</script>

<style lang="scss" scoped>

.dark-theme {
  #side-bar {
    background: $color-night;
    border-right: 4px solid $color-black;
    ul {

      li{
        &:hover{
          background: $color-tab-active-darkmode;
        }
      }
      .active {
        background: $color-tab-active-darkmode;
        color: $color-white !important;
      }
      .link-sidebar {
        color: white;
      }
    }

  }
}

#side-bar {
  width: 340px;
    min-width: 340px;
  font-family: $font-inter-regular;
  background-color: $color_white;
  min-height: calc(100% - 65px - 252px);
  border-right: 4px solid $color-alabaster;

  ul li {
    height: 64px;
    cursor: pointer;

    &:hover{
      background: $color-aqua-day;
    }
  }

  .link-sidebar {
    color: $dark-1;
    font-weight: 400;
    font-size: $font-big-20;
    line-height: 24px;
    padding: 20px 10px 20px 50px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 14px;
  }

  .active {
    border-right: 6px solid $color-caribbean-green;
    background: $color-aqua-day;
  }
  .btn-default-order{
    background: none;
    font-size: 20px;
    line-height: 24px;
    padding: 18px 21px 18px 50px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    p {
      margin: 0;
      display: flex;
      align-items: center;
      
    }
  }
  .dropdown-active {
    margin-right: 18px;
  }
  .icon-arrow1{
    font-size: 12px;
  }
  .icon-arrow2{
    font-size: 12px;
  }
  .icon-button {
    justify-content: flex-end;
  }
}
</style>
  
  