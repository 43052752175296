import BaseRequest from './base/BaseRequest';

export default class TransactionRequest extends BaseRequest {

  getHistory(params, typeCoin) {
    let url = '/transactions';
    if (typeCoin) {
      url += '/' + typeCoin;
    }
    return this.get(url, params);
  }

  getCoinHistory(params, typeCoin) {
    let url = '/transactions';
    if (typeCoin) {
      url += '/' + typeCoin;
    }
    return this.get(url, params);
  }

  withdraw(params) {
    let url = '/withdraw';
    return this.post(url, params);
  }

  confirmWithdraw({token, codeSms, codeEmail}) {
    let url = `/verify-withdraw/${token}?code_sms=${codeSms}&code_mail=${codeEmail}`;
    return this.get(url);
  }

  withdrawUsd(params) {
    let url = '/transactions/withdraw-usd';
    return this.post(url, params);
  }

  depositUsd(params) {
    let url = '/transactions/deposit-usd';
    return this.post(url, params);
  }

  getUsdTransactions(params) {
    let url = '/transactions/usd';
    return this.get(url, params);
  }

  cancelUsdDepositTransaction(transactionid) {
    let url = '/transactions/deposit/cancel-usd/' + transactionid;
    return this.put(url);
  }

  getStats(params) {
    let url = '/transactions/stats';
    return this.get(url, params);
  }

  getTotalPendingWithdraw(params) {
    let url = '/transactions/withdraw/total-pending-withdraw';
    return this.get(url, params);
  }

  getTotalUsdPendingWithdraw(params) {
    let url = '/transactions/withdraw/total-usd-pending-withdraw';
    return this.get(url, params);
  }

  downloadTransactions(params, fileName) {
    const url = '/transactions/export';
    this.download(url, params, fileName);
  }

  buyAml(params) {
    const url = '/aml-transactions';
    return this.post(url, params);
  }

  getPriceToBTC(params) {
    let url = '/coin-info/get-price-vs-btc';
    return this.getFutures(url, params);
  }

  getTransferHistory(params) {
    let url = '/transfer/history';
    return this.get(url, params);
  }
}
