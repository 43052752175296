// import {loadLanguageAsync} from '@/common/bootstrap'
import Cookies from 'js-cookie'

export default class AuthenticationUtils {

  static isAuthenticated() {
    AuthenticationUtils.loadDataIfNeed();
    return !!AuthenticationUtils.accessToken;
  }

  static saveAuthenticationData(data) {
    // window.localStorage.setItem('access_token', data.access_token || '');
    AuthenticationUtils.accessToken = data.access_token || '';
    AuthenticationUtils.setLocale(data.locale);
    Cookies.set('access_token', data.access_token, { domain: process.env.VUE_APP_PUBLIC_URL })
  }

  static removeAuthenticationData() {
    AuthenticationUtils.saveAuthenticationData({});
    AuthenticationUtils.accessToken = '';
    Cookies.remove('access_token', { domain: process.env.VUE_APP_PUBLIC_URL })
    Cookies.remove('access_token', { domain: process.env.VUE_APP_PUBLIC_URL_DR })
    // window.localStorage.setItem('locale', AuthenticationUtils.getLocale());
    Cookies.set('locale',AuthenticationUtils.getLocale() || 'en', { domain: process.env.VUE_APP_PUBLIC_URL })
  }

  static getAccessToken() {
    AuthenticationUtils.loadDataIfNeed();

    return AuthenticationUtils.accessToken;
  }

  static loadData() {
    AuthenticationUtils.accessToken = Cookies.get('access_token', { domain: process.env.VUE_APP_PUBLIC_URL }) || "";
    AuthenticationUtils.dataLoaded = true;
  }

  static loadDataIfNeed() {
    if (AuthenticationUtils.dataLoaded === undefined || !AuthenticationUtils.dataLoaded) {
      AuthenticationUtils.loadData();
    }
  }

  static setLocale(newLocale = null) {
    const locale = newLocale || AuthenticationUtils.getLocale();

    if (locale === AuthenticationUtils.getLocale()) {
      return;
    }

    const html = document.documentElement;
    html.setAttribute('lang', locale);

    // window.i18n.locale = locale;
    // loadLanguageAsync(locale).then(() => {
    //   window.app.$broadcast('UPDATED_LOCALE', locale);
    //   window.localStorage.setItem('locale', locale);
    // })

    return 
  }

  static getLocale(defaultLocale = 'en') {
    return Cookies.get('locale', { domain: process.env.VUE_APP_PUBLIC_URL }) || defaultLocale;
  }

};
