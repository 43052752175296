<template>
  <div id="setting_dashboard">
    <change-password ref='changePassComponent'/>
    <otp-modal name="otp_modal" :submit="updateModeWhitelist" :params="getParamsUpdateModeWhitelist()" :is-disable-button="isDisableButton" :contact_email="siteSetting.contact_email"/>
    <cancel-google-auth-modal @CancelGoogleAuthModal:CLOSE="hideCancelGoogleAuthModal"></cancel-google-auth-modal>
    <enable-google-authentication @EnableGoogleAuthentication:CLOSE="hideEnableGoogleAuthenticaion"></enable-google-authentication>
    <lost-otp-modal :contact_email="siteSetting.contact_email" name="lostOtpForm" @DISABLE_OTP_SUCCESS="disableOtpSuccess"/>
    <confirmation-modal>
      <template slot="content">
        <div class="checkbox-bank">
          <input type="checkbox" id="c-bank" v-model="isCreateNewBankAccount"/>
          <label for="c-bank">{{ $t('refferal.confirm_bank_account_content') }}</label>
        </div>
      </template>
    </confirmation-modal>
        <div class="right_list_option_acc">
          <div class="setting_top_header">
            <AccountTopHeader :title="$t('account.dashboard')"/>
          </div>
          <div v-show="fetching == false" class="contents_setting">
            <p class="title-medium-small">{{ $t('menu.account')}}</p>
            <div class="acount_info">
              <div class="acc_info">
                <div class="img-user"><img src="/images/icon/icon-user.svg" alt="icon"></div>
                <div class="sub_acc_info">
                  <div class="email_acc">{{user.email}}</div>
                  <div class="lv_content" :class="kyc.status">
                    <div class="kyc_level">
                      <div class="kyc_status">{{$t('menu.kyc_status')}}</div>
                      <div class="group_verify" v-show="fetching == false">
                        <div v-if="kyc.status!='pending' && kyc.status!='verified' && kyc.status!='retry_rejected' && kyc.status!='final_rejected'" class="status unverified">
                          {{ $t('account.btn_unverified')}}
                        </div>
                        <div v-if="kyc.status=='verified'" class="status verified">
                          {{ $t('account.btn_verified')}} 
                        </div>
                        <div v-if="kyc.status=='pending'" class="status pending">
                          {{ $t('account.btn_pending')}}
                        </div>
                        <div v-if="kyc.status=='retry_rejected'" class="status rejected">
                          {{ $t('account.btn_fail')}}
                        </div>

                        <div v-if="kyc.status=='final_rejected'" class="status rejected">
                          {{ $t('account.btn_rejected')}}
                        </div>

                        <div v-if="!kyc || !kyc.status && levelOtpVerify === 0 || kyc.status === 'retry_rejected' && levelOtpVerify === 0 "
                              class="status click verified" @click="onClickedVerifyGoogleAuthen(linkKycAccount)">
                          {{ $t('account.btn_verify_now')}}
                        </div>
                        <div v-show="!kyc || !kyc.status && levelOtpVerify === 1 || kyc.status === 'retry_rejected' && levelOtpVerify === 1 "
                              class="status click verified" id="kyc-connect" @click="startVerification">
                          {{ $t('account.btn_verify_now')}}
                        </div>
<!--                          <div v-if="kyc.status === 'pending' " class="status click verified" id="kyc-connect" >-->
<!--                            {{ $t('account.btn_verify_change')}}-->
<!--                          </div>-->
                      </div>
                      <div class="group_verify" v-if="fetching == true">
                        <div class="spinner" >
                          <div class="spinner-border">
                          </div> 
                        </div>
                      </div>
                  </div>
                  <div class="acc_user_id">
                    <p class="label-userID">{{$t('account.user_id')}}</p>
                    <div class="text-userID">{{ user.uid }}</div>
                  </div>
                    <div class="kyc_verify">
                      <div class="level" v-if="user.security_level">{{ $t('account.lv')}}.{{ user.security_level }}</div>
                      <div class="time_account" v-if="lastLogin.created_at">
                        {{ $t('account.last_login_time')}}: {{lastLogin.created_at | timestampToDate}} {{ $t('account.ip')}}: {{lastLogin.ip_address}}
                      </div>
                    </div> 
                  </div>
                </div>
              </div>
            
              <div class="acc_info_level">
                <p class="title-medium-small">{{$t('account.level')}}</p>
                <div class="none d-show">
                  <span class="text-label">{{$t('account.daily_limit_withdraw')}}</span>
                </div>
                <div class="withdraw_limit_map">
                  <div class="road_lv">
                    <div v-for="(item, index) in withdrawalLimitBTC" :key="index" class="item_road">
                      <div class="text_milestone dp-none">
                        <div class="title_step" :class="{'text-left': index == 0}" v-if="index!==(withdrawalLimitBTC.length-1)">
                          {{$t('account.daily_limit_withdraw')}}
                          <div class="nb_read_item">{{item.daily_limit | formatNumberAndDecimal}} BTC</div>
                        </div>
                        <div class="title_step text-right" v-if="index===(withdrawalLimitBTC.length-1)">
                          {{$t('account.daily_limit_withdraw')}}
                          <div class="nb_read_item">{{item.daily_limit | formatNumberAndDecimal }} BTC</div>
                        </div>
                      </div>
                      <div class="milestone">
                        <div class="icon-diamond-1" :class="{ 'step_active': (userSecurityLevel >= item.security_level) }" v-if="index !== withdrawalLimitBTC.length-1">
                          <div class="level" :class="{'left': index === 0}">
                            <i class="icon-diamond"></i>
                            <span class="text">{{$t('account.lv')}}.{{index+1}}</span>
                          </div>
                          <div class="none d-show">
                        <div class="widthdraw_limit">
                          {{item.daily_limit | formatNumberAndDecimal}} BTC
                        </div>
                      </div>
                        </div>
                        <div class="icon-diamond-1" :class="{ 'step_active': (userSecurityLevel >= item.security_level) }" v-if="index === withdrawalLimitBTC.length-1">
                          <div class="level right">
                            <i class="icon-diamond"></i>
                            <span class="text">{{$t('account.lv')}}.{{index+1}}</span>
                          </div>
                          <div class="none d-show">
                        <div class="widthdraw_limit">
                          {{item.daily_limit | formatNumberAndDecimal}} BTC
                        </div>
                      </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="acc_info_balance">
                  <div class="blance_left">
                    <div class="wrap_estmate">
                      <span class="text_estimate">{{$t('account.estimated_balance')}}</span>
                      <img :src="`/images/${typeIcon}.svg`" alt="icon" @click="toggleShowEstimate">
                    </div>
                    <div v-if="isShowEstimate">
                      <div class="number_balance">
                        <span class="value">{{ totalBtcBalance | formatNumberAndDecimal }} BTC</span>
                        <span class="sub_value">≈ ${{ totalUsdBalance | formatNumberAndDecimal }}</span>
                      </div>
                      <div class="estimate_note_wrap">
                        <div class="estimate_note">{{$t('account.estimate_note')}}</div>
                      </div>
                    </div>
                    <div v-else>
                      <div class="number_balance">
                        <span class="value">******</span>
                      </div>
                      <div class="estimate_note_wrap">
                        <div class="estimate_note">{{$t('account.estimate_note')}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="blance_right">
                    <div class="button_group">
                      <router-link to="/funds/deposits-wallet?coin=usdt">
                        <button class="button deposit">{{$t('funds.balances.deposit')}}</button>
                      </router-link>
                      <router-link to="/funds/withdraw-wallet?coin=usdt">
                        <button class="button withdraw">{{$t('funds.balances.withdrawal')}}</button>
                      </router-link>
                    </div>
                    <div class="overview">
                      <i class="icon-wallet-overview"></i>
                      <router-link to="/funds/balances-wallet" class="link">
                        <span class="text_overview">{{$t('funds.header')}}</span>
                        <i class="icon-arrow3"></i>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
              <div id="sumsub-wrapper" v-if="showSumsub" @click="cancelVerify">
                <div id="sumsub-websdk-container" @click="cancelVerify"></div>
              </div>
              </div>
          </div>
          <div v-if="fetching == true" class="d-flex justify-content-center align-items-center contents_setting" style="max-width: initial">
            <div class="loader"></div>
          </div>
        </div> 
  </div>
</template>

<script>
import rf from '@/request/RequestFactory'
import RemoveErrorsMixin from '@/common/RemoveErrorsMixin';
import CancelGoogleAuthModal from '@/components/shared_components/otp/CancelGoogleAuthModal';
import OtpModal from '@/components/shared_components/funds/common/OtpModal';
import EnableGoogleAuthentication from './account/EnableGoogleAuthentication';
import ChangePassword from './account/ChangePassword.vue';
import DeviceManager from './account/DeviceManager.vue';
import LostOtpModal from '@/components/shared_components/otp/LostOtpModal';
import ConfirmationModal from '@/components/shared_components/common/ConfirmationModal';
import SelectBox from '@/components/shared_components/common/SelectBox';
import moment from 'moment';
import Const from '@/common/Const';
import {getLayoutPath} from '@/routes';
import ChartContracts from "./ChartIndex.vue";
import {mapGetters, mapState} from 'vuex';
import AccountSiteBar from '@/components/shared_components/common/AccountSiteBar.vue';
import AccountTopHeader from '@/components/shared_components/common/AccountTopHeader.vue';
import BigNumber from "bignumber.js";
import 'url-search-params-polyfill';
import Cookies from "js-cookie";
import snsWebSdk from '@sumsub/websdk';

const KYC_UNVERIFED = 'unverified';
  const KYC_PENDING = 'pending';
  const KYC_VERIFIED = 'verified';
  const KYC_REJECTED = 'rejected';
  const BANK_UNVERIFED = 'unverified';
  const BANK_CREATING = 'creating';
  const BANK_VERIFING = 'verifing';
  const BANK_VERIFIED = 'verified';
  const BANK_REJECTED = 'rejected';
  var sha1 = require('sha1');
  BigNumber.config({ EXPONENTIAL_AT: 15 });
  export default {
    components: {
      ChartContracts,
      CancelGoogleAuthModal,
      EnableGoogleAuthentication,
      OtpModal,
      ChangePassword,
      LostOtpModal,
      DeviceManager,
      ConfirmationModal,
      SelectBox,
      AccountSiteBar,
      AccountTopHeader
    },
    mixins: [RemoveErrorsMixin],
    data() {
      return {
        linkKycAccount: getLayoutPath({name: 'IdentityPersonal'}, true),
        linkAPIAccount: getLayoutPath({name: 'ApiCreated'}, true),
        linkAPIPage: process.env.VUE_APP_API_URL + '/api/documentation',
        isShowTooltip: false,
        tab : 'main',
        enableWhiteList: undefined,
        enableLeaderboard: undefined,
        localShowFakeName: undefined,
        lastLogin: {},
        withdrawalLimitBTC: [],
        check: 0,
        settings: {
          email: true,
          phone: false,
          bank: false,
          otp: false,
          password: false,
        },
        kyc: {
          status: '',
          bank_status: '',
        },
        user: {},
        infomation: {},
        otp: undefined,
        userSettingWhitelist: undefined,
        enableOtp: true,
        emailNotification: true,
        telegramNotification: true,
        lineNotification:false,
        isCreateNewBankAccount: false,
        enableAmalFromAdmin: false,
        enableAmlPay: false,
        app_name : process.env.VUE_APP_NAME,
        moment: moment,
        isDisableButton: false,
        siteSetting: {},
        idEncrypt:'',
        perpetual_dividend: false,
        isEditFakeName: false,
        fakeNameTemp:'',
        isShowEstimate: true,
        // kyc_key : process.env.VUE_APP_BLOCKPASS_KYC_KEY,
        coinsMasterData: [],
        dataBalance: [],
        prices: {},
        balanceFutures: [],
        fetching: false,
        showSumsub: false,
      }
    },
    watch: {
      enableAmalFromAdmin(newValue) {
        if (!newValue) {
          this.enableAmlPay = false;
        }
      }
    },
    mounted(){
      this.getMasterData();
      let url=window.location.href;
      if(url.includes("need-close")) {
          window.close();
      }
      this.isShowEstimate = this.isShowBalances.dashboard
      this.getHistoryConnection();
    },
    computed: {
      ...mapState({
        masterdata: state => state.masterdata,
        theme: state => state.userThemeMode,
      }),
      ...mapGetters({
        isShowBalances: 'isShowBalances'
      }),
      isVerifiableBank() {
        return this.kyc.bank_status === BANK_CREATING
                || this.kyc.bank_status === BANK_VERIFING || this.kyc.bank_status === BANK_VERIFIED;
      },
      appUrl() {
        return window.location.origin;
      },
      submittableKyc() {
        return window._.get(this, "user.security_setting.otp_verified", 0);
      },
      userSecurityLevel() {
        if (this.user.security_level > this.user.max_security_level) {
          return this.user.security_level;
        }
        return this.user.max_security_level;
      },
      typeIcon() {
        if(this.theme === 'light-theme') {
          return this.isShowEstimate ? 'eye-open' : 'eye-close';
        } else {
          return this.isShowEstimate ? 'eyes-open-dark' : 'eyes-close-dark';
        }
      },
      levelOtpVerify() {
        return this.user?.security_setting?.otp_verified
      },
      totalBtcBalance() {
        return this.sumBalanceBy("btcValue");
      },
      totalUsdBalance() {
        return this.sumBalanceBy("usdValue");
      }
    },
    methods: {
      toggleShowEstimate() {
        this.isShowEstimate = !this.isShowEstimate;
        const balances = {...this.isShowBalances, dashboard: this.isShowEstimate}
        Cookies.set("hide-balance", JSON.stringify(balances, {domain: process.env.VUE_APP_PUBLIC_URL}))
        this.$store.commit('setShowBalances', balances)
      },
      onSaveFakeName(){
        rf.getRequest('UserRequest').onUpdateFakeName({fake_name: this.fakeNameTemp}).then(res =>{
          if(res && res.data){
           this.user.fake_name = this.fakeNameTemp;
           this.isEditFakeName = false;
           this.getUser(false);
           this.showSuccess(this.$t('mam.update.success'));
          }
        })
      },
      // activeLeaderboard() {
      // },
      getLinkPnlChart() {
        rf.getRequest('UserRequest').getLinkPnlChart({name: 'Pnl-Chart'}).then(res => {
            const path = APP_URL + '/pnl-chart?token='+res.data.id
            // if (!navigator.clipboard) {
            //   Utils.fallbackCopyTextToClipboard(path);
            //   return;
            // }
            // navigator.clipboard.writeText(path);
            // this.$router.push({ name: 'PNLChart', query: {token: res.data.id} });
            window.open(path,"_blank");
        });
      },
      convertDate(date) {
        var dateFormat = 'YYYY-MM-DD HH:mm:ss';
        var testDateUtc = moment.utc(date);
        var localDate = testDateUtc.local();
        return localDate.format(dateFormat);
      },
      addChannelTelegram: function (userID) {
        document.getElementById("cancel_focus_telegram").blur();
        this.listenForTelegramLine(userID);
        let apiTelegram = process.env.VUE_APP_MIX_API_TELEGRAM + userID;
        window.open(apiTelegram, "_blank");
      },

      getParamsUpdateModeWhitelist() {
        return {
          active: !this.userSettingWhitelist
        }
      },
      listenForTelegramLine(userId) {
          window.Echo.private('App.User.' + userId)
            .listen('UserNotificationUpdated', (res) => {
                  let channel = res.data.channel;
                  let is_enable= res.data.is_enable;
                  if(channel==="line"){
                    this.lineNotification = is_enable;
                  }
                  if(channel==="mail"){
                    this.emailNotification = is_enable;
                  }
                  if(channel==="telegram"){
                    this.telegramNotification = is_enable;
                  }
            });
      },
      async redirectLine(userID){
          await this.encryptId();
          document.getElementById("cancel_focus_line").blur();
          if(userID){
            let callback_uri=this.$router.resolve({name: 'Account'}).href+"?need-close";
            let state = userID + "&" + this.idEncrypt + "$" + callback_uri;
            let client_id=process.env.VUE_APP_MIX_LINE_CLIENT_ID;
            let redirect_uri=process.env.VUE_APP_API_URL+'/api/v1/get-auth-code';
            let response_type = "code";
            let url="https://notify-bot.line.me/oauth/authorize?";
            let scope="notify";
            var searchParams = new URLSearchParams("");
            searchParams.append("client_id",client_id);
            searchParams.append("redirect_uri",redirect_uri);
            searchParams.append("response_type",response_type);
            searchParams.append("scope",scope);
            searchParams.append("state",state);
            url=url+searchParams;
            window.open(url,"_blank");
          }
      },
      changeWalletFee(type) {
        if (this.tab != type && this.enableAmlPay) {
          this.tab = type;
          let param = {
            wallet_name : type,
          }
          rf.getRequest('UserRequest').changeTypeWalletAmalPayFee(param).then(res => {});
        }

      },
        
      showChannelModal(type, channel) {
          if (type === 'disable') {
              window.ConfirmationModal.show({
                  type: channel === 'email' ? 'disableEmail' : channel === 'telegram' ? 'disableTelegram' : channel==='line' ? 'disableLine':'',
                  title: '',
                  content: channel === 'email' ?
                      this.$t('account.email_disable_confirm') :
                      channel === 'telegram' ? this.$t('account.email_disable_confirm') :
                      channel === 'line' ? this.$t('account.email_disable_confirm') : '',
                  btnCancelLabel: window.i18n.t('common.action.no'),
                  btnConfirmLabel: window.i18n.t('common.action.yes'),
                  onConfirm: () => {
                      this.actionNotification(type, channel);
                  }
              });
          } else {
              document.getElementById("cancel_focus_mail").blur();
              this.actionNotification(type, channel);
          }
      },
      actionNotification(type, channel) {
            let param = {
                active: 1
            };
            if (type === 'disable') {
                param.active = 0;
            } else {
                param.active = 1;
            }
            if (channel === 'email') {
                rf.getRequest('UserRequest').changeEmailNotification(param).then(res => {
                    this.getUserNotification();
                });
            }
            if (channel === 'telegram' && param.active == 0) {
                rf.getRequest('UserRequest').changeTelegramNotification(param).then(res => {
                    this.getUserNotification();
                });
            }
            if (channel === 'line' ) {
                rf.getRequest('UserRequest').changeLineNotification(param).then(res => {
                    this.getUserNotification();
                });
            }
        },
      showTooltip() {
        this.isShowTooltip = !this.isShowTooltip;
      },
      hideTooltip() {
        this.isShowTooltip = false;
      },
      showCancelGoogleAuthModal() {
        window.CommonModal.show('CancelGoogleAuthModal');
      },
      showChangePasswordModal() {
        window.CommonModal.show('ChangePassword');
        this.$refs.changePassComponent.resetData();
      },
      hideCancelGoogleAuthModal() {
        window.CommonModal.hide('CancelGoogleAuthModal');
        this.getSecuritySettings();
        this.getUser(false);
      },
      hideEnableGoogleAuthenticaion() {
        window.CommonModal.hide('EnableGoogleAuthentication');
        this.getSecuritySettings();
        this.getUser(false);
      },
      showEnableOtpModal() {
        window.CommonModal.show('EnableGoogleAuthentication');
      },
      showModalLostOtp () {
        window.CommonModal.show('lostOtpForm');
      },
      disableOtpSuccess () {
        this.getSecuritySettings();
        this.getUser(false);
      },
      loadDataImmediately() {
        this.getUserSettings();
        this.getUserNotification();
        this.getSecuritySettings();
        this.getUser();
        this.getWithdrawalLimitBTC();
        this.getUserKyc();     
        this.getDividendSettings();   
      },
      getSecuritySettings() {
        rf.getRequest('UserRequest').getSecuritySettings().then(res => {
          this.settings = Object.assign({}, this.settings, res.data);
          this.enableOtp = window._.get(this, "settings.otp_verified", 0);
        });
      },
      getUserKyc() {
        rf.getRequest('UserRequest').getUserKyc().then(res => {
          this.kyc = res.data || {};
          this.isCreateNewBankAccount = this.kyc.bank_status === BANK_CREATING;
          this.fetching = false;
        });
      },
      async encryptId() {
        await rf.getRequest('UserRequest').encryptId().then(res => {
          this.idEncrypt = sha1(res.toString()) || {};          
        });                
      },
      getDividendSettings() {
        rf.getRequest('UserRequest').getDividendSetting().then(res => {
          this.enableAmalFromAdmin = res.data?.enable_fee_amal || false;
          this.perpetual_dividend = res.data?.enable_wallet_pay_fee || false;
        })

      },
      getHistoryConnection(params) {
        const promise = rf.getRequest('UserRequest').getHistoryConnection(params);
        this.getLatest(promise);
        return promise;
      },
      getLatest(promise) {
        promise.then(res => {
          this.lastLogin = res.data.data[0];
        });
      },

      getUserNotification(){
        rf.getRequest('UserRequest').getUserNotificationSettings().then(res => {
            const emailNotification =  _.find(res.data, (item) => {
              return item.channel === 'mail'
            }) || {};

            this.emailNotification = emailNotification.is_enable === 1;

            const telegramNotification = _.find(res.data, (item) => {
              return item.channel === 'telegram'
            }) || {};

            this.telegramNotification = telegramNotification.is_enable === 1;

            const lineNotification = _.find(res.data, (item) => {
              return item.channel === 'line'
            }) || {};
            this.lineNotification = lineNotification.is_enable === 1;
        });
      },

      getUserSettings() {
        rf.getRequest('UserRequest').getUserSettings().then(res => {
          const setting = _.find(res.data, (item) => {
            return item.key === 'whitelist'
          }) || {};
          this.userSettingWhitelist = this.enableWhiteList = !!parseInt(setting.value);

          const amlPay =  _.find(res.data, (item) => {
            return item.key === 'amal_pay'
          }) || {};
          const amlWalletPay =  _.find(res.data, (item) => {
            return item.key === 'amal_pay_wallet'
          }) || {};
          this.enableAmlPay = amlPay.value === "1";
          if (amlWalletPay && amlWalletPay.value) {
            this.tab = amlWalletPay.value;
          }

        });
      },
      getUser(useCache = true) {        
        rf.getRequest('UserRequest').getCurrentUser(useCache).then(res => {
          this.user = Object.assign({}, this.user, res.data);
          this.listenForTelegramLine(res.data.id);
          this.localShowFakeName = this.user.security_setting.use_fake_name;
          return this.enableLeaderboard = this.user.security_setting.use_fake_name
        });
      },
      getWithdrawalLimitBTC() {
        rf.getRequest('UserRequest').getWithdrawalLimitBTC().then(res => {
            this.withdrawalLimitBTC = res.data;
        });
      },
      activeWhitelist() {
        const message = this.userSettingWhitelist ? window.i18n.t('account.turn_on_mode_whitelist') : window.i18n.t('account.turn_off_mode_whitelist');
        this.showModal({
          type: 'primary',
          title: '',
          content: message,
          onConfirm: () => {
            if (this.enableOtp) {
              window.CommonModal.show('otp_modal');
              this.enableWhiteList = this.userSettingWhitelist;
            } else {
              const params = this.getParamsUpdateModeWhitelist();
              this.updateModeWhitelist(params);
            }
          },
          onCancel: () => {
            this.enableWhiteList = this.userSettingWhitelist;
          }
        });
      },
      updateModeWhitelist(params) {
        this.isDisableButton = true;
        rf.getRequest('UserRequest').activeWhitelist(params).then( response => {
          this.isDisableButton = false;
          window.CommonModal.hide('otp_modal');
          this.userSettingWhitelist = this.enableWhiteList = response.data.whitelist;
        }).catch(error => {
          this.isDisableButton = false;
          if (!error.response) {
            Message.error(window.i18n.t('common.message.network_error'));
          } else {
            this.convertRemoteErrors(error);
          }
        })
      },
      activeLeaderboard() {
        const message = this.enableLeaderboard ? window.i18n.t('leaderboard.show_fake_username') : window.i18n.t('leaderboard.show_real_username');
        this.showModal({
          title: '',
          content: message,
          btnCancelLabel: window.i18n.t('common.action.no'),
          btnConfirmLabel: window.i18n.t('common.action.yes'),
          onConfirm: () => {
            this.updateLeaderboard()
          },
          onCancel: () => {
            this.enableLeaderboard = !this.enableLeaderboard ? 1 : 0
          }
        });
      },
      updateLeaderboard() {
        let params = {
          use_fake_name: !this.enableLeaderboard ? 0 : 1
        }
        rf.getRequest('UserRequest').activeLeaderBoard(params).then(res=>{
          this.localShowFakeName = res.data;
        }).catch(err=>{
          this.localShowFakeName = 0;
        });
      },
      showSuccess(message) {
        Message.success(
          message,
          {},
          { position: 'bottom_left' }
        );
      },
      getSocketEventHandlers() {
        return {
          OtpUpdated: this.getSecuritySettings
        }
      },
      verifyBankAccount (status) {
        rf.getRequest('UserRequest').updateBankAccountStatus(status).then(res => {
          this.getUserKyc();
        });
      },
      verifyBankModal () {
        if (this.kyc.status !== KYC_VERIFIED) {
          this.showModal({
            type: 'warning',
            title: 'Error',
            content: this.$t('refferal.alert_bank_account_content'),
            noAction: true,
          });
          return;
        }
        this.showModal({
          type: 'confirm',
          title: this.$t('refferal.confirm_bank_account_title'),
          customContent: true,
          onConfirm: () => {
            this.verifyBankAccount({status: this.isCreateNewBankAccount ? BANK_CREATING : BANK_VERIFING})
          }
        });
      },
      showModal (config) {
        const type = config.type;
        const title = config.title;
        const content = config.content;
        const customContent = config.customContent;
        let btnCancelLabel = config.btnCancelLabel || this.$t('common.action.no');
        let btnConfirmLabel = config.btnConfirmLabel || this.$t('common.action.yes');
        if (config.noAction) {
          btnCancelLabel = null;
          btnConfirmLabel = null;
        }
        const onConfirm = config.onConfirm;
        const onCancel = config.onCancel;
        window.ConfirmationModal.show({
          type: type,
          title: title,
          content: content,
          customContent: customContent,
          btnCancelLabel: btnCancelLabel,
          btnConfirmLabel: btnConfirmLabel,
          onConfirm: onConfirm,
          onCancel: onCancel
        });
      },
      onClickedVerifyGoogleAuthen(pathRoute) {
        if (!this.submittableKyc) {
          Message.error(this.$t('account.otp.should_enable_first'), {}, { position: 'bottom_left' });
          return;
        }
        // this.$router.push({ path: pathRoute });
      },
      activeAmlPay() {
        if (this.enableAmalFromAdmin) {
          let param = {
            active: !this.enableAmlPay ? 1 : 0
          }
          rf.getRequest('UserRequest').changeAmlPay(param).then(res => { });
        }
      },
      getUserDevice(params) {
        const promise = rf.getRequest('UserRequest').getUserDevice(params);
        return promise;
      },
      onDatatableFinished() {
        if (!this.$refs.datatableDevice || !this.$refs.datatableDevice.rows) {
          return;
        }
        window._.each(this.$refs.datatableDevice.rows, item => {
          item.isActiveRow = false;
        });
      },
      activeRow(item) {
        item.isActiveRow = !item.isActiveRow;
        this.$forceUpdate();
      },
      refreshDevice() {
        this.$refs.datatableDevice.refresh();
      },
      confirmRemoveDevice (deviceId) {
        window.ConfirmationModal.show({
          type: 'primary',
          title: '',
          content: this.$t('account.modal.confirm_trusted_device'),
          btnCancelLabel: window.i18n.t('common.action.no'),
          btnConfirmLabel: window.i18n.t('common.action.yes'),
          onConfirm: () => {
            this.removeDevice(deviceId);
          },
          onCancel: () => {
            
          }
        });
      },
      removeDevice (deviceId) {
        rf.getRequest('UserRequest').deleteDevice(deviceId).then((res) => {
          this.refreshDevice();
          Message.success(window.i18n.t('address.deleted_success'));
        });
      },

      getMasterData() {
        window._.each(this.masterdata.settings, item => {this.siteSetting[item.key] = item.value;});
        this.coinsMasterData = window._.keyBy(this.masterdata.coins, (item) => item.coin);
      },

      async getNewAccessToken() {
        const res = await rf.getRequest('UserRequest').getNewToken()
        return Promise.resolve(res?.token)
        
      },
      cancelVerify(){
         this.showSumsub = false
         const elementSumsub = document.getElementById('sumsub-websdk-container')
         elementSumsub.style.display = 'none'
         document.body.style.overflowY = 'auto';
      },
      async startVerification (){
        if(this.showSumsub) return
        let accessToken = ''
        this.showSumsub = true
        await rf.getRequest('UserRequest').getNewToken().then(res => {
          accessToken = res.token
        });

       let snsWebSdkInstance = snsWebSdk.init(
            accessToken,
            // token update callback, must return Promise
            // Access token expired
            // get a new one and pass it to the callback to re-initiate the WebSDK
            () => this.getNewAccessToken()
        )
        .withConf({
            lang: Cookies.get('locale', { domain: process.env.VUE_APP_PUBLIC_URL }),
            uiConf: {
                customCssStr: ":root {\n  --black: #000000;\n   --grey: #fff;\n  --primary-color:var(--text-primary-color);\n   --grey-darker: #B2B2B2;\n  --border-color: #DBDBDB;\n}\n\np {\n  color: var(--black);\n  font-size: 16px;\n  line-height: 24px;\n}\n\n.steps {\n display: none;\n}\n\nsection {\n  margin: 0px auto;\n}\n\ninput {\n  color: var(--black);\n  font-weight: 600;\n  outline: none;\n}\n\nsection.content {\n  background-color: var(--grey);\n  color: var(--black);\n  padding: 40px 40px 16px;\n  box-shadow: none;\n  border-radius: 6px;\n}\n\nbutton.submit,\nbutton.back {\n  text-transform: capitalize;\n  border-radius: 6px;\n  height: 48px;\n  padding: 0 30px;\n  font-size: 16px;\n  background-image: none !important;\n  transform: none !important;\n  box-shadow: none !important;\n  transition: all 0.2s linear;\n}\n\nbutton.submit {\n  min-width: 132px;\n  background: none;\n  background-color: var(--black);\n}\n\n.content {\n height: 92vh;\n overflow: auto;   }\n\n.round-icon {\n  background-color: var(--black) !important;\n  background-image: none !important;\n}\n\n.sumsub-logo {\n margin-top: -34px;}\n\n.iframe2{\n margin: 0;}\n\n#loader{\n position: absolute;\n top: 50%;\n left: 50%;\n transform: translate(-50%, -50%);\n margin: 0; }"
            },
            // email: applicantEmail,
            // phone: applicantPhone,
        })
        .withOptions({ addViewportTag: true, adaptIframeHeight: false})
        // see below what kind of messages WebSDK generates
        .on('idCheck.onApplicantLoaded', (payload) => {
            // const elementSumsub = document.getElementById('sumsub-websdk-container')
            // elementSumsub.style.marginTop = '50px'
        //     document.body.style.overflowY = 'hidden';
        //   const iframes = document.getElementsByTagName("iframe"); 
        //   for (var i = 0; i < iframes.length; i++) {
        //   var iframe = iframes[i];
        //   if (iframe.getAttribute("src")?.includes("sumsub")) {
        //     const isMobile = window.matchMedia('(max-width: 940px)').matches
        //     iframe.style.marginTop = '20px';
        //   }
        // }
        })
        .on('idCheck.onStepCompleted', (payload) => {
            console.log('onStepCompleted', payload)
            // this.cancelVerify()
        })
        .on('idCheck.onError', (error) => {
            console.log('onError', error)
        })
        .build();

        // you are ready to go:
        // just launch the WebSDK by providing the container element for it
        snsWebSdkInstance.launch('#sumsub-websdk-container')
        const iframes = document.getElementsByTagName("iframe"); 
          for (var i = 0; i < iframes.length; i++) {
          var iframe = iframes[i];
          if (iframe.getAttribute("src")?.includes("sumsub")) {
            const isMobile = window.matchMedia('(max-width: 940px)').matches
            iframe.style.height = isMobile ? "100vh" : "104vh";
          }
        }
      },
      getCoinName(coin) {
        let coinName = "";
        if (this.coinsMasterData && this.coinsMasterData[coin]) {
          coinName = this.coinsMasterData[coin].name;
        }
        return coinName;
      },
      formatCurrencyRoundDown(currency, value) {
        let numberOfDecimalDigits =
          currency && currency.toLowerCase() === "usd"
            ? Const.NUMBER_OF_DECIMAL_DIGITS_USD
            : Const.NUMBER_OF_DECIMAL_DIGITS; //currency === 'usd' ? 2 : 8;

        let newValue = new BigNumber(value);
        return newValue
          .round(numberOfDecimalDigits, BigNumber.ROUND_FLOOR)
          .toString();
      },
      sumBalanceBy(attr) {
        let total = new BigNumber(0);
        for (let item of this.dataBalance) {
          if (item[attr] == "NaN" || new BigNumber(item[attr]).isZero()) {
            continue;
          }
          total = total.add(item[attr]);
        }
        return total.toString();
      },
      convertToBtcValue(coin, value) {
        let balance = value;
        if (new BigNumber(balance).isZero()) return 0;

        let pair = `btc_${coin}`;
        if (!this.prices[pair] || !this.prices[pair].price) {
          pair = `${coin}_btc`;
          if (!this.prices[pair] || !this.prices[pair].price) return 0;
          
          return new BigNumber(`${balance}`)
            .div(this.prices[pair].price)
            .toString();
        }
        return new BigNumber(`${balance}`)
          .mul(this.prices[pair].price)
          .toString();
      },
      convertBtcToCoinValue(coin, value) {
        let balance = value;
        let pair = `btc_${coin}`;
        if (!this.prices[pair] || !this.prices[pair].price) {
          pair = `${coin}_btc`;
          if (!this.prices[pair] || !this.prices[pair].price) return 0;
          return new BigNumber(`${balance}`)
            .mul(this.prices[pair].price)
            .toString();
        }
        return new BigNumber(`${balance}`)
          .div(this.prices[pair].price)
          .toString();
      },
      addPricesInBtc() {
        this.prices["btc_btc"] = { price: 1 };
        for (const fiat of Const.FIAT_LIKE_LIST) {
          const fiatPrice = this.prices[`${fiat}_btc`];
          const checkNullPrice =
            fiatPrice && !new BigNumber(fiatPrice.price).isZero();
          const price = checkNullPrice
            ? new BigNumber("1").div(fiatPrice.price).toString()
            : "0";
          this.prices[`btc_${fiat}`] = { price };
        }
      },
      convertCoins(fromCoin, toCoin, value) {
        // Example:
        // If do not have price of usd_$coin pair, transform to btc_$coin pair then transform to usd_btc pair.
        let btcValue = this.convertToBtcValue(fromCoin, value);
        let newValue = this.convertBtcToCoinValue(toCoin, btcValue);

        return newValue;
      },
      onUpdateBalance(dataBalance) {
        const data = [];
        const coinList = dataBalance[Const.TYPE_MAIN_BALANCE];
        const mainBalanceData = dataBalance[Const.TYPE_MAIN_BALANCE];
        // const marginBalanceData = dataBalance[Const.TYPE_MARGIN_BALANCE];
        const marginBalanceData = this.balanceFutures.assets;
        const exchangeBalanceData = dataBalance[Const.TYPE_EXCHANGE_BALANCE];
        const mamBalanceData = dataBalance[Const.TYPE_MAM_BALANCE];
        const airdropBalanceData = dataBalance[Const.TYPE_AIRDROP_BALANCE];

        for (let coin in coinList) {
          const item = {}

          item.coin = coin;
          item.name = this.getCoinName(coin);
          item.balance = mainBalanceData && mainBalanceData[coin] && mainBalanceData[coin]?.balance || 0;
          item.availableBalance = mainBalanceData && mainBalanceData[coin] && mainBalanceData[coin]?.available_balance || 0;
          item.exchangeBalance = exchangeBalanceData && exchangeBalanceData[coin] && exchangeBalanceData[coin]?.balance || 0;
          item.airdropBalanceBonus = airdropBalanceData && airdropBalanceData[coin] && airdropBalanceData[coin]?.balance_bonus || 0;
          item.availableAirdropBalanceBonus = airdropBalanceData && airdropBalanceData[coin] && airdropBalanceData[coin]?.available_balance_bonus || 0;
          item.exchangeAvailabelBalance = exchangeBalanceData && exchangeBalanceData[coin] && exchangeBalanceData[coin]?.available_balance || 0;
          item.marginBalance = marginBalanceData && marginBalanceData[coin] && marginBalanceData[coin]?.balance || 0;
          item.marginAvailabelBalance = marginBalanceData && marginBalanceData[coin] && marginBalanceData[coin]?.available_balance || 0;
          item.mamBalance = mamBalanceData && mamBalanceData[coin] && mamBalanceData[coin]?.balance || 0;
          item.airdropBalance = airdropBalanceData && airdropBalanceData[coin] && airdropBalanceData[coin]?.balance || 0;
          item.totalBalance = new BigNumber(item.balance).add(item.exchangeBalance).add(item.marginBalance);
          let coinType = item.coin || ''
          // if (coinType == Const.BTC) {
          //   item.totalBalance = new BigNumber(item.totalBalance).add(item.marginBalance)
          // }
          if (coinType == Const.AML) {
            item.totalBalance = new BigNumber(item.totalBalance)
              .add(item.marginBalance)
              .add(item.airdropBalance)
              .add(item.airdropBalanceBonus)
          }
          item.totalBalance = this.formatCurrencyRoundDown(coin, item.totalBalance.toString())
          item.btcValue = this.convertToBtcValue(coin, item.totalBalance)

          let usdValue = this.convertCoins(Const.BTC, Const.USDT, item.btcValue)
          if (!usdValue) {
            const pair = 'usdt_btc'
            const price = this.prices[pair] ? this.prices[pair].price : '0'
            usdValue = new BigNumber(item.btcValue).mul(price).toString()
          }
          item.usdValue = usdValue;

          data.push(item);
        }
        this.dataBalance = window._.sortBy(data, 'name');
      },
        getBalanceFutureAPI() {
            return rf.getRequest("UserRequest")
                .getFuturesBalances()
                .then((res) => {
                    this.assetsWallet = res.data;
                    const assetsKeyObject = {};
                    res.data.forEach((asset) => {
                        assetsKeyObject[asset.asset.toLowerCase()] = {
                            balance: asset?.balance,
                            marginBalance: 0,
                            availableBalance: 0,
                            totalAllowcated: 0,
                            unrealisedPnl: 0,
                            unrealisedROE: "",
                        };
                    });
                    this.balanceFutures = {
                        assets: assetsKeyObject,
                    };
                });
        },
      getPriceCoinGecKotoBTC() {
        for (const coin in this.coinsMasterData) {
          const pairValue = `btc_${this.coinsMasterData[coin].coin}`;
          if (this.prices[pairValue] === undefined && coin !== 'usd') {
            rf.getRequest('TransactionRequest')
                .getPriceToBTC({symbol: coin})
                .then(res => {
                  this.prices[pairValue] = { price: res?.data || 0 }; // Set the price object
                })
                .catch(error => {
                  // Handle the error if needed
                });
          }
        }
      },
    },
    async created () {
      this.fetching = true;
      await rf.getRequest("PriceRequest")
        .getPrices()
        .then((res) => {
        this.prices = {...this.prices, ...res.data};
        this.addPricesInBtc();
        this.getPriceCoinGecKotoBTC()
      });
      rf.getRequest("UserRequest")
        .getBalance(false)
        .then((res) => {
          this.onUpdateBalance(res.data);
        });
      this.loadDataImmediately();
      await this.getBalanceFutureAPI()
        document.title = this.$t('menu.account') + ` – ${this.tabTitle}`;
    },
  }
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

#sumsub-wrapper {
  background: rgb(0,0,0,0.2);
  position: absolute;
  z-index: 9999;
  background: rgb(0,0,0,0.5);
  top: 0;
  left: 0;
  width: 100%;
  min-height: 120vh;
  cursor: pointer;
}

#sumsub-websdk-container{
  max-width: 700px;
  margin: auto;
  @media screen and (max-width: 740px) {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.loader {
  display: block;
  margin: auto;
  border: 5px solid $color-grey-concrete;
  border-radius: 50%;
  border-top: 5px solid $color-blue-custom;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}


/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.title-medium-small {
  color: var(--dark-1);
  font-family: $font-inter-bold;
  font-size: 30px;
  line-height: 36px;
  margin-bottom: 43px;
  text-transform: none;
}
.account_container {
  display: inline-block;
  width: 100%;
  padding: 0px;
  background-color: var(--background-color-primary-2);
}
.path_acount_container {
  display: flex;
  margin-top: 4px;
  gap: 4px;
  background-color: var(--color-black);
}
.acount_info {
  .acc_info {
    display: flex;
    gap: 24px;
    border-bottom: 2px solid var(--color-dark-5);
    padding-bottom: 30px;
    .img-user {
      img {
        width: 80px;
        height: 80px;
        cursor: default;
      }
    }
    .sub_acc_info {
      .email_acc {
        font-family: $font-inter-bold;
        font-size: 24px;
        line-height: 34px;
        color: var(--text-primary-color);
      }
      .label-userID {
        font-size: 16px;
        line-height: 19px;
        color: var(--text-desc);
        @media screen and (max-width: 400px) {
          margin-bottom: 0px;
        }
      }
      .text-userID {
        font-size: 16px;
        line-height: 19px;
        color: var(--text-primary-color);
        margin-top: 18px;
        @media screen and (max-width: 400px) {
          margin-top: 0px;
        }
      }
    }
    .lv_content {
      display: flex;
      gap: 30px;
      margin-top: 8px;
      .kyc_level {
        .kyc_status {
          font-family: $font-inter-regular;
          font-size: 16px;
          line-height: 19px;
          color: var(--text-desc);
        }
        .group_verify {
          display: flex;
          gap: 12px;
          .status {
            margin-top: 8px;
            padding: 8px 15px;
            border-radius: 8px;
            font-family: $font-inter-regular;
            font-size: 18px;
            line-height: 21px;
            &.click {
              @include btn-common(yes);
              padding: 8px 15px;
            }
            &.unverified {
              background: var(--bgr-btn);
              color: var(--text-primary-color);
            }
            &.verified {
              background: $color-aqua-green;
            }
            &.pending {
              background: $text-color-yellow;
            }
            &.rejected {
              background: $text-color-red;
              color: $color-white;
            }
          }
        }
        .spinner {
          margin-top: 8px;
          height: 37px;
          width: 90px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: var(--bgr-btn); 
          color: var(--text-primary-color);
          border-radius: 8px; 
          @media screen and (max-width: 400px) {
            height: 32px;  
          }
          div {
            width: 15px; 
            height: 15px;
          }
        }  
      }
      .kyc_verify {
        .level {
          font-family: $font-inter-regular;
          font-size: 16px;
          line-height: 19px;
          color: var(--text-desc);
        }
        .time_account {
          font-family: $font-inter-regular;
          font-size: 16px;
          line-height: 19px;
          color: var(--text-primary-color);
          margin-top: 18px;
          @media screen and (max-width: 400px) {
            margin-top: 0px;
          }
        }
      }
    }
  }
  .acc_info_level {
    padding-top: 30px;
    .withdraw_limit_map {
      @media screen and (max-width: 576px) {
        padding-top: 20px;
      }
      .road_lv {
        display: flex;
        .item_road {
          flex: 1;
          .title_step {
            font-family: $font-inter-regular;
            font-size: 18px;
            line-height: 21px;
            color: var(--text-desc);
          }
          .nb_read_item {
            font-family: $font-inter-bold;
            font-size: 30px;
            line-height: 36px;
            color: var(--text-primary-color);
            margin-top: 10px;
            margin-bottom: 16px;
            white-space: nowrap;
          }
        }
      }
    }
    .acc_info_balance {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      .blance_left {
        .wrap_estmate {
          display: flex;
          align-self: center;
          gap: 14px;
          .text_estimate {
            font-family: $font-inter-regular;
            color: var(--text-desc);
            font-size: 20px;
            line-height: 24px;
          }
        }
        .number_balance {
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          gap: 16px;
          max-width: 100%;
          .value {
            font-family: $font-inter-bold;
            color: var(--text-primary-color);
            font-size: 34px;
            line-height: 40px;
          }
          .sub_value {
            font-family: $font-inter-bold;
            color: var(--text-desc);
            font-size: 34px;
            line-height: 40px;
          }
        }
        .estimate_note_wrap {
          border-top: 1px dashed var(--text-search);
          max-width: 350px;
          .estimate_note {
            margin-top: 8px;
            background: var(--accent-color);
            border-radius: 8px;
            padding: 10px;
            color: $color-white;
            font-size: 14px;
            line-height: 18px;
          }
        }
      }
      .blance_right {
        .button_group {
          margin-bottom: 80px;
          display: flex;
          gap: 24px;
          .button {
            width: 150px;
            max-width: 100%;
            &.deposit {
              @include btn-common(yes);
            }
            &.withdraw {
              @include btn-common(no);
            }
          }
        }
        .overview {
          display: flex;
          gap: 8px;
          justify-content: flex-end;
          i:before {
            color: var(--text-desc);
          }
          .link {
            display: flex;
            gap: 8px;
            align-items: center;
            .text_overview {
              font-family: $font-inter-regular;
              color: var(--text-desc);
              font-size: 18px;
              line-height: 20px;
              white-space: nowrap;
            }
          }
        }
      }
    }
    @media screen and (max-width: 1600px) {
      padding-right: 40px;
    }
    @media screen and (max-width: 992px) {
      padding-right: 0;
    }
  }
}

  .submit_contact {
    font-size: $font-small;
    color: $color-yellow;
    height: 20px;
    margin-top: 10px;
    text-align: center;
    padding-left: 52px;
    div {
      height: 25px !important;
    }
  }
  .submit {
    width: 200px;
    color: $color-curious-blue;
  }
  .contact_us {
    width: 100px;
    color: $color-curious-blue;
    margin-right: 18px;
  }
  .h-18 {
    height: 18px;
  }
.road_lv {
  .item_road{
    text-align: center;
    &:last-child{
      .icon-diamond-1{
        margin-right: 0px;
        text-align: right;
      }
    }
    &:first-child{
      .icon-diamond-1{
        margin-left: 0px;
        text-align: center;
      }
    }
    .text_milestone{
      font-size: $font-small;
      color: $color-grey-dusty;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 15px;
      .nb_read_item{
        color: $color-grey-dark;  
        font-size: $font-root;
        font-weight: 600;
      }
    }
  }
}
  .milestone {
    width: 100%;
    border-bottom: 6px solid var(--color-dark-5);
    @media screen and (max-width: 576px) { 
    border-bottom: 2px solid var(--color-dark-5);
    }
  }
  .icon-diamond-1 {
    margin: auto;
    text-align: center;
    font-size: $font-big-20;
    position: relative;
    padding-bottom: 16px;

    .widthdraw_limit {
      font-family: $font-inter-bold;
        color: var(--text-primary-color);
        font-size: 12px;
        line-height: 16px;
        padding-top: 12px;
        @media screen and (max-width: 380px) {
          height: 40px;
        }
      }
    .icon-diamond::before {
      color: var(--text-desc);
    }
    &.step_active {
      color: $color-aqua-green;
      &:after{
        background: $color-aqua-green;
        content: "";
        display: block;
        width: 100%;
        height: 6px;
        position: absolute;
        left: 0px;
        bottom: -6px;
      }
    }
    .level {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      &.left {
        justify-content: flex-start;
      }
      &.right {
        justify-content: flex-end;
      }
      .text {
        font-family: $font-inter-bold;
        color: var(--text-desc);
        font-size: 20px;
        line-height: 24px;
      }
    }
    &.step_active {
      .level {
        .text, .icon-diamond::before {
          color: $color-aqua-green;
        }
      }
      &:after{
        @media screen and (max-width: 380px) {
          height: 2px;
        bottom: -2px;
        }
        height: 6px;
        bottom: -6px;
      }
    }
  }
  .right_list_option_acc{
    background-color: var(--background-color-primary-2);
    flex: 1;
    .contents_setting {
      max-width: 1320px;
      padding: 36px 0px 36px 43px;
      min-height: calc(100vh - 50px);
      .contents_setting_container {
        display: flex;
        gap: 28px;
        &.setting_border-bottom {
          border-bottom: 2px solid $color-white-alto;;
        }
        .text-main {
          font-family: $font-inter-bold;
          font-size: 20px;
          line-height: 24px;
          color: var(--dark-1);
        }
        .contents_setting_icon {
          width: 52px;
        }
        .preferences_group {
          flex: 1;
          .check_authen_activation  {
            font-family: $font-inter-regular;
            font-size: 16px;
            line-height: 20px;
            color: var(--dark-2);
          }
          .group-text-main {
            margin-bottom: 43px;
          }
          .group {
            margin-bottom: 30px;
            .group_center_option {
              display: flex;
              .text-mb {
                margin-bottom: 5px;
              }
              .center_group {
                flex: 1;
              }
              .group_btn {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .group_status {
                  display: flex;
                  gap: 10px;
                  align-items: flex-start;
                  .text-status {
                    font-family: $font-inter-regular;
                    font-size: 18px;
                    line-height: 21px;
                    color: var(--dark-1);
                    max-width: 245px;
                  }
                }
                .group_btn-btn {
                  .btn {
                    font-family: $font-inter-regular;
                    border-radius: 8px;
                    padding: 14px 20px;
                    font-size: 18px;
                    line-height: 21px;
                    min-width: 140px;
                    &.btn_disable {
                      color: var(--dark-1);
                      background: $color-gray-alto;
                    }
                    &.btn_enable {
                      color: $color-white;
                      background: $text-color-jade;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .link_account{
    font-size: $font-small;
    font-weight: 500;
    margin: 0px;
    margin-top:6px;
    margin-bottom:20px;
    color: $color-grey-dusty;
    line-height: 20px;
    display: block;
    a{
      color: $color-curious-blue;
      &:hover{
        text-decoration: none;
      }
    }
  }
  .link_button{
    color: $color-curious-blue;
  }

  .right_withdrawal_acc{
    display: block;
    width: calc(100% - 110px - 70px - 15px);
    position: relative;
    line-height: 20px;
    font-size: $font-small;
    color: $color-grey-dusty;
  }
  .switch{
    margin-bottom: 0px;       
    input:checked + span{
      background-color: $color-jungle-green;
    }
    span{
      background-color: #d8d8d8;
    }    
  }
  .icon_if_w{
    width: 18px;
    height: 18px;
    font-size: $font-big;
    color: $color-alizarin-crimson
  }
  .btn_active{
    height: 35px;
    max-width: 100%;
    width: 246px;
    margin-right: 15px;
    text-align: center;
    background-color: $color-jungle-green;
    border-radius: 3px;
    font-weight: 600;
    color: $color-white;
    font-size: $font-smaller;
    text-transform: uppercase;
    display: inline-block;
    padding: 8px 0px;
    line-height: 20px;
    overflow: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    &:hover, &:active, &:focus{
      background-color: $color-blue-custom;
      border-color: $color-blue-custom;
      outline: none;
    }
  }
  .no-shadow {
    i {
      box-shadow: none !important;
    }
  }
  .link_referral {    
    a {
      text-decoration: none !important;
    }
  }
  .mgb0{
    margin-bottom: 0px;
  }

@media screen and (max-width: 992px) {
  #setting_dashboard {
    .right_list_option_acc .contents_setting {
      padding: 30px 16px;
      .title-medium-small {
        margin-bottom: 20px;
        font-size: 20px;
        line-height: 24px;
      }
      .d-show {
        display: block;
      }
      .acount_info {
        .acc_info {
          padding-bottom: 20px;
          gap: 16px;
          .img-user {
            img {
              width: 50px;
              height: 50px;
            }
          }
          .sub_acc_info {
            flex: 1;
            .email_acc {
              font-size: 16px;
              line-height: 19px;
            }
            .label-userID, .text-userID {
              font-size: 12px;
              line-height: 16px;
            }
            .lv_content {
              gap: 12px;
              -webkit-flex-wrap: wrap;
              flex-wrap: wrap;
              .kyc_level {
                .status {
                  font-size: 14px;
                  line-height: 16px;
                }
              }
              .kyc_status, .level, .time_account {
                font-size: 12px;
                line-height: 16px;
              }
              .kyc_verify {
                max-width: 167px;
              }
            }
          }
        }
        .acc_info_level {
          .acc_info_balance {
            -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
            gap: 30px;
            .blance_left .estimate_note_wrap {
              max-width: 256px;
              width: 100%;
            }
          }
          .text_milestone.dp-none {
            display: none;
          }
          .text-label {
            font-family: $font-inter-regular;
            font-size: 14px;
            line-height: 16px;
            color: var(--text-desc);
          }
          .nb_read_item {
            margin-top: 4px;
            margin-bottom: 20px;
            font-family: $font-inter-bold;
            font-size: 20px;
            line-height: 24px;
            color: var(--text-primary-color);
          }
          .text_estimate {
            font-size: 14px;
            line-height: 16px;
          }
          .number_balance {
       
            .value, .sub_value {
              font-size: 20px;
              line-height: 24px;
            }
          }
        }
      }
      .blance_right {
        .button_group {
          gap: 16px;
          margin-bottom: 30px;
        }
        .overview {
          i:before {
            font-size: 10px;
          }
          .link {
            .text_overview {
              font-size: 14px;
              line-height: 16px;
            }
          }
        }
      }
    }
    .icon-diamond-1 {
    padding-bottom: 8px;
  }
  .icon-diamond-1 .level .text{
    font-size: 14px;
    line-height: 19px;
  }
  .icon-diamond-1 .level.left{
   justify-content: center;
  }
  .icon-diamond-1 .level.right{
   justify-content: center;
  }
  }
  .acount_info .acc_info_level .acc_info_balance .blance_left .wrap_estmate {
    align-items: center;
    img{
      width: 14px;
      height: 8px;
    }
  }

}
@media screen and (max-width: 576px) {
  .acount_info .acc_info_level .acc_info_balance .blance_right .overview {
    justify-content: flex-start;
  }
}
</style>