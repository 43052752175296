<template>
  <div class="clearfix" id="table_top_roe">
    <data-table2 class="datatable_contracts" ref="datatable" :getData="getData">
      <template slot-scope="props">
        <th data-sort-field="rank">
          {{ $t('table.rank') }}
        </th>
        <th data-sort-field="name">
          {{ $t('withdraw_setting.name') }}
        </th>
        <th data-sort-field="rofit">
          {{ $t('table.rofit') }}
        </th>
        <th class="w_130px">
          {{ $t('table.is_real_name') }}
        </th>
      </template>
      <template slot="body" slot-scope="props">
        <tr>
          <td>{{ props.index + 1 }}</td>
          <td>{{ props.item.broker.name }}</td>
          <td>{{ props.item.percent_pnl }} %</td>
          <td>
            <button v-if="!props.item.broker.is_fake" class="btn btn-close-rank"><i class="icon icon-close"></i></button>
            <button v-if="props.item.broker.is_fake" class="btn btn-save-rank"><i class="icon icon-save"></i></button>
          </td>
        </tr>
      </template>
    </data-table2>
  </div>
</template>

<script>
  import rf from '@/request/RequestFactory'
  export default {
    
    methods: {
      getData (params) {
        return rf.getRequest('ChartsRequest').percent(params);
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/sass/variables";
  @import "@/assets/sass/common.scss"; 
  .clearfix-20 {
    clear: both;
    width: 100%;
    display: block;
    height: 20px;
  }
  .clearfix-25 {
    clear: both;
    width: 100%;
    display: block;
    height: 25px;
  }
  .clearfix-50 {
    clear: both;
    width: 100%;
    display: block;
    height: 50px;
  }
  .w_130px {
    width: 130px
  }
</style>

<style lang="scss">
  @import "@/assets/sass/variables";
  @import "@/assets/sass/common.scss"; 
  #table_top_roe {
    .datatable_contracts {
      th {
        font-size: $font-small;
        color: $color-grey;
        font-family: $font-family-roboto-medium;
        line-height: 20px;
        height: 48px;
        background-color: $color-white;
      }
      td {
        text-align: left;
        min-height: 38px;
        background-color: $color-white;
        font-size: $font-root;
        color: $color-grey-dark;
        font-family: $font-family-roboto-medium;
      }
    }
  }
</style>