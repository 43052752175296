export default {

  type: {
    join: 'join',
    assign: 'assign',
    revoke: 'revoke'
  },

  status: {
    submitted: 'submitted',
    approved: 'approved',
    executed: 'executed',
    rejected: 'rejected',
    canceled: 'canceled',
    failed: 'failed',

    opened: 'opened',
    closing: 'closing',
    closed: 'closed'
  },

  revokeType: {
    profit: 'profit',
    partial: 'partial',
    all: 'all'
  },

  action: {
    cancel: 'cancel',
    reject: 'reject',
    approve: 'approve',
    execute_im: 'execute_im'
  },

  revokeTypeSelectBox: [
    {id: 1, name: 'Profit Revocation', key: 'profit'},
    {id: 2, name: 'Partial Revocation', key: 'partial'},
    {id: 3, name: 'All Fund Revocation ', key: 'all'}
  ],

  getRequestType: function (item) {
    if (!item || (item && !item.type)) {
      return '';
    }
    if (item.type === this.type.assign) {
      return window.i18n.t('mam.type.assign');
    }
    if (item.type === this.type.revoke) {
      return window.i18n.t('mam.type.revoke') + ' - ' + 
        (item.revoke_type == 'profit'
          ? this.revokeTypeSelectBox[0].name
          : (item.revoke_type == 'partial'
            ? this.revokeTypeSelectBox[1].name
            : this.revokeTypeSelectBox[2].name)
        );
    }
    if (item.type === this.type.join) {
      return window.i18n.t('mam.type.join');
    }
    return '';
  },

  getRequestStatus: function (item) {
    if (!item || (item && !item.status)) {
      return '';
    }
    if (item.status === this.status.submitted) {
      return window.i18n.t('mam.status.submitted');
    }
    if (item.status === this.status.approved) {
      return window.i18n.t('mam.status.approved');
    }
    if (item.status === this.status.rejected) {
      return window.i18n.t('mam.status.rejected');
    }
    if (item.status === this.status.canceled) {
      return window.i18n.t('mam.status.canceled');
    }
    if (item.status === this.status.executed) {
      return window.i18n.t('mam.status.executed');
    }
    if (item.status === this.status.failed) {
      return window.i18n.t('mam.status.failed');
    }

    return '';
  },

  getRevokeType: function (item) {
    if (!item || (item && !item.revoke_type)) {
      return '';
    }
    if (item.revoke_type === this.revoke_type.profit) {
      return window.i18n.t('mam.revoke_type.profit');
    }
    if (item.revoke_type === this.revoke_type.partital) {
      return window.i18n.t('mam.revoke_type.partial');
    }
    if (item.revoke_type === this.revoke_type.all) {
      return window.i18n.t('mam.revoke_type.all');
    }
    return '';
  }
}
