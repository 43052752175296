<template>
  <div
    class="tableContainer tableContainer3"
    :style="{ width: widthTable }"
    :class="{
      overflow_unset: !this.isAuthenticated,
      'table-history': tableHistory,
      'table-open-order': tableOpenOrder,
      'table-history-order': tableOrderHistory,
     'no-data':this.rows.length === 0
    }"
  >
    <div class="wrap-table">
      <table :class="{ 'table-history': tableHistory }">
        <thead>
        <tr
            :class="defaultSort ? 'default-sort' : 'custom-sort'"
            @click="onSort"
        >
          <slot
              :resetIE="resetIE"
              :innerHtmlSort="innerHtmlSort"
              :echoclick="echoclick"
          />
        </tr>
        </thead>
        <tbody ref="bodyTable">
        <slot name="first_row" />
        <slot
            name="body"
            v-for="(row, index) in rows"
            :item="row"
            :index="index"
        />
        <template
            v-if="this.rows.length === 0 && !checkNoDataWhenAuthenticated && getDataSuccess && !tableOpenOrder && !tableHistory && !tableOrderHistory"
        >
          <tr class="empty-data" >
            <td colspan="50">
              <div class="fixed-empty">
                <div class="icon-no-data-1 text-center w-100">
                  <img
                      class="icon-search"
                      :src="
                      require(`@/assets/images/landing/icon-nodata${
                        theme === 'light-theme' ? '' : '-dark'
                      }.svg`)
                    "
                  />
                  <div
                      class="text-no-data w-100 text-center"
                      v-if="tableNoData === false && typeHistory === 'deposit'"
                  >
                    {{ $t("funds.transaction_history.no_deposit_data") }}
                  </div>
                  <div
                      class="text-no-data w-100 text-center"
                      v-if="tableNoData === false && typeHistory === 'withdraw'"
                  >
                    {{ $t("funds.transaction_history.no_deposit_data") }}
                  </div>
                  <div class="text-no-data w-100 text-center" v-else>
                    <template  v-if="typeHistory !== 'deposit'">
                      {{ msgEmptyData || $t("common.datatable.no_data") }}
                    </template>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </template>
        <template v-if="!getDataSuccess">
          <tr :style="{ height: heightOfBody + 'px'}" class="empty-data">
            <td colspan="50" style="padding-right: 0!important;">
              <div class="fixed-empty spinner">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </td>
          </tr>
        </template>
        <slot name="end_row" />
        </tbody>
      </table>
      <template
          v-if="this.rows.length === 0 && !checkNoDataWhenAuthenticated && getDataSuccess && typeEmpty == true"
      >
        <div class="empty-row text-center">
          <div class="fixed-empty">
            <img
                class="icon-search text-center"
                :src="
                      require(`@/assets/images/landing/icon-nodata${
                        theme === 'light-theme' ? '' : '-dark'
                      }.svg`)
                    "
            />
            <div class="w-100 text-center text-empty">
              {{ msgEmptyData }}
            </div>
          </div>
        </div>
      </template>
    </div>

    <template v-if="lastPage > 1">
<!--      <pagination-->
<!--        ref="pagination"-->
<!--        class="text-right"-->
<!--        :per-page="perPage"-->
<!--        :records="totalRecord"-->
<!--        :chunk="chunk"-->
<!--        @Pagination:page="onPageChange"-->
<!--        :paginationPosition="paginationPosition"-->
<!--        :pageParent="page"-->

<!--      ></pagination>-->
      <pagination2
          ref="pagination"
          class="text-right"
          :per-page="perPage"
          :records="totalRecord"
          @Pagination:page="onPageChange"
          :pageParent="page"
      ></pagination2>
    </template>
  </div>
</template>

<script>
import Pagination2 from "./Pagination2";
import {mapState} from "vuex";

export default {
  components: {
    Pagination2,
  },
  props: {
    getData: {
      type: Function,
    },
    limit: {
      type: Number,
      default: 10,
    },
    chunk: {
      type: Number,
      default: 5,
    },
    widthTable: {
      type: String,
      default: "100%",
    },
    msgEmptyData: {
      type: String,
    },
    typeHistory: {
      type: String,
    },
    defaultSort: {
      type: Boolean,
      default: true,
    },
    tableNoData: {
      type: Boolean,
      default: false,
    },
    innerHtmlSort: {
      type: String,
      default:
        '<span class="icon-double-arrow-table"></span>' +
        '<span class="icon-double-arrow-table-down"><span class="path1"></span><span class="path2"></span></span>' +
        '<span class="icon-double-arrow-table-up"><span class="path1"></span><span class="path2"></span></span>',
    },
    height: {
      type: Number,
      default: 0,
    },
    checkNoDataWhenAuthenticated: {
      type: Boolean,
      default: false,
    },
    isComingSoon: {
      type: Boolean,
      default: false,
    },
    tableHistory: {
      type: Boolean,
      default: false,
    },
    tableOrderHistory: {
      type: Boolean,
      default: false,
    },
    tableOpenOrder: {
      type: Boolean,
      default: false,
    },
    paginationPosition: {
      type: 'center' | 'right' | 'left',
      default: 'center',
    },
    searchingFavorite: {
      type: Boolean,
      default: false,
    },
    typeEmpty:{
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      maxPageWidth: 10,
      totalRecord: 0,
      lastPage: 0,
      page: 1,
      perPage: 5,
      fetching: false,
      rows: [],
      params: {},
      getDataSuccess: false,
      orderBy: null,
      sortedBy: null,

      emptyData: false,
      resetIE: false,
      isAuthenticated: window.isAuthenticated,
      heightOfBody: 208
    };
  },
  computed: {
    emptyRow() {
      let emptyRowCount = Math.max(this.limit - _.size(this.rows), 0);
      return Math.min(emptyRowCount, this.limit);
    },
    ...mapState({
      theme: (state) => state.userThemeMode,
    }),
    // theme: state => state.userThemeMode,
  },
  methods: {
    rawHtml() {
      document.getElementById("demo").innerHTML = "My First JavaScript";
    },
    onPageChange(page) {
      this.heightOfBody = this.$refs.bodyTable.offsetHeight;
      this.page = page;
      this.fetch();
    },
    echoclick() {
      this.resetIE = !this.resetIE;
    },
    getTarget(target) {
      let node = target;
      while (node.parentNode.nodeName !== "TR") {
        node = node.parentNode;
      }
      return node;
    },

    getSortOrder(target) {
      let sortOrder = target.dataset.sortOrder;
      switch (sortOrder) {
        case "asc":
          sortOrder = "";
          break;
        case "desc":
          sortOrder = "asc";
          break;
        default:
          sortOrder = "desc";
      }
      return sortOrder;
    },

    setSortOrders(target, sortOrder) {
      let iterator = target.parentNode.firstChild;
      while (iterator) {
        iterator.dataset.sortOrder = "";
        iterator = iterator.nextElementSibling;
      }
      target.dataset.sortOrder = sortOrder;
    },

    onSort(event) {
      this.heightOfBody = this.$refs.bodyTable.offsetHeight;
      const target = this.getTarget(event.target);
      const orderBy = target.dataset.sortField;
      if (!orderBy) {
        return;
      }
      this.sortedBy = this.getSortOrder(target);
      this.orderBy = this.sortedBy ? orderBy : "";
      Object.assign(this.params, {
        sort: this.orderBy,
        sort_type: this.sortedBy,
      });
      this.setSortOrders(target, this.sortedBy);
      this.fetch();
    },

    fetch() {
      try {
        const meta = {
          page: this.page,
          limit: this.limit,
        };
        this.rows = []
        this.fetching = true;
        this.getDataSuccess = false;
        this.getData(Object.assign(meta, this.params))
          .then((res) => {
            const data = res.data || res;
            if (!data) {
              return;
            }
            this.column = _.chain(this.$slots.default)
              .filter((el) => {
                return el.tag === "th";
              })
              .value().length;

            if (!data.data) {
              let sortData;
              if (this.sortedBy === "asc") {
                sortData = data.sort(function (a, b) {
                  let x = a[this?.params?.sort]?.toLowerCase();
                  let y = b[this?.params?.sort]?.toLowerCase();
                  if (x < y) {
                    return -1;
                  }
                  if (x > y) {
                    return 1;
                  }
                  return 0;
                });
              } else if (this.sortedBy === "desc") {
                sortData = data.sort(function (a, b) {
                  let x = a[this?.params?.sort]?.toLowerCase();
                  let y = b[this?.params?.sort]?.toLowerCase();
                  if (x > y) {
                    return -1;
                  }
                  if (x < y) {
                    return 1;
                  }
                  return 0;
                });
              } else {
                sortData = data;
              }
              this.rows = sortData;
              this.$emit("DataTable:finish");
              return;
            }
            this.page = parseInt(data.current_page);
            this.totalRecord = parseInt(data.total);
            this.lastPage = parseInt(data.last_page);
            this.perPage = parseInt(data.per_page);
            this.rows = data.data;
            this.emptyData = window._.isEmpty(this.rows);
            this.$emit("DataTable:finish");
          })
          .then((res) => {
            this.getDataSuccess = true;
            this.fetching = false;
          });
      } catch (err) {
        this.fetching = false;
      }
    },
    hotFetch() {
      const meta = {
        page: this.page,
        limit: this.limit,
        socket: true
      };
      this.getData(Object.assign(meta, this.params))
        .then((res) => {
          const data = res.data || res;
          if (!data) {
            return;
          }
          this.column = _.chain(this.$slots.default)
            .filter((el) => {
              return el.tag === "th";
            })
            .value().length;

          this.page = parseInt(data.current_page);
          this.totalRecord = parseInt(data.total);
          this.lastPage = parseInt(data.last_page);
          this.perPage = parseInt(data.per_page);
          this.rows = data.data;
          this.emptyData = window._.isEmpty(this.rows);
          this.$emit("DataTable:finish");
        })
        .then((res) => {
          this.getDataSuccess = true;
          this.fetching = false;
        });
    },
    refresh() {
      this.page = 1;
      this.params = this.params;
      this.fetch();
      // setTimeout(() => {
      //   this.fetch();
      // }, 500);
    },

    filter(params) {
      this.page = 1;
      this.params = params;
      if (this.orderBy) {
        Object.assign(this.params, {
          sort: this.orderBy,
          sort_type: this.sortedBy,
        });
      }

      this.fetch();
    },

    getRows() {
      return this.rows;
    },

    setRows(rows) {
      this.rows = rows;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  watch: {
    userThemeMode(theme) {
      this.theme = theme;
    },
    "$route.query.type": {
      handler() {
        this.params = {}
      },

    },
    lastPage(){
      this.$emit('isPagination', this.lastPage)
    }

  },
  created() {
    this.fetch();
    this.$on("DataTable:filter", (params) => {
      this.filter(params);
    });
  },
  mounted() {
    this.fetching = true;
    this.getDataSuccess = false;
    this.column = _.chain(this.$slots.default)
      .filter((el) => {
        return el.tag === "th";
      })
      .value().length;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.loader {
  display: block;
  margin: auto;
  border: 5px solid $color-grey-concrete;
  border-radius: 50%;
  border-top: 5px solid $color-blue-custom;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

.dark-theme {
  .icon-no-data-1 {
    div {
      color: $color-storm-grey !important;
    }
  }
  table {
    th {
      ::v-deep {
        .icon-double-arrow-table::before{
          color: $color-storm-grey;
            }
      }
    }
  }
}
.empty-row{
  padding-top: 100px;
  @media only screen and (max-width: 992px) {
    .fixed-empty {
      position: absolute;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      z-index: 1;
      &.spinner {
        padding-top: 40px;
      }
    }
  }
  .text-empty{
    color: var(--color-percent);
    margin-top: 16px;
  }
}
table {
  width: 100%;
  overflow-y: hidden;

  thead {
    text-align: center;
    color: $color_black;
    border: none;
    line-height: 17px;
    overflow: hidden;
    th {
      user-select: none;
      font-weight: normal;
      font-size: $font_small;
      border: none;
      //padding: 8px 0px 8px 20px;
      line-height: 20px;
      color: $color-grey;
      cursor: pointer;
      vertical-align: middle;

      &:last-child {
        padding: 8px 20px 8px 8px;
      }

      /* &:hover {
        color: $color-caribbean-green;
      } */
      ::v-deep  {

        .group-sort-tabl3 {
          display: inline-table;
          vertical-align: middle;
          font-size: 15px;
          margin-left: 5px;
          position: relative;
          top: -2px;
        }
        .icon-double-arrow-table::before{
          color: $color-gray-dark;
          width: 6px;
          height: 12px;
        }
        .icon-double-arrow-table {
          display: flex;
        }
        .icon-double-arrow-table-down {
          display: none;
          width: 6px;
          height: 12px;
        }
        .icon-double-arrow-table-up {
          display: none;
          width: 6px;
          height: 12px;
        }

        &[data-sort-order="desc"] {
          .icon-double-arrow-table-down {
            display: flex;
          }

          .icon-double-arrow-table-down .path1:before {
            color: var(--text-close); 
          }
          .icon-double-arrow-table,
          .icon-double-arrow-table-up {
            display: none;
          }
        }
        &[data-sort-order="asc"] {

          .icon-double-arrow-table,
          .icon-double-arrow-table-down {
            display: none;
          }
          .icon-double-arrow-table-up {
            display: flex;
          }
          .icon-double-arrow-table-up .path2:before {
            color: var(--text-close); 
          }
        }
      }
    }
  }

  tbody {
    text-align: center;
    color: $color-grey-dark;
    tr {
      height: 35px;
      overflow-y: hidden;
      transition-property: height;
      transition-duration: 0.3s, 0.3s;
      transition-timing-function: ease, ease-in;

      .icon-arrow_2_2 {
        color: $color-green;
      }
      &.active {
        max-height: 300px;
        background-color: $color-bright-gray;
        transition-property: height;
        transition-duration: 0.3s, 0.3s;
        transition-timing-function: ease, ease-in;
      }
      &.empty-data {
        width: 100%;
        text-align: center;
        color: $color-gray-dark;
        font-size: $font-root;
        text-align: center;
       
        @media only screen and (max-width: 992px) {
          min-height: 207px;
          display: flex;
        }
        &:hover {
          background: transparent;
        }
        .fixed-empty {
          @media only screen and (max-width: 992px) {
            position: absolute;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            margin-top: 50px;
            z-index: 1;
            &.spinner {
              padding-top: 40px;
            }
          }
        }
        td {
          width: 100%;
          text-align: center !important;
          color: $color-gray-dark !important;
          padding: 0 !important;
          font-size: $font-medium-bigger !important;
          font-family: $font-inter-regular !important;
        }
        p {
          padding-top: 22px;
          span {
            vertical-align: middle;
          }
        }
        .icon-no-data-1 {
          font-size: 40px;
          color: $color-grey-sliver;
          margin-bottom: 16px;
          padding-top: 60px;
          @media only screen and (max-width: 992px) {
            padding-top: 0;
          }
          .text-no-data {
            color: var(--text-no-data) !important;
            opacity: 0.8 !important;
          }

          div {
            font-family: $font-inter-regular;
            font-style: normal;
            font-size: 16px;
            line-height: 19px;
            color: $color-gray-dark;
            margin-top: 16px;
          }
        }
      }
    }

    td {
      font-weight: normal;
      font-size: $font_root;
      color: $color-grey-dark;
      border: none;
      padding: 8px 0px 8px 0px;
      white-space: nowrap;
      overflow: hidden;

      &:last-child {
        padding: 8px 20px 8px 0px;
        text-align: right;
      }

      &.info {
        background-color: $color-bright-gray;
        color: $color-grey-dusty;
        font-size: $font-small;
        text-align: left;
        padding: 8px 20px 8px 38px;
        .label-text {
          color: $color-grey;
        }
      }
    }
  }
}
@media only screen and (max-width: 992px) {
  table {
    thead {
      th {
        &:hover {
          color: $color-grey;
        }
      }
    }
  }
  .empty-row {
    padding-top: 33px;
  }
}
</style>
