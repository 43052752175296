import BaseRequest from './base/BaseRequest';

export default class EntryRequest extends BaseRequest {

  showEntryOnUI (params) {
    let url = '/show-entry-on-ui';
    return this.get(url, params);
  }

  registerEntry (params) {
    let url = '/register-entry';
    return this.post(url, params);
  }

  getOpeningContestOrLatestContest (params) {
    let url = '/get-contest';
    return this.get(url, params);
  }

  getMinimumMargin (params) {
    let url = '/get-minimum-margin';
    return this.get(url, params);
  }

  getTeamEntryList(params){
    let url ='/team-entry-list';
    return this.get(url,params);
  }

  getEntryMyRequestList(params){
    let url = '/entry-request/my-request';
    return this.get(url,params);
  }

  getEntryTeamRequestList(params){
    let url = '/entry-request/team-request';
    return this.get(url,params);
  }

  createJoinRequestTeamEntry(params){
    let url = '/entry-request/join-request';
    return this.post(url,params);
  }

  getPendingRequest(params){
    let url ='/entry-request/pending';
    return this.get(url,params);
  }

  createTeamEntry(params){
    let url = '/create-team-entry';
    return this.post(url,params);
  }

  changeStatusRequest(params) {
    let url = '/entry-request/change-status-entry-request';
    return this.put(url, params);
  }

  getTeamEntryMember(params){
    let url = '/entry/team-member';
    return this.get(url,params);
  }

  getMyTeamInformation(params){
    let url = '/entry/my-team';
    return this.get(url,params);
  }
}