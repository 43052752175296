<template>
  <div class="main_agreementInner">
    <div class="tit_page">
      <label>{{$t('fee_guide.title')}}</label>
    </div>
    <div class="main">
      
      <!-- <h2 class="color-black">
        1. {{$t('fee_guide.commission_rate')}}
        <div v-if="isAuthenticated">
          <span class="pull-right">{{$t('fee_guide.level_title')}}
            <b>
              {{$t('fee_guide.level_name')}} {{ user.user_fee_level ? user.user_fee_level.fee_level : 1 }}
            </b>
          </span>
        </div>
      </h2>-->
      <!-- <table class="fee-guide">
        <thead>
        <tr>
          <th width="25%" class="t-center">{{$t('fee_guide.rating')}}</th>
          <th width="25%" class="t-center">{{$t('trading_fee_setting.coin_hodling')}}</th>
          <th width="25%" class="t-center">{{$t('trading_fee_setting.fee_maker')}}</th>
          <th width="25%" class="t-center">{{$t('trading_fee_setting.fee_taker')}}</th>
        </tr>
        </thead>
        <tbody>
          <tr v-for="item in fee_levels">
            <td class="col1">{{ item.level }}</td>
            <td class="col2">>= {{ item.mgc_amount | toNumber }}</td>
            <td class="col3">{{ item.fee_maker | toNumber }}</td>
            <td class="col3">{{ item.fee_taker | toNumber }}</td>
          </tr>
        </tbody>
      </table>-->
      <!-- <div class="clearfix"></div>
      <div class="wid100 block_tric">
        <ul>
          <li>{{$t('fee_guide.commission_tip1')}}</li>
          <li>{{$t('fee_guide.commission_tip2', { time: '00:00' })}}</li>
        </ul>
      </div>-->
      
      <h2 class="color-black">{{$t('fee_guide.transaction_fee')}}</h2>
      <div class="wid100 block_tric">
        <ul>
          <li>{{$t('fee_guide.text13')}}</li>
          <li>{{$t('fee_guide.text14')}}</li>
          <li>{{$t('fee_guide.text15')}}</li>
        </ul>
      </div>

      <table class="example-table">
        <caption>
          <strong>{{$t('fee_guide.example')}}</strong>
          <span>{{$t('fee_guide.text16')}}</span>
        </caption>
        <tr>
          <td class="bold right color-black"><div>{{$t('fee_guide.actual_purcharse')}}</div></td>
          <td class="equal">=</td>
          <!-- <td>{{$t('fee_guide.fomula_actual_pucharse')}}</td> -->
          <td>{{$t('fee_guide.fomula_actual_pucharse')}}</td>
        </tr>
        <tr>
          <td class="bold right color-black"><div>{{$t('fee_guide.fee')}}</div></td>
          <td class="equal">=</td>
          <!-- <td>{{$t('fee_guide.formula_fee')}}</td> -->
          <td>{{$t('fee_guide.formula_fee')}}</td>
        </tr>
        <tr>
          <td class="bold right color-black"><div>{{$t('fee_guide.subtotal')}}</div></td>
          <td class="equal">=</td>
          <!-- <td>{{$t('fee_guide.formula_subtotal')}}</td> -->
          <td>{{$t('fee_guide.formula_subtotal')}}</td>
        </tr>
      </table>

      <table class="example-table">
        <caption>
          <strong>{{$t('fee_guide.for_example')}}</strong>
          <span>{{$t('fee_guide.text20')}}</span>
        </caption>
        <tr>
          <td class="bold right color-black">{{$t('fee_guide.actual_sell')}}</td>
          <td class="equal">=</td>
          <!-- <td>{{$t('fee_guide.formula_actual_sell')}}</td> -->
          <td>{{$t('fee_guide.formula_actual_sell')}}</td>
        </tr>
        <tr>
          <td class="bold right color-black">{{$t('fee_guide.fee')}}</td>
          <td class="equal">=</td>
          <!-- <td>{{$t('fee_guide.formula_sell_fee')}}</td> -->
          <td>{{$t('fee_guide.formula_sell_fee')}}</td>
        </tr>
        <tr>
          <td class="bold right color-black">{{$t('fee_guide.transaction_value')}}</td>
          <td class="equal">=</td>
          <!-- <td>{{$t('fee_guide.formula_transaction_value')}}</td> -->
          <td>{{$t('fee_guide.formula_transaction_value')}}</td>
        </tr>
      </table>

      <div class="wid100 block_tric">
        <ul>
          <li>{{$t('fee_guide.text24')}}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import rf from '@/request/RequestFactory'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      fee_levels: [],
      user: {},
      isAuthenticated: window.isAuthenticated,
      tb1_r1 :this.$i18n.t("fee_guide.fomula_actual_pucharse").slice(2),
      tb1_r2: this.$i18n.t('fee_guide.formula_fee').slice(2),
      tb1_r3 : this.$i18n.t('fee_guide.formula_subtotal').slice(2),
      tb2_r1 :this.$i18n.t("fee_guide.formula_actual_sell").slice(2),
      tb2_r2: this.$i18n.t('fee_guide.formula_sell_fee').slice(2),
      tb2_r3 : this.$i18n.t('fee_guide.formula_transaction_value').slice(2),

    };
  },
  computed: {
    ...mapState({
      masterdata: state => state.masterdata
    })
  },
  mounted() {
    this.fee_levels = this.masterdata.fee_levels;
    if (this.isAuthenticated) {
      rf.getRequest("UserRequest")
        .getCurrentUser()
        .then(res => {
          this.user = res.data;
        });
    }
    document.title = this.$t("menu.fees") + ` – ${this.tabTitle}`;  
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables";
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.tit_page {
  font-size: $font-title-size-medium-small;
  color: $color-eden-light;
  margin: 0px 0px 25px 0px;
  label {
    font-family: Roboto;
    font-size: 30px;
    font-weight: 500;
    line-height: 1.33;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
  }
}
.main_agreementInner {
  background-color: $color-alabaster;
  width: 100%;
  height: auto;
}
.color-black {
  color: $color-black;
}
.color-grey-dusty {
  color: $color-grey-dusty;
}
ul {
  list-style-type: none;
  li {
    font-family: Roboto;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.92;
    letter-spacing: normal;

    color: $color-grey-dusty;
    list-style: none;
  }

  li::before {
    color: $color_blue_logo;
    content: "\2022";
    font-size: 1em;
    padding-right: 1.1225em;
    position: relative;
    top: 0em;
  }
}
.img_icon {
  width: 16px;
  margin-right: 5px;
}
.double_tr {
  border-bottom: 1px solid #b4cbdb;
  height: 35px;
}

.block_tric {
  padding: 15px 10px;
}
.main_agreementInner {
  .content-header {
    width: 100%;
    height: 114px;
    border-bottom: 2px solid rgb(222, 227, 235);
    display: flex;
    align-items: center;
    padding: 0 34px;
    .left {
      width: 50%;
      float: left;
      text-align: left;
      font-size: 20px;
      label {
        color: $color-black;
        font-size: 22px;
        margin-bottom: 0px;
        padding-bottom: -10px;
        position: relative;
        top: 4px;
      }
      img {
        width: 30px;
        margin-right: 15px;
        position: relative;
        top: 1px;
      }
    }
  }
  .pull-right {
    float: right !important;
    margin-right: 7px;
  }
  .main {
    color: $color-grey-dusty;
    font-size: 15px;
    h2 {
      font-family: Roboto;
      font-size: 16px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      margin-bottom: 0px;
      margin-left: 0px;

      span {
        font-size: 13.3px;
        font-weight: normal;
        b {
          font-weight: bold;
          color: red;
        }
      }
    }
    .fee-guide {
      background-color: white;
      margin-top: 28px;
      font-size: 14px;
      margin-bottom: 15px;
      width: 100%;
      margin-left: -1px;
      thead {
        tr {
          text-align: center;
          color: $color-grey-dusty;
          background-color: $color-white;
          border: 1px solid $color-white;
          border-bottom: 1px solid #dee3eb;
          th {
            padding: 8px 20px;
            text-align: center;
            height: 56px;
            width: 170px;
            position: relative;
          }
        }
      }
      tbody {
        .col1 {
          font-weight: bold;
        }
        tr {
          td {
            padding: 8px 20px;
            text-align: center;
            border-bottom: 1px solid #dee3eb;
            height: 53px;
            width: 170px;
            position: relative;
          }
        }
      }
    }
    .example-table {
      border: 0;
      margin-top: 10px;
      
      width: 100%;
      background-color: $color-alabaster;
      caption {
        font-family: Roboto;
        font-size: 13px;
        margin-bottom: 26px;
        text-align: left;
        line-height: 30px;
        padding-left: 20px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        background-color: $color-athens-gray;
      }
      tr {
        td {
          font-family: Roboto;
          font-size: 13px;
          font-style: normal;
          font-stretch: normal;
          line-height: 2.69;
          font-weight: 500;
          letter-spacing: normal;
          color: $color-grey-dark;
          padding: 5px 35px;
          &.right {
            font-weight: bold;
            text-align: right;
            width: 320px;
            // padding-right: 30px;
            
          }
          &.equal{
            width: 70px;
            padding: 0;
            margin: 0;
            text-align: center;
          }
          div{
            // margin-right: 100px!important;
          }
         
        }
      }
    }
  }
}
</style>