<template>
  <div class="trade-history">
    <div class="tbl-history">
      <table class="table-size">
        <tbody v-if="!isLoading">
        <tr v-for="(trade, index) in getMyTrades()" :key="index">
          <td class="trade-history-cl1" :class="trade.trade_type"
              :title="trade.price | formatCurrencyAmount(currency) | paddingRight(coinSettingByPair.fractionPartPrice, '0')">
            {{ trade.price | formatCurrencyAmount(currency) | paddingRight(coinSettingByPair.fractionPartPrice, '0') }}
          </td>
          <td class="trade-history-cl2"
              :title="trade.quantity | formatCurrencyAmount(coin) | paddingRight(coinSettingByPair.fractionPartQuantity, '0')">
            {{ trade.quantity | formatCurrencyAmount(coin) | paddingRight(coinSettingByPair.fractionPartQuantity, '0') }}
          </td>
          <td class="trade-history-cl3">{{trade.created_at | orderTime}}</td>
        </tr>
        <tr class="no-data" v-if="isEmptyData">
          <td colspan="50">
            <div class="box-no-data text-center w-100">
                <img
                    class="icon-search"
                    :src="require(`@/assets/images/landing/icon-nodata${theme === 'light-theme'? '': '-dark'}.svg`)"
                />
              </div>
            <p>{{ $t('common.datatable.no_data') }}</p>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Const from "@/common/Const";
import {mapGetters, mapState} from "vuex";
import rf from "@/request/RequestFactory";
import moment from 'moment';


export default {
  name: "MyTrades",
  data() {
    return {
      myTrades: [],
      isEmptyData: false,
      coinSettings: [],
      coinSettingByPair: {},
      currency: this.$route.query.currency || Const.DEFAULT_CURRENCY,
      coin: this.$route.query.coin || Const.DEFAULT_COIN,
      isLoading: false

    };
  },
  watch: {
    userThemeMode(theme) {
      this.theme = theme;
    },
    '$route' (to, from) {
      this.currency = this.$route.query.currency || Const.DEFAULT_CURRENCY;
      this.coin = this.$route.query.coin || Const.DEFAULT_COIN;

      this.getCoinSettingsByPairCurrency();
      this.initData();
    },

  },
  computed: {
    ...mapState({
      masterdata: state => state.masterdata,
      theme: state => state.userThemeMode,
    }),
    ...mapGetters(['user']),
  },
  methods: {
    getMyTrades() {
      return this.myTrades;
    },
    getCoinSettings() {
      this.coinSettings = window._.map(this.masterdata.coin_settings, item => {
        item.fractionPartPrice = this.getDecimalPrecision(item.coin, item.precision);
        item.fractionPartQuantity = this.getDecimalPrecision(item.coin, item.quantity_precision);
        return item;
      });
      this.getCoinSettingsByPairCurrency();
    },
    getDecimalPrecision(currency, value) {
      if (currency === 'usd') {
        // convert to million unit
        value = new BigNumber(value).div(1000000).toString();
      }
      return Math.round(Math.log(1 / value) / Math.log(10));
    },

    getCoinSettingsByPairCurrency() {
      if (window._.isEmpty(this.coinSettings)) {
        this.coinSettingByPair = {};
        return;
      }
      const result = window._.filter(this.coinSettings, item => {
        return item.currency === this.currency && item.coin === this.coin;
      });
      if (!window._.isEmpty(result)) {
        this.coinSettingByPair = result[0];
      }
    },
    getSocketEventHandlers() {
      return {
        OrderTransactionCreated: this.OrderTransactionCreated
      }
    },

    OrderTransactionCreated(data) {
      const myTransaction = data.orderTransaction;
      if (myTransaction.buyer_id === this.user.id || myTransaction.seller_id === this.user.id) {
        let newItem = window._.cloneDeep(myTransaction);
        if (this.currency != newItem.currency || this.coin != newItem.coin) {
          return;
        }
        if (this.user.id === myTransaction.buyer_id && this.user.id === myTransaction.seller_id) {
          this.myTrades.splice(0, 0, {...newItem, trade_type: newItem.transaction_type === 'sell' ? 'buy' : 'sell'});
        }
        this.isEmptyData = false;
        this.myTrades.splice(0, 0, {...newItem, trade_type: newItem.transaction_type});
      }
    },

    initData() {
      const today = new Date();

      // Set the start date to be the start of today by setting the time to 00:00:00:000
      const start_date = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0);

      // Set the end date to be the end of today by setting the time to 23:59:59:999
      const end_date = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59, 999);
      const params = {
        currency: this.$route.query.currency || Const.DEFAULT_CURRENCY,
        coin: this.$route.query.coin || Const.DEFAULT_COIN,
        start_date: null,
        end_date: null,
        page: 1,
        limit: 30
      }
        this.isEmptyData = false;
        this.isLoading = true
      rf.getRequest('OrderRequest').getTradeHistory(params)
          .then(res => {
            this.isLoading = false
            this.myTrades = res.data.data;
            if(this.myTrades.length === 0) {
              this.isEmptyData = true;
            }
          });
    },
  },
  mounted() {
    let params = {
      currency: this.currency,
      coin: this.coin,
      count: 50,
    }
    this.getCoinSettings();
    this.initData();
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables";
.dark-theme {
  .trade-history .tbl-history .table-size tr td {
    color: $color-white;
  }
}
.trade-history{
  width: 100%;
  display: inline-block;
  padding: 5px 0px;
  .tbl-history{
    width: 100%;
    height: 100%;
    float: left;
    .table-size{
      width: 100%;
      height: fit-content;
      float: left;
      tr{
        width: 100%;
        cursor: pointer;
        &:hover{
          td {
            //background-color: $color-ebony-clay;
          }
        }
        &.no-data {
          text-align: center;
          border-bottom-style: none;

          & td:first-child{
            padding-left: 0 !important;
          }

          p{
            color: var(--text-close);
            font-family: $font-inter-regular;
            font-size: $font-root;
            line-height: 16px;
            margin: 16px 0 0 0;
          }
          .icon-nodata{
            font-size: 60px;
            color: $color-grey-sliver;
          }
          .box-no-data {
            padding-top: 80px;
          }
          &:hover {
            td {
              background: none !important;
            }
          }
        }
        .trade-history-cl1{
          display: inline-block;
          float: left;
          width: 35%;
          text-align: left;
        }
        .trade-history-cl2{
          display: inline-block;
          width: 40%;
          text-indent: 3%;
          text-align: right;
        }
        .trade-history-cl3{
          display: inline-block;
          width: 25%;
          text-align: right;
          padding-right: 15px;
        }
        td{
          font-size: $font-root;
          line-height: 24px;
          text-overflow: ellipsis;
          overflow: hidden;
          color: $color-grey-dark;
          //&:last-child {
          //  padding-right: 10px;
          //}
          &:first-child {
            padding-left: 10px;
          }
          &.sell {
            color: $text-color-red;
          }
          &.buy {
            color: $text-color-jade;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 576px) {
  .trade-history .tbl-history .table-size tr .trade-history-cl3 {
    padding-right: 0;
  }
}
</style>

<style lang="scss">
#home .page-container-right {
  #order_trade_form {
    overflow: hidden;
    height: 100%;
    .tab_content_ordertrade {
      overflow-y: auto;
      height: 100%;
      .item:hover{
        background-color: initial;
      }
    }

    .tbl-history {
      overflow-y: auto;
    }
  }
}
</style>