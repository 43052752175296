<template>
  <div id="acrive_orders">
    <div class="table-container table-positions">
      <data-table2 ref="datatable" :getData="getData" :limit="100" :msgEmptyData="$t('margin.active_order')">
        <template slot-scope="props">
          <th data-sort-field="instrument_symbol" @click="props.echoclick">
            <span class="box_custom_tooltip">
              {{$t('margin.positions_symbol')}}
              <div class="tooltip_custom_c">
                <span class="underline">{{$t('margin.positions_symbol')}}</span>
                {{$t('margin.position_symbol_tooltip')}}
              </div>
            </span>
            <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
            <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
          </th>
          <th data-sort-field="quantity" @click="props.echoclick">
            <span class="box_custom_tooltip">
              {{$t('margin.qty')}}
              <div class="tooltip_custom_c">
                <span class="underline">{{$t('margin.qty')}}</span>
                {{$t('margin.order_quantity_tooltip')}}
              </div>
            </span>
            <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
            <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
          </th>
          <th data-sort-field="price" @click="props.echoclick">
            <span class="box_custom_tooltip">
              {{$t('margin.order_price')}}
              <div class="tooltip_custom_c">
                <span class="underline">{{$t('margin.order_price')}}</span>
                {{$t('margin.order_price_tooltip')}}
              </div>
            </span>
            <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
            <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
          </th>
          <th data-sort-field="filled" @click="props.echoclick">
            <span class="box_custom_tooltip">
              {{$t('margin.filled')}}
              <div class="tooltip_custom_c">
                <span class="underline">{{$t('margin.filled')}}</span>
                {{$t('margin.order_filled_tooltip')}}
              </div>
            </span>
            <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
            <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
          </th>
          <th data-sort-field="remaining" @click="props.echoclick">
            <span class="box_custom_tooltip">
              {{$t('margin.remaining')}}
              <div class="tooltip_custom_c">
                <span class="underline">{{$t('margin.remaining')}}</span>
                {{$t('margin.order_remaining_tooltip')}}
              </div>
            </span>
            <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
            <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
          </th>
          <th data-sort-field="order_value" @click="props.echoclick">
            <span class="box_custom_tooltip">
              {{$t('margin.order_value')}}
              <div class="tooltip_custom_c">
                <span class="underline">{{$t('margin.order_value')}}</span>
                {{$t('margin.order_value_contract_tooltip')}}
              </div>
            </span>
            <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
            <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
          </th>
          <th data-sort-field="executed_price" @click="props.echoclick">
            <span class="box_custom_tooltip">
              {{$t('margin.fill_price')}}
              <div class="tooltip_custom_c">
                <span class="underline">{{$t('margin.fill_price')}}</span>
                {{$t('margin.order_filled_price_tooltip')}}
              </div>
            </span>
            <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
            <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
          </th>
          <th data-sort-field="type" @click="props.echoclick">
            <span class="box_custom_tooltip">
              {{$t('margin.type')}}
              <div class="tooltip_custom_c">
                <span class="underline">{{$t('margin.type')}}</span>
                {{$t('margin.order_type_tooltip')}}
              </div>
            </span>
            <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
            <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
          </th>
          <th data-sort-field="status" @click="props.echoclick">
            <span class="box_custom_tooltip">
              {{$t('margin.status')}}
              <div class="tooltip_custom_c">
                <span class="underline">{{$t('margin.status')}}</span>
                {{$t('margin.order_status_tooltip')}}
              </div>
            </span>
            <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
            <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
          </th>
          <th data-sort-field="text" @click="props.echoclick">
            <span class="box_custom_tooltip">
              {{$t('table.text')}}
              <div class="tooltip_custom_c">
                <span class="underline">{{$t('table.text')}}</span>
              </div>
            </span>
            <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
            <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
          </th>
          <th data-sort-field="note" @click="props.echoclick">
            <span class="box_custom_tooltip">
              {{$t('table.note')}}
              <div class="tooltip_custom_c">
                <span class="underline">{{$t('table.note')}}</span>
              </div>
            </span>
            <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
            <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
          </th>
          <th data-sort-field="created_at" @click="props.echoclick">
            <span class="box_custom_tooltip">
              {{$t('margin.time')}}
              <div class="tooltip_custom_c">
                <span class="underline">{{$t('margin.time')}}</span>
                {{$t('margin.order_time_tooltip')}}
              </div>
            </span>
            <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
            <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
          </th>
          <th></th>
        </template>
        <template slot="body" slot-scope="props">
          <tr class="border_tr" :class="[props.item.side === 'buy' ? 'up' : 'down']">
            <td>
              <span class="symbol_th">{{ props.item.instrument_symbol }}</span>
            </td>
            <td>
              <span :class="[props.item.side === 'buy' ? 'up' : 'down']">
                <template v-if="props.item.side === 'sell'">-</template>
                {{ props.item.quantity | formatMarginNumber(5) }}
              </span>
            </td>
            <td>{{ props.item.price | formatMarginPrice(OrderUtils.getTickSize(props.item)) }}</td>
            <td>{{ props.item.quantity - props.item.remaining | formatMarginNumber(5) }}</td>
            <td>{{ props.item.remaining | formatMarginNumber(5, '0') }}</td>
            <td>{{ props.item.order_value | formatMarginNumber(6) }}</td>
            <td>{{ props.item.executed_price | formatMarginPrice(OrderUtils.getTickSize(props.item)) }}</td>
            <td>{{ $t(getOrderCommonType(props.item.type, props.item.stop_type, props.item.pair_type, props.item)) }}</td>
            <td>{{ $t(OrderUtils.getOrderStatus(props.item)) }}</td>
            <td v-if ="!props.item.note">--</td>
            <td v-else> {{props.item.note | upperFirst}} </td>
            <td> {{ OrderUtils.getOrderNote(props.item) }} </td>
            <td class="time-column">{{ props.item.created_at | localTime }}</td>
            <td>
              <button class="btn btn-cancel" @click="cancelOrder(props.item)">
                {{ $t('margin.cancel') }}
              </button>
            </td>
          </tr>
        </template>
      </data-table2>
    </div>

  </div>
</template>
<script>
  import rf from '@/request/RequestFactory'
  import Const from '@/common/Const';
  import BigNumber from 'bignumber.js';
  import moment from 'moment';
  import OrderUtils from './OrderUtils.js';
  import { mapGetters, mapMutations, mapActions } from 'vuex';

  export default {
    components: {

    },
    data() {
      return {
        OrderUtils,
        isRefreshing: false,
      }
    },
    watch: {
     
    },
    computed: {
      ...mapGetters(['instrumentBySymbol']),
    },
    methods: {
      ...mapMutations({ setActiveOrderCount: 'setMamActiveOrderCount' }),
      ...mapActions(['getMamBalance']),
      getOrderCommonType(orderType, stopType, pairType, items) {
        if(!!pairType) return 'margin.' + pairType
        if(!!stopType) return 'margin.' + stopType
        if(!!orderType) return 'margin.' + orderType
      },
      getSocketEventHandlers() {
        return {
          MarginOrderUpdated: this.onMarginOrderUpdated,
        };
      },

      onMarginOrderUpdated(data) {
        if (data && data.order && data.action == 'canceled') {
          this.getMamBalance();
        }
        this.isRefreshing = true;
        this.$refs.datatable.fetch();
      },

      getData(params) {
        params.limit = 100;
        if (this.isRefreshing) {
          this.isRefreshing = false;
          params.is_refreshing = true;
        }
        if (!window.isAuthenticated) return;

        return rf.getRequest('MarginRequest').getActiveOrder({ ...params, is_mam: 1 })
          .then(res => {
            let total = 0;
            if (!res.data) {
              total = 0;
            } else if (res.data.total == undefined) {
              total = res.data.length;
            } else {
              total = res.data.total;
            }
            this.setActiveOrderCount(total);
            return res;
          });
      },

      cancelOrder(order) {
        let data = {
          id: order.id,
        }
        rf.getRequest('MarginRequest').cancelActiveOrder({ ...data, is_mam: 1 }).then(res => {
          // Message.success(this.$t('margin.order.canceled_success'), {}, { position: 'bottom_left' });
          // let rows = this.$refs.datatable.rows;
          // let index = rows.find(item => item.id === order.id);
          // rows.splice(index, 1);
          // this.$refs.datatable.rows = rows;
        }).catch(error => {
          console.log(error);
          Message.error('Cannot cancel order', {}, { position: 'bottom_left' });
        }).finally(() => {
          this.$refs.datatable.refresh();
        });
      },
    },
    mounted() {
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/sass/variables';
  .fl-right {
    float: right;
  }
  #acrive_orders {
    .btn-cancel {
      width: 55px;
      height: 25px;
      border-radius: 3px;
      border: solid 1px $color-jungle-green;
      letter-spacing: 0.8px;
      text-align: center;
      color: $color-jungle-green;
      font-size: $font-mini-mini;
      background-color: transparent;
      padding: 5px 2px;
      text-transform: uppercase;
      font-family: $font-family-roboto-bold;
      float: right;
      &:hover, &:focus {
        background-color: $color-blue-custom;
        border-color: $color-blue-custom;
        color: $color-white;
      }
    }
    .btn-clear {
      border-color: $color-denim;
      color: $color-denim;
    }
  }

  .box_custom_tooltip {
    position: relative;
    .tooltip_custom_c {
      visibility: hidden;
      display: none;
      width: 250px;
      background-color: #222832;
      color: #cccccc;
      text-align: center;
      padding: 5px 0;
      border-radius: 4px;
      font-size: 13px;
      -webkit-box-shadow: 0px 0px 3px #cccccc;
      box-shadow: 0px 0px 3px #cccccc;
      position: absolute;
      z-index: 50;
      left: 100%;
      top: 100%;
      line-height: 18px;
      &.left {
        left: auto;
        right: 100%;
      }
      .underline {
        text-decoration: underline;
        display: block;
        line-height: 20px;
        margin-bottom: 5px;
      }
    }
    &:hover {
      cursor: help;
      .tooltip_custom_c {
        display: block;
        visibility: visible;
        color: #cccccc;
      }
    }
  }
</style>

<style lang="scss">
  @import '@/assets/sass/variables';
   #acrive_orders {
    .table-positions {
      height: 238px;
      overflow-y: auto;
      overflow-x: auto;
      &::-webkit-scrollbar-track
      {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 0px;
        background-color: #555555;
      }

      &::-webkit-scrollbar
      {
        width: 5px;
        background-color: #555555;
      }

      &::-webkit-scrollbar-thumb
      {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: $color-grey;
      }
      .time-column {
        min-width: 138px;
      }
      .VuePagination {
        background-color: transparent;
        .page-item {
          a {
            color: $color-gray-dark;
            &:hover {
              color: $color-jungle-green;
            }
          }
          &.active {
            a {
              color: $color-jungle-green;
            }
          }
        }
      }
      table {
        overflow-x: initial;
        overflow-y: initial;
        display: block;
        width: 100%;
        min-width: 1200px;
        thead {
          display: block;
          width: 100%;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          position: sticky;
          top: 0px;
          background-color: #11212e;
          z-index: 5;
          overflow: inherit;
          tr {
            display: block;
            width: 100%;
            float: left;
            background-color: #191f2a;
            th {
              display: block;
              float: left;
              height: 48px;
              padding: 14px 0px 14px 16px;
              text-align: left;
              vertical-align: middle;
              font-size: $font-smaller;
              font-family: $font-family-roboto;
              color: $color-grey-dusty;
              &:last-child {
                float: right;
                padding: 14px 16px 14px 8px;
              }
            }
          }
        }
        tbody {
          display: block;
          width: 100%;
          tr {
            height: 36px;
            display: block;
            position: relative;
            height: 36px;
            margin-bottom: 2px;
            td {
              display: block;
              float: left;
              padding: 8px 0px 8px 16px;
              line-height: 20px;
              text-align: left;
              vertical-align: middle;
              color: $color-gray-dark;
              font-size: $font-smaller;
              font-family: $font-family-roboto-medium;
              &:last-child {
                float: right;
                padding: 8px 16px 8px 8px;
              }
              .symbol_th {
                font-size: 13px;
                color: $color-blue-custom;
              }
              .up {
                color: $color-jungle-green;
              }
              .down {
                color: $color-denim;
              }
            }
            &.empty-data {
              height: auto;
              text-align: center;
              &:hover {
                background-color: transparent !important;
              }
              td {
                margin: auto;
                float: none;
                width: auto;
              }
            }
          }
        }
      }
    }
    table {
      thead {
        background-color: transparent;
        overflow: inherit;
        th:first-child{
          width: 7%;
        }
        th:nth-child(2){
          width: 7%;
        }
        th:nth-child(3){
          width: 7%
        }
        th:nth-child(4){
          width: 6%;
        }
        th:nth-child(5){
          width: 6%;
        }
        th:nth-child(6) {
          width: 8%;
        }
        th:nth-child(7) {
          width: 7%;
        }
        th:nth-child(8) {
          width: 9%;
        }
        th:nth-child(9) {
          width:7%;
        }
        th:nth-child(10) {
          width: 7%;
        }
        th:nth-child(11) {
          width: 9%;
        }
        th:nth-child(12) {
          width: 7%;
        }
        th:nth-child(13) {
          width: 7%;
        }
      }
      tbody {
        td:first-child{
         width: 7%;
        }
        td:nth-child(2){
         width: 7%;
        }
        td:nth-child(3){
         width: 7%
        }
        td:nth-child(4){
         width: 6%;
        }
        td:nth-child(5) {
         width: 6%;
        }
        td:nth-child(6) {
         width: 8%;
        }
        td:nth-child(7) {
         width: 7%;
        }
        td:nth-child(8) {
         width: 9%;
        }
        td:nth-child(9) {
         width:7%;
        }
        td:nth-child(10) {
         width: 7%;
        }
        td:nth-child(11) {
          width: 9%;
        }
        td:nth-child(12) {
          width: 7%;
        }
        td:nth-child(13) {
          width: 7%;
        }
      }
    }
    .border_tr {
      position: relative;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      &.up {
        border-left: 5px solid $color-jungle-green;
        border-right: 5px solid $color-jungle-green;
      }
      &.down {
        border-left: 5px solid $color-denim;
        border-right: 5px solid $color-denim;
      }
    }
  }
</style>