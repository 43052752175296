<template>
  <div id="left-menu" :class="[sizeScreen < 769 && 'hideLeftMenu']">
    <div
      class="wrapper"
      v-for="(item, index) in listTab"
      :key="index"
      @click="select(item)"
    >
      <router-link :to="`${item.url}`">
        <div
          :class="['tab', tabIdActive == item.id && 'active']"
          @click="select(item.id)"
        >
          <img :src="tabIdActive == item.id ? item.iconActive : lightMode ? item.icon : item.iconDark" />
          <span>{{ $t(item.nameTab) }}</span>
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
import { left_menu } from "@/pages/wallet/constants/mockData";
import { mapState } from 'vuex';
export default {
  data() {
    return {
      listTab: left_menu,
    };
  },
  props: {
    tabIdActive: { type: Number, default: 1 },
    sizeScreen: {type : Number, default : 0}
  },
  computed: {
    ...mapState({
      lightMode: state => state.userThemeMode === 'light-theme',
    }),
  },
  methods: {
    select(item) {
      this.$emit("selected", item.id);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables";
#left-menu {
  height: 100%;
  min-height: 700px;

  .wrapper {
    a {
      text-decoration: unset;
    }
    .active {
      background-color: var(--color-tab-active);
      border-right: solid 6px $color-caribbean-green;
    }
    img {
      width: 30px;
    }
    .tab {
      padding: 18px 10px 18px 50px;
      display: flex;
      align-items: center;
      cursor: pointer;
      gap: 20px;
      span {
        font-weight: 400;
        font-size: 20px;
        color: var(--text-primary-color);
      }
    }

    &:hover{
      background-color: var(--color-tab-active);
    }
  }
}
.hideLeftMenu {
  display: none;
}
</style>