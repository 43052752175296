<template>
  <div id="left-menu" :class="[sizeScreen < 769 && 'hideLeftMenu']">
    <div
      class="wrapper"
      v-for="(item, index) in listTab"
      :key="index"
      @click="select(item)"
    >
      <router-link :to="`${item.url}`">
        <div
          :class="['tab', tabIdActive == item.id && 'active']"
          @click="select(item.id)"
        >
          <span>{{ $t(item.nameTab) }}</span>
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
import { left_menu } from "@/pages/wallet/constants/mockData";
import { mapState } from 'vuex';
import OverviewIcon from "@/pages/wallet/assets/icon/OverviewIcon.svg";
import IconOverviewDark from "@/pages/wallet/assets/icon/icon-overview-dark.svg";
import SpotIcon from "@/pages/wallet/assets/icon/Spot_icon.svg";
import IconSpotDark from "@/pages/wallet/assets/icon/icon-spot-dark.svg";
import FuturesIcon from "@/pages/wallet/assets/icon/Futures_icon.svg";
import IconFutureDark from "@/pages/wallet/assets/icon/Futures_icon_dark.svg";
import TransactionHistoryIcon from "@/pages/wallet/assets/icon/TransactionHistory_icon.svg";
import IconTransaction from "@/pages/wallet/assets/icon/icon_transaction.svg";
export default {
  data() {
    return {
      listTab:  [
        {
          id : 0,
          nameTab : 'menu.open_orders',
          url : '/convert/open-order',
        },
        {
          id : 1,
          nameTab : 'menu.history.convert',
          url : '/convert/history',
        },
      ]
      ,
    };
  },
  props: {
    tabIdActive: { type: Number, default: 1 },
    sizeScreen: {type : Number, default : 0}
  },
  computed: {
    ...mapState({
      lightMode: state => state.userThemeMode === 'light-theme',
    }),
  },
  methods: {
    select(item) {
      this.$emit("selected", item.id);
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/sass/variables";
#left-menu {
  height: 100%;
  min-height: 700px;

  .wrapper {
    a {
      text-decoration: unset;
    }
    .active {
      background-color: var(--color-tab-active);
      border-right: solid 6px $color-caribbean-green;
    }
    img {
      width: 30px;
    }
    .tab {
      padding: 18px 10px 18px 50px;
      display: flex;
      align-items: center;
      cursor: pointer;
      gap: 20px;
      span {
        font-weight: 400;
        font-size: 20px;
        color: var(--text-primary-color);
      }
    }

    &:hover{
      background-color: var(--color-tab-active);
    }
  }
}
.hideLeftMenu {
  display: none;
}
</style>