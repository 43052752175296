<template>
  <div id="account_container">
    <div id="account-sitebar" :class="{'hideLeftMenu': sizeScreen < 1300}">
      <AccountSiteBar />
    </div>
    <div id="acount_content" :class="{'overScreen': sizeScreen < 1300}">
      <router-view />
    </div>
  </div>
</template>
<script>
import AccountSiteBar from "@/components/shared_components/common/AccountSiteBar.vue";
export default {
  data() {
    return {
      sizeScreen: window.innerWidth,
    };
  },
  components: {
    AccountSiteBar
  },
  methods: {
    resizeHandler() {
      this.sizeScreen = window.innerWidth;
    },
  },
  mounted() {
    window.addEventListener("resize", this.resizeHandler);
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/variables";
#account_container {
  display: flex;
  height: 100%;
  border-top: solid 4px var(--color-black);
  #account-sitebar {
    // max-width: 340px;
    background-color: var(--background-color-primary);
    border-right: solid 4px var(--color-black);
  }
  #acount_content {
    flex: 1%;
    background-color: var(--background-home-page);
    width: 75%;
  }
  .overScreen {
    width: 100%;
  }
  .hideLeftMenu {
  display: none;
}
}
</style>