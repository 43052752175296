import BaseModelRequest from './base/BaseModelRequest'

export default class NoticeRequest extends BaseModelRequest {
  getModelName() {
    return 'notices'
  }

  getBannerNotices() {
    let url = '/notices/banners';
    return this.get(url);
  }
}
