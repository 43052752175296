<template>
  <div class="chart-container" id="exchange-chart">
    <fullscreen @change="fullscreenChange" ref="fullscreen">
      <div class="chart-option">
        <div class="time-option" style="display: flex; justify-content: space-between;">
          <div>
            <div style="margin: 5px 10px 0px 0px">{{ $t('account.pnl.display_range')}}</div>
            <div class="time_frame">
              <div class="time div-time" :class="{'active-time':(range == 1)}" @click="onChangeResolution('1')">1M</div>
              <div class="time div-time" :class="{'active-time':(range == 3)}" @click="onChangeResolution('3')">3M</div>
              <div class="time div-time" :class="{'active-time':(range == 6)}" @click="onChangeResolution('6')">6M</div>
              <div class="time div-time" :class="{'active-time':(range == 12)}" @click="onChangeResolution('12')">12M</div>
              <div class="time div-time" :class="{'active-time':(range == 24)}" @click="onChangeResolution('24')">24M</div>
              <div class="time div-time" :class="{'active-time':(range == 36)}" @click="onChangeResolution('36')">ALL</div>
            </div>
            <div style="margin: 5px 10px 0px 50px;">{{ $t('account.pnl.source')}}</div>
            <div class="sources">
              <div class="time div-time" :class="{'active-time':(source == 'margin')}" @click="onChangeSource('margin')">{{ $t('account.pnl.margin')}}</div>
              <div class="time div-time" :class="{'active-time':(source == 'mam')}" @click="onChangeSource('mam')">{{ $t('account.pnl.mam')}}</div>
            </div>
          </div>
            
          <div>
            <div style="margin: 5px 10px 0px 0px;">{{ $t('account.pnl.display_period')}}</div>
            <div class="date-frame" style="margin-top: 1px;">
              <date-picker v-model="startDate"  class="date-picker-input no-right-radius" :format="customFormatter"></date-picker>
              <div style="padding: 0 5px; padding-top: 2px;">~</div>
              <date-picker v-model="endDate" class="date-picker-input no-right-radius" :format="customFormatter"></date-picker>
            </div>
          </div>
        </div>
      </div>
      <div class="chart-content" :class="{'chart-full-screen': fullscreen}" >
          <trading-view-chart
            :user="user"
            :startDate="startDate"
            :endDate="endDate"
            :source="source"
            :resolution.sync="resolution"
            :min-tick = "minTick"
            ref="pnlChart">
          </trading-view-chart>
      </div>
    </fullscreen>
    <message></message>
  </div>
</template>

<script>
  import rf from '@/request/RequestFactory'
  import TradingViewChart from './TradingViewChart';
  import LocalStorageUtils from '@/common/LocalStorageUtils';
  import Const from '@/common/Const';
  import moment from "moment";
  import Message from '@/components/shared_components/common/Message';
  import DatePicker from "vuejs-datepicker";

  export default {
    components: {
      TradingViewChart,
      DatePicker,
      Message,
    },
    props: {
      token: {},
      symbol          : { type: String, default: 'notional' },
      keyChart        : { type: Number, default: 1 },
    },
    data() {
      return {
        resolution: 1,
        minTick: 'default',
        range: 1,
        source: 'margin',
        user: {
          pnl_chart_code: this.token
        },
        // selectedMinute: null,
        // selectedHour: '60',
        // isSelectMinute: true,
        // chartActive: 1,
        // timeActive: '1M',
        fullscreen: true,

        selectedTime: '60',

        /*button show Favored List*/
        showFavoredList: false,
        activeFavoredItem: 2,
        startDate: moment().subtract(1, 'months').toDate(),
        endDate: moment().toDate()
      };
    },
    watch: {
      symbol(newValue) {
        // console.log('chart this symbol =>', newValue, this.symbol)
        this.onSymbolChanged();
      },

      resolution() {
      //   this.saveDataLocalStorage();
      },

      startDate(){
        if(this.startDate > this.endDate) {
          this.showError(window.i18n.t('common.date.warning'));
          this.startDate = moment(this.endDate).toDate();
          return;
        }

        const startDate = moment(this.startDate).unix();
        const endDate = moment(this.endDate).unix();
        this.$refs.pnlChart.setRangeDate(startDate, endDate)
      },

      endDate(){
        if(this.startDate > this.endDate) {
          this.showError(window.i18n.t('common.date.warning'));
          this.endDate = moment(this.startDate).toDate();
          return;
        }

        const startDate = moment(this.startDate).unix();
        const endDate = moment(this.endDate).unix();
        this.$refs.pnlChart.setRangeDate(startDate, endDate)
      },

      // selectedTime(newTime) {
      //   if (!newTime) {
      //     return;
      //   }
      //   this.resolution = newTime;
      // },
    },
    methods: {
      showError(message) {
        window.Message.error(message, {}, { position: "bottom_left" });
      },

      getResolution(){
        this.$refs.pnlChart.getResolution()
      },

      // minutes() {
      //   return window._.map(Const.MINUTES, item => {
      //     const name = this.formatTime(item);
      //     return {id: `${item}`, name: name};
      //   });
      // },

      // hours() {
      //   return window._.map(Const.HOURS, item => {
      //     const name = this.formatTime(item);
      //     return {id: `${item}`, name: name};
      //   });
      // },

      customFormatter(date) {
        return moment(date).format('YYYY - MM - DD');
      },

      // times() {
      //   const arr = [
      //     { id: 'D', name: window.i18n.t('exchange.basic.time.one_day') },
      //     { id: 'W', name: window.i18n.t('exchange.basic.time.one_week') },
      //     { id: 'M', name: window.i18n.t('exchange.basic.time.one_month') }
      //   ];
      //   return window._.concat(this.minutes(), this.hours(), arr);
      // },

      // formatTime(time) {
      //   time = parseInt(time);
      //   if (time >= 60) {
      //     let hours = time / 60;
      //     return window.i18n.t(hours > 1 ? 'exchange.basic.time.hours' : 'exchange.basic.time.hour', { number: hours });
      //   }
      //   return window.i18n.t(time > 1 ? 'exchange.basic.time.minutes' : 'exchange.basic.time.minute', { number: time });
      // },

      onSymbolChanged() {
        // this.updateMinTick();
        // this.getDataLocalStorage();

        // update depth chart
        // this.onChangedPairInDepthChart();
      },

      onChangeResolution(time) {
        // this.timeActive = time;
        const res = parseInt(time) * 30;
        const dataRange = {
          1: '1',
          3: '1',
          6: 'D',
          12: 'W',
          24: 'M',
          36: 'M',
        }
        this.resolution = dataRange[time] ? dataRange[time] : 7 //res.toString();
        // this.selectedMinute = null;
        // this.selectedHour = null;
        // this.updateMinTick();
        // this.$refs.pnlChart.getResolution('onChangeResolution')
        this.range = parseInt(time);
        this.startDate = moment().subtract(parseInt(time), 'months').toDate();

        const startDate = moment(this.startDate).unix();
        const endDate = moment(this.endDate).unix();

        this.$refs.pnlChart && this.$refs.pnlChart.setRangeDate() && this.$refs.pnlChart.setRangeDate(startDate, endDate)

      },

      onChangeSource(source) {
        this.source = source
      },
      
      async updateMinTick() {
        let tickSize = this.tickSize;
        if (!tickSize) {
            return;
        }
        let minTick = '1';
        while (new BigNumber('1').div(minTick).gt(tickSize)) {
            minTick = minTick + '0';
        }
        this.minTick =  `${minTick}`;
      },

      // onCloseChart() {
      //   ConfirmationModal.show({
      //     type: 'warning',
      //     title: '',
      //     content: window.i18n.t('exchange.basic.chart.msg_close_chart'),
      //     btnCancelLabel: window.i18n.t('common.action.no'),
      //     btnConfirmLabel: window.i18n.t('common.action.yes'),
      //     onConfirm: () => {
      //       this.$emit('closeChart', this.keyChart);
      //       this.fullscreen = false;
      //     },
      //     onCancel: () => {
      //     }
      //   });
      // },

      // onChangedPairInDepthChart() {
      //   if (this.chartActive === 2) {
      //     this.$refs.depthChart.reload(this.symbol);
      //   }
      // },

      // redrawChart() {
      //   this.$nextTick(() => {
      //     // Re-render depth chart
      //     this.$refs.depthChart.redraw();
      //   });
      // },

      // getTimeActive(time) {
      //   switch(time) {
      //     case DAY:
      //       return 3;
      //     case WEEK:
      //       return 4;
      //     case MONTH:
      //       return 5;
      //   }
      //   throw "Selection tab not correct";
      // },

      // toggleFullScreen() {
      //   this.$refs['fullscreen'].toggle();
      // },

      fullscreenChange (fullscreen) {
        this.fullscreen = fullscreen;
      },

      toUpperCase(value) {
        return value.toUpperCase();
      },

      // saveDataLocalStorage() {
      //   const data = {
      //     // selectedMinute      : this.selectedMinute,
      //     // selectedHour        : this.selectedHour,
      //     // timeActive          : this.timeActive
      //     resolution          : this.resolution,
      //     startDate           : this.startDate,
      //     endDate             : this.endDate,
      //   };
      //   LocalStorageUtils.saveItem('pnl_chart', data);
      // },

      // getDataLocalStorage() {
      //   const data = LocalStorageUtils.getItem('pnl_chart');
      //   if (!data) {
      //     return;
      //   }

      //   this.startDate = data.startDate;
      //   this.endDate = data.endDate;

      //   // this.selectedMinute = data.selectedMinute;
      //   // this.selectedHour = data.selectedHour;
      //   this.resolution = data.resolution;
      //   // this.timeActive = data.timeActive;
      // },

      // showIndicatorDialog() {
      //   if (!window._.isEmpty(this.$refs.pnlChart)) {
      //     this.$refs.pnlChart.showIndicatorDialog();
      //   }
      // },

      // showChartPropertiesDialog() {
      //   if (!window._.isEmpty(this.$refs.pnlChart)) {
      //     this.$refs.pnlChart.showChartPropertiesDialog();
      //   }
      // },

      // onSaveTrandingChartConfiguration() {
      //   if (!window._.isEmpty(this.$refs.pnlChart)) {
      //     this.$refs.pnlChart.saveTradingChartConfiguration();
      //   }
      // },

      // onReloadTrandingChart() {
      //   if (!window._.isEmpty(this.$refs.pnlChart)) {
      //     this.$refs.pnlChart.reloadTrandingChart();
      //   }
      // },

      // onshowFavoredList() {
      //   this.showFavoredList = !this.showFavoredList;
      // },

      // showSetBarStyle(type) {
      //   if (!window._.isEmpty(this.$refs.pnlChart)) {
      //     this.$refs.pnlChart.setBarStyle(type);
      //   }
      // },
    },
    created() {
      // this.resolution = this.selectedHour;
      // this.getDataLocalStorage();
      this.onChangeResolution(1);
    }
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/sass/variables';

  $borderColor: #BBBBBB;
  $heightOption: 42px;   
  .chart-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    &>:first-child {
      width: 100%;
      height: 100%;
    }
    .fullscreen {
      background: $color-white !important;
    }
    .chart-option {
      min-width: 393px;
      background-color: $color-alabaster;
      padding: 0px 10px;
      height: $heightOption;
      border-bottom: 1px solid $color-grey;
      line-height: 28px;
      font-size: $font-smaller;
      color: $color-grey;
      .time-option {
        height: 100%;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        justify-content: space-between;
        div {
          height: 30px;
          margin-top: 2px;
          display: flex;
          /*padding-top: 3px;*/

          &.sc_search_select {
            margin-top: 0px;
          }
          select {
            height: 100%;
            padding-left: 5px;
            -webkit-appearance: none;
            border: none;
            border-right: 1px solid $borderColor;
            // padding-top: 3px;
            padding-bottom: 3px;
            -webkit-border-radius: 0px;
          }
        }
        .select-box-mul-chart {
          border-right: 1px solid $borderColor;
        }
        .time {
          &.div-time {
            border-right: 0px;
            display: inline-block;
            padding: 0px 8px;
            min-width: 35px;
            border-radius: 3px;
            margin-right: 3px;
            cursor: pointer;
            &:hover {
              background-color: $color-charade;
              color: $color-jungle-green;
            }
          }
          &.button-icon {
            cursor: pointer;
            font-size: $font-big;
            .icon_drop {              
              font-size: $font-mini-mini;
              float: right;
              line-height: 26px;
              margin-left: 5px;
            }
            &:hover {
              background-color: $color-blue-custom;
              color: $color-white;
            }
          }
          &.active-time {
            background-color: $color-jungle-green;
            color: $color-white;
          }
        }

        .group_favered {
          position: relative;
          .btn-favored {
            border: 0px;
            margin: 0 0 0 0;
            padding: 0 0 0 0;
            line-height: 20px;
            background-color: transparent;
            .icon {
              font-size: $font-big;
              display: inline-block;
              float: left;
              margin: 4px 0px;
            }
            .icon_drop {
              line-height: 25px;
              height: 25px;
              float: right;
              padding-top: 2px;
              font-size: 9px;
            }            
          }
          .box_favored_list {
            position: absolute;
            top: 100%;
            right: 0px;
            width: 195px;
            z-index: 999;
            margin-top: 5px;
            height: auto;
            .favored_lit {
              background-color: $color-white;
              margin: 0;
              padding: 5px 0px;
              li.item {
                display: block;
                width: 100%;
                line-height: 20px;
                cursor: pointer;
                padding: 3px 14px;
                font-size: $font-root;
                overflow: hidden;
                color: $color-grey-dark;
                &:hover {
                  color: $color-jungle-green;
                  .icon {
                    &:after,
                    &:before {
                      color: $color-jungle-green;
                    }
                  }
                }
                .icon {
                  display: inline-block;
                  float: left;
                  font-size: $font-big;
                  line-height: 20px;
                  margin-right: 5px;
                  width: 20px;
                }
                .name {
                  display: inline-block;
                  float: left;
                  line-height: 20px;
                  font-size: $font-root;
                  width: calc(100% - 25px);
                }
              }
            }
          }
          &.active,
          &:hover {
            background-color: $color-charade;
            .btn-favored {
              color: $color-jungle-green;
            }
          }
          &.active, {
            .btn-favored {
              .icon_drop {
                -ms-transform: rotate(180deg); /* IE 9 */
                -webkit-transform: rotate(180deg); /* Safari prior 9.0 */
                transform: rotate(180deg); /* Standard syntax */
                padding-top: 0px;
                padding-bottom: 18px;
              }
            }
          }
        }
      }
      .chart-type {
        float: right;
        height: 100%;
        line-height: $heightOption;
        .icon-close1 {
          color: $color-white;
          float: right;
          line-height: $heightOption;
          padding-left: 9px;
          padding-right: 9px;
          cursor: pointer;
          background: $color-grey;
        }
        .icon-screen {
          float: right;
          padding-left: 9px;
          padding-right: 9px;
          cursor: pointer;
          line-height: $heightOption;
        }
        .chart-tab {
          cursor: pointer;
          margin: 0;
          float: right;
          .tab-element {
            border-right: 0px;
            float: left;
            padding-left: 8px;
            padding-right: 8px;
            font-size: $font-smaller;
            color: $color-eden-light;
            font-family: $font-family-roboto-medium;
            &.active-tab {
              background: transparent;
              color: $color-jungle-green;
            }
          }
          a {
            color: $color-grey-dusty;
            &:hover {
              color: $color-corn;
            }
          }
        }
      }
    }
    .chart-content {
      height: calc(100% - #{$heightOption});
      width: 100%;
      .vue-highcharts {
        box-shadow: none !important;
        border: none !important;
      }
      &.chart-full-screen {
        height: calc(100% - #{$heightOption}) !important;
      }
    }
  }
</style>

