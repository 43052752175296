
<template>
  <div
    id="open-order-convert"
    class="open_order_s"
    :class="{
      no_padding: options && options.isNoPadding,
      trade_layout: openOrderLayout === 'trade',
    }"
  >
    <modal-sub />
    <div class="d-flex align-items-center group-control">
      <div
        class="drop-down-pair position-relative d-flex justify-content-between align-items-center"
        v-click-outside="clickOutSidePair"
      >
        <div
          class="w-100 current-pair"
          @click="openDropPair = !openDropPair"
        >
          <span v-if="currentPair.name">{{ currentPair.name }}</span>
          <span v-else class="placeholder-input">{{ $t("convert.coin-pair") }}</span>
        </div>
        <span
          class="w-100 h-100 current-pair"
          @click="openDropPair = !openDropPair"
        ></span>
        <i
          class="icon"
          @click="openDropPair = !openDropPair"
          :class="{ 'icon-arrow2': openDropPair, 'icon-arrow1': !openDropPair }"
        ></i>
        <div class="position-absolute drop-list-pair" v-show="openDropPair">
          <div class="position-relative text-search-pair">
            <input
              v-model="pairName"
              class="w-100 h-100"
              type="text"
              @input="searchPair"
              @focus="focusInput = true"
              :placeholder="$t('order.trade_history.search')"
            />
            <img
              class="close-value position-absolute"
              width="19"
              height="19"
              :src="require(`@/assets/images/landing/icon-search${dark}.svg`)"
            />
          </div>
          <div class="list-pair-drop">
            <template v-if="pairShow?.length > 0">
              <div
                class="list-pair"
                v-for="pair in pairShow"
                :key="pair.value"
                @click="selectPair(pair)"
              >
                <span>{{ pair.name }}</span>
              </div>
            </template>
            <div v-else class="list-no-data">{{ $t("convert.noresult") }}</div>
          </div>
        </div>
      </div>
      <div class="group-type">
        <div class="content-search search-type side-layout-order">
          <div class="button-side-layout-order">
            <button class="button-search" @click="searchOrder">
              {{ $t("common.search") }}
            </button>
            <button class="button-reset" @click="handleReset">
              {{ $t("button.reset") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <data-table3
      ref="datatable"
      :get-data="getData"
      :tableOpenOrder="tableOpenOrder"
      :msg-empty-data="$t('common.datatable.no_have_data')"
      class="table-convert"
      :limit="6"
    >
      <template slot-scope="props">
        <th
          v-for="item in headerTable"
          :key="item.id"
          :title="$t(item && item.name)"
          :data-sort-field="item && item.isSort ? item.id : null"
          @click="props.echoclick"
          :class="{ cursor: item.id === 'triggerConditions' }"
        >
          <span>{{ $t(item.name) }}</span>
          <i
            v-if="item.isSort"
            class="group-sort-tabl3"
            v-html="props.innerHtmlSort"
          ></i>
          <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
        </th>
        <th class="cancel-box text-right">
          <cancel-box
            @action-cancel-box="actionCancelOpenOrder"
            :visibleCancel="true"
          />
        </th>
      </template>
      <template slot="body" slot-scope="props">
        <tr>
          <td class="cl_date">
            {{ props.item.created_at | timestampToDate }}
          </td>
          <td
            class="cl_coin"
          >
            <img
              :src="`${coinImages[props?.item?.coin]}`"
              alt=""
             
              class="coin-img"
            />
            <img
              :src="`${currencyCoinsImages[props?.item?.currency]}`"
              alt=""
             
              class="currency-img"
            />
            {{ props.item.coin | uppercase }}/{{
              props.item.currency | uppercase
            }}
          </td>
          <td class="cl_price">
            <span
              v-if="
                props.item.type == 'market' || props.item.type == 'stop_market'
              "
            >
              {{ $t("order.open_order.market") }}</span
            >
            <span
              v-else
              class="hoverPrice"
            >
              <!--              // from -->
              <template v-if="props.item.trade_type === 'buy'">
<!--                <div-->
<!--                  v-show="isShowTooltip(props.item.total, 12, 5)"-->
<!--                  class="unHideCoin"-->
<!--                >-->
<!--                  {{ formatRoundDown(props.item.total, 5) }}-->
<!--                </div>-->
                {{
                  props.item.total | formatNumberAndDecimal
                }}
                {{ props.item.currency | uppercase }}
              </template>
              <template v-else>
<!--                <div-->
<!--                  v-show="isShowTooltip(props.item.quantity, 12, 5)"-->
<!--                  class="unHideCoin"-->
<!--                >-->
<!--                  props.item.quantity | formatNumberAndDecimal-->
<!--                </div>-->
                {{
                  props.item.quantity | formatNumberAndDecimal
                }}
                {{ props.item.coin | uppercase }}</template
              >
            </span>
          </td>

          <td
            class="cl_amount"
          >
          <span
              v-if="
                props.item.type == 'market' || props.item.type == 'stop_market'
              "
            >
<!--              <div-->
<!--                v-show="isShowTooltip(props.item.quantity, 12, 5)"-->
<!--                class="unHideCoin"-->
<!--              >-->
<!--                {{ formatRoundDown(props.item.quantity, 5) }}-->
<!--              </div>-->
              {{ props.item.quantity | formatNumberAndDecimal }}
              {{ props.item.trade_type === 'buy' ? props.item.currency : props.item.coin  | uppercase }}
          </span>
          <span
            v-else
            class="hoverPrice"
          >
          <template v-if="props.item.trade_type === 'buy'">
<!--              <div-->
<!--                v-show="isShowTooltip(props.item.quantity, 12, 5)"-->
<!--                class="unHideCoin"-->
<!--              >-->
<!--                {{ formatRoundDown(props.item.quantity, 5) }}-->
<!--              </div>-->
              {{
              props.item.quantity | formatNumberAndDecimal
                  
              }}
              {{ props.item.coin | uppercase }}</template
            >
            <template v-else>
<!--              <div-->
<!--                v-show="isShowTooltip(props.item.total, 12, 5)"-->
<!--                class="unHideCoin"-->
<!--              >-->
<!--                {{ formatRoundDown(props.item.total, 5) }}-->
<!--              </div>-->
              {{
                props.item.total | formatNumberAndDecimal
              }}
              {{ props.item.currency | uppercase }}</template
            >
          </span>
          </td>
          <td
          >
            <!--            <span>-->
            <!--              sell = queot = to -->
            <span class="hoverPrice">
              <div
                v-show="isShowTooltip(props.item.price, 8, 8)"
                class="unHideCoin"
              >
                {{ formatRoundDown(props.item.price, 8) }}
              </div>
              <p class="no-margin">{{ underlyingAsset(props.item) }}</p>
            </span>
           
            <span class="hoverPrice">
<!--              <div-->
<!--                v-show="isShowTooltip((1/props.item.price).toString(), 8, 8)"-->
<!--                class="unHideCoin"-->
<!--              >-->
<!--                {{ formatRoundDown((1/props.item.price).toString(), 8) }}-->
<!--              </div>-->
              <p>{{ priceAsset(props.item)  }}</p>
            </span>
          </td>
          <td>
            <span class="hoverPrice">
<!--              <div-->
<!--                v-show="isShowTooltip(props.item.executed_quantity, 12, 5)"-->
<!--                class="unHideCoin"-->
<!--              >-->
<!--                {{ formatRoundDown(props.item.executed_quantity, 5) }}-->
<!--              </div>-->
              {{
                props.item.executed_quantity | formatNumberAndDecimal
              }}
            </span>
          </td>
          <td class="t-right cursor-pointer pe-auto">
            <span
              class="cursor-pointer"
              @click="actionCancelOpenOrder('one', props.item.id)"
              >{{ $t("common.action.cancel") }}</span
            >
          </td>
          <!--  <td class="t-right">
             <buttpn class="btn btn-subs" @click="showModalSub">subs</buttpn>
           </td> -->
        </tr>
      </template>
    </data-table3>
  </div>
</template>

<script>
import OPEN_ORDER_HEADER_CONST from "./DataTableInit.js";
import COMMON_CONST from "@/common/Const";
import rf from "@/request/RequestFactory";
import CancelBox from "@/components/shared_components/common/CancelBox";
import ModalSub from "@/components/shared_components/common/ModalSub";
import BigNumber from "bignumber.js";
import {mapState} from "vuex";
import ETHIcon from "@/assets/images/ethereum-eth-logo.png"

const ORDER_TYPE_LIMIT = COMMON_CONST.SPOT_EXCHANGE.ORDER_TYPE_LIMIT;
const ORDER_TYPE_MARKET = COMMON_CONST.SPOT_EXCHANGE.ORDER_TYPE_MARKET;
const ORDER_TYPE_STOP_LIMIT = COMMON_CONST.SPOT_EXCHANGE.ORDER_TYPE_STOP_LIMIT;
const ORDER_TYPE_STOP_MARKET =
  COMMON_CONST.SPOT_EXCHANGE.ORDER_TYPE_STOP_MARKET;
const ORDER_TYPE_ALL = COMMON_CONST.SPOT_EXCHANGE.ORDER_TYPE_ALL;

export default {
  components: {
    CancelBox,
    ModalSub,
  },
  props: {
    options: {
      type: Object,
      default: null,
    },
    coinCode: {
      type: String,
      default: COMMON_CONST.DEFAULT_COIN,
    },
    currencyCode: {
      type: String,
      default: COMMON_CONST.DEFAULT_CURRENCY,
    },
    tableOpenOrder: {
      type: Boolean,
      default: false,
    },
    openOrderLayout: {
      type: "trade" | "order",
      default: "order",
    },
  },
  data() {
    return {
      headerTable: OPEN_ORDER_HEADER_CONST.OPEN_ORDER_CONVERT_HEADER,
      commonConst: COMMON_CONST,
      isHide: false,
      deleteType: COMMON_CONST.DEFAULT,
      coin: this.coinCode,
      currency: this.currencyCode,
      fetchingOpenOrder: false,
      coinsMasterData: {},
      coinNames: {},
      coinImages: {},
      currencyCoinNames: {},
      currencyCoinsImages: {},
      isHasHover: window.matchMedia("(min-width: 1150px)").matches,
      openDropPair: false,
      pairName: "",
      focusInput: false,
      currentPair: {},
      pairShow: [],
      ethicon: ETHIcon,
      zone: window.localStorage.getItem('spotEx_zone') || 0,

    };
  },
  computed: {
    ...mapState({
      masterData: (state) => state.masterdata,
      theme: (state) => state.userThemeMode,
    }),
    dark() {
      return this.theme === "light-theme" ? "" : "-dark";
    },
  },
  methods: {
    underlyingAsset(item) {
      return `1 ${item.coin.toUpperCase()} =
      ${this.$options.filters.formatNumberAndDecimal(item.price)}
      ${item.currency.toUpperCase()}`;
    },
    priceAsset(item) {
      const total = item.type == 'market'? 0 : (1 / item.price).toString();
      return `1 ${item.currency.toUpperCase()} =
      ${this.$options.filters.formatNumberAndDecimal(total)}
      ${item.coin.toUpperCase()}`;
    },
    handleWindowResize() {
      this.isHasHover = window.matchMedia("(min-width: 1150px)").matches;
    },
    localizeType(type) {
      if (type == "limit") return this.$t("order.order_form.limit");
      if (type == "market") return this.$t("orders.open_order.market");
      if (type == "stop_market")
        return this.$t("order.order_form.stop_market_table");
      if (type == "stop_limit")
        return this.$t("order.order_form.stop_limit_table");
    },
    mulBigNumber(number1, number2) {
      if (!number1 || !number2) return "0";
      return new BigNumber(number1).mul(number2).toFixed(15).toString();
    },
    isIE() {
      let ua = navigator.userAgent;
      var is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
      if (is_ie && $("table").length > 0) {
        $("#open-order table").addClass("fixieCancelbox-full");
      }
    },
    getEventHandlers() {
      return {
        SpotSelectedPairMarket: this.onSpotSelectedPairMarket,
        SpotOrderListUpdated: this.onOrderListUpdated,
      };
    },
    onSpotSelectedPairMarket(pair) {
      this.coin = pair.coin;
      this.currency = pair.currency;
    },
    onOrderListUpdated(data, isHideOtherPair) {
      if (!this.fetchingOpenOrder) {
        this.fetchingOpenOrder = true;
        if (isHideOtherPair) {
          this.$refs.datatable.filter({
            coin: this.coin,
            currency: this.currency,
          });
        } else {
          this.refreshTable();
        }
      }
    },
    refreshTable() {
      this.$refs && this.$refs.datatable && this.$refs.datatable.refresh();
    },
    getData(params) {
      if (!window.isAuthenticated) {
        return new Promise((resolve) => {
          return resolve({});
        });
      }
      if (this.openOrderLayout === "order") {
        // const el = document.getElementById('open-order');
        // if (el) {
        //   el.scrollIntoView({behavior: "smooth"});
        // }
      }
      params.market_type = 1;
      return rf
        .getRequest("OrderRequest")
        .getOrdersPending(params)
        .then((res) => {
          this.fetchingOpenOrder = false;
          this.$emit("dataFromChild", res.data.total);
          return res;
        });
    },
    showModalSub() {
      window.CommonModal.show("ModalSub");
    },
    actionCancelOpenOrder(deleteType = null, id = null) {
      const type = deleteType ? deleteType : this.deleteType;
      if (this.$refs.datatable.rows.length === 0) {
        this.showErrorToastMessageWithType(type);
        return;
      }

      if (
        (!deleteType && this.deleteType === this.commonConst.DEFAULT) ||
        this.$refs.datatable.rows.length === 0
      ) {
        return;
      }
      if (deleteType === "one") {
        const message = this.$t("order.open_order.cancel_one_success");
        this.requestHandler(
          rf.getRequest("OrderRequest").cancel(id),
          message,
          deleteType
        );
        return;
      }
      this.confirmCancelOrder(deleteType, id);
    },

    showErrorToastMessageWithType(type) {
      let message = "";
      let subMessage = "123";
      switch (type) {
        case ORDER_TYPE_LIMIT:
          message = window.i18n.t("order.open_order.no_data.limit");
          subMessage = window.i18n.t(
            "order.open_order.no_data.limit_sub_message"
          );
          break;
        case ORDER_TYPE_MARKET:
          message = window.i18n.t("order.open_order.no_data.market");
          subMessage = window.i18n.t(
            "order.open_order.no_data.market_sub_message"
          );
          break;
        case ORDER_TYPE_STOP_LIMIT:
          message = window.i18n.t("order.open_order.no_data.stop_limit");
          subMessage = window.i18n.t(
            "order.open_order.no_data.stop_limit_sub_message"
          );
          break;
        case ORDER_TYPE_STOP_MARKET:
          message = window.i18n.t("order.open_order.no_data.stop_market");
          subMessage = window.i18n.t(
            "order.open_order.no_data.stop_market_sub_message"
          );
          break;
        case ORDER_TYPE_ALL:
        default:
          message = window.i18n.t("order.open_order.no_data.all");
          subMessage = window.i18n.t(
            "order.open_order.no_data.all_sub_message"
          );
          break;
      }
      this.showOrderWarningMessage(message, subMessage);
    },

    confirmCancelOrder(type, id = "") {
      let confirmationMessage = "";
      let titleModal = "";
      switch (type) {
        case "one":
          confirmationMessage = window.i18n.t(
            "order.open_order.cancel_one_message"
          );
          titleModal = window.i18n.t("order.open_order.cancel_one_title");
          break;
        case ORDER_TYPE_ALL: {
          confirmationMessage = window.i18n.t(
            "order.open_order.cancel_all_message_convert"
          );
          titleModal = window.i18n.t("order.open_order.cancel_all_title_convert");
          break;
        }
        case ORDER_TYPE_LIMIT: {
          confirmationMessage = window.i18n.t(
            "order.open_order.cancel_limit_price_message"
          );
          titleModal = window.i18n.t(
            "order.open_order.cancel_limit_order_title"
          );
          break;
        }
        case ORDER_TYPE_MARKET: {
          confirmationMessage = window.i18n.t(
            "order.open_order.cancel_market_orders_message"
          );
          titleModal = window.i18n.t(
            "order.open_order.cancel_market_orders_title"
          );
          break;
        }
        case ORDER_TYPE_STOP_LIMIT: {
          confirmationMessage = window.i18n.t(
            "order.open_order.cancel_stop_limit_price_message"
          );
          titleModal = window.i18n.t(
            "order.open_order.cancel_stop_limit_price_title"
          );
          break;
        }
        case ORDER_TYPE_STOP_MARKET: {
          confirmationMessage = window.i18n.t(
            "order.open_order.cancel_stop_market_orders_message"
          );
          titleModal = window.i18n.t(
            "order.open_order.cancel_stop_market_orders_title"
          );
          break;
        }
        default:
          return;
      }
      ConfirmationModal.show({
        type: "primary",
        title: titleModal,
        content: confirmationMessage,
        btnCancelLabel: window.i18n.t("common.action.close"),
        btnConfirmLabel: window.i18n.t("common.action.confirm"),
        onConfirm: () => {
          this.cancelOrder(type, id);
        },
        onCancel: function () {},
        icon: "",
        textAlign: "left",
        class: "order_open_modal",
        displayCloseButton: false
      });
    },
    cancelOrder(type, id = "") {
      this.isShowConfirmDialog = false;
      const body = {
        market_type: 1,
      };
      let message = window.i18n.t("order.open_order.cancel_order_success");
      let messageCancelAll = window.i18n.t(
        "order.open_order.success_cancel_all_message"
      );
      switch (type) {
        case "one":
          message = window.i18n.t("order.open_order.cancel_order_success");
          this.requestHandler(
            rf.getRequest("OrderRequest").cancel(id),
            message,
            type
          );
          break;
        case "all":
          this.requestHandler(
            rf.getRequest("OrderRequest").cancelAll(body),
            messageCancelAll,
            type
          );
          break;
        default:
          this.requestHandler(
            rf.getRequest("OrderRequest").cancelByType({ type: type }),
            message,
            type
          );
          break;
      }
    },
    requestHandler(promise, message, type) {
      promise
        .then((res) => {
          this.$store.dispatch("setRecallBalances", true);
          this.showSuccessMessage(message);
        })
        .catch((error) => {
          if (!error.response) {
            this.showErrorMessage(
              window.i18n.t("common.message.network_error")
            );
          } else {
            // this.showErrorMessage(error.response.data.message);
            this.showErrorToastMessageWithType(type);
          }
        });
    },
    showSuccessMessage(message) {
      this.$refs.datatable.refresh();
      Message.success(message, {}, { position: "bottom_left" });
    },
    showErrorMessage(message) {
      Message.error(message, {}, { position: "bottom_left" });
    },
    showOrderWarningMessage(message, subMessage) {
      Message.orderWarning(
        message,
        subMessage,
        {},
        { position: "bottom_left" }
      );
    },
    handleMasterData() {
      this.currencyCoins = this.masterData.coin_settings;
      this.coinSetting = window._.keyBy(
        this.masterData.coins_confirmation,
        (item) => item.coin
      );
      this.coinsMasterData = window._.keyBy(
        this.masterData.coins,
        (item) => item.coin
      );
      this.coinsMasterData.usd = {
        name: window.i18n.t(`currency.usd.fullname`),
      };

      _.forEach(this.masterData.coins, (item, key) => {
        this.coinNames[item.coin] = item.name;
        this.coinImages[item.coin] = item.icon_image;
        this.currencyCoinNames[item.coin] = item.name;
        this.currencyCoinsImages[item.coin] = item.icon_image;
      });

      this.coinNames["usd"] = window.i18n.t(`currency.usd.fullname`);
      this.coinImages["usd"] = `/images/color_coins/usd.png`;
      this.coinImages['eth'] = this.ethicon;
      this.currencyCoinNames["usd"] = window.i18n.t(`currency.usd.fullname`);
      this.currencyCoinsImages["usd"] = `/images/color_coins/usd.png`;
    },
    clickOutSidePair() {
      this.openDropPair = false;
      this.pairName = "";
      this.focusInput = false;
      this.pairShow = this.pairs;
    },
    searchPair() {
      const debouncedFunc = _.debounce(() => {
        this.pairShow = this.pairs.filter((item) =>
          item.name.toLowerCase().trim().includes(this.pairName.toLowerCase())
        );
      }, 500);
      return debouncedFunc();
    },
    handleDelete() {
      this.pairName = "";
      this.currentPair = {};
      this.pairShow = this.pairs;
    },
    handleReset() {
      this.handleDelete();
      this.$refs.datatable.filter({});
    },
    searchOrder() {
      let [coin, currency] = this.currentPair.name.toLowerCase().split("/");
      this.$refs.datatable.filter({ coin: coin, currency: currency });
    },
    selectPair(pair) {
      this.currentPair = {
        name: pair.name,
        from: pair.name.split("/")[0],
        to: pair.name.split("/")[1],
        coin: pair.name.split("/")[0],
        currency: pair.name.split("/")[1],
      };
      this.openDropPair = false;
      this.pairName = '';
      this.pairShow = this.pairs;
      this.focusInput = false;
    },
    numFormat (value, maxDecimal) {
      const split = parseFloat(value).toString().split('.');
      const afterDecimal = split[1];
      const numFormat = afterDecimal && afterDecimal.length > maxDecimal ? new BigNumber(value).toFixed(
        maxDecimal || 2,
        BigNumber.ROUND_DOWN
      ) : parseFloat(value).toFixed(1);
      return numFormat;
    },
    formatRoundDown(value, maxDecimal) {
      if(value == null || Number(value) == 0 || value == "Infinity") return '--';
      const numFormat = this.numFormat(value, maxDecimal)
      return numFormat.toString().replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    isShowTooltip(value, maxLength, maxDecimal) {
      if(value == null || Number(value) == 0 || value == "Infinity") return false;
      const numFormat = this.numFormat(value, maxDecimal)
      return numFormat.replace(".", "").length > maxLength;
    },
    formatShortText(value, maxDecimal, maxLength) {
      if(value == null || Number(value) == 0 || value == "Infinity") return '--';
      const split = parseFloat(value).toString().split('.');
      const beforeDecimal = split[0];
      const numFormat = this.numFormat(value, maxDecimal)
      const shorttedNumber = beforeDecimal.length > maxLength ? numFormat.slice(0, maxLength-1).replace(/\d(?=(\d{3})+\.)/g, "$&,").concat('...') : 
      numFormat.slice(0, maxLength).replace(/\d(?=(\d{3})+\.)/g, "$&,").concat('...')
      return numFormat.length > maxLength ? shorttedNumber : numFormat.replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleWindowResize);
    this.isIE();
    this.handleMasterData();
  },
  created() {
    rf.getRequest("PriceRequest")
      .getPrices24h()
      .then((res) => {
        for (const key in res.data) {
          if (res.data[key].currency === 'usd') {
              delete res.data[key];
            }
        }
        this.pairs = Object.keys(res.data)
            .map((key) => {
              const coin = key.split("_")[1].toUpperCase();
              const currency = key.split("_")[0].toUpperCase();
              return {
                name: `${coin}/${currency}`,
                value: key, coin, currency,
                zone: this.masterData.coin_settings.find(c => c.coin === coin.toLowerCase() && c.currency === currency.toLowerCase()).zone || 0
              };
            })
            .filter(p => {
              const isFromMasterData = this.masterData.coin_settings.find(md => md.currency == p.currency.toLowerCase() && md.coin == p.coin.toLowerCase())
              return isFromMasterData ? true : false
            })
            .filter(z => z.zone == this.zone)
            .sort((a, b) => {
              const coinA = a.name;
              const coinB = b.name;
              return coinA.localeCompare(coinB);
            });
        this.pairShow = this.pairs;
      });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
#order-table table tbody tr td:last-child {
  text-align: center;
}
</style>
<style lang="scss" scoped>
@import "@/assets/sass/variables";

.dark-theme {
  #open-order-convert {
    background: $color-night;
  }
}
#open-order-convert {
  ::-webkit-scrollbar-thumb {
    /* background: var(--background-progress);  */
    border-radius: 10px;
  }
  .w-15 {
    width: 15%;
  }
  .group-control {
    gap: 20px;
    .drop-down-pair {
      width: 150px;
      height: 49px;
      padding: 14px;
      background: var(--monas-background-dropdown-convert);
      color: var(--text-second-color);
      border-radius: 8px;
      cursor: pointer;
      .current-pair {
        font-size: 18px;
        color: var(--color-choose-pair);
        display: flex;
        align-items: center;
        .placeholder-input {
          position: absolute;
        }
      }
      .icon-arrow1 {
        color: var(--color-choose-pair);
      }
      .drop-list-pair {
        width: 200px;
        background: var(--background-color-primary);
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        top: 57px;
        left:0;
        padding: 20px 0px 20px 20px;
        z-index: 15;

        .list-pair {
          margin-bottom: 16px;
          font-size: 18px;
          color: var(--color-dropdown);

          &:last-child {
            margin-bottom: 0;
          }
          &:hover{
            color: $color-caribbean-green;
          }
        }
        .list-pair-drop {
          max-height: 230px;
          overflow-y: auto;
          overflow-x: hidden;

        }
        .list-no-data {
          text-align: center;
          padding: 24px 0;
          color: var(--text-close);
        }

        .text-search-pair {
          width: 160px;
          height: 39px;
          margin-bottom: 16px;
          font-size: 16px;
          color: var(--text-primary-color);
          input {
            background: var(--background-color-second);
            border-radius: 8px;
            border: none;
            padding: 0 16px;
          }

          .icon-search {
            left: 15px;
            top: 50%;
            transform: translateY(-50%);
          }
          .close-value {
            top: 50%;
            transform: translateY(-50%);
            right: 15px;
          }
        }
      }
      @media only screen and (max-width: 375px) {
        width: 175px !important;
      }
    }
    .group-type {
      display: inline-block;
      float: left;
      .sc_search_select {
        height: 30px;
        width: 95px;
        display: inline-block;
        float: left;
      }

      button {
        line-height: 20px;
        height: 49px;
        padding: 6px 16px;
        width: auto;
         @media only screen and (max-width: 992px) {
          height: 44px;
          padding: 6px 12px;
        }
      }
    }
    .button-reset {
      margin-left: 0px;
      margin-right: 0px;
      background-color: var(--text-search);
      color: var(--color-neutral-dark);
      box-shadow: none;
      font-family: $font-inter-regular;
      border: 1px solid var(--text-search);
      border-radius: 3px;
      width: 100px !important;
      height: 49px;
      font-size: $font-smaller;
      float: left;
      /* -webkit-transition: 0.5s; */
      /* transition: 0.5s; */
      letter-spacing: 0.9px;
      border-radius: 8px ;
      font-size: 18px ;
      &:hover {
        background: $color-gray-dark;
        border-color: $color-gray-dark;
        color: $color-white;
        -webkit-transition: 0.5s;
        transition: 0.5s;
      }
      @media only screen and (max-width: 992px) {
        font-size: 16px;
        line-height: 19px;
        width: 81.5px !important;
      }
    }
    .button-search {
      margin-left: 25px;
      margin-right: 10px;
      background-color: $color-caribbean-green;
      color: $color-grey-dark;
      box-shadow: none;
      font-size: 18px;
      border: 1px solid $color-caribbean-green;
      border-radius: 8px !important;
      width: 100px !important;
      height: 49px;
      float: left;
      -webkit-transition: 0.5s;
      transition: 0.5s;
      letter-spacing: 0.9px;

      &:hover {
        background: $color-aquamarine;
        border-color: $color-aquamarine;
        color: $color-grey-dark;
        -webkit-transition: 0.5s;
        transition: 0.5s;
      }
      @media only screen and (max-width: 992px) {
        font-size: 16px;
        line-height: 19px;
        width: 81.5px !important;
      }
    }
    @media only screen and (max-width: 375px) {
      flex-direction: column;
      align-items: flex-start !important;
    }
  }
  .hoverPrice {
    position: relative;
    .unHideCoin {
      display: none;
    }
    &:hover {
      .unHideCoin {
        // z-index: 20;
        display: block;
        position: absolute;
        padding: 6px;
        border-radius: 8px;
        background: var(--color-tooltip);
        color: $color-white !important;
        top: 20px;
        left: 0;
        font-size: 16px;
        z-index: 10000;
      }
    }
  }
  //.dark-theme {
  //
  //  #open-order-convert {
  //    .VuePagination {
  //      background: $color-cinder!important;
  //      ul {
  //        margin: 0!important;
  //        padding: 20px 0!important;
  //      }
  //    }
  //    .table-convert {
  //      min-height: 500px;
  //
  //      table {
  //        background: $color-cinder;
  //        margin-bottom: 0;
  //        thead {
  //          tr {
  //            th {
  //              color: $color-storm-grey!important;
  //            }
  //          }
  //        }
  //
  //        tbody {
  //          tr {
  //            td {
  //              color: white!important;
  //            }
  //            .red {
  //              color: $text-color-red!important;
  //            }
  //            .blue {
  //              color: $text-color-jade!important;
  //            }
  //          }
  //          //.empty-data {
  //          //  background: transparent;
  //          //}
  //          .empty-data {
  //            background: transparent;
  //            background: $color-cinder;
  //            .icon-no-data-1 {
  //              margin: 0 !important;
  //            }
  //          }
  //
  //        }
  //      }
  //    }
  //  }
  //}

  .table-convert {
    margin-top: 30px;
    overflow-x: auto;
    min-height: 500px;
    table {
      background: transparent !important;
      thead {
        background: var(--background-color-second);
        tr {
          font-family: $font-inter-regular;
          font-size: $font-root;
          th {
            text-align: left;
            font-weight: 400;
            font-family: $font-inter-regular;
            font-size: $font-root;
            height: 65px;
            background: var(--color-tab-data-table);
            color: var(--color-percent);
          }
          th:first-child {
            padding-left: 24px;
            text-align: start;
            border-radius: 8px 0px 0px 8px !important;
            width: 19.0625%;
            min-width: 220px;
          }

          th:nth-child(2) {
            width: 13.28125%;
            min-width: 170px;
          }

          th:nth-child(3) {
            width: 9.375%;
            min-width: 145px;
            text-align: center;
          }
          th:nth-child(4) {
            width: 9.375%;
            min-width: 145px;
            text-align: center;
          }
          th:nth-child(5) {
            width: 27.801%;
            min-width: 300px;
            text-align: center;
          }
          th:nth-child(6) {
            width: 12.890625%;
            min-width: 165px;
            padding-left: 20px;
          }
          th:last-child {
            border-radius: 0px 8px 8px 0px !important;
            width: 8.28125%;
            min-width: 80px;
            .btn-cancel {
              padding-left: 0!important;
            }
          }

          .cancel-box {
            padding-top: 0!important;
            padding-bottom: 0!important;
          }
          .btn-cancel {
            padding-top: 0!important;
            padding-bottom: 0!important;
          }
        }
      }

      tbody {
        tr {
          .coin-img, .currency-img {
            width: 30px;
            height: 30px;
            @media only screen and (max-width: 992px) {
              width: 24px;
              height: 24px;
           }
          }
          .currency-img{
            margin-left: -8px;
            object-fit: contain;
           }
          td {
            padding: 20px 0;
            font-size: 14px;
            line-height: 19px;
            color: var(--text-primary-color) !important;
            white-space: normal;

            @media only screen and (max-width: 992px) {
              p{
                margin: 0;
            }
           }
            
            &:first-child {
              text-align: left !important;
              padding-left: 24px;
              padding-top: 13px;
            }
            &:nth-child(2) {
              text-align: left !important;
              padding-top: 16px;
            }
            &:nth-child(3) {
              // text-align: left !important;
              overflow: visible !important;
              padding-top: 13px;
            }
            &:nth-child(4) {
              // text-align: left;
              overflow: visible !important;
              padding-top: 13px;
            }
            &:nth-child(5) {
              overflow: visible !important;
            }
            &:nth-child(6) {
              text-align: left !important;
              padding-left: 20px;
            }
          }

          .t-right {
            .image {
              img {
                display: none;
              }
            }
          }
          .cursor-pointer {
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
    }
    :deep(.text-right) {
      @media screen and (max-width: 1550px) {
        .VuePagination {
        position:absolute;
        left:0;
        right:0;
        margin-left:auto;
        margin-right:auto;
        bottom: 40px;
      }
      }
    }
    @media screen and (max-width: 992px) {
      min-height: 350px;
    }
  }

  .table-convert {
    //min-height: 100px;
    .cursor {
      cursor: default;
    }
    tbody {
      tr {
        td {
          font-family: $font-inter-regular !important;
          //font-size: 16px !important;
        }
      }
    }
    .VuePagination {
      background: none;
    }
    .pagination {
      justify-content: center;
    }
    table {
      width: 100%;
      overflow-y: initial;
      margin-bottom: 22px;
      font-family: $font-inter-regular;

      tbody {
        tr {
          td {
            font-family: $font-inter-regular !important;
            color: $color-grey-dark;
            //font-size: $font-medium-bigger!important;
            text-align: center;
          }
        }
      }
      background: $color-gray-alabaster;
      .empty-data {
        background: transparent;
        .icon-no-data-1 {
          //margin: 0 !important;
        }
      }
    }
    .icon-sort-table {
      font-size: 17px !important;
    }
  }
  #confirmationModal {
    &.order_open_modal {
      .modal-dialog {
        .modal-content {
          .modal-body {
            button {
              &.btn-cancel {
                color: var(--color_dark_1);
              }
            }
          }
        }
      }
    }
  }
  .w-15 {
    width: 15%;
  }

  #confirmationModal {
    &.order_open_modal {
      .modal-dialog {
        .modal-content {
          .modal-body {
            button {
              &.btn-cancel {
                color: var(--color_dark_1);
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1550px) {
    #open-order-screen .order-group-screen {
      max-width: calc(100vw - 300px) !important;
    }
  }
  @media screen and (max-width: 1300px) {
    #open-order-screen .order-group-screen {
      max-width: 1318px !important;
    }
    .order-group-screen .history-table {
      padding: 0;
    }
  }

  @media only screen and (max-width: 1150px) {
    .trade_layout .table-convert {
      min-width: 1300px;
      table thead tr th {
        &:nth-child(5) {
          min-width: 130px;
        }
      }
    }
  }
  @media only screen and (max-width: 992px) {
    .group-control .drop-down-pair {
      width: 150px;
      height: 44px;
      .current-pair{
        font-size: 14px;
        line-height: 16px;
      }
    }
   .table-convert table tbody tr td{
    font-size: 14px;
    line-height: 16px;
   }
   .group-control{
    gap: 17px;
    .button-search {
      margin-left: 0;
      margin-right: 12px;
    }
   }
    .order-group-screen .history-table {
      padding: 0;
    }
    #order-table.table-open-order {
      .VuePagination {
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        bottom: 20px;
      }
    }
  }
  .no-margin {
    margin: 0;
  }
  :deep(.empty-data) {
    .icon-no-data-1 {
      margin-top: 80px !important;
    }
  }
}
</style>

